import React from 'react';
import {Breadcrumbs, Link, Typography, makeStyles} from '@material-ui/core'

interface Props {
    currentPath: Array<string>
    onClick: (index : number) => void
    initial?: string
}

export const BreadCrumb : React.FC<Props> = ({ currentPath = [], onClick, initial = 'Documents' }) => {

    const classes = useStyles();

    const homePath = [initial, ...currentPath];
    return (
        <Breadcrumbs separator='>' maxItems={3}>
            {homePath.map((directory, index) => (
                <Link key={index} color="inherit" href="#" onClick={() => onClick(index)}>
                    <Typography noWrap className={classes.breadcrumb} variant="h6" color="textSecondary">
                        {directory}
                    </Typography>
                </Link>
            ))}
        </Breadcrumbs>
    );
};

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
    },
    breadcrumb: {
        maxWidth: '25vw'
    }
}));