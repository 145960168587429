import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { MembershipData, MembershipQuery } from '../../models'
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { ConsortiumResourceVars } from '../../interfaces/shared';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { DeleteMembership } from './DeleteMembership';
import { NETWORK_GOVERNANCE_PATH, NETWORK_GOVERNANCE_MEMBERSHIPS_PATH } from '../../components/MainNav/SideNavs/Governance';
import { MembershipPermissions } from '../../components/Memberships/MembershipPermissions';
import { MembershipSummary } from '../../components/Memberships/MembershipSummary';
import { FormLink } from '../../components/FormControls/FormLink';
import { useCertificateInfo } from '../../hooks/useCertificateInfo';

export const GovernanceMembershipDetails: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GovernanceMembershipDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceMembershipDetails:${key}`, interpolate)

    const { org_id, consortium_id, membership_id } = useParams<any>();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    
    const {
        data: {
            membership
        } = { membership: null }
    } = useQuery<MembershipData, ConsortiumResourceVars>(MembershipQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: membership_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        proof: membershipJSONIDProof, 
        isLoading: isMembershipProofLoading
    } = useCertificateInfo({
        membershipId: membership_id,
        skipQuery: !membership?.verification_proof 
    });

    useEffect(() => {
        if (!membership) {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}`);
        }
    }, [consortium_id, history, membership, org_id]);

    
    let certificateCopyableList: CopyableSetting[] = [];
    if (membershipJSONIDProof) {
        certificateCopyableList = [
            { disableCopy: membershipJSONIDProof.commonName === undefined, title: lt('commonName'), displayValue: membershipJSONIDProof.commonName || lt('notAvailable') },
            { disableCopy: membershipJSONIDProof.organization === undefined, title: lt('organization'), displayValue: membershipJSONIDProof.organization || lt('notAvailable') },
            { disableCopy: membershipJSONIDProof.country === undefined, title: lt('country'), displayValue: membershipJSONIDProof.country || lt('notAvailable') },
            { disableCopy: membershipJSONIDProof.serial === undefined, title: lt('serial'), displayValue: membershipJSONIDProof.serial || lt('notAvailable') }
        ]
    };

    if (isMembershipProofLoading) {
        return <CircularProgress />
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item container wrap="nowrap" alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('membershipDetails')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" size="medium" onClick={() => setOpen(true)}>{lt('deleteMembership')}</Button>
                    </Grid>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item container lg={6} xs={12}>
                        <MembershipSummary {...{membership}} />
                    </Grid>
                    <Grid item container lg={6} xs={12}>
                        <MembershipPermissions {...{membership}} />
                    </Grid>
                </Grid>
                {membership && membershipJSONIDProof && (
                    <Grid item container spacing={3}>
                        <Grid item lg={6} xs={12}>
                            <CopyableSettings header={lt('certificate')} copyableList={certificateCopyableList} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLink isExternal={true} download="cert.pem" to={`data:text/plain;charset=utf-8,${membership.verification_proof}`}>{lt('downloadCertificate')}</FormLink>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <DeleteMembership open={open} setOpen={setOpen} />
        </>
    )
};

interface translations {
    membershipDetails: string
    certificate: string
    commonName: string
    organization: string
    country: string
    serial: string
    downloadCertificate: string
    notAvailable: string
    deleteMembership: string
}

const enTranslations: translations = {
    membershipDetails: 'Membership Details',
    certificate: 'Certificate',
    commonName: 'Common name',
    organization: 'Organization',
    country: 'Country',
    serial: 'Serial',
    downloadCertificate: 'Download certificate',
    notAvailable: 'N/A',
    deleteMembership: 'Delete membership'
}