import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SubNavs } from '../../MainNav/SideNavs/SubNavs';

export const LOGS_PATH = 'logs'
export const SETTINGS_PATH = 'settings'
export const NAMESPACES_PATH = 'namespaces'

interface Props {
    baseServicePath: string
    serviceSpecificTabs?: { name: string, path: string, selectedItem: string }[]
}

const BasicItems = ({ baseServicePath, serviceSpecificTabs } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BasicItems', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BasicItems:${key}`, interpolate)
    
    const history = useHistory()
    const { service_id } = useParams<any>();
    const { pathname } = useLocation();

    const [selectedItem, setSelectedItem] = useState<string>();

    const OVERVIEW = lt('overview')
    const LOGS = lt('logs')
    const SETTINGS = lt('settings')
    const NAMESPACES = lt('namespaces')


    const endsWithServiceSpecificPath = useCallback(() => {
        const selected = serviceSpecificTabs?.find(t => pathname.endsWith(`${service_id}/${t.path}`))
        if (selected) {
            setSelectedItem(selected.selectedItem)
        }
    }, [pathname, serviceSpecificTabs, service_id])

    useEffect(() => {
        if (pathname.endsWith(`${service_id}`)) setSelectedItem(OVERVIEW)
        else if (pathname.endsWith(`${service_id}/${LOGS_PATH}`)) setSelectedItem(LOGS)
        else if (pathname.endsWith(`${service_id}/${SETTINGS_PATH}`)) setSelectedItem(SETTINGS)
        else if (pathname.endsWith(`${service_id}/${NAMESPACES_PATH}`)) setSelectedItem(NAMESPACES)
        else endsWithServiceSpecificPath()
    }, [pathname, service_id, LOGS, OVERVIEW, SETTINGS, NAMESPACES, endsWithServiceSpecificPath])

    const historyPusher = (path?: string) => 
        history.push(`${baseServicePath}/${service_id}${path ? `/${path}` : ''}`)

    const baseTopTabs = [
        {
            name: OVERVIEW,
            action: () => historyPusher()
        }
    ]
    const baseBottomTabs = [
        {
            name: LOGS,
            action: () => historyPusher(LOGS_PATH)
        },
        {
            name: SETTINGS,
            action: () => historyPusher(SETTINGS_PATH)
        }
    ]

    if (baseServicePath.endsWith('firefly-os')) {
        baseBottomTabs.push({
            name: NAMESPACES,
            action: () => historyPusher(NAMESPACES_PATH)
        })
    }

    const makeTabs = () => {
        return baseTopTabs.concat(serviceSpecificTabs?.map(t => ({ ...t, action: () => historyPusher(t.path) })) ?? []).concat(baseBottomTabs)
    }

    return (
        <SubNavs list={makeTabs()} selected={true} {...{selectedItem}} />
    );
};

interface translations {
    overview: string
    logs: string
    settings: string
    namespaces: string
}
const enTranslations: translations = {
    overview: 'Dashboard',
    logs: 'Logs',
    settings: 'Settings',
    namespaces: 'Namespaces'
}

export { BasicItems };

