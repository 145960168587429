import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const PrecompiledPasteHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractManagementPrecompiledPasteHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractManagementPrecompiledPasteHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
}
const enTranslations: translations = {
    helpHeader: 'Paste your version metadata',
    helpDescription: 'Provide the already compiled ABI, bytecode, and optionally the Dev Docs for your contract.',
}