import { useQuery } from '@apollo/client';
import BookOpenOutlineIcon from 'mdi-react/BookOpenOutlineIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ConsortiumResourceVars, SideNavProps } from '../../../interfaces';
import { NavItem } from '../../NavComponents/NavItem';
import { SubNavs } from './SubNavs';
import {  EnvironmentData, EnvironmentQuery, FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars } from '../../../models';

export const SYSTEM_MEMBERSHIP = 'system'

export const ADDRESSBOOK_PATH = 'addressbook';
export const ADDRESSBOOK_MEMBERSHIP_PATH = 'memberships';
export const ADDRESSBOOK_RUNTIMES_PATH = 'runtimes';
export const ADDRESSBOOK_DESTINATIONS_PATH = 'destinations';
export const ADDRESSBOOK_ACTIVITY_PATH = 'activity';
export const ADDRESSBOOK_ACCOUNTS_PATH = 'accounts';
export const CHANNELS_PATH = 'channels';
export const ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH = 'certificateauthorities';


export const AddressBook = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AddressBook', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBook:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const historyPusher = useCallback((path: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${path}`), 
        [history, org_id, consortium_id, environment_id]);

    const { data: { 
            environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { }
    });

    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    const MEMBERSHIPS = lt('memberships');
    const RUNTIMES = lt('runtimes');
    const DESTINATIONS = lt('destinations');
    const ACTIVITY = lt('activity')
    const ACCOUNTS = lt('accounts');
    const IDENTITIES = lt('identities');

    const addressBookSubItems = useCallback(() => {
        const items = [{
            name: MEMBERSHIPS,
            action: () => historyPusher(`${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}`)
        },{
            name: RUNTIMES,
            action: () => historyPusher(`${ADDRESSBOOK_PATH}/${ADDRESSBOOK_RUNTIMES_PATH}`)
        }, {
            name: DESTINATIONS,
            action: () => historyPusher(`${ADDRESSBOOK_PATH}/${ADDRESSBOOK_DESTINATIONS_PATH}`)
        }]
        
        if (featureToggles?.fabricChannels && isFabric) items.push({
            name: IDENTITIES,
            action: () => historyPusher(`${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}`)
        })

        if (!isCorda && !isFabric) items.push({
            name: ACCOUNTS,
            action: () => historyPusher(`${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}`)
        })
        
        items.push({
            name: ACTIVITY,
            action: () => historyPusher(`${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACTIVITY_PATH}`)
        })

        return items;
    }, [historyPusher, isCorda, isFabric, featureToggles, MEMBERSHIPS, DESTINATIONS, RUNTIMES, ACTIVITY, ACCOUNTS, IDENTITIES])

    useEffect(() => {
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        const splitPathname = pathname.split('/');
        const currentTab = splitPathname[splitPathname.length -2];

        if (pathname.endsWith(`${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}`)) {
            setSelectedItem(MEMBERSHIPS);
            setActiveAndSelected()
        } else if(pathname.endsWith(`${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_RUNTIMES_PATH}`)) {
            setSelectedItem(RUNTIMES);
            setActiveAndSelected()
        } else if(pathname.endsWith(`${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_DESTINATIONS_PATH}`)) {
            setSelectedItem(DESTINATIONS);
            setActiveAndSelected()
        } else if(pathname.endsWith(`${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}`) || 
            currentTab === ADDRESSBOOK_ACCOUNTS_PATH) {
            setSelectedItem(ACCOUNTS);
            setActiveAndSelected()
        } else if(pathname.endsWith(`${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACTIVITY_PATH}`)) {
            setSelectedItem(ACTIVITY);
            setActiveAndSelected()
        } else if(pathname.endsWith(`${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}`)) {
            setSelectedItem(IDENTITIES);
            setActiveAndSelected()
        } else {
            setActiveAndSelected(false)
            setSelectedItem('')
        }
    }, [setSelected, environment_id, pathname, MEMBERSHIPS, DESTINATIONS, RUNTIMES, ACTIVITY, ACCOUNTS, IDENTITIES])

    return (
        <>
            <NavItem icon={<BookOpenOutlineIcon />}
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={addressBookSubItems()} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations {
    name: string
    memberships: string
    runtimes: string
    destinations: string
    activity: string,
    accounts: string,
    identities: string
}
const enTranslations: translations = {
    name: 'Address Book',
    memberships: 'Memberships',
    runtimes: 'Runtimes',
    destinations: 'Destinations',
    activity: 'Activity Log',
    accounts: 'Signing Accounts',
    identities: 'Certificate Authorities'
}

