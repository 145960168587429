import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const MintHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MintHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MintHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Token-Mint.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/kaleido-services/token-factory/usage/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Mint Token',
    helpDescription: 'In order to add more tokens to the current token supply it is needed to:',
    helpDesc1: "Input the amount of new tokens that want to be created",
    helpDesc2: 'Choose a target address either from the account selector that list all the accounts from all the members in the environment or a valid custom ethereum address. This is an optional step, if a target address is not selected, mints would be transfer to the minter address.',
}