import { useQuery } from '@apollo/client';
import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../components/DialogWrappers';
import { CREATE_ENVIRONMENTS_PATH } from '../../components/MainNav/EnvironmentPicker';
import { FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, ConsortiumZonesData, ConsortiumZonesQuery, Protocol, EnvironmentType } from '../../models';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { ConsortiumResourcesVars } from '../../interfaces';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreate:${key}`, interpolate);

    type locationState = { name: string, hybrid: string }
    const history = useHistory<locationState>()
    const { org_id, consortium_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}`
    
    const { 
        location: { 
            state: {
                name: envName,
                hybrid: envHybrid,
            } = { name: '', hybrid: false }
        } 
    } = history;

    const {
        loading,
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const [environmentType, setEnvironmentType] = useState<EnvironmentType | undefined>(undefined);
    const [protocol, setProtocol] = useState<Protocol | undefined>(undefined);
    const [name, setName] = useState(step === "2" ? envName as string : '');
    const [zoneId, setZoneId] = useState('');
    const [hybrid, setHybrid] = useState(step === "2" ? envHybrid as boolean : false)
    const step1Fields = [environmentType]
    const step2Fields = [protocol]
    const step3Fields = [name]

    const {
        loading: loadingToggles,
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-first'
    });

    const createStep = parseInt(step);
    const invalidStep = (createStep < 1 || createStep > 5);

    useEffect(() => {
        if (!zoneId && consortiumZones.length) {
            setZoneId(consortiumZones[0]._id)
        }
    }, [zoneId, consortiumZones])

    if (loading || loadingToggles) {
        return <CircularProgress />
    }

    if (((createStep >= 2) && !step1Fields.every(r => r)) || (environmentType === 'firefly' && (createStep >= 3) && !step2Fields.every(r => r)) || ((createStep >= 4) && !step2Fields.every(r => r)) || (createStep >= 5 && !step3Fields.every(r => r)) || invalidStep) {
        return <Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${CREATE_ENVIRONMENTS_PATH}/1`} />
    }

    const resolvedStep = (createStep === 3 && !featureToggles.corda) ? "4" : String(createStep);

    const stepComponents = [
        { 
            step: lt('environmentType'), 
            component: <Step1 {...{cancelPath}} {...{environmentType}} {...{setEnvironmentType}}/> 
        },
        { 
            step: lt('protocol'), 
            component: <Step2 environmentType={environmentType!} {...{cancelPath}} {...{protocol}} {...{setProtocol}}/> 
        },
        { 
            step: lt('environmentDetails'), 
            component: <Step3 environmentType={environmentType!} protocol={protocol!} {...{cancelPath}} {...{name}} {...{setName}} 
                {...{hybrid}} {...{setHybrid}} /> 
        },
        { 
            step: lt('settings'), 
            component: <Step4 environmentType={environmentType!} protocol={protocol!} {...{cancelPath}} name={envName as string} hybrid={envHybrid as boolean}
                {...{consortiumZones}} {...{zoneId}} {...{setZoneId}} />
        }
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createEnvironment')} stepUrlParam={resolvedStep!} {...{stepComponents}} />
};

interface translations {
    createEnvironment: string,
    environmentDetails: string,
    environmentType: string,
    protocol: string,
    settings: string
}
const enTranslations: translations = {
    createEnvironment: 'Create an Environment',
    environmentDetails: 'Environment Details',
    environmentType: 'Environment Type',
    protocol: 'Protocol',
    settings: 'Settings'
}