import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { FullScreenCreate } from "../../../../components/DialogWrappers";
import { B2B_BASE_PATH } from "../../../../components/MainNav/SideNavs/B2bCommunication";
import {
    ConsortiumResourcesVars,
    ConsortiumResourceVars,
    EnvironmentResourcesVars
} from "../../../../interfaces";
import {
    ConsortiumZonesData,
    ConsortiumZonesQuery,
    EnvironmentData,
    EnvironmentQuery, EnvironmentZonesData,
    EnvironmentZonesQuery,
    ServicesEnum
} from "../../../../models";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";

export const CreateDocExchange: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateDocExchange", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateDocExchange:${key}`, interpolate);

    type locationState = {
        name: string;
        membershipId: string;
        consortiumZoneId: string;
        config: string
    };
    const { org_id, consortium_id, environment_id, step } = useParams<any>();
    const history = useHistory<locationState>();

    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}`;

    const {
        location: {
            state: {
                name: serviceName,
                membershipId: serviceMembershipId,
                consortiumZoneId: serviceZoneId,
                config: serviceConfig
            } = { name: "", membershipId: "", consortiumZoneId: "", config: "" },
        },
    } = history;

    const { data: { environmentZones } = { environmentZones: [] } } = useQuery<
        EnvironmentZonesData,
        EnvironmentResourcesVars
    >(EnvironmentZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });

    const { data: { consortiumZones } = { consortiumZones: [] } } = useQuery<
        ConsortiumZonesData,
        ConsortiumResourcesVars
    >(ConsortiumZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
        },
        fetchPolicy: "cache-only",
    });

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });

    const isMultiRegion = environment?.test_features?.multi_region ?? false;
    const [name, setName] = useState(
        step && step >= "2" ? (serviceName as string) : ""
    );
    const [membershipId, setMembershipId] = useState(
        step && step >= "2" ? (serviceMembershipId as string) : ""
    );
    const [consortiumZoneId, setConsortiumZoneId] = useState(
        step && step >= "2"
            ? (serviceZoneId as string)
            : isMultiRegion && consortiumZones.length
            ? consortiumZones.find((z) => z.default)?._id ?? ""
            : ""
    );
    const [config, setConfig] = useState(
        step && step >= "2" ? (serviceConfig as string) : ""
    );

    const invalidStep = step !== "1" && step !== "2" && step !== "3";
    const createStep = invalidStep ? 0 : parseInt(step!) - 1;

    if (
        invalidStep ||
        (createStep >= 1 &&
            (!serviceName ||
                !serviceMembershipId ||
                (isMultiRegion && !serviceZoneId)))
    ) {
        return <Redirect to={`${cancelPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/create/1`} />;
    }

    const stepComponents = [
        {
            step: lt("runtimeDetails"),
            component: (
                <Step1
                    {...{ cancelPath }}
                    {...{ isMultiRegion }}
                    {...{ name }}
                    {...{ setName }}
                    {...{ membershipId }}
                    {...{ setMembershipId }}
                    {...{ consortiumZones }}
                    {...{ consortiumZoneId }}
                    {...{ setConsortiumZoneId }}
                />
            ),
        },
        {
            step: lt("selectConfiguration"),
            component: (
                <Step2
                    {...{ environmentZones }}
                    {...{ consortiumZones }}
                    {...{ name }}
                    {...{ membershipId }}
                    {...{ consortiumZoneId }}
                    {...{ cancelPath }}
                    {...{config}}
                    {...{setConfig}}
                />
            ),
        },
        {
            step: lt("selectSize"),
            component: (
                <Step3
                    {...{ environmentZones }}
                    {...{ consortiumZones }}
                    {...{ name }}
                    {...{ membershipId }}
                    {...{ consortiumZoneId }}
                    {...{ cancelPath }}
                    {...{config}}
                />
            ),
        },
    ];

    return (
        <FullScreenCreate
            cancelPath={cancelPath}
            toolbarHeader={lt("createDocExchange")}
            stepUrlParam={step!}
            {...{ stepComponents }}
        />
    );
};

interface translations {
    createDocExchange: string;
    runtimeDetails: string
    selectConfiguration: string;
    selectSize: string
}
const enTranslations: translations = {
    createDocExchange: "Create Document Exchange",
    runtimeDetails: 'Runtime Details',
    selectConfiguration: "Cloud Storage",
    selectSize: 'Select Size'
};
