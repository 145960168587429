import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ServiceQuery, ServiceData } from "../../models";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    CopyableSettings,
    CopyableSetting,
} from "../../components/DisplaySettings";
import { BLOCKCHAIN_BASE_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import { ResourceStateChip } from "../../components/FormControls/ResourceStateChip";
import { DisplayCard } from "../../components/DisplayWrappers";
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";

export const TetherOverview = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "TetherOverview", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`TetherOverview:${key}`, interpolate);

    const { service_id, consortium_id, org_id, environment_id } = useParams<any>();

    const {
        loading,
        data: { service } = { service: null },
    } = useQuery<ServiceData>(ServiceQuery, {
        variables: {
            id: service_id,
        },
    });

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    if (loading) return <CircularProgress />;

    if (!service)
        return <Redirect to={`${basePath}/${BLOCKCHAIN_BASE_PATH}`} />;

    const copyableList: CopyableSetting[] = [
        {
            title: lt("name"),
            displayValue: service.name,
        },
        {
            title: lt("id"),
            displayValue: service._id,
        },
        {
            title: lt("status"),
            displayValue: <ResourceStateChip state={service.state} />,
        },
        {
            title: lt("owner"),
            displayValue: service.membership.name,
        },
        {
            title: lt("createdOn"),
            displayValue: new Date(service.created_at).toLocaleString(),
        },
        {
            title: lt("apiEndpoint"),
            displayValue: service.urls?.http ?? "--",
        },
    ];

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("publicEthTether"),
            value: lt("blog"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://docs.kaleido.io/kaleido-services/tether/"
                ),
        }, {
            icon: <DescriptionIcon />,
            title: lt("tetherAPI"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://api.kaleido.io/tether.html"
                ),
        }
    ];

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt("overview")}</Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} md={8}>
                    <CopyableSettings
                        header={lt("information")}
                        copyableList={copyableList}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DisplayCard
                        header={lt("documentation")}
                        itemList={DocumentationList}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface translations {
    overview: string;
    name: string;
    id: string;
    status: string;
    owner: string;
    createdOn: string;
    apiEndpoint: string;
    information: string;
    publicEthTether: string
    blog: string
    documentation: string
    tetherAPI: string
}
const enTranslations: translations = {
    overview: "Overview",
    name: "Name",
    id: "ID",
    status: "Status",
    owner: "Owner",
    createdOn: "Created On",
    apiEndpoint: "API Endpoint",
    information: "Information",
    publicEthTether: 'Public Ethereum Tether',
    blog: "Blog",
    documentation: 'Documentation',
    tetherAPI: 'Tether API',
};
