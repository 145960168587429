import React, { useMemo, useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { BlocksForChartData, BlocksForChartQuery, BlocksForChartVars, TransactionsVars, EnvironmentData, EnvironmentQuery, TxIndexQuery, TxIndexData, NodesData, NodesQuery } from '../../models';
import { useParams, useHistory } from 'react-router-dom';
import { BlockKpi } from './BlockKpi';
import { LatestBlocks } from './LatestBlocks';
import { ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces';
import { TransactionsGauge } from './TransactionsGauge';
import { DisplayCard, DisplayGridWrapper, EmptyState } from '../../components/DisplayWrappers';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import { BarChart, BarChartSeries } from '../../components/Charts/BarChart';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_BLOCKS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { BrandColors } from '../../utils/Colors';
import { TransactionsTable, TransactionsColumns } from '../../components/Transactions/TransactionsTable';

export const BlockExplorer = () => {
    const pollingInterval = 4000;
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BlockExplorer', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BlockExplorer:${key}`, interpolate);

    const { org_id, consortium_id, environment_id } = useParams<any>();
    const history = useHistory();
    const [ suspendBlocksUpdates, setSuspendBlocksUpdates ] = useState(false);
    const [ suspendTransactionUpdates, setSuspendTransactionUpdates ] = useState(false);

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const environmentPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    
    const {
        data: { 
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            id: environment_id!
         },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: {
            ...environmentVariables
        },
        fetchPolicy: 'cache-only'
    });

    const isMonitorLive = useMemo(() => {
        return nodes.find(node => node.membership_id === 'sys--mon')?.state === 'started' ?? false
    }, [nodes])

    const {
        stopPolling: stopBlockPolling,
        startPolling: startBlockPolling,
        error,
        loading: blocksForChartLoading,
        data: {
            blocksForChart: {
                blocks,
                total: totalBlocks,
                successTransactions,
                failedTransactions
            }
        } = { blocksForChart: {blocks: [], total: 0, successTransactions: 0, failedTransactions: 0} }
    } = useQuery<BlocksForChartData, BlocksForChartVars>(BlocksForChartQuery, { 
        variables: {
            ...environmentVariables
        },
        pollInterval: pollingInterval,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        suspendBlocksUpdates? stopBlockPolling() : startBlockPolling(pollingInterval);
    }, [suspendBlocksUpdates, startBlockPolling, stopBlockPolling]);

    const {
        startPolling: startTxPolling,
        stopPolling: stopTxPolling,
        loading: transactionsLoading,
        data: {
            txIndex: {
                totalCount: totalTransactions,
                transactions
            }
        } = { txIndex: {totalCount: 0, transactions: []} }
    } = useQuery<TxIndexData, TransactionsVars>(TxIndexQuery, { 
        variables: {
            ...environmentVariables,
            limit: 5,
        },
        pollInterval: pollingInterval,
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        suspendTransactionUpdates? stopTxPolling() : startTxPolling(pollingInterval);
    }, [suspendTransactionUpdates, startTxPolling, stopTxPolling]);

    const upgradeNeeded = useMemo(() => {
        const statusCode = error?.graphQLErrors[0]?.extensions?.exception?.statusCode;
        return !!statusCode && statusCode === 404
    }, [error])

    if (upgradeNeeded && blocks.length === 0 && isMonitorLive && environment?.state === 'live') return <EmptyState imageFile='Empty-CloudConfigs.svg' 
                                        title={lt('upgradeNeeded')} 
                                        description={lt('upgradeDescription')} 
                                        button={{ text: lt('goToDashBoard'), onClick: () => history.push(environmentPath) }}
                                        documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/kaleido-services/block-explorer/' }} />

    if ((!transactions && transactionsLoading) || (blocksForChartLoading && !blocks) ) return <CircularProgress />;

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('header'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/block-explorer')
        },
        {
            icon: <DescriptionIcon />,
            title: lt('ledger'),
            value: lt('apiDocs'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://api.kaleido.io/platform.html#tag/Ledger') 
        }
    ];

    const barchartOpts = {
        chart: { height: '150px' },
        plotOptions: {
            series: {
                cursor: 'pointer',
                events: {
                    click: function (e) {
                        history.push(`${environmentPath}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}/${e.point.x}`)
                    }
                }
            }
        }
    } as Highcharts.Options;

    const blockSeries: BarChartSeries[] = [
        {
            name: lt('succeeded'), 
            values: blocks.map(d => [d.x, d.success.valueOf()]) ?? [], 
            showZero: true, 
            color: BrandColors.blurple
        },
        {
            name: lt('failed'), 
            values: blocks.map(d => [d.x, d.failure.valueOf()]) ?? [], 
            color: BrandColors.magenta
        }
    ];

    function tooltipFormatter (this: Highcharts.TooltipFormatterContextObject) {
        return `<b>${lt('block')}: </b> ${this.x} | <b>${lt('txs')}: <b/> ${this.y} | <b>${lt('status')}: </b> ${this.series.name}`
    }

    const BlockGraph = (
        <BarChart
            hideYAxis
            hideXAxis
            fullWidth 
            tooltipFormatter={tooltipFormatter} 
            title={lt('blocks')} 
            series={blockSeries}
            extraHCOptions={barchartOpts}
            height='150px' />
    )
    
    const transactionsColumns: TransactionsColumns[] = ['transaction', 'from', 'status', 'dateMined'];

    return (
        <Grid container direction="column" spacing={3} alignItems="stretch" wrap="nowrap">
            <Grid item xs={12}>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BlockKpi totalBlocks={totalBlocks} totalTransactions={totalTransactions} blocks={blocks} {...{environment}} {...{transactions}} />
            </Grid>
            <Grid item xs={12}>
                <DisplayGridWrapper header={lt('blocks')} subtitle={lt('last100')} displayGrid={BlockGraph} hideDivider />
            </Grid>
            <Grid item container spacing={3} justify="space-between">
                <Grid item container xs={12} md={8}>
                    <LatestBlocks blocks={blocks.slice(Math.max(blocks.length - 5, 0)).reverse()} setSuspendUpdates={setSuspendBlocksUpdates} />
                </Grid>
                <Grid item container wrap="nowrap"  xs={12} md={4}>
                    <TransactionsGauge {...{successTransactions}} {...{failedTransactions}} />
                </Grid>
            </Grid>
            <Grid item container spacing={3} justify="space-between">
                <Grid item container xs={12} md={8}>
                    <TransactionsTable isLatest columns={transactionsColumns} {...{transactions}} setSuspendUpdates={setSuspendTransactionUpdates}/>
                </Grid>
                <Grid item container xs={12} md={4}>
                    <DisplayCard header={lt('documentation')} itemList={DocumentationList} />
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    header: string,
    documentation: string
    apiDocs: string
    ledger: string
    docTitle: string
    blocks: string
    block: string
    txs: string
    succeeded: string
    failed: string,
    upgradeNeeded: string
    upgradeDescription: string,
    goToDashBoard: string,
    emptyDocumentation: string,
    status: string,
    last100: string,
}
const enTranslations: translations = {
    header: 'Block Explorer',
    documentation: 'Documentation',
    apiDocs: 'API Docs',
    docTitle: 'Doc Title',
    ledger: 'Ledger API',
    blocks: 'Blocks',
    block: 'Block',
    txs: 'Txs',
    succeeded: 'Succeeded',
    failed: 'Failed',
    upgradeDescription: 'We\'ve made a number of improvements to the Block Explorer! You will need to upgrade your environment to view the new Block Explorer Dashboard.',
    upgradeNeeded: 'Upgrade Needed',
    emptyDocumentation: 'Documentation',
    goToDashBoard: 'Go to Environment Dashboard',
    status: 'Status',
    last100: 'Last 100 Blocks'
}
