import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../../../components/DialogWrappers';


interface Props {
    disableOrgMFaDialogOpen: boolean
    setDisableOrgMFADialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProfileSettingDisableOrgMFA: React.FC<Props> = ({ disableOrgMFaDialogOpen, setDisableOrgMFADialogOpen }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProfileSettingDisableOrgMFA', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProfileSettingDisableOrgMFA:${key}`, interpolate);

    return (
        <FormDialog
            header={lt('header')}
            description={lt('disableOrgMFADescription')}
            open={disableOrgMFaDialogOpen}
            setOpen={setDisableOrgMFADialogOpen}
            hideSave
        />
    );
}

interface translations {
    header: string
    disableOrgMFADescription: string
}

const enTranslations: translations = {
    header: 'Cannot disable MFA',
    disableOrgMFADescription: 'Organization Multi-Factor Authentication requirement must be removed before first.'
}