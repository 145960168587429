import React from 'react';
import { DisplayGridWrapper } from './DisplayGridWrapper';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { EnvironmentData, EnvironmentQuery } from '../../models';
import { ConsortiumResourceVars } from '../../interfaces';

interface Props {
    header: string
    description: string
    buttonLabel?: string
    createPath?: string
    cancelPath?: string
    onViewDetailsClick?: () => void
    imageFiles?: string
    createDisabled?: boolean
}


export const EmptyCard = ({header, description, buttonLabel, createPath, onViewDetailsClick, imageFiles, cancelPath, createDisabled}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EmptyCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EmptyCard:${key}`, interpolate);

    const classes = useStyles();
    const history = useHistory();
    const { consortium_id, environment_id } = useParams<any>();

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
        skip: !environment_id
    });

    const disableCreate = createDisabled || (environment && environment.state !== 'live' ? true : false)

    const displayGrid = (
            <>
                <Grid item container spacing={3}>
                    <Grid item container justify="center" >
                        <img className={classes.image} src={`${process.env.PUBLIC_URL}/img/EmptyStates/${imageFiles}`} alt=""></img>
                    </Grid>
                    <Grid item>
                        {description}
                    </Grid>
                </Grid>
                <Grid item container spacing={3} className={classes.buttons}>
                    {(createPath || createDisabled) && 
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => createPath ? history.push(createPath, cancelPath ? { cancelPath } : undefined) : {}} 
                                disabled={disableCreate}>
                                {buttonLabel ? buttonLabel : lt('createNew')}
                            </Button>
                        </Grid>
                    }
                    { onViewDetailsClick &&
                        <Grid item>
                            <Button onClick={onViewDetailsClick}>
                                {lt('viewDetails')}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </>
    )
    return <DisplayGridWrapper hideDivider padDisplayGrid {...{header}}{...{displayGrid}} />
    
}

interface translations {
    createNew: string
    viewDetails: string
}

const enTranslations: translations = {
    createNew: 'Create New',
    viewDetails: 'View Details',
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3, 2)
    }, 
    buttons: {
        marginTop: theme.spacing(2)
    },
    image: {
        maxHeight: '150px'
    }
}))