import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from "@material-ui/core";
import { FormDialog } from '../../components/DialogWrappers'
import { Paywall } from '../FormControls/Paywall';

interface Props {
    name: string,
    resourceType: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    loading: boolean,
    onSave: () => Promise<void>,
    disableNameConfirmation?: boolean,
    closeDialogAfterSave?: boolean,
    deleteWarning?: string
}

export const DeleteResourceDialog = ({ name, open, setOpen, onSave, loading, disableNameConfirmation = false, closeDialogAfterSave = false, resourceType, deleteWarning }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DeleteResourceDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DeleteResourceDialog:${key}`, interpolate)

    const [nameToMatch, setNameToMatch] = useState('');

    useEffect(() => {
        setNameToMatch('')
    }, [name, open])

    const controlsWrapper = !disableNameConfirmation ?
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <TextField data-test="textField_confirmDeletionName"
                    value={nameToMatch} onChange={event => setNameToMatch(event.target.value)}
                    autoFocus
                    margin="normal"
                    label={lt('confirmDeletion')}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            {deleteWarning && 
            <Grid item>
                <Paywall description={deleteWarning} />
            </Grid>}
        </Grid> : undefined

    const saveDisabled = loading || (!disableNameConfirmation ? name !== nameToMatch : false)

    const confirmResourcePromptName = !disableNameConfirmation ? name : undefined

    return <FormDialog dataTestId="deleteResource"
        {...{open}} 
        {...{setOpen}} 
        header={lt('deleteResource', {resourceType, name})} 
        description={lt('deleteDescription')} 
        {...{controlsWrapper}}
        {...{saveDisabled}}
        {...{onSave}}
        {...{closeDialogAfterSave}}
        {...{confirmResourcePromptName}}
        saveText={lt('delete')} />
};

interface translations {
    deleteResource: string,
    deleteDescription: string,
    delete: string,
    confirmDeletion: string
}
const enTranslations: translations = {
    deleteResource: 'Delete {{resourceType}} ({{name}})?',
    deleteDescription: 'This will permanantly delete this resource.',
    delete: 'Delete',
    confirmDeletion: 'Name'
}