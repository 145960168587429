import { useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from "react-router-dom";
import { EnvironmentResourceVars } from '../../interfaces';
import { CorDappData, CorDappQuery, UpdateCorDappData, UpdateCorDappMutation, UpdateCorDappVars } from '../../models';
import { toFileSize } from '../../utils/StringUtils';
import { CopyableSetting, CopyableSettings } from '../DisplaySettings';
import { DisplayCard } from '../DisplayWrappers';
import { FormLink } from '../FormControls/FormLink';
import { NETWORK_COMPILED_CONTRACTS_PATH, NETWORK_CONTRACTS_PATH } from '../MainNav/ConsortiumWrapper';
import { NODE_CORDAPPS_PATH } from '../NodeNav/NodeNav';

export const CorDappViewer: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CorDappViewer', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CorDappViewer:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, node_id, cordapp_id } = useParams<any>();

    const [updateCorDapp, { loading: promoting }] = useMutation<UpdateCorDappData, UpdateCorDappVars>(UpdateCorDappMutation)

    const envResourceVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const nodeResourceVars = {
        ...envResourceVars,
        node_id: node_id!
    }

    const {
        refetch: refetchCorDapp,
        loading: loadingCorDapp,
        data: {
            cordapp
        } = { cordapp: null }
    } = useQuery<CorDappData, EnvironmentResourceVars>(CorDappQuery, { 
        variables: {
            ...nodeResourceVars,
            id: cordapp_id
        },
        fetchPolicy: 'cache-and-network'
    });

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/nodes/${node_id}/${NODE_CORDAPPS_PATH}`;

    const loading = loadingCorDapp || promoting;
    if(loading) return <CircularProgress />
    if (!cordapp) return <Redirect to={basePath} />

    const project = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${cordapp.consortiaContractId}`
    const compilation = `${project}/${NETWORK_COMPILED_CONTRACTS_PATH}/${cordapp.id}`;

    const copyableList: CopyableSetting[] = [{
        title: lt('name'),
        displayValue: (
            <FormLink to={compilation}>
                {cordapp.consortiaContractName}
            </FormLink>
        ),
        disableCopy: true
    }, {
        title: lt('description'),
        displayValue: (
            <FormLink to={compilation}>
                {cordapp.description}
            </FormLink>
        ),
        disableCopy: true
    }, {
        title: lt('state'),
        displayValue: cordapp.state,
        disableCopy: true
    }, {
        title: lt('dateCreated'),
        displayValue: new Date(cordapp.created).toLocaleString() ?? '',
        disableCopy: true
    }, {
        title: lt('size'),
        displayValue: toFileSize(cordapp.size),
        disableCopy: true
    }];
    
    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('corda'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>  window.open('https://docs.kaleido.io/kaleido-platform/protocol/corda')
        }
    ];

    const live = cordapp?.state === 'live';

    const doPromote = async () => {
        await updateCorDapp({
            variables: {
                ...nodeResourceVars,
                id: cordapp_id,
                cordapp: {
                    state: live ? 'stage' : 'live'
                }
            }
        });
        refetchCorDapp();
    }

    const actionBar = <>
        <Grid item>
            <Button color="default" size="medium" variant="outlined" onClick={doPromote}>
                {live ? lt('demote') : lt('promote')}
            </Button>
        </Grid>
    </>;

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('header')}</Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item md={12} lg={8}>
                    <CopyableSettings header={lt('details')} {...{copyableList}} {...{actionBar}}/>
                </Grid>
                <Grid container item lg={4}>
                    <DisplayCard header={lt('documentation')} itemList={DocumentationList}/>
                </Grid>
            </Grid>
        </Grid>
    )};

interface translations {
    header: string,
    details: string,
    documentation: string,
    corda: string,
    compilation: string,
    description: string,
    state: string,
    dateCreated: string,
    size: string,
    demote: string,
    promote: string,
    name: string
}
const enTranslations: translations = {
    header: 'CorDapp',
    details: 'Details',
    documentation: 'Documentation',
    corda: 'Corda',
    compilation: 'Compilation',
    description: 'Description / Version',
    state: 'State',
    dateCreated: 'Date Created',
    size: 'Size',
    demote: 'Move back to staging',
    promote: 'Make live',
    name: 'Name'
}