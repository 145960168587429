import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ExpansionPanel, AccordionDetails, ExpansionPanelSummary, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { Paywall } from "../../components/FormControls/Paywall";
import { CREATE_ORG_PATH } from "../../components/ManageOrgNav/ManageOrgNav";
import { CreateStepProps, Region, SessionData } from '../../interfaces';
import { CreateOrganizationData, CreateOrganizationMutation, CreateOrganizationVars, MakeOrgCreateMutationOptions, Organization, OrganizationsData, OrganizationsQuery, PlanSupports } from "../../models";
import { SessionQuery } from "../../queries/Session";
import { cognitoController } from "../../utils/cognitoController";
import { Step1Help } from './Step1Help';

interface Props extends CreateStepProps {
    regions: Region[],
};

export const Step1 = ({ cancelPath, regions }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOrganizationsStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOrganizationsStep1:${key}`, interpolate)

    const [message, setMessage] = useState('')
    const [name, setName] = useState('');
    const [masterOrg, setMasterOrg] = useState('');
    const [regionCode, setRegionCode] = useState('');
    const [primaryContactEmail, setPrimaryContactEmail] = useState('');

    const [createOrganization, { loading: createOrganizationLoading }] = useMutation<CreateOrganizationData, CreateOrganizationVars>(CreateOrganizationMutation)

    const {
        data: {
            organizations
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery, {
        fetchPolicy: 'cache-only'
    });

    const setOrg = (org?: Organization) => {
        if (org) {
            setPrimaryContactEmail(org.delegate);
            setMasterOrg(org._id);
            setRegionCode(org._id.substring(0,2));
        }
    }

    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;
    const ownedRootOrgs = organizations.filter(o => o.owner === session.user_id && !o.master_org);

    useEffect(() => {
        if (!masterOrg && ownedRootOrgs?.length) setOrg(
            ownedRootOrgs.find(o => PlanSupports.supportsNewOrg(o)) || ownedRootOrgs[0]
        );
    }, [ownedRootOrgs, masterOrg]);

    const org = organizations?.find(o => o._id === masterOrg);
    const supportsNewOrg = useMemo(() => !org || PlanSupports.supportsNewOrg(org), [org]);

    const save = async () => {

        await createOrganization(MakeOrgCreateMutationOptions({
            organization: {
                name,
                master_org: masterOrg,
                region_code: regionCode!,
            }
        })) 
        .then(async newOrg => {
            await cognitoController.renewSession(client, newOrg.data?.createOrganization._id);
            // Force a hard refresh so that we reconnect our WebSocket client with the new JWT
            window.location.href = `/orgs/${CREATE_ORG_PATH}/2?newOrg=${newOrg.data?.createOrganization._id}`;
        })
        .catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        });
    }

    const advancedSettings = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt("advancedSettings")}
                </Typography>
                <Typography variant="body2">
                    {lt('advanedDescription')}
                </Typography>
            </Grid>            
            <Grid item>
                <ExpansionPanel style={{backgroundColor: 'white'}}> 
                    <ExpansionPanelSummary expandIcon={<ChevronDownIcon />}>
                        <Typography variant="body2">{lt('advancedSettingsExpander')}</Typography>    
                    </ExpansionPanelSummary>
                    <AccordionDetails>
                        <Grid container direction="column" spacing={3}>                           
                            <Grid item>
                                    <TextField
                                        fullWidth
                                        value={regionCode}
                                        onChange={(e) => setRegionCode(e.target.value)}
                                        label={lt("region")}
                                        variant="outlined"
                                        select
                                    >
                                        {regions.filter(r => !r.redirectLoginTo).map(r => <MenuItem value={r.code} key={r.code}>{r.geo}</MenuItem>)}
                                    </TextField>
                            </Grid>
                            <Grid item>
                                    <TextField
                                        fullWidth
                                        value={primaryContactEmail}
                                        onChange={(e) => setPrimaryContactEmail(e.target.value)}
                                        label={lt("primaryContactEmail")}
                                        variant="outlined"
                                    />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </ExpansionPanel>
            </Grid>
        </Grid>
    );

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>

            <Grid item >
                <TextField
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    required
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            <Grid item >
                <TextField
                    value={masterOrg} 
                    onChange={event => setOrg(organizations.find(o => o._id === event.target.value))}
                    select
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('master')}
                    variant="outlined"
                >
                    {ownedRootOrgs.map((org) => (
                        <MenuItem key={org._id} value={org._id}>
                          {org.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            
            {!supportsNewOrg && 
                <Grid item >
                    <Paywall description={lt('paywall', org)} />
                </Grid>
            }

            {advancedSettings}

        </>
    )

    const disabled = createOrganizationLoading || !supportsNewOrg;

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} customNextButtonLabel={lt('create')} isFirstStep isLastStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string,
    master: string,
    paywall: string,
    name: string,
    create: string,
    advancedSettings: string,
    advanedDescription: string,
    advancedSettingsExpander: string,
    region: string,
    primaryContactEmail: string,
}
const enTranslations: translations = {
    header: 'Organization Details',
    master: 'Root Organization',
    paywall: 'Creation of new organizations is not available on the {{plan}} plan',
    name: 'Organization Name',
    create: 'Create',
    advancedSettings: 'Advanced Settings',
    advanedDescription: 'The new organization will inherit this configuration from the root organization by default',
    advancedSettingsExpander: 'Advanced Settings (Home Region, Primary Contact Email)',
    region: 'Home Region',
    primaryContactEmail: 'Primary Contact Email',
}
