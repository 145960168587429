import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { EnvironmentResourcesVars } from '../../interfaces';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ServicesQuery, ServicesData } from '../../models';
import { WalletCard } from './WalletCard';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_CLOUD_PATH } from '../../components/MainNav/SideNavs/KeyManagement';
import { EmptyState } from '../../components/DisplayWrappers';


export const CloudHsmWallets = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CloudHsmWallets', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CloudHsmWallets:${key}`, interpolate);

    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const {
        loading,
        data: {
            services
        } = { services: []}
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    
    const cloudHsmRuntimes = useMemo(() => {
        return services.filter(service => service.service === 'cloudhsm')
    }, [services]);

    const createPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/create/1`;

    if (loading && services.length === 0) return <CircularProgress />;
    if (!loading && cloudHsmRuntimes.length === 0) return (
        <EmptyState imageFile='Empty-CloudHSM.svg' 
            title={lt('createWallet')} 
            description={lt('emptyDescription')} 
            button={{ text: lt('createNew'), onClick: () => history.push(createPath) }}
            documentation={{ text: lt('documentation'), link: 'https://docs.kaleido.io/kaleido-services/cloudhsm/' }} />
    )

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container alignItems="center" justify="space-between">
                <Grid item>
                    <Typography variant="h5">{lt('header')}</Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() =>  history.push(createPath)}>{lt('createNew')}</Button>
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={3}>
                {cloudHsmRuntimes.map((service, index) => (
                    <Grid key={`service-${index}`} item xs={12} sm={6} lg={4}>
                        <WalletCard {...{service}} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

interface translations {
    header: string
    createNew: string
    createWallet: string
    emptyDescription: string
    documentation: string
}

const enTranslations: translations = {
    header: 'Cloud HSM Wallet',
    createNew: 'Create New',
    createWallet: 'Create a Cloud HSM Wallet',
    emptyDescription: 'Host your signing keys in your own private cloud account. Cloud HSM SIgner allows you to sign transactions using off-platform keys.',
    documentation: 'Documentation'
}