import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { APPS_BASE_PATH, APPS_CHAINCODES_PATH } from '../../../components/MainNav/SideNavs/AppsIntegrations';
import { EnvironmentResourceVars } from '../../../interfaces';
import { GatewayAPIData, GatewayAPIQuery } from '../../../models';
import { Step1 } from './Step1';

export const DeployToChannel: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DeployToChannel', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DeployToChannel:${key}`, interpolate)

    const { pathname } = useLocation()
    const { org_id, consortium_id, environment_id, gateway_api_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}/${gateway_api_id}`;

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')
    const chaincodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: gateway_api_id!
    }

    const [channelChosen, setChannelChosen] = useState('');

    const {
        data: {
            gatewayAPI: chaincode
        } = { chaincode: null }
    } = useQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, { 
        variables: chaincodeVars, 
        fetchPolicy: 'cache-first' 
    });

    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep >= 1) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: '', component: <Step1 cancelPath={cancelPath} {...{channelChosen}} {...{setChannelChosen}} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} hideStepper toolbarHeader={lt('promote', { chaincodeName: chaincode?.label } )} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    promote: string,
}
const enTranslations: translations = {
    promote: 'Instantiate {{chaincodeName}} to Channel',
}