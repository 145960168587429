import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import PublishIcon from 'mdi-react/PublishIcon';
import SyncIcon from 'mdi-react/SyncIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CHANNEL_CHAINCODES_PATH } from '../../components/ChannelNav/ChannelNav';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { GenericStatusIcon } from '../../components/DisplayWrappers/GenericStatusIcon';
import { APPS_BASE_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';
import { APPS_GATEWAY_APIS_PATH, APPS_CORDAPPS_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';
import { NETWORK_COMPILED_CONTRACTS_PROMOTE_PATH } from '../../components/MainNav/ConsortiumWrapper';
import { EnvironmentResourceVars } from '../../interfaces';
import { CompiledContract, Environment, GatewayAPIData, GatewayAPIQuery, PromoteCompiledContractMutation, PromoteCompiledContractVars } from '../../models';
import { Dotdotdot } from '../../components/Dotdotdot';

interface Props {
    compiledContract: CompiledContract,
    environment: Environment,
    promotedEnvironments: string[],
    setPromotedEnvironments: React.Dispatch<React.SetStateAction<string[]>>,
};

export const PromotedEnvironment = ({ compiledContract, environment, promotedEnvironments, setPromotedEnvironments }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CompiledContractPromotedEnvironment', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CompiledContractPromotedEnvironment:${key}`, interpolate)

    const { pathname } = useLocation()
    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();
    const [errorMessage, setErrorMessage] = useState('');
    const classes = useStyles();

    const [promoteCompiledContract, { loading: promoteCompiledContractLoading }] = useMutation<string, PromoteCompiledContractVars>(PromoteCompiledContractMutation)

    const getRedirectToGateway = () => {
        const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment._id}`
        if (environment.isFabric) {
            return `${basePath}/${APPS_BASE_PATH}/${CHANNEL_CHAINCODES_PATH}/${compiledContract._id}`
        } else if (environment.isCorda) {
            return `${basePath}/${APPS_BASE_PATH}/${APPS_CORDAPPS_PATH}/${compiledContract._id}`
        } else {
            return `${basePath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${compiledContract._id}`
        }
    } 

    const redirectToPromote = () => {
        history.push(`${pathname}/${NETWORK_COMPILED_CONTRACTS_PROMOTE_PATH}/1`, {
            environmentId: environment._id,
            promotedEnvironments
        })
    }

    const {
        loading,
        data: {
            gatewayAPI
        } = { gatewayAPI: null },
        refetch
    } = useQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, {
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment._id,
            id: compiledContract._id
        },
        fetchPolicy: 'cache-and-network'
    });
    
    useEffect(() => {
        if (gatewayAPI) {
           setPromotedEnvironments(envIds => [...envIds, gatewayAPI.environment_id ?? ''])
        }
    }, [gatewayAPI, setPromotedEnvironments])

    const isRunning = environment.state === 'live';

    const readyToPublish = !loading && !gatewayAPI && isRunning;

    const needsResync = gatewayAPI && (
        (compiledContract.abi && gatewayAPI.abi !== compiledContract.abi) ||
        ( compiledContract.dev_docs && gatewayAPI.devDocs !== compiledContract.dev_docs)
    );

    const makeValue = () => {
        if (loading && !gatewayAPI) return ''
        if (needsResync) return lt('needsResync');
        if (gatewayAPI) return lt('endpoint', { endpoint: gatewayAPI?.endpoint })
        if (!isRunning) return lt('notRunning')
        return lt('notPromoted')
    }

    const doResync = async (): Promise<void> => {
        try {
            await promoteCompiledContract({
                variables: {
                    contract_id: compiledContract.contract_id,
                    consortia_id: consortium_id!,
                    id: compiledContract._id,
                    promoteCompiledContract: {
                        environment_id: environment._id,
                    },
                }
            })
            // Refetch the Gateway API
            refetch()
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
    }

    const makeIcon = () => {
        if (promoteCompiledContractLoading) return <CircularProgress/>
        if (needsResync || readyToPublish) {
            return <>{needsResync ? <SyncIcon/> : <PublishIcon />}</>
        }
        if (gatewayAPI) {
            return <ChevronRightIcon />
        }
        return undefined
    }

    const makeDotMenuItems = () => {
        let menu = [];
        if (gatewayAPI) {
            menu.push({
                name: promoteCompiledContractLoading ? lt('versionLoading') : needsResync ? lt('resync') : lt('viewDetails'),
                icon: makeIcon(),
                action: () => {
                    if (promoteCompiledContractLoading) {
                        return;
                    }
                     if (!needsResync && gatewayAPI && isRunning) {
                        history.push(getRedirectToGateway()) 
                        return;
                     } 
                     if (needsResync) {
                        doResync();
                        return;
                     }
                }
            });
        } else {
            menu.push({
                name: lt('promote'),
                icon: makeIcon(),
                action: () => redirectToPromote()
            });
        }
        return menu;
    }

return (
    <>
    <MessageSnackbar setMessage={setErrorMessage} message={errorMessage} />
    <TableRow>
        <TableCell className={classes.tableCell}>
            <Typography noWrap variant="body2" color="inherit">
                {loading && !gatewayAPI ? <CircularProgress /> : <GenericStatusIcon overrideOk={gatewayAPI ? true : false} />}
            </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
            <Typography noWrap variant="body2" color="inherit">
                {environment.name}
            </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
            <Typography noWrap variant="body2" color="inherit">
                {makeValue()}
            </Typography>
        </TableCell>
        <TableCell align="right" className={classes.tableCell}>
            {isRunning && <Dotdotdot menuItems={makeDotMenuItems()}/>}
        </TableCell>
    </TableRow>
    </>
)
};

const useStyles = makeStyles((theme) => ({
    tableCell: {
        paddingLeft: theme.spacing(3)
    },
    maxWidth: {
        maxWidth: '100px'
    }
}));
interface translations {
    endpoint: string,
    notRunning: string,
    notPromoted: string,
    needsResync: string,
    promote: string,
    viewDetails: string,
    resync: string,
    versionLoading: string
}
const enTranslations: translations = {
    endpoint: 'Endpoint: {{endpoint}}',
    notRunning: 'Unknown - environment not live',
    notPromoted: 'Not promoted',
    needsResync: 'Re-sync required for updated ABI',
    promote: 'Promote',
    viewDetails: 'View Details',
    resync: 'Resync',
    versionLoading: 'Version is loading'
}