import { useEffect, useState } from "react";
import { ConsortiumResourceVars } from "../interfaces";
import {
    MembershipJSONIdProofData,
    MembershipJSONIdProofQuery,
} from "../models";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

export interface CertificateInterface {
    commonName?: string
    organization?: string
    country?: string
    serial?: string
}

interface Props {
    membershipId: string;
    skipQuery?: boolean;
}

const propName = new Map<string, keyof CertificateInterface>([
    ['CN', 'commonName'],
    ['O', 'organization'],
    ['C', 'country'],
    ['serial', 'serial']
])

//Hook that queries and parses membership certificate info

export const useCertificateInfo = ({ membershipId, skipQuery = false }: Props) => {
    const { consortium_id } = useParams<any>();
    const [
        membershipIdProof,
        setMembershipIdProof,
    ] = useState<CertificateInterface | null>(null);
    const [loading, setLoading] = useState(true);

    const {
        loading: queryLoading,
        data: { membershipJSONIdProof } = { membershipJSONIdProof: null },
    } = useQuery<MembershipJSONIdProofData, ConsortiumResourceVars>(
        MembershipJSONIdProofQuery,
        {
            variables: {
                consortia_id: consortium_id!,
                id: membershipId!,
            },
            fetchPolicy: "network-only",
            skip: skipQuery,
        }
    );

    useEffect(() => {
        if (membershipJSONIdProof) {
            let subjectJSON: CertificateInterface = {};
            const subjectItems = membershipJSONIdProof.subject.split("/");
            subjectItems.map((item) => {
                const currentItem = item.split("=");
                const prop = propName.get(currentItem[0]);
                if (currentItem.length === 2 && prop) {
                    subjectJSON[prop] = currentItem[1];
                }
                return null;
            });
            setMembershipIdProof({
                ...subjectJSON,
                serial: membershipJSONIdProof.serial,
            });
        }
        if (!queryLoading) setLoading(false)
    }, [membershipJSONIdProof, queryLoading]);

    return { proof: membershipIdProof, isLoading: loading };
};
