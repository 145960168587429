import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const HelpStep1 = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateProtocolConfigurationsStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateProtocolConfigurationsStep1:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/Node_Settings.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/using-kaleido/blockchain-advanced-configuration/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Protocol Configurations',
    helpDescription: 'Kaleido provides the ability to create a configuration profile that includes Gas Price, Target Gas Limit, Garbage Collection Mode, Sync Mode, Log Verbosity, CORS Origin Hosts at the environment level, for each membership.',
    helpDesc1: 'A configuration profile can be assigned to a node at creation time for these settings to be applied.',
    helpDesc2: 'To apply a node configuration profile to an existing node, the node needs to be "reset" after applying the configuration profile.',
}