import React from "react";
import { Typography, makeStyles, Divider } from "@material-ui/core";

interface Props {
    info: {
        label: string;
        content: string | JSX.Element;
    }[];
}

export const DisplayInfoList = ({ info }: Props) => {
    const classes = useStyles();

    const infoLength = info.length - 1;

    return (
        <>
            {info.map((item, index) => (
                <React.Fragment key={`${item.label}-${index}`}>
                    <div className={classes.rowContainer}>
                        <Typography variant="body2">{item.label}</Typography>
                        <div className={classes.contentContainer}>
                            {React.isValidElement(item.content) ? (
                                item.content
                            ) : (
                                <Typography variant="body1">
                                    {item.content}
                                </Typography>
                            )}
                        </div>
                    </div>
                    {index < infoLength && <Divider />}
                </React.Fragment>
            ))}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2, 0),
        width: "100%",
    },
    contentContainer: {
        maxWidth: "60%",
        textAlign: 'right'
    },
}));
