import React, { useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { StaticConfigData, StaticConfigQuery } from '../../../models';
import { loadStripe } from '@stripe/stripe-js/pure';
import { StripeContext } from '../../../App';
import { Elements } from '@stripe/react-stripe-js';
import { CircularProgress } from '@material-ui/core';

export const StripeWrapper: React.FC = ({ children }) => {

    const [stripePromise, setStripePromise] = useContext(StripeContext);

    const {
        data: {
            staticConfig: {
                stripeKey
            }
        } = { staticConfig: {} }
    } = useQuery<StaticConfigData>(StaticConfigQuery, {
        fetchPolicy: 'cache-first'
    });

    useEffect(() => {
        if (!stripePromise && stripeKey && setStripePromise) {
            setStripePromise(loadStripe(stripeKey));
        }
    }, [stripePromise, setStripePromise, stripeKey]);

    if (!stripePromise) {
        return <CircularProgress />
    }

    return <Elements stripe={stripePromise}>{children}</Elements>

};