import { useApolloClient, useMutation } from '@apollo/client';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { Category } from '../../components/HelpCenter/ContactUs/ContactUs';
import { HelpCenter, HelpCenterTab } from '../../components/HelpCenter/HelpCenter';
import { ORGS_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { PlanCard } from '../../components/PlanCard/PlanCard';
import { EnPlansTranslations, OrganizationData, OrganizationQuery, OrganizationVars, PlanName, PlansEnum, PlansTranslations, PlanUpgradeData, PlanUpgradeVars, UpgradePlanMutation } from '../../models';
import { cognitoController } from '../../utils/cognitoController';
import { UpdateContactInfoData, UpdateContactInfoVars, UpdateContactInfoMutation, MakeUpdateContactInfoMutationOptions } from '../../models/contactInfo';

export const SIGNUP_PLANS_BASE_PATH = 'signup';
export const SIGNUP_PLANS_PATH = 'plans';
export const SIGNUP_BILLING_PATH = 'billing';

export const SIGNUP_PLANS_PATHS = [
    `/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_PLANS_PATH}`,
    `/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_BILLING_PATH}/1`,
    `/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_BILLING_PATH}/2`,
    `/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_BILLING_PATH}/3`
]

export const SignupPlans = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SignupPlans', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SignupPlans:${key}`, interpolate);

    const [message, setMessage] = useState('');
    const [openStarterConfirm, setOpenStarterConfirm] = useState(false);
    const [helpCenter, setHelpCenter] = useState(false);
    const [preSelectedCategory, setPreSelectedCategory] = useState<Category>('salesEnquiry');

    const classes = useStyles();
    const { org_id } = useParams<any>();
    const history = useHistory();

    const client = useApolloClient();

    const [updateContactInfo] = useMutation<UpdateContactInfoData, UpdateContactInfoVars>(UpdateContactInfoMutation);

    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id! } })!

    const [upgradePlan] = useMutation<PlanUpgradeData, PlanUpgradeVars>(UpgradePlanMutation);

    if (organization.plan !== 'signup') return <Redirect to="/" />

    const onStarterConfirm = async () => {
        try {
            const { data } = await upgradePlan({
                variables: {
                    org_id,
                    targetPlan: {
                        plan: 'starter',
                        support_level: 100
                    }
                }
            })
            await cognitoController.renewSession(client, org_id);
            if (data?.upgradePlan) {
                await updateContactInfo(MakeUpdateContactInfoMutationOptions({
                    properties: {
                        kaleido_plan_at_signup: 'starter'
                    }
                }));

                history.push('/');
            } else {
                setMessage(lt('failedToChangePlan'));
            }
        } catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
            console.error(err)
        }
    };

    const onPlanClick = (plan: PlanName) => {
        if (plan === 'enterprise') {
            setPreSelectedCategory('enterprisePlanEnquiry');
            setHelpCenter(true);
        } else {
            history.push(`/${ORGS_PATH}/${org_id}/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_BILLING_PATH}/1`, {
                plan,
                supportLevel: plan === 'team' ? 100 : 200
            })
        }
    }

    return (
        <>
            <FormDialog 
                open={openStarterConfirm} 
                setOpen={setOpenStarterConfirm} 
                header={lt('confirmStarterHeader')}
                description={lt('starterDescription')}
                onSave={onStarterConfirm}
                saveText={lt('confirm')}  />
            <HelpCenter
                isOpen={helpCenter}
                setOpen={setHelpCenter}
                tab={HelpCenterTab.CONTACT_US}
                preSelectedCategory={preSelectedCategory}
            />
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <Container>
                <Grid container direction="column" spacing={4} justify="space-between">
                    <Grid item container alignItems="center" justify="center" direction="column" spacing={1}>
                        <Grid item>
                            <Typography 
                                className={classes.title} 
                                variant="h3">
                                    {lt('getStarted')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title} variant="h6">{lt('chooseOptions')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="center" justify="center" spacing={3}>
                        <Grid item>
                            <PlanCard
                                plan="starter"
                                isSignup
                                name={lt(PlansEnum['starter'])}
                                description={lt('starterDescription')}
                                onClick={() => setOpenStarterConfirm(true)}
                            />
                        </Grid>
                        <Grid item>
                            <PlanCard
                                isSignup
                                plan="team"
                                name={lt(PlansEnum['team'])}
                                description={lt('teamDescription')}
                                onClick={() => onPlanClick('team')}
                            />
                        </Grid>
                        <Grid item>
                            <PlanCard
                                isSignup
                                plan="business"
                                name={lt(PlansEnum['business'])}
                                description={lt('businessDescription')}
                                onClick={() => onPlanClick('business')}
                            />
                        </Grid>
                        <Grid item>
                            <PlanCard
                                isSignup
                                plan="enterprise"
                                name={lt(PlansEnum['enterprise'])}
                                description={lt('enterpriseDescription')}
                                onClick={() => onPlanClick('enterprise')}
                                buttonLabel={lt('contactUs')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item></Grid>
                    <Grid item></Grid>
                    <Grid item></Grid>
                </Grid>
            </Container>
        </>      
    )
}

interface translations extends PlansTranslations {
    starterDescription: string
    noUsageFeesAreIncluded: string
    usageFeesStartAt: string
    $055PerNodeHour: string
    $015PerHour: string
    teamDescription: string
    businessDescription: string
    enterpriseFees1: string
    enterpriseFees2: string
    enterpriseDescription: string
    contactUs: string
    $015perNodeHour: string
    $055PerHour: string
    getStarted: string
    chooseOptions: string
    failedToChangePlan: string
    limitedTimeOffer: string
    confirmStarterHeader: string
    confirm: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    confirm: 'Confirm',
    confirmStarterHeader: 'Confirm Starter Plan Selection',
    starterDescription: 'Get started on your blockchain journey with our free plan.',
    noUsageFeesAreIncluded: 'No usage fees are included.',
    businessDescription: 'Production-ready with enhanced services and support levels.',
    enterpriseFees1: 'Usage fees comes with',
    enterpriseFees2: 'additional savings',
    enterpriseDescription: 'Production-ready with full support and SLA guarantees',
    contactUs: 'Contact Us',
    $055PerHour: 'Nodes $0.55/hr',
    $055PerNodeHour: '$0.55 per node hour',
    $015PerHour: 'Nodes $0.15/hr',
    teamDescription: 'Grow your projects with expanded resources',
    $015perNodeHour: '$ 0.15 per node hour',
    usageFeesStartAt: 'Usage fees start at',
    getStarted: 'Get Started with the right plan.',
    chooseOptions: 'Choose the option that fits your blockchain journey. You may cancel anytime.',
    failedToChangePlan: 'Could not change plans. Please check plan requirements.',
    limitedTimeOffer: 'Get $200 in free usage credits'
}

const useStyles = makeStyles(() => ({
    title: {
        color: 'white'
    }
}))