import React from 'react';
import { AlertBanner } from '../Banners/AlertBanner';

interface Props {
    description: string
};

export const Paywall = ({ description }: Props) => {
    return (
        <AlertBanner {...{description}} />
    )
}