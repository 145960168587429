import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ConfigsQuery, ConfigsData, ConfigTypes, ConfigTypeTranslationsInterface, ConfigPageTitleTranslationsInterface, ConfigTypeTranslations, ConfigPageTitleTranslations } from '../../models/configs';
import { EnvironmentResourcesVars, EnvironmentResourceVars, ConsortiumResourceVars } from '../../interfaces';
import { NodeData, NodeQuery, EnvironmentData, EnvironmentQuery } from '../../models';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import VpnKeyIcon from 'mdi-react/KeyIcon';
import BackupIcon from 'mdi-react/CloudUploadIcon';
import NetworkIcon from 'mdi-react/AccessPointIcon';
import StreamIcon from 'mdi-react/SwapHorizontalIcon';
import ShieldOutlineIcon from 'mdi-react/ShieldOutlineIcon';
import { CLOUDCONFIG_BASE_PATH, ConfigTypesUrl, CLOUDCONFIG_KEYSTORE_PATH, CLOUDCONFIG_BACKUP_PATH, CLOUDCONFIG_LOGSTREAM_PATH, CLOUDCONFIG_NETWORK_PATH, cloudConfigTypeURList } from '../../components/MainNav/SideNavs/CloudConfigs';
import { useTranslation } from 'react-i18next';
import { EditableSettings } from '../../components/DisplaySettings';
import { ConfigStatus } from './ConfigStatus';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../../components/MainNav/SideNavs/Security';

export const CloudConfigurations = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeCloudConfigurations', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeCloudConfigurations:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();

    const history = useHistory();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        loading,
        data: {
            node
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: {
            ...environmentVariables,
            id: node_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            configs
        } = { configs: null }
    } = useQuery<ConfigsData, EnvironmentResourcesVars>(ConfigsQuery, {
        variables: environmentVariables,
        fetchPolicy: 'cache-and-network'
    });

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;


    if (loading || !node) return <CircularProgress />

    const configurations = configs?.filter(entry => entry._id === node.kms_id || entry._id === node.networking_id || entry._id === node.backup_id || entry._id === node.opsmetric_id || entry._id === node.baf_id);

    const historyPusher = (configType: ConfigTypesUrl, configId?: string) => {
        
        let basePath = cloudConfigTypeURList.includes(configType)
            ? `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${configType}`
            : `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`;
        if (configId) {
            history.push(`${basePath}/${configId}`);
        } else {
            history.push(basePath);
        }
    }

    const displayValue = (configType: ConfigTypes) => {
        const config = configurations?.find(config => config.type === configType)
        return config ? <ConfigStatus {...{node}} configuration={config} /> : lt('noneApplied')
    }

    const actionsList = [
        {
            icon: <VpnKeyIcon />,
            title: lt('keystore'),
            description: lt('keystoreDescription'),
            value: displayValue('kms'),
            buttonLabel: isCorda ? lt('comingSoon') : undefined,
            disabledButton: isCorda,
            action: () => historyPusher(CLOUDCONFIG_KEYSTORE_PATH, configurations?.find(config => config.type === 'kms')?._id)
        },
        {
            icon: <BackupIcon />,
            title: lt('backup'),
            description: lt('backupDescription'),
            value: displayValue('backup'),
            action: () => historyPusher(CLOUDCONFIG_BACKUP_PATH, configurations?.find(config => config.type === 'backup')?._id)
        },
        {
            icon: <StreamIcon />,
            title: lt('logstream'),
            description: lt('logstreamDescription'),
            value: displayValue('opsmetric'),
            action: () => historyPusher(CLOUDCONFIG_LOGSTREAM_PATH, configurations?.find(config => config.type === 'opsmetric')?._id)
        }
    ]

    if (node.role !== 'peer') {
        actionsList.push({
            icon: <NetworkIcon />,
            title: lt('networking'),
            description: lt('networkingDescription'),
            value: displayValue('networking'),
            action: () => historyPusher(CLOUDCONFIG_NETWORK_PATH, configurations?.find(config => config.type === 'networking')?._id)
        });
    }

    if (!isCorda && !isFabric) { 
        actionsList.push({
            icon: <ShieldOutlineIcon />,
            title: lt('baf_title'),
            description: lt('bafDescription'),
            value: displayValue('baf'),
            action: () => historyPusher(SECURITY_BAF_PATH, configurations?.find(config => config.type === 'baf')?._id)
        })
    }

    return (
        <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">
                        {lt('cloudConfigurations')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <EditableSettings
                        header={lt('configurations')}
                        {...{actionsList}} />
                </Grid>
            </Grid>
    )
}

interface translations extends ConfigTypeTranslationsInterface, ConfigPageTitleTranslationsInterface {
    cloudConfigurations: string,
    configurations: string
    keystoreDescription: string,
    backupDescription: string,
    logstreamDescription: string,
    networkingDescription: string,
    bafDescription: string,
    noneApplied: string,
    comingSoon: string
}
const enTranslations: translations = {
    ...ConfigTypeTranslations,
    ...ConfigPageTitleTranslations,
    cloudConfigurations: 'Cloud Configurations',
    configurations: 'Configurations',
    keystoreDescription: 'Enhance the protection of Key Material',
    backupDescription: 'Backup your node to the cloud',
    logstreamDescription: 'Stream logs to your own management system',
    networkingDescription: 'Private networking with your own Cloud account',
    bafDescription: 'Apply fine grained access control and OAuth 2.0 authentication',
    noneApplied: 'None Applied',
    comingSoon: 'Coming Soon'
}