import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step2Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConsortiumCreateStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConsortiumCreateStep2Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Network-HomeRegion.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('helpFooter')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks/#configuring-your-consortium"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpFooter: string,
}
const enTranslations: translations = {
    helpHeader: 'What is a home region?',
    helpDescription: 'The home region is the cloud provider and underlying region where information about the network (such as membership details) is stored. The available cloud providers are:',
    helpDesc1: 'AWS',
    helpDesc2: 'Microsoft Azure',
    helpFooter: 'Additional regions may be whitelisted during the next and final step of creating a network.',
}