import React, { useMemo, useEffect } from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
    TetherBalanceQuery,
    TetherBalanceData,
    TetherBalanceVars,
    tetherNetworkTypes,
} from "../../models/tether";
import { useTranslation } from "react-i18next";
import { convertWeiToEther } from "../../utils/StringUtils";

interface Props {
    refresh: boolean;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    network: tetherNetworkTypes;
    service_id: string;
}

export const DisplayBalance = ({ setRefresh, refresh, ...args }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "DisplayBalance", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`DisplayBalance:${key}`, interpolate);

    const {
        refetch,
        loading,
        data: { tetherBalance } = { tetherBalance: null },
    } = useQuery<TetherBalanceData, TetherBalanceVars>(TetherBalanceQuery, {
        variables: {
            ...args,
        },
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        if (refresh) {
            refetch()
                .catch(() => {})
                .finally(() => setRefresh(false));
        }
    }, [refresh, refetch, setRefresh]);

    const balance = useMemo(() => {
        return tetherBalance ? convertWeiToEther(tetherBalance.balance) : 0;
    }, [tetherBalance]);

    if (loading) return <CircularProgress style={{ width: 16, height: 16 }} />;

    return <Typography>{`${balance} ${lt("eth")}`}</Typography>;
};

interface translations {
    eth: string;
}
const enTranslations: translations = {
    eth: "ETH",
};
