import React from 'react';
import { makeStyles, Theme, Link as ReactLink, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { BrandColors } from '../../utils/Colors';

interface Props {
    onClick?: () => void
    to?: string
    isExternal?: boolean
    underlined?: 'always' | 'never' | 'hover'
    staticColor?: boolean
    contrastColor?: boolean
    bold?: boolean
    children?: JSX.Element | string
    download?: string
    target?: string
};

export const FormLink = ({ to, onClick, isExternal = false, underlined = 'hover', bold = false, staticColor = true, contrastColor = false, children, download, target }: Props) => {
    const classes = useStyles({ underlined, staticColor, contrastColor, bold });
    if (to) {
        if (isExternal) {
            return <ReactLink download={download} target={target} className={classes.link} href={to}>{children}</ReactLink>
        } else {
            return <RouterLink className={classes.link} to={to}>{children}</RouterLink>
        }
    } else if(onClick) {
        return <Link className={classes.link} onClick={onClick}>{children}</Link>
    } else {
        return <>{children}</>
    }
};

interface RouterLinkStyle {
    underlined: string
    staticColor: boolean
    contrastColor: boolean
    bold: boolean
}

const useStyles = makeStyles<Theme, RouterLinkStyle>(theme => ({
    link: props => ({
        textDecoration: props.underlined !== 'always' ? 'none' : '',
        fontWeight: props.bold ? 'bold' : undefined,
        color: props.contrastColor ? theme.palette.primary.contrastText : props.staticColor ? BrandColors.blurple : '',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: props.underlined === 'hover' ? 'underline' : '',
        }
    })
}));