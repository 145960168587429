import { gql } from '@apollo/client';
import { EnvironmentResourcesVars } from '../interfaces';

// INTERFACES

export type BackupFrequency = 1 | 2 | 3 | 4

export interface Backup {
    _id: string
    enabled: boolean
    backup_frequency: BackupFrequency
}

export interface CreateBackupData {
    createBackup: Backup
}

export interface UpdateBackupData {
    updateBackup: Backup
}

export interface BackupData {
    backup?: Backup
}

export interface CreateBackupVars extends EnvironmentResourcesVars {
    backup: {
        enabled: Backup['enabled']
        backup_frequency: Backup['backup_frequency']
    }
}

export interface UpdateBackupVars extends CreateBackupVars {}

// QUERIES & SUBSCRIPTIONS

export const BackupQuery = gql`
    query getBackup($consortia_id: String!, $environment_id: String!) {
        backup(consortia_id: $consortia_id, environment_id: $environment_id) {
            _id
            enabled
            backup_frequency
        }
    }`;

export const CreateBackupMutation = gql`
    mutation createBackup($consortia_id: String!, $environment_id: String!, $backup: CreateUpdateBackupInput!) {
        createBackup(consortia_id: $consortia_id, environment_id: $environment_id, backup: $backup) {
            _id
            enabled
            backup_frequency
        }
    }`;

export const UpdateBackupMutation = gql`
    mutation updateBackup($consortia_id: String!, $environment_id: String!, $backup: CreateUpdateBackupInput!) {
        updateBackup(consortia_id: $consortia_id, environment_id: $environment_id, backup: $backup) {
            _id
            enabled
            backup_frequency
        }
    }`;
