import React from "react"
import './KaleidoBlue.css'

export const KaleidoLogoBlue = () =>
(<svg className="kaleido-loader-blue" width="54" height="60" viewBox="0 0 54 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path className="shape-blue outer-shape-blue-1" d="M29.9377 0.847849C28.9766 0.293152 27.8789 0 26.7625 0C25.6463 0 24.5486 0.293152 23.5872 0.847849L14.4749 6.10888L14.4983 17.3848L36.5392 4.65945L29.9377 0.847849Z" />
	<path className="shape-blue outer-shape-blue-2" d="M53.5252 25.7554V18.1324C53.5252 15.8707 52.3086 13.7633 50.3499 12.6327L41.2612 7.38525L31.4843 13.0302L53.5252 25.7554Z" />
	<path className="shape-blue outer-shape-blue-3" d="M43.748 51.013L50.3499 47.2018C52.3086 46.0712 53.5251 43.964 53.5251 41.7024V31.1807L43.748 25.5625V51.013Z" />
	<path className="shape-blue outer-shape-blue-4" d="M29.9377 58.9871L39.0503 53.7257L39.0269 42.45L16.9858 55.1755L23.5873 58.9871C24.549 59.5418 25.647 59.835 26.7625 59.835C27.8785 59.835 28.9764 59.5418 29.9377 58.9871Z" />
	<path className="shape-blue outer-shape-blue-5" d="M22.0407 46.8046L0 34.0794V41.7024C0 43.964 1.21672 46.0713 3.17503 47.2019L12.264 52.4493L22.0407 46.8046Z" />
	<path className="shape-blue outer-shape-blue-6" d="M9.77688 8.82147L3.17503 12.6331C1.21672 13.7633 0 15.8707 0 18.1323V28.6544L9.77688 34.272V8.82147Z" />
	<path className="shape-blue inner-shape-blue-1" d="M39.0267 22.8368L26.7625 15.7562L16.8594 21.4737L39.0267 34.2718V22.8368Z" />
	<path className="shape-blue inner-shape-blue-2" d="M14.4985 25.5627V36.998L26.786 44.0922L36.6658 38.3611L14.4985 25.5627Z" />
</svg>);
