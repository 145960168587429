import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import {
    ServicesData, ServicesQuery, NodesData, NodesQuery, EnvironmentZonesData, EnvironmentZonesQuery, ServicesEnum, Node, Service, EnServicesTranslations, ServicesTranslations, RuntimeSizeTranslation, EnRuntimeSizeTranslation
} from '../../../models'
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from "@material-ui/core";
import { DisplayTable, EmptyState } from '../../../components/DisplayWrappers';
import AdjustIcon from 'mdi-react/AdjustIcon';
import AppsIcon from 'mdi-react/AppsIcon';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_RUNTIMES_PATH, SYSTEM_MEMBERSHIP } from '../../../components/MainNav/SideNavs/AddressBook';
import { ResourceStateChip } from '../../../components/FormControls/ResourceStateChip';

export const MemberRuntimes: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'MemberRuntimes', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MemberRuntimes:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, membership_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const columnHeaders = [
        '',
        lt('name'),
        lt('type'),
        lt('region'),
        lt('status')
    ];
    
    const getZoneLabel = (zoneId: string) => {
        const environmentZone = environmentZones.find(zone => zone._id === zoneId) || environmentZones[0];
        return environmentZone?.displayName;
    };

    const ownsNode = (node: Node) => {
        return node.membership_id === membership_id || (membership_id === SYSTEM_MEMBERSHIP && node.role === 'monitor')
    }

    const ownsService = (service: Service) => {
        return (service.membership_id === membership_id) || (membership_id === SYSTEM_MEMBERSHIP && service.service_type === 'utility')
    }

    const nodeRecords = nodes.filter(node => ownsNode(node)).map(node => ({
        key: node._id,
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membership_id}/${ADDRESSBOOK_RUNTIMES_PATH}/${node._id}`),
        columns: [
            { isIcon: true, value: <AdjustIcon /> },
            { value: node.name || '--' },
            { value: lt('node', { size: lt(node.size) }) },
            { value: getZoneLabel(node.zone_id) },
            { value: <ResourceStateChip state={node.state} /> }
        ]
    }));

    const serviceRecords = services.filter(service => ownsService(service)).map(service => ({
        key: service._id,
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membership_id}/${ADDRESSBOOK_RUNTIMES_PATH}/${service._id}`),
        columns: [
            { isIcon: true, value: <AppsIcon /> },
            { value: service.name || '--' },
            { value: lt(ServicesEnum[service.service]) },
            { value: getZoneLabel(service.zone_id) },
            { value: <ResourceStateChip state={service.state} /> }
        ]
    }));

    const runtimes = nodeRecords.concat(serviceRecords);

    if (runtimes.length === 0) {
        return <EmptyState
            title={lt('runtimes')}
            description={lt('noRuntimesForMember')}
            imageFile='Empty-Node.svg'
        />        
    }

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">
                    {lt('runtimes')}
                </Typography>
            </Grid>
            <Grid item>
                <DisplayTable header={lt('allRuntimes')} columnHeaders={columnHeaders} records={runtimes} />
            </Grid>
        </Grid>
    )
};

interface translations extends ServicesTranslations, RuntimeSizeTranslation {
    runtimes: string
    allRuntimes: string
    name: string
    type: string
    region: string
    status: string
    node: string
    noRuntimesForMember: string
}

const enTranslations: translations = {
    ...EnServicesTranslations,
    ...EnRuntimeSizeTranslation,
    runtimes: 'Runtimes',
    allRuntimes: 'All Runtimes',
    name: 'Name',
    type: 'Type',
    region: 'Region',
    status: 'Status',
    node: 'Node ({{size}})',
    noRuntimesForMember: 'This member has not created any runtimes yet'
}