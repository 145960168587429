import { Grid, Toolbar, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import { Banners } from '../Banners/Banners';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { HelpCenter, HelpCenterTab } from '../HelpCenter/HelpCenter';

interface Props {
    children: JSX.Element
};

const AppContent = ({ children }: Props) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppContent', enTranslations);

    const { org_id, consortium_id, environment_id } = useParams<any>();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();

    const [helpCenter, setHelpCenter] = useState(query.get('contact_support')?.toLowerCase() === 'true');

    const checkBanners = environment_id ? true : false

    return (
        <>
            <main className={classes.content}>
                <Toolbar />
                <Grid item container direction="column" spacing={2}>
                    { consortium_id && 
                    <Grid item className={classes.showBreadcrumbs}>
                        <Breadcrumbs />
                    </Grid> 
                    }
                    { checkBanners && <Banners /> }
                    <Grid item container>
                        { children }
                    </Grid>
                </Grid>
            </main>
            {org_id && 
            <>
                {/* <footer className={classes.footer}>
                    <Button onClick={() => setHelpCenter(true)} aria-label="share-feedback" 
                        disableRipple color='default' 
                        size="small" variant="contained" className={classes.feedbackButton} 
                        startIcon={
                            <CommentQuoteIcon size={18}/>
                        }>
                        {lt('shareFeedback')}
                    </Button>
                </footer> */}
                <HelpCenter
                    isOpen={helpCenter}
                    setOpen={setHelpCenter}
                    tab={HelpCenterTab.CONTACT_US}
                />
            </>
            }
        </>
    )
};

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3, 4, 6, 4),
        wordBreak: 'break-word',
        minWidth: 0
    },
    showBreadcrumbs: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    footer: {
        position: 'fixed', 
        right: -theme.spacing(4.1), 
        bottom: theme.spacing(6),
        height: theme.spacing(9),
    },
    feedbackButton: {
        textTransform: 'none',
        fontSize: 12,
        transform: 'rotate(-90deg)'
    },
}));

const enTranslations = {
    shareFeedback: 'Feedback'
}

export { AppContent };
