import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { FormLink } from '../FormControls/FormLink';

interface Props {
    imagePath: string,
    header: string,
    description?: string,
    bullet1?: string,
    bullet2?: string,
    bullet3?: string,
    bullet4?: string,
    finalInfo?: string,
    docLink?: string
};

export const Help = ({ imagePath, header, description = '', bullet1 = '', bullet2 = '', bullet3 = '', bullet4 = '', finalInfo = '', docLink }: Props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DialogWrapperHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DialogWrapperHelp:${key}`, interpolate)

    const makeText = (s: string): JSX.Element => {
        if (s) {
            return (
                <Typography variant="body2" color="textSecondary">
                    {s}
                </Typography>
            )
        } 
        return <></>
    }


    const makeLi = (s: string): JSX.Element => {
        if (s) {
            return (
                <li>
                    {makeText(s)}
                </li>
            )
        }
        return <></>
    }

    return (
        <>
            <Grid item container xs={12} md={4} direction="column" spacing={3} alignItems="center">
                <Grid item>
                    <img className={classes.image} src={imagePath} alt=""></img>
                </Grid>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {header}
                    </Typography>
                    {makeText(description)}
                    <ul>
                        {makeLi(bullet1)}
                        {makeLi(bullet2)}
                        {makeLi(bullet3)}
                        {makeLi(bullet4)}
                    </ul>
                    {makeText(finalInfo)}
                </Grid>
                <Grid item container alignItems="flex-start">
                {docLink &&
                <FormLink isExternal={true} target="_blank" to={docLink}>
                    {lt('documentationLink')}
                </FormLink>
                }
                </Grid>
            </Grid>
        </>
    )
};

const useStyles = makeStyles(() => ({
    image: {
        width: '188px', 
        height: '161px'
    }
}));

interface translations {
    documentationLink: string,
}
const enTranslations: translations = {
    documentationLink: 'Learn more'
}