import React from 'react';
import { useTranslation } from 'react-i18next';
import { GatewayAPIInstancesTable } from '../../components/GatewayAPIs/GatewayAPIInstancesTable';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { APPS_GATEWAY_VIEW_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';

export const GatewayAPIInstances: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GatewayAPIInstances', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GatewayAPIInstances:${key}`, interpolate)

    const { gateway_api_id } = useParams<any>();
    const {pathname} = useLocation();
    const history = useHistory();

    const handleInstanceClick = (endpoint: string) => {
        history.push(`${pathname}/${APPS_GATEWAY_VIEW_PATH}/${endpoint}`)
    }

    const onRowClick = (endpoint: string) => handleInstanceClick(endpoint)

    return (
        <>
            <GatewayAPIInstancesTable gatewayAPIId={gateway_api_id} header={lt('header')} {...{onRowClick}} />
        </>
    )
};

interface translations {
    header: string,
    viewInstanceAPI: string,
    viewInstanceAPIHeader: string,
    viewInstanceAPIDescription: string,
    
}
const enTranslations: translations = {
    header: 'Contract instances',
    viewInstanceAPI: 'View instance API',
    viewInstanceAPIHeader: 'View the Contract Instance API',
    viewInstanceAPIDescription: 'Select a node to view the Contract Instance API ({{endpoint}})'
}