import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers';

export const StepHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateDocExchangeHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateDocExchangeHelp:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-DocEx.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://www.kaleido.io/blockchain-platform/document-exchange"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string
}
const enTranslations: translations = {
    helpHeader: 'Document Exchange',
    helpDescription: 'Use the Document Exchange service to securely upload and transfer files within a Kaleido environment. It provides an ancillary file sharing utility that can exist next to the chain providing immense benefit at both a business and security level. Transactions simply need to reference a file’s hash, rather than dealing with the file contents directly. Documents can be stored on:',
    helpDesc1: 'Kaleido Storage',
    helpDesc2: 'AWS S3 Buckets',
    helpDesc3: 'Azure Blobs Conatiners'
}