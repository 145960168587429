import { useMutation, useQuery } from '@apollo/client';
import { Button, Chip, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import AlertOutlineIcon from 'mdi-react/AlertOutlineIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { NodeResourcesVars } from '../../interfaces';
import { CordaRolesData, CordaRolesQuery, CordaUsersData, CordaUsersQuery, DeleteCordaRoleMutation, DeleteCordaRoleVars, DeleteCordaUserMutation, DeleteCordaUserVars } from '../../models';
import { AlertDarkColors } from '../../utils/Colors';
import { DeleteResourceDialog } from '../DialogWrappers';
import { DisplayTable } from '../DisplayWrappers';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { AddCordaRoleDialog } from './AddCordaRoleDialog';
import { AddCordaUserDialog } from './AddCordaUserDialog';
import { ChangeCordaPasswordDialog } from './ChangeCordaPasswordDialog';
import { TableActions } from './TableActions';
import { UpdateCordaRoleDialog } from './UpdateCordaRoleDialog';
import { UpdateCordaUserDialog } from './UpdateCordaUserDialog';

export const CordaUsers: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CordaUsers', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CordaUsers:${key}`, interpolate)

    const classes = useStyles();

    const [deleteCordaUser, { loading: userDeleting }] = useMutation<String, DeleteCordaUserVars>(DeleteCordaUserMutation)
    const [deleteCordaRole, { loading: roleDeleting }] = useMutation<String, DeleteCordaRoleVars>(DeleteCordaRoleMutation)

    const { consortium_id, environment_id, node_id } = useParams<any>();
    const nodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        node_id: node_id!
    }

    const [addUserOpen, setAddUserOpen] = useState(false);
    const [updateUserOpen, setUpdateUserOpen] = useState(false);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [deleteUserOpen, setDeleteUserOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');

    const [addRoleOpen, setAddRoleOpen] = useState(false);
    const [updateRoleOpen, setUpdateRoleOpen] = useState(false);
    const [deleteRoleOpen, setDeleteRoleOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');

    const {
        loading: loadingUsers,
        refetch: refetchUsers,
        data: {
            cordaUsers
        } = { cordaUsers: [] }
    } = useQuery<CordaUsersData, NodeResourcesVars>(CordaUsersQuery, { 
        variables: nodeVars,
        fetchPolicy: 'cache-and-network'
    });

    const {
        loading: loadingRoles,
        refetch: refetchRoles,
        data: {
            cordaRoles
        } = { cordaRoles: [] }
    } = useQuery<CordaRolesData, NodeResourcesVars>(CordaRolesQuery, { 
        variables: nodeVars,
        fetchPolicy: 'cache-and-network'
    });

    const beforeChangePassword = (username: string) => {
        setSelectedUser(username);
        setChangePasswordOpen(true);
    }

    const beforeDeleteUser = (username: string) => {
        setSelectedUser(username);
        setDeleteUserOpen(true);
    }

    const beforeUpdateUser = (username: string) => {
        setSelectedUser(username);
        setUpdateUserOpen(true);
    }

    const deleteUser = async () => {
        await deleteCordaUser({
            variables: {
                id: selectedUser,
                ...nodeVars,
            }
        });
        refetchUsers();
    }

    const beforeDeleteRole = (roleName: string) => {
        setSelectedRole(roleName);
        setDeleteRoleOpen(true);
    }

    const beforeUpdateRole = (roleName: string) => {
        setSelectedRole(roleName);
        setUpdateRoleOpen(true);
    }

    const deleteRole = async () => {
        await deleteCordaRole({
            variables: {
                id: selectedRole,
                ...nodeVars,
            }
        });
        refetchRoles();
    }

    const userColumnHeaders = [
        '',
        lt('username'),
        lt('roles'),
        '',
    ]

    const rolesWithAnyWarning = (assignedRoles : string[] = []) => {
        let missing = 0;
        const rolesText = assignedRoles.join(', ');
        for (let assigned of assignedRoles) {
            let found = false;
            for (let {role} of cordaRoles) {
                found = found || role === assigned;
            }
            if (!found) missing++;
        }
        if (missing > 0) {
            return (<>
                {rolesText + " "}<Chip size="small" label={lt('missing')} icon={<AlertOutlineIcon className={classes.warning_primary} />} 
                className={classes.moveAwayFromText} />
            </>)
        }
        return rolesText;
    }

    const userRecords = cordaUsers.map(u => {
        const record : DisplayTableRecord = {
            key: u.username,
            // onClick: () => history.push(`${pathname}/${NODE_USER}/${u.username}`), /* no full page nav panels yet */
            columns: [
                {isIcon: true, value: <AccountIcon />, size: 'small'},
                {value: u.username},
                {value: rolesWithAnyWarning(u.roles) },
                {
                    value: <TableActions item={u.username} onDeleteClick={beforeDeleteUser} onChangePasswordClick={beforeChangePassword} onUpdateClick={beforeUpdateUser}/>,
                    onClick: () => {},
                    align: 'right',
                }
            ]
        };
        return record;
    })

    const roleColumnHeaders = [
        '',
        lt('role'),
        lt('permissions'),
        '',
    ]

    const roleRecords = cordaRoles.map(r => {
        const record : DisplayTableRecord = {
            key: r.role,
            // onClick: () => history.push(`${pathname}/${NODE_ROLE}/${u.username}`), /* no full page nav panels yet */
            columns: [
                {isIcon: true, value: <AccountGroupIcon />, size: 'small'},
                {value: r.role},
                {value: (r.permissions || []).join(', ') },
                {
                    value: <TableActions item={r.role} onDeleteClick={beforeDeleteRole} onUpdateClick={beforeUpdateRole} />,
                    onClick: () => {},
                    align: 'right'
                }
            ]
        };
        return record;
    })

    const selectedPermissions = useMemo(() => {
        return selectedRole && cordaRoles.find(r => r.role === selectedRole)?.permissions.join(', ');
    }, [cordaRoles, selectedRole])

    const selectedRoles = useMemo(() => {
        return selectedUser && (cordaUsers.find(u => u.username === selectedUser)?.roles || []).join(', ');
    }, [cordaUsers, selectedUser])

    const loading = loadingUsers || userDeleting || loadingRoles || roleDeleting;

    return (
        <>
            <AddCordaUserDialog open={addUserOpen} setOpen={setAddUserOpen} afterSave={refetchUsers} />
            <AddCordaRoleDialog open={addRoleOpen} setOpen={setAddRoleOpen} afterSave={refetchRoles} />
            <UpdateCordaUserDialog open={updateUserOpen} setOpen={setUpdateUserOpen} afterSave={refetchUsers} username={selectedUser} existingRoles={selectedRoles}/>
            <ChangeCordaPasswordDialog open={changePasswordOpen} setOpen={setChangePasswordOpen} afterSave={refetchUsers} username={selectedUser}/>
            <UpdateCordaRoleDialog open={updateRoleOpen} setOpen={setUpdateRoleOpen} afterSave={refetchRoles} role={selectedRole} existingPermissions={selectedPermissions}/>
            <DeleteResourceDialog resourceType={lt('user')} name={selectedUser} open={deleteUserOpen} setOpen={setDeleteUserOpen} onSave={deleteUser} {...{loading}} closeDialogAfterSave />
            <DeleteResourceDialog resourceType={lt('role')} name={selectedRole} open={deleteRoleOpen} setOpen={setDeleteRoleOpen} onSave={deleteRole} {...{loading}} closeDialogAfterSave />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('nodeCordaUsers')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" size="large" onClick={() => setAddUserOpen(true)}>
                            {lt('addUser')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container>
                    {loadingUsers ? 
                    <CircularProgress /> :
                    <DisplayTable header={lt('users')} columnHeaders={userColumnHeaders} records={userRecords} />
                    }
                </Grid>
                <Grid item container justify="flex-end" alignItems="center" >
                    <Grid item>
                        <Button color="primary" variant="contained" size="large" onClick={() => setAddRoleOpen(true)}>
                            {lt('addRole')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container>
                    {loadingRoles ? 
                    <CircularProgress /> :
                    <DisplayTable header={lt('roles')} columnHeaders={roleColumnHeaders} records={roleRecords} />
                    }
                </Grid>
            </Grid>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    warning_primary: {
        backgroundColor: AlertDarkColors.orange,
        color: 'white',
    },
    moveAwayFromText: {
        marginLeft: theme.spacing(1),
        backgroundColor: AlertDarkColors.orange,
        color: 'white',
    },
}))

interface translations {
    nodeCordaUsers: string,
    users: string,
    roles: string,
    user: string,
    role: string,
    username: string,
    addUser: string,
    addRole: string,
    permissions: string,
    missing: string,
}
const enTranslations: translations = {
    nodeCordaUsers: 'Corda RPC User Management',
    users: 'Users',
    roles: 'Roles',
    user: 'User',
    role: 'Role',
    username: 'Username',
    addUser: 'Create User',
    addRole: 'Create Role',
    permissions: 'Permissions',
    missing: 'Missing',
}