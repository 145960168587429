import { gql } from '@apollo/client';
import { NodeFields, ServiceFields, EnvironmentZoneFields, AppCredFields, 
    NodesData, ServicesData, EnvironmentZonesData, AppCredsData } from '../models'

export const EnvironmentWrapperQuery = gql`
    ${NodeFields}
    ${ServiceFields}
    ${EnvironmentZoneFields}
    ${AppCredFields}

    query EnvironmentWrapperQuery($consortia_id: String!, $environment_id: String!) {
        nodes(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...NodeFields
        }
        services(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...ServiceFields
        }
        environmentZones(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...EnvironmentZoneFields
        }
        appCreds(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...AppCredFields
        }
    }`;

export interface EnvironmentWrapperResult extends NodesData, ServicesData, EnvironmentZonesData, AppCredsData {}