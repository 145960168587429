import { gql } from "@apollo/client";
import { Environment } from "./environments";
import { MembershipOwnedResource } from "./memberships";
import { NodeConfigTypes, Node } from "./nodes";
import { ServiceConfigTypes } from "./services";

export type ConfigState = 'active' | 'inactive' | 'applied' | 'notApplied';

export type ProviderType = "aws" | "azure" | "hashicorp" | "aws_kms";

export interface ConfigTypeTranslationsInterface {
    backup: string
    keystore: string
    logstream: string
    networking: string
    storage: string
    cloudhsm: string
    baf: string
    configuration: string
}

export const ConfigTypeTranslations : ConfigTypeTranslationsInterface = {
    backup: 'Backup Store',
    keystore: 'Key Store',
    logstream: 'Log Stream',
    networking: 'Network Control',
    storage: 'Storage',
    cloudhsm: 'Cloud HSM',
    baf: 'App Firewall Policy',
    configuration: 'Protocol Configuration'
}

export interface ConfigPageTitleTranslationsInterface {
    backup_title: string,
    keystore_title: string,
    logstream_title: string,
    networking_title: string,
    storage_title: string,
    baf_title: string,
    cloudhsm_title: string
    ca_certs: string
    client_certs: string
    client_certs_secret: string
    configuration_title: string
}

export const ConfigPageTitleTranslations : ConfigPageTitleTranslationsInterface = {
    backup_title: 'Backup Configurations',
    keystore_title: 'Master Key Encryption Configurations',
    logstream_title: 'Log Streaming Configurations',
    networking_title: 'Network Control Configurations',
    storage_title: 'Cloud Storage Configurations',
    baf_title: "Blockchain Application Firewall",
    cloudhsm_title: 'Cloud HSM Configurations',
    ca_certs: 'CA Certs',
    client_certs: 'Client Certs',
    client_certs_secret: 'Client Certs Secret',
    configuration_title: 'Protocol Configurations'
}

export interface NodeConfigsDetails {
    gas_price?: number
    target_gas_limit?: number
    cors_origin_hosts?: string[]
    geth_log_verbosity?: string
    geth_cache_size?: number
    geth_cache_database_share?: number
    geth_cache_trie_share?: number
    geth_cache_gc_share?: number
    geth_cache_snapshot_share?: number
    geth_cache_trie_rejournal_interval?: number
    gc_mode?: string
    enable_trace_api?: boolean
    enable_revert_reason?: boolean
    sync_mode?: string
    restgw_max_inflight?: number
    restgw_max_tx_wait_time?: number
    restgw_always_manage_nonce?: boolean
    restgw_send_concurrency?: number
    restgw_attempt_gap_fill?: boolean
    restgw_flush_frequency?: number
    restgw_flush_msgs?: number
    restgw_flush_bytes?: number
    restgw_kafka_topic_conf?: KafkaTopicConfiguration
    rpc_gas_cap?: number
    fabric_logging_spec?: string
}

type NodeConfigDetailsKeysType = {
    [key in keyof NodeConfigsDetails]: boolean
}

const NodeConfigDetailsKeys: Required<NodeConfigDetailsKeysType> = {
    'gas_price': true,
    'target_gas_limit': true,
    'cors_origin_hosts': true,
    'geth_log_verbosity': true,
    'geth_cache_size': true,
    'geth_cache_database_share': true,
    'geth_cache_trie_share': true,
    'geth_cache_gc_share': true,
    'geth_cache_snapshot_share': true,
    'geth_cache_trie_rejournal_interval': true,
    'gc_mode': true,
    'enable_trace_api': true,
    'enable_revert_reason': true,
    'sync_mode': true,
    'restgw_max_inflight': true,
    'restgw_max_tx_wait_time': true,
    'restgw_always_manage_nonce': true,
    'restgw_send_concurrency': true,
    'restgw_attempt_gap_fill': true,
    'restgw_flush_frequency': true,
    'restgw_flush_msgs': true,
    'restgw_flush_bytes': true,
    'restgw_kafka_topic_conf': true,
    'rpc_gas_cap': true,
    'fabric_logging_spec': true
}

export const GetSupportedConfigDetailsKeys = (envOrNode: Environment | Node | null): NodeConfigDetailsKeysType => {
    let base = NodeConfigDetailsKeys
    if (envOrNode?.isFabric) {
        return {
            'restgw_max_inflight': true,
            'restgw_max_tx_wait_time': true,
            'restgw_send_concurrency': true,
            'fabric_logging_spec': true
        }
    } else {
        const { fabric_logging_spec, ...notFabric } = base
        return notFabric
    }
}

export interface NodeConfigsDetailsTranslationsInterface {
    gas_price: string
    target_gas_limit: string
    cors_origin_hosts: string
    geth_log_verbosity: string
    geth_cache_size: string
    geth_cache_database_share: string
    geth_cache_trie_share: string
    geth_cache_gc_share: string
    geth_cache_snapshot_share: string
    geth_cache_trie_rejournal_interval: string
    gc_mode: string
    enable_trace_api?: string
    enable_revert_reason?: string
    sync_mode: string
    restgw_max_inflight: string
    restgw_max_tx_wait_time: string
    restgw_always_manage_nonce: string
    restgw_send_concurrency: string
    restgw_attempt_gap_fill: string
    restgw_flush_frequency: string
    restgw_flush_msgs: string
    restgw_flush_bytes: string
    restgw_kafka_topic_conf: string
    rpc_gas_cap: string
    partition_count: string
    replication_factor: string
    retention_ms: string
    retention_bytes: string
    segment_bytes: string
    fabric_logging_spec: string
}

export const NodeConfigsDetailsTranslations : NodeConfigsDetailsTranslationsInterface = {
    gas_price: 'Gas Price',
    target_gas_limit: 'Target Gas Limit',
    cors_origin_hosts: 'Cors Origin Hosts',
    geth_log_verbosity: 'Geth Log Verbosity',
    geth_cache_size: 'Geth Cache Size',
    geth_cache_database_share: 'Geth Cache Database Share',
    geth_cache_trie_share: 'Geth Cache Trie Share',
    geth_cache_gc_share: 'Geth Cache GC Share',
    geth_cache_snapshot_share: 'Geth Cache Snapshot Share',
    geth_cache_trie_rejournal_interval: 'Geth Cache Trie Rejournal Interval',
    gc_mode: 'Garbage Collection Mode',
    enable_trace_api: 'Enable Trace API',
    enable_revert_reason: 'Enable Revert Reason',
    sync_mode: 'Sync Mode',
    restgw_max_inflight: 'Gateway API Inflight',
    restgw_max_tx_wait_time: 'Gateway API Transaction Wait Time',
    restgw_always_manage_nonce: 'Gateway API Manage Nonce',
    restgw_send_concurrency: 'Gateway API Send Concurrency',
    restgw_attempt_gap_fill: 'Gateway API Attempt Gap Fill',
    restgw_flush_frequency: 'Gateway API Flush Frequency',
    restgw_flush_msgs: 'Gateway API Flush Messages',
    restgw_flush_bytes: 'Gateway API Flush Bytes',
    restgw_kafka_topic_conf: 'Gateway Kafka Topic Configuration',
    rpc_gas_cap: 'RPC Gas Cap',
    partition_count: 'Gateway API Kafka Topic Partition Count',
    replication_factor: 'Gateway API Kafka Topic Replication Factor',
    retention_ms: 'Gateway API Kafka Topic Retention (ms)',
    retention_bytes: 'Gateway API Kafka Topic Retention Bytes',
    segment_bytes: 'Gateway API Kafka Topic Segment Bytes',
    fabric_logging_spec: 'Fabric Logging Specification'
}

export interface ConfigDetails extends NodeConfigsDetails {
    provider?: ProviderType
    region?: string
    api_key?: string
    api_secret?: string
    master_key?: string
    user_id?: string
    user_secret?: string
    role_arn?: string
    external_id_seed?: string
    group?: string
    bucket?: string
    environment_backup_target?: boolean
    allow_private?: boolean
    allow_public?: boolean
    target_address?: string
    target_region?: string
    subscription_id?: string
    tenant_id?: string
    tls?: TLS
    vault_name?: string
    baf_policy?: string // Too complex to model in GraphQL as well as Typescript. Serialize as string on wire
}

export interface TLS {
    ca_certs: string
    client_certs: string
    client_cert_secret: string
}

export interface KafkaTopicConfiguration{
    partition_count?: number
    replication_factor?: number
    retention_ms?: number
    retention_bytes?: number
    segment_bytes?: number
}

export interface FullNodeConfigurationDetails extends NodeConfigsDetails, KafkaTopicConfiguration {}

interface ConfigResourceVars {
    consortia_id: string,
    environment_id: string,
}
export interface CreateConfigVars extends ConfigResourceVars {
    configuration: ConfigurationCreate
}

export interface UpdateConfigVars extends ConfigResourceVars {
    id: string,
    configuration: ConfigurationUpdate,
}

export interface DeleteConfigVars extends ConfigResourceVars {
    id: string
}

export interface ConfigurationUpdate {
    name: string
    details: ConfigDetails
}

export interface ConfigurationCreate extends ConfigurationUpdate {
    membership_id: string
    type: string
}


export type CloudConfigTypes = NodeConfigTypes | ServiceConfigTypes

export type ConfigTypes = CloudConfigTypes | 'baf' | 'ethconnect' | 'node_config'

export interface Config extends MembershipOwnedResource {
    _id: string
    type: ConfigTypes
    name: string
    details: ConfigDetails
    _revision: string
    created_at: string
    environment_id: string
}

export interface ConfigData {
    config: Config
}

export interface CreateConfigData {
    createConfig: Config
}

export interface UpdateConfigData {
    updateConfig: Config
}

export interface ConfigsData {
    configs: Config[]
}

export const ConfigFields = ` 
    fragment ConfigFields on Config {
        _id
        type
        name
        membership_id
        details {
            provider
            user_id
            role_arn
            external_id_seed
            bucket
            environment_backup_target
            group
            region
            allow_private
            allow_public
            master_key
            api_key
            target_address
            target_region
            subscription_id
            tenant_id
            vault_name
            baf_policy
            gas_price
            target_gas_limit
            cors_origin_hosts
            geth_log_verbosity
            geth_cache_size
            geth_cache_database_share
            geth_cache_trie_share
            geth_cache_gc_share
            geth_cache_snapshot_share
            geth_cache_trie_rejournal_interval
            gc_mode
            enable_trace_api
            enable_revert_reason
            sync_mode
            restgw_max_inflight
            restgw_max_tx_wait_time
            restgw_always_manage_nonce
            restgw_send_concurrency
            restgw_attempt_gap_fill
            restgw_flush_frequency
            restgw_flush_msgs
            restgw_flush_bytes
            restgw_kafka_topic_conf {
                partition_count
                replication_factor
                retention_ms
                retention_bytes
                segment_bytes
            }
            rpc_gas_cap
            fabric_logging_spec
        }
        _revision
        created_at
        environment_id
        membership @client
    }`

export const ConfigsQuery = gql`
    ${ConfigFields}
    query getConfigs($consortia_id: String!, $environment_id: String!) {
        configs(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...ConfigFields
        }
    }`;

export const ConfigQuery = gql`
    ${ConfigFields}
    query getConfig($consortia_id: String!, $environment_id: String!, $id: String!) {
        config(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...ConfigFields
        }
    }`;

export const CreateConfigMutation = gql`
    ${ConfigFields}
    mutation createConfig($consortia_id: String!, $environment_id: String!, $configuration: ConfigurationCreateInput!) {
        createConfig(consortia_id: $consortia_id, environment_id: $environment_id, configuration: $configuration) {
            ...ConfigFields
        }
    }`;

export const UpdateConfigMutation = gql`
    ${ConfigFields}
    mutation updateConfig($consortia_id: String!, $environment_id: String!, $id: String!, $configuration: ConfigurationUpdateInput!) {
        updateConfig(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, configuration: $configuration) {
            ...ConfigFields
        }
    }`;

export const DeleteConfigMutation = gql`
mutation deleteConfig($consortia_id: String!, $environment_id: String!, $id: String!) {
    deleteConfig(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
}`;
