import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect, useLocation, useHistory } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers'
import { GithubStep1 } from './GithubStep1'
import { NETWORK_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { GithubCompilationProps } from '../../../components/ContractManagement/Github';
import { getDefaultCompilationDescription } from './VersioningUtils';
import { useQuery } from '@apollo/client';
import { CompiledContractsData, CompiledContractsVars, CompiledContractsQuery } from '../../../models';

export const CreateGithub: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationGithub', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationGithub:${key}`, interpolate)

    const history = useHistory<GithubCompilationProps>()
    const { pathname } = useLocation()
    const { org_id, consortium_id, contract_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${contract_id}`

    const {
        data: {
            compiledContracts
        } = { compiledContracts: null }
    } = useQuery<CompiledContractsData, CompiledContractsVars>(CompiledContractsQuery, {
        variables: { 
            consortia_id: consortium_id!,
            contract_id: contract_id!
        },
        fetchPolicy: 'cache-only'
    });

    let defaultCompilationDescription = getDefaultCompilationDescription(compiledContracts);

    const { 
        location: { 
            state: {
                description,
                membershipId,
                githubUrl,
                contractToCompile,
                solcVersion,
                evmVersion
            } = { description: defaultCompilationDescription,  membershipId: '',  githubUrl: '', contractToCompile: '', solcVersion: '', evmVersion: '' } 
        } 
    } = history

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep >= 1) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: '', component: <GithubStep1 cancelPath={cancelPath} {...{description}} {...{membershipId}} {...{githubUrl}} 
            {...{contractToCompile}} {...{solcVersion}} {...{evmVersion}} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} hideStepper toolbarHeader={lt('createCompilation')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createCompilation: string,
}
const enTranslations: translations = {
    createCompilation: 'Create a Version',
}