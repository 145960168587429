import { useQuery } from '@apollo/client';
import AppsIcon from 'mdi-react/AppsIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ConsortiumResourceVars, SideNavProps } from '../../../interfaces';
import { EnvironmentData, EnvironmentQuery } from '../../../models';
import { NavItem } from '../../NavComponents/NavItem';
import { LeftNavTab, SubNavs } from './SubNavs';

export const APPS_BASE_PATH = 'apps'
export const APPS_EVENTSTREAMS_PATH = 'eventstreams'
export const APPS_CHAINCODES_PATH = 'chaincodes';
export const APPS_GATEWAY_APIS_PATH = 'smartcontracts'
export const APPS_GATEWAY_VIEW_PATH = 'smartcontractview';
export const APPS_CORDAPPS_PATH = 'cordapps'
export const APPS_CORDAPP_VIEW_PATH = 'cordappview';

export const AppsIntegrations = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsIntegrations', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsIntegrations:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isEthereum = environment?.isEthereum;
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    const EVENT_STREAMS = lt('eventStreams')
    const APP_TYPE = isFabric ? lt('chaincodes') : isCorda ? lt('corDapps') : lt('smartContracts');
    const GATEWAYAPIS = isCorda ? lt('corDapps') : lt('smartContracts');

    const historyPusher = useCallback((path?: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}${path ? `/${path}` : ''}`), 
        [history, org_id, consortium_id, environment_id])
    
    useEffect(() => { 
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.includes(`${APPS_BASE_PATH}/${APPS_EVENTSTREAMS_PATH}`)) {
            setSelectedItem(EVENT_STREAMS)
            setActiveAndSelected()
        } else if (pathname.includes(`${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}`)) {
            setSelectedItem(APP_TYPE)
            setActiveAndSelected()
        } else if (pathname.includes(`${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}`) || pathname.includes(`${APPS_BASE_PATH}/${APPS_CORDAPPS_PATH}`)) {
            setSelectedItem(GATEWAYAPIS)
            setActiveAndSelected()
        } else {
            setSelectedItem('')
            setActiveAndSelected(false)
        }
    }, [pathname, setSelected, EVENT_STREAMS, APP_TYPE, GATEWAYAPIS])

    const appsSubItems = useCallback(() => {
        let items : LeftNavTab[] = [];

        if (isFabric) items = items.concat([
            {
                name: APP_TYPE,
                action: () => historyPusher(APPS_CHAINCODES_PATH)
            }
        ]);


        if (!isFabric) items = items.concat([
            {
                name: GATEWAYAPIS,
                action: () => historyPusher(isCorda ? APPS_CORDAPPS_PATH : APPS_GATEWAY_APIS_PATH)
            }
        ]);

        if (isEthereum) items = items.concat([
            {
                name: EVENT_STREAMS,
                action: () => historyPusher(APPS_EVENTSTREAMS_PATH),
            }
        ]);
        
        return items;
    }, [historyPusher, isCorda, isFabric, isEthereum, EVENT_STREAMS, APP_TYPE, GATEWAYAPIS])

    return (
        <>
            <NavItem icon={<AppsIcon />}
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={appsSubItems()} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations {
    name: string
    eventStreams: string,
    chaincodes: string,
    corDapps: string,
    smartContracts: string
    gatewayAPIs: string
}

const enTranslations: translations = {
    name: 'Apps',
    eventStreams: 'Event Streams',
    chaincodes: 'Chaincodes',
    corDapps: 'CorDapps',
    smartContracts: 'Smart Contracts',
    gatewayAPIs: 'Gateway APIs'
}
