import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, CircularProgress, TextField, Select, MenuItem } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { parsePhoneNumber } from 'awesome-phonenumber'; 
import { ContactInfoData, 
    ContactInfoQuery, 
    UpdateContactInfoMutation, 
    UpdateContactInfoVars, 
    UpdateContactInfoData, 
    MakeUpdateContactInfoMutationOptions, 
    ContactInfoProperties, 
    ContactInfoIndustryEnum, 
    ContactInfoJobTitleEnum,
    ContactInfoTranslationsInterface, 
    ContactInfoTranslations, 
    ContactInfoJobFunctionEnum
} from '../../models/contactInfo';
import { EditableSettings } from '../../components/DisplaySettings';
import { PhoneNumberInput } from '../../components/PhoneNumberInput/PhoneNumberInput';
import AccountIcon from 'mdi-react/AccountIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import PackageVariantIcon from 'mdi-react/PackageVariantIcon';
import { FormDialog } from '../../components/DialogWrappers';
import { isPhoneValid } from '../../utils/StringUtils';

export const ProfilePropertiesPersonalInfo: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProfilePropertiesPersonalInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProfilePropertiesPersonalInfo:${key}`, interpolate);

    const {
        loading,
        data: {
            contactInfo
        } = { contactInfo: null }
    } = useQuery<ContactInfoData>(ContactInfoQuery);

    const [phone, setPhone] = useState('');
    const [formattedPhone, setFormattedPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [industry, setIndustry] = useState<keyof typeof ContactInfoIndustryEnum | ''>('');
    const [jobTitle, setJobTitle] = useState<keyof typeof ContactInfoJobTitleEnum | ''>('');
    const [useCase, setUseCase] = useState<keyof typeof ContactInfoJobFunctionEnum | ''>('');
    const [editPhoneDialogOpen, setEditPhoneDialogOpen] = useState(false);
    const [editUserNameDialogOpen, setEditUserNameDialogOpen] = useState(false);
    const [editCompanyDialogOpen, setEditCompanyDialogOpen] = useState(false);
    const [editCompanySizeDialogOpen, setEditCompanySizeDialogOpen] = useState(false);
    const [editIndustryDialogOpen, setEditIndustryDialogOpen] = useState(false);
    const [editJobTitleDialogOpen, setEditJobTitleDialogOpen] = useState(false);
    const [editUseCaseDialogOpen, setEditUseCaseDialogOpen] = useState(false);

    const [updateContactInfo] = useMutation<UpdateContactInfoData, UpdateContactInfoVars>(UpdateContactInfoMutation);

    const updatePhoneNumber = (phoneNumber: string) => {
        setPhone(phoneNumber)
        setFormattedPhone(parsePhoneNumber(phoneNumber).getNumber('international'));
    }

    useEffect(() => {
        if (contactInfo) {
            setFirstName(contactInfo.firstname ?? '');
            setLastName(contactInfo.lastname ?? '');
            setCompany(contactInfo.company ?? '');
            setCompanySize(contactInfo.company_size ?? '');
            setIndustry(contactInfo.industry ?? '');
            setJobTitle(contactInfo.jobtitle ?? '');
            setUseCase(contactInfo.job_function ?? '');
            updatePhoneNumber(contactInfo.phone ?? '');
        }
    }, [contactInfo])

    if (loading) {
        return <CircularProgress />
    }

    const actionList = [
        {
            icon: <AccountIcon />,
            title: lt('userName'),
            value: lt('formattedName', { firstName: contactInfo?.firstname, lastName: contactInfo?.lastname }),
            action: () => setEditUserNameDialogOpen(true),
            buttonTestData: 'editUserNameButton'
        },
        {
            icon: <DomainIcon />,
            title: lt('company'),
            value: contactInfo?.company,
            action: () => setEditCompanyDialogOpen(true),
            buttonTestData: 'editUserCompanyButton'
        },
        {
            icon: <AccountIcon />,
            title: lt('phone'),
            value: formattedPhone,
            action: () => setEditPhoneDialogOpen(true),
            buttonTestData: 'editPhoneButton'
        },
        {
            icon: <AccountGroupIcon />,
            title: lt('companySize'),
            value: contactInfo?.company_size,
            action: () => setEditCompanySizeDialogOpen(true),
            buttonTestData: 'editUserCompanySizeButton'
        },
        {
            icon: <AccountHardHatIcon />,
            title: lt('industry'),
            value: contactInfo?.industry,
            action: () => setEditIndustryDialogOpen(true),
            buttonTestData: 'editIndustryButton'
        },
        {
            icon: <CardAccountDetailsOutlineIcon />,
            title: lt('jobTitle'),
            value: contactInfo?.jobtitle,
            action: () => setEditJobTitleDialogOpen(true),
            buttonTestData: 'editJobTitleButton'
        },
        {
            icon: <PackageVariantIcon />,
            title: lt('useCase'),
            value: contactInfo?.job_function,
            action: () => setEditUseCaseDialogOpen(true),
            buttonTestData: 'editUseCaseButton'
        }
    ];

    const handleSave = (properties: ContactInfoProperties) => {
        return updateContactInfo(MakeUpdateContactInfoMutationOptions({ properties }));
    }

    const phoneError = phone ? !isPhoneValid(phone) : false

    const editPhoneWrapper = (
        <PhoneNumberInput
            onPhoneNumberChange={setPhone}
            phoneNumber={phone}
        />
    );

    const editUserNameControlsWrapper = (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    data-test="editUserFirstName"
                    label={lt('firstName')}
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)}
                    variant="outlined"
                    fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    data-test="editUserLastName"
                    label={lt('lastName')}
                    value={lastName}
                    onChange={event => setLastName(event.target.value)}
                    variant="outlined"
                    fullWidth />
            </Grid>
        </Grid>
    );

    const editCompanyControlsWrapper = (
        <TextField
            data-test="editUserCompany"
            label={lt('name')}
            value={company}
            onChange={event => setCompany(event.target.value)}
            variant="outlined"
            fullWidth />
    );

    const editCompanySizeControlsWrapper = (
       <Select variant="outlined" fullWidth value={companySize} onChange={event => setCompanySize(event.target.value as string)}>
            <MenuItem value="1-25">1-25</MenuItem>
            <MenuItem value="25-100">25-100</MenuItem>
            <MenuItem value="100-1000">100-1000</MenuItem>
            <MenuItem value="1000+">1000+</MenuItem>
        </Select>
    );

    const editIndustryControlsWrapper = (
        <Select variant="outlined" fullWidth value={industry} onChange={event => setIndustry(event.target.value as keyof typeof ContactInfoIndustryEnum)}>
            {(Object.keys(ContactInfoIndustryEnum) as (keyof typeof ContactInfoIndustryEnum)[]).map((industry, index) => (
                <MenuItem key={`industry-${index}`} value={ContactInfoIndustryEnum[industry]}>{lt(industry)}</MenuItem>    
            ))}
        </Select>
    );

    const editJobTitleControlsWrapper = (
        <Select variant="outlined" fullWidth value={jobTitle} onChange={event => setJobTitle(event.target.value as keyof typeof ContactInfoJobTitleEnum)}>
            {(Object.keys(ContactInfoJobTitleEnum) as (keyof typeof ContactInfoJobTitleEnum)[]).map((title, index) => (
                <MenuItem key={`title-${index}`} value={ContactInfoJobTitleEnum[title]}>{lt(title)}</MenuItem>    
            ))}
        </Select>
    );

    const editJobFunctionControlsWrapper = (
        <Select variant="outlined" fullWidth value={useCase} onChange={event => setUseCase(event.target.value as keyof typeof ContactInfoJobFunctionEnum)}>
            {(Object.keys(ContactInfoJobFunctionEnum) as (keyof typeof ContactInfoJobFunctionEnum)[]).map((jobFunction, index) => (
                <MenuItem key={`job-${index}`} value={ContactInfoJobFunctionEnum[jobFunction]}>{lt(jobFunction)}</MenuItem>    
            ))}
        </Select>
    );

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('personalInfo')}</Typography>
                </Grid>
                <Grid item sm={8}>
                    <EditableSettings actionsList={actionList} />
                </Grid>
            </Grid>
            <FormDialog
                header={lt('userName')}
                open={editUserNameDialogOpen}
                setOpen={setEditUserNameDialogOpen}
                controlsWrapper={editUserNameControlsWrapper}
                onSave={() => handleSave({ firstname: firstName, lastname: lastName }).then(() => setEditUserNameDialogOpen(false))}
            />
            <FormDialog
                header={lt('editPhone')}
                saveDisabled={phoneError}
                open={editPhoneDialogOpen}
                setOpen={setEditPhoneDialogOpen}
                controlsWrapper={editPhoneWrapper}
                onSave={() => handleSave({ phone }).then(() => setEditPhoneDialogOpen(false))}
                onCancel={() => { if (contactInfo) { updatePhoneNumber(contactInfo.phone ?? '')} }}
                dialogContentHeight={275}
            />
            <FormDialog
                header={lt('company')}
                open={editCompanyDialogOpen}
                setOpen={setEditCompanyDialogOpen}
                controlsWrapper={editCompanyControlsWrapper}
                onSave={() => handleSave({ company }).then(() => setEditCompanyDialogOpen(false))}
            />
            <FormDialog
                header={lt('companySize')}
                open={editCompanySizeDialogOpen}
                setOpen={setEditCompanySizeDialogOpen}
                controlsWrapper={editCompanySizeControlsWrapper}
                onSave={() => handleSave({ company_size: companySize }).then(() => setEditCompanySizeDialogOpen(false))}
            />
            <FormDialog
                header={lt('industry')}
                open={editIndustryDialogOpen}
                setOpen={setEditIndustryDialogOpen}
                controlsWrapper={editIndustryControlsWrapper}
                onSave={() => handleSave({ industry }).then(() => setEditIndustryDialogOpen(false))}
            />
            <FormDialog
                header={lt('jobTitle')}
                open={editJobTitleDialogOpen}
                setOpen={setEditJobTitleDialogOpen}
                controlsWrapper={editJobTitleControlsWrapper}
                onSave={() => handleSave({ jobtitle: jobTitle }).then(() => setEditJobTitleDialogOpen(false))}
            />
            <FormDialog
                header={lt('useCase')}
                open={editUseCaseDialogOpen}
                setOpen={setEditUseCaseDialogOpen}
                controlsWrapper={editJobFunctionControlsWrapper}
                onSave={() => handleSave({ job_function: useCase }).then(() => setEditUseCaseDialogOpen(false))}
            />
        </>
    );
}

interface translations extends ContactInfoTranslationsInterface {
    personalInfo: string
    userName: string
    formattedName: string
    firstName: string
    lastName: string
    informationUnavailable: string
    updateFailure: string
    company: string
    name: string
    companySize: string
    industry: string
    jobTitle: string
    useCase: string
    editPhone: string
    phone: string
    phoneError: string
}

const enTranslations: translations = {
    ...ContactInfoTranslations,
    personalInfo: 'Personal Info',
    userName: 'User Name',
    formattedName: '{{firstName}} {{lastName}}',
    firstName: 'First Name',
    lastName: 'Last Name',
    informationUnavailable: 'Personal info not available at this time.',
    updateFailure: 'Failed to update contact information',
    company: 'Company',
    name: 'Name',
    companySize: 'Company Size',
    industry: 'Industry',
    jobTitle: 'Job Title',
    useCase: 'Use Case',
    editPhone: 'Edit Phone',
    phone: 'Phone',
    phoneError: 'Invalid Phone'
}