import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { B2B_BASE_PATH } from "../../../../components/MainNav/SideNavs/B2bCommunication";
import { RuntimeCreationFinalStep } from "../../../../components/RuntimeCreation/RuntimeCreationFinalStep";
import { RuntimeCreationSelectSizeContent } from "../../../../components/RuntimeCreation/RuntimeCreationSelectSizeContent";
import {
    ConsortiumZone, EnvironmentZone,
    RuntimeSize,
    ServicesEnum,
    OrganizationsData,
    OrganizationsQuery,
    App2AppLimitTranslations,
    EnApp2AppLimitTranslations
} from "../../../../models";
import { StepHelp } from "./StepHelp";
import { useQuery } from "@apollo/client";

interface Props {
    cancelPath: string;
    name: string;
    membershipId: string;
    consortiumZoneId: string;
    consortiumZones: ConsortiumZone[];
    environmentZones: EnvironmentZone[];
}

export const Step2 = ({
    cancelPath,
    name,
    consortiumZones,
    environmentZones,
    membershipId,
    consortiumZoneId,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateApp2AppStep2", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateApp2AppStep2:${key}`, interpolate);

    const { consortium_id, environment_id, org_id } = useParams<any>();

    const {
        data: {
            organizations
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery);

    const currentOrg = organizations.find(org => org._id === org_id);

    const [size, setSize] = useState<RuntimeSize>('small')

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const content = (
        <RuntimeCreationSelectSizeContent 
            {...{size}} 
            {...{setSize}}
            smallDescription={currentOrg?.plan === 'starter' ? lt('app2appStarterDescription') : lt('app2appSmallDescription')}
            mediumDescription={lt('app2appMediumDescription')}
            largeDescription={lt('app2appLargeDescription')} />
    )

    return (
        <>
            <RuntimeCreationFinalStep 
                servicePath={`${basePath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}`}
                service={ServicesEnum.app2app}
                {...{cancelPath}}
                {...{name}}
                {...{membershipId}}
                {...{consortiumZones}}
                {...{consortiumZoneId}}
                {...{environmentZones}}
                {...{content}}
                {...{size}}
                helpContent={<StepHelp />} />
        </>
    );
};

interface translations extends App2AppLimitTranslations {
    header: string;
    headerDescription: string;
    sizePaywall: string
}
const enTranslations: translations = {
    ...EnApp2AppLimitTranslations,
    header: "Select a Size",
    headerDescription: "You can select a size for this runtime best suited for your specific needs.",
    sizePaywall: 'Additional sizes (medium & large) with expanded capabilities are only available on the Business and Enterprise plans.'
};
