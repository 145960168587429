import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { Step2 } from './Step2';
import { ConsortiumZonesData, ConsortiumZonesQuery,
    EnvironmentZonesData, EnvironmentZonesQuery,
    EnvironmentData, EnvironmentQuery} from '../../models';
import { ConsortiumResourcesVars, ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces'
import { CircularProgress } from '@material-ui/core';
import { CHANNELS_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { Step3 } from './Step3';
import { Step4 } from './Step4';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'channelCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`channelCreate:${key}`, interpolate)

    type locationState = { name: string, description: string, membershipId: string, ordererNodeId: string, members: string[] }
    const history = useHistory<locationState>()
    const { org_id, consortium_id, environment_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}`;
    
    const { 
        location: { 
            state: {
                name: channelName,
                description: channelDescription,
                membershipId: channelMembershipId,
                ordererNodeId: channelOrdererNodeId,
                members: channelMembers
            } = { name: '', membershipId: '', description: '', ordererNodeId: '', members: [] } 
        }
    } = history;

    const { 
        data: { 
            environmentZones
        } = { environmentZones: [] } 
    } = useQuery<EnvironmentZonesData, EnvironmentResourcesVars>(EnvironmentZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });

    const privateZones = environmentZones.filter(z => z.type === 'private' && z.membership?.isMine);

    const isMultiRegion = environment?.test_features?.multi_region ?? false

    const step1Fulfilled = step && step >= "2"
    const [name, setName] = useState(step1Fulfilled ? channelName as string : '');
    const [description, setDescription] = useState(step1Fulfilled ? channelDescription as string : '');
    const [membershipId, setMembershipId] = useState(step1Fulfilled ? channelMembershipId as string : '');
    const [ordererNodeId, setOrdererNodeId] = useState(step1Fulfilled ? channelOrdererNodeId as string : '');
    const [selectedMembers, setSelectedMembers] = useState(step1Fulfilled ? channelMembers as string[] : ['system']);

    const invalidStep = (step !== "1" && step !== "2" && step !== "3" && step !== "4") || (step1Fulfilled && !name)
    if (invalidStep  || (step1Fulfilled && (!channelName || !channelMembershipId))) {
        return <Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/create/1`} />
    }

    if (!environment) return <CircularProgress/>

    const stepComponents = [
        { step: lt('channelConfigs'), component: <Step1 {...{cancelPath}} {...{isMultiRegion}} {...{name}} {...{setName}} {...{description}} {...{setDescription}} 
            {...{selectedMembers}} {...{setSelectedMembers}} 
            {...{membershipId}} {...{setMembershipId}} 
            {...{ordererNodeId}} {...{setOrdererNodeId}} 
            {...{consortiumZones}} {...{privateZones}} /> }
    ]   
    stepComponents.push(
        { step: lt('members'), component: <Step2 {...{name}} {...{description}} {...{membershipId}} {...{selectedMembers}} {...{setSelectedMembers}}  {...{cancelPath}} /> },
        { step: lt('nodes'), component: <Step3 {...{name}} {...{description}} {...{membershipId}} {...{selectedMembers}} {...{setSelectedMembers}} {...{cancelPath}} /> },
        { step: lt('policies'), component: <Step4 {...{name}} {...{description}} {...{membershipId}} {...{selectedMembers}} {...{setSelectedMembers}} {...{cancelPath}} /> },
    );
    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createChannel')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createChannel: string,
    channelConfigs: string,
    members: string,
    nodes: string,
    policies: string
}
const enTranslations: translations = {
    createChannel: 'Create a Channel',
    channelConfigs: 'Channel Details',
    members: 'Members',
    nodes: 'Nodes',
    policies: 'Policies'
}