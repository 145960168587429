import { TablePagination } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { ChannelChaincode, GatewayAPI } from '../../models';
import { DisplayTable, EmptyState } from '../DisplayWrappers';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { NETWORK_CONTRACTS_PATH } from "../MainNav/ConsortiumWrapper";

const PAGINATION_OPTIONS = [10, 25];

interface Props {
    header?: string
    description?: string
    onRowClick?: (gatewayAPIId: string) => void,
    chaincodes: GatewayAPI[] | ChannelChaincode[] | undefined,
    totalCount?: number,
    gatewayAPISource?: boolean
}

export const ChaincodesTable = ({ header, description, onRowClick, chaincodes = [], totalCount = 0, gatewayAPISource = false}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChaincodesTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChaincodesTable:${key}`, interpolate)
    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    
    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_OPTIONS[0]);
    const [page, setPage] = useState(0);
    let columnHeaders = [
        '',
        lt('contractName'),
        lt('chaincodeVersion'),
        lt('label')
    ]
    if (!gatewayAPISource) {
        columnHeaders.push(
            lt('sequence'),
            lt('initRequired')
        );
    }

    let records: DisplayTableRecord[] = [];
    chaincodes.forEach((g : GatewayAPI | ChannelChaincode) => {
        let record: DisplayTableRecord = {
            key: g._id,
            onClick: onRowClick ? () => onRowClick(g._id) : undefined,
            columns: [
                {isIcon: true, value: <img src={`${process.env.PUBLIC_URL}/img/protocols/TableIcons/icon-fabric.svg`} alt=""></img>},
                {value: g.consortiaContractName ?? '-'},
                {value: g.description || '', maxWidthPixelValue: 250},
                {value: g.label || ''}
            ] 
        }

        if (!gatewayAPISource) {
            record.columns.push(
                {value: g.sequence || '-'},
                {value: g.init_required? lt('yes') : lt('no')},
            )
        }

        records.push(record);
    });


    const showEmptyState = chaincodes.length === 0;

    if (showEmptyState) return (
        <EmptyState imageFile='Empty-Contracts-GatewayAPIs.svg'
            button={{ text: lt('promoteChaincode'), onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}`) }}
            title={gatewayAPISource ? lt('createChaincode') : lt('noChannelChaincodes')} 
            description={gatewayAPISource ? lt('emptyDescription') : ''}/>
    )

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const pagination = (
        <TablePagination rowsPerPageOptions={PAGINATION_OPTIONS} component="div" count={ totalCount } 
            rowsPerPage={rowsPerPage} page={page} 
            onChangeRowsPerPage={handleChangeRowsPerPage} onPageChange={handleChangePage}  />
    )

    return (
        <DisplayTable emptyLabel={lt('empty')}
            actionFooter={pagination} {...{header}} {...{description}} 
            {...{columnHeaders}} records={records.splice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)} />
    )
};

interface translations {
    header: string,
    description: string,
    contractName: string,
    chaincodeVersion: string,
    createChaincode: string,
    emptyDescription: string,
    emptyDocumentation: string,
    noChannelChaincodes: string,
    select: string,
    sequence: string,
    empty: string,
    label: string,
    initRequired: string,
    yes: string,
    no: string,
    promoteChaincode: string
}
const enTranslations: translations = {
    header: 'Chaincodes',
    description: 'The following chaincodes have been promoted to this environment.',
    contractName: 'App name',
    chaincodeVersion: 'Version',
    createChaincode: 'Promote a Chaincode to the Environment',
    emptyDescription: 'Go to Apps under the Network submenu to manage chaincodes.',
    emptyDocumentation: 'Documentation',
    noChannelChaincodes: 'No chaincodes have been installed to this channel.',
    select: 'Select',
    sequence: 'Sequence',
    empty: 'No chaincodes found.',
    label: 'Label',
    initRequired: 'Initialization required',
    yes: 'Yes',
    no: 'No',
    promoteChaincode: 'Promote a Chaincode'
}