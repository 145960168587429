import React from 'react';
import { 
    ListItem,
    makeStyles,
    ListItemText,
    List,
} from '@material-ui/core';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks'
import Popover from 'material-ui-popup-state/HoverPopover'
import { LeftNavTab } from './SubNavs'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import clsx from 'clsx';

interface Props {
    item: LeftNavTab,
    itemIsActive: boolean
};

export const NavPopoverItem = ({ item, itemIsActive }: Props) => {
    const classes = useStyles();

    const popupState = usePopupState({ variant: 'popover', popupId: item.name })

    const wrapActionWithPopupClose = (action: () => void) => {
        popupState.close()
        action()
    }

    return (
        <>
            <Popover
                data-test={`popover_${item.name}`}
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 226,
                }}
                transformOrigin={{
                    vertical: 8,
                    horizontal: 'left',
                }}
            >
                <List className={classes.list}>
                    {
                        item?.popoverItems?.map((pi, index) => (
                            <ListItem disabled={pi.disabled} divider={pi.divider} key={index} button onClick={() => wrapActionWithPopupClose(pi.action)}>
                                <ListItemText 
                                    primary={item.comingSoon ? <i>{pi.name}</i> : pi.name} 
                                    primaryTypographyProps={{variant: "body2"}} 
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Popover>

            <ListItem button {...bindHover(popupState)}
                className={clsx({
                    [classes.popoverHover]: popupState.isOpen,
                    [classes.nested]: !itemIsActive,
                })}
                disabled={!item?.popoverItems?.length}> 
                <ListItemText 
                    primary={item.comingSoon ? <i>{item.name}</i> : item.name} 
                    primaryTypographyProps={{variant: "body2"}} 
                />
                <ChevronRightIcon />
            </ListItem>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    // manipulating the color outside the theme aint gonna work in dark mode
    popoverHover: {
        backgroundColor: "#0000000a",
    },
    nested: {
        paddingLeft: theme.spacing(6)
    },
    list: {
        maxHeight: '300px',
        overflowY: 'auto'
    }
}));