import React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentStoreMetrics } from '../../../models';
import { BrandColors } from '../../../utils/Colors';
import { BarChart, BarChartSeries } from '../BarChart';
import { XAxisOptions } from 'highcharts';

interface Props {
    xAxis: XAxisOptions,
    runtimesForChart: DocumentStoreMetrics[],
    chart: 'sent' | 'received',
    height?: string
};

export const Transfers = ({ xAxis, runtimesForChart, chart, height }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsDocumentStoreTransfers', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChartsDocumentStoreTransfers:${key}`, interpolate)

    const addToList = (list: [number, number][], time: number, metric: number) => {
        const found = list.find(s => s[0] === time)
        if (found) {
            found[1] += metric
        } else {
            list.push([time, metric])
        }
    }

    const makeSeries = () => {
        let successful: [number, number][] = []
        let sent: [number, number][] = []
        let failed: [number, number][] = []  

        runtimesForChart.forEach(r => {            
            r.metrics.filter(m => new Date(m.time) < new Date()).forEach(d => {
                const time = Date.parse(d.time)
                if (chart === 'sent') {
                    addToList(successful, time, d.sent_status_success)
                    addToList(sent, time, d.sent_status_sent)
                    addToList(failed, time, d.sent_status_failed)
                } else if (chart === 'received') {
                    addToList(successful, time, d.received_status_success)
                    addToList(failed, time, d.received_status_failed)
                }
            })
        })

        let series: BarChartSeries[] = []
        series.push({
            name: lt('successful'),
            values: successful,
            color: BrandColors.blurple,
            showZero: true
        })
        if (chart === 'sent') {
            series.push({
                name: lt('sentStatus'),
                values: sent,
                color: BrandColors.yellow
            })
        }
        series.push({
            name: lt('failed'),
            values: failed,
            color: BrandColors.magenta
        })

        return series
    }

    const extraHCOptions: Highcharts.Options = {
        yAxis: {
            title: {
                text: lt('documents')
            },
            labels: {
                enabled: true
            }
        },
        legend:{
            enabled: true,
        },
        xAxis
    }

    return <BarChart title={lt(chart)} series={makeSeries()} height={height} {...{extraHCOptions}} hideXAxisLabel />
};

interface translations {
    sent: string,
    received: string,
    successful: string,
    failed: string,
    sentStatus: string,
    documents: string
}
const enTranslations: translations = {
    sent: 'Documents Sent',
    received: 'Documents Received',
    successful: 'Successful',
    failed: 'Failed',
    sentStatus: 'Sent',
    documents: 'Documents'
}