import React, { useState, useEffect } from 'react';
import { 
    List,
    makeStyles,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { SubNavs } from '../MainNav/SideNavs/SubNavs';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

export const TOKENEXPLORER_PATH = 'tokenexplorer'
export const TOKENEXPLORER_TOKENS_PATH = 'tokens'
export const TOKENEXPLORER_TRANSFERS_PATH = 'transfers'

export const TokenExplorerNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenExplorerNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenExplorerNav:${key}`, interpolate)

    const DASHBOARD = lt('dashboard')
    const TOKENS = lt('tokens')
    const TRANSFERS = lt('transfers')

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();
    const { pathname } = useLocation();
    
    const [selectedItem, setSelectedItem] = useState<string>();

    const splitPathname = pathname.split('/');
    const explorerType = splitPathname[splitPathname.length -2];

    useEffect(() => {
        if (pathname.endsWith(TOKENEXPLORER_PATH)) setSelectedItem(DASHBOARD)
        if (pathname.endsWith(TOKENEXPLORER_TOKENS_PATH) || explorerType === TOKENEXPLORER_TOKENS_PATH) setSelectedItem(TOKENS)
        if (pathname.endsWith(TOKENEXPLORER_TRANSFERS_PATH) || explorerType === TOKENEXPLORER_TRANSFERS_PATH) setSelectedItem(TRANSFERS)
    }, [pathname, node_id, explorerType, DASHBOARD, TOKENS, TRANSFERS])


    const baseEnvPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`

    const historyPusher = (path?: string) => 
        history.push(`${baseEnvPath}/${TOKENEXPLORER_PATH}${path ? `/${path}` : ''}`)

    const tabs = [
        {
            name: DASHBOARD,
            action: () => historyPusher()
        },
        {
            name: TOKENS,
            action: () => historyPusher(TOKENEXPLORER_TOKENS_PATH)
        },
        {
            name: TRANSFERS,
            action: () => historyPusher(TOKENEXPLORER_TRANSFERS_PATH)
        },
    ]

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(baseEnvPath)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{lt('environment')}</Typography>} 
                        disableTypography />
                </ListItem>

                <ListItem>
                    <ListItemText secondary={lt('tokenExplorer')} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>

                <SubNavs list={tabs} selected={true} {...{selectedItem}} />
            </List>
        </div> 
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    backButton: {
        paddingLeft: theme.spacing(1)
    },
    backIcon: {
        minWidth: 0
    }
}));

interface translations {
    dashboard: string
    tokens: string
    transfers: string
    tokenExplorer: string
    environment: string
}
const enTranslations: translations = {
    dashboard: 'Dashboard',
    tokens: 'Tokens',
    transfers: 'Transfers',
    tokenExplorer: 'Token Explorer',
    environment: 'Environment'
}
