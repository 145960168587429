import { NodeResourcesVars } from '../interfaces';
import { gql } from '@apollo/client';

export interface UpdateCordaUserInput {
    password?: string
    roles?: string[]
}

export interface CreateCordaUserInput extends UpdateCordaUserInput {
    username: string
}

export interface CordaUser extends CreateCordaUserInput {

}

export interface CordaPassword {
    password?: string // Only set if the password was generated (not supplied)
}

export interface UpdateCordaRoleInput {
    permissions: string[]
}

export interface CreateCordaRoleInput extends UpdateCordaRoleInput {
    role: string
}

export interface CordaRole extends CreateCordaRoleInput {

}

export interface CordaUsersData {
    cordaUsers: CordaUser[]
}

export interface CordaUserData {
    cordaUser: CordaUser
}

export interface CreateCordaUserData {
    createCordaUser: CordaPassword
}

export interface UpdateCordaUserData {
    updateCordaUser: CordaPassword
}

export interface ResetCordaPasswordData {
    resetCordaPassword: CordaPassword
}

export interface CordaRolesData {
    cordaRoles: CordaRole[]
}

export interface CordaRoleData {
    cordaRole: CordaRole
}

export interface CreateCordaRoleData {
    createCordaRole: string
}

export interface UpdateCordaRoleData {
    updateCordaRole: string
}

export interface CreateCordaUserVars extends NodeResourcesVars {
    user: CreateCordaUserInput
}

export interface CreateCordaRoleVars extends NodeResourcesVars {
    role: CreateCordaRoleInput
}

export interface UpdateCordaUserVars extends NodeResourcesVars {
    id: string,
    user: UpdateCordaUserInput
}

export interface DeleteCordaUserVars extends NodeResourcesVars {
    id: string,
}

export interface ResetCordaPasswordVars extends NodeResourcesVars {
    id: string,
}

export interface UpdateCordaRoleVars extends NodeResourcesVars {
    id: string,
    role: UpdateCordaRoleInput
}

export interface DeleteCordaRoleVars extends NodeResourcesVars {
    id: string,
}

export const CordaUserFields = ` 
    fragment CordaUserFields on CordaUser {
        username
        roles
    }`;

export const CordaRoleFields = ` 
    fragment CordaRoleFields on CordaRole {
        role
        permissions
    }`;

export const CordaPasswordFields = ` 
    fragment CordaPasswordFields on CordaPassword {
        password        
    }`;

export const CordaUsersQuery = gql`
    ${CordaUserFields}
    query getCordaUsers($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        cordaUsers(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id) {
            ...CordaUserFields
        }
    }`;

export const CordaUserQuery = gql`
    ${CordaUserFields}
    query getCordaUser($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        cordaUser(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ...CordaUserFields
        }
    }`;

export const CordaRoleQuery = gql`
    ${CordaRoleFields}
    query getCordaRole($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        cordaRole(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ...CordaRoleFields
        }
    }`;    

export const CordaRolesQuery = gql`
    ${CordaRoleFields}
    query getCordaRoles($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        cordaRoles(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id) {
            ...CordaRoleFields
        }
    }`;

export const CreateCordaUserMutation = gql`
    ${CordaPasswordFields}
    mutation createCordaUser($consortia_id: String!, $environment_id: String!, $node_id: String!, $user: CreateCordaUserInput!) {
        createCordaUser(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, user: $user) {
            ...CordaPasswordFields
        }
    }`;

export const UpdateCordaUserMutation = gql`
    ${CordaPasswordFields}
    mutation updateCordaUser($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!, $user: UpdateCordaUserInput!) {
        updateCordaUser(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id, user: $user) {
            ...CordaPasswordFields
        }
    }`;

export const DeleteCordaUserMutation = gql`
    mutation resetCordaPassword($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        deleteCordaUser(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ok
        }
    }`;

export const ResetCordaPasswordMutation = gql`
    ${CordaPasswordFields}
    mutation resetCordaPassword($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        resetCordaPassword(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ...CordaPasswordFields
        }
    }`;

export const CreateCordaRoleMutation = gql`
    mutation createCordaRole($consortia_id: String!, $environment_id: String!, $node_id: String!, $role: CreateCordaRoleInput!) {
        createCordaRole(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, role: $role) {
            ok
        }
    }`;

export const UpdateCordaRoleMutation = gql`
    mutation updateCordaRole($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!, $role: UpdateCordaRoleInput!) {
        updateCordaRole(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id, role: $role) {
            ok
        }
    }`;

export const DeleteCordaRoleMutation = gql`
    mutation deleteCordaRole($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        deleteCordaRole(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ok
        }
    }`;

