import React, { Dispatch, SetStateAction, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PrecompiledPaste } from '../../../components/ContractManagement/PrecompiledPaste';
import { PrecompiledPasteHelp } from '../../../components/ContractManagement/PrecompiledPasteHelp';
import { MessageSnackbar } from '../../../components/DialogWrappers';
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { CreateStepProps } from '../../../interfaces';

interface Props extends CreateStepProps {
    description: string,
    membershipId: string,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>
};

export const PrecompiledStep2 = ({ description, membershipId, cancelPath, loading, setLoading }: Props) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { contract_id } = useParams<any>();
    
    const [message, setMessage] = useState('');

    const postSave = async (compiledContractId: string) => {
        const splitPathname = pathname.split('/')
        const baseCompilationsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_COMPILED_CONTRACTS_PATH) + 1).join('/')
        history.push(`${baseCompilationsPath}/${compiledContractId}`)
    }

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <PrecompiledPaste {...{cancelPath}} {...{postSave}} isLastStep {...{description}} {...{membershipId}} {...{contract_id}} {...{setMessage}} {...{loading}} {...{setLoading}}/>
            <PrecompiledPasteHelp />
        </>
    )
};
