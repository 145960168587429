import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { EnvironmentData, EnvironmentQuery, EnvironmentStatusData, EnvironmentStatusQuery, NodesData, NodesQuery } from '../../models'
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import PauseIcon from 'mdi-react/PauseIcon';
import ResumeIcon from 'mdi-react/PlayIcon';
import BackupRestoreIcon from 'mdi-react/BackupRestoreIcon';
import UpgradeIcon from 'mdi-react/ArrowUpBoldBoxOutlineIcon';
import ForkUpgradeIcon from 'mdi-react/ArrowUpBoldHexagonOutlineIcon';
import { EditableSettings, EditableSettingItem } from '../../components/DisplaySettings'
import { EnvironmentPauseResume } from '../../components/DialogWrappers/EnvironmentPauseResume';
import { EnvironmentUpgrade } from '../../components/DialogWrappers/EnvironmentUpgrade';
import { EnvironmentForkUpgrade } from '../../components/DialogWrappers/EnvironmentForkUpgrade';
import { UpdateName, DeleteResource } from '../../components/DialogWrappers'
import { EnvironmentResourceVars, ConsortiumResourceVars, EnvironmentResourcesVars} from '../../interfaces';
import { Info } from './Info';
import { EnvironmentChainConfig } from '../../components/DialogWrappers/EnvironmentChainConfig';
import { BackupData, BackupQuery } from '../../models/backup';
import { EnvironmentBackup } from '../../components/DialogWrappers/EnvironmentBackup';

export const Settings: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentSettings:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        loading,
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, EnvironmentResourceVars>(EnvironmentQuery, { 
        variables: {
            ...environmentVariables,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        refetch: refetchEnvironmentStatus,
        data: {
            environmentStatus
        } = { environmentStatus: null }
    } = useQuery<EnvironmentStatusData, ConsortiumResourceVars>(EnvironmentStatusQuery, { 
        variables: {
            ...environmentVariables,
            id: environment_id!
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        refetch: refetchBackup,
        data: {
            backup
        } = { backup: null }
    } = useQuery<BackupData, EnvironmentResourcesVars>(BackupQuery, { 
        variables: {
            ...environmentVariables
        },
        fetchPolicy: 'cache-and-network'
    });

    const { data: { nodes } = { nodes: [] } } = useQuery<
        NodesData,
        EnvironmentResourcesVars
    >(NodesQuery, {
        variables: environmentVariables,
        fetchPolicy: "cache-only",
    });

    // unnecessary to refetch env status on initial load since it will be up to date anyways via the subscription
    // need to refetch the env status however if the environment state re-enters live, or paused
    const isInitialMount = useRef(true);
    const environmentState = environment?.state
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            refetchEnvironmentStatus()
            refetchBackup()
        }
    }, [environmentState, refetchEnvironmentStatus, refetchBackup])

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [pauseResumeDialogOpen, setPauseResumeDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
    const [forkUpgradeDialogOpen, setForkUpgradeDialogOpen] = useState(false);
    const [chainConfigDialogOpen, setChainConfigDialogOpen] = useState(false);
    const [backupDialogOpen, setBackupDialogOpen] = useState(false);

    if (loading || !environment || !environmentStatus) return <CircularProgress />

    const isPaused = environment.state === 'paused';
    const isLive = environment.state === 'live';
    const backupDisabled = !isLive || nodes.find(n => n.role === 'monitor')?.state !== 'started'
    const isArchivedOrLocked = environment.state === 'locked' || environment.state === 'archived';
    let hardForkEIPs : string[] = [];
    const getHardForkEIPs = () => {
        // we have a special case for certain geth environments which may have been hard forked in the past
        // to include only `constantinopleBlock`. For such environments, we will rely on nodemanager migration
        // to apply the EIP during an upgrade and will not be using hard fork
        if (environment.provider === 'geth' &&
            environmentStatus.upgrade.optional_hardfork_eips.length === 1 &&
            environmentStatus.upgrade.optional_hardfork_eips[0] === 'petersburgBlock') {
                return;
        } else if (environment.provider === 'pantheon' &&
            environmentStatus.upgrade.optional_hardfork_eips.length === 1 &&
            environmentStatus.upgrade.optional_hardfork_eips[0] === 'constantinopleFixBlock') {
                return;
        }
        hardForkEIPs = environmentStatus.upgrade.optional_hardfork_eips;
    }
    getHardForkEIPs();
    const preReqEIPs = environmentStatus.upgrade.prereq_hardfork_eips;
    const chainConfig = environmentStatus.upgrade.chain_config;
    const hardForkAndUpgrade = environmentStatus.upgrade.require_hard_fork_upgrade;
    const doesRequirePrefork = (preReqEIPs.length !==0);
    const isHardForkAvailable =  (hardForkEIPs.length !== 0);

    const actionsList: EditableSettingItem[] = [
        {
            icon: <AccountCircleIcon />,
            title: lt('changeName'),
            description: lt('nameDescription'),
            value: environment.name,
            buttonLabel: lt('change'),
            action: () => setUpdateNameDialogOpen(true)
        }
    ]

    if(!isArchivedOrLocked) {
        actionsList.push(
            {
                icon: isPaused ? <ResumeIcon /> : <PauseIcon />,
                title: isPaused ? lt('resumeEnvironment') : lt('pauseEnvironment'),
                description: isPaused ? lt('resumeDescription') : lt('pauseDescription'),
                buttonLabel: isPaused ? lt('resume') : lt('pause'),
                disabledButton: (environment.state === 'pause_pending'),
                action: () => setPauseResumeDialogOpen(true)
            } 
        )
    }

    // Hide upgrade/forkUpgrade/forkAndUpgrade when env is locked/archived
    if(!isArchivedOrLocked) {
        // The only case where we want to hide the `Upgrade` option is if this environment requires a Hard Fork combined with an Upgrade.
        // In this case, the only way to upgrade the environment is by clicking hard fork, so we choose to hide the `Upgrade` option
        if (!hardForkAndUpgrade) {
            actionsList.push({
                icon: <UpgradeIcon />,
                title: lt('upgrade'),
                description: lt('upgradeDescription'),
                value: lt('versionInfo', {
                    version: environmentStatus.upgrade.current_release.version,
                    message: environmentStatus.upgrade.available ? lt('outOfDate') : lt('upToDate')
                }),
                disabledButton: (!environmentStatus.upgrade.available || doesRequirePrefork || hardForkAndUpgrade),
                subvalues: doesRequirePrefork ?
                                {[lt('details')]: [lt('requirePreFork', { eips: environmentStatus.upgrade.prereq_hardfork_eips })] }
                                : {},
                buttonLabel: lt('upgrade'),
                action: () => setUpgradeDialogOpen(true)
            })
        }
        if (environment.isEthereum) {
            actionsList.push({
                icon: <ForkUpgradeIcon />,
                title: hardForkAndUpgrade ? lt('forkAndUpgrade') : lt('forkUpgrade'),
                description: !hardForkAndUpgrade ? lt('forkUpgradeDescription'): lt('hardforkAndUpgradeDescription'),
                disabledButton: (!hardForkAndUpgrade && !isHardForkAvailable) || !isLive,
                buttonLabel: lt('apply'),
                subvalues : {[lt('details')]: [lt('viewEIPs')]},
                subvaluesAction: {
                    label: "View",
                    action: () => setChainConfigDialogOpen(true)
                },
                action: () => setForkUpgradeDialogOpen(true)
            })
            if (environment.provider !== 'polygon-edge') {
                actionsList.push({
                    icon: <BackupRestoreIcon />,
                    title: lt('backup'),
                    description: lt('backupDescription'),
                    buttonLabel: lt('edit'),
                    value: backup?.enabled ? lt('backupEnabledValue', {backup_frequency: backup.backup_frequency}) : lt('disabled'),
                    subvalues: {[lt('backupHistory')]: [lt('mostRecentBackup', { 
                        recentBackupDate: environmentStatus.backup?.latest ? new Date(environmentStatus.backup.latest).toLocaleString() || '' : lt('na')
                    })]},
                    subvaluesAction: {
                        disabled: backupDisabled || !backup?.enabled,
                        label: lt('download'),
                        action: () => window.open(`/api/ui/v2/consortia/${consortium_id}/environments/${environment_id}/backup/status`)
                    },
                    action: () => setBackupDialogOpen(true)
                })
            }
        }
    }

    actionsList.push({
        icon: <DeleteOutlineIcon />,
        title: lt('deleteEnvironment'),
        description: lt('deleteDescription'),
        buttonLabel: lt('delete'),
        action: () => setDeleteDialogOpen(true)
    })

    return (
        <>

            <UpdateName defaultName={environment.name} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <EnvironmentPauseResume environment={environment} open={pauseResumeDialogOpen} setOpen={setPauseResumeDialogOpen} />
            <EnvironmentUpgrade environment={environment} environmentStatus={environmentStatus} open={upgradeDialogOpen} setOpen={setUpgradeDialogOpen} />
            <EnvironmentForkUpgrade environment={environment} environmentStatus={environmentStatus} open={forkUpgradeDialogOpen} setOpen={setForkUpgradeDialogOpen} />
            <DeleteResource name={environment.name} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} closeDialogAfterSave />
            <EnvironmentChainConfig open={chainConfigDialogOpen} setOpen={setChainConfigDialogOpen} chainConfig={chainConfig} optionalChainConfig={hardForkEIPs} />
            <EnvironmentBackup open={backupDialogOpen} setOpen={setBackupDialogOpen} backup={backup} environment={environment} afterSave={refetchBackup} disabled={backupDisabled} />

            <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">
                        {lt('environmentSettings')}
                    </Typography>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                        <EditableSettings
                            header={lt('environmentSettings')}
                            {...{actionsList}} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Info {...{environment}} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    environmentSettings: string,
    changeName: string,
    deleteEnvironment: string,
    pauseEnvironment: string,
    resumeEnvironment: string,
    name: string,
    delete: string,
    change: string,
    pause: string,
    resume: string,
    upgrade: string,
    apply: string,
    details: string,
    pausedDescription: string,
    requirePreFork: string,
    forkUpgrade: string,
    forkAndUpgrade: string,
    nameDescription: string,
    deleteDescription: string,
    resumeDescription: string,
    pauseDescription: string,
    upgradeDescription: string,
    forkUpgradeDescription: string,
    hardforkAndUpgradeDescription: string,
    versionInfo: string,
    outOfDate: string,
    upToDate: string,
    viewEIPs: string
    backup: string
    backupDescription: string
    backupEnabledValue: string
    edit: string
    disabled: string
    backupHistory: string
    download: string
    mostRecentBackup: string
    na: string
}
const enTranslations: translations = {
    environmentSettings: 'Environment Settings',
    changeName: 'Change environment name',
    deleteEnvironment: 'Delete environment',
    pauseEnvironment: 'Pause environment',
    resumeEnvironment: 'Wake environment',
    name: 'Name',
    delete: 'Delete',
    change: 'Change',
    pause: 'Pause',
    resume: 'Wake',
    upgrade: 'Upgrade',
    apply: 'Apply',
    details: 'Details',
    pausedDescription: 'Environment needs to be live to perform hard fork.',
    requirePreFork: '{{eips}} need to be hard forked before upgrading to the latest release',
    forkUpgrade: 'Hard Fork',
    forkAndUpgrade: 'Hard Fork and Upgrade',
    nameDescription: 'Change the name that identifies the environment.',
    deleteDescription: 'Delete the environment.',
    resumeDescription: 'Resume all runtimes in the environment.',
    pauseDescription: 'Pause all runtimes in the environment.',
    upgradeDescription: 'Upgrade the environment to the latest release.',
    forkUpgradeDescription: 'Hard Fork the environment to apply new EIPs.',
    hardforkAndUpgradeDescription: 'Hard Fork the environment to apply new EIPs and Upgrade to the latest release.',
    versionInfo: '{{version}} {{message}}',
    outOfDate: 'out of date',
    upToDate: 'up to date',
    viewEIPs: 'Click to view Currently Applied EIP(s) and new EIP(s) Available.',
    backup: 'Backup',
    backupDescription: 'Schedule environment backups up to 4x per day.',
    backupEnabledValue: 'Enabled {{backup_frequency}}x per day',
    edit: 'Edit',
    disabled: 'Disabled',
    backupHistory: 'Backup History',
    download: 'Download',
    mostRecentBackup: 'Most recent backup: {{recentBackupDate}}',
    na: '(not available)'
}