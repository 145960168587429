import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Grid, CircularProgress, Button, Typography } from "@material-ui/core";
import { DisplayTable } from '../../../components/DisplayWrappers'
import { CREATE_OAUTH_CONFIGURATION_PATH } from '../../../components/MainNav/SideNavs/Security'
import { EnvironmentResourcesVars } from '../../../interfaces'
import { OauthConfigurationsQuery, OauthConfigurationsData } from '../../../models/oauthConfiguration';

export const OauthConfigurations: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsSecurityOauthConfigurations', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsSecurityOauthConfigurations:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id } = useParams<any>();

    const environmentResourcesVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    let {
        loading,
        data: {
            oauthConfigurations
        } = { oauthConfigurations: [] }
    } = useQuery<OauthConfigurationsData, EnvironmentResourcesVars>(OauthConfigurationsQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-and-network' 
    });

    const actionBar = (
        <Grid item>
            <Button color="primary" variant="contained" size="large" onClick={() => history.push(`${pathname}/${CREATE_OAUTH_CONFIGURATION_PATH}/1`)}>
                {lt('newAppCred')}
            </Button>
        </Grid>
    )

    const columnHeaders = [
        lt('id'),
        lt('name'),
        lt('owner'),
        lt('createdAt')
    ]

    // only show my app creds and dont show service or integration app creds
    oauthConfigurations = oauthConfigurations.filter(a => a.membership.isMine)

    const records = oauthConfigurations.map(a => ({
        key: a._id,
        onClick: () => history.push(`${pathname}/${a._id}`),
        columns: [
            {value: a._id ?? '--'},
            {value: a.name ?? '--'},
            {value: a.membership.name ?? '--'},
            {value: new Date(a.created_at).toLocaleDateString()},
        ]
    }))

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('oauthConfiguration')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    {loading ? 
                    <CircularProgress /> :
                    <DisplayTable header={lt('oauthConfiguration')} emptyLabel={lt('noOauth')} description={lt('description')} {...{actionBar}} {...{columnHeaders}} {...{records}} />
                    }
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    oauthConfiguration: string,
    description: string,
    newAppCred: string,
    id: string,
    name: string,
    createdAt: string,
    owner: string,
    noOauth: string
}
const enTranslations: translations = {
    oauthConfiguration: 'OAuth Configurations',
    description: 'OAuth Resource Server Configurations for authorizing applications to connect to your nodes via the Blockchain Application Firewall (BAF) with JWT tokens signed by an OAuth Authorization Server.',
    newAppCred: 'New Configuration',
    id: 'ID',
    name: 'Name',
    owner: 'Owner',
    createdAt: 'Created at',
    noOauth: 'No OAuth Configuration has been created.'
}
