import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { ConsortiaData, ConsortiaQuery, UserMembershipsData, UserMembershipsQuery } from '../../models';
import { NavPopoverItem } from './SideNavs/NavPopoverItem'

const ConsortiumPicker = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConsortiumPicker', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConsortiumPicker:${key}`, interpolate)

    const history = useHistory()
    const { consortium_id, org_id } = useParams<any>();

    let { 
        data: { 
            consortia
        } = { consortia: [] } 
    } = useQuery<ConsortiaData>(ConsortiaQuery, { fetchPolicy: 'cache-only' });

    const { 
        data: { 
            memberships
        } = { memberships: [] } 
    } = useQuery<UserMembershipsData>(UserMembershipsQuery);

    // filter out consortia we dont have a membership to. cant use m.is_mine up at this level since the memberships query is not refreshed when org is switched.
    consortia = consortia.filter(c => memberships.some(m => m.consortia_id === c._id && m.org_id === org_id))

    const consortium = consortia.find(c => c._id === consortium_id)!

    let popoverItems = consortia && consortia.filter(entry => entry._id !== consortium._id).map((c) => ({
        name: c.name,
        action: () => history.push(`/orgs/${org_id}/consortia/${c._id}`),
        divider: false
    }))

    if (popoverItems.length) {
        popoverItems[popoverItems.length - 1].divider = true
    }

    popoverItems.push({
        name: lt('addBusinessNetwork'),
        action: () => history.push(`/orgs/${org_id}/consortia/create/1`),
        divider: false
    })

    const item = {
        name: consortium.name, 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />
};

interface translations {
    addBusinessNetwork: string
}
const enTranslations: translations = {
    addBusinessNetwork: 'Add Business Network'
}

export { ConsortiumPicker };

