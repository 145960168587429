import React, { useState, useEffect, useCallback } from 'react';
import { FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import MonitorStarIcon from 'mdi-react/MonitorStarIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { ConsortiumResourcesVars, EnvironmentResourcesVars } from '../../interfaces';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars } from '../../models';
import { DisplayTable } from '../../components/DisplayWrappers';
import { MembershipSelector } from '../../components/FormControls/MembershipSelector';
import { Channel, UpdateChannelVars, UpdateChannelMutation, ChannelsData, ChannelsQuery } from '../../models/channels';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    existingMembers: string[],
    selectedMembers: string[],
    setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>
    channel: Channel
}

export const AddMemberToChannel = ({ channel, open, setOpen, existingMembers, selectedMembers, setSelectedMembers }: Props) => {
    const { t, i18n } = useTranslation();

    i18n.addResourceBundle('en', 'AddMemberToChannel', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddMemberToChannel:${key}`, interpolate)

    const { consortium_id, environment_id, channel_id } = useParams<any>();
    const envResourceVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }
    const [member, setMember] = useState("");
    const [message, setMessage] = useState("");
    const [records, setRecords] = useState<DisplayTableRecord[]>([]);
    const [consortiumMemberships, setConsortiumMemberships] = useState([{_id: "sys--mon", org_name:"System", is_mine: false}]);

    const [updateChannel] = useMutation<Channel, UpdateChannelVars>(UpdateChannelMutation);

    const { 
        loading: membershipsLoading,
        data: { 
            consortiumMemberships : cm
        } = { consortiumMemberships: [] } 
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { fetchPolicy: 'cache-first' });

    // we dont get a channels DB change event for a member list update, so refetch it manually
    const [getChannels] = useLazyQuery<ChannelsData, EnvironmentResourcesVars>(ChannelsQuery, {
        variables: {
            consortia_id: consortium_id,
            environment_id: environment_id,
        },
        fetchPolicy: 'network-only'
    });

    const reset = useCallback(() => {
        setSelectedMembers(existingMembers);
    }, [existingMembers, setSelectedMembers])

    useEffect(()=> {
        if (open) {
            reset()
        }
    }, [open, reset])

    useEffect(()=> {
        if (consortiumMemberships.length === 1) {
            setConsortiumMemberships(memberships => [...memberships, ...cm.map((m) => ({_id: m._id, org_name: m.org_name, is_mine: m.is_mine}))]);
        }
    }, [cm, consortiumMemberships])

    const columnHeaders = [
        lt('name'),
        ""
    ]

    const removeMember = (memberId: string) => {
        const updatedSelectedMembers = selectedMembers.filter((m) => m !== memberId);
        setSelectedMembers(updatedSelectedMembers);
    }
    
    const updateRecords = () => {
        let updatedRecords = selectedMembers.map((selectedMemberId) => {
            const selectedMember = consortiumMemberships.find(m => m._id === selectedMemberId) || {org_name: lt('system')};
            const memberName = selectedMember.org_name;
            const disableRemove = channel.membership_id === selectedMemberId || (!featureToggles?.removeChannelMember && (existingMembers.includes(selectedMemberId)))
            return {
                key: `${selectedMemberId}`,
                columns: [
                    {    
                        value: <Grid item container spacing={3} alignItems="center" wrap="nowrap">
                                <Grid item>{selectedMemberId === 'sys--mon' ? <MonitorStarIcon></MonitorStarIcon> : <AccountCircleOutlineIcon></AccountCircleOutlineIcon>}</Grid>
                                <Grid item><Typography variant="body2">{memberName}</Typography></Grid>
                            </Grid>,
                    },
                    {
                        value: <IconButton disabled={disableRemove} edge="start" color="inherit" onClick={()=>{removeMember(`${selectedMemberId}`)}}><TrashCanOutlineIcon /></IconButton> 
                    }
                ]}
        });
        setRecords(updatedRecords);
    }

    if (records.length !== selectedMembers.length && !membershipsLoading) {
        updateRecords();
    }

    const allMembersSelected = selectedMembers.length === consortiumMemberships.length;
    const disabled = allMembersSelected || selectedMembers.some(m => m === member)

    const addMember = async () => {
        const updatedSelectedMembers = [...selectedMembers, member];
        setSelectedMembers(updatedSelectedMembers);
        setMember('')
    }

    const disableReset = () => {
        const hasChanges = existingMembers.some(m => !selectedMembers.includes(m)) || selectedMembers.some(m => !existingMembers.includes(m))
        return !hasChanges
    }
    
    const content = (
        <>
               <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <MembershipSelector includeAllOrgsMemberships excludedMembershipIds={selectedMembers} membershipId={selectedMembers.length === consortiumMemberships.length ? "" : member} includeSysMon setMembershipId={setMember} />
                </Grid>
                <Grid item container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Button variant="outlined" {...{disabled}} onClick={addMember} color='primary'>
                            {lt('add')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" disabled={disableReset()} onClick={reset} size='small'>
                            {lt('reset')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box border={1} borderColor={"#adabab"}>
                        <DisplayTable columnHeaders={columnHeaders} records={records}/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );

    const updater = () => {
        const updateVars = { ...envResourceVars, ...{ id: channel_id! } }
        return updateChannel({
            variables: {...{ channel: { members: selectedMembers } }, ...updateVars}
        }).then(() => getChannels()).then(() => {})
    } 

    return (
        <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog dataTestId="addIdentity"
            {...{ open }}
            {...{ setOpen }}
            header={lt('editMembers')}
            saveDisabled={selectedMembers.length === 0 || disableReset()}
            controlsWrapper={content}
            onSave={updater}
            successMessage={lt('confirmation')}
            noScroll={true}
            closeDialogAfterSave
            cancelText={lt('cancel')} /></>
    )
};

interface translations {
    editMembers: string
    cancel: string
    add: string
    system: string
    name: string
    confirmation: string
    reset: string
}
const enTranslations: translations = {
    editMembers: 'Edit Channel Members',
    cancel: 'Cancel',
    add: 'Add',
    system: 'System',
    name : 'Membership Name',
    confirmation: 'Channel members are being updated. It will take a few moments for the changes to be applied.',
    reset: 'Reset',
}
