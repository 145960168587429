import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step4HelpFabric = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep4HelpFabric', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep4HelpFabric:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Environment-MinReqs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpStateDatabaseDesc1')}
            bullet4={lt('helpStateDatabaseDesc2')}
            finalInfo={lt('helpMonitorNode')}
            docLink="https://help.kaleido.io/hc/en-us/articles/11246011431821-How-many-nodes-should-I-use-"
        />
    )
};


interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpMonitorNode: string,
    helpStateDatabaseDesc1: string,
    helpStateDatabaseDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Minimum Recommended Configuration',
    helpDescription: 'For reliable operation of your blockchain, Kaleido strongly recommends a minimum of 3 orderer nodes for every channel. Furthermore, please specify which Peer State Database to be used:',
    helpDesc1: '2 or more user-provided orderer nodes + system monitor node, or',
    helpDesc2: '3 or more user-provided orderer nodes',
    helpMonitorNode: '* The monitor node is free and automatically a part of all environments.',
    helpStateDatabaseDesc1: 'LevelDB is the default database and stores chaindata as simple key-value pairs.',
    helpStateDatabaseDesc2: 'CouchDB is an alternative choice that allows you to model data on the ledger as JSON and use queries against data values rather than simple keys.',



}