import React, { useEffect } from 'react';
import { Grid} from '@material-ui/core';
import { Service, ServiceQuery, ServiceData } from '../../models';
import { Config } from '../../models/configs';
import { useQuery, useLazyQuery } from '@apollo/client';
import { EnvironmentResourceVars } from '../../interfaces';
import { useParams } from 'react-router-dom';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { ServiceStatusQuery, ServiceStatusData, ServiceConfigVersions } from '../../models/servicesStatus';

interface Props {
    runtime: Service,
    config: Config,
    setRuntimesToApply: React.Dispatch<React.SetStateAction<string[]>>,
    removeRuntime:  (runtimeId: string) => void,
    isApplied: boolean,
    setIsApplied: React.Dispatch<React.SetStateAction<boolean>>,
    isRestarting: boolean
    setIsRestarting: React.Dispatch<React.SetStateAction<boolean>>
}

export const ServiceStatus = ({ runtime, config, removeRuntime, setRuntimesToApply,
    isApplied, setIsApplied, isRestarting, setIsRestarting}: Props) => {
    

    const { environment_id, consortium_id, config_id } = useParams<any>();

    const queryVariables = {
        environment_id: environment_id!,
        consortia_id: consortium_id!,
        id: runtime._id
    }

    const {
        data: { 
            service
        } = { service: null } 
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, { 
        variables: { 
            ...queryVariables
        },
        fetchPolicy: 'cache-only'
    });

    const [getServiceStatus, {
        data : {
            serviceStatus
        } = {serviceStatus: null}
    }] = useLazyQuery<ServiceStatusData, EnvironmentResourceVars>(ServiceStatusQuery, {
        variables: {
            ...queryVariables,
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (service) {
            if(service.state === 'started') {
                setIsRestarting(false);
                getServiceStatus()
            } else {
                setIsRestarting(true);
            }
        }
    }, [service, getServiceStatus, setIsRestarting]);

    useEffect(() => {
        if (config.type === 'cloudhsm') {
            setIsApplied(true);
        } else if (serviceStatus?.configVersions) {
            if(serviceStatus.configVersions[config.type as keyof ServiceConfigVersions]?.rev === config._revision && serviceStatus.configVersions[config.type as keyof ServiceConfigVersions]?.id === config._id  ){
                setIsApplied(true);
                removeRuntime(runtime._id);
            } else {
                setRuntimesToApply(c => c.find(entry => entry === runtime._id) ? c : [...c, runtime._id]) // Need to find out why this re runs
            }
        }
    }, [serviceStatus, config_id, config, setRuntimesToApply, runtime, removeRuntime, setIsApplied]);


    const statusText = () => {
        if (isRestarting) return <ResourceStateChip state={runtime.state} />;
        return isApplied ? <ResourceStateChip state='applied' /> : <ResourceStateChip state='notApplied' />
    }

    return (
        <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
                {statusText()}
            </Grid>
        </Grid>
    )
};
