import { useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, makeStyles, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { DisplayTable } from '../../components/DisplayWrappers';
import { OPS_MANAGE_NODE_PATH, OPS_MANAGE_ORG_PATH, OPS_MANAGE_SERVICE_PATH, OPS_PATH } from '../../components/OpsNav/OpsNav';
import { OpsQueryVars, Region } from '../../interfaces';
import { Consortium, Environment, Node, OpsConsortiaData, OpsConsortiaQuery, OpsEnvironmentsData, OpsEnvironmentsQuery, OpsMembershipsData, OpsMembershipsQuery, OpsNodesData, OpsNodesQuery, OpsOrganizationData, OpsOrganizationQuery, OpsOrganizationsData, OpsOrganizationsQuery, OpsServicesData, OpsServicesQuery, OpsUpdateConsortiumMutation, OpsUpdateConsortiumVars, OpsUpdateEnvironmentMutation, Organization, Service, UpdateEnvironmentVars } from '../../models';

interface Props {
    regions?: Region[]
}

export const OpsResources: React.FC<Props> = ({regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsResources', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsResources:${key}`, interpolate)
    const history = useHistory();
    
    const classes = useStyles();

    const {ops_org_id} = useParams<any>();
    
    const [message, setMessage] = useState('');
    const [orgsByID, setOrgsByID] = useState<{[id: string]: Organization}>({});
    const [allOrgIds, setAllOrgIds] = useState<string[]>([]);
    const [orgIDsForQuery, setOrgIDsForQuery] = useState<string[]>([]);

    const [opsUpdateEnvironment, {loading: envUpdating}] = useMutation<Environment, UpdateEnvironmentVars>(OpsUpdateEnvironmentMutation)
    const [opsUpdateConsortium, {loading: consUpdating}] = useMutation<Consortium, OpsUpdateConsortiumVars>(OpsUpdateConsortiumMutation)

    const {
        data: {
            opsOrganization: parentOrg
        } = { opsOrganization: null },
        error: orgError,
        loading: loadingParentOrg
    } = useQuery<OpsOrganizationData>(OpsOrganizationQuery, {
        skip: !ops_org_id,
        variables: { id: ops_org_id },
        fetchPolicy: 'no-cache'
    });
    
    const {
        data: {
            opsOrganizations: agreementOrgs
        } = { opsOrganizations: [] },
        error: agreementOrgsError,
        loading: loadingAgreementOrgs
    } = useQuery<OpsOrganizationsData, OpsQueryVars>(OpsOrganizationsQuery, {
        skip: loadingParentOrg || !parentOrg?.billing_account?.agreement,
        variables: { query: `billing_account.agreement=${parentOrg?.billing_account?.agreement}`},
        fetchPolicy: 'no-cache'
    });

    const agreementUniqueOrgs = parentOrg ? [parentOrg,...(agreementOrgs || [])].reduce<string[]>((v, o) => v.includes(o._id) ? v : v.concat(o._id), []) : [];
    const {
        data: {
            opsOrganizations: childOrgs
        } = { opsOrganizations: [] },
        error: childOrgsError,
        loading: loadingChildOrgs
    } = useQuery<OpsOrganizationsData, OpsQueryVars>(OpsOrganizationsQuery, {
        skip: loadingAgreementOrgs || loadingParentOrg,
        variables: { query:  agreementUniqueOrgs.map(o => `master_org=${o}`).join('&')},
        fetchPolicy: 'no-cache'
    });

    const loadingOrgs = loadingParentOrg || loadingAgreementOrgs || loadingChildOrgs;
    const orgsById: {[id: string]: Organization} = useMemo(() => ({}), []);
    if (parentOrg) orgsById[parentOrg._id] = parentOrg;
    for (const o of agreementOrgs) orgsById[o._id] = o;
    for (const o of childOrgs) orgsById[o._id] = o;
    useEffect(() => {
        if (!loadingOrgs) {
            const newAllOrgs = Object.keys(orgsById);
            if (newAllOrgs.join(',') !== allOrgIds.join(',')) {
                setAllOrgIds(newAllOrgs);
                setOrgsByID(orgsById);
                setOrgIDsForQuery(newAllOrgs);
            }
        }
    }, [orgsById, allOrgIds, loadingOrgs]);

    const {
        data: {
            opsMemberships: memberships
        } = { opsMemberships: [] },
        error: membershipsError,
        loading: membershipsLoading,
    } = useQuery<OpsMembershipsData, OpsQueryVars>(OpsMembershipsQuery, {
        skip: !orgIDsForQuery.length,
        variables: {
            query: orgIDsForQuery.map(id => `org_id[]=${id}`).join('&'),
        },
        fetchPolicy: 'no-cache'
    });

    const uniqueConsortia = useMemo(() => (
        memberships.reduce<string[]>((v, m) => v.includes(m.consortia_id) ? v : v.concat([m.consortia_id]), [])
    ), [memberships]);

    const {
        data: {
            opsConsortia: consortia
        } = { opsConsortia: [] },
        error: consortiaError,
        loading: consortiaLoading,
        refetch: refetchConsortia
    } = useQuery<OpsConsortiaData, OpsQueryVars>(OpsConsortiaQuery, {
        skip: !uniqueConsortia.length,
        variables: { query: uniqueConsortia.map(c => `_id[]=${c}`).join('&') },
        fetchPolicy: 'no-cache'
    });

    const {
        data: {
            opsNodes: nodes
        } = { opsNodes: [] },
        error: nodesError,
        loading: nodesLoading,
    } = useQuery<OpsNodesData, OpsQueryVars>(OpsNodesQuery, {
        skip: !memberships.length,
        variables: { query: memberships.map(m => `membership_id[]=${m._id}`).join('&') },
        fetchPolicy: 'no-cache'
    });

    const {
        data: {
            opsServices: services
        } = { opsServices: [] },
        error: servicesError,
        loading: servicesLoading,
    } = useQuery<OpsServicesData, OpsQueryVars>(OpsServicesQuery, {
        skip: !memberships.length,
        variables: { query: memberships.map(m => `membership_id[]=${m._id}`).join('&') },
        fetchPolicy: 'no-cache'
    });

    const uniqueEnvironments = useMemo(() => (
        [...nodes,...services].reduce<string[]>((v, r) => v.includes(r.environment_id) ? v : v.concat([r.environment_id]), [])
    ), [nodes,services]);

    const {
        data: {
            opsEnvironments: environments
        } = { opsEnvironments: [] },
        error: environmentsError,
        loading: environmentsLoading,
        refetch: refetchEnvironments,
    } = useQuery<OpsEnvironmentsData, OpsQueryVars>(OpsEnvironmentsQuery, {
        skip: !uniqueEnvironments.length,
        variables: { query: uniqueEnvironments.map(e => `_id[]=${e}`).join('&') },
        fetchPolicy: 'no-cache'
    });

    const resourcesLoading = membershipsLoading || consortiaLoading || nodesLoading || servicesLoading || environmentsLoading;

    const fetchError = orgError || agreementOrgsError || childOrgsError || membershipsError || consortiaError || nodesError || servicesError || environmentsError;
    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    if (!parentOrg || !childOrgs || loadingOrgs) return <CircularProgress/>

    const patchConsortiumState = async (c: Consortium, state: 'setup' | 'archived') => {
        try {
            await opsUpdateConsortium({
                variables: {
                    id: c._id,
                    consortium: {
                        state
                    }
                }
            });
            refetchConsortia()
        } catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    }

    const patchEnvState = async (e: Environment, state: 'live' | 'paused' | 'archived' | 'locked') => {
        try {
            await opsUpdateEnvironment({
                variables: {
                    consortia_id: e.consortia_id,
                    id: e._id,
                    environment: {
                        state
                    }
                }
            });
            refetchEnvironments()
        } catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    }

    const membershipFor = (n : Node | Service) => {
        const m = memberships.find(m => m._id === n.membership_id);
        if (!m) return '';
        return `${m.org_name ? m.org_name.substring(0,64) : ''} (${m.org_id}/${m._id})`
    }

    const envPanel = (e: Environment) => {
        const envNodes = nodes.filter(n => n.environment_id === e._id)
        const envServices = services.filter(s => s.environment_id === e._id);
        return <Grid item container direction="column" spacing={2} key={e._id}>
            <Grid item container alignItems='center' spacing={2}>
                <Grid item>
                    <Typography variant="h6">{lt('environment', e)}</Typography>
                </Grid>
                <Grid item>
                    {(e.state === 'paused' || e.state === 'archived') && <Button disabled={envUpdating} color="primary" onClick={() => patchEnvState(e, 'live')}>{e.state === 'archived' ? lt('unarchiveLive') : lt('resume')}</Button>}
                    {e.state !== 'paused' && <Button disabled={envUpdating} color="primary" onClick={() => patchEnvState(e, 'paused')}>{e.state === 'archived' ? lt('unarchivePaused') : e.state === 'locked'? lt('unlockPaused'):lt('pause')}</Button>}
                </Grid>
                {e.state !== 'archived' && <Grid item>
                    <Button disabled={envUpdating} color="primary" onClick={() => patchEnvState(e, 'archived')}>{lt('archive')}</Button>
                </Grid>}
            </Grid>
            <Grid item>
                {envNodes.length ? <DisplayTable
                    records={envNodes.map(n => ({
                        columns: [{value: n._id},{value:membershipFor(n)},{value:n.provider},{value:n.size},{value:n.state},{value:n.name},{value:n.created_at},{value:n.updated_at||''}],
                        onClick: () => history.push(`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/${ops_org_id}/${OPS_MANAGE_NODE_PATH}/${n._id}`)
                    }))}
                    columnHeaders={[
                        lt('nodeId'),
                        lt('membership'),
                        lt('type'),
                        lt('size'),
                        lt('state'),
                        lt('name'),
                        lt('createdAt'),
                        lt('updatedAt'),
                    ]}/> : undefined}
            </Grid>
            <Grid item>
                {envServices.length ? <DisplayTable
                    records={envServices.map(s => ({
                        columns: [{value: s._id},{value:membershipFor(s)},{value:s.service},{value:s.size},{value:s.state},{value:s.name},{value:s.created_at},{value:s.updated_at||''}],
                        onClick: () => history.push(`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/${ops_org_id}/${OPS_MANAGE_SERVICE_PATH}/${s._id}`)
                    }))}
                    columnHeaders={[
                        lt('serviceId'),
                        lt('membership'),
                        lt('type'),
                        lt('size'),
                        lt('state'),
                        lt('name'),
                        lt('createdAt'),
                        lt('updatedAt'),
                    ]}/> : undefined}
            </Grid>
        </Grid>
    };

    return <Grid container spacing={2} direction="column">
        <Grid item>
            <Paper className={classes.paper}>
                <Grid item>
                    <DisplayTable
                        records={[parentOrg, ...agreementOrgs.filter(o => o._id !== parentOrg._id), ...childOrgs].map(o => ({
                            columns: [{value: o._id},{value: o.master_org || ''},{value: o.billing_account?.agreement || ''},{value: o.name},{value:o.created_at},{value:o.updated_at||''}]
                        }))}
                        columnHeaders={[
                            lt('orgId'),
                            lt('masterOrg'),
                            lt('agreement'),
                            lt('name'),
                            lt('createdAt'),
                            lt('updatedAt'),
                        ]}/>
                </Grid>
            </Paper>
        </Grid>
        <Grid item container spacing={2} alignItems="center" alignContent="stretch">
            <Grid item xs>
                <FormControl fullWidth disabled={resourcesLoading}>
                    <InputLabel variant="outlined">{lt('scope')}</InputLabel>
                    <Select
                        value={orgIDsForQuery} onChange={event => setOrgIDsForQuery(event.target.value as string[])}
                        renderValue={(selected) => {
                            return selected ? (selected as string[]).join(',') : '';
                        }}
                        variant="outlined"
                        multiple
                    >
                        {Object.values(orgsByID).map(o => <MenuItem key={o._id} value={o._id}>
                            <Checkbox color="primary" checked={orgIDsForQuery.includes(o._id)} />
                            <ListItemText primary={o.name} secondary={o._id} />
                        </MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        {!orgIDsForQuery ? undefined : resourcesLoading ? <CircularProgress /> : consortia.map(c => <Paper className={classes.paper} key={c._id}>
            <Grid container direction="column" spacing={1}>
                <Grid item container alignContent='center' spacing={2}>
                    <Grid item>
                        <Typography variant="h5">{lt('consortium', c)}</Typography>
                    </Grid>
                    <Grid item>
                        {c.state === 'setup'
                            ? <Button disabled={consUpdating} color="primary" onClick={() => patchConsortiumState(c, 'archived')}>{lt('archive')}</Button>
                            : <Button disabled={consUpdating} color="primary" onClick={() => patchConsortiumState(c, 'setup')}>{lt('unarchive')}</Button>
                        }
                    </Grid>
                </Grid>
                {environments.filter(e => e.consortia_id === c._id).map(e => envPanel(e))}
            </Grid>
        </Paper>)}
        <MessageSnackbar {...{message}} {...{setMessage}} />
    </Grid>;
};

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
}));

interface translations {
    scope: string;
    consortium: string;
    environment: string;
    membership: string;
    orgId: string;
    nodeId: string;
    serviceId: string;
    masterOrg: string;
    agreement: string;
    createdAt: string;
    updatedAt: string;
    type: string;
    size: string;
    state: string;
    name: string;
    allOrgs: string;
    orgOnly: string;
    pause: string;
    resume: string;
    archive: string;
    unarchive: string;
    unarchivePaused: string;
    unarchiveLive: string;
    unlockPaused: string;
}

const enTranslations: translations = {
    scope: 'Scope:',
    consortium: 'Consortium: "{{name}}" ({{_id}}) - {{state}}',
    environment: 'Environment: "{{name}}" ({{_id}}) - {{state}}',
    membership: 'Membership',
    orgId: 'Org ID',
    nodeId: 'Node',
    serviceId: 'Service',
    masterOrg: 'Master Org',
    agreement: 'Agreement',
    createdAt: 'Created',
    updatedAt: 'Updated',
    type: 'Type',
    size: 'Size',
    state: 'State',
    name: 'Name',
    allOrgs: 'Org and agreement/child orgs {{orgs}}',
    orgOnly: 'Org {{_id}} only',
    pause: 'Pause',
    resume: 'Resume',
    archive: 'Archive',
    unarchive: 'Unarchive',
    unarchivePaused: 'Unarchive - paused',
    unarchiveLive: 'Unarchive - live',
    unlockPaused: 'Unlock - paused'
};
