import { useQuery } from '@apollo/client';
import { Grid, Typography } from "@material-ui/core";
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import ArrowUpDownIcon from 'mdi-react/ArrowUpDownIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import RotateRightIcon from 'mdi-react/RotateRightIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { DeleteResource, RestartRuntime, UpdateName } from '../../../../components/DialogWrappers';
import { EditableSettings } from '../../../../components/DisplaySettings';
import { DocumentStoreLimitTranslations, DocumentStorePreferencesData, DocumentStorePreferencesQuery, EnDocumentStoreLimitTranslations, EnRuntimeSizeTranslation, RuntimeSizeTranslation, ServiceData, ServiceQuery } from '../../../../models';
import { UpdateSize } from '../../../ServiceSettings/UpdateSize';
import { UpdatePreference } from './UpdatePreference';

//TODO: Get rid of this component we should use the shared ServiceSettings component that can be extended with service specific settings
export const Settings: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DocStoreSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DocStoreSettings:${key}`, interpolate)

    const { consortium_id, environment_id, service_id } = useParams<any>();

    const {
        data: { 
            service
        } = { service: null } 
    } = useQuery<ServiceData>(ServiceQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: service_id!
        },
        fetchPolicy: 'cache-only'
    });


    const {
        refetch: refetchPreferences,
        data: {
            documentStorePreferences
        } = { documentStorePreferences: null } 
    } = useQuery<DocumentStorePreferencesData>(DocumentStorePreferencesQuery, { 
        variables: { 
            service_id: service_id!
        }
    });

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [updateSizeDialogOpen, setUpdateSizeDialogOpen] = useState(false);
    const [restartDialogOpen, setRestartDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [updatePreferenceOpen, setUpdatePreferenceOpen] = useState(false);

    const actionsList = [
        {
            icon: <AccountCircleIcon />,
            title: lt('changeName'),
            value: service?.name,
            action: () => setUpdateNameDialogOpen(true)
        },
        {
            icon: <ArrowUpDownIcon />,
            title: lt('changeSize'),
            description: lt('changeSizeDescription'),
            value: service ? lt(service.size) : '',
            buttonLabel: lt('change'),
            action: () => setUpdateSizeDialogOpen(true),
        },
        {
            icon: <AccountCircleIcon />,
            title: lt('receiveFolder'),
            value: documentStorePreferences?.receivedDocumentsPath,
            action: () => setUpdatePreferenceOpen(true)
        },
        {
            icon: <RotateRightIcon />,
            title: lt('restartRuntime'),
            action: () => setRestartDialogOpen(true),
            buttonLabel: lt('restart')
        },
        {
            icon: <DeleteOutlineIcon />,
            title: lt('deleteRuntime'),
            action: () => setDeleteDialogOpen(true),
            buttonLabel: lt('delete')
        }
    ]

    const smallDescription = lt('documentstoreSmallDescription')
    const mediumDescription = lt('documentstoreMediumDescription')
    const largeDescription = lt('documentstoreLargeDescription')
    
    return (
        <>
            <UpdateName defaultName={service?.name ?? ''} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <UpdateSize service={service!} open={updateSizeDialogOpen} setOpen={setUpdateSizeDialogOpen}
                {...{smallDescription}} {...{mediumDescription}} {...{largeDescription}} />
            <UpdatePreference serviceId={service_id || ''} open={updatePreferenceOpen} setOpen={setUpdatePreferenceOpen} refetchPreferences={refetchPreferences} defaultName={documentStorePreferences?.receivedDocumentsPath ?? ''} />
            <RestartRuntime name={service?.name ?? ''} open={restartDialogOpen} setOpen={setRestartDialogOpen} />
            <DeleteResource closeDialogAfterSave name={service?.name ?? ''} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />

            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('docStoreSettings')}
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <EditableSettings
                        header={lt('settings')}
                        description={lt('settingsDesc')}
                        imagePath={`${process.env.PUBLIC_URL}/Node_Settings.svg`}
                        {...{actionsList}} />
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends RuntimeSizeTranslation, DocumentStoreLimitTranslations {
    docStoreSettings: string,
    settings: string,
    settingsDesc: string,
    changeName: string,
    receiveFolder: string,
    restartRuntime: string,
    deleteRuntime: string,
    delete: string
    restart: string
    changeSize: string
    changeSizeDescription: string
    change: string
}
const enTranslations: translations = {
    ...EnDocumentStoreLimitTranslations,
    ...EnRuntimeSizeTranslation,
    docStoreSettings: 'Document Exchange Settings',
    settings: 'Settings',
    settingsDesc: 'You can rename this runtime, as well as change it\'s size. Restarting the runtime can be a decent troubleshooting option. The runtime can also be permanently deleted.',
    changeName: 'Change Name',
    receiveFolder: 'Receive Folder',
    restartRuntime: 'Restart Runtime',
    deleteRuntime: 'Delete Runtime',
    delete: 'Delete',
    restart: 'Restart',
    changeSize: 'Change runtime size',
    changeSizeDescription: 'Change the size of this runtime to increase CPU, Memory, and other runtime specific limits.',
    change: 'Change'
}