import React from 'react';
import { Kpi } from '../../components/DisplayWrappers'
import { useTranslation } from 'react-i18next';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TimerOutlineIcon from 'mdi-react/TimerOutlineIcon';
import ChartAreasplineVariantIcon from 'mdi-react/ChartAreasplineVariantIcon';

interface Props {
    data: {
        maxBPM?: number,
        minBPM?: number,
        avgBPM?: number,
        avgBTime?: number,
        avgTPM?: number,
        totalPending?: number
    }
}

export const BlockchainKpis = ({
        data: {
            maxBPM,
            minBPM,
            avgBPM,
            avgBTime,
            avgTPM,
            totalPending
        }
     } : Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthBlockchainKpis', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentHealthBlockchainKpis:${key}`, interpolate)

    const kpiData = [
        {
            key: 'blockchain-kpi-maxBPM',
            value: (maxBPM !== undefined) ? maxBPM : '-',
            label: lt('maxBPM'),
            icon: <ChartBarIcon/>
        },
        {
            key: 'blockchain-kpi-minBPM',
            value: (minBPM !== undefined) ? minBPM : '-',
            label: lt('minBPM'),
            icon: <ChartBarIcon/>
        },
        {
            key: 'blockchain-kpi-avgBPM',
            value: (avgBPM !== undefined) ? avgBPM : '-',
            label: lt('avgBPM'),
            icon: <ChartBarIcon/>
        },
        {
            key: 'blockchain-kpi-avgBTime',
            value: (avgBTime !== undefined && (avgBTime + ' secs')) || '-',
            label: lt('avgBTime'),
            icon: <TimerOutlineIcon/>
        },
        {
            key: 'blockchain-kpi-avgTPM',
            value: (avgTPM !== undefined && avgTPM) || '-',
            label: lt('avgTPM'),
            icon: <ChartBarIcon/>
        },
        {
            key: 'blockchain-kpi-totalPending',
            value: totalPending || '-',
            label: lt('totalPending'),
            icon: <ChartAreasplineVariantIcon/>
        }
    ];

    return <Kpi data={kpiData} />;
};

interface translations {
    maxBPM: string,
    minBPM: string,
    avgBPM: string,
    avgBTime: string,
    avgTPM: string,
    totalPending: string
}
const enTranslations: translations = {
    maxBPM: "Max Blocks per min",
    minBPM: "Min Blocks per min",
    avgBPM: "Avg Blocks per min",
    avgBTime: "Average Block Time",
    avgTPM: "Avg Tx per min",
    totalPending: "Pending Txs"
}