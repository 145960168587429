import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicItems } from './BasicItems';

export const APP2APP_DESTINATIONS_PATH = 'destinations';
export const APP2APP_CLIENTS_PATH = 'clients';
export const APP2APP_PRODUCER_PATH = 'producer';
export const APP2APP_CREATE_DESTINATION_PATH = 'create';

interface Props {
    baseServicePath: string
}

export const App2AppItems = ({ baseServicePath } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'App2AppItems', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`App2AppItems:${key}`, interpolate)

    const DESTINATIONS = lt('destinations');
    const CLIENTS = lt('connectedClients');
    // This will have to be added back once the App2App UI client work resumes
    // const PRODUCER = lt('producer');

    const serviceSpecificTabs = [
        {
            name: DESTINATIONS,
            path: APP2APP_DESTINATIONS_PATH,
            selectedItem: DESTINATIONS
        },
        {
            name: CLIENTS,
            path: APP2APP_CLIENTS_PATH,
            selectedItem: CLIENTS
        },
        // This will have to be added back once the App2App UI client work resumes
        // {
        //     name: PRODUCER,
        //     path: APP2APP_PRODUCER_PATH,
        //     selectedItem: PRODUCER
        // }
    ]

    return (
        <BasicItems {...{baseServicePath}} {...{serviceSpecificTabs}} />
    );
};

interface translations {
    destinations: string
    connectedClients: string
    producer: String
}

const enTranslations: translations = {
    destinations: 'Destinations',
    connectedClients: 'Connected Clients',
    producer: 'Producer'
}
