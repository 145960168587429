import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Environment } from '../../models'
import { Checkbox, FormControlLabel, Grid, MenuItem, TextField } from "@material-ui/core";
import { FormDialog } from '../../components/DialogWrappers'
import { FormLink } from '../FormControls/FormLink';
import { Backup, BackupFrequency, CreateBackupData, CreateBackupMutation, CreateBackupVars, UpdateBackupData, UpdateBackupMutation } from '../../models/backup';
import { Paywall } from '../FormControls/Paywall';

interface Props {
    environment: Environment
    backup?: Backup | null,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    afterSave?: () => void
    disabled?: boolean
};

export const EnvironmentBackup = ({ environment, backup, open, setOpen, afterSave, disabled }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentBackup', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentBackup:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const [enabled, setEnabled] = useState<boolean>(backup?.enabled || false)
    const [frequency, setFrequency] = useState<BackupFrequency>(backup?.backup_frequency || 1)
    const [resetNeeded, setResetNeeded] = useState<boolean>()

    useEffect(() => {
        if (backup) {
            setEnabled(backup.enabled)
            setFrequency(backup.backup_frequency)
        }
    }, [backup])

    useEffect(() => {
        if (backup) {
            setResetNeeded(backup?.enabled !== enabled)
        } else {
            setResetNeeded(true)
        }
    }, [backup, enabled])

    const [createBackup] = useMutation<CreateBackupData, CreateBackupVars>(CreateBackupMutation)
    const [updateBackup] = useMutation<UpdateBackupData, CreateBackupVars>(UpdateBackupMutation)

    const descriptionLinksWrapper = (
        <Grid item>
            <FormLink isExternal={true} to="https://docs.kaleido.io/kaleido-services/env-backup/" target="_blank">
                {lt('learnMore')}
            </FormLink>
        </Grid>
    )

    const controlsWrapper = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <TextField select fullWidth disabled={disabled}
                    label={lt('frequency')} 
                    value={frequency} 
                    variant="outlined" 
                    onChange={e => setFrequency(e.target.value as unknown as BackupFrequency)}>
                        <MenuItem value={1}>{lt('freqPerDay', { freq: 1 })}</MenuItem>
                        <MenuItem value={2}>{lt('freqPerDay', { freq: 2 })}</MenuItem>
                        <MenuItem value={3}>{lt('freqPerDay', { freq: 3 })}</MenuItem>
                        <MenuItem value={4}>{lt('freqPerDay', { freq: 4 })}</MenuItem>
                </TextField>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary" disabled={disabled}
                            checked={enabled}
                            onChange={e => setEnabled(e.target.checked)}
                            value='enabled'/>
                    }
                    label={lt('enabled')}
                    labelPlacement="end"
                    />             
            </Grid>
            {resetNeeded &&
            <Grid item>
                <Paywall description={lt('resetNote')} />
            </Grid>
            }
            {disabled && !resetNeeded && 
            <Grid item>
                <Paywall description={lt('notRunning')} />
            </Grid>
            }
        </Grid>
    )

    const onSave = async () => {
        const vars = {
            variables: {
                consortia_id: consortium_id,
                environment_id: environment_id,
                backup: {
                    enabled,
                    backup_frequency: frequency
                }
            }
        }

        if (backup) {
            await updateBackup(vars)
        } else {
            await createBackup(vars)
        }

        if (afterSave) {
            afterSave()
        }
    }

    return (
        <FormDialog 
            saveDisabled={disabled}
            open={open}
            setOpen={setOpen}
            header={lt('header', {name: environment.name})} 
            description={' '} 
            onSave={onSave}
            closeDialogAfterSave
            descriptionLinksWrapper={descriptionLinksWrapper}
            controlsWrapper={controlsWrapper} />
    )
};

interface translations {
    header: string,
    learnMore: string
    enabled: string
    frequency: string
    freqPerDay: string
    resetNote: string
    notRunning: string
}
const enTranslations: translations = {
    header: 'Configure backups for this environment ({{name}})',
    learnMore: 'Learn more about automated environment backups',
    enabled: 'Enabled',
    frequency: 'Backup Frequency',
    freqPerDay: '{{freq}}x per day',
    resetNote: 'NOTE: the System Monitor node will be reset to apply these changes',
    notRunning: 'The environment must be live to configure environment backups'
}