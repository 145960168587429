import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { AccountsData, AccountsQuery, ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models'
import { useTranslation } from 'react-i18next';
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { DisplayTable } from '../../components/DisplayWrappers';
import { capitalize } from '../../utils/StringUtils';
import AccountBalanceWalletOutlinedIcon from 'mdi-react/WalletIcon';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';
import { MEMBERSHIP_SIGNING_ACCOUNTS_PATH } from '../../components/MembershipNav/MembershipNav';
import { ConsortiumResourcesVars } from '../../interfaces';
import { DisplayTableColumns } from '../../components/DisplayWrappers/DisplayTableRow';


export const AddressBookAccounts: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'AddressBookAccounts', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookAccounts:${key}`, interpolate)

    const { org_id, consortium_id, environment_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData>(AccountsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    if (loading && accounts.length === 0) {
        return <CircularProgress />
    }

    const columnHeaders = [
        '',
        lt('address'),
        lt('type'),
        lt('owner'),
        lt('created'),
    ];

    const myAccounts: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    const otherAccounts: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    for (const account of accounts) {
        const isEnvironmentResource = account.membership_id === environment_id || account.membership_id === 'sys--mon';
        const consortiumMembership = consortiumMemberships.find(membership => membership._id === account.membership_id);
        const membershipId = isEnvironmentResource ? SYSTEM_MEMBERSHIP : account.membership_id;
        const membershipName = isEnvironmentResource ? lt('system') : consortiumMembership?.org_name ?? '--';
        const record = {
            key: account._id,
            onClick: () => {
                history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membershipId}/${MEMBERSHIP_SIGNING_ACCOUNTS_PATH}/${account._id}`);
            },
            columns: [
                { value: <AccountBalanceWalletOutlinedIcon /> },
                { value: <ShortenedHash address={account._id} /> },
                { value: capitalize(account.account_type) },
                { value: membershipName},
                { value: new Date(account.created_at).toLocaleString() },
            ]
        };
        
        if(consortiumMembership?.is_mine || isEnvironmentResource) {
            myAccounts.push(record);
        } else {
            otherAccounts.push(record);
        }
    }
    
    return (
        <Grid container spacing={3}>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('myAccounts', { count: myAccounts.length })}
                    </Typography>
                </Grid>
                <Grid item>
                    {myAccounts.length > 0 ? <DisplayTable header={lt('accounts')} columnHeaders={columnHeaders} records={myAccounts} />
                        : <Typography>{lt('noAccounts')}</Typography>}
                </Grid>
            </Grid>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('otherAccounts', { count: otherAccounts.length })}
                    </Typography>
                </Grid>
                <Grid item>
                    {otherAccounts.length > 0 ? <DisplayTable header={lt('accounts')} columnHeaders={columnHeaders} records={otherAccounts} />
                        : <Typography>{lt('noAccounts')}</Typography>}
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    signingAccounts: string,
    accounts: string,
    noAccounts: string,
    address: string,
    type: string,
    created: string,
    owner: string,
    myAccounts: string,
    otherAccounts: string,
    system: string
}

const enTranslations: translations = {
    signingAccounts: 'Signing Accounts',
    accounts: 'Accounts',
    noAccounts: 'Member has no accounts',
    address: 'Address',
    type: 'Type',
    created: 'Date created',
    owner: 'Owner',
    myAccounts: 'My/Shared Accounts ({{count}})',
    otherAccounts: 'Other Accounts ({{count}})',
    system: 'System'
}