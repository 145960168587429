import React, { useMemo, useState } from 'react';
import { useParams, useHistory, Redirect, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { BlockQuery, BlockData, BlockVars, BlocksVars, BlocksQuery, BlocksData } from '../../models/ledger';
import { Grid, Typography, Button, CircularProgress, TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings';
import { TransactionsPerBlockQuery, TransactionsPerBlockVars, TransactionsPerBlockData } from '../../models';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_BLOCKS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { TransactionsTable } from '../../components/Transactions/TransactionsTable';

const LIMITLIST = [10, 25];

export const BlockInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BlockInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BlockInfo:${key}`, interpolate);

    const [limit, setLimit] = useState(LIMITLIST[0]);
    const [currentPage, setCurrentPage] = useState(0);
    const [errorMessage, setErrorMessasge] = useState('');

    const { consortium_id, environment_id, block_number, org_id } = useParams<any>();
    const { pathname } = useLocation()

    const redirectUrl = pathname.replace(`${block_number}`, '');

    const history = useHistory();

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        data: {
            blocks 
        } = {blocks: [] }
    } = useQuery<BlocksData, BlocksVars>(BlocksQuery, {
        variables: {
            ...queryVariables,
            limit: 1
        },
        fetchPolicy: 'network-only',
        pollInterval: 6000
    });

    const {
        loading,
        data: {
            block
        } = { block: null}
    } = useQuery<BlockData, BlockVars>(BlockQuery, {
        variables: {
            ...queryVariables,    
            number: block_number!
        }
    });

    const {
        fetchMore,
        data: {
            transactionsPerBlock: transactions
        } = { transactionsPerBlock: []}
    } = useQuery<TransactionsPerBlockData, TransactionsPerBlockVars>(TransactionsPerBlockQuery, {
        variables: {
            ...queryVariables,
            blockNumber: block_number!,
            limit
        }
    });

    const lastBlockNumber = useMemo(() => {
        return blocks[0]?.number
    }, [blocks]);

    if (!loading && !block) return <Redirect to={redirectUrl} />
    if (!block) return <CircularProgress />;

    const historyPusher = (blockNumber: number) => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}/${blockNumber}`)
    }

    const actionButtons = (
        <Grid container alignItems="center" spacing={3} wrap="nowrap">
            <Grid item>
                <Button variant="contained" color="primary" disabled={loading || block.number === 0} onClick={() => historyPusher(block.number - 1)}>
                    {lt('prev')}
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" disabled={loading || lastBlockNumber === block.number} color="primary" onClick={() => historyPusher( block.number + 1)}>
                   {lt('next')}
                </Button>
            </Grid>
        </Grid>
    )

    const copyableList : CopyableSetting[] = [
        {
            title: lt('hash'),
            displayValue: block.hash ?? '',
            copyableValue: block.hash ?? ''
        },{
            title: lt('parentHash'),
            displayValue: block.parentHash ?? '',
            copyableValue: block.parentHash ?? ''
        },{
            title: lt('minedBy'),
            displayValue: <ShortenedHash address={block.miner ?? ''} showFullAddress/>,
            copyableValue: block.miner ?? ''
        },{
            title: lt('height'),
            displayValue: block.number?.toString() ?? '',
            copyableValue: block.number?.toString() ?? ''
        },{
            title: lt('timestamp'),
            displayValue: new Date(block.timestamp ?? '').toLocaleString(),
            copyableValue: block.timestamp ?? ''
        },{
            title: lt('gas'),
            displayValue: block.gasUsed?.toString() ?? '',
            copyableValue: block.gasUsed?.toString() ?? ''
        }
    ]

    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        try {
            if ( page > currentPage && (page * limit) >= transactions.length) {
                await fetchMore({
                    variables: {
                        start: page * limit
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return {transactionsPerBlock: [...prev.transactionsPerBlock, ...fetchMoreResult.transactionsPerBlock]}
                    }
                })
            }
            setCurrentPage(page);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    }

    const transactionsRecords = transactions.slice(currentPage * limit, currentPage * limit + limit);

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCurrentPage(0)
        setLimit(parseInt(event.target.value));
    }

    const Pagination = <TablePagination rowsPerPageOptions={LIMITLIST} component="div" count={block.transactionCount} rowsPerPage={limit} onChangeRowsPerPage={onChangeRowsPerPage} onPageChange={onChangePage} page={currentPage} />

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge} />
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('blocks')}</Typography>
                </Grid>
                <Grid item>
                    <CopyableSettings actionBar={actionButtons} header={lt('block', {block: block_number})} {...{copyableList}} />
                </Grid>
                {transactions.length > 0 && (
                    <Grid item>
                        <TransactionsTable transactions={transactionsRecords} pagination={Pagination} />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

interface translations {
    blocks: string,
    hash: string,
    parentHash: string,
    minedBy: string,
    height: string,
    timestamp: string,
    gas: string,
    transactions: string,
    prev: string,
    next: string,
    block: string
}

const enTranslations: translations = {
    blocks: 'Blocks',
    hash: 'Hash',
    parentHash: 'Parent Hash',
    minedBy: 'Mined By',
    height: 'Height',
    timestamp: 'Timestamp',
    gas: 'Gas',
    transactions: 'Transactions',
    prev: 'Prev',
    next: 'Next',
    block: 'Block ({{block}})'
}