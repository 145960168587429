import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import {
    ConsortiumMembershipsData, ConsortiumMembershipsQuery, ServicesData,
    ServicesQuery, IDRegistryOrgsData, IDRegistryOrgsQuery,
    NodesQuery, NodesData, Membership, EnvironmentData, EnvironmentQuery
} from '../../models'
import { useTranslation } from 'react-i18next';
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { DisplayCard } from '../../components/DisplayWrappers';
import BusinessIcon from 'mdi-react/DomainIcon';
import VerifiedUserIcon from 'mdi-react/ShieldCheckIcon';
import AdjustIcon from 'mdi-react/AdjustIcon';
import MonitorStarIcon from 'mdi-react/MonitorStarIcon';
import { ConsortiumResourcesVars, ServiceResourcesVars, ConsortiumResourceVars } from '../../interfaces'
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import AccountDetailsOutlineIcon from 'mdi-react/AccountDetailsOutlineIcon';

export const AddressBookMemberships: React.FC = () => {
    const { t, i18n } = useTranslation();

    i18n.addResourceBundle('en', 'AddressBookMemberships', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookMemberships:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const idRegistryServiceID = services.find(entry => entry.service === 'idregistry' && entry.state === 'started')?._id;
    const idRegistryServicePresent = services.find(entry => entry.service === 'idregistry');

    const {
        loading,
        data: {
            idRegistryOrgs
        } = { idRegistryOrgs: [] }
    } = useQuery<IDRegistryOrgsData, ServiceResourcesVars>(IDRegistryOrgsQuery, {
        variables: { service_id: idRegistryServiceID! },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryServiceID
    });

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;

    if (loading && idRegistryOrgs.length === 0) {
        return <CircularProgress />
    }

    const myMemberships = consortiumMemberships.filter(entry => entry.is_mine).sort((a, b) => (a.org_name || a.org_id).localeCompare (b.org_name || b.org_id));
    const otherMemberships = consortiumMemberships.filter(entry => !entry.is_mine).sort((a, b) => (a.org_name || a.org_id).localeCompare (b.org_name || b.org_id));

    function generateIdentityLabel (membershipId: string) {
        if (idRegistryServicePresent) {
            if (idRegistryServiceID) {
                return idRegistryOrgs?.find(entry => entry.name.endsWith(membershipId)) ? 
                <ResourceStateChip state="established" /> : 
                <ResourceStateChip state="not_established" />
            } else {
                return <ResourceStateChip state="undetermined" />;
            }
        }
        return <ResourceStateChip state="not_established" />
    }

    function generateItemList(membership: Membership) {
        let items: DisplayCardListItem[] = [
            {
                title: lt('organization'),
                value: membership.owner?.name,
                icon: <BusinessIcon />
            }
        ]
        
        if (!isCorda) {
            items.push({
                title: lt('identity'),
                value: membership?.verification_proof ? 
                (
                    membership.verification_selfsigned ? 
                    <ResourceStateChip state="self_signed" /> : 
                    <ResourceStateChip state="externally_signed" />
                ) : 
                <ResourceStateChip state="none" />,
                icon: <AccountDetailsOutlineIcon />
            },{
                title: lt('onChainIdentity'),
                value: generateIdentityLabel(membership._id),
                icon: <VerifiedUserIcon />
            })
        }
        items.push(
            {
                title: lt('runtimes'),
                value: 
                    `${nodes.filter(entry => entry.role === 'monitor').length.toString()} ${lt('nodes')}, ${services.filter(entry => entry.service_type === 'member' && entry.membership_id === membership._id).length.toString()} ${lt('others')}`,
                icon: <AdjustIcon />
            }
        );
        return items;
    };

    const makeLinkButton = (membership_id: string) => {
        return {
            text: lt('viewMembershipDetails'),
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membership_id}`)
        }
    }

    const otherMembershipsContent = otherMemberships.length ? (
        otherMemberships.map(membership => (
            <Grid key={membership._id} item container xl={3} lg={4} md={6} sm={6} xs={12}>
                <DisplayCard header={membership.org_name} itemList={generateItemList(membership)}
                    linkButton={makeLinkButton(membership._id)}
                />
            </Grid>)
        )) : (
            <></>
            // hiding this empty state for now
            // <EmptyState imageFile='Empty-OtherMemberships.svg' 
            //     title={lt('readyToDecentralize')} 
            //     description={lt('emptyDescription')} 
            //     button={{ text: lt('inviteOrganization'), onClick: () => {}, disabled: true }}
            //     documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/business-networks/' }} />
        )
    
    const systemMembershipItemList = [
        {
            title: lt('system'),
            value: lt('systemOwnedResources'),
            icon: <MonitorStarIcon />
        },
        {
            title: lt('runtimes'),
            value: 
                `${nodes.filter(entry => entry.role === 'monitor').length.toString()} ${lt('nodes')}, ${services.filter(entry => entry.service_type === 'utility').length.toString()} ${lt('others')}`,
            icon: <AdjustIcon />
        },
    ]

    const myMembershipsContent = (
        <>
            {myMemberships.map(membership => (
                <Grid key={membership._id} item container xl={3} lg={4} md={6} sm={6} xs={12}>
                    <DisplayCard header={membership.org_name} itemList={generateItemList(membership)}
                        linkButton={makeLinkButton(membership._id)} />
                </Grid>)
            )}
            <Grid item container xl={3} lg={4} md={6} sm={6} xs={12}>
                <DisplayCard header={lt('system')} itemList={systemMembershipItemList}
                    linkButton={makeLinkButton(SYSTEM_MEMBERSHIP)} />
            </Grid>
        </>
    )

    return (
        <Grid container spacing={3}>
            <Grid item container direction={"column"} spacing={3}>
                <Grid item container>
                    <Typography variant="h5">
                        {lt('myMemberships', { count: myMemberships.length + 1 /* (+1 for the system membership) */ })}
                    </Typography>
                </Grid>
                <Grid item container spacing={3}>
                    {myMembershipsContent}
                </Grid>
            </Grid>
            <Grid item container direction={"column"} spacing={3}>
                <Grid item container>
                    <Typography variant="h5">
                        {lt('otherMemberships', { count: otherMemberships.length })}
                    </Typography>
                </Grid>
                <Grid item container spacing={3}>
                    {otherMembershipsContent}
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    memberships: string
    myMemberships: string
    otherMemberships: string
    organization: string
    none: string
    onChainIdentity: string
    established: string
    notEstablished: string
    runtimes: string
    viewMembershipDetails: string
    undetermined: string
    readyToDecentralize: string
    emptyDescription: string
    emptyDocumentation: string
    inviteOrganization: string
    system: string
    systemOwnedResources: string
    identity: string
    nodes: string
    others: string
}
const enTranslations: translations = {
    memberships: 'Memberships',
    myMemberships: 'My/Shared Memberships ({{count}})',
    otherMemberships: 'Other Memberships ({{count}})',
    organization: 'Organization',
    none: 'None',
    onChainIdentity: 'Registered On-Chain',
    established: 'Established',
    notEstablished: 'Not established',
    runtimes: 'Runtimes',
    viewMembershipDetails: 'View membership details',
    undetermined: 'Undetermined',
    readyToDecentralize: 'Ready to Decentralize Your Network?',
    emptyDescription: 'Decentralize ownership of your consortium by on-boarding an external organization',
    emptyDocumentation: 'Documentation',
    inviteOrganization: 'Invite Organization',
    system: 'System',
    systemOwnedResources: 'Shared environment resources',
    identity: 'Identity',
    nodes: 'Nodes',
    others: 'Others'
}