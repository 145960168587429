import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { AppCredsData, AppCredsQuery } from '../../../models'
import { Grid, CircularProgress, Button, Typography } from "@material-ui/core";
import { DisplayTable } from '../../../components/DisplayWrappers'
import { Create } from './Create'
import { EnvironmentResourcesVars } from '../../../interfaces'

export const AppCreds: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsSecurityAppCreds', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsSecurityAppCreds:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id } = useParams<any>();

    const environmentResourcesVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    let {
        loading,
        data: {
            appCreds
        } = { appCreds: [] }
    } = useQuery<AppCredsData, EnvironmentResourcesVars>(AppCredsQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-only' 
    });

    const [open, setOpen] = useState(false);

    const actionBar = (
        <Grid item>
            <Button color="primary" variant="contained" size="large" onClick={() => setOpen(true)}>
                {lt('newAppCred')}
            </Button>
        </Grid>
    )

    const columnHeaders = [
        lt('id'),
        lt('name'),
        lt('owner'),
        lt('createdAt')
    ]

    // only show my app creds and dont show service or integration app creds
    appCreds = appCreds.filter(a => a.membership.isMine && !a.service_id && !a.integration_id)

    const records = appCreds.map(a => ({
        key: a._id,
        onClick: () => history.push(`${pathname}/${a._id}`),
        columns: [
            {value: a._id},
            {value: a.name},
            {value: a.membership.name},
            {value: new Date(a.created_at).toLocaleString()},
        ]
    }))

    const onCreate = (appCredId?: string) => {
        if (appCredId) {
            history.push(`${pathname}/${appCredId}`)
        }
    }
    
    return (
        <>
            <Create {...{open}} {...{setOpen}} {...{onCreate}} />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('appCreds')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    {loading ? 
                    <CircularProgress /> :
                    <DisplayTable header={lt('appCreds')} emptyLabel={lt('noAppCreds')} description={lt('description')} {...{actionBar}} {...{columnHeaders}} {...{records}} />
                    }
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    appCreds: string,
    description: string,
    newAppCred: string,
    id: string,
    name: string,
    owner: string,
    createdAt: string,
    noAppCreds: string
}
const enTranslations: translations = {
    appCreds: 'App Creds',
    description: 'The runtime APIs exposed by all runtimes in Kaleido are secured with strong generated credentials. We call these Application Credentials (App Creds), and should be generated and managed for each application that accesses your runtime infrastructure.',
    newAppCred: 'New App Cred',
    id: 'ID',
    name: 'Name',
    owner: 'Owner',
    createdAt: 'Created at',
    noAppCreds: 'No App Creds have been created.'
}

