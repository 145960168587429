import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { CREATE_NODES_PATH } from '../../components/NodeNav/NodePicker'
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { ConsortiumZonesData, ConsortiumZonesQuery,
    EnvironmentZonesData, EnvironmentZonesQuery,
    EnvironmentData, EnvironmentQuery, NodeConfigs, NodeRole, FabricCaServiceDetails } from '../../models';
import { ConsortiumResourcesVars, ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces'
import { BLOCKCHAIN_BASE_PATH } from '../../components/MainNav/SideNavs/Blockchain';
import { CircularProgress } from '@material-ui/core';

export const blankNodeConfigs: NodeConfigs = {
    kms_id: '',
    backup_id: '',
    opsmetric_id: '',
    networking_id: ''
};

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreate:${key}`, interpolate)

    type locationState = { 
        name: string, 
        role: string, 
        membershipId: string, 
        zoneId: string, 
        configs: NodeConfigs, 
        createFabricCaName: string, 
        fabricCaServiceDetails: FabricCaServiceDetails 
    }
    const history = useHistory<locationState>()
    const { org_id, consortium_id, environment_id, step, nodeType } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKCHAIN_BASE_PATH}`;
    
    const { 
        location: { 
            state: {
                name: nodeName,
                role: nodeRole,
                membershipId: nodeMembershipId,
                zoneId: nodeZoneId,
                configs: nodeConfigurations,
                createFabricCaName: nodeCreateFabricCaName,
                fabricCaServiceDetails: nodeFabricCaServiceDetails
            } = { name: '',  role: 'validator', membershipId: '', zoneId: '', configs: blankNodeConfigs, createFabricCaName: '', fabricCaServiceDetails: undefined } 
        }
    } = history;

    const { 
        data: { 
            environmentZones
        } = { environmentZones: [] } 
    } = useQuery<EnvironmentZonesData, EnvironmentResourcesVars>(EnvironmentZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });

    const privateZones = environmentZones.filter(z => z.type === 'private' && z.membership?.isMine);
    
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;
    const isMultiRegion = environment?.test_features?.multi_region ?? false

    const step1Fulfilled = step && step >= "2"
    const [name, setName] = useState(step1Fulfilled ? nodeName : '');
    const [role, setRole] = useState(step1Fulfilled ? nodeRole as NodeRole : 'validator');
    const [membershipId, setMembershipId] = useState(step1Fulfilled ? nodeMembershipId : '');
    const [zoneId, setZoneId] = useState(
        step1Fulfilled ? nodeZoneId : (isMultiRegion && consortiumZones.length ? consortiumZones.find(z => z.default)?._id ?? '' : ''));

    const fabricCaFullfilled = isFabric && step >= "2"
    const [createFabricCaName, setCreateFabricCaName] = useState(fabricCaFullfilled ? nodeCreateFabricCaName : '');
    const [fabricCaServiceDetails, setFabricCaServiceDetails] = useState(fabricCaFullfilled ? nodeFabricCaServiceDetails : undefined);
        
    const configurationsFulfilled = isFabric ? (step && step >= "4") : (step && step >= "3")
    const [nodeConfigs, setNodeConfigs] = useState(configurationsFulfilled ? nodeConfigurations as NodeConfigs : blankNodeConfigs)

    const invalidStep = step !== "1" && step !== "2" && step !== "3" && (isFabric && step !== "4");
    const roleIsValid = nodeType === "orderer" || nodeType === "peer";
    const showOrdererHeader = nodeType === 'orderer' || (step >= 2 && role === 'orderer');
    const showPeerHeader = nodeType === 'peer' || (step >= 2 && role === 'peer');

    if (invalidStep || (isFabric && !roleIsValid && nodeType?.length > 0) || (step1Fulfilled && (!nodeName || !nodeMembershipId || (isMultiRegion && !zoneId)))) {
        return <Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${CREATE_NODES_PATH}/1`} />
    }

    if (isFabric && roleIsValid && role !== nodeType) {
        setRole(nodeType as NodeRole);
    }

    if (!environment) return <CircularProgress/>

    const stepComponents = [
        { step: lt('NodeConfigs'), component: <Step1 {...{cancelPath}} {...{isMultiRegion}} {...{name}} {...{setName}} {...{role}} {...{setRole}} 
            {...{membershipId}} {...{setMembershipId}} provider={environment.provider} 
            {...{consortiumZones}} {...{privateZones}} {...{zoneId}} {...{setZoneId}} /> }
    ]
    // Fabric specific step
    if (isFabric) stepComponents.push(
        { step: lt('ca'), component: <Step2 {...{cancelPath}} {...{name}} {...{membershipId}} {...{zoneId}} {...{role}} 
            {...{createFabricCaName}} {...{setCreateFabricCaName}} {...{fabricCaServiceDetails}} {...{setFabricCaServiceDetails}} /> },
    );
    stepComponents.push(
        { step: lt('configurations'), component: <Step3 {...{cancelPath}} {...{name}} {...{membershipId}} {...{zoneId}} {...{nodeConfigs}} {...{setNodeConfigs}} {...{isCorda}} {...{isFabric}} {...{role}} 
            {...{createFabricCaName}} {...{fabricCaServiceDetails}} /> },
        { step: lt('nodeSettings'), component: <Step4 {...{cancelPath}} {...{name}} {...{role}} {...{membershipId}} {...{zoneId}} 
            {...{createFabricCaName}} {...{fabricCaServiceDetails}}
            {...{consortiumZones}} {...{privateZones}} {...{environmentZones}} {...{nodeConfigs}} provider={environment.provider} consensusType={environment.consensus_type}/> }
    );
    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt(showOrdererHeader ? 'createOrdererNode' : showPeerHeader ? 'createPeerNode' : 'createNode')} 
        stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createNode: string,
    createPeerNode: string,
    createOrdererNode: string,
    NodeConfigs: string,
    configurations: string,
    nodeSettings: string,
    ca: string,
}
const enTranslations: translations = {
    createNode: 'Create a Node',
    createPeerNode: 'Create a Peer Node',
    createOrdererNode: 'Create an Orderer Node',
    NodeConfigs: 'Node Details',
    configurations: 'Configurations',
    nodeSettings: 'Node Settings',
    ca: 'Certificate Authority',
}