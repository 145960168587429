import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FireflyCreateNodeStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`FireflyCreateNodeStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Firefly.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/kaleido-platform/firefly/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string
}
const enTranslations: translations = {
    helpHeader: 'FireFly SuperNode Details',
    helpDescription: 'Hyperledger FireFly SuperNodes are a complete stack for building and scaling web3 applications. Each Kaleido membership may provision a single FireFly SuperNode.',
    helpDesc1: `Select the membership which will own this FireFly SuperNode. Here, you may choose an existing membership, or create a new one.`,
    helpDesc2: 'Choose a unique name for your FireFly SuperNode.',
    helpDesc3: 'Select the token plugin to use. This must be the same for all FireFly SuperNodes in the environment. ERC20_ERC721 is the recommended default.'
}