import React from 'react';
import { useTranslation } from 'react-i18next';
import { Consortium, EnvironmentState, EnvironmentProviderTranslations, EnEnvironmentProviderTranslations } from '../../models'
import { Kpi, DisplayGridWrapper, DisplayCard } from '../../components/DisplayWrappers'
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ConsortiumResourcesVars, LinkButtonProps } from '../../interfaces';
import { ConsortiumWrapperResult, ConsortiumWrapperQuery } from '../../queries/ConsortiumWrapper';
import { Grid, makeStyles, CircularProgress } from '@material-ui/core';
import LanIcon from 'mdi-react/LanIcon';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import { Dotdotdot } from '../../components';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'

interface Props {
    consortium: Consortium
};

export const Network = ({ consortium }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LandingDashboardNetwork', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LandingDashboardNetwork:${key}`, interpolate)

    const history = useHistory()
    const { org_id } = useParams<any>()

    const viewNetworkLink = `/orgs/${org_id}/consortia/${consortium._id}`

    const { 
        loading,
        data: { 
            environments,
            consortiumMemberships, 
            consortiumZones
        } = { environments: [], consortiumMemberships: [], consortiumZones: [] } 
    } = useQuery<ConsortiumWrapperResult, ConsortiumResourcesVars>(ConsortiumWrapperQuery,
        { 
            variables: { consortia_id: consortium._id }, 
            fetchPolicy: "cache-first" // lets just do this once for now
        });

    const zone = consortiumZones.find(z => z.default)!
    const regionDisplay = zone ? `${zone.cloud.toUpperCase()}: ${zone.region}` : ''
    const flag = zone ? <img className={classes.flag} src={`${process.env.PUBLIC_URL}/img/flags/4x3/${zone.flag}.svg`} alt=""></img> : <></>

    const kpiData = [
        {
            key: `${consortium._id}-envs`,
            value: environments.length,
            label: lt('environments'),
            icon: <LanIcon/>
        },
        {
            key: `${consortium._id}-memberships`,
            value: consortiumMemberships.length,
            label: lt('memberships'),
            icon: <AccountCircleOutlineIcon/>
        },
        {
            key: `${consortium._id}-homeRegion`,
            value: regionDisplay,
            label: lt('homeRegion'),
            icon: flag
        },
        {
            key: `${consortium._id}-enabledRegions`,
            value: consortiumZones.length,
            label: lt('enabledRegions'),
            icon: <MapMarkerOutlineIcon/>
        },
    ];

    const dotDotDotItems = [{
        name: lt('viewNetwork'),
        action: () => history.push(viewNetworkLink)
    }]

    const kpi = <Kpi data={kpiData} noPaper />

    const makeIcon = (state: EnvironmentState) => {
        return (
            <Grid item container spacing={1}>
                <Grid item>
                    <ResourceStateChip state={state} />
                </Grid>
                <Grid item>
                    <ChevronRightIcon />
                </Grid>
            </Grid>
        )
    }

    const envList = environments.map(e => ({
        title: e.name, 
        value: lt('nodeWithCount', { count: e.node_list.length }),
        icon: <LanIcon />,
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium._id}/environments/${e._id}`),
        actionIcon: makeIcon(e.state)
    }))

    const displayGrid = ( 
        loading ? <CircularProgress /> :
        <Grid item container direction="column" spacing={1} wrap="nowrap">
            <DisplayCard {...{loading}} itemList={envList} header={lt('environments')} hidePaper maxHeight='disable' />
        </Grid>
    )

    const linkButton: LinkButtonProps = {
        text: lt('viewNetwork'),
        onClick: () => history.push(viewNetworkLink)
    }

    const actionBar = (
        <Grid item container alignItems="center" spacing={3}>
            {/* only show the "bad" states */}
            { consortium.state !== 'setup' && 
            <Grid item>
                <ResourceStateChip state={consortium.state} />
            </Grid>
            }
            <Grid item>
                <Dotdotdot menuItems={dotDotDotItems} />
            </Grid>
        </Grid>
    )
    
    return (
        <DisplayGridWrapper {...{linkButton}}
            header={consortium.name} 
            {...{actionBar}}
            {...{kpi}}
            {...{displayGrid}} />
    )
};

const useStyles = makeStyles(() => ({
    flag: {
        height: '30px',
        width: '30px'
    }
}));

interface translations extends EnvironmentProviderTranslations {
    viewNetwork: string
    environments: string
    memberships: string
    homeRegion: string
    enabledRegions: string
    nodeWithCount: string
    nodeWithCount_plural: string
}
const enTranslations: translations = {
    ...EnEnvironmentProviderTranslations,
    viewNetwork: 'View Network',
    environments: 'Environments',
    memberships: 'Memberships',
    homeRegion: 'Home Region',
    enabledRegions: 'Enabled Regions',
    nodeWithCount: "{{count}} Node",
    nodeWithCount_plural: "{{count}} Nodes"
}