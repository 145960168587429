import { useMutation, useQuery } from '@apollo/client';
import { Button, Grid, makeStyles, Paper, Typography, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { DisplayTable } from '../../components/DisplayWrappers';
import { OPS_MANAGE_NODE_PATH, OPS_MANAGE_ORG_PATH, OPS_MANAGE_SERVICE_PATH, OPS_PATH } from '../../components/OpsNav/OpsNav';
import { OpsQueryVars } from '../../interfaces';
import { Consortium, Environment, Node, OpsConsortiaData, OpsConsortiaQuery, OpsEnvironmentsData, OpsEnvironmentsQuery, OpsMembershipsData, OpsMembershipsQuery, OpsNodesData, OpsNodesQuery, OpsServicesData, OpsServicesQuery, OpsUpdateConsortiumMutation, OpsUpdateConsortiumVars, OpsUpdateEnvironmentMutation, Service, UpdateEnvironmentVars } from '../../models';

export const OpsConsortium: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsConsortium', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsConsortium:${key}`, interpolate)
    const history = useHistory();
    
    const classes = useStyles();

    const {ops_consortia_id} = useParams<any>();
    
    const [message, setMessage] = useState('');
    const [editConsortiumTags, setEditConsortiumTags] = useState<Consortium| undefined>();
    const [editEnvTags, setEditEnvTags] = useState<Environment | undefined>();
    const [newTags, setNewTags] = useState('');

    const [opsUpdateEnvironment, {loading: envUpdating}] = useMutation<Environment, UpdateEnvironmentVars>(OpsUpdateEnvironmentMutation)
    const [opsUpdateConsortium, {loading: consUpdating}] = useMutation<Consortium, OpsUpdateConsortiumVars>(OpsUpdateConsortiumMutation)

    const {
        data: {
            opsMemberships: memberships
        } = { opsMemberships: [] },
        error: membershipsError,
    } = useQuery<OpsMembershipsData, OpsQueryVars>(OpsMembershipsQuery, {
        skip: !ops_consortia_id,
        variables: {
            query: `consortia_id=${ops_consortia_id}`,
        },
        fetchPolicy: 'no-cache'
    });

    const uniqueConsortia = useMemo(() => (
        memberships.reduce<string[]>((v, m) => v.includes(m.consortia_id) ? v : v.concat([m.consortia_id]), [])
    ), [memberships]);

    const {
        data: {
            opsConsortia: consortia
        } = { opsConsortia: [] },
        error: consortiaError,
        refetch: refetchConsortia
    } = useQuery<OpsConsortiaData, OpsQueryVars>(OpsConsortiaQuery, {
        skip: !uniqueConsortia.length,
        variables: { query: uniqueConsortia.map(c => `_id[]=${c}`).join('&') },
        fetchPolicy: 'no-cache'
    });

    const {
        data: {
            opsNodes: nodes
        } = { opsNodes: [] },
        error: nodesError,
    } = useQuery<OpsNodesData, OpsQueryVars>(OpsNodesQuery, {
        skip: !memberships.length,
        variables: { query: memberships.map(m => `membership_id[]=${m._id}`).join('&') },
        fetchPolicy: 'no-cache'
    });

    const {
        data: {
            opsServices: services
        } = { opsServices: [] },
        error: servicesError,
    } = useQuery<OpsServicesData, OpsQueryVars>(OpsServicesQuery, {
        skip: !memberships.length,
        variables: { query: memberships.map(m => `membership_id[]=${m._id}`).join('&') },
        fetchPolicy: 'no-cache'
    });

    const {
        data: {
            opsEnvironments: environments
        } = { opsEnvironments: [] },
        error: environmentsError,
        refetch: refetchEnvironments,
    } = useQuery<OpsEnvironmentsData, OpsQueryVars>(OpsEnvironmentsQuery, {
        skip: !ops_consortia_id,
        variables: { query: `consortia_id=${ops_consortia_id}` },
        fetchPolicy: 'no-cache'
    });

    const fetchError = membershipsError || consortiaError || nodesError || servicesError || environmentsError;
    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    const patchConsortium = async (c: Consortium, state?: 'setup' | 'archived', tags?: string[]) => {
        try {
            await opsUpdateConsortium({
                variables: {
                    id: c._id,
                    consortium: {
                        state: state ? state : undefined,
                        admin_tags: tags ? tags : undefined,
                    }
                }
            });
            refetchConsortia()
        } catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    }

    const patchEnv = async (e: Environment, state?: 'live' | 'paused' | 'archived' | 'locked', tags?: string[]) => {
        try {
            await opsUpdateEnvironment({
                variables: {
                    consortia_id: e.consortia_id,
                    id: e._id,
                    environment: {
                        state: state ? state : undefined,
                        admin_tags: tags ? tags : undefined,
                    }
                }
            });
            refetchEnvironments()
        } catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    }

    const membershipFor = (n : Node | Service) => {
        const m = memberships.find(m => m._id === n.membership_id);
        if (!m) return '';
        return `${m.org_name ? m.org_name.substring(0,64) : ''} (${m.org_id}/${m._id})`
    }

    const envPanel = (e: Environment) => {
        const envNodes = nodes.filter(n => n.environment_id === e._id)
        const envServices = services.filter(s => s.environment_id === e._id);
        const envTags = e.admin_tags?.join(',') || '';
        return <Grid item container direction="column" spacing={2} key={e._id}>
            <Grid item container alignItems='center' spacing={2}>
                <Grid item>
                    <Typography variant="h6">{lt('environment', e)}</Typography>
                </Grid>
                <Grid item>
                    {(e.state === 'paused' || e.state === 'archived') && <Button disabled={envUpdating} color="primary" onClick={() => patchEnv(e, 'live')}>{e.state === 'archived' ? lt('unarchiveLive') : lt('resume')}</Button>}
                    {e.state !== 'paused' && <Button disabled={envUpdating} color="primary" onClick={() => patchEnv(e, 'paused')}>{e.state === 'archived' ? lt('unarchivePaused') : e.state === 'locked'? lt('unlockPaused'):lt('pause')}</Button>}
                </Grid>
                {e.state !== 'archived' && <Grid item>
                    <Button disabled={envUpdating} color="primary" onClick={() => patchEnv(e, 'archived')}>{lt('archive')}</Button>
                </Grid>}
                <Grid item>
                    {envTags}
                </Grid>
                <Grid item>
                    <Button disabled={envUpdating} color="primary" onClick={() => { setEditEnvTags(e); setNewTags(envTags) }}>{lt('editTags')}</Button>
                </Grid>
            </Grid>
            <Grid item>
                {envNodes.length ? <DisplayTable
                    records={envNodes.map(n => {
                        const membership = memberships?.find(m => m._id === n.membership_id);
                        return {
                            columns: [{value: n._id},{value:membershipFor(n)},{value:n.provider},{value:n.size},{value:n.state},{value:n.name},{value:n.created_at},{value:n.updated_at||''}],
                            onClick: () => history.push(`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/${membership?.org_id}/${OPS_MANAGE_NODE_PATH}/${n._id}`)
                        }
                    })}
                    columnHeaders={[
                        lt('nodeId'),
                        lt('membership'),
                        lt('type'),
                        lt('size'),
                        lt('state'),
                        lt('name'),
                        lt('createdAt'),
                        lt('updatedAt'),
                    ]}/> : undefined}
            </Grid>
            <Grid item>
                {envServices.length ? <DisplayTable
                    records={envServices.map(s => {
                        const membership = memberships?.find(m => m._id === s.membership_id);
                        return {
                            columns: [{value: s._id},{value:membershipFor(s)},{value:s.service},{value:s.size},{value:s.state},{value:s.name},{value:s.created_at},{value:s.updated_at||''}],
                            onClick: () => history.push(`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/${membership?.org_id}/${OPS_MANAGE_SERVICE_PATH}/${s._id}`)
                        };
                    })}
                    columnHeaders={[
                        lt('serviceId'),
                        lt('membership'),
                        lt('type'),
                        lt('size'),
                        lt('state'),
                        lt('name'),
                        lt('createdAt'),
                        lt('updatedAt'),
                    ]}/> : undefined}
            </Grid>
        </Grid>
    };

    const editTagsControls = <Grid container direction='column'>
        <Grid item>
            <TextField
                variant='outlined'
                label={lt('newTags')}
                fullWidth
                value={newTags}
                onChange={e => setNewTags(e.target.value)}
            />
        </Grid>
    </Grid>

    const onEditTags = async () => {
        try {
            if (editConsortiumTags) {
                await patchConsortium(editConsortiumTags, undefined, newTags ? newTags.split(',').map(t => t.trim()) : [])
                setEditConsortiumTags(undefined);
            }
            else if (editEnvTags) {
                await patchEnv(editEnvTags, undefined, newTags ? newTags.split(',').map(t => t.trim()) : [])
               setEditEnvTags(undefined)}
            }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage)
        }
    }

    return <>
        <FormDialog
            header={lt('editTags')}
            open={!!(editConsortiumTags || editEnvTags)}
            setOpen={() => {setEditConsortiumTags(undefined); setEditEnvTags(undefined)}}
            controlsWrapper={editTagsControls}
            onSave={onEditTags}
            closeDialogAfterSave={false}
        />
        <Grid container spacing={2} direction="column">
            {consortia.map(c => {
                const consortiaTags = c.admin_tags?.join(',') || '';
                return <Paper className={classes.paper} key={c._id}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item container alignContent='center' alignItems='center' spacing={2}>
                            <Grid item>
                                <Typography variant="h5">{lt('consortium', c)}</Typography>
                            </Grid>
                            <Grid item>
                                {c.state === 'setup'
                                    ? <Button disabled={consUpdating} color="primary" onClick={() => patchConsortium(c, 'archived')}>{lt('archive')}</Button>
                                    : <Button disabled={consUpdating} color="primary" onClick={() => patchConsortium(c, 'setup')}>{lt('unarchive')}</Button>
                                }
                            </Grid>
                            <Grid item>
                                {consortiaTags}
                            </Grid>
                            <Grid item>
                                <Button disabled={consUpdating} color="primary" onClick={() => { setEditConsortiumTags(c); setNewTags(consortiaTags) }}>{lt('editTags')}</Button>
                            </Grid>
                        </Grid>
                        {
                            memberships.filter(m => m.consortia_id === c._id).map(m => (
                                <Grid item key={`m_${m._id}`} container spacing={1}>
                                    <Grid item>
                                        <Typography variant="body1">{lt('memberInfo', m)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Link color="primary" to={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/${m.org_id}`}>{m.org_id}</Link>
                                    </Grid>
                                </Grid>
                            ))
                        }
                        {environments.filter(e => e.consortia_id === c._id).map(e => envPanel(e))}
                    </Grid>
                </Paper>
            })}
            <MessageSnackbar {...{message}} {...{setMessage}} />
        </Grid>
    </>;
};

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
}));

interface translations {
    editTags: string;
    newTags: string;
    scope: string;
    consortium: string;
    environment: string;
    membership: string;
    memberInfo: string;
    orgId: string;
    nodeId: string;
    serviceId: string;
    masterOrg: string;
    agreement: string;
    createdAt: string;
    updatedAt: string;
    type: string;
    size: string;
    state: string;
    name: string;
    allOrgs: string;
    orgOnly: string;
    pause: string;
    resume: string;
    archive: string;
    unarchive: string;
    unarchivePaused: string;
    unarchiveLive: string;
    unlockPaused: string;
}

const enTranslations: translations = {
    editTags: 'Edit tags',
    newTags: 'New tags',
    scope: 'Scope:',
    consortium: 'Consortium: "{{name}}" ({{_id}}) - {{state}}',
    environment: 'Environment: "{{name}}" ({{_id}}) - {{state}}',
    membership: 'Membership',
    memberInfo: 'Membership: "{{org_name}}" {{_id}}:',
    orgId: 'Org ID',
    nodeId: 'Node',
    serviceId: 'Service',
    masterOrg: 'Master Org',
    agreement: 'Agreement',
    createdAt: 'Created',
    updatedAt: 'Updated',
    type: 'Type',
    size: 'Size',
    state: 'State',
    name: 'Name',
    allOrgs: 'Org and agreement/child orgs {{orgs}}',
    orgOnly: 'Org {{_id}} only',
    pause: 'Pause',
    resume: 'Resume',
    archive: 'Archive',
    unarchive: 'Unarchive',
    unarchivePaused: 'Unarchive - paused',
    unarchiveLive: 'Unarchive - live',
    unlockPaused: 'Unlock - paused'
};
