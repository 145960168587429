import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ContractTemplate } from '../../../components/ContractManagement/ContractTemplate';
import { KaleidoTemplateHelp } from '../../../components/ContractManagement/KaleidoTemplateHelp';
import { MessageSnackbar } from '../../../components/DialogWrappers';
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { CreateStepProps } from '../../../interfaces';

interface Props extends CreateStepProps{}

export const KaleidoTemplateStep1 = (
    { 
        cancelPath
    }: Props) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { contract_id } = useParams<any>();

    const [message, setMessage] = useState('');

    const postSave = async (compiledContractId: string) => {
        const splitPathname = pathname.split('/')
        const baseCompilationsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_COMPILED_CONTRACTS_PATH) + 1).join('/')
        history.push(`${baseCompilationsPath}/${compiledContractId}`)
    }

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <ContractTemplate templateType='kaleido' {...{contract_id}} {...{postSave}} isFirstStep isLastStep {...{cancelPath}} {...{setMessage}} envStatus={null /* no env picked on this flow */ }/>
            <KaleidoTemplateHelp />
        </>
    )
};
