import { useApolloClient } from '@apollo/client';
import { FormControl, Grid, makeStyles, RadioGroup, TextField, Typography } from "@material-ui/core";
import CloudOutlineIcon from 'mdi-react/CloudOutlineIcon';
import ServerIcon from 'mdi-react/ServerIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper } from '../../components/DialogWrappers';
import { FormRadio } from '../../components/FormControls/FormRadio';
import { CREATE_ENVIRONMENTS_PATH } from '../../components/MainNav/EnvironmentPicker';
import { CreateStepProps } from '../../interfaces';
import { EnvironmentType, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, Protocol } from '../../models';
import { Step3Help } from './Step3Help';
import { PayWallBanner } from '../../components/PayWall/PayWallBanner';
import { HelpCenter, HelpCenterTab } from '../../components/HelpCenter/HelpCenter';
interface Props extends CreateStepProps {
    environmentType: EnvironmentType,
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>,
    hybrid: boolean,
    setHybrid: React.Dispatch<React.SetStateAction<boolean>>,
    protocol: Protocol
};

export const Step3 = ({ environmentType, protocol, name, setName, hybrid, setHybrid, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep3', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep3:${key}`, interpolate)

    const classes = useStyles();

    const client = useApolloClient()
    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();
    const [helpCenter, setHelpCenter] = useState(false);

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${CREATE_ENVIRONMENTS_PATH}/4`, {
            name,
            hybrid
        })
    }

    const handleDeployentOptionChange = (v: string) => {
        setHybrid((v === "hybrid") ? true : false);
    };

    // direct read from cache
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsHybrid = useMemo(() => PlanSupports.hybrid(organization), [organization])
    const iconFontSize = 36;

    const makeIconBox = (hybrid: boolean) => (
        <Grid container className={classes.iconContainer} justify={"center"} spacing={1}>
            <Grid item><CloudOutlineIcon size={iconFontSize}/></Grid>
            {hybrid && <Grid item><ServerIcon size={iconFontSize}/></Grid>}
        </Grid>
    );

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>

            <Grid item >
                <TextField
                    data-test="environmentName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('deploymentOptions')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('deploymentOptionsDesc')}
                </Typography>
            </Grid>

            <FormControl>
                <RadioGroup value={hybrid} onChange={e => handleDeployentOptionChange(e.target.value)}>
                    <Grid container direction="column" spacing={1}>
                        <FormRadio 
                            value="cloud" selected={!hybrid}
                            label={lt('cloudOnly')} description={lt('cloudOnlyDesc')}
                            setValue={handleDeployentOptionChange} 
                            icon={makeIconBox(false)}/>
                        <FormRadio
                            value="hybrid" selected={hybrid} disabled={!supportsHybrid}
                            label={lt('hybrid')} description={lt('hybridDesc')}
                            setValue={handleDeployentOptionChange}
                            icon={makeIconBox(true)}/>
                        {!supportsHybrid && 
                            <PayWallBanner 
                                description={lt('hybridPaywall')} 
                                actionLabel={lt('hybridPaywallAction')} 
                                action={() => setHelpCenter(true)} />}
                    </Grid>
                </RadioGroup>
            </FormControl>
            <HelpCenter
                isOpen={helpCenter}
                setOpen={setHelpCenter}
                tab={HelpCenterTab.CONTACT_US}
                preSelectedCategory='enterprisePlanEnquiry'
            />
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={name ? false : true} onNext={save} />
            <Step3Help {...{protocol}} />
        </>
    )
};

interface translations {
    header: string,
    name: string,
    deploymentOptions: string,
    deploymentOptionsDesc: string,
    cloudOnly: string,
    cloudOnlyDesc: string,
    hybrid: string,
    hybridDesc: string,
    hybridPaywall: string,
    hybridPaywallAction: string,
}
const enTranslations: translations = {
    header: 'Enter Environment Details',
    name: 'Name of this environment',
    deploymentOptions: "Multi-Region Deployment Options",
    deploymentOptionsDesc: "Configure multi-region, multi-cloud and hybrid secure networking for your Blockchain environment.",
    cloudOnly: "Cloud Only",
    cloudOnlyDesc: "Deploy fully-managed nodes across our regional deployment zones in AWS and Azure",
    hybrid: "Hybrid (Cloud and On-Premise)",
    hybridDesc: "Securely extend to self-managed nodes on-premise, and in private cloud VPCs",
    hybridPaywall: 'The hybrid deployment option is not enabled on your current plan.',
    hybridPaywallAction: 'Contact Us'
}

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        minWidth: '90px'
    },
}));