import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Account, EtherBalanceOfData, EtherBalanceOfVars, EtherBalanceOfQuery } from '../../models'
import { Grid, CircularProgress, Button } from "@material-ui/core";
import { CopyableSettings, CopyableSetting } from '../DisplaySettings'
import { ShortenedHash } from '../FormControls/ShortenedHash'
import { TokenBalances } from './TokenBalances'
import { capitalize } from '../../utils/StringUtils';
import { Typography } from "@material-ui/core";
import { AddressTransactions } from './AddressTransactions';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_ETHER_POOL_PATH } from '../MainNav/SideNavs/DigitalAssets';

interface Props {
    account?: Account
};

export const WalletAccountDetails: React.FC<Props> = ({ account }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'WalletWalletAccount', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`WalletWalletAccount:${key}`, interpolate);

    const { pathname } = useLocation()
    const history = useHistory()
    const { org_id, consortium_id, environment_id, address, membership_id } = useParams<any>();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        data: {
            etherBalanceOf
        } = { etherBalanceOf: null }
    } = useQuery<EtherBalanceOfData, EtherBalanceOfVars>(EtherBalanceOfQuery, {
        variables: {
            ...environmentVariables,    
            accountAddress: address!
        },
        fetchPolicy: 'cache-and-network'
    });

    if(loading && !etherBalanceOf) return <CircularProgress />

    if(!account && membership_id) {
        return <Typography>{lt('accountNotFound')}</Typography>
    }

    const copyableList: CopyableSetting[] = [
        {
            title: lt('address'),
            displayValue: <ShortenedHash address={address!} showFullAddress retry />,
            copyableValue: address
        },
        { disableCopy: true, title: lt('type'), displayValue: capitalize(account?.account_type ?? lt('unknown')) },
        { disableCopy: true, title: lt('etherBalance'), displayValue: etherBalanceOf ?? ''},
        { disableCopy: true, title: lt('createdAt'), displayValue: account?.created_at ? new Date(account.created_at).toLocaleString() : lt('unknown') }
    ];

    const actionBar = (
        <Grid item>
            <Button color="primary" variant="contained" size="medium" 
                onClick={() => history.push(`/orgs/${org_id}/consortia/${consortium_id!}/environments/${environment_id}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_ETHER_POOL_PATH}/create/1`, {
                    account,
                    address,
                    cancelPath: pathname
                })}>
                {lt('fundAccount')}
            </Button>
        </Grid>
    )

    return (
        <Grid item container direction="row">
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <CopyableSettings header={lt('accountInfo')} {...{actionBar}} {...{ copyableList }} />
                </Grid>
                <Grid item>
                    <TokenBalances />
                </Grid>
                <Grid item>
                    <AddressTransactions address={address!} />
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    etherBalance: string
    accountInfo: string
    address: string
    type: string
    createdAt: string
    accountNotFound: string
    unknown: string
    fundAccount: string
}
const enTranslations: translations = {
    etherBalance: 'Ether balance',
    accountInfo: 'Account Information',
    address: 'Address',
    type: 'Account type',
    createdAt: 'Created at',
    accountNotFound: 'Account not found',
    unknown: 'Unknown',
    fundAccount: 'Fund ETH'
}