import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, TextField } from '@material-ui/core';
import { FormDialog } from '../../../components/DialogWrappers';
import { useMutation } from '@apollo/client';
import { MFAVars, DisableMFAMutation, MFADisableData, MFAInput } from '../../../models/mfa';

interface Props {
    disableMFADialogOpen: boolean
    setDisableMFADialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    mfaStatusChanged: () => Promise<void>
}

export const ProfileSettingsDisableMFA: React.FC<Props> = ({ disableMFADialogOpen, setDisableMFADialogOpen, mfaStatusChanged }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProfileSettingsDisableMFA', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProfileSettingsDisableMFA:${key}`, interpolate);

    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [disableMFA] = useMutation<MFADisableData, MFAVars>(DisableMFAMutation);

    useEffect(() => {
        if (disableMFADialogOpen) {
            setErrorMessage('');
            setCode('');
        }
    }, [disableMFADialogOpen]);

    const setupMFAControlsWrapper = (
        <Grid container direction="column" spacing={3}>
            {errorMessage &&
                <Grid item>
                    <Typography variant="body1" color="error">{errorMessage}</Typography>
                </Grid>}
            <Grid item>
                <Typography variant="body1">{lt('introduction')}</Typography>
            </Grid>
            <Grid item>
                <TextField value={code} onChange={event => setCode(event.target.value)} variant="outlined" label={lt('code')} fullWidth />
            </Grid>
        </Grid>
    );

    const handleDisableMFA = async () => {
        setErrorMessage('');
        try {
            let mfaInput: MFAInput = {};
            if(code.length === 6) {
                mfaInput.token = code;
            } else {
                mfaInput.recovery_code = code;
            }
            const result = await disableMFA({ variables: { mfaInput }});
            if(result.data?.disableMFA) {
                await mfaStatusChanged();
                setDisableMFADialogOpen(false);
            } else {
                setErrorMessage(lt('invalidCode'));
            }
        } catch(err) {
            setErrorMessage(err.toString());
        }
    };

    return (
        <FormDialog
            width="xs"
            header={lt('disableMFA')}
            open={disableMFADialogOpen}
            setOpen={setDisableMFADialogOpen}
            controlsWrapper={setupMFAControlsWrapper}
            saveText={lt('disable')}
            saveDisabled={code.length < 6}
            closeDialogAfterSave={false}
            onSave={handleDisableMFA}
        />
    );
}

interface translations {
    disableMFA: string
    introduction: string
    code: string
    invalidCode: string
    disable: string
}

const enTranslations: translations = {
    disableMFA: 'Disable Multi-Factor Authentication',
    introduction: 'Enter the 6-digit code you see in your authentication app. Optionally enter one of the unused recovery codes.',
    code: 'Code',
    invalidCode: 'The code entered is invalid, please try again.',
    disable: 'Disable',
}