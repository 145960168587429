import CalendarBlankOutlineIcon from 'mdi-react/CalendarBlankOutlineIcon';
import CalendarRefreshOutlineIcon from 'mdi-react/CalendarRefreshOutlineIcon';
import IdentifierIcon from 'mdi-react/IdentifierIcon';
import LanPendingIcon from 'mdi-react/LanPendingIcon';
import SignalCellularOutlineIcon from 'mdi-react/SignalCellularOutlineIcon';
import TextBoxOutlineIcon from 'mdi-react/TextBoxOutlineIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import {
    EnEnvironmentProviderTranslations, Environment,
    EnvironmentProviderTranslations
} from '../../models';
import { capitalize } from '../../utils/StringUtils';

interface Props {
    environment: Environment
};

export const Info = ({ environment }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentDashboardInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentDashboardInfo:${key}`, interpolate)

    const statusList: DisplayCardListItem[] = [
        {
            title: lt('envId'),
            value: `${environment._id}`,
            icon:  <IdentifierIcon />,
        },
        {
            title: lt('chainId'),
            value: `${environment.chain_id}`,
            icon:  <SignalCellularOutlineIcon />,
        },
        {
            title: lt('protocol'),
            value: `${capitalize(lt(environment.provider))}`,
            icon:  <TextBoxOutlineIcon />,
        },
        {
            title: lt('consensus'),
            value: `${capitalize(environment.consensus_type)}`,
            icon:  <LanPendingIcon />,
        },
        {
            title: lt('creationDate'),
            value: new Date(environment.created_at).toLocaleString(),
            icon: <CalendarBlankOutlineIcon />,
        },
    ]

    if (environment.updated_at) {
        statusList.push({
            title: lt('updatedDate'),
            value: new Date(environment.updated_at).toLocaleString(),
            icon: <CalendarRefreshOutlineIcon />,
        });
    }
    
    return (
        <>
            <DisplayCard dataTestId="environmentInfo" maxHeight="disable"
                header={lt('header')} 
                itemList={statusList} />
        </>
    )
};

interface translations extends EnvironmentProviderTranslations {
    header: string,
    status: string,
    releaseVersion: string, 
    versionInfo: string,
    outOfDate: string,
    upToDate: string,
    pause: string,
    resume: string,
    memberships: string,
    upgrade: string,
    paused: string,
    protocol: string,
    consensus: string,
    creationDate: string,
    updatedDate: string,
    chainId: string,
    envId: string
}
const enTranslations: translations = {
    ...EnEnvironmentProviderTranslations,
    header: 'Environment Info',
    status: 'Info',
    releaseVersion: 'Current Version', 
    versionInfo: '{{version}} {{message}}',
    outOfDate: 'out-of-date',
    upToDate: 'up-to-date',
    pause: 'Pause',
    resume: 'Start',
    memberships: 'Memberships',
    upgrade: 'Upgrade',
    paused: 'Paused',
    protocol: 'Protocol Provider',
    consensus: 'Consensus Algorithm',
    creationDate: 'Created',
    updatedDate: 'Updated',
    chainId: 'Chain ID',
    envId: 'Environment ID'
}
