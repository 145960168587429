import React, { useState, useEffect } from 'react';
import { 
    List,
    makeStyles,
    Divider,
    Collapse, ListItem, ListItemText, CircularProgress
} from '@material-ui/core';
import { ConsortiumPicker } from './ConsortiumPicker';
import { EnvironmentWrapper } from './EnvironmentWrapper';
import { EnvironmentPicker } from './EnvironmentPicker';
import { useParams } from "react-router-dom";
import ExpandLess from 'mdi-react/ChevronUpIcon';
import ExpandMore from 'mdi-react/ChevronDownIcon';
import { useTranslation } from 'react-i18next';
import { ConsortiumWrapper } from './ConsortiumWrapper';

interface Props {
    setConsortiumQueriesComplete: React.Dispatch<React.SetStateAction<boolean>>,
    setEnvironmentQueriesComplete: React.Dispatch<React.SetStateAction<boolean>>,
    cachedEnvironmentId: string,
    setCachedEnvironmentId: React.Dispatch<React.SetStateAction<string>>,
    hide: boolean
    refreshingSession: boolean
};

const MainNav = ({ setConsortiumQueriesComplete, setEnvironmentQueriesComplete, hide, cachedEnvironmentId, setCachedEnvironmentId, refreshingSession }: Props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MainNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MainNav:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const [governance, setGovernance] = useState<boolean>(false);
    const [dataExplorer, setDataExplorer] = useState<boolean>(false);
    const [healthMonitoring, setHealthMonitoring] = useState<boolean>(false);
    const [addressBook, setAddressBook] = useState<boolean>(false);
    const [firefly, setFirefly] = useState<boolean>(false);
    const [blockchain, setBlockchain] = useState<boolean>(false);
    const [apps, setApps] = useState<boolean>(false);
    const [security, setSecurity] = useState<boolean>(false);
    const [assets, setAssets] = useState<boolean>(false);
    const [b2b, setB2b] = useState<boolean>(false);
    const [cloudConfigs, setCloudConfigs] = useState(false);
    const [keyManagement, setKeyManagement] = useState(false);

    const minimize = (indexToIgnore: number) => {
        [
            setGovernance,
            setDataExplorer, 
            setHealthMonitoring,
            setAddressBook,
            setFirefly,
            setBlockchain, 
            setApps, 
            setSecurity,
            setAssets,
            setB2b,
            setCloudConfigs,
            setKeyManagement
        ].filter((_, i) => i !== indexToIgnore).forEach(m => m(false))
    }

    useEffect(() => { if (governance) minimize(0) }, [governance])
    useEffect(() => { if (dataExplorer) minimize(1) }, [dataExplorer])
    useEffect(() => { if (healthMonitoring) minimize(2) }, [healthMonitoring])
    useEffect(() => { if (addressBook) minimize(3) }, [addressBook])
    useEffect(() => { if (firefly) minimize(4) }, [firefly])
    useEffect(() => { if (blockchain) minimize(5) }, [blockchain])
    useEffect(() => { if (apps) minimize(6) }, [apps])
    useEffect(() => { if (security) minimize(7) }, [security])
    useEffect(() => { if (assets) minimize(8) }, [assets])
    useEffect(() => { if (b2b) minimize(9) }, [b2b])
    useEffect(() => { if (cloudConfigs) minimize(10) }, [cloudConfigs])
    useEffect(() => { if (keyManagement) minimize(11) }, [keyManagement])

    const [networkOpen, setNetworkOpen] = useState<boolean>(!environment_id);
    const [environmentOpen, setEnvironmentOpen] = useState<boolean>(true);

    const [cachedConsortiumId, setCachedConsortiumId] = useState(consortium_id || '')

    // whenever consortium_id changes, set the cached consortium_id and reset the cached env_id
    useEffect(() => {
        setCachedConsortiumId(consortium_id || '')
        setCachedEnvironmentId('')
    }, [consortium_id, setCachedEnvironmentId])

    // whenever environment_id changes, update the cached env_id
    useEffect(() => {
        if (environment_id) {
            setCachedEnvironmentId(environment_id)
        }
    }, [environment_id, setCachedEnvironmentId])

    // hide network panel unless we're actively working there
    useEffect(() => {
        setNetworkOpen(!environment_id)

        // leave the below comment for now, in case we decide to collapse the environment tab when navigating back up to network routes
        // setEnvironmentOpen((environment_id ? true : false) || (!environment_id && !cachedEnvironmentId))
    }, [environment_id])

    // in our quest to save the environment_id when you "go back up" and work on your network we've added caching for 
    // the selected environment_id (cachedEnvironmentId) since environment_id is eliminated from the url in network routes.

    // when the selected consortium_id changes theres an intermediate render (caused by useParams) where the consortium_id belongs to the 
    // newly selected consortium, but the the cachedEnvironmentId is still set to the environment_id from the previous
    // consortium. this is because the useEffect to clear our the cachedEnvironmentId runs AFTER the render mentioned above.

    // therefore, we can render EnvironmentWrapper only if we have a cachedEnvironmentId and 
    // our cached consortium_id matches the consortium_id from react router.  
    const canShowEnvironmentWrapper = cachedEnvironmentId && cachedConsortiumId === consortium_id

    // therefore, we can only pass the cachedEnvironmentId to EnvironmentPicker if the same criteria holds 
    // (minues the requirement for cachedEnvironmentId since empty is perfectly valid and means you need to pick one)
    const idForEnvironmentPicker = cachedConsortiumId === consortium_id ? cachedEnvironmentId : (environment_id || '')

    const content = (
        <>
            <ListItem button onClick={() => setNetworkOpen(!networkOpen)}>
                <ListItemText secondary={lt('network')} secondaryTypographyProps={{variant: "button"}} />
                {networkOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={networkOpen} timeout="auto">
                <ConsortiumPicker />
                <ConsortiumWrapper {...{governance}} {...{setGovernance}} />
            </Collapse>

            <Divider />

            <ListItem button onClick={() => setEnvironmentOpen(!environmentOpen)}>
                <ListItemText secondary={lt('environment')} secondaryTypographyProps={{variant: "button"}} />
                {environmentOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={environmentOpen} timeout="auto" data-test="environmentWrapper">
                <EnvironmentPicker 
                    {...{setCachedEnvironmentId}} 
                    environment_id={idForEnvironmentPicker} 
                    {...{setConsortiumQueriesComplete}} />
                {canShowEnvironmentWrapper && 
                <EnvironmentWrapper environment_id={cachedEnvironmentId} {...{setEnvironmentQueriesComplete}} 
                    {...{dataExplorer}} {...{setDataExplorer}} 
                    {...{healthMonitoring}} {...{setHealthMonitoring}} 
                    {...{addressBook}} {...{setAddressBook}} {...{firefly}} {...{setFirefly}}  {...{blockchain}} {...{setBlockchain}} 
                    {...{apps}} {...{setApps}} {...{security}} {...{setSecurity}} {...{b2b}} {...{setB2b}} {...{assets}} {...{setAssets}} 
                    {...{cloudConfigs}} {...{setCloudConfigs}} {...{keyManagement}} {...{setKeyManagement}} />
                }
            </Collapse>
        </>
    )

    return (
        <div className={hide ? classes.hide : ''}>
            <div className={classes.toolbar} />

            <List component="div" disablePadding={!refreshingSession}>
                {refreshingSession ? <ListItem><CircularProgress /></ListItem> : content}
            </List>
        </div> 
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    hide: {
        display: 'none'
    }
}));

interface translations {
    network: string, 
    environment: string
}
const enTranslations: translations = {
    network: 'Network',
    environment: 'Environment'
}

export { MainNav };

