import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { NodesData, NodesQuery, ServicesData, ServicesQuery, EnvironmentData, EnvironmentQuery, ServicesEnum, ServicesTranslations, EnServicesTranslations, EnvironmentZonesData, EnvironmentZonesQuery, environmentSupportsRotateSigners } from '../../models'
import { Typography, Grid, Button } from "@material-ui/core";
import { EnvironmentResourcesVars, ConsortiumResourceVars } from '../../interfaces';
import { EmptyState } from '../../components/DisplayWrappers';
import { ServicesCard } from './ServicesCard';
import { BLOCKCHAIN_BASE_PATH, BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH } from '../../components/MainNav/SideNavs/Blockchain';
import { NodeCard } from './NodeCard';
import { NodesCard } from '../../components/NodesCard/NodesCard';

export const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BlockchainDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BlockchainDashboard:${key}`, interpolate)

    const history = useHistory()
    const { consortium_id, environment_id, org_id } = useParams<any>();

    const environmentResourcesVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    let {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-only'
    });

    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;
    nodes = nodes.filter(n => n.membership.isMine || n.role === 'monitor').sort((a) => a.membership.isMine ? -1 : 1);
    const ordererNodes = nodes.filter(n => n.role === "orderer");
    const peerNodes = nodes.filter(n => n.role === "peer");

    const chainlinkInstances = useMemo(() => {
        return services.filter(s => s.service === ServicesEnum.chainlink && s.membership.isMine);
    }, [services]);

    const ipfsInstances = useMemo(() => {
        return services.filter(s => s.service === ServicesEnum.ipfs && s.membership.isMine);
    }, [services]);

    const rotateSignersInstances = useMemo(() => {
        return services.filter(s => s.service === ServicesEnum.rotatesigners);
    }, [services]);

    const caInstances = useMemo(() => {
        return services.filter(s => s.service === ServicesEnum['fabric-ca'] && s.membership.isMine);
    }, [services]);    

    const createNode = () => history.push(`${basePath}/nodes/create/1`)

    if (!nodes.filter(n => n.role !== 'monitor').length && !caInstances.length) return (
        <EmptyState imageFile='Empty-Node.svg' 
            title={lt('addANode')} 
            description={lt('emptyDescription')} 
            button={{ text: lt('createNode'), onClick: createNode, disabled: environment?.state !== 'live' }}
            documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/quick-start/first-blockchain/' }} />
    )
    
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('dashboard')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" size="large" onClick={createNode}>
                        {lt('createNode')}
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={3}>
                {!isFabric ? nodes.map(node => (
                    <Grid key={node._id} item container lg={4} sm={6} xs={12}>
                        <NodeCard {...{node}} {...{environmentZones}} />
                    </Grid>
                )) : <>
                    <Grid item container lg={4} sm={6} xs={12}>
                        <NodesCard type="orderer" nodes={ordererNodes}  />
                    </Grid>
                    <Grid item container lg={4} sm={6} xs={12}>
                        <NodesCard type="peer" nodes={peerNodes}  />
                    </Grid></>
                }

                <Grid item container lg={4} sm={6} xs={12}>
                    <ServicesCard 
                        services={ipfsInstances}
                        emptyHeader={lt(ServicesEnum.ipfs)}
                        emptyImagePath="Empty-IPFS.svg"
                        emptyDescription={lt('emptyDescriptionIpfs')}
                        servicePath={`${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}`}
                        serviceInfoLink={'https://docs.kaleido.io/kaleido-services/ipfs/'} 
                    />
                </Grid>
                {(!isCorda && !isFabric) && (
                    <>
                        <Grid item container lg={4} sm={6} xs={12}>
                            <ServicesCard services={chainlinkInstances}
                                emptyHeader={lt(ServicesEnum.chainlink)}
                                emptyImagePath="Empty-Chainlink.svg"
                                emptyDescription={lt('emptyDescriptionChainlink')}
                                servicePath={`${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.chainlink}`}
                                serviceInfoLink={'https://www.kaleido.io/blockchain-platform/chainlink'}
                             />
                        </Grid>
                    </>
                )}
                {!isFabric && 
                <Grid item container lg={4} sm={6} xs={12}>
                    <ServicesCard 
                        services={rotateSignersInstances}
                        emptyHeader={lt(ServicesEnum.rotatesigners)}
                        emptyImagePath="Empty-RotateSigners.svg"
                        emptyDescription={environmentSupportsRotateSigners(environment) ? lt('emptyDescriptionRotateSigners') : lt('rotateSignerNotSupported')}
                        servicePath={`${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}`}
                        serviceInfoLink={'https://docs.kaleido.io/kaleido-services/rotate-signers/'}
                        createDisabled={!environmentSupportsRotateSigners(environment) || rotateSignersInstances.length > 0}
                    />
                </Grid>
                }
                {(isFabric) && (
                    <>
                        <Grid item container lg={4} sm={6} xs={12}>
                            <ServicesCard services={caInstances}
                                emptyHeader={lt('certificateAuthorities')}
                                emptyImagePath="Empty-CertificateAuthority.svg"
                                emptyDescription={lt('emptyDescriptionCertificateAuthority')}
                                servicePath={`${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}`}
                                serviceInfoLink={'https://docs.kaleido.io/'} 
                                createDisabled
                                noLinkButton />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    )
};

interface translations extends ServicesTranslations {
    dashboard: string,
    environment: string,
    recentActivity: string,
    transactions: string,
    gatewayAPIs: string
    addANode: string
    certificateAuthorities: string
    createNode: string
    emptyDescription: string
    emptyDocumentation: string
    emptyDescriptionChainlink: string
    emptyDescriptionIpfs: string
    emptyDescriptionCertificateAuthority: string
    emptyDescriptionRotateSigners: string
    rotateSignerNotSupported: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    dashboard: 'Dashboard',
    environment: 'Environment: {{name}}',
    recentActivity: 'Recent activity',
    transactions: 'Transactions',
    gatewayAPIs: 'Gateway APIs',
    certificateAuthorities: 'Certificate Authorities',
    createNode: 'Create Node',
    addANode: 'Add a Node',
    emptyDescription: 'You haven\'t created a blockchain node of your own. Let\'s add a node owned by your membership.',
    emptyDocumentation: 'Documentation',
    emptyDescriptionChainlink: 'Chainlink is a decentralized oracle network used to provide external data to Ethereum smart contracts.',
    emptyDescriptionIpfs: 'IPFS nodes leverage a peer-to-peer file sharing protocol and present a decentralized, censorship resistant mechanism for storing large files and data structures off-chain.',
    emptyDescriptionCertificateAuthority: 'Create an orderer node to register a certificate authority.',
    emptyDescriptionRotateSigners: 'Setup an automated rotation to determine the schedule for nodes to participate in consensus.',
    rotateSignerNotSupported: 'The Rotate Signers service is only supported for environments running the IBFT consensus algorithm.'
}