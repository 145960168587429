import { useQuery } from '@apollo/client';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import WebhookIcon from 'mdi-react/WebhookIcon';
import SwapVerticalCircleOutlineIcon from 'mdi-react/SwapVerticalCircleOutlineIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams, useHistory } from 'react-router-dom';
import { CopyableSetting, CopyableSettings, EditableSettings } from '../../components/DisplaySettings';
import { EditableSettingItem } from '../../components/DisplaySettings/EditableSettings';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { EventStreamData, EventStreamQuery, EventStreamSubscriptionsData, EventStreamSubscriptionsQuery, 
    EventStreamTranslations, EnEventStreamTranslations } from '../../models/eventStreams';
import { DisplayCard, DisplayTable } from '../../components/DisplayWrappers';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { LinkButtonProps, NodeResourceVars, NodeResourcesVars } from '../../interfaces';
import PlusIcon from 'mdi-react/PlusIcon';
import { NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH, NODE_EVENTSTREAMS_PATH, NODE_LOGS_PATH } from '../../components/NodeNav/NodeNav';
import { DeleteResource } from '../../components/DialogWrappers';
import { StreamSuspendResume } from './StreamSuspendResume';

export const NodeEventStream = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStream', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStream:${key}`, interpolate)

    const { pathname } = useLocation()
    const history = useHistory()
    const { org_id,consortium_id, environment_id, node_id, eventstream_id } = 
        useParams<{org_id: string, consortium_id: string, environment_id: string, node_id: string, eventstream_id: string}>();

    const [suspendResumeOpen, setSuspendResumeOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const eventStreamsRedirect = pathname.replace(`/${eventstream_id}`, '')
    const ethconnectLogsRedirect = eventStreamsRedirect.replace(NODE_EVENTSTREAMS_PATH, `${NODE_LOGS_PATH}/ethconnect`)

    const {
        loading: eventStreamLoading,
        data: {
            eventStream
        } = { eventStream: null },
        refetch: refetchEventStream
    } = useQuery<EventStreamData, NodeResourceVars>(EventStreamQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_id: node_id,
            id: eventstream_id
        },
        fetchPolicy: 'cache-first'
    });

    const {
        loading: eventStreamSubscriptionsLoading,
        data: {
            eventStreamSubscriptions: nodeEventStreamSubscriptions
        } = { eventStreamSubscriptions: [] }
    } = useQuery<EventStreamSubscriptionsData, NodeResourcesVars>(EventStreamSubscriptionsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_id: node_id
        },
        fetchPolicy: 'cache-and-network'
    });

    const streamSubscriptions = nodeEventStreamSubscriptions.filter(es => es.stream === eventstream_id)

    if (!eventStreamLoading && !eventStream) return (<Redirect to={eventStreamsRedirect} />)

    const copyableList: CopyableSetting[] = [
        { title: lt('id'), displayValue: eventStream?.id ?? '' },
        { title: lt('name'), displayValue: eventStream?.name ?? '-' },
        { title: lt('path'), displayValue: eventStream?.path ?? '' },
        { 
            title: lt('subscriptions'), 
            displayValue: eventStreamSubscriptionsLoading && !streamSubscriptions.length ? <CircularProgress /> : streamSubscriptions.length.toString(), 
            disableCopy: true
        },
        { title: lt('type'), displayValue: eventStream?.type ? lt(eventStream?.type) : '', disableCopy: true },
        { title: lt('status'), displayValue: eventStream ? <ResourceStateChip state={eventStream.suspended ? 'paused' : 'live'} /> : '', disableCopy: true },
        { title: lt('batchSize'), displayValue: eventStream?.batchSize?.toString() ?? '', disableCopy: true },
        { title: lt('batchTimeoutMS'), displayValue: eventStream?.batchTimeoutMS?.toString() ?? '', disableCopy: true },
        { title: lt('blockedReryDelaySec'), displayValue: eventStream?.blockedReryDelaySec?.toString() ?? '', disableCopy: true },
        { title: lt('errorHandling'), displayValue: eventStream?.errorHandling ?? '', disableCopy: true },
        { title: lt('createdAt'), displayValue: eventStream ? new Date(eventStream.created).toLocaleString() : '', disableCopy: true },
    ]

    const editEventStreamPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_EVENTSTREAMS_PATH}/${eventStream?.id}/edit/1`;

    const streamList: EditableSettingItem[] = [
        eventStream?.type === 'webhook' ? {
            icon: <WebhookIcon />,
            title: lt('webhookSetup'),
            description: lt('webhookSetupDescription'),
            action: () => history.push(editEventStreamPath),
            copyableSettings: [
                { title: lt('url'), displayValue: eventStream?.webhook?.url ?? '' },
                { title: lt('requestTimeoutSec'), displayValue: eventStream?.webhook?.requestTimeoutSec?.toString() ?? '', disableCopy: true },
                { title: lt('verifyTlsCertificates'), displayValue: !eventStream?.webhook?.tlsSkipHostVerify ? lt('yes') : lt('no') }
            ]
        } : {
            icon: <SwapVerticalCircleOutlineIcon />,
            title: lt('websocketSetup'),
            description: lt('websocketSetupDescription'),
            action: () => history.push(editEventStreamPath),
            copyableSettings: [
                { title: lt('topic'), displayValue: eventStream?.websocket?.topic ?? '' },
            ]
        }
    ]

    if (eventStream?.webhook?.headers) {
        let headers: string[] = []
        Object.entries(eventStream.webhook.headers).forEach(k => {
            headers.push(`${k[0]}: ${k[1]}`)
        })
        streamList[0].copyableSettings!.push({ title: lt('headers'), displayValue: headers })
    }

    const docsList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('intro'),
            value: lt('introDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/event-streams/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('api'),
            value: lt('apiDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://api.kaleido.io/ethconnect.html#tag/Event-Streams')
        },
    ]

    const columnHeaders = [
        lt('name'),
        lt('event'),
        lt('createdAt')
    ]

    const records = streamSubscriptions.map(s => ({
        key: s.id,
        onClick: () => history.push(`${pathname}/${NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH}/${s.id}`),
        columns: [
            {value: s.name},
            {value: s.event.name},
            {value: new Date(s.created).toLocaleString()}
        ]
    }))

    const addSubscriptionButton: LinkButtonProps = {
        text: lt('addSubscription'),
        onClick: () => history.push(`${pathname}/${NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH}/create/1`),
        color: 'primary',
        startIcon: <PlusIcon />
    }

    const actionBar = (
        <Grid item container spacing={3} direction="row">
            <Grid item>
                <Button color="default" size="medium" variant="outlined" onClick={() => history.push(ethconnectLogsRedirect)}>
                    {lt('viewLogs')}
                </Button>
            </Grid>
            <Grid item>
                <Button color="default" size="medium" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
                    {lt('delete')}
                </Button>
            </Grid>
            <Grid item>
                <Button color={eventStream?.suspended ? "primary" : "default"} size="medium" variant={eventStream?.suspended ? "contained" : "outlined"} 
                    onClick={() => setSuspendResumeOpen(true)}>
                    {lt(eventStream?.suspended ? 'resume' : 'suspend')}
                </Button>
            </Grid>
        </Grid>
    )

    const actionAfterDelete = () => history.push(eventStreamsRedirect)

    return (
        <>
            <StreamSuspendResume action={eventStream?.suspended ? 'resume' : 'suspend'} open={suspendResumeOpen} setOpen={setSuspendResumeOpen} {...{refetchEventStream}} />

            <DeleteResource {...{actionAfterDelete}} disableNameConfirmation name={eventStream?.id ?? ''} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />

            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('eventStreamDetails')}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container direction="row" spacing={3}>
                    {eventStreamLoading && !eventStream ? 
                    <CircularProgress /> : 
                    <>
                        <Grid item container direction="column" spacing={3} xs={12} lg={8}>
                            <Grid item container>
                                <CopyableSettings header={lt('eventStreamInformation')} {...{actionBar}} {...{copyableList}} />
                            </Grid>
                            <Grid item container>
                                <EditableSettings header={lt(eventStream?.type === 'webhook' ? 'webhookDetails' : 'webSocketDetails')} actionsList={streamList} />
                            </Grid>
                            <Grid item container>
                                <DisplayTable loading={eventStreamSubscriptionsLoading && !streamSubscriptions.length} 
                                    header={lt('subscriptionsHeader', {count: streamSubscriptions.length})} 
                                    description={lt('subscriptionsDescription')} {...{columnHeaders}} {...{records}}
                                    linkButton={addSubscriptionButton} />
                            </Grid> 
                        </Grid>
                    </>
                    }

                    <Grid item xs={6} lg={4}>
                        <DisplayCard header={lt('documentation')} itemList={docsList} />
                    </Grid> 
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends EventStreamTranslations {
    eventStreamDetails: string
    eventStreamInformation: string
    webhookDetails: string
    webSocketDetails: string
    webhookSetup: string
    webhookSetupDescription: string
    websocketSetup: string
    websocketSetupDescription: string
    documentation: string
    intro: string
    introDescription: string
    api: string
    apiDescription: string
    subscriptionsHeader: string
    subscriptionsDescription: string
    addSubscription: string
    yes: string
    no: string
    delete: string
    suspend: string
    resume: string
    viewLogs: string
    topic: string
}
const enTranslations: translations = {
    ...EnEventStreamTranslations,
    eventStreamDetails: 'Event Stream Details',
    eventStreamInformation: 'Event Stream information',
    webhookDetails: 'Webhook details',
    webSocketDetails: 'WebSocket details',
    webhookSetup: 'Webhook Setup Information',
    webhookSetupDescription: 'View the webhook configuration below.',
    websocketSetup: 'WebSocket Setup Information',
    websocketSetupDescription: 'View the WebSocket configuration below.',
    documentation: 'Documentation',
    intro: 'Intro to Event Streams',
    introDescription: 'Consume and log contract events',
    api: 'API Documentation',
    apiDescription: 'API reference for Kaleido',
    subscriptionsHeader: 'Subscriptions ({{count}})',
    subscriptionsDescription: 'View the subscriptions that have been setup for this event stream.',
    addSubscription: 'Add Subscription',
    yes: 'Yes',
    no: 'No',
    delete: 'Delete Stream',
    suspend: 'Suspend Stream',
    resume: 'Resume Stream',
    viewLogs: 'View logs',
    topic: 'Topic',
}