import React, { useState, useEffect, useMemo } from "react";
import { DisplayGridWrapper } from "../../../components/DisplayWrappers";
import { Grid, Select, MenuItem, TextField, Button, CircularProgress, makeStyles } from "@material-ui/core";
import {
    IDRegistryVersionedProfileProperty,
    AddNewProfileEntryData,
    AddNewEntryVars,
    AddProfileEntryMutation,
    IDRegistryProfilePropertiesQuery,
} from "../../../models";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../../components/DialogWrappers";

interface Props {
    profileProperty: IDRegistryVersionedProfileProperty;
    serviceId: string;
    profileAddress: string;
    membershipId: string;
    idRegistryMembershipOrgOwner: string;
    profileId: string;
}

export const MemberProfileCard = ({
    profileProperty,
    serviceId,
    profileAddress,
    membershipId,
    idRegistryMembershipOrgOwner,
    profileId,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "MemberProfileCard", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`MemberProfileCard:${key}`, interpolate);

    const [version, setVersion] = useState<number>(
        profileProperty.values.length - 1
    );
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [editValue, setEditValue] = useState("");
    const [enableEdit, setEnableEdit] = useState(false);

    const [addNewEntry] = useMutation<AddNewProfileEntryData, AddNewEntryVars>(
        AddProfileEntryMutation
    );

    const classes = useStyles();

    const currentValue = useMemo(() => {
        return profileProperty.values[version].value;
    }, [profileProperty, version]);

    useEffect(() => {
        setVersion(profileProperty.values.length - 1);
    }, [profileProperty]);

    const onEditValue = async () => {
        try {
            setLoading(true);
            await addNewEntry({
                variables: {
                    service_id: serviceId,
                    profile_id: profileId,
                    profileEntry: {
                        key_name: profileProperty.name,
                        value: editValue,
                        profile_address: profileAddress,
                        membership_id: membershipId,
                    },
                },
                refetchQueries: [
                    {
                        query: IDRegistryProfilePropertiesQuery,
                        variables: {
                            service_id: serviceId,
                            owner: idRegistryMembershipOrgOwner,
                        },
                    },
                ],
            });
        } catch (err) {
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setLoading(false);
            setEnableEdit(false);
        }
    };

    useEffect(() => {
        setEditValue(currentValue);
    }, [currentValue]);

    const profileContent = (
        profileProperty: IDRegistryVersionedProfileProperty
    ) => (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Select
                    variant="outlined"
                    value={version}
                    onChange={(event) => setVersion(Number(event.target.value))}
                >
                    {profileProperty.values.map((value, index) => (
                        <MenuItem key={`version-${index}`} value={index}>
                            {lt("profilePropertyersionLabel", {
                                versionNumber: index,
                                versionDate: new Date(
                                    Number(value.version)
                                ).toLocaleString(),
                            })}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    disabled={!enableEdit || loading}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    contentEditable={false}
                />
            </Grid>
        </Grid>
    );

    const Actions = (
        <Grid container spacing={1} wrap="nowrap" alignItems="center">
            {loading && (
                <Grid item className={classes.spinner}>
                    <CircularProgress size={30} />
                </Grid>
            )}
            <Grid item>
                <Button
                    disabled={loading}
                    size="small"
                    variant="contained"
                    onClick={() => setEnableEdit((c) => !c)}
                >
                    {enableEdit ? lt("cancel") : lt("edit")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    disabled={
                        !enableEdit || currentValue === editValue || loading
                    }
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onEditValue}
                >
                    {lt("save")}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <>
            <MessageSnackbar {...{ message }} {...{ setMessage }} />
            <DisplayGridWrapper
                actionBar={Actions}
                padDisplayGrid
                header={profileProperty.name}
                displayGrid={profileContent(profileProperty)}
            />
        </>
    );
};

interface translations {
    profilePropertyersionLabel: string;
    save: string;
    edit: string;
    cancel: string;
}

const enTranslations: translations = {
    profilePropertyersionLabel: "Version {{versionNumber}} ({{versionDate}})",
    save: "Save",
    edit: "Edit",
    cancel: "Cancel",
};

const useStyles = makeStyles(() => ({
    spinner: {
        display: 'flex'
    }
}))
