import React, { useState } from 'react';
import Highcharts, { PointOptionsObject, FormatterCallbackFunction } from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more.js"
import SolidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from 'highcharts-react-official';
import { BaseChartOptions } from '../../utils/ChartOptions'
import { DisplayGridWrapper } from '../../components/DisplayWrappers';


interface Props {
    data: PointOptionsObject[],
    title: string,
    labelFormatter?: FormatterCallbackFunction<Highcharts.Point | Highcharts.Series>,
    subtitle?: string
    totalDecimals?: number
    totalPrefix?: string
    customTotalValue?: string
    customTotalSubtitle?: string
    height?: string
}

export const PieChart = ({subtitle, data, title, labelFormatter, customTotalValue, customTotalSubtitle, height = '280px', totalDecimals = 0, totalPrefix = ''}: Props) => {
    Highcharts.setOptions(BaseChartOptions);
    HighchartsMore(Highcharts);
    SolidGauge(Highcharts);

    const containerProps = { style: { width: '100%', height }};

    const [total, setTotal] = useState(0);
    const totalSubtitle = customTotalSubtitle || title;

    const options: Highcharts.Options = {
        chart: {
            plotBorderWidth: 0,
            plotShadow: false,
            events: {
                render: function() {
                    setTotal(this.series[0].data[0].total || 0);
                    var title = this.title;
                    title.attr({ y: this.plotTop + this.plotHeight / 2 + title.getBBox().height / 2 - 15 });
                }
            }
        },
        title: {
            text: `<span style="font-size: 1.5rem; font-weight: 700">${totalPrefix}${total.toFixed(totalDecimals)}</span><br /><span style="font-size: 0.8rem; font-weight:400">${totalSubtitle}</span>`,
            floating: true
        },
        series: [{
            type: 'pie',
            innerSize: '80%',
            startAngle: 0,
            endAngle: 360,
            data,
            center: ['50%', '50%']
        }],
        tooltip: {
            enabled: true,
            formatter: function(this: Highcharts.TooltipFormatterContextObject) {
                return '<b>'+ this.point.name +'</b>: '+ Math.round(this.percentage ?? 0) +' %';
             }
        },
        legend:{
            enabled: true,
            margin: 5,
            itemMarginTop: 20,
            labelFormatter
        },
        plotOptions: {
            pie: {
                size: '110%',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                center: ['50', '50'],
            },
        }
    }

    const initOptions = Highcharts.merge(options);

    const graph = (
        <HighchartsReact
            highcharts={Highcharts}
            options={initOptions}
            containerProps={containerProps}
        />
    )

    return <DisplayGridWrapper header={title} displayGrid={graph} hideDivider {...{subtitle}} /> 
}


