import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useQuery, useSubscription, useApolloClient } from '@apollo/client';
import { MakeConsortiaSubscriptionOptions, ConsortiaSubscription, OrganizationsSubscription, MakeOrganizationsSubscriptionOptions, 
    UserMembershipsSubscription, MakeUserMembershipsSubscriptionOptions, PlansEnum, StaticConfigData, StaticConfigQuery } from '../../models'
import { AppWrapperQuery, AppWrapperResult } from '../../queries'
import { AppContent } from './AppContent';
import { makeStyles, CssBaseline, CircularProgress, Backdrop, useTheme } from '@material-ui/core';
import { AppHeader } from './AppHeader';
import { AppNav } from './AppNav';
import { useParams, Redirect, useLocation } from "react-router-dom";
import ServerError from '../../views/ServerError';
import { ORGS_PATH } from '../ManageOrgNav/ManageOrgNav';
import { SessionData } from '../../interfaces';
import { SessionQuery } from '../../queries/Session';
import { AuthenticationBackgroundWrapper } from '../AuthenticationWrapper/AuthenticationBackgroundWrapper';
import { AuthenticationWrapper } from '../AuthenticationWrapper';
import { ContactInfoQuery, ContactInfoData } from '../../models/contactInfo';
import { encodeQueryData, endsWithAny } from '../../utils/StringUtils';
import { SIGNUP_PLANS_PATHS } from '../../views/SignupPlans/SignupPlans';
import { cognitoController } from '../../utils/cognitoController';
import { KaleidoLogoBlue } from '../Logos/KaleidoBlue';

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex'
    }
}));

interface Props  {
    children: JSX.Element
};

//All the logic for the wrapper will happen here
const AppWrapper = ({ children }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [wait, setWait] = useState(true);
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const { search } = useLocation();
    const { contact_support } = queryString.parse(search);


    const [isDrawerOpen, setDrawer] = useState(false);
    const [refreshingSession, setRefreshingSession] = useState(false);
    const [consortiumQueriesComplete, setConsortiumQueriesComplete] = useState(false);
    const [environmentQueriesComplete, setEnvironmentQueriesComplete] = useState(false);

    //Handle drawer for appNav
    const handleOpenDrawer = () => setDrawer(currentState => !currentState);

    const {
        loading: staticConfigLoading,
        data: {
            staticConfig: {
                disableHubspot
            }
        } = { staticConfig: {} }
    } = useQuery<StaticConfigData>(StaticConfigQuery, {
        fetchPolicy: 'cache-first'
    });

    const {
        loading: contactInfoLoading,
        data: {
            contactInfo
        } = { contactInfo: null }
    } = useQuery<ContactInfoData>(ContactInfoQuery);

    // Setup the consortia and orgs query and subscriptions
    const { 
        loading, 
        data: { 
            organizations,
            memberships
        } = { organizations: [], memberships: [] },
        error
    } = useQuery<AppWrapperResult>(AppWrapperQuery, { fetchPolicy: "cache-and-network" });
    useSubscription(ConsortiaSubscription, MakeConsortiaSubscriptionOptions());
    useSubscription(OrganizationsSubscription, MakeOrganizationsSubscriptionOptions());
    //handle membership cache updates ourselves by setting fetch policy to no cache
    useSubscription(UserMembershipsSubscription, MakeUserMembershipsSubscriptionOptions({ fetchPolicy: 'no-cache' }));

    const client = useApolloClient();

    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    const { pathname } = useLocation();

    useEffect(() => {
        if (org_id) {
            if (session.selected_org !== org_id) {
                setRefreshingSession(true)
                cognitoController.renewSession(client, org_id).then(() => setRefreshingSession(false))
            }
        }
    }, [org_id, client, session])

    if (error) {
        return <ServerError message={error.message} />
    }

    const buildRedirectParams = () => {
        const params: Partial<{ path: string | string[], redirect: string | string[], return_to: string | string[], contact_support: string  | string[]}> = {};
        if (contact_support) params.contact_support = contact_support;

        return encodeQueryData(params as { [key: string]: string });
    }

    const signup = organizations.length === 1 && organizations[0].plan === PlansEnum.signup;

    // we cant load anything unless we have your orgs, memberships, and consortia
    if (loading || contactInfoLoading || staticConfigLoading || !organizations.length) {
        return (
            <Backdrop transitionDuration={0} style={{ backgroundColor: '#F2F2F2', zIndex: theme.zIndex.tooltip, }} open={ true } >
            <KaleidoLogoBlue />
        </Backdrop>
        );
    }

    if (signup) {
        const hasAllHubspotFields = (contactInfo && contactInfo.company && contactInfo.firstname && 
            contactInfo.job_function && contactInfo.lastname) ? true : false

        if (!disableHubspot && !hasAllHubspotFields && pathname !=='/signup') {
            return <Redirect to="/signup" />
        } else if ((disableHubspot || hasAllHubspotFields) && !endsWithAny(SIGNUP_PLANS_PATHS, pathname)) {
            return <Redirect to={`/${ORGS_PATH}/${organizations[0]._id}/signup/plans`} />
        }
    }
    
    const searchParams = buildRedirectParams();
    if (pathname === '/') {
        if(organizations.length === 1) {
            return <Redirect to={`/${ORGS_PATH}/${organizations[0]._id}${searchParams ? `?${searchParams}` : ''}`} />
        } else {
            const prev_org = session.selected_org;
            if(prev_org) {
                return <Redirect to={`/${ORGS_PATH}/${prev_org}${searchParams ? `?${searchParams}` : ''}`} />
            } else {
                const ownedOrg = organizations.find(org => org.owner === session.user_id);
                if(ownedOrg) {
                    return <Redirect to={`/${ORGS_PATH}/${ownedOrg._id}${searchParams ? `?${searchParams}` : ''}`} />
                } else {
                    return <Redirect to={`/${ORGS_PATH}/${organizations[0]._id}${searchParams ? `?${searchParams}` : ''}`} />
                }
            }
        }
    }

    if (org_id && !organizations.some(o => o._id === org_id)) {
        return <Redirect to="/" />
    }

    // cant use m.is_mine up at this level since the memberships query is not refreshed when org is switched.
    const hasMembership = memberships.find(m => m.consortia_id === consortium_id && m.org_id === org_id)

    if (consortium_id && !hasMembership) {
        return <Redirect to={`/orgs/${org_id}?${searchParams ? `?${searchParams}` : ''}`} />
    }

    // if we have a consortium_id prevent loading until the basic consortium queries finish
    // if we have an environment_id prevent loading until the above finishes & the basic environment queries finish
    const canRender = () => {
        if (refreshingSession) return false
        if (environment_id) return environmentQueriesComplete && consortiumQueriesComplete
        if (consortium_id) return consortiumQueriesComplete
        return true
    }
    
    if (signup || endsWithAny(SIGNUP_PLANS_PATHS, pathname)) {
        if (pathname === '/signup') {
            return (
                <AuthenticationWrapper>
                    {children}
                </AuthenticationWrapper>
            )
        } else {
            return (
                <AuthenticationBackgroundWrapper>
                    {children}
                </AuthenticationBackgroundWrapper>
            )
        }
    } else {
        if(wait && canRender()) {
            setTimeout(() => {
                setWait(false)
            }, 0);
        }
        return (
            <div className={ classes.root }>
                <CssBaseline />
                <AppHeader {...{handleOpenDrawer}} disableOrgSwitcher={!canRender()} />
                <AppNav {...{canRender}} {...{setConsortiumQueriesComplete}} {...{setEnvironmentQueriesComplete}} refreshingSession={refreshingSession}
                    isOpen={isDrawerOpen} onClose={() => setDrawer(false)} />
                <AppContent>
                    { canRender() ? children : <CircularProgress /> }
                </AppContent>
                <Backdrop transitionDuration={{ enter: 0, exit: 700}} style={{ zIndex: theme.zIndex.tooltip + 1, backgroundColor: '#F2F2F2' }} open={wait}>
                <KaleidoLogoBlue />
            </Backdrop>
            </div>
        )
    }
};

export { AppWrapper };