import { useQuery } from '@apollo/client';
import { Button, Grid, Typography } from '@material-ui/core';
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { DisplayCard } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { BLOCKCHAIN_BASE_PATH } from '../../components/MainNav/SideNavs/Blockchain';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_ETHER_POOL_PATH } from '../../components/MainNav/SideNavs/DigitalAssets';
import { WebUiCard } from '../../components/WebUICard/WebUICard';
import { EnvironmentResourceVars } from '../../interfaces';
import { ServiceData, ServiceQuery } from '../../models';
import { ChainlinkStatusData, ChainlinkStatusQuery } from '../../models/servicesStatus';

export const ChainlinkOverview = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "ChainlinkOverview", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`ChainlinkOverview:${key}`, interpolate);

    const { consortium_id, service_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();
    const {pathname} = useLocation();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const queryVariables = {
        consortia_id: consortium_id,
        id: service_id,
        environment_id: environment_id
    }

    const {
        data: {
            service
        } = {service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: queryVariables,
        fetchPolicy: 'cache-only'
    });

    const {
        loading: refetchChainlinkStatusLoading,
        refetch: refetchChainlinkStatus,
        data : {
            chainlinkStatus
        } = {chainlinkStatus: null}
    } = useQuery<ChainlinkStatusData, EnvironmentResourceVars>(ChainlinkStatusQuery, {
        variables: queryVariables,
        fetchPolicy: 'cache-and-network'
    });

    // when service comes up for the first time, refetch the status
    const refetchedChainlinkStatusOnCreation = useRef<boolean>(false)
    useEffect(() => {
        if (!chainlinkStatus && !refetchChainlinkStatusLoading && service?.state === "started" && !refetchedChainlinkStatusOnCreation.current) {
            refetchedChainlinkStatusOnCreation.current = true
            refetchChainlinkStatus()
        }
    }, [chainlinkStatus, refetchChainlinkStatus, service, refetchChainlinkStatusLoading])

    if (!service) return <Redirect to={`${basePath}/${BLOCKCHAIN_BASE_PATH}`} />

    const copyableList: CopyableSetting[] = [
        {
            title: lt("name"),
            displayValue: service.name || "",
            disableCopy: true,
        }, {
            title: lt("id"),
            displayValue: service._id || '',
        }, {
            title: lt("status"),
            displayValue: service.state ? (
                <ResourceStateChip state={service.state} />
            ) : (
                ""
            ),
            disableCopy: true,
        }, {
            title: lt("owner"),
            displayValue: service.membership.name || "",
            disableCopy: true,
        }, {
            title: lt('createdOn'),
            displayValue: new Date(service.created_at || '').toLocaleString(),
            disableCopy: true,
        }
    ]
    
    if (service.urls?.http) {
        copyableList.push({
            title: lt('apiEndpoint'),
            displayValue: service.urls?.http || ''
        }) 
    }

    if (chainlinkStatus) {
        copyableList.push({
            title: lt('username'),
            displayValue: chainlinkStatus?.username || ''
        }, {
            title: lt('password'),
            displayValue: chainlinkStatus?.password || ''
        }, {
            title: lt('address'),
            displayValue: chainlinkStatus?.address || ''
        }, {
            title: lt('linkContractAddress'),
            displayValue: chainlinkStatus?.linkContractAddress || ''
        }, {
            title: lt('oracleContractAddress'),
            displayValue: chainlinkStatus?.oracleContractAddress || ''
        })
    }

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("chainlink"),
            value: lt("blog"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://www.kaleido.io/blockchain-platform/chainlink"
                ),
        }, {
            icon: <DescriptionIcon />,
            title: lt("chainlinkDocumentation"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://docs.kaleido.io/kaleido-services/3rd-party-services/chainlink/"
                ),
        }
    ];

    const FundButton = (
        <Button color="primary" variant="contained" size="medium" disabled={!chainlinkStatus ? true : false}
            onClick={() => history.push(`${basePath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_ETHER_POOL_PATH}/create/1`, {
                address: chainlinkStatus?.address,
                tokenAddress: chainlinkStatus?.linkContractAddress,    
                cancelPath: pathname
            })}>
            {lt('fundAccount')}
        </Button>
    )

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">
                    {lt("chainlinkOverview")}
                </Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <CopyableSettings
                        actionBar={FundButton}
                        header={lt("information")}
                        {...{ copyableList }}
                    />
                </Grid>
                <Grid item container direction="column" xs={12} lg={4} spacing={3} >
                    <Grid item container>
                        <WebUiCard header={lt('webUI')} disabled={service.state !== 'started'}
                            imageFiles={'Chainlink-Screen-2x.png'}  
                            description={lt('webUiDescription')}
                            onClick={() => window.open(service.urls?.http || '')} />
                    </Grid>
                    <Grid item container>
                        <DisplayCard
                            header={lt("documentation")}
                            itemList={DocumentationList}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    chainlinkOverview: string
    name: string
    id: string
    status: string
    owner: string
    createdOn: string
    apiEndpoint: string
    webUI: string,
    username: string
    documentation: string
    information: string
    webUiDescription: string
    chainlinkDocumentation: string
    chainlink: string
    blog: string
    password: string
    linkContractAddress: string
    oracleContractAddress: string
    fundAccount: string
    address: string
};

const enTranslations: translations = {
    name: 'Name',
    blog: 'Blog',
    address: 'Address',
    information: 'Information',
    chainlinkDocumentation: 'Chainlink Documentation',
    chainlink: 'Chainlink',
    fundAccount: 'Fund Account',
    id: 'ID',
    status: 'Status',
    owner: 'Owner',
    createdOn: 'Created On',
    apiEndpoint: 'API Endpoint',
    chainlinkOverview: 'Chainlink Overview',
    webUI: 'Web UI',
    username: 'Username',
    documentation: 'Documentation',
    webUiDescription: 'Access Chainlink Operator UI to monitor oracle activity. You will be able to view the oracle configuration and manage jobs, runs, bridges and transactions.',
    password: 'Password',
    linkContractAddress: 'Link Contract Address',
    oracleContractAddress: 'Oracle Contract Address'
};