import { useMutation } from '@apollo/client';
import { Box, FormControlLabel, Grid, IconButton, Switch, TextField } from "@material-ui/core";
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import React, { useMemo, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { CreateCordaUserData, CreateCordaUserMutation, CreateCordaUserVars } from '../../models';
import { MessageSnackbar } from '../DialogWrappers';
import { isStrongPassword } from './PasswordUtils';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    afterSave: () => void,
}

export const AddCordaUserDialog = ({ open, setOpen, afterSave }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AddCordaUserDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddCordaUserDialog:${key}`, interpolate)

    const { consortium_id, environment_id, node_id } = useParams<any>();
    const nodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        node_id: node_id!
    }

    const [username, setUsername] = useState('');
    const [roles, setRoles] = useState('');
    const [password, setPassword] = useState('');
    const [generate, setGenerate] = useState(true);
    const [okMessage, setOkMessage] = useState('');
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [displayPasswordOpen, setDisplayPasswordOpen] = useState(false);

    const [addCordaUser, { loading }] = useMutation<CreateCordaUserData, CreateCordaUserVars>(CreateCordaUserMutation)

    useEffect(() => {
        if (open) {
            setUsername('');
            setRoles('');
            setPassword('');
            setGenerate(true);
            setGeneratedPassword('');
        }
    }, [open])

    const onSave = async () => {
        const { data } = await addCordaUser({
            variables: {
                ...nodeVars,
                user: {
                    username,
                    roles: roles.split(',').map(r => r.trim()),
                    password: generate ? undefined : password,
                }
            }
        });
        const generatedPassword = data?.createCordaUser.password;
        if (generatedPassword) {
            setGeneratedPassword(generatedPassword);
            setDisplayPasswordOpen(true);
        }
        afterSave();
        setOpen(false);
        setOkMessage(lt('createdOk', {username}));
    }

    const strongPassword = useMemo(() => isStrongPassword(password), [password]);

    const controlsWrapper = <>
        <Grid container direction="column" spacing={2}>
            <Grid item xs>
                <TextField data-test="textField_username"
                    label={lt('usernameHelp')}
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                    autoFocus
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <TextField data-test="textField_roles"
                    label={lt('rolesHelp')}
                    value={roles}
                    onChange={event => setRoles(event.target.value)}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid item container justify="flex-start">
                <Grid item>
                    <FormControlLabel
                        label={lt('generateHelp')}
                        labelPlacement="top"
                        control={
                            <Switch data-test="switchField_generate"
                                checked={generate}
                                onChange={event => setGenerate(event.target.checked)}
                                color="primary"
                            />
                        }/>
                </Grid>
                <Grid item xs>
                    <TextField data-test="pwtField_password"
                        label={(password && !strongPassword) ? lt('passwordStrength') : lt('passwordHelp')}
                        type="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        disabled={generate}
                        error={password && !strongPassword ? true : false}
                    />
                </Grid>
            </Grid>
        </Grid>
    </>

    const displayPasswordWrapper = <>
        <Grid container justify="center" spacing={3} alignItems="center">
            <Grid item>
                <Box fontFamily="monospace">{generatedPassword}</Box>
            </Grid>
            <Grid item>
                <CopyToClipboard text={generatedPassword}>
                    <IconButton color="inherit" size="small">
                        <ContentCopyIcon />
                    </IconButton>
                </CopyToClipboard>
            </Grid>
        </Grid>
    </>;

    return (<>
        <MessageSnackbar messageType="success" message={okMessage} setMessage={setOkMessage}/>
        <FormDialog
            open={displayPasswordOpen} 
            setOpen={setDisplayPasswordOpen} 
            header={lt('displayPassword')} 
            description={lt('lastChance')}
            controlsWrapper={displayPasswordWrapper}
            closeDialogAfterSave={false}
            hideSave
            cancelText={lt('gotIt')}
            />
        <FormDialog
            {...{open}} 
            {...{setOpen}} 
            header={lt('createUser')} 
            description={lt('createDescription')} 
            {...{controlsWrapper}}
            saveDisabled={loading || !username || !roles || !(strongPassword || generate)}
            {...{onSave}}
            closeDialogAfterSave={false}
            saveText={lt('create')} />
    </>);
};

interface translations {
    createUser: string,
    createDescription: string,
    usernameHelp: string,
    rolesHelp: string,
    passwordHelp: string,
    generateHelp: string,
    create: string,
    createdOk: string,
    displayPassword: string,
    lastChance: string,
    gotIt: string,
    passwordStrength: string,
}
const enTranslations: translations = {
    createUser: 'Create a new Corda RPC user',
    createDescription: 'Set a username, list of roles, and a secure password - or let us generate one',
    usernameHelp: 'Enter the new username',
    rolesHelp: 'Enter a comma separated list of role names',
    passwordHelp: 'Enter a secure password',
    generateHelp: 'Generate password',
    create: 'Create',
    createdOk: 'New user "{{usernmae}}" created',
    displayPassword: 'Generated password for user',
    lastChance: 'This is the last time the password will be displayed',
    gotIt: 'Got it',
    passwordStrength: 'At least 8 chars, with mixed case + numbers or symbols',
}