import { useQuery, useApolloClient, useMutation } from "@apollo/client";
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
    CreateWrapper,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../components/DialogWrappers";
import { CreateStepProps } from "../../interfaces";
import {
    EnEnvironmentProviderTranslations,
    EnvironmentConsensus,
    EnvironmentProvider,
    EnvironmentProviderTranslations,
    FeatureToggles,
    FeatureTogglesData,
    FeatureTogglesQuery,
    FeatureTogglesVars,
    OrganizationData,
    OrganizationVars,
    OrganizationQuery,
    PlanSupports,
    CreateEnvironmentVars,
    CreateEnvironmentData,
    CreateEnvironmentMutation,
    CreateEnvironmentZoneData,
    CreateEnvironmentZoneVars,
    CreateEnvironmentZoneMutation,
    MakeEnvironmentCreateMutationOptions,
    MakeEnvironmentZoneCreateMutationOptions,
    ConsortiumZone,
    EnvironmentPrefundedAccounts,
    Protocol,
    EnvironmentType,
    EnvironmentStateDB,
} from "../../models";
import { Step4HelpCorda } from "./Step4HelpCorda";
import { Step4HelpEth } from "./Step4HelpEth";
import { Paywall } from "../../components/FormControls/Paywall";
import { ProtocolConfigurations } from "./ProtocolConfigurations";
import { RegionSelector } from '../../components/FormControls/RegionSelector';
import { Step4HelpFabric } from "./Step4HelpFabric";


interface Props extends CreateStepProps {
    environmentType: EnvironmentType,
    name: string,
    hybrid: boolean,
    protocol: Protocol,
    zoneId: string,
    setZoneId: React.Dispatch<React.SetStateAction<string>>,
    consortiumZones: ConsortiumZone[],
};

type EnvironmentProviderSuperset =
    | EnvironmentProvider
    | "corda_enterprise"
    | "pegasys_plus";
const NonDeployableProviders = ["corda_enterprise", "pegasys_plus"];

export const Step4 = ({ environmentType, protocol, name, hybrid, cancelPath, zoneId, setZoneId, consortiumZones }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle(
        "en",
        "CreateEnvironmentCreateStep4",
        enTranslations
    );
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateEnvironmentCreateStep4:${key}`, interpolate);

    const history = useHistory();
    const { org_id, consortium_id } = useParams<any>();

    const [message, setMessage] = useState("");
    const [provider, setProvider] = useState<EnvironmentProvider>("geth");
    const [consensus, setConsensus] = useState<EnvironmentConsensus>("poa");
    const [statedb, setStateDB] = useState<EnvironmentStateDB>("leveldb")
    const [chainId, setChainId] = useState('');
    const [blockPeriod, setBlockPeriod] = useState('5');
    const [prefundedAccounts, setPrefundedAccounts] = useState<
        EnvironmentPrefundedAccounts[]
    >([]);

    const handleProviderChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setProvider(event.target.value as EnvironmentProvider);
    };
    const handleConsensusChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setConsensus(event.target.value as EnvironmentConsensus);
    };
    const handStateDBChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setStateDB(event.target.value as EnvironmentStateDB);
    };
    const [
        createEnvironment,
        { loading: createEnvironmentLoading },
    ] = useMutation<CreateEnvironmentData, CreateEnvironmentVars>(
        CreateEnvironmentMutation
    );
    const [createEnvironmentZone] = useMutation<
        CreateEnvironmentZoneData,
        CreateEnvironmentZoneVars
    >(CreateEnvironmentZoneMutation);

    const mapProviderValue = (s: EnvironmentProvider) => s;
    const mapConsensusValue = (s: EnvironmentConsensus) => s;
    const mapStateDBValue = (s: EnvironmentStateDB) => s;

    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles },
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, {
        variables: {},
        fetchPolicy: "cache-first",
    });

    const client = useApolloClient();
    const { organization } = client.readQuery<
        OrganizationData,
        OrganizationVars
    >({ query: OrganizationQuery, variables: { id: org_id! } })!;

    const supportedProviderConsensusMappings: Partial<
        {
            [key in EnvironmentProvider]: EnvironmentConsensus[];
        }
    >  = useMemo(() => {
        return {
            quorum: ["ibft", "raft"],
            geth: ["poa"],
            pantheon: ["poa", "ibft"],
            autonity: ["tendermint"],
            corda: ["single-notary"],
            fabric: ["raft"],
            "polygon-edge": ["ibft"],
        };
     }, []);

    const isFirefly = environmentType === 'firefly'

    const unsupportedMultiRegionCombo = useCallback((provider: EnvironmentProvider, consensus: EnvironmentConsensus) => {
        return provider === 'quorum' && consensus === 'raft';
    }, []);

    const isConsensusDisabled = useCallback((s: EnvironmentConsensus) => {
        let forProvider = !supportedProviderConsensusMappings[provider]?.some(c => c === s) ?? true
        let regionRestricted = (hybrid || isFirefly) ? unsupportedMultiRegionCombo(provider, s) : false;
        return forProvider || regionRestricted;
    }, [provider, supportedProviderConsensusMappings, hybrid, unsupportedMultiRegionCombo, isFirefly])

    useEffect(() => {
        if (isConsensusDisabled(consensus)) {
            let c = supportedProviderConsensusMappings[provider];
            if (c && c[0]) {
                setConsensus(c[0]);
            }
        }
    }, [
        consensus,
        setConsensus,
        isConsensusDisabled,
        provider,
        supportedProviderConsensusMappings,
    ]);

    const isCorda = protocol === "corda";
    const isEthereum = protocol === "ethereum";
    const isFabric = protocol === "fabric";

    useEffect(() => {
        if (isCorda) {
            setProvider("corda");
            setConsensus("single-notary");
        }
        if (isFabric) {
            setProvider("fabric");
            setConsensus("raft");
        }
    }, [isCorda, isEthereum, isFabric, setConsensus, setProvider]);

    const makeProviderRadio = (
        p: EnvironmentProviderSuperset,
        label: string,
        description: string,
        logo?: string,
        logoAlt?: string,
        beta?: boolean
    ) => {
        const disabled = NonDeployableProviders.includes(p)
        return (
            <Grid item>
                <Paper
                    className={
                        provider === p
                            ? classes.radioPaperSelected
                            : classes.radioPaper
                    }
                    elevation={0}
                    onClick={() =>
                        disabled || setProvider(p as EnvironmentProvider)
                    }
                >
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <FormControlLabel
                                        {...{ disabled }}
                                        checked={provider === p}
                                        value={mapProviderValue(
                                            p as EnvironmentProvider
                                        )}
                                        label={
                                            <Grid container direction="row" alignItems="center" alignContent="center" justify='center' spacing={1} wrap='nowrap'>
                                                <Grid item>{label}</Grid>
                                                {beta && 
                                                <Grid item>
                                                    <Box component="span" className={classes.betaTag}>{lt('beta')}</Box>
                                                </Grid>}
                                            </Grid>
                                        }
                                        control={<Radio color="primary" />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    className={classes.radioDescription}
                                    xs
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {logo && (
                            <Grid item className={classes.radioDescription}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/img/protocols/${logo}-logo.svg`}
                                    alt={logoAlt}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        );
    };

    const makeConsensusRadio = (c: EnvironmentConsensus) => {
        return (
            <FormControlLabel
                checked={consensus === c}
                value={mapConsensusValue(c)}
                control={<Radio color="primary" />}
                label={lt(c)}
                disabled={isConsensusDisabled(c)}
            />
        );
    };

    const makeStateDBRadio = (c: EnvironmentStateDB) => {
        return (
            <FormControlLabel
                checked={statedb === c}
                value={mapStateDBValue(c)}
                control={<Radio color="primary" />}
                label={lt(c)}
            />
        );
    };

    const multiRegion = hybrid || !unsupportedMultiRegionCombo(provider, consensus) || isFirefly;
    const readyToGo = (multiRegion || zoneId) && name && !createEnvironmentLoading;

    const save = async () => {
        let new_env: CreateEnvironmentVars = {
            consortia_id: consortium_id!,
            environment: {
                name,
                provider: provider,
                consensus_type: consensus,
                test_features: {
                    multi_region: multiRegion,
                    hybrid: hybrid
                },
                optional_features: {
                    firefly: isFirefly
                },
                block_period: (consensus !== 'raft' && PlanSupports.blockPeriod(organization)) ? parseInt(blockPeriod) : undefined,
                chain_id: chainId ? parseInt(chainId) : undefined,
                prefunded_accounts: prefundedAccounts.length > 0 ? prefundedAccounts : undefined,
            }
        }
        if(isFabric) {
            new_env.environment.state_db = statedb;
        }
        // create the single region environment zone if not multi region
        const env = await createEnvironment(MakeEnvironmentCreateMutationOptions(new_env)).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
            return null
        })

        const newEnvId = env?.data?.createEnvironment?._id
        if (newEnvId) {
            // create the single region environment zone if not multi region
            if (!multiRegion && zoneId) {
                const { region, cloud, type } = consortiumZones.find(
                    (c) => c._id === zoneId
                )!;
                await createEnvironmentZone(
                    MakeEnvironmentZoneCreateMutationOptions({
                        consortia_id: consortium_id!,
                        environment_id: newEnvId,
                        environmentZone: {
                            region,
                            cloud,
                            type,
                        },
                    })
                ).catch((e) => {
                    ErrorSnackbarCatcher(e, setMessage);
                    return null;
                });
            }
            history.push(
                `/orgs/${org_id}/consortia/${consortium_id}/environments/${newEnvId}`
            );
        }
    };

    const paywalled =
        provider && !PlanSupports.supportsProvider(organization, provider);

    const autonityEnabled = featureToggles?.autonity;

    const advanceSettingsEnabled = featureToggles?.nodeConfigs;

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt("header", { name })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt("headerDescription")}
                </Typography>
            </Grid>

            {paywalled && (
                <Grid item>
                    <Paywall description={lt("paywall")} />
                </Grid>
            )}

            <Grid item container direction="column">
                <Typography variant="h5" gutterBottom>
                    {lt("provider")}
                </Typography>
                <FormControl component="fieldset" margin="none">
                    <RadioGroup
                        value={provider}
                        onChange={handleProviderChange}
                    >
                        <Grid container direction="column" spacing={1}>
                            {isEthereum &&
                                makeProviderRadio(
                                    "geth",
                                    lt("geth"),
                                    lt("gethDescription"),
                                    "geth",
                                    lt("geth")
                                )}
                            {isEthereum &&
                                makeProviderRadio(
                                    "quorum",
                                    lt("quorum"),
                                    lt("quorumDescription"),
                                    "quorum",
                                    lt("quorum")
                                )}
                            {isEthereum &&
                                makeProviderRadio(
                                    "pantheon",
                                    lt("pantheon"),
                                    lt("besuDescription"),
                                    "besu",
                                    lt("pantheon")
                                )}
                            {isEthereum &&
                                makeProviderRadio(
                                    "polygon-edge",
                                    lt("polygon-edge"),
                                    lt("polygon-edgeDescription"),
                                    "polygon-edge",
                                    lt("polygon-edge"),
                                    true
                                )}
                            {isEthereum &&
                                autonityEnabled &&
                                makeProviderRadio(
                                    "autonity",
                                    lt("autonity"),
                                    lt("autonityDescription")
                                )}
                            {isCorda &&
                                makeProviderRadio(
                                    "corda",
                                    lt("corda"),
                                    lt("cordaDescription"),
                                    "corda",
                                    lt("corda")
                                )}
                            {isCorda &&
                                makeProviderRadio(
                                    "corda_enterprise",
                                    lt("cordaEnterprise"),
                                    lt("cordaEnterpriseDescription"),
                                    "r3",
                                    lt("r3")
                                )}
                            {isFabric &&
                                makeProviderRadio(
                                    "fabric",
                                    lt("fabric"),
                                    lt("fabricDescription"),
                                    "fabric",
                                    lt("fabric")
                                )}
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item container direction="column">
                <Typography variant="h5" gutterBottom>
                    {lt("consensusAlgorithm")}
                </Typography>
                <FormControl component="fieldset" margin="none">
                    <RadioGroup
                        value={consensus}
                        onChange={handleConsensusChange}
                    >
                        {isEthereum && makeConsensusRadio("poa")}
                        {isEthereum && makeConsensusRadio("raft")}
                        {isEthereum && makeConsensusRadio("ibft")}
                        {isEthereum &&
                            autonityEnabled &&
                            makeConsensusRadio("tendermint")}
                        {isCorda && makeConsensusRadio("single-notary")}
                        {isFabric && makeConsensusRadio("raft")}
                    </RadioGroup>
                </FormControl>
                {isFabric && 
                <Grid item >
                    <Typography variant="h5" gutterBottom>
                        {lt("fabricConfigurations")}
                    </Typography>
                    <FormControl component="fieldset" margin="none">
                        <RadioGroup
                            value={consensus}
                            onChange={handStateDBChange}
                        >
                            {makeStateDBRadio("leveldb")}
                            {makeStateDBRadio("couchdb")}
                        </RadioGroup>
                    </FormControl>                
                </Grid>
                }
            </Grid>
            {!multiRegion && <>
                <Grid item>
                    <Typography variant="h5">
                        {lt('selectRegion')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        {lt('regionLimited')}
                    </Typography>
                </Grid>
                <Grid item>
                    <RegionSelector {...{zoneId}} {...{setZoneId}} {...{consortiumZones}} />
                </Grid>
            </>}
            {isEthereum && advanceSettingsEnabled && (
                <Grid item>
                    <ProtocolConfigurations
                        {...{ chainId }}
                        {...{ setChainId }}
                        {...{ blockPeriod }}
                        {...{ setBlockPeriod }}
                        {...{ prefundedAccounts }}
                        {...{ setPrefundedAccounts }}
                        {...{ consensus }}
                        {...{isFirefly}}
                        {...{provider}}
                    />
                </Grid>
            )}
        </>
    );

    return (
        <>
            <MessageSnackbar {...{ message }} {...{ setMessage }} />
            <CreateWrapper
                {...{ cancelPath }}
                {...{ content }}
                disabled={!readyToGo || createEnvironmentLoading || paywalled}
                isLastStep
                onNext={save}
            />
            {isEthereum && <Step4HelpEth />}
            {isCorda && <Step4HelpCorda />}
            {isFabric && <Step4HelpFabric />}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    radioPaper: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.default,
        borderStyle: "solid",
        borderColor: theme.palette.background.default,
        borderWidth: "3px",
        cursor: "pointer",
    },
    radioPaperSelected: {
        padding: theme.spacing(1, 2),
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        borderWidth: "3px",
        cursor: "pointer",
    },
    radioDescription: {
        paddingLeft: theme.spacing(4),
    },
    betaTag: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: '1px 3px 1px 3px',
        borderRadius: '2px',
        textTransform: 'uppercase',
        fontSize: 'small'
    },
}));

interface translations extends EnvironmentProviderTranslations {
    header: string;
    headerDescription: string;
    provider: string;
    consensusAlgorithm: string;
    gethDescription: string;
    quorumDescription: string;
    besuDescription: string;
    autonityDescription: string;
    cordaDescription: string;
    cordaEnterprise: string;
    cordaEnterpriseDescription: string;
    fabricDescription: string,
    fabricConfigurations: string,
    couchdb: string,
    leveldb: string,
    poa: string;
    raft: string;
    ibft: string;
    tendermint: string;
    "single-notary": string;
    r3: string;
    paywall: string;
    selectRegion: string,
    regionLimited: string,
    'polygon-edgeDescription': string
    beta: string
}
const enTranslations: translations = {
    ...EnEnvironmentProviderTranslations,
    header: "Select Provider Settings - {{name}}",
    headerDescription: "Choose your provider and associated algorithm.",
    provider: "Provider",
    consensusAlgorithm: "Consensus Algorithm",
    gethDescription: "Open source Ethereum",
    quorumDescription:
        "Open source Enterprise Ethereum with privacy externsions, built on Go Ethereum",
    besuDescription:
        "Open source Enterprise Ethereum with privacy extensions, built in Java",
    autonityDescription: "Geth based client for financial markets",
    cordaDescription: "Open source blockchain platform",
    cordaEnterprise: "Corda Enterprise (Coming Soon)",
    cordaEnterpriseDescription:
        "Additional features for security, reslience and scale provided by R3",
    fabricDescription: "Hyperledger Fabric 2.4",
    fabricConfigurations: "State Database",
    couchdb: "CouchDB",
    leveldb: "LevelDB",
    poa: "POA",
    raft: "Raft",
    ibft: "IBFT",
    tendermint: "Tendermint",
    "single-notary": "Managed Notary",
    r3: "R3",
    paywall:
        "This provider is not available on your current plan. Please contact us", // contact us is because it's actualy Business/Enterprise where we have providers disabled (Corda at time of writing)
    selectRegion: 'Select Deployment Zone',
    regionLimited: 'Multi-region deployment is not supported with your chosen consensus algorithm. Please choose a region.',
    'polygon-edgeDescription': 'Open source Ethereum-compatible chain, powered by the Polygon community',
    beta: 'Beta'
};
