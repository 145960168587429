import { useQuery } from "@apollo/client";
import { Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, MessageSnackbar } from '../../components/DialogWrappers';
import { RadioSelector, RadioSelectorOptions } from "../../components/FormControls/RadioSelector";
import { CREATE_NODES_PATH } from '../../components/NodeNav/NodePicker';
import { ConsortiumResourceVars, CreateStepProps, EnvironmentResourcesVars } from '../../interfaces';
import { MembershipData, MembershipQuery, ServicesData, ServicesQuery, NodeRole, FabricCaServiceDetails, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars } from '../../models';
import { Step2Help } from './Step2Help';

type CaType = 'selfSigned' | 'customKeys' | 'externalServer'

interface Props extends CreateStepProps {
    name: string,
    membershipId: string,
    zoneId: string,
    role: NodeRole,
    createFabricCaName: string
    setCreateFabricCaName: React.Dispatch<React.SetStateAction<string>>
    fabricCaServiceDetails?: FabricCaServiceDetails
    setFabricCaServiceDetails?: React.Dispatch<React.SetStateAction<FabricCaServiceDetails | undefined>>
};

export const Step2 = ({ 
        name, membershipId, zoneId, cancelPath, role, createFabricCaName, setCreateFabricCaName, fabricCaServiceDetails, setFabricCaServiceDetails }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep2:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const [caName, setCAName] = useState(createFabricCaName);
    const [message, setMessage] = useState('');

    const [caType, setCaType] = useState<CaType>(fabricCaServiceDetails?.enroll_with_ca ? 'externalServer' : fabricCaServiceDetails?.external_key ? 'customKeys' : 'selfSigned');

    // customKeys
    const [externalCert, setExternalCert] = useState(fabricCaServiceDetails?.external_cert);
    const [externalKey, setExternalKey] = useState(fabricCaServiceDetails?.external_key);

    // externalServer
    const [externalUrl, setExternalUrl] = useState(fabricCaServiceDetails?.enroll_with_ca?.parentserver.url);
    const [externalName, setExternalName] = useState(fabricCaServiceDetails?.enroll_with_ca?.parentserver.caname);
    const [enrollmentHosts, setEnrollmentHosts] = useState(fabricCaServiceDetails?.enroll_with_ca?.enrollment?.hosts);
    const [enrollmentProfile, setEnrollmentProfile] = useState(fabricCaServiceDetails?.enroll_with_ca?.enrollment?.profile);
    const [enrollmentLabel, setEnrollmentLabel] = useState(fabricCaServiceDetails?.enroll_with_ca?.enrollment?.label);
    const [tlsCertFiles, setTlsCertFiles] = useState(fabricCaServiceDetails?.enroll_with_ca?.tls?.certfiles?.join('\n'));
    const [tlsClientCertFile, setTlsClientCertFile] = useState(fabricCaServiceDetails?.enroll_with_ca?.tls?.client?.certfile);
    const [tlsClientKeyFile, setTlsClientKeyFile] = useState(fabricCaServiceDetails?.enroll_with_ca?.tls?.client?.keyfile);

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        fetchPolicy: 'cache-first'
    });

    const { 
        data: { 
            services
        } = { services: [] },
        loading: loadingServices
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        }
    });

    const { 
        data: { 
            membership
        } = { membership: undefined },
        loading: loadingMembership
    } = useQuery<MembershipData, ConsortiumResourceVars>(MembershipQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: membershipId!,
        }
    });

    const existingCA = services.find(s => s.membership_id === membershipId && s.service === 'fabric-ca');

    useEffect(() => {
        if (existingCA) {
            setCAName(existingCA.name);
            if (setFabricCaServiceDetails) {
                setFabricCaServiceDetails(existingCA.details)
                if (existingCA.details.external_key) {
                    setCaType('customKeys')
                    setExternalCert(fabricCaServiceDetails?.external_cert);
                    setExternalKey(fabricCaServiceDetails?.external_key);
                } else if (existingCA.details.enroll_with_ca) {
                    setCaType('externalServer')
                    setExternalUrl(fabricCaServiceDetails?.enroll_with_ca?.parentserver.url);
                    setExternalName(fabricCaServiceDetails?.enroll_with_ca?.parentserver.caname);
                    setEnrollmentHosts(fabricCaServiceDetails?.enroll_with_ca?.enrollment?.hosts);
                    setEnrollmentProfile(fabricCaServiceDetails?.enroll_with_ca?.enrollment?.profile);
                    setEnrollmentLabel(fabricCaServiceDetails?.enroll_with_ca?.enrollment?.label);
                    setTlsCertFiles(fabricCaServiceDetails?.enroll_with_ca?.tls?.certfiles?.join('\n'));
                    setTlsClientCertFile(fabricCaServiceDetails?.enroll_with_ca?.tls?.client?.certfile);
                    setTlsClientKeyFile(fabricCaServiceDetails?.enroll_with_ca?.tls?.client?.keyfile);
                } else {
                    setCaType('selfSigned')
                }
            }
        } else if (createFabricCaName) {
            setCAName(createFabricCaName)
        } else if (membership) {
            setCAName(`${membership.org_name} CA`);
        }
    }, [existingCA, membership, createFabricCaName, setFabricCaServiceDetails, fabricCaServiceDetails])

    const disabled = loadingMembership || loadingServices || !caName;

    const buildFabricCaServiceDetails: () => FabricCaServiceDetails | undefined = () => {
        if (caType === 'customKeys' && externalKey && externalCert) {
            return { 
                external_cert: externalCert, 
                external_key: externalKey 
            }
        } else if (caType === 'externalServer' && externalUrl) {
            return {
                enroll_with_ca: {
                    parentserver: {
                        url: externalUrl,
                        caname: externalName
                    },
                    enrollment: {
                        hosts: enrollmentHosts,
                        profile: enrollmentProfile,
                        label: enrollmentLabel,
                    },
                    tls: {
                        certfiles: tlsCertFiles ? [tlsCertFiles] : undefined,
                        client: {
                            certfile: tlsClientCertFile,
                            keyfile: tlsClientKeyFile
                        }
                    }
                }
            }
        }
    }

    const save = () => {
        if (!existingCA) {
            setCreateFabricCaName(caName)
        }
        const fabricCaServiceDetails = buildFabricCaServiceDetails()
        if (setFabricCaServiceDetails) {
            setFabricCaServiceDetails(buildFabricCaServiceDetails())
        }
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${CREATE_NODES_PATH}/3`, {
            name,
            membershipId,
            zoneId,
            role,
            createFabricCaName: !existingCA ? caName : '',
            fabricCaServiceDetails: fabricCaServiceDetails
        })
    }

    const radioOptions: RadioSelectorOptions[] = [
        {
            value: "selfSigned",
            label: lt("selfSigned"),
            description: lt("selfSignedDescription"),
            isDisabled: !!existingCA
        },
        {
            value: "customKeys",
            label: lt("customKeys"),
            description: lt("customKeysDescription"),
            isDisabled: !!existingCA || !featureToggles?.externalCA
        },
        {
            value: "externalServer",
            label: lt("externalServer"),
            description: lt("externalServerDescription"),
            isDisabled: !!existingCA || !featureToggles?.externalCA
        },
    ];

    const certPlaceholder = "ex:\n-----BEGIN CERTIFICATE-----\nMIIBfDCC...INCN\n-----END CERTIFICATE-----"
    const keyPlaceHolder = "ex:\n-----BEGIN PRIVATE KEY-----\nMIGHAgEA...JUOg\n-----END PRIVATE KEY-----"

    const customKeysContent = (
        <>
            <Grid item>
                <Typography variant="h6">
                    {lt('customKeys')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption" color="textSecondary">
                    {lt('externalKeyDescription')}
                </Typography>
                <TextField
                    value={externalKey} required multiline minRows={3}
                    placeholder={keyPlaceHolder}
                    onChange={event => setExternalKey(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('externalKey')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
            </Grid>
            <Grid item>
                <Typography variant="caption" color="textSecondary">
                    {lt('externalCertDescription')}
                </Typography>
                <TextField
                    value={externalCert} required multiline minRows={3}
                    placeholder={certPlaceholder}
                    onChange={event => setExternalCert(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('externalCert')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
            </Grid>
        </>
    )

    const externalServerContent = (
        <>
            <Grid item>
                <Typography variant="h6">
                    {lt('externalServer')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption" color="textSecondary">
                    {lt('externalUrlDescription')}
                </Typography>
                <TextField
                    value={externalUrl} required
                    onChange={event => setExternalUrl(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('externalUrl')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
                <TextField
                    value={externalName}
                    onChange={event => setExternalName(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('externalName')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
            </Grid>
            <Grid item>
                <Typography variant="caption" color="textSecondary">
                    {lt('tlsCertFilesDescription')}
                </Typography>
                <TextField
                    value={tlsCertFiles} multiline minRows={6}
                    placeholder={certPlaceholder.concat(certPlaceholder.replace('ex:', ''))}
                    onChange={event => setTlsCertFiles(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('tlsCertFiles')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
                <TextField
                    value={tlsClientCertFile}  multiline minRows={3}
                    placeholder={certPlaceholder}
                    onChange={event => setTlsClientCertFile(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('tlsClientCertFile')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
                <TextField
                    value={tlsClientKeyFile} multiline minRows={3}
                    placeholder={keyPlaceHolder}
                    onChange={event => setTlsClientKeyFile(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('tlsClientKeyFile')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
            </Grid>
            <Grid item>
                <TextField
                    value={enrollmentHosts}
                    onChange={event => setEnrollmentHosts(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('enrollmentHosts')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
                <TextField
                    value={enrollmentProfile}
                    onChange={event => setEnrollmentProfile(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('enrollmentProfile')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
                <TextField
                    value={enrollmentLabel}
                    onChange={event => setEnrollmentLabel(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('enrollmentLabel')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
            </Grid>
        </>
    )

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField
                    data-test="caName"
                    value={caName} 
                    onChange={event => setCAName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                    disabled={!!existingCA}
                />
            </Grid>

            <Grid item>
                <RadioSelector
                        options={radioOptions}
                        selected={caType}
                        onChange={(e) => setCaType(e.target.value as CaType)}
                    />
            </Grid>

            {caType === 'customKeys' && customKeysContent}

            {caType === 'externalServer' && externalServerContent}
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} />
            <Step2Help supportExternalCA={featureToggles?.externalCA || false} />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    name: string,
    selfSigned: string
    selfSignedDescription: string
    customKeys: string
    customKeysDescription: string
    externalServer: string
    externalServerDescription: string
    externalKey: string
    externalCert: string
    externalKeyDescription: string
    externalCertDescription: string
    externalUrl: string
    externalUrlDescription: string
    externalName: string
    enrollmentHosts: string
    enrollmentProfile: string
    enrollmentLabel: string
    tlsCertFiles: string
    tlsClientCertFile: string
    tlsClientKeyFile: string
    tlsCertFilesDescription: string
}
const enTranslations: translations = {
    header: 'Set up your Certificate Authority (CA)',
    headerDescription: 'Each membership has a single CA that issues certificates to all nodes',
    name: 'Certificate Authority Name',
    selfSigned: 'Self-Signed',
    selfSignedDescription: 'Use a root key uniquely generated for the membership and a self-signed CA certificate',
    customKeys: 'Existing Key and Certificate',
    customKeysDescription: 'Provide the PEM content for the root key and the CA certificate',
    externalServer: 'Enroll with a Fabric CA Server',
    externalServerDescription: 'Provide the configuration for the Fabric CA server to enroll with',
    externalKey: 'External Key',
    externalCert: 'External Cert',
    externalKeyDescription: 'Enter the PEM content for an external key to be used as the root signing key of the CA',
    externalCertDescription: 'Enter the PEM content for the cert corresponding to the external key',
    externalUrl: 'External URL',
    externalUrlDescription: 'Enter the URL for the Fabric CA server to enroll with. ex: http(s)://<enrollmentId>:<enrollmentSecret>@host.name:port',
    externalName: 'External CA Name',
    enrollmentHosts: 'Enrollment Hosts',
    enrollmentProfile: 'Enrollment Profile',
    enrollmentLabel: 'Enrollment Label',
    tlsCertFiles: 'TLS Cert Chain',
    tlsClientCertFile: 'TLS Client Cert',
    tlsClientKeyFile: 'TLS Client Key',
    tlsCertFilesDescription: 'Note: Multiple TLS certs may be entered with a line break (\\n) in between'
}