import React from 'react';
import { DisplayTable } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import moment from 'moment';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { useHistory, useParams } from 'react-router-dom';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_BLOCKS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { BlockForChart } from '../../models';

interface Props {
    blocks: BlockForChart[]
    setSuspendUpdates?: React.Dispatch<React.SetStateAction<boolean>>
}

export const LatestBlocks = ({blocks, setSuspendUpdates} : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LatestBlocks', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LatestBlocks:${key}`, interpolate);

    const history = useHistory();

    const {org_id, environment_id, consortium_id} = useParams<any>();

    const historyPusher = (number?: string) => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}${number ? `/${number}` : ''}`)
    }

    const records: DisplayTableRecord[] = blocks.map((block, index) => ({
        key: `blocks-${index}`,
        columns: [{
            value: block.x
        }, {
            value: block.miner === 'Genesis' ? block.miner : <ShortenedHash address={block.miner} setModalOpen={setSuspendUpdates} />
        }, {
            value: block.y
        }, {
            value: block.miner === 'Genesis'? '--': moment(block.timestamp).fromNow()
        }],
        onClick: () => historyPusher(block.x.toString())
    }));

    const columns = [lt('block'), lt('minedBy'), lt('txCount'), lt('dateMined')];


    const linkButton = {
        text: lt('viewBlocks'),
        onClick: () => historyPusher()
    }

    return (
        <DisplayTable header={lt('header')} records={records} columnHeaders={columns} {...{linkButton}} />
    )
}

interface translations {
    header: string,
    block: string,
    minedBy: string,
    txCount: string,
    dateMined: string,
    viewBlocks: string
}

const enTranslations: translations = {
    header: 'Latest Blocks',
    block: 'Block',
    minedBy: 'Mined By',
    dateMined: 'Date Mined',
    txCount: 'Tx Count',
    viewBlocks: 'View all blocks'
}