import React, { useMemo, useState } from 'react';
import { FullScreenCreate, MessageSnackbar } from '../../../components/DialogWrappers';
import { useParams, useHistory, Redirect, useLocation } from 'react-router-dom';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_TOKENS_PATH } from '../../../components/MainNav/SideNavs/DigitalAssets';
import { useTranslation } from 'react-i18next';
import { Transfer } from './Transfer';
import { Account, NodesData, NodesQuery } from '../../../models';
import { Mint } from './Mint';
import { Burn } from './Burn';
import { useQuery } from '@apollo/client';
import { CookieAppCred } from '../../../components/CookieAppCred/CookieAppCred';

type locationState = {sender: Account}
 
export const TokenAction = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenAction', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenAction:${key}`, interpolate);

    const [cookieAppCred, setCookieAppCred] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const {consortium_id, environment_id, org_id, token_address, step} = useParams<any>();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const history = useHistory<locationState>();
    const { pathname } = useLocation();
    
    const splitPathname = pathname.split('/');
    const tokenAction = splitPathname[splitPathname.length -2];

    const {
        location: {
            state: {
                sender: senderAddress,
            } = { sender: null}
        }
    } = history;

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVariables,
        fetchPolicy: 'cache-only',
        skip: !senderAddress
    });

    const membershipNode = useMemo(() => {
        if (senderAddress) {
            return nodes.find(node => node.membership_id === senderAddress.membership_id)
        }
    }, [nodes, senderAddress]);
    
    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`
    
    const cancelPath = `${basePath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/${token_address}`;

    const invalidStep = step !== "1";
    
    if (invalidStep || !senderAddress || !membershipNode) return <Redirect to={cancelPath} />;
    
    const routerState = {
        account: senderAddress
    }
    
    const getActionName = () => {
        if( tokenAction === 'mint') return lt('mintToken');
        if (tokenAction === 'burn') return lt('burnToken');
        return lt('transferToken');
    };

    const getComponent = () => {
        if( tokenAction === 'mint') return <Mint {...{membershipNode}} {...{cookieAppCred}} {...{senderAddress}} {...{cancelPath}} />;
        if (tokenAction === 'burn') return <Burn {...{membershipNode}} {...{cookieAppCred}} {...{senderAddress}} {...{cancelPath}} />;
        return <Transfer {...{membershipNode}} {...{cookieAppCred}} {...{senderAddress}} {...{cancelPath}} />;
    }

    const stepComponents = [
        {
            step: getActionName(),
            component: getComponent()
        }
    ];

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <CookieAppCred
                membershipId={membershipNode.membership_id} 
                nodeId={membershipNode._id} {...{setCookieAppCred}} 
                {...{setErrorMessage}} />
            <FullScreenCreate {...{routerState}} hideStepper cancelPath={cancelPath} toolbarHeader={getActionName()} stepUrlParam={step!} {...{stepComponents}} />
        </>
    )
};

interface translations {
    transferToken: string
    mintToken: string
    burnToken: string
}
const enTranslations: translations = {
    transferToken: 'Transfer Token',
    mintToken: 'Mint Token',
    burnToken: 'Burn Token'
}