import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, TextField, Grid } from "@material-ui/core";
import { Step1Help } from './Step1Help'
import { MembershipSelector } from '../../components/FormControls/MembershipSelector'
import { RegionSelector } from '../../components/FormControls/RegionSelector'
import { CreateStepProps } from '../../interfaces';
import { CreateServiceData, CreateServiceVars, CreateServiceMutation, MakeServiceCreateMutationOptions,
    ConsortiumZone, EnvironmentZone,
    CreateEnvironmentZoneData, CreateEnvironmentZoneVars, CreateEnvironmentZoneMutation, MakeEnvironmentZoneCreateMutationOptions } from '../../models'
import { useMutation } from '@apollo/client';
import { MessageSnackbar, ErrorSnackbarCatcher, CreateWrapper } from '../../components/DialogWrappers'
import { SECURITY_BASE_PATH, SECURITY_NETWORK_CONTROL_PATH } from '../../components/MainNav/SideNavs/Security';

interface Props extends CreateStepProps {
    isMultiRegion: boolean,
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    consortiumZoneId: string,
    consortiumZones: ConsortiumZone[],
    environmentZones: EnvironmentZone[],    
    setConsortiumZoneId: React.Dispatch<React.SetStateAction<string>>,
};

export const Step1 = ({ 
        isMultiRegion, name, setName, membershipId, setMembershipId, 
        consortiumZoneId, consortiumZones, environmentZones, setConsortiumZoneId, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateTunnelerCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateTunnelerCreateStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const consortiumZone = consortiumZones.find(z => z._id === consortiumZoneId)
    const environmentZone = environmentZones.find(z => 
        z.region === consortiumZone?.region &&
        z.type === consortiumZone?.type &&
        z.cloud === consortiumZone?.cloud)
 
    const [message, setMessage] = useState('');
    const [createService, { loading: createServiceLoading }] = 
        useMutation<CreateServiceData, CreateServiceVars>(CreateServiceMutation)
    const [createEnvironmentZone] = 
        useMutation<CreateEnvironmentZoneData, CreateEnvironmentZoneVars>(CreateEnvironmentZoneMutation)

    const save = async () => {
        let zoneId = environmentZone?._id

        // create the environment zone if multi region and the selected region doesnt exist
        if (consortiumZoneId && !zoneId) {
            const { region, cloud, type } = consortiumZones.find(c => c._id === consortiumZoneId)!
            const envZone = await createEnvironmentZone(MakeEnvironmentZoneCreateMutationOptions({
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                environmentZone: {
                    region,
                    cloud,
                    type
                }
            })).catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
                return null
            })

            zoneId = envZone?.data?.createEnvironmentZone?._id
        }

        // create the service
        createService(MakeServiceCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            service: {
                name,
                membership_id: membershipId,
                service: 'tunneler'
            }
        })).then(result => {
            if (result) {
                const newNodeId = result.data?.createService?._id
                if (newNodeId) {
                    history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_NETWORK_CONTROL_PATH}`)
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const disabled = !name || (isMultiRegion && !consortiumZoneId) || createServiceLoading;

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />

                <TextField
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            { isMultiRegion && 
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('selectDeploymentRegion')}
                </Typography>
                <RegionSelector zoneId={consortiumZoneId} setZoneId={setConsortiumZoneId} {...{consortiumZones}} />
            </Grid>
            }
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    name: string,
    selectDeploymentRegion: string
}
const enTranslations: translations = {
    header: 'Set Up Your PrivateStack Cloud Endpoint',
    headerDescription: 'Provision a the hosted end of the network bridge for your PrivateStack deployment.',
    name: 'Name of the deployment',
    selectDeploymentRegion: 'Select deployment region'
}