import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import { Node, NodeStatusData, NodeStatusQuery } from '../../models'
import { CircularProgress } from "@material-ui/core";
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';

interface Props {
    node: Node
}

export const ChannelJoinedStatus = ({ node }: Props) => {
    const { consortium_id, environment_id, channel_id } = useParams<any>();

    const {
        loading,
        stopPolling,
        data: {
            nodeStatus
        } = { nodeStatus: null } } = useQuery<NodeStatusData>(NodeStatusQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: node._id
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 10000,
        skip: node.state !== 'started' || !node.membership.isMine
    });

    const isJoined = nodeStatus?.fabric?.channels?.some(c => c === channel_id)

    useEffect(() => {
        if (isJoined) {
            stopPolling()
        }
    }, [isJoined, stopPolling])

    if (loading && !nodeStatus) {
        return <CircularProgress size={24} />
    }

    let state = 'unknown'

    if (nodeStatus?.fabric?.channels) {
        state = isJoined ? 'channelJoined' : 'channelJoining'
    }

    return (
        <ResourceStateChip state={state as 'unknown' | 'channelJoined' | 'channelJoining'} />
    )
};
