import { useQuery } from '@apollo/client';
import { Button, Grid, Typography } from "@material-ui/core";
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CopyableSetting, CopyableSettings } from '../../../components/DisplaySettings';
import { ResourceStateChip } from '../../../components/FormControls/ResourceStateChip';
import { EnRuntimeSizeTranslation, EnServicesTranslations, EnvironmentZonesData, EnvironmentZonesQuery, NodesData, NodesQuery, RuntimeSizeTranslation, ServicesData, ServicesEnum, ServicesQuery, ServicesTranslations, FeatureTogglesData, FeatureTogglesVars, FeatureTogglesQuery, getManageServiceSegments } from '../../../models';
import { B2B_BASE_PATH } from '../../../components/MainNav/SideNavs/B2bCommunication';
import { BLOCKCHAIN_BASE_PATH } from '../../../components/MainNav/SideNavs/Blockchain';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_CLOUD_PATH, KEY_MANAGEMENT_MANAGED_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import { FormLink } from '../../../components/FormControls/FormLink';
import { Buffer } from 'buffer';

export const AddressBookMemberRuntimeDetails: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'GovernanceMembershipDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceMembershipDetails:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, runtime_id } = useParams<any>();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`

    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }
    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-first'
    });

    const getZoneLabel = (zoneId: string) => {
        const environmentZone = environmentZones.find(zone => zone._id === zoneId) || environmentZones[0];
        return environmentZone?.displayName;
    };

    let runtimeDetailsCopyableList: CopyableSetting[] = [];

    let ownerUrl: string | undefined = undefined;

    const nodeRuntime = nodes.find(node => node._id === runtime_id);
    if (nodeRuntime) {
        runtimeDetailsCopyableList = [
            { title: lt('runtimeId'), displayValue: nodeRuntime._id },
            { title: lt('type'), displayValue: lt('node') },
            { title: lt('size'), displayValue: lt(nodeRuntime.size) },
            { title: lt('name'), displayValue: nodeRuntime.name },
            { title: lt('region'), displayValue: getZoneLabel(nodeRuntime.zone_id) },
            { title: lt('status'), displayValue: <ResourceStateChip state={nodeRuntime.state} /> },
            { title: lt('created'), displayValue: new Date(nodeRuntime.created_at).toLocaleString() },
        ]
        if (nodeRuntime.role === 'orderer' || nodeRuntime.role === 'peer') {
            runtimeDetailsCopyableList.push({ title: lt('nodeURL'), displayValue: nodeRuntime.urls?.orderer || nodeRuntime.urls?.peer || '-'})
        };
        runtimeDetailsCopyableList.push(
            { title: lt('lastUpdate'), displayValue: nodeRuntime.updated_at ? new Date(nodeRuntime.updated_at).toLocaleString() : '--' },
        );
        if (nodeRuntime.membership.isMine) {
            ownerUrl = `${basePath}/nodes/${nodeRuntime._id}`;
        }
    }

    const serviceRuntime = services.find(service => service._id === runtime_id);
    if (serviceRuntime) {
        runtimeDetailsCopyableList = [
            { title: lt('runtimeId'), displayValue: serviceRuntime._id },
            { title: lt('type'), displayValue: lt(ServicesEnum[serviceRuntime.service]) },
            { title: lt('size'), displayValue: lt(serviceRuntime.size) },
            { title: lt('name'), displayValue: serviceRuntime.name },
            { title: lt('region'), displayValue: getZoneLabel(serviceRuntime.zone_id) },
            { title: lt('status'), displayValue: <ResourceStateChip state={serviceRuntime.state} /> },
            { title: lt('created'), displayValue: new Date(serviceRuntime.created_at).toLocaleString() },
            { title: lt('lastUpdate'), displayValue: new Date(serviceRuntime.updated_at).toLocaleString() },
        ];

        const manageServiceSegments = featureToggles ? getManageServiceSegments(
            serviceRuntime, featureToggles, 
            BLOCKCHAIN_BASE_PATH, 
            B2B_BASE_PATH, 
            KEY_MANAGEMENT_BASE_PATH, 
            KEY_MANAGEMENT_CLOUD_PATH, 
            KEY_MANAGEMENT_MANAGED_PATH) : ''
        ownerUrl = manageServiceSegments ? `${basePath}/${manageServiceSegments}` : undefined
    }

    return (
        <Grid container spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('runtime')}
                    </Typography>
                </Grid>
                <Grid item>
                    {ownerUrl &&
                        <Button color="primary" variant="contained" size="large"
                            startIcon={<OpenInNewIcon />}
                            onClick={() => history.push(ownerUrl!)}>
                            {lt('manageRuntime')}
                        </Button>}
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={3}>
                <CopyableSettings header={lt('runtime')} copyableList={runtimeDetailsCopyableList} />
            </Grid>
            { (nodeRuntime?.provider === 'fabric' && nodeRuntime?.node_identity_data) && 
                <Grid item xs={12}>
                    <FormLink isExternal={true} download="cert.pem" to={`data:text/plain;charset=utf-8,${JSON.parse(Buffer.from(nodeRuntime.node_identity_data, 'hex').toString()).orgCA}`}>{lt('downloadCertificate')}</FormLink>
                </Grid>
            }
        </Grid>
    )
};

interface translations extends RuntimeSizeTranslation, ServicesTranslations {
    runtime: string
    type: string
    node: string
    size: string
    name: string
    region: string
    runtimeId: string
    status: string
    created: string
    lastUpdate: string
    manageRuntime: string
    nodeURL: string
    downloadCertificate: string
}

const enTranslations: translations = {
    ...EnServicesTranslations,
    ...EnRuntimeSizeTranslation,
    runtime: 'Runtime',
    type: 'Type',
    node: 'Node',
    size: 'Size',
    name: 'Name',
    region: 'Region',
    runtimeId: 'Runtime ID',
    status: 'Status',
    created: 'Created',
    lastUpdate: 'Last update',
    manageRuntime: 'Manage Runtime',
    nodeURL: 'Node URL',
    downloadCertificate: 'Download MSP CA Certificate'
}