import { gql } from '@apollo/client';

export interface SolcVersionsData {
    solcVersions: string[]
}

export const SolcVersionsQuery = gql`
    query getSolcVersions {
        solcVersions
    }`;
