import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const TransferHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenTransferHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenTransferHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Token-Transfer.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/kaleido-services/token-factory/usage/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Transfer Token',
    helpDescription: 'In order to send tokens from the selected account to another Ethereum account it is needed to:',
    helpDesc1: "Input the amount you want to transfer as long as it is less than the account's current balance.",
    helpDesc2: 'Choose a target address either from the account selector that list all the accounts from all the members in the environment or a valid custom ethereum address.',
}