import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Switch } from "@material-ui/core";
import WebIcon from 'mdi-react/WebIcon';
import FileTableOutlineIcon from 'mdi-react/FileTableOutlineIcon';
import MessageArrowRightOutlineIcon from 'mdi-react/MessageArrowRightOutlineIcon'
import FileKeyOutlineIcon from 'mdi-react/FileKeyOutlineIcon';
import TimerOutlineIcon from 'mdi-react/TimerOutlineIcon';
import { EditableSettings } from '../../components/DisplaySettings'
import { FormDialog } from '../../components/DialogWrappers'
import { BAFSaveWithUndo, BAFPolicyCORS } from './BAFPolicySchema';

interface Props {
    corsSection: BAFPolicyCORS,
    loading: boolean,
    save: BAFSaveWithUndo,
}

export const BAFPolicyCORSSection: React.FC<Props> = ({ corsSection, save, loading }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFPolicyCORSSection', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFPolicyCORSSection:${key}`, interpolate)

    const [allowedOrigins, setAllowedOrigins] = useState(corsSection.allowedOrigins?.join(',').trim() || '');
    const [allowedMethods, setAllowedMethods] = useState(corsSection.allowedMethods?.join(',').trim() || '');
    const [allowedHeaders, setAllowedHeaders] = useState(corsSection.allowedHeaders?.join(',').trim() || '');
    const [allowCredentials, setAllowCredentials] = useState(corsSection.allowCredentials ? true : false);
    const [maxAge, setMaxAge] = useState(String(corsSection.maxAge || 0));
    const [updateAllowedOriginsDialogOpen, setUpdateAllowedOriginsDialogOpen] = useState(false);
    const [updateAllowedMethodsDialogOpen, setUpdateAllowedMethodsDialogOpen] = useState(false);
    const [updateAllowedHeadersDialogOpen, setUpdateAllowedHeadersDialogOpen] = useState(false);
    const [updateAllowCredentialsDialogOpen, setUpdateAllowCredentialsDialogOpen] = useState(false);
    const [updateMaxAgeDialogOpen, setUpdateMaxAgeDialogOpen] = useState(false);

    const actionsList = [
        {
            icon: <WebIcon />,
            title: lt('allowedOrigins'),
            description: lt('allowedOriginsDescription'),
            value: corsSection.allowedOrigins?.join(',').trim(),
            buttonLabel: lt('change'),
            action: () => setUpdateAllowedOriginsDialogOpen(true)
        },
        {
            icon: <MessageArrowRightOutlineIcon />,
            title: lt('allowedMethods'),
            description: lt('allowedMethodsDescription'),
            value: corsSection.allowedMethods?.join(',').trim(),
            buttonLabel: lt('change'),
            action: () => setUpdateAllowedMethodsDialogOpen(true)
        },
        {
            icon: <FileTableOutlineIcon />,
            title: lt('allowedHeaders'),
            description: lt('allowedHeadersDescription'),
            value: corsSection.allowedHeaders?.join(',').trim(),
            buttonLabel: lt('change'),
            action: () => setUpdateAllowedHeadersDialogOpen(true)
        },
        {
            icon: <FileKeyOutlineIcon />,
            title: lt('allowCredentials'),
            description: lt('allowCredentialsDescription'),
            value: corsSection.allowCredentials ? "true" : "false",
            buttonLabel: lt('change'),
            action: () => setUpdateAllowCredentialsDialogOpen(true)
        },
        {
            icon: <TimerOutlineIcon />,
            title: lt('maxAge'),
            description: lt('maxAgeDescription'),
            value: String(corsSection.maxAge || 600),
            buttonLabel: lt('change'),
            action: () => setUpdateMaxAgeDialogOpen(true)
        }
    ]

    const setAllowedOriginsControls = <>
        <TextField
            data-test="textField_updateAllowedOrigins"
            value={allowedOrigins} onChange={event => setAllowedOrigins(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('allowedOrigins')}
            fullWidth
            variant="outlined"
        /></>

    const setAllowedMethodsControls = <>
        <TextField
            data-test="textField_updateAllowedMethods"
            value={allowedMethods} onChange={event => setAllowedMethods(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('allowedMethods')}
            fullWidth
            variant="outlined"
        /></>

    const setAllowedHeadersControls = <>
        <TextField
            data-test="textField_updateAllowedHeaders"
            value={allowedHeaders} onChange={event => setAllowedHeaders(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('allowedHeaders')}
            fullWidth
            variant="outlined"
        /></>

    const setAllowCredentialsControls = <>
        <Switch
            checked={allowCredentials}
            color="primary"
            onChange={event => setAllowCredentials(event.target.checked)}
            name="allowCredentials"
        /></>

    const badMaxAge = !(Number(maxAge) > 0);
    const setMaxAgeControls = <>
        <TextField
            data-test="textField_updateAllowedHeaders"
            value={maxAge} onChange={event => setMaxAge(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('maxAge')}
            fullWidth
            variant="outlined"
            error={badMaxAge}
        /></>

    const onSaveAllowedOrigins = async () => {
        const newAllowedOrigins = allowedOrigins.split(',').map(s => s.trim());
        await save(corsSection, 'allowedOrigins', newAllowedOrigins, v => setAllowedOrigins(v?.join(',') || ''))
    }

    const onSaveAllowedMethods = async () => {
        const newAllowedMethods = allowedMethods.split(',').map(s => s.trim());
        await save(corsSection, 'allowedMethods', newAllowedMethods, v => setAllowedMethods(v?.join(',') || ''))
    }

    const onSaveAllowedHeaders = async () => {
        const newAllowedHeaders = allowedHeaders.split(',').map(s => s.trim());
        await save(corsSection, 'allowedHeaders', newAllowedHeaders, v => setAllowedHeaders(v?.join(',') || ''))
    }

    const onSaveAllowCredentials = async () => {
        await save(corsSection, 'allowCredentials', allowCredentials, setAllowCredentials)
    }

    const onSaveMaxAge = async () => {
        const newMaxAge = parseInt(maxAge);
        await save(corsSection, 'maxAge', newMaxAge, v => setMaxAge(String(v)))
    }

    return (
        <>
            <FormDialog dataTestId="updateAllowedOrigins"
                setOpen={setUpdateAllowedOriginsDialogOpen}
                open={updateAllowedOriginsDialogOpen}
                header={lt('changeAllowedOrigins')} 
                description={lt('splitWithCommas')} 
                controlsWrapper={setAllowedOriginsControls}
                saveDisabled={!allowedOrigins || loading}
                onSave={onSaveAllowedOrigins}
                closeDialogAfterSave />

            <FormDialog dataTestId="updateAllowedMethods"
                    setOpen={setUpdateAllowedMethodsDialogOpen}
                    open={updateAllowedMethodsDialogOpen}
                    header={lt('changeAllowedMethods')} 
                    description={lt('splitWithCommas')} 
                    controlsWrapper={setAllowedMethodsControls}
                    saveDisabled={!allowedMethods || loading}
                    onSave={onSaveAllowedMethods}
                    closeDialogAfterSave />

            <FormDialog dataTestId="updateAllowedHeaders"
                    setOpen={setUpdateAllowedHeadersDialogOpen}
                    open={updateAllowedHeadersDialogOpen}
                    header={lt('changeAllowedHeaders')} 
                    description={lt('splitWithCommas')} 
                    controlsWrapper={setAllowedHeadersControls}
                    saveDisabled={!allowedHeaders || loading}
                    onSave={onSaveAllowedHeaders}
                    closeDialogAfterSave />

            <FormDialog dataTestId="updateAllowCredentials"
                    setOpen={setUpdateAllowCredentialsDialogOpen}
                    open={updateAllowCredentialsDialogOpen}
                    header={lt('changeAllowCredentials')} 
                    description={lt('allowCredentialsDescription')} 
                    controlsWrapper={setAllowCredentialsControls}
                    saveDisabled={loading}
                    onSave={onSaveAllowCredentials}
                    closeDialogAfterSave />

            <FormDialog dataTestId="updateMaxAge"
                    setOpen={setUpdateMaxAgeDialogOpen}
                    open={updateMaxAgeDialogOpen}
                    header={lt('changeMaxAge')} 
                    description={lt('maxAgeDescription')} 
                    controlsWrapper={setMaxAgeControls}
                    saveDisabled={badMaxAge || loading}
                    onSave={onSaveMaxAge}
                    closeDialogAfterSave />

            <Grid item xs={12} sm={12} md={8}>
                <EditableSettings
                    header={lt('cors')}
                    {...{actionsList}} />
            </Grid>
        </>
    )
};

interface translations {
    cors: string,
    change: string,
    splitWithCommas: string,

    allowedOrigins: string,
    allowedOriginsDescription: string,
    changeAllowedOrigins: string,

    allowedMethods: string,
    allowedMethodsDescription: string,
    changeAllowedMethods: string,

    allowedHeaders: string,
    allowedHeadersDescription: string,
    changeAllowedHeaders: string,

    allowCredentials: string,
    allowCredentialsDescription: string,
    changeAllowCredentials: string,

    maxAge: string,
    maxAgeDescription: string,
    changeMaxAge: string,
}
const enTranslations: translations = {
    cors: "Cross-origin resource sharing (CORS)",
    change: "Change",
    splitWithCommas: "Use a comma to separate multiple values",

    allowedOrigins: "Allowed Origins",
    allowedOriginsDescription: "Origins the browser should allow API calls from",
    changeAllowedOrigins: "Set Allowed Origins",

    allowedMethods: "Allowed Methods",
    allowedMethodsDescription: "HTTP Methods the browser should allow",
    changeAllowedMethods: "Set Allowed Methods",

    allowedHeaders: "Allowed Headers",
    allowedHeadersDescription: "HTTP Headers the browser should allow",
    changeAllowedHeaders: "Set Allowed Headers",

    allowCredentials: "Allow Credentials",
    allowCredentialsDescription: "Whether the browser should allow passing auth credentials",
    changeAllowCredentials: "Set Allow Credentials",

    maxAge: "Maximum Age",
    maxAgeDescription: "Maximum age in seconds before the browser must renew the pre-flight request",
    changeMaxAge: "Set Allowed Headers",

}