import React, { useState } from "react";
import { Grid, Typography, MenuItem, CircularProgress, TextField, Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { App2AppAddressBookData, App2AppAddressBookQuery, App2AppDestinationsData, App2appDestinationsQuery, ProduceApp2AppMessageVars, ProduceApp2AppMessageMutation, App2AppMessageInput } from "../../../models";
import { ServiceResourcesVars } from "../../../interfaces";
import { DisplayGridWrapper } from "../../../components/DisplayWrappers";
import { ErrorSnackbarCatcher, MessageSnackbar } from "../../../components/DialogWrappers";

export const App2AppProducer: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'App2AppProducer', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`App2AppProducer:${key}`, interpolate)
    const { service_id } = useParams<any>();
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [key, setKey] = useState('');
    const [content, setContent] = useState('');
    const [message, setMessasge] = useState('');
    const [messageType, setMessageType] = useState<'error' | 'success'>();
    const classes = useStyles();    

    const [produceApp2AppMessage, { loading }] = useMutation<boolean, ProduceApp2AppMessageVars>(ProduceApp2AppMessageMutation);

    const {
        loading: loadingAddressBook,
        data: {
            app2appAddressBook
        } = { app2appAddressBook: [] }
    } = useQuery<App2AppAddressBookData, ServiceResourcesVars>(App2AppAddressBookQuery,
        { variables: { service_id }, fetchPolicy: 'cache-and-network' });

    const {
        loading: loadingDestinations,
        data: {
            app2appDestinations
        } = { app2appDestinations: [] },
    } = useQuery<App2AppDestinationsData, ServiceResourcesVars>(App2appDestinationsQuery, {
        variables: { service_id: service_id! }, fetchPolicy: 'cache-and-network',
        onError: err => {setMessageType('error'); ErrorSnackbarCatcher(err, setMessasge)},
    });

    if ((loadingAddressBook && app2appAddressBook.length === 0) || (loadingDestinations && app2appDestinations.length === 0)) {
        return <CircularProgress />
    }

    let destinationToItems = [];
    for (const entry of app2appAddressBook) {
        for (const destination of entry.destinations) {
            destinationToItems.push(<MenuItem key={destination.uri} value={destination.uri}>{destination.uri}</MenuItem>);
        }
    }

    const gridContent = (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <TextField select fullWidth label={lt('from')} variant="outlined" value={from} onChange={event => setFrom(event.target.value as string)}>
                    {app2appDestinations.filter(destination => destination.setup_complete).map(destination => (
                        <MenuItem key={destination.uri} value={destination.uri}>{destination.uri}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item>
                <TextField select fullWidth label={lt('to')} variant="outlined" value={to} onChange={event => setTo(event.target.value as string)}>
                    {destinationToItems}
                </TextField>
            </Grid>
            <Grid item>
                <TextField fullWidth label={lt('key')} variant="outlined" value={key} onChange={event => setKey(event.target.value)} />
            </Grid>
            <Grid item>
                <TextField fullWidth label={lt('content')} value={content} onChange={event => setContent(event.target.value)} variant="outlined" multiline rows={10} />
            </Grid>
        </Grid>
    );

    const handleSend = async () => {
        const message: App2AppMessageInput = {
            headers: {
                from,
                to
            },
            content,
            key
        };
        try {
            if(await produceApp2AppMessage({ variables: { service_id, message } })) {
                setMessageType('success');
                setMessasge(lt('messageSent'));
            } else {
                setMessageType('error');
                setMessasge(lt('messageCouldNotBeSent'));
            }
        } catch (err) {
            setMessageType('error');
            ErrorSnackbarCatcher(err, setMessasge)
        }
    };

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('producer')}</Typography>
                </Grid>
                <Grid className={classes.producerContainer}>
                    <DisplayGridWrapper header={lt('composeMessage')} padDisplayGrid displayGrid={gridContent}
                        actionFooter={<Button disabled={loading || !from || !to || !content} size="large" variant="contained" color="primary" onClick={handleSend}>{lt('send')}</Button>} />
                </Grid>
            </Grid>
            <MessageSnackbar
                messageType={messageType}
                message={message}
                setMessage={setMessasge}
            />
        </>
    );
};

const useStyles = makeStyles(() => ({
    producerContainer: {
        maxWidth: '800px'
    }
}));

interface translations {
    producer: string
    composeMessage: string
    from: string
    to: string
    key: string
    content: string
    send: string
    messageSent: string
    messageCouldNotBeSent: string
}

const enTranslations: translations = {
    producer: 'Producer',
    composeMessage: 'Compose Message',
    from: 'From',
    to: 'To',
    key: 'Key',
    content: 'Content',
    send: 'Send',
    messageSent: 'Message sent',
    messageCouldNotBeSent: 'Message could not be sent'
}

