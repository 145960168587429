import { useQuery } from "@apollo/client";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodesData, NodesQuery, GatewayAPI, Channel } from "../../models";
import { DisplayTable } from "../../components/DisplayWrappers";
import { InstalledStatusRow } from "./InstalledStatusRow";

interface Props {
    gatewayAPI?: GatewayAPI
    channels?: Channel[]
}

export const InstalledStatuses = ({ gatewayAPI, channels }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'InstalledStatuses', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`InstalledStatuses:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: {
            consortia_id: consortium_id, 
            environment_id
        },
        fetchPolicy: 'cache-only'
    });

    const columnHeaders = ['', lt('node'), lt('owner'), lt('installStatus')]

    const preBuiltRows = (
        <>
            { nodes.filter(n => (n.membership.isMine && n.role === 'peer') || n.membership_id === 'sys--mon').map(n => 
                <InstalledStatusRow node={n} channels={channels} gatewayAPI={gatewayAPI} isMonitor={n.membership_id === 'sys--mon'} />) }
        </>
    )

    return (
        <DisplayTable
            header={lt('header')}
            description={lt('description')}
            {...{columnHeaders}} {...{preBuiltRows}} />
    )
};

interface translations {
    description: string
    header: string
    node: string
    owner: string
    installStatus: string
}
const enTranslations: translations = {
    description: 'View the chaincode installation status for the peer nodes below:',
    header: 'Installed Statuses',
    node: 'Node',
    owner: 'Owner',
    installStatus: 'Installed Status'
}