import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step3Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'InviteOrganizationCreateStep3Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`InviteOrganizationCreateStep3Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Subscription.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks/governance/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    finalInfo: string,
}
const enTranslations: translations = {
    helpHeader: 'Permissions',
    helpDescription: 'A decentralized enterprise-caliber business network requires robust mechanisms for network permissions and access control. Kaleido implements an “inherited permissions” approach for externally-invited organizations.',
    helpDesc1: 'Invited participants have full line of sight into the network’s configuration',
    helpDesc2: 'Permissions cannot be changed once applied in order to ensure the integrity and status quo of the network',
    finalInfo: 'Remember to review the details of this invitation. Once it is sent, it can no longer be edited!',
}