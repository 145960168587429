import React, { useEffect, useState, useCallback } from "react";
import { SubNavs } from "./SubNavs";
import { SideNavProps, ConsortiumResourceVars } from "../../../interfaces";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
    ServicesData,
    ServicesQuery,
    B2bDocumentsServices,
    B2bMessagingServices,
    EnvironmentData,
    EnvironmentQuery,
    FeatureTogglesData,
    FeatureTogglesVars,
    FeatureTogglesQuery,
    ServicesEnum,
} from "../../../models";
import MessageTextLockOutlineIcon from "mdi-react/MessageTextLockOutlineIcon";
import { NavItem } from "../../NavComponents/NavItem";

export const B2B_BASE_PATH = "b2b";

export const B2bCommunication = ({
    environment_id,
    selected,
    setSelected,
}: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "B2bCommunication", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`B2bCommunication:${key}`, interpolate);

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const DASHBOARD = lt("dashboard");
    const DOCUMENTS = lt("documents");
    const MESSAGING = lt("messaging");

    const b2bBasePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}`

    const {
        loading: servicesLoading,
        data: { services } = { services: [] },
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id,
        },
        fetchPolicy: "cache-only",
    });

    const { data: { featureToggles } = { featureToggles: null } } = useQuery<
        FeatureTogglesData,
        FeatureTogglesVars
    >(FeatureTogglesQuery, {
        variables: {},
        fetchPolicy: "cache-first",
    });

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    const documentsServices = services.filter(
        (s) => B2bDocumentsServices.has(s.service) && s.membership.isMine
    );
    const messagingServices = services.filter(
        (s) => B2bMessagingServices.has(s.service) && s.membership.isMine
    );

    const historyPusher = useCallback(
        (path?: string) =>
            history.push(`${b2bBasePath}${path ? `/${path}` : ""}`),
        [history, b2bBasePath]
    );

    useEffect(() => {
        if (servicesLoading) return;

        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected);
            setActive(activeAndSelected);
        };

        if (pathname.endsWith(B2B_BASE_PATH)) {
            setSelectedItem(DASHBOARD);
            setActiveAndSelected();
        } else {
            setSelectedItem("");
            setActiveAndSelected(false);
        }
    }, [servicesLoading, pathname, setSelected, DASHBOARD]);

    let docExchangeItems = documentsServices.filter(s => s.membership.isMine).map((service) => ({
        name: service.name,
        action: () =>
            historyPusher(`${service.service}/${service._id}`),
        divider: false,
        disabled: !featureToggles?.docExchange ?? true,
    }));

    if(featureToggles?.docExchange) {
        if (docExchangeItems.length) {
            docExchangeItems[docExchangeItems.length - 1].divider = true
        }
        docExchangeItems.push({
            name: lt('addRuntime'),
            action: () => history.push(`${b2bBasePath}/${ServicesEnum.documentstore}/create/1`),
            divider: false,
            disabled: environment?.state !== 'live'
        });
    }

    let messagingItems = messagingServices.filter(s => s.membership.isMine).map((service) => ({
        name: service.name,
        action: () =>
            historyPusher(`${service.service}/${service._id}`),
        divider: false,
        disabled: !featureToggles?.app2app ?? true,
    }));

    if(featureToggles?.app2app) {
        if (messagingItems.length) {
            messagingItems[messagingItems.length - 1].divider = true
        }
        messagingItems.push({
            name: lt('addRuntime'),
            action: () => history.push(`${b2bBasePath}/${ServicesEnum.app2app}/create/1`),
            divider: false,
            disabled: environment?.state !== 'live'
        });
    }


    const subItems = useCallback(() => {
        return [
            {
                name: DASHBOARD,
                action: () => historyPusher(),
                comingSoon: !featureToggles?.docExchange ?? true,
            },
            {
                name: DOCUMENTS,
                popoverItems: docExchangeItems,
                comingSoon: !featureToggles?.docExchange ?? true,
            },
            {
                name: MESSAGING,
                popoverItems: messagingItems,
                comingSoon: !featureToggles?.app2app ?? true
            },
        ];
    }, [
        historyPusher,
        docExchangeItems,
        messagingItems,
        DASHBOARD,
        DOCUMENTS,
        MESSAGING,
        featureToggles,
    ]);

    return (
        <>
            <NavItem
                comingSoon={isCorda || isFabric}
                icon={<MessageTextLockOutlineIcon />}
                header={lt("name")}
                action={() => {
                    if (isCorda || isFabric) historyPusher();
                    else if (!active) setSelected(!selected);
                }}
            />
            {!isCorda && !isFabric && (
                <SubNavs
                    list={subItems()}
                    selected={active || selected}
                    {...{ selectedItem }}
                />
            )}
        </>
    );
};

interface translations {
    name: string;
    dashboard: string;
    documents: string;
    messaging: string;
    addRuntime: string
}
const enTranslations: translations = {
    name: "B2B Communications",
    dashboard: "Dashboard",
    documents: "Documents",
    messaging: "Messaging",
    addRuntime: 'Add Runtime'
};
