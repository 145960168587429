import { BaseBilling } from "../../../models/billing";

export const estimateCost = (costToDate: number) => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 0).getTime();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
    const timeSoFar = date.getTime() - firstDay;
    const totalTime = lastDay - firstDay;
    const estimate = totalTime * costToDate / timeSoFar;
    return { date, firstDay, lastDay, estimate };
};

export const calculateBillingTotal = (billingData: BaseBilling[]) => {
    return billingData.reduce((acc, data) => { return acc + data.total_usd; }, 0);
};