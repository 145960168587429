import Cookies from "js-cookie";
import moment from "moment";
import { SubmitHigherAccessRequestFormVars } from "../models/contactInfo";

export type HubspotPushParams = [
    HubspotMethodType,
    (string | object)?
]

export enum HubspotEventEnum {
    LoggedIn = 'Logged In',
    SignedUp = 'Signed Up',
    VerifiedEmail = 'Verified Email'
}

type HubspotMethodType = 'identify' | 'trackEvent' | 'trackPageView' | 'setPath'
type SignInType = 'Kaleido' | 'Google'

interface HubspotIdentifyAttributes {
    kaleido_account_region?: string
    kaleido_email_confirmed?: boolean
    kaleido_sign_in_type?: SignInType
    account_signup_started?: boolean
    first_conversion_event_name?: string
    recent_conversion_event_name?: string
    first_conversion_date?: number
    recent_conversion_date?: number
}

export const hubspotIdentify = (email: string, attrs?: HubspotIdentifyAttributes) => {
    const _hsq = window._hsq
    _hsq?.push(['identify', {
        email,
        ...attrs
    }]);
}

export const hubspotTrackEvent = (id: HubspotEventEnum, value: string) => {
    const _hsq = window._hsq
    _hsq?.push(['trackEvent', { id, value }]);
}

export const hubspotTrackPageView = () => {
    const _hsq = window._hsq
    _hsq?.push(['setPath', window.location.pathname]);
    _hsq?.push(['trackPageView'])
}

export const verifyEmailInHS = (email: string, region?: string, signInType?: SignInType) => {
    hubspotIdentify(email, {
        kaleido_email_confirmed: true,
        kaleido_sign_in_type: signInType || 'Kaleido',
        kaleido_account_region: region,
        account_signup_started: true,
        first_conversion_event_name: 'Account Signup',
        recent_conversion_event_name: 'Account Signup',
        first_conversion_date: moment().startOf('day').valueOf(),
        recent_conversion_date: moment().startOf('day').valueOf()
    })
    hubspotTrackEvent(HubspotEventEnum.VerifiedEmail, email)
    hubspotTrackPageView()
};

export const requestHigherAccessFormFields = (type: string, source: string) => {
    const formFields: SubmitHigherAccessRequestFormVars = {
        formFields: {
            type,
            source,
            hubspotutk: Cookies.get('hubspotutk') ?? '',
        }
    }
    return formFields;
}