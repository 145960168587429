import { gql, MutationFunctionOptions } from '@apollo/client';

// INTERFACES

export type RoleType = 'admin' | 'user' | 'reader'
export const ROLES: RoleType[] = ['admin', 'user', 'reader']

export interface RolesTranslations {
    admin: string
    user: string
    reader: string
}

export const EnRolesTranslations: RolesTranslations = {
    admin: 'Admin',
    user: 'User',
    reader: 'Read Only'
}

export interface Role {
    _id: string
    user_id: string
    role: RoleType
    _revision: string
    created_at: string
    org_id: string
    email: string
    is_oidc_user: boolean
}

export interface RolesData {
    roles: Role[]
}

export interface CreateRoleData {
    createRole: Role
}

export interface DeleteRoleData {
    deleteRole: boolean
}

export interface UpdateRoleData {
    updateRole: Role
}

export interface CreateRoleVars {
    org_id: string
    role: RoleInput
}

export interface RoleInput {
    email?: string
    role?: RoleType
}

export interface DeleteRoleVars {
    org_id: string
    id: string
}

export interface UpdateRoleVars {
    org_id: string
    id: string
    role: RoleInput
}

// QUERIES & SUBSCRIPTIONS

export const RolesQuery = gql`
    query getRoles($org_id: String!) {
        roles(org_id: $org_id) {
            _id
            user_id
            role
            _revision
            created_at
            org_id
            email
            is_oidc_user
        }
    }`;

export const CreateRoleMutation = gql`
    mutation createRole($org_id: String!, $role: CreateRoleInput!) {
        createRole(org_id: $org_id, role: $role) {
            _id
            user_id
            role
            _revision
            created_at
            org_id
            email
        }
    }`;

export const DeleteRoleMutation = gql`
    mutation deleteRole($org_id: String!, $id: String!) {
        deleteRole(org_id: $org_id, id: $id)
    }`;

export const UpdateRoleMutation = gql`
    mutation updateRole($org_id: String!, $id: String!, $role: UpdateRoleInput!) {
        updateRole(org_id: $org_id, id: $id, role: $role) {
            _id
            user_id
            role
            _revision
            created_at
            org_id
            email
        }
    }`;

export function MakeRoleCreateMutationOptions(
    variables: CreateRoleVars,
    overrideOptions?: MutationFunctionOptions<CreateRoleData, CreateRoleVars>): MutationFunctionOptions<CreateRoleData, CreateRoleVars> {
    return {
        ...{
            variables,
            update(cache, { data }) {

                if (data?.createRole) {
                    const { roles } = cache.readQuery<RolesData>({ query: RolesQuery, variables })!;
                    cache.writeQuery({
                        query: RolesQuery,
                        data: { roles: roles.concat(data?.createRole) },
                        variables: { org_id: variables.org_id }
                    });
                }
            }
        }, ...overrideOptions
    }
}

export function MakeRoleDeleteMutationOptions(
    variables: DeleteRoleVars,
    overrideOptions?: MutationFunctionOptions<DeleteRoleData, DeleteRoleVars>): MutationFunctionOptions<DeleteRoleData, DeleteRoleVars> {
    return {
        ...{
            variables,
            update(cache, { data }) {
                if (data?.deleteRole) {
                    const { roles } = cache.readQuery<RolesData>({ query: RolesQuery, variables })!;
                    cache.writeQuery({
                        query: RolesQuery,
                        data: { roles: roles.filter(role => role._id !== variables.id) },
                        variables: { org_id: variables.org_id }
                    });
                }
            }
        }, ...overrideOptions
    }
}