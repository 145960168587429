import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more.js"
import SolidGauge from "highcharts/modules/solid-gauge.js";
import { useTranslation } from 'react-i18next';
import { BaseChartOptions } from '../../../utils/ChartOptions';
import { BrandColors } from '../../../utils/Colors';
import { PieChart } from '../../../components/Charts/PieChart';
import moment from 'moment';

interface Props {
    memberships: number
    nodes: number
    otherRuntimes: number
    storage: number
    support: number
}

export const UsagePieChart: React.FC<Props> = ({ memberships, nodes, otherRuntimes, storage, support }: Props) => {
    Highcharts.setOptions(BaseChartOptions);
    HighchartsMore(Highcharts);
    SolidGauge(Highcharts);

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransactionsGauge', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransactionsGauge:${key}`, interpolate);

    const data = [
        { name: 'memberships', y: memberships, color: BrandColors.blurple },
        { name: 'nodes', y: nodes, color: BrandColors.cyan },
        { name: 'otherRuntimes', y: otherRuntimes, color: BrandColors.magenta },
        { name: 'storage', y: storage, color: BrandColors.purple },
        { name: 'support', y: support, color: BrandColors.yellow },
    ];

    function labelFormatter(this: Highcharts.Point | Highcharts.Series) {
        return lt('pieChartItemLabel', { item: lt(this.name as keyof translations), value: getValue(this.name).toFixed(2) });
    }

    const getValue = (key: string) => {
        switch(key) {
            case 'memberships': return memberships;
            case 'nodes': return nodes;
            case 'otherRuntimes': return otherRuntimes;
            case 'storage': return storage;
            case 'support': return support;
            default: return 0;
        }
    };

    return <PieChart
        title={lt('costBreakDown')}
        subtitle={moment().format('MMM YYYY')}
        labelFormatter={labelFormatter}
        data={data}
        totalDecimals={2}
        totalPrefix="$"
        customTotalSubtitle={lt('monthToDate')}
        height={'486px'}
        />
}

interface translations {
    costBreakDown: string
    pieChartItemLabel: string
    memberships: string
    nodes: string
    otherRuntimes: string
    storage: string
    support: string
    monthToDate: string
}
const enTranslations: translations = {
    costBreakDown: 'Cost Breakdown',
    // eslint-disable-next-line
    pieChartItemLabel: '{{item}} ${{value}}',
    memberships: 'Memberships',
    nodes: 'Nodes',
    otherRuntimes: 'Other Runtimes',
    storage: 'Storage',
    support: 'Support',
    monthToDate: 'Month to date'
}