import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import { LogsData, LogsVars, LogsQuery } from '../../models'
import { makeStyles, CircularProgress } from "@material-ui/core";
import { MessageSnackbar, ErrorSnackbarCatcher } from '../DialogWrappers'

// fetch for recent logs every X ms
const REFETCH_DELAY = 5000

interface Props {
    container: string
};

export const LogsContent = ({ container }: Props) => {
    const classes = useStyles();

    const { consortium_id, environment_id, node_id, service_id } = useParams<any>();

    const resourceVars = useMemo(() => ({
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: node_id || service_id!
    }), [consortium_id, environment_id, node_id, service_id])

    // create the ref for the pre tag
    const preRef = useRef<HTMLPreElement>(null)

    const [message, setMessage] = useState('');
    const [scrollHeight, setScrollHeight] = useState(0)
    const [initialBeforeposState, setInitialBeforeposState] = useState<string>()
    const [logEntries, setLogEntries] = useState([] as JSX.Element[])
    
    const getPollingVariables = useCallback(() => (
        {
            ...resourceVars,
            resource: node_id ? 'node' : 'service',
            container: container
        }
    ), [resourceVars, container, node_id])

    // create the lazy query which will be used to fetch previous log entries on scroll up
    const [fetchPrevious, { 
        loading: previousLoading, 
        error: previousError,
        data: { 
            logs: {
                beforepos = undefined,
                logs: previousLogs = [] as string[]
            }
        } = { logs: { beforepos: undefined, logs: [] as string[] } }
    }] = useLazyQuery<LogsData, LogsVars>(LogsQuery, { 
        fetchPolicy: 'no-cache' 
    })

    // create the initial logs query and the one that will be refetched to grab the latest logs
    const {
        loading,
        refetch,
        error,
        data: logsData
    } = useQuery<LogsData, LogsVars>(LogsQuery, { 
        variables: getPollingVariables(),
        fetchPolicy: 'no-cache',
        errorPolicy: 'all' // show error when node is unavailable. we also need to cope with logsData = null
    });

    // establish the default since a failed query causes the data to come back as null, _not_ undefined
    const defaultLogs = { 
        beforepos: undefined, 
        frompos: undefined, 
        logs: [] as string[] 
    }

    const  { 
        logs: {
            beforepos: intialLoadBeforepos = undefined,
            frompos = undefined,
            logs = [] as string[]
        } = defaultLogs 
    } = (!error && logsData) || { logs: defaultLogs }

    // show error if either query fails
    useEffect(() => {
        if (error || previousError) {
            ErrorSnackbarCatcher(error || previousError, setMessage)
        }
    }, [error, previousError]);

    // beforeposState needs to be initally set with the inital beforepos value from the first time the logs query fires
    useEffect(() => {
        if (!initialBeforeposState) {
            setInitialBeforeposState(intialLoadBeforepos)
        }
    }, [intialLoadBeforepos, initialBeforeposState]);
    
    // setup polling interval for the latest log entries by fetching every X seconds using the most recent frompos result
    const intervalRef = useRef<number>(0)
    useEffect(() => {
        return () => window.clearInterval(intervalRef.current);
    }, []);
    useEffect(() => {
        if (frompos && !loading && !previousLoading) {
            window.clearInterval(intervalRef.current)
            intervalRef.current = window.setInterval(async () => {
                try {
                    await refetch({...getPollingVariables(), frompos})
                }
                catch(err) {
                    // This is a background error - we keep retrying.
                    console.error('Failed to fetch logs', err);
                }
            }, REFETCH_DELAY);
        }
    }, [frompos, getPollingVariables, loading, previousLoading, refetch])

    // handle appending new logs (and the logs from the initial fetch)
    useEffect(() => {
        if (logs.length) {
            console.log(`updating logs frompos ${frompos}`)
            setLogEntries(existingEntries => [
                ...existingEntries, 
                ...logs.map((log, i) => <div key={`${i}-frompos-${frompos}`}>{log}</div>)
            ])
        }
    }, [logs, frompos])

    // handle appending previous logs
    useEffect(() => {
        if (previousLogs.length) {
            console.log(`updating logs beforepos ${beforepos}`)
            setLogEntries(existingEntries => [
                ...previousLogs.map((log, i) => <div key={`${i}-beforepos-${beforepos}`}>{log}</div>), 
                ...existingEntries
            ])
        }
    }, [previousLogs, beforepos])

    // handle the scrolling when updates occur
    // useLayoutEffect so the scrolling happens on the already rendered log display container
    useLayoutEffect(() => {
        if (logEntries.length && preRef.current) {
            const oldScrollHeight = scrollHeight
            const newScrollHeight = preRef.current.scrollHeight

            // if window didnt change, nothing to scroll to
            if (oldScrollHeight === newScrollHeight) {
                return
            }

            const scrollTop = preRef.current.scrollTop
            const clientHeight = preRef.current.clientHeight

            // if your at the top, scroll down to where the update occurred
            if (scrollTop === 0) {
                preRef.current.scrollTo({top: newScrollHeight - oldScrollHeight, behavior: 'smooth'})
            } else if ((scrollTop + clientHeight) >= oldScrollHeight) { //if your at the bottom, keep scrolled to the bottom 
                preRef.current.scrollTo({top: newScrollHeight, behavior: 'smooth'})
            } 
            
            // update to the new scroll height
            setScrollHeight(newScrollHeight)
        }
    }, [logEntries.length, scrollHeight]) 

    // handle the onscroll event when scrolling within the logs <pre> container
    const scrollForPrevious = () => {
        if (preRef.current && preRef.current.scrollTop < 1 && !previousLoading) {
            // reuse the beforepos we get from the previously run fetchPrevious query
            // but, if this is the first time running fetchingPrevious, beforepos is undefined
            // so, use the initialBeforeposState
            const before = beforepos !== undefined ? beforepos : initialBeforeposState

            // dont fetchPrevious if beforepos was 0
            if (before) {
                fetchPrevious({
                    variables: {...getPollingVariables(), ...{beforepos: before}}
                })
            }
        }
    }

    const content = (
        <pre className={classes.pre} onScroll={scrollForPrevious} ref={preRef}>
            <code>
                {loading && <CircularProgress data-test="loadingLogs" color="inherit" />}
                {logEntries}
            </code>
        </pre>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            {content}
        </>
    )
}

const useStyles = makeStyles(theme => ({
    pre: {
        margin: '0px',
        padding: theme.spacing(0,2),
        maxWidth: '100%',
        overflow: 'auto',
        height: '60vh',
        backgroundColor: 'black',
        color: 'white',
        fontSize: 14
    }
}));
