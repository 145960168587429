import React, { useState, useEffect } from 'react';
import { FormDialog } from '../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { CreateMembershipMutation, CreateMembershipVars, CreateMembershipData, ConsortiumMembershipsQuery } from '../../models';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateMembership = ({ open, setOpen }: Props) => {

    const { org_id, consortium_id } = useParams<any>();

    const { t, i18n } = useTranslation();

    i18n.addResourceBundle('en', 'GovernanceCreateMembership', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceCreateMembership:${key}`, interpolate)

    const [name, setName] = useState<string>('');

    useEffect(() => {
        setName('');
    }, [open]);

    const [createMembership, { loading: createMembershipLoading }] = useMutation<CreateMembershipData, CreateMembershipVars>(CreateMembershipMutation);

    const content = (
        <TextField data-test="textField_membershipName"
            value={name} 
            autoFocus 
            label={lt('membershipName')} 
            fullWidth 
            variant="outlined" 
            onChange={event => setName(event.target.value)} 
        />
    );

    const updater = () => {
        return createMembership({ 
            variables: {
                consortia_id: consortium_id!, 
                membership: { 
                    org_name: name, 
                    org_id: org_id! 
                } 
            },
            refetchQueries: [{
                query: ConsortiumMembershipsQuery,
                variables: {
                    consortia_id: consortium_id
                }
            }]
        }).then(() => { }).catch(() => {});
    } 

    return (
        <FormDialog dataTestId="addMembership"
            {...{ open }}
            {...{ setOpen }}
            header={lt('addMemberToMyOrg')}
            saveDisabled={createMembershipLoading || !name}
            controlsWrapper={content}
            onSave={updater}
            closeDialogAfterSave
            successMessage={lt('confirmation')}
            saveText={lt('add')} />
    )

};

interface translations {
    addMemberToMyOrg: string
    add: string
    membershipName: string
    confirmation: string
}
const enTranslations: translations = {
    addMemberToMyOrg: 'Add membership',
    add: 'Add',
    membershipName: 'Membership Name',
    confirmation: 'Membership has been added'
}
