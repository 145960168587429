import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import { TokenContractBalanceOfData, TokenContractBalanceOfVars, TokenContractBalanceOfQuery } from '../../models'
import { Typography, CircularProgress } from "@material-ui/core";

interface Props {
    contractAddress: string
};

export const TokenBalance = ({ contractAddress }: Props) => {
    const { consortium_id, environment_id, address } = useParams<any>();

    const balanceOfVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        contractAddress,
        accountAddress: address!
    }

    const {
        loading,
        data: {
            tokenContractBalanceOf
        } = { tokenContractBalanceOf: '' }
    } = useQuery<TokenContractBalanceOfData, TokenContractBalanceOfVars>(TokenContractBalanceOfQuery, { 
        variables: balanceOfVars
    });    

    return (
        <>
            {loading && <CircularProgress />}
            <Typography variant="body2" color="inherit">{tokenContractBalanceOf}</Typography>
        </>
    )
};

