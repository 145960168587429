import React, { Dispatch, SetStateAction } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ServicesData, ServicesQuery } from '../../models';
import { EnvironmentResourcesVars, AutocompleteIdName } from '../../interfaces'
import { IdNameAutocomplete } from './IdNameAutocomplete';

interface NodeAutocompleteProps {
    service: AutocompleteIdName,
    setService: Dispatch<SetStateAction<AutocompleteIdName>>,
    serviceType: 'ipfs' | 'firefly-os' | 'fabric-ca',
    membershipId: string,
    dataTest?: string,
    envZoneId: string | undefined
};
  
export const ServiceAutocomplete = (props: NodeAutocompleteProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ServiceAutocomplete', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ServiceAutocomplete:${key}`, interpolate)
    const { consortium_id, environment_id } = useParams<any>();
    let label = '';
    let isAutocompleteDisabled = false;

    const {
        data: {
            services: allServices
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },        
        fetchPolicy: 'cache-only'
    });

    const services = allServices.filter(n => n.membership.isMine && n.membership_id === props.membershipId && n.service === props.serviceType && (props.envZoneId && n.zone_id === props.envZoneId)); 
    let defaultId: string | undefined = undefined

    if (props.serviceType === "fabric-ca") {
        // if a fabric CA node aready exists, pre set it and disable autocomplete
        if(services.length !== 0) {
            defaultId = services[0]._id
            isAutocompleteDisabled = true;
        }
    }

    switch (props.serviceType) {
        case 'ipfs':
            label = lt('selectOrAddIPFS');
            break;
        case 'fabric-ca':
            label = lt('selectOrAddFabricCa');
            break;
    }

    const options = services.map(m => ({id: m._id, name: m.name}))

    return (
        <IdNameAutocomplete selected={props.service} setSelected={props.setService} options={options} disabled={isAutocompleteDisabled}
            defaultId={defaultId} dataTest={props.dataTest} label={label} setWidth={400} />
    )
};

interface translations {
    selectOrAddIPFS: string,
    selectOrAddFabricCa: string
}
const enTranslations: translations = {
    selectOrAddIPFS: 'Select existing or create a new IPFS runtime',
    selectOrAddFabricCa: 'Select existing or create a new Fabric CA runtime'
}