import React from 'react';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CancelOutlinedIcon from 'mdi-react/CancelIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import AccountMultiple from 'mdi-react/AccountMultipleIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import FileIcon from 'mdi-react/FileIcon';
import LanIcon from 'mdi-react/LanIcon';
import SignTextIcon from 'mdi-react/SignTextIcon';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Membership } from '../../models';
import { SYSTEM_MEMBERSHIP } from '../MainNav/SideNavs/AddressBook';
import { BrandColors } from '../../utils/Colors';
import { DisplayCard } from '../DisplayWrappers';

interface Props {
    membership?: Membership | null
}

export const MembershipPermissions = ({membership}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MembershipPermissions', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MembershipPermissions:${key}`, interpolate);

    const { membership_id } = useParams<any>();

    const classes = useStyles();

    const itemList = [
        {
            title: lt('createSignersTitle'),
            value: lt('createSignersDescription'),
            disabled: membership_id === SYSTEM_MEMBERSHIP,
            icon: <SignTextIcon />,
            actionIcon: membership?.permissions.create_signers  ? <CheckCircleIcon className={classes.checkIcon} fontSize="large" /> :
                membership_id === SYSTEM_MEMBERSHIP ? <CloseCircleIcon fontSize="large" color={BrandColors.magenta} /> : <CancelOutlinedIcon fontSize="large" />
        },
        {
            title: lt('manageEnvironmentsTitle'),
            value: lt('manageEnvironmentsDescription'),
            disabled: membership_id === SYSTEM_MEMBERSHIP,
            icon: <LanIcon />   ,
            actionIcon: membership?.permissions.manage_envs  ? <CheckCircleIcon className={classes.checkIcon} fontSize="large" /> : 
                membership_id === SYSTEM_MEMBERSHIP ? <CloseCircleIcon fontSize="large" color={BrandColors.magenta} /> : <CancelOutlinedIcon fontSize="large" />
        },
        {
            title: lt('inviteOrganizationsTitle'),
            value: lt('inviteOrganizationsDescription'),
            disabled: membership_id === SYSTEM_MEMBERSHIP,
            icon: <DomainIcon />,
            actionIcon: membership?.permissions.invite_orgs  ? <CheckCircleIcon className={classes.checkIcon} fontSize="large" /> : 
                membership_id === SYSTEM_MEMBERSHIP ? <CloseCircleIcon fontSize="large" color={BrandColors.magenta} /> : <CancelOutlinedIcon fontSize="large" />
        },
        {
            title: lt('multipleMembershipsTitle'),
            value: lt('multipleMembershipsDescription'),
            disabled: membership_id === SYSTEM_MEMBERSHIP,
            icon: <AccountMultiple />,
            actionIcon: membership?.permissions.multiple_members  ? <CheckCircleIcon className={classes.checkIcon} fontSize="large" /> : 
                membership_id === SYSTEM_MEMBERSHIP ? <CloseCircleIcon fontSize="large" color={BrandColors.magenta} /> : <CancelOutlinedIcon fontSize="large" />
        },
        {
            title: lt('manageContractsTitle'),
            value: lt('manageContractsDescription'),
            icon: <FileIcon />,
            disabled: membership_id === SYSTEM_MEMBERSHIP,
            actionIcon: membership?.permissions.manage_contracts === false ? <CancelOutlinedIcon fontSize="large" /> : membership?.permissions.manage_contracts === true
                || (membership?.permissions.manage_contracts === null && membership?.permissions.manage_envs) ? <CheckCircleIcon className={classes.checkIcon} fontSize="large" />
                : membership_id === SYSTEM_MEMBERSHIP ? <CloseCircleIcon fontSize="large" color={BrandColors.magenta} /> : <CancelOutlinedIcon fontSize="large" />
        }
    ]


    return <DisplayCard header={lt('permissions')} {...{itemList}} />

}

const useStyles = makeStyles(theme => ({
    checkIcon: {
        color: theme.palette.primary.main
    }
}));

interface translations {
    permissions: string,
    createSignersTitle: string,
    createSignersDescription: string,
    manageEnvironmentsTitle: string,
    manageEnvironmentsDescription: string,
    inviteOrganizationsTitle: string,
    inviteOrganizationsDescription: string,
    multipleMembershipsTitle: string,
    multipleMembershipsDescription: string,
    manageContractsTitle: string,
    manageContractsDescription: string,
}

const enTranslations: translations = {
    permissions: 'Permissions',
    createSignersTitle: 'Create Signers',
    createSignersDescription: 'Create signing nodes in environments',
    manageEnvironmentsTitle: 'Manage Environments',
    manageEnvironmentsDescription: 'Create and upgrade environments',
    inviteOrganizationsTitle: 'Invite Organizations',
    inviteOrganizationsDescription: 'Invite other organizations to the consortium',
    multipleMembershipsTitle: 'Multiple Memberships',
    multipleMembershipsDescription: 'Create additional memberships for oneself',
    manageContractsTitle: 'Manage Contracts',
    manageContractsDescription: 'Promote managed smart contracts to environments'
}