import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Environment-Protocol.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            finalInfo={lt('helpFooter')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    helpFooter: string,
}
const enTranslations: translations = {
    helpHeader: 'What is an app?',
    helpDescription: 'Kaleido Apps help you manage shared business logic across your decentralized network',
    helpDesc1: 'Contain complied versions of your smart contracts',
    helpDesc2: 'Allow network participants to track those compilations back to the original source code version & approvals',
    helpDesc3: 'Manage promotion and tracking of those compilations to your blockchain environments',
    helpFooter: 'Each app is scoped to one Blockchain technology, so the first step is to choose the protocol you will be working with in your environments',
}