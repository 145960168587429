import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RadioSelector, RadioSelectorOptions } from '../../../components/FormControls/RadioSelector';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_MANAGED_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import { RuntimeCreationFinalStep } from '../../../components/RuntimeCreation/RuntimeCreationFinalStep';
import { ConsortiumZone, EnServicesTranslations, EnvironmentZone, ManagedWalletServices, ServicesEnum, ServicesTranslations } from '../../../models';
import { Step2Help } from './Step2Help';

interface Props {
    cancelPath: string
    name: string
    membershipId: string,
    consortiumZoneId: string,
    consortiumZones: ConsortiumZone[],
    environmentZones: EnvironmentZone[],
}


export const Step2 = ({cancelPath, name, consortiumZones, environmentZones, membershipId, consortiumZoneId}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateManagedWalletsStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateManagedWalletsStep2:${key}`, interpolate)

    const [walletType, setWalletType] = useState('');

    const { consortium_id, environment_id, org_id } = useParams<any>();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const wallets = [...ManagedWalletServices];

    const radioOptions: RadioSelectorOptions[] = wallets.map(walletType => ({
        value: walletType,
        label: lt(ServicesEnum[walletType]),
        description: walletType === 'hdwallet' ? lt('hdwalletDescription') : lt('ethwalletDescription')
    }))

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">{lt('header')}</Typography>
            </Grid>
            <Grid item container direction="column">
                <Grid item>
                    <Typography variant="body1">{lt('selectWalletType')}</Typography> 
                </Grid>
                <Grid item>
                    <RadioSelector options={radioOptions} selected={walletType} onChange={(e) => setWalletType(e.target.value)} />
                </Grid>
            </Grid>
        </>
    )

    const disabled = !walletType

    return (
        <>
            <RuntimeCreationFinalStep disabled={disabled}
                servicePath={`${basePath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${walletType === 'hdwallet' ? ServicesEnum.hdwallet : ServicesEnum.ethwallet}`}
                service={walletType === 'hdwallet' ? ServicesEnum.hdwallet : ServicesEnum.ethwallet}
                {...{cancelPath}}
                {...{name}}
                {...{membershipId}}
                {...{consortiumZones}}
                {...{consortiumZoneId}}
                {...{environmentZones}}
                {...{content}}
                helpContent={<Step2Help />} />
        </>
    )
}

interface translations extends ServicesTranslations {
    header: string
    walletType: string
    selectWalletType: string
    hdwalletDescription: string
    ethwalletDescription: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    header: 'Managed Wallets',
    walletType: 'Wallet Type',
    selectWalletType: 'Select Wallet Type',
    hdwalletDescription: 'Submit transactions anonymously, mask your identity & manage accounts',
    ethwalletDescription: 'A simple and secure way to manage key ownership for signing transactions.'
}