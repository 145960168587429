import { useQuery } from '@apollo/client';
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import LocationOnIcon from 'mdi-react/MapMarkerIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { ComingSoon, EmptyState } from '../../components/DisplayWrappers';
import { DisplayTable } from '../../components/DisplayWrappers/DisplayTable';
import { DisplayTableColumns } from '../../components/DisplayWrappers/DisplayTableRow';
import { ADDRESSBOOK_DESTINATIONS_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { ConsortiumResourcesVars, ConsortiumResourceVars, ServiceResourcesVars } from '../../interfaces';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery, EnvironmentData, EnvironmentQuery, IDRegistryAllProfilePropertiesData, IDRegistryAllProfilePropertiesQuery, ServicesData, ServicesQuery } from '../../models';

export const AddressBookDestinations: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'AddressBookDestinations', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookDestinations:${key}`, interpolate)

    const { org_id, consortium_id, environment_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const isCorda = environment?.isCorda;

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const idRegistryServiceID = services.find(entry => entry.service === 'idregistry' && entry.state === 'started')?._id;

    const {
        loading,
        data: {
            idRegistryOrgs
        } = { idRegistryOrgs: [] }
    } = useQuery<IDRegistryAllProfilePropertiesData, ServiceResourcesVars>(IDRegistryAllProfilePropertiesQuery, {
        variables: { service_id: idRegistryServiceID! },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryServiceID
    });

    if (isCorda) {
        return <ComingSoon page="offChainTransfer" />
    }

    if(idRegistryOrgs.length === 0) {
        if(loading) {
            return <CircularProgress />
        } else {
            return (
                <EmptyState
                    title={lt('destinations')}
                    description={lt('emptyStateDescription')}
                    imageFile='Empty-Destination.svg'
                    documentation={{text: lt('emptyStateDocumentation'), link: 'https://docs.kaleido.io/kaleido-services/document-store/'}}
                />
            )
        }
    }

    let myDestinations: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    let otherDestinations: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    for (const idRegistryOrg of idRegistryOrgs) {
        const orgName = idRegistryOrg.name.substr(1);
        for (const profileProperty of idRegistryOrg.details.profile_properties) {
            if (/^kld:\/\/documentstore|app2app\//.test(profileProperty.name) && profileProperty.value.startsWith('-----BEGIN CERTIFICATE-----')) {

                if (consortiumMemberships.find(membership => membership._id === orgName)?.is_mine) {
                    myDestinations.push({
                        key: profileProperty.name,
                        columns: [
                            {
                                value: <LocationOnIcon />
                            },
                            {
                                value: profileProperty.name.substr(profileProperty.name.lastIndexOf('/') + 1)
                            },
                            {
                                value: profileProperty.name.startsWith('kld://app2app') ? lt('app2app') : lt('documentStore')
                            },
                            {
                                value: consortiumMemberships.find(membership => membership._id === orgName)?.org_name ?? ''
                            },
                            {
                                value: profileProperty.name
                            }
                        ],
                        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${orgName}/${ADDRESSBOOK_DESTINATIONS_PATH}/${encodeURIComponent(profileProperty.name)}`),
                    });

                } else {
                    otherDestinations.push({
                        key: profileProperty.name,
                        columns: [
                            {
                                value: <LocationOnIcon />
                            },
                            {
                                value: profileProperty.name.substr(profileProperty.name.lastIndexOf('/') + 1)
                            },
                            {
                                value: profileProperty.name.startsWith('kld://app2app') ? lt('app2app') : lt('documentStore')
                            },
                            {
                                value: consortiumMemberships.find(membership => membership._id === orgName)?.org_name ?? ''
                            },
                            {
                                value: profileProperty.name
                            }
                        ],
                        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${orgName}/${ADDRESSBOOK_DESTINATIONS_PATH}/${encodeURIComponent(profileProperty.name)}`),
                    });
                }

            }
        }
    }

    const columnHeaders = [
        '',
        lt('name'),
        lt('type'),
        lt('owner'),
        lt('uri')
    ];

    return (
        <Grid container spacing={3}>
            <Grid item container direction={"column"} spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('myDestinations', { count: myDestinations.length })}
                    </Typography>
                </Grid>
                <Grid item>
                    {myDestinations.length > 0 && <DisplayTable header={lt('destinations')} columnHeaders={columnHeaders} records={myDestinations} />}
                </Grid>
            </Grid>
            <Grid item container direction={"column"} spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('otherDestinations', { count: otherDestinations.length })}
                    </Typography>
                </Grid>
                <Grid item>
                    {otherDestinations.length > 0 && <DisplayTable header={lt('destinations')} columnHeaders={columnHeaders} records={otherDestinations} />}
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    myDestinations: string
    otherDestinations: string
    destinations: string
    owner: string
    name: string
    type: string
    uri: string
    app2app: string
    documentStore: string
    emptyStateTitle: string
    emptyStateDescription: string
    emptyStateDocumentation: string
}

const enTranslations: translations = {
    myDestinations: 'My Destinations ({{count}})',
    otherDestinations: 'Other Destinations ({{count}})',
    destinations: 'Destinations',
    owner: 'Owner',
    name: 'Name',
    type: 'Type',
    uri: 'URI',
    app2app: 'App2App',
    documentStore: 'Document Exchange',
    emptyStateTitle: 'No Destinations',
    emptyStateDescription: 'No destinations have been configured yet in this environment. A destination is a programmatically targetable URI for secure submission of signed-and-encrypted messages and documents.',
    emptyStateDocumentation: 'Documentation',
}