import React, {useState, useCallback, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { AddIdentityMutation, AddIdentityVars } from '../../../../models';
import { ProcessItem } from '../../../../components/DisplayWrappers/ProcessItem';

interface Props {
    isSetupOnProgress: boolean
    setIsSetupOnProgress: React.Dispatch<React.SetStateAction<boolean>>
    hasCert: boolean
    hasIdReg: boolean
    setHasCert: React.Dispatch<React.SetStateAction<boolean>>
    membershipId: string
};

export const CertSetup = ({ hasCert, hasIdReg, setHasCert, isSetupOnProgress, membershipId, setIsSetupOnProgress }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CertSetup', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CertSetup:${key}`, interpolate)

    const [loading, setLoading] = useState(false);
    const { consortium_id } = useParams<any>();
    const [createIdentity] = useMutation<any, AddIdentityVars>(AddIdentityMutation);

    const generateCert = useCallback(async () => {
        try {
            setLoading(true);
            await createIdentity({
                variables: {
                    consortia_id: consortium_id,
                    id: membershipId,
                    identity: {
                        test_certificate: true
                    }
                }
            })
            setHasCert(true);
        } catch (err) {
            setIsSetupOnProgress(false);
        } finally {
            setLoading(false);
        }
    }, [membershipId, consortium_id, createIdentity, setHasCert, setIsSetupOnProgress])

    useEffect(() => {
        if(hasCert) setLoading(false);
    }, [hasCert]);

    useEffect(() => {
        if (isSetupOnProgress && !hasCert && hasIdReg) generateCert()
    }, [isSetupOnProgress, hasCert, hasIdReg, generateCert])

    return (
        <ProcessItem title={lt('certificate')} isDone={hasCert} loading={loading} statusMessage={hasCert ? lt('provisioned') : lt('notProvisioned')} />
    )
};

interface translations {
    notProvisioned: string,
    certificate: string,
    provisioned: string
}
const enTranslations: translations = {
    notProvisioned: 'Not Established',
    certificate: 'Establish Membership Identity',
    provisioned: 'Established'
}
