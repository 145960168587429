import { gql } from '@apollo/client';

export const SessionQuery = gql`
    query Session {
        session @client {
            realm
            email
            iat
            exp
            user_id
            selected_org
            social_login
        }
    }`;
