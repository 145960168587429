import {
    Drawer, Grid, IconButton, makeStyles, Tab, Tabs, Typography
} from '@material-ui/core';
import CloseIcon from 'mdi-react/CloseIcon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { BrandColors } from '../../utils/Colors';
import { StyledBadge } from '../AppWrapper/StyledBadge';
import { Category, ContactUs } from './ContactUs/ContactUs';
import { Documentation } from './Documentation';
import { SupportInbox } from './SupportInbox/SupportInbox';
import TabPanel from './TabPanel';

export enum HelpCenterTab {
    DOCUMENTATION = 0,
    SHARE_FEEDBACK = 1,
    CONTACT_US = 2,
    SUPPORT_INBOX = 3
}

interface Props {
    isOpen: boolean;
    setOpen: (arg1: boolean) => void;
    pendingTicketsCount?: number;
    tab?: HelpCenterTab;
    preSelectedCategory?: Category
}

export const HelpCenter = ({
    isOpen,
    setOpen,
    pendingTicketsCount,
    tab = HelpCenterTab.CONTACT_US,
    preSelectedCategory,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'HelpCenter', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`HelpCenter:${key}`, interpolate);
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    
    const [currentTab, setCurrentTab] = React.useState(tab);

    // when re-opened, preset the tab from the caller
    useEffect(() => {
        if (isOpen) {
            setCurrentTab(tab)
        }
    }, [isOpen, tab]);

    useEffect(() => {
        setCurrentTab(tab);
    }, [tab]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
        setCurrentTab(newTab);
    };

    const handleClose = () => {
        setOpen(false);
        history.replace(location.pathname)
    }

    return (
        <Drawer
            classes={{
                paper: classes.root,
            }}
            anchor="right"
            open={isOpen}
            onClick={(event) => {
                event.stopPropagation();
            }}
            onClose={handleClose}
        >
            <div className={classes.topContainer}>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.header}
                >
                    <Typography variant="h5">{lt('helpCenter')}</Typography>
                    <IconButton color="inherit" size="medium" onClick={handleClose} data-testid='closeHelpCenter'>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    classes={{ indicator: classes.tabIndicator }}
                    aria-label="Help Center Tabs"
                    indicatorColor="primary"
                >
                    <Tab
                        classes={{
                            root: classes.tab,
                        }}
                        label={lt('documentation')}
                    />
                    <Tab
                        classes={{
                            root: classes.tab,
                        }}
                        label={lt('shareFeedback')}
                    />
                    <Tab
                        classes={{
                            root: classes.tab,
                        }}
                        label={lt('contactUs')}
                    />
                    <Tab
                        classes={{
                            root: classes.tab,
                        }}
                        label={
                            <StyledBadge
                                badgeContent={pendingTicketsCount}
                                classes={{ badge: classes.badge }}
                            >
                                <span>{lt('supportInbox')}</span>
                            </StyledBadge>
                        }
                    />
                </Tabs>
            </div>
            <TabPanel value={currentTab} index={HelpCenterTab.DOCUMENTATION}>
                <Documentation />
            </TabPanel>
            <TabPanel value={currentTab} index={HelpCenterTab.SHARE_FEEDBACK}>
                <ContactUs closeHelpCenter={handleClose} shareFeedback />
            </TabPanel>
            <TabPanel value={currentTab} index={HelpCenterTab.CONTACT_US}>
                <ContactUs closeHelpCenter={handleClose} preSelectedCategory={preSelectedCategory} />
            </TabPanel>
            <TabPanel value={currentTab} index={HelpCenterTab.SUPPORT_INBOX}>
                <SupportInbox setCurrentTab={setCurrentTab} />
            </TabPanel>
        </Drawer>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '650px',
        maxWidth: '100%',
        height: '100%',
    },
    topContainer: {
        borderBottom: `1px solid ${BrandColors.gray86}`,
    },
    header: {
        padding: '30px 30px 5px',
    },
    tab: {
        fontSize: 12,
        margin: '5px 0',
        padding: '0 10px',
    },
    tabIndicator: {
        height: 4,
    },
    badge: {
        position: 'relative',
    },
}));

interface translations {
    helpCenter: string;
    shareFeedback: string;
    supportInbox: string;
    contactUs: string;
    documentation: string;
}

const enTranslations: translations = {
    helpCenter: 'Help Center',
    shareFeedback: 'SHARE FEEDBACK',
    supportInbox: 'SUPPORT INBOX',
    contactUs: 'CONTACT SUPPORT',
    documentation: 'DOCUMENTATION',
};
