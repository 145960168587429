import { CircularProgress, TablePagination, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DisplayTable, Props as DisplayTableProps } from '../../components/DisplayWrappers';

const LIMITLIST = [5, 10, 25, 50, 100];

export interface SkipLimt {
    skip: number,
    limit: number
}

export interface Props {
    setSkipLimit: (skipLimit: SkipLimt) => void
    tableProps: DisplayTableProps
}

export const flatten = (o: any, prefix = '') => {
    if (!o) return {};
    let ret: {[k: string]: string} = {};
    for (let [k,v] of Object.entries(o)) {
        if (!v || k.startsWith('__')) continue;
        if (Array.isArray(v)) {
           ret[`${prefix}${k}`] = v.join(', ');
        } else if (typeof v === 'object') {
            ret = {...ret, ...flatten(v, `${prefix}${k}.`)};
        }
        else ret[`${prefix}${k}`] = String(v);
    }
    return ret;
}

export const OpsTable: React.FC<Props> = ({ setSkipLimit, tableProps }) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(0);

    useEffect(() => {
        if (pageSize === 0) {
            setPageSize(LIMITLIST[0]);
        } else {
            setSkipLimit({skip: currentPage * pageSize, limit: pageSize+1 /* to catch if there are more pages */});
        }
    }, [setSkipLimit, pageSize, currentPage]);

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0)
    }

    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        setCurrentPage(page)
    };

    const {records} = tableProps;

    if (pageSize === 0) return <></>;
    if (!records) return <CircularProgress />

    const upToAPage = records.slice(0, pageSize);
    const count = (records.length > pageSize) ? -1 : (currentPage * pageSize) + records.length;

    const pagination = <TablePagination rowsPerPageOptions={LIMITLIST} component="div" {...{count}} rowsPerPage={pageSize} onChangeRowsPerPage={onChangeRowsPerPage} onPageChange={onChangePage} page={currentPage} />;

    return (
        <Paper>
            <DisplayTable {...{...tableProps, records: upToAPage}} actionFooter={pagination} />
        </Paper>
    )
};
