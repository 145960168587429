import { useMutation, useQuery } from '@apollo/client';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { CookieAppCred } from '../../components/CookieAppCred/CookieAppCred';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { DisplayCard, DisplayGridWrapper } from '../../components/DisplayWrappers';
import { FIREFLY_BASE_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { WebUiCard } from '../../components/WebUICard/WebUICard';
import { ConsortiumResourceVars, EnvironmentResourceVars, LinkButtonProps } from '../../interfaces';
import { EnvironmentData, EnvironmentQuery, EnvironmentZonesData, EnvironmentZonesQuery, NodeData, NodeQuery, ServiceData, ServiceQuery } from '../../models';
import { HigherAccessRequestData, SubmitHigherAccessMutation, SubmitHigherAccessRequestFormVars } from '../../models/contactInfo';
import { IsFireflyInitializedData, IsFireflyInitializedQuery } from '../../models/firefly';
import { requestHigherAccessFormFields } from '../../utils/HubspotUtils';
import { FireflyServiceCard } from '../BlockchainDashboard/FireflyServiceCard';
import { FireflyStatusChip } from '../BlockchainDashboard/FireflyStatusChip';
import { NodeCard } from '../BlockchainDashboard/NodeCard';
import { ServiceStatusData, ServiceStatusQuery } from '../../models/servicesStatus';

export const Firefly = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    i18n.addResourceBundle("en", "fireflyOverview", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`fireflyOverview:${key}`, interpolate);

    const { consortium_id, service_id, environment_id, org_id } = useParams<any>();
    const [appCred, setAppCred] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error'|'success'|undefined>();
    const [openingWebUI, setOpeningWebUI] = useState(false);
    const [openingSandboxUI, setOpeningSandboxUI] = useState(false);
    const [openingWebAPI, setOpeningWebAPI] = useState(false);

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    const envVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [submitAccessRequest] =  useMutation<HigherAccessRequestData, SubmitHigherAccessRequestFormVars>(SubmitHigherAccessMutation)


    const {
        data: {
            service
        } = {service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            ...envVars,
            id: service_id
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: envVars,
        fetchPolicy: 'cache-only'
    });

    const nodeId = service?.details.node_id ?? '';
    const ipfsId = service?.details.ipfs_service_id ?? '';

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data : {
            node
        } = {node: null}
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, {
        variables: {
            ...envVars,
            id: nodeId
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            service: ipfsService
        } = {service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            ...envVars,
            id: ipfsId
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            isFireflyInitialized
        } = { isFireflyInitialized: { initialized: false, readyForRegistration: false, fireflyTokensErc1155: false } },
    } = useQuery<IsFireflyInitializedData, EnvironmentResourceVars>(IsFireflyInitializedQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            id: service?._id || '',
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 5000,
        skip: !service?._id || environment?.state !== 'live'
    });

    const {
        data: {
            serviceStatus
        } = { serviceStatus: null }
    } = useQuery<ServiceStatusData, EnvironmentResourceVars>(ServiceStatusQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            id: service?._id ?? '',
        },
        fetchPolicy: 'cache-and-network',
        skip: !service || environment?.state !== 'live' || service?.state !== 'started'
    });
    
   const webUIUrl = `https://${appCred}@${service?.urls?.webui?.slice(8)}`;
   const webAPIUrl = `https://${appCred}@${service?.urls?.swaggerui?.slice(8)}`;
   const sandboxUIUrl = `https://${appCred}@${service?.urls?.sandboxui?.slice(8)}`;

    useEffect(() => {
        if (openingWebUI && appCred) {
            window.open(webUIUrl);
            setOpeningWebUI(false)
        }
    }, [openingWebUI, appCred, webUIUrl])

    useEffect(() => {
        if (openingWebAPI && appCred) {
            window.open(webAPIUrl);
            setOpeningWebAPI(false)
        }
    }, [openingWebAPI, appCred, webAPIUrl])

    useEffect(() => {
        if (openingSandboxUI && appCred) {
            window.open(sandboxUIUrl);
            setOpeningSandboxUI(false)
        }
    }, [openingSandboxUI, appCred, sandboxUIUrl])

    useEffect(() => {
        setAppCred('')
    }, [service?._id])

    if (!service) return <Redirect to={`${basePath}/${FIREFLY_BASE_PATH}`} />

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("fireflyDocumentation"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://hyperledger.github.io/firefly/"
                ),
        }
    ];

    const copyableList: CopyableSetting[] = [
        {
            title: lt("name"),
            displayValue: service.name || "",
            disableCopy: true,
        }, {
            title: lt("id"),
            displayValue: service._id || '',
        }, {
            title: lt("status"),
            displayValue: service.state ? (
                <FireflyStatusChip service={service} />
            ) : (
                ""
            ),
            disableCopy: true,
        }, {
            title: lt("owner"),
            displayValue: service.membership.name || "",
            disableCopy: true,
        }, {
            title: lt('createdOn'),
            displayValue: new Date(service.created_at || '').toLocaleString(),
            disableCopy: true,
        }
    ];

    if (service.urls?.http) {
        copyableList.push({
            title: lt('apiEndpoint'),
            displayValue: service.urls?.http || ''
        }) 
    }

    if (service.urls?.ws) {
        copyableList.push({
            title: lt('wsEndpoint'),
            displayValue: service.urls?.ws || ''
        }) 
    }

    if (service.urls?.webui) {
        copyableList.push({
            title: lt('webUIEndpont'),
            displayValue: service.urls?.webui || ''
        }) 
    }

    if (service.urls?.sandboxui) {
        copyableList.push({
            title: lt('sandboxUIEndpoint'),
            displayValue: service.urls?.sandboxui || ''
        }) 
    }

    if (service.urls?.swaggerui) {
        copyableList.push({
            title: lt('swaggerUIEndpont'),
            displayValue: service.urls?.swaggerui || ''
        }) 
    }

    const onWebUIClick = () => {
        setOpeningWebUI(true);
    }

    const onSandboxUIClick = () => {
        setOpeningSandboxUI(true);
    }

    const onWebAPIClick = () => {
        setOpeningWebAPI(true);
    }

    const requestMediumLargeNode: LinkButtonProps = {
        text: lt('requestAccess'),
        variant: 'contained',
        color: 'primary',
        disabled: requestSubmitted,
        onClick: async () => {
            const formFields = requestHigherAccessFormFields(
                'dedicatedWeb3Solution',
                window.location.href
            );
            const { data } = await submitAccessRequest({
                variables: {
                    ...formFields,
                },
            });
            if (data?.requestHigherAccess) {
                setMessageType('success');
                setMessage(lt('requestSubmitted'));
                setRequestSubmitted(true);
            }
        },
    };

    const web3Offering = (title: string, imagePath: string) => {
        return (
            <Grid container direction="row" spacing={1}>
                <Grid item >
                    <img
                        src={`${process.env.PUBLIC_URL}/img/AssetPlatform/${imagePath}.svg`}
                        alt={`${imagePath}`}
                    ></img>
                </Grid>
                <Grid item className={classes.solutionsText}>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
            </Grid>
        );
    };

    const dedicatedWeb3SolutionsGrid = (
        <>
            <Grid item container direction="column" spacing={3}>
                <Grid item container justifyContent="center">
                    {web3Offering(
                        lt('digitalAssetPlatform'),
                        'digital-asset-platform'
                    )}
                    {web3Offering(lt('nftPlatform'), 'nft-platform')}
                    {web3Offering(lt('consortium'), 'consortium-as-a-service')}
                    {web3Offering(
                        lt('cbdcPlatform'),
                        'custom-dedicated-sidechains'
                    )}
                </Grid>
                <Grid item>{lt('dedicatedWeb3SolutionsDescription')}</Grid>
            </Grid>
        </>
    );

    const disableFfLinks = !(isFireflyInitialized.initialized || (serviceStatus?.firefly?.node.registered && serviceStatus?.firefly?.org?.registered));
    
    return (
        <>
            <MessageSnackbar {...{messageType}} {...{message}} {...{setMessage}} />
            { (openingWebUI || openingWebAPI || openingSandboxUI) && 
                <CookieAppCred setErrorMessage={setMessage}
                    serviceId={service._id}
                    setCookieAppCred={setAppCred} 
                    membershipId={service.membership_id} />
            }
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt("fireflyOverview")}
                    </Typography>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item xs={8}>
                        <CopyableSettings
                            header={lt("ffNode")}
                            {...{ copyableList }}
                        />
                    </Grid>
                    <Grid item container xs={4}>
                        <WebUiCard header={lt('webUI')} disabled={disableFfLinks} dataTestId='fireflyExplorer'
                            imageFiles={'FireflyExplorer.png'} 
                            description={lt('webUiDescription')}
                            onClick={onWebUIClick} />
                    </Grid>
                    {node && 
                        <Grid key={node._id} item container md={4} sm={6} xs={12}>
                            <NodeCard {...{node}} {...{environmentZones}} showNodeTypeLabel/>
                        </Grid>
                    }
                    {ipfsService && 
                        <Grid key={ipfsService._id} item container md={4} sm={6} xs={12}>
                            <FireflyServiceCard service={ipfsService} actionLabel={lt('viewIPFS')} showNodeTypeLabel/>
                        </Grid>
                    }                    
                    <Grid item container xs={4}>
                        <DisplayGridWrapper
                            padDisplayGrid
                            header={lt('dedicatedWeb3Solutions')}
                            linkButton={requestMediumLargeNode}
                            displayGrid={dedicatedWeb3SolutionsGrid}
                        />
                    </Grid>

                    {service.urls?.sandboxui && 
                    <Grid item container xs={4}>
                        <WebUiCard header={lt('sandboxUI')} disabled={disableFfLinks} 
                            imageFiles={'FireflySandbox.png'} 
                            description={lt('sandboxUIDescription')}
                            onClick={onSandboxUIClick} />
                    </Grid>
                    }

                    <Grid item container xs={4}>
                        <WebUiCard header={lt('webAPI')} disabled={disableFfLinks} dataTestId='fireflySwagger'
                            imageFiles={'FireflySwagger.png'} 
                            description={lt('webAPIDescription')}
                            onClick={onWebAPIClick} />
                    </Grid>

                    <Grid container item direction="column" xs={4} spacing={3}>
                        <Grid item>
                            <DisplayCard
                                header={lt("documentation")}
                                itemList={DocumentationList}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    fireflyOverview: string
    ffNode: string
    name: string
    id: string
    status: string
    owner: string
    consortium: string
    createdOn: string
    cbdcPlatform: string
    dedicatedWeb3Solutions: string
    dedicatedWeb3SolutionsDescription: string
    digitalAssetPlatform: string
    nftPlatform: string
    apiEndpoint: string
    webUI: string,
    webUIEndpont: string
    documentation: string
    information: string
    webUiDescription: string
    fireflyDocumentation: string
    viewIPFS: string
    blog: string
    webAPI: string
    webAPIDescription: string
    wsEndpoint: string
    requestAccess: string
    requestSubmitted: string
    swaggerUIEndpont: string
    sandboxUIEndpoint: string
    sandboxUI: string
    sandboxUIDescription: string
};

const enTranslations: translations = {
    name: 'Name',
    ffNode: 'FireFly Node',
    blog: 'Blog',
    information: 'Information',
    fireflyDocumentation: 'FireFly Documentation',
    id: 'ID',
    status: 'Status',
    owner: 'Owner',
    consortium: 'Consortium',
    createdOn: 'Created On',
    cbdcPlatform: 'CBDC Platform',
    dedicatedWeb3Solutions: 'Kaleido Dedicated Web3 Solutions',
    dedicatedWeb3SolutionsDescription: 'If you are looking for a production-ready FireFly solution that includes advanced features and mass-scale NFTs, contact us about the Kaleido Asset Platform.',
    digitalAssetPlatform: 'Digital Asset Platform',
    nftPlatform: 'NFT Platform',
    apiEndpoint: 'API Endpoint',
    fireflyOverview: 'FireFly Private Network Sandbox',
    webUI: 'FireFly Explorer',
    webUIEndpont: 'Web UI Endpoint',
    documentation: 'Documentation',
    viewIPFS: 'View IPFS',
    webUiDescription: 'View messages, data and blockchain transactions between nodes and members of your network.',
    webAPI: 'FireFly Swagger API',
    webAPIDescription: 'View the API documentation for this FireFly node and try sending API requests using Swagger.',
    wsEndpoint: 'Websocket Endpoint',
    requestAccess: 'Request Access',
    requestSubmitted: 'Request submitted. Someone will be in touch with you soon.',
    swaggerUIEndpont: 'Swagger API Endpoint',
    sandboxUIEndpoint: 'Sandbox UI Endpoint',
    sandboxUI: 'FireFly Sandbox',
    sandboxUIDescription: 'Open the Sandbox to explore and exercise FireFly features such as Messaging, Tokens and Contracts.'
};

const useStyles = makeStyles(theme => ({
    solutionsText: {
        marginTop: theme.spacing(1)
    }
}))