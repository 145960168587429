import { gql, SubscriptionHookOptions } from '@apollo/client';
import { ConsortiaData, ConsortiaQuery } from './consortia'
import { ConsortiumResourcesVars } from '../interfaces'

// INTERFACES

export type MembershipSignatureTypes = 'self_signed' | 'externally_signed' | 'none';
export type OnChainRegistryStatesTypes = 'established' | 'not_established' | 'undetermined';

// this interface can be extended for any resource that is owned by a membership
export interface MembershipOwnedResource {
    membership_id: string
    membership: {
        isMine: boolean
        name: string
    }
}
export interface OptionalMembershipOwnedResource {
    membership_id?: string
    membership?: {
        isMine: boolean
        name: string
    }
}

// this should be an autogenerated interface from the Membership model in graphql
export interface Membership {
    _id: string
    consortia_id: string
    org_id: string
    org_name: string
    state: string
    verification_type: string
    verification_proof: string
    verification_selfsigned: boolean
    created_at: string
    deleted_at: string
    minimum_nodes: Number
    maximum_nodes: Number
    is_mine: boolean
    permissions: MembershipPermissions
    owner?: {
        name: string
        email: string
    }
}

export interface MembershipPermissions {
    manage_envs: boolean
    invite_orgs: boolean
    create_signers: boolean
    multiple_members: boolean
    manage_contracts: boolean
}

export interface CertificateProofInterface {
    _id: string
    name: string
    nonce: string
    state: string
    payload: string
    type: string
    org_id: string
}

export interface CertificateProofData {
    createProof: CertificateProofInterface
}
export interface MembershipData {
    membership: Membership
}

export interface CreateMembershipData {
    createMembership: Membership
}

export interface MembershipIdProof {
    payload: string
}

export interface MembershipJSONIdProof {
    subject: string
    issuer: string
    serial: string
}

// omit is_mine to discourage its use since its not valid at the UserMembershipsQuery level (which is performed in AppWrapper)
export type UserMemberships = Omit<Membership, 'is_mine'>[]

export interface UserMembershipsData {
    memberships: UserMemberships
}

export interface OpsMembershipsInput {
    query: string;
}

export interface OpsMembershipsData {
    opsMemberships: Membership[]
}

export interface UserMembershipData {
    membership: Membership
}

export interface ConsortiumMembershipsData {
    consortiumMemberships: Membership[]
}

export interface ConsortiumMembershipData {
    consortiumMembership: Membership
}

export interface MembershipIdProofData {
    membershipIdProof: MembershipIdProof
}

export interface MembershipJSONIdProofData {
    membershipJSONIdProof: MembershipJSONIdProof
}

export interface CreateMembershipVars {
    consortia_id: string
    membership: {
        org_id: string
        org_name: string
    }
}

export interface DeleteMembershipVars {
    consortia_id: string
    id: string
}

export interface AddExternalCertificateVars {
    org_id: string
    id: string
    certificateProof: {
        payload: string
        type: string
    }
}

export interface CreateProofVars {
    org_id: string
    certificateProof: {
        name: string
    }
}

export interface AddIdentityVars {
    consortia_id: string
    id: string
    identity: {
        test_certificate?: boolean
        proof_id?: string
    }
}

// FRAGMENTS

export const MembershipFields = ` 
    fragment MembershipFields on Membership {
        _id
        consortia_id
        org_id
        org_name
        state
        verification_type
        verification_proof
        verification_selfsigned
        created_at
        deleted_at
        minimum_nodes
        maximum_nodes
        is_mine
        permissions {
            manage_envs
            invite_orgs
            create_signers
            multiple_members
            manage_contracts
        }
        owner {
            name
            email
        }
    }
`


export const CertficateProofFields = ` 
    fragment CertficateProofFields on CertificateProof {
        _id
        name
        nonce
        state
        payload
        type
        org_id
}
`

export const MembershipIdProofFields = `
    fragment MembershipIdProofFields on MembershipIdProof {
        payload
    }
`

export const MembershipJSONIdProofFields = `
    fragment MembershipJSONIdProofFields on MembershipJSONIdProof {
        issuer
        serial
        subject
    }
`

// QUERIES & SUBSCRIPTIONS

export const MembershipQuery = gql`
    ${MembershipFields}
    query getMembership($consortia_id: String!, $id: String!) {
        membership(consortia_id: $consortia_id, id: $id) {
            ...MembershipFields
        }
    }`;

export const UserMembershipsQuery = gql`
    ${MembershipFields}
    query getMemberships {
        memberships {
            ...MembershipFields
        }
    }`;
   
export const OpsMembershipsQuery = gql`
    ${MembershipFields}
    query getOpsMemberships($query: String!) {
        opsMemberships(query: $query) {
            ...MembershipFields
        }
    }`;

export const MembershipIdProofQuery = gql`
    ${MembershipIdProofFields}
    query getMembershipIdProof($consortia_id: String!, $id: String!) {
        membershipIdProof(consortia_id: $consortia_id, id: $id) {
            ...MembershipIdProofFields
        }
}`;

export const MembershipJSONIdProofQuery = gql`
    ${MembershipJSONIdProofFields}
    query getMembershipJSONIdProof($consortia_id: String!, $id: String!) {
        membershipJSONIdProof(consortia_id: $consortia_id, id: $id) {
            ...MembershipJSONIdProofFields
        }
}`;

export const CreateMembershipMutation = gql`
    ${MembershipFields}
    mutation createMembership($consortia_id: String!, $membership: CreateMembershipInput) {
        createMembership(consortia_id: $consortia_id, membership: $membership) {
            ...MembershipFields
        }
}`;

export const DeleteMembershipMutation = gql`
    mutation deleteMembership($consortia_id: String!, $id: String!) {
        deleteMembership(consortia_id: $consortia_id, id: $id)
}`;

export const AddIdentityMutation = gql`
    ${MembershipFields}
    mutation addIdentity($consortia_id: String!, $id: String!, $identity: IdentityInput!) {
        addIdentity(consortia_id: $consortia_id, id: $id, identity: $identity) {
            ...MembershipFields
        }
}`;

export const AddExternalCertificateMutation = gql`
    ${CertficateProofFields}
    mutation addExternalCertificate($org_id: String!, $id: String!, $certificateProof: CertificateProofInput!) {
        addExternalCertificate(org_id: $org_id, id: $id, certificateProof: $certificateProof) {
            ...CertficateProofFields
        }
}`;

export const CreateProofMutation = gql`
    ${CertficateProofFields}
    mutation createProof($org_id: String!, $certificateProof: CertificateProofInput!) {
        createProof(org_id: $org_id, certificateProof: $certificateProof) {
            ...CertficateProofFields
        }
}`;

export const UserMembershipsSubscription = gql`
    ${MembershipFields}
    subscription onMembershipsChanged {
        membership {
            ...MembershipFields
        }
    }`;

export const ConsortiumMembershipsQuery = gql`
    ${MembershipFields}
    query getConsortiumMemberships($consortia_id: String!) {
        consortiumMemberships(consortia_id: $consortia_id) {
            ...MembershipFields
        }
    }`;

export const ConsortiumMembershipsSubscription = gql`
    ${MembershipFields}
    subscription onConsortiumMembershipsChanged($consortia_id: String!) {
        consortiumMembership(consortia_id: $consortia_id) {
            ...MembershipFields
        }
    }`;

// SUBSCRIPTION OPTIONS

export function MakeUserMembershipsSubscriptionOptions(
    overrideOptions?: SubscriptionHookOptions<UserMembershipData>): SubscriptionHookOptions<UserMembershipData> {
    return { ...{
        onSubscriptionData: ({client, subscriptionData}) => {
            // Get the membership object from the event
            const { 
                data: { 
                    membership
                } = { membership: null }
            } = subscriptionData

            if (!membership) return

            // Get current list of memberships from the cache
            // assert that memberships must exist since this function throws if it doesnt
            let { memberships: cachedMemberships } = client.readQuery<UserMembershipsData>({ 
                query: UserMembershipsQuery, 
            })!
            
            // get the index of the cached membership
            const cachedMembershipIndex = cachedMemberships.findIndex(c => c._id === membership._id)

            // if it exists in the cache, update our query with the subscription data, and remove it from the cache if its been deleted
            if (cachedMembershipIndex >= 0) {
                const updatedMemberships = [ ...cachedMemberships ]
                updatedMemberships.splice(cachedMembershipIndex, 1, membership)

                client.writeQuery<UserMembershipsData>({ 
                    query: UserMembershipsQuery, 
                    data: {
                        memberships: updatedMemberships.filter(c => c.state !== 'deleted')
                    },
                })

                // memberships are deleted in the context of a consortium, so let the consortium memberships subscription handle cache eviction
                return 
            }

            // in case of duplicate events, never add a deleted membership into the cache
            if (membership.state === 'deleted') return

            // if it doesnt exist in the store, (it's been added), so we need to add it in manually
            client.writeQuery<UserMembershipsData>({ 
                query: UserMembershipsQuery, 
                data: Object.assign({}, cachedMemberships, {
                    memberships: [membership, ...cachedMemberships]
                }),
            })

            // after adding it, check if we already have that membership's consortium in our cache
            // if we dont, we need to requery the consortium list to get write the new consortium we've been invited to into our cache
            const { consortia: existingConsortia } = client.readQuery<ConsortiaData>({query: ConsortiaQuery})!
            let consortium = existingConsortia.find(c => c._id === membership.consortia_id)
            if (!consortium) {
                client.query<ConsortiaData>({
                    query: ConsortiaQuery,
                    fetchPolicy: "network-only"
                }).then(d => {
                    consortium = d?.data?.consortia.find(c => c._id === membership.consortia_id)
                    if (consortium) {
                        client.writeQuery<ConsortiaData>({ 
                            query: ConsortiaQuery, 
                            data: Object.assign({}, existingConsortia, {
                                consortia: [consortium, ...existingConsortia]
                            })
                        })
                    }
                })
            }
        }
    }, ...overrideOptions }
}

export function MakeConsortiumMembershipsSubscriptionOptions(
    variables: ConsortiumResourcesVars, 
    overrideOptions?: SubscriptionHookOptions<ConsortiumMembershipData>): SubscriptionHookOptions<ConsortiumMembershipData> {
    return { ...{
        variables,
        onSubscriptionData: ({client, subscriptionData}) => {
            // Get the membership object from the event
            const { 
                data: { 
                    consortiumMembership
                } = { consortiumMembership: null }
            } = subscriptionData

            if (!consortiumMembership) return

            // Get current list of memberships from the cache
            // assert that memberships must exist since this function throws if it doesnt
            const { consortiumMemberships: cachedMemberships } = client.readQuery<ConsortiumMembershipsData>({ 
                query: ConsortiumMembershipsQuery, 
                variables
            })!

            // get the index of the cached membership
            const cachedMembershipIndex = cachedMemberships.findIndex(c => c._id === consortiumMembership._id)

            // if it exists in the cache, update our query with the subscription data, and remove it from the cache if its been deleted
            if (cachedMembershipIndex >= 0) {
                const updatedMemberships = [ ...cachedMemberships ]
                updatedMemberships.splice(cachedMembershipIndex, 1, consortiumMembership)

                client.writeQuery<ConsortiumMembershipsData>({ 
                    query: ConsortiumMembershipsQuery, 
                    data: {
                        consortiumMemberships: updatedMemberships.filter(c => c.state !== 'deleted')
                    },
                    variables
                })

                if (consortiumMembership.state === 'deleted') {
                    client.cache.evict({id: `Membership:${consortiumMembership._id}`, broadcast: false})
                }
                return 
            }

            // in case of duplicate events, never add a deleted membership into the cache
            if (consortiumMembership.state === 'deleted') return

            // if it doesnt exist in the store, (it's been added), so we need to add it in manually
            client.writeQuery<ConsortiumMembershipsData>({ 
                query: ConsortiumMembershipsQuery, 
                data: Object.assign({}, cachedMemberships, {
                    consortiumMemberships: [consortiumMembership, ...cachedMemberships]
                }),
                variables
            })
        }
    }, ...overrideOptions }
}