import Highcharts from 'highcharts';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseChartOptions } from '../../../utils/ChartOptions';
import { DocumentStoreMetrics } from '../../../models';
import HighchartsMore from "highcharts/highcharts-more.js"
import SolidGauge from "highcharts/modules/solid-gauge.js";
import { BrandColors, AlertDarkColors } from '../../../utils/Colors';
import { PieChart } from '../../../components/Charts/PieChart';

interface Props {
    documentStoreMetrics: DocumentStoreMetrics
};

export const OutboundTransfers = ({ documentStoreMetrics }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DocumentStoreOutboundTransfers', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`DocumentStoreOutboundTransfers:${key}`, interpolate), [t])

    
    Highcharts.setOptions(BaseChartOptions);
    HighchartsMore(Highcharts);
    SolidGauge(Highcharts);

    const sentSuccess = documentStoreMetrics.metrics.reduce((acc, data) => acc + data.sent_status_success, 0);
    const sentSent = documentStoreMetrics.metrics.reduce((acc, data) => acc + data.sent_status_sent, 0);
    const sentFailed = documentStoreMetrics.metrics.reduce((acc, data) => acc + data.sent_status_failed, 0);

    const data = [
        { name: lt('successful'), y: sentSuccess, color: BrandColors.blurple },
        { name: lt('sent'), y: sentSent, color: BrandColors.yellow },
        { name: lt('failed'), y: sentFailed, color: AlertDarkColors.red },
    ];

    function labelFormatter(this: Highcharts.Point | Highcharts.Series) {
        return lt('pieChartItemLabel', { item: lt(this.name as keyof translations), value: getValue(this.name) });
    }

    const getValue = (key: string) => {
        switch(key) {
            case lt('successful'): return sentSuccess;
            case lt('sent'): return sentSent;
            case lt('failed'): return sentFailed;
            default: return 0;
        }
    };

    return <PieChart
        title={lt('sentStatus')}
        labelFormatter={labelFormatter}
        data={data}
        customTotalSubtitle={lt('transfersSent')}
        height={'275px'}
        />
};

interface translations {
    limit: string
    maxLabel: string
    pieChartItemLabel: string,
    sentStatus: string
    transfersSent: string
    successful: string
    sent: string
    failed: string
}
const enTranslations: translations = {
    limit: 'Limit %',
    maxLabel: '({{size}})',
    pieChartItemLabel: '{{item}}: {{value}}',
    sentStatus: 'Monthly Outbound Transfers',
    transfersSent: 'Sent',
    successful: 'Successful',
    sent: 'Sent',
    failed: 'Failed'
}