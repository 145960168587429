import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step4HelpEth = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep4HelpEth', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep4HelpEth:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Environment-MinReqs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            finalInfo={lt('helpMonitorNode')}
            docLink="https://help.kaleido.io/hc/en-us/articles/11246011431821-How-many-nodes-should-I-use-"
        />
    )
};


interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    helpMonitorNode: string,
}
const enTranslations: translations = {
    helpHeader: 'Minimum Recommended Configuration',
    helpDescription: 'Kaleido provides four node clients for the customization of your private blockchain: Geth, Quorum, Hyperledger Besu and Polygon Edge. For reliable operation of your blockchain, Kaleido strongly recommends:',
    helpDesc1: 'IBFT - 3 user nodes + monitor node',
    helpDesc2: 'Raft - 2 user nodes + monitor node',
    helpDesc3: 'POA - 2 user nodes + monitor node',
    helpMonitorNode: '* The monitor node is free and automatically a part of all environments.',
}