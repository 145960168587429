import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step3Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeContractsCreateStep3Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeContractsCreateStep3Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Gateway API Created!',
    helpDescription: 'This Gateway API has now been installed on every single node in the environment. You can use the Gateway API on any of your nodes to deploy new instances of this contract, as well as send transactions to existing instances. Highlights:',
    helpDesc1: 'What is a Gateway API? - A dynamic swagger console and downloadable specification for RESTful interaction with smart contract methods.',
    helpDesc2: 'Deploy contracts - Use the Gateway API to deploy your smart contract programmatically or directly through the console.',
    helpDesc3: 'Send transactions - Leverage the API to interact with existing on-chain smart contracts by passing the contract address as a parameter in the call.',
}