import { useMutation } from '@apollo/client';
import { TextField } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { Consortium, UpdateOrganizationMutation, UpdateOrganizationVars } from '../../models';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SetDelegate = ({ open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SetDelegate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SetDelegate:${key}`, interpolate)

    const { org_id } = useParams<any>();
    
    const [message, setMessage] = useState('');
    const [newDelegate, setNewDelegate] = useState('');

    const [updateOrganization, { loading: updateOrganizationLoading }] = useMutation<Consortium, UpdateOrganizationVars>(UpdateOrganizationMutation)

    const updater = async () => {
        try {
            await updateOrganization({
                variables: {
                    id: org_id,
                    organization: {
                        delegate: newDelegate,
                    }
                }
            });
            setOpen(false);
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage)
        }
    };

    const controlsWrapper = <>
        <TextField
            value={newDelegate} onChange={event => setNewDelegate(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('delegate')}
            fullWidth
            variant="outlined"
        />
    </>;

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog dataTestId="updateName"
            {...{open}} 
            {...{setOpen}} 
            header={lt('delegateTitle')} 
            description={lt('delegateDescription')} 
            {...{controlsWrapper}} 
            saveDisabled={!newDelegate || updateOrganizationLoading}
            onSave={updater}/>
    </>;
};

interface translations {
    delegate: string,
    delegateTitle: string,
    delegateDescription: string,
}
const enTranslations: translations = {
    delegate: 'Primary Email Contact',
    delegateTitle: 'Set Primary Email Contact',
    delegateDescription: 'Main contact email for this organization - does not need to be a user',
}