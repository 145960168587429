import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { SideNavProps, ConsortiumResourceVars } from '../../../interfaces';
import { SubNavs } from './SubNavs';
import ShieldKeyOutlineIcon from 'mdi-react/ShieldKeyOutlineIcon';
import { NavItem } from '../../NavComponents/NavItem'
import { useQuery } from '@apollo/client';
import { EnvironmentData, EnvironmentQuery } from '../../../models';

export const KEY_MANAGEMENT_BASE_PATH = 'keymanagement';
export const KEY_MANAGEMENT_NODE_PATH = 'node';
export const KEY_MANAGEMENT_MANAGED_PATH = 'managed';
export const KEY_MANAGEMENT_CLOUD_PATH = 'cloud';

export const KeyManagement = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'KeyManagement', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`KeyManagement:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const historyPusher = useCallback((path?: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_BASE_PATH}${path ? `/${path}` : ''}`), 
        [history, org_id, consortium_id, environment_id])

    const DASHBOARD = lt('dashboard');
    const NODE_WALLET = lt('nodeWallet');
    const MANAGED_WLALET = lt('managedWallet');
    const CLOUD_HSM = lt('cloudHsm');

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;
    
    const addressBookSubItems = useCallback(() => {
        return [{
            name: DASHBOARD,
            action: () => historyPusher()
        },{
            name: NODE_WALLET,
            action: () => historyPusher(KEY_MANAGEMENT_NODE_PATH)
        },{
            name: MANAGED_WLALET,
            action: () => historyPusher(KEY_MANAGEMENT_MANAGED_PATH)
        },{
            name: CLOUD_HSM,
            action: () => historyPusher(KEY_MANAGEMENT_CLOUD_PATH)
        }]
    }, [historyPusher, DASHBOARD, NODE_WALLET, MANAGED_WLALET, CLOUD_HSM])

    useEffect(() => {
        
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.endsWith(`${environment_id}/${KEY_MANAGEMENT_BASE_PATH}`)) {
            setSelectedItem(DASHBOARD);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_NODE_PATH}`)) {
            setSelectedItem(NODE_WALLET);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}`)) {
            setSelectedItem(MANAGED_WLALET);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}`)) {
            setSelectedItem(CLOUD_HSM);
            setActiveAndSelected()
        }else {
            setActiveAndSelected(false)
            setSelectedItem('')
        }
    }, [setSelected, environment_id, pathname, DASHBOARD, MANAGED_WLALET, CLOUD_HSM, NODE_WALLET]);

    return (
        <>
            <NavItem comingSoon={isCorda} icon={<ShieldKeyOutlineIcon />}
                header={lt('name')} 
                action={() => {
                    if (isCorda) historyPusher()
                    else if (!active) setSelected(!selected)
                }} 
            />
            {!isCorda && <SubNavs list={addressBookSubItems()} selected={active || selected} {...{selectedItem}} />}
        </>
    )
};

interface translations {
    name: string
    dashboard: string
    nodeWallet: string
    managedWallet: string
    cloudHsm: string
}
const enTranslations: translations = {
    name: 'Key Management',
    dashboard: 'Dashboard',
    nodeWallet: 'Node Wallets',
    managedWallet: 'Managed Wallets',
    cloudHsm: 'Cloud HSM Wallets'
}


