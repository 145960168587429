import { gql } from '@apollo/client';
import { PlanName } from '.';

// INTERFACES

export interface PlanUpgradeData {
    upgradePlan: boolean
}

export interface PlanUpgradeVars {
    org_id: string
    targetPlan: TargetPlanInput
}

export interface TargetPlanInput {
    plan: PlanName
    support_level: Number
}

// QUERIES & SUBSCRIPTIONS

export const UpgradePlanMutation = gql`
    mutation upgradePlan($org_id: String!, $targetPlan: TargetPlanInput!) {
        upgradePlan(org_id: $org_id, targetPlan: $targetPlan)
    }`;