import React, { useState } from "react";
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from "../../components/DialogWrappers";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
    DeleteMembershipVars,
    DeleteMembershipMutation,
    Membership,
    ConsortiumMembershipsQuery,
} from "../../models";
import {
    NETWORK_GOVERNANCE_PATH,
    NETWORK_GOVERNANCE_MEMBERSHIPS_PATH,
} from "../../components/MainNav/SideNavs/Governance";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteMembership = ({ open, setOpen }: Props) => {
    const { org_id, consortium_id, membership_id } = useParams<any>();
    const [message, setMessage] = useState("");

    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle("en", "GovernanceDeleteMembership", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`GovernanceDeleteMembership:${key}`, interpolate);

    const [
        deleteMembership,
        { loading: deleteMembershipLoading },
    ] = useMutation<Membership, DeleteMembershipVars>(DeleteMembershipMutation);

    const updater = () => {
        return deleteMembership({
            variables: { consortia_id: consortium_id!, id: membership_id! },
            refetchQueries: [{
                query: ConsortiumMembershipsQuery,
                variables: {
                    consortia_id: consortium_id
                }
            }]
        }).then(() => {
            history.push(
                `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}`
            );
        }).catch((err) => {
            ErrorSnackbarCatcher(err, setMessage);
            return;
        });
    };

    return (
        <>
            <MessageSnackbar {...{ message }} {...{ setMessage }} />
            <FormDialog
                {...{ open }}
                {...{ setOpen }}
                header={lt("deleteMembership")}
                saveDisabled={deleteMembershipLoading}
                description={lt("message")}
                onSave={updater}
                saveText={lt("delete")}
            />
        </>
    );
};

interface translations {
    deleteMembership: string;
    delete: string;
    message: string;
}

const enTranslations: translations = {
    deleteMembership: "Delete Membership",
    delete: "Delete",
    message: "Are you sure you want to delete the Membership?",
};
