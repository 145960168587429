import SetSplitIcon from 'mdi-react/SetSplitIcon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayCard } from '../../../components/DisplayWrappers';
import { DocumentStoreMetrics, DOCUMENTSTORE_LIMITS, RuntimeSize, Service } from '../../../models';
import { ChunksSent } from '../Common/ChunksSent';
import FileDocumentOutlineIcon from "mdi-react/FileDocumentOutlineIcon";

interface Props {
    documentStoreMetrics: DocumentStoreMetrics
    service: Service
};

export const MonthlyLimits = ({ documentStoreMetrics, service }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DocumentStoreMonthlyLimits', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`DocumentStoreMonthlyLimits:${key}`, interpolate), [t])

    const chunksSent = documentStoreMetrics.metrics.reduce((acc, data) => acc + data.chunks_sent, 0);

    const getChunksLimit = (size: RuntimeSize) => {
        if (size === 'large') return lt('unlimited')
        if (size === 'medium') return DOCUMENTSTORE_LIMITS.chunks.medium
        return DOCUMENTSTORE_LIMITS.chunks.small
    }

    const getMaxFileSize = (size: RuntimeSize) => {
        if (size === 'large') return DOCUMENTSTORE_LIMITS.fileSize.large
        if (size === 'medium') return DOCUMENTSTORE_LIMITS.fileSize.medium
        return DOCUMENTSTORE_LIMITS.fileSize.small
    }

    const itemList = [
        {
            icon: <SetSplitIcon />,
            title: lt("chunks"),
            value: lt('limits', { used: chunksSent, max: getChunksLimit(service.size) }),
            actionIcon: service.size !== 'large' ? <ChunksSent {...{chunksSent}} upperLimit={getChunksLimit(service.size)} /> : undefined
        },
        {
            icon: <FileDocumentOutlineIcon />,
            title: lt("maxFileSize"),
            value: getMaxFileSize(service.size)
        }
    ]

    return (
       <DisplayCard header={lt('header')} {...{itemList}} divider />
    )
};

interface translations {
    chunks: string
    header: string
    limits: string
    unlimited: string
    maxFileSize: string
}
const enTranslations: translations = {
    chunks: 'Chunks',
    header: 'Monthly Limits',
    limits: '{{used}} / {{max}}',
    unlimited: 'Unlimited',
    maxFileSize: 'Max File Size'
}