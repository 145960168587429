import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers'

export const LogStreamHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigLogStreamHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigLogStreamHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/using-kaleido/cloud-integrations/log-streaming/"
            bullet1={lt('bulletHelp1')}
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    bulletHelp1: string
}
const enTranslations: translations = {
    helpHeader: 'Log Stream',
    helpDescription: 'As an alternative to this configuration-intensive approach, Kaleido offers the option to integrate nodes with AWS’ monitoring and management service, Cloudwatch, and directly stream realtime logs.',
    bulletHelp1: 'A fully-encompassed monitoring service provides the ability to visualize Kaleido logs alongside existing resources and processes, and surfaces an aggregated trove of data that can lead to greater insights and application optimization.'
}