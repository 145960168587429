import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { StaticConfigQuery, StaticConfigData } from '../../models';
import { useQuery } from '@apollo/client';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConsortiumCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConsortiumCreate:${key}`, interpolate)

    type locationState = { name: string, description: string, homeRegion: string }
    const history = useHistory<locationState>()
    const { org_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}`
    
    const { 
        location: { 
            state: {
                name: consortiumName,
                description: consortiumDescription,
                homeRegion: consortiumHomeRegion,
            } = { name: '', description: '', homeRegion: '' }
        } 
    } = history;

    const [name, setName] = useState((step && step >= "2") ? consortiumName as string : '');
    const [description, setDescription] = useState((step && step >= "2") ? consortiumDescription as string : '');
    const [homeRegion, setHomeRegion] = useState((step && step >= "3") ? consortiumHomeRegion as string : '');
    const invalidStep = step !== "1" && step !== "2" && step !== "3"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    const redirect = `/orgs/${org_id}/consortia/create/1`

    const {
        data: {
            staticConfig: {
                zoneRegions: regions
            }
        } = { staticConfig: { zoneRegions: [] } }
    } = useQuery<StaticConfigData>(StaticConfigQuery, { 
        fetchPolicy: 'cache-first'
    });

    if (invalidStep || (createStep >= 1 && (!name))) {
        return <Redirect to={redirect} />
    }

    if (invalidStep || (createStep >= 2 && (!homeRegion))) {
        return <Redirect to={redirect} />
    }

    const stepComponents = [
        { 
            step: lt('networkDetails'), 
            component: <Step1 {...{cancelPath}} {...{name}} {...{setName}} {...{description}} {...{setDescription}} /> 
        },
        { 
            step: lt('homeRegion'), 
            component: <Step2 {...{cancelPath}} {...{name}} {...{description}} {...{homeRegion}} {...{setHomeRegion}} {...{regions}} /> 
        },
        { 
            step: lt('deploymentRegions'), 
            component: <Step3 {...{cancelPath}} {...{name}} {...{description}} {...{homeRegion}} {...{regions}} /> 
        }
    ]

    return <FullScreenCreate {...{cancelPath}} toolbarHeader={lt('createConsortium')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createConsortium: string,
    networkDetails: string,
    homeRegion: string,
    deploymentRegions: string
}
const enTranslations: translations = {
    createConsortium: 'Create a Network',
    networkDetails: 'Network Details',
    homeRegion: 'Home Region',
    deploymentRegions: 'Deployment Regions'
}