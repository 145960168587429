import React from 'react';
import { 
    List,
    makeStyles,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { SessionData } from '../../interfaces';
import { SessionQuery } from '../../queries/Session';
import CardAccountDetailsIcon from 'mdi-react/CardAccountDetailsIcon';
import KeyOutlineIcon from 'mdi-react/KeyOutlineIcon';
import SecurityIcon from 'mdi-react/SecurityIcon';
import { NavItem } from '../NavComponents';

export const PROFILE_SETTINGS_PATH = 'profile'
export const PROFILE_SECURITY_PATH = 'security'
export const PROFILE_SETTINGS_ADMIN_API_KEYS_PATH = 'apikeys';

export const ProfileSettingsNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProfileSettingsNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProfileSettingsNav:${key}`, interpolate);
    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;
    const history = useHistory();
    const { pathname } = useLocation();
    const historyPusher = (path?: string) => history.push(`/${PROFILE_SETTINGS_PATH}` + (path ? `/${path}` : ''));

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem>
                    <ListItemText secondary={lt('profileSettings')} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>
                <ListItem>
                    <ListItemText secondary={session.email} secondaryTypographyProps={{variant: "body2"}} />
                </ListItem>
                <NavItem icon={<CardAccountDetailsIcon />}
                    header={lt('personalInfo')}
                    action={() => historyPusher()}
                    itemIsActive={pathname.endsWith(PROFILE_SETTINGS_PATH)}
                />
                <NavItem icon={<SecurityIcon />} disabled={session.realm === 'oidc'}
                    header={lt('security')}
                    action={() => historyPusher(PROFILE_SECURITY_PATH)}
                    itemIsActive={pathname.endsWith(PROFILE_SECURITY_PATH)}
                />
                <NavItem icon={<KeyOutlineIcon />}
                    header={lt('adminAPIKeys')}
                    action={() => historyPusher(PROFILE_SETTINGS_ADMIN_API_KEYS_PATH)}
                    itemIsActive={pathname.endsWith(PROFILE_SETTINGS_ADMIN_API_KEYS_PATH)}
                />
            </List>
        </div> 
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    backButton: {
        paddingLeft: theme.spacing(1)
    },
    backIcon: {
        minWidth: 0
    }
}));

interface translations {
    profileSettings: string
    personalInfo: string
    security: string
    adminAPIKeys: string
}

const enTranslations: translations = {
    profileSettings: 'Profile Settings',
    personalInfo: 'Personal Info',
    security: 'Security',
    adminAPIKeys: 'API Keys'
}