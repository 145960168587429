import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { B2B_BASE_PATH } from "../../../../components/MainNav/SideNavs/B2bCommunication";
import { RuntimeCreationFinalStep } from "../../../../components/RuntimeCreation/RuntimeCreationFinalStep";
import { RuntimeCreationSelectSizeContent } from "../../../../components/RuntimeCreation/RuntimeCreationSelectSizeContent";
import {
    ConsortiumZone, EnvironmentZone,
    RuntimeSize,
    ServiceDetails, ServicesEnum, DocumentStoreLimitTranslations, EnDocumentStoreLimitTranslations
} from "../../../../models";
import { StepHelp } from "./StepHelp";

interface Props {
    cancelPath: string;
    name: string;
    membershipId: string;
    consortiumZoneId: string;
    consortiumZones: ConsortiumZone[];
    environmentZones: EnvironmentZone[];
    config: string
}

export const Step3 = ({
    cancelPath,
    name,
    consortiumZones,
    environmentZones,
    membershipId,
    consortiumZoneId,
    config
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateDocExchangeStep3", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateDocExchangeStep3:${key}`, interpolate);

    const { consortium_id, environment_id, org_id } = useParams<any>();

    const [size, setSize] = useState<RuntimeSize>('small')

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    let details: ServiceDetails = {};
    if (config) {
        details = {
            storage_id: config
        }
    }

    const content = (
        <RuntimeCreationSelectSizeContent 
            {...{size}} 
            {...{setSize}}
            smallDescription={lt('documentstoreSmallDescription')}
            mediumDescription={lt('documentstoreMediumDescription')}
            largeDescription={lt('documentstoreLargeDescription')} />
    )

    return (
        <>
            <RuntimeCreationFinalStep 
                servicePath={`${basePath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}`}
                service={ServicesEnum.documentstore}
                {...{cancelPath}}
                {...{name}}
                {...{membershipId}}
                {...{consortiumZones}}
                {...{consortiumZoneId}}
                {...{environmentZones}}
                {...{content}}
                {...{details}}
                {...{size}}
                helpContent={<StepHelp />} />
        </>
    );
};

interface translations extends DocumentStoreLimitTranslations{
    header: string;
    headerDescription: string;
    sizePaywall: string
}
const enTranslations: translations = {
    ...EnDocumentStoreLimitTranslations,
    header: "Select a Size",
    headerDescription: "You can select a size for this runtime best suited for your specific needs.",
    sizePaywall: 'Additional sizes (medium & large) with expanded capabilities are only available on the Business and Enterprise plans.'
};
