import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
    
interface Props {
    contractsInFile: string[],
    contractToCompile: string,
    setContractToCompile: React.Dispatch<React.SetStateAction<string>>,
    disabled: boolean
};

export const UploadContractContractSelector = ({ contractsInFile, contractToCompile, setContractToCompile, disabled }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'UploadContractContractSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`UploadContractContractSelector:${key}`, interpolate)

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    return (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('selectContractToCompile')}
                </Typography>
            </Grid>
            <Grid item>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel ref={inputLabel} required>
                        {lt('selectContract')}
                    </InputLabel>
                    <Select {...{disabled}}
                        labelWidth={labelWidth}
                        value={contractToCompile}
                        onChange={e => setContractToCompile(e.target.value as string)}>
                        {contractsInFile.map(l => {
                            return (
                                <MenuItem key={l} selected={l === contractToCompile} value={l}>{l}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </>
    )
};

interface translations {
    selectContract: string,
    selectContractToCompile: string,

}
const enTranslations: translations = {
    selectContract: 'Select contract',
    selectContractToCompile: 'Select contract to compile'
}
