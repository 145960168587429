import React from 'react';
import { Typography, CircularProgress, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EnvironmentZoneQuery, EnvironmentZoneData, EnvironmentZoneVars, ServicesData, ServicesQuery } from '../../../../models';
import { useQuery } from '@apollo/client';
import { CopyableSettings } from '../../../../components/DisplaySettings';

interface Props {
    destinationName: string
}

export const ExtManagedDestInstructions: React.FC<Props> = ({ destinationName }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ExtManagedDestInstructions', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ExtManagedDestInstructions:${key}`, interpolate);
    const { consortium_id, environment_id, service_id } = useParams<any>();

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const service = services.find(service => service._id === service_id)!;

    const {
        loading,
        data: {
            environmentZone
        } = { environmentZone: undefined }
    } = useQuery<EnvironmentZoneData, EnvironmentZoneVars>(EnvironmentZoneQuery, {
        variables: {
            id: service!.zone_id,
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        skip: !service
    });

    if (loading) {
        return <CircularProgress />
    }

    const commonName = `app2app-${service_id}-${destinationName}`;
    const key = `kld://app2app/z/${environmentZone?.net_host.replace(/\..*$/, '')}/m/${service.membership_id}/e/${environment_id}/s/${service_id}/d/${destinationName}`;

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="body2">{lt('instructions')}</Typography>
            </Grid>
            <Grid item>
                <CopyableSettings noBorders copyableList={[
                    {
                        title: lt('certificateCommonName'),
                        displayValue: commonName
                    }, {
                        title: lt('membershipIdentification'),
                        displayValue: service.membership_id
                    }, {
                        title: lt('onChainRegistryKey'),
                        displayValue: key
                    }
                ]} />
            </Grid>
        </Grid>
    );
};

interface translations {
    instructions: string
    certificateCommonName: string
    membershipIdentification: string
    onChainRegistryKey: string
}
const enTranslations: translations = {
    instructions: 'Generate an X509 certificate with the common name shown below. Add the certificate PEM to the on-chain registry using the following membership identification and key:',
    certificateCommonName: 'Certificate common name',
    membershipIdentification: 'Membership identification',
    onChainRegistryKey: 'On-chain registry key'
}