import React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DisplayGridWrapper } from '../DisplayWrappers';
import { CopyableSettings } from '../DisplaySettings';
import { PasswordInput } from '../PasswordInput/PasswordInput';

interface Props {
    header: string
    description: string
    imageFiles: string
    disabled?: boolean
    credentials?: { username?: string, password?: string }
    onClick: () => void,
    dataTestId?: string,
    buttonText?: string
}


export const WebUiCard = ({header, description, imageFiles, onClick, disabled = false, credentials, dataTestId, buttonText}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'WebUiCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`WebUiCard:${key}`, interpolate);

    const classes = useStyles();

    const copyableList = [{ 
        title: lt('username'), 
        displayValue: credentials?.username || '',
    }, {
        title: lt('password'), 
        displayValue: <PasswordInput size='small' variant='outlined' value={credentials?.password || ''} />,
        copyableValue: credentials?.password || ''
    }]

    const displayGrid = (
            <>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container justify="center" >
                        <img className={classes.image} src={`${process.env.PUBLIC_URL}/img/WebUi/${imageFiles}`} alt=""></img>
                    </Grid>
                    <Grid item>
                        {description}
                    </Grid>
                    {credentials?.username && credentials.password ? <CopyableSettings {...{copyableList}} /> : null}
                </Grid>
                <Grid item className={classes.buttons}>
                    <Button data-test={`webUiCardButton_${dataTestId}`} {...{disabled}} variant="contained" color="primary" onClick={onClick}>
                        {buttonText || lt('openWebUI')}
                    </Button>
                </Grid>
            </>
    )
    return <DisplayGridWrapper hideDivider padDisplayGrid {...{header}}{...{displayGrid}} />
    
}

interface translations {
    openWebUI: string
    username: string
    password: string
}

const enTranslations: translations = {
    openWebUI: 'Open Web UI',
    username: 'Username',
    password: 'Password'
}

const useStyles = makeStyles(theme => ({
    buttons: {
        marginTop: theme.spacing(2)
    },
    image: {
        maxHeight: '150px'
    }
}))