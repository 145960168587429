import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import ArrowDownBoldCircleOutlineIcon from 'mdi-react/ArrowDownBoldCircleOutlineIcon';
import ArrowUpBoldCircleIcon from 'mdi-react/ArrowUpBoldCircleIcon';
import CogIcon from 'mdi-react/CogIcon';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import RestartIcon from 'mdi-react/RestartIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { EnvironmentResourceVars, NodeResourcesVars, LinkButtonProps } from '../../interfaces';
import { CorDapp, CorDappsData, CorDappsQuery, NodeData, NodeQuery, NodeStatusData, NodeStatusQuery, UpdateCorDappData, UpdateCorDappMutation, UpdateCorDappVars, CordaRestartData, RestartCordaMutation } from '../../models';
import { AlertBanner } from '../Banners/AlertBanner';
import { ErrorSnackbarCatcher, MessageSnackbar, FormDialog } from '../DialogWrappers';
import { DisplayTable, EmptyState } from '../DisplayWrappers';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { NODE_CORDAPPS_PATH } from '../NodeNav/NodeNav';
import { toFileSize } from '../../utils/StringUtils';

export const CorDapps: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CorDapps', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CorDapps:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();
    const envVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }
    const nodeVars = {
        ...envVars,
        id: node_id!
    }
    const nodeResourceVars = {
        ...envVars,
        node_id: node_id!
    }

    const [errorMessage, setErrorMessage] = useState('');
    const [confirmRestartOpen, setConfirmRestartOpen] = useState(false);

    const [updateCorDapp, { loading: promoting }] = useMutation<UpdateCorDappData, UpdateCorDappVars>(UpdateCorDappMutation)
    const [restartCorda] = useMutation<CordaRestartData, NodeResourcesVars>(RestartCordaMutation)

    const history = useHistory();
    const { pathname } = useLocation();

    const {
        loading: loadingCorDapps,
        refetch: refetchCorDapps,
        data: {
            cordapps
        } = { CorDapps: [] }
    } = useQuery<CorDappsData, NodeResourcesVars>(CorDappsQuery, { 
        variables: nodeResourceVars,
        fetchPolicy: 'cache-and-network'
    });

    const {
        data : {
            node
        } = {node: null}
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, {
        variables: {
            ...nodeVars,
        },
        fetchPolicy: 'cache-only'
    });

    const {
        refetch: refetchNodeStatus,
        loading: loadingNodeStatus,
        data : {
            nodeStatus
        } = {nodeStatus: null}
    } = useQuery<NodeStatusData, EnvironmentResourceVars>(NodeStatusQuery, {
        skip: node?.state !== 'started',
        variables: {
            ...nodeVars,
        },
        fetchPolicy: 'network-only'
    });

    const doCordaRestart = async () => {
        await restartCorda({
            variables: {
                ...nodeResourceVars
            }
        })
        handleRefresh();
    }

    const doPromote = async (id: string, newState: string) => {
        try {
            await updateCorDapp({
                variables: {
                    ...nodeResourceVars,
                    id,
                    cordapp: {
                        state: newState,
                    }
                }
            })
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
        handleRefresh();
    }

    const userColumnHeaders = [
        '',
        lt('id'),
        lt('name'),
        lt('description'),
        lt('created'),
        lt('size'),
        ''
    ]

    const buildCordappRecords = ((c: CorDapp, live: boolean) => {
        const record : DisplayTableRecord = {
            key: c.id,
            onClick: () => history.push(`${pathname}/${c.id}`),
            columns: [
                {isIcon: true, value: live ? <CogIcon /> : <CogOutlineIcon/>},
                {value: c.id},
                {value: c.consortiaContractName},
                {value: c.description},
                {value: new Date(c.created).toLocaleString()},
                {value: toFileSize(c.size)},
                {
                    value: (
                        <Grid container alignContent="flex-start">
                            <Grid item xs>
                                <Tooltip title={live ? lt('demote') : lt('promote')}>
                                    <IconButton onClick={event => { event.stopPropagation(); doPromote(c.id, live ? 'stage' : 'live') } } disabled={promoting} >
                                        {live ? <ArrowDownBoldCircleOutlineIcon /> : <ArrowUpBoldCircleIcon /> }
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    align: 'right',
                }
            ]
        };
        return record;
    })

    const handleRefresh = () => {
        refetchNodeStatus();
        refetchCorDapps();
    }

    const liveRecords = cordapps?.live.map(c => buildCordappRecords(c, true))
    const hasLive = liveRecords?.length ? true : false;
    const stagingRecords = cordapps?.staging.map(c => buildCordappRecords(c, false))
    const hasStaging = stagingRecords?.length ? true : false;

    const getHeader : () => {alertTitle: string, alertText: string, alertButton?: LinkButtonProps} = () => {
        if (!loadingNodeStatus && nodeStatus?.corda?.server_status !== 'active') {
            return {
                alertTitle: lt('restarting'),
                alertText: lt('restartingDescription'),
            }
        }
        else if (cordapps?.status === "need-restart") {
            return {
                alertTitle: lt('needRestart'),
                alertText: lt('needRestartDescription'),
                alertButton: {
                    text: lt('restartCorda'), 
                    onClick: () => setConfirmRestartOpen(true), 
                    startIcon: <RestartIcon/>,
                }
            }
        } else if (!loadingCorDapps && hasStaging && !hasLive) {
            return {
                alertTitle: lt('promotePrompt'),
                alertText: lt('promotePromptDescription'),
            }
        }
        return { alertTitle: '', alertText: '' };        
    };
    const {alertTitle, alertText, alertButton} = getHeader();

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <FormDialog header={lt('restart')}  description={lt('restartDescription')} saveText={lt('restart')} onSave={doCordaRestart} closeDialogAfterSave open={confirmRestartOpen} setOpen={setConfirmRestartOpen}/>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('nodeCorDapps')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color="primary" size={loadingCorDapps ? "small" : "medium"} disabled={loadingCorDapps}
                                onClick={() => handleRefresh()} >
                        { loadingCorDapps ? <CircularProgress color="inherit" /> : <RefreshIcon /> }
                    </IconButton>
                    </Grid>
                </Grid>
                {alertTitle &&
                    <Grid item>
                        <AlertBanner title={alertTitle} description={alertText} linkButton={alertButton} />
                    </Grid>
                }
                {hasLive &&
                    <Grid item container>
                        <DisplayTable header={lt('liveCorDapps')} columnHeaders={userColumnHeaders} records={liveRecords} />
                    </Grid>
                }
                {hasStaging &&
                    <Grid item container>
                        <DisplayTable header={lt('stagingCorDapps')} columnHeaders={userColumnHeaders} records={stagingRecords} />
                    </Grid>
                }
                {!loadingCorDapps && !hasLive && !hasStaging &&
                    <EmptyState imageFile='Empty-Contracts.svg' 
                        title={lt('newCorDapp')} 
                        description={lt('emptyDescription')} 
                        button={{ text: lt('newCorDapp'), onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_CORDAPPS_PATH}/create/1`) }}/>
                }
            </Grid>
        </>
    )
};

interface translations {
    nodeCorDapps: string,
    id: string,
    created: string,
    size: string,
    liveCorDapps: string,
    stagingCorDapps: string,
    demote: string,
    promote: string,
    newCorDapp: string,
    emptyDescription: string,
    info: string,
    promotePrompt: string,
    promotePromptDescription: string,
    needRestart: string,
    needRestartDescription: string,
    restartCorda: string,
    restarting: string,
    restartingDescription: string,
    restart: string,
    restartDescription: string,
    name: string
    description: string
}
const enTranslations: translations = {
    nodeCorDapps: 'CorDapp Management',
    id: 'ID',
    created: 'Created',
    size: 'Size',
    liveCorDapps: 'Live',
    stagingCorDapps: 'Staging',
    demote: 'Move back to staging',
    promote: 'Make live',
    newCorDapp: 'Add CorDapp',
    emptyDescription: 'No CorDapps have been promoted to this environment yet',
    info: 'Info',
    needRestart: 'Ready to restart',
    needRestartDescription: 'The Corda server runtime needs to be restarted to pick up the new set of live CorDapps',
    promotePrompt: 'Ready to go live',
    promotePromptDescription: 'You have CorDapps in the staging area of this node ready to be made live',
    restartCorda: 'Restart Corda Runtime',
    restarting: 'Corda runtime restarting',
    restartingDescription: 'Check the logs to see the progress of the restart',
    restart: 'Restart',
    restartDescription: 'The Corda server will shut down briefly and restart to pick up the current set of live CorDapps. You can check the restart progress in the logs.',
    name: 'Name',
    description: 'Description / Version'
}