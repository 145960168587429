import React from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import AlertOutlineIcon from 'mdi-react/AlertOutlineIcon';
import { AlertLightColors, AlertDarkColors } from '../../utils/Colors';
import { LinkButtonProps } from '../../interfaces';

interface Props {
    title?: string
    description: JSX.Element | string,
    linkButton?: LinkButtonProps,
    buttonVariant?: 'text' | 'outlined' | 'contained'
}

export const AlertBanner = ({ title, description, linkButton, buttonVariant='contained' }: Props) => {
    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.container}>
            <Grid item container spacing={3} alignItems="center" wrap="nowrap">
                <Grid item>
                    <AlertOutlineIcon color={AlertDarkColors.orange} />
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        {title && 
                        <>
                            <b>{title}</b>
                            <span> - </span>
                        </>
                        }
                        {description}
                    </Typography>
                </Grid>
                {linkButton &&
                <Grid item className={classes.alignRight}>
                    <Button disabled={linkButton.disabled} variant={buttonVariant} onClick={linkButton.onClick} color={buttonVariant === 'text' ? 'default' : 'primary'} startIcon={linkButton.startIcon}>
                        {linkButton.text}
                    </Button> 
                </Grid>
                }
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    alignRight: {
        marginLeft: 'auto',
        minWidth: '160px'
    },
    container: {
        backgroundColor: AlertLightColors.orange,
        border: 'solid',
        borderWidth: '2px',
        borderColor: AlertDarkColors.orange,
        padding: theme.spacing(1)
    }
}))