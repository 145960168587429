import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from "@material-ui/core";
import { APP2APP_RUNTIME_LIMITS, NODE_SIZE_DETAILS, RuntimeSize, ServicesEnum } from '../../models'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BaseChartOptions } from '../../utils/ChartOptions'
import { RuntimeChart } from './Runtimes'

interface Props {
    runtimeChart: RuntimeChart,
    nodeLength: number,
    service?: keyof typeof ServicesEnum
};

export const Runtime = ({ runtimeChart, nodeLength, service }: Props) => {

    Highcharts.setOptions(BaseChartOptions);

    type ChartType = 'cpu' | 'memory'

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthRuntime', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`EnvironmentHealthRuntime:${key}`, interpolate), [t])
    const app2AppOrDocStore = service === 'app2app' || service === 'documentstore'

    // SHARED OPTIONS
    const containerProps = { style: { maxWidth: "98%", height: "200px" } }

    const makeLabel = (l: string, isUnit?: boolean) => `<span style="font-size: ${isUnit? '0.775' : '0.875'}rem; font-weight: 300; line-height: 1.43; fill: rgba(0, 0, 0, 0.54)">${l}</span>`

    const getMaxValue = (size: RuntimeSize, type: ChartType) => {
        if (type === 'cpu') return app2AppOrDocStore ? APP2APP_RUNTIME_LIMITS[size].cores * 100 : NODE_SIZE_DETAILS[size].cores * 100
        if (type === 'memory') return app2AppOrDocStore ? APP2APP_RUNTIME_LIMITS[size].memory :  NODE_SIZE_DETAILS[size].memory
        return 0
    }

    const getRelativeValue = (value: number, maxValue: number, type: ChartType) => {
        if (type === 'cpu') return Math.round((value / maxValue) * 100)
        if (type === 'memory') return value
        return 0
    }

    const getMaxLabel = (size: RuntimeSize, type: ChartType) => {
        if (type === 'cpu') return app2AppOrDocStore ? `(${APP2APP_RUNTIME_LIMITS[size].cores} vCPU)` : `(${NODE_SIZE_DETAILS[size].cores} vCPU)`
        if (type === 'memory') return app2AppOrDocStore ? `(${APP2APP_RUNTIME_LIMITS[size].memory} MB)` : `(${NODE_SIZE_DETAILS[size].memory} MB)`
        return ''
    }

    const getValueColor = (value: number, maxValue: number) => {
        if ((value / maxValue) < 0.2) return '#25bf15'
        if ((value / maxValue) < 0.4) return '#78bf15'
        if ((value / maxValue) < 0.6) return '#ff9d00'
        if ((value / maxValue) < 0.8) return '#ff6a00'
        return 'red'
    }

    const makeChartOptions = (value: number, label: string, size: RuntimeSize, type: ChartType): Highcharts.Options => {
        const maxValue = getMaxValue(size, type) // the theoretical max value based on the size (ex: small node cant go above 50 cpu units)
        const relativeValue = getRelativeValue(value, maxValue, type) // the percent based value considering the node size's maxValue
        const limitValue = type === 'cpu' ? 100 : maxValue // the max limit for the chart (for cpu we plot percentages, so its 100%)
        const options: Highcharts.Options = {
            title: {
                text: `<span style="font-size: 1.5rem; font-weight: 400">${relativeValue}</span><br />${makeLabel(label)}<br />${makeLabel(getMaxLabel(size, type), true)}`,
                align: 'center',
                verticalAlign: 'middle',
                y: 38
            },

            series: [{
                type: 'pie',
                innerSize: '70%',
                startAngle: -120,
                endAngle: 120,
                data: [
                    { name: '', y: relativeValue, color: getValueColor(relativeValue, limitValue) },
                    { name: '', y: limitValue - relativeValue, color: 'rgba(0, 0, 0, 0.08)' }
                ],
                center: ['50%', '65%'],
                states: {
                    hover: { enabled: false },
                    inactive: { opacity: 1 }
                }
            }],
            tooltip: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        style: {
                            fontWeight: 'bold',
                            color: 'white'
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '100%']
                }
            }
        }
        const mergedOptions = Highcharts.merge(options);
        return mergedOptions
    }

    const cpuOptions = makeChartOptions(runtimeChart.cpu || 0, lt('cpu'), runtimeChart.size, 'cpu')
    const memoryOptions = makeChartOptions(runtimeChart.memory || 0, lt('memory'), runtimeChart.size, 'memory')

    return (
        <Grid item container direction="column" spacing={3}>
            <Grid item container direction="row" spacing={3}>
                <Grid item container direction="column" xs={6}>
                    <Grid item>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={cpuOptions}
                            containerProps={containerProps}
                        />
                    </Grid>
                    <Grid item container justify="center">
                        <Typography variant="h5">
                            {runtimeChart.disk}
                        </Typography>
                    </Grid>
                    <Grid item container justify="center">
                        <Typography variant="body2" color="textSecondary">
                            {lt('storage')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={6}>
                    <Grid item>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={memoryOptions}
                            containerProps={containerProps}
                        />
                    </Grid>
                    {runtimeChart.peerCounts &&
                    <>
                        <Grid item container justify="center">
                            <Typography variant="h5" color={runtimeChart.peerCounts.ok ? 'inherit' : 'error'}>
                                {runtimeChart.peerCounts.p2p_peers}/{nodeLength - 1}
                            </Typography>
                        </Grid>
                        <Grid item container justify="center">
                            <Typography variant="body2" color="textSecondary">
                                {lt('peerCount')}
                            </Typography>
                        </Grid>
                    </>
                    }
                </Grid>
            </Grid>

            {/* intentional empty item to apply a bottom spacing of 3 to the chart (and avoid inline css and bringing in styles class) */}
            <Grid item></Grid>
        </Grid>
    )
};

interface translations {
    cpu: string,
    memory: string,
    storage: string,
    peerCount: string
}
const enTranslations: translations = {
    cpu: 'CPU %',
    memory: 'Memory',
    storage: 'Storage (MB)',
    peerCount: 'Peer count'
}