import React, {useState, useEffect, useMemo, useRef} from 'react';
import { FormDialog } from '../../../components/DialogWrappers'
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { AddressbookQuery,AddressbookData, AddressbookVars, DestinationsData, DestinationsVars, DestinationsQuery, Addressbook, Destinations, TransferDocumentMutation, Destination, ConsortiumMembershipsData, ConsortiumMembershipsQuery, ServicesEnum } from '../../../models';
import { 
    Grid, 
    Typography, 
    makeStyles,
    Select,
    MenuItem,
    FormControl,
 } from '@material-ui/core';
import { ConsortiumResourcesVars } from '../../../interfaces';
import FileIcon from 'mdi-react/FileIcon';
import { DOCSTORE_DESTINATIONS_PATH, DOCSTORE_TRANSFERS_PATH } from '../../../components/ServicesNav/Services/DocumentStoreItems';
import { B2B_BASE_PATH } from '../../../components/MainNav/SideNavs/B2bCommunication';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    currentPath: string,
    documentName: string,
}

export const TransferFile: React.FC<Props> = ({
    open,
    setOpen,
    currentPath,
    documentName,
}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransferFile', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransferFile:${key}`, interpolate);

    const [selectedSenderDestination, setSelectedSenderDestination] = useState('');
    const [selectedReceiverDestination, setSelectedReceiverDestination] = useState('');
    const [membershipIdSelected, setMembershipIdSelected] = useState('');
    const [loading, setLoading] = useState(false);

    const { service_id, consortium_id, org_id, environment_id } = useParams<any>();
    const history = useHistory();
    const classes = useStyles();

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const {
        data: { 
            addressbook
        } = { addressbook: []}
    } = useQuery<AddressbookData, AddressbookVars>(AddressbookQuery, {
        variables: { service_id: service_id! },
        fetchPolicy: "cache-and-network"
    });

    const [transferDocument] = useMutation(TransferDocumentMutation);

    const {
        data: {
            consortiumMemberships: memberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });
    
    const {
        data: {
            destinations: senderDestinations
        } = { destinations: []}
    } = useQuery<DestinationsData, DestinationsVars>(DestinationsQuery, {
        variables: { service_id: service_id! }
    });


    const membershipsOptions = useMemo(() => {
        return (addressbook as Array<Addressbook>).map((entry: Addressbook) => {
           const membership = memberships.find(membership => membership._id === entry.membership.substr(1));
           return {
               name: membership?.org_name ?? '',
               id: membership?._id ?? '',
               destinations: entry.destinations
           }
       })
    }, [memberships, addressbook]);

    const onMembershipChange = (membershipId: string) => {
        setSelectedReceiverDestination('');
        setMembershipIdSelected(membershipId);
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            await transferDocument({
                variables: {
                    service_id,
                    transfer: {
                        from: selectedSenderDestination,
                        to: selectedReceiverDestination,
                        document: `${currentPath && currentPath + '/'}${documentName}`
                    }
                }
            })
            history.push(`${basePath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/${service_id}/${DOCSTORE_TRANSFERS_PATH}`)
        } catch (err) {
            throw new Error(err.message || err); //throw error to be handle by FormDialog component
        } finally {
            setLoading(false)
        }
    }
    
    const receiverDestinationsOptions = useMemo(() => {
        return membershipsOptions.find(entry => entry.id === `${membershipIdSelected}`)?.destinations ?? [] as Array<Destination>;
    }, [membershipIdSelected, membershipsOptions]);

    const senderFilteredDestinations = useMemo(() => {
        return senderDestinations?.filter(el => el.setup_complete);
    }, [senderDestinations]);

    useEffect(() => {
        setSelectedSenderDestination(senderFilteredDestinations[0]?.uri ?? '');
    }, [senderFilteredDestinations])
    
    useEffect(() => {
        setSelectedReceiverDestination(receiverDestinationsOptions[0]?.uri ?? '');
    }, [receiverDestinationsOptions]);

    useEffect(() => {
        setMembershipIdSelected(membershipsOptions[0]?.id ?? '');
    }, [membershipsOptions]);

    const setUpDestinations = async () => history.push(`${basePath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/${service_id}/${DOCSTORE_DESTINATIONS_PATH}/create/1`);

    const Content = (
        <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item className={classes.alignSelf}>
                    <FileIcon />
                </Grid>
                <Grid item xs={8}>
                    <Typography noWrap variant="body1">{documentName}</Typography>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container direction="column" spacing={1}>
                    <Grid item >
                        <Typography variant="h6" color="textSecondary">{lt('sendTo')}</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" fullWidth>
                            <Select labelWidth={labelWidth} value={membershipIdSelected} onChange={e =>  onMembershipChange(e.target.value as string)}>
                                {membershipsOptions.map(entry => (
                                    <MenuItem key={entry.id} value={entry.id}>{entry.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                    <Grid item >
                        <Typography variant="h6" color="textSecondary">{lt('selectReceiverDestination')}</Typography>
                    </Grid>
                    <Grid item>
                        <Select className={classes.dropdown} variant="outlined" value={selectedReceiverDestination} onChange={e => setSelectedReceiverDestination(e.target.value as string)}>
                            {receiverDestinationsOptions.map(entry => (
                                <MenuItem key={entry.uri} value={entry.uri}>{entry.name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h6" color="textSecondary">{lt('selectSenderDestination')}</Typography>
                </Grid>
                <Grid item>
                    <Select className={classes.dropdown} variant="outlined" value={selectedSenderDestination} onChange={e => setSelectedSenderDestination(e.target.value as string)}>
                        {(senderFilteredDestinations as Array<Destinations>).map(entry => (
                            <MenuItem key={entry.uri} value={entry.uri}>{entry.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    )

    if (senderFilteredDestinations.length === 0) {
        return <FormDialog open={open}
                        setOpen={setOpen}
                        header={lt('noDestinations')}
                        description={lt('noDestinationsDescription')}
                        onSave={setUpDestinations}
                        saveText={lt('setUpDestinations')} />
    }

    return <FormDialog 
                open={open}
                setOpen={setOpen}
                header={lt('transferFile')}
                controlsWrapper={Content} 
                onSave={onSubmit}
                saveText={lt('transfer')}
                successMessage={lt('success')}
                saveDisabled={loading} />
};

interface translations {
    chooseDocument: string,
    noFiles: string,
    upload: string,
    overwrite: string,
    sendTo: string,
    transferFile: string,
    transfer: string,
    selectReceiverDestination: string,
    selectSenderDestination: string,
    success: string,
    noDestinations: string,
    noDestinationsDescription: string,
    setUpDestinations: string
}

const enTranslations: translations = {
    chooseDocument: 'Choose the document to upload',
    noFiles: 'No Document Selected',
    upload: 'Upload',
    overwrite: 'Overwrite if document already exists',
    sendTo: 'Receiver Membership',
    transferFile: 'Transfer Document',
    transfer: 'Transfer',
    selectReceiverDestination: 'Receiver Destination',
    selectSenderDestination: 'Sender Destination',
    success: 'Transfer was successful!',
    noDestinations: 'There are no destinations setup',
    noDestinationsDescription: 'Destinations have to be configured before initiating a transfer.',
    setUpDestinations: 'Setup'
}

const useStyles = makeStyles(theme => ({
    alignSelf: {
        display:'flex',
        alignSelf: 'center'
    },
    dropdown: {
        width: '100%'
    },
    container: {
        marginBottom: theme.spacing(2)
    }
}));