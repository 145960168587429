import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    errorMessage: string
    handleSubmitPassword: () => void
    loading: boolean
}

export const PasswordPrompt: React.FC<Props> = ({ password, setPassword, errorMessage, handleSubmitPassword, loading }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'PasswordPrompt', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`PasswordPrompt:${key}`, interpolate);

    return (
        <form id="PasswordPromptForm" className={'Hubspot'} style={{ width: '300px' }} onSubmit={event => { event.preventDefault(); handleSubmitPassword() }} >
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('readyToSignIn')}</Typography>
                </Grid>
                {errorMessage &&
                    <Grid item>
                        <Typography color="error" variant="subtitle1">
                            {errorMessage}
                        </Typography>
                    </Grid>
                }
                <Grid item>
                    <TextField
                        label={lt('password')}
                        value={password}
                        fullWidth
                        variant="outlined"
                        autoComplete="username"
                        margin="none"
                        type="password"
                        onChange={event => setPassword(event.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button type="submit"
                        fullWidth
                        disabled={loading || !password}
                        variant="contained"
                        size="large"
                        color='primary'>
                        {lt('signIn')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

interface translations {
    readyToSignIn: string
    password: string
    signIn: string
}

const enTranslations: translations = {
    readyToSignIn: 'Ready to sign in!',
    password: 'Password',
    signIn: 'Sign In'
}
