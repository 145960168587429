import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { SECURITY_BASE_PATH, SECURITY_BAF_PATH } from '../../../components/MainNav/SideNavs/Security';
import { Step1 } from './Step1';

export const BAFPolicyCreate = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFPolicyCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFPolicyCreate:${key}`, interpolate);

    const {consortium_id, environment_id, org_id} = useParams<any>();

    type locationState = { 
        cancelPath: string
    }
    const history = useHistory<locationState>()
    
    const { 
        location: { 
            state: {
                cancelPath: referrerCancelPath
            }
        } 
    } = history
    
    const cancelPath = referrerCancelPath || `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`;

    const {step} = useParams<any>();

    const stepComponents = [
        {
            step: lt('chooseMembership'),
            component: <Step1 cancelPath={cancelPath} />
        }
    ]

    return (
        <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('toolBarHeader')} stepUrlParam={step!} {...{stepComponents}} hideStepper />
    )
}

interface translations {
    toolBarHeader: string,
    setupConfig: string,
    chooseMembership: string
}
const enTranslations: translations = {
    toolBarHeader: 'Create Blockchain Application Firewall Policy',
    setupConfig: 'Set Up Policy',
    chooseMembership: 'Choose Membership and Name'
}