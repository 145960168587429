import { Grid, TextField, Typography } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper } from '../../../components/DialogWrappers';
import { NETWORK_GOVERNANCE_INVITATIONS_PATH, NETWORK_GOVERNANCE_PATH } from "../../../components/MainNav/SideNavs/Governance";
import { CreateStepProps } from '../../../interfaces';
import { Step2Help } from './Step2Help';
import { isEmailValid } from "../../../utils/StringUtils";

interface Props extends CreateStepProps {
    orgName: string
    setOrgName: React.Dispatch<React.SetStateAction<string>>
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
};

export const Step2 = ({ orgName, setOrgName, email, setEmail, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GovernanceInviteOrganizationCreateStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceInviteOrganizationCreateStep2:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();
   
    const save = async () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_INVITATIONS_PATH}/create/3`, {
            orgName, 
            email
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField required
                    value={orgName} 
                    onChange={event => setOrgName(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('orgName')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField required
                    value={email} 
                    onChange={event => setEmail(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('email')}
                    variant="outlined"
                    helperText={email && !isEmailValid(email) && lt('mustBeValidEmail')}
                />
            </Grid>
        </>
    )

    const disabled = !orgName || !email || !isEmailValid(email)

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} />
            <Step2Help />
        </>
    )
};

interface translations {
    header: string
    orgName: string
    email: string
    mustBeValidEmail: string
}
const enTranslations: translations = {
    header: 'Contact Information',
    orgName: 'Organization name',
    email: 'Email',
    mustBeValidEmail: 'Must be a valid e-mail address'
}