import React, { useCallback, useEffect, useState } from 'react';
import { Service, ServiceState, EnvironmentData, EnvironmentQuery } from '../../models';
import { EmptyCard } from '../../components/DisplayWrappers/EmptyCard';
import AdjustIcon from 'mdi-react/AdjustIcon';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import { useTranslation } from 'react-i18next';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import CalendarClockIcon from 'mdi-react/CalendarClockIcon';
import { LinkButtonProps, ConsortiumResourceVars } from '../../interfaces';
import BadgeAccountHorizontalOutlineIcon from 'mdi-react/BadgeAccountHorizontalOutlineIcon';
import { useQuery } from '@apollo/client';

interface Props {
    services: Service[],
    emptyHeader: string
    emptyDescription: string
    servicePath: string // Ex: `${BLOCKCHAIN_BASE_PATH}/ipfs`
    serviceInfoLink: string
    emptyImagePath: string
    noLinkButton?: boolean
    createDisabled?: boolean
    dataTestId?: string
}

export const ServicesCard = ({services, emptyHeader, emptyDescription, servicePath, serviceInfoLink, emptyImagePath, noLinkButton = false, createDisabled, dataTestId}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ServicesCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ServicesCard:${key}`, interpolate)

    const { org_id, consortium_id, environment_id } = useParams<any>()
    const history = useHistory();

    const [servicesList, setServicesList] = useState<DisplayCardListItem[]>([]);

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    const createPath = `${basePath}/${servicePath}/create/1`;

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });

    const makeStatus = (state: ServiceState) => {
        return (
                <><Grid item container spacing={1}>
                    <Grid item>
                        <ResourceStateChip state={state} />
                    </Grid>
                    <Grid item>
                        <ChevronRightIcon />
                    </Grid> 
                </Grid>
            </>
        )
    };
    
    const generateList = useCallback(() => {
        return services.map(s => (
            { 
                title: s.name, 
                value: s.membership.name,
                icon: <AdjustIcon />,
                onClick: () =>  history.push(`${basePath}/${servicePath}/${s._id}`),
                actionIcon: makeStatus(s.state)
            }
        ))
    }, [history, servicePath, basePath, services]);

    let serviceInfo: DisplayCardListItem[]  = [];

    if (services.length === 1) {
        const service = services[0];
        const info : DisplayCardListItem[] = [
            {
                title: lt('status'),
                value: <ResourceStateChip state={service.state} />,
                icon: <ChartLineIcon />,
            }, {
                title: lt('id'),
                value: service._id,
                icon: <BadgeAccountHorizontalOutlineIcon />
            }, {
                title: lt('membership'),
                value: service.membership.name,
                icon: <AccountCircleOutlineIcon />
            }, {
                title: lt('creationDate'),
                value: new Date(service.created_at).toLocaleString(),
                icon: <CalendarClockIcon />,
            }
        ];
        serviceInfo.push(...info);
    };

    const linkButton: LinkButtonProps = {
        text: lt('createNew'),
        onClick: () => history.push(`${createPath}`),
        disabled: environment?.state !== 'live'
    };

    

    useEffect(() => {
        if (services.length > 1) {
            setServicesList(generateList());
        }
    }, [services, generateList])


    if (services.length === 0) return <EmptyCard
                                        header={emptyHeader} 
                                        description={emptyDescription}
                                        imageFiles={emptyImagePath}
                                        buttonLabel={lt('createNew')}
                                        createPath={createPath}
                                        createDisabled={createDisabled}
                                        onViewDetailsClick={() => window.open(serviceInfoLink)} />;

    if (services.length === 1 ) return <DisplayCard header={emptyHeader}
                                            itemList={serviceInfo}
                                            linkButton2={createDisabled ? undefined : linkButton}
                                            linkButton={{
                                                text: lt('manage'),
                                                onClick: () => history.push(`${basePath}/${servicePath}/${services[0]._id}`)
                                            }} />
    
    return (
        <DisplayCard {...{dataTestId}} header={emptyHeader}
            itemList={servicesList}
            linkButton={noLinkButton ? undefined : linkButton} />
    )                                        

};

interface translations {
    status: string,
    creationDate: string,
    id: string
    membership: string
    createNew: string
    manage: string
    initialize: string
};

const enTranslations: translations = {
    status: 'Status',
    creationDate: 'Creation Date',
    membership: 'Membership',
    id: 'ID',
    createNew: 'Create New',
    manage: 'Manage',
    initialize: 'Initialize'
}

