import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react';

export interface RadioSelectorOptions {
    value: string
    isDisabled?: boolean
    label: string
    description: string
}

interface Props {
    selected: string
    onChange: ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void) | undefined,
    options: RadioSelectorOptions[]
}


export const RadioSelector = ({selected, onChange, options}: Props) => {

    const classes = useStyles();

    const makeRadioLabel = (label: string, description: string) => 
        <>
            <Typography variant="body1">
                {label}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {description}
            </Typography>
        </>

    return (
        <FormControl fullWidth component="fieldset" margin="none">
            <RadioGroup value={selected} onChange={onChange}>
                {options.map(option => (
                    <FormControlLabel key={`radio-${option.label}`} data-test={`radio_${option.value}`}
                        checked={selected === option.value}
                        className={selected === option.value ? classes.radioPaperSelected : classes.radioPaper}
                        disabled={option.isDisabled} 
                        value={option.value} 
                        control={<Radio color="primary" />} 
                        label={makeRadioLabel(option.label, option.description)} />
                ))}
            </RadioGroup>
        </FormControl>
    )
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(2),
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
    },
    radioPaperSelected: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(2),
        padding: theme.spacing(1, 2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
    },
}))