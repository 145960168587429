import {gql} from '@apollo/client';

// INTERFACES

export interface AuditEntry {
    _id: string
    action: string
    objectType: string
    consortia_id: string
    timestamp: string
    data: AuditEventData
}

export interface AuditEventData {
    _id: string
    name: string
    membership_id: string
    zone_id: string
    environment_id: string
    state: string
    service: string
    service_type: string
    init_consensus_role: string
    consensus_type: string
    provider: string
    owner: string
    org_name: string
    description: string
    evm_version: string
    type: string
    environment_name: string
    region: string
    cloud: string
    owner_org_name: string
    membership_name: string
}

export interface AuditVars {
    consortia_id: string
    environment_id?: string
    createdBefore?: string
    skip: number
    limit: number
}

export interface AuditData {
    audit: AuditEntry[]
}

// QUERIES & SUBSCRIPTIONS

export const AuditQuery = gql`
    query getAudit($consortia_id: String!, $environment_id: String, $createdBefore: String, $skip: Int!, $limit: Int!) {
        audit(consortia_id: $consortia_id, environment_id: $environment_id, createdBefore: $createdBefore, skip: $skip, limit: $limit) {
            _id
            action
            objectType
            consortia_id
            timestamp
            data {
                _id
                name
                membership_id
                zone_id
                environment_id
                state
                service
                service_type
                init_consensus_role
                consensus_type
                provider
                owner
                org_name
                description
                evm_version
                type
                environment_name
                region
                cloud
                owner_org_name
                membership_name
            }
        }
    }`;