import React, { useState, useEffect } from "react";
import { FormDialog, MessageSnackbar } from "../../../components/DialogWrappers";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Typography,
    makeStyles,
    TextField,
    Checkbox,
    IconButton,
    FormControlLabel,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { DocumentsData, DocumentsVars } from "../../../models/documentstore";
import { ApolloQueryResult } from "@apollo/client";
import { DragAndDropZone } from "./Upload/DragAndDropZone";
import FileIcon from "mdi-react/FileIcon";
import DeleteIcon from "mdi-react/DeleteIcon";

const filterUploadPath = (folderPath: string) => {
    let newPath;
    if (folderPath !== "" && folderPath[folderPath.length - 1] !== "/") {
        newPath = folderPath.concat("/");
    } else {
        newPath = folderPath;
    }
    if (newPath[0] === "/") {
        newPath = newPath.substr(1);
    }
    return newPath;
};

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentPath: string;
    refetchDocuments: (
        variables?: DocumentsVars | undefined
    ) => Promise<ApolloQueryResult<DocumentsData>>;
}

const FILESIZELIMIT = 134217728; //Fixed for now

export const UploadFile: React.FC<Props> = ({
    open,
    setOpen,
    currentPath,
    refetchDocuments,
}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "UploadFile", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`UploadFile:${key}`, interpolate);

    const [file, setFile] = useState<FileList | null>(null);
    const [path, setPath] = useState<string>(currentPath);
    const [overwrite, setOverwrite] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { service_id } = useParams<any>();

    useEffect(() => {
        setPath(currentPath);
    }, [currentPath]);

    useEffect(() => {
        setFile(null);
        setOverwrite(false);
    }, [open]);

    const onSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("document", file![0]);
        const filteredPath = filterUploadPath(path);
        const response = await fetch(
            `/api/ui/v2/documentstore/${service_id}/documents/${filteredPath}${
                file![0].name
            }`,
            {
                method: overwrite ? "PUT" : "POST",
                headers: {
                    "kaleido-custom-content-type": "true",
                },
                body: formData,
            }
        );
        if (!response.ok) {
            setLoading(false);
            setFile(null);
            const err = await response.json();
            throw new Error(err.errorMessage)
        }
        await refetchDocuments();
        setLoading(false);
        setFile(null);
    };

    const onInputChange = (input: string) => {
        setPath(input);
        setIsValid(!/\/=?[^A-z0-9]/.test(input));
    };

    const Content = (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessage}
            />
            <Grid container direction="column" spacing={3}>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography variant="h6">
                                {lt("chooseDocument")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DragAndDropZone
                                {...{ file }}
                                {...{ setFile }}
                                {...{ setErrorMessage }}
                                fileSizeLimit={FILESIZELIMIT}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item>
                            <Typography variant="h6">
                                {lt("selectedFile")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            justify="space-between"
                            wrap="nowrap"
                        >
                            {file && file.length > 0 ? (
                                <>
                                    <Grid
                                        item
                                        container
                                        alignItems="center"
                                        spacing={1}
                                        xs={8}
                                    >
                                        <Grid item>
                                            <FileIcon />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography noWrap>
                                                {file![0].name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => setFile(null)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item>
                                    <Typography>{lt("noFiles")}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="h6">
                            {lt("specifyPath")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.pathInput}
                            label={lt("path")}
                            value={path}
                            onChange={(e) => onInputChange(e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item container alignItems="center">
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={overwrite}
                                        onChange={() => setOverwrite((c) => !c)}
                                    />
                                }
                                label={lt("overwrite")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );

    return (
        <FormDialog
            open={open}
            setOpen={setOpen}
            header={lt("uploadDocument")}
            controlsWrapper={Content}
            onSave={onSubmit}
            saveText="Upload"
            saveDisabled={!isValid || !file || loading}
            successMessage={lt("success")}
            closeDialogAfterSave
        />
    );
};

interface translations {
    uploadDocument: string;
    chooseDocument: string;
    noFiles: string;
    upload: string;
    overwrite: string;
    specifyPath: string;
    success: string;
    path: string;
    alreadyExists: string;
    selectedFile: string;
}
const enTranslations: translations = {
    chooseDocument: "Choose the document to upload",
    noFiles: "No Document Selected",
    upload: "Upload",
    overwrite: "Overwrite if document already exists",
    specifyPath: "Specify the target path (Optional)",
    success: "Upload successful!",
    path: "Path",
    alreadyExists: "already exists in directory",
    selectedFile: "Selected Document",
    uploadDocument: "Upload Document",
};

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4, 5, 2, 5),
    },
    input: {
        display: "none",
    },
    pathInput: {
        width: "100%",
    },
}));
