import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateConfigForm, CreateConfigInputList } from './CreateConfigForm';
import { ConfigDetails, ProviderType } from '../../../models/configs';
import { FieldElement } from 'react-hook-form';


interface Props {
    provider: ProviderType,
    register: {<Element extends FieldElement<ConfigDetails> = FieldElement<ConfigDetails>>(): (ref: Element | null) => void;}
}

export const CreateCloudHsm = ({provider, register}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateCloudHsm', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateCloudHsm:${key}`, interpolate);


    const AzureInputList: CreateConfigInputList[] = [
        {
            label: lt('targetAddress'),
            value: 'target_address'
        },{
            label: lt('userId'),
            value: 'user_id'
        },
        {
            label: lt('userSecret'),
            value: 'user_secret'
        },
        {
            label: lt('subscriptionId'),
            value: 'subscription_id'
        },
        {
            label: lt('tenantId'),
            value: 'tenant_id',
        },
        {
            label: lt('vaultName'),
            value: 'vault_name'
        }
    ]

    const AwsInputList: CreateConfigInputList[] = [
        {
            label: lt('targetAddress'),
            value: 'target_address'
        },{
            label: lt('userId'),
            value: 'user_id'
        }, {
            label: lt('userSecret'),
            value: 'user_secret'
        }, {
            label: lt('ca_certs'),
            value: 'tls.ca_certs',
            multiline: true
        }
    ]

    const AwsKmsInputList: CreateConfigInputList[] = [
        {
            label: lt('userId'),
            value: 'user_id'
        }, {
            label: lt('userSecret'),
            value: 'user_secret'
        }, {
            label: lt('targetRegion'),
            value: 'target_region'
        }
    ]

    const hashiCorpList: CreateConfigInputList[] = [
        {
            label: lt('targetAddress'),
            value: 'target_address'
        },{
            label: lt('vaultName'),
            value: 'vault_name'
        }, {
            label: lt('userSecret'),
            value: 'user_secret'
        }, {
            label: lt('ca_certs'),
            value: 'tls.ca_certs',
            multiline: true
        }, {
            label: lt('client_certs'),
            value: 'tls.client_certs',
            multiline: true
        }, {
            label: lt('client_cert_secret'),
            value: 'tls.client_cert_secret',
            multiline: true
        }
    ]

    const getList = () => {
        if (provider === 'aws') return AwsInputList;
        if (provider === 'hashicorp') return hashiCorpList;
        if (provider === 'aws_kms') return AwsKmsInputList;
        return AzureInputList
    }


    return  <CreateConfigForm inputList={getList()} {...{register}} />;

}

interface translations {
    name: string,
    userId: string
    userSecret: string 
    targetAddress: string
    subscriptionId: string
    tenantId: string
    vaultName: string
    ca_certs: string
    client_cert_secret: string
    client_certs: string
    targetRegion: string
}
const enTranslations: translations = {
    name: 'Name',
    subscriptionId: 'Subscription ID',
    vaultName: 'Vault Name',
    userSecret: 'User Secret',
    userId: 'User ID',
    tenantId: 'Tenant Id',
    targetAddress: 'Target Address',
    ca_certs: 'CA Certs',
    client_certs: 'Client Certs',
    client_cert_secret: 'Client Certs Secret',
    targetRegion: 'AWS Region'
}