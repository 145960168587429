import React from 'react';
import { Governance } from './SideNavs';
import { useParams, useLocation, useHistory } from "react-router-dom";
import ViewDashboardOutlineIcon from 'mdi-react/ViewDashboardOutlineIcon';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import AppsIcon from 'mdi-react/AppsIcon';
import { useTranslation } from 'react-i18next';
import { NavItem } from '../NavComponents/NavItem'

export const NETWORK_SETTINGS_PATH = 'settings'
export const NETWORK_REGIONS_PATH = 'regions'
export const NETWORK_CONTRACTS_PATH = 'apps'
export const NETWORK_COMPILED_CONTRACTS_PATH = 'versions'
export const NETWORK_COMPILED_CONTRACTS_PROMOTE_PATH = 'promote'
export const NETWORK_CONTRACTS_CREATE_PATH = 'create'

interface Props {
    governance: boolean,
    setGovernance: React.Dispatch<React.SetStateAction<boolean>>,
};

export const ConsortiumWrapper = ({ governance, setGovernance }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConsortiumWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConsortiumWrapper:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { org_id, consortium_id } = useParams<any>();

    return (
        <>
            <NavItem icon={<ViewDashboardOutlineIcon />} 
                header={lt('dashboard')} 
                action={() => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}`)} 
                itemIsActive={pathname.endsWith(consortium_id!)} 
            />
            
            <Governance selected={governance} setSelected={setGovernance} />
            
            <NavItem icon={<AppsIcon />}
                header={lt('apps')} 
                action={() => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/${NETWORK_CONTRACTS_PATH}`)} 
                itemIsActive={pathname.includes(`/consortia/${consortium_id!}/${NETWORK_CONTRACTS_PATH}`)} 
            />

            <NavItem icon={<CogOutlineIcon />}
                header={lt('settings')} 
                action={() => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/settings`)} 
                itemIsActive={pathname.endsWith(`/consortia/${consortium_id!}/${NETWORK_SETTINGS_PATH}`)} 
            />
        </>
    );
};

interface translations {
    dashboard: string,
    settings: string,
    apps: string
}
const enTranslations: translations = {
    dashboard: 'Dashboard',
    settings: 'Settings',
    apps: 'Apps'
}
