import { Grid, Typography, TextField, Paper, makeStyles, MenuItem, FormControlLabel, Switch, Select, ListItemText, Checkbox, InputLabel, FormControl, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageSnackbar, ErrorSnackbarCatcher, FormDialog } from '../../components/DialogWrappers';
import { Agreement, AgreementBase, AgreementTerm, AgreementTemplate, AgreementOverage, AgreementCredits, AgreementBilling, AgreementLimits, AgreementPriceSheet } from '../../models/agreements';
import { EnServicesTranslations } from '../../models/services';
import { Region } from '../../interfaces';
import fileDownload from 'js-file-download'
import { RuntimeSizeTranslation, EnRuntimeSizeTranslation } from '../../models';

interface Props {
    agreement: Agreement;
    saveAgreement: (a: Agreement, r?: string) => Promise<void>;
    deleteAgreement?: (id: string) => Promise<void>;
    loading: boolean;
    regions?: Region[];
}

interface AgreementPriceSheetWithJSONParts extends AgreementPriceSheet {
    hourlyJSON?: string,
    monthlyJSON?: string,
}

export const AgreementPanel: React.FC<Props> = ({agreement, saveAgreement, deleteAgreement, loading, regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsAgreementPanel', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsAgreementPanel:${key}`, interpolate)

    const classes = useStyles();

    const [message, setMessage] = useState('');
    const [region, setRegion] = useState<string | undefined>();
    const [agreementName, setAgreementName] = useState('');
    const [agreementBase, setAgreementBase] = useState<AgreementBase | undefined>();
    const [template, setTemplate] = useState<AgreementTemplate | undefined>();
    const [term, setTerm] = useState<AgreementTerm>({});
    const [overage, setOverage] = useState<AgreementOverage>({});
    const [credits, setCredits] = useState<AgreementCredits>({});
    const [billing, setBilling] = useState<AgreementBilling>({});
    const [priceSheets, setPriceSheets] = useState<AgreementPriceSheetWithJSONParts[]>([]);
    const [limits, setLimits] = useState<AgreementLimits | undefined>({});
    const [deleteAgreementOpen, setDeleteAgreementOpen] = useState(false);

    const toHTMLDate = (s?: string) => {
        if (!s) return;
        return s.substring(0, s.indexOf('T'));
    }

    useEffect(() => {
        setAgreementName(agreement.name);
        setAgreementBase({
            description: agreement.description,
            type: agreement.type,
            plans: agreement.plans,
            primary_org: agreement.primary_org,
        });
        setTemplate(agreement.template);
        setTerm({
            ...agreement.term,
            start_date: toHTMLDate(agreement.term?.start_date),
            end_date: toHTMLDate(agreement.term?.end_date),
        });
        setOverage(agreement.overage || {});
        setCredits(agreement.credits || {});
        setBilling({
            ...agreement.billing,
            uplifts: {
                ...agreement.billing?.uplifts,
                support: {
                    ...agreement.billing?.uplifts?.support
                }
            },
            storage: {
                ...agreement.billing?.storage
            }
        });
        setPriceSheets(agreement.price_sheets?.map(p => ({
            ...p,
            hourlyJSON: p.hourly && JSON.stringify(p.hourly),
            monthlyJSON: p.monthly && JSON.stringify(p.monthly),
        })) || []);
        setLimits(agreement.limits);
    }, [agreement]);

    useEffect(() => {
        if (regions?.length && !region) {
            setRegion(regions[0].code);
        }
    }, [regions, region]);

    const setField = (parent: any, fieldName: string, stateUpdater: () => void, value: any) => {
        parent[fieldName] = value;
        stateUpdater();
    }

    const nullOrUndefined = (f: any) => f === null || f === undefined;

    const nullSwitcher = (parent: any, fieldName: keyof translations, stateUpdater: () => void, defaultValue: any) => (
        <Grid item>
            <FormControlLabel
                control={<Switch
                    checked={!nullOrUndefined(parent[fieldName])}
                    color="primary"
                    onChange={(e) => {
                        setField(parent, fieldName, stateUpdater, nullOrUndefined(parent[fieldName]) ? defaultValue : undefined)
                    }}
                />}
            label={lt(fieldName)} labelPlacement="start" />
        </Grid>
    );

    const baseNullSwitcher = (value: any, fieldName: keyof translations, stateUpdater: (v: any) => void, defaultValue: any) => (
        <Grid item>
            <FormControlLabel
                control={<Switch
                    checked={!nullOrUndefined(value)}
                    color="primary"
                    onChange={(e) => {
                        stateUpdater(nullOrUndefined(value) ? defaultValue : undefined);
                    }}
                />}
            label={lt(fieldName)} labelPlacement="start" />
        </Grid>
    );

    const textFieldFor = (parent: any, fieldName: keyof translations, stateUpdater: () => void, type?: 'number' | 'date' | 'readonly' | 'kcents' | 'array', nullable?: boolean, required?: boolean) => (
        <Grid item container spacing={2} alignItems="center" alignContent="stretch" key={`${fieldName}`}>
            {nullable && nullSwitcher(parent, fieldName, stateUpdater, '')}
            <Grid item xs>
                <TextField
                    value={parent[fieldName] || ''}
                    onChange={event => setField(parent, fieldName, stateUpdater,
                        type === 'array' ? (event.target.value ? event.target.value.split(',') : '') : 
                        (type === 'number' || type === 'kcents') ? parseFloat(event.target.value) : event.target.value)}
                    type={type === 'readonly' ? undefined : type === 'kcents' ? 'number' : type}
                    margin="normal"
                    label={`${lt(fieldName)}${(parent[fieldName] && type === 'kcents') ? (' $' + (parent[fieldName]/100).toFixed(2)) : ''}`}
                    fullWidth
                    {...{required}}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    disabled={type === 'readonly' || (nullable && parent[fieldName] === undefined)}
                />
            </Grid>
        </Grid>
    );

    const baseTextField = (fieldName: keyof translations, stateUpdater: (s: string) => void, state?: string, required?: boolean, disabled?: boolean) => {
        return <Grid item container spacing={2} alignItems="center" alignContent="stretch" key={`${fieldName}`}>
            <Grid item xs>
                <TextField
                    value={state || ''}
                    onChange={event => stateUpdater(event.target.value)}
                    margin="normal"
                    label={`${lt(fieldName)}`}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...{required}}
                    {...{disabled}}
                />
            </Grid>
        </Grid>
    }

    const switcherFor = (parent: any, fieldName: keyof translations, stateUpdater: () => void) => (
        <Grid item container spacing={2} alignItems="center">
            <Grid item>
                <FormControlLabel
                    control={<Switch
                        checked={parent[fieldName] ? true : false}
                        color="primary"
                        onChange={(e) => {
                            setField(parent, fieldName, stateUpdater, parent[fieldName] ? false : true)
                        }}
                    />}
                label={lt(fieldName)} labelPlacement="start" />
            </Grid>       
        </Grid>
    );

    const selectFieldFor = (parent: any, fieldName: keyof translations, stateUpdater: () => void, values: string[]) => (
        <Grid item>
            <TextField
                value={parent[fieldName]} onChange={event => setField(parent, fieldName, stateUpdater, event.target.value)}
                margin="normal"
                label={lt(fieldName)}
                fullWidth
                variant="outlined"
                select
            >
                {values.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
            </TextField>
        </Grid>
    );

    const multiSelectFieldFor = (parent: any, fieldName: keyof translations, stateUpdater: () => void, allowedValues: string[]) => {
        const currentValues = parent[fieldName] || [];
        const valueSet = [...allowedValues];
        for (let v of currentValues) {
            if (!valueSet.includes(v)) valueSet.push(v);
        }
        return <Grid item>
            <FormControl fullWidth>
                <InputLabel variant="outlined">{lt(fieldName)}</InputLabel>
                <Select
                    value={currentValues} onChange={event => setField(parent, fieldName, stateUpdater, event.target.value)}
                    renderValue={(selected) => {
                        return selected ? (selected as string[]).join(',') : '';
                    }}
                    variant="outlined"
                    multiple
                >
                    {valueSet.map(v => <MenuItem key={v} value={v}>
                        <Checkbox color="primary" checked={currentValues.includes(v)} />
                        <ListItemText primary={v} />
                    </MenuItem>)}
                </Select>
            </FormControl>
        </Grid>
    }

    const perPlanTextFields = (parent: any, stateUpdater: () => void, type?: 'number' | 'date' | 'readonly' | 'kcents', nullable?: boolean) => (
        agreementBase?.plans?.map(plan => textFieldFor(parent, plan, stateUpdater, type, nullable))
    )

    const subSection = (title: keyof translations, children: JSX.Element) => {
        return (<Paper className={classes.paper}>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h6">{lt(title)}</Typography>
                </Grid>
                {children}
            </Grid>
        </Paper>)
    }

    const subSectionAdder = (values: any[], fieldName: keyof translations, stateUpdater: (v: any) => void, itemName: keyof translations, defVal: any, childGen: (s: any) => JSX.Element) => {
        const children: JSX.Element[] = [];
        for (let i = 0; i < values.length; i++) {
            children.push(<Paper className={classes.paper} key={`aas-${fieldName}-${i}`}>
                <Grid container direction="column" spacing={1}>
                    <Grid item container spacing={3}>
                        <Grid item xs>
                            <Typography variant="h6">{`${lt(itemName)} ${i+1}`}</Typography>
                        </Grid>
                        <Button variant="outlined" onClick={() => {
                            stateUpdater(values.slice(0,i).concat(values.slice(i+1)));
                        }}>{lt('deleteSection', {name: itemName})}</Button>
                    </Grid>
                    {childGen(values[i])}
                </Grid>
            </Paper>);
        }
        return <>
            {children}
            <Button variant="outlined" onClick={() => {
                stateUpdater(values.concat(defVal));
            }}>{lt('addSection', {name: itemName})}</Button>
        </>
    }

    const regionSelector = () => (
        <Grid item>
            <TextField
                value={region} onChange={event => setRegion(regions?.find(r => r.code === event.target.value)?.code)}
                margin="normal"
                label={lt('region')}
                fullWidth
                variant="outlined"
                select
            >
                {regions?.map(r => <MenuItem key={r.code} value={r.code}>{`${r.cloud}: ${r.region}`}</MenuItem>)}
            </TextField>
        </Grid>
    );

    if (!agreementBase) return <></>;

    const rebuildAgreement = () => {
        const newAgreement = {
            ...agreementBase,
            _id: agreement._id,
            name: agreementName,
            template,
            term: {
                ...term,
                start_date: term?.start_date && `${term?.start_date}T00:00:00.000Z`,
                end_date: term?.end_date && `${term?.end_date}T00:00:00.000Z`,
            },
            overage,
            credits,
            billing,
            price_sheets: priceSheets.map(p => ({
                ...p,
                hourlyJSON: undefined,
                hourly: p.hourlyJSON && JSON.stringify(p.hourlyJSON),
                monthlyJSON: undefined,
                monthly: p.monthlyJSON && JSON.stringify(p.monthlyJSON),
            } as AgreementPriceSheet)),
            limits
        };
        delete newAgreement.__typename;
        delete newAgreement.template?.__typename;
        delete newAgreement.term?.__typename;
        delete newAgreement.overage?.__typename;
        delete newAgreement.credits?.__typename;
        delete newAgreement.billing?.__typename;
        for (let p of newAgreement.price_sheets) delete p.__typename;
        delete newAgreement.limits?.__typename;
        return newAgreement;
    }
    
    const onSave = async () => {
        try {
            await saveAgreement(rebuildAgreement(), region);
        } catch(err) {
            ErrorSnackbarCatcher(err, setMessage)
        }
    };

    const newAgreementBase = {...agreementBase};
    const baseStateUpdater = () => setAgreementBase(newAgreementBase);
    const newTemplate = template && {...template};
    const tenmplateStateUpdater = () => setTemplate(newTemplate);
    const newTerm = {...term};
    const termStateUpdater = () => setTerm(newTerm);
    const newOverage = {...overage};
    const overageStateUpdater = () => setOverage(newOverage);
    const newCredits = {...credits};
    const creditsStateUpdater = () => setCredits(newCredits);
    const newBilling = {...billing};
    const billingStateUpdater = () => setBilling(newBilling);
    const newPriceSheets = [...priceSheets];
    const priceSheetsStateUpdater = () => setPriceSheets(newPriceSheets);
    const newLimits = limits && {...limits};
    const limitsStateUpdater = () => setLimits(newLimits);

    const isTemplate = !!template;
    const isNew = !agreement._id;

    const incomplete = !agreementName || (!isTemplate && !agreementBase.primary_org);

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog header={lt('delete')} open={deleteAgreementOpen} setOpen={setDeleteAgreementOpen} closeDialogAfterSave onSave={() => deleteAgreement!(agreement._id!)} saveDisabled={loading} />
        <Paper className={classes.paper}>
            <Grid container direction="column" spacing={1}>
                <Grid item container spacing={2}>
                    <Grid item xs>
                        <Typography variant="h5">{lt('title', {name: agreementName, id: agreement._id || lt('new')})}</Typography>
                    </Grid>
                    {!isNew && deleteAgreement && <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => setDeleteAgreementOpen(true)} disabled={loading || incomplete}>{lt('delete')}</Button>
                    </Grid>}
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => 
                            fileDownload(JSON.stringify(rebuildAgreement(), null, 2), `${agreementName}.json`, 'application/json')
                        }>{lt('download')}</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={onSave} disabled={loading || incomplete}>{lt('save')}</Button>
                    </Grid>
                </Grid>
                {isNew && regionSelector()}
                {baseTextField('name', setAgreementName, agreementName, true, !isNew)}
                {textFieldFor(newAgreementBase, 'description', baseStateUpdater)}
                {multiSelectFieldFor(newAgreementBase, 'plans', baseStateUpdater, ['starter','team','business','enterprise','partner'])}
                {selectFieldFor(newAgreementBase, 'type', baseStateUpdater, ['offer','contract','package'])}
                {subSection('binding', <>
                    {(<>
                        {baseNullSwitcher(template, 'template', setTemplate, {
                            duration: 6,
                            units: 'months'
                        })}
                    </>)}
                    {isTemplate ? <>
                        {textFieldFor(newTemplate, 'duration', tenmplateStateUpdater, 'number')}
                        {selectFieldFor(newTemplate, 'units', tenmplateStateUpdater, ['years','months','days'])}
                    </> : textFieldFor(newAgreementBase, 'primary_org', baseStateUpdater, undefined, undefined, true)}
                </>)}
                {subSection('term', <>
                    {!isTemplate && textFieldFor(newTerm, 'start_date', termStateUpdater, 'date', true)}
                    {!isTemplate && textFieldFor(newTerm, 'end_date', termStateUpdater, 'date', true)}
                    {switcherFor(newTerm, 'require_balance', termStateUpdater)}
                    {switcherFor(newTerm, 'expire_balance', termStateUpdater)}
                    {switcherFor(newTerm, 'archive_resources', termStateUpdater)}
                    {!isTemplate && textFieldFor(newTerm, 'expired_at', termStateUpdater, 'readonly')}
                    {!isTemplate && textFieldFor(newTerm, 'applied_at', termStateUpdater, 'readonly')}
                </>)}
                {subSection('overage', <>
                    {textFieldFor(newOverage, 'agreement', overageStateUpdater, undefined, true)}
                </>)}
                {subSection('credits', <>
                    {textFieldFor(newCredits, 'initial_balance', creditsStateUpdater, 'number', true)}
                </>)}
                {subSection('billing', <>
                    {textFieldFor(newBilling, 'discount_percent', billingStateUpdater, 'number', true)}
                    {textFieldFor(newBilling, 'discount_value', billingStateUpdater, 'kcents', true)}
                    {textFieldFor(newBilling, 'minimum', billingStateUpdater, 'kcents', true)}
                    {subSection('supportUplifts', <>
                        {perPlanTextFields(newBilling.uplifts!.support, billingStateUpdater, 'number', true)}
                    </>)}
                    {subSection('storageOverrides', <>
                        {perPlanTextFields(newBilling.storage, billingStateUpdater, 'number', true)}
                    </>)}
                </>)}
                {subSection('limitsHeader', <>
                    {(<>
                        {baseNullSwitcher(limits, 'limits', setLimits, {
                            nodes: {},
                            services: {},
                            service_types: []
                        })}
                    </>)}
                    {limits && <>
                        {subSection('nodes', <>
                            {textFieldFor(newLimits!.nodes, 'small', limitsStateUpdater, 'number', true)}
                            {textFieldFor(newLimits!.nodes, 'medium', limitsStateUpdater, 'number', true)}
                            {textFieldFor(newLimits!.nodes, 'large', limitsStateUpdater, 'number', true)}
                        </>)}
                        {subSection('services', <>
                            {textFieldFor(newLimits!.services, 'small', limitsStateUpdater, 'number', true)}
                            {textFieldFor(newLimits!.services, 'medium', limitsStateUpdater, 'number', true)}
                            {textFieldFor(newLimits!.services, 'large', limitsStateUpdater, 'number', true)}
                        </>)}
                        {multiSelectFieldFor(newLimits, 'service_types', limitsStateUpdater, Object.keys(EnServicesTranslations))}
                    </>}
                </>)}
                {subSection('price_sheets', <>
                    {subSectionAdder(newPriceSheets, 'price_sheets', setPriceSheets, 'sheet', {}, sheet => <>
                        {textFieldFor(sheet, 'tags', priceSheetsStateUpdater, 'array')}
                        {switcherFor(sheet, 'evictable', priceSheetsStateUpdater)}
                        {textFieldFor(sheet, 'discount_percent', priceSheetsStateUpdater, 'number', true)}
                        {textFieldFor(sheet, 'hourlyJSON', priceSheetsStateUpdater, undefined, true)}
                        {textFieldFor(sheet, 'monthlyJSON', priceSheetsStateUpdater, undefined, true)}
                    </>)}
                </>)}
            </Grid>
        </Paper>
    </>;
};

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
}));

interface translations extends RuntimeSizeTranslation {
    title: string;
    save: string;
    starter: string;
    team: string;
    business: string;
    enterprise: string;
    partner: string;
    partner_pref: string;
    signup: string;
    preview: string;
    template: string;
    name: string;
    description: string;
    type: string;
    primary_org: string;
    duration: string;
    units: string;
    term: string;
    start_date: string;
    end_date: string;
    require_balance: string;
    expire_balance: string;
    archive_resources: string;
    applied_at: string;
    expired_at: string;
    overage: string;
    agreement: string;
    plans: string;
    credits: string;
    initial_balance: string;
    billing: string;
    discount_percent: string;
    discount_value: string;
    minimum: string;
    storage: string;
    supportUplifts: string;
    storageOverrides: string;
    limitsHeader: string;
    limits: string;
    nodes: string;
    service_types: string;
    services: string;
    price_sheets: string;
    addSection: string;
    deleteSection: string;
    sheet: string;
    tags: string;
    tag: string;
    evictable: string;
    hourlyJSON: string;
    monthlyJSON: string;
    new: string;
    binding: string;
    region: string;
    delete: string;
    download: string;
}

const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    title: 'Agreement {{name}} ({{id}})',
    save: 'Save',
    starter: 'Starter',
    team: 'Developer',
    business: 'Business',
    enterprise: 'Enterprise',
    partner: 'Partner',
    partner_pref: 'Partner Preferred',
    signup: 'Signup',
    preview: 'Preview',
    template: 'Template',
    name: 'Name',
    description: 'Description',
    type: 'Type',
    primary_org: 'Primary Org - must be local to region of agreement',
    duration: 'Duration',
    units: 'Units',
    term: 'Term',
    start_date: 'Start Date',
    end_date: 'End Date',
    require_balance: 'Term ends when credit balance is zero?',
    expire_balance: 'Zero the credit balance when the agreement ends?',
    archive_resources: 'Archive all resources when the agreement ends?',
    applied_at: 'Applied at',
    expired_at: 'Expired at',
    overage: 'Overage',
    agreement: 'Rollover to Agreement',
    plans: 'Plans',
    credits: 'Credits',
    initial_balance: 'Initial Stripe balance to load',
    billing: 'Billing',
    discount_percent: 'Discount Percentage',
    discount_value: 'Monthly Discount',
    minimum: 'Committed Spend',
    storage: 'Storage',
    supportUplifts: 'Support uplift overrides (0.11 = 11% uplift)',
    storageOverrides: 'Storage overrides (cents per 10GB, per hour)',
    limitsHeader: 'Limits',
    limits: 'Global limits on resource',
    nodes: 'Node count by size',
    services: 'Service count by size',
    service_types: 'Service Types',
    price_sheets: 'Price Sheets',
    addSection: 'Add {{name}}',
    deleteSection: 'Delete {{name}}',
    sheet: 'Price Sheet',
    tags: 'Tags',
    tag: 'Tag',
    evictable: 'Evictable',
    hourlyJSON: 'Hourly Pricing (JSON)',
    monthlyJSON: 'Monthly Pricing (JSON)',
    new: 'New',
    binding: 'Binding',
    region: 'Region',
    delete: 'Delete',
    download: 'Download',
};
