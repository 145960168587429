import React, { useRef, useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { NodesSubscription, ServicesSubscription, MakeNodesSubscriptionOptions, MakeServicesSubscriptionOptions,
    EnvironmentZonesSubscription, MakeEnvironmentZonesSubscriptionOptions, ChannelsSubscription, MakeChannelsSubscriptionOptions,
    AppCredsSubscription, MakeAppCredsSubscriptionOptions, FeatureTogglesData, FeatureTogglesVars, FeatureTogglesQuery, ChannelsData, ChannelsQuery, EnvironmentData, EnvironmentQuery } from '../../models';
import {
    Firefly,
    Blockchain,
    AddressBook,
    DataExplorer,
    AppsIntegrations,
    DigitalAssets,
    B2bCommunication,
    CloudConfigs,
    HealthMonitoring
} from './SideNavs';
import { ListItemText, ListItem, CircularProgress } from "@material-ui/core";
import { EnvironmentWrapperQuery, EnvironmentWrapperResult } from '../../queries/EnvironmentWrapper'
import { ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import ViewDashboardOutlineIcon from 'mdi-react/ViewDashboardOutlineIcon';
import ArrowDecisionOutlineIcon from 'mdi-react/ArrowDecisionOutlineIcon';
import { NavItem } from '../NavComponents/NavItem'
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import { KeyManagement } from './SideNavs/KeyManagement';
import { CHANNELS_PATH } from './SideNavs/AddressBook';
import { Security } from './SideNavs/Security';

export const ENVIRONMENT_SETTINGS_PATH = 'settings'

interface Props {
    environment_id: string,
    setEnvironmentQueriesComplete: React.Dispatch<React.SetStateAction<boolean>>,
    dataExplorer: boolean,
    setDataExplorer: React.Dispatch<React.SetStateAction<boolean>>,
    healthMonitoring: boolean,
    setHealthMonitoring: React.Dispatch<React.SetStateAction<boolean>>,
    firefly: boolean,
    setFirefly: React.Dispatch<React.SetStateAction<boolean>>,
    blockchain: boolean,
    setBlockchain: React.Dispatch<React.SetStateAction<boolean>>,
    addressBook: boolean,
    setAddressBook: React.Dispatch<React.SetStateAction<boolean>>,
    apps: boolean,
    setApps: React.Dispatch<React.SetStateAction<boolean>>,
    security: boolean,
    setSecurity: React.Dispatch<React.SetStateAction<boolean>>,
    b2b: boolean,
    setB2b: React.Dispatch<React.SetStateAction<boolean>>,
    assets: boolean,
    setAssets: React.Dispatch<React.SetStateAction<boolean>>,
    cloudConfigs: boolean,
    setCloudConfigs: React.Dispatch<React.SetStateAction<boolean>>,
    keyManagement: boolean,
    setKeyManagement: React.Dispatch<React.SetStateAction<boolean>>
}

export const EnvironmentWrapper = ({
    environment_id,
    setEnvironmentQueriesComplete,
    dataExplorer,
    setDataExplorer,
    healthMonitoring,
    setHealthMonitoring,
    addressBook,
    setAddressBook,
    firefly,
    setFirefly,
    blockchain,
    setBlockchain,
    apps,
    setApps,
    security,
    setSecurity,
    b2b,
    setB2b,
    assets,
    setAssets,
    cloudConfigs,
    setCloudConfigs,
    keyManagement,
    setKeyManagement
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentWrapper:${key}`, interpolate)

    const { pathname } = useLocation()
    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const environmentResourcesVars = { 
        consortia_id: consortium_id!,
        environment_id: environment_id
    }

    // Setup the consortium wrapper query
    const { 
        loading: wrapperLoading,
        data: { 
            nodes,
            services
        } = { nodes: [], services: [] } 
    } = useQuery<EnvironmentWrapperResult, EnvironmentResourcesVars>(EnvironmentWrapperQuery,
        { variables: environmentResourcesVars, fetchPolicy: "cache-and-network" });

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-first'
    });

    const { data: { 
        environment 
    } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        loading: channelsLoading
    } = useQuery<ChannelsData, EnvironmentResourcesVars>(ChannelsQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-and-network',
        skip: environment?.provider !== 'fabric'
    });

    const isFabric = environment?.provider === 'fabric';
    const isCorda = environment?.isCorda
    const loading = channelsLoading || wrapperLoading;
    const fireflyQuickStartMode = environment?.optional_features?.firefly && nodes.length === 0;

    // Setup the consortium wrapper subscriptions
    useSubscription(NodesSubscription, MakeNodesSubscriptionOptions(environmentResourcesVars));
    useSubscription(ServicesSubscription, MakeServicesSubscriptionOptions(environmentResourcesVars));
    useSubscription(EnvironmentZonesSubscription, MakeEnvironmentZonesSubscriptionOptions(environmentResourcesVars));
    useSubscription(AppCredsSubscription, MakeAppCredsSubscriptionOptions(environmentResourcesVars));
    useSubscription(ChannelsSubscription, MakeChannelsSubscriptionOptions(environmentResourcesVars, {skip: environment?.provider !== 'fabric'}));

    // tell AppWrapper our environment queries have completed
    const hasSetEnvironmentQueriesCompleteRef = useRef<boolean>(false)
    useEffect(() => {
        if (environment?._id && !hasSetEnvironmentQueriesCompleteRef.current && !loading) {
            hasSetEnvironmentQueriesCompleteRef.current = true
            setEnvironmentQueriesComplete(true)
        }
    }, [loading, setEnvironmentQueriesComplete, environment?._id])

    // check that the cached nodes and services are actually valid for the current query variables.
    // this occurs because on cache-and-network, during the inital query with a new variable set,
    // the results from the previous query (with the old variables) are returned in the result.
    if (loading) {
        const isStale = (consortiaId: string, environmentId: string) => {
            return consortiaId !== environmentResourcesVars.consortia_id || 
                environmentId !== environmentResourcesVars.environment_id
        }
        if (!nodes.length || 
            !services.length || 
            nodes.some(s => isStale(s.consortia_id, s.environment_id)) ||
            services.some(s => isStale(s.consortia_id, s.environment_id))) {
            return (
                <ListItem>
                    <CircularProgress />
                </ListItem>
            )
        }
    }

    return (
        <>
            <NavItem icon={<ViewDashboardOutlineIcon />} 
                header={lt('dashboard')} 
                action={() => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/environments/${environment_id}`)} 
                itemIsActive={pathname.endsWith(environment_id)} 
            />

            { !fireflyQuickStartMode && 
                <><AddressBook {...{environment_id}} selected={addressBook} setSelected={setAddressBook} />
                {!isFabric && <DataExplorer {...{environment_id}} selected={dataExplorer} setSelected={setDataExplorer} />}
                {isFabric &&<NavItem icon={<ArrowDecisionOutlineIcon />} 
                    header={lt('channels')} 
                    action={() => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/environments/${environment_id}/${CHANNELS_PATH}`)} 
                    itemIsActive={pathname.endsWith(`${environment_id}/${CHANNELS_PATH}`)} 
                />}
                <HealthMonitoring {...{environment_id}} selected={healthMonitoring} setSelected={setHealthMonitoring}/></>
            }

                <NavItem icon={<CogOutlineIcon />} 
                    header={lt('settings')} 
                    action={() => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/environments/${environment_id}/${ENVIRONMENT_SETTINGS_PATH}`)} 
                    itemIsActive={pathname.endsWith(`${environment_id}/${ENVIRONMENT_SETTINGS_PATH}`)} 
                />

            { !fireflyQuickStartMode && 
                <><ListItem>
                    <ListItemText secondary={lt('myResources')} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>

                {!isCorda && !(isFabric && !featureToggles?.fabricFirefly) && <Firefly {...{environment_id}} selected={firefly} setSelected={setFirefly} />}
                <Blockchain {...{environment_id}} selected={blockchain} setSelected={setBlockchain} />
                <AppsIntegrations {...{environment_id}} selected={apps} setSelected={setApps} />
                <Security {...{environment_id}} selected={security} setSelected={setSecurity} />
                <B2bCommunication {...{environment_id}} selected={b2b} setSelected={setB2b} />
                {!isFabric && <DigitalAssets {...{environment_id}} selected={assets} setSelected={setAssets} />}
                {!isFabric && featureToggles?.keyManagement && <KeyManagement {...{environment_id}} selected={keyManagement} setSelected={setKeyManagement} />}
                <CloudConfigs {...{environment_id}} selected={cloudConfigs} setSelected={setCloudConfigs} /></>}
        </>
    )
};

interface translations {
    dashboard: string
    channels: string
    settings: string
    myResources: string
}
const enTranslations: translations = {
    dashboard: 'Dashboard',
    channels: 'Channels',
    settings: 'Settings',
    myResources: 'Manage Resources'
}
