import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { CreateNodeAccountVars, CreateNodeAccountMutation, CreateNodeAccountData,
    CreateEthwalletAccountVars, CreateEthwalletAccountMutation, CreateEthwalletAccountData } from '../../models'
import { FormDialog, MessageSnackbar } from '../../components/DialogWrappers'

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onAccountCreated: (createdAddress: string) => void
}

export const CreateAccount = ({ open, setOpen, onAccountCreated }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'WalletCreateAccount', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`WalletCreateAccount:${key}`, interpolate)

    const { consortium_id, environment_id, node_id, service_id } = useParams<any>();

    const [message, setMessage] = useState('');

    const [createNodeAccount, { loading: createNodeAccountLoading }] = 
        useMutation<CreateNodeAccountData, CreateNodeAccountVars>(CreateNodeAccountMutation)
    const [createEthwalletAccount, { loading: createEthwalletAccountLoading }] = 
        useMutation<CreateEthwalletAccountData, CreateEthwalletAccountVars>(CreateEthwalletAccountMutation)

    const updater = () => node_id ? 
        createNodeAccount({
            variables: {
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                node_id: node_id!
            }
        }).then((d) => {
            onAccountCreated(d.data?.createNodeAccount ?? '')
            setMessage(lt('accountCreated', {address: d.data?.createNodeAccount ?? ''}))
        }) :
        createEthwalletAccount({
            variables: {
                service_id: service_id!
            }
        }).then((d) => {
            onAccountCreated(d.data?.createEthwalletAccount ?? '')
            setMessage(lt('accountCreated', {address: d.data?.createEthwalletAccount ?? ''}))
        })

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} messageType="success" />
            <FormDialog 
                {...{open}} 
                {...{setOpen}} 
                header={lt('createAccount')}
                description={lt('createAccountDescription')} 
                saveDisabled={createNodeAccountLoading || createEthwalletAccountLoading}
                onSave={updater}
                closeDialogAfterSave
                saveText={lt('create')} />
        </>
    )
};

interface translations {
    createAccount: string,
    createAccountDescription: string,
    create: string,
    accountCreated: string
}
const enTranslations: translations = {
    createAccount: 'Create a new account?',
    createAccountDescription: 'This will create a new account in this wallet.',
    create: 'Create',
    accountCreated: 'New account created: {{address}}'
}