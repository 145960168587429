import { gql } from '@apollo/client';
import { HardforkEIPs } from './environmentStatus';

type ChainConfigEIPs = "homesteadBlock" | "byzantiumBlock" | "eip150Block" | "eip155Block" | "eip158Block"| "petersburgBlock" | "constantinopleBlock" | "constantinopleFixBlock" | "istanbulBlock" | "muirGlacierBlock" | "berlinBlock"

// INTERFACES

// this should be an autogenerated interface from the Release model in graphql
export interface Release {
    _id: string
    provider: string
    version: string
    release_status: [string]
    version_padded: string
    description: string
    chain_config: ChainConfigEIPs[],
    optional_eips?: HardforkEIPs[],
    prereq_eips?: HardforkEIPs[]
    supported_features?: ReleaseSupportedFeatures
}

export interface ReleasesVars {
    provider: string,
    limit: number
}

export interface ReleaseVars {
    id: string
}

export interface ReleasesData {
    releases: Release[]
}

export interface ReleaseData {
    release: Release
}

export interface ReleaseSupportedFeatures {
    fireflyVersion?: string
    cordaJDK8?: boolean
    envBackup?: boolean
}
// FRAGMENTS

export const ReleaseFields = ` 
    fragment ReleaseFields on Release {
        _id
        provider
        images
        version
        release_status
        version_padded
        description
        chain_config
        optional_chain_config
        optional_eips
        prereq_eips
        supported_features
    }`

// QUERIES & SUBSCRIPTIONS

export const ReleasesQuery = gql`
    ${ReleaseFields}
    query getReleases($provider: String!, $limit: Int!) {
        releases(provider: $provider, limit: $limit) {
            ...ReleaseFields
        }
    }`;

export const ReleaseQuery = gql`
    ${ReleaseFields}
    query getRelease($id: String!) {
        release(id: $id) {
            ...ReleaseFields
        }
    }`;
