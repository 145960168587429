import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Ether-Pool.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/kaleido-services/ether-pool/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Ether Pool',
    helpDescription: 'Each Kaleido environment is configured with one billion ether in the chain’s genesis block. It can be used to enforce optional gas costs on specific smart contract methods and/or to represent a fungible and transactional asset.',
    helpDesc1: `The ether pool is an environmentally-shared utility service and is accessible by any organization.`,
    helpDesc2: 'The governance of the pool is left entirely to the discretion of the consortium.',
    helpDesc3: 'The pool offers a convenient alternative to developing your own ERC20 token.',
}