
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, ClientMetadata } from 'amazon-cognito-identity-js';
import { SessionData, Session, Region } from '../interfaces';
import { ApolloClient } from '@apollo/client';
import { SessionQuery } from '../queries/Session';

function getCognitoUserPools(regions: Region[]) {
  return regions.filter(region => !region.redirectLoginTo).map(region => new CognitoUserPool({
    UserPoolId: region.cognitoUserPool,
    ClientId: region.cognitoClientID
  }))
}

interface CognitoResult { err: Error | null | undefined, result: any }
interface PreAuthData { totpToken?: string,  recoveryCode?: string}

// Common function to perform global actions against Cognito
async function globalCognitoUserAction(
    regions: Region[],
    email: string,
    action: (user: CognitoUser, done: (err: Error | null | undefined, result: any) => void) => void
  ) {
    let userPools = getCognitoUserPools(regions);

    // Run the action against the local region first
    let firstRegionPool = userPools.shift() as CognitoUserPool;
    let results = [] as CognitoResult[];
    let firstResult = (await new Promise((resolve) => 
      action(new CognitoUser({
        Username: email,
        Pool: firstRegionPool
      }), (err, result) => { resolve({err,result}); })
    )) as CognitoResult;
    results.push(firstResult as CognitoResult);
    
    // If the local region failed, run the action against all remote regions
    if (firstResult.err) {
      const promises = [];
      for (let remoteRegionUserPool of getCognitoUserPools(regions)) {
        promises.push(new Promise((resolve) => 
          action(new CognitoUser({
            Username: email,
            Pool: remoteRegionUserPool
          }), (err, result) => { resolve({err,result}); })
        ));
      }
      results = results.concat(await Promise.all(promises) as CognitoResult[]);
    }

    let success = results.find(result => !result.err && result.result);
    if (success) return success.result;
  
    // We've had an error, throw the first error.
    // Prefer not to return "UserNotFoundException", as that could be simply be the wrong region
    let failure = results.find(result => result.err && (result.err as any).code !== "UserNotFoundException");
    throw failure ? failure.err : results[0].err;
  
}

export const cognitoController = {
    checkLogin: async function(email: string) { // Perform a global search for this email in Kaleido to see where we should log in from
        // Our backend does a global search, and should swallow errors
        return fetch(`/api/ui/v2/uinfo/${encodeURIComponent(email)}`)
            .then(r => r.json())
            .catch(err => {
                console.error(`User check failed`, err);
                throw new Error("Unable to connect to Kaleido");
            });
    },
    verifySignup: async function (               
        verificationCode: string, 
        email: string,
        password: string,
        apolloClient: ApolloClient<object>,
        regions: Region[]
    ){
        await globalCognitoUserAction(regions, email.toLowerCase(), (cognitoUser, done) => {
          cognitoUser.confirmRegistration(
            verificationCode, 
            true, 
            done);
        });
        await this.login(
            email.toLowerCase(), 
            password, 
            apolloClient,
            regions
        );
    },
    createAccount: async function (
        email: string,
        password: string,
        region: Region,
        recaptcha: string,
    ) {

        let cognitoUserPool = new CognitoUserPool({
            UserPoolId: region.cognitoUserPool,
            ClientId: region.cognitoClientID,
        }); 

        const attributeList: CognitoUserAttribute[]= [];
        const dataEmail = {
            Name : 'email',
            Value : email.toLowerCase()
        };

        const attributeEmail = new CognitoUserAttribute(dataEmail);
        attributeList.push(attributeEmail);        
        return new Promise((resolve, reject) => {
            cognitoUserPool.signUp(
                email.toLowerCase(), 
                password, 
                attributeList, 
                attributeList, 
                async (err, result) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(true);
                    }
                },
                { recaptcha }
            );
        })
    },
    resendCode: async function (
        email: string,
        regions: Region[]
    ) {
        await globalCognitoUserAction(regions, email, (cognitoUser, done) => {
          cognitoUser.resendConfirmationCode(done);
        });
    },
    renewSession: (
        apolloClient: ApolloClient<object>,
        org_id?: string
    ) => {
        return new Promise((resolve, reject) => {
            fetch('/api/ui/v2/login/refresh', {
                method: 'post',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({selected_org: org_id})
            }).then(async response => {
                if (response.ok) {
                    const session = (await response.json()) as Session;
                    apolloClient.writeQuery<SessionData>({ 
                        query: SessionQuery, 
                        data: { session: {...session, selected_org: session.selected_org || ''} }
                    })
                    resolve(session.exp);
                } else {
                    reject(response.statusText);
                }
            });
        });
    },
    login: async function(
        username: string,
        password: string,
        apolloClient: ApolloClient<object>,
        regions: Region[],
        mfaCode?: string
    ) {

        let loginDetails = await this.checkLogin(username);

        if(loginDetails.mfa?.type === 'totp' && !mfaCode) {
            throw new Error('mfaRequired');
        }

        // If we found a redirect to an Azure login or an Enterprise Org, do the redirect.
        if (loginDetails.redirect) {
            window.location = loginDetails.redirect;
            return;
        }
    
        // If we found a case sensitve email address, then login using that.
        let caseCorrectedEmail = loginDetails.email || username;

        let preAuthData:PreAuthData = {};
        if(mfaCode) {
            if(mfaCode.length === 6) {
                preAuthData.totpToken = mfaCode;
            } else {
                preAuthData.recoveryCode = mfaCode;
            }
        }

        let result = await globalCognitoUserAction(regions, caseCorrectedEmail, (cognitoUser, done) => {
          const authenticationDetails = new AuthenticationDetails({
              Username: username,
              Password: password,
              ValidationData: {
                  ...preAuthData
              }
          });
          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: res => done(null, res),
            onFailure: err => done(err, null)
          });
        });

        const accessToken = result.getIdToken();
        const response = await fetch('/api/ui/v2/login/cognito', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                jwt: accessToken.getJwtToken(),
                cognitoRegion: loginDetails.cognitoRegion
            })
        });
        if (response.ok) {
            const session = (await response.json()).user_object as Session;
            await apolloClient.writeQuery<SessionData>({
                query: SessionQuery,
                data: {
                    session: {...session, selected_org: session.selected_org || ''}
                }
            });
        } else {
            let err;
            try {
                err = (await response.json()).error;
            } finally {
                throw new Error(err || response.statusText);
            }
        }

    },
    logout: async (redirect: string) => {
        const logoutResponse = await fetch('/api/ui/v2/logout', {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            }
        });
        const data = await logoutResponse.json();
        window.location.href = data?.redirect || redirect
    },
    forgotPassword: async function (
        email: string,
        regions: Region[],
        recaptcha: string,
    ) {
        await globalCognitoUserAction(regions, email.toLowerCase(), (cognitoUser, done) => {
          cognitoUser.forgotPassword({
            onSuccess: result => done(null, result),
            onFailure: error => done(error, null)
          }, { recaptcha });
        });
    },
    confirmPassword: async function (
        email: string,
        verificationCode: string,
        newPassword: string,
        apolloClient: ApolloClient<object>,
        regions: Region[],
        mfaCode?: string
    ) {
        await globalCognitoUserAction(regions, email.toLowerCase(), (cognitoUser, done) => {
        let clientMetadata:ClientMetadata = {};
        if(mfaCode) {
            if(mfaCode.length === 6) {
                clientMetadata.totpToken = mfaCode;
            } else {
                clientMetadata.recoveryCode = mfaCode;
            }
        }
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess: () => done(null, true),
          onFailure: error => done(error, null),
          
        }, clientMetadata);
      });
      await this.login(email, newPassword, apolloClient, regions, mfaCode);
    },
    changePassword: async function (
        email: string,
        currentPassword: string,
        newPassword: string,
        regions: Region[],
        mfaCode?: string
    ) {
        let loginDetails = await this.checkLogin(email);
        let caseCorrectedEmail = loginDetails.email || email;

        let preAuthData:PreAuthData = {};
        if(mfaCode) {
            if(mfaCode.length === 6) {
                preAuthData.totpToken = mfaCode;
            } else {
                preAuthData.recoveryCode = mfaCode;
            }
        }

        await globalCognitoUserAction(regions, caseCorrectedEmail, (cognitoUser, done) => {
            const authenticationDetails = new AuthenticationDetails({
                Username: caseCorrectedEmail,
                Password: currentPassword,
                ValidationData: {
                    ...preAuthData
                }
            });
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: res => {
                    cognitoUser.changePassword(currentPassword, newPassword, (err) => {
                        err? done(err, null) : done(null, res);
                    })
                },
                onFailure: err => done(err, null)
            });
        });
    }
};

