import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'


interface Props {
    isFabric?: boolean
};

export const Step3Help = ({isFabric} : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep3Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep3Help:${key}`, interpolate)
    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={isFabric ? '' : lt('helpDesc2')}
            bullet3={isFabric ? '' : lt('helpDesc3')}
            docLink="https://docs.kaleido.io/using-kaleido/cloud-integrations/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string
    helpDesc2: string
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Node Configurations',
    helpDescription: 'A configuration profile could be assigned to a node at creation time for these settings to be applied or attached after creation before resetting the node for settings to be applied. There are different type of configurations:',
    helpDesc1: `Integrate the node with Cloud Services which include key management, log streaming, backups and private data routing.`,
    helpDesc2: 'Blockchain Application Firewall (BAF) provides rich options for authentication and authorization of application connections to your Blockchain resources.',
    helpDesc3: 'Protocol Configurations provide the options to modify core node settings such as Gas Price, Garbage Collection Mode, Log Verbosity, Gateway API and others.'
}