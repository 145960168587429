import { useApolloClient, useMutation } from '@apollo/client';
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { Paywall } from '../../components/FormControls/Paywall';
import { RuntimeCreationSelectSizeContent } from '../../components/RuntimeCreation/RuntimeCreationSelectSizeContent';
import { CreateStepProps } from '../../interfaces';
import {
    ConsortiumZone,
    CreateEnvironmentZoneData, CreateEnvironmentZoneMutation, CreateEnvironmentZoneVars, CreateNodeData, CreateNodeMutation, CreateNodeVars,
    EnvironmentConsensus, EnvironmentProvider, EnvironmentZone,
    MakeEnvironmentZoneCreateMutationOptions, MakeNodeCreateMutationOptions,
    NodeConfigs, NodeRole, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, RuntimeSize, CreateServiceData, CreateServiceVars, CreateServiceMutation, FabricCaServiceDetails, 
} from '../../models';
import { Step4Help } from './Step4Help';

interface Props extends CreateStepProps {
    provider?: EnvironmentProvider,
    consensusType?: EnvironmentConsensus,
    name: string
    role: NodeRole
    membershipId: string,
    zoneId: string, //only set if env is multi region
    consortiumZones: ConsortiumZone[],
    privateZones?: EnvironmentZone[],
    environmentZones: EnvironmentZone[],
    nodeConfigs: NodeConfigs,
    createFabricCaName?: string
    fabricCaServiceDetails?: FabricCaServiceDetails
};

export const Step4 = ({ provider, consensusType, name, role, membershipId, zoneId, consortiumZones, privateZones, environmentZones, cancelPath, nodeConfigs, createFabricCaName, fabricCaServiceDetails }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep4', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep4:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const client = useApolloClient();
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!

    const signerNodesAllowed = PlanSupports.signerNodes(organization);

    const [message, setMessage] = useState('');
    const [size, setSize] = useState<RuntimeSize>('small');
    const [signer, setSigner] = useState(signerNodesAllowed);

    const isFabric = provider === 'fabric'
    const isCorda = provider?.includes('corda');
    const isRaft = consensusType === 'raft'

    const consortiumZone = consortiumZones.find(z => z._id === zoneId)
    const privateZone = privateZones?.find(z => z._id === zoneId);
    const environmentZone = privateZone || environmentZones.find(z => 
        (
            z.region === consortiumZone?.region &&
            z.type === consortiumZone?.type &&
            z.cloud === consortiumZone?.cloud
        ))

    const [createNode, { loading: createNodeLoading }] = 
        useMutation<CreateNodeData, CreateNodeVars>(CreateNodeMutation)
    const [createEnvironmentZone, { loading: createZoneLoading }] = 
        useMutation<CreateEnvironmentZoneData, CreateEnvironmentZoneVars>(CreateEnvironmentZoneMutation)
    const [createService, { loading: creatingCA }] = 
        useMutation<CreateServiceData, CreateServiceVars>(CreateServiceMutation);

    const save = async () => {
        let envZoneId = environmentZone?._id
        let isPrivateStack = envZoneId && privateZones?.find(z => z._id === envZoneId);

        // create the environment zone if multi region and the selected region doesnt exist
        if (zoneId && !envZoneId) {
            const { region, cloud, type } = consortiumZones.find(c => c._id === zoneId)!
            const envZone = await createEnvironmentZone(MakeEnvironmentZoneCreateMutationOptions({
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                environmentZone: {
                    region,
                    cloud,
                    type
                }
            })).catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
                throw e
            })

            envZoneId = envZone?.data?.createEnvironmentZone?._id
        }

        // if its the first fabric node for this membership, we have to create the CA
        if (isFabric && createFabricCaName) {
            await createService({
                variables: {
                    consortia_id: consortium_id!,
                    environment_id: environment_id!,
                    service: {
                        membership_id: membershipId!,
                        service: 'fabric-ca',
                        name: createFabricCaName,
                        zone_id: envZoneId || '',
                        details: fabricCaServiceDetails
                    }
                }
            }).catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
                throw e
            })
        }

        // create the node
        createNode(MakeNodeCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node: {
                name,
                role,
                membership_id: membershipId,
                size,
                init_consensus_role: (isRaft || (signer && !isPrivateStack)) ? 'signer' : 'non-signer',
                zone_id: envZoneId || '',
                ...nodeConfigs
            }
        })).then(result => {
            if (result) {
                const newNodeId = result.data?.createNode?._id
                if (newNodeId) {
                    history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${newNodeId}`)
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {privateZone ? lt('privateHeader') : lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {isCorda ? lt('cordaDescription') : privateZone ? lt('privateDescription') : lt('headerDescription')}
                </Typography>
            </Grid>

            {!privateZone && <Grid item>
                <Typography variant="h5">
                    {lt('size')}
                </Typography>
                
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('sizeDescription')}
                </Typography>
                
                <RuntimeCreationSelectSizeContent {...{size}} {...{setSize}} hideHeaderAndDescription />
            </Grid>}

            {!isCorda && !privateZone && !isRaft && 
            <Grid item>
                <Typography variant="h5">
                    {lt('role')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('roleDescription')}
                </Typography>
                {!signerNodesAllowed &&
                <Paywall description={lt('paywall')} />}
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={signer}
                        onChange={() => setSigner(!signer)}
                        value={signer}
                        color="primary"
                        disabled={!signerNodesAllowed}
                    />
                    }
                    label={lt('makeSigner')}
                />
            </Grid>
            }
        </>
    )

    const disabled = createNodeLoading || creatingCA || createZoneLoading

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} isLastStep />
            <Step4Help {...{provider}} />
        </>
    )
};

interface translations {
    header: string,
    privateHeader: string,
    headerDescription: string,
    cordaDescription: string,
    privateDescription: string,
    size: string,
    sizeDescription: string,
    role: string,
    roleDescription: string,
    makeSigner: string,
    paywall: string,
}
const enTranslations: translations = {
    header: 'Configure Your Node',
    privateHeader: 'Configure your self-managed node',
    headerDescription: 'Configure the size of your node and whether or not it will be a signer.',
    cordaDescription: 'Configure the size of your node',
    privateDescription: 'Get ready to connect your node into Kaleido',
    size: 'Node size',
    sizeDescription: 'Select the level of resources available to your node.',
    role: 'Role in Consensus',
    roleDescription: 'Select if you want to allow this node to propose and vote on blocks.',
    makeSigner: 'Make this node a signer',
    paywall: 'Your current plan does not support signer nodes',
}