import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, makeStyles, FormControl, InputLabel, Select } from "@material-ui/core";
import Highcharts from 'highcharts';
import { BaseChartOptions } from '../../utils/ChartOptions'
import { ServicesEnum, ServicesTranslations, EnServicesTranslations } from '../../models';

const MENU_PROPS = { getContentAnchorEl: null }

export type RuntimeType = 'node' | 'all' | keyof typeof ServicesEnum

interface Props {
    runtimeType: RuntimeType,
    setRuntimeType: React.Dispatch<React.SetStateAction<RuntimeType>>,
    servicesToInclude: (keyof typeof ServicesEnum)[]
    disabled?: boolean
};

export const RuntimeTypeSelector = ({ runtimeType, setRuntimeType, servicesToInclude, disabled }: Props) => {
    const classes = useStyles();

    Highcharts.setOptions(BaseChartOptions);

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthRuntimeTypeSelector', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`EnvironmentHealthRuntimeTypeSelector:${key}`, interpolate), [t])

    // unfortunately cant use TextField with multiple select so need this ref business here
    const runtimeInputLabel = useRef<HTMLLabelElement>(null);
    const [runtimeLabelWidth, setRuntimeLabelWidth] = useState(0);
    useEffect(() => {
        if (runtimeInputLabel.current)
        setRuntimeLabelWidth(runtimeInputLabel.current.offsetWidth);
    }, [runtimeInputLabel])
                
    return (
        <FormControl variant="outlined" className={classes.padDropdown}>
            <InputLabel ref={runtimeInputLabel}>
                {lt('runtimeType')}
            </InputLabel>
            <Select
                labelWidth={runtimeLabelWidth}
                disabled={disabled}
                value={runtimeType}
                onChange={e => setRuntimeType(e.target.value as RuntimeType)}
                MenuProps={MENU_PROPS}> 
                <MenuItem value={'all'}>{lt('all')}</MenuItem>
                <MenuItem value={'node'}>{lt('node')}</MenuItem>
                {[ ...new Set(servicesToInclude) ].map(s => <MenuItem key={s} value={s}>{lt(ServicesEnum[s])}</MenuItem>)}
            </Select>
        </FormControl>
    )
};

const useStyles = makeStyles(theme => ({
    padDropdown: {
        minWidth: '125px',
        paddingRight: theme.spacing(2)
    }
}));

interface translations extends ServicesTranslations {
    runtimeType: string,
    all: string,
    node: string,
    other: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    runtimeType: 'Types',
    all: 'All',
    node: 'Node',
    other: 'Other'
}