import React from 'react';
import { Typography, Grid, Button, Container, makeStyles } from "@material-ui/core";
import { FormLink } from '../FormControls/FormLink';

interface Props {
    imageFile: string
    title: string
    description: string
    button?: { text: string, onClick: () => void, disabled?: boolean }
    documentation?: { text: string, link: string }
    isHorizontal?: boolean
};

export const EmptyState = ({ imageFile, title, description, button, documentation, isHorizontal }: Props) => {
    const classes = useStyles();
    const textView = <>
        <Grid item>
            <Typography variant="h5">
                {title}
            </Typography>
        </Grid>

        <Grid item xs={isHorizontal ? 'auto' : 8}>
            <Typography align={isHorizontal ? 'inherit' : 'center'} variant="body2">
                {description}
            </Typography>
        </Grid>

        {button &&
            <Grid item>
                <Button data-test="button_emptyCreate" disabled={button.disabled} color="primary" size="large" variant="contained" onClick={button.onClick}>
                    {button.text}
                </Button>
            </Grid>}

        {documentation &&
            <Grid item>
                <FormLink to={documentation.link} isExternal={true} target="_blank">
                    {documentation.text}
                </FormLink>
            </Grid>
        }
    </>

    return (
        <Container>
            <Grid container direction={isHorizontal ? 'row' : 'column'} justifyContent="center" alignItems="center" spacing={3}>
                {isHorizontal ?
                    <Grid container item direction="column" md={6} xs={12} alignItems="center" justifyContent="center" spacing={2} className={classes.topBottomImgMargin}>
                        <img src={`${process.env.PUBLIC_URL}/img/EmptyStates/${imageFile}`} alt=""></img>
                    </Grid>
                    :
                    <Grid item>
                        <img src={`${process.env.PUBLIC_URL}/img/EmptyStates/${imageFile}`} alt=""></img>
                    </Grid>
                }
                {isHorizontal ?
                    <Grid container item direction="column" md={6} xs={12} alignItems="flex-start" justifyContent="center" spacing={2}>
                        {textView}
                    </Grid>
                    :
                    <>
                        {textView}
                    </>
                }
            </Grid>
        </Container>
    )
};

const useStyles = makeStyles(theme => ({
    topBottomImgMargin: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3)
    }
}));
