import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { NETWORK_GOVERNANCE_MEMBERSHIPS_PATH, NETWORK_GOVERNANCE_PATH } from '../../../components/MainNav/SideNavs/Governance';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GovernanceInviteOrganizationCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceInviteOrganizationCreate:${key}`, interpolate)

    type locationState = { orgName: string, email: string }
    const history = useHistory<locationState>()
    const { pathname } = useLocation()
    const { org_id, consortium_id, step } = useParams<any>();

    const invalidStep = step !== "1" && step !== "2" && step !== "3"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    const { 
        location: { 
            state: {
                orgName: hOrgName,
                email: hEmail,
            } = { orgName: '', email: '' }
        } 
    } = history;

    const [orgName, setOrgName] = useState(hOrgName)
    const [email, setEmail] = useState(hEmail)
    
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}`

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    if (invalidStep || createStep > 2 || (createStep === 2 && (!email || !orgName))) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: lt('intro'), component: <Step1 {...{orgName}} {...{email}} {...{cancelPath}} /> },
        { step: lt('contactInfo'), component: <Step2 {...{orgName}} {...{setOrgName}} {...{email}} {...{setEmail}} {...{cancelPath}} /> },
        { step: lt('permissions'), component: <Step3 {...{orgName}} {...{email}} {...{cancelPath}} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('inviteOrganizationToNetwork')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    inviteOrganizationToNetwork: string,
    intro: string
    contactInfo: string
    permissions: string
}
const enTranslations: translations = {
    inviteOrganizationToNetwork: 'Invite Organization to Network',
    intro: 'Invitation Info',
    contactInfo: 'Contact Info',
    permissions: 'Permissions'
}