import React from 'react';
import { makeStyles, Grid, Typography, TextField, Paper, Switch, IconButton, Divider, Button, FormControlLabel } from '@material-ui/core';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { BAFPolicyClaimMatcherState } from './Step1';
import { useTranslation } from 'react-i18next';

export const ClaimMatcher = ({ claimMatchers, setClaimMatchers, parentKey, appCredsOrJWT, newMatcher } : {
    claimMatchers: BAFPolicyClaimMatcherState[],
    setClaimMatchers: (newMatchers: BAFPolicyClaimMatcherState[]) => void,
    parentKey: string,
    newMatcher: () => BAFPolicyClaimMatcherState,
    appCredsOrJWT: 'appcreds' | 'jwt',
}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFMappingEditorClaimMatcher', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFMappingEditorClaimMatcher:${key}`, interpolate)

    const classes = useStyles();

    const setPropName = (i: number, propName: string) => {
        let newClaimMatchers = [...claimMatchers];
        newClaimMatchers[i] = {
            ...claimMatchers[i],
            propName,
        }
        setClaimMatchers(newClaimMatchers);
    }

    const setMatcherString = (i: number, stringValue: string) => {
        let newClaimMatchers = [...claimMatchers];
        newClaimMatchers[i] = {
            ...claimMatchers[i],
            stringValue,
        }
        setClaimMatchers(newClaimMatchers);
    }

    const setMatcherIsNested = (i: number, isNested: boolean) => {
        let newClaimMatchers = [...claimMatchers];
        if (isNested) {
            newClaimMatchers[i] = {
                ...claimMatchers[i],
                nested: true,
                nestedValue: claimMatchers[i].nestedValue || [{propName: '', stringValue: '', nested: false}],
            }            
        } else {
            newClaimMatchers[i] = {
                ...claimMatchers[i],
                nested: false,
            }    
        }
        setClaimMatchers(newClaimMatchers);
    }

    const setMatcherNested = (i: number, nestedValue: BAFPolicyClaimMatcherState[]) => {
        let newClaimMatchers = [...claimMatchers];
        newClaimMatchers[i] = {
            ...claimMatchers[i],
            nestedValue,
        }            
        setClaimMatchers(newClaimMatchers);
    }

    const addMatcher = () => {
        let newClaimMatchers = [...claimMatchers];
        newClaimMatchers.push(newMatcher())
        setClaimMatchers(newClaimMatchers);
    }

    const deleteMatcher = (i: number) => {
        let newClaimMatchers = claimMatchers.slice(0, i).concat(claimMatchers.slice(i+1));
        setClaimMatchers(newClaimMatchers);
    }

    const keysUse = {} as {[propName: string]: number}
    for (let m of claimMatchers) {
        keysUse[m.propName] = keysUse[m.propName] || 0;
        keysUse[m.propName]++;
    }

    return <Grid item xs={12}>
        <Paper>
            {claimMatchers.map((c,i) => <React.Fragment key={`${parentKey}-${i}`}>
                {appCredsOrJWT === 'appcreds'
                ? // App creds is a single line, and fixed to the 'id' property
                    <Grid item container spacing={2} justify="space-between" className={classes.innerPadding} alignItems="center">
                        <Grid item xs>
                            <Typography>{lt('id')}</Typography>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                data-test="textfield_appCredID"
                                fullWidth
                                label={lt('regex')}
                                value={c.stringValue}
                                variant="outlined"
                                onChange={event => setMatcherString(i, event.target.value)}/>
                        </Grid>
                    </Grid>
                : /* JWT is a potentially nested list of unique property name/value combinations */ <>
                    <Grid item container spacing={2} justify="space-between" className={classes.innerPadding} alignItems="center">
                        <Grid item xs>
                            <TextField
                                fullWidth
                                data-test={`${parentKey}-${i}-propname`}
                                label={lt('propName')}
                                value={c.propName}
                                variant="outlined"
                                onChange={event => setPropName(i, event.target.value)}
                                error={keysUse[c.propName] > 1}/>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch 
                                        data-test={`${parentKey}-${i}-nested`}
                                        color="primary"
                                        onChange={event => setMatcherIsNested(i, event.target.checked)}
                                        checked={c.nested}/>
                                }
                                label={lt('nested')}
                                labelPlacement="top"
                                />
                        </Grid>
                        {claimMatchers.length >= 2 && <Grid item>
                                <IconButton aria-label="delete" color="inherit" onClick={() => deleteMatcher(i)}>
                                    <DeleteIcon />
                                </IconButton>
                        </Grid>}                    
                    </Grid>
                    <Grid item container spacing={2} justify="space-between" className={classes.innerPadding} alignItems="center">
                        <Grid item xs>
                            {c.nested
                            ? <ClaimMatcher
                                parentKey={`${parentKey}-${i}`}
                                claimMatchers={c.nestedValue!}
                                setClaimMatchers={newMatchers => setMatcherNested(i, newMatchers)}
                                {...{appCredsOrJWT}}
                                {...{newMatcher}}
                                {...{lt}} />
                            : <TextField
                                fullWidth
                                data-test={`${parentKey}-${i}-value`}
                                label={lt('regex')}
                                value={c.stringValue}
                                variant="outlined"
                                onChange={event => setMatcherString(i, event.target.value)}/>
                            }
                        </Grid>
                    </Grid>
                </> }
                <Grid item>
                    <Divider />
                </Grid>
            </React.Fragment>)}
            {appCredsOrJWT === 'jwt' && <Grid item container spacing={2} justify="space-between" className={classes.innerPadding} alignItems="center">
                <Grid item xs>
                    <Button
                        variant="text"
                        color="primary"
                        startIcon={<PlusIcon />}
                        onClick={() => addMatcher()}
                    >{lt('addClaimMatcher')}</Button>

                </Grid>
            </Grid> }
        </Paper>
    </Grid>;
}

interface translations {
    addClaimMatcher: string,
    propName: string,
    regex: string,
    nested: string,
    id: string,
}
const enTranslations: translations = {
    addClaimMatcher: "Add Claim Matcher",
    propName: "Property Name",
    regex: "Case-insensitive regex",
    nested: "Nested",
    id: "Application Credential ID",
}

const useStyles = makeStyles(theme => ({
    innerPadding: {
        padding: theme.spacing(2),
    }
}));