import { gql } from '@apollo/client';
import { ConsortiumFields, OrganizationFields, ConsortiaData, OrganizationsData, UserMembershipsData, MembershipFields } from '../models'

export const AppWrapperQuery = gql`
    ${ConsortiumFields}
    ${OrganizationFields}
    ${MembershipFields}

    query AppWrapperQuery {
        consortia {
            ...ConsortiumFields
        }
        organizations {
            ...OrganizationFields
        }
        memberships {
            ...MembershipFields
        }
    }`;

export interface AppWrapperResult extends ConsortiaData, OrganizationsData, UserMembershipsData {}