import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    buttonDisabled: boolean;
    buttonFn: () => void;
    dashboardMode?: boolean;
}

export const PublicChainConnectivity = ({
    buttonDisabled,
    buttonFn,
    dashboardMode = false,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'PublicChainConnectivity', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`PublicChainConnectivity:${key}`, interpolate);
    const classes = useStyles();

    const requestAccessButton = (
        <Button
            data-test="button_submit_request_access"
            variant="outlined"
            color="primary"
            size="medium"
            onClick={buttonFn}
            disabled={buttonDisabled}
        >
            {lt('requestAccess')}
        </Button>
    );

    const publicChainEntry = (title: string, imagePath?: string, noBorder: boolean = false) => {
        return (
            <>
                <Grid
                    container
                    item
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    style={{textAlign: 'center', borderRight:noBorder ? 0: '1px solid #e0e0e0'}}
                    xs={3}
                >
                    {imagePath && (
                        <Grid item>
                            <img
                                src={`${process.env.PUBLIC_URL}/img/AssetPlatform/${imagePath}.svg`}
                                alt=""
                                style={{
                                    width: dashboardMode ? '30px' : 'inherit',
                                }}
                            ></img>
                        </Grid>
                    )}
                    <Grid item direction="column">
                        <Grid item>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };
    const paperContent = (
        <Grid container direction="row">
            {publicChainEntry(lt('ethereum'), 'ethereum-icon')}
            {publicChainEntry(lt('polygon'), 'polygon-icon-blue')}
            {publicChainEntry(lt('avalanche'), 'avalanche-icon')}
            {publicChainEntry(lt('andMore'), undefined, true)}
        </Grid>
    );

    return (
        <>
            <Grid item direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {lt('publicChainConnectivity')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="textSecondary">
                        {lt('publicChainConnectivityDescription')}
                    </Typography>
                </Grid>
            </Grid>

            <Paper className={classes.container}>{paperContent}</Paper>
            <Grid container justifyContent="flex-end">
                {requestAccessButton}
            </Grid>
        </>
    );
};
interface translations {
    publicChainConnectivity: string;
    publicChainConnectivityDescription: string;
    requestAccess: string;
    ethereum: string;
    polygon: string;
    avalanche: string;
    andMore: string;
}
const enTranslations: translations = {
    publicChainConnectivity: 'Public Chain Connectivity',
    publicChainConnectivityDescription:
        "Kaleido's dedicated web3 solutions allow you to easily leverage public blockchains for your projects.",
    requestAccess: 'Request Access',
    ethereum: 'Ethereum',
    polygon: 'Polygon',
    avalanche: 'Avalanche',
    andMore: 'And more!',
};
const useStyles = makeStyles((theme) => ({
    container: {
        margin: '20px 0',
        padding: '10px',
        flexDirection: 'row',
    },
    divider: {
        width: '100%',
        marginLeft: 0,
    },
    feature: {
        margin: '10px 0',
    },
    featureDashboardMode: {
        margin: '5px 0',
    },
    newTag: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: '2px 6px',
        letterSpacing: '1px',
        borderRadius: '3px',
        textTransform: 'uppercase',
        fontSize: 'small',
        fontWeight: 'bold',
        marginLeft: '8px',
    },
}));
