import { gql, MutationFunctionOptions } from '@apollo/client';

export interface PlatformUpdate {
    title: string,
    markdown: string,
    publish_date: string,
    doc_link: string,
    image_link: string,
    tags: string[],
    seen: boolean,   
}

export interface PlatformUpdatesVars {
    tags: string[],
    skip: number,
    limit: number,
}

export interface PlatformUpdatesData {
    platformUpdates: PlatformUpdate[]
}

export interface MarkPlatformUpdatesViewedVars {
    upToDate: string
}

export interface MarkPlatformUpdatesViewedData {
    markPlatformUpdatesViewed: boolean
}

export const PlatformUpdateFields = ` 
    fragment PlatformUpdateFields on PlatformUpdate {
        title
        markdown
        publish_date
        doc_link
        image_link
        tags
        seen
    }`

export const PlatformUpdatesQuery = gql`
    ${PlatformUpdateFields}
    query getPlatformUpdates($tags: [String]!, $skip: Int!, $limit: Int!) {
        platformUpdates(tags: $tags, skip: $skip, limit: $limit) {
            ...PlatformUpdateFields
        }
    }`;

export const MarkPlatformUpdatesViewedMutation = gql`
    mutation markPlatformUpdatesViewed($upToDate: String!) {
        markPlatformUpdatesViewed(upToDate: $upToDate)
}`;

export function MakeMarkPlatformUpdatesViewedMutationOptions(
    origQuery: PlatformUpdatesVars,
    variables: MarkPlatformUpdatesViewedVars,
    overrideOptions?: MutationFunctionOptions<MarkPlatformUpdatesViewedData, MarkPlatformUpdatesViewedVars>): MutationFunctionOptions<MarkPlatformUpdatesViewedData, MarkPlatformUpdatesViewedVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const ok = data?.markPlatformUpdatesViewed
            if (ok) {
                // Mark all updates as seen in our cache
                const { platformUpdates } = cache.readQuery<PlatformUpdatesData>({ query: PlatformUpdatesQuery, variables: origQuery })!;
                let seenDate = new Date(variables.upToDate).valueOf();
                let newPlatformUpdates = [];
                for (let platformUpdate of platformUpdates) {
                    newPlatformUpdates.push({
                        ...platformUpdate,
                        seen: (seenDate >= new Date(platformUpdate.publish_date).valueOf())
                    });
                }
                cache.writeQuery({
                    variables: origQuery,
                    query: PlatformUpdatesQuery,
                    data: { platformUpdates: newPlatformUpdates },
                });
            }
        }
    }, ...overrideOptions }
}