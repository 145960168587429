import { gql } from '@apollo/client';

// INTERFACES

export interface PlanCompliance {
    preview: ComplianceDetails
    starter: ComplianceDetails
    team: ComplianceDetails
    business: ComplianceDetails
    enterprise: ComplianceDetails
    partner: ComplianceDetails
    partner_pref: ComplianceDetails
    signup: ComplianceDetails
}

export interface ComplianceDetails {
    compliant: boolean
    consortia: {
        count: number
    }
    decentralizedConsortia: {
        consortia: string[]
    }
    environments: {
        consortia_id: string
        count: number
    }[] 
    lockedEnvironments: {
        consortia_id: string
        environment_ids: string[]
    }[]
    services: {
        environment_id: string
        count: number
    }[]
    appcreds: {
        environment_id: string
        count: number
    }[]
    nodes: {
        size: {
            medium: string[]
            large: string[]
        }
        count: {
            environment_id: string
            count: number
        }[]
    }
    configurations: {
        kms: string[]
        opsmetric: string[]
        networking: string[]
        backup: string[]
        storage: string[]
        cloudhsm: string[]
        node_config: string[]
        ethconnect: string[]
        baf: string[]
    }
    providers: {
        geth: string[]
        quorum: string[]
        pantheon: string[]
        corda: string[]
    }
    roles: {
        count: number
    }
    enforce_mfa: boolean
}

export interface PlanComplianceData {
    planCompliance: PlanCompliance
}

export const ComplianceDetailsFields = ` 
    fragment ComplianceDetailsFields on ComplianceDetails {
        compliant
        consortia {
            count
        }
        decentralizedConsortia {
            consortia
        }
        environments {
            consortia_id
            count
        }
        lockedEnvironments {
            consortia_id
            environment_ids
        }
        services {
            count
            environment_id
        }
        appcreds {
            count
            environment_id
        }
        nodes {
            size {
                medium
                large
            }
            count {
                environment_id
                count
            }
        }
        configurations {
            backup
            cloudhsm
            ethconnect
            kms
            networking
            node_config
            opsmetric
            storage
            baf
        }
        providers {
            corda
            quorum
            pantheon
            geth
        }
        roles {
            count
        }
        enforce_mfa
    }`

// QUERIES & SUBSCRIPTIONS

export const PlanComplianceQuery = gql`
    ${ComplianceDetailsFields}
    query getPlanCompliance($org_id: String!) {
        planCompliance(org_id: $org_id) {
            starter {
                ...ComplianceDetailsFields
            }
            team {
                ...ComplianceDetailsFields
            }
            business {
                ...ComplianceDetailsFields
            }
            enterprise {
                ...ComplianceDetailsFields
            }
        }
    }`;
