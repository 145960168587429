import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { ORGS_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import StripeCountrySelector from '../../components/StripeCountrySelector/StripeCountrySelector';
import { CreateStepProps } from "../../interfaces";
import { PlanName } from '../../models';
import { BillingProviderData, BillingProviderQuery, CreateSetupIntentData, CreateSetupIntentMutation, CreateSetupIntentVars, MakeUpdateBillingCreateMutationOptions, UpdateBillingProviderData, UpdateBillingProviderMutation, UpdateBillingProviderVars } from '../../models/billing';

interface Props extends CreateStepProps {
    plan: PlanName
    supportLevel: Number
}

export const Step2 = ({ cancelPath, plan,supportLevel }: Props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SignupBillingStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SignupBillingStep2:${key}`, interpolate);
    const history = useHistory();
    const { org_id } = useParams<any>();
    const stripe = useStripe();
    const elements = useElements();

    const {
        loading,
        data: {
            billingProvider
        } = { billingProvider: { type: 'none'} }
    } = useQuery<BillingProviderData>(BillingProviderQuery, { variables: { org_id } });

    const [updateBillingProvider] = useMutation<
        UpdateBillingProviderData,
        UpdateBillingProviderVars
    >(UpdateBillingProviderMutation);
    const [
        createSetupIntent,
        { loading: createSetupIntentLoading },
    ] = useMutation<CreateSetupIntentData, CreateSetupIntentVars>(
        CreateSetupIntentMutation
    );

    const [cardHolderName, setCardHolderName] = useState('');
    const [country, setCountry] = useState("US");
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [stateOrProvince, setStateOrProvince] = useState('');
    const [zipOrPostalCode, setZipOrPostalCode] = useState('');
    const [cardComplete, setCardComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [processing, setProcessing] = useState(false);
    const [setupIntentSecret, setSetupIntentSecret] = useState("");

    const updateSetupIntent = useCallback(() => {
        createSetupIntent({
            variables: {
                org_id,
            },
        })
            .then((res) => {
                setSetupIntentSecret(
                    res.data?.createSetupIntent.client_secret ?? ""
                );
            })
            .catch((err) => ErrorSnackbarCatcher(err, setErrorMessage));
    }, [createSetupIntent, org_id]);

    useEffect(() => {
        updateSetupIntent();
    }, [updateSetupIntent]);

    useEffect(() => {
        if (billingProvider.type !== 'none') {
            setCardHolderName(billingProvider.name ?? '');
            setCountry(billingProvider.address_country ?? "US");
            setAddressLine1(billingProvider.address_line1 ?? '');
            setAddressLine2(billingProvider.address_line2 ?? '');
            setCity(billingProvider.address_city ?? '');
            setStateOrProvince(billingProvider.address_state ?? '');
            setZipOrPostalCode(billingProvider.address_zip ?? '');
        }
    }, [billingProvider]);

    if (
        loading ||
        (createSetupIntentLoading && !setupIntentSecret) ||
        !(stripe && elements)
    ) {
        return <CircularProgress />;
    }

    const cardElementOptions = {
        style: {
            base: {
                '::placeholder': {
                    color: 'black'
                }
            }
        },
        hidePostalCode: true
    };

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">{lt('enterCreditCardInfo')}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6">{lt('creditCardDetails')}</Typography>
            </Grid>
            <Grid item>
                <TextField data-test="creditCardHoldersName" fullWidth label={lt('cardHolderName')} variant="outlined"
                    value={cardHolderName} onChange={event => setCardHolderName(event.target.value)} />
            </Grid>
            <Grid item>
                <CardElement onChange={event => setCardComplete(event.complete)} className={classes.creditCard}
                    options={cardElementOptions} />
            </Grid>
            <Grid item>
                <Typography variant="h6">{lt('billingAddress')}</Typography>
            </Grid>
            <Grid item>
                <StripeCountrySelector {...{ country }} {...{ setCountry }} />
            </Grid>
            <Grid item>
                <TextField data-test="addressLine1" fullWidth label={lt('addressLine1')} variant="outlined"
                    value={addressLine1} onChange={event => setAddressLine1(event.target.value)} />
            </Grid>
            <Grid item>
                <TextField data-test="addressLine2" fullWidth label={lt('addressLine2')} variant="outlined"
                    value={addressLine2} onChange={event => setAddressLine2(event.target.value)} />
            </Grid>
            <Grid item>
                <TextField data-test="city" fullWidth label={lt('city')} variant="outlined" value={city}
                    onChange={event => setCity(event.target.value)} />
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={6}>
                    <TextField data-test="stateOrProvince" fullWidth label={lt('stateOrProvice')} variant="outlined"
                        value={stateOrProvince} onChange={event => setStateOrProvince(event.target.value)} />
                </Grid>
                <Grid item xs={6}>
                    <TextField data-test="zipOrPostalCode" fullWidth label={lt('zipOrPostalCode')} variant="outlined"
                        value={zipOrPostalCode} onChange={event => setZipOrPostalCode(event.target.value)} />
                </Grid>
            </Grid>
        </>);
    
    const save = async () => {
        setProcessing(true);
        try {
            const { error, setupIntent } = await stripe.confirmCardSetup(
                setupIntentSecret,
                {
                    payment_method: {
                        card: elements.getElement(CardElement)!,
                        billing_details: {
                            name: cardHolderName,
                            address: {
                                country: country,
                                line1: addressLine1,
                                line2: addressLine2,
                                state: stateOrProvince,
                                city: city,
                                postal_code: zipOrPostalCode,
                            },
                        },
                    },
                }
            );
            if (error) {
                console.error(error)
                setErrorMessage(lt("stripeErrorMessage", error));
                setProcessing(false);
            } else if (setupIntent?.status === 'succeeded') {
                updateBillingProvider(
                    MakeUpdateBillingCreateMutationOptions({
                        org_id: org_id!,
                        billingProvider: {
                            type: "stripe",
                            id: (setupIntent?.payment_method ?? "") as string,
                        },
                    })
                )
                    .then(() => {
                        history.push(`/${ORGS_PATH}/${org_id}/signup/billing/3`, { plan, supportLevel });
                    })
                    .catch((err) => {
                        updateSetupIntent();
                        ErrorSnackbarCatcher(err, setErrorMessage);
                        setProcessing(false);
                    });
            } else {
                throw new Error(`Error confirming card. Status=${setupIntent?.status}`)
            }
        } catch (err) {
            console.error(err)
            setErrorMessage(lt("accountError"));
            setProcessing(false);
        }
    };

    const disabled = !cardHolderName || !cardComplete || !country || !addressLine1 || !city || !stateOrProvince || !zipOrPostalCode;

    const footerImg = (
        <img className={classes.image} src={`${process.env.PUBLIC_URL}/img/misc/Powered by Stripe - blurple.svg`} alt=""></img>
    )

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <CreateWrapper cancelPath={cancelPath} content={content} onNext={save} saving={processing} disabled={disabled} footerImg={footerImg} />
        </>
    );
};

const useStyles = makeStyles(theme => ({
    image: {
        height: '30px',
        marginTop: '4px'
    },
    creditCard: {
        borderRadius: 4,
        border: 'solid 1px #B5B5B5',
        padding: 17,
        '&:hover': {
            borderColor: 'black'
        },
        '&.StripeElement--focus': {
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 0 1px ${theme.palette.primary.light}`
        },
        '&:not(.StripeElement--focus):not(.StripeElement--complete):not(.StripeElement--empty)': {
            borderColor: 'red',
            boxShadow: `0 0 0 1px red`
        }
    }
}));

interface translations {
    enterCreditCardInfo: string
    creditCardDetails: string
    cardHolderName: string
    creditCardNumber: string
    billingAddress: string
    country: string
    addressLine1: string
    addressLine2: string
    city: string
    stateOrProvice: string
    zipOrPostalCode: string
    stripeErrorMessage: string
    accountError: string
}

const enTranslations: translations = {
    enterCreditCardInfo: 'Enter Credit Card Info',
    creditCardDetails: 'Credit Card Details',
    cardHolderName: 'Card Holder\'s Name',
    creditCardNumber: 'Credit Card Number',
    billingAddress: 'Billing Address',
    country: 'Country',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    city: 'City',
    stateOrProvice: 'State/Provice',
    zipOrPostalCode: 'Zip/Postal Code',
    stripeErrorMessage: 'Error processing card information ({{code}}): {{message}}',
    accountError: 'Could not set billing provider. Please contact support.'
}