import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'mdi-react/DeleteIcon';
import LockResetIcon from 'mdi-react/LockResetIcon';
import AccountEditOutlineIcon from 'mdi-react/AccountEditOutlineIcon';
import { Dotdotdot } from '../../components';

interface Props {
    item: string,
    onUpdateClick: (item: string) => void,
    onChangePasswordClick?: (item: string) => void,
    onDeleteClick: (item: string) => void,
}

export const TableActions = ({onDeleteClick, onChangePasswordClick, onUpdateClick, item}: Props) => {
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TableActions', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TableActions:${key}`, interpolate);

    const menuItems = [];
    menuItems.push({
        name: lt('update'),
        icon: <AccountEditOutlineIcon />,
        action: () => onUpdateClick(item)
    });
    onChangePasswordClick && menuItems.push({
        name: lt('changePassword'),
        icon: <LockResetIcon />,
        action: () => onChangePasswordClick(item)
    });
    menuItems.push({
        name: lt('delete'),
        icon: <DeleteIcon />,
        action: () => onDeleteClick(item)
    });

    return (
        <>
            <Dotdotdot {...{menuItems}} />
        </>
    )
}

interface translations {
    update: string,
    delete: string,
    changePassword: string,
}

const enTranslations: translations = {
    update: 'Update',
    delete: 'Delete',
    changePassword: 'Change Password',
}