import { gql } from '@apollo/client';
import { ServiceResourceVars } from '../interfaces';

// INTERFACES

export interface HDWallets {
    wallets: string[]
}

export interface HDWallet {
    id: string
    secret: string
}

export interface HDWalletsData {
    hdwallets: HDWallets
}

export interface CreateHDWalletData {
    createHDWallet: HDWallet
}

export interface HDWalletAccount {
    address: string
    privateKey: string
}

export interface HDWalletAccountData {
    hdwalletAccount: HDWalletAccount
}

export interface HDWalletAccountVars extends ServiceResourceVars {
    index: string
}

// FRAGMENTS

export const HDWalletFields = ` 
    fragment HDWalletFields on HDWallet {
        id
        secret
    }`

export const HDWalletAccountFields = ` 
    fragment HDWalletAccountFields on HDWalletAccount {
        address
        privateKey
    }`    

// MUTATIONS

export const CreateHDWalletMutation = gql`
    ${HDWalletFields}
    mutation createHDWallet($service_id: String!) {
        createHDWallet(service_id: $service_id) {
            ...HDWalletFields
        }
    }`;

// QUERIES & SUBSCRIPTIONS

export const HDWalletsQuery = gql`
    query hdwallets($service_id: String!) {
        hdwallets(service_id: $service_id) {
            wallets
        }
    }`;

export const HDWalletAccountQuery = gql`
    ${HDWalletAccountFields}
    query hdwalletAccount($service_id: String!, $id: String!, $index: String!) {
        hdwalletAccount(service_id: $service_id, id: $id, index: $index) {
            ...HDWalletAccountFields
        }
    }`;    