import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from "@material-ui/core";
import { Alert } from '../../../components/FormControls/Alert';
import { MembershipPermissions } from '../../../components/Memberships/MembershipPermissions';
import { MembershipSummary } from '../../../components/Memberships/MembershipSummary';

export const SystemDetails: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SystemDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SystemDetails:${key}`, interpolate);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    {lt('membershipDetails')}
                </Typography>
            </Grid>
            <Grid item container>
                <Alert severity="info" title={lt('infoTitle')} description={lt('infoDescription')} />
            </Grid>
            <Grid item container direction="row" spacing={3}>
                <Grid item container lg={6} xs={12}>
                    <MembershipSummary />
                </Grid>
                <Grid item container lg={6} xs={12}>
                    <MembershipPermissions />
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    membershipDetails: string,
    membershipName: string,
    infoTitle: string,
    infoDescription: string
}

const enTranslations: translations = {
    membershipDetails: 'Membership Details',
    membershipName: 'Membership name',
    infoTitle: 'IMPORTANT NOTE',
    infoDescription: 'The system membership is a shared environment resource.'
}