import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Typography, TextField, Grid } from "@material-ui/core";
import { MembershipSelector } from "../../../components/FormControls/MembershipSelector";
import { CreateWrapper } from "../../../components/DialogWrappers";
import { CreateStepProps } from "../../../interfaces";
import { HelpStep1 } from "./HelpStep1";

export const Step1 = ({ cancelPath }: CreateStepProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateConfigStep1", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateConfigStep1:${key}`, interpolate);

    const [membershipId, setMembershipId] = useState("");
    const [name, setName] = useState("");

    const history = useHistory();

    const save = () => {
        history.push(`${cancelPath}/create/2`, {
            name,
            membershipId,
        });
    };

    const disabled = !name || !membershipId;

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">{lt("header")}</Typography>
            </Grid>

            <Grid item>
                <MembershipSelector
                    {...{ membershipId }}
                    {...{ setMembershipId }}
                />

                <TextField
                    data-test="textField_configName"
                    value={name || ""}
                    onChange={(event) => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt("name")}
                    variant="outlined"
                />
            </Grid>
        </>
    );

    return (
        <>
            <CreateWrapper
                cancelPath={cancelPath}
                {...{ content }}
                {...{ disabled }}
                onNext={save}
                isFirstStep
            />
            <HelpStep1 />
        </>
    );
};

interface translations {
    header: string;
    name: string;
}
const enTranslations: translations = {
    header: "Choose your membership and name",
    name: "Name",
};
