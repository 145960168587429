import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button } from '@material-ui/core';
import FormTextboxPasswordIcon from 'mdi-react/FormTextboxPasswordIcon';
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import { EditableSettings, EditableSetting } from '../../components/DisplaySettings';
import { ProfileSettingsChangePassword } from './Security/ChangePassword';
import { ProfileSettingsSetupMFA } from './Security/SetupMFA';
import { useQuery, useApolloClient, useLazyQuery } from '@apollo/client';
import { MFATypeQuery, MFATypeData } from '../../models/mfa';
import { ProfileSettingsDisableMFA } from './Security/DisableMFA';
import { FeatureToggles, FeatureTogglesData, FeatureTogglesVars, FeatureTogglesQuery, OrganizationsQuery, OrganizationsData, MFABlockedOrganizationsData, MFABlockedOrganizationsQuery } from '../../models';
import { SessionData } from '../../interfaces';
import { SessionQuery } from '../../queries/Session';
import { ProfileSettingDisableOrgMFA } from './Security/DisableOrgMFA';
import { cognitoController } from '../../utils/cognitoController';

export const ProfileSettingsSecurity: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProfileSettingsSecurity', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProfileSettingsSecurity:${key}`, interpolate);
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    const [setupMFADialogOpen, setSetupMFADialogOpen] = useState(false);
    const [disableMFADialogOpen, setDisableMFADialogOpen] = useState(false);
    const [disableOrgMFaDialogOpen, setDisableOrgMFADialogOpen] = useState(false);
    const [isReset, setIsReset] = useState(false);

    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    let {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-first'
    });

    const {
        refetch,
        data: {
            mfaType
        } = { mfaType: '' }
    } = useQuery<MFATypeData>(MFATypeQuery, { fetchPolicy: 'cache-and-network' });
    
    const {
        refetch: refetchOrgs,
        data: {
            organizations
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery, { fetchPolicy: 'cache-and-network' });

    const [getMFABlockedOrganizations] = useLazyQuery<MFABlockedOrganizationsData>(MFABlockedOrganizationsQuery, { fetchPolicy: 'network-only' });

    const myMFARequiredORgs = organizations.filter(org => org.mfa_required && org.owner === session.user_id);

    const passwordActionList: EditableSetting[] = [
        {
            title: lt('password'),
            icon: <FormTextboxPasswordIcon />,
            action: () => setChangePasswordDialogOpen(true),
            description: lt('passwordDescription'),
            buttonLabel: lt('changePassword')
        }
    ];

    const MFAResetButton = <Button variant="outlined" onClick={() => handleChangeMFA()}>{lt('disable')}</Button>

    const mfaActionList: EditableSetting[] = [
        {
            title: lt('authenticationApp'),
            icon: <CellphoneIcon />,
            extraButtons: mfaType === 'totp' ? [MFAResetButton] : undefined,
            action: mfaType === 'totp' ? () => handleResetMFA() : () => handleChangeMFA(),
            description: lt('mfaDescription'),
            buttonLabel: mfaType === 'totp'? lt('reset') : lt('enable'),
            value: mfaType === 'totp'? lt('enabled') : lt('disabled')
        }
    ];

    const handleResetMFA = () => {
        setIsReset(true);
        setSetupMFADialogOpen(true);
    }

    const handleChangeMFA = () => {
        if(mfaType === 'totp') {
            if (myMFARequiredORgs.length > 0) {
                setDisableOrgMFADialogOpen(true)
            } else {
                setDisableMFADialogOpen(true);
            }
        } else {
            setIsReset(false);
            setSetupMFADialogOpen(true);
        }
    };

    const mfaStatusChanged = async () => {
        await refetch();
        await cognitoController.renewSession(client, session.selected_org);
        refetchOrgs();
        getMFABlockedOrganizations();
    };

    return (
        <>
            <Grid container>
                <Grid item container direction="column" sm={8} spacing={3}>
                    <Grid item>
                        <Typography variant="h5">
                            {lt('security')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <EditableSettings
                            header={lt('password')}
                            actionsList={passwordActionList}
                        />
                    </Grid>
                    {featureToggles.mfa &&
                    <Grid item>
                        <EditableSettings
                            header={lt('multiFactorAuthentication')}
                            actionsList={mfaActionList}
                        />
                    </Grid>}
                </Grid>
            </Grid>
            <ProfileSettingsChangePassword
                changePasswordDialogOpen={changePasswordDialogOpen}
                setChangePasswordDialogOpen={setChangePasswordDialogOpen} />
            <ProfileSettingsSetupMFA
                setupMFADialogOpen={setupMFADialogOpen}
                setSetupMFADialogOpen={setSetupMFADialogOpen}
                mfaStatusChanged={mfaStatusChanged}
                {...{isReset}}
            />
            <ProfileSettingsDisableMFA
                disableMFADialogOpen={disableMFADialogOpen}
                setDisableMFADialogOpen={setDisableMFADialogOpen}
                mfaStatusChanged={mfaStatusChanged}
            />
            <ProfileSettingDisableOrgMFA
                {...{disableOrgMFaDialogOpen}}
                {...{setDisableOrgMFADialogOpen}}
            />
        </>
    );
}

interface translations {
    security: string
    password: string
    passwordDescription: string
    changePassword: string
    multiFactorAuthentication: string
    authenticationApp: string
    mfaDescription: string
    setup: string
    enabled: string
    change: string
    disabled: string
    enable: string
    disable: string
    reset: string
}

const enTranslations: translations = {
    security: 'Security',
    password: 'Password',
    passwordDescription: 'Change the password for your account',
    changePassword: 'Change Password',
    multiFactorAuthentication: 'Multi-Factor Authentication',
    authenticationApp: 'Authentication App',
    mfaDescription: 'Multi-factor based authentication when logging into Kaleido',
    setup: 'Setup',
    enabled: 'Enabled',
    change: 'Change',
    disabled: 'Disabled',
    enable: 'Enable',
    disable: 'Disable',
    reset: 'Reset'
}