import { useQuery } from "@apollo/client";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { DocumentsKPI } from "../../../components/Charts/B2B/DocumentsKPI";
import { ConnectRuntimeWrapper } from "../../../components/ConnectRuntime/ConnectRuntimeWrapper";
import { CopyableSetting, CopyableSettings } from "../../../components/DisplaySettings";
import { DisplayCard, DisplayTable } from "../../../components/DisplayWrappers";
import { ResourceStateChip } from "../../../components/FormControls/ResourceStateChip";
import { ShortenedString } from "../../../components/FormControls/ShortenedString";
import { B2B_BASE_PATH } from "../../../components/MainNav/SideNavs/B2bCommunication";
import { DOCSTORE_TRANSFERS_PATH } from "../../../components/ServicesNav/Services/DocumentStoreItems";
import { EnvironmentResourceVars, LinkButtonProps } from "../../../interfaces";
import {
    DocumentStoreConfigData, DocumentStoreConfigQuery,
    DocumentsTransfersData,
    DocumentsTransfersQuery, DocumentsTransfersVars, ServiceData, ServiceQuery,
    ServicesEnum,
    DocumentStoreProviderTranslations,
    EnDocumentStoreProviderTranslations,
    DocumentStoreMetricsData,
    DocumentStoreMetricsVars,
    DocumentStoreMetricsQuery
} from "../../../models";
import moment from "moment";
import { MonthlyLimits } from "./MonthlyLimits";
import { OutboundTransfers } from "./OutboundTransfers";
import { SECURITY_BASE_PATH } from "../../../components/MainNav/SideNavs/Security";

export const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "DocStoreOverview", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`DocStoreOverview:${key}`, interpolate);

    const history = useHistory()
    const { consortium_id, environment_id, service_id, org_id } = useParams<any>();

    const { data: { service } = { service: null } } = useQuery<
        ServiceData,
        EnvironmentResourceVars
    >(ServiceQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: service_id!,
        },
        fetchPolicy: "cache-only",
    });

    const {
        loading,
        data: { documentStoreConfig } = { documentStoreConfig: null },
    } = useQuery<DocumentStoreConfigData>(DocumentStoreConfigQuery, {
        variables: {
            service_id: service_id!,
        },
        skip: service?.state !== 'started',
        fetchPolicy: "cache-and-network",
    });

    const thisMonth = moment().month() + 1
    const thisYear = moment().year()
    const {
        data: {
            documentStoreMetrics
        } = { documentStoreMetrics: null },
    } = useQuery<DocumentStoreMetricsData, DocumentStoreMetricsVars>(DocumentStoreMetricsQuery, {
        variables: {
            consortia_id: consortium_id, 
            environment_id, 
            id: service_id,
            month: thisMonth,
            year: thisYear
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 20000
    });

    const {
        loading: transfersLoading,
        data: {
            documentsTransfers : {
                transfers: transfersResults = [],
            } = { transfers: null},
        } = { documentsTransfers: {} }} = useQuery<DocumentsTransfersData, DocumentsTransfersVars>(DocumentsTransfersQuery, {
            variables: { service_id: service_id!, offset: 0, limit: 5 },
            fetchPolicy: 'cache-and-network',
            skip: service?.state !== 'started',
            pollInterval: 20000
    });

    if ((loading && !documentStoreConfig)) return <CircularProgress />;
    if (!service)
        return (
            <Redirect
                to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}`}
            />
        );

    let providerList: CopyableSetting[] = [];
    if (documentStoreConfig?.provider && documentStoreConfig.provider !== 'internal') {
        providerList.push({
            title: lt("provider"),
            displayValue: lt(documentStoreConfig.provider),
            disableCopy: true,
        });
        if (documentStoreConfig?.region) {
            providerList.push({
                title: lt("region"),
                displayValue: documentStoreConfig.region,
                disableCopy: true,
            });
        }
        if (documentStoreConfig?.bucket_or_container) {
            providerList.push({
                title: lt("container"),
                displayValue: documentStoreConfig.bucket_or_container,
                disableCopy: true,
            });
        }
    }

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("docExchange"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://docs.kaleido.io/kaleido-services/document-store/"
                ),
        },
        {
            icon: <DescriptionIcon />,
            title: lt("documentsApi"),
            value: lt("apiDocs"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://api.kaleido.io/documentstore.html#tag/Documents"
                ),
        },
        {
            icon: <DescriptionIcon />,
            title: lt("documentExchangeBlog"),
            value: lt("blog"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://www.kaleido.io/blockchain-blog/new-document-store-service-offers-private-off-chain-storage"
                ),
        },
        {
            icon: <DescriptionIcon />,
            title: lt("snippets"),
            value: lt("snippetsDescription"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://github.com/kaleido-io/documentstore-snippets"
                ),
        },
    ];

    const records = transfersResults?.map((entry, index) => ({
        key: `${index}`,
        columns: [{
            value: <ShortenedString content={entry?.document ?? '-'} prefixCharacters={10} postfixCharacters={20} hideCopy />
        },{
            value: <ResourceStateChip state={entry.status} />
        }, {
            value: new Date(entry.timestamp).toLocaleString()
        }, {
            value: <ShortenedString content={entry.to} prefixCharacters={3} postfixCharacters={20} hideCopy />
        }],
    }));
    const headers = [lt('name'), lt('status'), lt('date'), lt('receiver')];
    const transfersLinkButton: LinkButtonProps = {
        text: lt('viewAllTransfers'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/${service_id}/${DOCSTORE_TRANSFERS_PATH}`)
    };
    const appCredsLinkButton: LinkButtonProps = {
        text: lt('viewAppCreds'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}`)
    };

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">
                    {lt("docStoreOverview")}
                </Typography>
            </Grid>

            <Grid item>
                <DocumentsKPI allRuntimes={[{ _id: service_id, name: service.name }]} forDashboard />
            </Grid>

            <Grid item container direction="row" spacing={3}>
                <Grid item container xs={12} lg={8}>
                    <DisplayTable linkButton={transfersLinkButton} emptyLabel={lt('noTransfers')} loading={transfersLoading && !transfersResults?.length} columnHeaders={headers} {...{records}} header={lt('transfers')} />
                </Grid>
                {documentStoreMetrics &&
                <Grid item container xs={12} lg={4}>
                    <MonthlyLimits {...{documentStoreMetrics}} {...{service}} />
                </Grid>
                }

                <Grid item container xs={12} md={6} lg={8}>
                    <ConnectRuntimeWrapper customLinkButton={appCredsLinkButton} service={service} />
                </Grid>
                {documentStoreMetrics &&
                <Grid item container xs={12} lg={4}>
                    <OutboundTransfers {...{documentStoreMetrics}} />
                </Grid>}

                {providerList.length > 0 && 
                <Grid item container xs={12} sm={6}>
                    <CopyableSettings
                        header={lt("serviceEndpoints")}
                        copyableList={providerList}
                    />
                </Grid>
                }
                <Grid item container xs={12} sm={6}>
                    <DisplayCard
                        header={lt("documentation")}
                        itemList={DocumentationList}
                    />
                </Grid>
            </Grid>                
        </Grid>
    );
};


interface translations extends DocumentStoreProviderTranslations {
    docStoreOverview: string;
    region: string;
    owner: string;
    provider: string;
    container: string;
    serviceEndpoints: string;
    status: string;
    runtimeName: string;
    docExchange: string;
    documentation: string;
    apiDocs: string;
    documentsApi: string;
    documentExchangeBlog: string;
    blog: string;
    sent: string,
    name: string
    receiver: string
    noTransfers: string
    transfers: string
    date: string
    viewAllTransfers: string
    viewAppCreds: string
    snippets: string
    snippetsDescription: string
}
const enTranslations: translations = {
    ...EnDocumentStoreProviderTranslations,
    documentExchangeBlog: "Document Exchange Blog",
    blog: "Blog",
    docExchange: "Document Exchange",
    apiDocs: "API Docs",
    documentation: "Documentation",
    documentsApi: "Document Exchange API",
    docStoreOverview: "Document Exchange Overview",
    owner: "Owner",
    region: "Region",
    provider: "Provider",
    container: "Container",
    serviceEndpoints: "Provider Information",
    status: "Status",
    runtimeName: "Runtime Name",
    sent: 'Sent',
    date: 'Date',
    transfers: 'Most Recent Outbound Transfers',
    name: 'Name',
    receiver: 'Receiver',
    noTransfers: 'No Transfers Yet',
    viewAllTransfers: 'View All Transfers',
    viewAppCreds: 'View App Creds',
    snippets: 'Document Exchange Snippets',
    snippetsDescription: 'View sample code for this runtime'
};
