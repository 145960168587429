import { useQuery } from "@apollo/client";
import { CircularProgress, Typography, TableRow, TableCell, makeStyles } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GatewayAPI, Node, ChaincodeStatusesData, ChaincodeStatusesVars, ChaincodeStatusesQuery, Channel } from "../../models";
import AdjustIcon from 'mdi-react/AdjustIcon';
import { ResourceStateChip } from "../../components/FormControls/ResourceStateChip";

interface Props {
    node: Node
    gatewayAPI?: GatewayAPI
    isMonitor?: boolean
    channels?: Channel[]
}

export const InstalledStatusRow = ({ node, gatewayAPI, isMonitor, channels }: Props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'InstalledStatusRow', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`InstalledStatusRow:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const {
        loading,
        data: {
            chaincodeStatuses
        } = { chaincodeStatuses: null }
    } = useQuery<ChaincodeStatusesData, ChaincodeStatusesVars>(ChaincodeStatusesQuery, { 
        variables: {
            consortia_id: consortium_id,
            environment_id,
            node_id: node._id,
            id: gatewayAPI?._id || ''
        }, 
        pollInterval: 10000,
        skip: isMonitor || false,
        fetchPolicy: 'network-only' // force a network call to get the chaincode deployment statuses
    });

    const installedToNode = channels?.some(c => c.members.some(m => m === node.membership_id))
    let status = 'notInstalled'

    if (installedToNode) {
        let supportsStatuses = chaincodeStatuses?.installed !== undefined || (isMonitor && gatewayAPI?.installed !== undefined)
        status = 'unknown'
        if (supportsStatuses) {
            if (isMonitor) {
                status = gatewayAPI?.installed ? 'installed' : 'installing'
            } else {
                status = chaincodeStatuses?.installed ? 'installed' : 'installing'
            }
        }
    }

    return (
        <TableRow>
            <TableCell className={classes.tableCell}>
                <AdjustIcon />
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography noWrap variant="body2" color="inherit">
                    {node.name}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography noWrap variant="body2" color="inherit">
                    {node.membership.isMine ? node.membership.name : lt('system')}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                {(loading && !chaincodeStatuses) ? <CircularProgress size={24} /> : <ResourceStateChip state={status as 'unknown' | 'installed' | 'installing' | 'notInstalled'} />}
            </TableCell>
        </TableRow>
    )
};

const useStyles = makeStyles((theme) => ({
    tableCell: {
        paddingLeft: theme.spacing(3)
    },
}));

interface translations {
    system: string
}
const enTranslations: translations = {
    system: 'System'
}