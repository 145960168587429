import { useApolloClient, useQuery } from "@apollo/client";
import {
    Button, Grid,
    MenuItem, TextField,
    Typography
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper } from "../../../../components/DialogWrappers";
import {
    RadioSelector,
    RadioSelectorOptions
} from "../../../../components/FormControls/RadioSelector";
import { B2B_BASE_PATH } from "../../../../components/MainNav/SideNavs/B2bCommunication";
import {
    CLOUDCONFIG_BASE_PATH,
    CLOUDCONFIG_STORAGE_PATH
} from "../../../../components/MainNav/SideNavs/CloudConfigs";
import { PayWallBanner } from "../../../../components/PayWall/PayWallBanner";
import { EnvironmentResourcesVars } from "../../../../interfaces";
import {
    OrganizationData,
    OrganizationQuery, OrganizationVars,
    PlanSupports, ServicesEnum
} from "../../../../models";
import { ConfigsData, ConfigsQuery } from "../../../../models/configs";
import { StepHelp } from "./StepHelp";

interface Props {
    cancelPath: string;
    name: string;
    membershipId: string;
    consortiumZoneId: string;
    config: string
    setConfig: React.Dispatch<React.SetStateAction<string>>,
}

export const Step2 = ({
    cancelPath,
    name,
    membershipId,
    consortiumZoneId,
    config,
    setConfig
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateDocExchangeStep2", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateDocExchangeStep2:${key}`, interpolate);

    const [storageType, setStorageType] = useState<"kaleido" | "external">(
        "kaleido"
    );

    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const { loading, data: { configs } = { configs: [] } } = useQuery<
        ConfigsData,
        EnvironmentResourcesVars
    >(ConfigsQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
        },
        fetchPolicy: "cache-and-network",
    });

    // direct read from cache
    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsExternalStorage = useMemo(() => PlanSupports.docXExternalStorage(organization), [organization])

    const storageConfigs = useMemo(() => {
        return configs.filter((config) => config.type === "storage");
    }, [configs]);

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const radioOptions: RadioSelectorOptions[] = [
        {
            value: "kaleido",
            label: lt("kaleidoStorage"),
            description: lt("kaleidoStorageDescription"),
        },
        {
            isDisabled: !supportsExternalStorage,
            value: "external",
            label: lt("externalStorage"),
            description: lt("externalStorageDescription"),
        },
    ];

    const onSave = () => {
        history.push(
            `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/create/3`,
            {
                name,
                membershipId,
                consortiumZoneId,
                config: storageType === 'external' ? config : ''
            }
        );
    };

    const content = (
        <>
            <Grid item container direction="column">
                <Grid item container spacing={1} direction="column">
                    <Grid item>
                        <Typography variant="h5">{lt("header")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            gutterBottom
                        >
                            {lt("headerDescription")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <RadioSelector
                        options={radioOptions}
                        selected={storageType}
                        onChange={(e) =>
                            setStorageType(
                                e.target.value as "kaleido" | "external"
                            )
                        }
                    />
                </Grid>
            </Grid>

            {!supportsExternalStorage && <PayWallBanner description={lt('externalStoragePaywall')} />}

            {storageType === 'external' &&
            <>
                <Grid item container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="h5">
                            {lt("externalStorage")}
                        </Typography>
                    </Grid>
                    <Grid item container justify="space-between" wrap="nowrap">
                        <Grid item xs={10}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                gutterBottom
                            >
                                {lt("configDescription")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    history.push(
                                        `${basePath}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_STORAGE_PATH}`
                                    )
                                }
                            >
                                {lt("create")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item container direction="column" spacing={3}>
                    <Grid item>
                        <TextField
                            data-test="textField_configSelector"
                            disabled={
                                storageConfigs.length === 0
                            }
                            fullWidth
                            select
                            label={lt("configuration")}
                            value={config}
                            variant="outlined"
                            onChange={(e) => setConfig(e.target.value)}
                        >
                            {storageConfigs.map((entry, index) => (
                                <MenuItem key={`type-${index}`} value={entry._id}>
                                    {entry.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {storageConfigs.length === 0 && !loading && (
                        <Grid item>
                            <Typography color="error" variant="body2">
                                {lt("noConfig")}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </>
            }
        </>
    );

    const disabled = (storageType === 'external' && !config)

    return (
        <>
            <CreateWrapper
                disabled={disabled}
                {...{ content }}
                {...{ cancelPath }}
                onNext={onSave}
            />
            <StepHelp />
        </>
    );
};

interface translations {
    header: string;
    externalStorage: string;
    configuration: string;
    configDescription: string;
    noConfig: string;
    create: string;
    headerDescription: string;
    kaleidoStorage: string;
    kaleidoStorageDescription: string;
    externalStorageDescription: string;
    externalStoragePaywall: string
}
const enTranslations: translations = {
    header: "Document Exchange",
    kaleidoStorage: "Kaleido Storage",
    kaleidoStorageDescription: "Documents will be stored in Kaleido.",
    externalStorageDescription:
        "Documents will be stored in either an AWS S3 Bucket or an Azure Blob Container.",
    externalStorage: "External Storage",
    headerDescription:
        "Select the type of storage that will be used in this instance of Document Exchange.",
    configuration: "Storage Configuration",
    configDescription:
        "Select the external storage configuration",
    noConfig: "No storage configuration created",
    create: "Create",
    externalStoragePaywall: "The AWS and Azure cloud storage options are only available on the Business and Enterprise plans."
};
