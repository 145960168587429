import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const GithubHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractMangementGithubHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractMangementGithubHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Import your code from Github',
    helpDescription: 'The compiler runs directly against your Github repo; simply copy over the URL of the Solidity file you want to compile and we extract the source from the chosen branch/tag/commit, import the dependencies (including via NPM as is common in Truffle projects), auto-detect the right Solildity version to compile with, and perform the compilation. Optionally, you can provide the following:',
    helpDesc1: 'Name of the contract to be compiled - only required if your Solidity file declares more than one contract.',
    helpDesc2: 'Github personal access token - only required if your code is in a private repository.',
    helpDesc3: 'EVM and Compiler versions - we will attempt to auto detect the appropriate version, but you can specifiy an override here if desired.',
}