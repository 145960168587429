import React from 'react';
import { FullScreenCreate } from '../../../../components/DialogWrappers';
import { useParams, useHistory } from 'react-router-dom';
import { APP2APP_DESTINATIONS_PATH, APP2APP_CREATE_DESTINATION_PATH } from '../../../../components/ServicesNav/Services/App2AppItems';
import { B2B_BASE_PATH } from '../../../../components/MainNav/SideNavs/B2bCommunication';
import { ServicesEnum, ServicesData, ServicesQuery } from '../../../../models';
import { ORGS_PATH } from '../../../../components/ManageOrgNav/ManageOrgNav';
import { useTranslation } from 'react-i18next';
import { Step1 } from '../../Common/createDestination/Step1';
import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Step2 } from './Step2';

export const CreateApp2AppDestination: React.FC = () => {
  const { t, i18n } = useTranslation();
  i18n.addResourceBundle('en', 'CreateApp2AppDestination', enTranslations);
  const lt = (key: keyof translations, interpolate?: object) => t(`CreateApp2AppDestination:${key}`, interpolate);
  const { org_id, consortium_id, environment_id, service_id, step } = useParams<any>();
  const cancelPath = `/${ORGS_PATH}/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/${service_id}/${APP2APP_DESTINATIONS_PATH}`
  const history = useHistory();

  const {
    data: {
      services
    } = { services: [] }
  } = useQuery<ServicesData>(ServicesQuery, {
    variables: {
      consortia_id: consortium_id!,
      environment_id: environment_id!
    },
    fetchPolicy: 'cache-only'
  });

  const service = services.find(service => service._id === service_id);
  if(!service) {
    return <Typography>{lt('invalidService')}</Typography>
  }

  const membershipId = service.membership_id;

  const check = () => {
    history.push(`/${ORGS_PATH}/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}` +
    `/${ServicesEnum.app2app}/${service_id}/${APP2APP_DESTINATIONS_PATH}/${APP2APP_CREATE_DESTINATION_PATH}/2`)
  };

  const stepComponents: { step: string, component: JSX.Element }[] = [
    {
      step: lt('prerequisites'),
      component: <Step1 prerequisitesComplete={check} membershipId={membershipId} cancelPath={cancelPath} />
    },
    {
      step: lt('destinationDetails'),
      component: <Step2 cancelPath={cancelPath} />
    }
  ];

  return (
    <FullScreenCreate toolbarHeader={lt('createDestination')} stepComponents={stepComponents} stepUrlParam={step} cancelPath={cancelPath} />
  );
};

interface translations {
  createDestination: string
  prerequisites: string
  nameDestination: string
  invalidService: string
  destinationDetails: string
}

const enTranslations: translations = {
  createDestination: 'Create Destination',
  prerequisites: 'Prerequisites',
  nameDestination: 'Name destination',
  invalidService: 'Invalid service',
  destinationDetails: 'Destination Details'
}