import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Snackbar, IconButton, Slide, SnackbarOrigin } from "@material-ui/core";
import CloseIcon from 'mdi-react/CloseIcon';
import ErrorIcon from 'mdi-react/AlertCircleIcon';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import { TransitionProps } from '@material-ui/core/transitions';

const TRANSITION_TIMEOUT = 400

interface Props {
    message: string,
    setMessage: React.Dispatch<React.SetStateAction<string>>,
    messageType?: "error" | "success", // error is default
    anchorOrigin?: SnackbarOrigin
}

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" timeout={TRANSITION_TIMEOUT} />;
}

// the `any` sucks here, but thats what the error type is when you do .catch(...)
export function ErrorSnackbarCatcher (e: any, setErrorMessage: React.Dispatch<React.SetStateAction<string>>) {
    if (e.graphQLErrors?.length) {
        setErrorMessage(e.graphQLErrors[0]?.message ?? '')
    } else if (e.message || e.errorMessage || e.error) {
        setErrorMessage(e.message || e.errorMessage || e.error)
    }
}

export const MessageSnackbar = ({ 
    message, 
    setMessage, 
    messageType = "error", 
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' } 
}: Props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(message ? true : false);

    const timeoutRef = useRef<number>(0)
    useEffect(() => {
        return () => window.clearTimeout(timeoutRef.current);
    }, []);

    const handleClose = () => {
        window.clearTimeout(timeoutRef.current);
        setOpen(false)
        timeoutRef.current = window.setTimeout(() => setMessage(''), TRANSITION_TIMEOUT)
    }

    useEffect(() => {
        setOpen(message ? true : false)
    }, [message]);      

    return (
        <Snackbar data-test={`snackbar_${messageType}`}
            open={open}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
            autoHideDuration={messageType === "error" ? 13000 : 6000} // keep error snackbar around for a bit longer for cypress screenshots
            ContentProps={{
                className: messageType === "error" ? classes.error : classes.success
            }}
            TransitionComponent={SlideTransition}
            message={
                <div className={classes.message}>
                    {messageType ===  "error" && <ErrorIcon className={classes.icon} />}
                    {messageType ===  "success" && <CheckCircleIcon className={classes.icon} />}
                    {message}
                </div>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>,
            ]} />
    )
};

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    success: {
        backgroundColor: 'green'
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));
