import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { ConsortiumZone, CreateEnvironmentZoneData, CreateEnvironmentZoneMutation, CreateEnvironmentZoneVars, CreateServiceData, CreateServiceMutation, CreateServiceVars, EnvironmentZone, MakeEnvironmentZoneCreateMutationOptions, MakeServiceCreateMutationOptions, ServiceDetails, ServicesEnum, EnvironmentData, EnvironmentQuery, RuntimeSize } from '../../models';
import { ConsortiumResourceVars } from '../../interfaces';

interface Props {
    servicePath: string
    service: keyof typeof ServicesEnum
    cancelPath: string;
    name: string;
    membershipId: string;
    consortiumZoneId: string;
    consortiumZones: ConsortiumZone[];
    environmentZones: EnvironmentZone[];
    content: JSX.Element
    helpContent: JSX.Element
    details?: ServiceDetails
    size?: RuntimeSize
    isFirstStep?: boolean
    disabled?: boolean
}

export const RuntimeCreationFinalStep = ({
    servicePath,
    service,
    cancelPath,
    name,
    consortiumZones,
    environmentZones,
    membershipId,
    consortiumZoneId,
    content,
    helpContent,
    details,
    size,
    isFirstStep = false,
    disabled = false
}: Props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const { consortium_id, environment_id } = useParams<any>();
    const history = useHistory();

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });
    const isMultiRegion = environment?.test_features?.multi_region ?? false

    const [createService, { loading: createServiceLoading }] = useMutation<CreateServiceData, CreateServiceVars>(CreateServiceMutation);
    const [createEnvironmentZone, { loading: createEnvironmentZoneLoading }] = useMutation<CreateEnvironmentZoneData, CreateEnvironmentZoneVars>(CreateEnvironmentZoneMutation);

    const consortiumZone = consortiumZones.find(
        (z) => z._id === consortiumZoneId
    );
    const environmentZone = environmentZones.find(
        (z) =>
            z.region === consortiumZone?.region &&
            z.type === consortiumZone?.type &&
            z.cloud === consortiumZone?.cloud
    );

    const onSave = async () => {
        let zoneId = environmentZone?._id;
        // create the environment zone if multi region and the selected region doesnt exist
        if (consortiumZoneId && !zoneId) {
            const { region, cloud, type } = consortiumZones.find(
                (c) => c._id === consortiumZoneId
            )!;
            const envZone = await createEnvironmentZone(
                MakeEnvironmentZoneCreateMutationOptions({
                    consortia_id: consortium_id!,
                    environment_id: environment_id!,
                    environmentZone: {
                        region,
                        cloud,
                        type,
                    },
                })
            ).catch((e) => {
                ErrorSnackbarCatcher(e, setErrorMessage);
                return null;
            });

            zoneId = envZone?.data?.createEnvironmentZone?._id;
        }

        // create the service
        createService(
            MakeServiceCreateMutationOptions({
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                service: {
                    name,
                    membership_id: membershipId,
                    zone_id: zoneId || "",
                    service,
                    details,
                    size: size
                },
            })
        )
            .then((result) => {
                if (result) {
                    const newServiceId = result.data?.createService?._id;
                    if (newServiceId) {
                        history.push(
                            `${servicePath}/${newServiceId}`
                        );
                    }
                }
            })
            .catch((e) => {
                ErrorSnackbarCatcher(e, setErrorMessage);
            });
    };

    const isDisabled = disabled || createServiceLoading || createEnvironmentZoneLoading || !name || !membershipId || (isMultiRegion && !consortiumZoneId);

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <CreateWrapper
                disabled={isDisabled}
                {...{ content }}
                {...{ cancelPath }}
                onNext={onSave}
                isLastStep {...{isFirstStep}}
            />
            { helpContent }
        </>
    );
};
