import { useQuery, useMutation } from "@apollo/client";
import { Grid, Typography, Button } from "@material-ui/core";
import BusinessIcon from "mdi-react/DomainIcon";
import AccountDetailsIcon from "mdi-react/AccountDetailsOutlineIcon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { DisplayCard } from "../../components/DisplayWrappers";
import { ResourceStateChip } from "../../components/FormControls/ResourceStateChip";
import {
    NETWORK_GOVERNANCE_INVITATIONS_PATH,
    NETWORK_GOVERNANCE_MEMBERSHIPS_PATH,
    NETWORK_GOVERNANCE_PATH,
} from "../../components/MainNav/SideNavs/Governance";
import {
    ConsortiumResourcesVars,
    ConsortiumResourceVars,
} from "../../interfaces";
import {
    ConsortiumMembershipsData,
    ConsortiumMembershipsQuery,
    Membership,
    Invitation,
    DeleteInvitationMutation,
    ConsortiumInvitationsQuery,
    ConsortiumInvitationsData,
} from "../../models";
import { CreateMembership } from "./CreateMembership";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import AccountPlusOutlineIcon from "mdi-react/AccountPlusOutlineIcon";
import { Dotdotdot } from "../../components";
import { EmptyCard } from "../../components/DisplayWrappers/EmptyCard";

export const GovernanceMemberships: React.FC = () => {
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);

    i18n.addResourceBundle("en", "GovernanceMemberships", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`GovernanceMemberships:${key}`, interpolate);

    const history = useHistory();
    const { org_id, consortium_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
    };

    const baseGovernancePath = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}`;
    const inviteOrgPath = `${baseGovernancePath}/${NETWORK_GOVERNANCE_INVITATIONS_PATH}/create/1`;

    const {
        data: { consortiumMemberships } = { consortiumMemberships: [] },
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(
        ConsortiumMembershipsQuery,
        {
            variables: environmentVars,
            fetchPolicy: "cache-only",
        }
    );

    const {
        data: { consortiumInvitations } = { consortiumInvitations: [] },
        refetch: refetchInvitations,
    } = useQuery<ConsortiumInvitationsData, ConsortiumResourcesVars>(
        ConsortiumInvitationsQuery,
        {
            variables: {
                consortia_id: consortium_id,
            },
            fetchPolicy: "cache-and-network",
        }
    );

    const [deleteInvitationMutation] = useMutation<any, ConsortiumResourceVars>(
        DeleteInvitationMutation
    );

    const myMemberships = consortiumMemberships
        .filter((entry) => entry.is_mine)
        .sort((a, b) => a.org_name.localeCompare(b.org_name));

    const otherMemberships = consortiumMemberships
        .filter((entry) => !entry.is_mine)
        .sort((a, b) => a.org_name.localeCompare(b.org_name));

    const deleteInvitation = async (i: Invitation) => {
        await deleteInvitationMutation({
            variables: {
                consortia_id: consortium_id!,
                id: i._id,
            },
        });
        refetchInvitations();
    };

    const invitationsList = consortiumInvitations.map((i) => ({
        icon: <AccountPlusOutlineIcon />,
        title: i.org_name,
        value: i.email,
        actionIcon: (
            <Grid
                container
                wrap="nowrap"
                alignContent="center"
                alignItems="center"
            >
                <Grid item>
                    <ResourceStateChip state={i.state} />
                </Grid>
                <Grid item>
                    <Dotdotdot
                        menuItems={[
                            {
                                action: () => deleteInvitation(i),
                                name: lt("delete"),
                                icon: <DeleteOutlineIcon />,
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        ),
    }));

    function generateItemList(membership: Membership) {
        let items = [
            {
                title: lt("organization"),
                value: membership.owner?.name,
                icon: <BusinessIcon />,
            },
            {
                title: lt("identity"),
                value: membership?.verification_proof ? (
                    membership.verification_selfsigned ? (
                        <ResourceStateChip state="self_signed" />
                    ) : (
                        <ResourceStateChip state="externally_signed" />
                    )
                ) : (
                    <ResourceStateChip state="none" />
                ),
                icon: <AccountDetailsIcon />,
            },
        ];
        return items;
    }

    const getLinkButton = (id: string) => {
        return {
            text: lt("viewMembershipDetails"),
            onClick: () =>
                history.push(
                    `${baseGovernancePath}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}/${id}`
                ),
        };
    };

    const myMembershipsContent = myMemberships.map((membership) => (
        <Grid
            key={membership._id}
            item
            container
            xl={3}
            lg={4}
            md={6}
            sm={6}
            xs={12}
        >
            <DisplayCard
                header={membership.org_name}
                itemList={generateItemList(membership)}
                linkButton={getLinkButton(membership._id)}
            />
        </Grid>
    ));

    const otherMembershipsContent = otherMemberships.map((membership) => (
        <Grid
            key={membership._id}
            item
            container
            xl={3}
            lg={4}
            md={6}
            sm={6}
            xs={12}
        >
            <DisplayCard
                header={membership.org_name}
                itemList={generateItemList(membership)}
                linkButton={getLinkButton(membership._id)}
            />
        </Grid>
    ));

    const makeInvitationsContent = () => {
        if (invitationsList.length) {
            return (
                <DisplayCard
                    header={lt("invitations", {
                        count: invitationsList.length,
                    })}
                    itemList={invitationsList}
                />
            );
        } else if (otherMemberships.length) {
            return (
                <EmptyCard
                    imageFiles="Empty-OtherMemberships.svg"
                    header={lt("readyToExpand")}
                    description={lt("expandDescription")}
                    createPath={inviteOrgPath}
                />
            );
        }
        return <> </>;
    };

    return (
        <>
            <Grid container spacing={3}>
                {invitationsList.length !== 0 && (
                    <Grid item sm={6} xs={12}>
                        {makeInvitationsContent()}
                    </Grid>
                )}
                <Grid item container direction={"column"} spacing={3}>
                    <Grid
                        item
                        container
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5">
                                {lt("myMemberships", {
                                    count:
                                        myMemberships.length /* (+1 for the system membership) */,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                data-test="button_addMembership"
                                variant="contained"
                                color="primary"
                                onClick={() => setOpen(true)}
                            >
                                {lt("addMembership")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={3}>
                        {myMembershipsContent}
                    </Grid>
                </Grid>
                <Grid item container direction={"column"} spacing={3}>
                    <Grid
                        item
                        container
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5">
                                {lt("otherMemberships", {
                                    count: otherMemberships.length,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push(inviteOrgPath)}
                            >
                                {lt("inviteOrganization")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={3}>
                        {otherMembershipsContent}
                    </Grid>
                </Grid>
            </Grid>
            <CreateMembership open={open} setOpen={setOpen} />
        </>
    );
};

interface translations {
    memberships: string;
    myMemberships: string;
    otherMemberships: string;
    organization: string;
    identity: string;
    none: string;
    externallySigned: string;
    selfSigned: string;
    viewMembershipDetails: string;
    joinDate: string;
    delegate: string;
    addMembership: string;
    readyToDecentralize: string;
    emptyDescription: string;
    emptyDocumentation: string;
    inviteOrganization: string;
    invitations: string;
    readyToExpand: string;
    expandDescription: string;
    delete: string;
}
const enTranslations: translations = {
    memberships: "Memberships",
    myMemberships: "My Memberships ({{count}})",
    otherMemberships: "Other Memberships ({{count}})",
    organization: "Organization",
    identity: "Identity",
    none: "None",
    externallySigned: "Externally signed",
    selfSigned: "Self-signed on Kaleido",
    viewMembershipDetails: "View membership details",
    joinDate: "Join date",
    delegate: "Primary Contact Email",
    addMembership: "Add membership",
    readyToDecentralize: "Ready to Decentralize Your Network?",
    emptyDescription:
        "Decentralize ownership of your network by on-boarding an external organization.",
    emptyDocumentation: "Documentation",
    inviteOrganization: "Invite Organization",
    invitations: "Network Invitations ({{count}})",
    readyToExpand: "Invite Additional Organizations",
    expandDescription:
        "Continue to expand your network by on-boarding additonal external organizations.",
    delete: "Delete",
};
