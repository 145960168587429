import { gql } from '@apollo/client';
import { EnvironmentResourcesVars, PaginatedEnvironmentResourcesVars } from '../interfaces';



export interface BlocksVars extends EnvironmentResourcesVars {
    limit?: number
    start?: number
}

export interface BlockVars extends EnvironmentResourcesVars {
    number: string
}

export interface StatsVars extends EnvironmentResourcesVars {
    since: string | number
}

export interface ContractResource extends PaginatedEnvironmentResourcesVars {
    contractAddress: string
}
export interface Block {
    number: number
    hash: string
    timestamp: string
    transactionCount: number
    size: number
    miner: string
}

export interface StatsDetails {
    provider: string
    consensus: string
    status: string
}

export interface Stats {
    transactionCount: number
    blockCount: number
    contractCount: number
    erc20TokenContractCount: number
    erc721TokenContractCount: number
    failedTransactionCount: number
    height: number
    lastTransactionTimestamp: string
    details: StatsDetails
}
export interface BlockInfo extends Block {
    parentHash: string
    gasUsed: number
}

export interface BlocksData {
    blocks: Block[]
}

export interface BlockData {
    block: BlockInfo
}

export interface StatsData {
    stats: Stats
}


export const BlocksFields = ` 
    fragment BlocksFields on Block {
        number
        hash
        timestamp
        transactionCount
        size
        miner
    }`

export const BlockFields = ` 
    fragment BlockFields on BlockInfo {
        number
        hash
        timestamp
        transactionCount
        size
        miner
        parentHash
        gasUsed
}`

export const StatsFields = `
    fragment StatsFields on Stats {
        transactionCount
        blockCount
        contractCount
        erc20TokenContractCount
        erc721TokenContractCount
        height
        lastTransactionTimestamp
        failedTransactionCount
        details {
            provider
            consensus
            status
        }
    }
`


export const BlocksQuery = gql`
    ${BlocksFields}
    query getBlocks($consortia_id: String!, $environment_id: String!, $limit: Int, $start: Int) {
        blocks(consortia_id: $consortia_id, environment_id: $environment_id, limit: $limit, start: $start) {
            ...BlocksFields
        }
    }`;

export const BlockQuery = gql`
    ${BlockFields}
    query getBlock($consortia_id: String!, $environment_id: String!, $number: String!) {
        block(consortia_id: $consortia_id, environment_id: $environment_id, number: $number) {
            ...BlockFields
        }
    }`;

export const StatsQuery = gql`
    ${StatsFields}
    query getStats($consortia_id: String!, $environment_id: String!, $since: String!) {
        stats(consortia_id: $consortia_id, environment_id: $environment_id, since: $since) {
            ...StatsFields
        }
    }`;


