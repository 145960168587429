import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Node, UpdateNodeMutation, UpdateNodeVars, Service, UpdateServiceVars, UpdateServiceMutation,
    AppCred, UpdateAppCredVars, UpdateAppCredMutation,
    ContractProject, UpdateContractProjectVars, UpdateContractProjectMutation, CompiledContract, Environment,
    UpdateCompiledContractMutation, UpdateCompiledContractVars, UpdateEnvironmentVars, UpdateEnvironmentMutation, Consortium, UpdateConsortiumVars, UpdateConsortiumMutation, UpdateOrganizationVars, UpdateOrganizationMutation } from '../../models'
import { TextField } from "@material-ui/core";
import { FormDialog } from './FormDialog'
import { capitalize } from '../../utils/StringUtils';
import { UpdateOauthConfigurationMutation, UpdateOauthConfiguration } from '../../models/oauthConfiguration';

interface Props {
    defaultName: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    fieldNameToUpdate?: "name" | "description",
}

export const UpdateName = ({ defaultName, open, setOpen, fieldNameToUpdate = "name" }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'UpdateName', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`UpdateName:${key}`, interpolate)

    const { consortium_id, environment_id, node_id, service_id, appcred_id, contract_id, compiled_contract_id, org_id, oauth_id } = useParams<any>();

    const envResourceVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [name, setName] = useState(defaultName);

    useEffect(() => {
        setName(defaultName)
    }, [defaultName])

    const [updateNode, { loading: updateNodeLoading }] = useMutation<Node, UpdateNodeVars>(UpdateNodeMutation)
    const [updateService, { loading: updateServiceLoading }] = useMutation<Service, UpdateServiceVars>(UpdateServiceMutation)
    const [updateAppCred, { loading: updateAppCredLoading }] = useMutation<AppCred, UpdateAppCredVars>(UpdateAppCredMutation)
    const [updateOauthConfiguration, { loading: updateOauthLoading }] = useMutation<AppCred, UpdateOauthConfiguration>(UpdateOauthConfigurationMutation)
    const [updateContractProject, { loading: updateContractProjectLoading }] = useMutation<ContractProject, UpdateContractProjectVars>(UpdateContractProjectMutation)
    const [updateCompiledContract, { loading: updateCompiledContractLoading }] = useMutation<CompiledContract, UpdateCompiledContractVars>(UpdateCompiledContractMutation)
    const [updateEnvironment, { loading: updateEnvironmentLoading }] = useMutation<Environment, UpdateEnvironmentVars>(UpdateEnvironmentMutation)
    const [updateConsortium, { loading: updateConsortiumLoading }] = useMutation<Consortium, UpdateConsortiumVars>(UpdateConsortiumMutation)
    const [updateOrganization, { loading: updateOrganizationLoading }] = useMutation<Consortium, UpdateOrganizationVars>(UpdateOrganizationMutation)

    const loading = updateNodeLoading || updateServiceLoading || updateAppCredLoading || 
        updateContractProjectLoading || updateCompiledContractLoading || updateEnvironmentLoading ||
        updateConsortiumLoading || updateOrganizationLoading || updateOauthLoading
    
    const updater = () => {         
        if (node_id) {
            const updateVars = { ...envResourceVars, ...{ id: node_id! } }
            return updateNode({
                variables: {...{ node: { name } }, ...updateVars}
            }).then(() => {})
        } else if (service_id) {
            const updateVars = { ...envResourceVars, ...{ id: service_id! } }
            return updateService({
                variables: {...{ service: { name } }, ...updateVars}
            }).then(() => {})
        } else if (appcred_id) {
            const updateVars = { ...envResourceVars, ...{ id: appcred_id! } }
            return updateAppCred({
                variables: {...{ appCred: { name } }, ...updateVars}
            }).then(() => {})
        }  else if (oauth_id) {
            const updateVars = { ...envResourceVars, ...{ id: oauth_id! } }
            return updateOauthConfiguration({
                variables: {...{ oauth: { name } }, ...updateVars}
            }).then(() => {})
        } else if (compiled_contract_id) {
            const updateVars = {
                consortia_id: consortium_id!,
                contract_id: contract_id!,
                id: compiled_contract_id!
            }
            return updateCompiledContract({
                variables: {...{ compiledContract: { [fieldNameToUpdate]: name } }, ...updateVars}
            }).then(() => {})
        } else if (contract_id) {
            const updateVars = {
                consortia_id: consortium_id!,
                id: contract_id!
            }
            return updateContractProject({
                variables: {...{ contractProject: { [fieldNameToUpdate]: name } }, ...updateVars}
            }).then(() => {})
        } else if (environment_id) {
            const updateVars = {
                consortia_id: consortium_id!,
                id: environment_id!
            }
            return updateEnvironment({
                variables: {...{ environment: { [fieldNameToUpdate]: name } }, ...updateVars}
            }).then(() => {})
        } else if (consortium_id) {
            const updateVars = {
                id: consortium_id!
            }
            return updateConsortium({
                variables: {...{ consortium: { [fieldNameToUpdate]: name } }, ...updateVars}
            }).then(() => {})
        } else if(org_id) {
            const updateVars = {
                id: org_id!
            }
            return updateOrganization({
                variables: {...{ organization: { name } }, ...updateVars}
            }).then(() => {})
        }
        else {
            return new Promise<void>(() => {})
        }
    }

    const controlsWrapper = 
        <>
            <TextField
                data-test="textField_updateName"
                value={name} onChange={event => setName(event.target.value)}
                autoFocus
                margin="normal"
                label={capitalize(lt(fieldNameToUpdate))}
                fullWidth
                variant="outlined"
            />
        </>

    return <FormDialog dataTestId="updateName"
        {...{open}} 
        {...{setOpen}} 
        header={lt('changeHeader', {field: fieldNameToUpdate})} 
        description={lt('changeDescription', {field: fieldNameToUpdate})} 
        {...{controlsWrapper}} 
        saveDisabled={!name || loading}
        onSave={updater}
        closeDialogAfterSave />
};

interface translations {
    changeHeader: string,
    changeDescription: string,
    name: string,
    description: string,
}
const enTranslations: translations = {
    changeHeader: 'Change {{field}}',
    changeDescription: 'You can update this resource by providing a new {{field}} in the field below.',
    name: 'name',
    description: 'description',
}