import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { EnvironmentResourcesVars } from '../../interfaces';
import { CHANNELS_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { ChannelData, ChannelQuery } from '../../models/channels';
import { CHANNEL_CHAINCODES_PATH } from '../../components/ChannelNav/ChannelNav';
import { ChaincodesTable } from '../../components/Chaincodes/ChaincodesTable';

export const ADD_CHAINCODE_PATH = 'create';
export const AddressBookChannelChaincodes: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'AddressBookChannelChaincodes', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookChannelChaincodes:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, channel_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: channel_id!
    }

    const addChaincodePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${channel_id}/${CHANNEL_CHAINCODES_PATH}/${ADD_CHAINCODE_PATH}/1`;

    const {
        loading,
        data: {
            channel
        } = { channel: null }
    } = useQuery<ChannelData, EnvironmentResourcesVars>(ChannelQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    if (loading || !channel) {
        return <CircularProgress />
    }

    const channelContracts = channel.contracts;

    return (
        <Grid container spacing={3}>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('channelChaincodes', { channelName: channel.name })}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                        data-test="button_addChannel"
                        variant="contained"
                        color="primary"
                        onClick={()=> {history.push(addChaincodePath)}}
                    >
                        {lt('addChaincode')}
                    </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <ChaincodesTable chaincodes={channelContracts} totalCount={channelContracts.length} header={lt('chaincodes')} description={lt('description')}/>
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    channelChaincodes: string
    chaincodes: string
    description: string
    label: string
    sequence: string
    initRequired: string
    url: string
    addedOn: string
    version: string
    addChaincode: string
}
const enTranslations: translations = {
    channelChaincodes: '{{channelName}} Chaincodes',
    chaincodes: 'Chaincodes',
    description: 'The following chaincodes have been installed to this channel.',
    label: 'Label',
    sequence: 'Sequence',
    initRequired: 'Init Required',
    url: 'URL',
    addedOn: 'Added On',
    version: 'Version',
    addChaincode: 'Add Chaincode'
}
