import React from 'react';
import { EtherBalanceOfData, EtherBalanceOfVars, EtherBalanceOfQuery, AddressWidgetInfo } from '../../../models';
import { ListItemText, ListItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../MainNav/SideNavs/AddressBook';
import { MEMBERSHIP_SIGNING_ACCOUNTS_PATH } from '../../MembershipNav/MembershipNav';
import { FormLink } from '../FormLink';

interface Props {
    addressWidgetInfo: AddressWidgetInfo
};

export const ShortenedHashSigningAccount: React.FC<Props> = ({ addressWidgetInfo }: Props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ShortenedHashSigningAccount', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ShortenedHashSigningAccount:${key}`, interpolate)
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const {
        data: {
            etherBalanceOf
        } = { etherBalanceOf: '--' }
    } = useQuery<EtherBalanceOfData, EtherBalanceOfVars>(EtherBalanceOfQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            accountAddress: addressWidgetInfo._id
        }
    });

    const membershipId = addressWidgetInfo.membership_id === environment_id || addressWidgetInfo.membership_id === 'sys--mon' ? SYSTEM_MEMBERSHIP : addressWidgetInfo.membership_id;
    const baseUrl = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membershipId}`;
    const viewAccountDetailsUrl = baseUrl + `/${MEMBERSHIP_SIGNING_ACCOUNTS_PATH}/${addressWidgetInfo._id}`

    return (
        <>
            <ListItem>
                <ListItemText primary={lt('etherBalance')} secondary={etherBalanceOf} />
            </ListItem>
            <ListItem>
                <ListItemText primary={lt('moreInformation')} secondary={
                    <>
                        {window.location.pathname !== viewAccountDetailsUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewAccountDetailsUrl}>
                                    {lt('viewAccountDetails')}
                                </FormLink>
                            </span>}
                        {window.location.pathname !== baseUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={baseUrl}>
                                    {lt('viewAddressBookEntry')}
                                </FormLink>
                            </span>}
                    </>} />
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(() => ({
    linkContainer: {
        display: 'block',
        marginTop: 6
    }
}));

interface translations {
    etherBalance: string
    moreInformation: string
    viewAccountDetails: string
    viewAddressBookEntry: string
}

const enTranslations: translations = {
    etherBalance: 'Ether Balance',
    moreInformation: 'More Information',
    viewAccountDetails: 'View Account Details',
    viewAddressBookEntry: 'View Account Owner Address Book Entry'
}