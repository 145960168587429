import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from "@material-ui/core";
import { SupportedEvmVersions } from '../../models';
import { SolcVersionSelector } from './SolcVersionSelector'
import { EvmVersionSelector } from './EvmVersionSelector'

interface Props {
    evmVersion: string,
    setEvmVersion: React.Dispatch<React.SetStateAction<SupportedEvmVersions>>,
    solcVersion: string,
    setSolcVersion: React.Dispatch<React.SetStateAction<string>>,
    disabled?: boolean
};

export const CompilerAndEvm = ({ evmVersion, setEvmVersion, solcVersion, setSolcVersion, disabled = false }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CompilerAndEvm', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CompilerAndEvm:${key}`, interpolate)

    return (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('compilerAndEvm')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('compilerAndEvmDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <SolcVersionSelector {...{disabled}} {...{solcVersion}} {...{setSolcVersion}} />
            </Grid>

            <Grid item>
                <EvmVersionSelector {...{disabled}} {...{evmVersion}} {...{setEvmVersion}} />
            </Grid>
        </>
    )
};

interface translations {
    compilerAndEvm: string,
    compilerAndEvmDescription: string,
}
const enTranslations: translations = {
    compilerAndEvm: 'Select compiler and EVM',
    compilerAndEvmDescription: '',
}