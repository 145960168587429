import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuditEntry } from '../../models/audit';
import { capitalize } from '../../utils/StringUtils';
import { EnvironmentProviderTranslations, EnEnvironmentProviderTranslations, EnvironmentProvider } from '../../models';

interface Props {
    auditEntry: AuditEntry
    omitEnvironment?: boolean
}

export const ActivityDescription = ({ auditEntry, omitEnvironment }: Props) => {
    const { t, i18n } = useTranslation();
    
    i18n.addResourceBundle('en', 'ActivityDescription', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ActivityDescription:${key}`, interpolate)

    const getDescription = (entry: AuditEntry): string => {
        switch (entry.objectType) {
            case 'services': return lt('descriptionService', {
                service: capitalize(entry.data.service),
                serviceName: entry.data.name,
                environment: omitEnvironment ? '' : lt('inEnvironment', { environment: entry.data.environment_name })
            });
            case 'nodes': return lt('descriptionNodes', {
                nodeName: entry.data.name,
                environment: omitEnvironment ? '' : lt('inEnvironment', { environment: entry.data.environment_name })
            });
            case 'environments': return lt('descriptionEnvironments', {
                environmentName: entry.data.name,
                consensusType: entry.data.consensus_type,
                provider: lt(entry.data.provider as EnvironmentProvider)
            });
            case 'appcreds': return lt('descriptionAppCreds', {
                name: entry.data.name
            });
            case 'memberships': return lt('descriptionMemberships', {
                org: entry.data.owner_org_name
            });
            case 'consortia': return lt('descriptionConsortia', {
                name: entry.data.name
            });
            case 'invitations': return lt('descriptionInvitations', {
                orgName: entry.data.org_name
            });
            case 'compiled_contracts': return lt('descriptionCompiledContracts', {
                description: entry.data.description,
                evmVersion: entry.data.evm_version
            });
            case 'configurations': return lt('descriptionCompiledConfigurations', {
                configurationType: capitalize(entry.data.type),
                configurationName: entry.data.name,
                environment: omitEnvironment ? '' : lt('inEnvironment', { environment: entry.data.environment_name })
            });
            case 'env_zones':
            case 'con_zones': return lt('descriptionConZones', {
                region: entry.data.region,
                cloud: entry.data.cloud
            });
            default: return lt('descriptionGeneric', {
                type: entry.objectType || lt('unknown')
            });
        }
    };

    // calling component can wrap this however it likes
    return <>{getDescription(auditEntry)}</> 
};

interface translations extends EnvironmentProviderTranslations {
    unknown: string
    description: string
    descriptionService: string
    descriptionNodes: string
    descriptionEnvironments: string
    descriptionAppCreds: string
    descriptionMemberships: string
    descriptionConsortia: string
    descriptionInvitations: string
    descriptionCompiledContracts: string
    descriptionCompiledConfigurations: string
    descriptionConZones: string
    descriptionGeneric: string
    inEnvironment: string
}
const enTranslations: translations = {
    ...EnEnvironmentProviderTranslations,
    unknown: 'Unknown',
    description: 'Description',
    descriptionService: '{{service}} runtime "{{serviceName}}"{{environment}}',
    descriptionNodes: 'Node "{{nodeName}}"{{environment}}',
    descriptionEnvironments: 'Environment "{{environmentName}}" with consensus type {{consensusType}} and provider {{provider}}',
    descriptionAppCreds: 'App credential "{{name}}" with Basic Auth type',
    descriptionMemberships: 'Membership owned by "{{org}}"',
    descriptionConsortia: 'Consortium "{{name}}"',
    descriptionInvitations: 'Invitation to "{{orgName}}"',
    descriptionCompiledContracts: 'Compiled contract "{{description}}" using {{evmVersion}}',
    descriptionCompiledConfigurations: '{{configurationType}} configuration "{{configurationName}}"{{environment}}',
    descriptionConZones: 'Region "{{region}}" cloud {{cloud}}"',
    descriptionGeneric: 'Event of type "{{type}}"',
    inEnvironment: ' in environment "{{environment}}"'
}