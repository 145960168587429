import { gql } from '@apollo/client';

export interface CordaRestartOk {
    ok: boolean
}

export interface CordaRestartData {
    restartCorda: CordaRestartOk
}

export const CordaRestartOkFields = ` 
    fragment CordaRestartOkFields on CordaRestartOk {
        ok
    }`;

export const RestartCordaMutation = gql`
    ${CordaRestartOkFields}
    mutation restartCorda($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        restartCorda(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id) {
            ...CordaRestartOkFields
        }
    }`;

