import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ServiceQuery, ServiceData } from '../../models';
import { useParams, Redirect } from 'react-router-dom';
import { EnvironmentResourceVars } from '../../interfaces';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { CookieAppCred } from '../../components/CookieAppCred/CookieAppCred';
import { MessageSnackbar } from '../../components/DialogWrappers';
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import { DisplayCard } from '../../components/DisplayWrappers';
import { WebUiCard } from '../../components/WebUICard/WebUICard';
import { BLOCKCHAIN_BASE_PATH } from '../../components/MainNav/SideNavs/Blockchain';

export const Ipfs = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "IpfsOverview", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`IpfsOverview:${key}`, interpolate);

    const { consortium_id, service_id, environment_id, org_id } = useParams<any>();
    const [appCred, setAppCred] = useState('');
    const [message, setMessage] = useState('');
    const [openingWebUI, setOpeningWebUI] = useState(false);

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const {
        data: {
            service
        } = {service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            consortia_id: consortium_id,
            id: service_id,
            environment_id: environment_id
        },
        fetchPolicy: 'cache-only'
    });

    const inAuthUrl = `https://${appCred}@${service?.urls?.webui?.slice(8)}`;

    useEffect(() => {
        if (openingWebUI && appCred) {
            window.open(inAuthUrl);
            setOpeningWebUI(false)
        }
    }, [openingWebUI, appCred, inAuthUrl])

    if (!service) return <Redirect to={`${basePath}/${BLOCKCHAIN_BASE_PATH}`} />

    const copyableList: CopyableSetting[] = [
        {
            title: lt("name"),
            displayValue: service.name || "",
            disableCopy: true,
        }, {
            title: lt("id"),
            displayValue: service._id || '',
        }, {
            title: lt("status"),
            displayValue: service.state ? (
                <ResourceStateChip state={service.state} />
            ) : (
                ""
            ),
            disableCopy: true,
        }, {
            title: lt("owner"),
            displayValue: service.membership.name || "",
            disableCopy: true,
        }, {
            title: lt('createdOn'),
            displayValue: new Date(service.created_at || '').toLocaleString(),
            disableCopy: true,
        }
    ];

    if (service.urls?.http) {
        copyableList.push({
            title: lt('apiEndpoint'),
            displayValue: service.urls?.http || ''
        }) 
    }

    if (service.urls?.webui) {
        copyableList.push({
            title: lt('webUIEndpont'),
            displayValue: service.urls?.webui || ''
        }) 
    }

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("ipfsDocumentation"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://docs.kaleido.io/kaleido-services/ipfs/"
                ),
        }, {
            icon: <DescriptionIcon />,
            title: lt("ipfsFileStore"),
            value: lt("blog"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://www.kaleido.io/blockchain-platform/ipfs-file-store"
                ),
        },
    ];

    const onWebUIClick = () => {
        setOpeningWebUI(true);
    }
    
    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            { openingWebUI && 
                <CookieAppCred setErrorMessage={setMessage}
                    serviceId={service._id}
                    setCookieAppCred={setAppCred} 
                    membershipId={service.membership_id} />
            }
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt("ipfsOverview")}
                    </Typography>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item xs={8}>
                        <CopyableSettings
                            header={lt("information")}
                            {...{ copyableList }}
                        />
                    </Grid>
                    <Grid container item direction="column" xs={4} spacing={3}>
                        <Grid item>
                            <WebUiCard header={lt('webUI')} disabled={service.state !== 'started'}
                                imageFiles={'IPFS-Screen-2x.png'} 
                                description={lt('webUiDescription')}
                                onClick={onWebUIClick} />
                        </Grid>
                        <Grid item>
                            <DisplayCard
                                header={lt("documentation")}
                                itemList={DocumentationList}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    ipfsOverview: string
    name: string
    id: string
    status: string
    owner: string
    createdOn: string
    apiEndpoint: string
    webUI: string,
    webUIEndpont: string
    documentation: string
    information: string
    webUiDescription: string
    ipfsDocumentation: string
    ipfsFileStore: string
    blog: string
};

const enTranslations: translations = {
    name: 'Name',
    blog: 'Blog',
    information: 'Information',
    ipfsDocumentation: 'IPFS Documentation',
    ipfsFileStore: 'IPFS File Store',
    id: 'ID',
    status: 'Status',
    owner: 'Owner',
    createdOn: 'Created On',
    apiEndpoint: 'API Endpoint',
    ipfsOverview: 'IPFS Overview',
    webUI: 'Web UI',
    webUIEndpont: 'Web UI Endpoint',
    documentation: 'Documentation',
    webUiDescription: 'The Home screen within the IPFS UI will display specific details about the IPFS node. Namely it exposes: Peer ID, Protocol Version, Agent Version and Public Key.'
};