import React, { useState, useMemo } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { ConsortiaData, ConsortiaQuery, OrganizationData, OrganizationVars, OrganizationQuery, PlanSupports, ConsortiumZonesData, ConsortiumZonesQuery, StaticConfigData, StaticConfigQuery } from '../../models'
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import { EditableSettings } from '../../components/DisplaySettings'
import { UpdateName, DeleteResource } from '../../components/DialogWrappers'
import { ConsortiumResourcesVars } from '../../interfaces';
import { NETWORK_REGIONS_PATH } from '../../components/MainNav/ConsortiumWrapper';

export const Settings: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NetworkSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NetworkSettings:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();
    const client = useApolloClient()

    const {
        loading,
        data: { 
            consortia
        } = { consortia: [] } 
    } = useQuery<ConsortiaData>(ConsortiaQuery, { fetchPolicy: 'cache-only' });

    const consortium = consortia.find(c => c._id === consortium_id!)

    const {
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            staticConfig: {
                zoneRegions: regions
            }
        } = { staticConfig: { zoneRegions: [] } }
    } = useQuery<StaticConfigData>(StaticConfigQuery, { 
        fetchPolicy: 'cache-first'
    });

    // direct read from cache
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsMultiRegion = useMemo(() => PlanSupports.multiRegion(organization), [organization])

    const canAddRegion = useMemo(() => {
        return supportsMultiRegion && regions.length > consortiumZones.length
    }, [supportsMultiRegion, consortiumZones.length, regions.length])

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    if (loading || !consortium) return <CircularProgress />

    const actionsList = [
        {
            icon: <AccountCircleIcon />,
            title: lt('changeName'),
            description: lt('nameDescription'),
            value: consortium.name,
            buttonLabel: lt('change'),
            action: () => setUpdateNameDialogOpen(true)
        },
        {
            icon: <MapMarkerOutlineIcon />,
            title: lt('addRegion'),
            description: !supportsMultiRegion ? lt('multiRegionNotSupported') : (canAddRegion ? lt('addRegionDescription') : lt('noRegionsToAdd')),
            value: consortiumZones.map(c => `${c.cloud.toUpperCase()}: ${c.region}`).join(', '),
            buttonLabel: lt('addRegion'),
            action: () => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/${NETWORK_REGIONS_PATH}/create/1`),
            disabledButton: !canAddRegion
        },
        {
            icon: <DeleteOutlineIcon />,
            title: lt('deleteConsortium'),
            description: lt('deleteDescription'),
            buttonLabel: lt('delete'),
            action: () => setDeleteDialogOpen(true)
        }
    ]

    return (
        <>
            <UpdateName defaultName={consortium.name} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <DeleteResource name={consortium.name} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} closeDialogAfterSave />

            <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">
                        {lt('consortiumSettings')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <EditableSettings
                        header={lt('consortiumSettings')}
                        {...{actionsList}} />
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    consortiumSettings: string
    changeName: string
    deleteConsortium: string
    name: string
    delete: string
    change: string
    nameDescription: string
    deleteDescription: string
    addRegion: string
    multiRegionNotSupported: string
    addRegionDescription: string
    noRegionsToAdd: string
}
const enTranslations: translations = {
    consortiumSettings: 'Network Settings',
    changeName: 'Change network name',
    deleteConsortium: 'Delete network',
    name: 'Name',
    delete: 'Delete',
    change: 'Change',
    nameDescription: 'Change the name that identifies the network.',
    deleteDescription: 'Delete the network.',
    addRegion: 'Add Region',
    multiRegionNotSupported: 'Multi region environments are not available at your current plan level.',
    addRegionDescription: 'Add additional deployment regions',
    noRegionsToAdd: 'No additional deployment regions are available.'
}