import { gql } from '@apollo/client';
import { ServiceResourcesVars } from '../interfaces';

// INTERFACES

export interface RotatesignersConfiguration {
    rotationInterval?: number
    targetSignerCount?: number
    rotationSize?: number
    rotationAlgorithm?: 'oldest-first'
}

export interface RotatesignersConfigurationData {
    rotatesignersConfiguration: RotatesignersConfiguration
}

export interface RotatesignersStatus {
    urls: JSON
    status: string
    release: string
    isActivated: boolean
    lastRotationTime: string
}

export interface RotatesignersStatusData {
    rotatesignersStatus: RotatesignersStatus
}

export interface RotatesignersSignerInfo {
    signer: string
    lastActive: string
}

export interface RotatesignersCurrentSignersData {
    rotatesignersCurrentSigners: RotatesignersSignerInfo[]
}

export interface RotatesignersNextSignersData {
    rotatesignersNextSigners: RotatesignersSignerInfo[]
}

// MUTATION INTERFACES

export interface UpdateRotatesignersConfigurationVars extends ServiceResourcesVars {
    configuration: RotatesignersConfiguration
}

// FRAGMENTS

export const RotatesignersConfigurationFields = ` 
    fragment RotatesignersConfigurationFields on RotatesignersConfiguration {
        rotationInterval
        targetSignerCount
        rotationSize
        rotationAlgorithm
    }`

export const RotatesignersStatusFields = ` 
    fragment RotatesignersStatusFields on RotatesignersStatus {
        urls
        status
        release
        isActivated
        lastRotationTime
    }`

export const RotatesignersSignerInfoFields = ` 
    fragment RotatesignersSignerInfoFields on RotatesignersSignerInfo {
        signer
        lastActive
    }`    

// QUERIES & SUBSCRIPTIONS

export const RotatesignersConfigurationQuery = gql`
    ${RotatesignersConfigurationFields}
    query rotatesignersConfiguration($service_id: String!) {
        rotatesignersConfiguration(service_id: $service_id) {
            ...RotatesignersConfigurationFields
        }
    }`;

export const RotatesignersStatusQuery = gql`
    ${RotatesignersStatusFields}
    query rotatesignersStatus($consortia_id: String!, $environment_id: String!, $service_id: String!) {
        rotatesignersStatus(consortia_id: $consortia_id, environment_id: $environment_id, service_id: $service_id) {
            ...RotatesignersStatusFields
        }
    }`;

export const RotatesignersCurrentSignersQuery = gql`
    ${RotatesignersSignerInfoFields}
    query rotatesignersCurrentSigners($service_id: String!) {
        rotatesignersCurrentSigners(service_id: $service_id) {
            ...RotatesignersSignerInfoFields
        }
    }`;

export const RotatesignersNextSignersQuery = gql`
    ${RotatesignersSignerInfoFields}
    query rotatesignersNextSigners($service_id: String!) {
        rotatesignersNextSigners(service_id: $service_id) {
            ...RotatesignersSignerInfoFields
        }
    }`;

// MUTATIONS

export const UpdateRotatesignersConfigurationMutation = gql`
    ${RotatesignersConfigurationFields}
    mutation updateRotatesignersConfiguration($service_id: String!, $configuration: RotatesignersConfigurationInput!) {
        updateRotatesignersConfiguration(service_id: $service_id, configuration: $configuration) {
            ...RotatesignersConfigurationFields
        }
    }`;

export const RotatesignersActivateMutation = gql`
    mutation rotatesignersActivate($service_id: String!) {
        rotatesignersActivate(service_id: $service_id) {
            success
        }
    }
`

export const RotatesignersDeactivateMutation = gql`
    mutation rotatesignersDeactivate($service_id: String!) {
        rotatesignersDeactivate(service_id: $service_id) {
            success
        }
    }
`