import { useApolloClient } from '@apollo/client';
import { Box, Grid, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, MessageSnackbar } from '../../../components/DialogWrappers';
import { NETWORK_CONTRACTS_CREATE_PATH, NETWORK_CONTRACTS_PATH } from "../../../components/MainNav/ConsortiumWrapper";
import { CreateStepProps } from '../../../interfaces';
import { OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, Protocol } from '../../../models';
import { Step1Help } from './Step1Help';

interface Props extends CreateStepProps {
    protocol: Protocol,
    setProtocol: (p: Protocol) => void,
};

export const Step1 = ({ protocol, setProtocol, cancelPath }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const [message, setMessage] = useState('');

    const client = useApolloClient();
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsFabric = useMemo(() => PlanSupports.fabric(organization), [organization])

    const makeProtocolRadio = (p: Protocol, label: string, description: string, link: string, beta: boolean) => {
        return (
            <Grid item>
                <Paper className={protocol === p ? classes.radioPaperSelected : classes.radioPaper } elevation={0} onClick={() => setProtocol(p)}>
                    <Grid container direction="row" alignItems="center" alignContent="center" justify='center' spacing={2} wrap='nowrap'>
                        <Grid item className={classes.logoWidth}>
                        {beta && <Grid item>
                                     <Box component="span" className={classes.betaTag}>{lt('beta')}</Box>
                                 </Grid>}
                            <img className={classes.logoCentered} src={`${process.env.PUBLIC_URL}/img/protocols/${p}-logo.svg`} alt={lt('logo', {label})} ></img>
                        </Grid>
                        <Grid item container direction='column' spacing={1}>
                            <Grid item>
                                <Typography variant="h6" color="textPrimary">
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="textSecondary">
                                    {description}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link href={link} target="_blank">{lt('learnMore')}</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const save = async () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${NETWORK_CONTRACTS_CREATE_PATH}/2`, {
            protocol,
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item container direction="column">
                <Grid container direction="row" spacing={1}>
                    {makeProtocolRadio(
                        'ethereum',
                        lt('ethereum'),
                        lt('ethereumDescription'),
                        'https://docs.kaleido.io/kaleido-platform/protocol/ethereum',
                        false)}
                    {makeProtocolRadio(
                        'corda',
                        lt('corda'),
                        lt('cordaDescription'),
                        'https://docs.kaleido.io/kaleido-platform/protocol/corda',
                        false)}
                    {supportsFabric && makeProtocolRadio(
                        'fabric',
                        lt('fabric'),
                        lt('fabricDescription'),
                        'https://docs.kaleido.io/kaleido-platform/protocol/fabric',
                        false)}
                </Grid>
            </Grid>

        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={false} onNext={save} isFirstStep />
            <Step1Help />
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    radioPaperSelected: {
        padding: theme.spacing(2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    logoWidth: {
        minWidth: '150px',
    },
    logoCentered: {
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    betaTag: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: '1px 3px 1px 3px',
        borderRadius: '2px',
        textTransform: 'uppercase',
        fontSize: 'small'
    },
}));

interface translations {
    header: string,
    headerDescription: string,
    ethereum: string,
    ethereumDescription: string,
    corda: string,
    cordaDescription: string,
    fabric: string,
    fabricDescription: string,
    beta: string,
    logo: string,
    learnMore: string,
}
const enTranslations: translations = {
    header: 'Select a Protocol',
    headerDescription: 'Which blockchain technology are you building for?',
    ethereum: 'Ethereum',
    ethereumDescription: 'Ethereum smart contracts are written in specialized smart contract programming languages, including Solidity. Then compiled into "bytecode" that is deployed onto the chain, alongside the transactions',
    corda: 'Corda',
    cordaDescription: 'Corda CorDapps are written in a restricted subset of Java or Kotlin, defining the flow, data and business logic of the transaction. Then compiled into Jar files that are signed and distributed to the nodes',
    fabric: 'Hyperledger Fabric',
    fabricDescription: 'Fabric Chaincode is a program, written in Go or Node.js that implements a prescribed interface. Chaincode runs in a separate process from the peer and initializes and manages the ledger state through transactions submitted by applications',
    beta: 'Beta',
    logo: '{{label}} Logo',
    learnMore: 'Learn More',
}