import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import AccountCashIcon from "mdi-react/AccountCashIcon";
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import AccountStarIcon from 'mdi-react/AccountStarIcon';
import AccountTieIcon from "mdi-react/AccountTieIcon";
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NavItem } from "../NavComponents";
import { OPS_MANAGE_NODE_PATH, OPS_MANAGE_SERVICE_PATH, OPS_PATH } from "../OpsNav/OpsNav";

export const OPS_ORG_PATH = "orgs";
export const OPS_ORG_RESOURCES_PATH = "resources";
export const OPS_ORG_AGREEMENT_PATH = "agreement";
export const OPS_ORG_BILLING_PATH = "billing";

export const OpsOrgNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "OpsOrgNav", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`OpsOrgNav:${key}`, interpolate);

    const {ops_org_id} = useParams<any>();

    const history = useHistory();
    const { pathname } = useLocation();

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(`/${OPS_PATH}/${OPS_ORG_PATH}`)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{lt('organizations')}</Typography>} 
                        disableTypography />
                </ListItem>

                <NavItem
                    icon={<AccountTieIcon />}
                    header={lt("overview")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_ORG_PATH}/${ops_org_id}`)}
                    itemIsActive={pathname.endsWith(ops_org_id)}
                />
                
                <NavItem
                    icon={<AccountHardHatIcon />}
                    header={lt("resources")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_ORG_PATH}/${ops_org_id}/${OPS_ORG_RESOURCES_PATH}`)}
                    itemIsActive={pathname.endsWith(OPS_ORG_RESOURCES_PATH) || pathname.includes(OPS_MANAGE_NODE_PATH) || pathname.includes(OPS_MANAGE_SERVICE_PATH)}
                />

                <NavItem
                    icon={<AccountStarIcon />}
                    header={lt("agreement")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_ORG_PATH}/${ops_org_id}/${OPS_ORG_AGREEMENT_PATH}`)}
                    itemIsActive={pathname.endsWith(OPS_ORG_AGREEMENT_PATH)}
                />

                <NavItem
                    icon={<AccountCashIcon />}
                    header={lt("billing")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_ORG_PATH}/${ops_org_id}/${OPS_ORG_BILLING_PATH}`)}
                    itemIsActive={pathname.endsWith(OPS_ORG_BILLING_PATH)}
                />

            </List>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    toolbar: {
        [theme.breakpoints.up("md")]: theme.mixins.toolbar,
    },
    backButton: {
        paddingLeft: theme.spacing(1),
    },
    backIcon: {
        minWidth: 0,
    },
}));

interface translations {
    organizations: string;
    organization: string;
    overview: string;
    resources: string;
    agreement: string;
    billing: string;
}

const enTranslations: translations = {
    organizations: 'Organizations',
    organization: 'Organization',
    overview: 'Overview',
    resources: 'Resources',
    agreement: 'Agreement',
    billing: 'Billing',
};
