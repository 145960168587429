import React, { useEffect, useState, useCallback } from 'react';
import { TableRow, TableCell, Typography, makeStyles } from '@material-ui/core';
import { Service, Node, NodeStatusData, NodeStatusQuery, NodeData, NodeQuery, RuntimeStateTranslationInterface, RuntimeStateTranslation, ServicesEnum } from '../../models';
import { useTranslation } from 'react-i18next';
import { Config } from '../../models/configs';
import { useQuery, useLazyQuery } from '@apollo/client';
import { EnvironmentResourceVars } from '../../interfaces';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Dotdotdot } from '../../components';
import clsx from 'clsx';
import { NodeStatus } from './NodeStatus';
import { ServiceStatus } from './ServiceStatus';
import { instanceOfService } from '../../utils/TypeUtils';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_CLOUD_PATH, KEY_MANAGEMENT_MANAGED_PATH } from '../../components/MainNav/SideNavs/KeyManagement';

interface Props {
    runtime: Service | Node,
    config: Config,
    setRuntimesToApply: React.Dispatch<React.SetStateAction<string[]>>,
    onApplyClick: (runtimeId: Node | Service) => void,
    onDetachClick: (runtimeId: Node | Service) => void
}


export const RuntimesAttachedRow = ({runtime, onApplyClick, config, setRuntimesToApply, onDetachClick}: Props) => {
    const classes = useStyles();
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConfigInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConfigInfo:${key}`, interpolate);
    
    const [isApplied, setIsApplied] = useState(false);
    const [isRestarting, setIsRestarting] = useState(false);

    const { environment_id, consortium_id, config_id, org_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation()
    
    const queryVariables = {
        environment_id: environment_id!,
        consortia_id: consortium_id!,
        id: runtime._id
    }
    
    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    
    //Services routes should be add here as they come along
    const serviceURL: Map<keyof typeof ServicesEnum, string> = new Map([
        [ServicesEnum.cloudhsm, `${basePath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/${runtime._id}`],
        [ServicesEnum.ethwallet, `${basePath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.ethwallet}/${runtime._id}`]
    ])

    const {
        data: { 
            node
        } = { node: null } 
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: { 
            ...queryVariables
        },
        fetchPolicy: 'cache-only'
    });
    
    const [getNodeStatus, {
        data : {
            nodeStatus
        } = {nodeStatus: null}
    }] = useLazyQuery<NodeStatusData, EnvironmentResourceVars>(NodeStatusQuery, {
        variables: {
            ...queryVariables,
        },
        fetchPolicy: 'network-only'
    });
    
    useEffect(() => {
        if (node) {
            if(node.state === 'started') {
                setIsRestarting(false);
                getNodeStatus()
            } else {
                setIsRestarting(true);
            }
        }
    }, [node, getNodeStatus]);
    
    const removeRuntime = useCallback((runtimeId: string) => {
        setRuntimesToApply(c => {
            const runtimes = [...c];
            const index = runtimes.indexOf(runtimeId);
            if (index > -1) {
                runtimes.splice(index, 1);
            }    
            return runtimes
        })
    }, [setRuntimesToApply])

    useEffect(() => {
        if (config.type === 'backup' || config.type === 'storage' || config.type === 'cloudhsm') {
            setIsApplied(true);
        } else if (nodeStatus?.configVersions) {
            if(nodeStatus.configVersions[config.type]?.rev === config._revision && nodeStatus.configVersions[config.type]?.id === config._id  ){
                setIsApplied(true);
                removeRuntime(runtime._id);
            } else {
                setRuntimesToApply(c => c.find(entry => entry === runtime._id) ? c : [...c, runtime._id]) // Need to find out why this re runs
            }
        }
        return () => {
            removeRuntime(runtime._id);
        }
    }, [nodeStatus, config_id, config, setRuntimesToApply, runtime, removeRuntime]);

    const viewService = () => {
        const path = serviceURL.get((runtime as Service).service);
        history.push(path || pathname); //This should never happen but its a security measure since most of the services have not been implemented
    }

    const menuItems = [{
        name: lt('viewRuntime'),
        action: instanceOfService(runtime) ? () => viewService() : () => history.push(`${basePath}/nodes/${runtime._id}`)
    },{
        name: lt('detach'),
        action: () => onDetachClick(runtime)
    }]

    if (!isApplied) {
        menuItems.push({
            name: lt('apply'),
            action: () => onApplyClick(runtime)
        })
    }

    const getName = () => {
        return instanceOfService(runtime) && runtime.service === 'idregistry' 
            ? lt('idRegistry') 
            : lt('noRuntimeName', {id: runtime._id})
    }

    return (
        <TableRow>
            <TableCell className={classes.tableCell}>
                <Typography noWrap variant="body2" color="inherit">
                    {runtime.name || getName()}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography noWrap variant="body2" color="inherit">
                    {instanceOfService(runtime) ? lt('service') : lt('node')}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography noWrap variant="body2" color="inherit">
                    {runtime._id}
                </Typography>
            </TableCell>
            <TableCell className={clsx(classes.tableCell, classes.maxWidth)}>
                {instanceOfService(runtime) ? 
                    <ServiceStatus {...{removeRuntime}} {...{isRestarting}} {...{setIsRestarting}} {...{setRuntimesToApply}} {...{isApplied}} {...{setIsApplied}} {...{config}} {...{runtime}} /> :
                    <NodeStatus {...{removeRuntime}} {...{isRestarting}} {...{setIsRestarting}} {...{setRuntimesToApply}} {...{isApplied}} {...{setIsApplied}} {...{config}} {...{runtime}} />}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                <Dotdotdot disabled={isRestarting} {...{menuItems}} />
            </TableCell>
        </TableRow>
    )
};

const useStyles = makeStyles((theme) => ({
    tableCell: {
        paddingLeft: theme.spacing(3)
    },
    maxWidth: {
        maxWidth: '100px'
    }
}));

interface translations extends RuntimeStateTranslationInterface {
    noRuntimeName: string,
    service: string,
    node: string,
    apply: string,
    restarting: string,
    idRegistry: string,
    detach: string
    viewRuntime: string
}

const enTranslations: translations = {
    ...RuntimeStateTranslation,
    noRuntimeName: 'No name was provided: {{id}}',
    service: 'Service',
    node: 'Node',
    apply: 'Apply Config',
    restarting: 'Restarting...',
    idRegistry: 'Id Registry',
    detach: 'Detach',
    viewRuntime: 'View Runtime'
}
