import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { ORGS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH } from '../../../components/ManageOrgNav/ManageOrgNav';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { OrganizationData, OrganizationVars, OrganizationQuery, PlanName } from '../../../models';
import { Step3 } from './Step3';
import { useApolloClient } from '@apollo/client';

export const ChangePlan: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SetupBillingProvider', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SetupBillingProvider:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const { step } = useParams<any>();
    const cancelPath = `/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}`;
    const client = useApolloClient();
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id! } })!

    type changePlanState = { plan: PlanName, supportLevel: number };
    const history = useHistory<changePlanState>();

    const {
        location: {
            state: {
                plan: selectedPlan,
                supportLevel: selectedSupportLevel
            } = { plan: organization.plan, supportLevel: organization.support_level }
        }
    } = history;

    const [plan, setPlan] = useState<PlanName>(selectedPlan);
    const [supportLevel, setSupportLevel] = useState<Number>(selectedSupportLevel);

    const stepComponents: { step: string; component: JSX.Element; }[] = [
        {
            step: lt('selectPlan'), component: <Step1 setPlan={setPlan} supportLevel={supportLevel} setSupportLevel={setSupportLevel} />
        },
        {
            step: lt('selectSupport'), component: <Step2 plan={plan} supportLevel={supportLevel} setSupportLevel={setSupportLevel} cancelPath={cancelPath} />
        },
        {
            step: lt('confirm'), component: <Step3 plan={plan} supportLevel={supportLevel} cancelPath={cancelPath} />
        }
    ];

    return (
        <FullScreenCreate toolbarHeader={lt('setupBillingProvider')} stepUrlParam={step!} cancelPath={cancelPath} stepComponents={stepComponents} />
    );
}

interface translations {
    setupBillingProvider: string
    selectPlan: string
    selectSupport: string
    confirm: string
}

const enTranslations: translations = {
    setupBillingProvider: 'Change Plan',
    selectPlan: 'Select Plan',
    selectSupport: 'Select Support',
    confirm: 'Confirm'
}