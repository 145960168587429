import React, { useState, useEffect } from "react";
import {
    CreateWrapper,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../components/DialogWrappers";
import { useHistory, useParams } from "react-router-dom";
import { SelfSignInfoDisplay } from "./SelfSignedInfoDisplay";
import { GenerateExtIdentity } from "./GenerateExtIdentity";
import { useMutation, useQuery } from "@apollo/client";
import {
    AddExternalCertificateMutation,
    CertificateProofInterface,
    AddExternalCertificateVars,
    CreateProofMutation,
    CreateProofVars,
    MembershipData,
    MembershipQuery,
    CertificateProofData,
    AddIdentityVars,
    AddIdentityMutation,
} from "../../models";
import { useLocalStorageState } from "../../hooks/useLocalStorageState";
import { ConsortiumResourceVars } from "../../interfaces";
import { CircularProgress } from "@material-ui/core";
import { Step1Help } from './Step1Help';

interface Props {
    cancelPath: string;
    isSelfSigned: boolean;
}

export const Step2 = ({ cancelPath, isSelfSigned }: Props) => {
    const [extCertificate, setExtCertificate] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [cert, setCert] = useLocalStorageState<CertificateProofInterface>(
        "cert"
    );

    const { org_id, membership_id, consortium_id } = useParams<any>();

    const { loading, data: { membership } = { membership: null } } = useQuery<
        MembershipData,
        ConsortiumResourceVars
    >(MembershipQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: membership_id!,
        },
        fetchPolicy: "cache-first",
    });

    const [addExternalCertMutation] = useMutation<
        CertificateProofData,
        AddExternalCertificateVars
    >(AddExternalCertificateMutation);

    const [createProofMutation] = useMutation<
        CertificateProofData,
        CreateProofVars
    >(CreateProofMutation);

    const [createIdentity] = useMutation<any, AddIdentityVars>(AddIdentityMutation);

    const history = useHistory();

    useEffect(() => {
        if (!cert && !isSelfSigned) {
            createProofMutation({
                variables: {
                    org_id: org_id!,
                    certificateProof: {
                        name: membership?.org_name ?? '',
                    },
                },
            })
                .then((res) => setCert(res.data?.createProof ?? undefined))
                .catch((err) => ErrorSnackbarCatcher(err, setErrorMessage));
        }
    }, [
        org_id,
        membership_id,
        createProofMutation,
        setErrorMessage,
        setCert,
        cert,
        isSelfSigned,
        membership
    ]);

    if (loading) return <CircularProgress />

    const onNext = async () => {
        if (isSelfSigned) {
            history.push(cancelPath);
        } else {
            if (cert) {
                try {
                    await addExternalCertMutation({
                        variables: {
                            org_id: org_id!,
                            id: cert._id,
                            certificateProof: {
                                payload: extCertificate,
                                type: "x509",
                            },
                        },
                    });
                    await createIdentity({
                        variables: {
                            consortia_id: consortium_id,
                            id: membership_id,
                            identity: {
                                proof_id: cert._id
                            }
                        }
                    })
                    history.push(cancelPath);
                } catch (err) {
                    ErrorSnackbarCatcher(err, setErrorMessage);
                }
            }
        }
    };

    const uniqueIdentifier = cert ? `${org_id}-${cert.nonce}--${membership?.org_name}` : '';

    return (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessage}
            />
            <CreateWrapper isLastStep
                {...{ cancelPath }}
                disabled={!isSelfSigned && !extCertificate}
                content={
                    isSelfSigned ? (
                        <SelfSignInfoDisplay {...{ cancelPath }} />
                    ) : (
                        <GenerateExtIdentity {...{ setExtCertificate }} {...{uniqueIdentifier}} />
                    )
                }
                {...{ onNext }}
                hideBack={isSelfSigned}
            />
            <Step1Help />
        </>
    );
};
