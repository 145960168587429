import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractProject } from '../../models'
import { DisplayCard } from '../../components/DisplayWrappers'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { useHistory, useParams } from 'react-router-dom';
import AppsIcon from 'mdi-react/AppsIcon';
import { NETWORK_CONTRACTS_PATH } from '../../components/MainNav/ConsortiumWrapper';

interface Props {
    contractProjects: ContractProject[]
    loading: boolean
};

export const Apps = ({ contractProjects, loading }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NetworkDashboardContractProjects', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NetworkDashboardContractProjects:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>()
    const history = useHistory()

    const envList = contractProjects.map(c => ({
        title: c.name, 
        value: c.membership.name,
        icon: <AppsIcon />,
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${c._id}`),
        actionIcon: <ChevronRightIcon />
    }))

    const linkButton = {
        text: lt('viewContractProjects'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}`)
    }
    
    return (
        <DisplayCard {...{loading}}
            header={lt('contractProjects', { count: envList.length })}
            itemList={envList}
            {...{linkButton}} />
    )
};

interface translations {
    contractProjects: string
    viewContractProjects: string
}
const enTranslations: translations = {
    contractProjects: 'Apps ({{count}})',
    viewContractProjects: 'View Apps'
}