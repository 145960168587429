import React, { useMemo, useEffect } from "react";
import { Grid, Typography, TextField, ExpansionPanel, ExpansionPanelSummary, AccordionDetails } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { PreFundedAccounts } from "./PreFundedAccounts";
import {
    EnvironmentConsensus,
    OrganizationData,
    OrganizationVars,
    OrganizationQuery,
    PlanSupports,
    EnvironmentPrefundedAccounts,
    EnvironmentProvider,
} from "../../models";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { FormLink } from "../../components/FormControls/FormLink";

interface Props {
    chainId: string;
    setChainId: React.Dispatch<React.SetStateAction<string>>;
    blockPeriod: string;
    setBlockPeriod: React.Dispatch<React.SetStateAction<string>>;
    prefundedAccounts: EnvironmentPrefundedAccounts[];
    consensus: EnvironmentConsensus;
    setPrefundedAccounts: React.Dispatch<
        React.SetStateAction<EnvironmentPrefundedAccounts[]>
    >;
    isFirefly: boolean
    provider: EnvironmentProvider
}

export const ProtocolConfigurations = ({
    chainId,
    setChainId,
    blockPeriod,
    setBlockPeriod,
    prefundedAccounts,
    setPrefundedAccounts,
    consensus,
    isFirefly,
    provider
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle(
        "en",
        "CreateEnvironmentCreateStep4",
        enTranslations
    );
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateEnvironmentCreateStep4:${key}`, interpolate);

    const usupportedBlockPeriod: Partial<
        {
            [key in EnvironmentConsensus]: boolean;
        }
    > = {
        raft: true,
    };

    const { org_id } = useParams<any>();

    const client = useApolloClient();
    const { organization } = client.cache.readQuery<
        OrganizationData,
        OrganizationVars
    >({ query: OrganizationQuery, variables: { id: org_id } })!;

    useEffect(() => {
        if (consensus === 'raft') {
            setBlockPeriod('N/A')
        } else if (consensus === 'ibft' && !isFirefly && provider !== 'polygon-edge') {
            setBlockPeriod('10')
        } else {
            setBlockPeriod('5')
        }
    }, [consensus, setBlockPeriod, isFirefly, provider])

    const supportsBlockPeriod = useMemo(() => PlanSupports.blockPeriod(organization), [organization])
    const supportsIBFT2Sec = useMemo(() => PlanSupports.ibft2sec(organization), [organization])

    const minBlockPeriod = (consensus === 'ibft' && supportsIBFT2Sec) ? 2 : 5;
    const maxBlockPeriod = (consensus === 'ibft') ? 20 : 10;
    const invalidPeriod = parseInt(blockPeriod) < minBlockPeriod || parseInt(blockPeriod) > maxBlockPeriod;

    const blockPeriodHelper: () => string = () => {
        if (!supportsBlockPeriod) return lt("availableOnEnterprise");
        if (invalidPeriod) return lt("blockPeriodRange", {minBlockPeriod,maxBlockPeriod});
        if (usupportedBlockPeriod[consensus]) return lt("unsupportedOnRaft");
        return "";
    };

    return (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt("header")}
                </Typography>
                <Typography variant="body2">
                    <Trans i18nKey="CreateEnvironmentCreateStep4:headerDescription"
                        components={[<FormLink onClick={() => window.open('https://docs.kaleido.io/using-kaleido/blockchain-advanced-configuration/')} />]}>
                    </Trans>
                </Typography>
            </Grid>
            <Grid item>
                {/*To prevent user from facing an error when accounts are empty */}
                <ExpansionPanel disabled={prefundedAccounts.length > 0 ? true : undefined} style={{backgroundColor: 'white'}}> 
                    <ExpansionPanelSummary expandIcon={<ChevronDownIcon />}>
                        <Typography variant="body2">{lt('advancedSettings')}</Typography>    
                    </ExpansionPanelSummary>
                    <AccordionDetails>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Typography variant="h6" gutterBottom>
                                    {lt("chainId")}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {lt("chainIdDescription")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                    <TextField
                                        fullWidth
                                        value={chainId}
                                        type="number"
                                        onChange={(e) => setChainId(e.target.value)}
                                        label={lt("id")}
                                        variant="outlined"
                                        InputProps={{inputProps: {min: 11, max: 4294967295}}}
                                    />
                            </Grid>
                            {!usupportedBlockPeriod[consensus] && (
                                <>
                                    <Grid item>
                                        <Typography variant="h6" gutterBottom>
                                            {lt("blockPeriod")}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {lt("blockPeriodDescription")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                            <TextField
                                                fullWidth
                                                value={blockPeriod}
                                                onChange={(e) =>
                                                    setBlockPeriod(e.target.value)
                                                }
                                                type="number"
                                                label={lt("period")}
                                                variant="outlined"
                                                disabled={!supportsBlockPeriod}
                                                helperText={blockPeriodHelper()}
                                                error={invalidPeriod}
                                            />
                                    </Grid>
                                </>
                            )}
                            <Grid item>
                                <Typography variant="h6" gutterBottom>
                                    {lt("prefundedAccounts")}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {lt("prefundedDescription")}
                                </Typography>
                            </Grid>
                            <PreFundedAccounts
                                {...{ prefundedAccounts }}
                                {...{ setPrefundedAccounts }}
                                />
                        </Grid>
                    </AccordionDetails>
                </ExpansionPanel>
            </Grid>
        </Grid>
    );
};

interface translations {
    header: string;
    headerDescription: string;
    prefundedDescription: string;
    chainId: string;
    blockPeriod: string;
    prefundedAccounts: string;
    unsupportedOnRaft: string;
    blockPeriodRange: string;
    availableOnEnterprise: string;
    advancedSettings: string
    chainIdDescription: string
    chainIdAndBlockPeriod: string
    id: string
    blockPeriodDescription: string
    period: string
}
const enTranslations: translations = {
    id: 'ID',
    chainIdDescription: 'By default a random large number will be generated, giving your chain its own unique ID. Or choose your own numeric ID.',
    header: "Advanced Settings (Optional)",
    advancedSettings: 'Customize your Chain ID, Block period and pre-funded accounts',
    headerDescription:
        "These options cannot be changed after the environment is created. <0>Learn More</0>",
    prefundedDescription:
        "All Ether in your chain will be private, and cannot be exchanged with Ether in the Ethereum main net. Custom funded accounts will be in addition to the Ether pool of 1 billion created automatically by Kaleido, with a UI and API for distribution.",
    chainId: "Chain ID",
    blockPeriod: "Block Period",
    prefundedAccounts: "Pre-Funded Accounts",
    unsupportedOnRaft: "Not Supported On Raft",
    blockPeriodRange: "Value must be between {{minBlockPeriod}} and {{maxBlockPeriod}}",
    availableOnEnterprise: "Only avaialable on Enterprise plan",
    chainIdAndBlockPeriod: 'Chain ID and Block Period',
    blockPeriodDescription: 'Blocks are mined regularly with your chosen consensus algorithm, with or without transactions. Transactions will be included in the next available block. Lower block times reduce latency, and higher block times aid throughput.',
    period: 'Period (seconds)'
};
