import { useQuery } from '@apollo/client';
import { capitalize, Chip } from '@material-ui/core';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import FileTreeIcon from 'mdi-react/FileTreeIcon';
import NewspaperIcon from 'mdi-react/NewspaperIcon';
import SignTextIcon from 'mdi-react/SignTextIcon';
import SitemapIcon from 'mdi-react/SitemapIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import { MANAGE_ORG_PATH, ORGS_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { SessionData } from '../../interfaces';
import { EnPlansTranslations, Organization, OrganizationData, OrganizationQuery, OrganizationsSuborgsData, OrganizationsSuborgsQuery, OrganizationVars, PlansEnum, PlansTranslations } from '../../models';
import { MFATypeData, MFATypeQuery } from '../../models/mfa';
import { RolesData, RolesQuery } from '../../models/roles';
import { SessionQuery } from '../../queries/Session';
import { LeaveOrg } from './LeaveOrg';

interface Props {
    org: Organization
}

export const Org: React.FC<Props> = ({ org }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgDetails:${key}`, interpolate);
    const history = useHistory();

    const [leaveOrgOpen, setLeaveOrgOpen] = useState(false);

    const { data: { session } = { session: undefined } } = useQuery<SessionData>(SessionQuery, { fetchPolicy: 'cache-only' });

    const { data: { mfaType } = { mfaType: '' } } = useQuery<MFATypeData>(MFATypeQuery);

    const { 
        data: {
            organization: masterOrg,
        } = { organization: undefined }
    } = useQuery<OrganizationData, OrganizationVars>(OrganizationQuery, {
        skip: !org.master_org,
        variables: {
            id: org.master_org!
        },
        fetchPolicy: 'cache-only'
    });

    const { 
        data: {
            organizationSuborgs: subOrgs,
        } = { organizationSuborgs: [] }
    } = useQuery<OrganizationsSuborgsData, OrganizationVars>(OrganizationsSuborgsQuery, {
        variables: {
            id: org._id,
        },
        fetchPolicy: 'cache-and-network'
    });

    const isOwner = org.owner === session?.user_id;
    const {
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, {
        skip: isOwner,
        variables: { org_id: org._id },
        fetchPolicy: 'cache-and-network' }
    );
    const role = roles.find(r => r.user_id === session?.user_id);

    const mfaBlocked = mfaType !== 'totp' && org.mfa_required;

    let itemList: DisplayCardListItem[] = [
        {
            title: lt('type'),
            value: org.master_org ? lt('subOrg', masterOrg || {name: org.master_org}) : `${lt('rootOrg')}${org.type === 'oidc' ? lt('oidc') : ''}`,
            icon: <SitemapIcon />,
        },
        {
            title: lt('subscription'),
            value: lt(PlansEnum[org.plan]),
            icon: <NewspaperIcon />
        },
        {
            title: lt('delegate'),
            value: org.delegate,
            icon: <EmailOutlineIcon />,
        }
    ];

    let itemListCol2: DisplayCardListItem[] = [];
    if (subOrgs?.length) itemListCol2.push({
        title: lt('subOrgs'),
        value: subOrgs.length,
        icon: <FileTreeIcon />,
    });
    itemListCol2.push({
        title: lt('role'),
        value: isOwner ? lt('owner') : capitalize(role?.role || ''),
        icon: <CardAccountDetailsOutlineIcon />,
    }, {
        title: lt('id'),
        value: org._id,
        icon: <SignTextIcon />,
    });

    const generateActionLinkButton = () => {
        if (session?.user_id === org.owner || role?.role === 'admin') {
            return {
                text: lt('manage'),
                onClick: () => history.push(`/${ORGS_PATH}/${org._id}/${MANAGE_ORG_PATH}`)
            };
        } else if (session?.realm !== 'oidc') {
            return {
                text: lt('leaveOrg'),
                onClick: () => setLeaveOrgOpen(true)
            };
        }
    };

    const viewNetworksButton = !mfaBlocked? {
        text: lt('viewNetworks'),
        onClick: () => history.push(`/${ORGS_PATH}/${org._id}`)
    } : undefined

    const manageActionsButton = generateActionLinkButton()
    const linkButton = manageActionsButton ? manageActionsButton : viewNetworksButton
    const linkButton2 = manageActionsButton ? viewNetworksButton : undefined

    return (
        <>
            <DisplayCard
                header={org.name}
                actionBar={mfaBlocked? <Chip icon={<AlertCircleOutlineIcon />} label={lt('mfaRequired')} /> : undefined}
                itemList={itemList}
                itemListCol2={itemListCol2}
                linkButton={linkButton}
                linkButton2={linkButton2}
            />
            <LeaveOrg open={leaveOrgOpen} setOpen={setLeaveOrgOpen} org={org} />
        </>);
};

interface translations extends PlansTranslations {
    delegate: string
    subscription: string
    users: string
    viewNetworks: string
    manage: string
    leaveOrg: string
    mfaRequired: string
    type: string
    subOrg: string
    subOrgs: string
    rootOrg: string
    owner: string
    role: string
    id: string
    oidc: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    delegate: 'Primary Contact Email',
    subscription: 'Subscription',
    users: 'Users',
    viewNetworks: 'View Networks',
    manage: 'Manage Organization',
    leaveOrg: 'Leave Org',
    mfaRequired: 'MFA Required',
    type: 'Type',
    rootOrg: 'Root Organization',
    subOrg: 'Sub Org ({{name}})',
    subOrgs: 'Sub-Orgs',
    owner: 'Owner',
    role: 'Role',
    id: 'ID',
    oidc: ' (using OIDC login)'
}