import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { NodesData, NodesQuery, ServicesData, ServicesQuery, EnvironmentData, EnvironmentQuery, ServicesEnum, ServicesTranslations, EnServicesTranslations, ReleaseData, ReleaseVars, ReleaseQuery } from '../../models'
import { Typography, Grid, Button} from "@material-ui/core";
import { EnvironmentResourcesVars, ConsortiumResourceVars } from '../../interfaces';
import { BLOCKCHAIN_BASE_PATH } from '../../components/MainNav/SideNavs/Blockchain';
import { NodesCard } from '../../components/NodesCard/NodesCard';
import { ServicesCard } from '../BlockchainDashboard/ServicesCard';
import { FireflyServiceCard } from '../BlockchainDashboard/FireflyServiceCard';
import { EmptyState } from '../../components/DisplayWrappers';
import { FIREFLY_SERVICE_PATH, FIREFLY_BASE_PATH } from '../../components/MainNav/SideNavs/Firefly';

export const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FireflyDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`FireflyDashboard:${key}`, interpolate)

    const history = useHistory()

    const { consortium_id, environment_id, org_id } = useParams<any>();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const environmentResourcesVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });


    const { data: { release } = { release: null } } = useQuery<
        ReleaseData,
        ReleaseVars
    >(ReleaseQuery, {
        skip: !environment,
        variables: {
            id: environment?.release_id!,
        },
        fetchPolicy: 'cache-and-network',
    });

    let {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-only'
    });
    
    nodes = nodes.filter(n => n.membership.isMine || n.role === 'monitor').sort((a) => a.membership.isMine ? -1 : 1);
    const ipfsInstances = useMemo(() => {
        return services.filter(s => s.service === ServicesEnum.ipfs && s.membership.isMine);
    }, [services]);

    const fireflyInstances = useMemo(() => {
        return services.filter(s => s.service === ServicesEnum['firefly-os'] && s.membership.isMine);
    }, [services]);

    const createNode = () => history.push(`${basePath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/create/1`)
    
    return !fireflyInstances.length ? (
        <EmptyState
            imageFile="Empty-Firefly.svg"
            title={lt('addFireflyNode')}
            description={lt('fireflyCreateNodeDescription')}
            button={{
                text: lt('createNode'),
                onClick: createNode,
                disabled: environment?.state !== 'live',
            }}
            documentation={{
                text: lt('emptyDocumentation'),
                link: 'https://docs.kaleido.io/kaleido-platform/firefly/',
            }}
        />
    ) : (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">{lt('dashboard')}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={createNode}
                        disabled={
                            !!(
                                release &&
                                release.supported_features &&
                                release.supported_features.fireflyVersion !==
                                    '1.2'
                            )
                        }
                    >
                        {lt('createNode')}
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={3}>
                {fireflyInstances.map((service) => (
                    <Grid
                        key={service._id}
                        item
                        container
                        lg={4}
                        sm={6}
                        xs={12}
                    >
                        <FireflyServiceCard {...{ service }} fireflyService />
                    </Grid>
                ))}

                <Grid item container lg={4} sm={6} xs={12}>
                    <NodesCard {...{ nodes }} hideCreateNode />
                </Grid>

                <Grid item container lg={4} sm={6} xs={12}>
                    <ServicesCard
                        services={ipfsInstances}
                        emptyHeader={lt(ServicesEnum.ipfs)}
                        emptyImagePath="Empty-IPFS.svg"
                        emptyDescription={lt('emptyDescriptionIpfs')}
                        servicePath={`${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}`}
                        serviceInfoLink={
                            'https://docs.kaleido.io/kaleido-services/ipfs/'
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface translations extends ServicesTranslations {
    dashboard: string,
    environment: string,
    emptyDocumentation: string
    emptyDescriptionIpfs: string
    addFireflyNode: string
    fireflyCreateNodeDescription: string
    createNode: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    dashboard: 'Dashboard',
    environment: 'Environment: {{name}}',
    emptyDocumentation: 'Documentation',
    emptyDescriptionIpfs: 'IPFS nodes leverage a peer-to-peer file sharing protocol and present a decentralized, censorship resistant mechanism for storing large files and data structures off-chain.',
    addFireflyNode: 'Add FireFly Node',
    fireflyCreateNodeDescription: 'Get started with Hyperledger FireFly by creating your first SuperNode',
    createNode: 'Create FireFly Node'
}