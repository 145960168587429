import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { ErrorSnackbarCatcher, FullScreenCreate, MessageSnackbar } from '../../../components/DialogWrappers';
import { NODE_EVENTSTREAMS_PATH } from '../../../components/NodeNav/NodeNav';
import { NodeResourceVars } from '../../../interfaces';
import { CreateEditEventStream, EventStreamData, EventStreamQuery, UpdateEventStreamData, UpdateEventStreamMutation, UpdateEventStreamVars } from '../../../models/eventStreams';
import { Step2Webhook } from './Step2Webhook';
import { Step2WebSocket } from './Step2WebSocket';

export const Edit: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamsCreateEventStream', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamsCreateEventStream:${key}`, interpolate)

    const { pathname } = useLocation()
    const history = useHistory();
    const { org_id, consortium_id, environment_id, node_id, eventstream_id, step } = useParams<any>();

    const {
        loading: eventStreamLoading,
        data: {
            eventStream
        } = { eventStream: null },
    } = useQuery<EventStreamData, NodeResourceVars>(EventStreamQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_id: node_id,
            id: eventstream_id
        },
        fetchPolicy: 'cache-first'
    });

    const [message, setMessage] = useState('');

    const [updateEventStream, { loading }] = 
        useMutation<UpdateEventStreamData, UpdateEventStreamVars>(UpdateEventStreamMutation)

    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_EVENTSTREAMS_PATH}`

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const invalidStep = step !== "1"

    if (eventStreamLoading) return <CircularProgress/>
    if (!eventStream) return <Redirect to={redirectToStep1} />

    if (invalidStep) {
        return <Redirect to={redirectToStep1} />
    }

    const {name,type} = eventStream

    const save = async (updatedEventStream: CreateEditEventStream) => {
        // create the event stream
        updateEventStream({
            variables: {
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                node_id: node_id!,
                id: eventStream.id,
                eventStream: updatedEventStream,
            }
        }).then(result => {
            if (result) {
                const newEventStreamId = result.data?.updateEventStream?.id
                if (newEventStreamId) {
                    history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_EVENTSTREAMS_PATH}/${newEventStreamId}`)
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const stepComponents = [
        { step: lt('setupStream'), component:
            type === 'webhook'
                ? <Step2Webhook {...{name}} {...{type}} cancelPath={cancelPath} existing={eventStream} {...{save}} {...{loading}}/>
                : <Step2WebSocket {...{name}} {...{type}} cancelPath={cancelPath} existing={eventStream} {...{save}} {...{loading}}/>
        },
    ]

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('updateEventStream')} stepUrlParam={step!} {...{stepComponents}} />
    </>
};

interface translations {
    updateEventStream: string,
    details: string,
    setupStream: string
}
const enTranslations: translations = {
    updateEventStream: 'Update Event Stream',
    details: 'Details',
    setupStream: 'Setup Stream'
}