import React, { useState, useEffect } from 'react';
import { 
    List,
    makeStyles,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { MembershipQuery, MembershipData, EnvironmentData, EnvironmentQuery } from '../../models';
import { useParams, useHistory, useLocation, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { SubNavs } from '../MainNav/SideNavs/SubNavs';
import { MembershipPicker } from './MembershipPicker';
import { ConsortiumResourceVars } from '../../interfaces';
import { SYSTEM_MEMBERSHIP } from '../MainNav/SideNavs/AddressBook';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';

export const MEMBERSHIP_RUNTIMES_PATH = 'runtimes'
export const MEMBERSHIP_DESTINATIONS_PATH = 'destinations'
export const MEMBERSHIP_SIGNING_ACCOUNTS_PATH = 'signingaccounts'
export const MEMBERSHIP_ON_CHAIN_PROFILE_PATH = 'onchainprofile'

const MembershipNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MembershipNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MembershipNav:${key}`, interpolate)

    const DETAILS = lt('details');
    const RUNTIMES = lt('runtimes')
    const DESTINATIONS = lt('destinations')
    const SIGNING_ACCOUNTS = lt('signingAccounts')
    const ON_CHAIN_PROFILE = lt('onChainProfile')

    const history = useHistory()
    const { org_id, consortium_id, environment_id, membership_id, runtime_id, address, destination_uri } = useParams<any>();
    const { pathname } = useLocation();
    
    const [selectedItem, setSelectedItem] = useState<string>();

    useEffect(() => {
        if (pathname.endsWith(membership_id!)) setSelectedItem(DETAILS)
        if (pathname.endsWith(runtime_id!)) setSelectedItem(RUNTIMES)
        if (pathname.endsWith(MEMBERSHIP_RUNTIMES_PATH)) setSelectedItem(RUNTIMES)
        if (pathname.endsWith(destination_uri!)) setSelectedItem(DESTINATIONS)
        if (pathname.endsWith(MEMBERSHIP_DESTINATIONS_PATH)) setSelectedItem(DESTINATIONS)
        if (pathname.endsWith(address!)) setSelectedItem(SIGNING_ACCOUNTS)
        if (pathname.endsWith(MEMBERSHIP_SIGNING_ACCOUNTS_PATH)) setSelectedItem(SIGNING_ACCOUNTS)
        if (pathname.endsWith(MEMBERSHIP_ON_CHAIN_PROFILE_PATH)) setSelectedItem(ON_CHAIN_PROFILE)
    }, [pathname, membership_id, DETAILS, RUNTIMES, DESTINATIONS, ON_CHAIN_PROFILE, SIGNING_ACCOUNTS, runtime_id, address, destination_uri ])

    const {
        data: { 
            membership
        } = { membership: null } 
    } = useQuery<MembershipData, ConsortiumResourceVars>(MembershipQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            id: membership_id!
        },
        fetchPolicy: 'cache-only'
    });

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    const baseMembershipsPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/addressbook/memberships`

    const historyPusher = (path?: string) => history.push(`${baseMembershipsPath}/${membership_id}${path ? `/${path}` : ''}`)

    let tabs = [
        {
            name: DETAILS,
            action: () => historyPusher()
        },
        {
            name: RUNTIMES,
            action: () => historyPusher(MEMBERSHIP_RUNTIMES_PATH)
        },
        {
            name: DESTINATIONS,
            action: () => historyPusher(MEMBERSHIP_DESTINATIONS_PATH)
        }
    ]
    if (!isCorda && !isFabric) {
        tabs.push({
            name: SIGNING_ACCOUNTS,
            action: () => historyPusher(MEMBERSHIP_SIGNING_ACCOUNTS_PATH)
        },
        {
            name: ON_CHAIN_PROFILE,
            action: () => historyPusher(MEMBERSHIP_ON_CHAIN_PROFILE_PATH)
        })
    }

    if (!membership && membership_id !== SYSTEM_MEMBERSHIP) {
        return <Redirect to={baseMembershipsPath} />
    }

    if (membership_id === SYSTEM_MEMBERSHIP) {
        tabs = tabs.filter(t => t.name !== DESTINATIONS && t.name !== ON_CHAIN_PROFILE)
    }

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(baseMembershipsPath)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{lt('addressBook')}</Typography>} 
                        disableTypography />
                </ListItem>

                <ListItem>
                    <ListItemText secondary={lt('membership')} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>
                
                <MembershipPicker />

                <SubNavs list={tabs} selected={true} {...{selectedItem}} />
            </List>
        </div> 
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    backButton: {
        paddingLeft: theme.spacing(1)
    },
    backIcon: {
        minWidth: 0
    }
}));

interface translations {
    details: string
    runtimes: string
    destinations: string
    signingAccounts: string
    onChainProfile: string
    addressBook: string
    membership: string
}
const enTranslations: translations = {
    details: 'Details',
    runtimes: 'Runtimes',
    destinations: 'Destinations',
    signingAccounts: 'Signing Accounts',
    onChainProfile: 'On-Chain Profile',
    addressBook: 'Address Book',
    membership: 'Membership'
}

export { MembershipNav };

