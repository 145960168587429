import React from 'react';
import { AuditEntry } from '../../models/audit';
import { Membership } from '../../models'

interface Props {
    auditEntry: AuditEntry
    consortiumMemberships: Membership[]
}

export const ActivityOwner = ({ auditEntry, consortiumMemberships }: Props) => {
    const getOwner = (entry: AuditEntry): string => {
        switch (entry.objectType) {
            case 'compiled_contracts': // handled in next case
            case 'configurations': // handled in next case
            case 'appcreds': // handled in next case
            case 'services': return consortiumMemberships.find(membership => membership._id === entry.data.membership_id)?.org_name ?? '--';
            case 'consortia': return entry.data.owner_org_name;
            case 'nodes': return entry.data.membership_name ?? '--';
            case 'memberships': return entry.data.org_name ?? '--';
            default: return '--'
        }
    };

    // calling component can wrap this however it likes
    return <>{getOwner(auditEntry)}</> 
};
