import React, { useState } from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { TransactionQuery, TransactionData, TransactionVars } from '../../models';
import { useTranslation } from 'react-i18next';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { Grid, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, makeStyles, CircularProgress } from '@material-ui/core';
import { TransactionLog } from './TransactionLog';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_BLOCKS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import moment from 'moment';
import { FormLink } from '../../components/FormControls/FormLink';


export const TransactionInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransactionInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransactionInfo:${key}`, interpolate);

    const [inputData, setInputData] = useState('hex');

    const {environment_id, consortium_id, transaction_hash, org_id} = useParams<any>();

    const { pathname } = useLocation()
    const redirectUrl = pathname.replace(`${transaction_hash}`, '');

    const classes = useStyles();

    const {
        loading,
        data: {
            transaction
        } = {transaction: null}
    } = useQuery<TransactionData, TransactionVars>(TransactionQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            hash: transaction_hash!
        }
    });

    if (!loading && !transaction) return <Redirect to={redirectUrl} />;
    if (!transaction) return <CircularProgress />;

    const copyableList : CopyableSetting[] = [
        {
            title: lt('hash'),
            displayValue: transaction.hash ?? '',
            copyableValue: transaction.hash ?? ''
        },{
            title: lt('block'),
            displayValue:
                <FormLink to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}/${transaction.blockNumber?.toString()}`}>
                    {transaction.blockNumber?.toString() ?? ''}
                </FormLink>,
            copyableValue: transaction.blockNumber?.toString() ?? ''
        },{
            title: lt('from'),
            displayValue: <ShortenedHash address={transaction.from ?? ''} />,
            copyableValue: transaction.from ?? ''
        },
    ]

    if (transaction.to) {
        copyableList.push({
            title: lt('to'),
            displayValue: <ShortenedHash address={transaction.to} />,
            copyableValue: transaction.to
        })
    }

    if (transaction.contractAddress) {
        copyableList.push({
            title: lt('contractAddress'),
            displayValue: <ShortenedHash address={transaction.contractAddress} />,
            copyableValue: transaction.contractAddress
        })
    }

    copyableList.push({
        title: lt('gas'),
        displayValue: transaction.gasProvided?.toString() ?? '',
        copyableValue: transaction.gasProvided?.toString() ?? ''
    },{
        title: lt('status'),
        displayValue: transaction.status === 'success' ? <ResourceStateChip state="success" /> : <ResourceStateChip state='failed' />,
        disableCopy: true
    },{
        title: lt('timestamp'),
        displayValue: transaction.timestamp ? `${new Date(transaction.timestamp).toLocaleString()} (${moment(transaction.timestamp).fromNow()})` : '--',
        copyableValue: transaction.timestamp ?? '',
        disableCopy: true
    });

    if(transaction.method) {
        copyableList.push({
            title: lt('method'),
            displayValue: transaction.method,
            copyableValue: transaction.method
        })
    }

    if(transaction.inputs) {
        copyableList.push({
            title: lt('inputs'),
            displayValue: <TextField multiline value={JSON.stringify(transaction.inputs, null, 4)} fullWidth variant="outlined" rows={3} inputProps={{className: classes.multiline}} />,
            copyableValue: JSON.stringify(transaction.inputs)
        })
    }

    const InputDataSelector = (
        <FormControl fullWidth>
            <FormLabel component="legend">{lt('inputData')}</FormLabel>
            <RadioGroup value={inputData} onChange={e => setInputData(e.target.value)}> 
                <FormControlLabel value="hex" control={<Radio color="primary" />} label={lt('hexViewer')} />
                <FormControlLabel value='bytecode' control={<Radio color="primary" />} label={lt('hex')} />
            </RadioGroup>
        </FormControl>
    )

    if(transaction.hexData){
        copyableList.push({
            title: InputDataSelector,
            displayValue: inputData === 'hex' ? <TextField fullWidth value={transaction.hexData} multiline rows="9" variant="outlined" inputProps={{className: `${classes.hexData} ${classes.multiline}`}} /> : 
                <TextField fullWidth value={transaction.inputBytes} multiline rows="9" variant="outlined" inputProps={{className: `${classes.hexData} ${classes.multiline}`}} />,
            copyableValue: inputData === 'hex' ? transaction.hexData : transaction.inputBytes
        })
    }

    

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('transactions')}</Typography>
            </Grid>
            <Grid item>
                <CopyableSettings header={lt('transaction', {transaction: transaction_hash})} {...{copyableList}} />
            </Grid>
            {transaction.logs && transaction.logs?.length > 0 && 
                <>
                    <Grid item>
                        <Typography variant="h5">{lt('logs')}</Typography>
                    </Grid>
                    {transaction.logs.map((entry, index) => (
                        <Grid key={`logs-${index}`} item>
                            <TransactionLog log={entry}/>
                        </Grid>
                    ))}
                </>
            }
        </Grid>
    )
}

interface translations {
    hash: string
    block: string
    from: string
    to: string
    timestamp: string
    gas: string
    inputData: string
    contractAddress: string
    bytes: string
    ascii: string
    transactions: string
    transaction: string
    bytecode: string
    hex: string
    hexViewer: string
    method: string
    inputs: string
    logs: string
    status: string
}

const enTranslations: translations = {
    hash: 'Hash',
    block: 'Block',
    contractAddress: 'Contract Address',
    from: 'From',
    to: 'To',
    timestamp: 'Timestamp',
    gas: 'Gas',
    inputData: 'Input Data',
    bytes: 'Bytes',
    ascii: 'Ascii',
    transactions: 'Transactions',
    transaction: 'Transaction - {{transaction}}',
    bytecode: 'Bytecode',
    hex: 'Hex',
    hexViewer: 'Hex Viewer',
    method: 'Method',
    inputs: 'Inputs',
    logs: 'Logs (Events)',
    status: 'Status'
}


const useStyles = makeStyles(theme => ({
    hexData: {
        fontFamily: 'monospace',
    },
    multiline: {
        color: theme.palette.text.secondary
    }
}))