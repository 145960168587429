import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, makeStyles, FormControl, InputLabel, Select } from "@material-ui/core";
import Highcharts from 'highcharts';
import { BaseChartOptions } from '../../utils/ChartOptions'

const MENU_PROPS = { getContentAnchorEl: null } // avoids the select menu jumping around when selecting runtimes

interface Props {
    allRuntimes: { _id: string, name: string }[],
    selectedRuntimeIds: string[],
    setSelectedRuntimeIds: React.Dispatch<React.SetStateAction<string[]>>,
    refreshIsDisabled: boolean,
    disableMultiSelect?: boolean,
    title?: string
};

export const RuntimeSelector = ({ allRuntimes, selectedRuntimeIds, setSelectedRuntimeIds, refreshIsDisabled, disableMultiSelect, title }: Props) => {
    const classes = useStyles();

    Highcharts.setOptions(BaseChartOptions);

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthRuntimeSelector', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`EnvironmentHealthRuntimeSelector:${key}`, interpolate), [t])

    // unfortunately cant use TextField with multiple select so need this ref business here
    const runtimeInputLabel = useRef<HTMLLabelElement>(null);
    const [runtimeLabelWidth, setRuntimeLabelWidth] = useState(0);
    useEffect(() => {
        if (runtimeInputLabel.current)
        setRuntimeLabelWidth(runtimeInputLabel.current.offsetWidth);
    }, [runtimeInputLabel])

    const handleRuntimesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (disableMultiSelect) {
            setSelectedRuntimeIds([event.target.value as string]);
            return
        }

        const values = event.target.value as string[]
        if (values.find(v => v === 'deselectAll')) {
            setSelectedRuntimeIds([])
            return
        } else if (values.find(v => v === 'selectAll')) {
            setSelectedRuntimeIds(allRuntimes.map(r => r._id))
            return
        }
        setSelectedRuntimeIds(values);
    };

    const runtimeList = useMemo(() => {
        let runtimeList = (
            allRuntimes.map(r => (
                <MenuItem key={r._id} value={r._id} className={selectedRuntimeIds.find(x => x === r._id) ? classes.isSelected : ''}>
                    {r.name}
                </MenuItem>
            ))
        )
        if (!disableMultiSelect) {
            runtimeList.unshift(
                <MenuItem divider disabled={!selectedRuntimeIds.length} key='deselectAll' value='deselectAll'>
                    {lt('deselectAll')}
                </MenuItem>
            )
            runtimeList.unshift(
                <MenuItem disabled={selectedRuntimeIds.length === allRuntimes.length} key='selectAll' value='selectAll'>
                    {lt('selectAll')}
                </MenuItem>
            )
        }
        return runtimeList
    }, [allRuntimes, selectedRuntimeIds, disableMultiSelect, classes.isSelected, lt])
                
    return (
        <FormControl variant="outlined" className={classes.runtimeSelector}>
            <InputLabel ref={runtimeInputLabel}>
                {title || disableMultiSelect ? lt('selectRuntime') : lt('includeRuntimes')}
            </InputLabel>
            <Select style={{minWidth: runtimeLabelWidth}}
                labelWidth={runtimeLabelWidth}
                disabled={refreshIsDisabled}
                multiple={disableMultiSelect ? false : true}
                value={selectedRuntimeIds}
                onChange={handleRuntimesChange}
                MenuProps={MENU_PROPS}> 
                { runtimeList }
            </Select>
        </FormControl>
    )
};

const useStyles = makeStyles(theme => ({
    runtimeSelector: {
        paddingRight: theme.spacing(2),
        width: '300px'
    },
    isSelected: {
        fontWeight: theme.typography.fontWeightBold
    }
}));

interface translations {
    includeRuntimes: string,
    selectRuntime: string,
    deselectAll: string,
    selectAll: string,
}
const enTranslations: translations = {
    includeRuntimes: 'Filter runtimes',
    selectRuntime: 'Select runtime',
    deselectAll: 'Deselect all',
    selectAll: 'Select all',
}