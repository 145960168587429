import React, { useMemo, useState, useEffect, useRef }  from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayGridWrapper } from '../../components/DisplayWrappers'
import { LineChart } from '../../components/Charts/LineChart';
import { BarChart, BarChartSeries } from '../../components/Charts/BarChart';
import { BlockchainKpis } from './BlockchainKpis';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { makeStyles, Grid, Typography, FormControl, InputLabel, Select, IconButton, CircularProgress, MenuItem } from "@material-ui/core";
import { BrandColors } from '../../utils/Colors'
import { RuntimeSelector } from '../EnvironmentHealth/RuntimeSelector';
import { 
        TxAndBlockStatsForChartData,
        TxAndBlockStatsForChartVars,
        TxAndBlockStatsForChartQuery,
        EthereumStatisticsData,
        EthereumStatisticsVars,
        EthereumStatisticsQuery,
        NodesQuery,
        NodesData,

} from '../../models'
import { EnvironmentResourcesVars } from '../../interfaces';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";

const REFRESH_TIMEOUT = 2500
const MONITORING_TIMEFRAMES = [3, 6, 12, 24, 48, 72] // last X hours
const INTERVALS = [5, 15, 30, 45, 60] // minutes

const MENU_PROPS = { getContentAnchorEl: null } // avoids the select menu jumping around when selecting runtimes

export const Blockchain = () => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthBlockchain', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentHealthBlockchain:${key}`, interpolate)

    const [disableRefresh, setDisableRefresh] = useState(false);
    const [interval, setInterval] = useState(15);
    const [monitoringTimeframe, setMonitoringTimeframe] = useState(24);
    const [publicPrivateTx, setPublicPrivateTx] = useState('all');

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!
    }

    const timeoutRef = useRef<number>(0)
    useEffect(() => {
        return () => window.clearTimeout(timeoutRef.current);
    }, []);
    const handleRefresh = () => {
        window.clearTimeout(timeoutRef.current);
        setDisableRefresh(true)

        Promise.all([refetchLedgerData(), refetchNodeData()]).then(() => {
            timeoutRef.current = window.setTimeout(() => {
                setDisableRefresh(false)
            }, REFRESH_TIMEOUT)
        })
    }

    const {
        data: {
            txAndBlockStatsForChart: ledgerData
        } = { txAndBlockStatsForChart: null },
        loading: ledgerDataLoading,
        refetch: refetchLedgerData
    } = useQuery<TxAndBlockStatsForChartData, TxAndBlockStatsForChartVars>(TxAndBlockStatsForChartQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            hours: monitoringTimeframe
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: {
            environmentEthStats: nodeData
        } = { environmentEthStats: [] },
        loading: nodeDataIsLoading,
        refetch: refetchNodeData
    } = useQuery<EthereumStatisticsData, EthereumStatisticsVars>(EthereumStatisticsQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            hours: monitoringTimeframe,
            interval
        },
        fetchPolicy: 'cache-and-network'
    });

    // query the nodes - ensured to be there, so cache-only and no need for laoding
    const { 
        data: { nodes } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const refreshIsDisabled = nodeDataIsLoading || ledgerDataLoading || disableRefresh;

    // unfortunately cant use TextField with multiple select so need this ref business here to get a consistent look
    const intervalInputLabel = useRef<HTMLLabelElement>(null);
    const [intervalLabelWidth, setIntervalLabelWidth] = useState(0);
    useEffect(() => {
        if (intervalInputLabel.current)
        setIntervalLabelWidth(intervalInputLabel.current.offsetWidth);
    }, [intervalInputLabel])

    const timeframeInputLabel = useRef<HTMLLabelElement>(null);
    const [timeframeLabelWidth, setTimeframeLabelWidth] = useState(0);
    useEffect(() => {
        if (timeframeInputLabel.current)
        setTimeframeLabelWidth(timeframeInputLabel.current.offsetWidth);
    }, [timeframeInputLabel])
    

    const allNodes = useMemo(() => {
        let list = [] as { _id: string, name: string }[]
        list = list.concat(nodes.map(n => 
            ({ _id: n._id, name: n.name })
        ))
        return list;
    }, [nodes])

    // Page specific chart settings based on designs
    const barchartOpts = {
        xAxis: { title: { enabled: false } },
        yAxis: { title: { enabled: false } },
    } as Highcharts.Options;
    const linechartOpts = {
        xAxis: { title: { enabled: false } },
        yAxis: { title: { enabled: false } },
    } as Highcharts.Options;
    const barChartHeight = '150px';
    const lineChartHeight = '200px';

    const blocksSeries: BarChartSeries[] = [
        {
            name: lt('blocks'),
            values: ledgerData?.blocks?.data?.count?.map(d => [Number(d.x).valueOf(), d.y.valueOf()]) ?? [],
            showZero: true
        }
    ];

    const txData = publicPrivateTx === 'all' ? ledgerData?.allTx : publicPrivateTx === 'public' ? ledgerData?.publicTx : ledgerData?.privateTx;

    const transactionsSeries: BarChartSeries[] = [
        {
            name: lt('succeeded'),
            values: txData?.data?.success?.map(d => [Number(d.x).valueOf(), d.y.valueOf()]) ?? [],
            color: BrandColors.blurple,
            showZero: true,
        },
        {
            name: lt('failed'),
            values: txData?.data?.failed?.map(d => [Number(d.x).valueOf(), d.y.valueOf()]) ?? [],
            color: BrandColors.magenta
        }
    ];

    // track the nodes to show in the filter
    const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>(allNodes.map(r => r._id));
    
    const filteredNodeData = useMemo(() => {
        return nodeData?.filter(n => selectedNodeIds.includes(n.id));
    }, [nodeData, selectedNodeIds]);

    const pendingTxSeries = filteredNodeData?.map(r => ({
        name: nodes.find(n => n._id === r.id)?.name || r.id,
        values: r.data.map(d => [Date.parse(d.t), d.tp]) as [number,number][]
    }));
    const queuedTxSeries = filteredNodeData?.map(r => ({
        name: nodes.find(n => n._id === r.id)?.name || r.id,
        values: r.data.map(d => [Date.parse(d.t), d.tq]) as [number,number][]
    }));
    const peersSeries = filteredNodeData?.map(r => ({
        name: nodes.find(n => n._id === r.id)?.name || r.id,
        values: r.data.map(d => [Date.parse(d.t), d.p]) as [number,number][]
    }));
    const blockHeightSeries = filteredNodeData?.map(r => ({
        name: nodes.find(n => n._id === r.id)?.name || r.id,
        values: r.data.map(d => [Date.parse(d.t), d.h]) as [number,number][]
    }));

    let nodeDataPoints = 0;
    let highestPending = undefined;
    if (nodeData) {
        highestPending = 0;
        for (let r of nodeData) {
            if (r.data.length) {
                nodeDataPoints = Math.max(nodeDataPoints, r.data.length);
                highestPending = Math.max(r.data[r.data.length-1].tp, highestPending);
            }
        }
    }

    let kpis = {}
    if (ledgerData) {
        kpis = {
            maxBPM: ledgerData.blocks.frequencyMax,
            minBPM: ledgerData.blocks.frequencyMin,
            avgBPM: ledgerData.blocks.frequencyAvg,
            avgBTime: ledgerData.blocks.time,
            avgTPM: ledgerData.tx.frequencyAvg,
            totalPending: highestPending
        }
    };

    const wrapChartWithLoading = (isLoading: boolean, dataLength: number | undefined, chart: JSX.Element, header: string, actionBar: JSX.Element | undefined) => {
        if (isLoading && !dataLength) {
            return (
                <DisplayGridWrapper hideDivider {...{header}} padDisplayGrid displayGrid={<CircularProgress />}/>
            )
        } else if (!dataLength || dataLength <= 1) {
            const notEnoughData = <Typography variant="body2">{lt('notEnoughData')}</Typography>
            return (
                <DisplayGridWrapper {...{header}} padDisplayGrid displayGrid={notEnoughData}/>
            )
        }
        const displayGrid = (
            <>
                {chart}
            </>
        )
        return (
            <DisplayGridWrapper hideDivider {...{header}} {...{displayGrid}}  actionBar={actionBar}/>
        )
    }

    return (
        <Grid container direction="column" spacing={3}>
            
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('blockchain')}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" className={classes.padDropdown}>
                        <InputLabel ref={timeframeInputLabel}>
                            {lt('timeframe')}
                        </InputLabel>
                        <Select
                            labelWidth={timeframeLabelWidth}
                            disabled={refreshIsDisabled}
                            value={monitoringTimeframe}
                            onChange={e => setMonitoringTimeframe(e.target.value as number)}
                            MenuProps={MENU_PROPS}> 
                            {MONITORING_TIMEFRAMES.map((entry, index) => (
                                <MenuItem key={`timeframe-${index}`} value={entry}>{lt('lastHours', { hours: entry})}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <IconButton color="primary" size={refreshIsDisabled ? "small" : "medium"} disabled={refreshIsDisabled}
                                onClick={() => handleRefresh()} >
                        { refreshIsDisabled ? <CircularProgress color="inherit" /> : <RefreshIcon /> }
                    </IconButton>
                </Grid>
            </Grid>

            <Grid item>
                <BlockchainKpis data={kpis}/>
            </Grid>
            <Grid item>
                {wrapChartWithLoading(ledgerDataLoading, ledgerData?.allTx.data.total.length,
                    <BarChart height={barChartHeight} title={lt('blocks')} series={blocksSeries} extraHCOptions={barchartOpts}/>,
                lt('blocksPerHour'), undefined)}
            </Grid>
            <Grid item>
                {
                    wrapChartWithLoading(ledgerDataLoading, ledgerData?.allTx.data.total.length,
                        <BarChart height={barChartHeight} title={lt('transactions')} series={transactionsSeries} extraHCOptions={barchartOpts}/>,
                    lt('transactionsPerHour'),
                    <Select
                        variant="outlined"
                        disabled={refreshIsDisabled}
                        value={publicPrivateTx}
                        onChange={e => setPublicPrivateTx(e.target.value as string)}
                        MenuProps={MENU_PROPS}>
                        <MenuItem key={`chart-all-tx`} value={'all'}>{lt('allTx')}</MenuItem>
                        <MenuItem key={`chart-public-tx`} value={'public'}>{lt('publicTx')}</MenuItem>
                        <MenuItem key={`chart-private-tx`} value={'private'}>{lt('privateTx')}</MenuItem>
                    </Select>)
                }
            </Grid>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                </Grid>
                <Grid item>
                        <RuntimeSelector title={lt('filterNodes')} allRuntimes={allNodes} selectedRuntimeIds={selectedNodeIds} setSelectedRuntimeIds={setSelectedNodeIds} refreshIsDisabled={refreshIsDisabled} />

                        <FormControl variant="outlined" className={classes.padDropdown}>
                            <InputLabel ref={intervalInputLabel}>
                                {lt('interval')}
                            </InputLabel>
                            <Select
                                labelWidth={intervalLabelWidth}
                                disabled={refreshIsDisabled}
                                value={interval}
                                onChange={e => setInterval(e.target.value as number)}
                                MenuProps={MENU_PROPS}> 
                                {INTERVALS.map((entry, index) => (
                                    <MenuItem key={`interval-${index}`} value={entry}>{lt('everyInterval', { interval: entry})}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} md={6}>
                        {
                            wrapChartWithLoading(ledgerDataLoading, nodeDataPoints,
                                <LineChart height={lineChartHeight} title={lt('pendingTransactions')} series={pendingTxSeries} extraHCOptions={linechartOpts}/>,
                            lt('pendingTransactions'), undefined)
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            wrapChartWithLoading(ledgerDataLoading, nodeDataPoints,
                                <LineChart height={lineChartHeight} title={lt('queuedTransactions')} series={queuedTxSeries} extraHCOptions={linechartOpts}/>,
                            lt('queuedTransactions'), undefined)
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} md={6}>
                        {
                            wrapChartWithLoading(ledgerDataLoading, nodeDataPoints,
                                <LineChart height={lineChartHeight} title={lt('peers')} series={peersSeries} extraHCOptions={linechartOpts}/>,
                            lt('peers'), undefined)
                        }                        
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            wrapChartWithLoading(ledgerDataLoading, nodeDataPoints,
                                <LineChart height={lineChartHeight} title={lt('blockHeight')} series={blockHeightSeries} extraHCOptions={linechartOpts} />,
                            lt('blockHeight'), undefined)
                        }                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

const useStyles = makeStyles(theme => ({
    padDropdown: {
        paddingRight: theme.spacing(2)
    }
}));

interface translations {
    blockchain: string,
    blocksPerHour: string,
    transactionsPerHour: string,
    blocks: string,
    transactions: string,
    pendingTransactions: string,
    queuedTransactions: string,
    peers: string,
    blockHeight: string,
    succeeded: string,
    failed: string,
    filterNodes: string,
    lastHours: string,
    interval: string,
    everyInterval: string,
    timeframe: string,
    privateTx: string,
    publicTx: string,
    allTx: string,
    notEnoughData: string,
}
const enTranslations: translations = {
    blockchain: 'Health & Monitoring',
    blocksPerHour: 'Blocks Per Hour',
    transactionsPerHour: 'Transactions Per Hour',
    blocks: 'Blocks',
    transactions: 'Transactions',
    pendingTransactions: 'Pending Transactions',
    queuedTransactions: 'Queued Transactions',
    peers: 'Peers',
    blockHeight: 'Block Height',
    succeeded: 'Succeeded',
    failed: 'Failed',
    filterNodes: 'Filter Nodes',
    interval: "Metric interval",
    timeframe: "Time frame",
    everyInterval: 'Every {{interval}} minutes',
    lastHours: 'Last {{hours}} hours',
    allTx: "All Tx",
    privateTx: "Private Tx",
    publicTx: "Public Tx",
    notEnoughData: 'This environment has not been running long enough to collect any metrics data. Try again later.',
}