import React from 'react';
import {
    Grid,
    MenuItem,
    IconButton,
    Select
} from '@material-ui/core';
import NavigateBeforeIcon from 'mdi-react/ChevronLeftIcon';
import NavigateNextIcon from 'mdi-react/ChevronRightIcon';
import { useTranslation } from 'react-i18next';

interface Props {
    initialRow: number
    lastRow: number | undefined
    onPreviousPage: (e : React.MouseEvent) => void
    onNextPage: (e : React.MouseEvent) => void
    limit: number
    onLimitChange: (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => void
    isLastPage: boolean | undefined
}

export const Pagination: React.FC<Props> = ({
    initialRow,
    lastRow,
    onPreviousPage,
    onNextPage,
    limit,
    onLimitChange,
    isLastPage
}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'pagination', enTranslations);

    return (
        <Grid container wrap="nowrap" justify="flex-end" alignItems="center" direction="row" spacing={3}>
                <Grid item>
                    <Grid container alignItems="center" spacing={1} justify="flex-end">
                        <Grid item>
                            {t('pagination:rowsPerPage').replace(/^\w/, c => c.toUpperCase())}
                        </Grid>
                        <Grid item>
                            <Select value={limit} onChange={onLimitChange}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {`${initialRow} - ${lastRow}`}
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <IconButton onClick={e => onPreviousPage(e)} disabled={initialRow === 1}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={e => onNextPage(e)} disabled={!isLastPage}>
                                <NavigateNextIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
};

const enTranslations = {
    rowsPerPage: 'rows per page:'
}
