import { useApolloClient, useMutation } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import FaceAgentIcon from 'mdi-react/FaceAgentIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CongratsMessage } from '../../../components/CongratsMessage/CongratsMessage';
import { CreateWrapper, MessageSnackbar } from '../../../components/DialogWrappers';
import { DisplayCard } from '../../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../../components/DisplayWrappers/DisplayCardItem';
import { MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, ORGS_PATH } from '../../../components/ManageOrgNav/ManageOrgNav';
import { CreateStepProps } from '../../../interfaces';
import { EnPlansTranslations, PlanName, PlansEnum, PlansTranslations } from '../../../models';
import { PlanUpgradeData, PlanUpgradeVars, UpgradePlanMutation } from '../../../models/planUpgrade';
import { cognitoController } from '../../../utils/cognitoController';

interface Props extends CreateStepProps {
    plan: PlanName
    supportLevel: Number
}

export const Step3 = ({ plan, supportLevel, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChangePlanStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChangePlanStep2:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const [errorMessage, setErrorMessage] = useState('');
    const [planChangeComplete, setPlanChangeComplete] = useState(false);
    const apolloClient = useApolloClient();

    const [upgradePlan] = useMutation<PlanUpgradeData, PlanUpgradeVars>(UpgradePlanMutation);

    const getSupportLabel = () => {
        switch (supportLevel) {
            case 100: return lt('free');
            case 200: return lt('select');
            case 300: return lt('priority');
        }
    };

    const itemList: DisplayCardListItem[] = [
        {
            icon: <TuneIcon />,
            title: lt('subscriptionPlan'),
            value: lt(PlansEnum[plan])
        },
        {
            icon: <FaceAgentIcon />,
            title: lt('supportLevel'),
            value: getSupportLabel()
        }
    ];

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('confirmPlan')}
                </Typography>
            </Grid>
            <Grid item>
                <DisplayCard itemList={itemList} />
            </Grid>
        </>
    );

    const save = async () => {
        try {
            const { data } = await upgradePlan({ variables: { org_id: org_id!, targetPlan: { plan, support_level: supportLevel } } });
            await cognitoController.renewSession(apolloClient, org_id);
            if (data?.upgradePlan) {
                setPlanChangeComplete(true);
            } else {
                setErrorMessage(lt('failedToChangePlan'));
            }
        } catch(err) {
            setErrorMessage(lt('failedToChangePlan'));
        }
    };

    if (planChangeComplete) {
        return (
            <CongratsMessage {...{plan}} redirectPath={`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}`} />
        );
    } else {
        return (
            <>
                <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
                <CreateWrapper disabled={false} cancelPath={cancelPath} {...{ content }} onNext={save} isLastStep customNextButtonLabel={lt('changePlan')} />
            </>
        );
    }
};

interface translations extends PlansTranslations {
    confirmPlan: string
    subscriptionPlan: string
    supportLevel: string
    free: string
    select: string
    priority: string
    changePlan: string
    failedToChangePlan: string
    congratulationsTitle: string
    congratulationsSubtitle: string
    congratulationsDescription: string
    finish: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    confirmPlan: 'Confirm Subscription Plan Change',
    subscriptionPlan: 'Subscription Plan',
    supportLevel: 'Support Level',
    free: 'Free',
    select: 'Select',
    priority: 'Priority',
    changePlan: 'Change Plan',
    failedToChangePlan: 'Could not change plans. Please check plan requirements.',
    congratulationsTitle: 'Congratulations!',
    congratulationsSubtitle: 'You are now on the {{planName}} Plan.',
    congratulationsDescription: 'We hope you enjoy exploring new possibilities with your plan.',
    finish: 'Finish'
}