import { XAxisOptions } from 'highcharts';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentStoreMetric, DocumentStoreMetrics } from '../../../models';
import { LineChart } from '../LineChart';

interface Props {
    xAxis: XAxisOptions,
    allRuntimes: { _id: string, name: string }[],
    runtimesForChart: DocumentStoreMetrics[],
    height?: string
};

export const DocumentsStored = ({ xAxis, allRuntimes, runtimesForChart, height }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsDocumentStoreDocumentsStored', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`ChartsDocumentStoreDocumentsStored:${key}`, interpolate), [t])


    const getValue = (d: DocumentStoreMetric): [number, number] => {
        return [Date.parse(d.time), d.documents_in_kaleido_storage]
    }

    const series = runtimesForChart.map(r => {
        return { 
            name: allRuntimes.find(runtime => runtime._id === r.id)?.name || '', 
            values: r.metrics.filter(m => new Date(m.time) < new Date()).map(d => getValue(d))
        }
    })

    const extraHCOptions: Highcharts.Options = {
        time: {
            useUTC: true
        },
        xAxis: { ...xAxis, ...{ title: { text: '' } }}
    }

    return <LineChart showLegend title={lt('chart')} series={series} height={height} {...{extraHCOptions}} />
};

interface translations {
    chart: string
}
const enTranslations: translations = {
    chart: 'Documents Stored'
}