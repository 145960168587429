import React from 'react';
import { useTranslation } from 'react-i18next';
import { Environment, EnvironmentState, EnvironmentProviderTranslations, EnEnvironmentProviderTranslations } from '../../models'
import { DisplayCard } from '../../components/DisplayWrappers'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { useHistory, useParams } from 'react-router-dom';
import LanIcon from 'mdi-react/LanIcon';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { Grid } from '@material-ui/core';
import { capitalize } from '../../utils/StringUtils';

interface Props {
    environments: Environment[]
    loading: boolean
};

export const Environments = ({ environments, loading }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NetworkDashboardEnvironments', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NetworkDashboardEnvironments:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>()
    const history = useHistory()

    const makeIcon = (state: EnvironmentState) => {
        return (
            <Grid item container spacing={1}>
                <Grid item>
                    <ResourceStateChip state={state} />
                </Grid>
                <Grid item>
                    <ChevronRightIcon />
                </Grid>
            </Grid>
        )
    }

    const envList = environments.map(e => ({
        title: e.name, 
        value: `${capitalize(lt(e.provider))} / ${capitalize(e.consensus_type)}`,
        icon: <LanIcon />,
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${e._id}`),
        actionIcon: makeIcon(e.state)
    }))

    const linkButton = {
        text: lt('createEnvironment'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/create/1`)
    }
    
    return (
        <DisplayCard {...{loading}}
            header={lt('environments', { count: envList.length })}
            itemList={envList}
            {...{linkButton}} />
    )
};

interface translations extends EnvironmentProviderTranslations {
    environments: string,
    createEnvironment: string
}
const enTranslations: translations = {
    ...EnEnvironmentProviderTranslations,
    environments: 'Environments ({{count}})',
    createEnvironment: 'Create Environment'
}