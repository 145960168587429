import React, { useState, useEffect } from 'react';
import { SubNavs } from './SubNavs';
import { SideNavProps, ConsortiumResourceVars } from '../../../interfaces';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PollBoxOutlineIcon from 'mdi-react/PollBoxOutlineIcon';
import { NavItem } from '../../NavComponents/NavItem'
import { useQuery } from '@apollo/client';
import { EnvironmentData, EnvironmentQuery, FeatureTogglesData, FeatureTogglesQuery } from '../../../models';

export const ENVIRONMENT_HEALTH_PATH = 'health'
export const ENVIRONMENT_HEALTH_RUNTIMES_PATH = 'runtimes'
export const ENVIRONMENT_HEALTH_BLOCKCHAIN_PATH = 'blockchain'
export const ENVIRONMENT_HEALTH_DOCUMENTS_PATH = 'documents'
export const ENVIRONMENT_HEALTH_MESSAGING_PATH = 'messaging'

export const HealthMonitoring = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'HealthMonitoring', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`HealthMonitoring:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();
    const { pathname } = useLocation()

    const [selectedItem, setSelectedItem] = useState<string>();
    const [active, setActive] = useState<boolean>(false);

    const DASHBOARD = lt('dashboard')
    const RUNTIMES = lt('runtimes')
    const BLOCKCHAIN = lt('blockchain')
    const DOCUMENTS = lt('documents')
    const MESSAGING = lt('messaging')

    const { 
        data: { 
            featureToggles 
        } = { featureToggles: null } 
    } = useQuery<FeatureTogglesData>(FeatureTogglesQuery, { fetchPolicy: "cache-first" });

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });
    
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    useEffect(() => { 
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.endsWith(`${environment_id!}/${ENVIRONMENT_HEALTH_PATH}`)) {
            setSelectedItem(DASHBOARD)
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id!}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_RUNTIMES_PATH}`)) {
            setSelectedItem(RUNTIMES)
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id!}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_BLOCKCHAIN_PATH}`)) {
            setSelectedItem(BLOCKCHAIN)
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id!}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_DOCUMENTS_PATH}`)) {
            setSelectedItem(DOCUMENTS)
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id!}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_MESSAGING_PATH}`)) {
            setSelectedItem(MESSAGING)
            setActiveAndSelected()
        } else {
            setSelectedItem('')
            setActiveAndSelected(false)
        }
        
    }, [environment_id, pathname, setSelected, DASHBOARD, RUNTIMES, BLOCKCHAIN, DOCUMENTS, MESSAGING])

    const historyPusher = (path?: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ENVIRONMENT_HEALTH_PATH}${path ? `/${path}` : ''}`)

    const tabs = [
        {
            name: DASHBOARD,
            action: () => historyPusher()
        },
        {
            name: RUNTIMES,
            action: () => historyPusher(ENVIRONMENT_HEALTH_RUNTIMES_PATH)
        }
    ]

    if (!isCorda && !isFabric) {
        tabs.push({
            name: BLOCKCHAIN,
            action: () => historyPusher(ENVIRONMENT_HEALTH_BLOCKCHAIN_PATH)
        })

        if (featureToggles?.docExchange) {
            tabs.push({
                name: DOCUMENTS,
                action: () => historyPusher(ENVIRONMENT_HEALTH_DOCUMENTS_PATH)
            }, {
                name: MESSAGING,
                action: () => historyPusher(ENVIRONMENT_HEALTH_MESSAGING_PATH)
            })
        }
    }

    return (
        <>
            <NavItem icon={<PollBoxOutlineIcon />}
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={tabs} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations {
    name: string
    dashboard: string
    runtimes: string
    blockchain: string
    documents: string
    messaging: string
}
const enTranslations: translations = {
    name: 'Health & Monitoring',
    dashboard: 'Dashboard',
    runtimes: 'Runtimes',
    blockchain: 'Blockchain',
    documents: 'My Documents',
    messaging: 'My Messaging'
}