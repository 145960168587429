import React, { useState, useMemo, useEffect } from 'react';
import { TransactionsVars, TransactionData, TransactionVars, TransactionQuery, Transaction, TxIndexQuery, TxIndexData} from '../../models';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TablePagination, Grid, Typography, CircularProgress } from '@material-ui/core';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { TransactionsTable } from '../../components/Transactions/TransactionsTable';

const LIMITLIST = [10, 25];

export const Transactions = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransactionsExplorer', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransactionsExplorer:${key}`, interpolate);

    const [limit, setLimit] = useState(LIMITLIST[0]);
    const [currentPage, setCurrentPage] = useState(0);
    const [errorMessage, setErrorMessasge] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [searchActive, setSearchActive] = useState(false);
    const [activeFilter, setActiveFilter] = useState('');

    const {environment_id, consortium_id} = useParams<any>();

    const location = useLocation();
    const history = useHistory()
    const param = new URLSearchParams(location.search)
    const page = param.get('page');
    const urlLimit = param.get('limit');

    useEffect(()=> {
        if (page && parseInt(page)) {
            const p = parseInt(page);
            if ( p <= 0) {
                setCurrentPage(0);
            } else {
                setCurrentPage(p - 1);
            }
        } 
    }, [page]);

    useEffect(() => {
        if(urlLimit) {
            const l = parseInt(urlLimit);
            if(LIMITLIST.includes(l)) {
                setLimit(l);
            } else {
                setLimit(LIMITLIST[0]);
            }
        } 
    }, [urlLimit])

    const updatePage = (page: number, limit: number) => {
        history.push({
            search: `?page=${page + 1}&limit=${limit}`,
        })
    }

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        refetch,
        data: {
            txIndex: {
                totalCount,
                transactions
            }
        } = { 
            txIndex: {
                totalCount: 0, 
                transactions: []
            } || null 
        }
    } = useQuery<TxIndexData, TransactionsVars>(TxIndexQuery, { 
        variables: {
            ...queryVariables,
            limit,
            start: currentPage * limit,
            status: activeFilter !== 'all' ? activeFilter : undefined
        },
        fetchPolicy: 'no-cache'
    });

    const [searchTransaction, {
        loading: searchLoading,
        data: {
            transaction
        } = {transaction: null}
    }] = useLazyQuery<TransactionData, TransactionVars>(TransactionQuery, {
        onError: () => setErrorMessasge(lt('searchNotFound', {search: searchInput}))
    });

    const data = useMemo(() => {
        return transaction && searchActive && !searchLoading ? [transaction] : transactions;
    }, [transactions, transaction, searchLoading, searchActive]);
    
    if (loading && transactions && transactions.length === 0) return <CircularProgress />;

    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        updatePage(page, limit);
    }
    
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updatePage(0, parseInt(event.target.value));
    }


    const onSubmitSearch = async (searchInput: string) => {
        setSearchInput(searchInput);
        if(searchInput) {
            await searchTransaction({
                variables: {
                    ...queryVariables,
                    hash: searchInput
                }
            })
        }
    };

    const onRefreshClick = () =>{
        updatePage(0, limit)
        refetch();
    }

    const onFilter = (options: {[key: string]: string}) => {
        updatePage(0, limit);
        setActiveFilter(options[lt('status')]);
    }

    const toolBarOptions = {
        refresh: {
            onRefresh: onRefreshClick
        },
        search: {
            onSearch: onSubmitSearch,
            setSearchActive,
            label: lt('searchLabel')
        },
        filter: {
            onSubmit: onFilter,
            loading: loading,
            inputs: [{
                label: lt('status'),
                options: ['success', 'failed']
            }]
        }
    }

    const Pagination = <TablePagination 
                            rowsPerPageOptions={LIMITLIST} 
                            component="div" 
                            count={totalCount} 
                            rowsPerPage={limit} 
                            onChangeRowsPerPage={onChangeRowsPerPage} 
                            onPageChange={onChangePage} 
                            page={currentPage} 
                            nextIconButtonProps={{disabled: loading || (currentPage + 1) * limit >= totalCount}} 
                            backIconButtonProps={{disabled: loading || currentPage <= 0}} />;
    
    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge} />
            <Grid container direction="column" spacing={3}>
                <Grid item container wrap="nowrap" justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">{lt('header')}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <TransactionsTable {...{toolBarOptions}} transactions={data as Transaction[]} pagination={transaction && searchActive ? undefined : Pagination} />
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    header: string,
    transaction: string,
    from: string,
    to: string,
    dateMined: string,
    searchLabel: string,
    searchNotFound: string,
    status: string,
    success: string,
    failed: string,
}

const enTranslations: translations = {
    header: 'Latest Transactions',
    transaction: 'Transaction',
    from: 'From',
    to: 'To',
    dateMined: 'Date Mined',
    searchLabel: 'Enter Transaction Hash',
    searchNotFound: 'Transaction "{{search}}" Not Found!',
    status: 'Status',
    success: 'Success',
    failed: 'Failed',
};