import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { OpsOrganizationsData, OpsOrganizationsQuery } from '../../models/organizations';
import { OpsTable, SkipLimt } from './OpsTable';
import { useHistory } from 'react-router-dom';
import { OPS_PATH, OPS_MANAGE_ORG_PATH } from '../../components/OpsNav/OpsNav';

interface Props {
    setMessage: (m: string) => void,
    region: string,
    idFilter: string,
    nameFilter: string,
    emailFilter: string,
    billingFilter: string,
    planFilter: string,
    parentFilter: string,
    agreementFilter: string,
}

export const OrgsTable: React.FC<Props> = ({setMessage, region, idFilter, nameFilter, emailFilter, billingFilter, planFilter, parentFilter, agreementFilter}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsOrganizationsDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsOrganizationsDashboard:${key}`, interpolate)

    const [skipLimit, setSkipLimit] = useState<SkipLimt>({skip: 0, limit: 0});

    const history = useHistory();

    let query = `skip=${skipLimit.skip}&limit=${skipLimit.limit}`;
    query = idFilter ? `${query}&_id=~${idFilter}` : query;
    query = nameFilter ? `${query}&name=~${nameFilter}` : query;
    query = emailFilter ? `${query}&delegate=~${emailFilter}` : query;
    query = planFilter ? `${query}&plan=~${planFilter}` : query;
    query = billingFilter ? `${query}&billing_account.type=~${billingFilter}` : query;
    query = parentFilter ? `${query}&master_org=~${parentFilter}` : query;
    query = agreementFilter ? `${query}&billing_account.agreement=~${agreementFilter}` : query;

    const {
        data: {
            opsOrganizations: orgs
        } = { opsOrganizations: [] },
        error: fetchError, 
    } = useQuery<OpsOrganizationsData>(OpsOrganizationsQuery, {
        skip: !skipLimit.limit,
        variables: {
            query,
            region
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    const records = useMemo<DisplayTableRecord[]>(() => {
        return orgs.map(o => ({
            columns: [{
                value: o._id,
                maxWidthPixelValue: 75,
            },{
                value: o.name,
                maxWidthPixelValue: 250,
            }, {
                value: o.delegate,
                maxWidthPixelValue: 250,
            }, {
                value: o.billing_account?.type || '',
                maxWidthPixelValue: 75,
            }, {
                value: o.plan,
                maxWidthPixelValue: 75,
            }, {
                value: o.master_org || '',
                maxWidthPixelValue: 75,
            }, {
                value: o.billing_account?.agreement || '',
                maxWidthPixelValue: 75,
            }],
            onClick: () => history.push(`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/${o._id}`)
        }))
    }, [orgs, history]);

    return (
        <OpsTable {...{setSkipLimit}} tableProps={{
            records,
            columnHeaders: [
                lt('id'),
                lt('name'),
                lt('delegate'),
                lt('billing'),
                lt('plan'),
                lt('parent'),
                lt('agreement'),
            ]
        }}/>
    )
};

interface translations {
    id: string,
    name: string;
    agreement: string;
    delegate: string;
    billing: string;
    plan: string;
    parent: string;
}
const enTranslations: translations = {
    id: 'ID',
    name: 'Name',
    agreement: 'Agreement',
    delegate: 'E-mail',
    billing: 'Billing',
    plan: 'Plan',
    parent: 'Parent',
}
