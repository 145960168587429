import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import { CopyableSettings, EditableSettings, CopyableSetting } from '../../../components/DisplaySettings';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import { useQuery } from '@apollo/client';
import { OauthConfigurationData, OauthConfigurationQuery, JwtKeyTypeTranslationsInterface, jwtKeyTypeTranslations } from '../../../models/oauthConfiguration';
import { EnvironmentResourceVars } from '../../../interfaces';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeleteResource, UpdateName } from '../../../components/DialogWrappers';
import { SECURITY_BASE_PATH } from '../../../components/MainNav/SideNavs/Security';


export const OauthConfiguration = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsSecurityOauthConfiguration', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsSecurityOauthConfiguration:${key}`, interpolate)

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const { consortium_id, environment_id, oauth_id, org_id } = useParams<any>();
    const classes = useStyles();
    const history = useHistory();

    const environmentResourcesVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}`;

    const {
        refetch,
        loading,
        data: {
            oauthConfiguration
        } = { oauthConfiguration: null }
    } = useQuery<OauthConfigurationData, EnvironmentResourceVars>(OauthConfigurationQuery, { 
        variables: {
            ...environmentResourcesVars,
            id: oauth_id
        },
        fetchPolicy: 'cache-and-network',
        onError: () => history.push(basePath)
    });

    useEffect(() => {
        refetch();
    }, [deleteDialogOpen, refetch])

    if (loading && !oauthConfiguration) return <CircularProgress />
    if (!oauthConfiguration) return <Redirect to={basePath} />

    const copyableList: CopyableSetting[] = [
        {
            title: lt('id'),
            displayValue: oauthConfiguration._id,
        }, {
            title: lt('name'),
            displayValue: oauthConfiguration?.name ?? '--'
        },{
            title: lt('owner'),
            displayValue: oauthConfiguration?.membership.name ?? '--'
        }, {
            title: lt('jwtType'),
            displayValue: lt(oauthConfiguration.jwt_key_type)
        }, {
            title: lt('publicKey'),
            displayValue: <TextField multiline fullWidth variant="outlined" rows={3} value={oauthConfiguration.jwt_key_set} inputProps={{className: classes.multiline}} />,
            copyableValue: oauthConfiguration.jwt_key_set
        }
    ]

    const actionsList = [
        {
            icon: <AccountCircleIcon />,
            title: lt('changeName'),
            buttonLabel: lt('change'),
            value: oauthConfiguration?.name,
            action: () => setUpdateNameDialogOpen(true)
        },
        {
            icon: <DeleteOutlineIcon />,
            title: lt('delete'),
            buttonLabel: lt('deleteLabel'),
            action: () => setDeleteDialogOpen(true)
        }
    ]

    return (
        <>
            <UpdateName defaultName={oauthConfiguration.name ?? ''} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <DeleteResource closeDialogAfterSave disableNameConfirmation open={deleteDialogOpen} setOpen={setDeleteDialogOpen} name={oauthConfiguration?.name || ''} />
            <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">
                        {lt('oauthConfigurationDetails')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <CopyableSettings header={lt('oauthConfigurationInfo')} {...{copyableList}} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <EditableSettings
                        header={lt('oauthConfigurationSettings')}
                        description={lt('oauthConfigurationDesc')}
                        {...{actionsList}} />
                </Grid>
            </Grid>
        </>
    )
}

interface translations extends JwtKeyTypeTranslationsInterface {
    changeName: string
    change: string
    delete: string
    deleteLabel: string
    oauthConfigurationDetails: string
    oauthConfigurationInfo: string
    oauthConfigurationSettings: string
    oauthConfigurationDesc: string
    id: string
    jwtType: string
    name: string
    owner: string
    publicKey: String
}
const enTranslations: translations = {
    ...jwtKeyTypeTranslations,
    publicKey: 'Public Key',
    id: 'ID',
    jwtType: 'JWT Type',
    name: 'Name',
    owner: 'Owner',
    change: 'Change',
    changeName: 'Change Name',
    delete: 'Delete',
    deleteLabel: 'Delete',
    oauthConfigurationDesc: 'You can rename this Oauth Configuration. You can also permanently delete the Oauth Configuration which will terminate access to any application that was using it to connect to your runtime.',
    oauthConfigurationInfo: 'Oauth Configuration Info',
    oauthConfigurationDetails: 'Oauth Configuration Details',
    oauthConfigurationSettings: 'Oauth Configuration Settings'
};

const useStyles = makeStyles(theme => ({
    multiline: {
        color: theme.palette.text.secondary
    }
}))