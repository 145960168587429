import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { FormDialog } from '../../../components/DialogWrappers';
import { DeleteRoleMutation, DeleteRoleVars, DeleteRoleData, MakeRoleDeleteMutationOptions } from '../../../models/roles';
import { useParams } from 'react-router-dom';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    roleId: string
}

export const DeleteUser: React.FC<Props> = ({ open, setOpen, roleId }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'deleteUser', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`deleteUser:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const [deleteRole] = useMutation<DeleteRoleData, DeleteRoleVars>(DeleteRoleMutation);

    const handleDelete = () => {
        return deleteRole(MakeRoleDeleteMutationOptions({ org_id: org_id!, id: roleId })).then(() => { });
    }

    const controlsWrapper = (
        <Typography>{lt('warning')}</Typography>
    );

    return <FormDialog open={open} setOpen={setOpen} header={lt('deleteUser')} closeDialogAfterSave={true}
        saveText={lt('delete')} controlsWrapper={controlsWrapper} onSave={handleDelete} successMessage={lt('successDeletionMessage')} />
}

interface translations {
    deleteUser: string
    delete: string
    warning: string
    successDeletionMessage: string
}

const enTranslations: translations = {
    deleteUser: 'Delete User',
    delete: 'Delete',
    warning: 'Are you sure you want to delete the user?',
    successDeletionMessage: 'User successfully deleted'
}