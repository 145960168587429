import { useQuery } from '@apollo/client';
import { Grid, Button, Typography } from '@material-ui/core';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { EnvironmentResourceVars, ConsortiumResourceVars } from '../../interfaces';
import { Service, EnvironmentData, EnvironmentQuery, ServiceData, ServiceQuery, NodesData, NodesQuery, ServiceState, ReleaseVars, ReleaseQuery, ReleaseData } from '../../models';
import { IsFireflyInitializedQuery, IsFireflyInitializedData, FireflyState } from '../../models/firefly';
import { useTranslation } from 'react-i18next';
import { FIREFLY_BASE_PATH, FIREFLY_INITIALIZE_PATH, FIREFLY_REGISTRATION_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { ServiceStatusData, ServiceStatusQuery } from '../../models/servicesStatus';

interface Props {
    service: Service
    setServicesWithInitAvailable?: React.Dispatch<React.SetStateAction<string[]>>
    isDisplayCardItem?: boolean
};

export const FireflyStatusChip = ({ service, isDisplayCardItem, setServicesWithInitAvailable }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FireflyStatusChip', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`FireflyStatusChip:${key}`, interpolate)

    const history = useHistory()
    
    const { org_id, consortium_id, environment_id } = useParams<any>()
    const [ffState, setFfState] = useState<ServiceState | FireflyState>('uninitialized');

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            serviceStatus
        } = { serviceStatus: null },
        stopPolling: stopPollingServiceStatus,
        startPolling: startPollingServiceStatus
    } = useQuery<ServiceStatusData, EnvironmentResourceVars>(ServiceStatusQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            id: service._id,
        },
        fetchPolicy: 'cache-and-network',
        skip: environment?.state !== 'live' || service.state !== 'started'
    });

    const {
        loading: isFireflyInitializedLoading,
        data: {
            isFireflyInitialized
        } = { isFireflyInitialized: null },
        stopPolling,
        startPolling
    } = useQuery<IsFireflyInitializedData, EnvironmentResourceVars>(IsFireflyInitializedQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            id: service._id,
        },
        fetchPolicy: 'cache-and-network',
        skip: environment?.state !== 'live' || service.state !== 'started'
    });

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: { 
            consortia_id: consortium_id,
            environment_id: environment_id
        },
        fetchPolicy: 'cache-only'
    });

    const node = nodes.find(n => n._id === service.details.node_id)
    const monitorNode = nodes.find(n => n.membership_id === 'sys--mon')

    const {
        data: {
            service: ipfsService
        } = {service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            id: service.details.ipfs_service_id || '',
        },
        fetchPolicy: 'cache-only'
    });

    const { data: { 
        release 
      } = { release: null }
 } = useQuery<ReleaseData, ReleaseVars>(ReleaseQuery, {
      skip: !environment,
      variables: {
          id: environment?.release_id!
      },
      fetchPolicy: 'cache-and-network'
 });

    const isNewFireFly =
        release &&
        release.supported_features &&
        release.supported_features.fireflyVersion === '1.2';
    
    const readyToInit = serviceStatus?.fireflyAPIStatus?.status === 'available';
    const ffInitialized = isFireflyInitialized?.initialized;
    const needsInit = isNewFireFly ? ffInitialized === false && readyToInit : !serviceStatus?.firefly;
    const initPrereqs =
        monitorNode?.state === 'started' &&
        service.state === 'started' &&
        node?.state === 'started' &&
        ipfsService?.state === 'started' &&
        service.membership.isMine &&
        !!serviceStatus?.runtime?.started;
    const showInitButton = needsInit && initPrereqs && !ffInitialized;
    const showRegisterButton =
        !needsInit &&
        (isNewFireFly
            ? 
              (isFireflyInitialized?.readyForRegistration &&
              isFireflyInitialized?.namespacesRegisteredCount === 0)
            : !serviceStatus?.firefly?.org?.registered ||
              !serviceStatus?.firefly?.node?.registered);

    useEffect(() => {
        if (isFireflyInitialized?.initialized) {
            stopPollingServiceStatus()
            stopPolling()
        }
    }, [isFireflyInitialized, stopPollingServiceStatus, stopPolling])

    useEffect(() => {
        if (!needsInit && !showRegisterButton) {
            if (setServicesWithInitAvailable) {
                setServicesWithInitAvailable(fireflyIds => fireflyIds.filter(i => i !== service._id))
            }
        } else if ((showInitButton || showRegisterButton) && setServicesWithInitAvailable) {
                setServicesWithInitAvailable(fireflyIds => [...fireflyIds.filter(i => i !== service._id), service._id])
        }
    }, [needsInit, showInitButton, stopPolling, service._id, setServicesWithInitAvailable, showRegisterButton])

    // restart the polling when the node comes up
    useEffect(() => {
        if (service.state === 'started') {
            startPolling(5000)
            startPollingServiceStatus(5000)
        }
    }, [service.state, startPolling, startPollingServiceStatus])

    const baseFireflyServicePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${FIREFLY_BASE_PATH}/${service.service}/${service._id}`
    const initPath = `${baseFireflyServicePath}/${FIREFLY_INITIALIZE_PATH}`
    const registerPath = `${baseFireflyServicePath}/${FIREFLY_REGISTRATION_PATH}`

    useEffect(()=> {
        setFfState(service.state)
    }, [isFireflyInitialized, readyToInit, service.state]);

    const {totalNamespaces = 0, namespacesRegisteredCount = 0} = isFireflyInitialized ?? {};
    const unregisteredNamespacesCount = totalNamespaces - namespacesRegisteredCount;

    const makeContent = () => {
        if (showInitButton || showRegisterButton) {
            return (
                <Grid
                    item
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                >
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            size="medium"
                            data-test={showRegisterButton ? `button_register` : `button_initialize`}
                            disabled={!isNewFireFly}
                            onClick={() =>
                                history.push(
                                    showRegisterButton ? registerPath : initPath
                                )
                            }
                        >
                            {lt(
                                showRegisterButton
                                    ? 'registerFirefly'
                                    : 'initializeFirefly'
                            )}
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <>
                    <Grid
                        item
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                    >
                        {(readyToInit || ffState !== 'started' || !isNewFireFly) && (
                            <>
                                <Grid
                                    item
                                    container
                                    justifyContent={
                                        isDisplayCardItem
                                            ? 'flex-end'
                                            : 'flex-start'
                                    }
                                >
                                    <ResourceStateChip state={ffState} />
                                </Grid>
                            </>
                        )}
                        {isNewFireFly && serviceStatus !== null &&
                            !readyToInit &&
                            ffState === 'started' && (
                                <Grid item>
                                    <Typography color="primary">
                                        {lt('waitingForFireflyToStart')}
                                    </Typography>
                                </Grid>
                            )}
                    </Grid>
                    {!isFireflyInitializedLoading &&
                        ffState === 'started' &&
                        readyToInit && (
                            <Grid item container alignItems="flex-end">
                                <Typography color="textSecondary">
                                    {lt(
                                        namespacesRegisteredCount === 1
                                            ? 'registeredCountSingle'
                                            : 'registeredCount',
                                        {
                                            registeredCount:
                                                namespacesRegisteredCount,
                                        }
                                    )}
                                    {unregisteredNamespacesCount > 0
                                        ? lt('unregisteredCount', {
                                              unregisteredNamespaces:
                                                  unregisteredNamespacesCount,
                                          })
                                        : ''}
                                </Typography>
                            </Grid>
                        )}
                </>
            );
        }
    }

    return isDisplayCardItem &&
        !showInitButton &&
        !showRegisterButton &&
        readyToInit ? (
        <Grid item container spacing={1}>
            <Grid item>{makeContent()}</Grid>
            <Grid item>
                <ChevronRightIcon />
            </Grid>
        </Grid>
    ) : (
        makeContent()
    );
};

interface translations {
    initializeFirefly: string,
    initializeFireflyTokens: string,
    registerFirefly: string,
    waitingForFireflyToStart: string,
    registeredCountSingle: string,
    registeredCount: string,
    unregisteredCount: string
};

const enTranslations: translations = {
    initializeFirefly: 'Initialize',
    initializeFireflyTokens: 'Initialize Tokens',
    registerFirefly: 'Register',
    waitingForFireflyToStart: 'Waiting for FireFly to start...',
    registeredCountSingle: '{{registeredCount}} namespace',
    registeredCount: '{{registeredCount}} namespaces',
    unregisteredCount: ' ({{unregisteredNamespaces}} unregistered)'
}