import { XAxisOptions } from 'highcharts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { App2AppMetrics } from '../../../models';
import { BrandColors } from '../../../utils/Colors';
import { BarChart, BarChartSeries } from '../BarChart';

interface Props {
    xAxis: XAxisOptions,
    runtimesForChart: App2AppMetrics[],
    chart: 'sent' | 'received',
    height?: string
};

export const Messages = ({ xAxis, runtimesForChart, chart, height }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsB2BMessages', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChartsB2BMessages:${key}`, interpolate)

    const addToList = (list: [number, number][], time: number, metric: number) => {
        const found = list.find(s => s[0] === time)
        if (found) {
            found[1] += metric
        } else {
            list.push([time, metric])
        }
    }

    const makeSeries = () => {
        let successful: [number, number][] = []

        runtimesForChart.forEach(r => {            
            r.metrics.filter(m => new Date(m.time) < new Date()).forEach(d => {
                const time = Date.parse(d.time)
                if (chart === 'sent') {
                    addToList(successful, time, d.messages_sent)
                } else if (chart === 'received') {
                    addToList(successful, time, d.messages_received)
                }
            })
        })

        let series: BarChartSeries[] = []
        series.push({
            name: lt(chart),
            values: successful,
            color: BrandColors.blurple,
            showZero: true
        })

        return series
    }

    const extraHCOptions: Highcharts.Options = {
        yAxis: {
            title: {
                text: lt('messages')
            },
            labels: {
                enabled: true
            }
        },
        legend:{
            enabled: true,
        },
        xAxis
    }

    return <BarChart title={lt(chart)} series={makeSeries()} height={height} {...{extraHCOptions}} hideXAxisLabel />
};

interface translations {
    sent: string,
    received: string,
    messages: string
}
const enTranslations: translations = {
    sent: 'Messages Sent',
    received: 'Messages Received',
    messages: 'Messages'
}