import { useApolloClient, useQuery } from '@apollo/client';
import { Button, capitalize, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import CalendarRefreshIcon from 'mdi-react/CalendarRefreshIcon';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import CubeOutlineIcon from 'mdi-react/CubeOutlineIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import FaceAgentIcon from 'mdi-react/FaceAgentIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import FileTreeIcon from 'mdi-react/FileTreeIcon';
import KeyboardArrowRightIcon from 'mdi-react/KeyboardArrowRightIcon';
import SignTextIcon from 'mdi-react/SignTextIcon';
import SitemapIcon from 'mdi-react/SitemapIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import { CHANGE_PLAN_PATH, CREATE_ORG_PATH, MANAGE_ORG_BILLING_PATH, MANAGE_ORG_MANAGE_USERS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SETTINGS_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, ORGS_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { SessionData } from '../../interfaces';
import { BillingProviderData, BillingProviderQuery, BillingSummaryData, BillingSummaryQuery, EnPlansTranslations, EnRuntimeSizeTranslation, FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, OrganizationData, OrganizationQuery, OrganizationsSuborgsData, OrganizationsSuborgsQuery, OrganizationVars, PlansEnum, PlansTranslations, RolesData, RolesQuery, RuntimeSizeTranslation } from '../../models';
import { SessionQuery } from '../../queries/Session';
import { calculateBillingTotal, estimateCost } from './Billing/CostUtils';

export const OrgDashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgDashboard:${key}`, interpolate);

    // separate PlanTranslations since Starter plan conflicts with Starter nodes (which are translated as small)
    i18n.addResourceBundle('en', 'PlanTranslations', EnPlansTranslations);
    const pt = (key: keyof PlansTranslations, interpolate?: object) => t(`PlanTranslations:${key}`, interpolate);
    
    const { org_id } = useParams<any>();
    const history = useHistory();
    const classes = useStyles();

    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id! } })!
    const { data: { session } = { session: undefined } } = useQuery<SessionData>(SessionQuery, { fetchPolicy: 'cache-only' });

    const {
        loading: loadingRoles,
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, { variables: { org_id }, fetchPolicy: 'cache-and-network' });

    const {
        loading: loadingBillingProvider,
        data: {
            billingProvider
        } = { billingProvider: undefined }
    } = useQuery<BillingProviderData>(BillingProviderQuery, { variables: { org_id } });

    const {
        loading: loadingBillingSummary,
        data: {
            billingSummary
        } = { billingSummary: undefined }
    } = useQuery<BillingSummaryData>(BillingSummaryQuery, { variables: { org_id }, fetchPolicy: 'cache-and-network' });

    const { 
        data: {
            organization: masterOrg,
        } = { organization: undefined }
    } = useQuery<OrganizationData, OrganizationVars>(OrganizationQuery, {
        skip: !organization.master_org,
        variables: {
            id: organization.master_org!
        },
        fetchPolicy: 'cache-only'
    });

    const { 
        data: {
            organizationSuborgs: subOrgs,
        } = { organizationSuborgs: [] }
    } = useQuery<OrganizationsSuborgsData, OrganizationVars>(OrganizationsSuborgsQuery, {
        variables: {
            id: org_id,
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { }
    });

    const isOwner = organization.owner === session?.user_id;
    const role = roles.find(r => r.user_id === session?.user_id);

    if ((loadingRoles || loadingBillingSummary || loadingBillingProvider) && !(roles || billingSummary)) {
        return <CircularProgress />;
    }

    const overviewItems: DisplayCardListItem[] = [
        {
            icon: <DomainIcon />,
            title: lt('name'),
            value: organization.name
        },
        {
            icon: <SignTextIcon />,
            title: lt('orgId'),
            value: organization._id
        },
        {
            icon: <EmailOutlineIcon />,
            title: lt('delegate'),
            value: organization.delegate
        },
        {
            icon: <AccountMultipleIcon />,
            title: lt('users'),
            value: roles.length
        }
    ];

    const getSupportLabel = () => {
        switch (organization.support_level) {
            case 100: return lt('free');
            case 200: return lt('select');
            case 300: return lt('priority');
        }
    };

    const subscriptionItems: DisplayCardListItem[] = [
        {
            icon: <TuneIcon />,
            title: lt('plan'),
            value: pt(PlansEnum[organization.plan]),
            actionIcon: <Button size="small" variant="contained" color="primary" disabled={!isOwner}
                onClick={() => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`)}
            >{lt('changePlan')}</Button>
        },
        {
            icon: <FaceAgentIcon />,
            title: lt('support'),
            value: getSupportLabel()
        },
        {
            icon: <BullseyeIcon />,
            title: lt('allowedNodeSizes'),
            value: organization.limits.nodes.allowed_sizes.map(size => lt(size)).join(lt('listSeparation'))
        },
        {
            icon: <CubeOutlineIcon />,
            title: lt('enabledFeatures'),
            value: lt('runtimesAndConfigurations', {
                runtimes: organization.limits.services.allowed_services.length,
                configurations: organization.limits.configurations.allowed_configurations.length
            })
        }
    ];

    let totalCost = 0;
    if (billingSummary) {
        totalCost = calculateBillingTotal(billingSummary.memberships) + calculateBillingTotal(billingSummary.nodes) +
            calculateBillingTotal(billingSummary.services) + calculateBillingTotal(billingSummary.storage) +
            calculateBillingTotal(billingSummary.support);
    }

    const { estimate } = estimateCost(totalCost);

    let paymentType = '--';
    if (billingProvider) {
        if (['aws', 'azure', 'stripe'].includes(billingProvider.type)) {
            paymentType = lt('payAsYouGo');
        } else if (['other', 'contract'].includes(billingProvider.type)) {
            paymentType = lt('contract');
        }
    }

    const billingAndUsageItems: DisplayCardListItem[] = [
        {
            icon: <CalendarIcon />,
            title: lt('monthToDateCost'),
            value: lt('dollarAmount', { amount: totalCost.toFixed(2) })
        },
        {
            icon: <ChartLineIcon />,
            title: lt('forecastedMonthlyCost'),
            value: lt('dollarAmount', { amount: estimate.toFixed(2) })
        },
        {
            icon: <ViewGridIcon />,
            title: lt('paymentType'),
            value: paymentType
        },
        {
            icon: <CalendarRefreshIcon />,
            title: lt('paymentPeriod'),
            value: billingProvider?.type === 'none' ? lt('none') : lt('endOfMonth')
        }
    ];

    const userstems: DisplayCardListItem[] = roles.map(role => ({
        icon: <AccountCircleOutlineIcon />,
        title: role.email,
        value: lt('userJoined', { date: new Date(role.created_at).toLocaleDateString() })
    }));

    const documentationList: DisplayCardListItem[] = [
        {
            icon: <FileDocumentIcon />,
            title: lt('plans'),
            value: lt('plansDescription'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/plans/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('resourceLimits'),
            value: lt('resourceLimitsDescription'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/resource-limits/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('billingProviders'),
            value: lt('subscriptionRequiredInformation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/billing-provider/')
        }
    ];

    const relationshipItems: DisplayCardListItem[] = [{
        title: lt('type'),
        value: organization.master_org ? lt('subOrg') : `${lt('rootOrg')}${organization.type === 'oidc' ? lt('oidc') : ''}`,
        icon: <SitemapIcon />,
    }];
    if (organization.master_org) {
        relationshipItems.push({
            title: lt('rootOrg'),
            value: masterOrg ? <Link key={`link-to-${organization.master_org}`} className={classes.suborg} to={`/${ORGS_PATH}/${organization.master_org}/${MANAGE_ORG_PATH}`}>{masterOrg.name}</Link> : organization.master_org,
            icon: <FileTreeIcon />,
        });
    } else {
        relationshipItems.push({
            title: lt('subOrgs', {count: subOrgs.length}),
            value: <>{subOrgs.map(o =>
                o.owner === session?.user_id
                ? <Link key={`link-to-${o._id}`} className={classes.suborg} to={`/${ORGS_PATH}/${o._id}/${MANAGE_ORG_PATH}`}>{lt('suborgDisplay', o)}</Link>
                : <Typography key={`link-to-${o._id}`} className={classes.suborg} variant="body2" display="inline">{lt('suborgDisplay', o)}</Typography>
            )}</>,
            icon: <FileTreeIcon />,
        });
    }
    relationshipItems.push({
        title: lt('yourRole'),
        value: isOwner ? lt('owner') : capitalize(role?.role || ''),
        icon: <CardAccountDetailsOutlineIcon />,        
    });

    const makeRelationshipLinkButton = () => {
        if (organization.type === 'oidc' || !featureToggles.newOrg) return undefined

        if (organization.master_org) {
            return {
                text: lt('manageRootOrg'),
                onClick: () => history.push(`/${ORGS_PATH}/${masterOrg?._id}/${MANAGE_ORG_PATH}`)
            }
        } else {
            return {
                text: lt('createChildOrg'),
                onClick: () => history.push(`/${ORGS_PATH}/${CREATE_ORG_PATH}/1`)
            }
        }
    } 

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('dashboard')}</Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item lg={4} sm={6} xs={12}>
                    <DisplayCard header={lt('overview')} itemList={overviewItems}
                        linkButton={{
                            text: lt('viewSettings'),
                            onClick: () => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SETTINGS_PATH}`)
                        }}
                    />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <DisplayCard header={lt('billingAndUsage')} itemList={billingAndUsageItems}
                        linkButton={{
                            text: lt('viewBilling'),
                            onClick: () => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_BILLING_PATH}`)
                        }}
                    />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <DisplayCard header={lt('subscription')} itemList={subscriptionItems}
                        linkButton={{
                            text: lt('viewSubscription'),
                            onClick: () => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}`)
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item lg={4} sm={6} xs={12}>
                    <DisplayCard header={lt('relationships')} itemList={relationshipItems}
                        linkButton={makeRelationshipLinkButton()}/>
                </Grid>
                <Grid item lg={4} sm={6} xs={12} className={classes.flexItem}>
                    <DisplayCard header={lt('users')} itemList={userstems}
                        linkButton={{
                            text: lt('manageUsers'),
                            onClick: () => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_MANAGE_USERS_PATH}`)
                        }}
                    />
                </Grid>
                <Grid item lg={4} sm={6} xs={12} className={classes.flexItem}>
                    <DisplayCard header={lt('documentation')} itemList={documentationList} />
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    flexItem: {
        display: 'flex'
    },
    suborg: {
        textDecoration: 'none',
        paddingRight: theme.spacing(1),
    }
}));

interface translations extends RuntimeSizeTranslation {
    dashboard: string
    overview: string
    name: string
    orgId: string
    delegate: string
    users: string
    viewSettings: string
    subscription: string
    plan: string
    support: string
    free: string
    select: string
    priority: string
    allowedNodeSizes: string
    listSeparation: string
    enabledFeatures: string
    runtimesAndConfigurations: string
    viewSubscription: string
    billingAndUsage: string
    viewBilling: string
    monthToDateCost: string
    dollarAmount: string
    forecastedMonthlyCost: string
    paymentType: string
    payAsYouGo: string
    contract: string
    paymentPeriod: string
    endOfMonth: string
    none: string
    manageUsers: string
    userJoined: string
    plans: string
    plansDescription: string
    resourceLimits: string
    resourceLimitsDescription: string
    documentation: string
    billingProviders: string
    subscriptionRequiredInformation: string
    changePlan: string
    relationships: string
    type: string
    subOrg: string
    subOrgs: string
    yourRole: string
    owner: string
    createChildOrg: string
    rootOrg: string
    manageRootOrg: string
    suborgDisplay: string
    oidc: string
}

const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    dashboard: 'Dashboard',
    overview: 'Overview',
    name: 'Name',
    orgId: 'Org ID',
    delegate: 'Primary Contact Email',
    users: 'Users',
    viewSettings: 'View Settings',
    subscription: 'Subscription',
    plan: 'Plan',
    support: 'Support',
    free: 'Free',
    select: 'Select',
    priority: 'Priority',
    allowedNodeSizes: 'Allowed Node Sizes',
    listSeparation: ', ',
    enabledFeatures: 'Enabled Features',
    runtimesAndConfigurations: '{{runtimes}} Runtimes, {{configurations}} Configurations',
    viewSubscription: 'View Subscription',
    billingAndUsage: 'Billing and Usage',
    viewBilling: 'View Billing',
    monthToDateCost: 'Month to Date Cost',
    // eslint-disable-next-line
    dollarAmount: '${{amount}}',
    forecastedMonthlyCost: 'Forecasted Monthly Cost',
    paymentType: 'Payment Type',
    payAsYouGo: "Pay As You Go",
    contract: 'Contract',
    paymentPeriod: 'Payment Period',
    endOfMonth: 'End of Month',
    none: 'None',
    manageUsers: 'Manage Users',
    userJoined: 'Joined {{date}}',
    plans: 'Plans',
    plansDescription: 'Subscription plans to match the needs of a project from concept to production',
    resourceLimits: 'Resource Limits',
    resourceLimitsDescription: 'Thresholds across a Kaleido organization based on the selected plan.',
    documentation: 'Documentation',
    billingProviders: 'Billing Providers',
    subscriptionRequiredInformation: 'Subscription Required Information',
    changePlan: 'Change Plan',
    relationships: 'Relationships',
    type: 'Org Type',
    rootOrg: 'Root Organization',
    subOrg: 'Sub Org',
    subOrgs: 'Sub-Orgs ({{count}})',
    owner: 'Owner',
    yourRole: 'Your Role',
    createChildOrg: 'Create sub-org',
    manageRootOrg: 'Manage root-org',
    suborgDisplay: '{{name}} [{{_id}}]',
    oidc: ' (using OIDC login)'
}
