import { useLazyQuery } from '@apollo/client';
import { Button, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HDWalletAccountData, HDWalletAccountQuery, HDWalletAccountVars } from '../../models/hdwallet';
import { FormDialog, MessageSnackbar } from '../DialogWrappers';
import { CopyableSettings, CopyableSetting } from '../DisplaySettings';
import { ShortenedHash } from '../FormControls/ShortenedHash';
import { PasswordInput } from '../PasswordInput/PasswordInput';
import { useParams } from 'react-router-dom';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_ACCOUNTS_PATH } from '../MainNav/SideNavs/AddressBook';
import { FormLink } from '../FormControls/FormLink';

interface Props {
    serviceId: string
    walletId: string
    setWalletId: React.Dispatch<React.SetStateAction<string>>
    isSigningWidget?: boolean
    setAccountIndex?: React.Dispatch<React.SetStateAction<string>>
    setAccountAddress?: React.Dispatch<React.SetStateAction<string>>
    prefilledIndex?: string
}

export const FetchAccount = ({ serviceId, walletId, setWalletId, isSigningWidget, setAccountIndex, setAccountAddress, prefilledIndex = '' }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'HDWalletsFetchAccount', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`HDWalletsFetchAccount:${key}`, interpolate)

    const {org_id, consortium_id, environment_id} = useParams<any>();

    const [fetchWalletOpen, setFetchWalletOpen] = useState(false)
    const [index, setIndex] = useState(prefilledIndex)
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!fetchWalletOpen && !isSigningWidget) {
            setWalletId('')
            setIndex('')
        }
    }, [fetchWalletOpen, setWalletId, isSigningWidget])

    useEffect(() => {
        setFetchWalletOpen(walletId ? true : false)
    }, [walletId])

    const [fetchAccount, {
        loading: hdwalletAccountLoading,
        data: { 
            hdwalletAccount
        } = { hdwalletAccount: null },
        variables: {
            id: fetchedWithWalletId,
            index: fetchedWithIndex
        } = { id: '', index: '' }
    }] = useLazyQuery<HDWalletAccountData, HDWalletAccountVars>(HDWalletAccountQuery, { 
        fetchPolicy: 'cache-first',
        onError: () => setErrorMessage(lt('indexNotFound'))
    });

    const okToShowHdWalletAccount = hdwalletAccount && fetchedWithWalletId === walletId && fetchedWithIndex === index

    useEffect(() => {
        if (setAccountIndex && setAccountAddress) {
            setAccountIndex(okToShowHdWalletAccount ? fetchedWithIndex : '')
            setAccountAddress(okToShowHdWalletAccount ? hdwalletAccount?.address?.toLowerCase() ?? '' : '')
        }
    }, [setAccountIndex, setAccountAddress, hdwalletAccount, okToShowHdWalletAccount, fetchedWithIndex])

    let copyableList: CopyableSetting[] = [{
        title: lt('address'),
        displayValue: okToShowHdWalletAccount ? <ShortenedHash address={hdwalletAccount!.address} showFullAddress /> : '--',
        copyableValue: hdwalletAccount?.address,
        disableCopy: !okToShowHdWalletAccount
    }]
    if (!isSigningWidget) {
        copyableList.push({
            title: lt('privateKey'),
            displayValue:  
                <PasswordInput disabled={!okToShowHdWalletAccount}
                    value={okToShowHdWalletAccount ? hdwalletAccount!.privateKey : ''}
                    margin="none"
                    fullWidth
                    variant="outlined"
                />,
            copyableValue: okToShowHdWalletAccount ? hdwalletAccount!.privateKey : undefined,
            disableCopy: !okToShowHdWalletAccount
        },
        {
            title: lt('accountDetails'),
            displayValue: okToShowHdWalletAccount ? 
                <FormLink to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}/${hdwalletAccount!.address}`}>
                    {lt('viewAddressDetails')}
                </FormLink> : '--',
            disableCopy: true
        })
    }

    const fetchWalletAccount = (
        <Grid item container direction="column" spacing={3}>
            <Grid item container direction="row" alignItems="center" spacing={3}>
                <Grid item>
                    <TextField type='number' disabled={hdwalletAccountLoading || !walletId}
                        required
                        value={index} 
                        onChange={event => setIndex(event.target.value)}
                        fullWidth
                        margin="none"
                        label={lt('index')}
                        variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <Button color={isSigningWidget ? "default" : "primary"} variant="contained" size="large" disabled={!walletId || !index || hdwalletAccountLoading}
                        onClick={() => fetchAccount({ variables: { service_id: serviceId, id: walletId, index } })}>
                        {lt('fetch')}
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <CopyableSettings {...{copyableList}} />
            </Grid>
        </Grid>
    )

    return isSigningWidget ? fetchWalletAccount : (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage}/>
            <FormDialog open={fetchWalletOpen} setOpen={setFetchWalletOpen}
                header={lt('fetchWallet', { id: walletId })} 
                description={lt('provideIndex')}
                hideSave width="md"
                dialogType="medium" 
                controlsWrapper={fetchWalletAccount} 
            />
        </>
    )
};

interface translations {
    fetchWallet: string,
    index: string,
    fetch: string,
    address: string,
    privateKey: string,
    provideIndex: string,
    indexNotFound: string,
    accountDetails: string,
    viewAddressDetails: string,
}
const enTranslations: translations = {
    fetchWallet: 'Fetch Wallet Information ({{id}})',
    index: 'Index',
    fetch: 'Fetch',
    address: 'Address',
    privateKey: 'Private Key',
    provideIndex: 'Provide the index of the account that you wish to retrieve. The index can range from 0 to 2,147,483,647.',
    indexNotFound: 'Index not found.',
    accountDetails: 'Address Details',
    viewAddressDetails: 'View',
}