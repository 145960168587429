import React from 'react';
import Highcharts from 'highcharts';
import { useTranslation } from 'react-i18next';
import { BrandColors } from '../../utils/Colors';
import { PieChart } from '../../components/Charts/PieChart';


interface Props {
    erc20Tokens: number,
    erc721Tokens: number
}

export const TokensPie = ({erc20Tokens, erc721Tokens}: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokensPie', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokensPie:${key}`, interpolate);

    const data = [
        {name:'ERC20', y: erc20Tokens, color: BrandColors.blurple },
        {name: 'ERC721', y: erc721Tokens, color: BrandColors.yellow }
    ];

    function labelFormatter (this: Highcharts.Point | Highcharts.Series)  {
        return this.name + ` (${this.name === 'ERC20' ? erc20Tokens : erc721Tokens})`
    }

    return <PieChart {...{data}} title={lt('header')} {...{labelFormatter}} /> 
}

interface translations {
    blocks: string,
    blockNumber: string,
    header: string,
}
const enTranslations: translations = {
    blocks: 'Blocks',
    header: 'Tokens',
    blockNumber: 'Block Number',
}
