import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { ContractProjectData, ContractProjectQuery,
    CompiledContractsData, CompiledContractsVars, CompiledContractsQuery, ContractProjectTypeTranslations, EnContractProjectTypeTranslations } from '../../models'
import { Grid, Button, Typography } from "@material-ui/core";
import { DisplayTable, EmptyState, DisplayCard } from '../../components/DisplayWrappers'
import { ConsortiumResourceVars } from '../../interfaces'
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../components/MainNav/ConsortiumWrapper'
import { UpdateName } from '../../components/DialogWrappers/UpdateName';
import { DeleteResource } from '../../components/DialogWrappers/DeleteResource';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { getProtocolName } from '../../components/ContractManagement/AppsTable';
import { capitalize } from '../../utils/StringUtils';

export const ContractProject = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsContractProject', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsContractProject:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, contract_id } = useParams<any>();

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [updateDescriptionDialogOpen, setUpdateDescriptionDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const {
        data: {
            contractProject: app
        } = { contractProject: null }
    } = useQuery<ContractProjectData, ConsortiumResourceVars>(ContractProjectQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: contract_id!
        },
        fetchPolicy: 'cache-only' 
    });

    const {
        data: {
            compiledContracts
        } = { compiledContracts: null }
    } = useQuery<CompiledContractsData, CompiledContractsVars>(CompiledContractsQuery, {
        variables: { 
            consortia_id: consortium_id!,
            contract_id: contract_id!
        },
        fetchPolicy: 'cache-only'
    });

    const copyableList: CopyableSetting[] = [
        { title: lt('id'), displayValue: app!._id },
        { title: lt('name'), displayValue: app!.name, editOnClick: () => setUpdateNameDialogOpen(true) },
        { title: lt('description'), displayValue: app!.description || '', editOnClick: () => setUpdateDescriptionDialogOpen(true) },
        { title: lt('protocol'), displayValue: capitalize(getProtocolName(app!.type)), disableCopy: true },
        { title: lt('type'), displayValue: lt(app!.type), disableCopy: true },
        { title: lt('owner'), displayValue: app!.membership.name, disableCopy: true },
        { title: lt('createdAt'), displayValue: new Date(app!.created_at).toLocaleString(), disableCopy: true },
    ]

    const columnHeaders = [
        '',
        lt('description'),
        lt('owner'),
        lt('createdAt'),
        lt('status')
    ]

    const records = compiledContracts?.map(a => ({
        key: a._id,
        onClick: () => history.push(`${pathname}/${NETWORK_COMPILED_CONTRACTS_PATH}/${a._id}`),
        columns: [
            {isIcon: true, value: <DescriptionIcon />},
            {value: a.description, maxWidthPixelValue: 250 },
            {value: a.membership.name},
            {value: new Date(a.created_at).toLocaleString()},
            {value: <ResourceStateChip state={a.state} />}
        ]
    }));

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('contractManagement'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/smart-contracts/')
        },
        {
            icon: <DescriptionIcon />,
            title: lt('howItWorks'),
            value: lt('blog'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/')
        },
        {
            icon: <DescriptionIcon />,
            title: lt('whatAreContracts'),
            value: lt('blog'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://kaleido.io/how-do-smart-contracts-work/')
        }
    ];

    const actionBar = compiledContracts?.every(c => c.state === 'failed') ? (
        <Grid item>
            <Button color="default" size="medium" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
                {lt('deleteProject')}
            </Button>
        </Grid>
    ) : <></>

    const createCompilation = () => history.push(`${pathname}/${NETWORK_COMPILED_CONTRACTS_PATH}/create/1`)

    const content = (
        <Grid item container direction="column" spacing={3}>
            <Grid item container spacing={3}>
                <Grid item container md={12} lg={8}>
                    <CopyableSettings header={lt('appInfo')} {...{actionBar}} {...{copyableList}} />
                </Grid>
                <Grid item container md={12} lg={4}>
                    <DisplayCard header={lt('documentation')} itemList={DocumentationList} />
                </Grid>
            </Grid>
            <Grid item container>
                {compiledContracts?.length ? 
                <DisplayTable header={lt('versions')} {...{columnHeaders}} {...{records}} /> :
                <EmptyState imageFile='Empty-Contracts.svg' 
                    title={lt('createACompilation')} 
                    description={lt('emptyDescription')} 
                    button={{ text: lt('newVersion'), onClick: createCompilation }}
                    documentation={{ text: lt('documentation'), link: 'https://docs.kaleido.io/using-kaleido/smart-contracts/' }} />
                }
            </Grid>
        </Grid>
    )
    
    return (
        <>
            <UpdateName defaultName={app!.name} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <UpdateName fieldNameToUpdate="description" defaultName={app!.description || ''} open={updateDescriptionDialogOpen} setOpen={setUpdateDescriptionDialogOpen} />
            <DeleteResource disableNameConfirmation name={app!.name} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />

            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('appDetails', {name: app?.name})}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" size="large" onClick={createCompilation}>
                            {lt('newVersion')}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item container direction="row" spacing={3}>
                    <Grid item container direction="column" spacing={3}>
                        { content }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends ContractProjectTypeTranslations {
    appDetails: string
    appInfo: string
    versions: string
    newVersion: string
    description: string
    owner: string
    createdAt: string
    status: string
    name: string
    type: string
    id: string
    deleteProject: string
    createACompilation: string
    emptyDescription: string
    documentation: string
    contractManagement: string
    whatAreContracts: string
    blog: string
    howItWorks: string
    protocol: string
}
const enTranslations: translations = {
    ...EnContractProjectTypeTranslations,
    appDetails: '{{name}} Details',
    appInfo: 'Information',
    versions: 'Versions',
    newVersion: 'Create New Version',
    description: 'Description',
    owner: 'Owner',
    createdAt: 'Created at',
    status: 'Status',
    name: 'Name',
    type: 'Type',
    id: 'ID',
    deleteProject: 'Delete project',
    createACompilation: 'Create a Version',
    emptyDescription: 'Let\'s create our first compiled version of this app.',
    documentation: 'Documentation',
    contractManagement: 'Contract management',
    whatAreContracts: 'What are contracts?',
    blog: 'Blog',
    howItWorks: 'How does it work?',
    protocol: 'Protocol'
}