import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { ErrorSnackbarCatcher, FullScreenCreate, MessageSnackbar } from '../../../components/DialogWrappers';
import { APPS_BASE_PATH, APPS_EVENTSTREAMS_PATH } from '../../../components/MainNav/SideNavs/AppsIntegrations';
import { NODE_EVENTSTREAMS_PATH } from '../../../components/NodeNav/NodeNav';
import { CreateEditEventStream, CreateEventStreamData, CreateEventStreamMutation, CreateEventStreamVars, EventStreamType } from '../../../models/eventStreams';
import { Step1 } from './Step1';
import { Step2Webhook } from './Step2Webhook';
import { Step2WebSocket } from './Step2WebSocket';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamsCreateEventStream', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamsCreateEventStream:${key}`, interpolate)

    const { pathname } = useLocation()
    const { org_id, consortium_id, environment_id, step } = useParams<any>();

    type locationState = { 
        nodeId: string,
        cancelPath: string
    }
    const history = useHistory<locationState>()

    const { 
        location: { 
            state: {
                nodeId: createNodeId,
                cancelPath: referrerCancelPath
            } = { nodeId: '', cancelPath: '' } 
        } 
    } = history

    const [message, setMessage] = useState('');
    const [name, setName] = useState('')
    const [nodeId, setNodeId] = useState(createNodeId || '');
    const [type, setType] = useState<EventStreamType>('webhook');
    const cancelPath = referrerCancelPath || `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}/${APPS_EVENTSTREAMS_PATH}`

    const [createEventStream, { loading }] = 
        useMutation<CreateEventStreamData, CreateEventStreamVars>(CreateEventStreamMutation)

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const invalidStep = step !== "1" && step !== "2"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep > 1 || (createStep >= 1 && !name)) {
        return <Redirect to={redirectToStep1} />
    }

    const save = async (eventStream: CreateEditEventStream) => {
        // create the event stream
        createEventStream({
            variables: {
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                node_id: nodeId,
                eventStream
            }
        }).then(result => {
            if (result) {
                const newEventStreamId = result.data?.createEventStream?.id
                if (newEventStreamId) {
                    history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${nodeId}/${NODE_EVENTSTREAMS_PATH}/${newEventStreamId}`)
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const stepComponents = [
        { step: lt('details'), component: <Step1 {...{nodeId}} {...{setNodeId}} {...{name}} {...{setName}} {...{type}} {...{setType}} cancelPath={cancelPath} /> },
        { step: lt('setupStream'), component:
            type === 'webhook'
                ? <Step2Webhook {...{nodeId}} {...{name}} {...{type}} {...{save}} {...{loading}} cancelPath={cancelPath} />
                : <Step2WebSocket {...{nodeId}} {...{name}} {...{type}} {...{save}} {...{loading}} cancelPath={cancelPath} />
        },
    ]

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createEventStream')} stepUrlParam={step!} {...{stepComponents}} />
    </>
};

interface translations {
    createEventStream: string,
    details: string,
    setupStream: string
}
const enTranslations: translations = {
    createEventStream: 'Create Event Stream',
    details: 'Details',
    setupStream: 'Setup Stream'
}