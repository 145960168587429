import React, { useEffect, useState, useMemo, useCallback } from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { CircularProgress, Grid, Typography, Button, Paper, FormControlLabel, makeStyles, Checkbox } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { NodesData, NodesQuery, NodeStatusData, NodeStatusQuery, Account, GatewayAPIData, GatewayAPIQuery } from '../../models';
import { EnvironmentResourcesVars, EnvironmentResourceVars } from '../../interfaces';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { CookieAppCred } from '../../components/CookieAppCred/CookieAppCred';
import { useTranslation } from 'react-i18next';
import { AccountSelector } from '../../components/AccountSelector/AccountSelector';
import { MessageSnackbar, ErrorSnackbarCatcher } from '../../components/DialogWrappers';
import { NodeSelector } from '../../components/FormControls/NodeSelector';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/KeyboardArrowRightIcon';
import { DisplayCard } from '../../components/DisplayWrappers';
import { APPS_BASE_PATH, APPS_GATEWAY_APIS_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';

type locationState = { factory: boolean, node: string }

export const GatewaySwagger = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GatewaySwagger', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GatewaySwagger:${key}`, interpolate);

    const { consortium_id, environment_id, endpoint, gateway_api_id, org_id } = useParams<any>();
    const history = useHistory<locationState>();
    const classes = useStyles();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    
    const { 
        location: { 
            state: {
                factory: factoryPreSelected,
                node: preselectedNodeId
            } = { factory: false, node: ''} 
        }
    } = history;

    const [spec, setSpec] = useState<object | undefined>(undefined);
    const [selectedNodeId, setSelectedNodeId] = useState(preselectedNodeId);
    const [cookieAppCred, setCookieAppCred] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [account, setAccount] = useState<Account | undefined>(undefined)
    const [loading, setLoading] = useState(false);
    const [isFactory, setIsFactory] = useState(factoryPreSelected);
    const [hideSwagger, setHideSwagger] = useState(false);

    const queryVars = { consortia_id: consortium_id!, environment_id: environment_id! };
    const { 
        data: { 
            nodes: allNodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: queryVars,
        fetchPolicy: 'cache-only'
    });

    const nodes = allNodes.filter(n => n.state === 'started' && n.membership.isMine)

    const selectedNode = useMemo(() => {
        return nodes.find(n => n._id === selectedNodeId);
    }, [selectedNodeId, nodes]);

    const {
        loading: statusLoading,
        data: {
            nodeStatus
        } = { nodeStatus: null }
    } = useQuery<NodeStatusData>(NodeStatusQuery, {
        variables: {
            ...queryVars,
            id: selectedNode?._id ?? ''
        },
        fetchPolicy: 'network-only',
        skip: !selectedNode
    });

    const {
        loading: gatewayLoading,
        data: {
            gatewayAPI
        } = { gatewayAPI: null }
    } = useQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, { 
        variables: {
            ...queryVars,
            id: gateway_api_id
        },
        fetchPolicy: 'cache-first' 
    });

    useEffect(() => {
        setAccount(undefined)
    }, [selectedNode])

    useEffect(() => {
        setSpec(undefined)
    }, [endpoint]);
    
    const path = useMemo(() => {
        let path;
        const basePath = selectedNode?.urls?.kaleido_connect;
        if (endpoint) {
            path = `${basePath}/instances/${endpoint}?swagger&refresh`
        } else {
            path = `${basePath}/gateways/${gatewayAPI?.endpoint || gatewayAPI?._id}/?swagger&refresh${isFactory ? '&factory' : ''}`;
        }
        return path
    }, [selectedNode, endpoint, gatewayAPI, isFactory]);

    const completePath = useMemo(() => {
        let finalPath;
        if (account?.account_type === 'hdwallet') {
            finalPath = `${path}&from=hd-${account?.hdwalletDetails?.serviceId}-${account?.hdwalletDetails?.walletId}-${account?.hdwalletDetails?.accountIndex}`
        } else {
            finalPath = `${path}&from=${account?._id || nodeStatus?.user_accounts[0] || ''}`
        }
        return `${finalPath}&schemes=https&noauth`
    }, [path, account, nodeStatus]);
    
    const fetchCall = useCallback(async (path: string) => {
        if (selectedNode && cookieAppCred && nodeStatus) {
            const auth = window.btoa(cookieAppCred);
            const httpHeaders = {
                Authorization: `Basic ${auth}`
            };
            const data = await fetch(path, {
                method: 'GET',
                headers: new Headers(httpHeaders)
            })
            if (!data.ok) {
                const response = await data.json();
                throw new Error(response.error);
            }
            const spec = await data.json();
            return spec
        }
    }, [selectedNode, cookieAppCred, nodeStatus]);

    if ((statusLoading && !nodeStatus) || (gatewayLoading && !gatewayAPI)) return <CircularProgress />
    if (!gatewayAPI) return <Redirect to={`${basePath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}`} />;

    const deployCheckLabel = (
        <div className={classes.radioLabel}>
            <Typography variant="body1">
                {lt('deployContract')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {lt('deployDescription')}
            </Typography>
        </div>
    );

    const onSubmit = async () => {
        setLoading(true);
        try {
            const response = await fetchCall(completePath);
            setSpec(response as object)
            setHideSwagger(false);
        } catch(err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
            setHideSwagger(true);
        } finally {
            setLoading(false)
        }
    }

    const getFileName = () => {
        if (endpoint) return endpoint as string;
        return gatewayAPI?.endpoint || gatewayAPI?._id || ''
    }

    const onDownloadClick = async () => {
        try {
            const response = await fetchCall(completePath)
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(response));
            var downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", getFileName() + ".swagger.json");
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        } catch(err) {
            setErrorMessage(lt('tryAgainLater'))
        }
    }

    const DocumentationList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('restApi'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/ethconnect/rest-apis/')
        }
    ];

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            {/* This will only re-render when a node with different membership is selected */}
            {selectedNode && (
                <CookieAppCred key={selectedNode.membership_id} membershipId={selectedNode.membership_id} 
                        nodeId={selectedNode._id} {...{setCookieAppCred}} 
                        {...{setErrorMessage}} />
            )}
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">{lt('header')}</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={onDownloadClick}
                            variant="contained"
                            color="primary">
                                {lt('downloadApi')}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.paperPadding}>
                            <Grid container direction="column" spacing={3} alignItems="center" >
                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography gutterBottom variant="body1">{lt('selectNodeDescription')}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <NodeSelector nodeId={selectedNodeId} setNodeId={setSelectedNodeId} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography gutterBottom variant="body1">{lt('chooseAccountDescription')}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <AccountSelector {...{account}} 
                                                {...{setAccount}}
                                                disabled={!selectedNode}
                                                preSelectedAddress={nodeStatus?.user_accounts[0]}
                                                fullWidth
                                                customSelectAccountMessage={lt('selectSignerAccount')}
                                                membershipId={selectedNode?.membership_id ?? ''} />
                                        </Grid>
                                    </Grid>
                                    {!endpoint && (
                                        <Grid item className={classes.deployRadio}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        data-test="checkbox_deployOnly"
                                                        color="primary"
                                                        checked={isFactory}
                                                        onChange={() => setIsFactory((c: boolean) => !c)}
                                                    />
                                                }
                                                label={deployCheckLabel}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button fullWidth
                                            data-test="button_viewApi"
                                            className={classes.button}
                                            variant="contained"
                                            color="primary"
                                            disabled={!selectedNode || !cookieAppCred || !account}
                                            onClick={onSubmit}>
                                            {lt('generateSwagger')}
                                        </Button>
                                    </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <DisplayCard header={lt('documentation')} itemList={DocumentationList} />
                    </Grid>
                </Grid>
                {loading && <Grid item><CircularProgress /></Grid>}
                { spec && !loading && !hideSwagger && (
                    <Grid item>
                        <Paper className={classes.paperPadding}>
                            <SwaggerUI
                                requestInterceptor={a => {
                                    const auth = window.btoa(cookieAppCred);
                                    let authHeaders = a.headers['Authorization'];
                                    if (!authHeaders || authHeaders === 'Basic ') a.headers['Authorization'] = `Basic ${auth}`
                                    return a
                                }}
                                spec={spec} />
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </>
    )
};

interface translations {
    header: string,
    node: string
    selectSignerAccount: string
    generateSwagger: string
    chooseAnAccount: string
    selectNode: string
    selectNodeDescription: string
    chooseAccountDescription: string
    deployContract: string
    deployDescription: string
    tryAgainLater: string
    downloadApi: string
    documentation: string
    restApi: string
}
const enTranslations: translations = {
    documentation: 'Documentation',
    restApi: 'REST API Gateway',
    header: 'REST API Definition',
    node: 'Node',
    selectSignerAccount: 'Select Signer Account',
    generateSwagger: 'View API',
    chooseAnAccount: 'Choose an Account',
    selectNode: 'Select Node',
    selectNodeDescription: 'Select the node that will be used to interact with the instance.',
    chooseAccountDescription: 'Select the account that will be used as default to sign transactions. If not, a node account will be selected by default.',
    deployContract: 'Deploy Contract',
    deployDescription: 'Only show the endpoint to deploy the contract',
    tryAgainLater: 'Sorry we encounter a problem. Please try again later',
    downloadApi: 'Download API',
}

const useStyles = makeStyles(theme => ({
    paperPadding: {
        padding: theme.spacing(3)
    },
    button: {
        minWidth: 120
    },
    deployRadio: {
        alignSelf: 'flex-start'
    },
    radioLabel: {
        paddingTop: theme.spacing(1)
    },
}));
