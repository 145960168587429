import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models';
import { ConsortiumResourcesVars } from '../../interfaces'

interface Props {
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    excludedMembershipIds?: string[],
    includeSysMon?: boolean,
    includeAllOrgsMemberships?: boolean
    disabled?: boolean
};

export const MembershipSelector = ({ membershipId, setMembershipId, excludedMembershipIds, includeSysMon = false, includeAllOrgsMemberships = false, disabled }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MembershipSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MembershipSelector:${key}`, interpolate)

    const { consortium_id } = useParams<any>();

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const { 
        data: { 
            consortiumMemberships
        } = { consortiumMemberships: [] } 
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    let memberships = consortiumMemberships.filter(m => !includeAllOrgsMemberships ? (m.is_mine) : true).map(m => ({_id: m._id, org_name: m.org_name, is_mine: m.is_mine}));
    
    if (includeSysMon) {
        memberships.push({_id: "sys--mon", org_name:"System", is_mine: false});
    }
    memberships = memberships.filter(m => !excludedMembershipIds?.includes(m._id));

    useEffect(() => {
        if (!membershipId && memberships.length) {
            setMembershipId(memberships.find(m => m.is_mine)?._id ?? memberships.find(m => m._id === 'sys--mon')?._id ?? '')
        }
    }, [memberships, membershipId, setMembershipId])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMembershipId(event.target.value as string);
    };

    return (
        <FormControl variant="outlined" fullWidth disabled={disabled}>
            <InputLabel ref={inputLabel} required>
                {lt(memberships.length === 0 ? 'noMembers' : 'selectMembership')}
            </InputLabel>
            <Select 
                labelWidth={labelWidth}
                value={memberships.some(m => m._id === membershipId) ? membershipId : ''}
                disabled={memberships.length === 0 || disabled}
                onChange={handleChange}>
                {memberships.map(m => (
                    <MenuItem key={m._id} selected={m._id === membershipId} value={m._id}>
                        {memberships.find(x => x._id === m._id)?.org_name ?? ''}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    )
};

interface translations {
    selectMembership: string
    noMembers: string
}
const enTranslations: translations = {
    selectMembership: 'Select Membership',
    noMembers: 'You have selected all the member(s) in your network.'
}