import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { APIKeyData, APIKeysQuery, APIKey } from '../../models/apiKeys';
import { OrganizationsData, OrganizationsQuery } from '../../models';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { DisplayTable, EmptyState } from '../../components/DisplayWrappers';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { Dotdotdot } from '../../components';
import { GenerateProfileAPIKey } from './GenerateAPIKey';
import { DeleteProfileAPIKey } from './DeleteAPIKey';
import KeyIcon from 'mdi-react/KeyIcon';

export const AdminAPIKeys: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AdminAPIKeys', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AdminAPIKeys:${key}`, interpolate);

    const [newAPIKeyDialogOpen, setNewAPIKeyDialogOpen] = useState(false);
    const [deleteAPIKeyDialogOpen, setDeleteAPIKeyDialogOpen] = useState(false);
    const [apiKeyToDelete, setApiKeyToDelete] = useState<APIKey | undefined>();

    const {
        data: {
            organizations
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery, { fetchPolicy: 'cache-only' });

    const {
        loading,
        data: {
            apiKeys
        } = { apiKeys: [] }
    } = useQuery<APIKeyData>(APIKeysQuery, { fetchPolicy: 'cache-and-network'});

    if (loading) {
        return <CircularProgress />
    }

    const handleDeleteAPIKey = (apiKey: APIKey) => {
        setApiKeyToDelete(apiKey);
        setDeleteAPIKeyDialogOpen(true);
    }

    const columnHeaders = [
        '',
        lt('apiKey'),
        lt('name'),
        lt('organization'),
        ''
    ];

    const records: DisplayTableRecord[] = apiKeys.map(apiKey => ({
        key: apiKey._id,
        columns: [
            { value: <KeyIcon /> },
            { value: lt('secretPartOfKey', { id: apiKey._id }) },
            { value: apiKey.name || '--' },
            { value: organizations.find(org => org._id === apiKey.org_id)?.name || '--' },
            {
                value: <Dotdotdot menuItems={[
                    { name: lt('deleteAPIKey'), action: () => handleDeleteAPIKey(apiKey) }
                ]} />
            }
        ]
    }));

    return (
        <>
            {apiKeys.length === 0 ? (
                <EmptyState imageFile='Empty-API-Keys.svg'
                    title={lt('apiKeys')}
                    description={lt('apiKeyDocumentation')}
                    button={{ text: lt('generateNewAPIKey'), onClick: () => setNewAPIKeyDialogOpen(true) }}
                    documentation={{ text: lt('apisAndAuthentication'), link: 'https://docs.kaleido.io/developers/automation/api-keys/' }} />
            ) : (
                    <Grid container spacing={3} direction="column">
                        <Grid item container alignItems="center" justify="space-between">
                            <Grid item>
                                <Typography variant="h5">
                                    {lt('adminApiKeys')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => setNewAPIKeyDialogOpen(true)}>{lt('generateNewAPIKey')}</Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <DisplayTable header={lt('adminApiKeys')} columnHeaders={columnHeaders} records={records} />
                        </Grid>
                    </Grid>
                )}
            <GenerateProfileAPIKey open={newAPIKeyDialogOpen} setOpen={setNewAPIKeyDialogOpen} organizations={organizations} />
            <DeleteProfileAPIKey open={deleteAPIKeyDialogOpen} setOpen={setDeleteAPIKeyDialogOpen} apiKey={apiKeyToDelete!} />
        </>
    );
}

interface translations {
    adminApiKeys: string
    apiKey: string
    name: string
    organization: string
    secretPartOfKey: string
    generateNewAPIKey: string
    deleteAPIKey: string
    apiKeys: string
    apiKeyDocumentation: string
    apisAndAuthentication: string
}

const enTranslations: translations = {
    adminApiKeys: 'Admin API Keys',
    apiKey: 'API Key',
    name: 'Name',
    organization: 'Organization',
    secretPartOfKey: '{{id}}-[secret part of key]',
    generateNewAPIKey: 'Generate New API Key',
    deleteAPIKey: 'Delete API Key',
    apiKeys: 'API Keys',
    apiKeyDocumentation: 'Everything that you can do via the Kaleido console, you can also do via the Kaleido API. API Keys serve as authorization tokens to programmatically manage your organization resources.',
    apisAndAuthentication: 'APIs & Authentication'
}