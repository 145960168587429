import { useQuery, useSubscription } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../components/DialogWrappers';
import { NODE_CORDAPPS_PATH } from '../../components/NodeNav/NodeNav';
import { ConsortiumResourcesVars, EnvironmentResourceVars } from '../../interfaces';
import { Account, ContractProjectsData, ContractProjectsQuery, ContractProjectsSubscription, MakeContractProjectsSubscriptionOptions, NodeData, NodeQuery } from '../../models';
import { Step1 } from './Step1';

export const KALEIDO_CONTRACT_TEMPLATES = 'Kaleido Contract Templates'
export const CREATE_NEW_PROJECT = 'createNewProject'

export type SourceCodeType = 'token' | 'github' | 'upload' | 'paste' | ''

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeCorDappsCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeCorDappsCreate:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, node_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}`

    type locationState = { sourceCodeType: SourceCodeType, contractId: string, newProjectName: string, deployerAccount?: Account}
    const history = useHistory<locationState>()

    const { 
        location: { 
            state: {
                sourceCodeType: createSourceCodeType,
                contractId: createContractId,
                newProjectName: createNewProjectName,
                deployerAccount
            } = { sourceCodeType: '', contractId: '', newProjectName: '', deployerAccount: undefined } 
        } 
    } = history

    // setup the contract projects query and subscription
    // we need the query to establish the records in the cache
    useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-and-network'
    });

    const { 
        loading: nodeLoading,
        data: { 
            node 
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: node_id!
        },
        fetchPolicy: 'cache-only'
    });

    useSubscription(ContractProjectsSubscription, MakeContractProjectsSubscriptionOptions({ consortia_id: consortium_id! }));

    const invalidStep = step !== "1"

    const [sourceCodeType, setSourceCodeType] = useState<SourceCodeType>((step && step >= "1") ? createSourceCodeType as SourceCodeType : '')
    const [contractId, setContractId] = useState((step && step >= "2") ? createContractId : CREATE_NEW_PROJECT);
    const [newProjectName, setNewProjectName] = useState((step && step >= "2") ? createNewProjectName : '');

    const redirect = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_CORDAPPS_PATH}/create/1`

    if (nodeLoading) return <CircularProgress />

    if (!node || invalidStep) {
        return <Redirect to={redirect} />
    }

    const stepComponents = [
        { 
            step: lt('importType'), 
            component: <Step1 {...{cancelPath}} {...{sourceCodeType}} {...{setSourceCodeType}}
                {...{contractId}} {...{setContractId}}
                {...{newProjectName}} {...{setNewProjectName}} {...{deployerAccount}} membershipId={node.membership_id}/> 
        },
    ]

    return <FullScreenCreate {...{cancelPath}} toolbarHeader={lt('newContract')} stepUrlParam={step!} {...{stepComponents}} hideStepper />
};

interface translations {
    newContract: string
    importType: string
    createGatewayAPI: string
    deployInstance: string
}
const enTranslations: translations = {
    newContract: 'Add New Version',
    importType: 'Import Type',
    createGatewayAPI: 'Create CordApp',
    deployInstance: 'Deploy Contract Instance'
}