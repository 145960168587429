import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const FireflyTemplateHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'RegistrationFireflyTemplateHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`RegistrationFireflyTemplateHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Firefly.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/kaleido-platform/firefly/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'Register your Identity',
    helpDescription: 'During this step, you will select an account to use, and define how you want your organization and node name to appear to other members in the network. Everyone under the same namespace in the network will be able to see this information under the FireFly Network tab.',
    finalInfo: 'Clicking Register will complete the FireFly node setup process and let you interact with other members in the network!'
}
