import React, { isValidElement } from 'react';
import { Typography, Paper, makeStyles, Grid, Divider, Button } from "@material-ui/core";
import { LinkButtonProps } from '../../interfaces';

interface Props {
    header?: string | JSX.Element,
    description?: string,
    actionBar?: JSX.Element, 
    kpi?: JSX.Element,
    displayGrid: JSX.Element, 
    linkButton?: LinkButtonProps,
    linkButton2?: LinkButtonProps,
    actionFooter?: JSX.Element,
    hideDivider?: boolean,
    hideFooterDivider?: boolean,
    padDisplayGrid?: boolean,
    toolBar?: JSX.Element,
    subtitle?: string,
    hidePaper?: boolean,
    leftAlignActionFooter?: boolean
    height?: string // forced height, should only be used in exceptional circumstances
};

const DisplayGridWrapper = ({ header, subtitle, description, actionBar, kpi, displayGrid, linkButton, linkButton2, actionFooter, hideDivider, 
    hideFooterDivider, padDisplayGrid, toolBar, hidePaper = false, leftAlignActionFooter = false, height }: Props) => {
    const classes = useStyles();    
        
    const content = (
        <Grid item container wrap="nowrap" direction="column" className={classes.height100} style={{ height: height}}>
            { toolBar ? toolBar :
                (header || description || subtitle || actionBar) && 
                    <Grid item container justifyContent="space-between" className={classes.container} alignItems="center" spacing={3}>
                        <Grid item>
                            <Grid item container direction="column" spacing={3}>
                                <Grid item container alignItems="baseline" spacing={1} justifyContent="space-between" wrap="nowrap">
                                    <Grid item>
                                        {isValidElement(header) ? header : 
                                            <Typography variant="h6" noWrap>
                                                {header}
                                            </Typography>
                                        }
                                    </Grid>
                                    {subtitle && 
                                        <Grid item>
                                            <Typography variant="subtitle1" noWrap>
                                                {subtitle}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                                {description && 
                                <Grid item>
                                    <Typography component="p" variant="body2">
                                        {description}
                                    </Typography>   
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                        
                        {actionBar && 
                        <Grid item>
                            {actionBar}
                        </Grid>
                        }
                    </Grid>
            }

            {!hideDivider &&
                <Grid item>
                    <Divider />
                </Grid>
            }     

            {kpi && 
                <Grid item>
                    {kpi}
                    <Divider />
                </Grid>
            }              
            
            <Grid item container direction="column" justifyContent="space-between" 
                className={`${classes.height100} ${padDisplayGrid ? classes.container : ''}`}>
                {displayGrid}
            </Grid>

            { (linkButton || actionFooter) && (
                <>
                    { !hideFooterDivider && <Divider /> }

                    <Grid item container justifyContent={leftAlignActionFooter? 'flex-start' : 'space-between'} className={classes.padLink} alignItems="center">   
                        <Grid item>
                            {linkButton &&
                            <Grid item container spacing={3}>
                                <Grid item>
                                    <Button data-test={linkButton.dataTestId} variant={linkButton.variant || 'text'} onClick={linkButton.onClick} color={linkButton.color} startIcon={linkButton.startIcon} disabled={linkButton.disabled}>
                                        {linkButton.text}
                                    </Button> 
                                </Grid>
                                {linkButton2 && 
                                <Grid item>
                                    <Button data-test={linkButton2.dataTestId} variant={linkButton2.variant || 'text'} onClick={linkButton2.onClick} color={linkButton2.color} startIcon={linkButton2.startIcon} disabled={linkButton2.disabled}>
                                        {linkButton2.text}
                                    </Button> 
                                </Grid>
                                }
                            </Grid>
                            }
                        </Grid>
                        <Grid item>
                            {actionFooter}
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    )

    return hidePaper ? content : <Paper className={classes.paper}> {content} </Paper>
};

const useStyles = makeStyles(theme => ({
    padLink: {
        padding: theme.spacing(2, 3),
    },
    paper: {
        width: '100%',
    },
    containerLeftRight: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    container: {
        padding: theme.spacing(3)
    },
    height100: {
        height: '100%'
    },
}));

export { DisplayGridWrapper };