import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const HelpStep2 = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateProtocolConfigurationsStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateProtocolConfigurationsStep2:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/Node_Settings.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/using-kaleido/blockchain-advanced-configuration/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Protocol Configurations',
    helpDescription: 'Depending on your requirements, Kaleido provides the ability to configure the following advanced settings for your nodes. For detailed information please visit our documentation but here are 3 things to take into account:',
    helpDesc1: 'The following settings are only applicable to Large nodes and are ignored for Small/Medium sized nodes: Archive Garbage collection mode, Enable Trace API, Enable Revert Reason.',
    helpDesc2: 'Use Cors Origin Hosts to configure a list of origins (as regular expressions) that are allowed to access RPC/WSS endpoints on nodes. If changed (default: *), it may cause some panels of the Kaleido console to not function as expected.',
    helpDesc3: 'REST API Gateway configurations will be applicable when the API calls are directed to the node/nodes this configuration will be applied to.',
}
