import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isPhoneNumberValid, isPhoneExtValid } from '../../utils/StringUtils';

interface Props {
    onPhoneNumberChange: React.Dispatch<React.SetStateAction<string>>,
    phoneNumber: string
}

const decomposePhoneNumber = (phoneNumber: string) => {
    const parts = phoneNumber.split(' ext ');
    return{
        number: parts[0],
        ext: parts.length === 2 ? parts[1] : ''
    }
}

const constructPhoneNumber = (number: string, ext: string) => {
    // If the number is not set, then ignore any ext. value
    if (number === '') {
        return ''
    }

    const prefix = number.substring(0, 1) === '+' ? '' : '+'
    return ext ? `${prefix}${number} ext ${ext}` : `${prefix}${number}`
}

export const PhoneNumberInput = ({ onPhoneNumberChange, phoneNumber }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'PhoneNumberInput', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`PhoneNumberInput:${key}`, interpolate);

    const { number, ext } = decomposePhoneNumber(phoneNumber);

    const [phone, setPhone] = useState(number);
    const [phoneExt, setPhoneExt] = useState(ext);

    const phoneError = phone ? !isPhoneNumberValid(phone) : false
    const phoneExtError = phoneExt ? !isPhoneExtValid(phoneExt) : false

    const handlePhoneNumberChange = (newNumber: string) => {
        setPhone(newNumber);
        onPhoneNumberChange(constructPhoneNumber(newNumber, ext));
    }

    const handlePhoneExtChange = (newExt: string) => {
        setPhoneExt(newExt);
        onPhoneNumberChange(constructPhoneNumber(number, newExt));
    }

    return (
        <Grid container direction="row" spacing={1}>
            <Grid data-test="editPhoneNumber" item xs={8}>
                <PhoneInput
                    country='us'
                    value={phone}
                    onChange={phone => handlePhoneNumberChange(phone)}
                    isValid={() => phoneError ? lt('phoneNumberError') : true}
                    countryCodeEditable={true}
                    inputStyle={{'height': '38px'}}
                    />
            </Grid>
            <Grid container direction="row" item xs>
                <TextField
                    size="small"
                    error={phoneExtError}
                    data-test="editPhoneExt"
                    label={lt('phoneExt')}
                    value={phoneExt}
                    onChange={event => handlePhoneExtChange(event.target.value)}
                    variant="outlined"
                    helperText={phoneExtError ? lt('phoneExtError') : undefined}
                    inputProps={{
                        maxLength: 5
                    }}
                    />
            </Grid>
        </Grid>
    );
}

interface translations {
    phoneNumberError: string,
    phoneExt: string,
    phoneExtError: string
}

const enTranslations: translations = {
    phoneNumberError: 'Invalid Phone',
    phoneExt: 'ext (optional):',
    phoneExtError: 'Invalid Phone Extension'
}