import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, Redirect } from "react-router-dom";
import {
    ConfigsData,
    ConfigsQuery,
    NodeConfigsDetailsTranslationsInterface,
    NodeConfigsDetailsTranslations,
    FullNodeConfigurationDetails,
} from "../../models/configs";
import { useQuery } from "@apollo/client";
import {
    EnvironmentResourcesVars,
    EnvironmentResourceVars,
} from "../../interfaces";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import {
    EditableSettings,
    EditableSettingItem,
} from "../../components/DisplaySettings";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import { NodeQuery, NodeData } from "../../models";
import { ConfigStatus } from "./ConfigStatus";
import { BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH, BLOCKCHAIN_BASE_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import { buildProtocolConfigPropertiesList } from "../Configurations/TypeUtils";

export const ProtocolConfigurations = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "ProtocolConfigurations", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`ProtocolConfigurations:${key}`, interpolate);

    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();

    const history = useHistory();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const { data: { node } = { node: null } } = useQuery<
        NodeData,
        EnvironmentResourceVars
    >(NodeQuery, {
        variables: {
            ...environmentVariables,
            id: node_id!,
        },
        fetchPolicy: "cache-only",
    });

    const { loading, data: { configs } = { configs: null } } = useQuery<
        ConfigsData,
        EnvironmentResourcesVars
    >(ConfigsQuery, {
        variables: environmentVariables,
        fetchPolicy: "cache-and-network",
    });

    if (loading) return <CircularProgress />;
    if (!node) return <Redirect to={`${basePath}/${BLOCKCHAIN_BASE_PATH}`} />;

    const config = configs?.find((entry) => entry._id === node?.node_config_id);

    const displayValue = () => {
        return config ? (
            <ConfigStatus {...{ node }} configuration={config} />
        ) : (
            lt("noneApplied")
        );
    };

    const buildSubValues = (data?: FullNodeConfigurationDetails) => {
        return buildProtocolConfigPropertiesList((key: string) => t(`ProtocolConfigurations:${key}`), node, data)
    }

    const historyPusher = (configId?: string) => {
        history.push(
            `${basePath}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}/${
                configId ? configId : ""
            }`
        );
    };

    const actionsList: EditableSettingItem[] = [
        {
            icon: <AccountGroupIcon />,
            title: lt("protocolConfiguration"),
            value: displayValue(),
            action: () => historyPusher(config?._id),
            copyableSettings: buildSubValues(config?.details),
        },
    ];

    return (
        <Grid container direction="column" spacing={3} wrap="nowrap">
            <Grid item>
                <Typography variant="h5">
                    {lt("protocolConfigurations")}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <EditableSettings
                    header={lt("configurations")}
                    {...{ actionsList }}
                />
            </Grid>
        </Grid>
    );
};

interface translations extends NodeConfigsDetailsTranslationsInterface {
    protocolConfigurations: string;
    configurations: string;
    noneApplied: string;
    protocolConfiguration: string;
}

const enTranslations: translations = {
    ...NodeConfigsDetailsTranslations,
    protocolConfigurations: "Protocol Configurations",
    protocolConfiguration: "Protocol Configuration",
    configurations: "Configurations",
    noneApplied: "None Applied",
};
