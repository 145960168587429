import { FormControl, Grid, RadioGroup, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CreateWrapper } from '../../components/DialogWrappers';
import { FormRadio } from '../../components/FormControls/FormRadio';
import { ORGS_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { CreateStepProps } from '../../interfaces';
import { PlanName } from '../../models';

interface Props extends CreateStepProps {
    plan: PlanName
    supportLevel: Number
    setSupportLevel: React.Dispatch<React.SetStateAction<Number>>
}

export const Step1 = ({ cancelPath, plan, supportLevel, setSupportLevel }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SignupBillingStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SignupBillingStep1:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const history = useHistory();
    
    const handleSupportLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch(event.target.value) {
            case 'basic': setSupportLevel(100); break;
            case 'select': setSupportLevel(200); break;
            case 'priority': setSupportLevel(300); break;
        }
    };

    const content = (
            <>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {lt('selectSupportLevel')}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset" margin="none">
                        <RadioGroup value={supportLevel} onChange={handleSupportLevelChange}>
                            <Grid container direction="column" spacing={1}>
                                {plan !== 'business' &&
                                <FormRadio selected={supportLevel === 100} value="basic" label={lt('basicTitle')} description={lt('basicDescription')} />}
                                <FormRadio disabled={plan !== 'business'} selected={supportLevel === 200} value="select" label={lt('selectTitle')} description={lt('selectDescription')} />
                                <FormRadio disabled={plan !== 'business'} selected={supportLevel === 300} value="priority" label={lt('priorityTitle')} description={lt('priorityDescription')} />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </>
        );

    const save = () => {
        history.push(`/${ORGS_PATH}/${org_id}/signup/billing/2`, { plan, supportLevel });
    };

    return <CreateWrapper disabled={false} cancelPath={cancelPath} {...{ content }} onNext={save} />;
}

interface translations {
    selectSupportLevel: string
    basicTitle: string
    basicDescription: string
    selectTitle: string
    selectDescription: string
    priorityTitle: string
    priorityDescription: string
}

const enTranslations: translations = {
    selectSupportLevel: 'Select Support Level',
    basicTitle: 'Basic (Free)',
    basicDescription: 'Best effort response time. Limited to business hours (US Eastern). Low severity tickets only.',
    selectTitle: 'Select (7% of Total Monthly Spend)',
    selectDescription: 'Guaranteed responses within 1 business day. Limited to business hours (US Eastern).',
    priorityTitle: 'Priority (15% of Total Monthly Spend)',
    priorityDescription: '24/7 availability for urgent requests. Guaranteed responses within 1 business day. 1 hour response for critical requests. Recommended for production environments.'
}