import { useMutation, useQuery } from "@apollo/client";
import { Divider, Grid, Link, makeStyles, Paper, Radio, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, MessageSnackbar } from '../../components/DialogWrappers';
import { CREATE_ENVIRONMENTS_PATH } from '../../components/MainNav/EnvironmentPicker';
import { CreateStepProps } from '../../interfaces';
import { EnvironmentType, FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars } from '../../models';
import { HigherAccessRequestData, SubmitHigherAccessMutation, SubmitHigherAccessRequestFormVars } from "../../models/contactInfo";
import { requestHigherAccessFormFields } from "../../utils/HubspotUtils";
import { DedicatedWeb3SolutionsAccess } from "./DedicatedWeb3SolutionsAccess";
import { Step1Help } from './Step1Help';
interface Props extends CreateStepProps {
    environmentType: EnvironmentType | undefined,
    setEnvironmentType: (e: EnvironmentType) => void
};

export const Step1 = ({ environmentType, setEnvironmentType, cancelPath }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error'|'success'|undefined>();
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [submitAccessRequest] =  useMutation<HigherAccessRequestData, SubmitHigherAccessRequestFormVars>(SubmitHigherAccessMutation)


    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { }
    });

    const makeEnvTypeRadio = (envType: EnvironmentType, label: string, description: string, link: string, beta: boolean, disabled: boolean) => {
        return (
            <Grid item>
                <Paper className={disabled ? classes.radioPaperDisabled : environmentType === envType ? classes.radioPaperSelected : classes.radioPaper } elevation={0} onClick={() => disabled ? undefined : setEnvironmentType(envType)}>
                    <Grid container direction="row" alignItems="center" alignContent="center" justify='center' spacing={2} wrap='nowrap'>
                        <Radio color="primary" checked={environmentType === envType}/>
                        <Grid item container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="h6" color="textPrimary">
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="textSecondary">
                                    {description}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link href={link} target="_blank">{lt('learnMore')}</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const save = async () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${CREATE_ENVIRONMENTS_PATH}/2`, {
            environmentType
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
                <Divider style={{margin: '20px 0 0'}} />
            </Grid>
            <Grid item direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {lt('baasEnvironments')}
                    </Typography>
                </Grid>
                {makeEnvTypeRadio(
                    'standard',
                    lt('standardBlockchain'),
                    lt('standardBlockchainDescription'),
                    'https://docs.kaleido.io/using-kaleido/environments/',
                    false,
                    false
                )}
            </Grid>
            <Grid item direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {lt('sandboxEnvironments')}
                    </Typography>
                </Grid>
                <Grid item direction="column" spacing={1}>
                    {featureToggles?.firefly &&
                        makeEnvTypeRadio(
                            'firefly',
                            lt('fireflyBlockchain'),
                            lt('fireflyBlockchainDescription'),
                            'https://docs.kaleido.io/kaleido-platform/firefly/',
                            false,
                            !featureToggles?.firefly
                        )}
                </Grid>
            </Grid>
        </>
    );

    const requestDedicatedAccess = async () => {
        const formFields = requestHigherAccessFormFields('dedicatedWeb3Solution', window.location.href);
        const { data }= await submitAccessRequest({
            variables: {
                ...formFields
            }
        });
        if (data?.requestHigherAccess) {
            setMessageType('success')
            setMessage(lt('requestSubmitted'))
            setRequestSubmitted(true)
        }
    }

    const requestAccess = (
        <>
            <Divider style={{margin: '0 0 20px'}} />
            <DedicatedWeb3SolutionsAccess buttonDisabled={requestSubmitted} buttonFn={requestDedicatedAccess}/>
        </>
    );

    const getHelpHeader = () => {
        if (!environmentType) return lt('empty')
        return environmentType === 'firefly' ? lt('fireflyBlockchain') : lt('standardBlockchain')
    }

    return (
        <>
            <MessageSnackbar messageType={messageType} {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={!environmentType} onNext={save} additionalContent={requestAccess} isFirstStep />
            <Step1Help helpHeader={getHelpHeader()} {...{environmentType}}/>
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    radioPaperDisabled: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
        cursor: 'none',
        opacity: '50%'
    },
    radioPaperSelected: {
        padding: theme.spacing(2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    logoWidth: {
        minWidth: '150px',
    },
    logoCentered: {
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    betaTag: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: '1px 3px 1px 3px',
        borderRadius: '2px',
        textTransform: 'uppercase',
        fontSize: 'small'
    },
}));

interface translations {
    baasEnvironments: string;
    beta: string;
    header: string;
    headerDescription: string;
    fireflyBlockchain: string;
    fireflyBlockchainDescription: string;
    requestSubmitted: string;
    sandboxEnvironments: string;
    standardBlockchain: string;
    standardBlockchainDescription: string;
    logo: string;
    learnMore: string;
    empty: string;
}
const enTranslations: translations = {
    baasEnvironments: 'BaaS Environments',
    beta: 'Beta',
    header: 'Select an Environment Type',
    headerDescription: 'Choose the type of environment you would like to create',
    fireflyBlockchain: 'FireFly Private Network Sandbox',
    fireflyBlockchainDescription:
        'Quickly and easily test drive FireFly along with Kaleido’s other pre-built tools and services.',
    requestSubmitted: 'Request submitted. Someone will be in touch with you soon.',
    sandboxEnvironments: 'Sandbox Environments',
    standardBlockchain: 'Standard Blockchain Service',
    standardBlockchainDescription: 'Deploy blockchain nodes in your own secure multi-cloud hosting environment. Access dozens of pre-built tools and services to rapidly build and scale blockchain solutions.',
    logo: '{{label}} Logo',
    learnMore: 'Learn More',
    empty: 'Environment Types'
};
