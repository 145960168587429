import React, { useState, useMemo } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { BrandColors } from '../../../../utils/Colors';
import { useTranslation } from 'react-i18next';
import fileSize from 'filesize';

interface Props {
    setFile: React.Dispatch<React.SetStateAction<FileList | null>>
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
    fileSizeLimit: number //bytes
}

export const DragAndDropZone = ({setFile, setErrorMessage, fileSizeLimit}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DragAndDropZone', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DragAndDropZone:${key}`, interpolate);

    const classes = useStyles();

    const [dragIsActive, setDragIsActive] = useState(false);

    const sizeLimit = useMemo(() => {
        return fileSize(fileSizeLimit);
    }, [fileSizeLimit])

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        setDragIsActive(false)
        e.preventDefault();
        const {
            dataTransfer: { files }
        } = e;
        const { length } = files;
        const reader = new FileReader();
        if (length === 0) {
            return false;
        }
        const { size } = files[0];

        if (size > fileSizeLimit) {
            setErrorMessage && setErrorMessage(lt('sizeExceeded', {file: sizeLimit}));
            return false;
        }
        setErrorMessage('');
        reader.onerror = () => setErrorMessage(lt('loadingFileError'));
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            setFile(files);
        };
    }
    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragIsActive(true);
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragIsActive(false);
    };

    return (
        <div className={classes.dropZone} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
            <Grid container className={`${classes.container} ${dragIsActive && classes.dragActive}`} direction="column" justify="center" alignItems="center">
                <Grid item>
                    <img className={classes.image} src={`${process.env.PUBLIC_URL}/cloud-upload-outline.svg`} alt=""></img>
                </Grid>
                {dragIsActive ? (
                    <Grid item>
                        <Typography variant="body1">
                            {lt('releaseFile')}
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <Typography variant="body1">
                                {lt('dropDocument')}
                                <input className={classes.input}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={e => setFile(e.target.files)} />
                                <label className={classes.browse} htmlFor="raised-button-file">{lt('browse')}</label>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {lt('supportSize', {size: sizeLimit})}
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    dropZone: {
        backgroundColor: BrandColors.lightGrey,
        width: '100%',
        height: 150,
    },
    dragActive: {
        border: 'solid',
        borderWidth: 4,
        borderColor: BrandColors.blurple
    },
    container: {
        height: '100%'
    },
    image: {
        height: 60
    },
    input: {
        display: 'none'
    },
    browse: {
        color: BrandColors.blurple,
        cursor: 'pointer'
    }
}));

interface translations {
    dropDocument: string
    supportSize: string
    browse: string
    releaseFile: string
    loadingFileError: string
    sizeExceeded: string
}
const enTranslations: translations = {
    dropDocument: 'Drop your document here, or ',
    supportSize: 'Supports all file types up to {{size}}',
    browse: 'browse',
    releaseFile: 'Release to drop the files here',
    loadingFileError: 'Error Uploading File',
    sizeExceeded: 'File size exceeded the limit of {{size}}'
}