import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FormControlLabel, Grid, MenuItem, Switch, TextField } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Consortium, OrganizationData, OrganizationQuery, OrganizationVars, RolesData, RolesQuery, UpdateOrganizationMutation, UpdateOrganizationVars } from '../../models';
import { FormDialog, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const TransferOwner = ({ open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransferOwner', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransferOwner:${key}`, interpolate)

    const { org_id } = useParams<any>();
    
    const [message, setMessage] = useState('');
    const [newUserId, setNewUserId] = useState('');
    const [setPrimaryContact, setSetPrimaryContact] = useState(true);

    const client = useApolloClient();
    const { organization } = client.cache.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id} })!;
    
    const [updateOrganization, { loading: updateOrganizationLoading }] = useMutation<Consortium, UpdateOrganizationVars>(UpdateOrganizationMutation)

    const {
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, {
        variables: { org_id },
        fetchPolicy: 'cache-and-network' }
    );
    const otherRoles = roles.filter(r => r.user_id !== organization?.owner);

    useEffect(() => {
        if (!newUserId && otherRoles.length) setNewUserId(otherRoles[0].user_id);
    }, [otherRoles, newUserId])
    
    const updater = async () => {
        const newRole = roles!.find(r => r.user_id === newUserId)!;
        try {
            await updateOrganization({
                variables: {
                    id: org_id,
                    organization: {
                        delegate: setPrimaryContact ? newRole.email : undefined,
                        owner: newRole?.user_id,
                    }
                }
            });
            setOpen(false);
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage)
        }
    };

    const controlsWrapper = 
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <TextField
                    select
                    value={newUserId} onChange={event => setNewUserId(event.target.value)}
                    autoFocus
                    margin="normal"
                    label={lt('organizationUser')}
                    fullWidth
                    variant="outlined"
                >
                    {otherRoles.map(r => <MenuItem key={r.user_id} value={r.user_id}>{r.email}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Switch 
                            color="primary"
                            onChange={() => setSetPrimaryContact(!setPrimaryContact)}
                            checked={setPrimaryContact}/>
                    }
                    label={lt('setPrimaryContact')}
                    labelPlacement="start"
                    />
            </Grid>
        </Grid>

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog dataTestId="updateName"
            {...{open}} 
            {...{setOpen}} 
            header={lt('transferTitle')} 
            description={lt('transferDescription')} 
            {...{controlsWrapper}} 
            saveDisabled={!newUserId || updateOrganizationLoading}
            onSave={updater}/>
    </>;
};

interface translations {
    transferTitle: string,
    transferDescription: string,
    organizationUser: string,
    setPrimaryContact: string,
}
const enTranslations: translations = {
    transferTitle: 'Transfer Ownership of {{name}}',
    transferDescription: 'Select a member of this organization to transfer it to',
    organizationUser: 'Organization User',
    setPrimaryContact: 'Update Primary Contact Email to new owner',
}