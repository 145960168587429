import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { OPS_MANAGE_AGREEMENT_PATH, OPS_PATH } from '../../components/OpsNav/OpsNav';
import { Agreement, CreateAgreementData, CreateAgreementVars, OpsCreateAgreementMutation, OpsAgreementData, OpsAgreementQuery, OpsAgreementUpdateMutation, UpdateAgreementData, UpdateAgreementVars, DeleteAgreementVars, OpsDeleteAgreementMutation, DeleteAgreementData } from '../../models/agreements';
import { AgreementPanel } from './AgreementPanel';
import { Region } from '../../interfaces';

interface Props {
    regions?: Region[]
}

export const AgreementEditor: React.FC<Props> = ({regions}) => {
    const {ops_agreement_id} = useParams<any>();
    
    const history = useHistory();

    const [message, setMessage] = useState('');
    const [agreement, setAgreement] = useState<Agreement | undefined>();

    const {
        data: {
            opsAgreement: loadedAgreement
        } = { opsAgreement: null },
        loading,
        error: fetchError,
        refetch,
    } = useQuery<OpsAgreementData>(OpsAgreementQuery, {
        skip: !ops_agreement_id,
        variables: {
            id: ops_agreement_id,
        },
        fetchPolicy: 'no-cache'
    });

    const [updateAgreementMutation, {loading: saving}] = useMutation<UpdateAgreementData, UpdateAgreementVars>(OpsAgreementUpdateMutation)
    const [createAgreementMutation, {loading: creating}] = useMutation<CreateAgreementData, CreateAgreementVars>(OpsCreateAgreementMutation)
    const [deleteAgreementMutation, {loading: deleting}] = useMutation<DeleteAgreementData, DeleteAgreementVars>(OpsDeleteAgreementMutation)

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    useEffect(() => {
        if (ops_agreement_id && loadedAgreement) {
            setAgreement(loadedAgreement);
        }
        else if (!ops_agreement_id && !agreement) {
            setAgreement({
                _id: '',
                name: '',
                type: 'contract',
                description: '',
                plans: [],
            });
        }
    }, [agreement, loadedAgreement, ops_agreement_id]);

    if (loading) return <CircularProgress />;
    if (!agreement) return <></>;

    const saveAgreement = async (agreement: Agreement, region?: string) => {
        if (ops_agreement_id) {
            const id = agreement._id!;
            const agreementUpdate = {
                ...agreement,
            }
            delete agreementUpdate._id;
            delete agreementUpdate.name;
            await updateAgreementMutation({
                variables: {
                    id,
                    agreement: agreementUpdate
                }
            });
            refetch();
        } else {
            const res = await createAgreementMutation({
                variables: {
                    region: region!,
                    agreement
                }
            });
            history.push(`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}/${res?.data?.opsCreateAgreement._id}`);
        }
    };

    const deleteAgreement = async (id: string) => {
        await deleteAgreementMutation({
            variables: {
                id: id
            }
        });
        history.push(`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}`);
    };

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <AgreementPanel {...{agreement}} {...{saveAgreement}} {...{deleteAgreement}} {...{regions}} loading={loading || saving || creating || deleting}/>
    </>;
};

