import { gql } from '@apollo/client';
import { ConsortiumResourcesVars, ConsortiumResourceVars } from '../interfaces';
import { MembershipPermissions } from './memberships';

// INTERFACES

export type InvitationState = 'sent' | 'accepted' | 'declined' | 'revoked' | 'expired'

// this should be an autogenerated interface from the Invitation model in graphql
export interface Invitation {
    _id: string
    createdAt: string
    consortia_id: string
    email: string
    from: string
    from_org_id: string
    from_membership_id: string
    org_name: string
    resolved_by?: string
    org_id?: string
    state: InvitationState
    permissions: MembershipPermissions
    charter: {
        consortia: {
            name: string
            description: string
        }
        environments: {
            consensus_type: string
            name: string
            nodes: number
            provider: string
            services: number
        }[]
        memberships: {
            name: string
            status: string
        }[]
    }
}

export interface CreateInvitationData {
    createInvitation: Invitation
}

export interface CreateInvitationVars extends ConsortiumResourcesVars {
    invitation: {
        email: string
        from_org_id: string
        org_name: string
        permissions?: MembershipPermissions
    }
}

export interface UpdateInvitationData {
    updateInvitation: Invitation
}

export interface UpdateInvitationVars extends ConsortiumResourceVars {
    invitation: {
        org_id: string
        state: InvitationState
        org_name?: string
    }
}

export interface UserInvitationsData {
    invitations: Invitation[]
}

export interface ConsortiumInvitationsData {
    consortiumInvitations: Invitation[]
}

// FRAGMENTS

export const InvitationFields = ` 
    fragment InvitationFields on Invitation {
        _id
        createdAt
        consortia_id
        email
        from
        from_org_id
        from_membership_id
        org_name
        resolved_by
        org_id
        state
        permissions {
            manage_envs
            invite_orgs
            create_signers
            multiple_members
            manage_contracts
        }
        charter {
            consortia {
                name
                description
            }
            environments {
                consensus_type
                name
                nodes
                provider
                services
            }
            memberships {
                name
                status
            }
        }
    }
`

// QUERIES & SUBSCRIPTIONS

export const UserInvitationsQuery = gql`
    ${InvitationFields}
    query getInvitations {
        invitations {
            ...InvitationFields
        }
    }`;

export const CreateInvitationMutation = gql`
    ${InvitationFields}
    mutation createInvitation($consortia_id: String!, $invitation: CreateInvitationInput) {
        createInvitation(consortia_id: $consortia_id, invitation: $invitation) {
            ...InvitationFields
        }
}`;

export const UpdateInvitationMutation = gql`
    ${InvitationFields}
    mutation updateInvitation($consortia_id: String!, $id: String!, $invitation: UpdateInvitationInput) {
        updateInvitation(consortia_id: $consortia_id, id: $id, invitation: $invitation) {
            ...InvitationFields
        }
}`;

export const DeleteInvitationMutation = gql`
    mutation deleteInvitation($consortia_id: String!, $id: String!) {
        deleteInvitation(consortia_id: $consortia_id, id: $id)
}`;

export const ConsortiumInvitationsQuery = gql`
    ${InvitationFields}
    query getConsortiumInvitations($consortia_id: String!) {
        consortiumInvitations(consortia_id: $consortia_id) {
            ...InvitationFields
        }
    }`;
