import React, { useState } from "react";
import { EmptyState } from "../../components/DisplayWrappers";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Typography,
    Button,
    Paper,
    makeStyles,
    Box,
    IconButton,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import { useMutation } from "@apollo/client";
import {
    SetTetherAccountMutation,
    SetTetherAccountVars,
    TetherAccountData,
} from "../../models/tether";
import { ErrorSnackbarCatcher } from "../../components/DialogWrappers";
import { useParams } from "react-router-dom";
import elliptic from 'elliptic';

interface Props {
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    refreshData: () => Promise<void>;
}

const generatePrivateKey = () => {
    const EC = elliptic.ec;
    const ec = new EC('secp256k1');
    const key = ec.genKeyPair();
    const privateKey = `0x${key.getPrivate('hex')}`;
    return privateKey;
};

export const CreateTetherAccount = ({ setMessage, refreshData }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateTetherAccount", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateTetherAccount:${key}`, interpolate);

    const classes = useStyles();
    const { service_id } = useParams<any>();

    const [privateKey, setPrivateKey] = useState("");

    const [setTetherAccount] = useMutation<
        TetherAccountData,
        SetTetherAccountVars
    >(SetTetherAccountMutation);

    const handleGenerateKey = () => {
        const privateKey = generatePrivateKey();
        setPrivateKey(privateKey);
    };

    if (!privateKey)
        return (
            <EmptyState
                imageFile="Empty-Tether.svg"
                title={lt("generateAccountAndPrivateKey")}
                description={lt("emptyDescription")}
                button={{
                    text: lt("generateAccount"),
                    onClick: handleGenerateKey,
                }}
            />
        );

    const onSubmitClick = async () => {
        try {
            await setTetherAccount({
                variables: {
                    privateKey,
                    service_id,
                },
            });
            await refreshData();
        } catch (err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={3}
        >
            <Grid item>
                <Typography variant="h5">
                    {lt("privateKeyGenerated")}
                </Typography>
            </Grid>
            <Grid item>
                <Paper className={classes.qrPaper}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyItems="center"
                    >
                        <QRCode size={250} value={privateKey} />
                        <Box mt={2}>
                            <Typography>{lt("privateKey")}</Typography>
                            <Box
                                mt={1}
                                display="flex"
                                flexWrap="nowrap"
                                alignItems="center"
                            >
                                <Typography variant="h6">
                                    {privateKey}
                                </Typography>
                                <Box ml={1}>
                                    <CopyToClipboard text={privateKey}>
                                        <IconButton
                                            color="inherit"
                                            size="small"
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item>
                <Typography variant="h6">{lt("oneTimeToSaveKey")}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography align="center" variant="h6">
                    {lt("keyNeededDescription")}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    onClick={onSubmitClick}
                    variant="contained"
                    color="primary"
                >
                    {lt("submitKey")}
                </Button>
            </Grid>
        </Grid>
    );
};

interface translations {
    generateAccount: string;
    emptyDescription: string;
    generateAccountAndPrivateKey: string;
    privateKeyGenerated: string;
    privateKey: string;
    submitKey: string;
    oneTimeToSaveKey: string;
    keyNeededDescription: string;
}
const enTranslations: translations = {
    generateAccountAndPrivateKey:
        "Generate an account and secure your private key",
    emptyDescription:
        "Pinning state proofs to a public Ethereum network offers robust protections against historical rewrites via the proven indelibility of confirmed transactions, while still maintaining the integrity of your private chain's sensitive ledger data.",
    generateAccount: "Generate Account",
    privateKey: "Private Key",
    submitKey: "Submit Key",
    privateKeyGenerated: "A private key has been generated",
    oneTimeToSaveKey:
        "This is your ONE TIME chance to secure your private key before generating your account through Kaleido.",
    keyNeededDescription:
        "This key will be needed to recover any ether held in the account. Please scan this QR code containing your private key into your personal wallet, print or save it to recover unspent funds at any time.",
};

const useStyles = makeStyles((theme) => ({
    qrPaper: {
        padding: theme.spacing(4),
    },
}));
