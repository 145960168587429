import React, { useMemo, useState } from 'react';
import { NodeWalletsCards } from './DashboardCards/NodeWalletsCard';
import { useParams } from 'react-router-dom';
import { WalletsQuery, WalletsData } from '../../models/wallets';
import { EnvironmentResourcesVars, ConsortiumResourceVars, LinkButtonProps } from '../../interfaces';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { WalletsCard } from './DashboardCards/WalletsCard';
import { ServicesData, ServicesQuery, ManagedWalletServices, CloudWalletServices, EnvironmentData, EnvironmentQuery } from '../../models';
import { DisplayCard, ComingSoon, DisplayGridWrapper } from '../../components/DisplayWrappers';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import { requestHigherAccessFormFields } from '../../utils/HubspotUtils';
import { HigherAccessRequestData, SubmitHigherAccessRequestFormVars, SubmitHigherAccessMutation } from '../../models/contactInfo';
import { MessageSnackbar } from '../../components/DialogWrappers';

export const Dashboard = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'KeyManagementDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`KeyManagementDashboard:${key}`, interpolate);

    const { consortium_id, environment_id } = useParams<any>();

    const {
        loading,
        data: {
            wallets
        } = { wallets: []}
    } = useQuery<WalletsData, EnvironmentResourcesVars>(WalletsQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        }
    })

    const {
        data: {
            services
        } = { services: []}
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    })

    const managedWallets = useMemo(() => {
        return services.filter(service =>  ManagedWalletServices.has(service.service));
    }, [services]);

    const cloudHSMWallets = useMemo(() => {
       return services.filter(service =>  CloudWalletServices.has(service.service));
    }, [services])

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<
        'error' | 'success' | undefined
    >();
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [submitAccessRequest] = useMutation<
        HigherAccessRequestData,
        SubmitHigherAccessRequestFormVars
    >(SubmitHigherAccessMutation);


    const requestThirdPartyWallets: LinkButtonProps = {
        text: lt('requestAccess'),
        variant: 'contained',
        color: 'primary',
        disabled: requestSubmitted,
        onClick: async () => {
            const formFields = requestHigherAccessFormFields(
                'thirdPartyWallets',
                window.location.href
            );
            const { data } = await submitAccessRequest({
                variables: {
                    ...formFields,
                },
            });
            if (data?.requestHigherAccess) {
                setMessageType('success');
                setMessage(lt('requestSubmitted'));
                setRequestSubmitted(true);
            }
        },
    };

    const thirdPartyWallet = (title: string, imagePath: string) => {
        return (
            <Grid item direction="column" alignItems='center'>
                <Grid item style={{textAlign: 'center'}}>
                    <img
                        src={`${process.env.PUBLIC_URL}/img/AssetPlatform/${imagePath}.svg`}
                        alt={`${imagePath}`}
                    ></img>
                </Grid>
                <Grid item style={{ marginTop: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
            </Grid>
        );
    };

    const thirdPartyWalletsGrid = (
        <>
            <Grid item container direction="column" spacing={3}>
                <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                >
                    {thirdPartyWallet(lt('fireblocks'), 'fireblocks')}
                    {thirdPartyWallet(lt('andMore'), 'wallet')}
                </Grid>
                <Grid item>{lt('thirdPartyWalletsDescription')}</Grid>
            </Grid>
        </>
    );

    if (isCorda || isFabric) return <ComingSoon page="keyManagementDashboard"/>

    if (loading && wallets.length === 0) return <CircularProgress />;

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('managedWallet'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/wallet/') 
        },
        {
            icon: <DescriptionIcon />,
            title: lt('cloudhsm'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/cloudhsm/') 
        }
    ];

    return (
        <>
            <MessageSnackbar
                {...{ messageType }}
                {...{ message }}
                {...{ setMessage }}
            />
            <Grid container direction="column" spacing={3}>
                <Grid item container>
                    <Grid item>
                        <Typography variant="h5">{lt('header')}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid container item xs={12} sm={6} lg={4}>
                        <NodeWalletsCards
                            wallets={wallets.filter(
                                (wallet) =>
                                    wallet.account_type === 'node' &&
                                    wallet.membership_id !== 'sys--mon' &&
                                    wallet.membership.isMine
                            )}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={6} lg={4}>
                        <WalletsCard services={managedWallets} isManaged />
                    </Grid>
                    <Grid container item xs={12} sm={6} lg={4}>
                        <WalletsCard services={cloudHSMWallets} />
                    </Grid>
                    <Grid container item xs={12} sm={6} lg={4}>
                        <DisplayCard
                            header={lt('documentation')}
                            itemList={DocumentationList}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={6} lg={4}>
                        <DisplayGridWrapper
                            padDisplayGrid
                            header={lt('thirdPartyWallets')}
                            linkButton={requestThirdPartyWallets}
                            displayGrid={thirdPartyWalletsGrid}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

interface translations {
    andMore: string;
    header: string;
    documentation: string;
    fireblocks: string;
    managedWallet: string;
    cloudhsm: string;
    thirdPartyWallets: string;
    thirdPartyWalletsDescription: string;
    requestAccess: string;
    requestSubmitted: string;
}

const enTranslations: translations = {
    andMore: 'And more!',
    header: 'Key Management Dashboard',
    documentation: 'Documentation',
    fireblocks: 'Fireblocks',
    managedWallet: 'Managed Wallet',
    cloudhsm: 'Cloud HSM Wallet',
    thirdPartyWallets: 'Third Party Wallets',
    thirdPartyWalletsDescription:
        "Kaleido's dedicated web3 solutions allow you to use third party wallets for your projects.",
    requestAccess: 'Request Access',
    requestSubmitted:
        'Request submitted. Someone will be in touch with you soon.',
};