import React, { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { ConfigData, ConfigQuery, ConfigTypeTranslationsInterface, ConfigTypeTranslations } from '../../models/configs';
import { EnvironmentResourcesVars, EnvironmentResourceVars, LinkButtonProps } from '../../interfaces';
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import { DisplayTable } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { ServicesData, ServicesQuery, NodesData, NodesQuery, ResetNodeMutation, ResetServiceMutation, Service, Node } from '../../models';
import { ConfigTypesUrl } from '../../components/MainNav/SideNavs/CloudConfigs';
import AddIcon from 'mdi-react/PlusIcon';
import { FormDialog } from '../../components/DialogWrappers';
import { RuntimesAttachedRow } from './RuntimesAttachedRow';
import { DetachConfig } from '../Configurations/DetachConfig';
import { getPathParams } from '../Configurations/TypeUtils';
import { instanceOfService } from '../../utils/TypeUtils';

interface Props {
    setAlert: (description: string) => void,
}

export const ConfigsAttachedTable = ({ setAlert } : Props ) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConfigsAttachedTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConfigsAttachedTable:${key}`, interpolate);

    const [runtimesToApply, setRuntimesToApply] = useState<Array<string>>([]);
    const [open, setOpen] = useState(false);
    const [actionRuntime, setActionRuntime] = useState<Service | Node | null>(null);
    const [openDetach, setOpenDetach] = useState(false);

    const {consortium_id, environment_id, config_id, configType, basePath, attachConfigPath} = getPathParams(useLocation().pathname, useParams<any>());
    const history = useHistory();

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [resetNode] = useMutation<any, EnvironmentResourceVars>(ResetNodeMutation);
    const [resetService] = useMutation<any, EnvironmentResourceVars>(ResetServiceMutation);

    const {
        loading: configsLoading,
        data: {
            config
        } = { config: null }
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: config_id!
        }
    });

    const {
        data: { 
            services
        } = { services: null } 
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: { 
            ...queryVariables
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: { 
            nodes
        } = { nodes: null } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: { 
            ...queryVariables
        },
        fetchPolicy: 'cache-only'
    });

    let alertText = '';
    if (runtimesToApply.length > 0) {
        alertText = lt('warningDescription', {runtimeId: runtimesToApply[0]})
    }

    useEffect(() => {
        setAlert(alertText)
    }, [alertText, setAlert])

    const onApplyConfirm = async () => {
        if (actionRuntime) {
            if (instanceOfService(actionRuntime)) {
                await resetService({
                    variables: {
                        environment_id: environment_id!,
                        consortia_id: consortium_id!,
                        id: actionRuntime._id
                    }
                })
            } else {
                await resetNode({
                    variables: {
                        environment_id: environment_id!,
                        consortia_id: consortium_id!,
                        id: actionRuntime._id
                    }
                })
            }
        }
        setActionRuntime(null)
    }

    const runtimes = useMemo(() => {
        const servicesAttached = services?.filter(entry => entry.details.storage_id === config_id 
            || entry.details.kms_id === config_id || entry.details.backup_id === config_id 
            || entry.details.cloudhsm_id === config_id) || [];
        const nodesAttached = nodes?.filter(node => node.backup_id === config_id 
            || node.kms_id === config_id || node.opsmetric_id === config_id || node.networking_id === config_id
            || node.baf_id === config_id || node.node_config_id === config_id) || [];
        return [...servicesAttached, ...nodesAttached];
    }, [services, nodes, config_id]);

    const onApplyClick = (runtimeId: Service | Node) => {
        setActionRuntime(runtimeId);
        setOpen(true)
    }

    const onDetachClick = (runtimeId: Service | Node) => {
        setActionRuntime(runtimeId);
        setOpenDetach(true);
    }

    const configRecords = runtimes?.map((entry, index) => <RuntimesAttachedRow key={index} runtime={entry} 
                                                                {...{setRuntimesToApply}} config={config!} {...{onApplyClick}}
                                                                {...{onDetachClick}} />);

    const columnHeaders = [lt('name'), lt('type'), lt('id'), lt('status'), ''];

    if (!configsLoading && !config) return (<Redirect to={basePath} />)

    if(configsLoading) return <CircularProgress />

    const attachRuntimesButton: LinkButtonProps = {
        text: lt('attachRuntime'),
        onClick: () => history.push(`${basePath}/${attachConfigPath}/${config_id}`),
        color: 'primary',
        startIcon: <AddIcon />
    }

    return <>
        <FormDialog {...{open}} {...{setOpen}} header={lt('confirmApply')} description={lt('applyDescription')} 
            successMessage={lt('applySuccessful')} saveText={lt('apply')} onSave={onApplyConfirm} closeDialogAfterSave />
        <DetachConfig open={openDetach} setOpen={setOpenDetach} runtime={actionRuntime!} configType={configType as ConfigTypesUrl} />
        <DisplayTable {...{columnHeaders}} preBuiltRows={configRecords} header={lt('runTimesAttached', { amount: runtimes.length })} cellSize="medium" linkButton={attachRuntimesButton} hideFooterDivider/>
    </>
}

interface translations extends ConfigTypeTranslationsInterface {
    name: string,
    type: string,
    runTimesAttached: string,
    id: string,
    attachRuntime: string,
    warningDescription: string,
    apply: string,
    confirmApply: string,
    applySuccessful: string,
    applyDescription: string,
    status: string,
}

const enTranslations: translations = {
    ...ConfigTypeTranslations,
    name: 'Name',
    type: 'Type',
    runTimesAttached: 'Runtimes Attached ({{amount}})',
    id: 'Id',
    attachRuntime: 'Attach Runtime',
    warningDescription: 'There are one or more runtimes to which the configuration has not been applied yet.',
    apply: 'Apply',
    confirmApply: 'Apply Configuration',
    applySuccessful: 'Reset Node Successful',
    applyDescription: 'In order to apply the configuration, the node will have to be reset. Are you sure you want to continue?',
    status: 'Status',
}