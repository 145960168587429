import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { ServiceResourcesVars } from '../../interfaces';
import { CreateHDWalletData, CreateHDWalletMutation, HDWallet } from '../../models/hdwallet';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onHDWalletCreated: (h: HDWallet) => void
}

export const CreateHDWallet = ({ open, setOpen, onHDWalletCreated }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateHDWallet', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateHDWallet:${key}`, interpolate)

    const { service_id } = useParams<any>();

    const [message, setMessage] = useState('');

    const [createHDWallet, { loading: createHDWalletLoading }] = 
        useMutation<CreateHDWalletData, ServiceResourcesVars>(CreateHDWalletMutation, { fetchPolicy: 'no-cache' }) // do not store passphrase

    const updater = () => 
        createHDWallet({
            variables: {
                service_id: service_id!
            }
        }).then((d) => {
            const hdwallet = d.data?.createHDWallet
            if (hdwallet) {
                onHDWalletCreated(hdwallet)
            } else {
                setMessage(lt('failed'))
            }
        })

    return (
        <>
            <MessageSnackbar message={message} setMessage={setMessage}/>
            <FormDialog 
                {...{open}} 
                {...{setOpen}} 
                header={lt('createHDWallet')}
                description={lt('createHDWalletDescription')} 
                saveDisabled={createHDWalletLoading}
                onSave={updater}
                closeDialogAfterSave
                saveText={lt('create')} />
        </>
    )
};

interface translations {
    createHDWallet: string,
    createHDWalletDescription: string,
    create: string,
    failed: string
}
const enTranslations: translations = {
    createHDWallet: 'Create a new HD Wallet?',
    createHDWalletDescription: 'This will create a new HD Wallet and add it to this runtime\'s collection of HD Wallets.',
    create: 'Create',
    failed: 'Failed to create the HD Wallet'
}