import React, { useState } from 'react';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { CloudConfigTypesUrl, CLOUDCONFIG_BASE_PATH } from '../../../components/MainNav/SideNavs/CloudConfigs';

type locationState = {configType: CloudConfigTypesUrl, name: string}

export const CreateCloudConfig = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfig', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfig:${key}`, interpolate);

    const history = useHistory<locationState>();

    const {consortium_id, environment_id, org_id} = useParams<any>();
    
    const {
        location: {
            state: {
                name: configName,
                configType
            } = { name: '', configType: ''}
        }
    } = history;
    
    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}`
    const cancelPath = `${basePath}/${configType}`;

    const {step} = useParams<any>();
    const [name, setName] = useState(configName);

    const invalidStep = step !== "1" && step !== "2";
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || (createStep >= 1 && !name)) return <Redirect to={`${basePath}/create/1`} />


    const stepComponents = [
        {
            step: lt('chooseMembership'),
            component: <Step1 cancelPath={cancelPath} {...{configType}} {...{name}} {...{setName}} />
        },
        {
            step: lt('setupConfig'),
            component: <Step2 cancelPath={cancelPath} preSelectedConfigType={configType} />
        }
    ];


    return (
        <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('toolBarHeader')} stepUrlParam={step!} {...{stepComponents}} />
    )
}

interface translations {
    toolBarHeader: string,
    setupConfig: string,
    chooseMembership: string
}
const enTranslations: translations = {
    toolBarHeader: 'Create Cloud Configuration',
    setupConfig: 'Set Up Configuration',
    chooseMembership: 'Choose Membership and Name'
}