import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { EnvironmentResourcesVars, EnvironmentResourceVars } from '../interfaces'
import { MembershipOwnedResource } from './memberships';
import { EnvironmentProvider, EnvironmentConsensus, ProtocolTypedResource } from './environments';

export type NodeState = "initializing" | "joining" | "joined" | "started" | "stopped" | "failed" | "paused" | "delete_pending" | 
    "delete_failed" | "upgrading" | "upgrade_pending" | "archived" | "locked" | "deleted" | "stop_pending";

export const NODE_SIZE_DETAILS = {
    starter: {
        cores: 0.5,
        memory: 1024
    },
    small: {
        cores: 0.5,
        memory: 1024
    },
    medium: {
        cores: 1,
        memory: 2048
    },
    large: {
        cores: 2,
        memory: 4096
    }
}

export interface RuntimeStateTranslationInterface {
    initializing: string,
    stopped: string,
    joining: string,
    provisioning: string,
    failed: string,
    deploying: string,
    started: string,
    paused: string,
    upgrading: string,
    deprovisioning: string,
    delete_pending: string,
    upgrade_pending: string,
    delete_failed: string,
    joined: string,
    archived: string,
    locked: string,
    deleted: string,
    stop_pending: string
}

export const  RuntimeStateTranslation: RuntimeStateTranslationInterface = {
    stopped: 'Stopped',
    joined: 'Joined',
    joining: 'Joining',
    initializing: 'Initializing',
    provisioning: 'Provisioning',
    failed: 'Failed',
    deploying: 'Deploying',
    started: 'Started',
    paused: 'Paused',
    upgrading: 'Upgrading',
    upgrade_pending: 'Upgrade Pending',
    delete_pending: 'Delete Pending',
    deprovisioning: 'Deprovisioning',
    delete_failed: 'Delete Failed',
    archived: 'Archived',
    locked: 'Locked',
    deleted: 'Deleted',
    stop_pending: 'Stop Pending'
}

export interface RuntimeSizeTranslation {
    starter: string
    small: string
    medium: string
    large: string
}

export const EnRuntimeSizeTranslation: RuntimeSizeTranslation = {
    starter: 'Small', // starter should display as small
    small: 'Small',
    medium: 'Medium',
    large: 'Large'
}

export type RuntimeSize = "starter" | "small" | "medium" | "large";

export type NodeConfigTypes = 'opsmetric' | 'kms' | 'backup' | 'networking' | 'baf' | 'node_config';

export type NodeRole = "monitor" | "validator" | "orderer" | "peer";

export interface NodeConfigs {
    backup_id?: string | null,
    kms_id?: string | null,
    opsmetric_id?: string | null,
    networking_id?: string | null,
    baf_id?: string | null,
    node_config_id?: string | null
}

export interface Node extends NodeConfigs, MembershipOwnedResource, ProtocolTypedResource {
    _id: string,
    consortia_id: string,
    environment_id: string,
    name: string,
    state: NodeState,
    size: RuntimeSize,
    created_at: string,
    updated_at?: string,
    init_consensus_role: "signer" | "non-signer",
    node_identity: string,
    node_identity_data: string,
    consensus_identity: string,
    consensus_type: EnvironmentConsensus,
    provider: EnvironmentProvider,
    role: NodeRole,
    zone_id: string,
    hybrid_port_allocation?: number,
    urls?: { kaleido_connect: string, rpc: string, wss: string, private_tx?: string, orderer?: string, peer?: string, explorer?: string },
    quorum_private_address?: string,
    pantheon_private_address?: string
}

export interface NodeInput extends NodeConfigs {
    name: Node['name'],
    role: NodeRole,
    size: RuntimeSize,
    init_consensus_role: "signer" | "non-signer",
    membership_id: Node['membership_id'],
    zone_id: Node['zone_id']
}
export interface CreateNodeVars extends EnvironmentResourcesVars {
    node: NodeInput
}

interface UpdateNodeInput extends NodeConfigs {
    name?: Node['name'],
    size?: RuntimeSize,
}
export interface UpdateNodeVars extends EnvironmentResourceVars {
    node: UpdateNodeInput
}

export interface OpsNodesInput {
    query: string;
}

export interface CreateNodeData {
    createNode: Node
}

export interface NodesData {
    nodes: Node[]
}

export interface OpsNodesData {
    opsNodes: Node[]
}

export interface NodeData {
    node: Node
}

export interface NodeLogTypesData {
    nodeLogTypes: string[]
}

export const NodeFields = ` 
    fragment NodeFields on Node {
        _id
        consortia_id
        created_at
        environment_id
        membership_id
        kms_id
        opsmetric_id
        ethconnect_id
        networking_id
        backup_id
        node_config_id
        baf_id
        name
        urls
        size
        state
        peer_count
        provider
        consensus_type
        node_identity
        node_identity_data
        consensus_identity
        role
        init_consensus_role
        quorum_private_address
        pantheon_private_address
        updated_at
        zone_id
        hybrid_port_allocation
        membership @client
        isCorda @client
        isEthereum @client
        isFabric @client
    }`

export const NodesQuery = gql`
    ${NodeFields}
    query getNodes($consortia_id: String!, $environment_id: String!) {
        nodes(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...NodeFields
        }
    }`;

export const OpsNodesQuery = gql`
    ${NodeFields}
    query getOpsNodes($query: String!) {
        opsNodes(query: $query) {
            ...NodeFields
        }
    }`;

export const NodeQuery = gql`
    ${NodeFields}
    query getNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        node(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...NodeFields
        }
    }`;    

export const CreateNodeMutation = gql`
    ${NodeFields}
    mutation createNode($consortia_id: String!, $environment_id: String!, $node: CreateNodeInput!) {
        createNode(consortia_id: $consortia_id, environment_id: $environment_id, node: $node) {
            ...NodeFields
        }
    }`;

export const UpdateNodeMutation = gql`
    ${NodeFields}
    mutation updateNode($consortia_id: String!, $environment_id: String!, $id: String!, $node: UpdateNodeInput!) {
        updateNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, node: $node) {
            ...NodeFields
        }
    }`;
    
export const RestartNodeMutation = gql`
    mutation restartNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        restartNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export const DeleteNodeMutation = gql`
    ${NodeFields}
    mutation deleteNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        deleteNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...NodeFields
        }
    }`;

export const ResetNodeMutation = gql`
    ${NodeFields}
    mutation resetNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        resetNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...NodeFields
        }
    }`;

export const StopNodeMutation = gql`
    mutation stopNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        stopNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export const StartNodeMutation = gql`
    mutation startNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        startNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export const AttemptSetNodeReadyMutation = gql`
    mutation attemptSetNodeReady($consortia_id: String!, $environment_id: String!, $id: String!) {
        attemptSetNodeReady(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export const VoteInNodeMutation = gql`
    mutation voteInNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        voteInNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export const VoteOutNodeMutation = gql`
    mutation voteOutNode($consortia_id: String!, $environment_id: String!, $id: String!) {
        voteOutNode(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export const NodesSubscription = gql`
    ${NodeFields}
    subscription onNodesChanged($consortia_id: String!, $environment_id: String!) {
        node(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...NodeFields
        }
    }`

export const NodeLogTypesQuery = gql`
    query nodeLogTypes($consortia_id: String!, $environment_id: String!, $id: String!) {
        nodeLogTypes(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) 
    }`


export function MakeNodeCreateMutationOptions(
    variables: CreateNodeVars,
    overrideOptions?: MutationFunctionOptions<CreateNodeData, CreateNodeVars>): MutationFunctionOptions<CreateNodeData, CreateNodeVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const node = data?.createNode
            if (node) {
                const { nodes } = cache.readQuery<NodesData>({ query: NodesQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!nodes.find(n => n._id === node._id)) {
                    cache.writeQuery({
                        variables,
                        query: NodesQuery,
                        data: { nodes: nodes.concat(node) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

export function MakeNodesSubscriptionOptions(
    variables: EnvironmentResourcesVars,
    overrideOptions?: SubscriptionHookOptions<NodeData>
    ): SubscriptionHookOptions<NodeData> {
        return { ...{
            variables,
            onSubscriptionData: ({client, subscriptionData}) => {
                // Get the node object from the event
                const { 
                    data: { 
                        node
                    } = { node: null }
                } = subscriptionData
    
                if (!node) return
    
                // Get current list of nodes from the cache
                // assert that nodes must exist since this function throws if it doesnt
                const { nodes: existingNodes } = client.readQuery<NodesData>({ 
                    query: NodesQuery, 
                    variables 
                })!
                
                // if it already exists in the store, (its an update), apollo automagically updated the cache
                const cachedNodes = existingNodes.find(c => c._id === node._id)
    
                // but, if it came back as deleted, we need to remove it from the cache manually
                if (cachedNodes) {
                    if (cachedNodes.state === 'deleted') {
                        client.cache.evict({id: `Node:${cachedNodes._id}`, broadcast: false})
                    }
                    client.writeQuery<NodesData>({ 
                        query: NodesQuery, 
                        data: Object.assign({}, existingNodes, {
                            nodes: [...existingNodes.filter(c => c.state !== 'deleted')]
                        }),
                        variables
                    })
                    return 
                }
    
                // in case of duplicate events, never add a deleted item into the cache
                if (node.state === 'deleted') return
    
                // if it doesnt exist in the store, (it's been added), so we need to add it in manually
                client.writeQuery<NodesData>({ 
                    query: NodesQuery, 
                    data: Object.assign({}, existingNodes, {
                        nodes: [node, ...existingNodes]
                    }),
                    variables
                })
            }
        }, ...overrideOptions }
}
