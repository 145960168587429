import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, makeStyles, Breadcrumbs as MaterialBreadcrumbs } from '@material-ui/core';
import { useParams, Link, useLocation } from "react-router-dom";
import { useQuery, useLazyQuery } from '@apollo/client';
import {
    ConsortiaData, ConsortiaQuery, EnvironmentData, EnvironmentQuery,
    NodeData, NodeQuery,
    ServiceData, ServiceQuery,
    AppCredData, AppCredQuery,
    MembershipData, MembershipQuery,
    ContractProjectData, ContractProjectQuery,
    AccountsData, AccountsVars, AccountsQuery,
    CompiledContractData, CompiledContractVars, CompiledContractQuery,
    GatewayAPIData, GatewayAPIQuery, NodesData, NodesQuery, ServicesData, ServicesQuery, ChannelData, ChannelQuery
} from '../../models';
import { useTranslation } from 'react-i18next';
import { EnvironmentResourceVars, ConsortiumResourceVars, NodeResourceVars } from '../../interfaces';
import { ConfigTypesUrl } from '../MainNav/SideNavs/CloudConfigs';
import { ConfigData, ConfigQuery, ConfigTypeTranslationsInterface, ConfigTypeTranslations } from '../../models/configs';
import { ADDRESSBOOK_PATH, SYSTEM_MEMBERSHIP, ADDRESSBOOK_ACCOUNTS_PATH, ADDRESSBOOK_RUNTIMES_PATH, CHANNELS_PATH } from '../MainNav/SideNavs/AddressBook';
import { MEMBERSHIP_SIGNING_ACCOUNTS_PATH } from '../MembershipNav/MembershipNav';
import { ResourceStateChip } from '../FormControls/ResourceStateChip';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_BLOCKS_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from '../BlockExplorerNav/BlockExplorerNav';
import { ENVIRONMENT_DATA_EXPLORER } from '../MainNav/SideNavs/DataExplorer';
import { NETWORK_GOVERNANCE_PATH } from '../MainNav/SideNavs/Governance';
import { APPS_CHAINCODES_PATH, APPS_CORDAPPS_PATH, APPS_GATEWAY_VIEW_PATH } from '../MainNav/SideNavs/AppsIntegrations';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../MainNav/SideNavs/Security';
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TOKENS_PATH, TOKENEXPLORER_TRANSFERS_PATH } from '../TokenExplorerNav/TokenExplorerNav';
import { KEY_MANAGEMENT_CLOUD_PATH, KEY_MANAGEMENT_MANAGED_PATH } from '../MainNav/SideNavs/KeyManagement';
import { OauthConfigurationData, OauthConfigurationQuery } from '../../models/oauthConfiguration';
import { NODE_CORDAPPS_PATH } from '../NodeNav/NodeNav';
import { BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH } from '../MainNav/SideNavs/Blockchain';
import { EventStreamData, EventStreamQuery, EventStreamSubscriptionData, EventStreamSubscriptionQuery } from '../../models/eventStreams';

const Breadcrumbs = () => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Breadcrumbs', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Breadcrumbs:${key}`, interpolate)

    const { pathname } = useLocation()
    const { consortium_id, environment_id, service_id, node_id, appcred_id,
        address, transfer_id, membership_id, contract_id, compiled_contract_id,
        config_id, gateway_api_id, endpoint, block_number, transaction_hash, contract_address,
        runtime_id, destination_uri, token_address, transfer_hash, config_type, oauth_id, 
        eventstream_id, eventstream_subscription_id, channel_id } = useParams<any>();

    const environmentResourcesVars = useMemo(() => ({
        consortia_id: consortium_id!
    }), [consortium_id])

    const environmentResourceVars = useMemo(() => ({
        ...environmentResourcesVars,
        environment_id: environment_id!
    }), [environmentResourcesVars, environment_id]);

    // consortium query
    const {
        data: {
            consortia
        } = { consortia: [] }
    } = useQuery<ConsortiaData>(ConsortiaQuery, { fetchPolicy: 'cache-only' });

    const consortium = consortia.find(c => c._id === consortium_id)!

    // environment query

    const { data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        skip: !environment_id,
        variables: {
            ...environmentResourcesVars,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    // node query

    const [getNode,
        { data: {
            node
        } = { node: null }
        }] = useLazyQuery<NodeData, EnvironmentResourceVars>(NodeQuery, {
            variables: {
                ...environmentResourceVars,
                id: node_id!
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (node_id) getNode()
    }, [node_id, getNode])

    // service query

    const [getService,
        { data: {
            service
        } = { service: null }
        }] = useLazyQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
            variables: {
                ...environmentResourceVars,
                id: service_id!
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (service_id) getService()
    }, [service_id, getService])

    // appcred query

    const [getAppCred,
        { data: {
            appCred
        } = { appCred: null }
        }] = useLazyQuery<AppCredData, EnvironmentResourceVars>(AppCredQuery, {
            variables: {
                ...environmentResourceVars,
                id: appcred_id!
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (appcred_id) getAppCred()
    }, [appcred_id, getAppCred])

    //oauth configuration query

    const [getOauth, {
            data: {
                oauthConfiguration
            } = { oauthConfiguration: null }
        }] = useLazyQuery<OauthConfigurationData, EnvironmentResourceVars>(OauthConfigurationQuery, { 
            variables: {
                ...environmentResourceVars,
                id: oauth_id
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (getOauth) getOauth()
    }, [oauth_id, getOauth])


    // contract query

    const [getContractProject,
        { data: {
            contractProject
        } = { contractProject: null }
        }] = useLazyQuery<ContractProjectData, ConsortiumResourceVars>(ContractProjectQuery, {
            variables: {
                ...environmentResourcesVars,
                id: contract_id!
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (contract_id) getContractProject()
    }, [contract_id, getContractProject])

    // compiled contract query

    const [getCompiledContract,
        { data: {
            compiledContract
        } = { compiledContract: null }
        }] = useLazyQuery<CompiledContractData, CompiledContractVars>(CompiledContractQuery, {
            variables: {
                ...environmentResourcesVars,
                contract_id: contract_id!,
                id: compiled_contract_id!
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (compiled_contract_id) getCompiledContract()
    }, [compiled_contract_id, getCompiledContract])

    // accounts query

    const [getAccounts,
        { data: {
            accounts
        } = { accounts: [] }
        }] = useLazyQuery<AccountsData, AccountsVars>(AccountsQuery, {
            variables: {
                ...environmentResourceVars,
                wallet_id: node_id || service_id
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (address) getAccounts()
    }, [address, getAccounts])

    // gateway API query

    const [getGatewayAPI,
        { data: {
            gatewayAPI
        } = { gatewayAPI: null }
        }] = useLazyQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, {
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (gateway_api_id) getGatewayAPI({
            variables: {
                ...environmentResourceVars,
                id: gateway_api_id
            }
        })
    }, [gateway_api_id, getGatewayAPI, environmentResourceVars])


    const [getEventStream,
        { data: {
            eventStream
        } = { eventStream: null }
        }] = useLazyQuery<EventStreamData, NodeResourceVars>(EventStreamQuery, {
            variables: {
                ...environmentResourceVars,
                node_id: node_id!,
                id: eventstream_id!
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (eventstream_id && node_id) getEventStream()
    }, [eventstream_id, node_id, getEventStream])

    // eventstream subscription query

    const [getEventStreamSubscription,
        { data: {
            eventStreamSubscription
        } = { eventStreamSubscription: null }
        }] = useLazyQuery<EventStreamSubscriptionData, NodeResourceVars>(EventStreamSubscriptionQuery, { 
            variables: {
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                node_id: node_id,
                id: eventstream_subscription_id
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (eventstream_subscription_id && node_id) getEventStreamSubscription()
    }, [eventstream_subscription_id, node_id, getEventStreamSubscription])

    // membership query

    const [getMembership,
        { data: {
            membership
        } = { membership: null }
        }] = useLazyQuery<MembershipData, ConsortiumResourceVars>(MembershipQuery, {
            variables: {
                ...environmentResourcesVars,
                id: membership_id!
            },
            fetchPolicy: 'cache-only'
        });

    useEffect(() => {
        if (membership_id && membership_id !== SYSTEM_MEMBERSHIP) getMembership()
    }, [membership_id, getMembership])

    const account = accounts.find(a => a._id === address)
    
    // get config type - present for cloud configurations

    const configType : ConfigTypesUrl | undefined = useMemo(() => {
        if (pathname.includes(`${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`)) {
            return 'baf';
        } else if (pathname.includes(`nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}`)) {
            return 'configuration'
        } else if (config_type) {
            return config_type as ConfigTypesUrl;
        }
    }, [config_type, pathname]);

    //get confign Name 

    const [getConfig,
        { data: {
            config
        } = { config: null }
        }] = useLazyQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
            variables: {
                ...environmentResourceVars,
                id: config_id!
            },
            fetchPolicy: 'cache-and-network'
        });

    useEffect(() => {
        if (config_id) getConfig();
    }, [config_id, getConfig]);

    // channel query

    const [getChannel,
        { data: {
            channel
        } = { channel: null }
        }] = useLazyQuery<ChannelData, EnvironmentResourceVars>(ChannelQuery, {
            variables: {
                ...environmentResourceVars,
                id: channel_id!
            },
            fetchPolicy: 'cache-only'
    });

    useEffect(() => {
        if (channel_id) getChannel()
    }, [channel_id, getChannel])

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentResourceVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentResourceVars,
        fetchPolicy: 'cache-only'
    });

    const lookupRuntimeName = (): string => {
        return nodes.find(node => node._id === runtime_id)?.name || services.find(service => service._id === runtime_id)?.name || runtime_id || '';
    };

    // make and render breadcrumbs

    const splitPath = pathname.split('/');

    const segmentMatches = (segment: string, index: number) => {
        if (splitPath.length >= index) {
            return splitPath[index] === segment;
        }
        return false;
    };

    const makeBreadcrumb = (resourceId: string, resourceType: string, resourceName: string, chip?: JSX.Element, pathOffset = 1) => {
        const lastItem = resourceId ? splitPath.indexOf(resourceId) + 1 : splitPath.length - pathOffset
        let linkPath = splitPath.slice(0, lastItem).join('/')

        return (
            <Grid item container spacing={1} alignItems="center">
                <Grid item>
                    <Link color="inherit" to={linkPath} className={classes.link}>
                        <Typography noWrap className={classes.breadcrumb} variant="body2" color="textSecondary">
                            {`${resourceType}${resourceName && resourceType ? ': ' : ''}${resourceName}`}
                        </Typography>
                    </Link>
                </Grid>
                {chip &&
                    <Grid item>
                        {chip}
                    </Grid>
                }
            </Grid>
        )
    }

    return (

        <MaterialBreadcrumbs maxItems={3} itemsBeforeCollapse={0} itemsAfterCollapse={3} aria-label="breadcrumb">
            {consortium && makeBreadcrumb(
                consortium_id!, 
                lt('consortium'), 
                consortium.name,
                consortium.state !== 'setup' ? <ResourceStateChip state={consortium.state} /> : undefined )}
            {environment && makeBreadcrumb(
                environment_id!, 
                lt('environment'), 
                environment.name, 
                environment.state !== 'live' ? <ResourceStateChip state={environment.state} /> : undefined )}
                        
            {node && makeBreadcrumb(
                node_id!, 
                lt('node'), 
                node.name,
                node.state !== 'started' ? <ResourceStateChip state={node.state} /> : undefined )}
            
            {node && pathname.includes(`/${NODE_CORDAPPS_PATH}/`) && makeBreadcrumb('', lt('cordapps'), '')}

            {service && pathname.includes(`/${KEY_MANAGEMENT_CLOUD_PATH}`) && 
                makeBreadcrumb('', lt('cloudhsm'), '', undefined, pathname.endsWith(service_id) ? 2 : 3)}

            {service && pathname.includes(`/${KEY_MANAGEMENT_MANAGED_PATH}`) && 
                makeBreadcrumb('', lt('managedWallets'), '', undefined, pathname.endsWith(service_id) ? 2 : 3)}

            {service && makeBreadcrumb(
                service_id!, 
                lt('runtime'), 
                service.name,
                service.state !== 'started' ? <ResourceStateChip state={service.state} /> : undefined)}

            {appCred && makeBreadcrumb('', lt('appCreds'), '', undefined)} 
            {appCred && makeBreadcrumb(appcred_id!, lt('appCred'), appCred.name)}

            {oauthConfiguration && makeBreadcrumb('', lt('oauths'), '', undefined)} 
            {oauthConfiguration && makeBreadcrumb(oauth_id!, lt('oauth'), oauthConfiguration.name ?? '')}

            {contractProject && !compiled_contract_id && makeBreadcrumb('', lt('contractProjects'), '')} 
            {contractProject && !compiled_contract_id && makeBreadcrumb(contract_id!, '', contractProject.name)}

            {compiledContract && makeBreadcrumb('', lt('contractProject'), contractProject?.name ?? '', undefined, 2)} 
            {compiledContract && makeBreadcrumb(compiled_contract_id!, lt('compiledContract'), compiledContract.description ?? '--')}

            {account && (node || service) && makeBreadcrumb('', lt('wallet'), '')}
            {account && (node || service) && makeBreadcrumb(address!, '', account._id)}
            
            {environment?.isEthereum && gatewayAPI && makeBreadcrumb('', lt('gatewayAPIs'), '', undefined, endpoint ? 3 : pathname.includes(APPS_GATEWAY_VIEW_PATH) ? 2 : 1)} 
            {environment?.isEthereum && gatewayAPI && makeBreadcrumb(gateway_api_id!, '', gatewayAPI.endpoint)}
            {environment?.isEthereum && gatewayAPI && pathname.includes(APPS_GATEWAY_VIEW_PATH) && makeBreadcrumb(endpoint, lt('gateway'), endpoint ? endpoint : '', undefined, 0)}

            {gatewayAPI && pathname.includes(APPS_CHAINCODES_PATH) && makeBreadcrumb('', lt('chaincodes'), '', undefined, undefined)} 
            {gatewayAPI && pathname.includes(APPS_CHAINCODES_PATH) && makeBreadcrumb(gateway_api_id!, '', gatewayAPI.endpoint)}

            {gatewayAPI && pathname.includes(APPS_CORDAPPS_PATH) && makeBreadcrumb('', lt('cordapps'), '', undefined, undefined)} 
            {gatewayAPI && pathname.includes(APPS_CORDAPPS_PATH) && makeBreadcrumb(gateway_api_id!, '', gatewayAPI.endpoint)}

            {eventStream && makeBreadcrumb('', lt('eventStreams'), '', undefined, eventStreamSubscription ? 3 : undefined)} 
            {eventStream && makeBreadcrumb(eventstream_id!, '', eventStream.name || eventStream.id)}

            {eventStreamSubscription && makeBreadcrumb(eventstream_subscription_id!, lt('eventStreamSubscription'), eventStreamSubscription.name)}

            {transfer_id && makeBreadcrumb('', lt('transfer'), '')}
            {transfer_id && makeBreadcrumb(transfer_id!, '', transfer_id)}

            {configType && configType !== 'baf' && makeBreadcrumb('', lt('cloudConfig', {configType: lt(configType)}), '')}
            {configType && configType === 'configuration' && makeBreadcrumb('', lt('configuration'), '', undefined, config_id ? undefined : 0)}
            {configType && configType === 'baf' && makeBreadcrumb('', lt('baf'), '', undefined, config_id ? undefined : 0)}
            {config_id && makeBreadcrumb(config_id!, '', config?.name || '')}

                {membership_id && segmentMatches(ADDRESSBOOK_PATH, 7) &&
                    segmentMatches(membership_id, 9) &&
                    makeBreadcrumb('', lt('addressBook'), '', undefined, splitPath.length - 9)}

            {membership && pathname.includes(NETWORK_GOVERNANCE_PATH) && makeBreadcrumb('', lt('governance'), '')}
        
            {membership && makeBreadcrumb(membership_id!, '', membership?.org_name || '')}

            {membership_id === SYSTEM_MEMBERSHIP && makeBreadcrumb(SYSTEM_MEMBERSHIP, '', lt('system'))}

            {pathname.includes(BLOCKEXPLORER_PATH) && (
                pathname.includes(BLOCKEXPLORER_BLOCKS_PATH) ||
                pathname.includes(BLOCKEXPLORER_TRANSACTIONS_PATH)
            ) && makeBreadcrumb('', lt('blockExplorer'), '', undefined, block_number || transaction_hash || contract_address ? 2 : 1)}

            {block_number && makeBreadcrumb('', lt('blocks'), '')}
            {block_number && makeBreadcrumb(block_number!, '', block_number)}

            {transaction_hash && makeBreadcrumb('', lt('transactions'), '')}
            {transaction_hash && makeBreadcrumb(transaction_hash!, '', transaction_hash)}

            {contract_address && pathname.includes(ENVIRONMENT_DATA_EXPLORER) && makeBreadcrumb('', lt('contracts'), '')}
            {contract_address && pathname.includes(ENVIRONMENT_DATA_EXPLORER) && makeBreadcrumb(contract_address!, '', contract_address)}
            
            {membership_id && address && pathname.includes(MEMBERSHIP_SIGNING_ACCOUNTS_PATH) && makeBreadcrumb('',  lt('signingAccounts'), '')}
            {membership_id && address && pathname.includes(MEMBERSHIP_SIGNING_ACCOUNTS_PATH) && makeBreadcrumb('',  address, '')}

            {address && pathname.includes(`/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}`) && makeBreadcrumb('',  lt('signingAccounts'), '')}
            {address && pathname.includes(`/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}`) && makeBreadcrumb('',  address, '')}

            {destination_uri && makeBreadcrumb('', lt('destinations') ,'')}
            {destination_uri && makeBreadcrumb('', decodeURIComponent(destination_uri) ,'')}

            {runtime_id && pathname.includes(`/${ADDRESSBOOK_RUNTIMES_PATH}`) && makeBreadcrumb('', lt('runtimes'), '')}
            {runtime_id && pathname.includes(`/${ADDRESSBOOK_RUNTIMES_PATH}`) && makeBreadcrumb(runtime_id, '', lookupRuntimeName())}

            {channel_id && pathname.includes(`/${CHANNELS_PATH}`) && makeBreadcrumb('', lt('channels'), '')}
            {channel_id && channel && pathname.includes(`/${CHANNELS_PATH}`) && makeBreadcrumb(
                channel_id!, 
                '', 
                channel.name,
                channel.state !== 'live' ? <ResourceStateChip state={channel.state} /> : undefined )}

            {pathname.includes(TOKENEXPLORER_PATH) && (
                pathname.includes(TOKENEXPLORER_TOKENS_PATH) ||
                pathname.includes(TOKENEXPLORER_TRANSFERS_PATH)
            ) && makeBreadcrumb('', lt('tokenExplorer'), '', undefined, token_address || transfer_hash  ? 2 : 1)}

            {transfer_hash && makeBreadcrumb('', lt('transfers'), '')}
            {transfer_hash && makeBreadcrumb(transfer_hash!, '', transfer_hash)}

            {token_address && makeBreadcrumb('', lt('tokens'), '')}
            {token_address && makeBreadcrumb(token_address, '', token_address)}

        </MaterialBreadcrumbs>
    )
};

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
    },
    breadcrumb: {
        maxWidth: '25vw'
    }
}));

interface translations extends ConfigTypeTranslationsInterface {
    consortium: string
    governance: string
    environment: string
    node: string
    runtime: string
    security: string
    appCred: string
    appCreds: string
    contractProjects: string
    contractProject: string
    wallet: string
    transfer: string
    cloudConfig: string
    addressBook: string
    compiledContract: string
    gatewayAPIs: string
    chaincodes: string
    blocks: string
    transactions: string
    contracts: string
    runtimes: string
    signingAccounts: string
    paused: string
    blockExplorer: string
    system: string
    destinations: string
    baf: string
    transfers: string
    tokens: string
    tokenExplorer: string
    managedWallets: string
    oauth: string
    oauths: string
    cordapps: string
    eventStreams: string
    eventStreamSubscription: string
    gateway: string
    instance: string
    channels: string
    fabricApps: string
}
const enTranslations: translations = {
    ...ConfigTypeTranslations,
    oauth: 'Oauth',
    oauths: 'Oauth Configurations',
    consortium: 'Network',
    governance: 'Governance',
    environment: 'Environment',
    node: 'Node',
    runtime: 'Runtime',
    security: 'Security',
    appCred: 'App Cred',
    appCreds: 'App Creds',
    contractProjects: 'Apps',
    contractProject: 'App',
    wallet: 'Wallet',
    transfer: 'Transfer',
    cloudConfig: 'Cloud Configuration: {{configType}}',
    addressBook: 'Address Book',
    compiledContract: 'Version',
    gatewayAPIs: 'Smart Contracts',
    cordapps: 'CorDapps',
    chaincodes: 'Chaincodes',
    blocks: 'Blocks',
    transactions: 'Transactions',
    contracts: 'Contracts',
    runtimes: 'Runtimes',
    signingAccounts: 'Signing Accounts',
    paused: 'Paused',
    blockExplorer: 'Block Explorer',
    system: 'System',
    destinations: 'Destinations',
    baf: 'Blockchain Application Firewall',
    transfers: 'Transfers',
    tokens: 'Tokens',
    tokenExplorer: 'Token Explorer',
    managedWallets: 'Managed Wallets',
    eventStreams: 'Event Streams',
    eventStreamSubscription: 'Subscription',
    gateway: 'Gateway',
    instance: 'Instance',
    channels: 'Channels',
    fabricApps: 'Fabric Apps'
}


export { Breadcrumbs };