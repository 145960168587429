import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { AlertBanner } from './AlertBanner';
import { PROFILE_SECURITY_PATH, PROFILE_SETTINGS_PATH } from '../ProfileSettingsNav/ProfileSettingsNav';

export const MFABlockedOrgsBanner = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MFABlockedOrgsBanner', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MFABlockedOrgsBanner:${key}`, interpolate);

    const history = useHistory()
    return (
        <AlertBanner title={lt('title')} description={lt('description')} linkButton={{ text: lt('openSecuritySettings'),
        onClick:() => history.push(`/${PROFILE_SETTINGS_PATH}/${PROFILE_SECURITY_PATH}`) }}  />
    )
};

interface translations {
    title: string
    description: string
    openSecuritySettings: string
}

const enTranslations: translations = {
    title: 'MFA setup required',
    description: 'One or more organizations require multi-factor based authentication.',
    openSecuritySettings: 'Open security settings'
}
