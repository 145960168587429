import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography, TextField } from '@material-ui/core';
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector'
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher, Help } from '../../../components/DialogWrappers';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../../../components/MainNav/SideNavs/Security';
import { CreateStepProps } from '../../../interfaces';
import { useMutation } from '@apollo/client';
import { CreateConfigMutation, CreateConfigData, CreateConfigVars } from '../../../models/configs';
import { BAFPolicy } from '../BAFPolicySchema';

const DEFAULT_POLICY: BAFPolicy = {
    "appcreds": {
        "enabled": true,
        "basicAuth": true,
        "mappings": [
            {
                "comment": "Example mapping: matches all valid application credentials",
                "claims": { "id": ".+" },
                "ruleset": "admin"
            }
        ]
    },
    "rulesets": {
        "admin": {
            "comment": "Full access to all JSON/RPC methods",
            "rpc": [
                {
                    "method": ".*",
                    "allow": true
                }
            ]
        }
    },
    "jwt": {
        "enabled": false,
        "mappings": []
    }
};

interface Props extends CreateStepProps {
}

export const Step1 = ({ cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreatePolicyStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreatePolicyStep1:${key}`, interpolate)

    const [name, setName] = useState('');
    const [membershipId, setMembershipId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const [createConfig, {loading}] = useMutation<CreateConfigData, CreateConfigVars>(CreateConfigMutation);

    const save = async () => {
        try {
            let result = await createConfig({
                variables: {
                    consortia_id: consortium_id!,
                    environment_id: environment_id!,
                    configuration: {
                        membership_id: membershipId!,
                        name: name,
                        type: "baf",
                        details: {
                            baf_policy: JSON.stringify(DEFAULT_POLICY)
                        }
                    }
                }
            })
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/${result.data?.createConfig._id}`)
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
    }

    const disabled = !name || !membershipId || loading;

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />

                <TextField data-test="textField_policyName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            <Help 
                imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
                header={lt('helpHeader')}
                description={lt('helpDescription')}
                docLink="https://docs.kaleido.io/kaleido-services/baf/"
                bullet1={lt('bulletHelp1')}
                bullet2={lt('bulletHelp2')}
                bullet3={lt('bulletHelp3')}
            />
        </>
    )
};

interface translations {
    header: string,
    name: string,

    helpHeader: string,
    helpDescription: string,
    bulletHelp1: string,
    bulletHelp2: string,
    bulletHelp3: string,
}
const enTranslations: translations = {
    header: 'Choose your membership and name',
    name: 'Name',

    helpHeader: "Blockchain Application Firewall Policy",
    helpDescription: "A firewall policy is attached to on or more nodes, and configures those nodes with an additional runtime and layer of boundary security",
    bulletHelp1: "Authentication with OAuth 2.0, Open ID Connect, and custom JSON Web Tokens (JWT). In addition to strong generated application credentials",
    bulletHelp2: "Fine grained access control based on analyzing each RPC command sent to the node, including decoding pre-signed transaction payloads",
    bulletHelp3: "Dynamic policy generation without restart for Kaleido multi-tenant nodes",
}