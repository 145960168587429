import React, { useState } from "react";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { App2appDestinationsQuery, App2AppDestinationsData } from "../../../models/app2app";
import { useParams, useHistory } from "react-router-dom";
import { ServicesEnum, ServicesData, ServicesQuery } from "../../../models";
import { ORGS_PATH } from "../../../components/ManageOrgNav/ManageOrgNav";
import { B2B_BASE_PATH } from "../../../components/MainNav/SideNavs/B2bCommunication";
import { APP2APP_DESTINATIONS_PATH, APP2APP_CREATE_DESTINATION_PATH } from "../../../components/ServicesNav/Services/App2AppItems";
import { ErrorSnackbarCatcher, MessageSnackbar } from "../../../components/DialogWrappers";
import { ServiceResourcesVars } from "../../../interfaces";
import { App2AppDestinationDetails } from "./DestinationDetails";
import { EmptyState } from "../../../components/DisplayWrappers";

export const App2AppDestinations: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'App2AppDestinations', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`App2AppDestinations:${key}`, interpolate);
    const history = useHistory();
    const { org_id, consortium_id, environment_id, service_id } = useParams<any>();
    const [errorMessage, setErrorMessasge] = useState('');

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const app2appService = services.find(service => service._id === service_id)!;

    const {
        refetch,
        loading,
        data: {
            app2appDestinations
        } = { app2appDestinations: [] },
    } = useQuery<App2AppDestinationsData, ServiceResourcesVars>(App2appDestinationsQuery, {
        variables: { service_id: service_id! }, fetchPolicy: 'cache-and-network',
        onError: err => ErrorSnackbarCatcher(err, setErrorMessasge),
    });

    if (loading && app2appDestinations.length === 0) {
        return <CircularProgress />
    }

    return (
        <>
            <Grid container direction="column" wrap="nowrap" spacing={3}>
                <Grid item container justify="space-between">
                    <Grid item>
                        <Typography variant="h5">{lt('destinations')}</Typography>
                    </Grid>
                    <Grid item>
                        <Button size="large" variant="contained" color="primary"
                            onClick={() => history.push(`/${ORGS_PATH}/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}` +
                                `/${ServicesEnum.app2app}/${service_id}/${APP2APP_DESTINATIONS_PATH}/${APP2APP_CREATE_DESTINATION_PATH}/1`)}>
                            {lt('addDestination')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    {app2appDestinations.map(app2appDestination => (
                        <Grid item key={app2appDestination.uri}>
                            <App2AppDestinationDetails app2appService={app2appService} app2appDestination={app2appDestination} refetch={refetch} />
                        </Grid>
                    ))}
                </Grid>
                {app2appDestinations.length === 0 &&
                    <EmptyState title={lt('destinations')} description={lt('noDestinations')} imageFile='Empty-Destination.svg'
                        documentation={{ text: lt('learnAboutDestinations'), link: 'https://docs.kaleido.io/kaleido-services/app2app/destinations/' }} />}
            </Grid>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessasge}
            />
        </>
    );
}

interface translations {
    destinations: string
    addDestination: string
    invalidService: string
    noDestinations: string
    learnAboutDestinations: string
}

const enTranslations: translations = {
    destinations: 'Destinations',
    addDestination: 'ADD Destination',
    invalidService: 'Invalid service',
    noDestinations: 'No destinations have been created',
    learnAboutDestinations: 'Learn about destinations'
}