import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import AccountStarIcon from "mdi-react/AccountStarIcon";
import AccountTieIcon from "mdi-react/AccountTieIcon";
import HandshakeOutlineIcon from 'mdi-react/HandshakeOutlineIcon';
import CubeOutlineIcon from 'mdi-react/CubeOutlineIcon';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { NavItem } from "../NavComponents";

export const OPS_PATH = "konsole";
export const OPS_MANAGE_ORG_PATH = "orgs";
export const OPS_MANAGE_CONSORTIA_PATH = "consortia";
export const OPS_MANAGE_ENVIRONMENTS_PATH = "environments";
export const OPS_MANAGE_AGREEMENT_PATH = "agreements";
export const OPS_MANAGE_AGREEMENT_PATH_CREATE = "create";
export const OPS_MANAGE_NODE_PATH = "nodes";
export const OPS_MANAGE_SERVICE_PATH = "services";

export const OpsNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "OpsNav", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`OpsNav:${key}`, interpolate);

    const history = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.endsWith(OPS_PATH)) {
            history.push(`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}`);
        }
    }, [pathname, history])

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem>
                    <ListItemText
                        secondary={lt("ops")}
                        secondaryTypographyProps={{ variant: "button" }}
                    />
                </ListItem>

                <NavItem
                    icon={<AccountTieIcon />}
                    header={lt("orgs")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}`)}
                    itemIsActive={pathname.endsWith(OPS_MANAGE_ORG_PATH)}
                />

                <NavItem
                    icon={<HandshakeOutlineIcon />}
                    header={lt("consortia")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_MANAGE_CONSORTIA_PATH}`)}
                    itemIsActive={pathname.includes(OPS_MANAGE_CONSORTIA_PATH)}
                />

                <NavItem
                    icon={<CubeOutlineIcon />}
                    header={lt("environments")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_MANAGE_ENVIRONMENTS_PATH}`)}
                    itemIsActive={pathname.endsWith(OPS_MANAGE_ENVIRONMENTS_PATH)}
                />

                <NavItem
                    icon={<AccountStarIcon />}
                    header={lt("agreements")}
                    action={() => history.push(`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}`)}
                    itemIsActive={pathname.includes(`/${OPS_MANAGE_AGREEMENT_PATH}`)}
                />

            </List>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    toolbar: {
        [theme.breakpoints.up("md")]: theme.mixins.toolbar,
    },
    backButton: {
        paddingLeft: theme.spacing(1),
    },
    backIcon: {
        minWidth: 0,
    },
}));

interface translations {
    ops: string;
    orgs: string;
    consortia: string;
    environments: string;
    agreements: string;
}

const enTranslations: translations = {
    ops: 'Ops',
    orgs: 'Organizations',
    consortia: 'Consortia',
    environments: 'Environments',
    agreements: 'Agreements',
};
