import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from 'mdi-react/DotsVerticalIcon';
import { ActionMenu } from '../ActionMenu';
import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks';

interface Props {
    menuItems: {
        name: string;
        icon?: JSX.Element;
        action: () => void;
    }[],
    disabled?: boolean
}

export const Dotdotdot = ({menuItems, disabled}: Props) => {

    const popupState = usePopupState({ variant: 'popover', popupId: null });

    return (
        <>
            <IconButton data-test="dotdotdot" size="small" disabled={disabled} {...bindTrigger(popupState)}>
                <MoreVertIcon />
            </IconButton>
            <ActionMenu {...{popupState}} {...{menuItems}} />
        </>
    )
};
