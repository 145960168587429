import React from 'react';
import { RuntimeCreationStep1Wrapper } from '../../../components/RuntimeCreation/RuntimeCreationStep1Wrapper';
import { CreateStepProps } from '../../../interfaces';
import { ConsortiumZone } from '../../../models';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_CLOUD_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import { StepHelp } from './StepHelp';

interface Props extends CreateStepProps {
    isMultiRegion: boolean,
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    consortiumZoneId: string,
    consortiumZones: ConsortiumZone[],
    setConsortiumZoneId: React.Dispatch<React.SetStateAction<string>>
};


export const Step1 = ({isMultiRegion, name, setName, membershipId, setMembershipId, 
    consortiumZoneId, consortiumZones, setConsortiumZoneId, 
    cancelPath}: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateCloudHsmWalletStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateCloudHsmWalletStep1:${key}`, interpolate)

    const history = useHistory();
    const { consortium_id, environment_id, org_id } = useParams<any>();

    const onSave = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/create/2`, {
            name,
            membershipId,
            consortiumZoneId
        });
    }

    return (
        <>
            <RuntimeCreationStep1Wrapper header={lt('createWallet')} description={lt('description')} onSave={onSave} 
                {...{cancelPath}} {...{isMultiRegion}} {...{name}} {...{setName}} {...{membershipId}} {...{setMembershipId}}
                {...{consortiumZones}} {...{consortiumZoneId}} {...{setConsortiumZoneId}} />
            <StepHelp />
        </>
    )
};

interface translations {
    createWallet: string,
    description: string
}
const enTranslations: translations = {
    createWallet: 'Create Cloud HSM Wallet',
    description: ''
}