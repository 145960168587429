import { gql } from '@apollo/client';
import { MembershipOwnedResource } from './memberships';
import { EnvironmentResourcesVars, EnvironmentResourceVars } from '../interfaces';

export interface OauthConfiguration extends MembershipOwnedResource {
    _id: string
    _revision: string
    created_at: string
    updated_at: string
    environment_id: string
    membership_id: string
    dapp_id?: string
    name?: string
    verification_type?: string
    jwt_key_set: string
    jwt_key_type: jwtKeyType
};


export interface JwtKeyTypeTranslationsInterface {
    jwks: string
    ec_pem: string
    rsa_pem: string
}

export type jwtKeyType = keyof JwtKeyTypeTranslationsInterface;

export const jwtKeyTypeTranslations : JwtKeyTypeTranslationsInterface = {
    jwks: 'JSON Web Key Store (JWKS)',
    ec_pem: 'EC PEM File',
    rsa_pem: 'RSA PEM File',
}

export interface CreateOathConfiguration {
    membership_id: string
    name: string
    verification_type: string
    jwt_key_set: string
    jwt_key_type: jwtKeyType
}

export interface CreateOathConfigurationVars extends EnvironmentResourcesVars {
    oauth: CreateOathConfiguration
}

export interface OauthConfigurationData {
    oauthConfiguration: OauthConfiguration
}

export interface OauthConfigurationsData {
    oauthConfigurations: OauthConfiguration[]
}

export interface UpdateOauthConfiguration extends EnvironmentResourceVars {
    oauth: {
        name: OauthConfiguration['name'],
    }
}

export const OauthConfigurationFields = ` 
    fragment OauthConfigurationFields on OauthConfiguration {
        _id
        _revision
        created_at
        updated_at
        environment_id
        membership_id
        dapp_id
        name
        verification_type
        jwt_key_set
        jwt_key_type
        membership @client
    }`

export const OauthConfigurationsQuery = gql`
    ${OauthConfigurationFields}
    query getOauthsConfigurations($consortia_id: String!, $environment_id: String!) {
        oauthConfigurations(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...OauthConfigurationFields
        }
    }`

export const OauthConfigurationQuery = gql`
    ${OauthConfigurationFields}
    query getOauthConfiguration($consortia_id: String!, $environment_id: String!, $id: String!) {
        oauthConfiguration(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...OauthConfigurationFields
        }
    }`

export const CreateOauthConfigurationMutation = gql`
    ${OauthConfigurationFields}
    mutation createOauthConfiguration($consortia_id: String!, $environment_id: String!, $oauth: CreateOauthConfiguration!) {
        createOauthConfiguration(consortia_id: $consortia_id, environment_id: $environment_id, oauth: $oauth) {
            ...OauthConfigurationFields
        }
    }`;

export const UpdateOauthConfigurationMutation = gql`
    ${OauthConfigurationFields}
    mutation updateOauthConfiguration($consortia_id: String!, $environment_id: String!, $id: String!, $oauth: UpdateOauth!) {
        updateOauthConfiguration(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, oauth: $oauth) {
            ...OauthConfigurationFields
        }
    }`;


export const DeleteOauthConfigurationMutation = gql`
    ${OauthConfigurationFields}
    mutation deleteOauthConfiguration($consortia_id: String!, $environment_id: String!, $id: String!) {
        deleteOauthConfiguration(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...OauthConfigurationFields
        }
    }`;
