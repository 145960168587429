import React, { FunctionComponent, useEffect, useState, RefObject } from 'react';
import { Popover, makeStyles, Theme, Fade } from '@material-ui/core';

interface Props {
    popoverOpen: boolean
    anchorRef: RefObject<HTMLElement>
    setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
    placement?: 'top' | 'bottom' | 'left' | 'right'
    arrowColor?: string
    marginThreshold?: number
};

export const DisplayPopover: FunctionComponent<Props> = ({
    popoverOpen,
    setPopoverOpen,
    anchorRef,
    children,
    placement = 'top',
    arrowColor = 'white',
    marginThreshold = 16
}) => {

    const classes = useStyles({ placement, arrowColor });
    const boundaries = anchorRef.current?.getBoundingClientRect();
    const [popoverHeight, setPopoverHeight] = useState(0);
    const [popoverWidth, setPopoverWidth] = useState(0);
    const [, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        const handleResize = () => {
            if (popoverOpen) {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth
                });
            }
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [popoverOpen]);

    interface ArrowCoordinates {
        top: number
        left: number
    }

    let arrowCoordinates: ArrowCoordinates | undefined;

    if (popoverOpen && boundaries) {
        switch (placement) {
            case 'top':
                if (popoverHeight < boundaries.top - marginThreshold) {
                    arrowCoordinates = {
                        top: boundaries.top,
                        left: (boundaries.left + boundaries.width / 2) - 8
                    }
                }
                break;
            case 'bottom':
                if (popoverHeight < window.innerHeight - boundaries.bottom - marginThreshold) {
                    arrowCoordinates = {
                        top: boundaries?.bottom - 8,
                        left: (boundaries.left + boundaries.width / 2) - 8
                    }
                }
                break;
            case 'left':
                if (popoverWidth < boundaries.left - marginThreshold) {
                    arrowCoordinates = {
                        top: (boundaries.top + boundaries.height / 2) - 8,
                        left: boundaries.left
                    }
                }
                break;
            case 'right':
                if (popoverWidth < window.innerWidth - boundaries.right - marginThreshold) {
                    arrowCoordinates = {
                        top: (boundaries.top + boundaries.height / 2) - 8,
                        left: boundaries.right - 8
                    }
                }
        }
    }

    const capturePopoverDimensions = (element: Element) => {
        const computedStyle = getComputedStyle(element);
        setPopoverHeight(parseInt(computedStyle.height));
        setPopoverWidth(parseInt(computedStyle.width));
    };

    return (
        <>
            <Popover
                onEnter={element => capturePopoverDimensions(element)}
                open={popoverOpen}
                anchorEl={anchorRef.current}
                marginThreshold={marginThreshold}
                anchorOrigin={{
                    vertical: placement === 'top' || placement === 'bottom' ? placement : 'center',
                    horizontal: placement === 'left' || placement === 'right' ? placement : 'center'
                }}
                transformOrigin={{
                    vertical: placement === 'top' ? 'bottom' : placement === 'bottom' ? 'top' : 'center',
                    horizontal: placement === 'right' ? 'left' : placement === 'left' ? 'right' : 'center'
                }}
                onClose={() => setPopoverOpen(false)}>
                {children}
            </Popover>
            {arrowCoordinates &&
                <Fade in={true} timeout={250}>
                    <span className={classes.arrow} style={
                        {
                            top: arrowCoordinates.top,
                            left: arrowCoordinates.left
                        }
                    } />
                </Fade>
            }
        </>
    );
}

interface ArrowStyle {
    placement: string
    arrowColor: string
}

const useStyles = makeStyles<Theme, ArrowStyle>(theme => ({
    arrow: props => ({
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: theme.zIndex.tooltip,
        '&::after': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: props.placement === 'bottom' ?
                '0 8px 8px 8px ' : props.placement === 'top' ? '8px 8px 0 8px' : props.placement === 'left' ? '8px 0 8px 8px' : '8px 8px 8px 0',
            borderColor: props.placement === 'bottom' ?
                `transparent transparent ${props.arrowColor} transparent` : props.placement === 'top' ? `${props.arrowColor} transparent transparent transparent` :
                    props.placement === 'left' ? `transparent transparent transparent ${props.arrowColor}` : `transparent ${props.arrowColor} transparent transparent`
        }
    })
}));