import {
    Button, Grid, TextField, Typography
} from "@material-ui/core";
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { isEmailValid } from '../../utils/StringUtils';
import { FormLink } from '../FormControls/FormLink';

interface Props {
    email: string,
    handleSubmitEmail: () => Promise<boolean>,
    setEmail: React.Dispatch<React.SetStateAction<string>>,
    errorMessage: string,
    loading: boolean
    disableRecaptcha: boolean
    recaptcha: string | null
    setRecaptcha: (s: string | null) => void
};

export const ResetPassword: React.FC<Props> = ({
    email,
    setEmail,
    handleSubmitEmail,
    errorMessage,
    loading,
    disableRecaptcha,
    recaptcha,
    setRecaptcha,
}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ResetPassword', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ResetPassword:${key}`, interpolate);

    const recaptchaRef = useRef<ReCAPTCHA | null>(null)
    const resetCaptcha = () => {
        if (recaptchaRef?.current) {
            recaptchaRef.current.reset()
        }
    }

    const showRecaptcha = !disableRecaptcha && !!process.env.REACT_APP_RECAPTCHA_SITE_ID

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault(); 
        let success = await handleSubmitEmail() 
        if (!success) {
            resetCaptcha()
            setRecaptcha(null)
        }
    }

    return (
        <form id="ResetPasswordForm" className={'Hubspot'} style={{ width: '300px' }} onSubmit={event => onSubmit(event)} >
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('letsResetYourPassword')}
                    </Typography>
                </Grid>
                {errorMessage &&
                    <Grid item>
                        <Typography color="error" variant="subtitle1">{errorMessage}</Typography>
                    </Grid>
                }
                <Grid item>
                    <TextField
                        label={lt('workEmailAddress')}
                        value={email}
                        fullWidth
                        variant="outlined"
                        autoComplete="username"
                        margin="none"
                        onChange={event => setEmail(event.target.value)}
                        helperText={email && !isEmailValid(email) && lt('mustByValidEmail')}
                    />
                </Grid>
                {showRecaptcha &&
                    <Grid item>
                        <ReCAPTCHA size="normal" ref={(r) => recaptchaRef.current = r}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_ID!}
                            onChange={e => setRecaptcha(e)}
                        />
                    </Grid>}
                <Grid item>
                    <Button type="submit"
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        disabled={!email || !isEmailValid(email) || (showRecaptcha && !recaptcha) || loading}>
                        {lt('submitEmail')}
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        <Trans i18nKey="ResetPassword:alreadyHaveYourPassword"
                            components={[<FormLink to="/login" />]}>
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
};

interface translations {
    letsResetYourPassword: string
    workEmailAddress: string
    submitEmail: string
    alreadyHaveYourPassword: string
    mustByValidEmail: string
}

const enTranslations: translations = {
    letsResetYourPassword: 'Let\'s reset your password',
    workEmailAddress: 'Work Email Address',
    submitEmail: 'Submit Email',
    alreadyHaveYourPassword: 'Already have your password? <0>Login</0>',
    mustByValidEmail: 'Must be a valid e-mail address'
}