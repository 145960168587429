import React, { useMemo } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { EnvironmentsData, EnvironmentsQuery, ConsortiumMembershipsData, ConsortiumMembershipsQuery, ContractProjectsData, ContractProjectsQuery, 
    ConsortiumZonesData, ConsortiumZonesQuery, StaticConfigData, StaticConfigQuery, OrganizationData, OrganizationVars, 
    OrganizationQuery, PlanSupports } from '../../models'
import { Typography, Grid, Button } from "@material-ui/core";
import { Overview } from './Overview'
import { Environments } from './Environments'
import { Apps } from './Apps'
import { ConsortiumResourcesVars } from '../../interfaces';
import { Activity } from './Activity'
import { Regions } from './Regions'
import { EmptyState } from '../../components/DisplayWrappers';

export const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NetworkDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NetworkDashboard:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { org_id, consortium_id } = useParams<any>();
    const client = useApolloClient()

    const consortiumVars = {
        consortia_id: consortium_id!
    }

    const {
        loading: environmentsLoading,
        data: {
            environments
        } = { environments: [] }
    } = useQuery<EnvironmentsData, ConsortiumResourcesVars>(EnvironmentsQuery, { 
        variables: consortiumVars,
        fetchPolicy: 'cache-only'
    });

    const { 
        loading: consortiumMembershipsLoading,
        data: { 
            consortiumMemberships
        } = { consortiumMemberships: [] } 
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    const {
        loading: consortiumZonesLoading,
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        loading: regionsLoading,
        data: {
            staticConfig: {
                zoneRegions: regions
            }
        } = { staticConfig: { zoneRegions: [] } }
    } = useQuery<StaticConfigData>(StaticConfigQuery, { 
        fetchPolicy: 'cache-first'
    });

    const {
        loading: contractProjectsLoading,
        data: {
            contractProjects
        } = { contractProjects: [] }
    } = useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: consortiumVars,
        fetchPolicy: 'cache-first'
    });
    

    // direct read from cache
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsMultiRegion = useMemo(() => PlanSupports.multiRegion(organization), [organization])

    const loading = environmentsLoading || consortiumMembershipsLoading || contractProjectsLoading || consortiumZonesLoading

    const createEnvironment = () => history.push(`${pathname}/environments/create/1`)

    if (!environmentsLoading && !environments.length) return (
        <EmptyState imageFile='Empty-Environment.svg' 
            title={lt('createAnEnvironment')} 
            description={lt('emptyDescription')} 
            button={{ text: lt('createEnvironment'), onClick: createEnvironment }}
            documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/quick-start/first-blockchain/' }} />
    )
    
    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('dashboard')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" size="large" onClick={createEnvironment}>
                            {lt('createEnvironment')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container direction="row" spacing={3}>
                    <Grid item container lg={4} md={6} xs={12}>
                        <Overview {...{loading}} {...{environments}} {...{consortiumMemberships}} 
                            {...{contractProjects}} {...{consortiumZones}} {...{supportsMultiRegion}} />
                    </Grid>

                    <Grid item container lg={4} md={6} xs={12}>
                        <Environments loading={environmentsLoading} {...{environments}} />
                    </Grid>

                    <Grid item container lg={4} md={6} xs={12}>
                        <Apps loading={contractProjectsLoading} {...{contractProjects}} />
                    </Grid>

                    {supportsMultiRegion && 
                    <Grid item container lg={4} md={6} xs={12}>
                        <Regions loading={consortiumZonesLoading || regionsLoading} {...{consortiumZones}} {...{regions}} />
                    </Grid>
                    }

                    <Grid item container md={6} xs={12}>
                        <Activity {...{environments}} {...{consortiumMemberships}} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    dashboard: string
    createAnEnvironment: string
    createEnvironment: string
    emptyDescription: string
    emptyDocumentation: string
}
const enTranslations: translations = {
    dashboard: 'Dashboard',
    createAnEnvironment: 'Create an Environment',
    createEnvironment: 'Create Environment',
    emptyDescription: 'Let\'s create some blockchain resources and bring your network to life.',
    emptyDocumentation: 'Documentation'
}