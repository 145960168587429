import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { PaginatedEnvironmentResourcesVars, EnvironmentResourceVars } from '../../interfaces';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { Grid, Typography, CircularProgress, TablePagination, makeStyles } from '@material-ui/core';
import { DisplayTable, EmptyState } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { LedgerContract, LedgerContractQuery, LedgerContractData, TokenContractsData, TokenContractsQuery } from '../../models';
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TOKENS_PATH } from '../../components/TokenExplorerNav/TokenExplorerNav';
import Jazzicon from 'react-jazzicon';
import { jsNumberForAddress } from '../../utils/StringUtils';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_TOKENS_PATH } from '../../components/MainNav/SideNavs/DigitalAssets';

const LIMITLIST = [10, 25];

export const Tokens = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenExplorerTokens', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenExplorerTokens:${key}`, interpolate)

    const [errorMessage, setErrorMessasge] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [searchActive, setSearchActive] = useState(false);

    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();
    const classes = useStyles();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const location = useLocation();
    const param = new URLSearchParams(location.search)
    const page = param.get('page');
    const urlLimit = param.get('limit');

    useEffect(()=> {
        if (page && parseInt(page)) {
            const p = parseInt(page);
            if ( p === 0) {
                setCurrentPage(p);
            } else {
                setCurrentPage(p - 1);
            }
        } 
    }, [page]);

    useEffect(() => {
        if(urlLimit) {
            const l = parseInt(urlLimit);
            if(LIMITLIST.includes(l)) {
                setLimit(l);
            } else {
                setLimit(LIMITLIST[0]);
            }
        } 
    }, [urlLimit])

    const updatePage = (page: number, limit: number) => {
        history.push({
            search: `?page=${page + 1}&limit=${limit}`
        })
    }

    const {
        refetch,
        loading,
        data: {
            tokenContracts: {
                totalCount,
                contracts
            }
        } = {tokenContracts: {
            totalCount: -1,
            contracts: []
        }}
    } = useQuery<TokenContractsData, PaginatedEnvironmentResourcesVars>(TokenContractsQuery, {
        variables: {
            ...environmentVariables,
            limit,
            start: currentPage * limit,
        },
        fetchPolicy: 'cache-and-network'
    });

    const [searchContract, {
        loading: searchLoading,
        data: {
            ledgerContract
        } = {ledgerContract: null}
    }] = useLazyQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        onError: () => setErrorMessasge(lt('searchNotFound', {search: searchInput}))
    });

    if (loading && contracts.length === 0) return <CircularProgress />;
    
    const data = ledgerContract && searchActive && !searchLoading ? [ledgerContract] : contracts;
    
    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        updatePage(page, limit);
    }

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updatePage(0, parseInt(event.target.value))
    }

    const columns = [lt('name'), lt('type'), lt('address')];

    const returnType = (token: LedgerContract) => {
        if (token.isERC20) return lt('ERC20');
        if (token.isERC721) return lt('ERC721');
        return ''
    };

    const getNameElement = (token: LedgerContract) => (
        <Grid item container spacing={1}  alignItems="center">
            <Grid item className={classes.icon}>
                <Jazzicon diameter={20} seed={jsNumberForAddress(token.address)} />
            </Grid>
            <Grid item>
                <Typography variant="body2">{token.tokenName ?? ''}</Typography>
            </Grid>
        </Grid>
    )

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const records: DisplayTableRecord[] = (data as LedgerContract[]).map((contract, index) => ({
        key: `tokens-${index}`,
        columns: [{
            value: getNameElement(contract),
        },{
            value: returnType(contract)
        }, {
            value: <ShortenedHash address={contract.address ?? ''} />
        }],
        onClick: () => history.push(`${basePath}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TOKENS_PATH}/${contract.address}`)
    }));

    if (records.length === 0) {
        return <EmptyState imageFile='Empty-Token.svg' 
                    title={lt('noTokens')} 
                    description={lt('emptyDescription')} 
                    button={{ text: lt('createToken'), onClick: () => history.push(`${basePath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}`) }}
                    documentation={{ text: lt('documentation'), link: 'https://docs.kaleido.io/kaleido-services/token-factory/' }} />
    } 

    const onSubmitSearch = async (searchInput: string) => {
        setSearchInput(searchInput);
        if(searchInput) {
            await searchContract({
                variables: {
                    ...environmentVariables,
                    id: searchInput
                }
            })
        }
    };

    const onRefreshClick = () =>{
        updatePage(0, limit);
        refetch();
    }

    const toolBarOptions = {
        search: {
            active: true,
            onSearch: onSubmitSearch,
            setSearchActive,
            label: lt('searchLabel')
        },
        refresh: {
            active: true,
            onRefresh: onRefreshClick
        }
    }

    const Pagination = <TablePagination 
                            rowsPerPageOptions={LIMITLIST} 
                            component="div" 
                            count={totalCount === -1 ? (contracts.length < limit ? (currentPage) * limit + contracts.length : -1) : totalCount} 
                            rowsPerPage={limit} 
                            onChangeRowsPerPage={onChangeRowsPerPage} 
                            onPageChange={onChangePage} 
                            page={currentPage} 
                            nextIconButtonProps={{disabled: loading || (totalCount !== -1 ? ((currentPage + 1) * limit >= totalCount) : (contracts.length < limit))}}
                            backIconButtonProps={{disabled: loading || currentPage <= 0}} />

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge}/>
            <Grid container direction="column" spacing={3}>
                <Grid item container wrap="nowrap" justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">{lt('header')}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <DisplayTable loading={loading} {...{toolBarOptions}} header={lt('header')} columnHeaders={columns} {...{records}} actionFooter={ledgerContract && searchActive ? undefined : Pagination} />
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    header: string,
    type: string,
    txCount: string,
    creator: string,
    searchLabel: string,
    searchNotFound: string,
    name: string
    address: string
    ERC20: string
    ERC721: string
    tokenZkp: string
    noTokens: string
    emptyDescription: string
    createToken: string
    documentation: string
}

const enTranslations: translations = {
    documentation: 'Documentation',
    noTokens: 'No Token Contracts',
    createToken: 'Create Token',
    emptyDescription: 'Build your own token smart contract using the Kaleido Gateway API templates and understand the fundamental essentials underpinning token specifications, as well as the key differences between token models.',
    header: 'Tokens',
    type: 'Type',
    txCount: 'Tx Count',
    creator: 'Deployed By',
    searchLabel: 'Enter Token Address',
    searchNotFound: 'Token "{{search}}" Not Found',
    name: 'Token Name',
    address: 'Address',
    ERC20: 'ERC20',
    ERC721: 'ERC721',
    tokenZkp: 'Token ZKP'
}

const useStyles = makeStyles(() => ({
    icon: {
        display: 'flex',
        alignSelf: 'center'
    }
}))