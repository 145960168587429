import { useMutation } from "@apollo/client";
import {
    CreateServiceData,
    CreateServiceVars,
    CreateServiceMutation,
} from "../models";
import { useCallback } from "react";

interface Props {
    consortiumId: string;
    membershipId: string;
    environmentId: string;
    name: string;
    service: string;
}

export const useProvisionService = ({
    consortiumId,
    membershipId,
    environmentId,
    name,
    service,
}: Props) => {
    const [createService, { loading }] = useMutation<
        CreateServiceData,
        CreateServiceVars
    >(CreateServiceMutation);

    const provisionService = useCallback(async () => {
        return await createService({
            variables: {
                consortia_id: consortiumId,
                environment_id: environmentId,
                service: {
                    service: service,
                    membership_id: membershipId,
                    name: name,
                },
            },
        });
    }, [
        createService,
        name,
        consortiumId,
        service,
        membershipId,
        environmentId,
    ]);

    return { provisionService, loading };
};
