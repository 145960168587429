import { useApolloClient, useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper } from '../../../components/DialogWrappers';
import { NETWORK_GOVERNANCE_INVITATIONS_PATH, NETWORK_GOVERNANCE_PATH } from "../../../components/MainNav/SideNavs/Governance";
import { PayWallBanner } from "../../../components/PayWall/PayWallBanner";
import { ConsortiumResourcesVars, CreateStepProps } from '../../../interfaces';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports } from "../../../models";
import { Step1Help } from './Step1Help';
import { AlertBanner } from "../../../components/Banners/AlertBanner";

interface Props extends CreateStepProps {
    orgName: string
    email: string
};

export const Step1 = ({ orgName, email, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GovernanceInviteOrganizationCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceInviteOrganizationCreateStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: {
            consortia_id: consortium_id
        },
        fetchPolicy: 'cache-only'
    });

    // direct read from cache
    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const planSupportsInvitations = PlanSupports.sendingInvitations(organization) 
    const membershipSupportsInvitations = consortiumMemberships.some(m => m.is_mine && m.permissions.invite_orgs)
    const canInvite = planSupportsInvitations && membershipSupportsInvitations
   
    const save = async () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_INVITATIONS_PATH}/create/2`, {
            orgName, 
            email
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {lt('whatStays')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('whatStaysDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {lt('whatChanges')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('whatChangesDescription')}
                </Typography>
            </Grid>

            {!planSupportsInvitations && 
            <Grid item>
                <PayWallBanner description={lt('planPaywall')} />
            </Grid> 
            }

            {!membershipSupportsInvitations && 
            <Grid item>
                <AlertBanner description={lt('membershipPaywall')} />
            </Grid> 
            }
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={!canInvite} onNext={save} isFirstStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string
    headerDescription: string
    whatStays: string
    whatStaysDescription: string
    whatChanges: string
    whatChangesDescription: string
    planPaywall: string
    membershipPaywall: string
}
const enTranslations: translations = {
    header: 'Invite Organization',
    headerDescription: 'Congratulations, this network is about to embark on the next stage in it\'s blockchain lifecycle - decentralization.',
    whatStays: 'What stays the same?',
    whatStaysDescription: 'All of your organization\'s runtimes, memberships and app credentials will remain controlled by you.',
    whatChanges: 'What changes?',
    whatChangesDescription: 'The network, and all environments inside it are now shared, therefore nobody can delete them. While you can no longer delete these resources you have the option to leave. When the last member leaves a network, or the last node is deleted in an environment, these resources are automatically cleaned up.',
    planPaywall: 'A Business or Enterprise Plan is required to invite external organizations.',
    membershipPaywall: 'You do not have the permission to invite external organizations.'
}