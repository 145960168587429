import React, { useState, SetStateAction, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
    AddNewProfileEntryData,
    AddNewEntryVars,
    AddProfileEntryMutation,
    IDRegistryProfilePropertiesQuery,
} from "../../../models";
import { Grid, TextField, Typography } from "@material-ui/core";
import {
    FormDialog,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../../components/DialogWrappers";
import { useTranslation } from "react-i18next";

export interface Props {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    serviceId: string;
    profileAddress: string;
    membershipId: string;
    idRegistryMembershipOrgOwner: string;
    profileId: string;
}

export const AddNewEntryProfile = ({
    open,
    setOpen,
    serviceId,
    profileAddress,
    membershipId,
    idRegistryMembershipOrgOwner,
    profileId,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "AddNewEntryProfile", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`AddNewEntryProfile:${key}`, interpolate);

    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [addNewEntry] = useMutation<AddNewProfileEntryData, AddNewEntryVars>(
        AddProfileEntryMutation
    );

    useEffect(() => {
        if (open) {
            setKey("");
            setValue("");
        }
    }, [open]);

    const onAddNewEntrySubmit = async () => {
        try {
            setLoading(true);
            await addNewEntry({
                variables: {
                    service_id: serviceId,
                    profile_id: profileId,
                    profileEntry: {
                        key_name: key,
                        value,
                        profile_address: profileAddress,
                        membership_id: membershipId,
                    },
                },
                refetchQueries: [
                    {
                        query: IDRegistryProfilePropertiesQuery,
                        variables: {
                            service_id: serviceId,
                            owner: idRegistryMembershipOrgOwner,
                        },
                    },
                ],
            });
        } catch (err) {
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setLoading(false);
        }
    };

    const createKeyContent = (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextField
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label={lt("key")}
                />
            </Grid>
            <Grid item>
                <TextField
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label={lt("value")}
                />
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {lt("overrideExplanation")}
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            <MessageSnackbar {...{ message }} {...{ setMessage }} />
            <FormDialog
                {...{ open }}
                {...{ setOpen }}
                saveDisabled={loading || !value || !key}
                cancelDisabled={loading}
                closeDialogAfterSave
                header={lt("addNewProfileKey")}
                controlsWrapper={createKeyContent}
                saving={loading}
                onSave={onAddNewEntrySubmit}
            />
        </>
    );
};

interface translations {
    addNewProfileKey: string;
    key: string;
    value: string;
    overrideExplanation: string;
}

const enTranslations: translations = {
    addNewProfileKey: "Add New Profile Key",
    key: "Key",
    value: "Value",
    overrideExplanation:
        "NOTE: This will create a new version for the value if the key already exists",
};
