import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { IDRegistryOrgsQuery, IDRegistryOrgsData } from '../../../../models/idRegistry';
import { ProcessItem } from '../../../../components/DisplayWrappers';
import { useTranslation, Trans } from 'react-i18next';
import { FormLink } from '../../../../components/FormControls/FormLink';
import { ServiceResourcesVars } from '../../../../interfaces';

interface Props {
    isCertReg: boolean
    membershipId: string,
    setIsCertReg: React.Dispatch<React.SetStateAction<boolean>>,
    idRegistryId: string
}

export const ExternalInfo = ({ isCertReg, membershipId, setIsCertReg, idRegistryId }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ExternalInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ExternalInfo:${key}`, interpolate);

    const helpPath = 'https://docs.kaleido.io/kaleido-services/registry/cli-usage/';

    const message = (
        <Trans i18nKey="ExternalInfo:description"
            components={[<FormLink onClick={() => window.open(helpPath)} />]}>
        </Trans>
    )

    const {
        loading: fetchingOrgs,
        data: {
            idRegistryOrgs
        } = { idRegistryOrgs: undefined }
    } = useQuery<IDRegistryOrgsData, ServiceResourcesVars>(IDRegistryOrgsQuery, {
        variables: {
            service_id: idRegistryId
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (idRegistryOrgs) {
            setIsCertReg(idRegistryOrgs.find(org => org.name.endsWith(membershipId)) !== undefined);
        }
    }, [idRegistryOrgs, setIsCertReg, membershipId]);

    return (
        <ProcessItem 
            isDone={isCertReg} 
            loading={fetchingOrgs} 
            isInfo
            title={lt('externallySignedCertificate')} 
            statusMessage={message} />
    )
};

interface translations {
    externallySignedCertificate: string
    description: string
}
const enTranslations: translations = {
    externallySignedCertificate: 'Register Membership Identity On-Chain',
    description: 'Your membership requires externally signed certificate. Please go to the following link where you will find more information regarding this process. <0>Learn More</0>'
}