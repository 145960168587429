import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TokenContractsData, TokenContractsVars, TokenContractsQuery , TokensTransfersQuery, TokensTransfersData} from '../../models';
import { useQuery } from '@apollo/client';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { DisplayCard } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { LatestContracts } from './LatestContracts';
import { PaginatedEnvironmentResourcesVars } from '../../interfaces';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import { StatsQuery, StatsData, StatsVars } from '../../models/ledger';
import { TokensKpi } from './TokensKpi';
import { TokensPie } from './TokensPie';
import { TransactionsTable, TransactionsColumns } from '../../components/Transactions/TransactionsTable';


export const Dashboard = () => {
    const pollingInterval = 4000;
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenExplorer', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenExplorer:${key}`, interpolate);

    const { consortium_id, environment_id } = useParams<any>();

    const [ suspendTokensUpdates, setSuspendTokensUpdates ] = useState(false);
    const [ suspendTransfersUpdates, setSuspendTransfersUpdates ] = useState(false);

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading: statsLoading,
        data: {
            stats
        } = { stats: null}
    } = useQuery<StatsData, StatsVars>(StatsQuery, {
        variables: {
            ...environmentVariables,
            since: "0"
        },
        pollInterval: pollingInterval
    })

    const {
        startPolling: startTokenPolling,
        stopPolling: stopTokenPolling,
        loading: tokensLoading,
        data: {
            tokenContracts: {
                totalCount: totalContracts,
                contracts
            }
        } = {tokenContracts: { totalCount: 0, contracts: []}}
    } = useQuery<TokenContractsData, TokenContractsVars>(TokenContractsQuery, {
        variables: {
            ...environmentVariables,
            limit: 5,
        },
        pollInterval: pollingInterval,
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        suspendTokensUpdates? stopTokenPolling() : startTokenPolling(pollingInterval);
    }, [suspendTokensUpdates, startTokenPolling, stopTokenPolling]);

    const {
        startPolling: startTransfersPolling,
        stopPolling: stopTransfersPolling,
        loading: transactionsLoading,
        data: {
            allTokenTransfers: {
                totalCount: totalTransactions,
                transactions
            }
        } = {allTokenTransfers: { totalCount: 0, transactions: [] }}
    } = useQuery<TokensTransfersData, PaginatedEnvironmentResourcesVars>(TokensTransfersQuery, {
        variables: {
            ...environmentVariables,
            limit: 5,
        },
        pollInterval: pollingInterval,
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        suspendTransfersUpdates? stopTransfersPolling() : startTransfersPolling(pollingInterval);
    }, [suspendTransfersUpdates, startTransfersPolling, stopTransfersPolling]);

    if ((!transactions && transactionsLoading) || (tokensLoading && !contracts) || (statsLoading && !stats) ) return <CircularProgress />;

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('header'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/token-explorer/')
        },
        {
            icon: <DescriptionIcon />,
            title: lt('ledgerApi'),
            value: lt('apiDocs'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://api.kaleido.io/platform.html#tag/Ledger') 
        }
    ];

    const latestTransactionsColumns: TransactionsColumns[] = ['token', 'from', 'to', 'dateMined' ];

    return (
        <Grid container direction="column" spacing={3} alignItems="stretch" wrap="nowrap">
            <Grid item xs={12}>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TokensKpi totalTransfers={totalTransactions} totalTokens={totalContracts} stats={stats} />
            </Grid>
            <Grid item container spacing={3} justify="space-between">
                <Grid item container xs={12} md={8}>
                    <LatestContracts tokens={contracts} setSuspendUpdates={setSuspendTokensUpdates} />
                </Grid>
                <Grid item container wrap="nowrap"  xs={12} md={4}>
                    <TokensPie erc20Tokens={stats?.erc20TokenContractCount ?? 0} erc721Tokens={stats?.erc721TokenContractCount ?? 0} />
                </Grid>
            </Grid>
            <Grid item container spacing={3} justify="space-between">
                <Grid item container xs={12} md={8}>
                    <TransactionsTable isLatest columns={latestTransactionsColumns}  {...{transactions}} isToken setSuspendUpdates={setSuspendTransfersUpdates} />
                </Grid>
                <Grid item container xs={12} md={4}>
                    <DisplayCard header={lt('documentation')} itemList={DocumentationList} />
                </Grid>
            </Grid>
        </Grid>
    )
}

interface translations {
    header: string,
    documentation: string,
    apiDocs: string
    ledgerApi: string
    latestTransfers: string
}
const enTranslations: translations = {
    header: 'Token Explorer',
    documentation: 'Documentation',
    apiDocs: 'API Docs',
    ledgerApi: 'Ledger API',
    latestTransfers: 'Latest Transfers'
}

