import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Divider, Button, IconButton, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import KeyIcon from 'mdi-react/AccountKeyOutlineIcon';
import KeyLinkIcon from 'mdi-react/KeyLinkIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import ShieldAccountIcon from 'mdi-react/ShieldAccountIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { EditableSettings } from '../../components/DisplaySettings'
import { BAFSaveWithUndo, BAFPolicyAppCredsOrJWT } from './BAFPolicySchema';
import { SECURITY_BAF_MAPPINGS_PATH } from '../../components/MainNav/SideNavs/Security';

interface Props {
    loading: boolean,
    save: BAFSaveWithUndo,
    type: 'jwt' | 'appcreds',
    authSection: BAFPolicyAppCredsOrJWT,
    basePath: string,
    rulesetNames: string[],
}

export const BAFPolicyAuthSection: React.FC<Props> = ({ type, save, loading, authSection, basePath, rulesetNames }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFPolicyAuthSection', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFPolicyAuthSection:${key}`, interpolate)

    const classes = useStyles();

    const history = useHistory();

    const [mappings, setMappings] = useState(authSection.mappings); // Below logic must use mappings

    const trueFalse = (b? : boolean) => b ? lt('true') : lt('false')

    const actionsList = [
        {
            icon: type === 'appcreds' ? <KeyIcon /> : <ShieldAccountIcon/>,
            title: type === 'appcreds' ? lt('appCreds') : lt('jwt'),
            description: type === 'appcreds' ? lt('appCredsDescription') : lt('jwtDescription'),
            buttonLabel: lt('edit'),
            buttonDisabled: loading,
            buttonTestData: `button_edit${type}`,
            value: authSection.enabled ? lt('enabled') : lt('disabled'),
            action: () => history.push(`${basePath}/${type}/1`),
            extraButtons: [] as JSX.Element[],
            subvalues: authSection.enabled ? {
                [lt('bearer')]: [trueFalse(authSection.bearer)],
                [lt('query')]: [authSection.query || ''],
                [lt('header')]: [authSection.header || ''],
                [lt('cookie')]: [authSection.cookie || ''],
                [type === 'appcreds' ? lt('basicAuth') : '']: [type === 'appcreds' ? trueFalse(authSection.basicAuth) : ''],
            } : undefined,
        }
    ]

    const move = (upDown: 'up' | 'down', i: number) => {
        const newMappings = [...mappings];
        if (upDown === 'up') {
            const swap = newMappings[i-1];
            newMappings[i-1] = newMappings[i];
            newMappings[i] = swap;
        } else {
            const swap = newMappings[i+1];
            newMappings[i+1] = newMappings[i];
            newMappings[i] = swap;
        }
        setMappings(newMappings);
        // async save, but will re-store if we fail
        save(authSection, 'mappings', newMappings, setMappings);
    }

    const deleteMapping = (i: number) => {
        const newMappings = mappings.splice(0,i).concat(mappings.splice(i+1));
        setMappings(newMappings);
        save(authSection, 'mappings', newMappings, setMappings);
    }

    if (mappings?.length) {
        mappings.forEach((m,i) => {
            const subvalues : { [x: string]: string[] } = {};
            if (m.templated) {
                subvalues[lt('templated')] = [lt('true')]
            }
            for (const match in m.claims) {
                let name = type === 'appcreds' && match === 'id' ? lt('idMatch') : lt('match', {match});
                subvalues[name] = [JSON.stringify(m.claims[match])];
            }
            const extraButtons : JSX.Element[] = [];
            if (mappings?.length > 1) {
                if (i !== 0) extraButtons.push(
                    <IconButton key="close" onClick={() => move('up', i)}>
                        <ArrowUpIcon />
                    </IconButton>
                );
                if (i !== (mappings?.length - 1)) extraButtons.push(
                    <IconButton key="close" onClick={() => move('down', i)}>
                        <ArrowDownIcon />
                    </IconButton>
                );
            }
            extraButtons.push(
                <Tooltip title={lt('deleteMapping')}>
                    <span>
                        <IconButton key="delete" disabled={loading}  onClick={() => deleteMapping(i)}>
                            <DeleteIcon/>
                        </IconButton>
                    </span>
                </Tooltip>
            );
            actionsList.push({
                icon: <KeyLinkIcon/>,
                title: m.ruleset,
                description: m.comment || '',
                buttonLabel: lt('edit'),
                buttonDisabled: loading,
                buttonTestData: `button_edit${m.ruleset}`,
                value: '',
                extraButtons,
                action: () => history.push(`${basePath}/${type}/${SECURITY_BAF_MAPPINGS_PATH}/${i}/1`),
                subvalues,
            })
        })
    }



    return (
        <>
            <Grid item xs={12} sm={12} md={8}>
                <EditableSettings
                    header={type === 'appcreds' ? lt('authenticationAppCreds') : lt('authenticationJWT')}
                    {...{actionsList}}>
                    <Divider />
                    <Grid item container className={classes.itemContainer} alignItems="center" justify="space-between">
                        <Grid item>
                            <Tooltip title={!rulesetNames.length ? lt('noRulesets') : ''}>
                                <span>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        startIcon={<PlusIcon />}
                                        disabled={loading || !rulesetNames.length}
                                        onClick={() => history.push(`${basePath}/${type}/${SECURITY_BAF_MAPPINGS_PATH}/${mappings ? mappings.length : 0}/1`)}
                                    >{type === 'appcreds' ? lt('addAppCredMapping') : lt('addJWTMapping')}</Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </EditableSettings>
            </Grid>
    </>
    )
};

interface translations {
    edit: string,
    deleteMapping: string,
    noRulesets: string,

    enabled: string,
    disabled: string,
    true: string,
    false: string,
    templated: string,
    match: string,
    idMatch: string,
    ruleset: string,

    basicAuth: string,
    bearer: string,
    query: string,
    header: string,
    cookie: string,

    authenticationAppCreds: string,
    appCreds: string,
    appCredsDescription: string,
    addAppCredMapping: string,

    authenticationJWT: string,
    jwt: string,
    jwtDescription: string,
    addJWTMapping: string,
}
const enTranslations: translations = {
    edit: "Edit",
    deleteMapping: "Delete Mapping",
    noRulesets: "You must define a one ruleset first",

    enabled: "enabled",
    disabled: "disabled",
    true: "True",
    false: "False",
    templated: "Templated",
    match: "Match on \"{{match}}\"",
    idMatch: "Match Application Credential ID",
    ruleset: "Mapping {{i}}",

    basicAuth: "Basic Auth",
    bearer: "Bearer",
    query: "Query",
    header: "Header",
    cookie: "Cookie",

    authenticationAppCreds: "Authentication - API Keys",
    appCreds: "Application Credentials",
    appCredsDescription: "Strong generated credentials used as API Keys or HTTPS Basic Auth",
    addAppCredMapping: "Add Credential Mapping",

    authenticationJWT: "Authentication - Access Tokens",
    jwt: "JSON Web Token Verification",
    jwtDescription: "Validate JWTs generated via Oauth 2.0, OpenID Connect, or in your App",
    addJWTMapping: "Add JWT Mapping",

}

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        padding: theme.spacing(3),
    },
    missingComment:  {
        fontStyle: "italic",
    },
}));
