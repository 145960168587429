import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
import { EnvironmentData, EnvironmentQuery, GatewayAPIData, GatewayAPIQuery } from '../../models'
import { Grid, CircularProgress, Button, Typography } from "@material-ui/core";
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings'
import { ConsortiumResourceVars, EnvironmentResourceVars } from '../../interfaces'
import { GatewayAPIInstances } from './GatewayAPIInstances'
import { NETWORK_CONTRACTS_PATH, NETWORK_COMPILED_CONTRACTS_PATH } from '../../components/MainNav/ConsortiumWrapper'
import { FormLink } from '../../components/FormControls/FormLink';
import { APPS_GATEWAY_VIEW_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';
import { CorDappInstances } from './CorDappInstances';

// shared for Eth & Corda

export const GatewayAPI: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GatewayAPI', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GatewayAPI:${key}`, interpolate)

    const { pathname } = useLocation()
    const { consortium_id, environment_id, gateway_api_id } = useParams<any>();
    const history = useHistory();

    const gatewayAPIsRedirect = pathname.replace(`/${gateway_api_id}`, '')

    const gatewayAPIVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: gateway_api_id!
    }

    const { 
        data: { 
            environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;

    const {
        loading,
        data: {
            gatewayAPI
        } = { gatewayAPI: null }
    } = useQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, { 
        variables: gatewayAPIVars, 
        fetchPolicy: 'cache-first' 
    });

    // if the gatewayAPI was deleted, then you need to get kicked out of the detail view.
    if (!loading && !gatewayAPI) return (<Redirect to={gatewayAPIsRedirect} />)
    if (!gatewayAPI) return <CircularProgress />

    const splitPathname = pathname.split('/')
    const baseContractsPath = `${splitPathname.slice(0, splitPathname.indexOf(consortium_id!) + 1).join('/')}/${NETWORK_CONTRACTS_PATH}`
    const projectRedirectPath = `${baseContractsPath}/${gatewayAPI.consortiaContractId}`
    const compilationRedirectPath = `${projectRedirectPath}/${NETWORK_COMPILED_CONTRACTS_PATH}/${gatewayAPI._id}`

    const makeLink = (path: string, displayValue: string) => (
        <Grid item container>
            <FormLink to={path}>
                {displayValue}
            </FormLink>
        </Grid>
    );

    const copyableList: CopyableSetting[] = [
        { title: lt('id'), displayValue: gatewayAPI._id }
    ]

    if (!isCorda) {
        copyableList.push(
            { title: lt('endpoint'), displayValue: gatewayAPI.endpoint },
            { title: lt('gatewayAPIPath'), displayValue: `/gateways/${gatewayAPI.endpoint}` },
        )
    }

    copyableList.push(
        { title: lt('contractName'), displayValue: makeLink(projectRedirectPath, gatewayAPI.consortiaContractName || ''), disableCopy: true },
        { title: lt('compiledContractDescription'), displayValue: makeLink(compilationRedirectPath, gatewayAPI.description || ''), disableCopy: true },
    );

    if (gatewayAPI.createdAt) {
        copyableList.push({ title: lt('promotedAt'), displayValue: new Date(gatewayAPI.createdAt).toLocaleString(), disableCopy: true })
    }

    const actionBar = (
        <Grid item container spacing={3} direction="row">
            {!isCorda && <Grid item>
                <Button color="primary" size="large" variant="contained" onClick={() => history.push(`${pathname}/${APPS_GATEWAY_VIEW_PATH}`)}>
                    {lt('viewGatewayAPI')}
                </Button>
            </Grid>}
            {!isCorda && <Grid item>
                <Button color="primary" size="large" variant="contained" onClick={() => history.push(`${pathname}/${APPS_GATEWAY_VIEW_PATH}`, {factory: true})}>
                    {lt('deployInstance')}
                </Button>
            </Grid>}
        </Grid>
    )

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {isCorda ? lt('cordappDetails') : lt('gatewayAPIDetails')}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container direction="row" spacing={3}>
                    {loading && !gatewayAPI ? 
                    <CircularProgress /> : 
                    <>
                        <Grid item container>
                            <CopyableSettings header={isCorda ? lt('cordappInformation'):lt('gatewayAPIInformation')} {...{copyableList}} {...{actionBar}} />
                        </Grid>
                        { (isCorda && environment) &&
                        <Grid item container>
                            <CorDappInstances {...{environment}}/>
                        </Grid> } 
                        { !isCorda && 
                        <Grid item container>
                            <GatewayAPIInstances />
                        </Grid> }
                    </>
                    }
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    gatewayAPIDetails: string,
    gatewayAPIInformation: string,
    id: string,
    description: string,
    endpoint: string,
    gatewayAPIPath: string,
    contractName: string,
    cordappDetails: string,
    cordappInformation: string,
    compiledContractDescription: string,
    promotedAt: string,
    viewGatewayAPI: string,
    deployInstance: string,
}
const enTranslations: translations = {
    gatewayAPIDetails: 'Smart Contract Details',
    gatewayAPIInformation: 'Smart Contract information',
    id: 'ID',
    description: 'Description',
    endpoint: 'Gateway API endpoint',
    gatewayAPIPath: 'Gateway API full path',
    contractName: 'App name',
    cordappDetails: 'CorDapp Details',
    cordappInformation: 'CorDapp Information',
    compiledContractDescription: 'Version description',
    promotedAt: 'Promoted at',
    viewGatewayAPI: 'View Gateway API',
    deployInstance: 'Deploy instance',
}