import React, { useState } from "react";
import { App2AppDestination, Service, CreateApp2AppDestinationData, CreateApp2AppDestinationVars, CreateApp2AppDestinationMutation, App2AppDestinationsData } from "../../../models";
import { DisplayGridWrapper } from "../../../components/DisplayWrappers";
import { useTranslation } from "react-i18next";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { CopyableSettings, CopyableSetting } from "../../../components/DisplaySettings";
import { Dotdotdot } from "../../../components";
import BookOpenOutlineIcon from 'mdi-react/BookOpenOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import { useHistory, useParams } from "react-router-dom";
import { ORGS_PATH } from "../../../components/ManageOrgNav/ManageOrgNav";
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH } from "../../../components/MainNav/SideNavs/AddressBook";
import { MEMBERSHIP_DESTINATIONS_PATH } from "../../../components/MembershipNav/MembershipNav";
import { DeleteApp2AppDestinationDialog } from "./DeleteDestination";
import { useMutation, ApolloQueryResult } from "@apollo/client";
import { FormDialog, MessageSnackbar, ErrorSnackbarCatcher } from "../../../components/DialogWrappers";
import { ExtManagedDestInstructions } from "./CreateDestination/ExtManagedDestInstructions";
import { ServiceResourcesVars } from "../../../interfaces";
import { KldManagedDestInstructions } from "./CreateDestination/KldManagedDestInstructions";

interface Props {
    app2appService: Service
    app2appDestination: App2AppDestination
    refetch: (variables?: ServiceResourcesVars | undefined) => Promise<ApolloQueryResult<App2AppDestinationsData>>
}

export const App2AppDestinationDetails: React.FC<Props> = ({ app2appService, app2appDestination, refetch }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'App2AppDestinationDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`App2AppDestinationDetails:${key}`, interpolate)
    const history = useHistory();
    const classes = useStyles();
    const { org_id, consortium_id, environment_id, service_id } = useParams<any>();
    const [deleteDestinationOpen, setDeleteDestinationOpen] = useState(false);
    const [instructionsVisible, setInstructionsVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [createApp2AppDestination] = useMutation<CreateApp2AppDestinationData, CreateApp2AppDestinationVars>(CreateApp2AppDestinationMutation);

    let menuItems = [
        {
            name: lt('deleteDestination'),
            icon: <DeleteOutlineIcon />,
            action: () => setDeleteDestinationOpen(true)
        }
    ];

    if (app2appDestination.setup_complete) {
        menuItems.unshift({
            name: lt('viewInAddressBook'),
            icon: <BookOpenOutlineIcon />,
            action: () => history.push(`/${ORGS_PATH}/${org_id}/consortia/${consortium_id}/environments/${environment_id}` +
                `/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${app2appService.membership_id}/` +
                `${MEMBERSHIP_DESTINATIONS_PATH}/${encodeURIComponent(app2appDestination.uri)}`)
        });
    }

    const copyableList: CopyableSetting[] = [
        {
            title: lt('uri'),
            displayValue: app2appDestination.uri
        },
        {
            title: lt('type'),
            disableCopy: true,
            displayValue: lt(app2appDestination.kaleido_managed ? 'kaleidoManaged' : 'externallyManaged')
        },
        {
            title: lt('status'),
            disableCopy: true,
            displayValue: lt(app2appDestination.setup_complete ? 'ready' : 'setupIncomplete')
        }
    ];

    const actionBar = (
        <Grid container alignItems="center" wrap="nowrap">
            {!app2appDestination.setup_complete &&
                <Grid item className={classes.completeSetupActionContainer}>
                    <Button variant="outlined" onClick={() => handleCompleteSetup()}>{lt('completeSetup')}</Button>
                </Grid>}
            <Grid>
                <Dotdotdot menuItems={menuItems} />
            </Grid>
        </Grid>
    );

    const displayGrid = (
        <CopyableSettings copyableList={copyableList} />
    );

    const handleCompleteSetup = () => {
        if (app2appDestination.kaleido_managed) {
            createApp2AppDestination({
                variables: { service_id, destination: { name: app2appDestination.name, kaleido_managed: app2appDestination.kaleido_managed } }
            }).then(result => {
                if (result.data?.createApp2AppDestination.result === 'Destination setup incomplete.') {
                    setInstructionsVisible(true);
                } else {
                    refetch();
                }
            }).catch(err => {
                ErrorSnackbarCatcher(err, setErrorMessage);
            });
        } else {
            setInstructionsVisible(true);
        }
    };

    return (
        <>
            <DisplayGridWrapper
                header={app2appDestination.name}
                actionBar={actionBar}
                displayGrid={displayGrid} />
            <DeleteApp2AppDestinationDialog destinationName={app2appDestination.name} open={deleteDestinationOpen} setOpen={setDeleteDestinationOpen} />
            <FormDialog
                width="md"
                hideSave={true}
                cancelText={lt('close')}
                header={lt('completeSetup')}
                open={instructionsVisible}
                setOpen={setInstructionsVisible}
                controlsWrapper={app2appDestination.kaleido_managed ? <KldManagedDestInstructions destinationName={app2appDestination.name} /> :
                    <ExtManagedDestInstructions destinationName={app2appDestination.name} />}
            />
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessage}
            />
        </>
    );
};

const useStyles = makeStyles(theme => ({
    completeSetupActionContainer: {
        marginRight: theme.spacing(3)
    }
}));

interface translations {
    kaleidoManaged: string
    externallyManaged: string
    connect: string
    completeSetup: string
    viewInAddressBook: string
    deleteDestination: string
    uri: string
    type: string
    status: string
    ready: string
    setupIncomplete: string
    close: string
}
const enTranslations: translations = {
    kaleidoManaged: 'Kaleido managed',
    externallyManaged: 'Externally managed',
    connect: 'Connect',
    completeSetup: 'Complete Setup',
    viewInAddressBook: 'View in Address book',
    deleteDestination: 'Delete Destination',
    uri: 'URI',
    type: 'Type',
    status: 'Status',
    ready: 'Ready',
    setupIncomplete: 'Setup Incomplete',
    close: 'Close'
}