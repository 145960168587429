import React from 'react';
import {
    makeStyles,
    Grid,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import BallotOutlineIcon from 'mdi-react/BallotOutlineIcon';
import BookshelfIcon from 'mdi-react/BookshelfIcon';
import DocsIcon from 'mdi-react/BookOpenVariantIcon';
import CodeBracesBoxIcon from 'mdi-react/CodeBracesBoxIcon';
import FileDocumentBoxCheckOutlineIcon from 'mdi-react/FileDocumentBoxCheckOutlineIcon';
import { useTranslation } from 'react-i18next';
// import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';

function createLink(title: string, icon: React.ReactNode, redirectUrl: string) {
    return { title, icon, redirectUrl };
}

export const Documentation = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Documentation', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`Documentation:${key}`, interpolate);


    const links = [
        createLink(
            lt('faq'),
            <BallotOutlineIcon />,
            'https://help.kaleido.io/hc/en-us'
        ),
        createLink('All Documentation', <DocsIcon />, 'https://docs.kaleido.io'),
        createLink(
            lt('gettingStarted'),
            <BookshelfIcon />,
            'https://docs.kaleido.io/using-kaleido/quick-start-ethereum'
        ),
        createLink(
            lt('apiDocs'),
            <CodeBracesBoxIcon />,
            'https://api.kaleido.io'
        ),
        createLink(
            lt('platformBasics'),
            <FileDocumentBoxCheckOutlineIcon />,
            'https://docs.kaleido.io/kaleido-platform'
        ),
        // TODO do we have a link for this
        // createLink('Ask the Community', <AccountMultipleIcon />, '?'),
    ];

    return (
        <Grid className={classes.container} container>
            {links.map((link) => {
                return (
                    <Grid item container xs={6} key={link.title}>
                        <ListItem
                            button
                            component="a"
                            href={link.redirectUrl}
                            target="_blank"
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                {link.icon}
                            </ListItemIcon>
                            <ListItemText primary={link.title} />
                        </ListItem>
                    </Grid>
                );
            })}
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '35px 0 0',
        width: 'auto',
        margin: 0,
    },
    listItemIcon: {
        minWidth: 40,
        marginLeft: theme.spacing(3),
    },
    itemContainer: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(6),
    },
}));

interface translations {
    faq: string
    gettingStarted: string
    apiDocs: string
    platformBasics: string
}

const enTranslations: translations = {
    faq: 'Frequently Asked Questions',
    gettingStarted: 'Getting Started',
    apiDocs: 'View API Docs',
    platformBasics: 'Platform Basics'
};
