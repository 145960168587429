import React, { useRef, useEffect, useCallback } from 'react';
import { 
    ListItem,
    CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery, useSubscription } from '@apollo/client';
import { EnvironmentsSubscription, MakeEnvironmentsSubscriptionOptions, 
    ConsortiumMembershipsSubscription, MakeConsortiumMembershipsSubscriptionOptions,
    ConsortiumZonesSubscription, MakeConsortiumZonesSubscriptionOptions } from '../../models';
import { useParams, useHistory } from "react-router-dom";
import { ConsortiumWrapperQuery, ConsortiumWrapperResult } from '../../queries/ConsortiumWrapper'
import { ConsortiumResourcesVars } from '../../interfaces'
import { NavPopoverItem } from './SideNavs/NavPopoverItem';

export const CREATE_ENVIRONMENTS_PATH = 'create'

interface Props {
    environment_id: string,
    setConsortiumQueriesComplete: React.Dispatch<React.SetStateAction<boolean>>,
    setCachedEnvironmentId: React.Dispatch<React.SetStateAction<string>>
};

const EnvironmentPicker = ({ environment_id, setConsortiumQueriesComplete, setCachedEnvironmentId }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentPicker', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentPicker:${key}`, interpolate)

    const history = useHistory();
    const { org_id, consortium_id } = useParams<any>();
    const networkDashboardPath = `/orgs/${org_id}/consortia/${consortium_id}`

    const environmentsVars = { 
        consortia_id: consortium_id!
    }

    // Setup the consortium wrapper query
    const { 
        loading,
        data: { 
            environments
        } = { environments: [] } 
    } = useQuery<ConsortiumWrapperResult, ConsortiumResourcesVars>(ConsortiumWrapperQuery,
        { variables: environmentsVars, fetchPolicy: "cache-and-network" });

    // Setup the consortium wrapper subscriptions
    useSubscription(EnvironmentsSubscription, MakeEnvironmentsSubscriptionOptions(environmentsVars));
    useSubscription(ConsortiumMembershipsSubscription, MakeConsortiumMembershipsSubscriptionOptions(environmentsVars, { fetchPolicy: 'no-cache' }));
    useSubscription(ConsortiumZonesSubscription, MakeConsortiumZonesSubscriptionOptions(environmentsVars));

    // tell AppWrapper our consortium queries have completed
    const hasSetConsortiumQueriesCompleteRef = useRef<boolean>(false)
    useEffect(() => {
        if (!hasSetConsortiumQueriesCompleteRef.current && !loading) {
            hasSetConsortiumQueriesCompleteRef.current = true
            setConsortiumQueriesComplete(true)
        }
    }, [loading, setConsortiumQueriesComplete])

    const environment = environments.find(e => e._id === environment_id)

    // watch over our environment 
    const eject = useCallback(() => {
        setCachedEnvironmentId('')
        history.replace(networkDashboardPath)
    }, [setCachedEnvironmentId, history, networkDashboardPath])
    useEffect(() => {
        if (environment?.state === 'deleted') {
            eject()
        }
    }, [environment, eject])
    useEffect(() => {
        if (!loading && environment_id && !environment) {
            eject()
        }
    }, [loading, environment_id, environment, eject])

    // check that the cached environments are actually valid for the current query variables.
    // this occurs because on cache-and-network, during the inital query with a new variable set,
    // the results from the previous query (with the old variables) are returned in the result.
    if (loading) {
        const isStale = (consortiaId: string) => {
            return consortiaId !== environmentsVars.consortia_id
        }
        if (!environments.length || environments.some(e => isStale(e.consortia_id))) {
            return (
                <ListItem>
                    <CircularProgress />
                </ListItem>
            )
        }
    }

    let popoverItems = environments && environments.filter(entry => entry._id !== environment?._id).map((c) => ({
        name: c.name,
        action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id!}/environments/${c._id}`),
        divider: false
    }))

    if (popoverItems.length) {
        popoverItems[popoverItems.length - 1].divider = true
    }

    popoverItems.push({
        name: lt('addEnvironment'),
        action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id!}/environments/create/1`),
        divider: false
    })

    const item = {
        name: environment?.name ?? lt('selectEnvironment'), 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />
};

interface translations {
    addEnvironment: string
    selectEnvironment: string
}
const enTranslations: translations = {
    addEnvironment: 'Add Environment',
    selectEnvironment: 'Select Environment'
}

export { EnvironmentPicker };

