import React from 'react';
import { useParams } from "react-router-dom";
import { CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import { GatewayAPI, Node, ChaincodeStatusesData, ChaincodeStatusesVars, ChaincodeStatusesQuery, ChaincodeStatuses } from '../../models';
import { useQuery } from '@apollo/client';
import { ResourceStateChip } from '../FormControls/ResourceStateChip';
import { useTranslation } from 'react-i18next';

interface Props {
    node?: Node
    gatewayAPI?: GatewayAPI
    isMonitor?: boolean
    statusChecker: (chaincodeStatus?: GatewayAPI | ChaincodeStatuses | null) => boolean
    checkSequenceChannelId?: string
}

export const StatusCheckCell = ({ node, gatewayAPI, isMonitor, statusChecker, checkSequenceChannelId }: Props) => {
    const classes = useStyles();
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'StatusCheckCell', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`StatusCheckCell:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const {
        loading,
        data: {
            chaincodeStatuses
        } = { chaincodeStatuses: null }
    } = useQuery<ChaincodeStatusesData, ChaincodeStatusesVars>(ChaincodeStatusesQuery, { 
        variables: {
            consortia_id: consortium_id,
            environment_id,
            node_id: node?._id || '',
            id: gatewayAPI?._id || ''
        }, 
        skip: isMonitor || false,
        fetchPolicy: 'cache-first' 
    });

    const getChannelStatusChip = () => {
        if (loading && !chaincodeStatuses) {
            return <CircularProgress size={24} />
        }

        let statusResult = false
        let supportsStatuses = true

        if (isMonitor) {
            statusResult = statusChecker(gatewayAPI) 
        } else {
            supportsStatuses = !!chaincodeStatuses
            statusResult = statusChecker(chaincodeStatuses)
        }

        const statusChip = <ResourceStateChip state={supportsStatuses ? (statusResult ? 'yes' : 'no') : 'unknown'} />

        if (!checkSequenceChannelId || !supportsStatuses) {
            return statusChip
        }
    
        const get = () => {
            if (isMonitor) {
                return gatewayAPI?.committedChannels?.find(cc => cc.id === checkSequenceChannelId)?.sequence
            } else {
                return chaincodeStatuses?.committedChannels?.find(cc => cc.id === checkSequenceChannelId)?.sequence
            }
        }

        return (
            <Grid item container spacing={1} direction='column'>
                <Grid item>
                    {statusChip}
                </Grid>
                <Grid item>
                    <hr className={classes.hr} />
                </Grid>
                <Grid item container direction='row' alignItems='center' justify='space-between'>
                    <Grid item>
                        <Typography variant="body2">{lt('sequence')}</Typography>
                    </Grid>
                    <Grid item>
                        {get()}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return getChannelStatusChip()
};

const useStyles = makeStyles((theme) => ({
    hr: {
        padding: theme.spacing(0),
        margin: 0,
        borderColor: theme.palette.background.paper,
        opacity: '50%'
    },
}));

interface translations {
    sequence: string
}
const enTranslations: translations = {
    sequence: 'Sequence'
}
