import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { ContractProjectsData, ContractProjectsQuery } from '../../models'
import { Grid, Button, Typography } from "@material-ui/core";
import { EmptyState } from '../../components/DisplayWrappers'
import { ConsortiumResourcesVars } from '../../interfaces'
import { AppsTable } from '../../components/ContractManagement/AppsTable';

export const Apps: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsContractProjects', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsContractProjects:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id } = useParams<any>();

    const consortiumResourcesVars = {
        consortia_id: consortium_id!,
    }

    const {
        data: {
            contractProjects
        } = { contractProjects: [] }
    } = useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: consortiumResourcesVars,
        fetchPolicy: 'cache-only' 
    });

    const createContractProject = () => history.push(`${pathname}/create/1`)

    if (!contractProjects.length) return (
        <EmptyState imageFile='Empty-Contracts.svg' 
            title={lt('createAContractProject')} 
            description={lt('emptyDescription')} 
            button={{ text: lt('createContractProject'), onClick: createContractProject }}
            documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/smart-contracts/' }} />
    )
    
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('contractProjects')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" size="large" onClick={createContractProject}>
                        {lt('newContractProject')}
                    </Button>
                </Grid>
            </Grid>

            <Grid item container>
                <AppsTable apps={contractProjects} header={lt('apps', { count: contractProjects.length })}/>
            </Grid>
        </Grid>
    )
};

interface translations {
    contractProjects: string,
    newContractProject: string,
    name: string,
    type: string,
    contractDescription: string,
    owner: string,
    createdAt: string,
    createAContractProject: string,
    createContractProject: string,
    emptyDescription: string
    emptyDocumentation: string
    apps: string
}
const enTranslations: translations = {
    contractProjects: 'Apps',
    newContractProject: 'Add New App',
    name: 'Name',
    type: 'Type',
    contractDescription: 'Description',
    owner: 'Owner',
    createdAt: 'Created at',
    createAContractProject: 'Create an App',
    createContractProject: 'Create App',
    emptyDescription: 'Let\'s get started with Kaleido\'s enterprise contract management by creating an app.',
    emptyDocumentation: 'Documentation',
    apps: 'All Apps ({{count}})'
}