import { gql } from '@apollo/client';

export interface NodeConfigRevision {
    id: string
    rev: string
}

export interface NodeConfigVersions {
    opsmetric?: NodeConfigRevision
    networking?: NodeConfigRevision
    kms?: NodeConfigRevision
    baf?: NodeConfigRevision
    ethconnect?: NodeConfigRevision
    node_config?: NodeConfigRevision
}

export interface CordaNodeStatus {
    server_status?: string
}

//Incomplete Interface
interface ProviderWithPublicAddress {
    public_address: string
}
export interface GethNodeStatus extends ProviderWithPublicAddress {}
export interface QuorumNodeStatus extends ProviderWithPublicAddress {}
export interface PantheonNodeStatus extends ProviderWithPublicAddress {}
export interface PolygonEdgeNodeStatus extends ProviderWithPublicAddress {}
export interface FabricNodeStatus {
    p2p_address?: string
    node_info?: string
    explorer?: {
        username?: string
        password?: string
    }
    channels?: string[]
}

export interface NodeStatus {
    _id: string
    block_height: number
    consensus_identity: string
    consensus_role: string
    init_consensus_role: string
    release: string
    geth?: GethNodeStatus
    quorum?: QuorumNodeStatus
    pantheon?: PantheonNodeStatus
    polygon_edge?: PolygonEdgeNodeStatus
    role?: string
    user_accounts: string[]
    configVersions?: NodeConfigVersions
    status: string
    corda?: CordaNodeStatus
    fabric?: FabricNodeStatus
    clientName: string
    urls?: { explorer?: string }
}

export interface NodeStatusData {
    nodeStatus: NodeStatus
}

export const NodeStatusFields = ` 
    fragment NodeStatusFields on NodeStatus {
        _id
        status
        block_height
        consensus_identity
        consensus_role
        geth 
        kafka
        init_consensus_role
        quorum
        pantheon
        polygon_edge
        corda
        fabric
        release
        role
        urls
        user_accounts
        clientName
        configVersions {
            opsmetric {
                id
                rev
            }
            networking {
                id
                rev
            }
            kms {
                id
                rev
            }
            baf {
                id
                rev
            }
            node_config {
                id
                rev
            }
        }
    }`

export const NodeStatusQuery = gql`
    ${NodeStatusFields}
    query getNodeStatus($consortia_id: String!, $environment_id: String!, $id: String!) {
        nodeStatus(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...NodeStatusFields
        }
    }`;    

