import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from "react-router-dom";
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { Step2Success } from './Step2Success';
import { CREATE_ORG_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { Region } from '../../interfaces';

interface Props {
    regions: Region[];
}

export const Create: React.FC<Props> = ({regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOrganizationsCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOrganizationsCreate:${key}`, interpolate)

    const { step } = useParams<any>();
    const cancelPath = `/orgs`

    const invalidStep = step !== "1" && step !== "2"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    const redirect = `/orgs/${CREATE_ORG_PATH}/create/1`

    if (invalidStep || createStep >= 2) {
        return <Redirect to={redirect} />
    }

    const stepComponents = [
        { 
            step: lt('createOrg'), 
            component: <Step1 {...{cancelPath}} {...{regions}}/> 
        },
        { 
            step: lt('createOrg'), 
            component: <Step2Success/> 
        },
    ]

    return <FullScreenCreate hideStepper {...{cancelPath}} toolbarHeader={lt('createOrg')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createOrg: string,
}
const enTranslations: translations = {
    createOrg: 'Create an Organization'
}