import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces';
import { EnvironmentData, EnvironmentQuery, EnvironmentStatusData, EnvironmentStatusQuery, ReleaseData, ReleaseQuery, ReleaseVars, ServicesData, ServicesQuery } from '../../models';
import { EnvironmentPauseResume } from '../DialogWrappers/EnvironmentPauseResume';
import { EnvironmentUpgrade } from '../DialogWrappers/EnvironmentUpgrade';
import { FormLink } from '../FormControls/FormLink';
import { AlertBanner } from './AlertBanner';

export const Banners = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Banners', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Banners:${key}`, interpolate)

    const { consortium_id, environment_id, } = useParams<any>();
    
    const environmentResourcesVars = {
        consortia_id: consortium_id!
    }
    
    // environment query
    const [environmentPauseOpen, setEnvironmentPauseOpen] = useState(false);
    const [environmentUpgradeOpen, setEnvironmentUpgradeOpen] = useState(false);

    const { data: { 
            environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        skip: !environment_id,
        variables: {
            ...environmentResourcesVars,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: {
            ...environmentResourcesVars,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const { data: { 
           release 
         } = { release: null }
    } = useQuery<ReleaseData, ReleaseVars>(ReleaseQuery, {
         skip: !environment,
         variables: {
             id: environment?.release_id!
         },
         fetchPolicy: 'cache-and-network'
    });

    const { data: { 
        environmentStatus 
        } = { environmentStatus: null }
    } = useQuery<EnvironmentStatusData, ConsortiumResourceVars>(EnvironmentStatusQuery, {
        skip: environment?.state !== 'live',
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-and-network'
    });

    const resumeLinkButton = {
        text: lt('wakeEnvironment'),
        onClick: () => setEnvironmentPauseOpen(true)
    }

    const isDev = release && release.version_padded.startsWith('00000')

    const FINAL_SUPPORTED_ORION_RELEASE = "00001.00000.00051"
    const orionBanner = (
        !isDev && release && release.version_padded < FINAL_SUPPORTED_ORION_RELEASE && environment?.provider === 'pantheon' ? (
            <Grid item>
                <AlertBanner title={lt('orionTitle')} description={
                    <Trans i18nKey="Banners:orionDepricationDescription"
                        components={[<FormLink to="https://help.kaleido.io/hc/en-us/articles/12034025837197-Where-is-the-private-transaction-manager-for-Besu-" isExternal={true} target="_blank" />]}>
                    </Trans>
                }/>
            </Grid>
        ) : null
    )

    const FINAL_FF_1_0_RELEASE = "00001.00000.00062"
    const ff1Banner = (
        !isDev && release && release.version_padded <= FINAL_FF_1_0_RELEASE && services.filter(s => s.service === 'firefly-os').length > 0 ? (
            <Grid item>
                <AlertBanner title={lt('fireflyTitle')} description={
                    <Trans i18nKey="Banners:fireflyDescription"
                        components={[<FormLink to="https://github.com/hyperledger/firefly/releases/tag/v1.1.0" isExternal={true} target="_blank" />]}>
                    </Trans>
                }/>
            </Grid>
        ) : null
    )

    const ff1Dot2Banner = (
        release && release.supported_features && release.supported_features.fireflyVersion !== '1.2' && services.filter(s => s.service === 'firefly-os').length > 0 ? (
            <Grid item>
                <AlertBanner title={lt('firefly1Dot2Title')} description={
                    <Trans i18nKey="Banners:firefly1Dot2Description"
                        components={[<FormLink to="https://help.kaleido.io/hc/en-us/articles/19495880087437-Upgrade-existing-nodes-to-FireFly-1-2" isExternal={true} target="_blank" />]}>
                    </Trans>
                }/>
            </Grid>
        ) : null
    )

    const getEnvironmentBanners = () => {
        switch (environment?.state) {
            case 'paused':
                    return (
                    <>
                        <EnvironmentPauseResume environment={environment!} open={environmentPauseOpen} setOpen={setEnvironmentPauseOpen} />
                        {environmentStatus && <EnvironmentUpgrade environment={environment!} open={environmentUpgradeOpen} {...{environmentStatus}} setOpen={setEnvironmentUpgradeOpen} />}
                        <Grid item>
                            <AlertBanner title={lt('pausedTitle')} description={lt('pausedDescription')} linkButton={resumeLinkButton} />
                        </Grid>
                    </>
                    );
            case 'archived':
                return (
                    <>
                        <Grid item>
                            <AlertBanner title={lt('archivedTitle')} description={lt('archivedDescription')}/>
                        </Grid>
                    </>
                );    
            
            case 'locked':
                return (
                    <>
                        <Grid item>
                            <AlertBanner title={lt('lockedTitle')} description={lt('lockedDescription')}/>
                        </Grid> 
                    </>
                );
        
            default:
                return null;
        }
    };



    return (
        <>
            {getEnvironmentBanners()}
            {orionBanner}
            {ff1Banner}
            {ff1Dot2Banner}
        </>
    )
};

interface translations {
    pausedTitle: string
    archivedTitle: string
    lockedTitle: string
    pausedDescription: string
    lockedDescription: string
    archivedDescription: string
    wakeEnvironment: string
    upgradePlan: string
    upgradeEnvironment: string
    baseline: string
    baselineDescription: string
    ipfsUpgradeTitle: string
    ipfsUpgradeDescription: string
    orionTitle: string
    orionDepricationDescription: string
    fireflyTitle: string
    firefly1Dot2Title: string
    fireflyDescription: string
    firefly1Dot2Description: string
}
const enTranslations: translations = {
    pausedTitle: 'Environment is paused',
    archivedTitle: 'Environment is archived',
    lockedTitle: 'Environment is locked',
    pausedDescription: 'Start this environment to resume all of its blockchain nodes and other runtimes.',
    archivedDescription:'Contact kaleido support to get access to your resources.',
    lockedDescription:'Upgrade to a higher plan or contact your organization owner to unlock your resources.',
    wakeEnvironment: 'Wake Environment',
    upgradePlan: 'Upgrade Plan',
    upgradeEnvironment: 'Upgrade Now',
    baseline: 'Automatic upgrade scheduled',
    baselineDescription: 'All environments below version {{version}} will be automatically upgraded during the week of {{date}}. <0>More info</0>',
    ipfsUpgradeTitle: 'IPFS Upgrade',
    ipfsUpgradeDescription: 'IPFS v0.5 will be installed when you upgrade your environment. This major IPFS release contains a breaking API change you need to be aware of before you upgrade. <0>More info</0>',
    orionTitle: 'Orion Deprecation',
    orionDepricationDescription: 'Orion will be removed from runtimes after this environment is upgraded. <0>More info</0>',
    fireflyTitle: 'FireFly 1.1 Upgrade',
    firefly1Dot2Title: 'FireFly 1.2 Upgrade',
    fireflyDescription: 'When this environment is upgraded FireFly will be upgraded to version 1.1. Only the default namespace will be supported after upgrading. <0>More info</0>',
    firefly1Dot2Description: 'FireFly Sandboxes on Kaleido are changing! You will need to perform actions to migrate your sandbox, or upgrade to one of our production FireFly offerings. We are no longer supporting new initializations or registrations to old FireFly nodes. <0>Read here for details</0>',
}
