import React, { useState, useEffect } from 'react';
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AlertBanner } from '../../components/Banners/AlertBanner';
import { CreateCaIdentityData, CreateCaIdentityVars, CreateCaIdentityMutation, MakeCaIdentityCreateMutationOptions } from '../../models/certificateAuthorities';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface CAIdentityRoleTranslation {
    client: string
    null: string
    admin: string
    peer: string
    orderer: string
}

export const EnCAIdentityRoleTranslation: CAIdentityRoleTranslation = {
    client: 'Client',
    null: 'Null',
    admin: 'Admin',
    peer: 'Peer',
    orderer: 'Orderer',
}

export const CreateIdentity = ({ open, setOpen }: Props) => {
    const classes = useStyles();
    const { service_id } = useParams<any>();
    const { t, i18n } = useTranslation();

    i18n.addResourceBundle('en', 'GovernanceCreateIdentity', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceCreateIdentity:${key}`, interpolate)

    const [name, setName] = useState<string>('');
    const [role, setRole] = useState<string>('client');
    const [message, setMessage] = useState("");
    const [enrollmentSecret, setEnrollmentSecret] = useState("");
    const [registerIdentity] = useMutation<CreateCaIdentityData, CreateCaIdentityVars>(CreateCaIdentityMutation);
    const [successfulRegistration, setSuccessfulRegistration] = useState<boolean>(false);

    useEffect(() => {
        setName('');
        setSuccessfulRegistration(false);
        setRole('client');
    }, [open]);

    // const [CreateIdentity, { loading: CreateIdentityLoading }] = useMutation<CreateIdentityData, CreateIdentityVars>(CreateIdentityMutation);

    const content = (
        <>
            {!successfulRegistration ? <>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Typography noWrap variant="body2" color="textSecondary">
                        {lt('addIdentityDesc')}
                    </Typography>
                </Grid>
                <Grid item container>
                    <TextField data-test="textField_identityName"
                        value={name} 
                        autoFocus 
                        label={lt('identityName')} 
                        fullWidth 
                        variant="outlined" 
                        onChange={event => setName(event.target.value)} 
                    />
                </Grid>
                <Grid item className={classes.padDropdown}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel required>
                            {lt('role')}
                        </InputLabel>
                        <Select labelWidth={35} value={role}
                            onChange={e => setRole(e.target.value as 'client' | 'null' | 'admin' | 'peer' | 'orderer')}>
                            <MenuItem value={'client'}>{lt('client')}</MenuItem>
                            <MenuItem value={'null'}>{lt('null')}</MenuItem>
                            <MenuItem value={'admin'}>{lt('admin')}</MenuItem>
                            <MenuItem value={'peer'}>{lt('peer')}</MenuItem>
                            <MenuItem value={'orderer'}>{lt('orderer')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid></> : <>
         <Grid item container direction="column" spacing={3}>
                <Grid container justify="center" spacing={3} alignItems="center">
                    <Grid item>
                        <Box fontFamily="monospace">{enrollmentSecret}</Box>
                    </Grid>
                    <Grid item>
                        <CopyToClipboard text={enrollmentSecret}>
                            <IconButton color="inherit" size="small">
                                <ContentCopyIcon />
                            </IconButton>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
                <Grid item>
                    <AlertBanner title={lt('reminder')} description={lt('lastChance')} />
                </Grid>
        </Grid></>}
        </>
    );

    const updater = () => {
         return registerIdentity(MakeCaIdentityCreateMutationOptions({
            service_id: service_id!,
            caIdentity: {
                enrollmentID: name,
                role
            }
        })).then(result => {
            if (result) {
                const registration = result.data?.createCaIdentity?.registrations[0];
                if (registration) {
                    setEnrollmentSecret(registration.enrollmentSecret ?? '');
                    setSuccessfulRegistration(true);
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        }) 
    } 

    return (
        <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog dataTestId="addIdentity"
            {...{ open }}
            {...{ setOpen }}
            header={lt(successfulRegistration ? 'successfulRegistration' : 'addMemberToMyOrg')}
            saveDisabled={!name}
            controlsWrapper={content}
            onSave={updater}
            hideSave={successfulRegistration}
            noScroll={true}
            closeDialogAfterSave={successfulRegistration}
            successMessage={successfulRegistration ? lt('confirmation') : ''}
            cancelText={successfulRegistration ? lt('done') : lt('cancel')}
            saveText={lt('create')} /></>
    )
};

const useStyles = makeStyles(theme => ({
    padDropdown: {
        marginBottom: theme.spacing(10),
        paddingBottom: theme.spacing(2),
        overflowY: 'hidden'
    }
}));

interface translations extends CAIdentityRoleTranslation {
    addMemberToMyOrg: string
    cancel: string
    create: string
    done: string
    identityName: string
    confirmation: string
    role: string
    successfulRegistration: string
    lastChance: string
    reminder: string
    addIdentityDesc: string
}
const enTranslations: translations = {
    ...EnCAIdentityRoleTranslation,
    addMemberToMyOrg: 'Create New Fabric Identity',
    cancel: 'Cancel',
    create: 'Create',
    done: 'Done',
    identityName: 'Username',
    confirmation: 'Identity has been added',
    role: 'Role',
    successfulRegistration: 'Successfully Created an Identity',
    lastChance: 'This is the last time the enrollment secret will be displayed. Please backup the secret and keep it in a safe place.',
    reminder: 'Reminder',
    addIdentityDesc: 'Once the user is successfully registered, the enrollment secret will be displayed.'
}
