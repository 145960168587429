import { gql } from '@apollo/client';

// INTERFACES

export interface InvoicesData {
    invoices: Invoice[]
}

export interface Invoice {
    id: string
    name: string
    date: string
}

// QUERIES & SUBSCRIPTIONS

export const InvoicesQuery = gql`
    query getInvoices($org_id: String!) {
        invoices(org_id: $org_id) {
            id
            name
            date
        }
    }
`;