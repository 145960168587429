import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { NodesData, NodesQuery, EnvironmentData, EnvironmentQuery } from '../../models';
import { useParams, useHistory } from "react-router-dom";
import { EnvironmentResourcesVars, ConsortiumResourceVars } from '../../interfaces';
import { NavPopoverItem } from '../MainNav/SideNavs/NavPopoverItem';

export const CREATE_NODES_PATH = 'create'

const NodePicker: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodePicker', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodePicker:${key}`, interpolate)

    const history = useHistory();
    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();

    const nodesVars = { 
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const { 
        data: { 
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: nodesVars,
        fetchPolicy: 'cache-only'
    });

    const node = nodes.find(n => n._id === node_id)

    let popoverItems = nodes.filter(n => n._id !== node!._id && n.membership.isMine).map(n => ({
        name: n.name,
        action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id!}/environments/${environment_id}/nodes/${n._id}`),
        divider: false,
        disabled: false
    }))

    if (popoverItems.length) {
        popoverItems[popoverItems.length - 1].divider = true
    }

    if (environment?.isFabric) {
        popoverItems.push({
            name: lt('addOrdererNode'),
            action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id!}/environments/${environment_id}/nodes/create/1/orderer`),
            divider: false,
            disabled: environment?.state !== 'live'
        })
        popoverItems.push({
            name: lt('addPeerNode'),
            action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id!}/environments/${environment_id}/nodes/create/1/peer`),
            divider: false,
            disabled: environment?.state !== 'live'
        })
    } else {
        popoverItems.push({
            name: lt('addNode'),
            action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id!}/environments/${environment_id}/nodes/create/1`),
            divider: false,
            disabled: environment?.state !== 'live'
        })
    }

    const item = {
        name: node!.name, 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />
};

interface translations {
    addNode: string
    addOrdererNode: string
    addPeerNode: string
}
const enTranslations: translations = {
    addNode: 'Add Node',
    addOrdererNode: 'Add Orderer Node',
    addPeerNode: 'Add Peer Node'
}

export { NodePicker };

