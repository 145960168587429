import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Node, NodeState } from '../../models'
import { DisplayCard } from '../../components/DisplayWrappers'
import AdjustIcon from 'mdi-react/AdjustIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import GamepadCircleLeftOutlineIcon from 'mdi-react/GamepadCircleLeftIcon';
import { useHistory, useParams } from 'react-router-dom';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_RUNTIMES_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';
import { LinkButtonProps } from '../../interfaces';
import { Grid } from '@material-ui/core';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { EmptyCard } from '../DisplayWrappers/EmptyCard';

interface Props {
    nodes: Node[]
    isMine?: boolean
    type?: "orderer" | "peer" | "non-fabric"
    hideCreateNode?: boolean
};

export const NodesCard = ({ nodes, isMine = false, type = "non-fabric", hideCreateNode = false }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodesCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodesCard:${key}`, interpolate)

    const { org_id, consortium_id, environment_id } = useParams<any>()
    const history = useHistory();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`

    const makeIcon = (state: NodeState) => {
        return (
            <Grid item container spacing={1}>
                <Grid item>
                    <ResourceStateChip state={state} />
                </Grid>
                <Grid item>
                    <ChevronRightIcon />
                </Grid>
            </Grid>
        )
    };

    const membershipTranslation = lt('system');

    const nodesList = useMemo(() => {
        let n = [...nodes];
        if (isMine) {
            n = n.filter(n => n.membership.isMine);
        };
        return n 
            .sort((a) => a.membership.isMine || a.role === 'monitor' ? -1 : 1)
            .map(n => {
                const membershipId = n.role === 'monitor' ? SYSTEM_MEMBERSHIP : n.membership_id
                const membershipName = n.role === 'monitor' ? membershipTranslation : n.membership.name
                return { 
                    title: n.name, 
                    value: membershipName,
                    icon: type === 'orderer' ? <BullseyeIcon/> : type === 'peer' ? <GamepadCircleLeftOutlineIcon/> : <AdjustIcon />,
                    onClick: () => history.push(n.membership.isMine
                        ? `${basePath}/nodes/${n._id}`
                        : `${basePath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membershipId}/${ADDRESSBOOK_RUNTIMES_PATH}/${n._id}`
                    ),
                    actionIcon: makeIcon(n.state)
                }
            })
    }, [nodes, isMine, type, history, basePath, membershipTranslation])

    const createNodeType = type === 'orderer' ? 'orderer' : type === 'peer' ? 'peer' : '';
    const createNodePath = 'nodes';
    const linkButton: LinkButtonProps = {
        text: lt(type === 'orderer' ? 'createOrdererNode' : type === 'peer' ? 'createPeerNode' : 'createNode'),
        onClick: () => history.push(`${basePath}/${createNodePath}/create/1/${createNodeType}`)
    }

    const noNodes = nodes.length === 0;
    const nodeCardContent = noNodes ? (
        <EmptyCard header={lt(type === 'orderer' ? 'ordererNode' : type === 'peer' ? 'peerNode' : 'nodes')}
            imageFiles= {type === 'orderer' ? "Empty-OrdererNode.svg" : type === 'peer' ? "Empty-PeerNode.svg" : "Empty-Node.svg"}
            description={lt(type === 'orderer' ? 'noOrdererNodeDescription' : 'noPeerNodeDescription')} 
            onViewDetailsClick={() => window.open('https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/')}
            createPath={`${basePath}/nodes/create/1/${createNodeType}`} />
    ) : <DisplayCard linkButton={hideCreateNode ? undefined: linkButton}
        header={lt(type === 'orderer' ? 'ordererNodes' : type === 'peer' ? 'peerNodes' : 'nodes', { count: nodesList.length })}
        itemList={nodesList}
        dataTestId="environmentNodes" />;
    
    return (nodeCardContent)
};

interface translations {
    nodes: string,
    ordererNode: string,
    ordererNodes: string,
    peerNode: string,
    peerNodes: string,
    system: string,
    createNode: string,
    createOrdererNode: string,
    createPeerNode: string,
    noOrdererNodeDescription: string,
    noPeerNodeDescription: string
}
const enTranslations: translations = {
    nodes: 'Blockchain Nodes ({{count}})',
    ordererNode: 'Orderer Node',
    ordererNodes: 'Orderer Nodes ({{count}})',
    peerNode: 'Peer Node',
    peerNodes: 'Peer Nodes ({{count}})',
    system: 'System',
    createNode: 'Create Node',
    createOrdererNode: 'Create Orderer Node',
    createPeerNode: 'Create Peer Node',
    noOrdererNodeDescription: 'Orderer nodes are responsible for transaction ordering, using consensus algorithms to ensure consistency among the members of a channel. Orderer nodes also produce blocks and disseminate them to peers.',
    noPeerNodeDescription: 'Peer nodes are responsible for running chaincodes and processing transactions, as well as maintaining the shared ledger for each channel.'
}