import { gql } from '@apollo/client';
import { EnvironmentResourcesVars } from '../interfaces'

export interface LogResults {
    beforepos?: string
    frompos?: string
    logs: string[]
}

export interface LogsVars extends EnvironmentResourcesVars {
    id: string,
    resource: string,
    container: string,
    beforepos?: string,
    frompos?: string
}

export interface LogsData {
    logs: LogResults
}

export const LogsFields = ` 
    fragment LogsFields on LogResults {
        beforepos
        frompos
        logs
    }`

export const LogsQuery = gql`
    ${LogsFields}
    query logs($consortia_id: String!, $environment_id: String!, $id: String!, $resource: String!, $container: String!, $beforepos: String, $frompos: String) {
        logs(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, resource: $resource, container: $container, beforepos: $beforepos, frompos: $frompos) {
            ...LogsFields
        }
    }`;    

