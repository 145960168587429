import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import {
    ServicesData, ServicesQuery, NodesQuery, NodesData, EnvironmentZonesData,
    EnvironmentZonesQuery, ServicesEnum, ServicesTranslations, EnServicesTranslations, RuntimeSizeTranslation, EnRuntimeSizeTranslation
} from '../../models'
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from "@material-ui/core";
import { DisplayTable } from '../../components/DisplayWrappers/DisplayTable';
import AdjustIcon from 'mdi-react/AdjustIcon';
import AppsIcon from 'mdi-react/AppsIcon';
import { DisplayTableColumns } from '../../components/DisplayWrappers/DisplayTableRow';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_RUNTIMES_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';

export const AddressBookRuntimes: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'AddressbookRuntimes', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressbookRuntimes:${key}`, interpolate)

    const { org_id, consortium_id, environment_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const getZoneLabel = (zoneId: string) => {
        const environmentZone = environmentZones.find(zone => zone._id === zoneId) || environmentZones[0];
        return environmentZone?.displayName;
    };

    let myRuntimes: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    let otherRuntimes: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    for (const node of nodes) {
        const membershipId = node.role === 'monitor' ? SYSTEM_MEMBERSHIP : node.membership_id
        const membershipName = node.role === 'monitor' ? lt('system') : node.membership.name
        const record = {
            key: node._id,
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membershipId}/${ADDRESSBOOK_RUNTIMES_PATH}/${node._id}`),
            columns: [
                { isIcon: true, value: <AdjustIcon /> },
                { value: node.name || '--' },
                { value: lt('node', { size: lt(node.size) }) },
                { value: getZoneLabel(node.zone_id) },
                { value: membershipName },
                { value: <ResourceStateChip state={node.state} /> }
            ]
        }
        node.membership.isMine || node.role === 'monitor' ? myRuntimes.push(record) : otherRuntimes.push(record);
    }

    for (const service of services) {
        const membershipId = service.service_type === 'utility' ? SYSTEM_MEMBERSHIP : service.membership_id
        const membershipName = service.service_type === 'utility' ? lt('system') : service.membership.name
        const record = {
            key: service._id,
            onClick: () => {
                history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membershipId}/${ADDRESSBOOK_RUNTIMES_PATH}/${service._id}`);
            },
            columns: [
                { isIcon: true, value: <AppsIcon /> },
                { value: service.name || '--' },
                { value: lt(ServicesEnum[service.service]) },
                { value: getZoneLabel(service.zone_id) },
                { value: membershipName },
                { value: <ResourceStateChip state={service.state} /> }
            ]
        };
        service.membership.isMine || service.service_type === 'utility' ? myRuntimes.push(record) : otherRuntimes.push(record);
    }

    const columnHeaders = [
        '',
        lt('name'),
        lt('type'),
        lt('region'),
        lt('owner'),
        lt('status')
    ];

    return (
        <Grid container spacing={3}>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('myRuntimes', { count: myRuntimes.length })}
                    </Typography>
                </Grid>
                <Grid item>
                    {myRuntimes.length > 0 ? <DisplayTable header={lt('runtimes')} columnHeaders={columnHeaders} records={myRuntimes} />
                        : <Typography>{lt('noRuntimes')}</Typography>}
                </Grid>
            </Grid>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('otherRuntimes', { count: otherRuntimes.length })}
                    </Typography>
                </Grid>
                <Grid item>
                    {otherRuntimes.length > 0 ? <DisplayTable header={lt('runtimes')} columnHeaders={columnHeaders} records={otherRuntimes} />
                        : <Typography>{lt('noRuntimes')}</Typography>}
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations extends ServicesTranslations, RuntimeSizeTranslation {
    myRuntimes: string
    otherRuntimes: string
    runtimes: string
    noRuntimes: string
    name: string
    type: string
    region: string
    owner: string
    status: string
    node: string
    system: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    ...EnRuntimeSizeTranslation,
    myRuntimes: 'My/Shared Runtimes ({{count}})',
    otherRuntimes: 'Other Runtimes ({{count}})',
    runtimes: 'Runtimes',
    noRuntimes: 'No Runtimes',
    name: 'Name',
    type: 'Type',
    region: 'Region',
    owner: 'Owner',
    status: 'Status',
    node: 'Node',
    system: 'System',
}