import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { NodesQuery, NodesData, RuntimeSizeTranslation, EnRuntimeSizeTranslation, ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models'
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { DisplayTable } from '../../components/DisplayWrappers/DisplayTable';
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import GamepadCircleLeftOutlineIcon from 'mdi-react/GamepadCircleLeftIcon';
import { DisplayTableColumns } from '../../components/DisplayWrappers/DisplayTableRow';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { ConsortiumResourcesVars, EnvironmentResourcesVars, EnvironmentResourceVars } from '../../interfaces';
import { ShortenedString } from '../../components/FormControls/ShortenedString';
import { ChannelData, ChannelQuery } from '../../models/channels';
import { AddMemberToChannel } from './AddMember';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_RUNTIMES_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';

export const AddressBookChannelMembers: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    i18n.addResourceBundle('en', 'AddressBookChannelMembers', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookChannelMembers:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, channel_id } = useParams<any>();
    const [open, setOpen] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState(['']);
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: channel_id!
    }

    const { 
        data: { 
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });
    
    const {
        loading,
        data: {
            channel
        } = { channel: null }
    } = useQuery<ChannelData, EnvironmentResourceVars>(ChannelQuery, { 
        variables: {
            ...environmentVars,
            id: channel_id
        },
        fetchPolicy: 'cache-only'
    });

    const { data: { nodes : nodesCollection } = { nodes: [] } } = useQuery<
        NodesData,
        EnvironmentResourcesVars
    >(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: "cache-only",
    });

    if (loading || !channel) {
        return <CircularProgress />
    }

    const nodes = nodesCollection.filter((n) => (channel.members).includes(n.membership_id));
    const columnHeaders = [
        '',
        lt('name'),
        lt('url'),
        lt('owner'),
        lt('addedOn'),
        lt('status')
    ];

    let { members } = channel;
    if (selectedMembers[0] === '') {
        setSelectedMembers(members);
    }

    const disableEdit = channel.state === 'updating' || channel.state === 'creating' || nodes.some(n => n.state !== 'started') || channel.name === 'default-channel'
    const content = [];
    const noNodeMembers = [];
    for (const member of members) {
        let memberNodes: Array<{
            key: string,
            columns: DisplayTableColumns[],
            onClick?: () => void
        }> = [];
        const consortiumMember = consortiumMemberships.find(m => m._id === member) || {org_name: member === 'sys--mon' ? lt('system') : member};
        let memberName = consortiumMember.org_name;
        for (const node of nodes) {
            if (node.membership_id !== member) continue;
            const urls = node.urls ?? {orderer: '-', peer: '-'};
            const url = node.role === 'orderer' ? urls.orderer : urls.peer;
            const record = {
                key: node._id,
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${node.membership_id === 'sys--mon' ? SYSTEM_MEMBERSHIP : node.membership_id}/${ADDRESSBOOK_RUNTIMES_PATH}/${node._id}`),
                columns: [
                    { isIcon: true, value: node.role === 'orderer' ? <BullseyeIcon /> : <GamepadCircleLeftOutlineIcon/>},
                    { value: node.name || '--' },
                    { value: <ShortenedString noLowerCase content={url || '-'} prefixCharacters={30} postfixCharacters={20} hideCopy />},
                    { value: memberName },
                    { value: new Date(node.created_at).toLocaleString() },
                    { value: <ResourceStateChip state={node.state} /> }
                ]
            }
            memberNodes.push(record);
        }
        if (memberNodes.length === 0) {
            noNodeMembers.push(<Grid item><DisplayTable header={lt('memberNodes', { channelName: memberName })} emptyLabel={lt('noNodes')} hideEmptyIcon columnHeaders={columnHeaders} records={memberNodes} /></Grid>);
            continue;
        }
        content.push(<Grid item key={member}><DisplayTable header={lt('memberNodes', { channelName: memberName })} emptyLabel={lt('noNodes')} hideEmptyIcon columnHeaders={columnHeaders} records={memberNodes} /></Grid>)
    }

    return (
        <><Grid container spacing={3}>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('memberNodes', { channelName: channel.name })}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                        data-test="button_addMemberToChannel"
                        variant="contained"
                        color="primary"
                        disabled={disableEdit}
                        onClick={() => setOpen(true)}
                    >
                        {lt('editMembers')}
                    </Button>
                    </Grid>
                </Grid>
                {content}
                {noNodeMembers}
            </Grid>
        </Grid>
        <AddMemberToChannel channel={channel} existingMembers={members} {...{selectedMembers}} {...{setSelectedMembers}} open={open} setOpen={setOpen} /></>
    )
};

interface translations extends RuntimeSizeTranslation {
    memberNodes: string
    ordererNodes: string
    peerNodes: string
    noOrdererNodes: string
    noPeerNodes: string
    noNodes: string
    name: string
    owner: string
    status: string
    node: string
    url: string
    addedOn: string
    editMembers: string,
    system: string,
}
const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    memberNodes: '{{channelName}} Nodes',
    ordererNodes: 'Orderer Nodes',
    peerNodes: 'Peer Nodes',
    noOrdererNodes: 'No Orderer Nodes',
    noPeerNodes: 'No Peer Nodes',
    noNodes: 'No Nodes',
    name: 'Name',
    owner: 'Owner',
    status: 'Runtime Status',
    node: 'Node',
    url: 'URL',
    addedOn: 'Added On',
    editMembers: 'Edit Members',
    system: 'System',
}
