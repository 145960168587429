import { Grid, Grow, IconButton, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import CloseIcon from 'mdi-react/CloseIcon';
import SearchIcon from 'mdi-react/MagnifyIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SearchType {
    setTypeValue: (value: React.SetStateAction<string>) => void,
    typeValue: string,
    typeList: Array<{value: string, label: string}>
}

interface Props {
    onSubmit: (seacrhInput: string) => Promise<void> | void,
    setSearchActive: (value: React.SetStateAction<boolean>) => void,
    isOpen:  boolean,
    setIsOpen: (value: React.SetStateAction<boolean>) => void,
    searchType?: SearchType
    label?: string
}

export const SearchBar = ({onSubmit, setSearchActive, isOpen, setIsOpen, searchType, label}: Props) => {
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SearchBar', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SearchBar:${key}`, interpolate);

    const classes = useStyles();

    const [input, setInput] = useState('');

    const updateSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault()
        setSearchActive(true);
        onSubmit(input);
    };

    return (
        <Grow appear in={isOpen} timeout={300}>
            <Grid container alignItems="center" wrap="nowrap" spacing={3} className={classes.container}>
                
                <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>
                        <IconButton type="submit" size="small">
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField autoFocus placeholder={label ? label : lt('label')} value={input} onChange={(e) => setInput(e.target.value)} fullWidth
                            onKeyPress={(e) => e.key === 'Enter' ? updateSearch(e) : () => {}} />
                    </Grid>
                    {searchType?.setTypeValue && (
                        <Grid item xs={2} container spacing={1} alignItems="center" justify="flex-end" wrap="nowrap" className={classes.selectContainer}>
                            <Grid item>
                                <Typography>{lt('searchBy')}</Typography>
                            </Grid>
                            <Grid item>
                                <Select fullWidth value={searchType.typeValue} onChange={e => searchType.setTypeValue(e.target.value as string)}>
                                    {searchType.typeList.map((entry, index) => (
                                        <MenuItem key={index} value={entry.value}>{entry.label}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                
                <Grid item>
                    <IconButton onClick={() => {setIsOpen(false); setSearchActive(false)}} size="small">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grow>
    )
}


const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    selectContainer: {
        minWidth: '170px'
    },
    container: {
        paddingLeft: theme.spacing(2)
    }
}));

interface translations {
    label: string
    searchBy: string
}

const enTranslations: translations = {
    label: 'Search..',
    searchBy: 'Search By'
}