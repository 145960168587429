import { gql } from '@apollo/client';
import { AccountType, WalletOrigin } from './accounts'
import { MembershipOwnedResource } from './memberships';

// INTERFACES

export interface Address extends MembershipOwnedResource {
    _id: string,
    account_type: AccountType,
    description: string,
    wallet_id: string,
    name: string,
    type: "contract" | "account" | "external",
    wallet_origin: WalletOrigin
}

export interface AddressWidgetInfo extends MembershipOwnedResource {
    _id: string
    type: string
    tokenInfo?: {
        name: string
        symbol: string
        isERC20: boolean
        isERC721: boolean
        erc20TotalSupply: string
    }
    gatewayAPIInfo?: {
        consortiaContractId: string
        contractProjectName: string
        gatewayAPIId: string
        gatewayAPIEndpoint: string
        instanceEndpointId: string
    }
}

export interface AddressData {
    address: Address
}

export interface AddressWidgetInfoData {
    addressWidgetInfo: AddressWidgetInfo
}

// FRAGMENTS

export const AddressFields = ` 
    fragment AddressFields on Address {
        _id
        account_type
        description
        membership_id
        wallet_id
        name
        type
        wallet_origin {
            _id
            name
            type
        }

        membership @client
    }`

export const AddressWidgetInfoFields = ` 
    fragment AddressWidgetInfoFields on AddressWidgetInfo {
        _id
        membership_id
        type
        tokenInfo {
          isERC20
          isERC721
          name
          symbol
          erc20TotalSupply
        }
        gatewayAPIInfo {
          gatewayAPIEndpoint
          gatewayAPIId
          instanceEndpointId
          consortiaContractId
          contractProjectDescription
          contractProjectName
          contractProjectType
        }
        membership @client
    }`

// QUERIES & SUBSCRIPTIONS

export const AddressQuery = gql`
    ${AddressFields}
    query getAddress($consortia_id: String!, $environment_id: String!, $id: String!) {
        address(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...AddressFields
        }
    }`;

    export const AddressWidgetInfoQuery = gql`
    ${AddressWidgetInfoFields}
    query getAddressWidgetInfo($consortia_id: String!, $environment_id: String!, $id: String!) {
        addressWidgetInfo(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...AddressWidgetInfoFields
        }
    }`;