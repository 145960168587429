import React from 'react';
import { useQuery } from '@apollo/client';
import { ChannelsData, ChannelsQuery } from '../../models';
import { useParams, useHistory } from "react-router-dom";
import { EnvironmentResourcesVars } from '../../interfaces';
import { CHANNELS_PATH } from '../MainNav/SideNavs/AddressBook';
import { NavPopoverItem } from '../MainNav/SideNavs/NavPopoverItem';
import { CREATE_CHANNELS_PATH } from '../../views/AddressBook/Channels';
import { useTranslation } from 'react-i18next';

const ChannelPicker: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChannelPicker', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChannelPicker:${key}`, interpolate);

    const history = useHistory();
    const { org_id, consortium_id, environment_id, channel_id } = useParams<any>();

    const environmentVars = { 
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            channels
        } = { channels: [] }
    } = useQuery<ChannelsData, EnvironmentResourcesVars>(ChannelsQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const orgName = channels.find(n => n._id === channel_id)?.name

    const channelsForDropdown = channels.map(c => ({ id: c._id, name: c.name })).filter(c => c.id !== channel_id);
    let popoverItems = channelsForDropdown.map((m, index) => ({
        name: m.name,
        action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${m.id}`),
        divider: index === channelsForDropdown.length - 1,
        disabled: false
    }))

    popoverItems.push({
        name: lt('addChannel'),
        action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${CREATE_CHANNELS_PATH}/1`),
        divider: false,
        disabled: false
    })

    const item = {
        name: orgName || '', 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />
};

interface translations {
    addChannel: string
}

const enTranslations: translations = {
    addChannel: 'Add Channel'
}

export { ChannelPicker };

