import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more.js"
import SolidGauge from "highcharts/modules/solid-gauge.js";
import { useTranslation } from 'react-i18next';
import { BaseChartOptions } from '../../../utils/ChartOptions';
import { LineChart } from '../../../components/Charts/LineChart';
import { DisplayGridWrapper } from '../../../components/DisplayWrappers';
import { Grid, Typography } from '@material-ui/core';
import { estimateCost } from './CostUtils';

interface Props {
    costToDate: number
}

export const ForecastedCost: React.FC<Props> = ({ costToDate }: Props) => {
    Highcharts.setOptions(BaseChartOptions);
    HighchartsMore(Highcharts);
    SolidGauge(Highcharts);

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransactionsGauge', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransactionsGauge:${key}`, interpolate);

    const { date, firstDay, lastDay, estimate } = estimateCost(costToDate);

    const linechartOpts = {
        xAxis: { title: { enabled: false } },
        yAxis: { title: { enabled: false }, max: estimate * 2 }
    } as Highcharts.Options;

    const series: { name: string, values: [number, number][] }[] = [
        {
            name: lt('forecastedMonthlyCost'),
            values: [[date.getTime(), Number(costToDate.toFixed(2))], [lastDay, Number(estimate.toFixed(2))]]
        },
        {
            name: lt('monthToDateSpent'),
            values: [[firstDay, 0], [date.getTime(), Number(costToDate.toFixed(2))]]
        },
        {
            name: lt('billingCycleStart'),
            values: [[firstDay, 0], [firstDay, 0]]
        }
    ];

    const content = (
        <Grid container direction="column">
            <Grid item>
                <LineChart
                    title={lt('cost')}
                    series={series}
                    valuePrefix="$"
                    extraHCOptions={linechartOpts}
                />
            </Grid>
            <Grid item container justify="space-between">
                <Grid item>
                    <Typography variant="body1">{lt('totalCost')}</Typography>
                    <Typography variant="body2">{lt('endOfMonth', { month: date.toLocaleString('default', { month: 'long' }) })}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{lt('amount', { amount: estimate.toFixed(2) })}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <DisplayGridWrapper header={lt('forecastedCost')} displayGrid={content} hideDivider padDisplayGrid={true} />
    );

}

interface translations {
    forecastedCost: string
    cost: string
    monthToDateSpent: string
    billingCycleStart: string
    forecastedMonthlyCost: string
    totalCost: string
    endOfMonth: string
    amount: string
}

const enTranslations: translations = {
    forecastedCost: 'Forecasted Cost',
    cost: 'Cost',
    monthToDateSpent: 'Month-to-date spent',
    billingCycleStart: 'Billing cycle start',
    forecastedMonthlyCost: 'Forecasted monthly cost',
    totalCost: 'Total Cost',
    endOfMonth: 'End of {{month}}',
    // eslint-disable-next-line
    amount: '${{amount}}'
}