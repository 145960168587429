import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConsortiumCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConsortiumCreateStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Organization.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    finalInfo: string,
}
const enTranslations: translations = {
    helpHeader: 'Join a Network',
    helpDescription: 'You have been invited to join a decentralized network. A decentralized network has shared ownership across a group of stakeholders. All operations at this tier are off-chain, and include:',
    helpDesc1: 'Cloud + region whitelisting',
    helpDesc2: 'Membership creation, certificate management and governance',
    helpDesc3: 'Network auditing',
    finalInfo: 'Be sure to specify an organization name. This will be used as the name of your membership once you have joined the network.',
}