import {
    Button,
    Grid, TextField,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AlertDarkColors } from '../../utils/Colors';
import { FormLink } from '../FormControls/FormLink';

interface Props {
    email: string
    verificationCode: string
    newCodeSent: boolean
    setVerificationCode: React.Dispatch<React.SetStateAction<string>>
    errorMessage: string
    loading: boolean
    handleVerifyAccount: () => void
    handleResendCode: () => void
}

export const VerifyEmail: React.FC<Props> = ({
    email,
    verificationCode,
    setVerificationCode,
    handleVerifyAccount,
    loading,
    newCodeSent,
    handleResendCode,
    errorMessage
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'VerifyEmail', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`VerifyEmail:${key}`, interpolate);

    return (
        <form id="VerifyEmailForm" className={'Hubspot'} style={{ width: '300px' }} onSubmit={event => { event.preventDefault(); handleVerifyAccount() }} >
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('checkYourInbox')}
                    </Typography>
                </Grid>
                {errorMessage &&
                    <Grid item>
                        <Typography color="error" variant="subtitle1">
                            {errorMessage}
                        </Typography>
                    </Grid>
                }
                {newCodeSent &&
                    <Grid item>
                        <Typography variant="subtitle1" className={classes.codeSent}>
                            {lt('newCodeSent')}
                        </Typography>
                    </Grid>
                }
                <Grid item>
                    <Typography variant="subtitle1">
                        <Trans i18nKey="VerifyEmail:verificationCodeSent" values={{ email }}
                            components={[<span className={classes.emailAddress} />]}>
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1">
                        <Trans i18nKey="VerifyEmail:checkSpamFolderOrResendCode"
                            components={[<FormLink onClick={() => handleResendCode()} />]}>
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        label={lt('code')}
                        value={verificationCode}
                        fullWidth
                        variant="outlined"
                        margin="none"
                        onChange={event => setVerificationCode(event.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button type="submit"
                        fullWidth
                        disabled={!verificationCode || loading}
                        variant="contained"
                        color='primary'>
                        {lt('verifyEmail')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const useStyles = makeStyles(() => ({
    codeSent: {
        color: AlertDarkColors.green
    },
    emailAddress: {
        fontWeight: 600,
        wordBreak: 'break-word'
    }
}));

interface translations {
    checkYourInbox: string
    verificationCodeSent: string
    checkSpamFolderOrResendCode: string
    code: string
    verifyEmail: string
    newCodeSent: string
}

const enTranslations: translations = {
    checkYourInbox: 'Check your inbox!',
    verificationCodeSent: 'We sent a 6 digit verification code to <0>{{email}}</0>. It will expire in 24 hours, so activate it soon.',
    checkSpamFolderOrResendCode: 'If you don\'t see it in your inbox, check your spam folder. Still don\'t see it? <0>Send a new code</0>.',
    code: 'Code',
    verifyEmail: 'Verify Email',
    newCodeSent: 'A new code has been sent'
}
