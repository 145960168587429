import React from 'react';
import { Grid, makeStyles, Typography, Button, Paper } from '@material-ui/core';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';
import { AlertLightColors, AlertDarkColors } from '../../utils/Colors';
import { LinkButtonProps } from '../../interfaces';
import moment from 'moment'
import clsx from 'clsx';

interface Props {
    icon?: JSX.Element
    title?: string
    description: string,
    boldDescriptionSuffix?: string,
    eventCreatedAtDate?: string,
    linkButton?: LinkButtonProps,
    paperWrapBackground?: boolean
};

export const InfoBanner = ({ icon, title, description, boldDescriptionSuffix, eventCreatedAtDate, linkButton, paperWrapBackground }: Props) => {
    const classes = useStyles();

    const content = (
        <Grid container direction="column" className={clsx(classes.paper, { [classes.infoContainer]: !paperWrapBackground })}>
            <Grid item container spacing={3} alignItems="center">
                <Grid item>
                    {icon || <InfoOutlineIcon color={AlertDarkColors.blue} />}
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        {title && 
                        <>
                            <b>{title}</b>
                            <span> - </span>
                        </>
                        }
                        {description} {boldDescriptionSuffix ? <b>{boldDescriptionSuffix}</b> : null}
                    </Typography>
                </Grid>
                {linkButton &&
                <Grid item className={classes.alignRight}>
                    {eventCreatedAtDate ? 
                    <Typography variant="body2" display="inline" className={classes.padRight}>
                        {moment(eventCreatedAtDate).fromNow()}
                    </Typography> : null}
                    <Button variant='contained' onClick={linkButton.onClick} color="primary" startIcon={linkButton.startIcon}>
                        {linkButton.text}
                    </Button> 
                </Grid>
                }
            </Grid>
        </Grid>
    )

    return paperWrapBackground ? (
        <Paper>
            {content}
        </Paper>
    ) : content
}

const useStyles = makeStyles((theme) => ({
    alignRight: {
        marginLeft: 'auto'
    },
    padRight: {
        marginRight: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2)
    },
    infoContainer: {
        backgroundColor: AlertLightColors.blue,
        border: 'solid',
        borderWidth: '2px',
        borderColor: AlertDarkColors.blue,
        padding: theme.spacing(1)
    }
}))