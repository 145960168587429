import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Environment, 
    UpgradeEnvironmentMutation,
    EnvironmentStatus} from '../../models'
import { TextField, Grid } from "@material-ui/core";
import { FormDialog } from '../../components/DialogWrappers'
import { ConsortiumResourceVars } from '../../interfaces';
import { FormLink } from '../FormControls/FormLink';

interface Props {
    environment: Environment
    environmentStatus: EnvironmentStatus,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
};

export const EnvironmentUpgrade = ({ environment, environmentStatus, open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentUpgrade', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentUpgrade:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!,
        id: environment_id!
    }

    const [upgradeNameToMatch, setUpgradeNameToMatch] = useState('');
    const [upgradeEnvironment] = useMutation<Environment, ConsortiumResourceVars>(UpgradeEnvironmentMutation)

    const upgradeDescriptionLinksWrapper = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <FormLink isExternal={true} to="https://docs.kaleido.io/release-notes/" target="_blank">
                    {lt('releaseNotes')}
                </FormLink>
            </Grid>
            <Grid item>
                <FormLink isExternal={true} to="https://docs.kaleido.io/kaleido-platform/maintenance/protocol-and-services/" target="_blank">
                    {lt('upgradeLearnMore')}
                </FormLink>
            </Grid>
        </Grid>
    )

    const upgradeControlsWrapper = (
        <TextField
            value={upgradeNameToMatch} onChange={event => setUpgradeNameToMatch(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('confirmUpgrade')}
            fullWidth
            variant="outlined"
        />
    )

    return (
        <FormDialog 
            open={open}
            setOpen={setOpen}
            header={lt('upgradeEnvironment', {name: environment.name})} 
            description={lt('upgradeDescription', {version: environmentStatus.upgrade.target_release.version})} 
            onSave={() => upgradeEnvironment({
                variables: environmentVars
            }).then(() => {})}
            closeDialogAfterSave
            saveDisabled={environment.name !== upgradeNameToMatch}
            confirmResourcePromptName={environment.name}
            confirmSuffix={lt('confirmUpgradeSuffix')}
            descriptionLinksWrapper={upgradeDescriptionLinksWrapper}
            controlsWrapper={upgradeControlsWrapper}
            saveText={lt('upgrade')} />
    )
};

interface translations {
    upgrade: string,
    upgradeEnvironment: string,
    upgradeDescription: string,
    confirmUpgrade: string
    confirmUpgradeSuffix: string,
    releaseNotes: string,
    upgradeLearnMore: string
}
const enTranslations: translations = {
    upgrade: 'Upgrade',
    upgradeEnvironment: 'Upgrade this environment ({{name}})?',
    upgradeDescription: 'This environment will be upgraded to version {{version}}.',
    confirmUpgrade: 'Name',
    confirmUpgradeSuffix: ' to confirm the upgrade.',
    releaseNotes: 'View Release Notes',
    upgradeLearnMore: 'Learn more about our rolling upgrade process'
}