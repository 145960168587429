import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MessageSnackbar } from '../../../components/DialogWrappers'
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper'
import { CreateStepProps } from '../../../interfaces';
import { PrecompiledUpload } from '../../../components/ContractManagement/PrecompiledUpload';
import { PrecompiledUploadHelp } from '../../../components/ContractManagement/PrecompiledUploadHelp';

interface Props extends CreateStepProps {
    description: string,
    membershipId: string,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
};

export const UploadStep2 = ({ description, membershipId, loading, setLoading, cancelPath }: Props) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { contract_id } = useParams<any>();
    
    const [message, setMessage] = useState('');

    // if we get a new error message, kill the loading bar
    useEffect(() => {
        if (message) setLoading(false)
    }, [message, setLoading])

    const postSave = async (compiledContractId: string) => {
        const splitPathname = pathname.split('/')
        const baseCompilationsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_COMPILED_CONTRACTS_PATH) + 1).join('/')
        history.push(`${baseCompilationsPath}/${compiledContractId}`)
    }

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <PrecompiledUpload {...{cancelPath}} {...{loading}} {...{postSave}} {...{setLoading}} {...{setMessage}} 
                {...{description}} {...{membershipId}} {...{contract_id}} isLastStep />
            <PrecompiledUploadHelp />
        </>
    )
};
