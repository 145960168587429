import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Node, Service, CreateEnvironmentZoneData, CreateEnvironmentZoneVars, CreateEnvironmentZoneMutation, EnvironmentZonesData, EnvironmentZonesQuery } from '../../models'
import { LinkButtonProps } from '../../interfaces'
import { ConnectList } from './ConnectList';
import { ConnectRuntimeDialog } from './ConnectRuntimeDialog';
import { useHistory, useParams } from 'react-router-dom';
import { NODE_CONTRACTS_PATH, NODE_CORDAPPS_PATH } from '../NodeNav/NodeNav';
import { FormDialog } from '../DialogWrappers';
import { useMutation, useQuery } from '@apollo/client';

// one of the two (node or service) is required otherwise this component renders nothing
interface Props {
    node?: Node,
    service?: Service,
    open?: boolean,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>, //optional for services
    header?: string,
    description?: string,
    actionText?: string,
    hybrid?: boolean, // If set to true, then this will display a button to convert the brige into a deployment zone (only applies to hybrid envs)
    customLinkButton?: LinkButtonProps
}

export const ConnectRuntimeWrapper = ({ node, service, open = false, setOpen, header, description, actionText, hybrid, customLinkButton }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConnectRuntimeWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConnectRuntimeWrapper:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const resolvedHeader = header ? header : node ? lt('connectYourNode') : lt('connectRuntime')
    const resolvedDescription = description ? description : node ? lt('connectYourNodeDescription') : lt('connectRuntimeDescription')
    const resolvedActionText = actionText ? actionText : node ? lt('connectNode') : lt('connectRuntime')
    const [createZoneOpen, setCreateZoneOpen] = useState(false);

    const [createEnvironmentZone] = useMutation<CreateEnvironmentZoneData, CreateEnvironmentZoneVars>(CreateEnvironmentZoneMutation)

    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const onCreateZone = async () => {
        await createEnvironmentZone({
            variables: {
                ...environmentVars,
                environmentZone: {
                    type: 'private',
                    region: '',
                    cloud: '',
                    bridge_id: service?._id,    
                }
            }
        })
    };

    const deploymentZoneCreated = !!environmentZones.find(z => z.bridge_id === service?._id);

    const linkButton: LinkButtonProps | undefined = setOpen ? {
        text: resolvedActionText,
        onClick: () => setOpen(true),
        color: 'primary',
        variant: 'contained'
    } : customLinkButton

    const linkButton2: LinkButtonProps | undefined = !node?.isFabric && node ? {
        text: node?.isCorda ? lt('newCorDapp') : lt('newContract'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node._id}/${node?.isCorda ? NODE_CORDAPPS_PATH : NODE_CONTRACTS_PATH}/create/1`),
        color: 'primary',
        variant: 'contained',
        disabled: node.state !== 'started'
    } : hybrid ? {
        text: deploymentZoneCreated ? lt('deploymentZoneCreated') : lt('createDeploymentZone'),
        onClick: () => setCreateZoneOpen(true),
        color: 'primary',
        variant: 'contained',
        disabled: service?.state !== 'started' || deploymentZoneCreated
    } : undefined;

    const runtime = node ? node : service;
    if (!Object.keys(runtime?.urls || {}).length) return <></>
    const runtimeId = runtime?._id;

    return (
        <>
            <FormDialog
                open={createZoneOpen}
                setOpen={setCreateZoneOpen}
                header={lt('createDeploymentZone')} 
                description={lt('createDeploymentZoneDesc')} 
                saveText={lt('create')}
                onSave={onCreateZone}
                closeDialogAfterSave={true}/>
            {setOpen && <ConnectRuntimeDialog {...{open}} {...{setOpen}} membershipId={node?.membership_id ?? service!.membership_id} {...{runtimeId}} />}
            <ConnectList header={resolvedHeader} description={resolvedDescription} {...{linkButton}} {...{linkButton2}} {...{node}} {...{service}}/>
        </>
    )
};

interface translations {
    connectYourNode: string,
    connectYourNodeDescription: string,
    connectNode: string,
    connectRuntime: string,
    connectRuntimeDescription: string,
    newContract: string,
    newCorDapp: string,
    create: string,
    deploymentZoneCreated: string,
    createDeploymentZone: string,
    createDeploymentZoneDesc: string,
}
const enTranslations: translations = {
    connectYourNode: 'Connect your node',
    connectYourNodeDescription: 'Send transactions to the blockchain network using these endpoints along with valid application credentials.',
    connectNode: 'Connect App',
    connectRuntime: 'Connect App',
    connectRuntimeDescription: 'Connect to this runtime using these endpoints along with valid application credentials.',
    newContract: 'Import Smart Contract',
    newCorDapp: 'Add CorDapp',
    create: 'Create',
    deploymentZoneCreated: 'Deployment Zone Created',
    createDeploymentZone: 'Create Deployment Zone',
    createDeploymentZoneDesc: 'Make your PrivateStack Bridge a deployment zone to run self-managed nodes',
}