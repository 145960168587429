import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, TextField } from '@material-ui/core';
import { PasswordInput } from '../../../components/PasswordInput/PasswordInput';
import { isPasswordCompliant } from '../../../utils/StringUtils';
import { FormDialog } from '../../../components/DialogWrappers';
import { cognitoController } from '../../../utils/cognitoController';
import { useApolloClient, useQuery } from '@apollo/client';
import { SessionData } from '../../../interfaces';
import { SessionQuery } from '../../../queries/Session';
import { StaticConfigData, StaticConfigQuery } from '../../../models';
import { MFATypeData, MFATypeQuery } from '../../../models/mfa';

interface Props {
    changePasswordDialogOpen: boolean,
    setChangePasswordDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProfileSettingsChangePassword: React.FC<Props> = ({ changePasswordDialogOpen, setChangePasswordDialogOpen }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProfileSettingsChangePassword', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProfileSettingsChangePassword:${key}`, interpolate);
    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    const {
        data: {
            staticConfig: {
                zoneRegions: regions
            }
        } = { staticConfig: { zoneRegions: [] } }
    } = useQuery<StaticConfigData>(StaticConfigQuery, { 
        fetchPolicy: 'cache-first'
    });

    const {
        data: {
            mfaType
        } = { mfaType: '' }
    } = useQuery<MFATypeData>(MFATypeQuery, { fetchPolicy: 'cache-and-network' });

    const [currentPassword, setCurrentPassword] = useState('');
    const [mfaCode, setMFACode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(changePasswordDialogOpen) {
            setCurrentPassword('');
            setMFACode('');
            setNewPassword('');
            setConfirmNewPassword('');
            setErrorMessage('');
            setLoading(false);
        }
    }, [changePasswordDialogOpen]);

    const changePasswordControlsWrapper = (
        <Grid container direction="column" spacing={3}>
            {errorMessage &&
            <Grid item>
                <Typography variant="body1" color="error">{errorMessage}</Typography>
            </Grid>}
            <Grid item>
                <PasswordInput
                    fullWidth
                    label={lt('currentPassword')}
                    variant="outlined"
                    value={currentPassword}
                    autoComplete="off"
                    margin="none"
                    onChange={event => setCurrentPassword(event.target.value)}
                />
            </Grid>
            {mfaType === 'totp' &&
            <Grid item>
                <TextField
                    fullWidth
                    label={lt('mfaCode')}
                    variant="outlined"
                    value={mfaCode}
                    autoComplete="off"
                    margin="none"
                    onChange={event => setMFACode(event.target.value)}
                />
            </Grid>}
            <Grid item>
                <PasswordInput
                    fullWidth
                    label={lt('newPassword')}
                    variant="outlined"
                    value={newPassword}
                    autoComplete="new-password"
                    margin="none"
                    onChange={event => setNewPassword(event.target.value)}
                    helperText={newPassword && !isPasswordCompliant(newPassword) && lt('passwordMustComplyWithRequirements')}
                />
            </Grid>
            <Grid item>
                <PasswordInput
                    fullWidth
                    label={lt('confirmNewPassword')}
                    variant="outlined"
                    value={confirmNewPassword}
                    autoComplete="new-password"
                    margin="none"
                    onChange={event => setConfirmNewPassword(event.target.value)}
                    helperText={newPassword !== confirmNewPassword && lt('passwordMismatch')}
                />
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {lt('passwordRequirements')}
                </Typography>
            </Grid>
        </Grid>
    );

    const handleChangePassword = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            await cognitoController.changePassword(session.email, currentPassword, newPassword, regions, mfaCode);
            setChangePasswordDialogOpen(false);
        } catch(err) {
            if(err.code === 'NotAuthorizedException') {
                setErrorMessage(lt('incorrectCurrentPassword'));
            } else {
                setErrorMessage(err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormDialog
            width="xs"
            header={lt('changePassword')}
            open={changePasswordDialogOpen}
            setOpen={setChangePasswordDialogOpen}
            controlsWrapper={changePasswordControlsWrapper}
            saveText={lt('changePassword')}
            saveDisabled={!currentPassword || !isPasswordCompliant(newPassword) || (newPassword !== confirmNewPassword) || loading}
            closeDialogAfterSave={false}
            onSave={handleChangePassword}
            successMessage={lt('passwordChangedSuccessfully')}
        />
    );
}

interface translations {
    changePassword: string
    currentPassword: string
    mfaCode: string
    newPassword: string
    confirmNewPassword: string
    passwordMustComplyWithRequirements: string
    passwordRequirements: string
    passwordMismatch: string
    passwordChangedSuccessfully: string
    incorrectCurrentPassword: string
}

const enTranslations: translations = {
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    mfaCode: 'Multi-Factor Authentication Code',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    passwordMustComplyWithRequirements: 'Password must comply with requirements',
    passwordRequirements: 'Min 8 characters. Must include 1 of each: upper & lower case, number & special character',
    passwordMismatch: 'Passwords must be equal',
    passwordChangedSuccessfully: 'Password changed successfully',
    incorrectCurrentPassword: 'Current password is incorrect'
}