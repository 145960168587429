import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { DocumentsTransfersData, DocumentsTransfersVars, DocumentsTransfersQuery } from '../../../models';
import { useTranslation } from 'react-i18next';
import InsertDriveFileIcon from 'mdi-react/FileIcon';
import {Grid, makeStyles, Typography, Tooltip, Zoom, CircularProgress} from '@material-ui/core';
import { Pagination } from './Pagination';
import { DisplayTable } from '../../../components/DisplayWrappers';
import { ShortenedString } from '../../../components/FormControls/ShortenedString';
import { ResourceStateChip } from '../../../components/FormControls/ResourceStateChip';


export const Transfers: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DocExchangeTransfers', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DocExchangeTransfers:${key}`, interpolate)
    
    const { org_id, service_id, consortium_id, environment_id, } = useParams<any>();
    const classes = useStyles();
    const history = useHistory()
    
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);

    const {
        loading,
        stopPolling,
        data: {
            documentsTransfers : {
                transfers: transfersResults,
                is_truncated
            } = { transfers: [], is_truncated: false},
        } = { documentsTransfers: {} }} = useQuery<DocumentsTransfersData, DocumentsTransfersVars>(DocumentsTransfersQuery, {
            variables: { service_id: service_id!, offset, limit },
            fetchPolicy: 'cache-and-network',
            pollInterval: 5000
    });

    useEffect(() => {
        return () => {
            stopPolling();
        };
    }, [stopPolling]);

    if (!transfersResults && loading) return <CircularProgress />

    const onNextPage = (e : React.MouseEvent)  => {
        e.preventDefault();
        setOffset(c => c + limit);
    };

    const onPreviousPage = (e: React.MouseEvent) => {
        e.preventDefault();
        setOffset(c => c - limit);
    };

    const onLimitChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
        setLimit(event.target.value as number);
    };

    const transferPathCell = (documentName: string) => (
        <Grid container wrap="nowrap" alignItems="center" className={classes.pathCell}>
            <Grid item>
                <InsertDriveFileIcon className={classes.icon}/>
            </Grid>
            <Grid item xs={11}>
                <Tooltip title={<Typography variant="body2">{documentName}</Typography>} arrow enterDelay={300} TransitionComponent={Zoom} placement="top" >
                    <Typography variant="body2" noWrap>{documentName}</Typography>
                </Tooltip>
            </Grid>
        </Grid>
    )

    const records = transfersResults?.map((entry, index) => ({
        key: `${index}`,
        columns: [{
            value: entry.document ? transferPathCell(entry.document) : '--'
        },{
            value: <ResourceStateChip state={entry.status} />
        }, {
            value: entry.hash ? <ShortenedString content={entry.hash} hideCopy />  : '--'
        }, {
            value: entry.to
        }, {
            value: new Date(entry.timestamp).toLocaleString()
        }],
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/b2b/documentstore/${service_id}/transfers/${entry.id}`)
    }));

    const headers = [lt('name'), lt('status'), lt('hash'), lt('receiver'), lt('date')];

    const pagination = records && records.length > 0 ? (
        <Pagination
            initialRow={offset + 1}
            lastRow={transfersResults && transfersResults.length + offset}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            limit={limit}
            onLimitChange={onLimitChange}
            isLastPage={is_truncated} />
    ) : undefined


    return (
        <Grid container spacing={3} direction="column">
            <Grid item>
                <Typography variant="h5">{lt('transfers')}</Typography>
            </Grid>
            <Grid item container>
                <DisplayTable emptyLabel={lt('noTransfers')} columnHeaders={headers} {...{records}} header={lt('transfers')} actionFooter={pagination} />
            </Grid>
        </Grid>
    )
};

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(1),
        display: 'flex',
        alignSelf: 'center'
    },
    pathCell: {
        maxWidth: '20vw'
    },
    statusIcon: {
        display: 'flex',
        alignSelf: 'center'
    }
}));

interface translations {
    sent: string,
    received: string,
    failed: string
    name: string
    hash: string
    size: string
    receiver: string
    noTransfers: string
    transfers: string
    updated: string
    status: string
    date: string
}
const enTranslations: translations = {
    sent: 'Sent',
    status: 'Status',
    date: 'Date',
    transfers: 'Transfers',
    received: 'Received',
    failed: 'Failed',
    name: 'Name',
    updated: 'Updated',
    hash: 'Hash',
    size: 'Size',
    receiver: 'Receiver',
    noTransfers: 'No Transfers Yet'
};