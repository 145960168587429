import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'mdi-react/DeleteIcon';
import SendIcon from 'mdi-react/SendIcon';
import CloudDownloadIcon from 'mdi-react/CloudDownloadIcon';
import { Dotdotdot } from '../../../../components';

interface Props {
    isDirectory: boolean,
    onDownloadClick: (docName: string) => Promise<void>,
    actionDocumentName: string,
    onDeleteClick: (docName: string, isDirectory: boolean) => void,
    onTransferClick: (docName: string) => void,
}

export const DocStoreActionMenu = ({isDirectory, onDownloadClick, actionDocumentName, onDeleteClick, onTransferClick}: Props) => {
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ActionMenu', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ActionMenu:${key}`, interpolate);


    const menuItems = [{
        name: isDirectory ? lt('deleteDirectory') : lt('deleteDocument'),
        icon: <DeleteIcon />,
        action: () => onDeleteClick(actionDocumentName, isDirectory)
    }]

    if(!isDirectory) {
        menuItems.unshift({
            name: lt('sendDocument'),
            icon: <SendIcon />,
            action: () => onTransferClick(actionDocumentName)
        },{
            name: lt('downloadDocument'),
            icon: <CloudDownloadIcon />,
            action: () => onDownloadClick(actionDocumentName)
        })
    }

    return (
        <Dotdotdot {...{menuItems}} />
    )
}

interface translations {
    sendDocument: string,
    downloadDocument: string,
    deleteDirectory: string,
    deleteDocument: string
}

const enTranslations: translations = {
    sendDocument: 'Send Document',
    downloadDocument: 'Download Document',
    deleteDirectory: 'Delete Directory',
    deleteDocument: 'Delete Document'
}