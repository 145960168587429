import { gql, MutationFunctionOptions } from '@apollo/client';
import { PlanName } from './plans';

// INTERFACES
export enum ContactInfoIndustryEnum {
    automotive ='Automotive',
    education = 'Education',
    energy = 'Energy',
    financial = 'Financial',
    government = 'Government',
    healthcare = 'Healthcare',
    insurance = 'Insurance',
    legal = 'Legal',
    mediaAndEntertainment = 'Media & Entertainment',
    nonProfit = 'Non-Profit',
    publishing = 'Publishing',
    realState = 'Real Estate',
    retailAndConsumerGoods = 'Retail & Consumer Goods',
    supplyChain = 'Supply Chain',
    technology = 'Technology',
    travelEndTransportation = 'Travel and Transportation',
    other = 'Other'
};

export enum ContactInfoJobTitleEnum {
    businessLead = 'Business Lead',
    executiveCLevel = 'Executive/C-level',
    innovationLead = 'Innovation Lead',
    architect = 'Architect',
    developer = 'Developer',
    projectManager = 'Project Manager',
    operations = 'Operations',
    student = 'Student',
    consultant = 'Consultant',
};

export enum  ContactInfoJobFunctionEnum {
    student = 'Student',
    interestedInKaleidoCareers = 'Interested in Kaleido careers',
    potentialTechPartner = 'Potential tech parnter',
    consultantReadyForPoC = 'Consultant Ready for PoC',
    readyForPilot = 'Ready for pilot',
    readyForProduction = 'Ready for production',
    developerResearch = 'Developer research',
    comparingAvailablePlatforms = 'Comparing available platforms',
    other = 'Other'
}

//Way to get ContactInfoIndustryEnum keys as translation type based on typescrip issues https://github.com/microsoft/TypeScript/issues/24220#issuecomment-573716981
type IndustryEnumType = {
    [key in keyof typeof ContactInfoIndustryEnum]: string
}

type JobTitleEnumType = {
    [key in keyof typeof ContactInfoJobTitleEnum]: string
}

type JobFunctionEnumType = {
    [key in keyof typeof ContactInfoJobFunctionEnum]: string
}

export interface ContactInfoIndustryTranslationsInterface extends IndustryEnumType {}

export interface ContactInfoJobTitleTranslationsInterface extends JobTitleEnumType {};

export interface ContactInfoJobFunctionTranslationsInterface extends JobFunctionEnumType {};

export interface ContactInfoTranslationsInterface extends ContactInfoJobTitleTranslationsInterface, ContactInfoIndustryTranslationsInterface, ContactInfoJobFunctionTranslationsInterface {};

export const ContactInfoJobTitleTranslations: ContactInfoJobTitleTranslationsInterface = {
    executiveCLevel: 'Executive/C-level',
    businessLead: 'Business Lead',
    innovationLead: 'Innovation Lead',
    architect: 'Architect',
    developer: 'Developer',
    projectManager: 'Project Manager',
    operations: 'Operations',
    student: 'Student',
    consultant: 'Consultant',
}

export const ContactInfoJobFunctionTranslations: ContactInfoJobFunctionTranslationsInterface = {
    interestedInKaleidoCareers: 'Interested in Kaleido careers',
    potentialTechPartner: 'Potential tech partner',
    consultantReadyForPoC: 'Consultant Ready for PoC',
    readyForPilot: 'Ready for pilot',
    readyForProduction: 'Ready for production',
    developerResearch: 'Developer research',
    comparingAvailablePlatforms: 'Comparing available platforms',
    student: 'Student',
    other: 'Other'
}

export const ContactInfoIndustryTranslations: ContactInfoIndustryTranslationsInterface = {
    automotive: 'Automotive',
    education: 'Education',
    energy: 'Energy',
    financial: 'Financial',
    government: 'Government',
    healthcare: 'Healthcare',
    insurance: 'Insurance',
    legal: 'Legal',
    mediaAndEntertainment: 'Media & Entertainment',
    nonProfit: 'Non-Profit',
    publishing: 'Publishing',
    realState: 'Real Estate',
    retailAndConsumerGoods: 'Retail & Consumer Goods',
    supplyChain: 'Supply Chain',
    technology: 'Technology',
    travelEndTransportation: 'Travel & Transportation',
    other: 'Other',
}

export const ContactInfoTranslations = {...ContactInfoIndustryTranslations, ...ContactInfoJobTitleTranslations, ...ContactInfoJobFunctionTranslations}

export interface ContactInfoProperties {
    company?: string
    company_size?: string
    firstname?: string
    industry?: keyof typeof ContactInfoIndustryEnum | ''
    job_function?: keyof typeof ContactInfoJobFunctionEnum | ''
    jobtitle?: keyof typeof ContactInfoJobTitleEnum | ''
    lastname?: string
    phone?: string
}

export interface ContactInfo extends ContactInfoProperties {
    _id: string
}

export interface ContactInfoData {
    contactInfo: ContactInfo
}

interface UpdateContactInfoProperties extends ContactInfoProperties {
    kaleido_account_region?: string
    kaleido_email_confirmed?: boolean
    kaleido_owned_org?: string
    kaleido_sign_in_type?: "Google" | "Kaleido"
    kaleido_plan_at_signup?: PlanName
    account_signup_started?: boolean
    account_signup_completed?: boolean
    lead_create_type?: "Account Signup"
}

export interface HigherAccessRequestData {
    requestHigherAccess: boolean
}

export interface HigherAccessRequestFields {
    type: string;
    source: string;
    hubspotutk: string;
}

export interface SubmitHigherAccessRequestFormVars {
    formFields: HigherAccessRequestFields
}

export interface UpdateContactInfoVars {
    properties: UpdateContactInfoProperties
}

export interface UpdateContactInfoData {
    updateContactInfo: boolean
}

// QUERIES & SUBSCRIPTIONS

export const ContactInfoQuery = gql`
    query getContactInfo {
        contactInfo {
            _id
            company
            company_size
            firstname
            industry
            job_function
            jobtitle
            lastname
            phone
        }
    }`;

export const UpdateContactInfoMutation = gql`
    mutation updateContactInfo($properties: ContactInfoProperties!) {
        updateContactInfo(properties: $properties)
    }`;

export const SubmitHigherAccessMutation = gql`
    mutation requestHigherAccess($formFields: HigherAccessRequestFields!) {
        requestHigherAccess(formFields: $formFields)
    }`;

export function MakeUpdateContactInfoMutationOptions(
    variables: UpdateContactInfoVars,
    overrideOptions?: MutationFunctionOptions<UpdateContactInfoData, UpdateContactInfoVars>): MutationFunctionOptions<UpdateContactInfoData, UpdateContactInfoVars> {
    return {
        ...{
            variables,
            update(cache, { data }) {
                if (data?.updateContactInfo) {
                    const { contactInfo } = cache.readQuery<ContactInfoData>({ query: ContactInfoQuery })!;
                    if (contactInfo) {
                        cache.writeQuery({
                            query: ContactInfoQuery,
                            data: { contactInfo: { ...contactInfo, ...variables.properties } }
                        });
                    }
                }
            }
        }, ...overrideOptions
    }
}