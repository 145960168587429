import React from 'react';
import { useQuery } from '@apollo/client';
import { ServicesData, ServicesQuery } from '../../models';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { NavPopoverItem } from '../MainNav/SideNavs/NavPopoverItem';
import { BLOCKCHAIN_BASE_PATH, BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH } from '../MainNav/SideNavs/Blockchain';
import { ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH, ADDRESSBOOK_PATH } from '../MainNav/SideNavs/AddressBook';

const CertificateAuthorityPicker: React.FC = () => {
    const history = useHistory();
    const { org_id, consortium_id, environment_id, service_id } = useParams<any>();
    const { pathname } = useLocation();
    const addressBookMode = pathname.includes(`${ADDRESSBOOK_PATH}`);
    
    const {
        data: { services } = { services: [] },
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id,
        },
        fetchPolicy: "cache-only",
    });

    let certificateAuthorities = services.filter((entry) => entry.service === 'fabric-ca');
    if (!addressBookMode) {
        certificateAuthorities = services.filter((entry) => entry.membership.isMine);
    }
    const orgName = certificateAuthorities.find(n => n._id === service_id)?.name;

    const casForDropdown = certificateAuthorities.map(c => ({ id: c._id, name: c.name })).filter(c => c.id !== service_id);
    const servicePath = addressBookMode ?  `${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}` : `${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}`;
    let popoverItems = casForDropdown.map(m => ({
        name: m.name,
        action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${servicePath}/${m.id}`)
    }))

    const item = {
        name: orgName || '', 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />
};
export { CertificateAuthorityPicker };

