import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { CreateCompiledContractData, CreateCompiledContractVars, SupportedEvmVersions,
    CreateCompiledContractMutation, MakeCompiledContractCreateMutationOptions } from '../../models';
import { ErrorSnackbarCatcher, CreateWrapper } from '../../components/DialogWrappers'
import { Typography, Grid, TextField } from "@material-ui/core";
import { MembershipSelector } from '../../components/FormControls/MembershipSelector'
import { CompilerAndEvm } from './CompilerAndEvm'
import { CreateStepProps } from '../../interfaces';

export type GithubCompilationProps = { 
    description?: string, 
    membershipId?: string, 
    githubUrl?: string, 
    contractToCompile?: string, 
    solcVersion?: string, 
    evmVersion?: SupportedEvmVersions | "" 
}

interface Props extends GithubCompilationProps, CreateStepProps {
    contract_id: string
    setMembershipId?: React.Dispatch<React.SetStateAction<string>> // optional prop if you want to use this forms membershipId to call back and set your own
    setMessage: React.Dispatch<React.SetStateAction<string>>
    loading?: boolean,
    saving?: boolean,
    preSave?: () => Promise<string>
    postSave: (compiledContractId: string) => Promise<void>
    isFirstStep?: boolean
    isLastStep?: boolean
}

export const Github = (
    { 
        contract_id,
        setMessage,
        loading,
        saving,
        description: defaultDescription, 
        membershipId: defaultMembershipId, 
        setMembershipId: setPreSaveMembershipId, 
        githubUrl: defaultGithubUrl, 
        contractToCompile: defaultContractToCompile, 
        solcVersion: defaultSolcVersion, 
        evmVersion: defaultEvmVersion,
        cancelPath,
        preSave,
        postSave,
        isFirstStep,
        isLastStep,
    }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractManagementGithub', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractManagementGithub:${key}`, interpolate)

    const { consortium_id } = useParams<any>();


    const [description, setDescription] = useState(defaultDescription || '');
    const [githubUrl, setGithubUrl] = useState(defaultGithubUrl || '');
    const [contractToCompile, setContractToCompile] = useState(defaultContractToCompile || '');
    const [githubAccessToken, setGithubAccessToken] = useState('');
    const [membershipId, setMembershipId] = useState(defaultMembershipId || '');
    const [solcVersion, setSolcVersion] = useState(defaultSolcVersion || '0');
    const [evmVersion, setEvmVersion] = useState<SupportedEvmVersions>(defaultEvmVersion || "constantinople");

    const [createCompiledContract, { loading: createCompiledContractLoading }] = 
        useMutation<CreateCompiledContractData, CreateCompiledContractVars>(CreateCompiledContractMutation)

    useEffect(( )=> {
        setDescription(defaultDescription || '')
    }, [defaultDescription])

    useEffect(() => {
        if (setPreSaveMembershipId) setPreSaveMembershipId(membershipId)
    }, [membershipId, setPreSaveMembershipId])

    const save = async () => {
        const empty = () => new Promise<string>(function(resolve) {
            resolve('');
         });
        (preSave ? preSave : empty)()
            .then(newProjectId => {
                createCompiledContract(MakeCompiledContractCreateMutationOptions({
                    consortia_id: consortium_id!,
                    contract_id: newProjectId || contract_id!,
                    compiledContract: {
                        membership_id: membershipId,
                        description,
                        contract_url: githubUrl,
                        contract_name: contractToCompile,
                        oauth_token: githubAccessToken,
                        solc_version: solcVersion !== "0" ? solcVersion : '',
                        evm_version: evmVersion
                    }
                })).then(result => {
                    if (result) {
                        const newCompilationId = result.data?.createCompiledContract?._id
                        if (newCompilationId) {
                            postSave(newCompilationId)
                        }
                    }
                }).catch(e => {
                    ErrorSnackbarCatcher(e, setMessage)
                })
            })
            .catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
            })
    }

    const disabled = loading || createCompiledContractLoading || !membershipId || !githubUrl || !description

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item >
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            <Grid item>
                <TextField 
                    required
                    multiline
                    rows={2}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <Typography variant="h5">
                    {lt('githubDetails')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('githubDetailsDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField 
                    required
                    value={githubUrl} 
                    onChange={event => setGithubUrl(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('githubUrl')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField 
                    value={contractToCompile} 
                    onChange={event => setContractToCompile(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('contractToCompile')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField 
                    value={githubAccessToken} 
                    onChange={event => setGithubAccessToken(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('githubAccessToken')}
                    variant="outlined"
                />
            </Grid>

            <CompilerAndEvm {...{evmVersion}} {...{setEvmVersion}} {...{solcVersion}} {...{setSolcVersion}} />
        </>
    )

    return (
        <>
            <CreateWrapper {...{saving}} cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep={isFirstStep} isLastStep={isLastStep} />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    description: string,
    githubDetails: string,
    githubDetailsDescription: string,
    github: string,
    githubDescription: string,
    githubUrl: string,
    precompiled: string,
    precompiledDescription: string,
    contractToCompile: string,
    githubAccessToken: string,
}
const enTranslations: translations = {
    header: 'Enter Version Details',
    headerDescription: 'Select the Membership who will own this version and give this specific version a description.',
    description: 'Description',
    githubDetails: 'Enter Github information',
    githubDetailsDescription: 'Kaleido requires the specific Github URL to your Solidity file. This URL must end with ".SOL". Gist\'s and raw formats are not currently supported.',
    github: 'Use Github',
    githubDescription: 'My project source code is stored in a Github repository (supports Truffle projects!)',
    githubUrl: 'Github URL to solidity file',
    precompiled: 'Bring my own source',
    precompiledDescription: 'I will upload my source code files and/or compile the code myself',
    contractToCompile: 'Contract to be compiled',
    githubAccessToken: 'Github personal access token',
}

