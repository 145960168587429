import React, { useState } from 'react';
import { FormDialog } from '../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@material-ui/core';

interface Props {
    fromAccount: string | undefined,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const TransferDialog = ({open, setOpen, fromAccount}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenTransferDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenTransferDialog:${key}`, interpolate);

    const [amount, setAmount] = useState('');
    const [to, setTo] = useState('');

    const onSave = async () => console.log('saved');

    const content = (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="body2">{`${lt('from')}: ${fromAccount}`}</Typography>
            </Grid>
            <Grid item>
                <TextField variant="outlined" fullWidth label={lt('to')} value={to} onChange={e => setTo(e.target.value)}  />
            </Grid>
            <Grid item>
                <TextField variant="outlined" fullWidth label={lt('amount')} value={amount} onChange={e => setAmount(e.target.value)}  />
            </Grid>
        </Grid>
    )



    return <FormDialog header={lt('header')} {...{onSave}} {...{open}} {...{setOpen}} controlsWrapper={content} saveText={lt('transfer')} />
}

interface translations {
    header: string,
    from: string,
    to: string,
    amount: string
    transfer: string
}
const enTranslations: translations = {
    header: 'Transfer Token',
    from: 'From',
    to: 'Target Address',
    amount: 'Transfer Amount',
    transfer: 'Transfer'
}