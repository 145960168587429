import React from 'react';
import { FormDialog } from '../../components/DialogWrappers';
import { ResetNodeMutation, UpdateNodeMutation, UpdateNodeVars, ServiceDetails, NodeConfigs, Service, Node, ResetServiceMutation, UpdateServiceMutation, UpdateServiceVars } from '../../models';
import { EnvironmentResourceVars } from '../../interfaces';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigTypesUrl } from '../../components/MainNav/SideNavs/CloudConfigs';
import { instanceOfService } from '../../utils/TypeUtils';

interface Props {
    runtime: Service | Node,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    configType: ConfigTypesUrl
}

export const DetachConfig = ({ runtime, open, setOpen, configType}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DetachConfig', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DetachConfig:${key}`, interpolate);
    
    const [resetNode] = useMutation<any, EnvironmentResourceVars>(ResetNodeMutation);
    const [updateNode] = useMutation<any, UpdateNodeVars>(UpdateNodeMutation);

    const [resetService] = useMutation<any, EnvironmentResourceVars>(ResetServiceMutation);
    const [updateService] = useMutation<any, UpdateServiceVars>(UpdateServiceMutation);

    const { environment_id, consortium_id } = useParams<any>();

    const detailsForm: Map<ConfigTypesUrl | undefined, ServiceDetails | NodeConfigs>= new Map([
        ['backup', { backup_id: null }],
        [ 'logstream', { opsmetric_id: null}],
        [ 'keystore', { kms_id: null}],
        [ 'networking', { networking_id: null}],
        [ 'storage', { storage_id: null}],
        [ 'baf', { baf_id: null}],
        [ 'storage', {storage_id: null}],
        [ 'cloudhsm', {cloudhsm_id: null}],
        [ 'configuration', {node_config_id: null}]
    ])

    const mutationVariables = {
        environment_id: environment_id!,
        consortia_id: consortium_id!
    }

    const detachFromNode = async (details?: ServiceDetails | NodeConfigs) => {
        await updateNode({
            variables: {
                ...mutationVariables,
                id: runtime._id,
                node: {
                    ...details
                }
            }
        });
        await resetNode({
            variables: {
                ...mutationVariables,
                id: runtime._id
            }
        });
    }

    const detachFromService = async (details?: ServiceDetails) => {
        await updateService({
            variables: {
                ...mutationVariables,
                id: runtime._id,
                service: {
                    details: {
                        ...details
                    }
                }
            }
        });
        await resetService({
            variables: {
                ...mutationVariables,
                id: runtime._id
            }
        });
    }

    const onSubmit = async () => {
        const details = detailsForm.get(configType as ConfigTypesUrl);
        if (instanceOfService(runtime)) {
            await detachFromService(details as ServiceDetails)
        } else {
            await detachFromNode(details)
        }
    }

    return (
        <FormDialog {...{open}} {...{setOpen}} onSave={onSubmit} header={lt('header')} description={lt('description')} saveText={lt('detach')} closeDialogAfterSave/>
    )
}

interface translations {
    header: string,
    description: string,
    detach: string
}

const enTranslations: translations = {
    header: 'Detach Configuration',
    description: 'In order to detach configuration we will have to reset the node, do you want to continue?',
    detach: 'Detach'
}