import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const StepHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateCloudHsmWalletHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateCloudHsmWalletHelp:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-NodeSummary.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/kaleido-services/cloudhsm/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string
}
const enTranslations: translations = {
    helpHeader: 'Cloud HSM Wallets',
    helpDescription: 'Kaleido offers a CloudHSM Signer service that allows you to sign transactions using off-platform keys, while benefiting from a fully-managed signing experience including nonce management and REST APIs. The CloudHSM Signer service supports the following backends:',
    helpDesc1: 'AWS CloudhHSM',
    helpDesc2: 'Azure Key Vault',
    helpDesc3: 'Hashicorp Vault with Ethereum Signing extension'
}