import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { EnvironmentResourceVars, EnvironmentResourcesVars } from '../interfaces'
import { MembershipOwnedResource } from './memberships';
import { RuntimeSize } from './nodes';
import { FeatureToggles, Environment } from '.';
import { FireflyTokenPluginNames } from './firefly';

export type ServiceState = "provisioning" | "failed" | "deploying" | "started" | "paused" | "upgrading" | "deprovisioning" | 
"delete_failed" | "archived" | "locked" | "deleted";

// key must match the cplane service name and = the value
export enum ServicesEnum {
    rotatesigners = 'rotatesigners',
    idregistry = 'idregistry',
    tether = 'tether',
    openlaw = 'openlaw',
    chainlink = 'chainlink',
    app2app = 'app2app',
    documentstore = 'documentstore',
    ipfs = 'ipfs',
    hdwallet = 'hdwallet',
    ethwallet = 'ethwallet',
    tokenfactory = 'tokenfactory',
    tokenswap = 'tokenswap',
    tokenzkp = 'tokenzkp',
    tunneler = 'tunneler',
    cloudhsm = 'cloudhsm',
    'fabric-ca' = 'fabric-ca',
    'firefly-os' = 'firefly-os',
    dataexchange = 'dataexchange'
}

export type Services = keyof typeof ServicesEnum

// services only get added to below sets when we have implemented dashboards for them
// (ignoring whether the feature toggle is set or not)\
export const B2bDocumentsServices = new Set<Services>([ServicesEnum.documentstore])
export const B2bMessagingServices = new Set<Services>([ServicesEnum.app2app])
export const ManagedWalletServices = new Set<Services>([ServicesEnum.ethwallet, ServicesEnum.hdwallet]) 
export const CloudWalletServices = new Set<Services>([ServicesEnum.cloudhsm]);

export const BlockchainServices = new Set<Services>([ServicesEnum.chainlink,ServicesEnum.ipfs, ServicesEnum.idregistry, ServicesEnum.tether, ServicesEnum.rotatesigners, ServicesEnum['firefly-os']]);
export const B2bServices = new Set<Services>([...B2bDocumentsServices, ...B2bMessagingServices])
export const KeyManagementServices = new Set<Services>([...ManagedWalletServices, ...CloudWalletServices])

export const SizeSupportedServices = new Set<Services>([...B2bDocumentsServices, ...B2bMessagingServices, ServicesEnum.ipfs, ServicesEnum.hdwallet])

export const ServiceToFeatureMap: { [key in keyof typeof ServicesEnum]: keyof FeatureToggles | undefined } = {
    rotatesigners: undefined,
    idregistry: undefined,
    tether: undefined,
    openlaw: undefined,
    chainlink: 'chainlink',
    app2app: 'app2app',
    documentstore: 'docExchange',
    ipfs: 'ipfs',
    hdwallet: undefined,
    ethwallet: 'keyManagement',
    tokenfactory: undefined,
    tokenswap: undefined,
    tokenzkp: undefined,
    tunneler: 'tunneler',
    cloudhsm: 'keyManagement',
    'fabric-ca': undefined,
    'firefly-os': undefined,
    dataexchange: undefined
}

export const environmentSupportsRotateSigners = (env?: Environment | null) => {
    return env?.consensus_type === 'ibft'
}

export const getManageServiceSegments = (
    s: Service, 
    featureToggles: FeatureToggles, 
    blockchainBasePath: string, // probably worth refactoring this when time allows as its ugly, but its needed to avoid circular dependencies
    b2bBasePath: string, 
    keyManagementBasePath: string, 
    keyManagementCloudPath: string, 
    keyManagementManagedPath: string) => {
    if (!s.membership.isMine) return undefined

    let bundleSegment = ''
    if (BlockchainServices.has(s.service)) {
        bundleSegment = blockchainBasePath
    } else if (B2bServices.has(s.service)) {
        bundleSegment = b2bBasePath
    } else if (CloudWalletServices.has(s.service)) {
        bundleSegment = `${keyManagementBasePath}/${keyManagementCloudPath}`
    } else if (ManagedWalletServices.has(s.service)) {
        bundleSegment = `${keyManagementBasePath}/${keyManagementManagedPath}`
    } else {
        // dont return the link if we couldnt find the bundleSegment
        // since this can return undefined, the caller will have to handle the backup link path - which should be to address book
        return undefined 
    }

    const serviceFeatureToggle = ServiceToFeatureMap[s.service]
    
    if (!serviceFeatureToggle || featureToggles[serviceFeatureToggle]) {
        return `${bundleSegment}/${s.service}/${s._id}`
    }
}

export enum BundlesEnum {
    AppsIntegrations = 'AppsIntegrations',
    B2b = 'B2b',
    DigitalAssets = 'DigitalAssets',
    Blockchain = 'Blockchain',
    ManagedWallets = 'ManagedWallets', 
    CloudWallets = 'CloudWallets'
}

export type ServicesTranslations = {
    [key in keyof typeof ServicesEnum]: string
}

export const EnServicesTranslations: ServicesTranslations = {
    [ServicesEnum.documentstore]: 'Document Exchange',
    [ServicesEnum.rotatesigners]: 'Rotate Signers',
    [ServicesEnum.idregistry]: 'ID Registry',
    [ServicesEnum.tether]: 'Tether',
    [ServicesEnum.openlaw]: 'OpenLaw',
    [ServicesEnum.chainlink]: 'Chainlink',
    [ServicesEnum.app2app]: 'App2App',
    [ServicesEnum.ipfs]: 'IPFS',
    [ServicesEnum.hdwallet]: 'HD Wallets',
    [ServicesEnum.ethwallet]: 'Kaleido Wallet',
    [ServicesEnum.tokenfactory]: 'Token Factory',
    [ServicesEnum.tokenswap]: 'Token Swap',
    [ServicesEnum.tokenzkp]: 'Token ZKP',
    [ServicesEnum.tunneler]: 'Network Bridge',
    [ServicesEnum.cloudhsm]: 'Cloud HSM',
    [ServicesEnum['fabric-ca']]: 'Certificate Authority',
    [ServicesEnum['firefly-os']]: 'FireFly',
    [ServicesEnum['dataexchange']]: 'FireFly Enterprise Data Exchange'
}

export type ServiceConfigTypes = 'storage' | 'cloudhsm' | 'kms' | 'backup';

export interface ServiceDetails extends FabricCaServiceDetails {
    storage_id?: string
    kms_id?: string
    backup_id?: string
    cloudhsm_id?: string
    node_id?: string
    ipfs_service_id?: string
    tokens_plugin_name?: FireflyTokenPluginNames
}

export interface FabricCaServiceDetails {
    external_key?: string
    external_cert?: string
    enroll_with_ca?: {
        parentserver: {
            url: string
            caname?: string
        }
        enrollment?: {
            hosts?: string
            profile?: string
            label?: string
        }
        tls?: {
            certfiles?: string[]
            client?: {
                certfile?: string
                keyfile?: string
            }
        }
    }
}

export interface Service extends MembershipOwnedResource {
    _id: string
    consortia_id: string
    environment_id: string
    name: string
    size: RuntimeSize
    service: keyof typeof ServicesEnum
    service_type: 'utility' | 'member' | 'member-utility'
    service_guid: string
    urls?: { http: string, ws: string, webui?: string, swaggerui?: string, sandboxui?: string }
    state: ServiceState
    details: ServiceDetails
    zone_id: string
    created_at: string
    updated_at: string
}

export interface ServiceData {
    service: Service
}

export interface UpdateServiceVars extends EnvironmentResourceVars {
    service: {
        name?: Service['name'],
        details?: ServiceDetails
        size?: RuntimeSize
    }
}

export interface CreateServiceVars extends EnvironmentResourcesVars {
    service: {
        service: string
        membership_id: string
        zone_id?: string
        name: string
        details?: ServiceDetails 
        size?: RuntimeSize
    }
}

export interface OpsServicesVars {
    query: string;
}

export interface CreateServiceData {
    createService: Service
}

export interface ServicesData {
    services: Service[]
}

export interface OpsServicesData {
    opsServices: Service[]
}

export interface ServiceLogTypesData {
    serviceLogTypes: string[]
}

export const ServiceFields = ` 
    fragment ServiceFields on Service {
        _id
        consortia_id
        environment_id
        membership_id
        name
        size
        service
        service_type
        service_guid
        urls
        state
        details {
            storage_id
            kms_id
            backup_id
            cloudhsm_id
            node_id
            ipfs_service_id
            tokens_plugin_name
            external_key
            external_cert
            enroll_with_ca
        }
        zone_id
        size
        created_at
        updated_at
        membership @client
    }`

export const ServicesQuery = gql`
    ${ServiceFields}
    query getServices($consortia_id: String!, $environment_id: String!) {
        services(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...ServiceFields
        }
    }`

export const OpsServicesQuery = gql`
    ${ServiceFields}
    query getOpsServices($query: String!) {
        opsServices(query: $query) {
            ...ServiceFields
        }
    }`

export const ServiceQuery = gql`
    ${ServiceFields}
    query getService($consortia_id: String!, $environment_id: String!, $id: String!) {
        service(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...ServiceFields
        }
    }`

export const CreateServiceMutation = gql`
    ${ServiceFields}
    mutation createService($consortia_id: String!, $environment_id: String!, $service: CreateServiceInput!) {
        createService(consortia_id: $consortia_id, environment_id: $environment_id, service: $service) {
            ...ServiceFields
        }
    }`

export const UpdateServiceMutation = gql`
    ${ServiceFields}
    mutation updateService($consortia_id: String!, $environment_id: String!, $id: String!, $service: UpdateServiceInput!) {
        updateService(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, service: $service) {
            ...ServiceFields
        }
    }`;
    
export const RestartServiceMutation = gql`
    mutation restartService($consortia_id: String!, $environment_id: String!, $id: String!) {
        restartService(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export const ResetServiceMutation = gql`
    ${ServiceFields}
    mutation resetService($consortia_id: String!, $environment_id: String!, $id: String!) {
        resetService(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...ServiceFields
        }
    }`;

export const DeleteServiceMutation = gql`
    ${ServiceFields}
    mutation deleteService($consortia_id: String!, $environment_id: String!, $id: String!) {
        deleteService(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...ServiceFields
        }
    }`;

export const ServicesSubscription = gql`
    ${ServiceFields}
    subscription onServicesChanged($consortia_id: String!, $environment_id: String!) {
        service(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...ServiceFields
        }
    }`

export const ServiceLogTypesQuery = gql`
    query serviceLogTypes($consortia_id: String!, $environment_id: String!, $id: String!) {
        serviceLogTypes(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) 
    }`

export function MakeServiceCreateMutationOptions(
    variables: CreateServiceVars,
    overrideOptions?: MutationFunctionOptions<CreateServiceData, CreateServiceVars>): MutationFunctionOptions<CreateServiceData, CreateServiceVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const service = data?.createService
            if (service) {
                const { services } = cache.readQuery<ServicesData>({ query: ServicesQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!services.find(s => s._id === service._id)) {
                    cache.writeQuery({
                        variables,
                        query: ServicesQuery,
                        data: { services: services.concat(service) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

export function MakeServicesSubscriptionOptions(
    variables: EnvironmentResourcesVars,
    overrideOptions?: SubscriptionHookOptions<ServiceData>
    ): SubscriptionHookOptions<ServiceData> {
        return { ...{
            variables,
            onSubscriptionData: ({client, subscriptionData}) => {
                // Get the service object from the event
                const { 
                    data: { 
                        service
                    } = { service: null }
                } = subscriptionData
    
                if (!service) return
    
                // Get current list of services from the cache
                // assert that services must exist since this function throws if it doesnt
                const { services: existingServices } = client.readQuery<ServicesData>({ 
                    query: ServicesQuery, 
                    variables 
                })!
                
                // if it already exists in the store, (its an update), apollo automagically updated the cache
                const cachedServices = existingServices.find(c => c._id === service._id)
    
                // but, if it came back as deleted, we need to remove it from the cache manually
                if (cachedServices) {
                    if (cachedServices.state === 'deleted') {
                        client.cache.evict({id: `Service:${cachedServices._id}`, broadcast: false})
                    }
                    client.writeQuery<ServicesData>({ 
                        query: ServicesQuery, 
                        data: Object.assign({}, existingServices, {
                            services: [...existingServices.filter(c => c.state !== 'deleted')]
                        }),
                        variables
                    })
                    return 
                }
    
                // in case of duplicate events, never add a deleted consortium into the cache
                if (service.state === 'deleted') return
    
                // if it doesnt exist in the store, (it's been added), so we need to add it in manually
                client.writeQuery<ServicesData>({ 
                    query: ServicesQuery, 
                    data: Object.assign({}, existingServices, {
                        services: [service, ...existingServices]
                    }),
                    variables
                })
            }
        }, ...overrideOptions }
}
