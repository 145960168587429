import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers';

export const Step4Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep4Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep4Help:${key}`, interpolate)
    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-StreamDetail.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#user-created-channels"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string
}
const enTranslations: translations = {
    helpHeader: 'Fabric Policies',
    helpDescription: 'Policies assign certain rules on how members should make network-level decisions together. Everything done on a Fabric network is controlled by a policy. For instance, they can be used to specify the number of organizations required to endorse a smart contract.'
}