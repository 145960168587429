import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect, useLocation } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { NETWORK_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { Protocol } from '../../../models';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProject', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProject:${key}`, interpolate)

    const { pathname } = useLocation()
    const { org_id, consortium_id, step } = useParams<any>();
    
    const [protocol, setProtocol] = useState<Protocol>('ethereum');
    
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}`

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const invalidStep = step !== "1" && step !== "2"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep > 1) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: lt('protocol'), component: <Step1 {...{protocol}} {...{setProtocol}} cancelPath={cancelPath} /> },
        { step: lt('settings'), component: <Step2 {...{protocol}}  cancelPath={cancelPath} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createContractProject')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createContractProject: string,
    protocol: string,
    settings: string,
}
const enTranslations: translations = {
    createContractProject: 'Create an App',
    protocol: 'Protocol',
    settings: 'Settings',
}