import { Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { Region } from '../../interfaces';
import { ConsortiaTable } from './ConsortiaTable';

interface Props {
    regions?: Region[]
}

export const OpsConsortia: React.FC<Props> = ({regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsConsortia', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsConsortia:${key}`, interpolate)

    const [message, setMessage] = useState('');
    const [idFilter, setIDFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [stateFilter, setStateFilter] = useState('');
    const [tagsFilter, setTagsFilter] = useState('');

    const filterBar = <Grid container spacing={2} alignItems="center" alignContent="stretch">
        <Grid item xs>
            <Typography variant="h5">{lt('filter')}</Typography>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('id')} 
                value={idFilter} 
                variant="outlined" 
                onChange={e => setIDFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('name')} 
                value={nameFilter} 
                variant="outlined" 
                onChange={e => setNameFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('state')} 
                value={stateFilter} 
                variant="outlined" 
                onChange={e => setStateFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('tags')} 
                value={tagsFilter} 
                variant="outlined" 
                onChange={e => setTagsFilter(e.target.value)}/>
        </Grid>
    </Grid>;

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <Grid container direction="column" spacing={3}>
            <Grid item>
                {filterBar}
            </Grid>
            {regions?.map(({code, cloud, region}) => (
                <Grid item container direction="column" spacing={3} key={`${code}-${cloud}-${region}`}>
                    <Grid item>
                        <Typography variant="h5">{`${cloud}: ${region}`}</Typography>
                    </Grid>
                    <Grid item>
                        <ConsortiaTable region={code} {...{setMessage}} {...{idFilter}} {...{nameFilter}} {...{stateFilter}} {...{tagsFilter}}/>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </>;
};

interface translations {
    filter: string;
    id: string;
    name: string;
    state: string;
    tags: string;
}
const enTranslations: translations = {
    filter: 'Filter:',
    id: 'ID',
    name: 'Name',
    state: 'State',
    tags: 'Tags',
}
