import { gql } from '@apollo/client';
import { Release, ReleaseFields } from './releases'

export type HardforkEIPs = "constantinopleBlock" | "petersburgBlock" | "istanbulBlock" | "constantinopleFixBlock" | "muirGlacierBlock" | "berlinBlock"

interface EnvironmentHealth {
    all_nodes_up: boolean
    highest_block_height: number
    lowest_block_height: number
    nodes_down: number
    nodes_up: number
    stopped_nodes: number
    all_hosted_nodes_up: boolean
}

interface EnvironmentUpgrade {
    available: boolean
    require_hard_fork_upgrade: boolean
    current_release: Release
    chain_config: EnvironmentChainConfig
    optional_hardfork_eips: HardforkEIPs[]
    prereq_hardfork_eips: HardforkEIPs[]
    target_release: Release
}

export interface EnvironmentChainConfig {
    homesteadBlock?: number
    eip150Block?: number
    eip155Block?: number
    eip158Block?: number
    byzantiumBlock?: number
    constantinopleFixBlock?: number
    constantinopleBlock?: number
    petersburgBlock?: number
    istanbulBlock?: number
    muirGlacierBlock?: number
    berlinBlock?: number
    londonBlock?: number
}

export interface EnvironmentStatus {
    _id: string
    health: EnvironmentHealth
    upgrade: EnvironmentUpgrade
    backup?: {
        in_progress: boolean
        latest: string
    }
}

export interface EnvironmentStatusData {
    environmentStatus: EnvironmentStatus
}

export const EnvironmentStatusFields = ` 
    ${ReleaseFields}
    fragment EnvironmentStatusFields on EnvironmentStatus {
        _id
        health {
            all_nodes_up
            highest_block_height
            lowest_block_height
            nodes_down
            nodes_up
            nodes_stopped
            all_hosted_nodes_up
        }
        upgrade {
            available
            require_hard_fork_upgrade
            current_release {
                ...ReleaseFields
            }
            prereq_hardfork_eips
            optional_hardfork_eips
            chain_config {
                homesteadBlock
                eip150Block
                eip155Block
                eip158Block
                byzantiumBlock
                constantinopleBlock
                constantinopleFixBlock
                petersburgBlock
                istanbulBlock
                muirGlacierBlock
                berlinBlock
                londonBlock
            }
            target_release {
                ...ReleaseFields
            }
        }
        backup {
            in_progress
            latest
        }
    }`

export const EnvironmentStatusQuery = gql`
    ${EnvironmentStatusFields}
    query getEnvironmentStatus($consortia_id: String!, $id: String!) {
        environmentStatus(consortia_id: $consortia_id, id: $id) {
            ...EnvironmentStatusFields
        }
    }`;    

