import React from 'react';
import { Collapse, List } from '@material-ui/core';
import { SubNavItem } from  './SubNavItem'

export interface LeftNavTab {
    name: string, 
    action?: () => void,
    popoverItems?: {
        name: string, 
        action: () => void,
        divider?: boolean,
        disabled?: boolean
    }[],
    comingSoon?: boolean
    disabled?: boolean
}

interface Props {
    list: Array<LeftNavTab>,
    selected: boolean, 
    selectedItem?: string
};

export const SubNavs = ({ list, selected, selectedItem }: Props) => {
    return (
        <Collapse in={selected} timeout="auto" unmountOnExit>
            <List disablePadding>
                { list.map((item, index) => <SubNavItem key={index} {...{item}} itemIsActive={selected && item.name === selectedItem} />) }
            </List>
        </Collapse>
    )
};
