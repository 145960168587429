import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from "@material-ui/core";
import { DisplayTable } from '../../components/DisplayWrappers'
import { useHistory, useParams } from 'react-router-dom';
import { CLOUDCONFIG_BASE_PATH } from '../../components/MainNav/SideNavs/CloudConfigs';

export const NetworkControl: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsSecurityNetworkControl', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsSecurityNetworkControl:${key}`, interpolate)

    const history = useHistory()
    const {consortium_id, environment_id, org_id} = useParams<any>();

    const actionBar = (
        <Grid item>
            <Button color="primary" variant="contained" size="large" 
                onClick={() => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}`)}>
                {lt('manageNetworkControl')}
            </Button>
        </Grid>
    )
    
    return (
        <Grid item container>
            <DisplayTable header={lt('header')} description={lt('description')} {...{actionBar}} />
        </Grid>
    )
};

interface translations {
    header: string,
    description: string,
    manageNetworkControl: string
}
const enTranslations: translations = {
    header: 'Network Control',
    description: 'Kaleido offers the ability to configure nodes with a private ingress and connect through your AWS Virtual Private Cloud (VPC) via a PrivateLink endpoint.',
    manageNetworkControl: 'Manage Network Control'
}