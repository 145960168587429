import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, TextField, Grid, Paper, Link, makeStyles } from "@material-ui/core";
import { CREATE_NODES_PATH } from '../../components/NodeNav/NodePicker'
import { Step1Help } from './Step1Help'
import { MembershipSelector } from '../../components/FormControls/MembershipSelector'
import { ConsortiumZone, EnvironmentZone, NodeRole, EnvironmentProvider, NodesData, NodesQuery } from '../../models';
import { RegionSelector } from '../../components/FormControls/RegionSelector'
import { CreateWrapper } from '../../components/DialogWrappers'
import { CreateStepProps, EnvironmentResourcesVars } from '../../interfaces';
import { capitalize } from '../../utils/StringUtils';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { AlertBanner } from '../../components/Banners/AlertBanner';

interface Props extends CreateStepProps {
    isMultiRegion: boolean,
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>
    role: NodeRole
    setRole: React.Dispatch<React.SetStateAction<NodeRole>>
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    zoneId: string,
    setZoneId: React.Dispatch<React.SetStateAction<string>>,
    consortiumZones: ConsortiumZone[],
    privateZones: EnvironmentZone[],
    provider?: EnvironmentProvider,
};

export const Step1 = ({ 
        isMultiRegion, name, setName, membershipId, setMembershipId, provider, role, setRole,
        zoneId, consortiumZones, privateZones, setZoneId, cancelPath }: Props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, nodeType } = useParams<any>();
    const [disableFabricPeer, setDisableFabricPeer] = useState(false)

    const isFabric = provider === 'fabric'

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: {
            consortia_id: consortium_id,
            environment_id
        },
        fetchPolicy: 'cache-only',
        skip: !isFabric
    });

    useEffect(() => {
        if (isFabric && role === 'validator') {
            setRole('orderer');
        } else if (!isFabric && role !== 'validator') {
            setRole('validator');
        };
    }, [isFabric, role, setRole])

    useEffect(() => {
        const disablePeer = isFabric && !nodes.some(n => n.membership_id === membershipId && n.role === 'orderer')
        setDisableFabricPeer(disablePeer)
        if (disablePeer && role === 'peer') {
            setRole('orderer')
        }
    }, [membershipId, nodes, isFabric, role, setRole])

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${CREATE_NODES_PATH}/2`, {
            name,
            membershipId,
            zoneId,
            role
        })
    }

    const makeFabricNodeRoleRadio = (r: NodeRole, label: string, description: string, link: string) => {
        const disablePeer = r === 'peer' && disableFabricPeer
        return (
            <Grid item>
                <Paper title={disablePeer ? lt('ordererFirst') : undefined}
                    className={clsx({
                        [classes.radioPaperDisabled]: disablePeer,
                        [classes.radioPaperSelected]: role === r && !disablePeer,
                        [classes.radioPaper]: role !== r && !disablePeer
                    })}
                    elevation={0} 
                    onClick={disablePeer ? undefined : () => setRole(r)}>
                    <Grid container direction="row" alignItems="center" alignContent="center" justify='center' spacing={2} wrap='nowrap'>
                        <Grid item className={classes.logoWidth}>
                            <img className={classes.logoCentered} src={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-${capitalize(r)}Node.svg`} alt={lt('logo', {label})} ></img>
                        </Grid>
                        <Grid item container direction='column' spacing={1}>
                            <Grid item>
                                <Typography variant="h6" color="textPrimary">
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="textSecondary">
                                    {description}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link href={link} target="_blank">{lt('learnMore')}</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            {isFabric && role && role !== 'validator' && !nodeType && 
                    <><Grid item>
                        <Typography variant="h5">
                            {lt('role')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {lt('roleDescription')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={1}>
                        {makeFabricNodeRoleRadio(
                                'orderer',
                                lt('orderer'),
                                lt('ordererDescription'),
                                'https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/')}
                        {makeFabricNodeRoleRadio(
                                'peer',
                                lt('peer'),
                                lt('peerDescription'),
                                'https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/')}
                        </Grid>
                    </Grid></>
                }

            <Grid item>
                <TextField
                    data-test="nodeName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            { isMultiRegion && 
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('selectDeploymentRegion')}
                </Typography>
                <RegionSelector {...{zoneId}} {...{setZoneId}} {...{consortiumZones}} {...{privateZones}}/>
            </Grid>
            }

            {
                isFabric && nodeType === 'peer' && disableFabricPeer &&
                <Grid item>
                    <AlertBanner title='' description={lt('ordererFirst')} />
                </Grid>
            }
        </>
    )

    const disabled = !name || (isMultiRegion && !zoneId) || (disableFabricPeer && nodeType === 'peer')

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} isFirstStep />
            <Step1Help {...{role}}/>
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    radioPaperSelected: {
        padding: theme.spacing(2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    radioPaperDisabled: {
        padding: theme.spacing(2),
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
        opacity: '50%'
    },
    logoWidth: {
        maxWidth: '250px',
    },
    logoCentered: {
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}));
interface translations {
    header: string,
    headerDescription: string,
    name: string,
    selectDeploymentRegion: string,
    role: string,
    roleDescription: string,
    orderer: string,
    peer: string,
    ordererDescription: string,
    peerDescription: string,
    learnMore: string,
    logo: string,
    ordererFirst: string
}
const enTranslations: translations = {
    header: 'Set Up Your Node',
    headerDescription: 'Provision a node to take part in this environment.',
    name: 'Name of this node',
    selectDeploymentRegion: 'Select deployment region',
    role: 'Role',
    roleDescription: 'Which type of node would you like to create?',
    orderer: 'Orderer',
    peer: 'Peer',
    ordererDescription: 'Orderer nodes are responsible for transaction ordering, using consensus algorithms to ensure consistency among the members of a channel. An orderer node must be created for the selected membership before any peer nodes can be created.',
    peerDescription: 'Peer nodes are responsible for running chaincodes and processing transactions, as well as maintaining the shared ledger for each channel.',
    learnMore: 'Learn More',
    logo: '{{role}} Logo',
    ordererFirst: 'An orderer node must be created for the selected membership before any peer nodes can be created.'
}