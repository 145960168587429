
import { gql } from '@apollo/client';
import { PaginatedEnvironmentResourcesVars, EnvironmentResourcesVars } from '../interfaces'

export type TransactionStatus = 'success' | 'failed'

export interface TransactionsVars extends PaginatedEnvironmentResourcesVars {
    status?: string
    total?: boolean
};

export interface TransactionVars extends EnvironmentResourcesVars {
    hash: string
}

export interface TransactionsPerBlockVars extends PaginatedEnvironmentResourcesVars {
    blockNumber: String
}

export interface AddressTransactionsVars extends PaginatedEnvironmentResourcesVars {
    address: String
}

export interface TransactionReceipt  {
    transactionIndex: number
    blockHash: string
    status?: boolean
    blockNumber: number
}

export interface TxIndex {
    hash: string
    status: TransactionStatus
    timestamp: string
    to?: string
    from?: string
}

export interface TransactionSummary extends TxIndex {
    index?: number
    blockHash?: string
    blockNumber?: number
    tokenInfo?: TokenInfo
    private?: boolean
    events?: object
    isERC721?: boolean
    isERC20?: boolean
}

export interface Transaction extends TransactionSummary {
    inputBytes?: string
    gasProvided?: number
    nextHash?: string
    hexData?: string
    method?: string
    inputs?: object
    logs?: TransactionLog[]
    contractAddress?: string
}

export interface TransferEvent {
    to: string
    from: string
    value?: string
    tokenId?: string
    eventSignature: string
}

export interface TokenInfo {
    tokenDetails: TokenDetails 
    minted: Boolean
    burned: Boolean
}

export interface TokenDetails {
    tokenName: string
    tokenSymbol: string
}

export interface TransactionLog {
    address: string
    topics: [string]
    data: string
    blockNumber: number
    transactionHash: string
    transactionIndex: number
    blockHash: string
    logIndex: number
    removed: boolean
    event: string
    inputs: object
    id: string
}

export interface Transactions {
    totalCount: number
    transactions: Transaction[]
}

export interface TxIndexs{
    totalCount: number 
    transactions: TxIndex[]
}

export interface TxIndexData {
    txIndex: TxIndexs
}
export interface TransactionsData {
    transactions: Transactions
}

export interface TokensTransfersData {
    allTokenTransfers: Transactions
}
export interface TokenTransfersData {
    tokenTransfers: Transactions
}

export interface TransactionsPerBlockData {
    transactionsPerBlock: Transaction[]
}

export interface AddressTransactionsData {
    addressTransactions: Transaction[]
}

export interface TransactionData {
    transaction: Transaction
}

export const ItemTransactionsFields = `
    fragment ItemTransactionsFields on Transaction {
        index
        hash
        blockHash
        blockNumber
        timestamp
        status
        private
        from
        to
    }
`

export const TxIndexFields = `
    fragment TxIndexFields on TxIndex {
        totalCount
        transactions {
            hash
            to
            from
            timestamp
            status
        }
    }
`

export const TransactionFields = ` 
    fragment TransactionFields on Transaction {
        index
        hash
        blockHash
        blockNumber
        timestamp
        status
        private
        contractAddress
        isERC721
        isERC20
        tokenInfo {
            tokenDetails {
                tokenName
                tokenSymbol
            } 
            minted
            burned
        }
        from
        to
        events
        inputBytes
        gasProvided
        nextHash
        hexData
        method
        inputs
        logs {
            address
            topics
            data
            blockNumber
            transactionHash
            transactionIndex
            blockHash
            logIndex
            removed
            id
            event
            inputs
        }
    }`;

export const TransactionsFields = `
    fragment TransactionsFields on Transactions {
        totalCount
        transactions {
            index
            hash
            blockHash
            blockNumber
            timestamp
            tokenInfo {
                tokenDetails {
                    tokenName
                    tokenSymbol
                } 
                minted
                burned
            }
            status
            private
            from
            to
            events
            isERC721
            isERC20
        }
    }`

export const TransactionReceiptFields = `
    fragment TransactionReceiptFields on TransactionReceipt {
        transactionIndex
        blockHash
        status
        blockNumber
    }`

export const TransactionsQuery = gql`
    ${TransactionsFields}
    query getTransactions($consortia_id: String!, $environment_id: String!, $status: String, $total: Boolean, $start: Int, $limit: Int) {
        transactions(consortia_id: $consortia_id, environment_id: $environment_id, status: $status, total: $total, start: $start, limit: $limit) {
            ...TransactionsFields
        }
    }`;

export const TokensTransfersQuery = gql`
    ${TransactionsFields}
    query getTokensTransfers($consortia_id: String!, $environment_id: String!, $start: Int, $limit: Int) {
        allTokenTransfers(consortia_id: $consortia_id, environment_id: $environment_id, start: $start, limit: $limit) {
            ...TransactionsFields
        }
    }`;

export const TokenTransfersQuery = gql`
    ${TransactionsFields}
    query getTokenTransfers($consortia_id: String!, $environment_id: String!, $address: String!, $start: Int, $limit: Int) {
        tokenTransfers(consortia_id: $consortia_id, environment_id: $environment_id, address: $address, start: $start, limit: $limit) {
            ...TransactionsFields
        }
    }`;

export const TxIndexQuery = gql`
    ${TxIndexFields}
    query getTxIndex($consortia_id: String!, $environment_id: String!, $status: String, $start: Int, $limit: Int) {
        txIndex(consortia_id: $consortia_id, environment_id: $environment_id, status: $status, start: $start, limit: $limit) {
            ...TxIndexFields
        }
    }`;
export const TransactionQuery = gql`
    ${TransactionFields}
    query getTransaction($consortia_id: String!, $environment_id: String!, $hash: String!) {
        transaction(consortia_id: $consortia_id, environment_id: $environment_id, hash: $hash) {
            ...TransactionFields
        }
    }`;    

export const TransactionsPerBlockQuery = gql`
    ${ItemTransactionsFields}
    query getTransactionsPerBlock($consortia_id: String!, $environment_id: String!, $blockNumber: String!, $start: Int, $limit: Int) {
        transactionsPerBlock(consortia_id: $consortia_id, environment_id: $environment_id, blockNumber: $blockNumber, start: $start, limit: $limit) {
            ...ItemTransactionsFields
        }
    }`;    

export const AddressTransactionsQuery = gql`
    ${ItemTransactionsFields}
    query getAddressTransactions($consortia_id: String!, $environment_id: String!, $address: String!) {
        addressTransactions(consortia_id: $consortia_id, environment_id: $environment_id, address: $address ) {
            ...ItemTransactionsFields
        }
    }`;