import React from 'react';
import PollIcon from 'mdi-react/PollIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import { Kpi } from '../../components/DisplayWrappers/Kpi';
import { useTranslation } from 'react-i18next';
import { Stats } from '../../models/ledger';

interface Props {
    stats: Stats | null,
    totalTransfers: number,
    totalTokens: number
}

export const TokensKpi = ({stats, totalTransfers, totalTokens} : Props) => {    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokensKpi', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokensKpi:${key}`, interpolate);

    const kpiItems = [{
        value: stats?.height ?? '--',
        label: lt('latestBlock'),
        icon: <PollIcon />
    }, {
        value: totalTokens ?? '--',
        label: lt('totalTokens'),
        icon: <PollIcon />
    }, {
        value: totalTransfers ?? '--',
        label: lt('totalTransfers'),
        icon: <ClockOutlineIcon />
    }, {
        value: stats?.transactionCount ?? '--',
        label: lt('totalTxs'),
        icon: <PollIcon />
    }]; 

    return <Kpi data={kpiItems} />
}

interface translations {
    latestBlock: string
    tokenContracts: string
    totalTransfers: string
    totalTokens: string
    totalTxs: string
}

const enTranslations: translations = {
    latestBlock: 'Latest Block',
    tokenContracts: 'Latest Block',
    totalTransfers: 'Total Transfers',
    totalTokens: 'Total Tokens',
    totalTxs: 'Total Txs'
}
