import React, { useEffect, useCallback, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { NodeData, NodeQuery,
    ServiceData, ServiceQuery,
    AppCredsData, AppCredsQuery, MakeAppCredCreateMutationOptions,
    CreateAppCredData, CreateAppCredVars, CreateAppCredMutation, RegenerateAppCredData,
    RegenerateAppCredMutation } from '../../models';
import { EnvironmentResourceVars, EnvironmentResourcesVars } from '../../interfaces';
import Cookies from 'js-cookie'
import { ErrorSnackbarCatcher } from '../DialogWrappers';

// the purpose of this component is to reuse/regenerate/create a valid app cred for the passed in membershipId.
// the app cred is then stored as a session cookie (deleted once user closes the browser).

// it's intended to be dropped in by your wrapper component when you need an appCred to do something.
// it will eventually call your state setter (setCookieAppCred) with a valid appCred in the format (id:password).
// then your wrapper can do with your newly set cookieAppCred as it pleases.

// this component doesn't render anything and it does it's work silently with status updates going to console log.
export const getUIAppCredName = (environmentId:string, membershipId: string) => {
    return `Kaleido_UI_${environmentId}_${membershipId}`
}

const RETEST_DELAY = 2000
const MAX_RETEST_ATTEMPTS = 10

interface Props {
    membershipId: string,
    nodeId?: string, // tested
    serviceId?: string, // not tested
    setCookieAppCred: React.Dispatch<React.SetStateAction<string>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
};

export const CookieAppCred = ({ membershipId, nodeId, serviceId, setCookieAppCred, setErrorMessage }: Props) => {
    const { consortium_id, environment_id } = useParams<any>();

    // node query

    const [getNode, 
        { data: { 
            node 
        } = { node: null }
    }] = useLazyQuery<NodeData, EnvironmentResourceVars>(NodeQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: nodeId!
        },
        fetchPolicy: 'cache-only'
    });

    useEffect(() => {
        if (nodeId) getNode()
    }, [nodeId, getNode])

    // service query

    const [getService, 
        { data: { 
            service 
        } = { service: null }
    }] = useLazyQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: serviceId!
        },
        fetchPolicy: 'cache-only'
    });

    useEffect(() => {
        if (serviceId) getService()
    }, [serviceId, getService])

    // appcred query

    let {
        loading: appCredLoading,
        data: {
            appCreds
        } = { appCreds: [] }
    } = useQuery<AppCredsData, EnvironmentResourcesVars>(AppCredsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only' 
    });

    const appCredName = getUIAppCredName(environment_id, membershipId);
    const appCred = appCreds.find(a => a.membership_id === membershipId && a.name === appCredName)

    // appcred test against runtime URL

    const retestAttempts = useRef<number>(0)
    const intervalRef = useRef<number>(0)
    useEffect(() => {
        // cleanup
        return () => window.clearInterval(intervalRef.current);
    }, []);

    const testAppCred = useCallback((id: string, password: string) => {
        const nodeTestAction = node?.isFabric ? 'receipts' : 'replies'
        const testUrl = node ? `${node.urls?.kaleido_connect}/${nodeTestAction}?limit=1` : service?.urls?.webui || service?.urls?.http;
        if (!testUrl) return

        console.log(`testing appcred id: ${id} against ${testUrl}`)

        const appCredBasicAuth = `${id}:${password}`
        const auth = window.btoa(appCredBasicAuth);

        const httpHeaders = {
            Authorization: `Basic ${auth}`,
            'content-type': 'application/json',
            'cache-control': 'no-cache'
        };
        return fetch(testUrl, {
            method: 'GET',
            headers: new Headers(httpHeaders)
        })
    }, [node, service])

    // dont send the app cred back to the caller component until its been validated
    // retest appcred MAX_RETEST_ATTEMPTS times until it works (allows for processing time propagating app cred from cplane to netplane)

    const retester = useCallback((id: string, password: string, appCredBasicAuth: string) => {
        window.clearInterval(intervalRef.current)

        if (retestAttempts.current.valueOf() >= MAX_RETEST_ATTEMPTS) {
            console.log(`stopped retesting appcred ${id} after too many attempts`)
            retestAttempts.current = 0
            setCookieAppCred(appCredBasicAuth) // return it anyway so the user gets the error message when caller component attempts to to stuff with it
            return
        }

        console.log(`retesting appcred ${id} attempt ${retestAttempts.current}`)

        const retest = () => {
            intervalRef.current = window.setInterval(() => {
                retestAttempts.current++
                retester(id, password, appCredBasicAuth);
            }, RETEST_DELAY);
        }

        testAppCred(id, password)?.then(response => {
            if (response.ok) {
                window.clearInterval(intervalRef.current)
                retestAttempts.current = 0
                console.log(`appcred test success, appcred id: ${id}`)
                setCookieAppCred(appCredBasicAuth)
                console.log(`successfully set appcred id: ${id}`)
            } else {
                retest()
            }
        }).catch(e => {
            if (e.message === 'Failed to fetch') {
                retest()
            } else {
                ErrorSnackbarCatcher(e, setErrorMessage)
            }
        })
    }, [setCookieAppCred, testAppCred, setErrorMessage])

    const updateCookieAndSetAppCred = useCallback((id: string, password: string, skipTest: boolean) => {
        const appCredBasicAuth = `${id}:${password}`
        Cookies.set(appCredName, appCredBasicAuth)
        if (skipTest) {
            setCookieAppCred(appCredBasicAuth)
            console.log(`successfully set appcred id: ${id}`)
        } else {
            console.log(`testing newly created / regenerated appcred id: ${id}. testing in ${RETEST_DELAY} ms`) 
            setTimeout(() => retester(id, password, appCredBasicAuth), RETEST_DELAY)
        }
    }, [appCredName, setCookieAppCred, retester])

    // create new appcred

    const [createAppCred] = 
        useMutation<CreateAppCredData, CreateAppCredVars>(CreateAppCredMutation)
    
    const creator = useCallback(() => {
        createAppCred(MakeAppCredCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            appCred: {
                membership_id: membershipId,
                name: appCredName
            }
        })).then((d) => {
            if (d.data?.createAppCred) {
                updateCookieAndSetAppCred(d.data.createAppCred._id, d.data.createAppCred.password, false)
            } else {
                console.error('error creating appcred')
            } 
        }).catch(e => {
            ErrorSnackbarCatcher(e, setErrorMessage)
        })
    }, [appCredName, consortium_id, createAppCred, environment_id, membershipId, updateCookieAndSetAppCred, setErrorMessage])

    // regenerate existing appcred

    const [regenerateAppCred] = useMutation<RegenerateAppCredData, EnvironmentResourceVars>(RegenerateAppCredMutation)
    
    const regenerator = useCallback((id: string) => {         
        return regenerateAppCred({
            variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id
        }}).then((d) => {
            if (d.data?.regenerateAppCred) {
                updateCookieAndSetAppCred(d.data.regenerateAppCred._id, d.data.regenerateAppCred.password, false)
            } else {
                console.error('error regenerating appcred')
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setErrorMessage)
        })
    }, [consortium_id, environment_id, regenerateAppCred, updateCookieAndSetAppCred, setErrorMessage])

    // test app cred

    const createOrRegenerate = useCallback(() => {
        if (appCred) {
            console.log(`regenerating password for appcred id: ${appCred._id}`)
            regenerator(appCred._id)
        } else {
            console.log('creating new appCred')
            creator()
        }
    }, [appCred, creator, regenerator])

    const tester = useCallback((id: string, password: string) => {
        testAppCred(id, password)?.then(response => {
            if (response.ok) {
                console.log(`appcred test success, appcred id: ${id}`)
                updateCookieAndSetAppCred(id, password, true)
            } else if (response.status === 401) {
                console.log(`appcred test failed with a 401 status, appcred id: ${id}. regenerating in ${RETEST_DELAY} ms`) 
                setTimeout(() => createOrRegenerate(), RETEST_DELAY)
            }
        }).catch(e => {
            if (e.message === 'Failed to fetch') {
                console.log(`appcred test failed with Failed to fetch, appcred id: ${id}. regenerating in ${RETEST_DELAY} ms`)
                setTimeout(() => createOrRegenerate(), RETEST_DELAY)
            } else {
                ErrorSnackbarCatcher(e, setErrorMessage)
            }
        })
    }, [updateCookieAndSetAppCred, createOrRegenerate, setErrorMessage, testAppCred])

    // now actual logic

    const hasTestRanAlready = useRef<boolean>(false)

    const makeAppCredCookie = useCallback(() => {
        const appCredCookie = Cookies.get(appCredName)
        if (appCredCookie) {
            const splitAppCredCookie = appCredCookie.split(':')
            tester(splitAppCredCookie[0], splitAppCredCookie[1])
        } else {
            createOrRegenerate()
        }
    }, [appCredName, tester, createOrRegenerate])

    useEffect(() => {
        if (appCredLoading || (nodeId && node?.state !== 'started') || (serviceId && service?.state !== 'started')) {
            return
        }

        // only do this once
        if (hasTestRanAlready.current) {
            return
        }
        hasTestRanAlready.current = true

        makeAppCredCookie()
    }, [makeAppCredCookie, node?.state, service?.state, appCredLoading, nodeId, serviceId])

    // you show nothing jon snow
    return <></>
};