import React, { useState } from 'react';
import { CreateStepProps } from '../../../interfaces';
import { Grid, Typography, TextField } from '@material-ui/core';
import { Step1Help } from './Step1Help';
import { useTranslation } from 'react-i18next';
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../../components/DialogWrappers';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CreateOauthConfigurationMutation, CreateOathConfigurationVars, jwtKeyType, JwtKeyTypeTranslationsInterface, jwtKeyTypeTranslations } from '../../../models/oauthConfiguration';
import { RadioSelector, RadioSelectorOptions } from '../../../components/FormControls/RadioSelector';
import { IsJsonString } from '../../../utils/StringUtils';

interface Props extends CreateStepProps {
    name: string
    membershipId: string,
}

const jwtKeyTypeOptions: jwtKeyType[] = ['jwks', 'rsa_pem', 'ec_pem'];

const PEMKEYCHECK = "-----BEGIN PUBLIC KEY-----"

export const Step2 = ({membershipId, name, cancelPath}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOauthConfigurationStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOauthConfigurationStep2:${key}`, interpolate);

    const [message, setMessage] = useState('');
    const [jwtType, setJwtType] = useState<jwtKeyType>(jwtKeyTypeOptions[0]);
    const [publicKey, setPublicKey] = useState('');

    const { consortium_id, environment_id } = useParams<any>();

    const history = useHistory();

    const [createOauthConfiguration, {loading}] = useMutation<null, CreateOathConfigurationVars>(CreateOauthConfigurationMutation);

    const isValidPublicKey = () => {
        if (jwtType === 'jwks') return IsJsonString(publicKey);
        return publicKey.includes(PEMKEYCHECK);
    }

    const save = async () => {
        try {
            await createOauthConfiguration({
                variables: {
                    environment_id: environment_id!,
                    consortia_id: consortium_id!,
                    oauth: {
                        membership_id: membershipId,
                        name,
                        jwt_key_type: jwtType,
                        jwt_key_set: publicKey.trim(),
                        verification_type: 'jwt'
                    }
                }
            })
            history.push(cancelPath)
        } catch (err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    const radioOptions: RadioSelectorOptions[] = jwtKeyTypeOptions.map(type => ({
        value: type,
        label: lt(type),
        description: lt(`${type}Desc` as keyof translations)
    }));

    const isValidKey = isValidPublicKey();

    const disabled = loading || !publicKey || !isValidKey || !jwtType || !name || !membershipId;

    const content =  (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>
            <Grid item>
                <RadioSelector options={radioOptions} selected={jwtType} onChange={(e) => setJwtType(e.target.value as jwtKeyType)} />
            </Grid>
            <Grid item>
                <TextField error={(publicKey && !isValidKey) || undefined} 
                    helperText={ publicKey && !isValidKey && lt('inputValid', {type: lt(jwtType)})}  
                    variant="outlined" 
                    rows={5} 
                    label={lt('publicKey')} 
                    fullWidth 
                    multiline 
                    value={publicKey} 
                    onChange={e => setPublicKey(e.target.value)} />
            </Grid>
        </>
    );

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper disabled={disabled} cancelPath={cancelPath} {...{content}} onNext={save} isLastStep />
            <Step1Help />
        </>
    )
            
};

interface translations extends JwtKeyTypeTranslationsInterface {
    header: string
    name: string
    jwtType: string
    publicKey: string
    jwksDesc: string
    ec_pemDesc: string
    rsa_pemDesc: string
    inputValid: string
}

const enTranslations: translations = {
    ...jwtKeyTypeTranslations,
    header: 'Enter Information',
    name: 'Name',
    jwtType: 'JWT Type',
    publicKey: 'Public Key',
    jwksDesc: 'This is the format provided by an OAuth authorization server to download on a URL ending as /.well-known/jwks.json or similar. It contains full details of the public keys, including their type.',
    ec_pemDesc: 'This is a file containing a single Elliptic Curve public key, in PEM format',
    rsa_pemDesc: 'This is a file containing a single RSA public key, in PEM format',
    inputValid: 'Please input a valid {{type}}'
}
