import React from "react";
import { FullScreenCreate } from "../../components/DialogWrappers";
import { useParams, useHistory } from "react-router-dom";
import {
    NETWORK_GOVERNANCE_PATH,
    NETWORK_GOVERNANCE_MEMBERSHIPS_PATH,
} from "../../components/MainNav/SideNavs/Governance";
import { useTranslation } from "react-i18next";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";

type locationState = { isSelfSigned: boolean, cancelPath: string };

export const ConfirmIdentity = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "ConfirmIdentity", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`ConfirmIdentity:${key}`, interpolate);

    const history = useHistory<locationState>();
    const { org_id, consortium_id, membership_id, step } = useParams<any>();

    const {
        location: {
            state: { isSelfSigned, cancelPath } = {
                isSelfSigned: true,
                cancelPath: `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}/${membership_id}`
            },
        },
    } = history;

    const stepComponents = [
        {
            step: lt("identityType"),
            component: <Step1 {...{ cancelPath }} {...{ isSelfSigned }} />,
        },
        {
            step: lt("certificate"),
            component: <Step2 {...{ cancelPath }} {...{ isSelfSigned }} />,
        },
    ];

    return (
        <FullScreenCreate
            {...{ cancelPath }}
            toolbarHeader={lt("confirmIdentity")}
            stepUrlParam={step}
            {...{ stepComponents }}
        />
    );
};

interface translations {
    confirmIdentity: string;
    identityType: string;
    certificate: string;
}
const enTranslations: translations = {
    confirmIdentity: "Confirm Identity",
    identityType: "Identity Type",
    certificate: "Certificate",
};
