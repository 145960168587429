import React from 'react';
import { AddressWidgetInfo } from '../../../models';
import { ListItemText, ListItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../MainNav/SideNavs/AddressBook';
import { DATA_CONTRACTS, ENVIRONMENT_DATA_EXPLORER } from '../../MainNav/SideNavs/DataExplorer';
import { FormLink } from '../FormLink';
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TOKENS_PATH } from '../../TokenExplorerNav/TokenExplorerNav';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_TOKENS_PATH } from '../../MainNav/SideNavs/DigitalAssets';

interface Props {
    addressWidgetInfo: AddressWidgetInfo
};

export const ShortenedHashToken = ({ addressWidgetInfo }: Props) => {

    const classes = useStyles();

    const { pathname } = useLocation();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ShortenedHashToken', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ShortenedHashToken:${key}`, interpolate)
    const { org_id, consortium_id, environment_id } = useParams<any>();
    
    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/`;
    const viewContractDetailsUrl = basePath +
        `${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}/${addressWidgetInfo._id}`;
    const viewDeployerAddressBookEntryUrl = basePath +
        `${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${addressWidgetInfo.membership_id === environment_id ? SYSTEM_MEMBERSHIP : addressWidgetInfo.membership_id}`;
    const viewTokenInfoUrl = basePath +
        `${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TOKENS_PATH}/${addressWidgetInfo._id}`;
    const viewTokenActionURL = basePath +
        `${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/${addressWidgetInfo._id}`

    return (
        <>
            {addressWidgetInfo.tokenInfo!.isERC20 &&
                <ListItem>
                    <ListItemText primary={lt('supply')} secondary={addressWidgetInfo.tokenInfo!.erc20TotalSupply} />
                </ListItem>}
            <ListItem>
                <ListItemText primary={lt('moreInformation')} secondary={
                    <>
                        {pathname !== viewContractDetailsUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewContractDetailsUrl}>
                                    {lt('viewContractDetails')}
                                </FormLink>
                            </span>}
                        {pathname !== viewTokenInfoUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewTokenInfoUrl}>
                                    {lt('viewTokenDetails')}
                                </FormLink>
                            </span>}
                        {pathname !== viewTokenActionURL &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewTokenActionURL}>
                                    {lt('viewTokenAction')}
                                </FormLink>
                            </span>}
                        {pathname !== viewDeployerAddressBookEntryUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewDeployerAddressBookEntryUrl}>
                                    {lt('viewDeployerAddressBookEntry')}
                                </FormLink>
                            </span>}
                    </>} />
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(() => ({
    linkContainer: {
        display: 'block',
        marginTop: 6
    }
}));

interface translations {
    supply: string
    moreInformation: string
    viewContractDetails: string
    viewDeployerAddressBookEntry: string
    viewTokenDetails: string
    viewTokenAction: string
}

const enTranslations: translations = {
    supply: 'Supply',
    moreInformation: 'More Information',
    viewContractDetails: 'View Contract Details',
    viewDeployerAddressBookEntry: 'View Deployer Address Book Entry',
    viewTokenDetails: 'View Token Details',
    viewTokenAction: 'View Token Actions'
}