import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { NodesQuery, NodesData, RuntimeSizeTranslation, EnRuntimeSizeTranslation } from '../../models'
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { DisplayTable } from '../../components/DisplayWrappers/DisplayTable';
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import GamepadCircleLeftOutlineIcon from 'mdi-react/GamepadCircleLeftIcon';
import { DisplayTableColumns } from '../../components/DisplayWrappers/DisplayTableRow';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { EnvironmentResourcesVars } from '../../interfaces';
import { ShortenedString } from '../../components/FormControls/ShortenedString';
import { ChannelData, ChannelQuery } from '../../models/channels';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_RUNTIMES_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';
import { ChannelJoinedStatus } from './ChannelJoinedStatus';

export const AddressBookChannelNodes: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    i18n.addResourceBundle('en', 'AddressBookChannelNodes', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookChannelNodes:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, channel_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: channel_id!
    }

    const {
        loading,
        data: {
            channel
        } = { channel: null }
    } = useQuery<ChannelData, EnvironmentResourcesVars>(ChannelQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const { data: { nodes : nodesCollection } = { nodes: [] } } = useQuery<
        NodesData,
        EnvironmentResourcesVars
    >(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: "cache-only",
    });

    if (loading || !channel) {
        return <CircularProgress />
    }

    const nodes = nodesCollection.filter((n) => (channel.members).includes(n.membership_id));
    let ordererNodes: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    let peerNodes: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    let systemNodes: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];

    for (const node of nodes) {
        const membershipName = node.membership.name
        const urls = node.urls ?? {orderer: '-', peer: '-'};
        const url = node.role === 'orderer' ? urls.orderer : node.role === 'peer' ? urls.peer : '-';
        const record = {
            key: node._id,
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${node.membership_id === 'sys--mon' ? SYSTEM_MEMBERSHIP : node.membership_id}/${ADDRESSBOOK_RUNTIMES_PATH}/${node._id}`),
            columns: [
                { isIcon: true, value: node.role === 'orderer' ? <BullseyeIcon /> : <GamepadCircleLeftOutlineIcon/>},
                { value: node.name || '--' },
                { value: <ShortenedString noLowerCase content={url || '-'} prefixCharacters={30} postfixCharacters={20} hideCopy />},
                { value: node.membership_id === 'sys--mon' ? lt('system') : membershipName },
                { value: new Date(node.created_at).toLocaleString() },
            ]
        }
        if (node.role === 'peer') {
            record.columns.push({
                value: <ChannelJoinedStatus node={node} />
            })
        }
        record.columns.push({ value: <ResourceStateChip state={node.state} /> })
        node.role === 'orderer' ? ordererNodes.push(record) : node.role === 'peer' ? peerNodes.push(record) : systemNodes.push(record);
    }

    const columnHeaders = [
        '',
        lt('name'),
        lt('url'),
        lt('owner'),
        lt('addedOn'),
    ];
    const finalColumns = [lt('status')]
    const additionalPeerColumns = [lt('channelStatus')]

    return (
        <Grid container spacing={3}>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('channelNodes', { channelName: channel.name })}
                    </Typography>
                </Grid>
                <Grid item>
                    <DisplayTable header={lt('ordererNodes')} hideEmptyIcon emptyLabel={lt('noOrdererNodes')} 
                        columnHeaders={columnHeaders.concat(finalColumns)} records={ordererNodes} />
                </Grid>
                <Grid item>
                    <DisplayTable header={lt('peerNodes')} hideEmptyIcon emptyLabel={lt('noPeerNodes')} 
                        columnHeaders={columnHeaders.concat(additionalPeerColumns).concat(finalColumns)} records={peerNodes} />
                </Grid>
                {systemNodes.length > 0 &&
                <Grid item>
                    <DisplayTable header={lt('systemNodes')} hideEmptyIcon 
                        columnHeaders={columnHeaders.concat(finalColumns)} records={systemNodes} />
                </Grid>
                }
            </Grid>
        </Grid>
    )
};

interface translations extends RuntimeSizeTranslation {
    channelNodes: string
    ordererNodes: string
    peerNodes: string
    systemNodes: string
    noOrdererNodes: string
    noPeerNodes: string
    name: string
    owner: string
    status: string
    node: string
    url: string
    addedOn: string
    system: string
    channelStatus: string
}
const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    channelNodes: '{{channelName}} Nodes',
    ordererNodes: 'Orderer Nodes',
    peerNodes: 'Peer Nodes',
    systemNodes: 'System Nodes',
    noOrdererNodes: 'No Orderer Nodes',
    noPeerNodes: 'No Peer Nodes',
    name: 'Name',
    owner: 'Owner',
    status: 'Runtime Status',
    node: 'Node',
    url: 'URL',
    addedOn: 'Added On',
    system: 'System',
    channelStatus: 'Channel Status'
}
