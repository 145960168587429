import {
    Box,
    Button,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    buttonDisabled: boolean;
    buttonFn: () => void;
    dashboardMode?: boolean;
}

export const DedicatedWeb3SolutionsAccess = ({
    buttonDisabled,
    buttonFn,
    dashboardMode = false,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle(
        'en',
        'DedicatedWeb3SolutionsAccess',
        enTranslations
    );
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`DedicatedWeb3SolutionsAccess:${key}`, interpolate);
    const classes = useStyles();

    const requestAccessButton = (
        <Button
            data-test="button_submit_request_access"
            variant="outlined"
            color="primary"
            size="medium"
            onClick={buttonFn}
            disabled={buttonDisabled}
        >
            {lt('requestAccess')}
        </Button>
    );

    const assetPlatformFeature = (
        title: string,
        description: string,
        imagePath: string
    ) => {
        return (
            <>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    className={
                        dashboardMode
                            ? classes.featureDashboardMode
                            : classes.feature
                    }
                >
                    <Grid item>
                        <img
                            src={`${process.env.PUBLIC_URL}/img/AssetPlatform/${imagePath}.svg`}
                            alt=""
                            style={{
                                width: dashboardMode ? '30px' : 'inherit',
                            }}
                        ></img>
                    </Grid>
                    <Grid item direction="column">
                        <Grid item>
                            <Typography
                                variant="h6"
                                style={{
                                    fontSize: dashboardMode
                                        ? '14px'
                                        : 'inherit',
                                }}
                            >
                                {title}
                            </Typography>
                        </Grid>
                        {!dashboardMode && <Grid item>{description}</Grid>}
                    </Grid>
                </Grid>
            </>
        );
    };

    const paperContent = (
        <>
            <Grid container direction="row" justifyContent="space-between">
                <Grid container item xs={dashboardMode ? 7 : 6}>
                    {!dashboardMode && (
                        <>
                            <Grid container item direction="row">
                                <Grid item>
                                    <Typography variant="h6">
                                        {lt('kaleidoAssetPlatform')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box className={classes.newTag}>
                                        {lt('new')}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {lt('assetPlatformDescription')}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {assetPlatformFeature(
                        lt('digitalAssetPlatform'),
                        lt('digitalAssetPlatformDesc'),
                        'digital-asset-platform'
                    )}
                    {assetPlatformFeature(
                        lt('nftPlatform'),
                        lt('nftPlatformDesc'),
                        'nft-platform'
                    )}
                </Grid>
                <Grid
                    container
                    item
                    xs={dashboardMode ? 5 : 6}
                    className={dashboardMode ? '' : classes.rightBox}
                >
                    {!dashboardMode && (
                        <>
                            <Grid container item direction="row">
                                <Grid item>
                                    <Typography variant="h6">
                                        {lt('ecosystemOfferings')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {lt('ecosystemOfferingsDesc')}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {assetPlatformFeature(
                        lt('consortium'),
                        lt('consortiumDesc'),
                        'consortium-as-a-service'
                    )}
                    {assetPlatformFeature(
                        lt('cbdcPlatform'),
                        lt('cbdcPlatformDesc'),
                        'custom-dedicated-sidechains'
                    )}
                </Grid>

                {dashboardMode && (
                    <Grid item style={{ marginTop: '8px' }}>
                        <Typography variant="body2">
                            {lt('dedicatedWeb3SolutionsDescription')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {dashboardMode && (
                <Grid
                    container
                    justifyContent="flex-start"
                    style={{ margin: '16px 0' }}
                >
                    {requestAccessButton}
                </Grid>
            )}
        </>
    );

    return (
        <>
            {!dashboardMode && (
                <Grid item direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            {lt('dedicatedWeb3Solutions')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary">
                            {lt('dedicatedWeb3SolutionsDescription')}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {dashboardMode ? (
                paperContent
            ) : (
                <Paper className={classes.container}>{paperContent}</Paper>
            )}
            {!dashboardMode && (
                <Grid container justifyContent="flex-end">
                    {requestAccessButton}
                </Grid>
            )}
        </>
    );
};
interface translations {
    assetPlatformDescription: string;
    consortium: string;
    consortiumDesc: string;
    cbdcPlatform: string;
    cbdcPlatformDesc: string;
    dedicatedWeb3Solutions: string;
    dedicatedWeb3SolutionsDescription: string;
    digitalAssetPlatform: string;
    digitalAssetPlatformDesc: string;
    ecosystemOfferings: string;
    ecosystemOfferingsDesc: string;
    kaleidoAssetPlatform: string;
    new: string;
    nftPlatform: string;
    nftPlatformDesc: string;
    requestAccess: string;
}
const enTranslations: translations = {
    assetPlatformDescription:
        'Enterprise web3 stack powered by Hyperledger FireFly',
    consortium: 'Consortium',
    consortiumDesc: 'A complete stack for decentralized consortia',
    cbdcPlatform: 'CBDC Platform',
    cbdcPlatformDesc: 'A full suite of tools for building CDBC solutions',
    dedicatedWeb3Solutions: 'Dedicated Web3 Solutions',
    dedicatedWeb3SolutionsDescription:
        "Launch powerful dApps on highly customizable, fully dedicated infrastructure that's built to scale.",
    digitalAssetPlatform: 'Digital Asset Platform',
    digitalAssetPlatformDesc: 'An institutional-grade digital asset platform',
    ecosystemOfferings: 'Ecosystem Offerings',
    ecosystemOfferingsDesc: 'Infrastructure to launch your own ecosystems',
    kaleidoAssetPlatform: 'Kaleido Asset Platform',
    new: 'New',
    nftPlatform: 'NFT Platform',
    nftPlatformDesc: 'A mass-scale NFT platform with zero gas fees',
    requestAccess: 'Request Access',
};
const useStyles = makeStyles((theme) => ({
    rightBox: {
        borderLeft: '1px solid #e0e0e0',
        paddingLeft: '8px',
    },
    container: {
        margin: '20px 0',
        padding: '10px',
        flexDirection: 'row',
    },
    divider: {
        width: '100%',
        marginLeft: 0,
    },
    feature: {
        margin: '10px 0',
    },
    featureDashboardMode: {
        margin: '5px 0',
    },
    newTag: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: '2px 6px',
        letterSpacing: '1px',
        borderRadius: '3px',
        textTransform: 'uppercase',
        fontSize: 'small',
        fontWeight: 'bold',
        marginLeft: '8px',
    },
}));
