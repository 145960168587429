import React, { useMemo } from 'react';
import { TextField, MenuItem, makeStyles } from '@material-ui/core';
import { Config } from '../../models/configs';
import { useTranslation } from 'react-i18next';
import { NodeConfigs, NodeConfigTypes } from '../../models';

interface Props {
    configurations: Config[],
    configType: NodeConfigTypes,
    nodeConfigs: NodeConfigs,
    handleDataChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, configType: keyof NodeConfigs) => void
}

export const ConfigOptions = ({configurations, configType, handleDataChange, nodeConfigs}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConfigOptions', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConfigOptions:${key}`, interpolate)

    const classes = useStyles();

    const configTypeMap: Map<NodeConfigTypes, keyof NodeConfigs> = new Map([
        ['backup', 'backup_id'],
        ['kms', 'kms_id'],
        ['opsmetric', 'opsmetric_id'],
        ['networking', 'networking_id'],
        ['baf', 'baf_id'],
        ['node_config', 'node_config_id']
    ]);

    const configOptions = useMemo(() => {
        return configurations.filter(config => config.type === configType)
    }, [configurations, configType]);

    const content = (
        <TextField 
            label="Select" 
            disabled={configOptions.length <= 0} 
            className={classes.dropdown} 
            size="small" 
            variant="outlined" 
            select 
            fullWidth 
            value={nodeConfigs[configTypeMap.get(configType)!]} 
            onChange={e => handleDataChange(e, configTypeMap.get(configType)!)}>
                <MenuItem value="">
                    <em>{lt('none')}</em>
                </MenuItem>
                {configOptions.map((config, index) => (
                    <MenuItem key={`${configType}-${index}`} value={config._id}>
                        {config.name}
                    </MenuItem>
                ))}
        </TextField>
    )

    return configOptions.length > 0 ? content : null
}

const useStyles = makeStyles(() => ({
    dropdown: {
        width: '100px'
    }
}));

interface translations {
  none: string
}
const enTranslations: translations = {
    none: 'None'
}