import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateManageWalletStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateManageWalletStep1Help:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-NodeSummary.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/kaleido-services/wallet/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Managed Wallets',
    helpDescription: 'The managed wallet allows the generation and management of individual ethereum accounts, that can be used for signing transactions by other linked Kaleido services. Such as:',
    helpDesc1: `To generate and transfer tokens.`,
    helpDesc2: 'Submit transactions via the REST API Gateway',
}