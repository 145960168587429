export const isValidURL = (url: string) => {
    const ValidHttpPattern =
        /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
    return ValidHttpPattern.test(url);
};

export function capitalize(value: string): string {
    return value.length > 0
        ? value.charAt(0).toUpperCase() + value.substr(1)
        : '';
}

export const jsNumberForAddress = (address: string): number => {
    const addr = address.slice(2, 10);
    const seed = parseInt(addr, 16);
    return seed;
};

export const endsWithAny = (suffixes: string[], string: string): boolean => {
    return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
    });
};

export const isEmailDomainBlocked = (
    email: string,
    useExtendedBlockList?: boolean
): boolean => {
    const extendedBlockList = [
        'gmail.',
        'gmai.com',
        'gamil.',
        'yahoo.',
        'hotmail.',
        'icloud.',
        'outlook.',
        '163.',
        'gmx.',
        'aol.',
        'yopmail.',
        'googlemail.',
        'pm.me',
        'mail.ru',
        'yandex.',
        'mail.com',
        'me.com',
        'live.',
        'msn.',
        '@qq.',
        'email.',
        'proton.me',
        'mac.',
        'web.de',
        '126.',
        'duck.',
        'comcast.net',
        'msn.',
        'att.net',
        'o2.pl',
    ];

    if (useExtendedBlockList) {
        const domain = email.toLowerCase().trim().split('@').pop();
        if (!domain) return false;
        for (const i of extendedBlockList) {
            if (domain?.includes(i)) {
                return true;
            }
        }
        return false;
    }
    return email.toLowerCase().trim().endsWith('qq.com');
};

export const isEmailValid = (email: string): boolean => {
    return /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
        email
    );
};

export const isPhoneValid = (phone: string): boolean => {
    return /^[- +(.x)0-9ext]+$/.test(phone);
};

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
    return /^[- +(.x)0-9]+$/.test(phoneNumber);
};

export const isPhoneExtValid = (ext: string): boolean => {
    return /^[0-9]{0,5}$/.test(ext);
};

export const removeHexFromAddress = (address: string) => {
    return address.substring(0, 2) === '0x' ? address.substring(2) : address;
};

export const isPasswordCompliant = (password: string): boolean => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/.test(
        password
    );
};

export const isEthAddress = function (address: string) {
    //check for basic ethereum address requirements
    return /^(0x)?[0-9a-f]{40}$/i.test(address);
};

export const IsJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export function toFileSize(b: number) {
    const mb = 1024 * 1024;
    const kb = 1024;
    if (b >= mb) return (b / mb).toFixed(2) + 'MB';
    else if (b >= kb) return (b / kb).toFixed(2) + 'KB';
    else return String(b) + 'B';
}

export const convertWeiToEther = (balanceInWei: number) => {
    const eth = balanceInWei / 10 ** 18;
    return eth.toString();
};

export const convertEthToWei = (balanceInEth: number) => {
    const wei = balanceInEth * 10 ** 18;
    return wei.toString();
};

export const encodeQueryData = (data: { [key: string]: string }) => {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
};
