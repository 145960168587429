import React from 'react';
import { useQuery } from '@apollo/client';
import { OrganizationsData, OrganizationsQuery, MFABlockedOrganizationsData, MFABlockedOrganizationsQuery, FeatureTogglesData, FeatureTogglesVars, FeatureTogglesQuery, FeatureToggles, Organization } from '../../models';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Container, Button } from '@material-ui/core';
import { SessionData } from '../../interfaces';
import { SessionQuery } from '../../queries/Session';
import { Org } from './Org';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { MFABlockedOrgsBanner } from '../../components/Banners/MFABlockedOrgs';
import { ORGS_PATH, CREATE_ORG_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { useHistory } from 'react-router-dom';

export const OrgListDashboard: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgListDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgListDashboard:${key}`, interpolate);
    const { data: { session } = { session: undefined } } = useQuery<SessionData>(SessionQuery);

    const {
        data: {
            organizations
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery);

    const {
        data: {
            mfaBlockedOrganizations
        } = { mfaBlockedOrganizations: [] }
    } = useQuery<MFABlockedOrganizationsData>(MFABlockedOrganizationsQuery);

    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { }
    });

    const history = useHistory();

    // Sort the orgs heirachically, with those I own first, and children under the owner
    const rootOrOrphanOrgs = organizations.filter(org => !org.master_org || !organizations.find(o => o._id === org.master_org));
    const ownedOrgs = rootOrOrphanOrgs.filter(org => org.owner === session?.user_id);
    const joinedOrgs = rootOrOrphanOrgs.filter(org => org.owner !== session?.user_id)
        .concat(mfaBlockedOrganizations.filter(org => org.owner !== session?.user_id));
    const orgAndChildren = (org: Organization) => <React.Fragment key={`org-and-children-${org._id}`}>
        <Grid key={org._id} item><Org org={org} /></Grid>
        {organizations.filter(o => o.master_org && o.master_org === org._id).map(childOrg => (
            <Grid key={childOrg._id} item><Org org={childOrg} /></Grid>
        ))}
    </React.Fragment>
    const allOrgs = () => <>
        {ownedOrgs.map(org => orgAndChildren(org))}
        {joinedOrgs.map(org => orgAndChildren(org))}
    </>

    const documentationList: DisplayCardListItem[] = [
        {
            icon: <FileDocumentIcon />,
            title: lt('usingKaleido'),
            value: lt('organizations'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/orgs-and-users/')
        }
    ];

    return (
        <Container>
            <Grid container direction="column" spacing={3}>
                {mfaBlockedOrganizations.length > 0 &&
                <Grid item>
                    <MFABlockedOrgsBanner />
                </Grid>}
                <Grid item container justify="space-between">
                    <Grid item>
                        <Typography variant="h5">{lt('organizations')}</Typography>
                    </Grid>
                    {featureToggles.newOrg && session?.realm !== 'oidc' && <Grid item>
                        <Button color="primary" variant="contained"
                            onClick={() => history.push(`/${ORGS_PATH}/${CREATE_ORG_PATH}/1`)}
                            >{lt('createOrg')}</Button>
                    </Grid>}
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item container spacing={3} direction="column" md={8}>
                        {allOrgs()}
                    </Grid>
                    <Grid item md>
                        <DisplayCard header={lt('documentation')} itemList={documentationList} />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

interface translations {
    documentation: string
    usingKaleido: string
    organizations: string
    createOrg: string
}

const enTranslations: translations = {
    documentation: 'Documentation',
    usingKaleido: 'Using Kaleido',
    organizations: 'Organizations',
    createOrg: 'Create Organization'
}