import { Container, Grid, Hidden, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { AuthenticationBackgroundWrapper } from './AuthenticationBackgroundWrapper';
import { EnterpriseBlockchain } from './Marketing/EnterpriseBlockchain';
import { G2Crowd } from './Marketing/G2Crowd';

interface Props {
    children: JSX.Element
};

export const AuthenticationWrapper = ({ children }: Props) => {
    const { pathname } = useLocation();

    const classes = useStyles();

    return (
        <>
            <AuthenticationBackgroundWrapper>
                <Grid item container>
                    <Container>
                        <Grid item container justify="center">
                            <Hidden smDown implementation="js">
                                {pathname === '/signup' ? (
                                    <>
                                        <Grid item container direction="column" alignItems="center" justify="center" sm={6} spacing={3}>
                                            <G2Crowd />
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item container direction="column" alignItems="center" justify="center" sm={6} spacing={3}>
                                        <EnterpriseBlockchain />
                                    </Grid>
                                )}
                            </Hidden>
                            <Grid item container sm={6} alignItems="center" justify="center">
                                <Grid item>
                                    <Paper className={classes.paper}>
                                        {children}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </AuthenticationBackgroundWrapper>
        </>
    )
};

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(6)
    },
    hidden: {
        display: 'none'
    }
}));
