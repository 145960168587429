import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers'

export const NetworkHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigNetworkHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigNetworkHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/using-kaleido/cloud-integrations/privatelink/"
            bullet1={lt('bulletHelp1')}
            bullet2={lt('bulletHelp2')}
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    bulletHelp1: string,
    bulletHelp2: string
}
const enTranslations: translations = {
    helpHeader: 'Network Control',
    helpDescription: 'Advanced Private Networking with a Virtual Private Cloud (VPC) PrivateLink endpoint allows for the Kaleido network to be accessed privately outside of the public internet.',
    bulletHelp1: 'Leveraging a node’s private communication layer via PrivateLink ensures that any business critical or sensitive traffic never leaves the AWS backbone.',
    bulletHelp2: 'Nodes can be configured with a hybrid ingress (public and private) allowing for users to partition any incoming data streams in accordance with their organizational and consortia mandates.'
}