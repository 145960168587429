import React, { useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { NodeData, NodeQuery, PlanSupports, OrganizationData, OrganizationVars, OrganizationQuery, RuntimeSizeTranslation, EnRuntimeSizeTranslation, NodesData, NodesQuery } from '../../models'
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import ArrowUpDownIcon from 'mdi-react/ArrowUpDownIcon';
import RotateRightIcon from 'mdi-react/RotateRightIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import ResetIcon from 'mdi-react/LockResetIcon';
import StopCircleOutlineIcon from 'mdi-react/StopCircleOutlineIcon';
import PlayOutlineIcon from 'mdi-react/PlayOutlineIcon';
import VoteInOutIcon from 'mdi-react/FormatListChecksIcon';
import { EditableSettings } from '../../components/DisplaySettings'
import { UpdateName, RestartRuntime, DeleteResource } from '../../components/DialogWrappers'
import { UpdateSize } from './UpdateSize';
import { EnvironmentResourcesVars, EnvironmentResourceVars} from '../../interfaces';
import { ResetRuntime } from '../../components/DialogWrappers/ResetRuntime';
import { VoteInRuntime } from '../../components/DialogWrappers/VoteInRuntime';
import { VoteOutRuntime } from '../../components/DialogWrappers/VoteOutRuntime';
import { StopRuntime } from '../../components/DialogWrappers/StopRuntime';
import { StartRuntime } from '../../components/DialogWrappers/StartRuntime';

export const Settings: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeSettings:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        loading,
        data: {
            node
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: {
            ...environmentVariables,
            id: node_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables:{
            ...environmentVariables   
        },
        fetchPolicy: 'cache-only'
    });

    const client = useApolloClient();
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [updateSizeDialogOpen, setUpdateSizeDialogOpen] = useState(false);
    const [restartDialogOpen, setRestartDialogOpen] = useState(false);
    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [voteInDialogOpen, setVoteInDialogOpen] = useState(false);
    const [voteOutDialogOpen, setVoteOutDialogOpen] = useState(false);
    const [stopDialogOpen, setStopDialogOpen] = useState(false);
    const [startDialogOpen, setStartDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    if (loading || !node) return <CircularProgress />

    const peerNodeExists = nodes.filter(n => n.membership_id === node.membership_id && n.role === 'peer').length > 0;
    const canVote = PlanSupports.signerNodes(organization) && node.isEthereum && node.consensus_type !== 'raft' && node.state === 'started';
    const canVoteIn = canVote && node.init_consensus_role === 'non-signer';
    const canVoteOut = canVote && node.init_consensus_role === 'signer';

    const actionsList = [
        {
            icon: <AccountCircleIcon />,
            title: lt('changeName'),
            description: lt('nameDescription'),
            value: node.name,
            buttonLabel: lt('change'),
            action: () => setUpdateNameDialogOpen(true)
        },
        {
            icon: <ArrowUpDownIcon />,
            title: lt('changeNodeSize'),
            description: lt('sizeDescription'),
            value: lt(node.size),
            buttonLabel: lt('change'),
            action: () => setUpdateSizeDialogOpen(true)
        },
        {
            icon: <RotateRightIcon />,
            title: lt('restartNode'),
            description: lt('restartDescription'),
            buttonLabel: lt('restart'),
            action: () => setRestartDialogOpen(true)
        },
        {
            icon: <ResetIcon />,
            title: lt('resetNode'),
            description: lt('resetDescription'),
            buttonLabel: lt('reset'),
            action: () => setResetDialogOpen(true)
        }
    ]

    if (canVoteIn) {
        actionsList.push({
            icon: <VoteInOutIcon />,
            title: lt('voteInNode'),
            description: lt('voteInDescription'),
            buttonLabel: lt('change'),
            action: () => setVoteInDialogOpen(true)
        })
    }

    if (canVoteOut) {
        actionsList.push({
            icon: <VoteInOutIcon />,
            title: lt('voteOutNode'),
            description: lt('voteOutDescription'),
            buttonLabel: lt('change'),
            action: () => setVoteOutDialogOpen(true)
        })
    }

    if (node.consensus_type !== 'raft' && node.state === 'started') {
        actionsList.push({
            icon: <StopCircleOutlineIcon />,
            title: lt('stopNode'),
            description: lt('stopDescription'),
            buttonLabel: lt('stop'),
            action: () => setStopDialogOpen(true)
        })
    }

    if (node.consensus_type !== 'raft' && node.state === 'stopped') {
        actionsList.push({
            icon: <PlayOutlineIcon />,
            title: lt('startNode'),
            description: lt('startDescription'),
            buttonLabel: lt('start'),
            action: () => setStartDialogOpen(true)
        })
    }
    if (node.role !== 'orderer' || (node.role === 'orderer' && !peerNodeExists)) {
        actionsList.push({
            icon: <DeleteOutlineIcon />,
            title: lt('deleteNode'),
            description: lt('deleteDescription'),
            buttonLabel: lt('delete'),
            action: () => setDeleteDialogOpen(true)
        })
    }

    return (
        <>

            <UpdateName defaultName={node.name} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <UpdateSize {...{node}} open={updateSizeDialogOpen} setOpen={setUpdateSizeDialogOpen} />
            <RestartRuntime name={node.name} open={restartDialogOpen} setOpen={setRestartDialogOpen} />
            <ResetRuntime name={node.name} open={resetDialogOpen} setOpen={setResetDialogOpen} />
            <VoteInRuntime {...{node}} open={voteInDialogOpen} setOpen={setVoteInDialogOpen} />
            <VoteOutRuntime {...{node}} open={voteOutDialogOpen} setOpen={setVoteOutDialogOpen} />
            <StopRuntime name={node.name} open={stopDialogOpen} setOpen={setStopDialogOpen} />
            <StartRuntime name={node.name} open={startDialogOpen} setOpen={setStartDialogOpen} />
            <DeleteResource name={node.name} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} closeDialogAfterSave />

            <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">
                        {lt('nodeSettings')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <EditableSettings
                        header={lt('nodeSettings')}
                        {...{actionsList}} />
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends RuntimeSizeTranslation {
    nodeSettings: string,
    nodeSettingsDesc: string,
    changeName: string,
    changeNodeSize: string,
    restartNode: string,
    deleteNode: string,
    name: string,
    provider: string,
    change: string,
    restart: string,
    delete: string,
    nameDescription: string,
    sizeDescription: string,
    restartDescription: string,
    deleteDescription: string,
    resetNode: string,
    resetDescription: string,
    reset: string,
    stopNode: string,
    stopDescription: string,
    stop: string,
    startNode: string,
    startDescription: string,
    start: string,
    voteInNode: string,
    voteInDescription: string,
    voteOutNode: string,
    voteOutDescription: string
}
const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    nodeSettings: 'Node Settings',
    nodeSettingsDesc: 'You can rename this node, as well as change it\'s size. Restarting the node can be a decent troubleshooting option. The node can also be permanently deleted.',
    changeName: 'Change node name',
    changeNodeSize: 'Change node size',
    restartNode: 'Restart node',
    deleteNode: 'Delete node',
    provider: 'Provider',
    name: 'Name',
    change: 'Change',
    restart: 'Restart',
    delete: 'Delete',
    nameDescription: 'Change the name that identifies the node.',
    sizeDescription: 'Change the size of the node to increase memory and CPU.',
    restartDescription: 'Restart the node keeping its actual configuration.',
    deleteDescription: 'Delete the node.',
    resetNode: 'Reset Node',
    resetDescription: 'Hard reset node to apply new configurations.',
    reset: 'Reset',
    stopNode: 'Stop / Suspend Node',
    stopDescription: 'Suspend this node indefinitely.',
    stop: 'Stop',
    startNode: 'Start Node',
    startDescription: 'Start this stopped node.',
    start: 'Start',
    voteInNode: 'Change to Signer ',
    voteInDescription: 'Make this node a signer',
    voteOutNode: 'Change to Non-Signer',
    voteOutDescription: 'Make this node a non-signer'
}