import { gql, MutationFunctionOptions, SubscriptionHookOptions } from "@apollo/client";
import { PlanLimits } from ".";
import { OpsQueryVars } from "../interfaces";
import { EnvironmentProvider } from "./environments";
import { PlanName } from "./plans";

// PLAN LIMIT FUNCTIONS

export type PlanSupportsFunction = keyof typeof PlanSupports;

export const PlanSupports = {
    multiRegion: (o: Organization) => {
        return (
            o.plan === "business" ||
            o.plan === "enterprise" ||
            o.plan === "partner" ||
            o.plan === "partner_pref"
        );
    },
    hybrid: (o: Organization) => {
        return o.limits.features?.allowed_features.includes("hybrid");
    },
    fabric: (o: Organization) => {
        return o.limits.providers?.allowed_providers.includes("fabric");
    },
    ibft2sec: (o: Organization) => {
        return o.limits.features?.allowed_features.includes("ibft2sec");
    },
    supportedNodeSizes: (o: Organization) => {
        return o.limits.nodes.allowed_sizes;
    },
    baf: (o: Organization) => {
        return o.limits.configurations.allowed_configurations.includes("baf");
    },
    supportsProvider: (o: Organization, provider: EnvironmentProvider) => {
        const allowedProviders = o.limits.providers?.allowed_providers;
        if (allowedProviders) return allowedProviders.includes(provider);
        else return true; // Handling the case it's missing from the plan - which should only be until AM is delivered
    },
    signerNodes: (o: Organization) => {
        return o.plan !== 'starter'
    },
    blockPeriod: (o: Organization) => {
        return o.plan === 'enterprise' || o.plan === 'partner' || o.plan === "partner_pref"
    },
    supportsNewOrg: (o: Organization) => {
        return o.plan !== 'starter'
    },
    docXExternalStorage: (o: Organization) => {
        return o.plan !== 'starter' && o.plan !== 'team'
    },
    serviceSizes: (o: Organization) => {
        return o.plan !== 'starter' && o.plan !== 'team'
    },
    acceptingInvitations: (o: Organization) => {
        return o.plan !== 'starter' && o.plan !== 'team'
    },
    sendingInvitations: (o: Organization) => {
        return o.plan !== 'starter' && o.plan !== 'team'
    },
}

// INTERFACES

export enum BillingTypeEnum {
    none = 'none',
    other = 'other',
    contract = 'contract',
    stripe = 'stripe',
    aws = 'aws',
    azure = 'azure',
}
export type BillingType = keyof typeof BillingTypeEnum;

export const BillingTypes: BillingType[] = []
for (const billingType in BillingTypeEnum) BillingTypes.push(billingType as BillingType);

// this should be an autogenerated interface from the Organization model in graphql
export interface Organization {
    _id: string;
    type: string;
    plan: PlanName;
    plan_id: string;
    support_level: Number;
    name: string;
    owner: string;
    delegate: string;
    terms_accepted: string;
    trial_ends: string;
    cognito_domain: string;
    cognito_user_pool_id: string;
    cognito_region: string;
    cognito_client_id: string;
    cognito_client_secret: string;    
    mfa_required?: boolean;
    limits: PlanLimits;
    master_org?: string;
    billing_account?: {
        type: string,
        agreement?: string,
        stripe_customer_id?: string,
    }
    created_at: string,
    updated_at?: string,
    oidc_details?: OidcDetails
}

interface OidcDetails {
    url?: string
    alias?: string
    client_id?: string
    client_secret?: string
}

export interface OrganizationVars {
    id: string;
}

export interface OpsOrganizationsVars extends OpsQueryVars {
    region: string;
}

export interface OpsOrganizationsData {
    opsOrganizations: Organization[];
}

export interface OrganizationsData {
    organizations: Organization[];
}

export interface OrganizationsSuborgsData {
    organizationSuborgs: Organization[];
}

export interface LeaveOrgData {
    leaveOrg: boolean
}

export interface MFABlockedOrganizationsData {
    mfaBlockedOrganizations: Organization[]
}

export interface OrganizationData {
    organization: Organization;
}

export interface OpsOrganizationData {
    opsOrganization: Organization;
}

export interface UpdateOrganizationInput {
    name?: string;
    owner?: string;
    delegate?: string;
}

export interface CreateOrganizationInput extends UpdateOrganizationInput {
    region_code: string;
    master_org: string;
}

export interface CreateOrganizationData {
    createOrganization: Organization
}

export interface UpdateOrganizationVars extends OrganizationVars {
    organization: UpdateOrganizationInput;
}

export interface CreateOrganizationVars {
    organization: CreateOrganizationInput;
}

interface SetMFARequiredInput  {
    isRequired: boolean
}

export interface RequireOrgMFAVars  extends OrganizationVars {
    MFARequired: SetMFARequiredInput
}

export interface OpsUpdateOrganizationVars extends OrganizationVars {
    opsUpdates: {
        name?: string;
        plan?: PlanName;
        support_level?: Number;
        billing_account?: {
            type: BillingType;
            agreement?: string;
        }
        oidc_details?: OidcDetails
        type?: string
    }
}

// FRAGMENTS

export const OrganizationFields = ` 
    fragment OrganizationFields on Organization {
        _id
        type
        plan
        plan_id
        support_level
        name
        owner
        delegate
        terms_accepted
        trial_ends
        cognito_domain
        cognito_user_pool_id
        cognito_region
        cognito_client_id
        cognito_client_secret
        mfa_required
        master_org
        created_at
        updated_at
        billing_account {
            type
            agreement
            stripe_customer_id
        }
        limits {
            nodes {
                allowed_tiers
                allowed_sizes
            }
            services {
                allowed_tiers
                allowed_services
            }
            configurations {
                allowed_tiers
                allowed_configurations
            }
            features {
                allowed_tiers
                allowed_features
            }
            providers {
                allowed_tiers
                allowed_providers
            }
        }
        oidc_details {
            url
            alias
            client_id
            client_secret
        }
    }`;

// QUERIES & SUBSCRIPTIONS

export const OrganizationsQuery = gql`
    ${OrganizationFields}
    query getOrganizations {
        organizations {
            ...OrganizationFields
        }
    }
`;

export const OrganizationsSuborgsQuery = gql`
    ${OrganizationFields}
    query getOrganizationSuborgs($id: String!) {
        organizationSuborgs(id: $id) {
            ...OrganizationFields
        }
    }
`;

export const OpsOrganizationsQuery = gql`
    ${OrganizationFields}
    query getOpsOrganizations($region: String, $query: String) {
        opsOrganizations(region: $region, query: $query) {
            ...OrganizationFields
        }
    }
`;

export const MFABlockedOrganizationsQuery = gql`
    ${OrganizationFields}
    query getMFABlockedOrganizations {
        mfaBlockedOrganizations {
            ...OrganizationFields
        }
    }`;

export const OrganizationQuery = gql`
    ${OrganizationFields}
    query getOrganization($id: String!) {
        organization(id: $id) {
            ...OrganizationFields
        }
    }
`;

export const OpsOrganizationQuery = gql`
    ${OrganizationFields}
    query getOpsOrganization($id: String!) {
        opsOrganization(id: $id) {
            ...OrganizationFields
        }
    }
`;

export const OrganizationsSubscription = gql`
    ${OrganizationFields}
    subscription onOrganizationsChanged {
        organization {
            ...OrganizationFields
        }
    }
`;

export const UpdateOrganizationMutation = gql`
    ${OrganizationFields}
    mutation updateOrganization(
        $id: String!
        $organization: UpdateOrganizationInput!
    ) {
        updateOrganization(id: $id, organization: $organization) {
            ...OrganizationFields
        }
    }
`;

export const CreateOrganizationMutation = gql`
    ${OrganizationFields}
    mutation createOrganization(
        $organization: CreateOrganizationInput!
    ) {
        createOrganization(organization: $organization) {
            ...OrganizationFields
        }
    }
`;

export const RequireMFAOrgMutation = gql`
    mutation setMFARequired(
        $id: String!
        $MFARequired: SetMFARequiredInput!
    ) {
        setMFARequired(id: $id, MFARequired: $MFARequired)
    }
`;

export const LeaveOrgMutation = gql`
    mutation leaveOrg(
        $id: String!
    ) {
        leaveOrg(id: $id)
    }
`;

export const OpsUpdateOrganizationMutation = gql`
    ${OrganizationFields}
    mutation opsUpdateOrganization(
        $id: String!,
        $opsUpdates: OpsUpdateOrganizationInput!
    ) {
        opsUpdateOrganization(id: $id, opsUpdates: $opsUpdates) {
            ...OrganizationFields
        }
    }
`;

export function MakeOrgCreateMutationOptions(
    variables: CreateOrganizationVars,
    overrideOptions?: MutationFunctionOptions<CreateOrganizationData, CreateOrganizationVars>): MutationFunctionOptions<CreateOrganizationData, CreateOrganizationVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const org = data?.createOrganization
            if (org) {
                const { organizations } = cache.readQuery<OrganizationsData>({ query: OrganizationsQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!organizations.find(n => n._id === org._id)) {
                    cache.writeQuery({
                        variables,
                        query: OrganizationsQuery,
                        data: { organizations: organizations.concat(org) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

// SUBSCRIPTION OPTIONS

export function MakeOrganizationsSubscriptionOptions(
    overrideOptions?: SubscriptionHookOptions<OrganizationData>
): SubscriptionHookOptions<OrganizationData> {
    return {
        ...{
            onSubscriptionData: ({ client, subscriptionData }) => {
                // Get the organization object from the event
                const {
                    data: { organization } = { organization: null },
                } = subscriptionData;

                if (!organization) return;

                // Get current list of organizations from the cache
                // assert that organizations must exist since this function throws if it doesnt
                const { organizations: existingOrgs } = client.readQuery<
                    OrganizationsData
                >({ query: OrganizationsQuery })!;

                // if it already exists in the store, (its an update), apollo automagically updated the cache
                const cachedOrg = existingOrgs.find(
                    (c) => c._id === organization._id
                );

                // orgs cant be deleted ATM, so forget about handling "deletes"...

                // if it doesnt exist in the store, (it's been added), so we need to add it in manually
                if (!cachedOrg) {
                    client.writeQuery<OrganizationsData>({
                        query: OrganizationsQuery,
                        data: Object.assign({}, existingOrgs, {
                            organizations: [organization, ...existingOrgs],
                        }),
                    });
                }
            },
        },
        ...overrideOptions,
    };
}
