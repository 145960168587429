import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { NodesData, NodesQuery, LedgerContract } from '../../models';
import { AlertBanner } from './AlertBanner';
import { NODE_CONTRACTS_PATH } from '../NodeNav/NodeNav';

interface Props {
    ledgerContract: LedgerContract
};

export const NoGatewayAPI = ({ ledgerContract }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BannersNoGatewayAPI', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BannersNoGatewayAPI:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();
    
    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVariables,
        fetchPolicy: 'cache-only',
    });

    const myNode = nodes.find(n => n.membership.isMine)

    const canCreate = myNode && ledgerContract.bytecodeWithoutConstructor

    const createGatewayAPIButton = canCreate ? {
        text: lt('createGatewayAPI'),
        onClick: () => history.push(
            `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${myNode!._id}/${NODE_CONTRACTS_PATH}/create/1`,
            { contractAddress: ledgerContract._id, sourceCodeType: 'paste', cancelPath: history.location.pathname }
        )
    } : undefined
    
    return (
        <AlertBanner title={lt('noGatewayApi')} description={canCreate ? lt('noGatewayDescription') : lt('upgradeNeeded')} linkButton={createGatewayAPIButton}  />
    )
};

interface translations {
    noGatewayApi: string
    createGatewayAPI: string
    noGatewayDescription: string
    upgradeNeeded: string
}
const enTranslations: translations = {
    noGatewayApi: 'No Gateway API',
    createGatewayAPI: 'Create Gateway API',
    noGatewayDescription: 'Create a Gateway API for RESTful interaction with this contract\'s methods',
    upgradeNeeded: 'Upgrade this environment to create a Gateway API for RESTful interaction with this contract\'s methods'
}
