import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import CubeSendIcon from 'mdi-react/CubeSendIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Node } from '../../models';
import { EventStream, EventStreamSubscription, EventStreamTranslations, EnEventStreamTranslations } from '../../models/eventStreams';
import { DisplayTable, EmptyState } from '../DisplayWrappers';
import { ResourceStateChip } from '../FormControls/ResourceStateChip';
import { NODE_EVENTSTREAMS_PATH } from "../NodeNav/NodeNav";
import { APPS_EVENTSTREAMS_PATH, APPS_BASE_PATH } from "../MainNav/SideNavs/AppsIntegrations";
import { KpiCard } from "./KpiCard";

interface Props {
    eventStreams: EventStream[]
    nodes: Node[]
    eventStreamSubscriptions: EventStreamSubscription[]
    eventStreamsLoading?: boolean
    eventStreamSubscriptionsLoading?: boolean
};

export const EventStreamsList = ({ eventStreams, nodes, eventStreamSubscriptions, eventStreamsLoading, eventStreamSubscriptionsLoading }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EventStreamsList', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EventStreamsList:${key}`, interpolate)

    const { pathname } = useLocation()
    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id } = 
        useParams<{org_id: string, consortium_id: string, environment_id: string, node_id: string}>();

    const canCreate = nodes.some(n => n.state === 'started')

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`

    const columnHeaders = [
        '',
        lt('nameOrId'),
        lt('node'),
        lt('subscriptions'),
        lt('status'),
        lt('createdAt')
    ].filter(h => node_id && h === lt('node') ? false : true)

    const records = eventStreams.map(es => {
        const nodeName = nodes.find(n => n._id === es.node_id)?.name ?? ''
        return {
            key: es.id,
            onClick: () => history.push(`${basePath}/nodes/${es.node_id}/${NODE_EVENTSTREAMS_PATH}/${es.id}`),
            columns: [
                {isIcon: true, value: <CubeSendIcon />},
                {value: es.name || es.id},
                {value: nodeName},
                {value: eventStreamSubscriptions.filter(s => s.stream === es.id).length || 0},
                {value: <ResourceStateChip state={es.suspended ? 'paused' : 'live'} />},
                {value: new Date(es.created).toLocaleString()}
            ].filter(h => node_id && h.value === nodeName ? false : true)
        }
    })
    
    const loading = (eventStreamsLoading && !eventStreams.length) || (eventStreamSubscriptionsLoading && !eventStreamSubscriptions.length)

    if (loading) {
        return <CircularProgress/>
    }

    const newEventStream = () => history.push(`${basePath}/${APPS_BASE_PATH}/${APPS_EVENTSTREAMS_PATH}/create/1`, {
        nodeId: node_id,
        cancelPath: pathname
    })

    const showEmptyState = !eventStreamsLoading && !eventStreams?.length

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('eventStreams')}
                        </Typography>
                    </Grid>
                    {!showEmptyState && 
                    <Grid item>
                        <Button color="primary" variant="contained" size="large" onClick={newEventStream} disabled={!canCreate}>
                            {lt('newEventStream')}
                        </Button>
                    </Grid>
                    }
                </Grid>
                <Grid item>
                    <KpiCard 
                        liveEventStreams={eventStreams.filter(s => !s.suspended).length} 
                        pausedEventStreams={eventStreams.filter(s => s.suspended).length}
                        eventStreamSubscriptions={eventStreamSubscriptions.length} />
                </Grid>
                <Grid item container>
                    {showEmptyState ? 
                    <EmptyState imageFile='Empty-EventStream.svg' 
                        title={lt('emptyTitle')} 
                        description={lt('emptyDescription')} 
                        button={{ text: lt('emptyGoTo'), onClick: () => newEventStream(), disabled: !canCreate }}
                        documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/kaleido-services/event-streams/' }} /> :
                    <DisplayTable {...{loading}} header={lt('header')} description={lt(node_id ? 'nodeDescription' : 'envDescription')} {...{columnHeaders}} {...{records}} />
                    }
                </Grid> 
            </Grid>
        </>
    )
};

interface translations extends EventStreamTranslations {
    header: string,
    nodeDescription: string,
    envDescription: string,
    emptyTitle: string,
    emptyDescription: string,
    emptyGoTo: string,
    emptyDocumentation: string,
    eventStreams: string,
    node: string,
    newEventStream: string,
    nameOrId: string
}
const enTranslations: translations = {
    ...EnEventStreamTranslations,
    header: 'Deployed Event Streams',
    nodeDescription: 'The following Event Streams have been deployed to this node.',
    envDescription: 'The following Event Streams have been deployed to this environment.',
    emptyTitle: 'Create an Event Stream',
    emptyDescription: 'Consume and log events emitted from your deployed smart contracts and send them to a target of your choice. Define the target to deliver the events to, and we take care of the rest.',
    emptyGoTo: 'Create Event Stream',
    emptyDocumentation: 'Documentation',
    eventStreams: 'Event Streams',
    node: 'Node',
    newEventStream: 'New Event Stream',
    nameOrId: 'Name / ID'
}