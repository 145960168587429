import React from 'react';
import { 
    List,
    makeStyles,
    ListItemIcon,
    ListItemText,
    Typography,
    ListItem
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { ServicesData, ServicesQuery } from '../../models';
import { useParams, useHistory, Redirect, useLocation } from "react-router-dom";
import { BlockchainServices, B2bServices, ManagedWalletServices, CloudWalletServices, BundlesEnum, ServicesEnum, ServicesTranslations, EnServicesTranslations } from '../../models/services'
import { B2B_BASE_PATH } from '../MainNav/SideNavs/B2bCommunication'
import { BasicItems, DocumentStoreItems } from './Services'
import { ServicesPicker } from './ServicesPicker'
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_MANAGED_PATH, KEY_MANAGEMENT_CLOUD_PATH } from '../MainNav/SideNavs/KeyManagement';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { useTranslation } from 'react-i18next';
import { CloudHsmItems } from './Services/CloudHsmItems';
import { BLOCKCHAIN_BASE_PATH } from '../MainNav/SideNavs/Blockchain';
import { App2AppItems } from './Services/App2AppItems';
import { TetherItems } from './Services/TetherItems';
import { RotateSignersItems } from './Services/RotateSignersItems';
import { FIREFLY_BASE_PATH } from '../MainNav/SideNavs/Firefly';

const ServicesNav = () => {
    const { pathname } = useLocation()
    const classes = useStyles();
    const history = useHistory()
    const { org_id, consortium_id, environment_id, service_id } = useParams<any>();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ServiceNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ServiceNav:${key}`, interpolate)

    let {
        data: { 
            services
        } = { services: [] } 
    } = useQuery<ServicesData>(ServicesQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const service = services.find(s => s._id === service_id)

    const baseEnvPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`

    const splitPathname = pathname.split('/')
    
    // must follow url convention of `/SERVICE/service_id/...`
    if (!service || splitPathname[splitPathname.indexOf(service_id) - 1].toLowerCase() !== service.service.toLowerCase()) {
        return <Redirect to={baseEnvPath} />
    }

    let baseBundlePath = baseEnvPath
    let baseServicePath = baseBundlePath
    let subNavItems = <></>

    // Only Wallets services nav should group multiple different service types under a single bundle
    const isCloudWalletService = CloudWalletServices.has(service.service)
    const isManagedWalletService = ManagedWalletServices.has(service.service)

    services = isCloudWalletService || isManagedWalletService ? 
        services.filter(s => isManagedWalletService ? ManagedWalletServices.has(s.service) : CloudWalletServices.has(s.service)) : 
            services.filter(s => s.service === service.service)
    const navHeader = isCloudWalletService || isManagedWalletService ? 
        lt(isCloudWalletService ? BundlesEnum.CloudWallets : BundlesEnum.ManagedWallets) : 
            lt(ServicesEnum[service.service])

    if (B2bServices.has(service.service)) {
        baseBundlePath += `/${B2B_BASE_PATH}`
        baseServicePath = `${baseBundlePath}/${service.service}`
        switch (service.service) {
            case 'app2app':
                subNavItems = <App2AppItems {...{baseServicePath}} />
                break;
            case 'documentstore':
                subNavItems = <DocumentStoreItems {...{baseServicePath}} />
                break;
            default:
                subNavItems = <BasicItems {...{baseServicePath}} />
        }
    } else if (ManagedWalletServices.has(service.service)) {
        baseBundlePath += `/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}`
        baseServicePath = `${baseBundlePath}/${service.service}`
        switch(service.service) {
            default:
                subNavItems = <BasicItems {...{baseServicePath}} />
        }
    }  else if (CloudWalletServices.has(service.service)) {
        baseBundlePath += `/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}`;
        baseServicePath = `${baseBundlePath}/${service.service}`
        switch(service.service) {
            case 'cloudhsm':
                subNavItems = <CloudHsmItems {...{baseServicePath}} />
                break;
            default:
                subNavItems = <BasicItems {...{baseServicePath}} />
        }
    } else if (BlockchainServices.has(service.service)) {
        baseBundlePath += service.service === 'firefly-os' ? `/${FIREFLY_BASE_PATH}` : `/${BLOCKCHAIN_BASE_PATH}`
        baseServicePath = `${baseBundlePath}/${service.service}`
        switch(service.service) {
            case 'tether': 
                subNavItems = <TetherItems {...{baseServicePath}} />
                break;
            case 'rotatesigners': 
                subNavItems = <RotateSignersItems {...{baseServicePath}} />
                break;
            default: 
                subNavItems =  <BasicItems {...{baseServicePath}} />
        }
    }


    return (
        <div data-test="nodeWrapper">
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(baseBundlePath)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{lt('dashboard')}</Typography>} 
                        disableTypography />
                </ListItem>

                <ListItem>
                    <ListItemText secondary={navHeader} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>
                
                <ServicesPicker createServicePath={(isCloudWalletService || isManagedWalletService) ? baseBundlePath : baseServicePath} {...{services}} />
                { subNavItems }
            </List>
        </div>
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    backButton: {
        paddingLeft: theme.spacing(1)
    },
    backIcon: {
        minWidth: 0
    }
}));

interface translations extends ServicesTranslations {
    dashboard: string
    runtime: string
    [BundlesEnum.CloudWallets]: string
    [BundlesEnum.ManagedWallets]: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    dashboard: 'Dashboard',
    runtime: 'Runtime',
    [BundlesEnum.CloudWallets]: 'Cloud HSM Wallets',
    [BundlesEnum.ManagedWallets]: 'Managed Wallets',
}

export { ServicesNav };

