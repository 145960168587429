import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import NetworkIcon from 'mdi-react/AccessPointIcon';
import BackupIcon from 'mdi-react/CloudUploadIcon';
import VpnKeyIcon from 'mdi-react/KeyIcon';
import ShieldOutlineIcon from 'mdi-react/ShieldOutlineIcon';
import StreamIcon from 'mdi-react/SwapHorizontalIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper } from '../../components/DialogWrappers';
import { EditableSettings } from '../../components/DisplaySettings';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../../components/MainNav/SideNavs/Security';
import { cloudConfigTypeURList, CLOUDCONFIG_BACKUP_PATH, CLOUDCONFIG_BASE_PATH, CLOUDCONFIG_KEYSTORE_PATH, CLOUDCONFIG_LOGSTREAM_PATH, CLOUDCONFIG_NETWORK_PATH, ConfigTypesUrl } from '../../components/MainNav/SideNavs/CloudConfigs';
import { CREATE_NODES_PATH } from '../../components/NodeNav/NodePicker';
import { CreateStepProps, EnvironmentResourcesVars } from '../../interfaces';
import { FabricCaServiceDetails, NodeConfigs, NodeRole } from '../../models';
import { ConfigsData, ConfigsQuery, ConfigTypeTranslationsInterface, ConfigTypeTranslations } from '../../models/configs';
import { ConfigOptions } from './ConfigOptions';
import { Step3Help } from './Step3Help';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import { BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH } from '../../components/MainNav/SideNavs/Blockchain';

interface Props extends CreateStepProps {
    name: string
    membershipId: string,
    zoneId: string,
    nodeConfigs: NodeConfigs,
    setNodeConfigs: React.Dispatch<React.SetStateAction<NodeConfigs>>,
    isCorda?: boolean,
    isFabric?: boolean,
    role: NodeRole,
    createFabricCaName: string
    fabricCaServiceDetails?: FabricCaServiceDetails
};

export const Step3 = ({ name, membershipId, zoneId, cancelPath, nodeConfigs, setNodeConfigs, isCorda, isFabric, role, createFabricCaName, fabricCaServiceDetails }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep3', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep3:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        data: {
            configs
        } = { configs: [] }
    } = useQuery<ConfigsData, EnvironmentResourcesVars>(ConfigsQuery, {
        variables: environmentVariables,
        fetchPolicy: 'cache-and-network'
    });

    const save = () => {
        const nextStep = isFabric ? '4' : '3' // fabric has an extra step for the CA
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${CREATE_NODES_PATH}/${nextStep}`, {
            name,
            membershipId,
            zoneId,
            role,
            createFabricCaName,
            configs: nodeConfigs,
            fabricCaServiceDetails
        })
    }

    const handleDataChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, configType: keyof NodeConfigs) => {
        const currentData = {...nodeConfigs};
        currentData[configType] = e.target.value as string;
        setNodeConfigs(currentData);
    }

    const historyPusher = (configType: ConfigTypesUrl, configId?: string) => {
        let path = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
        if (cloudConfigTypeURList.includes(configType)) {
            path = `${path}/${CLOUDCONFIG_BASE_PATH}/${configType}`;
        } else if (configType === 'baf') {
            path = `${path}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`;
        } else {
            path = `${path}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}`;
        };

        if (configId) {
            history.push(`${path}/${configId}`);
        } else {
            history.push(path);
        }
    }
    const actionsList = [
        {
            icon: <VpnKeyIcon />,
            title: lt('keystore'),
            description: lt('keystoreDescription'),
            value: <ConfigOptions configurations={configs} configType="kms" {...{nodeConfigs}} {...{handleDataChange}} />,
            buttonLabel: isCorda ? lt('comingSoon') : lt('createNew'),
            disabledButton: isCorda || !!nodeConfigs.kms_id,
            action: () => historyPusher(CLOUDCONFIG_KEYSTORE_PATH)
        },
        {
            icon: <BackupIcon />,
            title: lt('backup'),
            description: lt('backupDescription'),
            value: <ConfigOptions configurations={configs} configType="backup" {...{nodeConfigs}} {...{handleDataChange}} />,
            buttonLabel: lt('createNew'),
            disabledButton: !!nodeConfigs.backup_id,
            action: () => historyPusher(CLOUDCONFIG_BACKUP_PATH)
        },
        {
            icon: <StreamIcon />,
            title: lt('logstream'),
            description: lt('logstreamDescription'),
            value: <ConfigOptions configurations={configs} configType="opsmetric" {...{nodeConfigs}} {...{handleDataChange}} />,
            buttonLabel: lt('createNew'),
            disabledButton: !!nodeConfigs.opsmetric_id,
            action: () => historyPusher(CLOUDCONFIG_LOGSTREAM_PATH)
        }
    ]

    if (role !== 'peer') {
        actionsList.push({
            icon: <NetworkIcon />,
            title: lt('networking'),
            description: lt('networkingDescription'),
            value: <ConfigOptions configurations={configs} configType="networking" {...{nodeConfigs}} {...{handleDataChange}} />,
            buttonLabel: lt('createNew'),
            disabledButton: !!nodeConfigs.networking_id,
            action: () => historyPusher(CLOUDCONFIG_NETWORK_PATH)
        });
    }

    if (!isCorda && !isFabric) {
        actionsList.push({
            icon: <ShieldOutlineIcon />,
            title: lt('baf'),
            description: lt('bafDescription'),
            value: <ConfigOptions configurations={configs} configType="baf" {...{nodeConfigs}} {...{handleDataChange}} />,
            buttonLabel: lt('createNew'),
            disabledButton: !!nodeConfigs.baf_id,
            action: () => historyPusher(SECURITY_BAF_PATH)
        })
    };

    if (!isCorda) {
        actionsList.push({
            icon: <AccountGroupIcon />,
            title: lt('configuration'),
            description: lt(isFabric ? 'protocolConfigsFabric' : 'protocolConfigurationDescription'),
            value: <ConfigOptions configurations={configs} configType="node_config" {...{nodeConfigs}} {...{handleDataChange}} />,
            buttonLabel: lt('createNew'),
            disabledButton: !!nodeConfigs.baf_id,
            action: () => historyPusher(BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH)
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('configDescription')}
                </Typography>
            </Grid>
            <EditableSettings hidePaper  {...{actionsList}} />
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={false} onNext={save} />
            <Step3Help {...{isFabric}}/>
        </>
    )
};


interface translations extends ConfigTypeTranslationsInterface {
    header: string,
    name: string,
    configure: string,
    backupDescription: string,
    keystoreDescription: string,
    logstreamDescription: string,
    networkingDescription: string,
    configDescription: string,
    bafDescription: string,
    protocolConfigurationDescription: string
    createNew: string,
    comingSoon: string
    protocolConfigsFabric: string
}
const enTranslations: translations = {
    ...ConfigTypeTranslations,
    header: 'Configurations',
    name: 'Name of this node',
    configure: 'Configure',
    keystoreDescription: 'Enhance the protection of Key Material',
    protocolConfigurationDescription: 'Garbage Collection, Gateway API and others.',
    backupDescription: 'Backup your node to the cloud',
    logstreamDescription: 'Stream logs to your own management system',
    networkingDescription: 'Private networking with your own Cloud account',
    configDescription: 'You can connect your node to native cloud services. Configurations can also be edited after creation.',
    bafDescription: 'OAuth 2.0, multi-tenancy and access control',
    createNew: 'Create',
    comingSoon: 'Coming soon',
    protocolConfigsFabric: 'Configure the Fabric Logging Specification and the FabConnect REST API Gateway'
}