import {
    Button,
    Grid, TextField,
    Typography
} from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    mfaCode: string
    setMFACode: React.Dispatch<React.SetStateAction<string>>
    errorMessage: string
    loading: boolean
    handleMFA: () => void
}

export const MFAChallenge: React.FC<Props> = ({
    mfaCode,
    setMFACode,
    loading,
    errorMessage,
    handleMFA
}) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MFAChallenge', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MFAChallenge:${key}`, interpolate);

    return (
        <form id="MFAChallengeForm" className={'Hubspot'} style={{ width: '300px' }} onSubmit={event => { event.preventDefault(); handleMFA() }}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('multiFactorAuthentication')}
                    </Typography>
                </Grid>
                {errorMessage &&
                    <Grid item>
                        <Typography color="error" variant="subtitle1">
                            {errorMessage}
                        </Typography>
                    </Grid>
                }
                <Grid item>
                    <Typography variant="body1">{lt('introduction')}</Typography>
                </Grid>
                <Grid item>
                    <TextField label={lt('code')} variant="outlined" fullWidth value={mfaCode} onChange={event => setMFACode(event.target.value)} />
                </Grid>
                <Grid item>
                    <Button type="submit" fullWidth variant="contained" color="primary" size="large"
                        disabled={loading || mfaCode.length < 6}>{lt('verify')}</Button>
                </Grid>
            </Grid>
        </form>
    )
}

interface translations {
    multiFactorAuthentication: string
    introduction: string
    code: string
    verify: string
}

const enTranslations: translations = {
    multiFactorAuthentication: 'Multi-Factor Authentication',
    introduction: 'Enter the 6-digit code you see in your authentication app. Optionally, enter one of the unused recovery codes',
    code: 'Code',
    verify: 'Verify'
}
