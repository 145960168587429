import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { PaginatedEnvironmentResourcesVars, EnvironmentResourceVars, ConsortiumResourceVars } from '../../interfaces';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { Grid, Typography, CircularProgress, TablePagination } from '@material-ui/core';
import { DisplayTable, ComingSoon } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { LedgerContractsQuery, LedgerContractsData, LedgerContract, LedgerContractQuery, LedgerContractData, EnvironmentData, EnvironmentQuery } from '../../models';
import { DATA_CONTRACTS, ENVIRONMENT_DATA_EXPLORER } from '../../components/MainNav/SideNavs/DataExplorer';

const LIMITLIST = [10, 25];

export const Contracts = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BlocksTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BlocksTable:${key}`, interpolate)

    const [errorMessage, setErrorMessasge] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [searchActive, setSearchActive] = useState(false);

    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const location = useLocation();
    const param = new URLSearchParams(location.search)
    const page = param.get('page');
    const urlLimit = param.get('limit');

    useEffect(()=> {
        if (page && parseInt(page)) {
            const p = parseInt(page);
            if ( p === 0) {
                setCurrentPage(p);
            } else {
                setCurrentPage(p - 1);
            }
        } 
    }, [page]);

    useEffect(() => {
        if(urlLimit) {
            const l = parseInt(urlLimit);
            if(LIMITLIST.includes(l)) {
                setLimit(l);
            } else {
                setLimit(LIMITLIST[0]);
            }
        } 
    }, [urlLimit])

    const updatePage = (page: number, limit: number) => {
        history.push({
            search: `?page=${page + 1}&limit=${limit}`
        })
    }

    const {
        refetch,
        loading,
        data: {
            ledgerContracts: {
                totalCount,
                contracts
            }
        } = {ledgerContracts: {
            totalCount: -1,
            contracts: []
        }}
    } = useQuery<LedgerContractsData, PaginatedEnvironmentResourcesVars>(LedgerContractsQuery, {
        variables: {
            ...environmentVariables,
            limit,
            start: currentPage * limit,
        },
        fetchPolicy: 'cache-and-network'
    });

    const [searchContract, {
        loading: searchLoading,
        data: {
            ledgerContract
        } = {ledgerContract: null}
    }] = useLazyQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        onError: () => setErrorMessasge(lt('searchNotFound', {search: searchInput}))
    });

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;

    if (isCorda) return <ComingSoon page="dataExplorer" />

    if (loading && contracts.length === 0) return <CircularProgress />;
    
    const data = ledgerContract && searchActive && !searchLoading ? [ledgerContract] : contracts;
    
    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        updatePage(page, limit);
    }

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updatePage(0, parseInt(event.target.value))
    }

    const columns = [lt('gatewayEndpoint'), lt('address'), lt('creator')]

    const records: DisplayTableRecord[] = (data as LedgerContract[]).map((contract, index) => ({
        key: `contracts-${index}`,
        columns: [{
            value: contract.gatewayAPIEndpoint ?? '--'
        },{
            value: <ShortenedHash address={contract.address ?? ''} />
        }, {
            value: <ShortenedHash address={contract.creator ?? ''} />
        }],
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}/${contract.address}`)
    }));

    const onSubmitSearch = async (searchInput: string) => {
        setSearchInput(searchInput);
        if(searchInput) {
            await searchContract({
                variables: {
                    ...environmentVariables,
                    id: searchInput
                }
            })
        }
    };

    const onRefreshClick = () =>{
        updatePage(0, limit);
        refetch();
    }

    const toolBarOptions = {
        search: {
            active: true,
            onSearch: onSubmitSearch,
            setSearchActive,
            label: lt('searchLabel')
        },
        refresh: {
            active: true,
            onRefresh: onRefreshClick
        }
    }

    const Pagination = <TablePagination 
                            rowsPerPageOptions={LIMITLIST} 
                            component="div" 
                            count={totalCount === -1 ? (contracts.length < limit ? (currentPage) * limit + contracts.length : -1) : totalCount} 
                            rowsPerPage={limit} 
                            onChangeRowsPerPage={onChangeRowsPerPage} 
                            onPageChange={onChangePage} 
                            page={currentPage} 
                            nextIconButtonProps={{disabled: loading || (totalCount !== -1 ? ((currentPage + 1) * limit >= totalCount) : (contracts.length < limit))}}
                            backIconButtonProps={{disabled: loading || currentPage <= 0}} />

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge}/>
            <Grid container direction="column" spacing={3}>
                <Grid item container wrap="nowrap" justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">{lt('header')}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <DisplayTable loading={loading} {...{toolBarOptions}} header={lt('header')} columnHeaders={columns} {...{records}} actionFooter={ledgerContract && searchActive ? undefined : Pagination} />
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    header: string,
    address: string,
    txCount: string,
    creator: string,
    searchLabel: string,
    searchNotFound: string,
    gatewayEndpoint: string
}

const enTranslations: translations = {
    header: 'Contracts',
    address: 'Address',
    txCount: 'Tx Count',
    creator: 'Deployed By',
    searchLabel: 'Enter Contract Address',
    searchNotFound: 'Contract "{{search}}" Not Found',
    gatewayEndpoint: 'Gateway Api Endpoint'
}