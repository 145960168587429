import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { WalletsQuery, WalletsData } from '../../models/wallets';
import { EnvironmentResourcesVars } from '../../interfaces';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { DisplayTable, EmptyState } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { NODE_ACCOUNTS_PATH } from '../../components/NodeNav/NodeNav';
import { CREATE_NODES_PATH } from '../../components/NodeNav/NodePicker';


export const NodeWallets = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeWallets', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeWallets:${key}`, interpolate);

    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const createPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${CREATE_NODES_PATH}/1`

    const {
        loading,
        data: {
            wallets
        } = { wallets: []}
    } = useQuery<WalletsData, EnvironmentResourcesVars>(WalletsQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-and-network'
    })

    const nodeWallets = useMemo(() => wallets.filter(wallet => wallet.account_type === 'node' && wallet.membership_id !== 'sys--mon' && !wallet.deactivated && wallet.membership.isMine), [wallets]);

    if (loading && wallets.length === 0) return <CircularProgress />;

    if (nodeWallets.length === 0) return (
        <EmptyState imageFile='Empty-Node.svg' 
            title={lt('addNode')} 
            description={lt('emptyDescription')} 
            button={{ text: lt('createNode'), onClick: () => history.push(createPath) }}
            documentation={{ text: lt('documentation'), link: 'https://docs.kaleido.io/using-kaleido/blockchain-nodes/' }} />
    )

    const columnHeaders = [lt('node'), lt('membership'), lt('accountsNumber'), lt('dateCreated')];

    const records: DisplayTableRecord[] = nodeWallets.map((wallet, index) => ({
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${wallet.wallet_id}/${NODE_ACCOUNTS_PATH}`),
        key: `table_${index}`,
        columns: [
            {
                value: wallet.node_name ?? ''
            }, {
                value: wallet.membership.name
            }, {
                value: wallet.account_count
            }, {
                value: new Date(wallet.created_at).toLocaleString()
            }
        ]
     }))
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container>
                <Grid item>
                    <Typography variant="h5">{lt('nodeWallets')}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <DisplayTable {...{columnHeaders}} {...{records}} header={lt('header')} />
            </Grid>
        </Grid>
    )
}

interface translations {
    header: string
    nodeWallets: string
    node: string
    accountsNumber: string
    dateCreated: string
    membership: string
    emptyDescription: string
    addNode: string
    documentation: string
    createNode: string
}

const enTranslations: translations = {
    header: 'All Node Wallets',
    nodeWallets: 'Node Wallets',
    node: 'Node',
    accountsNumber: 'Accounts #',
    dateCreated: 'Date Created',
    membership: 'Membership',
    emptyDescription: 'In order to start signing transactions using accounts from your node wallets, is necessary to create a node first.',
    addNode: 'Add a Node',
    documentation: 'Documentation',
    createNode: 'Create Node'
}