import { gql } from "@apollo/client";
import { GraphQLType } from '../interfaces';
import { PlanName } from "./plans";
import { ServicesEnum } from "./services";

export interface AgreementBase extends GraphQLType {
    description: string;
    type: 'offer' | 'contract' | 'package';
    plans: PlanName[];
    primary_org?: string;
}

export interface AgreementTemplate extends GraphQLType {
    duration: number;
    units: 'years'|'months'|'days';
}

export interface AgreementTerm extends GraphQLType {
    start_date?: string;
    end_date?: string;
    require_balance?: boolean;
    expire_balance?: boolean;
    archive_resources?: boolean;
    expired_at?: string;
    applied_at?: string;
}

export interface AgreementOverage extends GraphQLType {
    agreement?: string;
}

export interface AgreementCredits extends GraphQLType {
    initial_balance?: number;
};

export interface AgreementBilling extends GraphQLType {
    discount_percent?: number;
    discount_value?: number;
    minimum?: number;
    uplifts?: {
        support?: {[plan: string]: number};
    };
    storage?: {[plan: string]: number};
};

export interface AgreementPriceSheet extends GraphQLType {
    tags?: string[];
    evictable?: boolean;
    discount_percent?: number;
    hourly?: {
        node?: {
            [provider: string]: {
                [size: string]: {
                    [plan: string]: number;
                }
            }
        };
        service?: {
            [type: string]: {
                [size: string]: {
                    [plan: string]: number;
                }
            }
        };
    },
    monthly?: {
        membership?: {
            [plan: string]: number;
        };
    }   
};

export interface AgreementLimits extends GraphQLType {
    nodes?: {
        [size: string]: number;
    };
    services?: {
        [size: string]: number;
    };
    service_types?: [keyof typeof ServicesEnum];
}

export interface AgreementUpdate extends AgreementBase {
    template?: AgreementTemplate;
    term?: AgreementTerm;
    overage?: AgreementOverage;
    credits?: AgreementCredits;
    billing?: AgreementBilling;
    price_sheets?: AgreementPriceSheet[];
    limits?: AgreementLimits;
}

export interface AgreementCreate extends AgreementUpdate {
    name: string
}

export interface Agreement extends AgreementCreate {
    _id: string
}

export interface OpsAgreementsData {
    opsAgreements: Agreement[];
}

export interface OpsAgreementData {
    opsAgreement: Agreement;
}

export interface OpsAgreementsResolveData {
    opsAgreementResolve: Agreement
}

export interface UpdateAgreementVars {
    id: string;
    agreement: Agreement;
}

export interface UpdateAgreementData {
    opsUpdateAgreement: AgreementUpdate
}

export interface CreateAgreementVars {
    region: string;
    agreement: AgreementCreate;
}

export interface CreateAgreementData {
    opsCreateAgreement: Agreement
}

export interface DeleteAgreementVars {
    id: string;
}

export interface DeleteAgreementData {
    opsDeleteAgreement: string
}

export interface BindAgreementVars {
    org_id: string;
    name: string;
}

export interface BindAgreementData {
    opsBindAgreement: Agreement
}

export interface UnBindAgreementData {
    opsUnBindAgreement: string
}

export interface OpsOKData {
    opsOk: boolean;
}

// FRAGMENTS

export const AgreementFields = ` 
    fragment AgreementFields on Agreement {
        _id
        type
        name
        description
        primary_org
        plans
        template {
            duration
            units
        }
        term {
            start_date
            end_date
            require_balance
            expire_balance
            archive_resources
            expired_at
            applied_at
        }
        overage {
            agreement
        }
        credits {
            initial_balance
        }
        billing {
            discount_percent
            discount_value
            minimum
            uplifts
            storage
        }
        price_sheets {
            tags
            evictable
            discount_percent
            hourly
            monthly
        }
        limits {
            nodes
            services
            service_types
        }
    }`;

// QUERIES & SUBSCRIPTIONS

export const OpsOKQuery = gql`
    query getOpsOK {
        opsOk
    }
`;

export const OpsAgreementsQuery = gql`
    ${AgreementFields}
    query getOpsAgreements($region: String, $query: String) {
        opsAgreements(region: $region, query: $query) {
            ...AgreementFields
        }
    }
`;

export const OpsAgreementQuery = gql`
    ${AgreementFields}
    query getOpsAgreement($id: String!) {
        opsAgreement(id: $id) {
            ...AgreementFields
        }
    }
`;

export const OpsAgreementResolveQuery = gql`
    ${AgreementFields}
    query getOpsAgreementForOrg($org_id: String!) {
        opsAgreementResolve(org_id: $org_id) {
            ...AgreementFields
        }
    }
`;

export const OpsAgreementUpdateMutation = gql`
    ${AgreementFields}
    mutation opsUpdateAgreement(
        $id: String!
        $agreement: AgreementInput!
    ) {
        opsUpdateAgreement(id: $id, agreement: $agreement) {
            ...AgreementFields
        }
    }
`;

export const OpsCreateAgreementMutation = gql`
    ${AgreementFields}
    mutation opsAtCreate(
        $region: String!
        $agreement: AgreementInput!
    ) {
        opsCreateAgreement(region: $region, agreement: $agreement) {
            ...AgreementFields
        }
    }
`;

export const OpsDeleteAgreementMutation = gql`
    mutation opsDeleteAgreement(
        $id: String!
    ) {
        opsDeleteAgreement(id: $id)
    }
`;

export const OpsBindAgreementMutation = gql`
    ${AgreementFields}
    mutation opsBindAgreement(
        $org_id: String!
        $name: String!
    ) {
        opsBindAgreement(org_id: $org_id, name: $name) {
            ...AgreementFields
        }
    }
`;

export const OpsUnBindAgreementMutation = gql`
    mutation opsUnBindAgreement(
        $org_id: String!
    ) {
        opsUnBindAgreement(org_id: $org_id)
    }
`;
