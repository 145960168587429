import React, {useMemo, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Typography, Grid, Button, Divider } from "@material-ui/core";
import { CreateWrapper } from '../../../../components/DialogWrappers'
import { useQuery } from '@apollo/client';
import { MembershipIdProofQuery, MembershipIdProofData, MembershipQuery, MembershipData, ServicesData, ServicesQuery } from '../../../../models';
import { IdRegSetup } from './IdRegSetup';
import { CertSetup } from './CertSetup';
import { IsRegSetup } from './IsRegSetup';
import {Step1Help} from './Step1Help';
import { CreateStepProps, ConsortiumResourceVars } from '../../../../interfaces';
import { ExternalInfo } from './ExternalInfo';

interface Props extends CreateStepProps {
    membershipId: string
    prerequisitesComplete: () => void
};

export const Step1 = ({ membershipId, prerequisitesComplete, cancelPath }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateDestinationStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateDestinationStep1:${key}`, interpolate)

    const [hasCert, setHasCert] = useState(false);
    const [isCertReg, setIsCertReg] = useState(false);
    const [isSetupOnProgress, setIsSetupOnProgress] = useState(false);

    const {consortium_id, environment_id } = useParams<any>();

    const {
        data: IdProof
    } = useQuery<MembershipIdProofData>(MembershipIdProofQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            id: membershipId!
        }
    });

    const {
        data: {
            membership
        } = { membership: null }
    } = useQuery<MembershipData, ConsortiumResourceVars>(MembershipQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: membershipId
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: { 
            services
        } = { services: [] } 
    } = useQuery<ServicesData>(ServicesQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const isExternal = !!membership?.verification_proof && !membership?.verification_selfsigned;

    const hasIdReg = useMemo(() => {
        return !!services.find(entry => entry.service === 'idregistry' && entry.state === 'started');
    }, [services]);

    const idRegistryId = useMemo(() => {
        const idRegistry = services.find(entry => entry.service === 'idregistry' && entry.state === 'started')
        return idRegistry?._id ?? '';
    }, [services]);


    useEffect(() => {
        setHasCert(!!IdProof?.membershipIdProof.payload);
    }, [IdProof]);

    const isSetupComplete = useMemo(() => {
        return hasCert && hasIdReg && isCertReg;
    }, [hasCert, hasIdReg, isCertReg]);

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Divider />
                </Grid>
                <IdRegSetup {...{hasIdReg}} {...{setIsSetupOnProgress}}  {...{isSetupOnProgress}} {...{membershipId}} {...{isExternal}}/>
                <Grid item>
                    <Divider />
                </Grid>
                <CertSetup {...{isSetupOnProgress}} {...{setIsSetupOnProgress}} {...{membershipId}} {...{hasIdReg}} {...{hasCert}} {...{setHasCert}}/>
                <Grid item>
                    <Divider />
                </Grid>
                { isExternal ? <ExternalInfo {...{isCertReg}} {...{setIsCertReg}} {...{idRegistryId}} {...{membershipId}}/> : 
                    <IsRegSetup {...{isSetupOnProgress}} {...{setIsSetupOnProgress}} {...{membershipId}} {...{hasCert}} {...{hasIdReg}} {...{isCertReg}} {...{setIsCertReg}} {...{idRegistryId}} /> 
                }
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
            {
                isExternal && !hasIdReg && 
                <Grid item>
                    <Button 
                        onClick={() => setIsSetupOnProgress(true)} 
                        disabled={isSetupOnProgress} 
                        variant="contained" 
                        fullWidth 
                        color="primary">
                            {lt('provisionOnChainRegistry')}
                    </Button>
                </Grid>
            }
            {
                !isSetupComplete && !isExternal && 
                <Grid item>
                    <Button onClick={() => setIsSetupOnProgress(true)} disabled={isSetupOnProgress || isSetupComplete} variant="contained" fullWidth color="primary">{lt('letKaleido')}</Button>
                </Grid>
            }
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={!isSetupComplete} onNext={prerequisitesComplete} isFirstStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string,
    letKaleido: string
    provisionOnChainRegistry: string
}
const enTranslations: translations = {
    header: 'Prerequisites',
    provisionOnChainRegistry: 'Provision On-Chain Registry',
    letKaleido: 'Let Kaleido register my membership identity on-chain'
}