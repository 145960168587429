import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models';
import { useParams, useHistory } from "react-router-dom";
import { ConsortiumResourcesVars } from '../../interfaces';
import { SYSTEM_MEMBERSHIP } from '../MainNav/SideNavs/AddressBook';
import { NavPopoverItem } from '../MainNav/SideNavs/NavPopoverItem';

const MembershipPicker: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MembershipPicker', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MembershipPicker:${key}`, interpolate)

    const history = useHistory();
    const { org_id, consortium_id, environment_id, membership_id } = useParams<any>();

    const environmentVars = { 
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const { 
        data: { 
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const orgName = membership_id === SYSTEM_MEMBERSHIP ? lt('system') : consortiumMemberships.find(n => n._id === membership_id)?.org_name

    const membershipsForDropdown = (
        [{ id: SYSTEM_MEMBERSHIP, org_name: lt('system') }]
            .concat(consortiumMemberships.map(c => ({ id: c._id, org_name: c.org_name })))
            .filter(c => c.id !== membership_id)
    )

    let popoverItems = membershipsForDropdown.map(m => ({
        name: m.org_name,
        action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/addressbook/memberships/${m.id}`)
    }))

    const item = {
        name: orgName || '', 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />
};

interface translations {
    system: string
}
const enTranslations: translations = {
    system: 'System'
}

export { MembershipPicker };

