import { FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography, TextField } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper } from '../../../components/DialogWrappers';
import { NodeSelector } from "../../../components/FormControls/NodeSelector";
import { APPS_BASE_PATH, APPS_EVENTSTREAMS_PATH } from "../../../components/MainNav/SideNavs/AppsIntegrations";
import { CreateStepProps } from '../../../interfaces';
import { EventStreamType } from "../../../models/eventStreams";
import { Step1Help } from './Step1Help';

interface Props extends CreateStepProps {
    nodeId: string,
    setNodeId: React.Dispatch<React.SetStateAction<string>>,
    name: string,
    setName: React.Dispatch<React.SetStateAction<string>>,
    type: EventStreamType,
    setType: React.Dispatch<React.SetStateAction<EventStreamType>>,
};

export const Step1 = ({ nodeId, setNodeId, name, setName, type, setType, cancelPath }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamsCreateEventStreamStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamsCreateEventStreamStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const save = async () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}/${APPS_EVENTSTREAMS_PATH}/create/2`, {
            type,
            nodeId
        })
    }

    const makeFormControl = (s: EventStreamType, label: string, description: string) => {
        return (
            <FormControlLabel checked={type === s}
                value={mapTypeValue(s)} 
                control={<Radio color="primary" />} 
                label={makeLabel(label, description)} />
        )
    }

    const makeLabel = (label: string, description: string) => 
        <div className={classes.radioLabel}>
            <Typography variant="body1">
                {label}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {description}
            </Typography>
        </div>

    const mapTypeValue = (s: EventStreamType) => s

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setType((event.target.value as EventStreamType));
    };

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <NodeSelector {...{nodeId}} {...{setNodeId}} />
            </Grid>

            <Grid item>
                <TextField required data-test='textField_eventStreamName' 
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <Typography variant="h5">
                    {lt('type')}
                </Typography>

                <FormControl component="fieldset" margin="none">
                    <RadioGroup value={type} onChange={handleChange}>
                        {makeFormControl('webhook', lt('webhook'), lt('webhookDescription'))}
                    </RadioGroup>
                    <RadioGroup value={type} onChange={handleChange}>
                        {makeFormControl('websocket', lt('websocket'), lt('websocketDescription'))}
                    </RadioGroup>
                </FormControl>
            </Grid>

        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={!type} onNext={save} isFirstStep />
            <Step1Help />
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioLabel: {
        paddingTop: theme.spacing(1)
    },
}));

interface translations {
    header: string,
    headerDescription: string,
    webhook: string,
    webhookDescription: string,
    websocket: string,
    websocketDescription: string,
    type: string,
    name: string
}
const enTranslations: translations = {
    header: 'Enter Event Stream Details',
    headerDescription: 'Consume and log events emitted from your deployed smart contracts and send them to a target of your choice.',
    webhook: 'Webhook',
    webhookDescription: 'Events will be delivered via HTTP to an API endpoint of your choosing.',
    websocket: 'WebSocket',
    websocketDescription: 'Events will be pushed to your application over a connection established from your own network.',
    type: 'Connection Type',
    name: 'Name'
}