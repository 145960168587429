import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import {
    DestinationsQuery,
    DestinationsData,
    DestinationsVars,
    DeleteDestinations,
    CreateDestinationMutation,
} from "../../../models/documentstore";
import { useTranslation } from "react-i18next";
import InsertDriveFileIcon from "mdi-react/FileIcon";
import {
    Grid,
    makeStyles,
    Typography,
    Button,
    CircularProgress,
    capitalize,
    IconButton,
} from "@material-ui/core";
import {
    FormDialog,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../../components/DialogWrappers";
import {
    GenericStatusIcon,
    DisplayTable,
} from "../../../components/DisplayWrappers";
import { DestinationsMenu } from "./DestinationsMenu";
import { DOCSTORE_DESTINATIONS_PATH } from "../../../components/ServicesNav/Services/DocumentStoreItems";
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';

export const Destinations: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "destinationsTable", enTranslations);

    const { org_id, service_id, consortium_id, environment_id } = useParams<any>();

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmSetup, setConfirmSetup] = useState(false);
    const [destinationAction, setDestinationAction] = useState("");
    const [errorMessage, setErrorMessasge] = useState("");
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const history = useHistory();

    const {
        refetch,
        loading: destinationsLoading,
        data: destinationsResults,
    } = useQuery<DestinationsData, DestinationsVars>(DestinationsQuery, {
        variables: { service_id: service_id! },
        fetchPolicy: "cache-and-network",
        onError: (err) => ErrorSnackbarCatcher(err, setErrorMessasge),
    });

    const [createDestination] = useMutation(CreateDestinationMutation);

    const [deleteDocument] = useMutation(DeleteDestinations);

    const onConfirmDelete = async () => {
        await deleteDocument({
            variables: {
                service_id: service_id!,
                destinationName: destinationAction,
            },
        });
        await refetch();
    };

    const onDeleteClick = (destName: string) => {
        setDestinationAction(destName);
        setConfirmDelete(true);
    };

    const onConfirmSetup = async () => {
        setLoading(true);
        try {
            await createDestination({
                variables: {
                    service_id: service_id!,
                    newDest: destinationAction,
                },
            });
            await refetch();
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        } finally {
            setLoading(false);
        }
    };

    const onSetupClick = (destName: string) => {
        setDestinationAction(destName);
        setConfirmSetup(true);
    };

    if ((destinationsLoading && !destinationsResults) || loading)
        return <CircularProgress />;

    const onCreateDestinationsClick = () => {
        history.push(
            `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/b2b/documentstore/${service_id}/${DOCSTORE_DESTINATIONS_PATH}/create/1`
        );
    };

    const destinationNameCell = (destName: string) => (
        <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
                <InsertDriveFileIcon className={classes.icon} />
            </Grid>
            <Grid item>
                <Typography variant="body2" noWrap>
                    {destName}
                </Typography>
            </Grid>
        </Grid>
    );

    const statusCell = (isSetupComplete: boolean) => (
        <Grid container alignItems="center" wrap="nowrap">
            <Grid item className={classes.icon}>
                <GenericStatusIcon
                    iconProps={{ fontSize: "small" }}
                    overrideOk={isSetupComplete}
                />
            </Grid>
            <Grid item>
                <Typography variant="body2" noWrap>
                    {isSetupComplete
                        ? t("destinationsTable:setupComplete")
                        : t("destinationsTable:setupIncomplete")}
                </Typography>
            </Grid>
        </Grid>
    );
    
    const uriCell = (uri: string) => (
        <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
                {uri}
            </Grid>
            <Grid item>
                <CopyToClipboard text={uri}>
                    <IconButton color="inherit" size="small">
                        <ContentCopyIcon />
                    </IconButton>
                </CopyToClipboard>
            </Grid>
        </Grid>
    );

    const records = destinationsResults
        ? destinationsResults.destinations.map((entry, index) => ({
              key: `${index}`,
              columns: [
                  {
                      value: destinationNameCell(entry.name),
                  },
                  {
                      value: statusCell(entry.setup_complete),
                  },
                  {
                      value: uriCell(entry.uri),
                  },
                  {
                      isIcon: true,
                      value: (
                          <DestinationsMenu
                              actionDestinationName={entry.name}
                              setupComplete={entry.setup_complete}
                              {...{ onSetupClick }}
                              {...{ onDeleteClick }}
                          />
                      ),
                  },
              ],
          }))
        : [];

    const headers = [t("destinationsTable:name"), t("destinationsTable:status"), t("destinationsTable:uri"), t("destinationsTable:actions")];

    return (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessasge}
            />
            <FormDialog
                successMessage={t("destinationsTable:successDelete")}
                open={confirmDelete}
                description={destinationAction}
                setOpen={setConfirmDelete}
                header={t("destinationsTable:confirmDelete")}
                onSave={() => onConfirmDelete()}
                saveText={t("destinationsTable:delete")}
                closeDialogAfterSave
            />
            <FormDialog
                successMessage={t("destinationsTable:successSetup")}
                open={confirmSetup}
                description={t("destinationsTable:setupDescription")}
                setOpen={setConfirmSetup}
                header={t("destinationsTable:confirmSetup", {
                    dest: destinationAction,
                })}
                onSave={() => onConfirmSetup()}
                saveText={t("destinationsTable:setup")}
                closeDialogAfterSave
            />
            <Grid container direction="column" spacing={3}>
                <Grid
                    item
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item>
                        <Typography variant="h5">{capitalize(t('destinationsTable:destinations'))}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={onCreateDestinationsClick}
                        >
                            {t("destinationsTable:addDestinations")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container>
                    <DisplayTable
                        emptyLabel={t("destinationsTable:noDestinations")}
                        columnHeaders={headers}
                        {...{ records }}
                        header={capitalize(t("destinationsTable:destinations"))}
                        cellSize="medium"
                    />
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1),
        display: "flex",
        alignSelf: "center",
    },
}));

const enTranslations = {
    name: "Name",
    uri: "URI",
    actions: "Actions",
    destinations: "Destinations",
    addDestinations: "Add Destination",
    setupComplete: "Complete",
    status: 'Status',
    setupIncomplete: "Incomplete",
    confirmDelete: "Do you want to delete destination?",
    delete: "Delete",
    successDelete: "Delete Successful",
    setup: "Setup ",
    noDestinations: "No Destinations Created Yet",
    successSetup: "Setup Succesful",
    confirmSetup: 'Do you want to setup destination "{{dest}}"?',
    setupDescription:
        "This will register the destination in Id Registry for it to be active.",
};
