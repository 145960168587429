import AccountDetailsOutlineIcon from 'mdi-react/AccountDetailsOutlineIcon';
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DisplayCard } from '../../components/DisplayWrappers';
import { BLOCKCHAIN_BASE_PATH } from '../../components/MainNav/SideNavs/Blockchain';
import { FIREFLY_BASE_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { RuntimeSizeTranslation, EnRuntimeSizeTranslation, Service } from '../../models';
import { FireflyStatusChip } from './FireflyStatusChip';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';

interface Props {
    service: Service,
    actionLabel?: string,
    showNodeTypeLabel?: Boolean
    fireflyService?: Boolean
};

export const FireflyServiceCard = ({ service, actionLabel, showNodeTypeLabel = false, fireflyService = false}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BlockchainDashboardServiceCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BlockchainDashboardServiceCard:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>()

    let itemList = [
        {
            title: lt('status'),
            value: service.service === 'firefly-os' ? <FireflyStatusChip service={service} /> : <ResourceStateChip state={service.state} />,
            icon: <ChartLineIcon />
        },
        {
            title: lt('size'),
            value: lt(service.size),
            icon: <BullseyeIcon />
        }
    ]

    if (service.service === 'firefly-os') {
        itemList.push({
            title: lt('owningMember'),
            value: service.membership.name,
            icon: <AccountDetailsOutlineIcon />
        })
    }

    const linkButton =  {
        text: actionLabel ?? lt('viewService'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${fireflyService ? FIREFLY_BASE_PATH : BLOCKCHAIN_BASE_PATH}/${service.service}/${service._id}`)
    }
    
    return (
        <>
        <DisplayCard {...{linkButton}}
            header={showNodeTypeLabel ? lt('ipfsNode') : service.name}
            {...{itemList}} />
        </>
    )
};

interface translations extends RuntimeSizeTranslation {
    status: string
    size: string
    region: string
    consensusRole: string
    viewService: string
    change: string
    ipfsNode: string
    owningMember: string
}
const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    status: 'Status',
    size: 'Size',
    region: 'Region',
    consensusRole: 'Consensus role',
    viewService: 'View FireFly Node',
    change: 'Change',
    ipfsNode: 'IPFS Node',
    owningMember: 'Owning Member'
}
