import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { CreateAppCredData, CreateAppCredVars, CreateAppCredMutation, MakeAppCredCreateMutationOptions } from '../../../models'
import { FormDialog } from '../../../components/DialogWrappers'
import { TextField } from "@material-ui/core";
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector'

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onCreate: (appCredId?: string) => void
}

export const Create = ({ open, setOpen, onCreate }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppCredsCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppCredsCreate:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const [createAppCred, { loading: createAppCredLoading }] = 
        useMutation<CreateAppCredData, CreateAppCredVars>(CreateAppCredMutation)

    const [name, setName] = useState('');
    const [membershipId, setMembershipId] = useState('');
    
    const updater = () => 
        createAppCred(MakeAppCredCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            appCred: {
                membership_id: membershipId,
                name
            }
        })).then((d) => onCreate(d.data?.createAppCred._id))

    const controlsWrapper = 
        <>
            <MembershipSelector {...{membershipId}} {...{setMembershipId}} />

            <TextField
                data-test="textField_appCredName"
                value={name} onChange={event => setName(event.target.value)}
                autoFocus
                margin="normal"
                label={lt('name')}
                fullWidth
                variant="outlined"
            />
        </>

    const saveDisabled = createAppCredLoading || !name || !membershipId

    return (
        <>
            <FormDialog 
                {...{open}} 
                {...{setOpen}} 
                header={lt('header')}
                {...{controlsWrapper}}
                {...{saveDisabled}}
                onSave={updater}
                saveText={lt('create')} />
        </>
    )
};

interface translations {
    header: string,
    create: string,
    name: string
}
const enTranslations: translations = {
    header: 'Generate a new application credential',
    create: 'Create',
    name: 'Name'
}