import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
import { Channel, ChannelChaincode, ChannelsData, ChannelsQuery, GatewayAPIData, GatewayAPIQuery } from '../../models'
import { Grid, CircularProgress, Button, Typography } from "@material-ui/core";
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings'
import { EnvironmentResourcesVars, EnvironmentResourceVars } from '../../interfaces'
import { APPS_BASE_PATH, APPS_CHAINCODES_PATH} from '../../components/MainNav/SideNavs/AppsIntegrations';
import { ChannelsTable } from '../../components/Channels/ChannelsTable';
import { NETWORK_CONTRACTS_PATH, NETWORK_COMPILED_CONTRACTS_PATH } from '../../components/MainNav/ConsortiumWrapper';
import { FormLink } from '../../components/FormControls/FormLink';
import { InstalledStatuses } from './InstalledStatuses';

export const INSTANTIATE_CHAINCODE_PATH = 'instantiate';
export const Chaincode: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Chaincode', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Chaincode:${key}`, interpolate)

    const { pathname } = useLocation()
    const { org_id, consortium_id, environment_id, gateway_api_id } = useParams<any>();
    const history = useHistory();

    const chaincodesRedirect = pathname.replace(`/${gateway_api_id}`, '')
    const addChaincodeToChannelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}/${gateway_api_id}/${INSTANTIATE_CHAINCODE_PATH}/1`;

    const chaincodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: gateway_api_id!
    }

    const {
        data: {
            channels
        } = { channels: [] }
    } = useQuery<ChannelsData, EnvironmentResourcesVars>(ChannelsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        loading,
        data: {
            gatewayAPI: chaincode
        } = { chaincode: null }
    } = useQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, { 
        variables: chaincodeVars, 
        pollInterval: 10000,
        fetchPolicy: 'network-only' // force a network call to get the chaincode deployment statuses
    });

    // if the chaincode was deleted, then you need to get kicked out of the detail view.
    if (!loading && !chaincode) return (<Redirect to={chaincodesRedirect} />)
    if (!chaincode) return <CircularProgress />

    const splitPathname = pathname.split('/')
    const baseContractsPath = `${splitPathname.slice(0, splitPathname.indexOf(consortium_id!) + 1).join('/')}/${NETWORK_CONTRACTS_PATH}`
    const projectRedirectPath = `${baseContractsPath}/${chaincode.consortiaContractId}`
    const compilationRedirectPath = `${projectRedirectPath}/${NETWORK_COMPILED_CONTRACTS_PATH}/${chaincode._id}`

    const makeLink = (path: string, displayValue: string) => (
        <Grid item container>
            <FormLink to={path}>
                {displayValue}
            </FormLink>
        </Grid>
    );

    let chaincodeId = chaincode._id;
    let chaincodeChannels: Channel[] = [];
    channels.forEach((ch) => {
        const contracts = ch.contracts;
        contracts.forEach((contract: ChannelChaincode) => {
            if (contract._id === chaincodeId) {
                chaincodeChannels.push(ch);
            }
        })
    });
    
    const copyableList: CopyableSetting[] = [
        { title: lt('id'), displayValue: chaincode._id },
        { title: lt('contractName'), displayValue: makeLink(projectRedirectPath, chaincode.consortiaContractName || ''), disableCopy: true },
        { title: lt('chaincodeVersion'), displayValue: makeLink(compilationRedirectPath, chaincode.description || ''), disableCopy: true },
        { title: lt('label'), displayValue: chaincode.label || '', disableCopy: true },
        { title: lt('sequence'), displayValue: chaincode.sequence || '', disableCopy: true },
    ]

    let supportsSystemMonitorStatuses = chaincode.installed !== undefined && chaincodeChannels.length

    const actionBar = (
        <Grid item container spacing={3} direction="row">
            <Grid item>
                <Button color="primary" size="large" variant="contained" onClick={() => history.push(addChaincodeToChannelPath)}>
                    {lt('deployInstance')}
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('chaincodeDetails')}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container direction="row" spacing={3}>
                    {loading && !chaincode ? 
                    <CircularProgress /> : 
                    <>
                        <Grid item container>
                            <CopyableSettings header={lt('chaincodeInformation')} {...{copyableList}} {...{actionBar}} />
                        </Grid>
                    </>
                    }
                </Grid>

                <Grid item>
                    <InstalledStatuses gatewayAPI={chaincode} channels={chaincodeChannels} />
                </Grid>

                <Grid item>
                    <ChannelsTable header={lt('channels')} description={lt('channelsDesc')} channels={chaincodeChannels} gatewayAPI={supportsSystemMonitorStatuses ? chaincode : undefined} />
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    chaincodeDetails: string,
    chaincodeInformation: string,
    channels: string,
    channelsDesc: string,
    id: string,
    endpoint: string,
    chaincodePath: string,
    contractName: string,
    chaincodeVersion: string,
    label: string,
    promotedAt: string,
    deployInstance: string,
    viewChaincodeHeader: string,
    viewChaincodeDescription: string,
    viewDeployAPIHeader: string,
    viewDeployAPIDescription: string
    status: string
    sequence: string
}
const enTranslations: translations = {
    chaincodeDetails: 'Chaincode Details',
    chaincodeInformation: 'Chaincode information',
    channels: 'Channels',
    channelsDesc: 'This chaincode has been deployed to the following channels: ',
    id: 'ID',
    endpoint: 'API endpoint',
    chaincodePath: 'Chaincode full path',
    contractName: 'App name',
    chaincodeVersion: 'Version description',
    label: 'Label',
    promotedAt: 'Promoted at',
    deployInstance: 'Deploy instance to channel',
    viewChaincodeHeader: 'View the Chaincode',
    viewChaincodeDescription: 'Select a node to view the Chaincode.',
    viewDeployAPIHeader: 'Deploy an instance via the Chaincode',
    viewDeployAPIDescription: 'Select a node to view the Chaincode to deploy a new instance.',
    status: 'Install status',
    sequence: 'Sequence'
}