import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOauthConfigurationHelp1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOauthConfigurationHelp1:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-NodeSummary.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/using-kaleido/blockchain-nodes"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
}
const enTranslations: translations = {
    helpHeader: 'Oauth Configuration',
    helpDescription: 'OAuth Resource Server Configurations for authorizing applications to connect to your nodes via the Blockchain Application Firewall (BAF) with JWT tokens signed by an OAuth Authorization Server.',
}