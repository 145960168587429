import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { SessionData } from '../../interfaces';
import { FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, OrganizationsData, OrganizationsQuery } from '../../models';
import { SessionQuery } from '../../queries/Session';
import { NavPopoverItem } from '../MainNav/SideNavs/NavPopoverItem';
import { CREATE_ORG_PATH, MANAGE_ORG_PATH, ORGS_PATH } from './ManageOrgNav';

export const CREATE_NODES_PATH = 'create'

const OrgPicker: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgPicker', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgPicker:${key}`, interpolate)

    const history = useHistory();
    const { org_id } = useParams<any>();

    const { data: { session } = { session: undefined } } = useQuery<SessionData>(SessionQuery, { fetchPolicy: 'cache-only' });

    const { 
        data: { 
            organizations
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery, { 
        fetchPolicy: 'cache-only'
    });

    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { }
    });

    const org = organizations.find(o => o._id === org_id)

    // show all orgs in popover, the org nav will handle ejecting if the user is missing a manage role
    let popoverItems = organizations.filter(o => o._id !== org!._id).map(o => ({
        name: o.name,
        action: () => history.push(`/orgs/${o._id}/${MANAGE_ORG_PATH}`),
        divider: false,
        disabled: false
    }))

    if (popoverItems.length) {
        popoverItems[popoverItems.length - 1].divider = true
    }

    featureToggles.newOrg && session?.realm !== 'oidc' && popoverItems.push({
        name: lt('createOrg'),
        action: () => history.push(`/${ORGS_PATH}/${CREATE_ORG_PATH}/1`),
        divider: false,
        disabled: false
    })

    const item = {
        name: org!.name, 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />
};

interface translations {
    createOrg: string
}
const enTranslations: translations = {
    createOrg: 'Create Organization'
}

export { OrgPicker };

