import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EnvironmentResourcesVars } from '../../interfaces';
import { NodesData, NodesQuery } from '../../models';
import { AllEventStreamsData, AllEventStreamsQuery, AllEventStreamSubscriptionsData, AllEventStreamSubscriptionsQuery } from '../../models/eventStreams';
import { EventStreamsList } from './EventStreamsList';
import { MessageSnackbar, ErrorSnackbarCatcher } from '../DialogWrappers';

export const AllEventStreams = () => {
    const { consortium_id, environment_id } = useParams<{org_id: string, consortium_id: string, environment_id: string}>();

    const [errorMessage, setErrorMessage] = useState('')

    const {
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: { 
            consortia_id: consortium_id,
            environment_id: environment_id
        },
        fetchPolicy: 'cache-only'
    });

    const myNodes = nodes.filter(n => n.membership.isMine && n.state === 'started')

    const {
        loading: allEventStreamsLoading,
        data: {
            allEventStreams
        } = { allEventStreams: [] },
        error: allEventStreamsError
    } = useQuery<AllEventStreamsData>(AllEventStreamsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_ids: myNodes.map(n => n._id)
        },
        fetchPolicy: 'cache-and-network',
        skip: !myNodes.length
    });

    const {
        loading: allEventStreamSubscriptionsLoading,
        data: {
            allEventStreamSubscriptions
        } = { allEventStreamSubscriptions: [] },
        error: allEventStreamSubscriptionsError
    } = useQuery<AllEventStreamSubscriptionsData>(AllEventStreamSubscriptionsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_ids: myNodes.map(n => n._id)
        },
        fetchPolicy: 'cache-and-network',
        skip: !myNodes.length
    });

    useEffect(() => {
        if (allEventStreamsError) {
            ErrorSnackbarCatcher(allEventStreamsError, setErrorMessage);
        } else if (allEventStreamSubscriptionsError) {
            ErrorSnackbarCatcher(allEventStreamSubscriptionsError, setErrorMessage);
        }
    }, [allEventStreamsError, allEventStreamSubscriptionsError])

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage}/>
            <EventStreamsList {...{nodes}} eventStreams={allEventStreams} eventStreamsLoading={allEventStreamsLoading} 
                eventStreamSubscriptions={allEventStreamSubscriptions} eventStreamSubscriptionsLoading={allEventStreamSubscriptionsLoading} />
        </>
    )
};
