import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../../components/DialogWrappers';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { DeleteAPIKeyVars, DeleteAPIKeyMutation, DeleteAPIKeyData, APIKey, MakeAPIKeyDeleteMutationOptions } from '../../models/apiKeys';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    apiKey: APIKey
}

export const DeleteProfileAPIKey: React.FC<Props> = ({ open, setOpen, apiKey }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DeleteAPIKey', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DeleteAPIKey:${key}`, interpolate);
    const [deleteAPIKey] = useMutation<DeleteAPIKeyData, DeleteAPIKeyVars>(DeleteAPIKeyMutation);

    const handleDelete = () => {
        return deleteAPIKey(MakeAPIKeyDeleteMutationOptions({ org_id: apiKey.org_id, id: apiKey._id })).then(() => { });
    }

    const controlsWrapper = (
        <Typography>{lt('warning')}</Typography>
    );

    return <FormDialog open={open} setOpen={setOpen} header={lt('deleteAPIKey')} closeDialogAfterSave={true}
        saveText={lt('delete')} controlsWrapper={controlsWrapper} onSave={handleDelete} successMessage={lt('successDeletionMessage')} />
}

interface translations {
    deleteAPIKey: string
    delete: string
    warning: string
    successDeletionMessage: string
}

const enTranslations: translations = {
    deleteAPIKey: 'Delete API Key',
    delete: 'Delete',
    warning: 'Are you sure you want to delete the API Key? Any subsequent calls leveraging this key will cease to be authorized by the Kaleido servers.',
    successDeletionMessage: 'API Key successfully deleted'
}