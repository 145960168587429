import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers'

export const KeyStoreHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigKeyStoreHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigKeyStoreHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
            header={lt('helpHeader')}
            docLink="https://docs.kaleido.io/using-kaleido/cloud-integrations/kms/"
            description={lt('description')}
            bullet1={lt('bulletHelp1')}
            bullet2={lt('bulletHelp2')}
        />
    )
};

interface translations {
    helpHeader: string,
    description: string,
    bulletHelp1: string,
    bulletHelp2: string
}
const enTranslations: translations = {
    helpHeader: 'Key Store',
    description: 'Kaleido employs multiple measures to key materials given their importance to the security and integrity of the network:',
    bulletHelp2: ' Kaleido stores only the signing key cipher text and a single auditable decryption call is sent to the AWS Identity Access Management service when the node needs to initialize.',
    bulletHelp1: 'Key Management Integration adds a further layer of security to a node’s private signing materials by encrypting any sensitive keys with a master encryption key controlled by the node owner.'
}