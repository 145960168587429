import { gql, MutationFunctionOptions } from '@apollo/client';

// INTERFACES

export interface APIKey {
    _id: string
    name: string
    org_id: string
}

export interface CreatedAPIKey extends APIKey {
    apikey: string
}

export interface CreateAPIKeyData {
    createAPIKey: CreatedAPIKey
}

export interface APIKeyData {
    apiKeys: APIKey[]
}

export interface CreateAPIKeyVars {
    org_id: string
    apiKey: APIKeyInput
}

export interface APIKeyInput {
    name?: string
}

export interface DeleteAPIKeyData {
    deleteAPIKey: string
}

export interface DeleteAPIKeyVars {
    org_id: string
    id: string
}

// QUERIES & SUBSCRIPTIONS

export const APIKeysQuery = gql`
    query getApiKeys {
        apiKeys {
            _id
            name
            org_id
        }
    }`;

export const CreateAPIKeyMutation = gql`
    mutation createAPIKey($org_id: String!, $apiKey: CreateAPIKeyInput) {
        createAPIKey(org_id: $org_id, apiKey: $apiKey) {
            _id
            name
            org_id
            apikey
        }
    }`;

export const DeleteAPIKeyMutation = gql`
    mutation deleteAPIKey($org_id: String!, $id: String!) {
        deleteAPIKey(org_id: $org_id, id: $id)
    }`;

    export function MakeAPIKeyCreateMutationOptions(
        variables: CreateAPIKeyVars,
        overrideOptions?: MutationFunctionOptions<CreateAPIKeyData, CreateAPIKeyVars>): MutationFunctionOptions<CreateAPIKeyData, CreateAPIKeyVars> {
        return {
            ...{
                variables,
                update(cache, { data }) {
                        if (data?.createAPIKey) {
                        const { apiKeys } = cache.readQuery<APIKeyData>({ query: APIKeysQuery, variables })!;
                        cache.writeQuery({
                            query: APIKeysQuery,
                            data: { apiKeys: apiKeys.concat(data?.createAPIKey) },
                            variables: { org_id: variables.org_id }
                        });
                    }
                }
            }, ...overrideOptions
        }
    }
    
    export function MakeAPIKeyDeleteMutationOptions(
        variables: DeleteAPIKeyVars,
        overrideOptions?: MutationFunctionOptions<DeleteAPIKeyData, DeleteAPIKeyVars>): MutationFunctionOptions<DeleteAPIKeyData, DeleteAPIKeyVars> {
        return {
            ...{
                variables,
                update(cache, { data }) {
                    if (data?.deleteAPIKey) {
                        const { apiKeys } = cache.readQuery<APIKeyData>({ query: APIKeysQuery, variables })!;
                        cache.writeQuery({
                            query: APIKeysQuery,
                            data: { apiKeys: apiKeys.filter(apiKey => apiKey._id !== variables.id) },
                            variables: { org_id: variables.org_id }
                        });
                    }
                }
            }, ...overrideOptions
        }
    }