import { IconButton, Typography } from "@material-ui/core";
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import ProtocolIcon from 'mdi-react/ProtocolIcon';
import WebhookIcon from 'mdi-react/WebhookIcon';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { LinkButtonProps } from '../../interfaces';
import { Node, Service, EnvironmentProviderTranslations, EnServicesTranslations, EnEnvironmentProviderTranslations, ServicesTranslations } from '../../models';
import { DisplayCard } from '../DisplayWrappers';
import { DisplayCardListItem } from '../DisplayWrappers/DisplayCardItem';
import { ResourceStateChip } from "../FormControls/ResourceStateChip";

interface Props {
    node?: Node,
    service?: Service,
    linkButton?: LinkButtonProps,
    linkButton2?: LinkButtonProps,
    appcredId?: string,
    appcredSecret?: string,
    header?: string,
    description?: string,
    maxHeight?: 'disable' | 'extend',
}

export const ConnectList = ({ node, service, linkButton, linkButton2, appcredId, appcredSecret, header, description, maxHeight }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConnectRuntimeWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConnectRuntimeWrapper:${key}`, interpolate)
    const makeCopyButton = (text: string) => {
        return (
            <CopyToClipboard text={text}>
                <IconButton color="inherit" size="small">
                    <ContentCopyIcon />
                </IconButton>
            </CopyToClipboard> 
        )
    }

    const connectList : DisplayCardListItem[] = []

    let subType = node ? node.provider : service?.service;
    let typeName = lt(subType as keyof translations)

    const mapIcon = (proto? : string) => {
        if (proto === 'kaleido_connect') return <WebhookIcon />;
        return <ProtocolIcon />;
    }

    const mapTitle = (proto? : string) => {
        switch(subType) {
        case 'geth':
        case 'quorum':
        case 'pantheon':
        case 'polygon-edge':
            switch (proto) {
                case 'rpc':
                case 'http':
                    return lt('rpcHttpEndpoint')
                case 'ws':
                case 'wss':
                    return lt('rpcWSEndpoint')
                case 'kaleido_connect':
                    return lt('restAPIGateway')
                case 'private_tx':
                    return lt('privateTx')
            }
            break;
        case 'corda':
            break;
        case 'fabric':
            switch (proto) {
                case 'peer':
                    return lt('peer')
                case 'explorer':
                    return lt('fabricExplorer')
                case 'kaleido_connect':
                    return lt('restAPIGateway')
            }
            break;
        default:
            switch(proto) {
                case 'http':
                    return lt('httpEndpoint', {typeName})
                case 'webui': 
                    return lt('webui', {typeName})
                case 'swaggerui': 
                    return lt('swaggerui', {typeName})
                case 'sandboxui': 
                    return lt('sandboxui', {typeName})
                case 'ws':
                case 'wss':
                    return lt('wsEndpoint', {typeName})
            }
        }
        // Fallback to just displaying the string
        return <Typography>{proto}</Typography>;
    }

    const getUrlSuffix = (s?: Service) => {
        if (s?.service === 'documentstore' || s?.service === 'app2app') {
            return 'api/v1'
        }
        return ''
    }

    for (let [proto,urlString] of Object.entries({...node?.urls, ...service?.urls})) {
        let url = new URL(urlString as string)
        if (appcredId && appcredSecret) {
            url.username = appcredId;
            url.password = appcredSecret;
        }
        const urlHref = `${url.href}${getUrlSuffix(service)}`
        connectList.push({
            icon: mapIcon(proto),
            title: mapTitle(proto),
            value: urlHref,
            actionIcon: makeCopyButton(urlHref)
        })
    }

    return (
        <>
            <DisplayCard dataTestId="connectList" {...{header}} {...{description}} itemList={connectList} {...{linkButton}} {...{linkButton2}} {...{maxHeight}} actionBar={<>{service && <ResourceStateChip state={service.state}/>}</>}/>
        </>
    )
};

interface translations extends ServicesTranslations, EnvironmentProviderTranslations {
    restAPIGateway: string,
    rpcHttpEndpoint: string,
    rpcWSEndpoint: string,
    httpEndpoint: string,
    wsEndpoint: string,
    privateTx: string,
    peer: string
    fabricExplorer: string
    webui: string
    swaggerui: string
    sandboxui: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    ...EnEnvironmentProviderTranslations,
    restAPIGateway: 'REST API Gateway',
    rpcHttpEndpoint: 'JSON/RPC HTTP endpoint',
    rpcWSEndpoint: 'JSON/RPC Websocket endpoint',
    httpEndpoint: '{{typeName}} HTTP endpoint',
    wsEndpoint: '{{typeName}} Websocket endpoint',
    privateTx: "Private Transaction Manager",
    peer: 'Peer',
    fabricExplorer: 'Blockchain Explorer',
    webui: '{{typeName}} Web UI',
    swaggerui: '{{typeName}} Swagger UI',
    sandboxui: '{{typeName}} Sandbox UI',
}