import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect, useLocation, useHistory } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers'
import { FabricStep1 } from './FabricStep1'
import { NETWORK_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { useQuery } from '@apollo/client';
import { CompiledContractsData, CompiledContractsVars, CompiledContractsQuery } from '../../../models';
import { getDefaultCompilationDescription } from './VersioningUtils';

export type PrecompiledCompilationProps = { 
    description: string, 
    membershipId: string, 
}

export const CreateFabric: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateFabric', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateFabric:${key}`, interpolate)

    const history = useHistory<PrecompiledCompilationProps>()
    const { pathname } = useLocation()
    const { org_id, consortium_id, contract_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${contract_id}`

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const { 
        location: { 
            state: {
                description: compilationDescription,
                membershipId: compilationMembershipId,
            } = { description: '',  membershipId: '' } 
        } 
    } = history

    const {
        data: {
            compiledContracts
        } = { compiledContracts: null }
    } = useQuery<CompiledContractsData, CompiledContractsVars>(CompiledContractsQuery, {
        variables: { 
            consortia_id: consortium_id!,
            contract_id: contract_id!
        },
        fetchPolicy: 'cache-only'
    });

    let defaultCompilationDescription = getDefaultCompilationDescription(compiledContracts);

    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState(compiledContracts ?  defaultCompilationDescription : compilationDescription);
    const [membershipId, setMembershipId] = useState(compilationMembershipId);

    const invalidStep = step !== "1"

    if (invalidStep) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { 
            step: '', 
            component: <FabricStep1 cancelPath={cancelPath} {...{description}} {...{setDescription}} {...{membershipId}} {...{setMembershipId}} {...{loading}} {...{setLoading}} /> 
        }
    ]

    return <FullScreenCreate cancelPath={cancelPath} {...{loading}} hideStepper toolbarHeader={lt('createCompilation')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createCompilation: string,
    compilationDetails: string,
    provideSource: string
}
const enTranslations: translations = {
    createCompilation: 'Upload a Chaincode',
    compilationDetails: 'Version Details',
    provideSource: 'Provide Source'
}
