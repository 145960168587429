import React from 'react';
import { makeStyles, Grid, Typography, TextField, Paper, Switch, IconButton, Divider } from '@material-ui/core';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { useTranslation } from 'react-i18next';

export const RulePanel = ({title, toggles, regexInputs, handleDelete}: {
    title: string,
    handleDelete: () => void,
    regexInputs?: {
        [k: string]: {desc: string, val: string, setter: (newVal: string) => void}
    },
    toggles: {
        [k: string]: {desc: string, checked?: boolean, setter: (checked: boolean) => void}
    },
}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFRulesetEditorRulePanel', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFRulesetEditorRulePanel:${key}`, interpolate);

    const classes = useStyles();
    return <>
        <Grid item container spacing={2} justify="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item>
                <IconButton key="close" aria-label="close" color="inherit" data-test={`button_delete${title}`} onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
        <Grid item container spacing={2} justify="center">
            <Grid item xs={12}>
                <Paper>
                    {regexInputs && Object.keys(regexInputs).map((k) =>
                    <React.Fragment key={`regexInput-${title}-${k}`}>
                        <Grid item container spacing={2} justify="space-between" className={classes.innerPadding}>
                            <Grid item xs>
                                <Grid item container direction="column">
                                    <Grid><Typography variant="body1" color="textPrimary">{k}</Typography></Grid>
                                    <Grid><Typography variant="body2" color="textSecondary">{regexInputs[k].desc}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs>
                            <TextField
                                fullWidth
                                data-test={`textfield_edit${k}`}
                                label={lt('regex')}
                                value={regexInputs[k].val}
                                variant="outlined" 
                                onChange={event => regexInputs[k].setter(event.target.value)}/>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </React.Fragment>)}
                    {Object.keys(toggles).map((k) =>
                    <React.Fragment key={`toggle-${title}-${k}`}>
                        <Grid item container spacing={2} justify="space-between" className={classes.innerPadding}>
                            <Grid item>
                                <Grid item container direction="column">
                                    <Grid><Typography variant="body1" color="textPrimary">{k}</Typography></Grid>
                                    <Grid><Typography variant="body2" color="textSecondary">{toggles[k].desc}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Switch
                                    data-test={`toggle_ruleset${k}`}
                                    checked={toggles[k].checked ? true : false}
                                    color="primary"
                                    onChange={event => toggles[k].setter(event.target.checked)}
                                    name={k}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </React.Fragment>)}
                </Paper>
            </Grid>
        </Grid>
    </>
}

interface translations {
    regex: string,
}
const enTranslations: translations = {
    regex: "Case-insensitive regex",
}

const useStyles = makeStyles(theme => ({
    innerPadding: {
        padding: theme.spacing(2),
    }
}));