import { CircularProgress, Grid, Typography, Link } from '@material-ui/core';
import React from 'react';
import { GenericStatusIcon } from '.';
import { useTranslation } from "react-i18next";

interface Props {
    loading: boolean,
    isDone: boolean,
    title: string,
    statusMessage: string | JSX.Element,
    isInfo?: boolean,
    learnMoreLink?: string,
}

export const ProcessItem = ({loading, isDone, isInfo, title, statusMessage, learnMoreLink}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProcessItem', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProcessItem:${key}`, interpolate)

    return (
        <Grid item container alignItems="center" wrap="nowrap" spacing={3}>
            {loading ? (
                <Grid item>
                    <CircularProgress size={20} />
                </Grid>
            ) : (
                <Grid item>
                    <GenericStatusIcon overrideOk={isDone} />
                </Grid>
            )}
            <Grid item container wrap="nowrap" direction="column" >
                <Grid item>
                    <Typography variant="body1">{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color={(isDone || isInfo) ? "textSecondary" : "error"}>{statusMessage}</Typography>
                    {learnMoreLink && <Link href={learnMoreLink} target="_blank">{lt('learnMore')}</Link>}
                </Grid>
            </Grid>
        </Grid>
    )
}

interface translations {
    learnMore: string
}

const enTranslations: translations = {
    learnMore: 'Learn More',
};
