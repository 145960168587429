import React, {  } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { ORGS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_PAYMENT_METHOD_PATH } from '../../../components/ManageOrgNav/ManageOrgNav';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { StripeWrapper } from './StripeWrapper';

export const SetupBillingProvider: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SetupBillingProvider', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SetupBillingProvider:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const { step } = useParams<any>();
    const cancelPath = `/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}`;

    const stepComponents: { step: string; component: JSX.Element; }[] = [
        {
            step: '', component: <Step1 cancelPath={cancelPath} />
        }, {
            step: '', component:<StripeWrapper> <Step2 cancelPath={cancelPath} /></StripeWrapper>
        }
    ];
    
    return (
        <FullScreenCreate toolbarHeader={lt('setupBillingProvider')} hideStepper stepUrlParam={step!} cancelPath={cancelPath} stepComponents={stepComponents} />
    );
}

interface translations {
    setupBillingProvider: string
}

const enTranslations: translations = {
    setupBillingProvider: 'Setup Billing Provider'
}