import React, { useState, useEffect } from "react";
import { CopyableSettings, CopyableSetting } from "../DisplaySettings";
import { useTranslation } from "react-i18next";
import {
    Membership,
    IDRegistryOrg,
    NodesData,
    NodesQuery,
    EnvironmentData,
    EnvironmentQuery,
    ServicesQuery,
    ServicesData,
    OnChainRegistryStatesTypes,
} from "../../models";
import { useQuery } from "@apollo/client";
import {
    EnvironmentResourcesVars,
    ConsortiumResourceVars,
} from "../../interfaces";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { ResourceStateChip } from "../FormControls/ResourceStateChip";
import { Button } from "@material-ui/core";
import {
    NETWORK_GOVERNANCE_PATH,
    NETWORK_GOVERNANCE_MEMBERSHIPS_PATH,
    NETWORK_GOVERNANCE_IDENTITY_PATH,
} from "../MainNav/SideNavs/Governance";
import { EstablishIdentityDialog } from "./EstablishIdentityDialog";

interface Props {
    membership?: Membership | null;
    idRegistryOrgs?: IDRegistryOrg[] | null;
}

export const MembershipSummary = ({ membership, idRegistryOrgs }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "MembershipSummary", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`MembershipSummary:${key}`, interpolate);

    const { environment_id, consortium_id, org_id } = useParams<any>();
    const history = useHistory();
    const location = useLocation();

    const [onChainRegistryState, setOnChainRegistryState] = useState<OnChainRegistryStatesTypes>('undetermined');
    const [openEstablishIdentity, setOpenEstablishIdentity] = useState(false);

    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const { data: { nodes } = { nodes: [] } } = useQuery<
        NodesData,
        EnvironmentResourcesVars
    >(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: "cache-only",
    });

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const idRegistryServiceID = services.find(entry => entry.service === 'idregistry' && entry.state === 'started')?._id;
    const idRegistryServicePresent = services.find(entry => entry.service === 'idregistry');

    useEffect(() => {
        if (idRegistryServicePresent) {
            if (idRegistryServiceID) {
                if (idRegistryOrgs?.find(entry => entry.name.endsWith(membership?._id ?? ''))) {
                    setOnChainRegistryState('established');
                } else {
                    setOnChainRegistryState('not_established')
                }
            } else {
                setOnChainRegistryState('undetermined');
            }
        } else {
            setOnChainRegistryState('not_established')
        }
    }, [idRegistryServiceID, idRegistryServicePresent, idRegistryOrgs, membership])

    function generateIdentityLabel () {
        switch(onChainRegistryState) {
            case 'established': return <ResourceStateChip state="established" />
            case "not_established": return <ResourceStateChip state="not_established" />
            default: return <ResourceStateChip state="undetermined" />
        }
    }

    const isCorda = environment?.isCorda;

    const node = nodes.find((node) => node.membership_id === "sys--mon");

    const copyableList: CopyableSetting[] = [
        {
            disableCopy: true,
            title: lt("identity"),
            displayValue: membership?.verification_proof ? (
                membership.verification_selfsigned ? (
                    <ResourceStateChip state="self_signed" />
                ) : (
                    <ResourceStateChip state="externally_signed" />
                )
            ) : (
                <ResourceStateChip state="none" />
            ),
            customAction: !membership?.verification_proof && membership?.is_mine ? (
                <Button
                    onClick={() =>
                        history.push( {
                            pathname: `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}/${membership?._id}/${NETWORK_GOVERNANCE_IDENTITY_PATH}/1`,
                            state: {
                                cancelPath: location.pathname,
                                isSelfSigned: true
                            }
                        })
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                >
                    {lt("confirmIdentity")}
                </Button>
            ) : undefined,
        },
    ];

    if (!isCorda && idRegistryOrgs !== undefined) {
        copyableList.push({
            disableCopy: true,
            title: lt("registeredOnChain"),
            displayValue: generateIdentityLabel(),
            customAction: membership?.is_mine && onChainRegistryState === 'not_established' && membership?.verification_proof ? (
                <Button
                    onClick={() =>
                        setOpenEstablishIdentity(true)
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                >
                    {lt("establishIdentity")}
                </Button>
            ) : undefined,
        });
    }

    copyableList.push(
        {
            title: lt("membershipName"),
            displayValue: membership
                ? membership.org_name || lt("none")
                : lt("system"),
        },
        { title: lt("id"), displayValue: membership?._id || lt("none") },
        {
            title: lt("organizationName"),
            displayValue: membership?.owner?.name || lt("none"),
        },
        {
            title: lt("organizationId"),
            displayValue: membership?.org_id || lt("none"),
        },
        {
            title: lt("delegate"),
            displayValue: membership?.owner?.email || lt("none"),
        },
        {
            disableCopy: true,
            title: lt("joinDate"),
            displayValue: new Date(
                membership?.created_at || node?.created_at || ""
            ).toLocaleString(),
        }
    );

    return (
        <>
            <EstablishIdentityDialog 
                open={openEstablishIdentity} 
                setOpen={setOpenEstablishIdentity} 
                isExternallySigned={!membership?.verification_selfsigned}
            />
            <CopyableSettings header={lt("header")} {...{ copyableList }} />
        </>
    )

};

interface translations {
    header: string;
    identity: string;
    selfSigned: string;
    externallySigned: string;
    none: string;
    membershipName: string;
    id: string;
    organizationName: string;
    organizationId: string;
    delegate: string;
    joinDate: string;
    registeredOnChain: string;
    established: string;
    notEstablished: string;
    undetermined: string;
    system: string;
    confirmIdentity: string;
    establishIdentity: string
}

const enTranslations: translations = {
    header: "Membership Summary",
    identity: "Identity",
    selfSigned: "Self signed on Kaleido",
    externallySigned: "Externally signed",
    none: "N/A",
    membershipName: "Membership name",
    id: "Membership ID",
    organizationName: "Organization name",
    organizationId: "Organization ID",
    delegate: "Primary Contact Email",
    joinDate: "Join date",
    registeredOnChain: "Registered On-Chain",
    established: "Established",
    notEstablished: "Not established",
    undetermined: "Undetermined",
    system: "System",
    confirmIdentity: "Confirm Identity",
    establishIdentity: 'Establish Identity'
};
