import {
    Avatar, Grid, makeStyles, Typography
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SupportTicket } from '../../../models/helpCenter';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../DialogWrappers';
import Status from './Status';

interface Props {
    data: SupportTicket;
    redirecting: boolean;
    setRedirecting: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Ticket({ data, redirecting, setRedirecting }: Props) {
    const classes = useStyles();

    const { org_id } = useParams<any>();
    const [errorMessage, setErrorMessage] = useState('');
    const formRef = useRef<HTMLFormElement>(null);
    const jwtRef = useRef<HTMLInputElement>(null);
    const returnToRef = useRef<HTMLInputElement>(null);

    const handleClick = async () => {
        const getZendeskSso = async () => {
            await fetch(
                `/api/ui/v2/sso/zendesk?org_id=${org_id}`,
                {
                    cache: 'no-cache',
                }).then((res) => {
                    return res.json();
                }).then(async (res) => {
                    const urlObject = new URL(res?.zendeskUrl);
                    const urlParams = new URLSearchParams(urlObject.search);
                    const jwtParam = urlParams.get('jwt') ?? '';
                    if (jwtRef?.current && formRef?.current && returnToRef?.current) {
                        jwtRef.current.value = jwtParam
                        returnToRef.current.value = "https://help.kaleido.io/hc/requests/" + data.id
                        formRef.current.submit();
                    }
                    return jwtParam;
                })
                .catch((err) => {
                    ErrorSnackbarCatcher(err, setErrorMessage);
                });
        }
        getZendeskSso();
    };

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <form ref={formRef} action={`https://kaleidoio.zendesk.com/access/jwt`} method="post">
                <input ref={jwtRef} type="hidden" name="jwt"></input>
                <input ref={returnToRef} type="hidden" name="return_to"></input>
            </form>
            <Grid
                item
                container
                className={classes.container}
                onClick={handleClick}
            >
                <Grid item container justifyContent="center" xs={1}>
                    <Avatar />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    xs={8}
                    className={classes.detailsContainer}
                >
                    <Typography
                        className={clsx(classes.text, classes.subjectText)}
                        variant="body1"
                    >
                        {data.id + ": " + data.subject}
                    </Typography>
                    <Typography
                        className={clsx(classes.text, classes.descriptionText)}
                        variant="body2"
                    >
                        {data.description}
                    </Typography>
                    <Status status={data.status} />
                </Grid>
                <Grid
                    className={classes.updatedAt}
                    item
                    container
                    justifyContent="flex-end"
                    xs={3}
                >
                    {moment(data.updatedAt).fromNow()}
                </Grid>
            </Grid></>
    );
}

const useStyles = makeStyles(() => ({
    container: {
        height: 130,
        maxHeight: 130,
        padding: '20px 35px 10px 30px',
        borderBottom: '1px solid #DBDBDB',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(123, 123, 123, 0.15)',
        },
    },
    detailsContainer: {
        paddingLeft: 20,
    },
    text: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    subjectText: {
        maxHeight: 20,
    },
    descriptionText: {
        maxHeight: 40,
    },
    updatedAt: {
        textAlign: 'end',
    },
}));
