import { gql } from '@apollo/client';
import { MembershipOwnedResource } from './memberships';

export interface Wallet extends MembershipOwnedResource {
    wallet_id: string
    account_count: number
    account_type: string
    membership_id: string
    node_name?: string
    details: JSON
    created_at: string
    deactivated: boolean
}

export interface WalletsData {
    wallets: Wallet[]
}

export const WalletsFields = ` 
    fragment WalletsFields on Wallet {
        wallet_id
        node_name
        account_count
        account_type
        membership_id
        details
        created_at
        deactivated
        membership @client
    }`


export const WalletsQuery = gql`
    ${WalletsFields}
    query getWallets($consortia_id: String!, $environment_id: String!) {
        wallets(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...WalletsFields
        }
    }`;

