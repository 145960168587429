import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { ConsortiumResourcesVars, ConsortiumResourceVars } from '../interfaces'

// INTERFACES

export type EnvironmentType = "firefly" | "standard"

export type Protocol = "ethereum" | "corda" | "fabric"

export type EnvironmentState = "setup" | "initializing" | "live" | "delete_pending" | "deleted" | "failed" | "pause_pending" | 
    "paused" | "archived" | "locked" | "resume_pending" | "upgrading" | "paused_upgrading" | "archived_upgrading" | "locked_upgrading"

export enum EnvironmentEthereumProviderEnum {
    quorum = 'quorum',
    geth = 'geth',
    pantheon = 'pantheon',
    autonity = 'autonity',
    'polygon-edge' = 'polygon-edge'
}

export enum EnvironmentCordaProviderEnum {
    corda = 'corda'
}

export enum EnvironmentFabricProviderEnum {
    fabric = 'fabric'
}

export type EnvironmentProvider = keyof typeof EnvironmentEthereumProviderEnum | keyof typeof EnvironmentCordaProviderEnum | keyof typeof EnvironmentFabricProviderEnum
export type EnvironmentConsensus = "poa" | "raft" | "ibft" | "tendermint" | "single-notary"
export type EnvironmentStateDB = "leveldb" | "couchdb"


export interface EnvironmentProviderTranslations {
    geth: string,
    quorum: string,
    pantheon: string,
    autonity: string,
    corda: string,
    fabric: string,
    'polygon-edge': string
}

export const EnEnvironmentProviderTranslations: EnvironmentProviderTranslations = {
    geth: 'Geth',
    quorum: 'Quorum',
    pantheon: 'Hyperledger Besu',
    autonity: 'Autonity',
    corda: 'Corda OS',
    fabric: 'Hyperledger Fabric',
    'polygon-edge': 'Polygon Edge'
}

// this interface is implemented by nodes and environments to determine their protocol type easily
export interface ProtocolTypedResource {
    isCorda: boolean
    isEthereum: boolean
    isFabric: boolean
}

// this should be an autogenerated interface from the Environment model in graphql
export interface Environment extends ProtocolTypedResource {
    _id: string
    name: string
    state: EnvironmentState
    created_at: string
    updated_at?: string
    consortia_id: string
    provider: EnvironmentProvider
    consensus_type: EnvironmentConsensus
    release_id: string
    test_features?: EnvironmentTestFeatures
    optional_features: EnvironmentOptionalFeatures
    block_period: number
    chain_id: number
    node_list: string[]
    admin_tags?: string[];
    state_db?: EnvironmentStateDB;
}

export interface EnvironmentPrefundedAccounts {
    address: string
    balance: string
}

export interface EnvironmentTestFeatures {
    multi_region: boolean
    hybrid: boolean
}

export interface EnvironmentOptionalFeatures {
    firefly: boolean
}

export interface CreateEnvironmentVars extends ConsortiumResourcesVars {
    environment: {
        name: string,
        provider: EnvironmentProvider,
        consensus_type: EnvironmentConsensus,
        test_features: EnvironmentTestFeatures
        optional_features: EnvironmentOptionalFeatures
        prefunded_accounts?: EnvironmentPrefundedAccounts[]
        chain_id?: number
        block_period?: number
        state_db?: EnvironmentStateDB
    }
}

export interface UpdateEnvironmentVars extends ConsortiumResourceVars {
    environment: {
        name?: Environment['name'],
        state?: EnvironmentState
        admin_tags?: string[],
    }
}

export interface EnvironmentsData {
    environments: Environment[]
}

export interface OpsEnvironmentsData {
    opsEnvironments: Environment[]
}

export interface EnvironmentData {
    environment: Environment
}

export interface CreateEnvironmentData {
    createEnvironment: Environment
}

// FRAGMENTS

export const EnvironmentFields = ` 
    fragment EnvironmentFields on Environment {
        _id
        name
        description
        state
        mode
        provider
        release_id
        consensus_type
        consortia_id
        chain_id
        block_period
        region
        enable_tether
        limit_initial_signers
        node_list
        service_list
        autopause_init_delay
        autopause_idle_hours
        created_at
        deleted_at
        paused_at
        resumed_at
        member_policy
        amend_policy
        locked_policies
        test_features
        optional_features
        updated_at
        isCorda @client
        isEthereum @client
        isFabric @client
        admin_tags
        state_db
    }`

// QUERIES & SUBSCRIPTIONS

export const EnvironmentsQuery = gql`
    ${EnvironmentFields}
    query getEnvironments($consortia_id: String!) {
        environments(consortia_id: $consortia_id) {
            ...EnvironmentFields
        }
    }`;

export const OpsEnvironmentsQuery = gql`
    ${EnvironmentFields}
    query getOpsEnvironments($region: String, $query: String!) {
        opsEnvironments(region: $region, query: $query) {
            ...EnvironmentFields
        }
    }`;

export const EnvironmentQuery = gql`
    ${EnvironmentFields}
    query getEnvironment($consortia_id: String!, $id: String!) {
        environment(consortia_id: $consortia_id, id: $id) {
            ...EnvironmentFields
        }
    }`;

export const CreateEnvironmentMutation = gql`
    ${EnvironmentFields}
    mutation createEnvironment($consortia_id: String!, $environment: CreateEnvironmentInput!) {
        createEnvironment(consortia_id: $consortia_id, environment: $environment) {
            ...EnvironmentFields
        }
    }`;

export const EnvironmentsSubscription = gql`
    ${EnvironmentFields}
    subscription onEnvironmentsChanged($consortia_id: String!) {
        environment(consortia_id: $consortia_id) {
            ...EnvironmentFields
        }
    }`;

export const UpdateEnvironmentMutation = gql`
    ${EnvironmentFields}
    mutation updateEnvironment($consortia_id: String!, $id: String!, $environment: UpdateEnvironmentInput!) {
        updateEnvironment(consortia_id: $consortia_id, id: $id, environment: $environment) {
            ...EnvironmentFields
        }
    }`;

export const DeleteEnvironmentMutation = gql`
    ${EnvironmentFields}
    mutation deleteEnvironment($consortia_id: String!, $id: String!) {
        deleteEnvironment(consortia_id: $consortia_id, id: $id) {
            ...EnvironmentFields
        }
    }`;

export const UpgradeEnvironmentMutation = gql`
    ${EnvironmentFields}
    mutation upgradeEnvironment($consortia_id: String!, $id: String!) {
        upgradeEnvironment(consortia_id: $consortia_id, id: $id) {
            ...EnvironmentFields
        }
    }`;

export const ForkUpgradeEnvironmentMutation = gql`
    ${EnvironmentFields}
    mutation forkUpgradeEnvironment($consortia_id: String!, $id: String!) {
        forkUpgradeEnvironment(consortia_id: $consortia_id, id: $id) {
            ...EnvironmentFields
        }
    }`;

export const HardForkAndUpgradeEnvironmentMutation = gql`
    ${EnvironmentFields}
    mutation hardforkAndUpgradeEnvironment($consortia_id: String!, $id: String!) {
        hardforkAndUpgradeEnvironment(consortia_id: $consortia_id, id: $id) {
            ...EnvironmentFields
        }
    }`;

export const OpsUpdateEnvironmentMutation = gql`
    ${EnvironmentFields}
    mutation opsUpdateEnvironment($consortia_id: String!, $id: String!, $environment: UpdateEnvironmentInput!) {
        opsUpdateEnvironment(consortia_id: $consortia_id, id: $id, environment: $environment) {
            ...EnvironmentFields
        }
    }`;

// MUTATION OPTIONS

export function MakeEnvironmentCreateMutationOptions(
    variables: CreateEnvironmentVars,
    overrideOptions?: MutationFunctionOptions<CreateEnvironmentData, CreateEnvironmentVars>): MutationFunctionOptions<CreateEnvironmentData, CreateEnvironmentVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const environment = data?.createEnvironment
            if (environment) {
                const { environments } = cache.readQuery<EnvironmentsData>({ query: EnvironmentsQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!environments.find(n => n._id === environment._id)) {
                    cache.writeQuery({
                        variables,
                        query: EnvironmentsQuery,
                        data: { environments: environments.concat(environment) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

// SUBSCRIPTION OPTIONS

export function MakeEnvironmentsSubscriptionOptions(
    variables: ConsortiumResourcesVars, 
    overrideOptions?: SubscriptionHookOptions<EnvironmentData>): SubscriptionHookOptions<EnvironmentData> {
    return { ...{
        variables,
        onSubscriptionData: ({client, subscriptionData}) => {
            // Get the environmentsChanged object from the event
            const { 
                data: { 
                    environment
                } = { environment: null }
            } = subscriptionData

            if (!environment) return

            // Get current list of environments from the cache
            // assert that environments must exist since this function throws if it doesnt
            const { environments: existingEnvironments } = client.readQuery<EnvironmentsData>({ 
                query: EnvironmentsQuery, 
                variables 
            })!
            
            // if it already exists in the store, (its an update), apollo automagically updated the cache
            const cachedEnvironment = existingEnvironments.find(c => c._id === environment._id)

            // but, if it came back as deleted, we need to remove it from the cache manually
            if (cachedEnvironment) {
                if (cachedEnvironment.state === 'deleted') {
                    client.cache.evict({id: `Environment:${cachedEnvironment._id}`, broadcast: false})
                }
                client.writeQuery<EnvironmentsData>({ 
                    query: EnvironmentsQuery, 
                    data: Object.assign({}, existingEnvironments, {
                        environments: [...existingEnvironments.filter(c => c.state !== 'deleted')]
                    }),
                    variables
                })
                return 
            }

            // in case of duplicate events, never add a deleted item into the cache
            if (environment.state === 'deleted') return

            // if it doesnt exist in the store, (it's been added), so we need to add it in manually
            client.writeQuery<EnvironmentsData>({ 
                query: EnvironmentsQuery, 
                data: Object.assign({}, existingEnvironments, {
                    environments: [environment, ...existingEnvironments]
                }),
                variables
            })
        }
    }, ...overrideOptions }
}

