import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { Chip, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Theme, makeStyles } from "@material-ui/core";
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import WalletOutlineIcon from 'mdi-react/WalletOutlineIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import Jazzicon from 'react-jazzicon';
import { useParams } from "react-router-dom";
import { EnvironmentResourceVars } from '../../interfaces';
import { AddressQuery, AddressWidgetInfoData, AddressWidgetInfoQuery, CompiledContractData, CompiledContractQuery, CompiledContractVars } from '../../models';
import { jsNumberForAddress } from '../../utils/StringUtils';
import { FormDialog } from '../DialogWrappers';
import { DisplayPopover, PopoverTitle } from '../DisplayWrappers';
import { ShortenedHashAddress } from './ShortenedHashDialogs/Address';
import { ShortenedHashContract } from './ShortenedHashDialogs/Contract';
import { ShortenedHashGatewayAPIContract } from './ShortenedHashDialogs/GatewayAPIContract';
import { ShortenedHashSigningAccount } from './ShortenedHashDialogs/SigningAccount';
import { ShortenedHashToken } from './ShortenedHashDialogs/Token';

interface Props {
    address: string
    showFullAddress?: boolean
    retry?: boolean
    noPopover?: boolean
    showMember?: boolean
    setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
};

const REFETCH_DELAY = 5000;
const MAX_REFETCH_ATTEMPTS = 10;

export const ShortenedHash = ({ address, showFullAddress = false, retry = false, noPopover = false, showMember=false, setModalOpen }: Props) => {
    const classes = useStyles({ showFullAddress });
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ShortenedHash', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ShortenedHash:${key}`, interpolate)
    const client = useApolloClient()
    const { consortium_id, environment_id } = useParams<any>();

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const {
        loading,
        refetch,
        data: {
            addressWidgetInfo
        } = { addressWidgetInfo: null }
    } = useQuery<AddressWidgetInfoData, EnvironmentResourceVars>(AddressWidgetInfoQuery, {
        variables: {
            consortia_id: consortium_id || '',
            environment_id: environment_id || '',
            id: address.toLowerCase()
        }
    });

    const [getCompiledContract, {
        data: {
            compiledContract
        } = { compiledContract: null }
    }] = useLazyQuery<CompiledContractData, CompiledContractVars>(CompiledContractQuery, { fetchPolicy: 'network-only' });

    // accounts take a small amount of time to be inserted in the monitor node address book 
    // so, we have to poll / refetch for newly created accounts

    // setup refs to track the polling interval and refetch attempts
    const refetchAttempts = useRef<number>(0)
    const intervalRef = useRef<number>(0)
    useEffect(() => {
        // cleanup
        return () => window.clearInterval(intervalRef.current);
    }, []);

    const refetchAddressInfo = useCallback(() => {
        window.clearInterval(intervalRef.current)

        if (loading) return
        if (addressWidgetInfo) return
        if (refetchAttempts.current.valueOf() >= MAX_REFETCH_ATTEMPTS) {
            console.log(`stopped refetching looking for ${address} after too many attempts`)
            // write an empty object for the query so we dont keep refetching in the future for an address we wont find
            client.writeQuery({
                query: AddressQuery,
                data: {
                    address: {}
                },
                variables: {
                    consortia_id: consortium_id || '',
                    environment_id: environment_id || '',
                    id: address.toLowerCase()
                }
            })
            return
        }

        console.log(`refetching looking for address info for: ${address}. attempt ${refetchAttempts.current}`)

        refetch().then(r => {
            if (!r?.data?.addressWidgetInfo?._id) {
                intervalRef.current = window.setInterval(() => {
                    refetchAttempts.current++
                    try {
                        refetchAddressInfo();
                    } catch (err) {
                        console.log(err);
                    }
                }, REFETCH_DELAY);
            } else {
                console.log(`found ${address}. stopping refetch`)
                window.clearInterval(intervalRef.current)
            }
        }).catch(err => {
            console.log(err); //This will be overwritten on new address widget pr
        })
    }, [address, addressWidgetInfo, consortium_id, environment_id, client, loading, refetch])

    useEffect(() => {
        if (setModalOpen) {
            setModalOpen(popoverOpen || dialogOpen);
        }
        if (!compiledContract && popoverOpen && addressWidgetInfo?.gatewayAPIInfo?.gatewayAPIId) {
            getCompiledContract({
                variables: {
                    consortia_id: consortium_id!,
                    id: addressWidgetInfo.gatewayAPIInfo.gatewayAPIId,
                    contract_id: addressWidgetInfo.gatewayAPIInfo.consortiaContractId
                }
            });
        }
    }, [compiledContract, popoverOpen, dialogOpen, setModalOpen, addressWidgetInfo, consortium_id, environment_id, getCompiledContract]);

    useEffect(() => {
        if (retry) {
            refetchAddressInfo()
        }
    }, [refetchAddressInfo, retry]);

    const anchorRef = useRef<HTMLDivElement>(null);

    let icon;
    let popperIcon;
    let prefix = '';
    let postfix= '';
    let title = '';
    let subtitle = '';
    let additionalListItems;

    if (addressWidgetInfo?.type === 'contract') {
        if (addressWidgetInfo.tokenInfo?.isERC20 || addressWidgetInfo.tokenInfo?.isERC721) {
            title = lt('tokenTitle', { tokenName: addressWidgetInfo.tokenInfo.name, tokenSymbol: addressWidgetInfo.tokenInfo.symbol });
            subtitle = lt(addressWidgetInfo.tokenInfo.isERC20 ? 'tokenSubtitleERC20' : 'tokenSubtitleERC721');
            icon = <div className={classes.jazzIconSmall}><Jazzicon diameter={16} seed={jsNumberForAddress(address)} /></div>
            popperIcon = <Jazzicon diameter={40} seed={jsNumberForAddress(address)} />;
            prefix = addressWidgetInfo.tokenInfo.symbol;
            additionalListItems = <ShortenedHashToken addressWidgetInfo={addressWidgetInfo} />;
        } else {
            if (addressWidgetInfo.gatewayAPIInfo?.gatewayAPIId) {
                if(compiledContract) {
                    title = lt('gatewayApiContractTitle', { contractName: addressWidgetInfo.gatewayAPIInfo.contractProjectName, contractVersion: compiledContract.description });
                } else {
                    title = addressWidgetInfo.gatewayAPIInfo.contractProjectName;
                }
                subtitle = lt('gatewayApiContractSubtitle');
                prefix = addressWidgetInfo.gatewayAPIInfo.contractProjectName;
                postfix = addressWidgetInfo.membership.name;
                additionalListItems = <ShortenedHashGatewayAPIContract addressWidgetInfo={addressWidgetInfo} compiledContract={compiledContract ?? undefined} />
            } else {
                prefix = title = lt('contract');
                additionalListItems = <ShortenedHashContract addressWidgetInfo={addressWidgetInfo} />
            }
            icon = popperIcon = <FileDocumentIcon />
        }
    } else if (addressWidgetInfo?.type === 'account') {
        if (addressWidgetInfo.membership_id === 'sys--mon' || addressWidgetInfo.membership_id === environment_id) {
            title = prefix = lt('system');
        } else {
            title = lt('membershipTitle', { membershipName: addressWidgetInfo.membership.name, membershipId: addressWidgetInfo.membership_id });
            prefix = addressWidgetInfo.membership.name;
        }
        subtitle = lt('nodeSigningAccount');
        additionalListItems = <ShortenedHashSigningAccount addressWidgetInfo={addressWidgetInfo} />
    } else {
        title = prefix = lt('address');
        icon = popperIcon = <WalletOutlineIcon />
        if (addressWidgetInfo) {
            additionalListItems = <ShortenedHashAddress addressWidgetInfo={addressWidgetInfo} />
        }
    }

    const addressToDisplay = showFullAddress ? address.toLowerCase() : address.substr(2, 6).toLowerCase();
    const membershipToDisplay = addressWidgetInfo?.membership_id === 'sys--mon' || addressWidgetInfo?.membership_id === environment_id ?
        lt('system') : addressWidgetInfo?.membership.name;

    useEffect(() => {
        if (!dialogOpen) {
            setPopoverOpen(false);
        } 
    }, [dialogOpen]);

    const commonListItems = (
        <>
            <ListItem>
                <ListItemText primary={lt('address')} secondary={address} />
                <ListItemSecondaryAction>
                    <CopyToClipboard text={address}>
                        <IconButton edge="end">
                            <ContentCopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                </ListItemSecondaryAction>
            </ListItem>
            {addressWidgetInfo?.membership_id && addressWidgetInfo?.type !== 'account' &&
                <ListItem>
                    <ListItemText primary={lt('deployedBy')} secondary={membershipToDisplay} />
                </ListItem>}
        </>
    );

    return (
        <>
            <Chip
                size="small"
                className={classes.chip}
                icon={icon}
                label={
                    <Grid container wrap="nowrap">
                        <span className={classes.prefix}>{prefix}</span>
                        <span className={classes.separator}>|</span>
                        <span className={classes.addressToDisplay}>
                            {addressToDisplay}
                        </span>
                        {showMember && <><span className={classes.separator}>|</span>
                        <span className={classes.postfix}>{postfix}</span></>}
                    </Grid>
                }
                ref={anchorRef}
                onClick={(event) => {
                    if (!noPopover) {
                        event.stopPropagation();
                        setPopoverOpen(!popoverOpen);
                    }
                }}
            />
            <div onClick={(event) => event.stopPropagation()}>
                <DisplayPopover
                    popoverOpen={popoverOpen && !dialogOpen}
                    anchorRef={anchorRef}
                    setPopoverOpen={setPopoverOpen}
                    placement="top"
                >
                    <PopoverTitle
                        title={title}
                        subtitle={subtitle}
                        icon={popperIcon}
                        action={{
                            icon: <OpenInNewIcon style={{ fill: 'white' }} />,
                            handler: () => setDialogOpen(true),
                        }}
                    />
                    <List className={classes.list}>{commonListItems}</List>
                </DisplayPopover>
                <FormDialog
                    open={dialogOpen}
                    dataTestId="addressWidget"
                    setOpen={setDialogOpen}
                    header={title}
                    subtitle={subtitle}
                    hideActions={true}
                    dialogType="medium"
                    controlsWrapper={
                        <List className={classes.list}>
                            {commonListItems}
                            {additionalListItems}
                        </List>
                    }
                />
            </div>
        </>
    );
};

interface ShortenedHashStyle {
    showFullAddress: boolean
}

const useStyles = makeStyles<Theme, ShortenedHashStyle>(() => ({
    chip: {
        borderRadius: 3
    },
    list: {
        paddingTop: 0
    },
    jazzIconSmall: {
        marginTop: 2
    },
    prefix: {
        width: 50,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    addressToDisplay: props => ({
        width: props.showFullAddress ? 310 : 50,
        textAlign: 'center',
    }),
    separator: {
        width: 10,
        textAlign: 'center'
    }
}));

interface translations {
    shortenedString: string
    nodeTitle: string
    nodeSigningAccount: string
    contract: string
    system: string
    address: string
    deployedBy: string
    gatewayApiContractTitle: string
    gatewayApiContractSubtitle: string
    tokenTitle: string
    tokenSubtitleERC20: string
    tokenSubtitleERC721: string
    membershipTitle: string
}
const enTranslations: translations = {
    shortenedString: '{{prefix}}...',
    nodeTitle: '{{nodeName}} ({{nodeId}})',
    nodeSigningAccount: 'Signing Account',
    contract: 'Contract',
    system: 'System',
    address: 'Address',
    deployedBy: 'Deployed By',
    gatewayApiContractTitle: '{{contractName}} ({{contractVersion}})',
    gatewayApiContractSubtitle: 'Gateway API Contract',
    tokenTitle: '{{tokenName}} ({{tokenSymbol}})',
    tokenSubtitleERC20: 'ERC20 Token Contract',
    tokenSubtitleERC721: 'ERC721 Token Contract',
    membershipTitle: '{{membershipName}} ({{membershipId}})'
}