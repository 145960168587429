import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicItems } from './BasicItems';

export const CLOUDHSM_WALLET_PATH = 'wallet';

interface Props {
    baseServicePath: string
}

export const CloudHsmItems = ({ baseServicePath } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CloudHsmItems', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CloudHsmItems:${key}`, interpolate)

    const WALLET = lt('wallet');

    const serviceSpecificTabs = [
        {
            name: WALLET,
            path: CLOUDHSM_WALLET_PATH,
            selectedItem: WALLET
        }
    ]

    return (
        <BasicItems {...{baseServicePath}} {...{serviceSpecificTabs}} />
    );
};

interface translations {
    wallet: string
}

const enTranslations: translations = {
    wallet: 'Wallet'
}


