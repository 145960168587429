import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { EnvironmentsData, EnvironmentsQuery, Environment } from '../../models';
import { ConsortiumResourcesVars } from '../../interfaces'

interface Props {
    environmentId: string,
    setEnvironmentId: React.Dispatch<React.SetStateAction<string>>,
    filterEnvironments?: (environment: Environment) => boolean
};

export const EnvironmentSelector = ({ environmentId, setEnvironmentId, filterEnvironments = () => true }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentSelector:${key}`, interpolate)

    const { consortium_id } = useParams<any>();

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const { 
        data: { 
            environments
        } = { environments: [] } 
    } = useQuery<EnvironmentsData, ConsortiumResourcesVars>(EnvironmentsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEnvironmentId(event.target.value as string);
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} required>
                {lt('selectEnvironment')}
            </InputLabel>
            <Select 
                data-test="environmentSelector"
                labelWidth={labelWidth}
                value={environmentId}
                onChange={handleChange}>
                {environments.filter(env => filterEnvironments(env)).map(m => (
                    <MenuItem key={m._id} selected={m._id === environmentId} value={m._id}>
                        {environments.find(x => x._id === m._id)?.name ?? ''}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    )
};

interface translations {
    selectEnvironment: string
}
const enTranslations: translations = {
    selectEnvironment: 'Select Environment'
}