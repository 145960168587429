import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateIpfsHelpStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateIpfsHelpStep1:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-IPFS.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/kaleido-services/ipfs/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'IPFS File Store',
    helpDescription: 'IPFS nodes leverage a peer-to-peer file sharing protocol and present a decentralized, censorship resistant mechanism for storing large files and data structures off-chain.',
    helpDesc1: `Shared and randomly disseminated upon upload, files can be referenced by one or more hashes rather than including them in their entirety on the chain.`,
    helpDesc2: 'The uploader of a file has the autonomy to implement optional encryption techniques and/or to structure data within a configured directory.',
}