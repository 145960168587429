import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from "@material-ui/core";
import { FormDialog } from '../../../../components/DialogWrappers'
import { useMutation, ApolloQueryResult } from '@apollo/client';
import { UpdateDocumentStorePreferencesMutation, UpdateDocumentStorePreferencesData, UpdateDocumentStorePreferencesVars, DocumentStorePreferencesData } from '../../../../models';

export interface Props {
    serviceId: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    defaultName: string,
    refetchPreferences: (variables?: Record<string, any> | undefined) => Promise<ApolloQueryResult<DocumentStorePreferencesData>>
}

export const UpdatePreference = ({ serviceId, open, setOpen, refetchPreferences, defaultName }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'UpdateDocumentStorePreferences', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`UpdateDocumentStorePreferences:${key}`, interpolate)

    const [path, setPath] = useState(defaultName);
    const [isValid, setIsValid] = useState(true);
    const [editPreference] = useMutation<UpdateDocumentStorePreferencesData, UpdateDocumentStorePreferencesVars>(UpdateDocumentStorePreferencesMutation);

    useEffect(() => {
        if (open) {
            setPath(defaultName);
        }
    }, [defaultName, open]);

    const onSubmit = async () => {
        await editPreference({
            variables: {
                service_id: serviceId,
                preference: {
                    key: 'receivedDocumentsPath',
                    value: path!
                }
            }
        })
        await refetchPreferences();
    }

    const onInputChange = (input: string) => {
        setPath(input);
        setIsValid(!/\/=?[^A-z0-9${}]/.test(input));
    }

    const controlsWrapper = 
        <TextField
            value={path} onChange={event => onInputChange(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('path')}
            fullWidth
            variant="outlined"
        />

    return <FormDialog 
        {...{open}} 
        {...{setOpen}} 
        header={lt('updatePreference')} 
        description={lt('updatePreferenceDescription')} 
        {...{controlsWrapper}} 
        saveDisabled={!isValid}
        closeDialogAfterSave
        onSave={onSubmit} />
};

interface translations {
    updatePreference: string,
    updatePreferenceDescription: string,
    path: string
}
const enTranslations: translations = {
    updatePreference: 'Update Preference',
    updatePreferenceDescription: 'You can update the path to the folder for your received documents.',
    path: 'Path'
}