import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicItems } from './BasicItems';

export const TETHER_ACTIVITY_PATH = 'activity';
export const TETHER_MANAGE_PATH = 'manage';

interface Props {
    baseServicePath: string
}

export const TetherItems = ({ baseServicePath } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TetherItems', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TetherItems:${key}`, interpolate)

    const ACTIVITY = lt('activity');
    const MANAGE = lt('manage')

    const serviceSpecificTabs = [
        {
            name: MANAGE,
            path: TETHER_MANAGE_PATH,
            selectedItem: MANAGE
        },
        {
            name: ACTIVITY,
            path: TETHER_ACTIVITY_PATH,
            selectedItem: ACTIVITY
        },
    ]

    return (
        <BasicItems {...{baseServicePath}} {...{serviceSpecificTabs}} />
    );
};

interface translations {
    activity: string,
    manage: string
}

const enTranslations: translations = {
    manage: 'Manage',
    activity: 'Activity'
}