import { useQuery } from '@apollo/client';
import { FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { XAxisOptions } from 'highcharts';
import RefreshIcon from 'mdi-react/RefreshIcon';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Bytes } from '../../components/Charts/B2B/Bytes';
import { ChunksSent } from '../../components/Charts/B2B/ChunksSent';
import { DocumentsKPI } from '../../components/Charts/B2B/DocumentsKPI';
import { DocumentsStored } from '../../components/Charts/B2B/DocumentsStored';
import { Transfers } from '../../components/Charts/B2B/Transfers';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { DisplayGridWrapper } from '../../components/DisplayWrappers';
import { EnvironmentResourcesVars } from '../../interfaces';
import { AllDocumentStoreMetricsData, AllDocumentStoreMetricsQuery, AllDocumentStoreMetricsVars, RuntimeSize, ServicesData, ServicesQuery } from '../../models';
import { RuntimeSelector } from './RuntimeSelector';

const REFRESH_TIMEOUT = 2500
const MONTHS = [1,2,3,4,5,6,7,8,9,10,11,12]
const YEARS = [2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030]

const MENU_PROPS = { getContentAnchorEl: null } // avoids the select menu jumping around when selecting runtimes

export const Documents = () => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthDocuments', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`EnvironmentHealthDocuments:${key}`, interpolate), [t])

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!
    }

    // unfortunately cant use TextField with multiple select so need this ref business here to get a consistent look
    const monthInputLabel = useRef<HTMLLabelElement>(null);
    const [monthLabelWidth, setMonthLabelWidth] = useState(0);
    useEffect(() => {
        if (monthInputLabel.current)
        setMonthLabelWidth(monthInputLabel.current.offsetWidth);
    }, [monthInputLabel])

    const yearInputLabel = useRef<HTMLLabelElement>(null);
    const [yearLabelWidth, setYearLabelWidth] = useState(0);
    useEffect(() => {
        if (yearInputLabel.current)
        setYearLabelWidth(yearInputLabel.current.offsetWidth);
    }, [yearInputLabel])

    const thisMonth = moment().month() + 1
    const thisYear = moment().year()
    
    const [message, setMessage] = useState('');
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [month, setMonth] = useState(thisMonth);
    const [year, setYear] = useState(thisYear);

    const [sentReceived, setSentReceived] = useState<'sent' | 'received'>('sent');

    // refresh the metrics using a timeout with a visual indicator that its refreshing
    const timeoutRef = useRef<number>(0)
    useEffect(() => {
        return () => window.clearTimeout(timeoutRef.current);
    }, []);
    const handleRefresh = () => {
        window.clearTimeout(timeoutRef.current);
        setDisableRefresh(true)

        Promise.all([refetchAllDocumentStoreMetrics()]).then(() => {
            timeoutRef.current = window.setTimeout(() => {
                setDisableRefresh(false)
            }, REFRESH_TIMEOUT)
        })
    } 

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const allRuntimes = useMemo(() => {
        let list = [] as { _id: string, name: string, size: RuntimeSize }[]
        list = list.concat(services.filter(s => s.membership.isMine && s.service === 'documentstore').map(n => 
            ({ _id: n._id, name: n.name, size: n.size })
        ))
        return list
    }, [services])

    // track the runtimes to show in the filter
    const [selectedRuntimeIds, setSelectedRuntimeIds] = useState<string[]>(allRuntimes.map(r => r._id));

    // query the resource usage stats
    const {
        refetch: refetchAllDocumentStoreMetrics,
        loading: allDocumentStoreMetricsLoading,
        data: {
            allDocumentStoreMetrics
        } = { allDocumentStoreMetrics: [] },
        error: allDocumentStoreMetricsError
    } = useQuery<AllDocumentStoreMetricsData, AllDocumentStoreMetricsVars>(AllDocumentStoreMetricsQuery, {
        variables: {
            ...environmentVars, 
            ids: allRuntimes.map(r => r._id),
            month,
            year
        },
        fetchPolicy: 'cache-and-network'
    });

    const runtimesForChart = useMemo(() => (
        allDocumentStoreMetrics.filter(r => selectedRuntimeIds.find(id => id === r.id))
    ), [selectedRuntimeIds, allDocumentStoreMetrics])

    // show error if the query fails
    useEffect(() => {
        const e = allDocumentStoreMetricsError
        if (e) {
            ErrorSnackbarCatcher(e, setMessage)
        }
    }, [allDocumentStoreMetricsError]);

    const wrapChartWithLoading = (chart: JSX.Element, header: string, putSentAndReceived = false) => {
        let actionBar: JSX.Element | undefined = undefined

        if (allDocumentStoreMetricsLoading && !runtimesForChart?.length) {
            return (
                <DisplayGridWrapper hideDivider {...{header}} padDisplayGrid displayGrid={<CircularProgress />}/>
            )
        } else if (!selectedRuntimeIds.length) {
            const noRuntimesSelected = <Typography variant="body2">{lt('noRuntimesSelected')}</Typography>
            return (
                <DisplayGridWrapper {...{header}} padDisplayGrid displayGrid={noRuntimesSelected}/>
            )
        } else if (putSentAndReceived) {
            actionBar = (
                <FormControl variant="outlined" className={classes.selectWidth}>
                    <Select value={sentReceived} onChange={e => setSentReceived(e.target.value as 'sent' | 'received')}>
                        <MenuItem value={'sent'}>{lt('sent')}</MenuItem>
                        <MenuItem value={'received'}>{lt('received')}</MenuItem>
                    </Select>
                </FormControl>
            )
        }

        return (
            <DisplayGridWrapper hideDivider {...{header}} displayGrid={chart} {...{actionBar}} />
        )
    }

    const preventRenderError = allDocumentStoreMetricsError ? true : false
    const refreshIsDisabled = allDocumentStoreMetricsLoading || disableRefresh

    const xAxis: XAxisOptions = {
        type: 'datetime',
        min: Date.UTC(year, month - 1, 1),
        max: Date.UTC(year, month - 1, new Date(year, month, 0).getDate()),
        tickInterval: 24 * 3600 * 1000, // 1 day
        labels: {
            step: 3
        },
    }
                
    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('documents')}
                    </Typography>
                </Grid>
                <Grid item>
                    <DocumentsKPI {...{allRuntimes}} />
                </Grid>

                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">
                            {lt('metrics')}
                        </Typography>
                    </Grid>
                    {!preventRenderError && 
                    <Grid item>
                        <RuntimeSelector {...{allRuntimes}} {...{selectedRuntimeIds}} {...{setSelectedRuntimeIds}} {...{refreshIsDisabled}} />
                        
                        <FormControl variant="outlined" className={classes.padDropdown}>
                            <InputLabel ref={monthInputLabel}>
                                {lt('month')}
                            </InputLabel>
                            <Select
                                labelWidth={monthLabelWidth}
                                disabled={refreshIsDisabled}
                                value={month}
                                onChange={e => setMonth(e.target.value as number)}
                                MenuProps={MENU_PROPS}> 
                                {MONTHS.map((m) => (
                                    <MenuItem key={`month-${m}`} value={m}>{lt(`month${m}` as keyof translations)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className={classes.padDropdown}>
                            <InputLabel ref={yearInputLabel}>
                                {lt('year')}
                            </InputLabel>
                            <Select
                                labelWidth={yearLabelWidth}
                                disabled={refreshIsDisabled}
                                value={year}
                                onChange={e => setYear(e.target.value as number)}
                                MenuProps={MENU_PROPS}> 
                                {YEARS.filter(y => y <= thisYear).map((y) => (
                                    <MenuItem key={`year-${y}`} value={y}>{y}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <IconButton color="primary" size={refreshIsDisabled ? "small" : "medium"} disabled={refreshIsDisabled}
                                    onClick={() => handleRefresh()} >
                            { refreshIsDisabled ? <CircularProgress color="inherit" /> : <RefreshIcon /> }
                        </IconButton>
                    </Grid>
                    }
                </Grid>

                {!preventRenderError && 
                <Grid item container spacing={3}>
                    <Grid item container>
                        {wrapChartWithLoading(
                            <Transfers {...{xAxis}} height='25vh' {...{allRuntimes}} {...{runtimesForChart}} chart={sentReceived} />, 
                            lt('documentsSentAndReceived'),
                            true
                        )}
                    </Grid>

                    <Grid item container>
                        {wrapChartWithLoading(
                            <Bytes {...{xAxis}} height='25vh' {...{allRuntimes}} {...{runtimesForChart}} chart={sentReceived} />, 
                            lt('bytesSentAndReceived'),
                            true
                        )}
                    </Grid>

                    <Grid item container lg={6}>
                        {wrapChartWithLoading(
                            <ChunksSent service='documentstore' {...{xAxis}} height='25vh' {...{allRuntimes}} {...{runtimesForChart}} />, 
                            lt('chunksSent')
                        )}
                    </Grid>

                    <Grid item container lg={6}>
                        {wrapChartWithLoading(
                            <DocumentsStored {...{xAxis}} height='25vh' {...{allRuntimes}} {...{runtimesForChart}} />, 
                            lt('documentsStored')
                        )}
                    </Grid>
                </Grid>
                }
            </Grid>
        </>
    ) 
};

const useStyles = makeStyles(theme => ({
    padDropdown: {
        paddingRight: theme.spacing(2)
    },
    selectWidth: {
        width: '150px'
    }
}));

interface translations {
    documents: string,
    metrics: string,
    time: string,
    noRuntimesSelected: string,
    documentsSentAndReceived: string,
    sent: string,
    received: string,
    month: string,
    month1: string,
    month2: string,
    month3: string,
    month4: string,
    month5: string,
    month6: string,
    month7: string,
    month8: string,
    month9: string,
    month10: string,
    month11: string,
    month12: string,
    year: string,
    bytesSentAndReceived: string,
    chunksSent: string,
    documentsStored: string
}
const enTranslations: translations = {
    documents: 'Documents',
    metrics: 'Document Exchange Summary',
    time: 'Time',
    noRuntimesSelected: 'No runtimes have been selected. Use the filter above to select one or more runtimes.',
    documentsSentAndReceived: 'Documents Sent & Received',
    sent: 'Sent',
    received: 'Received',
    month: 'Month',
    month1: 'January',
    month2: 'February',
    month3: 'March',
    month4: 'April',
    month5: 'May',
    month6: 'June',
    month7: 'July',
    month8: 'August',
    month9: 'September',
    month10: 'October',
    month11: 'November',
    month12: 'December',
    year: 'Year',
    bytesSentAndReceived: 'KB Sent & Received',
    chunksSent: 'Chunks Sent (Monthly Total)',
    documentsStored: 'Documents in Kaleido Storage'
}