import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
    
interface Props {
    filesInZip: string[],
    fileToSearch: string,
    setFileToSearch: React.Dispatch<React.SetStateAction<string>>,
    disabled: boolean
};

export const UploadContractFileSelector = ({ filesInZip, fileToSearch, setFileToSearch, disabled }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'UploadContractFileSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`UploadContractFileSelector:${key}`, interpolate)

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    return (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('selectFileToSearch')}
                </Typography>
            </Grid>
            <Grid item>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel ref={inputLabel} required>
                        {lt('selectFile')}
                    </InputLabel>
                    <Select {...{disabled}}
                        labelWidth={labelWidth}
                        value={fileToSearch}
                        onChange={e => setFileToSearch(e.target.value as string)}>
                        {filesInZip.map(l => {
                            return (
                                <MenuItem key={l} selected={l === fileToSearch} value={l}>{l}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </>
    )
};

interface translations {
    selectFile: string,
    selectFileToSearch: string,

}
const enTranslations: translations = {
    selectFile: 'Select file',
    selectFileToSearch: 'Select file to scan for contracts'
}
