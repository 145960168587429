import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayCard } from '../../../components/DisplayWrappers'
import { useParams, useHistory } from 'react-router-dom';
import { KEY_MANAGEMENT_BASE_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import { Wallet } from '../../../models/wallets';
import { KEY_MANAGEMENT_NODE_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { NODE_ACCOUNTS_PATH } from '../../../components/NodeNav/NodeNav';
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import { EmptyCard } from '../../../components/DisplayWrappers/EmptyCard';
import { CREATE_NODES_PATH } from '../../../components/NodeNav/NodePicker';

interface Props {
    wallets: Wallet[]
};

export const NodeWalletsCards = ({ wallets }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeWalletsCards', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeWalletsCards:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const createPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${CREATE_NODES_PATH}/1`

    const itemList = wallets.map(wallet => ({
        title: wallet.node_name ?? '',
        value: wallet.membership.name ?? '',
        icon: <BullseyeIcon />,
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${wallet.wallet_id}/${NODE_ACCOUNTS_PATH}`),
        actionIcon: <ChevronRightIcon />
    }))

    const linkButton = {
        text: lt('viewNodesWallet'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_NODE_PATH}`)
    };

    if (wallets.length === 0) return <EmptyCard header={lt('header')} 
                                                imageFiles="Empty-Node.svg"
                                                description={lt('emptyDescription')}
                                                createPath={createPath}
                                                onViewDetailsClick={() => window.open('https://docs.kaleido.io/using-kaleido/blockchain-nodes/')} />
    
    return (
        <DisplayCard {...{linkButton}}
            header={lt('header')}
            {...{itemList}} />
    )
};

interface translations {
    header: string
    viewNodesWallet: string
    emptyDescription: string
}
const enTranslations: translations = {
    header: 'Node Wallets',
    viewNodesWallet: 'View Node Wallets',
    emptyDescription: 'In order to start signing transactions using accounts from your node wallets, is necessary to create a node first.'
}