import { Divider, ExpansionPanel, AccordionDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import moment from 'moment';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DisplayGridWrapper, DisplayTable } from '../../components/DisplayWrappers';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { FormLink } from '../../components/FormControls/FormLink';
import { MANAGE_ORG_INVOICES_PATH, MANAGE_ORG_PATH, ORGS_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { BillingSummary, Consortium, EnPlansTranslations, EnRuntimeSizeTranslation, EnServicesTranslations, PlansEnum, PlansTranslations, RuntimeSizeTranslation, ServicesEnum, ServicesTranslations, UserMemberships } from '../../models';
import { calculateBillingTotal, estimateCost } from './Billing/CostUtils';
import { ForecastedCost } from './Billing/ForecastedCost';
import { UsagePieChart } from './Billing/UsagePieChart';

interface Props {
    memberships: UserMemberships,
    consortia: Consortium[],
    billingSummary: BillingSummary,
    hideHeader?: boolean,
}

export const ManageOrgBillingUsage: React.FC<Props> = ({memberships, consortia, billingSummary, hideHeader}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ManageOrgBillingUsage', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ManageOrgBillingUsage:${key}`, interpolate);

    // separate PlanTranslations since Starter plan conflicts with Starter nodes (which are translated as small)
    i18n.addResourceBundle('en', 'PlanTranslations', EnPlansTranslations);
    const pt = (key: keyof PlansTranslations, interpolate?: object) => t(`PlanTranslations:${key}`, interpolate);
    
    const { org_id } = useParams<any>();
    const history = useHistory();

    const membershipsTableColumnHeaders = [
        lt('membership'),
        lt('consortium'),
        lt('plan'),
        lt('cost')
    ];

    const getMembershipLabel = (membershipId: string) => {
        const membershipName = memberships.find(membership => membership._id === membershipId)?.org_name;
        if (membershipName) {
            return lt('namedMembership', { membershipName, membershipId });
        } else {
            return lt('deletedMembership', { membershipId });
        }
    };

    const membershipTableRecords: DisplayTableRecord[] = billingSummary.memberships.map(membership => ({
        columns: [
            { value: getMembershipLabel(membership.membership_id) },
            { value: consortia.find(consortia => consortia._id === membership.consortia_id)?.name || '--' },
            { value: pt(PlansEnum[membership.plan_id]) },
            { value: lt('costNumber', { number: Number(membership.total_usd).toFixed(2) }) }]
    }));

    const nodesTableColumnHeaders = [
        lt('node'),
        lt('size'),
        lt('environment'),
        lt('plan'),
        lt('hours'),
        lt('hourly'),
        lt('cost')
    ];

    const nodesTableRecords: DisplayTableRecord[] = billingSummary.nodes.map(node => ({
        columns: [
            { value: node.node_id },
            { value: lt(node.size) },
            { value: node.environment_id },
            { value: pt(PlansEnum[node.plan_id]) },
            { value: node.unit_hrs },
            { value: lt('costNumber', { number: Number(node.unit_cost_usd).toFixed(2) }) },
            { value: lt('costNumber', { number: Number(node.total_usd).toFixed(2) }) }]
    }));

    const otherRuntimesTableColumnHeaders = [
        lt('runtime'),
        lt('type'),
        lt('size'),
        lt('environment'),
        lt('plan'),
        lt('hours'),
        lt('hourly'),
        lt('cost')
    ];

    const otherRuntimesTableRecords: DisplayTableRecord[] = billingSummary.services.map(service => ({
        columns: [
            { value: service.service_id },
            { value: lt(ServicesEnum[service.service]) },
            { value: lt(service.size) },
            { value: service.environment_id },
            { value: pt(PlansEnum[service.plan_id]) },
            { value: service.unit_hrs },
            { value: lt('costNumber', { number: Number(service.unit_cost_usd).toFixed(2) }) },
            { value: lt('costNumber', { number: Number(service.total_usd).toFixed(2) }) }]
    }));

    const storageTableColumnHeaders = [
        lt('plan'),
        lt('cost')
    ];

    const storageTableRecords: DisplayTableRecord[] = billingSummary.storage.map(storage => ({
        columns: [
            { value: pt(PlansEnum[storage.plan_id]) },
            { value: lt('costNumber', { number: Number(storage.total_usd).toFixed(2) }) }]
    }));

    const supportTableColumnHeaders = [
        lt('level'),
        lt('cost')
    ];

    const getSupportLabel = (supportLevel: number) => {
        switch (supportLevel) {
            case 100: return lt('basic');
            case 200: return lt('select');
            case 300: return lt('priority');
        }
        return '--';
    };

    const supportTableRecords: DisplayTableRecord[] = billingSummary.support.map(support => ({
        columns: [
            { value: getSupportLabel(support.support_level) },
            { value: lt('costNumber', { number: Number(support.total_usd).toFixed(2) }) }]
    }));

    const membershipsTotal = calculateBillingTotal(billingSummary.memberships);
    const nodesTotal = calculateBillingTotal(billingSummary.nodes);
    const servicesTotal = calculateBillingTotal(billingSummary.services);
    const storageTotal = calculateBillingTotal(billingSummary.storage);
    const supportTotal = calculateBillingTotal(billingSummary.support);
    const adjustmentsTotal = calculateBillingTotal(billingSummary.adjustments || []);
    const grandTotal = membershipsTotal + nodesTotal + servicesTotal + storageTotal + supportTotal;
    const { estimate } = estimateCost(grandTotal);

    const generateExpansionPanel = (title: string, total: number, tableColumnHeaders: string[], tableRecords: DisplayTableRecord[]) => {
        return (<ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ChevronDownIcon />}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="body1">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {lt('costNumber', { number: total.toFixed(2) })}
                        </Typography>
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <AccordionDetails>
                <DisplayTable columnHeaders={tableColumnHeaders} records={tableRecords} />
            </AccordionDetails>
        </ExpansionPanel>);
    };

    const chartContent = (
        <Grid container direction="column" spacing={3}>
            <Grid item container alignItems="center">
                <Grid item container xs={6} direction="column">
                    <Grid item>
                        <Typography variant="body1">{lt('forecastedCost')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{lt('endOfMonth', { month: moment().format('MMM YYYY') })}</Typography>
                    </Grid>
                </Grid>
                <Grid item justify="flex-end" container xs={6}>
                    <Grid item>
                        <Typography variant="h5">{lt('costNumber', { number: estimate.toFixed(2) })}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid item container alignItems="center">
                <Grid item container xs={6} direction="column">
                    <Grid item>
                        <Typography variant="body1">{lt('totalCost')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{lt('currentMonthToDate')}</Typography>
                    </Grid>
                </Grid>
                <Grid item justify="flex-end" container xs={6}>
                    <Grid item>
                        <Typography variant="h5">{lt('costNumber', { number: grandTotal.toFixed(2) })}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {billingSummary.adjustments && <>
                {billingSummary.adjustments.map(a => <>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item container alignItems="center" key={`${a.description}`}>
                        <Grid item xs>
                            <Typography variant="body1">{a.description}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5">{lt('costNumber', { number: a.total_usd.toFixed(2) })}</Typography>
                        </Grid>
                    </Grid>
                </>)}
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item container alignItems="center">
                    <Grid item xs direction="column">
                        <Grid item>
                            <Typography variant="body1">{lt('billedCost')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{lt('currentBillToDate')}</Typography>
                        </Grid>                        
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">{lt('costNumber', { number: (grandTotal + adjustmentsTotal).toFixed(2) })}</Typography>
                    </Grid>
                </Grid>
            </>}            
        </Grid>
    );

    return (
        <>
            <Grid container direction="column" spacing={3}>
                {!hideHeader && <>
                <Grid item>
                    <Typography variant="h5">{lt('billingAndUsage', { date: moment().format('MMM YYYY') })}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        <Trans i18nKey="ManageOrgBillingUsage:sectionDescription"
                            components={[<FormLink onClick={() => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_INVOICES_PATH}`)} />]}>
                        </Trans>
                    </Typography>
                </Grid>
                </>}
                <Grid item container direction="column" spacing={5}>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6">{lt('memberships')}</Typography>
                        </Grid>
                        <Grid item>
                            {generateExpansionPanel(lt('membershipExpansionTitle', { count: billingSummary.memberships.length }), membershipsTotal,
                                membershipsTableColumnHeaders, membershipTableRecords)}
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6">{lt('runtimes')}</Typography>
                        </Grid>
                        <Grid item>
                            {generateExpansionPanel(lt('nodesExpansionTitle', { count: billingSummary.nodes.length }), nodesTotal,
                                nodesTableColumnHeaders, nodesTableRecords)}
                        </Grid>
                        <Grid item>
                            {generateExpansionPanel(lt('otherRuntimes', { count: billingSummary.services.length }), servicesTotal,
                                otherRuntimesTableColumnHeaders, otherRuntimesTableRecords)}
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6">{lt('storage')}</Typography>
                        </Grid>
                        <Grid item>
                            {generateExpansionPanel(lt('storageExpansionTitle', { count: billingSummary.storage.length }), storageTotal,
                                storageTableColumnHeaders, storageTableRecords)}
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6">{lt('support')}</Typography>
                        </Grid>
                        <Grid item>
                            {generateExpansionPanel(lt('support'), supportTotal,
                                supportTableColumnHeaders, supportTableRecords)}
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6">{lt('totalCost')}</Typography>
                        </Grid>
                        <Grid item>
                            <DisplayGridWrapper displayGrid={chartContent} padDisplayGrid={true} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item md={6}>
                        <UsagePieChart memberships={membershipsTotal} nodes={nodesTotal} otherRuntimes={servicesTotal} storage={storageTotal} support={supportTotal} />
                    </Grid>
                    <Grid item md={6}>
                        <ForecastedCost costToDate={grandTotal} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

interface translations extends RuntimeSizeTranslation, ServicesTranslations {
    billingAndUsage: string
    sectionDescription: string
    membershipExpansionTitle: string
    memberships: string
    membership: string
    consortium: string
    plan: string
    cost: string
    hours: string
    hourly: string
    costNumber: string
    nodesExpansionTitle: string
    runtimes: string
    node: string
    environment: string
    otherRuntimes: string
    runtime: string
    size: string
    type: string
    storage: string
    storageExpansionTitle: string
    totalCost: string
    support: string
    level: string
    basic: string
    select: string
    priority: string
    small: string
    medium: string
    large: string
    currentMonthToDate: string
    forecastedCost: string
    endOfMonth: string
    namedMembership: string
    deletedMembership: string
    billedCost: string
    currentBillToDate: string
}

const enTranslations: translations = {
    ...EnServicesTranslations,
    ...EnRuntimeSizeTranslation,
    billingAndUsage: 'Billing Forecast & Usage - {{date}}',
    sectionDescription: 'Showing usage and estimated costs. For final costs go to <0>Invoices</0>.',
    membershipExpansionTitle: 'Memberships x {{count}}',
    memberships: 'Memberships',
    membership: 'Membership',
    consortium: 'Consortium',
    plan: 'Plan',
    cost: 'Cost',
    hours: 'Hours',
    hourly: 'Hourly',
    // eslint-disable-next-line
    costNumber: '${{number}}',
    nodesExpansionTitle: 'Nodes x {{count}}',
    runtimes: 'Runtimes',
    node: 'Node',
    environment: 'Environment',
    otherRuntimes: 'Other Runtimes x {{count}}',
    runtime: 'Runtime',
    size: 'Size',
    type: 'Type',
    storage: 'Storage',
    storageExpansionTitle: 'Storage x {{count}}',
    totalCost: 'Total Cost',
    support: 'Support',
    level: 'Level',
    basic: 'Basic',
    select: 'Select',
    priority: 'Priority',
    currentMonthToDate: 'Current month-to-date spent',
    forecastedCost: 'Forecasted Cost',
    endOfMonth: 'End of {{month}}',
    namedMembership: '{{membershipName}} ({{membershipId}})',
    deletedMembership: 'Deleted ({{membershipId}})',
    billedCost: 'Billable Cost',
    currentBillToDate: 'Current month-to-date costs that are billable',
}