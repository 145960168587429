import React from 'react';
import {
    Grid,
    makeStyles,
    CircularProgress,
    Typography,
    Box,
} from '@material-ui/core';

interface Props {
    text?: string;
}

export const CenteredSpinner = ({ text }: Props) => {
    const classes = useStyles();
    return (
        <Grid
            container
            className={classes.container}
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress />
            {text && (
                <Box pl={3}>
                    <Typography>{text}</Typography>
                </Box>
            )}
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        width: '100%',
        height: '60%',
    },
}));
