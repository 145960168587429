import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { createApolloClient } from "./apollo";
import { ApolloProvider } from '@apollo/client';
import Routes from './components/Router';
import ServerError from './views/ServerError';
import { SessionData, Session } from './interfaces'
import { SessionQuery } from './queries/Session';
import { BrandColors } from './utils/Colors'
import { Stripe } from '@stripe/stripe-js';

const apolloClient = createApolloClient();

const theme = createTheme({
    palette: {
        background: {
            default: '#F2F2F2'
        },
        primary: {
            main: BrandColors.blurple,
        },
        secondary: {
            main: BrandColors.cyan,
        },
        text: {
            primary: '#2D2D2D',
            secondary: '#2D2D2D'
        }
    },
    typography: {
        fontFamily: ['Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
        h5: {
            fontWeight: 500,
            fontSize: 20
        },
        h6: {
            fontWeight: 500,
            fontSize: 16
        },
        body1: {
            fontWeight: 500,
            fontSize: 14
        },
        body2: {
            fontWeight: 400,
            fontSize: 14
        },
        button: {
            fontWeight: 500,
            fontSize: 14
        },
    }
});

export const StripeContext = React.createContext<[Promise<Stripe | null> | undefined, Dispatch<SetStateAction<Promise<Stripe | null> | undefined>> | undefined]>([undefined, undefined]);

const App: React.FC = () => {

    const [ready, setReady] = useState<boolean>(false);
    const [serverError, setServerError] = useState<string | undefined>();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

    useEffect(() => {
        fetch('/api/ui/v2/login/status', {
            headers: { 'content-type': 'application/json' }
        }).then(async response => {
            if (response.ok) {
                const session = (await response.json()) as Session;
                apolloClient.writeQuery<SessionData>({ 
                    query: SessionQuery, 
                    data: {
                        session: {...session, selected_org: session.selected_org || ''}
                    }
                })
            } else if (response.status !== 401) {
                setServerError(response.statusText);
            }
        }).finally(() => {
            setReady(true);
        });
    }, []);

    if (ready) {
        if (serverError) {
            return <ServerError message={serverError} />
        } else {
            return (
                <StripeContext.Provider value={[stripePromise, setStripePromise]}>
                    <ThemeProvider theme={theme}>
                        <ApolloProvider client={apolloClient}>
                            <Routes setServerError={setServerError}/>
                        </ApolloProvider>
                    </ThemeProvider>
                </StripeContext.Provider>
            );
        }
    } else {
        return <></>
    }

};

export default App;