import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { AccountsData, AccountsVars, AccountsQuery, ServicesEnum } from '../../models'
import { Typography, Grid, Button, CircularProgress } from "@material-ui/core";
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import { DisplayTable, ToolBarOptions } from '../DisplayWrappers'
import { ShortenedHash } from '../FormControls/ShortenedHash'
import { CreateAccount } from './CreateAccount'
import { RefreshCloudHsmAccountsMutation } from '../../models/cloudhsm';
import { ServiceResourcesVars } from '../../interfaces';
import { MessageSnackbar, ErrorSnackbarCatcher } from '../DialogWrappers';

const REFETCH_DELAY = 2000
const MAX_REFETCH_ATTEMPTS = 10

export const Wallet: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Wallet', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Wallet:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id, node_id, service_id } = useParams<any>();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const splitPathname = pathname.split('/');
    const serviceType = splitPathname[splitPathname.length - 1] === 'wallet' ?  splitPathname[splitPathname.length - 3] : splitPathname[splitPathname.length -2];

    const [refreshCloudHsmAccounts] = useMutation<null, ServiceResourcesVars>(RefreshCloudHsmAccountsMutation)

    const accountsVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        wallet_id: node_id || service_id
    }

    const {
        loading,
        refetch,
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData, AccountsVars>(AccountsQuery, { 
        variables: accountsVars,
        fetchPolicy: 'cache-and-network'
    });

    const columnHeaders = [
        '',
        lt('address'),
        lt('type'),
        lt('createdAt')
    ]

    const records = accounts.map(a => ({
        key: a._id,
        onClick: () => history.push(`${pathname}/${a._id}`),
        columns: [
            {isIcon: true, value: <AccountCircleIcon />},
            {value: <ShortenedHash address={a._id} showFullAddress retry /> },
            {value: a.account_type.charAt(0).toUpperCase() + a.account_type.slice(1)},
            {value: new Date(a.created_at).toLocaleString()},
        ]
    }))

    // accounts take a small amount of time to be inserted in the monitor node address book 
    // so, we have to poll / refetch for newly created accounts
    
    // setup refs to track the polling interval and refetch attempts
    const refetchAttempts = useRef<number>(0)
    const intervalRef = useRef<number>(0)
    useEffect(() => {
        // cleanup
        return () => window.clearInterval(intervalRef.current);
    }, []);
    const onAccountCreated = (createdAddress: string) => {
        // always clear the interval
        window.clearInterval(intervalRef.current)

        if (!createdAddress) return
        if (loading) return
        if (refetchAttempts.current.valueOf() >= MAX_REFETCH_ATTEMPTS) {
            console.log(`stopped refetching looking for ${createdAddress} after too many attempts`)
            refetchAttempts.current = 0
            return
        }

        console.log(`refetching looking for ${createdAddress}. attempt ${refetchAttempts.current}`)

        refetch().then(r => {
            const createdAccount = r.data?.accounts.find(a => a._id.toLowerCase() === createdAddress.toLowerCase())
            if (!createdAccount) {
                intervalRef.current = window.setInterval(() => {
                    refetchAttempts.current++
                    onAccountCreated(createdAddress)
                }, REFETCH_DELAY);
            } else {
                console.log(`found ${createdAddress}. stopping refetch`)
                window.clearInterval(intervalRef.current)
                refetchAttempts.current = 0
            }
        })
    }

    const getService = () => {
        if (serviceType === ServicesEnum.cloudhsm) return lt('cloudHsm');
        if (serviceType === ServicesEnum.ethwallet) return lt('ethWallet')
        return ''
    };

    const onRefreshCloudHsm = async () => {
        try {
            await refreshCloudHsmAccounts({variables: {service_id}});
            await refetch();
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
    }

    const toolBarOptions : ToolBarOptions = {
        refresh: {
            onRefresh: onRefreshCloudHsm
        }
    }
    
    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage}  />
            <CreateAccount {...{open}} {...{setOpen}} {...{onAccountCreated}} />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {service_id ? getService() : lt('nodeWallet')}
                        </Typography>
                    </Grid>
                    { serviceType !== 'cloudhsm' && (
                        <Grid item>
                            <Button color="primary" variant="contained" size="large" onClick={() => setOpen(true)}>
                                {lt('newAccount')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid item container>
                    {loading && !accounts.length ? 
                    <CircularProgress /> :
                    <DisplayTable header={lt('accounts')} {...{columnHeaders}} {...{records}} toolBarOptions={serviceType === 'cloudhsm' ? toolBarOptions : undefined} />
                    }
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    newAccount: string
    nodeWallet: string,
    ethWallet: string,
    accounts: string,
    address: string,
    type: string,
    createdAt: string,
    cloudHsm: string
}
const enTranslations: translations = {
    newAccount: 'New Account',
    nodeWallet: 'Node Wallet',
    ethWallet: 'Kaleido Wallet',
    cloudHsm: 'Cloud HSM',
    accounts: 'Accounts',
    address: 'Address',
    type: 'Account type',
    createdAt: 'Created at'
}