import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
    BlocksData,
    BlocksQuery,
    BlocksVars,
    BlockData,
    BlockVars,
    BlockQuery,
} from "../../models/ledger";
import { DisplayTableRecord } from "../../components/DisplayWrappers/DisplayTableRow";
import { ShortenedHash } from "../../components/FormControls/ShortenedHash";
import moment from "moment";
import { DisplayTable } from "../../components/DisplayWrappers";
import {
    Grid,
    Typography,
    TablePagination,
    CircularProgress,
} from "@material-ui/core";
import {
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../components/DialogWrappers";
import {
    BLOCKEXPLORER_BLOCKS_PATH,
    BLOCKEXPLORER_PATH,
} from "../../components/BlockExplorerNav/BlockExplorerNav";
import { ShortenedString } from "../../components/FormControls/ShortenedString";

const LIMITLIST = [10, 25];

export const Blocks = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "BlocksTable", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`BlocksTable:${key}`, interpolate);

    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [errorMessage, setErrorMessasge] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [searchActive, setSearchActive] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const {
        loading,
        fetchMore,
        refetch,
        data: { blocks } = { blocks: [] },
    } = useQuery<BlocksData, BlocksVars>(BlocksQuery, {
        variables: {
            ...environmentVariables,
            limit,
        },
        fetchPolicy: "network-only",
    });

    const [
        searchBlock,
        { loading: searchLoading, data: { block } = { block: null } },
    ] = useLazyQuery<BlockData, BlockVars>(BlockQuery, {
        onError: () =>
            setErrorMessasge(lt("searchNotFound", { search: searchInput })),
    });

    const data = useMemo(() => {
        return block && searchActive && !searchLoading
            ? [block]
            : blocks.slice(currentPage * limit, currentPage * limit + limit);
    }, [blocks, block, limit, currentPage, searchActive, searchLoading]);

    if (loading && blocks.length === 0) return <CircularProgress />;

    const onChangePage = async (e: unknown, page: number) => {
        setPageLoading(true);
        try {
            if (page > currentPage && page * limit >= blocks.length) {
                await fetchMore({
                    variables: {
                        start: blocks[blocks.length - 1].number - limit,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return {
                            blocks: [...prev.blocks, ...fetchMoreResult.blocks],
                        };
                    },
                });
            }
            setCurrentPage(page);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        } finally {
            setPageLoading(false);
        }
    };

    const onChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setCurrentPage(0);
        setLimit(parseInt(event.target.value));
    };

    const onSubmitSearch = async (searchInput: string) => {
        setSearchInput(searchInput);
        if (searchInput) {
            await searchBlock({
                variables: {
                    ...environmentVariables,
                    number: searchInput,
                },
            });
        }
    };

    const columns = [
        lt("block"),
        lt("hash"),
        lt("minedBy"),
        lt("txCount"),
        lt("dateMined"),
    ];

    const Pagination = (
        <TablePagination
            rowsPerPageOptions={LIMITLIST}
            component="div"
            count={blocks[0] ? blocks[0].number + 1 : -1}
            rowsPerPage={limit}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onPageChange={onChangePage}
            page={currentPage}
            nextIconButtonProps={{
                disabled:
                    pageLoading ||
                    (currentPage + 1) * limit >=
                        (blocks[0] ? blocks[0].number : -1),
            }}
        />
    );

    const records: DisplayTableRecord[] = data.map((block, index) => ({
        key: `blocks-${index}`,
        columns: [
            {
                value: block.number,
            },
            {
                value: <ShortenedString content={block.hash ?? ""} hideCopy />,
            },
            {
                value:
                    block.miner === "Genesis" ? (
                        block.miner
                    ) : (
                        <ShortenedHash address={block.miner ?? ""} />
                    ),
            },
            {
                value: block.transactionCount,
            },
            {
                value:
                    block.miner === "Genesis"
                        ? "--"
                        : moment(block.timestamp).fromNow(),
            },
        ],
        onClick: () =>
            history.push(
                `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}/${block.number}`
            ),
    }));

    const onRefreshClick = () => {
        setCurrentPage(0);
        refetch();
    };

    const toolBarOptions = {
        search: {
            onSearch: onSubmitSearch,
            setSearchActive,
            label: "Enter block number",
        },
        refresh: {
            onRefresh: onRefreshClick,
        },
    };

    return (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessasge}
            />
            <Grid container direction="column" spacing={3}>
                <Grid
                    item
                    container
                    wrap="nowrap"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h5">{lt("header")}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <DisplayTable
                        {...{ toolBarOptions }}
                        header={lt("blocks")}
                        columnHeaders={columns}
                        {...{ records }}
                        actionFooter={
                            block && searchActive ? undefined : Pagination
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
};

interface translations {
    header: string;
    block: string;
    blocks: string;
    minedBy: string;
    txCount: string;
    dateMined: string;
    hash: string;
    searchLabel: string;
    searchNotFound: string;
    number: string;
}

const enTranslations: translations = {
    header: "Latest Blocks",
    block: "Block",
    blocks: "Blocks",
    minedBy: "Mined By",
    dateMined: "Date Mined",
    txCount: "Tx Count",
    hash: "Hash",
    searchLabel: "Search Number or Hash...",
    searchNotFound: 'Block "{{search}}" Not Found!',
    number: "Number",
};
