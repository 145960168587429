import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const FireflyTemplateHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractMangementFireflyTemplateHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractMangementFireflyTemplateHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Firefly.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/kaleido-platform/firefly/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'Initialize FireFly',
    helpDescription: 'During this step, Kaleido will ensure your environment contains the latest supported version of the FireFly contract(s).',
    helpDesc1: 'If you choose to start a new multiparty network, the FireFly contract will be added to your Network Apps and promoted to your current environment.',
    helpDesc2: 'If you choose to join an existing network, we will connect your FireFly instance to the deployed contract with the address you selected.',
    finalInfo: 'Clicking Initialize will complete the FireFly node setup process!'
}
