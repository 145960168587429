import React, { useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { OrganizationData, OrganizationQuery, OrganizationVars, EnPlansTranslations, PlansTranslations, PlansEnum, ServicesTranslations, EnServicesTranslations, ServicesEnum, RuntimeSizeTranslation, EnRuntimeSizeTranslation } from '../../models';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, Button, Typography } from '@material-ui/core';
import { ORGS_PATH, MANAGE_ORG_PATH, CHANGE_PLAN_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, } from '../../components/ManageOrgNav/ManageOrgNav';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import FaceAgentIcon from 'mdi-react/FaceAgentIcon';
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import CubeOutlineIcon from 'mdi-react/CubeOutlineIcon';
import { FormLink } from '../../components/FormControls/FormLink';
import { HelpCenter, HelpCenterTab } from '../../components/HelpCenter/HelpCenter';
import { SessionData } from '../../interfaces';
import { SessionQuery } from '../../queries/Session';

export const ManageOrgSubscription: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ManageOrgSubscription', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ManageOrgSubscription:${key}`, interpolate);

    // separate PlanTranslations since Starter plan conflicts with Starter nodes (which are translated as small)
    i18n.addResourceBundle('en', 'PlanTranslations', EnPlansTranslations);
    const pt = (key: keyof PlansTranslations, interpolate?: object) => t(`PlanTranslations:${key}`, interpolate);

    const { org_id } = useParams<any>();
    const history = useHistory();
    const [helpCenter, setHelpCenter] = useState(false);

    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id! } })!
    const { data: { session } = { session: undefined } } = useQuery<SessionData>(SessionQuery, { fetchPolicy: 'cache-only' });

    const isOwner = organization.owner === session?.user_id;

    const getSupportLabel = () => {
        switch (organization.support_level) {
            case 100: return lt('free');
            case 200: return lt('select');
            case 300: return lt('priority');
        }
    };

    let billingProviderInformation: DisplayCardListItem[] = [
        {
            icon: <TuneIcon />,
            title: lt('subscriptionPlan'),
            value: pt(PlansEnum[organization.plan])
        },
        {
            icon: <FaceAgentIcon />,
            title: lt('support'),
            value: getSupportLabel()
        }
    ];

    if (organization.limits.nodes) {
        billingProviderInformation.push({
            icon: <BullseyeIcon />,
            title: lt('allowedNodeSizes'),
            value: organization.limits.nodes.allowed_sizes.map(size => lt(size)).join(lt('listSeparation'))
        });
    }

    if (organization.limits.configurations) {
        billingProviderInformation.push({
            icon: <CogOutlineIcon />,
            title: lt('allowedConfigurations'),
            value: organization.limits.configurations.allowed_configurations.map(config => lt(config)).join(lt('listSeparation'))
        });
    }

    if (organization.limits.services) {
        billingProviderInformation.push({
            icon: <CubeOutlineIcon />,
            title: lt('allowedRuntimes'),
            value: organization.limits.services.allowed_services.map(service => lt(ServicesEnum[service])).join(lt('listSeparation'))
        });
    }

    const documentationList: DisplayCardListItem[] = [
        {
            icon: <DescriptionIcon />,
            title: lt('plans'),
            value: lt('plansDescription'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/plans/')
        },
        {
            icon: <DescriptionIcon />,
            title: lt('resourceLimits'),
            value: lt('resourceLimitsDescription'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/resource-limits/')
        }
    ];

    return (
        <>
            <Grid container spacing={3} direction="column">
                <Grid item container justify="space-between">
                    <Grid item>
                        <Typography variant="h5">{lt('subscription')}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" disabled={!isOwner}
                            onClick={() => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`)}>
                            {lt('changePlan')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item md={8}>
                        <DisplayCard header={lt('overview')} itemList={billingProviderInformation} leftAlignActionFooter={true} maxHeight="disable"
                            actionFooter={<Typography variant="body2">
                                <Trans i18nKey="ManageOrgSubscription:footerMessage"
                                    components={[<FormLink onClick={() => setHelpCenter(true)} />]}>
                                </Trans>
                            </Typography>} />
                    </Grid>
                    <Grid item md={4}>
                        <DisplayCard header={lt('documentation')} itemList={documentationList} />
                    </Grid>
                </Grid>
            </Grid>
            <HelpCenter
                isOpen={helpCenter}
                setOpen={setHelpCenter}
                tab={HelpCenterTab.CONTACT_US}
            />
        </>
    );
}

interface translations extends RuntimeSizeTranslation, ServicesTranslations {
    subscription: string
    changePlan: string
    overview: string
    documentation: string
    footerMessage: string
    plans: string
    plansDescription: string
    resourceLimits: string
    resourceLimitsDescription: string
    subscriptionPlan: string
    support: string
    free: string
    select: string
    priority: string
    allowedNodeSizes: string
    small: string
    medium: string
    large: string
    listSeparation: string
    allowedConfigurations: string
    kms: string
    opsmetric: string
    networking: string
    backup: string
    storage: string
    cloudhsm: string
    baf: string
    ethconnect: string
    node_config: string
    allowedRuntimes: string
}

const enTranslations: translations = {
    ...EnServicesTranslations,
    ...EnRuntimeSizeTranslation,
    subscription: 'Subscription',
    changePlan: 'Change Plan',
    overview: 'Overview',
    documentation: 'Documentation',
    footerMessage: 'To change or cancel your subscription, please select the Starter Plan. To permanently close your account, please <0>contact us</0>.',
    plans: 'Plans',
    plansDescription: 'Subscription plans to match the needs of a project from concept to production',
    resourceLimits: 'Resource Limits',
    resourceLimitsDescription: 'Thresholds across a Kaleido organization based on the selected plan.',
    subscriptionPlan: 'Subscription Plan',
    support: 'Support',
    free: 'Free',
    select: 'Select',
    priority: 'Priority',
    allowedNodeSizes: 'Allowed Node Sizes',
    listSeparation: ', ',
    allowedConfigurations: 'Allowed Configurations',
    kms: 'Keystore',
    opsmetric: 'Log Stream',
    networking: 'Networking',
    backup: 'Backup',
    storage: 'Storage',
    cloudhsm: 'Cloud HSM',
    baf: 'Blockchain Application Firewall',
    ethconnect: 'EthConnect',
    node_config: 'Node Configuration',
    allowedRuntimes: 'Allowed Runtimes'
}