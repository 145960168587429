import { gql, MutationFunctionOptions } from '@apollo/client';
import { ServiceResourcesVars } from '../interfaces';
import { EnvironmentResourcesVars } from '../interfaces';

// INTERFACES

export const APP2APP_LIMITS = {
    chunks: {
        starter: 1000,
        small: 10000,
        medium: 50000,
    },
    fileSize: {
        small: '4 KB',
        medium: '64 KB',
        large: '1 MB'
    }
}

export const APP2APP_RUNTIME_LIMITS = {
    starter: {
        cores: 0.3,
        memory: 256
    },
    small: {
        cores: 0.3,
        memory: 512
    },
    medium: {
        cores: 0.5,
        memory: 512
    },
    large: {
        cores: 1,
        memory: 1024
    }
}

export interface App2AppAddressBookData {
    app2appAddressBook: App2AppAddressBookEntry[]
}

export interface App2AppAddressBookEntry {
    membership: string,
    destinations: {
        name: string
        uri: string
    }[]
}

export interface App2AppLimitTranslations {
    app2appStarterDescription: string
    app2appSmallDescription: string
    app2appMediumDescription: string
    app2appLargeDescription: string
}

export const EnApp2AppLimitTranslations: App2AppLimitTranslations = {
    app2appStarterDescription: `Max Message Size (${APP2APP_LIMITS.fileSize.small}), Transfer Limit (${APP2APP_LIMITS.chunks.starter}/month)`,
    app2appSmallDescription: `Max Message Size (${APP2APP_LIMITS.fileSize.small}), Transfer Limit (${APP2APP_LIMITS.chunks.small}/month)`,
    app2appMediumDescription: `Max Message Size (${APP2APP_LIMITS.fileSize.medium}), Transfer Limit (${APP2APP_LIMITS.chunks.medium}/month)`,
    app2appLargeDescription: `Max Message Size (${APP2APP_LIMITS.fileSize.large}), Transfer Limit (Unlimited)`,
}

export interface App2AppDestination {
    uri: string
    name: string
    kaleido_managed: boolean
    setup_complete: boolean
}

export interface App2AppDestinationsData {
    app2appDestinations: App2AppDestination[]
}

export interface App2AppDestinationCertificateData {
    app2appDestinationCertificate: {
        certificate: string
    }
}

export interface App2AppClientsData {
    app2appClients: App2AppClient[]
}

export interface App2AppClient {
    id: string
    established: number
    subscriptions: string[]
    metrics: {
        sent: App2AppClientMetrics
        received: App2AppClientMetrics
    }
}

export interface App2AppClientMetrics {
    bytes: number
    chunks: number
    messages: number
}

export interface App2AppDestinationCertificateVars extends ServiceResourcesVars {
    destinationName: string
}

export interface CreateApp2AppDestinationVars extends ServiceResourcesVars {
    destination: CreateApp2AppDestinationInput
}

export interface CreateApp2AppDestinationInput {
    name: string
    kaleido_managed: boolean
}

export interface CreateApp2AppDestinationData {
    createApp2AppDestination: CreateApp2AppDestination
}

export interface CreateApp2AppDestination {
    result: string
    action: string
}

export interface DeleteApp2AppDestinationData {
    deleteApp2AppDestination: boolean
}

export interface DeleteApp2AppDestinationVars extends ServiceResourcesVars {
    destinationName: string
}

export interface ProduceApp2AppMessageVars extends ServiceResourcesVars {
    message: App2AppMessageInput
}

export interface App2AppMessageInput {
    headers: {
        from: string
        to: string
        signature?: string
    }
    key?: string
    content: string
}

export interface App2AppMetrics {
    id: string
    month: string
    year: string
    metrics: App2AppMetric[]
}

export interface App2AppMetric {
    time: string
    bytes_sent: number
    bytes_received: number
    chunks_sent: number
    chunks_received: number
    messages_sent: number
    messages_received: number
}

export interface App2AppMetricsVars extends EnvironmentResourcesVars{
    id: string,
    month: number,
    year: number
}

export interface AllApp2AppMetricsVars extends EnvironmentResourcesVars{
    ids: string[],
    month: number,
    year: number
}

export interface App2AppMetricsData {
    app2AppMetrics: App2AppMetrics
}

export interface AllApp2AppMetricsData {
    allApp2AppMetrics: App2AppMetrics[]
}

// QUERIES & SUBSCRIPTIONS

export const App2AppAddressBookQuery = gql`
query getApp2appAddressBookQuery($service_id: String!) {
    app2appAddressBook(service_id: $service_id) {
        membership
        destinations {
          name
          uri
        }
    }
}`;


export const App2appDestinationsQuery = gql`
    query getApp2appDestinationsQuery($service_id: String!) {
        app2appDestinations(service_id: $service_id) {
            uri
            name
            kaleido_managed
            setup_complete
        }
    }`;

export const App2appClientsQuery = gql`
    query getApp2appClients($service_id: String!) {
        app2appClients(service_id: $service_id) {
            id
            subscriptions
            established
            metrics {
                received {
                    bytes
                    messages
                    chunks
                }
                sent {
                    bytes
                    chunks
                    messages
                }
            }
        }
    }
`

export const App2appDestinationCertificateQuery = gql`
    query getApp2appDestinationCertificate($service_id: String!, $destinationName: String!) {
        app2appDestinationCertificate(service_id: $service_id, destinationName: $destinationName) {
            certificate
        }
    }`;

export const CreateApp2AppDestinationMutation = gql `
    mutation createApp2AppDestination($service_id: String!, $destination: CreateApp2AppDestinationInput!) {
        createApp2AppDestination(service_id: $service_id, destination: $destination ) {
            result
            action
        }
    }
`
export const DeleteApp2AppDestinationMutation = gql `
    mutation deleteApp2AppDestination($service_id: String!, $destinationName: String!) {
        deleteApp2AppDestination(service_id: $service_id, destinationName: $destinationName )
    }
`

export const ProduceApp2AppMessageMutation = gql `
    mutation produceApp2AppMessage($service_id: String!, $message: App2AppMessageInput!) {
        produceApp2AppMessage(service_id: $service_id, message: $message )
    }
`

export const App2AppMetricsFields = `
    fragment App2AppMetricsFields on App2AppMetrics {
        id
        month
        year
        metrics {
            time
            bytes_sent
            bytes_received
            chunks_sent
            chunks_received
            messages_sent
            messages_received
        }
    }
`;

export const AllApp2AppMetricsQuery = gql `
    ${App2AppMetricsFields}
    query allApp2AppMetrics($consortia_id: String!, $environment_id: String!, $ids: [String!]!, $month: Int!, $year: Int!) {
        allApp2AppMetrics(consortia_id: $consortia_id, environment_id: $environment_id, ids: $ids, month: $month, year: $year) {
            ...App2AppMetricsFields
        }
    }
`

export const App2AppMetricsQuery = gql `
    ${App2AppMetricsFields}
    query app2AppMetrics($consortia_id: String!, $environment_id: String!, $id: String!, $month: Int!, $year: Int!) {
        app2AppMetrics(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, month: $month, year: $year) {
            ...App2AppMetricsFields
        }
    }
`
export function MakeDeleteApp2AppDestinationMutationOptions(
    variables: DeleteApp2AppDestinationVars,
    overrideOptions?: MutationFunctionOptions<DeleteApp2AppDestinationData, DeleteApp2AppDestinationVars>): MutationFunctionOptions<DeleteApp2AppDestinationData, DeleteApp2AppDestinationVars> {
    return {
        ...{
            variables,
            update(cache, { data }) {
                if (data?.deleteApp2AppDestination) {
                    const { app2appDestinations } = cache.readQuery<App2AppDestinationsData>({ query: App2appDestinationsQuery, variables })!;
                    cache.writeQuery({
                        query: App2appDestinationsQuery,
                        data: { app2appDestinations: app2appDestinations.filter(destination => destination.name !== variables.destinationName) },
                        variables: { service_id: variables.service_id }
                    });
                }
            }
        }, ...overrideOptions
    }
}