import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts, { AxisTypeValue } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BaseChartOptions } from '../../utils/ChartOptions'

export interface BarChartSeries {
    name: string,
    values: [number, number][],
    color?: string,
    showZero?: boolean,
    valuesSuffix? : string
}

interface Props {
    title: string,
    series: BarChartSeries[],
    height?: string,
    xAxisLabel?: string, // defaults to Time
    xAxisType?: AxisTypeValue // defaults to datetime
    extraHCOptions?: Highcharts.Options,
    tooltipFormatter?: Highcharts.TooltipFormatterCallbackFunction | undefined,
    hideXAxis?: boolean,
    hideXAxisLabel?: boolean,
    hideYAxis?: boolean,
    fullWidth?: boolean
};

export const BarChart = ({ series, height, xAxisLabel, hideXAxisLabel, xAxisType = 'datetime', extraHCOptions, tooltipFormatter, hideXAxis, hideYAxis, fullWidth }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsLineChart', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`ChartsLineChart:${key}`, interpolate), [t])

    // SHARED OPTIONS
    const containerProps = { style: { maxWidth: fullWidth ? "100%" : "98%", height } }

    // CHART
    const options: Highcharts.Options = {
        xAxis: {
            visible: !hideXAxis,
            lineWidth: 0,
            tickWidth: 0,
            title: {
                text: hideXAxisLabel ? '' : (xAxisLabel || lt('time'))
            },
            type: xAxisType    
        },
        yAxis: {
            visible: !hideYAxis,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            labels: {
                enabled: false
            }
        },
        tooltip:{
            formatter: tooltipFormatter
        },
        series: series.map(s => {
            return {
                type: 'column',
                data: s.values,
                name: s.name,
                color: s.color,
                minPointLength: s.showZero ? 3 : 0,
                groupPadding: 0,
                borderWidth: 0,
                tooltip: {
                    footerFormat: s.name,
                    valueSuffix: s.valuesSuffix
                }
            }
        })
    }
    const mergedOptions = Highcharts.merge(BaseChartOptions, options, extraHCOptions);
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={mergedOptions}
            containerProps={containerProps}
        />
    ) 
};

interface translations {
    time: string
}
const enTranslations: translations = {
    time: 'Time'
}