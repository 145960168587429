import React from 'react';
import { Paper, Grid, FormControlLabel, Radio, Typography, makeStyles, Theme } from '@material-ui/core';

interface Props {
    disabled?: boolean
    selected: boolean
    value: string
    label: string
    description: string | JSX.Element
    setValue?: (v: string) => void
    icon?: JSX.Element
}

export const FormRadio = ({ disabled = false, selected, value, label, description, icon, setValue }: Props) => {

    const clickable = setValue ? true : false;
    const classes = useStyles({ selected, disabled, clickable });

    const setValueWrapper = (setValue && !disabled) ? (
        (e: React.MouseEvent) => {
            setValue(value);
        }
    ) : undefined;

    return (
        <Grid item>
            <Paper className={classes.radioPaper} elevation={0} onClick={setValueWrapper}>
                <Grid container justify="flex-end" alignItems="center">
                    <Grid item container direction="column" xs>
                        <Grid item>
                            <FormControlLabel checked={selected} value={value}
                                control={<Radio color="primary" disabled={disabled} />} {...{ label }} />
                        </Grid>
                        <Grid item className={classes.radioDescription}>
                            {
                                React.isValidElement(description) ? description : (
                                    <Typography variant="body2" color="textSecondary">
                                        {description}
                                    </Typography>
                                )
                            }
                        </Grid>
                    </Grid>
                    {icon && <Grid item>
                        {icon}
                    </Grid>}
                </Grid>
            </Paper>
        </Grid>
    );
};

interface RadioStyle {
    selected: boolean,
    disabled: boolean,
    clickable: boolean
}

const useStyles = makeStyles<Theme, RadioStyle>((theme) => ({
    radioPaper: ({disabled,selected,clickable}) => ({
        padding: theme.spacing(1, 2),
        backgroundColor: selected ? undefined : theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: selected ? theme.palette.primary.main : theme.palette.background.default,
        borderWidth: '3px',
        cursor: (clickable && !disabled) ? 'pointer' : undefined,
    }),
    radioDescription: props => ({
        paddingLeft: theme.spacing(4),
        opacity: props.disabled? '.6' : 1,
    })
}));