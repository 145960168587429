import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import AttachFileIcon from 'mdi-react/PaperclipIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateWrapper, ErrorSnackbarCatcher } from '../../components/DialogWrappers';
import { CreateStepProps } from '../../interfaces';
import { CompiledContract, SupportedEvmVersions, CompiledContractsQuery, CompiledContractsData, CompiledContractsVars, CompiledContractData, CompiledContractVars, CompiledContractQuery } from '../../models';
import { CompilerAndEvm } from './CompilerAndEvm';
import { UploadContractContractSelector } from './UploadContractContractSelector';
import { UploadContractFileSelector } from './UploadContractFileSelector';
import { useApolloClient } from "@apollo/client";

interface Props extends CreateStepProps {
    ownerAndDescriptionSetterPanel?: JSX.Element  // should only be set from Create Node Contract flow
    membershipId: string
    contract_id: string
    description: string
    loading: boolean
    saving?: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setMessage: React.Dispatch<React.SetStateAction<string>>
    preSave?: () => Promise<string>
    postSave: (compiledContractId: string) => Promise<void>
    isLastStep?: boolean
};

const createCompiledContract = async (o: {
    consortiumId: string;
    contractId: string;
    membershipId: string;
    description: string;
    solcVersion: string;
    evmVersion: string;
    fileToSearch: string;
    contractToCompile: string;
    file: File;
}) : Promise<CompiledContract> => {
    const formData = new FormData();
    formData.append('membership_id', o.membershipId);
    formData.append('description', o.description);
    formData.append('solc_version', o.solcVersion !== "0" ? o.solcVersion : '');
    formData.append('evm_version', o.evmVersion);
    formData.append('contract_filename', o.fileToSearch);
    formData.append('contract_name', o.contractToCompile);
    formData.append('file', o.file, o.file.type?.includes('zip') ? 'source.zip' : 'source.sol'); // must be the last thing we append

    const url = `/api/ui/v2/contractcompiler/consortia/${o.consortiumId}/contracts/${o.contractId}/compiled_contracts`
    const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
            'kaleido-custom-content-type': 'true'
        },
    })
    // if compiler call failed, show error
    if (!response?.ok) {
        const erra = await response.json()
        throw new Error(erra.errorMessage)
    }

    return await response.json();
}

export const PrecompiledUpload = ({ ownerAndDescriptionSetterPanel, membershipId, contract_id, description, cancelPath, loading, setLoading, 
        saving, setMessage, preSave, postSave, isLastStep }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractManagementPrecompiledUpload', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractManagementPrecompiledUpload:${key}`, interpolate)

    const { consortium_id } = useParams<any>();

    const client = useApolloClient();
    
    const [file, setFile] = useState<File | null>(null);
    const [listing, setListing] = useState(false);
    const [createCompiledContractLoading, setCreateCompiledContractLoading] = useState(false);
    const [contractsInFile, setContractsInFile] = useState<string[]>([]);
    const [contractToCompile, setContractToCompile] = useState('');
    const [filesInZip, setFilesInZip] = useState<string[]>([]);
    const [fileToSearch, setFileToSearch] = useState('');
    const [solcVersion, setSolcVersion] = useState('0');
    const [evmVersion, setEvmVersion] = useState<SupportedEvmVersions>("constantinople");

    // when the file changes, reset the form
    useEffect(() => {
        setContractsInFile([])
        setContractToCompile('')
        setFilesInZip([])
        setFileToSearch('')
    }, [file])

    const save = useCallback(async () => {
        (preSave ? preSave : () => Promise.resolve(''))()
            .then(newProjectId => {
                setCreateCompiledContractLoading(true);
                const contractId = newProjectId || contract_id!;
                createCompiledContract({
                    consortiumId: consortium_id!,
                    contractId,
                    membershipId,
                    description,
                    solcVersion,
                    evmVersion,
                    fileToSearch,
                    contractToCompile,
                    file: file!,
                }).then(async fetchResult => {
                    const newCompilationId = fetchResult?._id
                    if (newCompilationId) {
                        // Because we are using fetch, there's no GraphQL hook to use to update the query - so we need
                        // to perform a GraphQL read of the result, and then do a cache update on the collection.
                        const variables = {
                            consortia_id: consortium_id!,
                            contract_id: contractId
                        }
                        const {data} = await client.query<CompiledContractData, CompiledContractVars>({
                            query: CompiledContractQuery,
                            variables: {
                                ...variables,
                                id: fetchResult?._id
                            }
                        });
                        const { compiledContracts } = client.readQuery<CompiledContractsData, CompiledContractsVars>({ query: CompiledContractsQuery, variables })!;
                        if (data?.compiledContract && !compiledContracts.find(a => a._id === data.compiledContract._id)) {
                            client.writeQuery({
                                variables,
                                query: CompiledContractsQuery,
                                data: { compiledContracts: compiledContracts.concat(data.compiledContract) },
                            });
                        }
                        postSave(newCompilationId)
                    }
                }).catch(e => {
                    ErrorSnackbarCatcher(e, setMessage)
                }).then(() => setCreateCompiledContractLoading(false))
            })
            .catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
            })
    }, [contract_id, setMessage, postSave, preSave, consortium_id, membershipId, description, solcVersion, evmVersion, fileToSearch, contractToCompile, file, client]) 

    const callCompiler = useCallback(async () => {
        setListing(true)

        // call the compiler
        const formData = new FormData();
        formData.append('file', file!);
        const headers = {
            'kaleido-custom-content-type': 'true',
            'kld-file-size': `${file!.size}`,
            'kld-contract-name': contractToCompile,
            'kld-evm-version': evmVersion,
            'kld-solc-version': solcVersion !== "0" ? solcVersion : '',
            'kld-contract-source': fileToSearch
        }

        const response = await fetch(`/api/ui/v2/contractcompiler/contractinfo`, {
            method: 'POST',
            headers,
            body: formData,
            keepalive: false,
            cache: 'no-cache'
        });

        // if compiler call failed, show error
        if (!response?.ok) {
            const erra = response ? await response.json() : 'Failed to fetch'
            ErrorSnackbarCatcher(erra, setMessage)
            setListing(false)
            return
        } 
        
        // for compiler list calls, it either returns a list of files, or a list of contracts in a file
        if (!fileToSearch && file!.name.endsWith('.zip')) {
            setFilesInZip(await response.json())
        } else {
            setContractsInFile(await response.json())
        }
        setListing(false)
        return

    }, [contractToCompile, evmVersion, file, fileToSearch, solcVersion, setMessage]);

    // whenever the fileToSearch changes, trigger a new compiler list call to fetch the contracts inside fileToSearch
    useEffect(() => {
        if (fileToSearch && !contractToCompile) {
            callCompiler()
        }
    }, [fileToSearch, contractToCompile, callCompiler])

    // set states for conditional rendering
    const listingOrLoading = listing || loading
    const disabled = listingOrLoading || !contractToCompile || createCompiledContractLoading || !membershipId || !description

    const content = (
        <>
            {ownerAndDescriptionSetterPanel}

            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('selectFileHeader')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('selectFileDescription')}
                    </Typography>
                </Grid>
                <Grid item container alignItems="center" spacing={3}>
                    <Grid item  xs={3}>
                        <Typography noWrap>{file ? file.name : lt('noFileSelected')}</Typography>
                    </Grid>
                    <Grid item >
                        <AttachFileIcon />
                    </Grid>
                    <Grid item>
                        <input
                            accept=".sol,.zip"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={e => setFile(e?.target?.files?.length ? e.target.files[0] : null)}
                            />
                        <label htmlFor="raised-button-file">
                            <Button color="secondary" size="large" variant="contained" component="span" disabled={listingOrLoading}>
                                {lt('selectFile')}
                            </Button>
                        </label>
                    </Grid>
                    <Grid item>
                        {listing ? <CircularProgress /> :
                        <Button disabled={!file || listingOrLoading} color="primary" size="large" 
                            variant="contained" onClick={callCompiler}>
                            {lt('upload')}
                        </Button>
                        }
                    </Grid>
                </Grid>

                {filesInZip.length > 0 && 
                <UploadContractFileSelector disabled={listingOrLoading} {...{filesInZip}} {...{fileToSearch}} {...{setFileToSearch}} /> 
                }

                {contractsInFile.length > 0 &&
                <>
                    <UploadContractContractSelector disabled={listingOrLoading} {...{contractsInFile}} {...{contractToCompile}} {...{setContractToCompile}} />
                    <CompilerAndEvm disabled={listingOrLoading} {...{evmVersion}} {...{setEvmVersion}} {...{solcVersion}} {...{setSolcVersion}} />
                </>
                }
            </Grid>
        </>
    )

    return (
        <CreateWrapper {...{saving}} cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isLastStep={isLastStep} />
    )
};

interface translations {
    header: string,
    headerDescription: string,
    abi: string,
    bytecode: string,
    devDocs: string,
    selectFile: string,
    upload: string,
    noFileSelected: string,
    selectFileHeader: string,
    selectFileDescription: string,
    failedUpload: string,
    failedCompile: string

}
const enTranslations: translations = {
    header: 'Provide Source Files',
    headerDescription: 'Kaleido will automatically detect the contracts in your file(s) and prompt you for the desired contract to compile.',
    abi: 'ABI',
    bytecode: 'Bytecode',
    devDocs: 'Dev Docs',
    selectFile: 'Select file',
    upload: 'Upload',
    noFileSelected: 'No file selected',
    selectFileHeader: 'Select a file',
    selectFileDescription: 'The file can either be a single solidity file .SOL, or a zip archive for a project containing many files.',
    failedUpload: 'Failed upload',
    failedCompile: 'Failed compile'
}
