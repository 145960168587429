import { useMutation, useQuery } from '@apollo/client';
import { FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { AccountSelector } from '../../../components/AccountSelector/AccountSelector';
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../../components/DialogWrappers';
import { CreateStepProps, EnvironmentResourcesVars } from '../../../interfaces';
import { Account, FundAccountData, FundAccountMutation, FundAccountVars, FundingUnit, EtherBalanceOfData, EtherBalanceOfVars, EtherBalanceOfQuery, AccountsData, AccountsVars, AccountsQuery, FundType, ServicesQuery, ServicesData, ServicesEnum } from '../../../models';
import { isEthAddress } from '../../../utils/StringUtils';
import { Step1Help } from './Step1Help';

interface Props extends CreateStepProps {
    account?: Account
    address?: string
    tokenAddress?: string
};

export const Step1 = ({ account: preselectAccount, address: preselectAddress = '', cancelPath, tokenAddress: preselectTokenAddress = '' }: Props) => {
    const classes = useStyles();
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEtherPoolCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEtherPoolCreateStep1:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const [message, setMessage] = useState('')
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');

    const [account, setAccount] = useState<Account | undefined>(preselectAccount)
    const [customAddress, setCustomAddress] = useState(!preselectAccount ? preselectAddress : '');
    const [tokenAddress, setTokenAddress] = useState(preselectTokenAddress);
    const [amount, setAmount] = useState('')
    const [unit, setUnit] = useState<FundingUnit>('ether');
    const [fundType, setFundType] = useState<FundType>('eth');

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData, AccountsVars>(AccountsQuery, {
        variables: queryVariables,
        fetchPolicy: 'cache-first'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: queryVariables,
        fetchPolicy: 'cache-only'
    });

    const systemMonitorAccount = accounts.find(a => a.membership_id === 'sys--mon')

    const {
        refetch: refetchEtherBalanceOf,
        data: {
            etherBalanceOf
        } = { etherBalanceOf: '--' }
    } = useQuery<EtherBalanceOfData, EtherBalanceOfVars>(EtherBalanceOfQuery, {
        variables: {
            ...queryVariables,
            accountAddress: systemMonitorAccount?._id ?? ''
        },
        skip: !systemMonitorAccount,
        fetchPolicy: 'cache-and-network'
    });

    const isCustomAddressValid = isEthAddress(customAddress);
    const isTokenAddressValid = isEthAddress(tokenAddress);

    useEffect(() => {
        if (account) {
            setCustomAddress('')
        }
    }, [account])

    const [fundAccount, { loading: fundAccountLoading }] = 
        useMutation<FundAccountData, FundAccountVars>(FundAccountMutation);

    const isEthType = fundType === 'eth';
    const hasChainlink = services.some(s => s.service === ServicesEnum.chainlink);

    const save = () => {
        fundAccount({
            variables: {
                consortia_id: consortium_id,
                environment_id: environment_id,
                fund: {
                    type: fundType,
                    account: account?._id || customAddress,
                    amount: amount,
                    unit: isEthType ? unit : undefined,
                    tokenAddress: tokenAddress ? tokenAddress : undefined
                }
            }
        }).then(result => {
            if (result) {
                if (result.data?.fundAccount.status) {
                    setMessageType('success');
                    setMessage(lt('successfulFund', {account: account?._id || customAddress}));
                    refetchEtherBalanceOf()
                } else {
                    throw new Error(lt('failedFund'))
                }
            }
        }).catch(e => {
            setMessage('error')
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const makeFormControl = (s: FundingUnit | FundType, label: string, description: string, currentValue: FundingUnit | FundType = unit, disabled: boolean = false) => {
        return (
            <FormControlLabel checked={currentValue === s} disabled={fundAccountLoading || disabled}
                value={mapTypeValue(s)} 
                control={<Radio color="primary" />} 
                label={makeLabel(label, description)} />
        )
    }

    const makeLabel = (label: string, description: string) => 
        <div className={classes.radioLabel}>
            <Typography variant="body1">
                {label}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {description}
            </Typography>
        </div>

    const mapTypeValue = (s: FundingUnit | FundType) => s

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnit((event.target.value as FundingUnit));
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFundType(event.target.value as FundType);
    };


    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
                <FormControl component="fieldset" margin="none">
                    <RadioGroup value={fundType} onChange={handleTypeChange}>
                        {makeFormControl('eth', lt('fundEth'), lt('fundEtherDescription'), fundType)}
                        {makeFormControl('token', lt('fundLink'), lt('fundLinkDescription'), fundType, !hasChainlink)}
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item>
                <AccountSelector {...{account}} {...{setAccount}} fullWidth disabled={fundAccountLoading} customSelectAccountMessage={lt('selectAccount')} />
            </Grid>

            <Grid item container direction="column" alignContent="center">
                <Typography variant="body2">{lt('or')}</Typography>
            </Grid>

            <Grid item>
                <TextField disabled={account || fundAccountLoading ? true : false} 
                    error={(customAddress && !isCustomAddressValid) || undefined} 
                    helperText={customAddress && !isCustomAddressValid && lt('notValidHex')}
                    value={customAddress} 
                    onChange={event => setCustomAddress(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('customAddress')}
                    variant="outlined"
                />
            </Grid>

            { !isEthType && (
                <>
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            {lt('tokenAddress')}
                        </Typography>

                        <Typography variant="body2" color="textSecondary">
                            {lt('tokenAddressDescription', { remaining: etherBalanceOf })}
                        </Typography>

                        <TextField disabled={account || fundAccountLoading ? true : false} 
                            error={(tokenAddress && !isTokenAddressValid) || undefined} 
                            helperText={tokenAddress && !isTokenAddressValid && lt('notValidHex')}
                            value={tokenAddress} 
                            onChange={event =>  setTokenAddress(event.target.value)}
                            fullWidth
                            margin="normal"
                            label={lt('address')}
                            variant="outlined"
                        />
                    </Grid>
                </>
            )}
            

            <Grid item>
                <Typography variant="h5" gutterBottom={isEthType}>
                    {isEthType ? lt('selectAmountAndUnit') : lt('selectAmount')} 
                </Typography>

                {isEthType && (
                    <Typography variant="body2" color="textSecondary">
                        {lt('remainingEther', { remaining: etherBalanceOf })}
                    </Typography>
                )}
                
                <TextField required type="number" disabled={fundAccountLoading}
                    value={amount} 
                    onChange={event => setAmount(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('amount')}
                    variant="outlined"
                />

                {isEthType && (
                    <FormControl component="fieldset" margin="none">
                        <RadioGroup value={unit} onChange={handleChange}>
                            {makeFormControl('ether', lt('ether'), lt('etherDescription'))}
                            {makeFormControl('wei', lt('wei'), lt('weiDescription'))}
                        </RadioGroup>
                    </FormControl>
                )}
            </Grid>

        </>
    )

    const disabled = !(account?._id || customAddress) || !amount || (customAddress && !isCustomAddressValid ? true : false) || (!isEthType ? !tokenAddress || !isTokenAddressValid : false)

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} {...{messageType}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} saving={fundAccountLoading} isFirstStep isLastStep customNextButtonLabel={lt('fund')} />
            <Step1Help />
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioLabel: {
        paddingTop: theme.spacing(1)
    },
}));

interface translations {
    header: string
    headerDescription: string
    selectAccountToFund: string
    customAddress: string
    amount: string
    selectAmountAndUnit: string
    selectAmount: string
    ether: string
    etherDescription: string
    wei: string
    weiDescription: string
    or: string
    notValidHex: string
    successfulFund: string
    failedFund: string
    fund: string
    selectAccount: string
    remainingEther: string
    token: string
    fundLinkDescription: string
    fundEth: string
    fundLink: string
    tokenAddress: string
    tokenAddressDescription: string
    address: string
    fundEtherDescription: string
}
const enTranslations: translations = {
    address: 'Address',
    header: 'Ether Pool',
    headerDescription: "Fund an account from the environment's faucet. Can transfer ETH or LINK tokens owned by the faucet account.",
    selectAccountToFund: 'Select account to fund',
    customAddress: 'Custom address',
    amount: 'Amount',
    selectAmount: 'Select Amount',
    selectAmountAndUnit: 'Select amount and unit',
    ether: 'Ether',
    etherDescription: '(ETH) The most common denomination.',
    wei: 'Wei',
    weiDescription: 'The smallest denomination of ether. 1 ETH = 1x10^18 wei.',
    or: 'OR',
    notValidHex: 'Invalid address',
    successfulFund: 'Successfully funded {{account}}.',
    failedFund: 'Failed to fund account.',
    fund: 'Fund',
    selectAccount: 'Select account',
    remainingEther: 'Ether remaining: {{remaining}}',
    token: 'Token',
    fundLinkDescription: 'Fund LINK tokens from token contract. Chainlink runtime needs to be created',
    fundEth: 'Fund Ether',
    fundLink: 'Fund LINK',
    tokenAddress: 'Token Address',
    tokenAddressDescription: 'LINK token contract address',
    fundEtherDescription: 'Fund ETH from the environment faucet.'
}