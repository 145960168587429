import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from "react-router-dom";
import { Typography, Grid, CircularProgress, makeStyles, TextField } from "@material-ui/core";
import {CopyableSettings, CopyableSetting } from '../../../components/DisplaySettings'
import { DocumentTransferVars, DocumentTransferQuery, DocumentTransferData, DocExchangeTransferStatusTranslationsInterface, DocExchangeTransferStatusTranslations } from '../../../models';
import { GenericStatusIcon } from '../../../components/DisplayWrappers';

export const TransferInfo: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DocumentTransferInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DocumentTransferInfo:${key}`, interpolate)

    const { service_id, transfer_id, org_id, consortium_id, environment_id } = useParams<any>();
    const classes = useStyles();

    const {
        loading,
        data: {
            documentTransfer
        } = { documentTransfer: null }
    } = useQuery<DocumentTransferData, DocumentTransferVars>(DocumentTransferQuery, {
        variables: { 
            service_id: service_id!,
            transferId: transfer_id!
        }
    });

    if (loading) return <CircularProgress />
    if (!documentTransfer) return <Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/b2b/documentstore/${service_id}`} />

    const copyableList: CopyableSetting[] = [
        {
            title: lt('id'),
            displayValue: documentTransfer.id,
            disableCopy: true
        }
    ];

    if (documentTransfer.transferHash) {
        copyableList.push({
            title: lt('transferHash'),
            displayValue: documentTransfer.transferHash
        })
    }

    if (documentTransfer.hash) {
        copyableList.push({
            title: lt('hash'),
            displayValue: documentTransfer.hash
        })
    }

    copyableList.push({ 
        title: lt('status'),
        displayValue: (
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item className={classes.icon}>
                    <GenericStatusIcon iconProps={{fontSize: 'small'}} overrideOk={documentTransfer.status === 'received'} />
                </Grid>
                <Grid item>
                    <Typography variant="body2" noWrap>{lt(documentTransfer.status)}</Typography>
                </Grid>
            </Grid>
        ),
        disableCopy: true
    }, {
        title: lt('from'),
        displayValue: documentTransfer.from,
        disableCopy: true
    }, {
        title: lt('to'),
        displayValue: documentTransfer.to,
        disableCopy: true
    }, {
        title: lt('document'),
        displayValue: documentTransfer.document ?? '--',
        disableCopy: true
    }, {
        title: lt('createdAt'),
        displayValue: new Date(documentTransfer.timestamp).toLocaleString(),
        disableCopy: true
    });

    if (documentTransfer.senderSignature) {
        copyableList.push({
            title: lt('senderSignature'),
            displayValue: <TextField multiline value={documentTransfer.senderSignature} fullWidth variant="outlined" rows={3} inputProps={{className: classes.multiline}} />,
            copyableValue: documentTransfer.senderSignature
        })
    };

    if (documentTransfer.recipientSignature) {
        copyableList.push({
            title: lt('recipientSignature'),
            displayValue: <TextField multiline value={documentTransfer.recipientSignature} fullWidth variant="outlined" rows={3} inputProps={{className: classes.multiline}} />,
            copyableValue: documentTransfer.recipientSignature
        })
    }

    const content = (
        <>
            <Grid item>
                <CopyableSettings header={lt('transferInfo')} {...{copyableList}} />
            </Grid>
        </>
    )

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('trasnferDetail')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                {loading ? <CircularProgress /> : content }
            </Grid>
        </Grid>
    )
};

interface translations extends DocExchangeTransferStatusTranslationsInterface {
    trasnferDetail: string,
    transferInfo: string,
    createdAt: string,
    id: string,
    status: string,
    from: string,
    to: string,
    document: string
    hash: string
    transferHash: string
    recipientSignature: string
    senderSignature: string
}
const enTranslations: translations = {
    ...DocExchangeTransferStatusTranslations,
    hash: 'Document Hash',
    transferHash: 'Transfer Hash',
    recipientSignature: 'Recipient Signature',
    senderSignature: 'Sender Signature',
    trasnferDetail: 'Transfer Detail',
    transferInfo: 'Transfer Information',
    createdAt: 'Created at',
    id: 'Id',
    status: 'Status',
    from: 'From',
    to: 'to',
    document: 'Document'
};

const useStyles = makeStyles(theme => ({
    icon: {
        display: 'flex',
        alignSelf: 'center'
    },
    multiline: {
        color: theme.palette.text.secondary
    }
}))