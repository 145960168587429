import React, { useState, useEffect } from 'react';
import { cognitoController } from '../utils/cognitoController';
import { ResetPassword, SetNewPassword } from '../components';
import { useApolloClient } from "@apollo/client";
import { Region } from '../interfaces';

interface ForgotPasswordProps {
    regions: Region[]
    disableRecaptcha: boolean
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ regions, disableRecaptcha }) => {
    
    const [step, setStep] = useState<'sendEmail' | 'setNewPassword'>('sendEmail');
    const [email, setEmail] = useState('');
    const [verificationCode, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [mfaRequired, setMFARequired] = useState(false);
    const [mfaCode, setMFACode] = useState('');
    const [recaptcha, setRecaptcha] = useState<string | null>('');
    const apolloClient = useApolloClient();

    useEffect(() => {
        if(step === 'setNewPassword') {
            cognitoController.checkLogin(email).then(result => {
                setMFARequired(result.mfa?.type === 'totp');
            });
        }
    }, [step, email]);

    const handleSubmitEmail = async () => {
        let success = false

        setLoading(true);
        setErrorMessage('');
        try {
            await cognitoController.forgotPassword(
                email,
                regions,
                recaptcha ? recaptcha : '',
            );
            setStep('setNewPassword');
            success = true
        } catch (err) {
            setErrorMessage(err.message);
            success = false
        } finally {
            setLoading(false);
        }

        return success
    };

    const handleSetNewPassword = async () => {
        setLoading(true);
        try {
            await cognitoController.confirmPassword(
                email,
                verificationCode,
                newPassword,
                apolloClient,
                regions,
                mfaCode
            );
        } catch (err) {
            setErrorMessage(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (step === 'sendEmail') {
        return (
            <ResetPassword email={email}
                handleSubmitEmail={handleSubmitEmail}
                setEmail={setEmail}
                errorMessage={errorMessage}
                loading={loading}
                disableRecaptcha={disableRecaptcha}
                recaptcha={recaptcha}
                setRecaptcha={setRecaptcha}
            />
        );
    } else {
        return (
            <SetNewPassword email={email}
                code={verificationCode}
                setCode={setCode}
                mfaRequired={mfaRequired}
                mfaCode={mfaCode}
                setMFACode={setMFACode}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                handleSetNewPassword={handleSetNewPassword}
                errorMessage={errorMessage}
                loading={loading || !regions.length} />
        );
    }

};
