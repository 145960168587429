import React from 'react';
import clsx from 'clsx';
import { Badge, makeStyles } from '@material-ui/core';
import { BrandColors } from '../../utils/Colors';

interface Props {
    children: React.ReactNode;
    badgeContent: React.ReactNode;
    classes?: { badge: string };
    relativePosition?: boolean;
}

export const StyledBadge = ({
    classes = { badge: '' },
    children,
    badgeContent,
    relativePosition = false,
}: Props) => {
    const internalClasses = useStyles();
    return (
        <Badge
            data-test='pendingTicketsCount'
            badgeContent={badgeContent}
            classes={{
                badge: clsx(internalClasses.badge, classes.badge, {
                    [internalClasses.relativePosition]: relativePosition,
                }),
            }}
        >
            {children}
        </Badge>
    );
};

const useStyles = makeStyles((theme) => ({
    badge: {
        backgroundColor: BrandColors.magenta,
        color: 'white',
    },
    relativePosition: {
        position: 'relative',
    },
}));
