import React from "react";
import {
    Grid,
    Button,
    TextField,
    IconButton,
    Typography,
} from "@material-ui/core";
import PlusIcon from "mdi-react/PlusIcon";
import CloseIcon from "mdi-react/CloseIcon";
import { useTranslation } from "react-i18next";
import { isEthAddress } from "../../utils/StringUtils";
import { EnvironmentPrefundedAccounts } from "../../models";

interface Props {
    prefundedAccounts: EnvironmentPrefundedAccounts[];
    setPrefundedAccounts: React.Dispatch<
        React.SetStateAction<EnvironmentPrefundedAccounts[]>
    >;
}

export const PreFundedAccounts = ({
    prefundedAccounts,
    setPrefundedAccounts,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "PreFundedAccounts", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`PreFundedAccounts:${key}`, interpolate);

    const addInput = () => {
        setPrefundedAccounts((c) => [...c, { address: "", balance: "" }]);
    };

    const removeInput = (index: number) => {
        setPrefundedAccounts((c) => {
            let arr = [...c];
            arr.splice(index, 1);
            return arr;
        });
    };

    const updateAccountAddress = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        index: number
    ) => {
        e.persist();
        setPrefundedAccounts((c) => {
            let obj = [...c];
            obj[index].address = e.target.value;
            return obj;
        });
    };

    const updateAccountBalance = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        index: number
    ) => {
        e.persist();
        setPrefundedAccounts((c) => {
            let obj = [...c];
            obj[index].balance = e.target.value;
            return obj;
        });
    };

    const inputComponent = (index: number) => {
        const address = prefundedAccounts[index].address;
        const balance = prefundedAccounts[index].balance;
        return (
            <Grid
                container
                item
                direction="column"
                key={`cors-input-${index}`}
                spacing={1}
            >
                <Grid item>
                    <Typography variant="h6">
                        {lt("account", { index: index + 1 })}
                    </Typography>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            required
                            fullWidth
                            autoComplete="off"
                            label={lt("address")}
                            variant="outlined"
                            value={address}
                            onChange={(e) => updateAccountAddress(e, index)}
                            helperText={lt("validEthAddress")}
                            error={address ? !isEthAddress(address) : false}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            required
                            type="number"
                            fullWidth
                            autoComplete="off"
                            label={lt("balance")}
                            variant="outlined"
                            onChange={(e) => updateAccountBalance(e, index)}
                            value={balance}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => removeInput(index)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const AddInputButton = (
        <Grid item>
            <Button
                data-test="button_addRPCRule"
                variant="text"
                color="primary"
                startIcon={<PlusIcon />}
                onClick={() => addInput()}
            >
                {lt("addAccount")}
            </Button>
        </Grid>
    );

    return (
        <Grid item container spacing={1} direction="column">
            {prefundedAccounts.map((account, index) => inputComponent(index))}
            <Grid item>{AddInputButton}</Grid>
        </Grid>
    );
};

interface translations {
    addAccount: string;
    account: string;
    address: string;
    balance: string;
    validEthAddress: string;
    wei: string
}
const enTranslations: translations = {
    addAccount: "Add Account",
    account: "Account {{index}}",
    address: "Address",
    balance: "Balance (wei)",
    validEthAddress: "Must be a valid ETH address",
    wei: 'Wei'
};
