import { useApolloClient, useQuery } from '@apollo/client';
import {
    AppBar,
    Button,
    Divider,
    FormControl,
    Grid,
    Hidden,
    IconButton,
    Input,
    InputLabel,
    Toolbar,
    Typography,
    makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { SessionData } from '../../interfaces';
import { OrganizationsData, OrganizationsQuery } from '../../models';
import { SessionQuery } from '../../queries/Session';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { DisplayPopover } from '../DisplayWrappers';
import { CHANGE_PLAN_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, ORGS_PATH } from '../ManageOrgNav/ManageOrgNav';
import { HelpWrapper } from './HelpWrapper';
import { BLUE_DRAWER_WIDTH, DRAWER_WIDTH, KALEIDO_LOGO, TOOLBAR_HEIGHT } from './LayoutConstants';
import { OrgSwitcherPopover } from './OrgSwitcherPopover';



interface Props {
    handleOpenDrawer: (event: React.MouseEvent<HTMLButtonElement>) => void
    disableOrgSwitcher?: boolean
};

const AppHeader = ({ handleOpenDrawer, disableOrgSwitcher }: Props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppHeader', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppHeader:${key}`, interpolate)
    const [orgPopoverOpen, setOrgPopoverOpen] = useState(false);

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const client = useApolloClient();

    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    const {
        data: {
            organizations
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery);

    const currentOrg = organizations.find(org => org._id === org_id);
    const isOrgOwner = currentOrg?.owner === session.user_id;

    const hasConsortiumId = consortium_id ? true : false
    const alwaysShowLogo = !hasConsortiumId ? true : false

    const anchorRef = useRef<HTMLDivElement>(null);

    return (
        <AppBar position="absolute" elevation={0} className={clsx(classes.appBar, {
            [classes.appBarShift]: hasConsortiumId ? true : false,
        })}>
            <Toolbar className={clsx(classes.toolBar, {
                [classes.toolBarNoConsortium]: !hasConsortiumId ? true : false,
            })}>
                <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                    <Grid item container alignContent="center" spacing={0} className={clsx({
                        [classes.hideMdUp]: alwaysShowLogo ? false : true
                    })}>
                        <Grid item className={clsx(classes.menuIcon, {
                            [classes.hideMdUp]: true
                        })}>
                            <IconButton color="inherit" onClick={handleOpenDrawer}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Hidden smDown>
                            <Grid item className={clsx({
                                [classes.hideMdUp]: alwaysShowLogo ? false : true
                            })}>
                                <img className={classes.kaleidoLogo} src={process.env.PUBLIC_URL + '/Kaleido-Logo-Horizontal-Primary.svg'} alt="" />
                            </Grid>
                        </Hidden>
                    </Grid>

                    {hasConsortiumId &&
                        <Grid item className={classes.breadcrumbs}>
                            <Breadcrumbs />
                        </Grid>}

                    {isOrgOwner && currentOrg?.plan === 'starter' &&
                        <Hidden smDown>
                            <Grid item className={classes.uiSwitchContainer}>
                                <Button onClick={() => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`)} color="primary"
                                    size="small" variant="contained">{lt('upgrade')}</Button>
                            </Grid>
                        </Hidden>}

                    {org_id &&
                        <Grid item className={classes.uiSwitchContainer}>
                            <HelpWrapper />
                        </Grid>
                    }

                    {org_id &&
                        <Grid item >
                            <FormControl variant="standard" className={classes.orgsDropdown} disabled={disableOrgSwitcher}>
                                <InputLabel>
                                    <Typography variant="overline">{lt('organization')}</Typography>
                                </InputLabel>
                                <Input className={classes.orgValue}
                                    data-test='globalOrgSelector'
                                    type="button"
                                    ref={anchorRef}
                                    onClick={disableOrgSwitcher ? undefined : () => setOrgPopoverOpen(true)}
                                    disableUnderline={true}
                                    endAdornment={<MenuDownIcon />}
                                    inputProps={{
                                        style: { cursor: disableOrgSwitcher ? undefined : 'pointer', textAlign: 'left' }
                                    }}
                                    value={organizations.find(organization => organization._id === org_id)?.name} />
                                <DisplayPopover
                                    arrowColor="transparent"
                                    placement="bottom"
                                    anchorRef={anchorRef}
                                    popoverOpen={orgPopoverOpen}
                                    setPopoverOpen={setOrgPopoverOpen} >
                                    <OrgSwitcherPopover organizations={organizations} setPopoverOpen={setOrgPopoverOpen} />
                                </DisplayPopover>
                            </FormControl>
                        </Grid>}
                </Grid>
            </Toolbar>
            <Divider />
        </AppBar>
    )
};

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: theme.palette.background.default
    },
    toolBar: {
        height: TOOLBAR_HEIGHT,
    },
    toolBarNoConsortium: {
        [theme.breakpoints.up('md')]: {
            marginLeft: BLUE_DRAWER_WIDTH,
            paddingLeft: theme.spacing(0),
        }
    },
    menuIcon: {
        paddingTop: '3px',
        color: theme.palette.primary.main
    },
    kaleidoLogo: KALEIDO_LOGO,
    uiSwitchContainer: {
        marginLeft: 'auto',
        marginRight: 20,
        whiteSpace: 'nowrap'
    },
    orgsDropdown: {
        width: '200px'
    },
    appBarShift: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${DRAWER_WIDTH + BLUE_DRAWER_WIDTH}px)`,
            marginLeft: DRAWER_WIDTH + BLUE_DRAWER_WIDTH,
        }
    },
    hideMdUp: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    breadcrumbs: {
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        flexGrow: 1
    },
    orgValue: {
        cursor: 'pointer',
        textAlign: 'left'
    }
}))

interface translations {
    organization: string
    upgrade: string
}
const enTranslations = {
    organization: 'Organization',
    upgrade: 'Upgrade'
}

export { AppHeader };
