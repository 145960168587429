import { gql } from '@apollo/client';
import { Region } from '../interfaces';

export interface ZoneRegion extends Region {
  disabled: boolean,
  comingSoon: boolean,
}

export interface StaticConfig {
  zoneRegions: ZoneRegion[],
  awsMarketplaceURL: string,
  marketingSiteURL: string,
  testFeaturesEnabled: boolean,
  stripeKey: string
  disableRecaptcha: boolean
  disableHubspot: boolean
}

export interface StaticConfigData {
  staticConfig: StaticConfig
}

export const StaticConfigFields = ` 
    fragment StaticConfigFields on StaticConfig {
      zoneRegions {
        code,
        name,
        cloud,
        region,
        location,
        geo,
        disabled,
        comingSoon,
        flag,
        host,
        cplane,
        explorer,
        cognitoUserPool,
        cognitoClientID,
        redirectLoginTo
      },
      awsMarketplaceURL,
      marketingSiteURL,
      testFeaturesEnabled,
      stripeKey
      disableRecaptcha
      disableHubspot
    }`

export const StaticConfigQuery = gql`
    ${StaticConfigFields}
    query getStaticConfig {
        staticConfig {
            ...StaticConfigFields
        }
    }`;    

