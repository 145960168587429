import React from 'react';
import { makeStyles } from "@material-ui/core";
import { EnvironmentState, NodeState, ServiceState, DocExchangeTransferStatus } from '../../models'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import ErrorOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon'

interface Props {
    state?: EnvironmentState | NodeState | ServiceState | DocExchangeTransferStatus
    overrideOk?: boolean
    iconProps?: SvgIconProps | undefined
};

const GenericStatusIcon = ({ state, overrideOk, iconProps }: Props) => {
    const classes = useStyles();

    const size = iconProps?.fontSize ?? "large"

    const ok = <CheckCircleIcon fontSize={size} className={classes.checkGreen} />
    const notOk = <ErrorOutlineIcon fontSize={size} className={classes.checkWarning} />

    if (state) {
        switch (state) {
            case 'live':
            case 'started':
                return ok
            default:
                return notOk
        }
    }

    if (overrideOk) return ok
    return notOk    
};

const useStyles = makeStyles(() => ({
  checkGreen: {
      color: '#00CD79'
  },
  checkWarning: {
      color: '#FA9A43'
  }
}));

export { GenericStatusIcon };