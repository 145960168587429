import { gql } from '@apollo/client';
import { EnvironmentResourcesVars } from '../interfaces'
import { RuntimeSize } from './nodes'

export const RuntimeStatisticsFields = ` 
    fragment RuntimeStatisticsFields on RuntimeStatistics {
        id
        runtime
        type
        size
        data {
            t
            c
            m
            s
        }
    }`

export const EthereumStatisticsFields = `
    fragment EthereumStatisticsFields on EthereumStatistics {
        id
        data {
            t
            p
            tp
            tq
            h
        }
    }`

//EnvironmentPeerCounts
export interface EnvironmentPeerCountsData {
    environmentPeerCounts: EnvironmentPeerCount[]
}

export interface EnvironmentPeerCount {
    id: string
    ok: boolean
    p2p_peers: number
}

export const EnvironmentPeerCountsQuery = gql`
    query getEnvironmentPeerCounts($consortia_id: String!, $environment_id: String!) {
        environmentPeerCounts(consortia_id: $consortia_id, environment_id: $environment_id) {
            id
            ok
            p2p_peers
        }
    }`;

//EnvironmentResourceUsage

export interface EnvironmentResourceUsageVars extends EnvironmentResourcesVars{
    hours?: number
    interval?: number
}

export interface EnvironmentResourceUsageData {
    environmentResourceUsage: RuntimeStatistics[]
}

export const EnvironmentResourceUsageQuery = gql`
    ${RuntimeStatisticsFields}
    query getEnvironmentResourceUsage($consortia_id: String!, $environment_id: String!, $hours: Int, $interval: Int) {
        environmentResourceUsage(consortia_id: $consortia_id, environment_id: $environment_id, hours: $hours, interval: $interval) {
            ...RuntimeStatisticsFields
        }
    }`;
    
//BlocksForChart

export interface BlockForChart {
    timestamp: string
    id: string
    miner: string
    x: number
    y: number
    success: number
    failure: number
}

export interface BlocksForChartVars extends EnvironmentResourcesVars{}

export interface BlocksForChartData {
    blocksForChart: {
        blocks: BlockForChart[],
        total: number
        successTransactions: number,
        failedTransactions: number
    }
}

export const BlockForChartFields = ` 
    fragment BlockForChartFields on BlockForChart {
        timestamp
        id
        miner
        x
        y
        success
        failure
    }`

export const BlocksForChartQuery = gql`
    ${BlockForChartFields}
    query getBlocksForChart($consortia_id: String!, $environment_id: String!) {
        blocksForChart(consortia_id: $consortia_id, environment_id: $environment_id) {
            blocks {
                ...BlockForChartFields
            }
            total
            successTransactions
            failedTransactions
        }
    }`;    

//TxAndBlockStatsForChart

export interface TxStatsForChart {
    data: {
        failed: {
            x: string,
            y: number
        }[],
        success: {
            x: string,
            y: number
        }[],
        total: {
            x: string,
            y: number
        }[]
    },
    failed: number,
    success: number,
    total: number
}

export interface BlockStatsForChart {
    data: {
        count: {
            x: string,
            y: number
        }[],
        delay: {
            x: string,
            y: number
        }[],
        frequency: {
            x: string,
            y: number
        }[]
    },
    total: number
    time: number
    frequencyMax: number
    frequencyMin: number
    frequencyAvg: number
}

export interface TxOverallKpi {
    frequencyAvg: number
}

export interface TxAndBlockStatsForChartVars extends EnvironmentResourcesVars{
    hours: number
}

export interface TxAndBlockStatsForChart {
    allTx: TxStatsForChart
    publicTx: TxStatsForChart
    privateTx: TxStatsForChart
    blocks: BlockStatsForChart
    tx: TxOverallKpi
}

export interface TxAndBlockStatsForChartData {
    txAndBlockStatsForChart: TxAndBlockStatsForChart
}

export const BlockStatsForChartFields = ` 
    fragment BlockStatsForChartFields on BlockStatsForChart {
        data {
            count {
                x
                y
            }
            delay {
                x
                y
            }
            frequency {
                x
                y
            }
        }
        total
        time
        frequencyMax
        frequencyMin
        frequencyAvg
    }`

export const TxStatsForChartFields = ` 
    fragment TxStatsForChartFields on TxStatsForChart {
        data {
            failed {
                x
                y
            }
            success {
                x
                y
            }            
            total {
                x
                y
            }
        }
        failed
        success
        total
    }`

export const TxOverallKpiFields = ` 
    fragment TxOverallKpiFields on TxAllKpiForChart {
        frequencyAvg
    }`

export const TxAndBlockStatsForChartQuery = gql`
    ${TxStatsForChartFields}
    ${BlockStatsForChartFields}
    ${TxOverallKpiFields}
    query getTxAndBlockStatsForChart($consortia_id: String!, $environment_id: String!, $hours: Int!) {
        txAndBlockStatsForChart(consortia_id: $consortia_id, environment_id: $environment_id, hours: $hours) {
            allTx {
                ...TxStatsForChartFields
            }
            publicTx {
                ...TxStatsForChartFields
            }
            privateTx {
                ...TxStatsForChartFields
            }
            blocks {
                ...BlockStatsForChartFields
            }
            tx {
                ...TxOverallKpiFields
            }
        }
    }`; 

// Node Metrics TxPoolStatus

export interface NodeTxPoolStatus {
    pending: number,
    queued: number
}

export interface NodeTxPoolStatusVars extends EnvironmentResourcesVars{
    node_id: string
}

export interface NodeTxPoolStatusData {
    txPoolStatus: NodeTxPoolStatus
}

export const NodeTxPoolStatusFields = ` 
    fragment NodeTxPoolStatusFields on TxPoolStatus {
        pending
        queued
    }`

export const NodeTxPoolStatusQuery = gql`
    ${NodeTxPoolStatusFields}
    query getTxPoolStatus($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        txPoolStatus(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id) {
            ...NodeTxPoolStatusFields
        }
    }`;

// Runtime Metrics Statistics

export interface RuntimeStatistic {
    t: string,
    c: number,
    m: number,
    s: number
}

export interface RuntimeStatistics {
    id: string
    runtime: string
    type: string
    size: RuntimeSize
    data: RuntimeStatistic[]
}

export interface NodeStatisticsData {
    nodeStatistics: RuntimeStatistics
}

export interface NodeStatisticsVars extends EnvironmentResourcesVars{
    node_id: string,
    hours?: number,
    interval?: number
}

export const NodeStatisticsQuery = gql`
    ${RuntimeStatisticsFields}
    query getNodeStatistics($consortia_id: String!, $environment_id: String!, $node_id: String!, $hours: Int, $interval: Int) {
        nodeStatistics(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, hours: $hours, interval: $interval) {
            ...RuntimeStatisticsFields
        }
    }`;

// Ethereum Metrics Statistics

export interface EthereumStatistic {
    t: string,
    p: number,
    tp: number,
    tq: number,
    h: number
}

export interface EthereumStatistics {
    id: string
    data: EthereumStatistic[]
}

export interface EthereumStatisticsData {
    environmentEthStats: EthereumStatistics[]
}

export interface EthereumStatisticsVars extends EnvironmentResourcesVars{
    hours?: number,
    interval?: number
}

export const EthereumStatisticsQuery = gql`
    ${EthereumStatisticsFields}
    query getEnvironmentEthStats($consortia_id: String!, $environment_id: String!, $hours: Int, $interval: Int) {
        environmentEthStats(consortia_id: $consortia_id, environment_id: $environment_id, hours: $hours, interval: $interval) {
            ...EthereumStatisticsFields
        }
    }`;