import React from 'react';
import { useParams } from "react-router-dom";
import { SystemDetails } from './SystemDetails';
import { MembershipDetails } from './MembershipDetails'
import { SYSTEM_MEMBERSHIP } from '../../../components/MainNav/SideNavs/AddressBook';

export const MemberDetails: React.FC = () => {
    const { membership_id } = useParams<any>();
    
    if (membership_id === SYSTEM_MEMBERSHIP) return <SystemDetails />
    return <MembershipDetails />
};
