import { useQuery } from '@apollo/client';
import { Grid, Typography, TextField, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CreateWrapper } from '../../components/DialogWrappers';
import { MembershipAutocomplete } from '../../components/FormControls/MembershipAutocomplete';
import { CREATE_NODES_PATH } from '../../components/NodeNav/NodePicker';
import { CreateStepProps, EnvironmentResourcesVars, AutocompleteIdName } from '../../interfaces';
import { ServicesData, ServicesQuery } from '../../models';
import { FIREFLY_BASE_PATH, FIREFLY_SERVICE_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { Step1Help } from './Step1Help';
import { FireflyTokenPluginNames } from '../../models/firefly';

interface CreateNodeStep1Props extends CreateStepProps {
    isFabric: boolean
    fireflyNodeName: string
    setFireflyNodeName: React.Dispatch<React.SetStateAction<string>>
    tokensPluginName: FireflyTokenPluginNames
    setTokensPluginName: React.Dispatch<React.SetStateAction<FireflyTokenPluginNames>>
    membershipInfo: AutocompleteIdName,
    setMembershipInfo: React.Dispatch<React.SetStateAction<AutocompleteIdName>>
};

// In this step, user will enter a firefly node name and select (or create) a membership
export const CreateNodeStep1: React.FC<CreateNodeStep1Props> = ({isFabric, fireflyNodeName, setFireflyNodeName, 
    tokensPluginName, setTokensPluginName, membershipInfo, setMembershipInfo, cancelPath}) => {
    const history = useHistory();
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateFireflyNodeStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateFireflyNodeStep1:${key}`, interpolate);

    const [disablePluginName, setDisablePluginName] = useState(false);

    const {
        data: {
            services: allServices
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },        
        fetchPolicy: 'cache-only'
    });

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/${CREATE_NODES_PATH}/2`, {
            fireflyNodeName,
            membershipInfo
        })
    }

    // this will check if the membership already has a firefly node associated, in which case an error will display
    const memberHasFirefly = () => { 
        const fireflyServices = allServices.filter(n => n.membership.isMine && n.service === "firefly-os" && n.membership_id === membershipInfo.id);
        if(fireflyServices.length !== 0) {
            return true;
        }
        return false;
    }

    // this will check if a firefly node already exists with the same name, in which case an error will display
    const fireflyNodeNameExists = () => { 
        const fireflyServices = allServices.filter(n => n.membership.isMine && n.service === "firefly-os" && n.name === fireflyNodeName);
        if(fireflyServices.length !== 0) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        const firstService = allServices.find(s => s.service === 'firefly-os')
        if (firstService?.details.tokens_plugin_name) {
            setDisablePluginName(true)
            setTokensPluginName(firstService?.details.tokens_plugin_name)
        }
    }, [allServices, setTokensPluginName])

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <MembershipAutocomplete 
                    membershipInfo={membershipInfo} 
                    setMembershipInfo={setMembershipInfo} 
                    helperText={memberHasFirefly() ? lt('existingNodeWithMembership') : ''} 
                    error={memberHasFirefly()} 
                    setWidth={400}
                    dataTest={"membershipName"}
                />
            </Grid>
            <Grid item>
                <TextField
                    data-test="nodeName"
                    value={fireflyNodeName} 
                    onChange={event => {
                        setFireflyNodeName(event.target.value);
                    }}
                    style={{ width: 400 }}
                    label={lt('name')}
                    variant="outlined"
                    error={fireflyNodeNameExists()}
                    helperText={fireflyNodeNameExists() ? lt('duplicateFireflyNodeName') : ''}
                />
            </Grid>
            {!isFabric && 
            <Grid item>
                <TextField select style={{ width: 400 }} disabled={disablePluginName}
                    label={lt('tokensPluginName')} 
                    value={tokensPluginName} 
                    variant="outlined" 
                    onChange={e => setTokensPluginName(e.target.value as unknown as FireflyTokenPluginNames)}>
                        <MenuItem value={'erc20_erc721'}>{lt('erc20_erc721')}</MenuItem>
                </TextField>
            </Grid>
            }
        </>
    )

    const disabled = !fireflyNodeName || fireflyNodeNameExists() || memberHasFirefly() || !(membershipInfo.id || membershipInfo.name);

    return(
        <>
            <CreateWrapper cancelPath={cancelPath} {...{content}} onNext={save} isFirstStep {...{disabled}} />
            <Step1Help />
        </>
    )

}

interface translations {
    header: string,
    headerDescription: string,
    name: string,
    existingNodeWithMembership: string,
    duplicateFireflyNodeName: string
    tokensPluginName: string
    erc20_erc721: string
    erc1155: string
}
const enTranslations: translations = {
    header: 'Set Up Your FireFly SuperNode',
    headerDescription: 'Select the membership and a name for your new FireFly SuperNode.',
    name: 'Name of this node',
    existingNodeWithMembership: 'A FireFly node already exists for this membership',
    duplicateFireflyNodeName: 'A FireFly node with this name already exists',
    tokensPluginName: 'Tokens Plugin Name',
    erc20_erc721: 'ERC20_ERC721',
    erc1155: 'ERC1155'
}