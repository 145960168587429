import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { NETWORK_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { KaleidoTemplateStep1 } from './KaleidoTemplateStep1';

export const CreateKaleidoTemplate: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationKaleidoTemplate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationKaleidoTemplate:${key}`, interpolate)

    const { pathname } = useLocation()
    const { org_id, consortium_id, contract_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${contract_id}`

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep >= 1) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: '', component: <KaleidoTemplateStep1 cancelPath={cancelPath} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} hideStepper toolbarHeader={lt('createCompilation')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createCompilation: string,
}
const enTranslations: translations = {
    createCompilation: 'Create a Version',
}