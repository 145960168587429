import { useMutation } from '@apollo/client';
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { CreateWrapper, ErrorSnackbarCatcher } from '../../components/DialogWrappers';
import { MembershipSelector } from '../../components/FormControls/MembershipSelector';
import { CreateStepProps } from '../../interfaces';
import { CreateCompiledContractData, CreateCompiledContractMutation, CreateCompiledContractVars, EnvironmentStatus, MakeCompiledContractCreateMutationOptions, SupportedEvmVersions } from '../../models';
import { AlertBanner } from '../Banners/AlertBanner';
import { CompilerAndEvm } from './CompilerAndEvm';

// SHARED
type TOKEN_TYPE = 'ERC20' | 'ERC721' | 'FIREFLYERC20' | 'FIREFLYERC721';
type TEMPLATE_INFO = { githubUrl: string, endpoint: string, tokenType?: TOKEN_TYPE }

// KALEIDO TEMPLATE
export type KALEIDO_TEMPLATE_CONTRACTS = 'KaleidoERC20' | 'KaleidoERC20Burnable' | 'KaleidoERC20Mintable' | 
    'KaleidoERC20MintableBurnable' | 'KaleidoERC721Mintable' | 'KaleidoERC721MintableBurnable' | 
    'FireflyERC20WithData' | 'FireflyERC721WithData'
const kaleidoTemplatesGithubPath = 'https://github.com/kaleido-io/kaleido-contract-templates/blob/master/contracts'
export const KALEIDO_TEMPLATE_CONTRACT_OPTIONS: Map<KALEIDO_TEMPLATE_CONTRACTS, TEMPLATE_INFO> = new Map([
    ['FireflyERC20WithData', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC20/FireflyERC20WithData.sol`, endpoint: 'fireflyerc20', tokenType: 'FIREFLYERC20' } ],
    ['KaleidoERC20', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC20/KaleidoERC20.sol`, endpoint: 'kaleidoerc20', tokenType: 'ERC20' } ],
    ['KaleidoERC20Burnable', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC20/KaleidoERC20Burnable.sol`, endpoint: 'kaleidoerc20b', tokenType: 'ERC20' } ],
    ['KaleidoERC20Mintable', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC20/KaleidoERC20Mintable.sol`, endpoint: 'kaleidoerc20m', tokenType: 'ERC20' } ],
    ['KaleidoERC20MintableBurnable', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC20/KaleidoERC20MintableBurnable.sol`, endpoint: 'kaleidoerc20mb', tokenType: 'ERC20' } ],
    ['FireflyERC721WithData', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC721/FireflyERC721WithData.sol`, endpoint: 'fireflyerc721', tokenType: 'FIREFLYERC721' } ],
    ['KaleidoERC721Mintable', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC721/KaleidoERC721Mintable.sol`, endpoint: 'kaleidoerc721m', tokenType: 'ERC721' } ],
    ['KaleidoERC721MintableBurnable', { githubUrl: `${kaleidoTemplatesGithubPath}/token/ERC721/KaleidoERC721MintableBurnable.sol`, endpoint: 'kaleidoerc721mb', tokenType: 'ERC721' } ],
])

// SHARED
const ALL_TEMPLATE_CONTRACT_OPTIONS = new Map([...KALEIDO_TEMPLATE_CONTRACT_OPTIONS])
type ALL_TEMPLATE_CONTRACTS = KALEIDO_TEMPLATE_CONTRACTS 

interface Props extends CreateStepProps {
    templateType: 'kaleido'
    contract_id: string
    membershipId?: string
    description?: string
    template?: ALL_TEMPLATE_CONTRACTS
    setDescription?: React.Dispatch<React.SetStateAction<string>> // optional prop if you want to use this forms description to call back and set your own
    setMembershipId?: React.Dispatch<React.SetStateAction<string>> // optional prop if you want to use this forms membershipId to call back and set your own
    setMessage: React.Dispatch<React.SetStateAction<string>>
    loading?: boolean,
    saving?: boolean,
    preSave?: () => Promise<string>
    postSave: (compiledContractId: string) => Promise<void>
    isFirstStep?: boolean
    isLastStep?: boolean
    envStatus: EnvironmentStatus | null
}

export const ContractTemplate = (
    { 
        contract_id,
        setMessage,
        loading,
        saving,
        template: defaultTemplate,
        setDescription: setPreSaveDescription,
        description: defaultDescription, 
        membershipId: defaultMembershipId, 
        setMembershipId: setPreSaveMembershipId, 
        cancelPath,
        preSave,
        postSave,
        isFirstStep,
        isLastStep,
        envStatus,
    }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractManagementKaleidoTemplate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractManagementKaleidoTemplate:${key}`, interpolate)

    const { consortium_id } = useParams<any>();

    const [template, setTemplate] = useState<ALL_TEMPLATE_CONTRACTS | ''>(defaultTemplate || '');
    const [description, setDescription] = useState(defaultDescription || '');
    const [membershipId, setMembershipId] = useState(defaultMembershipId || '');
    const [githubUrl, setGithubUrl] = useState('');
    const [solcVersion, setSolcVersion] = useState('0');
    const [evmVersion, setEvmVersion] = useState<SupportedEvmVersions>("constantinople"); //only constantinople is supported

    const [createCompiledContract, { loading: createCompiledContractLoading }] = 
        useMutation<CreateCompiledContractData, CreateCompiledContractVars>(CreateCompiledContractMutation)

    const needHardFork = envStatus?.upgrade?.optional_hardfork_eips?.includes('constantinopleBlock') ? true : false;

    useEffect(() => {
        if (setPreSaveMembershipId) setPreSaveMembershipId(membershipId)
    }, [membershipId, setPreSaveMembershipId])

    // call the parents setDescription which is used as the promoted endpoint for kaleido templates only
    useEffect(() => {
        if (setPreSaveDescription && template) {
            setPreSaveDescription(ALL_TEMPLATE_CONTRACT_OPTIONS.get(template)!.endpoint)
        }
    }, [description, setPreSaveDescription, template])

    // defauilt the description to the template name
    useEffect(() => {
        if (template) {
            setDescription(template)
            setGithubUrl(ALL_TEMPLATE_CONTRACT_OPTIONS.get(template)!.githubUrl)
        }
    }, [template])

    const save = async () => {
        const empty = () => new Promise<string>(function(resolve) {
            resolve('');
         });
        (preSave ? preSave : empty)()
            .then(newProjectId => {
                createCompiledContract(MakeCompiledContractCreateMutationOptions({
                    consortia_id: consortium_id!,
                    contract_id: newProjectId || contract_id!,
                    compiledContract: {
                        membership_id: membershipId,
                        description,
                        contract_url: githubUrl,
                        solc_version: solcVersion !== "0" ? solcVersion : '',
                        evm_version: evmVersion
                    }
                })).then(result => {
                    if (result) {
                        const newCompilationId = result.data?.createCompiledContract?._id
                        if (newCompilationId) {
                            postSave(newCompilationId)
                        }
                    }
                }).catch(e => {
                    ErrorSnackbarCatcher(e, setMessage)
                })
            })
            .catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
            })
    }

    const disabled = loading || createCompiledContractLoading || !membershipId || !template || needHardFork;

    const content = (
        <>
            {needHardFork &&
                <Grid item>
                    <AlertBanner title={lt('hardFork')} description={lt('hardForkDescription')} linkButton={{
                        text: lt('documentation'),
                        onClick: () => window.open('https://help.kaleido.io/hc/en-us/articles/12551021819405-How-do-I-select-a-hard-fork-of-my-environment-')                
                    }} />
                </Grid>
            }

            <Grid item>
                <Typography variant="h5">
                    {lt('tokenHeader')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('tokenDescription')}
                </Typography>
            </Grid>

            <Grid item >
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            <Grid item>
                <TextField data-test="selectKaleidoTemplate" required select fullWidth label={lt('selectTemplate')} value={template} 
                    variant="outlined" onChange={e => setTemplate(e.target.value as ALL_TEMPLATE_CONTRACTS)}>
                    {Array.from(KALEIDO_TEMPLATE_CONTRACT_OPTIONS.keys()).map(c => (
                        <MenuItem key={c} value={c}>{lt(c)}</MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item>
                <TextField disabled
                    required
                    multiline
                    rows={2}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField disabled data-test="textField_githubUrl"
                    required
                    value={githubUrl} 
                    onChange={event => setGithubUrl(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('githubUrl')}
                    variant="outlined"
                />
            </Grid>

            <CompilerAndEvm disabled {...{evmVersion}} {...{setEvmVersion}} {...{solcVersion}} {...{setSolcVersion}} />
        </>
    )

    return (
        <>
            <CreateWrapper {...{saving}} cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep={isFirstStep} isLastStep={isLastStep} />
        </>
    )
};

interface translations {
    description: string,
    githubDetails: string,
    githubDetailsDescription: string,
    github: string,
    githubDescription: string,
    githubUrl: string,
    precompiled: string,
    precompiledDescription: string,
    contractToCompile: string,
    githubAccessToken: string,
    selectTemplate: string,
    KaleidoERC20: string
    KaleidoERC20Burnable: string
    KaleidoERC20Mintable: string 
    KaleidoERC20MintableBurnable: string
    KaleidoERC721Mintable: string
    KaleidoERC721MintableBurnable: string
    FireflyERC20WithData: string
    FireflyERC721WithData: string
    hardFork: string
    hardForkDescription: string
    documentation: string
    tokenHeader: string
    tokenDescription: string
}
const enTranslations: translations = {
    description: 'Description',
    githubDetails: 'Enter Github information',
    githubDetailsDescription: 'Kaleido requires the specific Github URL to your Solidity file. This URL must end with ".SOL". Gist\'s and raw formats are not currently supported.',
    github: 'Use Github',
    githubDescription: 'My project source code is stored in a Github repository (supports Truffle projects!)',
    githubUrl: 'Github URL to solidity file',
    precompiled: 'Bring my own source',
    precompiledDescription: 'I will upload my source code files and/or compile the code myself',
    contractToCompile: 'Contract to be compiled',
    githubAccessToken: 'Github personal access token',
    selectTemplate: 'Select Template',
    KaleidoERC20: 'KaleidoERC20',
    KaleidoERC20Burnable: 'KaleidoERC20Burnable',
    KaleidoERC20Mintable: 'KaleidoERC20Mintable',
    KaleidoERC20MintableBurnable: 'KaleidoERC20MintableBurnable',
    KaleidoERC721Mintable: 'KaleidoERC721Mintable',
    KaleidoERC721MintableBurnable: 'KaleidoERC721MintableBurnable',
    FireflyERC20WithData: 'FireflyERC20WithData',
    FireflyERC721WithData: 'FireflyERC721WithData',
    hardFork: 'Upgrade',
    hardForkDescription: 'Please apply the Constantinople hard fork before using the factory',
    documentation: 'Documentation',
    tokenHeader: 'Enter Version Details',
    tokenDescription: 'Select the Membership who will own this version and give this specific version a description.'
}

