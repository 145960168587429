import { useQuery } from '@apollo/client';
import { Grid, Typography } from "@material-ui/core";
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import AccountMultipleOutlineIcon from 'mdi-react/AccountMultipleOutlineIcon';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from "react-router-dom";
import { CopyableSetting, CopyableSettings, EditableSettingItem, EditableSettings } from '../../components/DisplaySettings';
import { DisplayCard } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { CHANNELS_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { ConsortiumResourcesVars, EnvironmentResourcesVars } from '../../interfaces';
import { EnServicesTranslations, ChannelsData, ChannelsQuery, ServicesTranslations, NodesData, NodesQuery, ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models';
import { ChannelCard } from './ChannelCard';

export const AddressBookChannelDetails: React.FC = () => {
    const { t, i18n } = useTranslation();

    i18n.addResourceBundle('en', 'AddressBookChannelDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookChannelDetails:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, channel_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            nodes : nodesCollection
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            channels
        } = { channels: [] }
    } = useQuery<ChannelsData>(ChannelsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const { 
        data: { 
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });


    const channel = channels.find(channel => channel._id === channel_id);
    if(!channel) {  
        return <Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}`}></Redirect>;
    }
    let members = channel.members;
    let membershipNames = consortiumMemberships.filter(membership => members.includes(membership._id)).map(m => m.org_name);
    if (members.includes('sys--mon')) {
        membershipNames.push("System");
    }
    const nodes = nodesCollection.filter((n) => (members).includes(n.membership_id));
    const ordererNodes = nodes.filter(n => n.role === "orderer");
    const peerNodes = nodes.filter(n => n.role === "peer");
    let channelDetailsCopyableList: CopyableSetting[] = [];
    if (channel) {
        channelDetailsCopyableList = [
            { title: lt('name'), displayValue: channel.name },
            { title: lt('channelId'), displayValue: channel._id }
        ]
        if (channel.description !== '') {
            channelDetailsCopyableList.push({ title: lt('description'), displayValue: channel.description ?? '-' });
        }
        channelDetailsCopyableList.push(
            { title: lt('members'), displayValue: membershipNames.join(", ") },
            { title: lt('type'), displayValue: lt('channel') },
            { title: lt('status'), displayValue: <ResourceStateChip state={channel.state} /> },
            { title: lt('created'), displayValue: new Date(channel.created_at).toLocaleString() },
            { title: lt('lastUpdate'), displayValue: channel.updated_at ? new Date(channel.updated_at).toLocaleString() : '--' },
        );
           
    }
    const docsList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('channels'),
            value: lt('documentation'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#user-created-channels')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('memberships'),
            value: lt('documentation'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#adding-members-to-an-existing-channel')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('chaincodes'),
            value: lt('documentation'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#chaincode-management')
        }
    ]

    

    const actionsList: EditableSettingItem[] = channel?.policies?.map((policy) => {
            const action = {
                icon: <AccountMultipleOutlineIcon />,
                title: policy.name,
                action: ()=> {},
                hiddenButton: true,
                copyableSettings: [
                    { title: lt('path'), displayValue: policy?.path ?? '' },
                    { title: lt('rule'), displayValue: policy?.rule ?? '' }
                ]
            }
            return action;
        });

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('channel')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <ChannelCard ordererNodes={ordererNodes.length} 
                    peerNodes={peerNodes.length} 
                    members={!channel ? consortiumMemberships.length : channel?.members ? channel?.members.length : 1 } 
                    chaincodes={Object.keys(channel.contracts).length}/>
            </Grid>

            <Grid item container spacing={3}>
                <Grid item md={12} lg={8}>
                    <CopyableSettings header={lt('information')} copyableList={channelDetailsCopyableList} />
                </Grid>
                <Grid container item lg={4}>
                    <DisplayCard header={lt('documentation')} itemList={docsList}/>
                </Grid>
            </Grid>
            {channel.policies && 
                <Grid item container spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                        <EditableSettings header={lt('policies')} {...{actionsList}} copyableSettingsBottomPadding />
                    </Grid>
                </Grid>
            }
        </Grid>
    )
};

interface translations extends ServicesTranslations {
    channel: string
    channels: string
    chaincodes: string
    documentation: string
    type: string
    size: string
    name: string
    description: string
    region: string
    channelId: string
    status: string
    created: string
    lastUpdate: string
    manageChannel: string
    information: string
    memberships: string
    apiDocs: string
    members: string
    defaultDescription: string
    policies: string
    path: string
    rule: string
}

const enTranslations: translations = {
    ...EnServicesTranslations,
    channel: 'Channel',
    channels: 'Channels',
    chaincodes: 'Chaincodes',
    documentation: 'Documentation',
    type: 'Type',
    size: 'Size',
    name: 'Name',
    description: 'Description',
    region: 'Region',
    channelId: 'Channel ID',
    status: 'Status',
    created: 'Created',
    lastUpdate: 'Last update',
    manageChannel: 'Manage Channel',
    information: 'Information',
    memberships: 'Members',
    apiDocs: 'API Docs',
    members: 'Members',
    policies: 'Policies',
    path: 'Path',
    rule: 'Rule',
    defaultDescription: 'A default channel exists for every Fabric environment.'
}