import { Grid, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { CreateStepProps } from '../../interfaces';
import { Step3Help } from './Step3Help';
import { CHANNELS_PATH } from "../../components/MainNav/SideNavs/AddressBook";
import { CREATE_CHANNELS_PATH } from "../AddressBook/Channels";

interface Props extends CreateStepProps {
    name: string,
    membershipId: string,
    description: string,
    selectedMembers?: string[]
};

export const Step3 = ({ 
        name, membershipId, description, selectedMembers, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateChannelCreateStep3', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateChannelCreateStep3:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const [message, setMessage] = useState("");

    const disabled = false;
    const save = async () => {
        try {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${CREATE_CHANNELS_PATH}/4`, {
                name,
                membershipId,
                description,
                members: selectedMembers
            })
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    }

    const content = (
        <>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('header')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('headerDescription')}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} />
            <Step3Help />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    name: string,
    add: string,
    membershipWarning: string,
    signer: string,
}
const enTranslations: translations = {
    header: 'Nodes',
    headerDescription: 'These are the nodes that will be added automatically to the channel. Coming soon.',
    name: 'Membership Name',
    add: 'Add',
    membershipWarning: 'For reliable operation of your blockchain, we strongly recommend having additional memberships and nodes.',
    signer: 'Signer'
}