import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect, useHistory } from "react-router-dom";
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { NODE_CONTRACTS_PATH } from '../../components/NodeNav/NodeNav';
import { useQuery, useSubscription } from '@apollo/client';
import { ContractProjectsData, ContractProjectsQuery, ContractProjectsSubscription, MakeContractProjectsSubscriptionOptions, Account } from '../../models';
import { ConsortiumResourcesVars } from '../../interfaces';

export const KALEIDO_CONTRACT_TEMPLATES = 'Kaleido Contract Templates'
export const CREATE_NEW_PROJECT = 'createNewProject'

export type SourceCodeType = 'token' | 'github' | 'upload' | 'paste' | ''

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeContractsCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeContractsCreate:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, node_id, step } = useParams<any>();

    type locationState = { 
        contractAddress: string, 
        sourceCodeType: SourceCodeType, 
        contractId: string, 
        compiledContractId: string, 
        newProjectName: string, 
        deployerAccount?: Account,
        cancelPath: string
    }
    const history = useHistory<locationState>()

    const { 
        location: { 
            state: {
                contractAddress,
                sourceCodeType: createSourceCodeType,
                contractId: createContractId,
                compiledContractId: createCompiledContractId,
                newProjectName: createNewProjectName,
                deployerAccount,
                cancelPath: referrerCancelPath
            } = { contractAddress: '', sourceCodeType: '', contractId: '', compiledContractId: '', newProjectName: '', deployerAccount: undefined, cancelPath: '' } 
        } 
    } = history

    // setup the contract projects query and subscription
    // we need the query to establish the records in the cache
    useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-and-network'
    });
    useSubscription(ContractProjectsSubscription, MakeContractProjectsSubscriptionOptions({ consortia_id: consortium_id! }));

    const invalidStep = step !== "1" && step !== "2" && step !== "3"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    const nodePath = `/nodes/${node_id}`;
    const cancelPath = referrerCancelPath || `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}${nodePath}`
    const [sourceCodeType, setSourceCodeType] = useState<SourceCodeType>((step && step >= "1") ? createSourceCodeType as SourceCodeType : '')
    const [contractId, setContractId] = useState((step && step >= "2") ? createContractId : CREATE_NEW_PROJECT);
    const [compiledContractId, setCompiledContractId] = useState((step && step >= "3") ? createCompiledContractId : '');
    const [newProjectName, setNewProjectName] = useState((step && step >= "2") ? createNewProjectName : '');

    const redirect = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_CONTRACTS_PATH}/create/1`

    if ((invalidStep || 
        (createStep >= 1 && (!sourceCodeType || (!contractId && !newProjectName))) || 
        (createStep >= 2 && (!contractId || !compiledContractId)))) {
        return <Redirect to={redirect} />
    }

    const stepComponents = [
        { 
            step: lt('importType'), 
            component: <Step1 {...{cancelPath}} {...{sourceCodeType}} {...{setSourceCodeType}} {...{contractAddress}}
                {...{contractId}} {...{setContractId}} disableOtherSourceCodeTypeOptions={createSourceCodeType ? true : false}
                {...{newProjectName}} {...{setNewProjectName}} {...{deployerAccount}} /> 
        },
        { 
            step: lt('createGatewayAPI'), 
            component: <Step2 {...{cancelPath}} {...{sourceCodeType}}  {...{setSourceCodeType}} {...{compiledContractId}} {...{setCompiledContractId}}
                contractId={contractId === CREATE_NEW_PROJECT ? '' : contractId} 
                {...{setContractId}} {...{contractAddress}}
                newProjectName={contractId === CREATE_NEW_PROJECT ? newProjectName : ''} {...{setNewProjectName}}  {...{deployerAccount}} /> 
        },
        { 
            step: lt('deployInstance'), 
            component: <Step3 {...{sourceCodeType}} {...{contractAddress}} {...{cancelPath}} {...{contractId}} {...{compiledContractId}} {...{deployerAccount}} /> 
        }
    ]

    return <FullScreenCreate {...{cancelPath}} toolbarHeader={lt('newContract')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    newContract: string
    importType: string
    createGatewayAPI: string
    deployInstance: string
}
const enTranslations: translations = {
    newContract: 'New Contract',
    importType: 'Import Type',
    createGatewayAPI: 'Create Smart Contract',
    deployInstance: 'Deploy Contract Instance',
}