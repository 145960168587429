import React from 'react';
import { Grid, Typography, IconButton, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import { AttachmentType } from './ContactUs';

interface Props {
    attachment: AttachmentType;
    onDelete: (id: string, token?: string) => void;
}

export const Attachment = ({ attachment, onDelete }: Props) => {
    const handleDelete = () => {
        onDelete(attachment.id);
    };
    const classes = useStyles();
    return (
        <Grid
            className={classes.container}
            container
            wrap="nowrap"
            alignItems="center"
        >
            <Grid container direction="row" alignItems="center">
                <PaperclipIcon />
                <Typography className={classes.fileName}>
                    {attachment.file.name}
                </Typography>
            </Grid>
            <Grid>
                {attachment.isUploading ? (
                    <div className={classes.circularProgressWrapper}>
                        <CircularProgress size={20} />
                    </div>
                ) : (
                    <IconButton aria-label="delete" onClick={handleDelete}>
                        <TrashCanOutlineIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    fileName: {
        marginLeft: theme.spacing(1),
    },
    circularProgressWrapper: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
    },
}));
