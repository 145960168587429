import {
    CLOUDCONFIG_BASE_PATH,
    ATTACH_CLOUDCONFIG_PATH,
    CREATE_CLOUDCONFIG_PATH,
    ConfigTypesUrl,
} from "../../components/MainNav/SideNavs/CloudConfigs";
import {
    SECURITY_BAF_PATH,
    ATTACH_BAF_PATH,
    CREATE_BAF_PATH,
} from "../../components/MainNav/SideNavs/Security";
import { BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import { SECURITY_BASE_PATH } from "../../components/MainNav/SideNavs/Security";
import { FullNodeConfigurationDetails, GetSupportedConfigDetailsKeys } from "../../models/configs";
import { Environment, Node } from "../../models";
import { AlertDarkColors } from "../../utils/Colors";
import React from "react";
import CheckIcon from "mdi-react/CheckIcon";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import { CopyableSetting } from "../../components/DisplaySettings";

export function getPathParams(
    pathname: string,
    params: {
        consortium_id?: string;
        environment_id?: string;
        config_id?: string;
        org_id?: string;
        config_type?: string;
    }
) {
    const {
        consortium_id,
        environment_id,
        config_id,
        org_id,
        config_type: cloudConfigType,
    } = params;

    const cloudConfigBasePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}`;
    const nodeConfigBasePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes`;
    const securityBasePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}`;
    let configType: ConfigTypesUrl;
    let basePath, attachConfigPath, createConfigPath, cancelPath: string;
    if (pathname.startsWith(cloudConfigBasePath)) {
        basePath = cloudConfigBasePath;
        configType = cloudConfigType as ConfigTypesUrl;
        attachConfigPath = `${configType}/${ATTACH_CLOUDCONFIG_PATH}`;
        createConfigPath = `${CREATE_CLOUDCONFIG_PATH}`;
        cancelPath = config_id
            ? `${basePath}/${configType}/${config_id}`
            : `${basePath}/${configType}`;
    } else if (pathname.startsWith(nodeConfigBasePath)) {
        basePath = nodeConfigBasePath;
        configType = BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH;
        attachConfigPath = `${configType}/attach`;
        createConfigPath = 'create';
        cancelPath = config_id
            ? `${basePath}/${configType}/${config_id}`
            : `${basePath}/${configType}`;
    } else {
        // assume == `${appsBasePath}/${SECURITY_BAF_PATH}` until another node_config else if comes in
        basePath = securityBasePath;
        configType = "baf";
        attachConfigPath = `${SECURITY_BAF_PATH}/${ATTACH_BAF_PATH}`;
        createConfigPath = `${SECURITY_BAF_PATH}/${CREATE_BAF_PATH}`;
        cancelPath = config_id
            ? `${basePath}/${SECURITY_BAF_PATH}/${config_id}`
            : `${basePath}/${SECURITY_BAF_PATH}`;
    }
    
    return {
        consortium_id,
        environment_id,
        org_id,
        config_id,
        configType: configType!,
        basePath: basePath!,
        attachConfigPath: attachConfigPath!,
        createConfigPath: createConfigPath!,
        cancelPath: cancelPath!,
    };
}

export const buildProtocolConfigPropertiesList = (lt: (key: string) => string, envOrNode: Environment | Node | null, data?: FullNodeConfigurationDetails) => {
    let arr: CopyableSetting[] = [];
    if (!data) return arr
    
    for (let [key, value] of Object.entries(data)) {
        if (key in GetSupportedConfigDetailsKeys(envOrNode)) {
            if (typeof value === "boolean") {
                arr.push({
                    title: lt(key),
                    displayValue: value ? (
                        <CheckIcon color={AlertDarkColors.green} />
                    ) : (
                        <CloseCircleIcon color={AlertDarkColors.red} />
                    ),
                    disableCopy: true,
                });
            } else if (typeof value === "object" && key === 'restgw_kafka_topic_conf') {
                for (let [key1, val1] of Object.entries(value)) {
                    if (val1 !== null && key1 !== '__typename') {
                        arr.push({
                            title: lt(key1),
                            displayValue: val1 as string,
                            disableCopy: true,
                        });
                    }
                }
            } else {
                arr.push({
                    title: lt(key),
                    displayValue: value,
                    disableCopy: true,
                });
            }
        }
    }
    return arr;
};