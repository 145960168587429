import { Grid, TextField, Typography } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipSelector } from '../../components/FormControls/MembershipSelector';
import { RegionSelector } from '../../components/FormControls/RegionSelector';
import { ConsortiumZone } from '../../models';

interface Props {
    isMultiRegion: boolean,
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    consortiumZoneId: string,
    consortiumZones: ConsortiumZone[],
    setConsortiumZoneId: React.Dispatch<React.SetStateAction<string>>
    header: string
    description?: string
};

export const RuntimeCreationStep1Content = ({ 
        isMultiRegion, name, setName, membershipId, setMembershipId, 
        consortiumZoneId, consortiumZones, setConsortiumZoneId, 
        header, description }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'RuntimeCreationStep1Content', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`RuntimeCreationStep1Content:${key}`, interpolate)

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {header}
                </Typography>
                {description && (
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {description}
                    </Typography>
                )}
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />

                <TextField
                    data-test="runtimeName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            { isMultiRegion && 
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('selectDeploymentRegion')}
                </Typography>
                <RegionSelector zoneId={consortiumZoneId} setZoneId={setConsortiumZoneId} {...{consortiumZones}} />
            </Grid>
            }
        </>
    )

    return content
};

interface translations {
    name: string,
    selectDeploymentRegion: string
}
const enTranslations: translations = {
    name: 'Name of this runtime',
    selectDeploymentRegion: 'Select deployment region'
}