import { gql } from '@apollo/client';



export interface ServiceConfigRevision {
    id: string
    rev: string
}

export interface ServiceConfigVersions {
    storage?: ServiceConfigRevision
}

export interface ChainlinkStatus extends ServiceStatus {
    address?: string
    ethBalance?: string
    linkContractAddress?: string
    oracleContractAddress?: string
    password?: string
    linkBalance?: string
    username?: string
}

export interface FireflyRegistered {
    registered?: boolean
}

interface FireflyStatus {
    node: FireflyRegistered
    org: FireflyRegistered
    plugins?: JSON
}

export interface ServiceStatus {
    urls: {
        http: string
        ws: string
    }
    status: string
    release: string
    firefly?: FireflyStatus
    fireflyAPIStatus?: { status: 'unavailable' | 'available', message: string }
    configVersions?: ServiceConfigVersions
    runtime?: { started: boolean }
}

export interface ServiceStatusData {
    serviceStatus: ServiceStatus
}

export interface ChainlinkStatusData {
    chainlinkStatus: ChainlinkStatus
}

interface TetherNetwork {
    total_tx_count: number,
    mined_tx_count: number,
    success_tx_count: number
    total_gas_used: number
    success_gas_used: number
    total_eth_used_wei: number
    last_success_timestamp: number
    last_failure_timestamp: number
    last_mined_failure_timestamp: number
    last_success_block: number
    last_mined_failure_block: number
    last_gas_price: number
    last_tx_price_wei: number
}

interface TxStats {
    goerli?: TetherNetwork
    mainnet?: TetherNetwork
}

interface TetherStatus {
    urls: JSON
    status: string
    release: string
    configVersions: ServiceConfigVersions
    environment_id: string
    account: string
    active_network: 'mainnet' | 'goerli'
    relay_interval: number
    is_activated: boolean
    arbitrator_address?: string
    tx_stats: TxStats
}

export interface TetherStatusData {
    tetherStatus: TetherStatus
}

export const ChainlinkStatusFields =  ` 
fragment ChainlinkStatusFields on ChainlinkStatus {
    urls
    status
    release
    configVersions {
        storage {
            id
            rev
        }
    }
    address
    ethBalance
    linkContractAddress
    oracleContractAddress
    password
    linkBalance
    username
}`

export const TetherStatusFields =  ` 
fragment TetherStatusFields on TetherStatus {
    urls
    status
    release
    arbitrator_address
    configVersions {
        storage {
            id
            rev
        }
    }
    account
    active_network
    relay_interval
    is_activated
    tx_stats {
        goerli {
            total_tx_count
            mined_tx_count
            success_tx_count
            total_gas_used
            success_gas_used
            total_eth_used_wei
            last_success_timestamp
            last_failure_timestamp
            last_mined_failure_timestamp
            last_success_block
            last_mined_failure_block
            last_gas_price
            last_tx_price_wei
        }
        mainnet {
            total_tx_count
            mined_tx_count
            success_tx_count
            total_gas_used
            success_gas_used
            total_eth_used_wei
            last_success_timestamp
            last_failure_timestamp
            last_mined_failure_timestamp
            last_success_block
            last_mined_failure_block
            last_gas_price
            last_tx_price_wei
        }
    }
}`

export const ServiceStatusFields = ` 
    fragment ServiceStatusFields on ServiceStatus {
        urls
        status
        release
        firefly {
            node {
                registered
            }
            org {
                registered
            }
            plugins
        }
        fireflyAPIStatus
        configVersions {
            storage {
                id
                rev
            }
        }
        runtime
    }`

export const ServiceStatusQuery = gql`
    ${ServiceStatusFields}
    query getServiceStatus($consortia_id: String!, $environment_id: String!, $id: String!) {
        serviceStatus(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...ServiceStatusFields
        }
    }`;

export const ChainlinkStatusQuery = gql`
    ${ChainlinkStatusFields}
    query getChainlinkStatus($consortia_id: String!, $environment_id: String!, $id: String!) {
        chainlinkStatus(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...ChainlinkStatusFields
        }
    }`;

export const TetherStatusQuery = gql`
    ${TetherStatusFields}
    query getTetherStatus($consortia_id: String!, $environment_id: String!, $id: String!) {
        tetherStatus(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...TetherStatusFields
        }
    }`;

