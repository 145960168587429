import React, { useState } from 'react';
import { FormDialog } from '../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';

interface Props {
    fromAccount: string | undefined,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const MintDialog = ({open, setOpen, fromAccount}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenMintDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenMintDialog:${key}`, interpolate);

    const [amount, setAmount] = useState('');
    const [to, setTo] = useState('');

    const onSave = async () => console.log('saved');

    const content = (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={10}>
                <TextField variant="outlined" fullWidth label={lt('to')} value={to} onChange={e => setTo(e.target.value)}  />
            </Grid>
            <Grid item xs={10}>
                <TextField variant="outlined" fullWidth label={lt('amount')} value={amount} onChange={e => setAmount(e.target.value)}  />
            </Grid>
        </Grid>
    )



    return <FormDialog header={lt('header')} {...{onSave}} {...{open}} {...{setOpen}} controlsWrapper={content} saveText={lt('mint')} />
}

interface translations {
    header: string,
    to: string,
    amount: string
    mint: string
}
const enTranslations: translations = {
    header: 'Mint Token',
    to: 'Target Address (Optional)',
    amount: 'Amount to Mint',
    mint: 'Mint'
}