export const BrandColors = {
    blurple: '#462de0',
    purple: '#a73dd5',
    magenta: '#ff396a',
    yellow: '#ffca00',
    cyan: '#abe6ff',
    lightGrey: '#F2F2F2',
    gray86: '#DBDBDB',
};

export const AlertLightColors = {
    red: '#FFE9E9',
    orange: '#FFF2DE',
    blue: '#EAF3FF',
    green: '#EFFFED',
};

export const AlertDarkColors = {
    red: '#EF0606',
    orange: '#FE9705',
    blue: '#0569FF',
    green: '#1AC80C',
};
