import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const Step2Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateManageWalletStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateManageWalletStep2Help:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-NodeSummary.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/kaleido-services/wallet/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Managed Wallet Types',
    helpDescription: 'You can choose between two different types of Managed Wallets:',
    helpDesc1: 'Kaleido Wallet allows the generation and management of individual ethereum accounts, that can be used for signing transactions by other linked Kaleido services.',
    helpDesc2: 'HD Wallets is a collection of individual key tree wallets that provides a deterministic derivation of Ethereum account addresses and their corresponding private signing keys from a single seed phrase.',
}