import { XAxisOptions } from 'highcharts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrandColors } from '../../../utils/Colors';
import { BarChart, BarChartSeries } from '../BarChart';

interface BytesMetrics {
    metrics: {
        time: string
        bytes_sent: number
        bytes_received: number
    }[]
}

interface Props {
    xAxis: XAxisOptions,
    runtimesForChart: BytesMetrics[],
    chart: 'sent' | 'received',
    height?: string
};

export const Bytes = ({ xAxis, runtimesForChart, chart, height }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsDocumentStoreBytes', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChartsDocumentStoreBytes:${key}`, interpolate)

    const addToList = (list: [number, number][], time: number, metric: number) => {
        const found = list.find(s => s[0] === time)
        if (found) {
            found[1] += metric
        } else {
            list.push([time, metric])
        }
    }

    const makeSeries = () => {
        let list: [number, number][] = []

        runtimesForChart.forEach(r => {            
            r.metrics.filter(m => new Date(m.time) < new Date()).forEach(d => {
                const time = Date.parse(d.time)
                if (chart === 'sent') {
                    addToList(list, time, d.bytes_sent / 1000)
                } else if (chart === 'received') {
                    addToList(list, time, d.bytes_received / 1000)
                }
            })
        })

        let series: BarChartSeries[] = []
        series.push({
            name: lt(chart),
            values: list,
            color: BrandColors.blurple,
            showZero: true
        })

        return series
    }

    const extraHCOptions: Highcharts.Options = {
        yAxis: {
            title: {
                text: lt('bytes')
            },
            labels: {
                enabled: true,
            }
        },
        legend:{
            enabled: true,
        },
        xAxis
    }

    return <BarChart title={lt(chart)} series={makeSeries()} height={height} {...{extraHCOptions}} hideXAxisLabel />
};

interface translations {
    sent: string,
    received: string,
    bytes: string
}
const enTranslations: translations = {
    sent: 'KB Sent',
    received: 'KB Received',
    bytes: 'Kilobytes (KB)'
}