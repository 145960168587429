import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-StreamDetail.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/kaleido-services/event-streams"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'What is an event stream?',
    helpDescription: 'In order to leverage the Kaleido Event Streams Service, your smart contract requires an event interface which is used to specify contract parameters for indexing. The event payload can be consumed by a client side app or streaming service that is subscribed to the contract. Specify your own custom event destinations - currently, we support the following types of event streams:',
    helpDesc1: 'Webhook: we will deliver events to an API endpoint of your choosing, via a HTTP/HTTPS connection configured with your own headers and security',
    helpDesc2: 'WebSocket: events are pushed real-time to your application, over a long-live WebSocket connection established from within your private network',
    finalInfo: 'Event Streams are configured on a per-node basis. You will need to select the node that will host the event stream.'
}