import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const KaleidoTemplateHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractMangementKaleidoTemplateHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractMangementKaleidoTemplateHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('finalInfo')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    finalInfo: string,
}
const enTranslations: translations = {
    helpHeader: 'Import from Kaleido Templates',
    helpDescription: 'Kaleido offers a number of template contracts you can use for your own projects. Currently we are featuring the following templates to choose from:',
    helpDesc1: 'ERC20 Tokens - Create an ERC20 token contract. Be sure to specify if you want your contract to be mintable, burnable, or both. (Constantinople EVM only)',
    helpDesc2: 'ERC721 Tokens - Create an ERC721 token contract. Be sure to specify if you want your contract to be burnable. (Constantinople EVM only)',
    finalInfo: 'Kaleido Template Contracts are stored in a public Github repo owned by Kaleido, so you can always check out the source code the template is using.'
}