import { useApolloClient, useMutation } from "@apollo/client";
import { CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { PayWallBanner } from "../../components/PayWall/PayWallBanner";
import { CreateStepProps } from '../../interfaces';
import { Invitation, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, UpdateInvitationData, UpdateInvitationMutation, UpdateInvitationVars } from "../../models";
import { VIEW_INVITATION_PATH } from "./Invitation";
import { Step1Help } from './Step1Help';

interface Props extends CreateStepProps {
    invitation?: Invitation,
};

export const Step1 = ({ cancelPath, invitation }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ViewInvitationInvitationsStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ViewInvitationInvitationsStep1:${key}`, interpolate)

    const history = useHistory();
    const { org_id } = useParams<any>();
    const [message, setMessage] = useState('')
    const [orgName, setOrgName] = useState<string | undefined>()

    const [updateInvitation, { loading: updateInvitationLoading }] = useMutation<UpdateInvitationData, UpdateInvitationVars>(UpdateInvitationMutation)

    useEffect(() => {
        if (orgName === undefined && invitation) {
            setOrgName(invitation.org_name)
        }
    }, [invitation, orgName])

    // direct read from cache
    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsInvitations = PlanSupports.acceptingInvitations(organization);

    const updateInvitationWrapper = async (state: 'accepted' | 'declined') => (
        await updateInvitation({
            variables: {
                id: invitation?._id ?? '',
                consortia_id: invitation?.consortia_id ?? '',
                invitation: {
                    org_id,
                    org_name: orgName,
                    state
                }
            }
        })
    )

    const save = async () => {
        await updateInvitationWrapper('accepted')
            .then(() => {
                history.push(`/orgs/${org_id}/${VIEW_INVITATION_PATH}/${invitation?._id}/2`);
            })
            .catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
            });
    }

    const decline = async () => {
        await updateInvitationWrapper('declined')
            .then(() => {
                history.push(cancelPath)
            })
            .catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
            });
    }

    const content = !invitation ? <CircularProgress /> : (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header', {consortia: invitation.charter.consortia.name})}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    {lt('description')}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {lt('organization')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {organization.name}
                </Typography>
            </Grid>

            <Grid item>
                <TextField required
                    value={orgName} 
                    onChange={event => setOrgName(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('orgName')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {lt('participatingMembers', { count: invitation.charter.memberships.length })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {invitation.charter.memberships.map(m => m.name).join(', ')}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {lt('environments', { count: invitation.charter.environments.length })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {invitation.charter.environments.map(m => m.name).join(', ')}
                </Typography>
            </Grid>
            
            {!supportsInvitations && 
                <Grid item >
                    <PayWallBanner description={lt('paywall')} />
                </Grid>
            }
        </>
    )

    const declineDisabled = updateInvitationLoading
    const disabled = declineDisabled || !orgName || !supportsInvitations;

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} onSecondaryDisabled={declineDisabled}
                onSecondary={decline} customCancelButtonLabel={lt('respondLater')} customSecondaryButtonLabel={lt('decline')} 
                customNextButtonLabel={lt('joinNetwork')} isFirstStep isLastStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string,
    description: string,
    paywall: string,
    joinNetwork: string
    decline: string
    respondLater: string
    orgName: string
    participatingMembers: string
    environments: string
    organization: string
}
const enTranslations: translations = {
    header: 'You\'re invited to join {{consortia}}!',
    description: 'A business network is comprised of a group of organizations and/or multiple members who have a shared business goal. You can join this business network by clicking Join Network below.',
    paywall: 'A Business or Enterprise Plan is required to join this network.',
    joinNetwork: 'Join Network',
    decline: 'Decline',
    respondLater: 'Respond Later',
    orgName: 'Organization Name',
    participatingMembers: 'Participating Members ({{count}})',
    environments: 'Environments ({{count}})',
    organization: 'Selected Organization'
}
