import React, { useState, useMemo } from "react";
import {
    Grid,
    Typography,
    CircularProgress,
    TextField,
    Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DisplayGridWrapper } from "../../components/DisplayWrappers";
import { useMutation, useQuery } from "@apollo/client";
import { NodeStatusData, NodeStatusQuery } from "../../models";
import { useParams } from "react-router-dom";
import { sha256 } from "js-sha256";
import CertificateOutlineIcon from "mdi-react/CertificateOutlineIcon";
import AccountKeyIcon from "mdi-react/AccountKeyIcon";
import { CopyableSettings } from "../../components/DisplaySettings";
import {
    VerifyReportMutation,
    VerifyReportData,
    VerifyReportVars,
} from "../../models/tether";
import { MessageSnackbar } from "../../components/DialogWrappers";

export const NodeTether = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "NodeTether", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`NodeTether:${key}`, interpolate);

    const { consortium_id, environment_id, node_id } = useParams<any>();
    const [blockNumber, setBlockNumber] = useState("");
    const [blockHash, setBlockHash] = useState("");
    const [signatureV, setSignatureV] = useState("");
    const [signatureR, setSignatureR] = useState("");
    const [signatureS, setSignatureS] = useState("");

    const [message, setMessage] = useState("");

    const envResVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const nodeVars = {
        ...envResVars,
        id: node_id!,
    };

    const {
        loading,
        data: { nodeStatus } = { nodeStatus: null },
    } = useQuery<NodeStatusData>(NodeStatusQuery, {
        variables: nodeVars,
        fetchPolicy: "cache-and-network",
    });

    const [verifyReport, { loading: verifyLoading }] = useMutation<VerifyReportData, VerifyReportVars>(
        VerifyReportMutation
    );

    const nodeIdHash = useMemo(() => {
        if (nodeStatus) return `0x${sha256(nodeStatus._id)}`;
        return "--";
    }, [nodeStatus]);

    if (loading || !nodeStatus) return <CircularProgress />;

    const nodeSigningAddress = nodeStatus.geth?.public_address?.toLowerCase() || 
        nodeStatus.quorum?.public_address?.toLowerCase() || 
        nodeStatus.pantheon?.public_address?.toLowerCase() ||
        nodeStatus.polygon_edge?.public_address?.toLowerCase()

    const kpiItems = [
        {
            displayValue: nodeIdHash,
            title: lt("nodeHashId"),
            icon: <CertificateOutlineIcon />,
        },
        {
            displayValue: nodeSigningAddress ?? "--", // this varies depending on the protocol
            title: lt("nodeSigningAddress"),
            icon: <AccountKeyIcon />,
        },
    ];

    const onVerifyClick = () => {
        verifyReport({
            variables: {
                node_id: node_id,
                report: {
                    r: signatureR,
                    s: signatureS,
                    sigOrHash: JSON.stringify({nodeId: node_id, nodeIdHash, blockNumber: Number(blockNumber), blockHash}),
                    sigOrV: `0x${Number(signatureV).toString(16)}`
                }
            }
        }).then(d => {
            const verifyAddress = d.data?.verifyReport.address?.toLowerCase()
            if (verifyAddress === nodeSigningAddress) {
                setMessage(lt('success', {address: nodeSigningAddress}))
            } else if (verifyAddress) {
                setMessage(lt('failed', {address: d.data?.verifyReport.address?.toLowerCase()}))
            } else {
                setMessage(lt('failedNoAddress'))
            }
        }).catch(() => setMessage(lt('failedNoAddress')))
    };

    const disabled =
        !blockHash || !blockNumber || !signatureR || !signatureS || !signatureV || verifyLoading; 

    const content = (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={lt("blockNumber")}
                    value={blockNumber}
                    onChange={(e) => setBlockNumber(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={lt("blockHash")}
                    value={blockHash}
                    onChange={(e) => setBlockHash(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={lt("signatureV")}
                    value={signatureV}
                    onChange={(e) => setSignatureV(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={lt("signatureR")}
                    value={signatureR}
                    onChange={(e) => setSignatureR(e.target.value)}
                />
            </Grid>
            <Grid item>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label={lt("signatureS")}
                    value={signatureS}
                    onChange={(e) => setSignatureS(e.target.value)}
                />
            </Grid>
            <Grid item>
                <Button onClick={onVerifyClick}
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    {lt("verify")}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <Grid container direction="column" spacing={3}>
            <MessageSnackbar setMessage={setMessage} message={message} messageType={message.startsWith(lt('success')) ? 'success' : 'error'} />
            <Grid item>
                <Typography variant="h5">{lt("publicTether")}</Typography>
            </Grid>
            <Grid item>
                <CopyableSettings
                    header={lt("nodeInformation")}
                    copyableList={kpiItems}
                />
            </Grid>
            <Grid item md lg={5}>
                <DisplayGridWrapper
                    padDisplayGrid
                    header={lt("verifyReport")}
                    displayGrid={content}
                />
            </Grid>
        </Grid>
    );
};

interface translations {
    publicTether: string;
    nodeHashId: string;
    nodeSigningAddress: string;
    nodeInformation: string;
    verifyReport: string;
    blockNumber: string;
    blockHash: string;
    signatureV: string;
    signatureR: string;
    signatureS: string;
    verify: string;
    success: string
    failed: string
    failedNoAddress: string
}

const enTranslations: translations = {
    publicTether: "Public Ethereum Tether",
    nodeHashId: "Node Hash Id",
    nodeSigningAddress: "Node Signing Key",
    nodeInformation: "Node Information",
    verifyReport: "Verify Report",
    blockHash: "Block Hash",
    blockNumber: "BlockNumber",
    signatureR: "Signature - R",
    signatureS: "Signature - S",
    signatureV: "Signature - V",
    verify: "Verify",
    success: 'Successfully verified report: {{address}}',
    failed: 'Failed to verify report, address mismatch: {{address}}',
    failedNoAddress: 'Failed to verify report, an error occurred'
};
