import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConsortiumCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConsortiumCreateStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Network-Summary.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('helpOffChain')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpOffChain: string,
}
const enTranslations: translations = {
    helpHeader: 'Network Summary',
    helpDescription: 'A network or “consortium” is a business network comprised of a group of organizations and/or multiple members who have a shared business goal.  At this step, you are tasked with supplying the following pieces of configuration:',
    helpDesc1: 'Name – Identity of the consortia',
    helpDesc2: 'Mission – Optional prose or legalese describing business purpose, bylaws, objectives, etc…',
    helpOffChain: 'All operations at this tier are off-chain, and include: cloud + region whitelisting, membership creation, certificate management, governance, and network auditing.',
}