import React, { useState } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import TextField, { TextFieldProps} from "@material-ui/core/TextField";
import { InputAdornment, makeStyles } from '@material-ui/core';


export const PasswordInput = (props: TextFieldProps) => {
    const [passwordIsMasked, setPasswordIsMasked] = useState<boolean>(true);
    const classes = useStyles();

    const togglePasswordMask = () => {
        setPasswordIsMasked(c => !c);
    };

    return(
        <TextField type={passwordIsMasked ? 'password' : 'text'}
                   {...props}
                   InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EyeIcon className={classes.eye}
                                            onClick={togglePasswordMask} />
                            </InputAdornment>
                        )
                    }}/>
    );
};

const useStyles = makeStyles(() => ({
    eye: {
        cursor: 'pointer'
    }
}))