import { useQuery } from '@apollo/client';
import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ConsortiaData, ConsortiaQuery, UserMembershipsData, UserMembershipsQuery } from '../../models';
import { BillingSummaryData, BillingSummaryQuery } from '../../models/billing';
import { ManageOrgBillingUsage } from './BillingUsage';

export const ManageOrgBilling: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ManageOrgBilling', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ManageOrgBilling:${key}`, interpolate);
    const { org_id } = useParams<any>();

    const {
        data: {
            consortia
        } = { consortia: [] }
    } = useQuery<ConsortiaData>(ConsortiaQuery, { fetchPolicy: 'cache-only' });

    const {
        data: {
            memberships
        } = { memberships: [] }
    } = useQuery<UserMembershipsData>(UserMembershipsQuery, { fetchPolicy: 'cache-only' });

    const {
        loading,
        data: {
            billingSummary
        } = { billingSummary: undefined }
    } = useQuery<BillingSummaryData>(BillingSummaryQuery, { variables: { org_id }, fetchPolicy: 'cache-and-network' });

    if (loading && !billingSummary) {
        return <CircularProgress />
    }

    if (!billingSummary) {
        return (<Typography>{lt('noBillingInformation')}</Typography>);
    }

    return <ManageOrgBillingUsage {...{memberships}} {...{consortia}} {...{billingSummary}} />;
}

interface translations {
    noBillingInformation: string
}

const enTranslations: translations = {
    noBillingInformation: 'Billing information is not available at this time.',
}