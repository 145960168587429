import { gql, MutationFunctionOptions } from '@apollo/client';
import { NodeResourcesVars, NodeResourceVars } from '../interfaces';

// EventStream

export type EventStreamType = 'webhook' | 'websocket'

export interface EventStream {
    id: string
    name: string
    created: string
    path: string
    suspended: boolean
    type: EventStreamType
    batchSize: number
    batchTimeoutMS: number
    errorHandling: 'block' | 'skip'
    blockedReryDelaySec: number
    webhook?: EventStreamWebhook
    websocket?: EventStreamWebSocket
    node_id: string
}

export interface CreateEditEventStream {
    name: EventStream['name']
    type: EventStream['type']
    batchSize: EventStream['batchSize']
    batchTimeoutMS: EventStream['batchTimeoutMS']
    errorHandling: EventStream['errorHandling']
    blockedReryDelaySec: EventStream['blockedReryDelaySec']
    webhook?: EventStreamWebhook
    websocket?: EventStreamWebSocket
}

export interface CreateEventStreamVars extends NodeResourcesVars {
    eventStream: CreateEditEventStream
}

export interface UpdateEventStreamVars extends NodeResourcesVars {
    id: string;
    eventStream: CreateEditEventStream;
}

export interface CreateEventStreamData {
    createEventStream: EventStream
}

export interface UpdateEventStreamData {
    updateEventStream: EventStream
}

export interface EventStreamWebhook {
    url: string
    headers?: {
        [key: string]: string
    }
    requestTimeoutSec: number
    tlsSkipHostVerify: boolean
}

export interface EventStreamWebSocket {
    topic: string;
}

export interface AllEventStreamsData {
    allEventStreams: EventStream[]
}

export interface EventStreamsData {
    eventStreams: EventStream[]
}

export interface EventStreamData {
    eventStream: EventStream
}

const EventStreamFields = ` 
    fragment EventStreamFields on EventStream {
        id
        name
        created
        path
        suspended
        type
        batchSize
        batchTimeoutMS
        errorHandling
        blockedReryDelaySec
        webhook {
            url
            headers
            requestTimeoutSec
            tlsSkipHostVerify
        }
        websocket {
            topic
        }
        node_id
    }`

export const AllEventStreamsQuery = gql`
    ${EventStreamFields}
    query getAllEventStreams($consortia_id: String!, $environment_id: String!, $node_ids: [String!]!) {
        allEventStreams(consortia_id: $consortia_id, environment_id: $environment_id, node_ids: $node_ids) {
            ...EventStreamFields
        }
    }`; 

export const EventStreamsQuery = gql`
    ${EventStreamFields}
    query getEventStreams($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        eventStreams(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id) {
            ...EventStreamFields
        }
    }`;    

export const EventStreamQuery = gql`
    ${EventStreamFields}
    query getEventStream($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        eventStream(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ...EventStreamFields
        }
    }`;    

export const CreateEventStreamMutation = gql`
    ${EventStreamFields}
    mutation createEventStream($consortia_id: String!, $environment_id: String!, $node_id: String!, $eventStream: CreateUpdateEventStreamInput!) {
        createEventStream(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, eventStream: $eventStream) {
            ...EventStreamFields
        }
    }`;    

export const UpdateEventStreamMutation = gql`
    ${EventStreamFields}
    mutation updateEventStream($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!, $eventStream: CreateUpdateEventStreamInput!) {
        updateEventStream(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id, eventStream: $eventStream) {
            ...EventStreamFields
        }
    }`;

export const SuspendEventStreamMutation = gql`
    mutation suspendEventStream($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        suspendEventStream(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id)
    }`;

export const ResumeEventStreamMutation = gql`
    mutation resumeEventStream($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        resumeEventStream(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id)
    }`;    

export const DeleteEventStreamMutation = gql`
    mutation deleteEventStream($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        deleteEventStream(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id)
    }`;    

export function MakeEventStreamDeleteMutationOptions(
    variables: NodeResourceVars,
    overrideOptions?: MutationFunctionOptions<string, NodeResourceVars>): MutationFunctionOptions<string, NodeResourceVars> {
    return {
        ...{
            variables,
            update(cache) {
                // try catch in case readQuery throws (because the cache is empty)
                try {
                    const { eventStreams } = cache.readQuery<EventStreamsData, NodeResourcesVars>({ query: EventStreamsQuery, variables })!;
                    cache.evict({id: `EventStream:${variables.id}`, broadcast: false})
                    cache.writeQuery<EventStreamsData, NodeResourcesVars>({
                        query: EventStreamsQuery,
                        data: { eventStreams: eventStreams.filter(s => s.id !== variables.id) },
                        variables
                    });
                } catch(err) {
                    console.log('Failed to clear EventStream from cache', err)
                }
            }
        }, ...overrideOptions
    }
}    

// EventStreamSubscription

export interface EventStreamSubscription {
    id: string
    name: string
    created: string
    path: string
    stream: string
    filter: EventStreamSubscriptionFilter
    event: EventStreamSubscriptionEvent
    fromBlock: string
    node_id: string
}

export interface CreateEventStreamSubscriptionVars extends NodeResourcesVars {
    eventStreamSubscription: {
        name: EventStreamSubscription['name']
        stream: EventStreamSubscription['stream']
        fromBlock: EventStreamSubscription['fromBlock']
        gateway: string // the gateway api endpoint
        instance: string // the instance endpoint, or contract address
        event: string // the name of the event
    }
}

export interface CreateEventStreamSubscriptionData {
    createEventStreamSubscription: EventStreamSubscription
}

interface EventStreamSubscriptionFilter {
    address?: string[]
    topics: string[][]
}

interface EventStreamSubscriptionEvent {
    name: string
    Inputs: EventStreamSubscriptionEventInput[]
    Outputs: JSON // dont know what this is
}

interface EventStreamSubscriptionEventInput {
    name: string
    type: string
    indexed: boolean
}

export interface AllEventStreamSubscriptionsData {
    allEventStreamSubscriptions: EventStreamSubscription[]
}

export interface EventStreamSubscriptionsData {
    eventStreamSubscriptions: EventStreamSubscription[]
}

export interface EventStreamSubscriptionData {
    eventStreamSubscription: EventStreamSubscription
}

const EventStreamSubscriptionFields = ` 
    fragment EventStreamSubscriptionFields on EventStreamSubscription {
        id
        name
        created
        path
        stream
        filter {
            address
            topics
        }
        event {
            name
            Inputs {
                name
                type
                indexed
            }
            Outputs
        }
        fromBlock
        node_id
    }`

export const AllEventStreamSubscriptionsQuery = gql`
    ${EventStreamSubscriptionFields}
    query getAllEventStreamSubscriptions($consortia_id: String!, $environment_id: String!, $node_ids: [String!]!) {
        allEventStreamSubscriptions(consortia_id: $consortia_id, environment_id: $environment_id, node_ids: $node_ids) {
            ...EventStreamSubscriptionFields
        }
    }`; 

export const EventStreamSubscriptionsQuery = gql`
    ${EventStreamSubscriptionFields}
    query getEventStreamSubscriptions($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        eventStreamSubscriptions(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id) {
            ...EventStreamSubscriptionFields
        }
    }`;    

export const EventStreamSubscriptionQuery = gql`
    ${EventStreamSubscriptionFields}
    query getEventStreamSubscription($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        eventStreamSubscription(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ...EventStreamSubscriptionFields
        }
    }`; 
    
export const CreateEventStreamSubscriptionMutation = gql`
    ${EventStreamSubscriptionFields}
    mutation createEventStreamSubscription($consortia_id: String!, $environment_id: String!, $node_id: String!, $eventStreamSubscription: CreateEventStreamSubscriptionInput!) {
        createEventStreamSubscription(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, eventStreamSubscription: $eventStreamSubscription) {
            ...EventStreamSubscriptionFields
        }
    }`;    

export const DeleteEventStreamSubscriptionMutation = gql`
    mutation deleteEventStreamSubscription($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        deleteEventStreamSubscription(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id)
    }`;        

export function MakeEventStreamSubscriptionDeleteMutationOptions(
    variables: NodeResourceVars,
    overrideOptions?: MutationFunctionOptions<string, NodeResourceVars>): MutationFunctionOptions<string, NodeResourceVars> {
    return {
        ...{
            variables,
            update(cache) {
                // try catch in case readQuery throws (because the cache is empty)
                try {
                    const { eventStreamSubscriptions } = cache.readQuery<EventStreamSubscriptionsData, NodeResourcesVars>({ query: EventStreamSubscriptionsQuery, variables })!;
                    cache.evict({id: `EventStreamSubscription:${variables.id}`, broadcast: false})
                    cache.writeQuery<EventStreamSubscriptionsData, NodeResourcesVars>({
                        query: EventStreamSubscriptionsQuery,
                        data: { eventStreamSubscriptions: eventStreamSubscriptions.filter(s => s.id !== variables.id) },
                        variables
                    });
                } catch(err) {
                    console.log('Failed to clear EventStreamSubscription from cache', err)
                }
            }
        }, ...overrideOptions
    }
}

// Translations

export interface EventStreamTranslations {
    id: string
    subscriptions: string
    status: string
    createdAt: string
    type: string
    path: string
    batchSize: string
    batchTimeoutMS: string
    blockedReryDelaySec: string
    errorHandling: string
    webhook: string
    websocket: string
    url: string
    requestTimeoutSec: string
    headers: string
    event: string
    name: string
    fromBlock: string
    verifyTlsCertificates: string
}

export const EnEventStreamTranslations: EventStreamTranslations = {
    id: 'ID',
    subscriptions: 'Subscriptions',
    status: 'Status',
    createdAt: 'Created at',
    type: 'Type',
    webhook: 'Webhook',
    websocket: 'WebSocket API',
    path: 'Path',
    batchSize: 'Batch size',
    batchTimeoutMS: 'Batch timeout (ms)',
    blockedReryDelaySec: 'Blocked retry delay (sec)',
    errorHandling: 'Error handling',
    url: 'URL',
    requestTimeoutSec: 'Request timeout (sec)',
    headers: 'Headers',
    event: 'Event name',
    name: 'Name',
    fromBlock: 'From block',
    verifyTlsCertificates: 'Verify TLS certificates'
}