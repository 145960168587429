import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Subscription.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/kaleido-services/event-streams"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'What is an event stream subscription?',
    helpDescription: 'Subscriptions are created when you subscribe an event within a specific smart contract, or, all smart contracts matching an event signature, to an event stream ID. The steps to set this up are as follows:',
    helpDesc1: 'Select a Gateway API to view all smart contract events contained in that Gateway API\'s ABI.',
    helpDesc2: 'Select the event to subscribe to. Choose whether this subscription should start with the most recent block available, or if it should start from a specific block number.',
    helpDesc3: 'Optionally, you may select a specific Gateway API contract instance to subscribe to. If this is omitted, then any events matching the event signature will trigger the subscription, no matter what specific contract instance emitted the event.',
}