import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsortiumZone, EnvironmentZone } from '../../models';

interface Props {
    zoneId: string,
    setZoneId: React.Dispatch<React.SetStateAction<string>>,
    consortiumZones: ConsortiumZone[],
    privateZones?: EnvironmentZone[],
};

export const RegionSelector = ({ zoneId, setZoneId, consortiumZones, privateZones }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'RegionSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`RegionSelector:${key}`, interpolate)

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setZoneId(event.target.value as string);
    };

    const getCZone = (zoneId: string) => consortiumZones.find(x => x._id === zoneId);


    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel}>
                {lt('selectRegion')}
            </InputLabel>
            <Select 
                labelWidth={labelWidth}
                value={zoneId}
                onChange={handleChange}>
                {[
                    consortiumZones.map(m => (
                        <MenuItem key={m._id} selected={m._id === zoneId} value={m._id}>
                            {`${getCZone(m._id)?.cloud?.toUpperCase()}: ${getCZone(m._id)?.region}`}
                        </MenuItem>
                    )),
                    ...(privateZones?.map(m => (
                        <MenuItem key={m._id} selected={m._id === zoneId} value={m._id}>
                            {m.displayName}
                        </MenuItem>
                    )) || [])
                ]}
            </Select>
        </FormControl>
    )
};

interface translations {
    selectRegion: string,
    regionsAvailable: string,
}
const enTranslations: translations = {
    selectRegion: 'Select Region',
    regionsAvailable: 'Regions available',
}