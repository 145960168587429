import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Step1Help } from "./Step1Help";
import { CreateStepProps } from "../../interfaces";
import { ConsortiumZone, EnvironmentZone, ServicesEnum } from "../../models";
import { RuntimeCreationStep1Content } from "../../components/RuntimeCreation/RuntimeCreationStep1Content";
import { RuntimeCreationFinalStep } from "../../components/RuntimeCreation/RuntimeCreationFinalStep";
import { BLOCKCHAIN_BASE_PATH } from "../../components/MainNav/SideNavs/Blockchain";

interface Props extends CreateStepProps {
    isMultiRegion: boolean;
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    membershipId: string;
    setMembershipId: React.Dispatch<React.SetStateAction<string>>;
    consortiumZoneId: string;
    consortiumZones: ConsortiumZone[];
    setConsortiumZoneId: React.Dispatch<React.SetStateAction<string>>;
    environmentZones: EnvironmentZone[];
}

export const Step1 = ({
    isMultiRegion,
    environmentZones,
    name,
    setName,
    membershipId,
    setMembershipId,
    consortiumZoneId,
    consortiumZones,
    setConsortiumZoneId,
    cancelPath,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateOnChainStep1", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateOnChainStep1:${key}`, interpolate);

    const { consortium_id, environment_id, org_id } = useParams<any>();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const content = (
        <RuntimeCreationStep1Content
            header={lt("createOnChain")}
            {...{ isMultiRegion }}
            {...{ name }}
            {...{ setName }}
            {...{ membershipId }}
            {...{ setMembershipId }}
            {...{ consortiumZones }}
            {...{ consortiumZoneId }}
            {...{ setConsortiumZoneId }}
        />
    );

    return (
        <RuntimeCreationFinalStep
            servicePath={`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.idregistry}`}
            service={ServicesEnum.idregistry}
            {...{ cancelPath }}
            {...{ name }}
            {...{ membershipId }}
            {...{ consortiumZones }}
            {...{ consortiumZoneId }}
            {...{ environmentZones }}
            {...{ content }}
            helpContent={<Step1Help />}
            isFirstStep
        />
    );
};

interface translations {
    createOnChain: string;
}
const enTranslations: translations = {
    createOnChain: "Create Create On-Chain Registry",
};
