import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers';

export const Step3Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FireflyCreateNodeStep3Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`FireflyCreateNodeStep3Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-SizeRole.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/using-kaleido/resource-limits"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'Node Settings',
    helpDescription: 'The last piece of configuration before you start your node',
    helpDesc1: `Each size has a direct correlation to ingress rate limiting and underlying memory CPU.`,
    finalInfo: 'Currently only small sized FireFly nodes are available.'
}