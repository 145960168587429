import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import CheckIcon from 'mdi-react/CheckIcon';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AlertBanner } from '../../../components/Banners/AlertBanner';
import { CookieAppCred } from '../../../components/CookieAppCred/CookieAppCred';
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../../components/DialogWrappers';
import { ShortenedHash } from '../../../components/FormControls/ShortenedHash';
import { ConsortiumResourceVars, EnvironmentResourceVars, EnvironmentResourcesVars, ServiceResourcesVars } from '../../../interfaces';
import { AccountsData, AccountsQuery, ChannelsData, ChannelsQuery, EnvironmentData, EnvironmentQuery, FireflyDeployedContractsData, FireflyGatewayAPIContractsQuery, ForceCreateCompiledContractData, ForceCreateCompiledContractMutation, ForceCreateCompiledContractVars, NodeData, NodeQuery, NodeStatusData, NodeStatusQuery, PrecompiledTemplate, ReleaseData, ReleaseQuery, ReleaseVars, ServiceData, ServiceQuery, SupportedEvmVersions, getFromForGatewayAPI } from '../../../models';
import { FireflyExtensionsData, FireflyExtensionsQuery, FireflyInitializeData, FireflyInitializeInput, FireflyInitializeVars, InitializeFireflyInstanceMutation, IsFireflyInitializedData, IsFireflyInitializedQuery } from '../../../models/firefly';
import { AlertDarkColors } from '../../../utils/Colors';
import { AddNamespaceHelp } from './AddNamespaceHelp';
import { FireflyTemplateHelp } from './FireflyTemplateHelp';

const solcVersion = 'v0.8.20+commit.a1b79de6' // Tied to https://github.com/kaleido-io/kaleido-contract-templates/blob/master/contracts/precompiled/firefly/1.2.0/README.md
const evmVersion: SupportedEvmVersions = "constantinople"; //only constantinople is supported
const DEFAULT_CHANNEL = 'default-channel'


export const Step1 = () => {
    const classes = useStyles();
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "FireflyInitializeStep1", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`FireflyInitializeStep1:${key}`, interpolate);

    const history = useHistory()
    const { consortium_id, environment_id, service_id } = useParams<any>();
    const { search } = useLocation();
    const { operation } = queryString.parse(search);


    const [namespace, setNamespace] = useState<string>('');
    const [fireflyContractAddress, setFireflyContractAddress] = useState('')
    const [fireflyChaincodeLabel, setFireflyChaincodeLabel] = useState('')
    const [cookieAppCred, setCookieAppCred] = useState('')
    const [saving, setSaving] = useState<'importing' | 'initializing' | 'done' | 'cookie' | ''>('cookie');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error' | 'success'>('error')
    const [selectedChannel, setSelectedChannel] = useState(DEFAULT_CHANNEL)
    const [selectedFireFlyContract, setSelectedFireFlyContract] = useState<string | undefined>()

    const [forceCreateCompiledContract] = 
        useMutation<ForceCreateCompiledContractData, ForceCreateCompiledContractVars>(ForceCreateCompiledContractMutation)

    const [initializeFirefly] = 
        useMutation<FireflyInitializeData, FireflyInitializeVars>(InitializeFireflyInstanceMutation);

    const envVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const {
        data: {
            channels
        } = { channels: [] }
    } = useQuery<ChannelsData, EnvironmentResourcesVars>(ChannelsQuery, { 
        variables: envVars,
        fetchPolicy: 'cache-only'
    });
    
    // if we get a new error message, kill the loading bar
    useEffect(() => {
        if (message && messageType === 'error') {
            setSaving('')
        }
    }, [message, messageType, setSaving])

    useEffect(() => {
        if (cookieAppCred && saving === 'cookie') {
            setSaving('')
        }
    }, [cookieAppCred, saving])

    const {
        data: {
            environment
        } = {environment:null}
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    useEffect(() => {
        if (environment?.isFabric && saving === 'cookie') {
            setSaving('')
        }
    }, [environment?.isFabric, saving])


    let {
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData>(AccountsQuery, {
        variables: envVars,
        fetchPolicy: 'cache-and-network',
        pollInterval: 5000,
        skip: environment?.isFabric
    });

    const {
        data: {
            service
        } = {service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            ...envVars,
            id: service_id
        },
        fetchPolicy: 'cache-only'
    });

    const nodeId = service?.details.node_id ?? '';

    const {
        data: {
            node
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: nodeId
        },
        fetchPolicy: 'cache-only',
    });

    const {
        loading: fireflyGatewayAPIContractsLoading,
        data: {
            fireflyDeployedContracts
        } = { fireflyDeployedContracts: null }
    } = useQuery<FireflyDeployedContractsData, EnvironmentResourcesVars>(FireflyGatewayAPIContractsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-and-network'
    });


    const {
        loading: isFireflyInitializedLoading,
        data: {
            isFireflyInitialized
        } = { isFireflyInitialized: { initialized: false, fireflyTokensErc1155: false } },
    } = useQuery<IsFireflyInitializedData, EnvironmentResourceVars>(IsFireflyInitializedQuery, {
        variables: {
            ...envVars,
            id: service?._id || '',
        },
        fetchPolicy: 'network-only'
    });

    const {
        loading: fireflyExtensionsLoading,
        data: {
            fireflyExtensions
        } = { fireflyExtensions: [] }
    } = useQuery<FireflyExtensionsData, ServiceResourcesVars>(FireflyExtensionsQuery, {
        variables: {
            service_id: service?._id || ''
        },
        fetchPolicy: 'network-only', // make sure we get the latest right away, since the value from the FireflyStatusChip is likely stale since it doesnt poll
    });

    const supportedTokensPluginName = fireflyExtensions.find(x => x.key === 'tokens')?.value.find(v => v.plugin === 'fftokens')?.name

    const {
        loading: node1StatusLoading,
        data : {
            nodeStatus: node1Status
        } = {nodeStatus: null}
    } = useQuery<NodeStatusData, EnvironmentResourceVars>(NodeStatusQuery, {
        variables: {
            ...envVars,
            id: nodeId
        },
        fetchPolicy: 'cache-and-network'
    });

    const node1PreselectedAccount = node1Status?.user_accounts?.find(() => true) || node?.consensus_identity
    const preselectedAccountFound = accounts.find(a => a._id === node1PreselectedAccount?.toLowerCase())
    const deployInstance = async(precompiledTemplate: PrecompiledTemplate, gatewayApiEndpoint: string, instanceEndpoint: string) => {
        const url = `${node?.urls?.kaleido_connect}/gateways/${gatewayApiEndpoint}`;
        let queryParams = `?kld-from=${getFromForGatewayAPI(preselectedAccountFound!)}&kld-sync=true`;
        if (instanceEndpoint) {
            queryParams += `&kld-register=${instanceEndpoint}`
        }
        const fullURl = url + queryParams

        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(cookieAppCred));

        let contractAddress = ''

        // todo: figure out what uri to use
        let body = precompiledTemplate === 'firefly_erc1155' ? { uri: '' } : {}

        const response = await fetch(fullURl, {
            method:'POST',
            body: JSON.stringify(body),
            headers: headers,
        });
        if (!response.ok) {
            throw new Error(lt('error', {status: `${response.status} ${response.statusText}`}));
        }
        const processResponse = await response.json();
        contractAddress = processResponse.contractAddress

        if (precompiledTemplate === 'firefly_ethereum') {
            setFireflyContractAddress(contractAddress)
        }

        return contractAddress
    }

    const forceCreate = async (precompiledTemplate: PrecompiledTemplate) => {
        let instanceAddress = '', chaincodeLabel = ''

        let fc = await forceCreateCompiledContract({
            variables: {
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                forceCompiledContract: {
                    membership_id: service?.membership_id || '',
                    precompiledTemplate: precompiledTemplate,
                    solc_version: solcVersion,
                    evm_version: evmVersion,
                    fireflyVersion: release?.supported_features?.fireflyVersion ?? '1.1',
                    selectedChannel: selectedChannel
                }
            }
        })

        let ccId = ''

        if (fc.data) {
            let instanceEndpoint = ''
            ccId = fc.data.forceCreateCompiledContract.compiledContract._id
            if (selectedFireFlyContract !== 'newContract') {
                instanceAddress = fc.data.forceCreateCompiledContract.instanceAddress
            }
            const gatewayApiEndpoint = fc.data.forceCreateCompiledContract.gatewayAPIEndpoint
            chaincodeLabel = fc.data.forceCreateCompiledContract.chaincodeLabel

            if (precompiledTemplate === 'firefly_ethereum') {
                setFireflyContractAddress(instanceAddress)
            }  else if (precompiledTemplate === 'firefly_fabric') {
                setFireflyChaincodeLabel(chaincodeLabel)
            }
            if (!instanceAddress && precompiledTemplate !== 'firefly_fabric') {
                instanceAddress = await deployInstance(precompiledTemplate, gatewayApiEndpoint || ccId, instanceEndpoint)
            }
        } else {
            throw new Error(lt('error'))
        }

        return {
            addressOrChaincode: precompiledTemplate === 'firefly_fabric' ? chaincodeLabel : instanceAddress,
            compiledContractId: ccId 
        }
    }

    const initialize = async (
        addressOrChaincode: string,
        precompiledTemplate: PrecompiledTemplate,
        compiledContractId?: string
    ) => {
        setSaving('initializing');

        let fabricPayload: FireflyInitializeInput = {
            chaincode: addressOrChaincode.slice(
                0,
                addressOrChaincode.lastIndexOf('-')
            ), // strip version details from label
            channel: selectedChannel ?? DEFAULT_CHANNEL,
            namespace,
            prerequisiteInfo: {
                consortia_id: consortium_id,
                environment_id,
                node_id: nodeId,
                gateway_api_id: compiledContractId,
            },
        };

        let ethPayload: FireflyInitializeInput = {
            contract_address: addressOrChaincode,
            namespace,
        };

        if (supportedTokensPluginName) {
            ethPayload.plugins = [
                { plugin: 'fftokens', name: supportedTokensPluginName },
            ];
        }
        await initializeFirefly({
            variables: {
                service_id: service_id,
                fireflyInitialize:
                    precompiledTemplate === 'firefly_fabric'
                        ? fabricPayload
                        : ethPayload,
            },
        });

        setMessageType('success');
        setMessage(
            lt(operation === 'addNamespace' ? 'addSuccess' : 'initSuccess')
        );
        setSaving('done');
    };

    const { data: { release } = { release: null } } = useQuery<
        ReleaseData,
        ReleaseVars
    >(ReleaseQuery, {
        skip: !environment,
        variables: {
            id: environment?.release_id!,
        },
        fetchPolicy: 'cache-and-network',
    });

    const isNewFireFly = ()=>{
        if (release?.supported_features && release?.supported_features.fireflyVersion === '1.2') {
            return true;
        }
        return false;
    }

    const save = async () => {
        setSaving('importing')

        let precompiledTemplate: PrecompiledTemplate = environment?.isFabric ? 'firefly_fabric' : 'firefly_ethereum'

        try {
            let addressOrChaincode = 'newContract', compiledContractId
            if (selectedFireFlyContract && selectedFireFlyContract !== 'newContract') {
                addressOrChaincode = selectedFireFlyContract
                compiledContractId = fireflyDeployedContracts?.contracts.find(c => c.address === selectedFireFlyContract)?.gatewayAPIId
            } else {
                let createdContractOrChaincode = await forceCreate(precompiledTemplate)
                addressOrChaincode = createdContractOrChaincode.addressOrChaincode
                compiledContractId = createdContractOrChaincode.compiledContractId
            }
            await initialize(addressOrChaincode, precompiledTemplate, compiledContractId)
        } catch (e) {
            setSaving('')
            ErrorSnackbarCatcher(e, setMessage);
        }
    }

    const loading = isFireflyInitializedLoading || fireflyExtensionsLoading || node1StatusLoading

    if (loading) {
        return <CircularProgress/>
    }

    if (isFireflyInitialized.initialized && (!loading && !supportedTokensPluginName && !environment?.isFabric)) {
        history.goBack()
    }

    const disabled = loading || saving !== '' || namespace === '' || (environment?.isEthereum === true && (!cookieAppCred))

    const fireflyContractAddressSelection = [
        <MenuItem
            key={'newFireFlyAddress'}
            selected={selectedFireFlyContract === 'newContract'}
            value={'newContract'}
        >
            {lt('newFireFlyContract')}
        </MenuItem>,
    ];

    if (!fireflyGatewayAPIContractsLoading) {
        fireflyDeployedContracts?.contracts.forEach((contract) => {
            if (selectedFireFlyContract === undefined) {
                setSelectedFireFlyContract(contract.address)
            }
            fireflyContractAddressSelection.push(
                <MenuItem
                    key={contract.address}
                    selected={selectedFireFlyContract === contract.address}
                    value={contract.address}
                >
                    {
                        <ShortenedHash
                            address={contract.address}
                            showFullAddress
                            showMember
                            noPopover
                        />
                    }
                </MenuItem>
            );
        });
    }
 
    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {operation === 'addNamespace'
                        ? lt('addNamespace')
                        : lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {operation === 'addNamespace'
                        ? lt('addNamespaceDescription')
                        : lt('headerDescription')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {lt('takesTime')}
                </Typography>
            </Grid>
            {isNewFireFly() && (
                <>
                    <Grid
                        item
                        className={clsx({
                            [classes.displayNone]:
                                isFireflyInitialized.initialized,
                        })}
                    >
                        <Typography variant="body2">
                            {lt('customNamespaceMessage')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                size="medium"
                                data-test="ff-namespaceName"
                                required
                                label={lt('namespaceName')}
                                value={namespace}
                                variant="outlined"
                                onChange={(e) =>
                                    setNamespace(e.target.value as string)
                                }
                            />
                        </FormControl>
                    </Grid>
                </>
            )}

            {environment?.isEthereum && !fireflyGatewayAPIContractsLoading && (
                <>
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            {lt('joinOrAddNewMultipartyNetwork')}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {lt('joinOrAddNewMultipartyNetworkMessage')}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel ref={inputLabel} required>
                                {lt('fireflyContracts')}
                            </InputLabel>
                            <Select
                                {...{ disabled }}
                                label={lt('fireflyContracts')}
                                labelWidth={labelWidth}
                                value={selectedFireFlyContract ?? 'newContract'}
                                onChange={(e) =>
                                    setSelectedFireFlyContract(
                                        e.target.value as string
                                    )
                                }
                            >
                                {fireflyContractAddressSelection}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <AlertBanner
                            description={lt('multiOrgNetworkMessage')}
                        />
                    </Grid>
                </>
            )}

            {environment?.isFabric && (
                <>
                    <Grid item>
                        <Typography variant="body2" gutterBottom>
                            {lt('channelMessage')}
                        </Typography>
                        <Typography
                            variant="body2"
                            gutterBottom
                            className={classes.italicText}
                        >
                            {lt('channelWarning')}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel ref={inputLabel} required>
                                {lt('channel')}
                            </InputLabel>
                            <Select
                                {...{ disabled }}
                                label={lt('channel')}
                                labelWidth={labelWidth}
                                value={selectedChannel}
                                onChange={(e) =>
                                    setSelectedChannel(e.target.value as string)
                                }
                            >
                                {channels.map((l) => {
                                    return (
                                        <MenuItem
                                            key={l._id}
                                            selected={
                                                l.name === selectedChannel
                                            }
                                            value={l.name}
                                        >
                                            {l.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <AlertBanner
                            description={lt('multiOrgFabricNetworkMessage')}
                        />
                    </Grid>
                </>
            )}

            {saving === 'importing' &&
                environment?.isEthereum &&
                !fireflyContractAddress && (
                    <Grid item container spacing={3} alignItems="center">
                        <Grid item>
                            <Typography variant="body2">
                                {lt('importingFireflyContract')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}

            {fireflyContractAddress && (
                <Grid item container spacing={3} alignItems="center">
                    <Grid item>
                        <Typography variant="body2">
                            {lt('fireflyContractDetected')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ShortenedHash address={fireflyContractAddress} />
                    </Grid>
                </Grid>
            )}

            {saving === 'importing' &&
                environment?.isFabric &&
                !fireflyChaincodeLabel && (
                    <Grid item container spacing={3} alignItems="center">
                        <Grid item>
                            <Typography variant="body2">
                                {lt('importingFireflyChaincode')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}

            {fireflyChaincodeLabel && (
                <Grid item container spacing={3} alignItems="center">
                    <Grid item>
                        <Typography variant="body2">
                            {lt('fireflyChaincodeDetected')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {fireflyChaincodeLabel}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {saving === 'initializing' && (
                <Grid item container spacing={3} alignItems="center">
                    <Grid item>
                        <Typography variant="body2">
                            {lt('initializing')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}

            {saving === 'done' && (
                <Grid item container spacing={3} alignItems="center">
                    <Grid item>
                        <Typography variant="body2">{lt('done')}</Typography>
                    </Grid>
                    <Grid item>
                        <CheckIcon color={AlertDarkColors.green} />
                    </Grid>
                </Grid>
            )}
        </>
    );

    return (
        <>
            {!!service && environment?.isEthereum && (
                <CookieAppCred
                    membershipId={service.membership_id}
                    nodeId={nodeId}
                    {...{ setCookieAppCred }}
                    setErrorMessage={setMessage}
                />
            )}
            <MessageSnackbar
                {...{ message }}
                {...{ setMessage }}
                {...{ messageType }}
            />
            <CreateWrapper
                saving={saving !== '' && saving !== 'done'}
                customNextButtonLabel={
                    saving === 'done'
                        ? undefined
                        : operation === 'addNamespace'
                        ? lt('add')
                        : lt('init')
                }
                cancelPath=""
                {...{ content }}
                onNext={saving === 'done' ? () => history.goBack() : save}
                isFirstStep
                isLastStep
                disabled={disabled && saving !== 'done'}
            />
            {operation === 'addNamespace' ? <AddNamespaceHelp/> : <FireflyTemplateHelp />}
        </>
    );
}

const useStyles = makeStyles(theme => ({
    accountSelector: {
        marginBottom: theme.spacing(2),
    },
    fireflyContractDetected: {
        display: 'inline',
        marginRight: theme.spacing(1)
    },
    displayNone: {
        display: 'none'
    },
    italicText: {
        fontStyle: 'italic'
    }
}));

interface translations {
    add: string
    customNamespaceMessage: string
    addNamespace: string
    addNamespaceDescription: string
    header: string
    headerDescription: string
    identityMessage: string
    init: string
    fireflyContractDetected: string
    fireflyChaincodeDetected: string
    tokenInit: string
    takesTime: string
    error: string
    importingFireflyContract: string
    addSuccess: string
    initSuccess: string
    initializing: string
    done: string
    importingFireflyChaincode: string
    namespaceName: string
    fireflyContracts: string
    channel: string
    channelMessage: string
    channelWarning: string
    joinOrAddNewMultipartyNetwork: string
    joinOrAddNewMultipartyNetworkMessage: string
    newFireFlyContract: string
    multiOrgNetworkMessage: string
    multiOrgFabricNetworkMessage: string
};

const enTranslations: translations = {
    add: 'Add',
    customNamespaceMessage: 'Give your namespace a customized name',
    addNamespace: 'Add Namespace',
    addNamespaceDescription: 'Set up another FireFly namespace in your FireFly Instance.',
    header: 'Initialize FireFly Node',
    headerDescription: 'During initialization, Kaleido will check for, and deploy the FireFly Apps required by this FireFly node.',
    identityMessage: 'Select identity to register to this FireFly node',
    init: 'Initialize',
    fireflyContractDetected: 'FireFly contract detected:',
    fireflyChaincodeDetected: 'FireFly chaincode detected:',
    tokenInit: 'Initialize Tokens',
    takesTime: 'Note: This step may take up to 1 minute to complete',
    error: 'An error occurred during initialization',
    importingFireflyContract: 'Detecting / deploying FireFly contract',
    addSuccess: 'Namespace has been added successfully!',
    initSuccess: 'FireFly has been initialized successfully!',
    initializing: 'Initializing FireFly Node',
    done: 'Initializing FireFly Node! The initializing status will be viewable on the Dashboard page.',
    importingFireflyChaincode: 'Detecting / deploying FireFly chaincode',
    namespaceName: 'Namespace Name',
    fireflyContracts: 'FireFly Contracts',
    channel: 'Channel',
    channelMessage: 'Select the channel to deploy your chaincode to.',
    channelWarning: 'Note: FireFly should only be deployed to a given channel once per membership to avoid potential issues during identity registration.',
    joinOrAddNewMultipartyNetwork: 'Start a brand new multiparty network or join an existing one',
    joinOrAddNewMultipartyNetworkMessage: 'Deploy a new contract if you would like to start a new multiparty network, or select from the list of existing FireFly contracts deployed to join an existing network for this namespace.',
    newFireFlyContract: 'Deploy New FireFly Contract',
    multiOrgNetworkMessage: 'To connect with another FireFly organization, ensure you share the same namespace and underlying smart contract. Specify your namespace and choose the correct smart contract if multiple instances are deployed. Note that the namespace name is case-sensitive.',
    multiOrgFabricNetworkMessage: 'To connect with another FireFly organization, ensure you share the same namespace and selected channel. Specify your namespace and choose the correct channel if multiple instances are deployed. Note that the namespace name is case-sensitive.'
};

