import { Button, Grid, Typography } from "@material-ui/core";
import AttachFileIcon from 'mdi-react/PaperclipIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorSnackbarCatcher } from '../DialogWrappers';

export const doUpload = async ({
    consortium_id,
    contract_id,
    description,
    membershipId,
    init_required = false,
    file,
    isFabric = false,
    setLoading,
    setErrorMessage,
    onComplete,
} : {
    consortium_id: string,
    contract_id: string,
    membershipId: string,
    description: string,
    isFabric?: boolean,
    init_required?: boolean,
    file: File,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
    onComplete: (compiledContractId: string) => void,
}) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('membership_id', membershipId);
    formData.append('description', description);
    if (isFabric) {
        formData.append('init_required', init_required.toString());
    }
    formData.append('file', file!, file.name); // must be the last thing we append
    const url = `/api/ui/v2/contractcompiler/consortia/${consortium_id}/contracts/${contract_id}/compiled_contracts`
    let compiledContract;
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'kaleido-custom-content-type': 'true'
            },
        })
        // if compiler call failed, show error
        if (!response?.ok) {
            const erra = await response.json()
            ErrorSnackbarCatcher(erra, setErrorMessage)
            return
        }

        compiledContract = await response.json();
    } catch(err) {
        ErrorSnackbarCatcher(err, setErrorMessage)
        return;
    } finally {
        setLoading(false);
    }
    onComplete(compiledContract._id);
}

interface Props {
    loading: boolean,
    file?: File | null,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    accept: string,
};

export const UploadBinary = ({ loading, file, setFile, accept }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationUploadBinary', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationUploadBinary:${key}`, interpolate)

    return (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('selectFileHeader')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('selectFileDescription')}
                </Typography>
            </Grid>
            <Grid item container alignItems="center" spacing={3}>
                <Grid item  xs={3}>
                    <Typography noWrap>{file ? file.name : lt('noFileSelected')}</Typography>
                </Grid>
                <Grid item >
                    <AttachFileIcon />
                </Grid>
                <Grid item>
                    <input
                        accept={accept}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={e => { setFile(e?.target?.files?.length ? e.target.files[0] : null)} }
                        />
                    <label htmlFor="raised-button-file">
                        <Button color="secondary" size="large" variant="contained" component="span" disabled={loading}>
                            {lt('selectFile')}
                        </Button>
                    </label>
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    selectFile: string,
    selectFileHeader: string,
    selectFileDescription: string,
    upload: string,
    noFileSelected: string,
}
const enTranslations: translations = {
    selectFile: 'Select file',
    selectFileHeader: 'Select a file',
    selectFileDescription: 'Select the file to upload',
    upload: 'Upload',
    noFileSelected: 'No file selected',
}
