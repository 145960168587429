import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmptyState } from '.';
import { ConsortiumResourceVars } from '../../interfaces';
import { EnvironmentData, EnvironmentQuery } from '../../models';

export type ComingSoonPage = 'appsIntegrationsDashboard' | 'blockchainFirewall' |
    'b2bDashboard' | 'digitalAssetsDashboard' | 'tokenExplorer' | 'eventStreams' |
    'dataExplorer' | 'offChainTransfer' | 'keyManagementDashboard'

interface Props {
    page: ComingSoonPage,
}

export const ComingSoon = ({ page }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ComingSoon', enTranslations);

    const { consortium_id, environment_id } = useParams<any>();

    const {
        data: { 
            environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        skip: !environment_id,
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const isCorda = environment?.isCorda;
    const isEth = environment?.isEthereum

    // theres no typesafe translation here, so when adding new ones we cant rely on the compiler!
    const lt = (key: string, interpolate?: object) => t(`ComingSoon:${key}`, interpolate);

    return (
        <EmptyState imageFile='Empty-ComingSoon.svg'
            title={lt(!isEth ? 'titleNonEth' : 'title', { feature: lt(page), provider: isCorda ? lt('corda') : lt('fabric') })}
            description={lt('description', { activity: lt(page + 'Activity') })} />
    )
};

const enTranslations = {
    title: 'The new version of {{feature}} will be coming shortly!',
    titleNonEth: '{{feature}} is coming soon for {{provider}} environments',
    corda: 'Corda',
    fabric: 'Fabric',
    description: 'We are working on an new section that delivers a complete end-to-end experience for {{activity}}.',

    dataExplorer: 'Data Explorer',
    dataExplorerActivity: 'managing your transactions and data',

    appsIntegrationsDashboard: 'Apps & Integrations dashboard',
    appsIntegrationsDashboardActivity: 'managing apps and integrations activities',

    blockchainFirewall: 'Blockchain Application Firewall',
    blockchainFirewallActivity: 'setting up and configuring an application level firewall for your blockchain',

    b2bDashboard: 'The B2B dashboard',
    b2bDashboardActivity: 'securely storing and transferring documents and messages',

    digitalAssetsDashboard: 'Digital Assets dashboard',
    digitalAssetsDashboardActivity: 'creating, managing, and even atomically swapping digital assets',

    tokenExplorer: 'Token Explorer',
    tokenExplorerActivity: 'creating and managing tokens',

    eventStreams: 'Event Streams',
    eventStreamsActivity: 'creating and managing event streams',

    offChainTransfer: 'Off-chain Application Messaging and Document Transfer',
    offChainTransferActivity: 'securely storing and transferring documents and messages',

    keyManagementDashboard: 'The Key Management dashboard',
    keyManagementDashboardActivity: 'managing transaction signing keys'
}
