import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const AddRegionsHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'RegionsAddRegionsHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`RegionsAddRegionsHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Network-DeploymentRegion.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks/#clouds-regions"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Deployment regions',
    helpDescription: 'A network can be configured to whitelist one or more regions across the different cloud providers.',
    helpDesc1: 'Resources (nodes and services) can be created and deployed on any of the consortia\'s whitelisted cloud regions',
    helpDesc2: 'Easily integrate with existing cloud suites and application stacks',
}