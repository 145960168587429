import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Environment, 
    UpdateEnvironmentVars,
    UpdateEnvironmentMutation} from '../../models'
import { FormDialog } from '../../components/DialogWrappers'

interface Props {
    environment: Environment
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
};

export const EnvironmentPauseResume = ({ environment, open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentPauseResume', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentPauseResume:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!,
        id: environment_id!
    }

    const [updateEnvironment] = useMutation<Environment, UpdateEnvironmentVars>(UpdateEnvironmentMutation)

    if (environment.state === "paused") {
        return (
            <FormDialog dataTestId="resumeEnvironment"
                open={open}
                setOpen={setOpen} 
                header={lt('resumeEnvironment', {name: environment.name})} 
                description={lt('resumeDescription')} 
                onSave={() => updateEnvironment({
                    variables: {...{ environment: { state: 'live'} }, ...environmentVars}
                }).then(() => {})}
                closeDialogAfterSave
                saveText={lt('resume')} />
        )
    }
    else {
        return (
            <FormDialog dataTestId="pauseEnvironment"
                open={open}
                setOpen={setOpen}
                header={lt('pauseEnvironment', {name: environment.name})} 
                description={lt('pauseDescription')} 
                onSave={() => updateEnvironment({
                    variables: {...{ environment: { state: 'paused'} }, ...environmentVars}
                }).then(() => {})}
                closeDialogAfterSave
                saveText={lt('pause')} />
        )
    }
};

interface translations {
    pauseEnvironment: string,
    pauseDescription: string,
    pause: string,
    resumeEnvironment: string,
    resumeDescription: string,
    resume: string,
}
const enTranslations: translations = {
    pauseEnvironment: 'Pause this environment ({{name}})?',
    pauseDescription: 'All nodes in this environment will be put to sleep and will remain dormant until the environment is resumed.',
    pause: 'Pause',
    resumeEnvironment: 'Start this environment ({{name}})?',
    resumeDescription: 'Starting this environment will wake up all nodes within.',
    resume: 'Start',
}