import React from "react";
import {
    Grid,
    TextField,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    MenuItem,
} from "@material-ui/core";
import { FullNodeConfigurationDetails } from "../../../models/configs";
import { FieldElement, Controller, Control, FieldError, NestDataObject } from "react-hook-form";
import { useTranslation } from "react-i18next";

type inputType = "int" | "string" | "boolean" | "stringArray" | "dropdown";

export interface InputList {
    type: inputType;
    multiline?: boolean;
    optional?: boolean;
    label: string;
    name: keyof FullNodeConfigurationDetails;
    options?: { value: string; label: string }[];
    pattern?: RegExp;
    xs?: boolean | 3 | 1 | 2 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto" | undefined
    md?: boolean | 3 | 1 | 2 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto" | undefined
    min?: number
    max?: number
}

interface Props {
    register: {
        <
            Element extends FieldElement<FullNodeConfigurationDetails> = FieldElement<
                FullNodeConfigurationDetails
            >
        >(): (ref: Element | null) => void;
    };
    inputList: InputList[];
    control: Control<FullNodeConfigurationDetails>;
    errors: NestDataObject<FullNodeConfigurationDetails, FieldError>
}

export const ConfigForm = ({ register, inputList, control, errors }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "ConfigForm", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`ConfigForm:${key}`, interpolate);

    const stringField = (entry: InputList) => (
        <Grid
            key={`field-${entry.name}`}
            item
            xs={entry.xs || 12}
            md={entry.md || 6}
        >
            <TextField
                multiline={entry.multiline}
                rowsMax={4}
                data-test={`textField_${entry.name}`}
                fullWidth
                autoComplete="off"
                required={!entry.optional}
                label={entry.label}
                inputRef={register}
                variant="outlined"
                name={entry.name}
            />
        </Grid>
    );

    const getIntError = (entry: InputList) => {
        if (errors[entry.name]) {
            if(errors[entry.name]?.type  === 'min') {
                return lt('errorMin', {value: entry.min});
            } else if(errors[entry.name]?.type  === 'max') {
                return lt('errorMax', {value: entry.max});
            }
        }
    }

    const intField = (entry: InputList) => (
        <Grid
            key={`field-${entry.name}`}
            item
            xs={entry.xs || 12}
            md={entry.md || 6}
        >
            <Controller
                control={control}
                rules={
                    {
                        min: entry.min,
                        max: entry.max
                    }
                }
                as={
                    <TextField
                        data-test={`textField_${entry.name}`}
                        fullWidth
                        type="number"
                        autoComplete="off"
                        required={!entry.optional}
                        label={entry.label}
                        variant="outlined"
                        helperText={getIntError(entry)}
                        error={!!errors[entry.name]}
                    />
                }
                onChange={([event]) => {
                    return parseInt(event.target.value);
                }}
                name={entry.name}
            />
        </Grid>
    );

    const booleanField = (entry: InputList) => (
        <Grid
            key={`field-${entry.name}`}
            item
            xs={entry.xs || 12}
            md={entry.md || 6}
        >
            <FormControl component="fieldset">
                <FormGroup>
                    <Controller
                        control={control}
                        as={  
                            <FormControlLabel 
                                control={<Checkbox
                                    required={!entry.optional}
                                    color="primary"
                                />}
                            label={entry.label}
                            />
                        }
                        name={entry.name}
                        defaultValue={false}
                    />
                </FormGroup>
            </FormControl>
        </Grid>
    );

    const dropdownField = (entry: InputList) => (
        <Grid
            key={`field-${entry.name}`}
            item
            xs={entry.xs || 12}
            md={entry.md || 6}
        >
            <Controller
                control={control}
                as={
                    <TextField
                        required={!entry.optional}
                        select
                        variant="outlined"
                        fullWidth
                        label={entry.label}
                    >
                        {entry.options &&
                            entry.options.map((option, index) => (
                                <MenuItem
                                    key={`${index}-${entry.name}`}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                    </TextField>
                }
                defaultValue={entry.options ? entry.options[0].value : ""}
                name={entry.name}
            />
        </Grid>
    );

    const getField: Map<inputType, (entry: InputList) => JSX.Element> = new Map(
        [
            ["int", intField],
            ["string", stringField],
            ["boolean", booleanField],
            ["stringArray", stringField],
            ["dropdown", dropdownField],
        ]
    );

    return (
        <>
            {inputList.map((entry) =>
                getField.get(entry.type as inputType)!(entry)
            )}
        </>
    );
};

interface translations {
    errorMin: string
    errorMax: string
}
const enTranslations: translations = {
    errorMin:  'Value can not be less than {{value}}',
    errorMax: 'Value can not be more than {{value}}'
};