import { useQuery } from '@apollo/client';
import MemoryIcon from 'mdi-react/MemoryIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ConsortiumResourceVars, SideNavProps } from '../../../interfaces';
import { EnvironmentData, EnvironmentQuery, FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars } from '../../../models';
import { NavItem } from '../../NavComponents/NavItem';
import { LeftNavTab, SubNavs } from './SubNavs';

export const SECURITY_BASE_PATH = 'security'
export const SECURITY_APPCREDS_PATH = 'appcreds';
export const SECURITY_OAUTH_CONFIGURATION_PATH = 'oauth'
export const CREATE_OAUTH_CONFIGURATION_PATH = 'create'
export const SECURITY_NETWORK_CONTROL_PATH = 'networkcontrol'
export const SECURITY_BAF_PATH = 'baf'
export const SECURITY_BAF_RULESET_PATH = 'ruleset'
export const SECURITY_BAF_MAPPINGS_PATH = 'mappings'
export const CREATE_TUNNELER_PATH = 'create';
export const ATTACH_BAF_PATH = 'attach';
export const CREATE_BAF_PATH = 'create';

export const Security = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Security', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Security:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const BAF = lt('baf')
    const APPCREDS = lt('appCreds')
    const OAUTH = lt('oauth')
    const NETWORKCONTROL = lt('networkControl')

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    let {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-and-network'
    });

    const historyPusher = useCallback((path?: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}${path ? `/${path}` : ''}`), 
        [history, org_id, consortium_id, environment_id])
    
    useEffect(() => { 
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

         if (pathname.includes(`${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`)) {
            setSelectedItem(BAF)
            setActiveAndSelected()
        } else if (pathname.includes(`${SECURITY_BASE_PATH}/${SECURITY_APPCREDS_PATH}`)) {
            setSelectedItem(APPCREDS)
            setActiveAndSelected()
        } else if (pathname.includes(`${SECURITY_BASE_PATH}/${SECURITY_OAUTH_CONFIGURATION_PATH}`)) {
            setSelectedItem(OAUTH)
            setActiveAndSelected()
        } else if (pathname.includes(`${SECURITY_BASE_PATH}/${SECURITY_NETWORK_CONTROL_PATH}`)) {
            setSelectedItem(NETWORKCONTROL)
            setActiveAndSelected()
        } else {
            setSelectedItem('')
            setActiveAndSelected(false)
        }
    }, [pathname, setSelected, BAF, APPCREDS, OAUTH, NETWORKCONTROL])

    const appsSubItems = useCallback(() => {
        let items : LeftNavTab[] = [
            {
                name: NETWORKCONTROL,
                action: () => historyPusher(SECURITY_NETWORK_CONTROL_PATH)
            },
            {
                name: APPCREDS,
                action: () => historyPusher(SECURITY_APPCREDS_PATH)
            }
        ];

        if (!isCorda && !isFabric) items = items.concat([
            {
                name: OAUTH,
                action: () => historyPusher(SECURITY_OAUTH_CONFIGURATION_PATH)
            },
            {
                name: BAF,
                action: () => historyPusher(SECURITY_BAF_PATH),
                comingSoon: !featureToggles.baf
            },
        ]);
        
        return items;
    }, [historyPusher, featureToggles, isCorda, isFabric, BAF, APPCREDS, OAUTH, NETWORKCONTROL])

    return (
        <>
            <NavItem icon={<MemoryIcon />}
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={appsSubItems()} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations {
    name: string
    security: string,
    baf: string,
    appCreds: string,
    oauth: string,
    networkControl: string
}

const enTranslations: translations = {
    name: 'Security',
    security: 'Security',
    baf: 'Application Firewall',
    appCreds: 'App Creds',
    oauth: 'OAuth',
    networkControl: 'Network Control'
}
