import { useMutation } from '@apollo/client';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { BillingTypeEnum, BillingTypes, OpsUpdateOrganizationMutation, OpsUpdateOrganizationVars, Organization, PlanName, PlanNames } from '../../models';

interface Props {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    organization: Organization
    onSave: () => void
}

export const OpsUpdateOrg: React.FC<Props> = ({open,setOpen,organization, onSave}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsUpdateOrg', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsUpdateOrg:${key}`, interpolate)

    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [plan, setPlan] = useState<PlanName>('signup');
    const [supportLevel, setSupportLevel] = useState<Number>(0);
    const [billingType, setBillingType] = useState<keyof typeof BillingTypeEnum>('none');

    const [opsUpdateOrganizationMutation, {loading}] = useMutation<Organization, OpsUpdateOrganizationVars>(OpsUpdateOrganizationMutation);

    useEffect(() => {
        setName(organization.name);
        setPlan(organization.plan);
        setSupportLevel(organization.support_level);
        setBillingType(organization.billing_account?.type as keyof typeof BillingTypeEnum || 'none');
    }, [organization])

    const doUpdateOrg = async () => {
        try {
            await opsUpdateOrganizationMutation({
                variables: {
                    id: organization._id,
                    opsUpdates: {
                        billing_account: {
                            type: billingType,
                            agreement: organization.billing_account?.agreement // Not editable here, but must preserve
                        },
                        name,
                        plan,
                        support_level: supportLevel
                    }
                }
            });
            onSave();
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    const getSupportLabel = (supportLevel: number) => {
        switch (supportLevel) {
            case 300: return `${lt('priority')} (${supportLevel})`;
            case 200: return `${lt('select')} (${supportLevel})`;
            case 100: default: return `${lt('basic')} (${supportLevel})`;
        }
    };

    const controlsWrapper = (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextField fullWidth variant="outlined" value={name} onChange={e => setName(e.target.value)} label={lt('name')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={plan} onChange={e => setPlan(e.target.value as PlanName)} select label={lt('plan')}>
                    {PlanNames.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={billingType} onChange={e => setBillingType(e.target.value as keyof typeof BillingTypeEnum)} select label={lt('billingType')}>
                    {BillingTypes.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={supportLevel} onChange={e => setSupportLevel(parseInt(e.target.value))} select label={lt('supportLevel')}>
                    {[100,200,300].map(s => <MenuItem key={s} value={String(s)}>{getSupportLabel(s)}</MenuItem>)}
                </TextField>
            </Grid>
        </Grid>
    )

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog
            {...{open}}
            {...{setOpen}}
            header={lt('title')}
            description={lt('description')}
            onSave={doUpdateOrg}
            saveDisabled={loading}
            closeDialogAfterSave={true}
            {...{controlsWrapper}}
        />
    </>;
};

interface translations {
    title: string;
    description: string;
    name: string;
    plan: string;
    billingType: string;
    supportLevel: string;
    priority: string;
    select: string;
    basic: string;
}
const enTranslations: translations = {
    title: 'Update org',
    description: 'Ops updatable organization fields',
    name: 'Name',
    plan: 'Plan',
    billingType: 'Billing Type',
    supportLevel: 'Support Level',
    priority: 'Priority',
    select: 'Business Select',
    basic: 'Basic',
}
