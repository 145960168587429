import React from "react";
import {
    CopyableSettings,
    CopyableSetting,
} from "../../components/DisplaySettings";
import { useTranslation } from "react-i18next";
import { TetherStatusData } from "../../models/servicesStatus";
import { convertWeiToEther } from "../../utils/StringUtils";

interface Props {
    network: "goerli" | "mainnet";
    tetherStatus: TetherStatusData["tetherStatus"];
}

export const CostSummary = ({ network, tetherStatus }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CostSummary", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CostSummary:${key}`, interpolate);

    const copyableList: CopyableSetting[] = [
        {
            title: lt("totalGasSpent"),
            displayValue: tetherStatus.tx_stats[network]
                ? tetherStatus.tx_stats[network]!.total_gas_used.toLocaleString(
                      "en"
                  )
                : "--",
            disableCopy: true,
        },
        {
            title: lt("lastTransactionPrice"),
            displayValue: tetherStatus.tx_stats[network]
                ? convertWeiToEther(
                      tetherStatus.tx_stats[network]!.last_tx_price_wei
                  )
                : "--",
            disableCopy: true,
        },
        {
            title: lt("totalEthSpent"),
            displayValue: tetherStatus.tx_stats[network]
                ? convertWeiToEther(
                      tetherStatus.tx_stats[network]!.total_eth_used_wei
                  )
                : "--",
            disableCopy: true,
        },
        {
            title: lt("totalTransactionCount"),
            displayValue: tetherStatus.tx_stats[network]
                ? tetherStatus.tx_stats[network]!.total_tx_count.toString()
                : "--",
            disableCopy: true,
        },
    ];

    return (
        <CopyableSettings
            header={lt("costSummary", { network: lt(network) })}
            {...{ copyableList }}
        />
    );
};

interface translations {
    goerli: string;
    mainnet: string;
    costSummary: string;
    totalGasSpent: string;
    lastTransactionPrice: string;
    totalEthSpent: string;
    totalTransactionCount: string;
    protectedBy: string;
}
const enTranslations: translations = {
    mainnet: "Mainnet",
    goerli: "Goerli",
    totalEthSpent: "Total Eth Spent",
    totalGasSpent: "Total Gas Spent (Wei)",
    totalTransactionCount: "Total Transaction Count",
    costSummary: "Cost Summary - {{network}}",
    lastTransactionPrice: "Last Transaction Price (ETH)",
    protectedBy: "Protected by {{network}}",
};
