import { useQuery } from '@apollo/client';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import LogoutIcon from 'mdi-react/LogoutIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContactInfoData, ContactInfoQuery } from '../../models/contactInfo';
import { cognitoController } from '../../utils/cognitoController';
import { DisplayPopover } from '../DisplayWrappers';
import { NavItem } from '../NavComponents/NavItem';
import { PROFILE_SETTINGS_PATH } from '../ProfileSettingsNav/ProfileSettingsNav';

interface Props {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    anchorRef: React.RefObject<HTMLElement>
};

const handleLogout = async () => {
    await cognitoController.logout('/login');
}

export const AccountWrapper = ({ open, setOpen, anchorRef }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AccountWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AccountWrapper:${key}`, interpolate);
    const history = useHistory();
    
    const {
        data: {
            contactInfo
        } = { contactInfo: null }
    } = useQuery<ContactInfoData>(ContactInfoQuery);

    const getContactName = () => {
        if (contactInfo?.firstname && contactInfo.lastname) {
            return lt('contactName', { firstName: contactInfo.firstname, lastName: contactInfo.lastname });
        }
        return contactInfo?.firstname || contactInfo?.lastname || '';
    }

    return (
        <>
            <AccountCircleIcon />
            <DisplayPopover popoverOpen={open} anchorRef={anchorRef} setPopoverOpen={setOpen} placement='right'>
                <List disablePadding>
                    <ListItem>
                        <ListItemText
                            primary={getContactName()}
                            secondary={contactInfo?._id} />
                    </ListItem>
                        <Divider />
                        <NavItem
                            icon={<CardAccountDetailsOutlineIcon />}
                            header={lt('accountSettings')}
                            action={() => history.push(`/${PROFILE_SETTINGS_PATH}`)}
                        />
                        <NavItem
                            icon={<AccountGroupIcon />}
                            header={lt('manageOrgs')}
                            action={() => history.push('/orgs')}
                        />
                    <Divider />
                    <NavItem
                        icon={<LogoutIcon />}
                        header={lt('logout')}
                        action={handleLogout}
                    />
                </List>
            </DisplayPopover>
        </>
    );
}

interface translations {
    contactName: string
    accountSettings: string
    manageOrgs: string
    logout: string
}
const enTranslations: translations = {
    contactName: '{{firstName}} {{lastName}}',
    accountSettings: 'Profile Settings',
    manageOrgs: 'Manage Orgs',
    logout: 'Logout'
}