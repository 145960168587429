import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { EnvironmentResourcesVars } from '../interfaces'
import { OptionalMembershipOwnedResource } from './memberships';

// this should be an autogenerated interface from the EnvironmentZone model in graphql
export interface EnvironmentZone extends OptionalMembershipOwnedResource {
    _id: string
    environment_id: string
    cloud?: string
    cm_host: string
    default: boolean
    multi_region_compatible: boolean
    name: string
    net_host: string
    region?: string
    type: 'kaleido' | 'private'
    x_host: string
    flag: string
    state: string
    bridge_id: string
    displayName: string
}

export interface CreateEnvironmentZoneVars extends EnvironmentResourcesVars {
    environmentZone: {
        region?: EnvironmentZone['region'],
        cloud?: EnvironmentZone['cloud'],
        type: EnvironmentZone['type'],
        bridge_id?: EnvironmentZone['bridge_id'],
    }
}

export interface CreateEnvironmentZoneData {
    createEnvironmentZone: EnvironmentZone
}

export interface EnvironmentZonesData {
    environmentZones: EnvironmentZone[]
}

export interface EnvironmentZoneData {
    environmentZone: EnvironmentZone
}

export interface EnvironmentZoneVars extends EnvironmentResourcesVars {
    id: string
}

// FRAGMENTS

export const EnvironmentZoneFields = ` 
    fragment EnvironmentZoneFields on EnvironmentZone {
        _id
        environment_id
        cloud
        cm_host
        default
        multi_region_compatible
        name
        net_host
        region
        type
        x_host
        flag
        state
        bridge_id
        membership_id
        displayName @client
        membership @client
    }`

// MUTATIONS
export const CreateEnvironmentZoneMutation = gql`
    ${EnvironmentZoneFields}
    mutation createEnvironmentZone($consortia_id: String!, $environment_id: String!, $environmentZone: CreateEnvironmentZoneInput!) {
        createEnvironmentZone(consortia_id: $consortia_id, environment_id: $environment_id, environmentZone: $environmentZone) {
            ...EnvironmentZoneFields
        }
    }`;

// QUERIES & SUBSCRIPTIONS

export const EnvironmentZonesQuery = gql`
    ${EnvironmentZoneFields}
    query getEnvironmentZones($consortia_id: String!, $environment_id: String!) {
        environmentZones(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...EnvironmentZoneFields
        }
    }`;

export const EnvironmentZoneQuery = gql`
    ${EnvironmentZoneFields}
    query getEnvironmentZone($consortia_id: String!, $environment_id: String!, $id: String!) {
        environmentZone(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...EnvironmentZoneFields
        }
    }`;

export const EnvironmentZonesSubscription = gql`
    ${EnvironmentZoneFields}
    subscription onEnvironmentZonesChanged($consortia_id: String!, $environment_id: String!) {
        environmentZone(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...EnvironmentZoneFields
        }
    }`;

// MUTATION OPTIONS
export function MakeEnvironmentZoneCreateMutationOptions(
    variables: CreateEnvironmentZoneVars,
    overrideOptions?: MutationFunctionOptions<CreateEnvironmentZoneData, CreateEnvironmentZoneVars>): 
        MutationFunctionOptions<CreateEnvironmentZoneData, CreateEnvironmentZoneVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const zone = data?.createEnvironmentZone
            if (zone) {
                // try/catch because for initial environment creation the environment zone subscription is not active so cache is empty
                // it will be queried immediately afterwards as part of the environment graphql startup life cycle
                try {
                    const { environmentZones } = cache.readQuery<EnvironmentZonesData>({ query: EnvironmentZonesQuery, variables })!;
                    // if subscription wrote it to the cache first, then we dont need to write it in here.
                    // (the already added cached entry will be auto updated with the result of the mutation)
                    if (!environmentZones.find(z => z._id === zone._id)) {
                        cache.writeQuery({
                            variables,
                            query: EnvironmentZonesQuery,
                            data: { environmentZones: environmentZones.concat(zone) },
                        });
                    }
                } catch(err) {
                    console.log('Error during create environment zone cache update')
                }
            }
        }
    }, ...overrideOptions }
}

// SUBSCRIPTION OPTIONS

export function MakeEnvironmentZonesSubscriptionOptions(
    variables: EnvironmentResourcesVars, 
    overrideOptions?: SubscriptionHookOptions<EnvironmentZoneData>): SubscriptionHookOptions<EnvironmentZoneData> {
    return { ...{
        variables,
        onSubscriptionData: ({client, subscriptionData}) => {
            // Get the environmentZone object from the event
            const { 
                data: { 
                    environmentZone
                } = { environmentZone: null }
            } = subscriptionData

            if (!environmentZone) return

            // Get current list of environmentZones from the cache
            // assert that environmentZones must exist since this function throws if it doesnt
            const { environmentZones: existingEnvironmentZones } = client.readQuery<EnvironmentZonesData>({ 
                query: EnvironmentZonesQuery, 
                variables 
            })!
            
            // if it already exists in the store, (its an update), apollo automagically updated the cache
            const cachedEnvironmentZone = existingEnvironmentZones.find(c => c._id === environmentZone._id)

            // but, if it came back as deleted, we need to remove it from the cache manually
            if (cachedEnvironmentZone) {
                if (cachedEnvironmentZone.state === 'deleted') {
                    client.cache.evict({id: `EnvironmentZone:${cachedEnvironmentZone._id}`, broadcast: false})
                }
                client.writeQuery<EnvironmentZonesData>({ 
                    query: EnvironmentZonesQuery, 
                    data: Object.assign({}, existingEnvironmentZones, {
                        environmentZones: [...existingEnvironmentZones.filter(c => c.state !== 'deleted')]
                    }),
                    variables
                })
                return 
            }

            // in case of duplicate events, never add a deleted item into the cache
            if (environmentZone.state === 'deleted') return

            // if it doesnt exist in the store, (it's been added), so we need to add it in manually
            client.writeQuery<EnvironmentZonesData>({ 
                query: EnvironmentZonesQuery, 
                data: Object.assign({}, existingEnvironmentZones, {
                    environmentZones: [environmentZone, ...existingEnvironmentZones]
                }),
                variables
            })
        }
    }, ...overrideOptions }
}

