import { useQuery } from '@apollo/client';
import { Button, Grid, MenuItem, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ServiceResourcesVars } from '../../interfaces';
import { Account, AccountsData, AccountsQuery, AccountTypeTranslation, AccountTypeTranslationInterface, ServicesData, ServicesQuery } from '../../models';
import { HDWalletsData, HDWalletsQuery } from '../../models/hdwallet';
import { FormDialog } from '../DialogWrappers';
import { DisplayGridWrapper, DisplayTable, ToolBarOptions } from '../DisplayWrappers';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { ShortenedHash } from '../FormControls/ShortenedHash';
import { FetchAccount } from '../HDWallet/FetchAccount';


interface Props {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedAccount:  React.Dispatch<React.SetStateAction<Account | undefined>>,
    selectedAccount?: Account,
    preSelectedAddress?: string,
    isMine?: boolean
    membershipId?: string
}
export const TableDialog = ({open, setOpen, isMine, selectedAccount, setSelectedAccount, membershipId, preSelectedAddress}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TableDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TableDialog:${key}`, interpolate);

    const columns = [lt('address'), lt('type'), lt('membership'), ''];

    const [typeSelected, setTypeSelected] = useState(selectedAccount?.hdwalletDetails ? 1 : 0);
    const [hdwalletServiceId, setHDWalletServiceId] = useState(selectedAccount?.hdwalletDetails?.serviceId ?? '');
    const [hdwalletWalletId, setHDWalletWalletId] = useState(selectedAccount?.hdwalletDetails?.walletId ?? '');
    const [hdwalletAccountIndex, setHDWalletAccountIndex] = useState(selectedAccount?.hdwalletDetails?.accountIndex ?? '');
    const [hdwalletAccountAddress, setHDWalletAccountAddress] = useState(selectedAccount?._id ?? '');

    useEffect(() => {
        setHDWalletWalletId('')
    }, [hdwalletServiceId]);

    const {
        loading: hdwalletsLoading,
        data: { 
            hdwallets
        } = { hdwallets: null }
    } = useQuery<HDWalletsData, ServiceResourcesVars>(HDWalletsQuery, { 
        variables: { 
            service_id: hdwalletServiceId
        },
        fetchPolicy: 'cache-and-network',
        skip: !hdwalletServiceId
    });

    //filters
    const [filterMembership, setFilterMembership] = useState('');
    const [filterAccountType, setFilterAccountType] = useState('');

    //search
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const {consortium_id, environment_id} = useParams<any>();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    let {
        stopPolling: stopPollingAccounts,
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData>(AccountsQuery, {
        variables: environmentVariables,
        fetchPolicy: 'cache-and-network',
        pollInterval: 5000
    });

    const {
        data: { 
            services
        } = { services: [] } 
    } = useQuery<ServicesData>(ServicesQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const hdwalletServices = services.filter(s => s.service === 'hdwallet' && 
        s.state === 'started' && s.membership.isMine && s.membership_id === (membershipId ?? s.membership_id))

    //default filters
    accounts = useMemo(() => {
        return accounts.filter(account => {
            const ownershipCondition = isMine ? account.membership.isMine : true;
            const membershipCondition = membershipId ? account.membership_id === membershipId : true
            const systemMonitor = account.membership_id !== 'sys--mon';
            const deactivatedAccount = account.deactivated;
            return ownershipCondition && membershipCondition && systemMonitor && !deactivatedAccount;
        });
    }, [accounts, isMine, membershipId]);

    const displayAccounts: Account[] = useMemo(() => {
        if (filterMembership || filterAccountType || (isSearchActive && searchInput)) {
            return accounts.filter(account => {
                const membershipCondition = filterMembership ? account.membership.name === filterMembership : true;
                const accountTypeCondition = filterAccountType ? account.account_type === filterAccountType : true;
                const searchCondition = isSearchActive && searchInput ? account._id.includes(searchInput) : true;
                return membershipCondition && accountTypeCondition && searchCondition
            })
        }
        return accounts;
    }, [accounts, filterMembership, filterAccountType, isSearchActive, searchInput]);

    useEffect(() => {
        if (preSelectedAddress) {
            const undercaseAddress = preSelectedAddress.toLowerCase();
            const account = accounts.find(c => c._id === undercaseAddress);
            if (account) {
                setSelectedAccount(account)
            }
        }
    }, [preSelectedAddress, accounts, setSelectedAccount]);

    useEffect(() => {
        if (accounts.length) {
            stopPollingAccounts()
        }
    }, [accounts, stopPollingAccounts])

    const records: DisplayTableRecord[] = displayAccounts.map(account => ({
        columns: [{
            value: <ShortenedHash showFullAddress address={account._id} />
        }, {
            value: lt(account.account_type) 
        }, {
            value: account.membership.name
        }, {
            value: <Button data-test='button_selectAccount' variant="outlined" onClick={() => {setSelectedAccount(account); setOpen(false);}}>{lt('select')}</Button>
        }]
    }))

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTypeSelected(newValue);
    };

    const onFilterSubmit = (options: {[key: string]: string}) => {
        setFilterMembership(options[lt('membership')]);
        setFilterAccountType(options[lt('walletType')])
    };

    const onSearchSubmit = (searchInput: string) => {
        setSearchInput(searchInput);
    }

    const getMembershipsOptions = () => {
        return accounts.reduce((acc: string[], curr) => {
            if (acc.indexOf(curr.membership.name) < 0 ) acc.push(curr.membership.name);
            return acc;
        }, [])
    }

    const getWalletTypeOptions = () => {
        return accounts.reduce((acc: string[], curr) => {
            if (acc.indexOf(curr.account_type) < 0 ) acc.push(curr.account_type);
            return acc;
        }, [])
    }

    const toolBarOptions: ToolBarOptions = {
        headerTab: {
            onChange: handleChange,
            selected: typeSelected,
            tabs: [{
                label: lt('signingAccounts'),
                action: () => setTypeSelected(0) 
            }, {
                label: lt('hdWallets'),
                action: () => setTypeSelected(1), 
            }]
        },
        filter: {
            onSubmit: onFilterSubmit,
            inputs: [{
                label: lt('membership'),
                options: getMembershipsOptions()
            }, {
                label: lt('walletType'),
                options: getWalletTypeOptions()
            }]
        },
        search: {
            label: lt('searchAddress'),
            setSearchActive: setIsSearchActive,
            onSearch: onSearchSubmit
        }
    }

    const serviceAndWalletSelector = (
        <Grid item container direction="column" spacing={3}>
            <Grid item container spacing={3} alignItems="center">
                <Grid item container xs={6}>
                    <TextField select required
                        fullWidth
                        label={lt('selectHDWallets')} 
                        value={hdwalletServiceId} 
                        variant="outlined" 
                        onChange={e => setHDWalletServiceId(e.target.value)}>
                        {hdwalletServices.map((entry) => (
                            <MenuItem key={entry._id} value={entry._id}>{`${entry.name} (${entry._id})`}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item container xs={6}>
                    {hdwallets?.wallets && hdwallets.wallets.length === 0 ?
                    <Typography>{lt('noHDWallet')}</Typography> :
                    <TextField select disabled={!hdwalletServiceId || hdwalletsLoading} required
                        fullWidth
                        label={lt('selectHDWallet')} 
                        value={hdwalletWalletId} 
                        variant="outlined" 
                        onChange={e => setHDWalletWalletId(e.target.value)}>
                        {/* avoids material ui warning of select Textfield with no children */}
                        {!hdwallets?.wallets.length ? <MenuItem value={''}>{''}</MenuItem> : null}

                        {hdwallets?.wallets.map((w) => (
                            <MenuItem key={w} value={w}>{w}</MenuItem>
                        ))}
                    </TextField>
                    }
                </Grid>
            </Grid>
            <Grid item>
                <FetchAccount isSigningWidget 
                    prefilledIndex={hdwalletAccountIndex}
                    setAccountIndex={setHDWalletAccountIndex} 
                    setAccountAddress={setHDWalletAccountAddress} 
                    serviceId={hdwalletServiceId} 
                    walletId={hdwalletWalletId} 
                    setWalletId={setHDWalletWalletId} />
            </Grid>
            <Grid item>
                <Button data-test='button_selectAccount' color="primary" variant="contained" size="large" disabled={!hdwalletAccountIndex}
                    onClick={() => {
                        setSelectedAccount({
                            _id: hdwalletAccountAddress, 
                            hdwalletDetails: {
                                serviceId: hdwalletServiceId,
                                walletId: hdwalletWalletId,
                                accountIndex: hdwalletAccountIndex
                            },
                            account_type: 'hdwallet',
                            created_at: '',
                            membership_id: hdwalletServices.find(s => s._id === hdwalletServiceId)?.membership_id ?? '',
                            membership: {
                                name: '',
                                isMine: true
                            }
                            })
                        setOpen(false);
                    }}>
                    {lt('selectThisAccount')}
                </Button>
            </Grid>
        </Grid>
    )

    // the height stuff here is hacky but its what were left with for now since one view goes through DisplayTable (which further manipulates the height)
    // and the other goes direct through DisplayGridWrapper so needs to roughly match the height of DisplayTable to make switching tabs less jumpy
    const makeControls = (type: number) => {
        if (type === 0 || (type === 1 && !hdwalletServices.length)) {
            return <DisplayTable height="50vh" records={type === 0 ? records : []} columnHeaders={columns} {...{toolBarOptions}} stickyHeader emptyLabel={lt('emptyTable')} />
        } 
        const toolBar = (
            <Grid item container justify="space-between" alignItems="center" wrap="nowrap">
                <Grid item container wrap="nowrap"  alignItems="center">
                    <Tabs value={toolBarOptions.headerTab!.selected} onChange={toolBarOptions.headerTab!.onChange} indicatorColor="primary" textColor="primary">
                        {toolBarOptions.headerTab!.tabs.map(tab => (
                            <Tab key={`tab-${tab.label}`} label={tab.label} disabled={tab.disabled} />
                        ))}
                    </Tabs>
                </Grid>
            </Grid>
        )
        return <DisplayGridWrapper hidePaper height="54vh" displayGrid={serviceAndWalletSelector} padDisplayGrid toolBar={toolBar} />
    }

    return (
        <FormDialog dataTestId="accountSelector" {...{open}} {...{setOpen}} header={isMine ? lt('myAccounts') : lt('availableAccounts') } hideActions 
            disableContentPadding dialogType="medium" controlsWrapper={makeControls(typeSelected)} width="md" />
    )
}

interface translations extends AccountTypeTranslationInterface {
    myAccounts: string
    availableAccounts: string
    address: string
    type: string
    membership: string
    select: string
    hdWallets: string
    signingAccounts: string
    walletType: string
    emptyTable: string
    searchAddress: string
    selectHDWallets: string
    selectHDWallet: string
    selectThisAccount: string
    noHDWallet: string
}

const enTranslations: translations = {
    ...AccountTypeTranslation,
    searchAddress: 'Find Account Address',
    myAccounts: 'My Accounts',
    availableAccounts: 'Available Accounts',
    address: 'Address',
    type: 'Wallet Type',
    membership: 'Membership',
    select: 'Select',
    hdWallets: 'HD Wallets',
    signingAccounts: 'Signing Accounts',
    walletType: 'Wallet Type',
    emptyTable: 'No Accounts Found',
    selectHDWallets: 'Select HD Wallets',
    selectHDWallet: 'Select HD Wallet ID',
    selectThisAccount: 'Select This Account',
    noHDWallet: 'No HD Wallets have been created.'
};