import React, { useState, useEffect } from 'react';
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher, Help } from '../../../components/DialogWrappers';
import { Grid, Typography, TextField, MenuItem, Switch, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CreateStepProps } from '../../../interfaces';
import { BAFPolicyMapping, BAFPolicyClaimMatcher } from '../BAFPolicySchema';
import { ClaimMatcher } from './ClaimMatcher';

interface Props extends CreateStepProps {
    mapping: BAFPolicyMapping,
    setMapping: (m: BAFPolicyMapping) => void,
    appCredsOrJWT: 'appcreds' | 'jwt',
    save: () => Promise<void>,
    loading: boolean,
    rulesets: string[],
    tenantsSet: boolean, 
}

export type BAFPolicyClaimMatcherState = { propName: string,  nested: boolean, stringValue?: string, nestedValue?: BAFPolicyClaimMatcherState[] }

const buildStateForMatcher : (matcher: BAFPolicyClaimMatcher) => BAFPolicyClaimMatcherState[] = (matcher) => {
    return Object.keys(matcher).map(k => ({
        nested: typeof matcher[k] !== 'string',
        propName: k,
        stringValue: typeof matcher[k] === 'string' && matcher[k],
        nestedValue: typeof matcher[k] !== 'string' && buildStateForMatcher(matcher[k] as BAFPolicyClaimMatcher),
    })) as BAFPolicyClaimMatcherState[];
}

const reduceMatcherState = ( claimMatchers: BAFPolicyClaimMatcherState[] ) => {
    let claims = {} as BAFPolicyClaimMatcher;
    claimMatchers.forEach(c => {
        claims[c.propName] = c.nested ? reduceMatcherState(c.nestedValue!) : c.stringValue!;
    });
    return claims;
}

const findInvalid = ( claimMatchers?: BAFPolicyClaimMatcherState[] ) => {
    if (!claimMatchers) return true;
    for (let c of claimMatchers) {
        if (!c.propName) return true;
        if (c.nested && findInvalid(c.nestedValue)) return true;
        if (!c.nested && !c.stringValue) return true;
    }
    return false;
}

const newMatcher = (appCredsOrJWT: 'appcreds' | 'jwt') => {
    return {propName: appCredsOrJWT === 'appcreds' ? 'id' : '', stringValue: '', nested: false}
}

export const Step1 = ({mapping, setMapping, appCredsOrJWT, cancelPath, save, loading, rulesets, tenantsSet}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFMappingEditorStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFMappingEditorStep1:${key}`, interpolate)

    const history = useHistory();

    const [errorMessage, setErrorMessasge] = useState('');

    const [claimMatchers, setClaimMatchers] = useState(buildStateForMatcher(mapping.claims));

    useEffect(() => {
        if (claimMatchers.length === 0) {
            setClaimMatchers([newMatcher(appCredsOrJWT)])
        }
    }, [claimMatchers, appCredsOrJWT]);

    const onNext = async () => {
        try {
            mapping.claims = reduceMatcherState(claimMatchers);
            await save();
            history.push(cancelPath);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    }

    const setMappingComment = (comment: string) => {
        setMapping({
            ...mapping,
            comment
        })
    }

    const setMappingTemplated = (templated: boolean) => {
        setMapping({
            ...mapping,
            templated
        })
    }

    const setMappingRuleset = (ruleset: string) => {
        setMapping({
            ...mapping,
            ruleset
        })
    }

    const incomplete = !mapping.ruleset || findInvalid(claimMatchers);

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">{lt(appCredsOrJWT)}</Typography>
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    label={lt('comment')}
                    value={mapping.comment || ''}
                    variant="outlined" 
                    onChange={event => setMappingComment(event.target.value)}/>
            </Grid>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item xs>
                    <Grid item container direction="column">
                        <Grid item><Typography variant="body1" color="textPrimary">{lt('templated')}</Typography></Grid>
                        <Grid item><Typography variant="body2" color="textSecondary">{lt('templatedDesc')}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Tooltip title={!tenantsSet ? lt('tenantsNotSet') : ''}>
                        <span>
                            <Switch
                                color="primary"
                                checked={mapping.templated ? true : false}                        
                                onChange={event => setMappingTemplated(event.target.checked)}
                                disabled={!tenantsSet}/>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item xs>
                    <Grid item container direction="column">
                        <Grid><Typography variant="body1" color="textPrimary">{lt('ruleset')}</Typography></Grid>
                        <Grid><Typography variant="body2" color="textSecondary">{lt('rulesetDesc')}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <TextField
                        data-test="select_Ruleset"
                        fullWidth
                        value={mapping.ruleset || ''}
                        variant="outlined"
                        onChange={event => setMappingRuleset(event.target.value)}
                        select
                        disabled={!rulesets.length}>
                        {rulesets.map((ruleset,i) =>
                            <MenuItem key={`ruleset-${i}`} value={ruleset}>{ruleset}</MenuItem>
                        )}
                    </TextField>
                </Grid>
            </Grid>
            <ClaimMatcher parentKey="matchers-" {...{claimMatchers}} {...{setClaimMatchers}} newMatcher={() => newMatcher(appCredsOrJWT)} {...{appCredsOrJWT}} />
        </>
    )

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge}/>
            <CreateWrapper cancelPath={cancelPath} disabled={loading || incomplete} {...{content}} {...{onNext}} isFirstStep isLastStep/>
            <Help 
                imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
                header={lt('helpHeader')}
                description={lt('helpDescription')}
                docLink="https://docs.kaleido.io/kaleido-services/baf/mappings/"
                bullet1={appCredsOrJWT === 'appcreds' ? lt('bulletHelp1AppCreds') : lt('bulletHelp1JWT') }
                bullet2={lt('bulletHelp2')}
                bullet3={lt('bulletHelp3')}
            />
        </>
    )
}

interface translations {
    appcreds: string,
    jwt: string,
    comment: string,
    ruleset: string,
    rulesetDesc: string,
    templated: string,
    templatedDesc: string,
    tenantsNotSet: string,

    helpHeader: string,
    helpDescription: string,
    bulletHelp1AppCreds: string,
    bulletHelp1JWT: string,
    bulletHelp2: string,
    bulletHelp3: string,
}
const enTranslations: translations = {
    appcreds: 'Credential Mapping',
    jwt: 'JWT Mapping',
    comment: "Description",
    ruleset: "Ruleset",
    rulesetDesc: "Ruleset to apply to matching connections",
    templated: "Templated",
    templatedDesc: "Auto-generate a mapping for each tenant using templating",
    tenantsNotSet: "Configure a tenants tag to enable templated mappings",

    helpHeader: "Mapping Credentials to Rulesets",
    helpDescription: "Map connections to authorization rulesets, using authenticated credentials",
    bulletHelp1AppCreds: "Map from the ID of the application credential, which does not change when the secret is regenerated",
    bulletHelp1JWT: "Map from fields in the nested heirarchy of claims inside of the verified JSON Web Token, signed by your authorization server",
    bulletHelp2: "Each claim matcher is a regular expression string to apply to the property. See the docs for detailed rules on matching, including handling arrays and other non-string values.",
    bulletHelp3: "If you are using Kaleido multi-tenant nodes with a Tag paired with this application firewall configuration, you can generate a templated rule for each matching tenant under the membership",
}
