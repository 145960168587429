import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers'

export const CloudHsmHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigCloudHsmHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigCloudHsmHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/kaleido-services/cloudhsm/"
            bullet1={lt('bulletHelp1')}
            bullet2={lt('bulletHelp2')}
            bullet3={lt('bulletHelp3')}
            bullet4={lt('bulletHelp4')}
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    bulletHelp1: string,
    bulletHelp2: string,
    bulletHelp3: string
    bulletHelp4: string
}
const enTranslations: translations = {
    helpHeader: 'Cloud HSM',
    helpDescription: 'Kaleido offers a CloudHSM Signer service that allows you to sign transactions using off-platform keys, while benefiting from a fully-managed signing experience including nonce management and REST APIs. The CloudHSM Signer service supports the following backends:',
    bulletHelp1: 'AWS CloudHSM',
    bulletHelp2: 'AWS KMS',
    bulletHelp3: 'Azure Key Vault',
    bulletHelp4: 'Hashicorp Vault with Ethereum Signing extension'
}