import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { AppCredsData, AppCredsQuery } from '../../models';
import { EnvironmentResourcesVars } from '../../interfaces'

interface Props {
    membershipId?: string,
    appCredId: string,
    setAppCredId: React.Dispatch<React.SetStateAction<string>>,
};

export const AppCredSelector = ({ membershipId, appCredId, setAppCredId }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppCredSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppCredSelector:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const { 
        data: { 
            appCreds: allAppCreds
        } = { appCreds: [] } 
    } = useQuery<AppCredsData, EnvironmentResourcesVars>(AppCredsQuery, {
        variables: { consortia_id: consortium_id!, environment_id: environment_id! },
        fetchPolicy: 'cache-only'
    });    

    const appCreds = allAppCreds.filter(a => a.membership.isMine && a.membership_id === (membershipId || a.membership_id) && !a.service_id)

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAppCredId(event.target.value as string);
    };

    useEffect(() => {
        if (!appCredId && appCreds.length) {
            setAppCredId(appCreds.find(n => true)?._id ?? '')
        }
    }, [appCreds, appCredId, setAppCredId])

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} required>
                {lt('selectAppCred')}
            </InputLabel>
            <Select 
                labelWidth={labelWidth}
                value={appCredId}
                onChange={handleChange}>
                {appCreds.map(m => (
                    <MenuItem key={m._id} selected={m._id === appCredId} value={m._id}>
                        {appCreds.find(x => x._id === m._id)?.name ?? ''}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    )
};

interface translations {
    selectAppCred: string
}
const enTranslations: translations = {
    selectAppCred: 'Select App Cred'
}