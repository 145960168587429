import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { CreateIdentityOnChainMutation, CreateIdentityVars, CreateIdentityData, IDRegistryOrgsQuery, IDRegistryOrgsData } from '../../../../models/idRegistry';
import { ProcessItem } from '../../../../components/DisplayWrappers/ProcessItem';
import { ServiceResourcesVars } from '../../../../interfaces';

interface Props {
    isCertReg: boolean
    isSetupOnProgress: boolean
    setIsSetupOnProgress: React.Dispatch<React.SetStateAction<boolean>>
    hasCert: boolean
    hasIdReg: boolean
    membershipId: string
    setIsCertReg: React.Dispatch<React.SetStateAction<boolean>>
    idRegistryId: string
};

export const IsRegSetup = ({ isCertReg, isSetupOnProgress, hasCert, hasIdReg, membershipId, setIsCertReg, setIsSetupOnProgress, idRegistryId }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'IsRegSetup', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`IsRegSetup:${key}`, interpolate);
    const [loading, setLoading] = useState(false);
    const [createIdentityOnChain] = useMutation<CreateIdentityData, CreateIdentityVars>(CreateIdentityOnChainMutation);

    const {
        loading: fetchingOrgs,
        refetch,
        data: {
            idRegistryOrgs
        } = { idRegistryOrgs: undefined }
    } = useQuery<IDRegistryOrgsData, ServiceResourcesVars>(IDRegistryOrgsQuery, {
        variables: {
            service_id: idRegistryId
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (idRegistryOrgs) {
            setIsCertReg(idRegistryOrgs.find(org => org.name.endsWith(membershipId)) !== undefined);
        }
    }, [idRegistryOrgs, setIsCertReg, membershipId]);

    const generateCert = useCallback(async () => {
        try {
            setLoading(true);
            await createIdentityOnChain({
                variables: {
                    identity: {
                        membership_id: membershipId
                    },
                    service_id: idRegistryId
                }
            });
            await refetch();
        } finally {
            setLoading(false);
            setIsSetupOnProgress(false);
        }
    }, [membershipId, createIdentityOnChain, idRegistryId, setIsSetupOnProgress, refetch]);

    useEffect(() => {
        if (isSetupOnProgress && hasIdReg && hasCert && !isCertReg && idRegistryId) generateCert()
    }, [isSetupOnProgress, hasCert, generateCert, hasIdReg, isCertReg, idRegistryId]);

    let statusMessage: string;
    if(fetchingOrgs) {
        statusMessage = lt('checking');
    } else {
        statusMessage = isCertReg ? lt('provisioned') : lt('notProvisioned');
    }
    return (
        <ProcessItem title={lt('onChainCert')} isDone={isCertReg || fetchingOrgs} loading={loading || fetchingOrgs} statusMessage={statusMessage} />
    )
};

interface translations {
    notProvisioned: string
    onChainCert: string
    provisioned: string
    checking: string
}
const enTranslations: translations = {
    notProvisioned: 'Not Registered',
    provisioned: 'Registered',
    onChainCert: 'Register Membership Identity On-Chain',
    checking: 'Checking...'
}
