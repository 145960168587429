import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HelpCenter, HelpCenterTab } from '../HelpCenter/HelpCenter';
import { useQuery } from '@apollo/client';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';
import {
    PendingTicketsCountQuery,
    PendingTicketsCountData,
    SupportInboxVars,
} from '../../models/helpCenter';

export const HelpWrapper = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    i18n.addResourceBundle('en', 'HelpWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`HelpWrapper:${key}`, interpolate);

    const [helpCenter, setHelpCenter] = useState(false);
    const [helpCenterTab, setHelpCenterTab] = useState(
        HelpCenterTab.CONTACT_US
    );

    const { org_id } = useParams<any>();
    const {
        data: { pendingTicketsCount } = { pendingTicketsCount: 0 },
    } = useQuery<PendingTicketsCountData, SupportInboxVars>(
        PendingTicketsCountQuery,
        {
            variables: { org_id },
            pollInterval: 60000,
            fetchPolicy: 'network-only',
        }
    );

    const openHelpCenter = (tab?: HelpCenterTab) => {
        if (tab !== null && tab !== undefined) {
            setHelpCenterTab(tab);
        }
        setHelpCenter(true);
    };

    return (
        <>
            <Button onClick={() => openHelpCenter(HelpCenterTab.DOCUMENTATION)} aria-label="help-center"
                size="small" variant="outlined" className={classes.helpButton} startIcon={
                    <HelpCircleIcon size={18} className={classes.helpIcon}/>
                }>
                   {lt('helpAndSupport')}
            </Button>
            <HelpCenter
                isOpen={helpCenter}
                setOpen={setHelpCenter}
                pendingTicketsCount={pendingTicketsCount}
                tab={helpCenterTab}
            />
        </>
    );
};

interface translations {
    helpAndSupport: string;
}
const enTranslations: translations = {
    helpAndSupport: 'Help & Support'
};

const useStyles = makeStyles((theme) => ({
    helpButton: {
        textTransform: 'none',
        fontSize: 12
    },
    helpIcon: {
        color: theme.palette.primary.main
    }
}));
