import React from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { ContractProjectsData, ContractProjectsQuery,
    ContractProjectsSubscription, MakeContractProjectsSubscriptionOptions } from '../../models'
import { Apps } from './Apps'
import { ContractProjectRouter } from './ContractProjectRouter'
import { ConsortiumResourcesVars, BaseRouterProps } from '../../interfaces'
import { useParams, Route, Switch, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Create as CreateProject } from './CreateProject/Create'
import { NETWORK_CONTRACTS_CREATE_PATH } from '../../components/MainNav/ConsortiumWrapper';

export const ContractsRouter = ({baseRoutePath} : BaseRouterProps) => {
    const { consortium_id } = useParams<any>();

    const consortiumResourcesVars = {
        consortia_id: consortium_id!,
    }

    // setup the contract projects query and subscription
    const {
        loading,
        data: {
            contractProjects
        } = { contractProjects: [] }
    } = useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: consortiumResourcesVars,
        fetchPolicy: 'cache-and-network' 
    });
    useSubscription(ContractProjectsSubscription, MakeContractProjectsSubscriptionOptions(consortiumResourcesVars));

    if (loading && !contractProjects.length) {
        return <CircularProgress />
    }
    
    return (
        <Switch>
            <Route path={`${baseRoutePath}/${NETWORK_CONTRACTS_CREATE_PATH}/:step`} render={() => <CreateProject />} />
            <Route path={`${baseRoutePath}/:contract_id`} render={({match}) => <ContractProjectRouter baseRoutePath={match.path} />} />
            <Route exact path={baseRoutePath} render={() => <Apps />} />
            <Redirect to={baseRoutePath} />
        </Switch>
    )
};