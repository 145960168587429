import {
    Button, Grid, TextField, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isPasswordCompliant } from '../../utils/StringUtils';
import { PasswordInput } from '../PasswordInput/PasswordInput';

interface Props {
    email: string
    code?: string
    setCode?: React.Dispatch<React.SetStateAction<string>>
    mfaRequired: boolean
    mfaCode: string
    setMFACode: React.Dispatch<React.SetStateAction<string>>
    newPassword: string
    setNewPassword: React.Dispatch<React.SetStateAction<string>>
    handleSetNewPassword: () => void
    errorMessage: string
    loading: boolean
};

export const SetNewPassword: React.FC<Props> = ({
    email,
    setCode,
    code,
    mfaRequired,
    mfaCode,
    setMFACode,
    newPassword,
    setNewPassword,
    handleSetNewPassword,
    errorMessage,
    loading
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SetNewPassword', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SetNewPassword:${key}`, interpolate);
    const [confirmPassword, setConfirmPassword] = useState('');

    return (
        <form id="SetNewPasswordForm" className={'Hubspot'} style={{ width: '300px' }} onSubmit={event => { event.preventDefault(); handleSetNewPassword() }}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('createANewPassword')}
                    </Typography>
                </Grid>
                {errorMessage &&
                    <Grid item>
                        <Typography color="error" variant="subtitle1">{errorMessage}</Typography>
                    </Grid>
                }
                {setCode &&
                    <>
                        <Grid item>
                            <Typography variant="body1">
                                <Trans i18nKey="SetNewPassword:verificationCodeSent" values={{ email }}
                                    components={[<span className={classes.emailAddress} />]}>
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={lt('code')}
                                fullWidth
                                value={code}
                                autoComplete="off"
                                margin="none"
                                variant="outlined"
                                onChange={event => setCode(event.target.value)}
                            />
                        </Grid>
                    </>}
                {mfaRequired &&
                    <Grid item>
                        <TextField
                            label={lt('mfaCode')}
                            fullWidth
                            value={mfaCode}
                            autoComplete="off"
                            margin="none"
                            variant="outlined"
                            onChange={event => setMFACode(event.target.value)}
                        />
                    </Grid>
                }
                <Grid item>
                    <PasswordInput
                        label={lt('newPassword')}
                        value={newPassword}
                        autoComplete="off"
                        margin="none"
                        fullWidth
                        variant="outlined"
                        onChange={event => setNewPassword(event.target.value)}
                        helperText={newPassword && !isPasswordCompliant(newPassword) && lt('passwordRequirements')}
                    />
                </Grid>
                <Grid item>
                    <PasswordInput
                        label={lt('confirmNewPassword')}
                        value={confirmPassword}
                        autoComplete="off"
                        fullWidth
                        margin="none"
                        variant="outlined"
                        onChange={event => setConfirmPassword(event.target.value)}
                        helperText={newPassword !== confirmPassword && lt('passwordMismatch')}
                    />
                </Grid>
                <Grid item>
                    <Button type="submit"
                        fullWidth
                        color="primary"
                        disabled={(code?.length === 0) || loading || !newPassword || (confirmPassword !== newPassword) || !isPasswordCompliant(newPassword)}
                        variant="contained"
                        size="large">
                        {lt('savePassword')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
};

const useStyles = makeStyles(() => ({
    emailAddress: {
        fontWeight: 600
    }
}));

interface translations {
    createANewPassword: string
    verificationCodeSent: string
    code: string
    mfaCode: string
    newPassword: string
    confirmNewPassword: string
    passwordRequirements: string
    passwordMismatch: string
    savePassword: string
}

const enTranslations: translations = {
    createANewPassword: 'Create a new password',
    verificationCodeSent: 'We sent a 6 digit verification code to <0>{{email}}</0> to reset your password.',
    code: 'Code',
    mfaCode: 'Multi-Factor Authentication or Recovery Code',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    passwordRequirements: 'Min 8 characters. Must include 1 of each: upper & lower case, number & special character',
    passwordMismatch: 'Passwords must be equal',
    savePassword: 'Save Password'
}