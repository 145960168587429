import React, { SetStateAction, useState, useEffect, useMemo } from "react";
import {
    FormDialog,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../DialogWrappers";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import {
    ServicesData,
    ServicesQuery,
    CreateIdentityOnChainMutation,
    CreateIdentityVars,
    CreateIdentityData,
    IDRegistryOrgsQuery,
} from "../../models";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ProcessItem } from "../DisplayWrappers";
import { useProvisionService } from "../../hooks/useProvisionService";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    isExternallySigned: boolean;
}

type steps = "id_reg" | "establish_id";

export const EstablishIdentityDialog = ({ open, setOpen, isExternallySigned }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "EstablishIdentityDialog", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`EstablishIdentityDialog:${key}`, interpolate);

    const [step, setStep] = useState<steps>("id_reg");
    const [inProgress, setInProgress] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDone, setIsDone] = useState(false);
    const link = "https://docs.kaleido.io/kaleido-services/registry/cli-usage/";

    const { consortium_id, environment_id, membership_id } = useParams<any>();

    const envParams = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const { data: { services } = { services: [] } } = useQuery<ServicesData>(
        ServicesQuery,
        {
            variables: envParams,
            fetchPolicy: "cache-only",
        }
    );

    const { provisionService: provisionIdRegistry } = useProvisionService({
        consortiumId: consortium_id,
        environmentId: environment_id,
        name: "On-chain Registry",
        service: "idregistry",
        membershipId: membership_id,
    });

    const [createIdentityOnChain] = useMutation<
        CreateIdentityData,
        CreateIdentityVars
    >(CreateIdentityOnChainMutation);

    const idReg = useMemo(() => {
        return services.find(
            (entry) =>
                entry.service === "idregistry" && entry.state === "started"
        );
    }, [services]);

    useEffect(() => {
        if (idReg) {
            setStep("establish_id");
        }
    }, [idReg]);

    //handle ID registry provision
    useEffect(() => {
        if (inProgress && step === "id_reg") {
            provisionIdRegistry().catch((err) =>
                ErrorSnackbarCatcher(err, setErrorMessage)
            );
        }
    }, [provisionIdRegistry, inProgress, step]);

    //handle Identity Establish
    useEffect(() => {
        if (idReg && inProgress && step === "establish_id") {
            createIdentityOnChain({
                variables: {
                    identity: {
                        membership_id: membership_id,
                    },
                    service_id: idReg._id,
                },
                refetchQueries: [{
                    query: IDRegistryOrgsQuery,
                    variables: {
                        service_id: idReg._id
                    }
                }]
            })
                .then(() => {
                    setIsDone(true);
                })
                .catch((err) => {
                    ErrorSnackbarCatcher(err, setErrorMessage);
                    setStep("id_reg"); //On fail identity is not provided
                })
                .finally(() => setInProgress(false));
        }
    }, [createIdentityOnChain, step, inProgress, idReg, membership_id]);

    const controls = (
        <Grid container direction="column" spacing={2}>
            <ProcessItem
                loading={step === "id_reg" && inProgress}
                isDone={step !== "id_reg"}
                title={lt("provisionIdReg")}
                statusMessage={lt("idRegDesc")}
            />
            {isExternallySigned ? 
                <ProcessItem
                    loading={step === "establish_id" && inProgress}
                    isDone={isDone && step === "establish_id" && !inProgress}
                    title={lt("establishIdentity")}
                    statusMessage={lt("establishExternallySignedIdentityDesc")}
                    learnMoreLink={link}
                /> :
                <ProcessItem
                    loading={step === "establish_id" && inProgress}
                    isDone={isDone && step === "establish_id" && !inProgress}
                    title={lt("establishIdentity")}
                    statusMessage={lt("establishIdentityDesc")}
            />}
        </Grid>
    );

    return (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessage}
            />
            <FormDialog
                dialogType="medium"
                header={lt("header")}
                {...{ open }}
                {...{ setOpen }}
                controlsWrapper={controls}
                disableBackdropClick
                saveText={lt(isDone? "close" :  "establish")}
                cancelDisabled={inProgress || isDone}
                saveDisabled={inProgress || isExternallySigned}
                onSave={() => new Promise(() => { isDone? setOpen(false) : setInProgress(true)})}
            />
        </>
    );
};

interface translations {
    header: string;
    provisionIdReg: string;
    idRegDesc: string;
    establishIdentity: string;
    establishIdentityDesc: string;
    establishExternallySignedIdentityDesc: string;
    learnMore: string;
    establish: string;
    close: string
}

const enTranslations: translations = {
    header: "Establish Membership Identity On-Chain",
    provisionIdReg: "Provision ID registry service",
    idRegDesc: "The ID registry service must be provisioned to establish identities on-chain.",
    establishIdentity: "Establish membership identity on-chain",
    establishIdentityDesc: "Store the membership identity on-chain through the ID registry service.",
    establishExternallySignedIdentityDesc: "To establish the externally-signed identity on-chain, use the Kaleido CLI. Please go to the following link for more information regarding this process.",
    learnMore: 'Learn More',
    establish: "Establish",
    close: 'Close'
};
