import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseChartOptions } from '../../../utils/ChartOptions';

interface Props {
    chunksSent: number
    upperLimit: number
};

export const ChunksSent = ({ chunksSent, upperLimit }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'B2BChunksSent', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`B2BChunksSent:${key}`, interpolate), [t])

    Highcharts.setOptions(BaseChartOptions);

    // SHARED OPTIONS
    const containerProps = { style: { width: "180px", height: "200px" } }

    const makeLabel = (l: string, isUnit?: boolean) => `<span style="font-size: ${isUnit? '0.775' : '0.875'}rem; font-weight: 300; line-height: 1.43; fill: rgba(0, 0, 0, 0.54)">${l}</span>`

    const getRelativeValue = (value: number, maxValue?: number) => {
        return !maxValue ? 0 : Math.round((value / maxValue) * 100)
    }

    const getMaxLabel = () => {
        return lt('maxLabel', { size: upperLimit})
    }

    const getValueColor = (value: number, maxValue: number) => {
        if ((value / maxValue) < 0.2) return '#25bf15'
        if ((value / maxValue) < 0.4) return '#78bf15'
        if ((value / maxValue) < 0.6) return '#ff9d00'
        if ((value / maxValue) < 0.8) return '#ff6a00'
        return 'red'
    }

    const makeChartOptions = (value: number, label: string): Highcharts.Options => {
        const relativeValue = getRelativeValue(value, upperLimit)

        const options: Highcharts.Options = {
            title: {
                text: `<span style="font-size: 1.5rem; font-weight: 400">${relativeValue}</span><br />${makeLabel(label)}<br />${makeLabel(getMaxLabel(), true)}`,
                align: 'center',
                verticalAlign: 'middle',
                y: 38
            },

            series: [{
                type: 'pie',
                innerSize: '70%',
                startAngle: -120,
                endAngle: 120,
                data: [
                    { name: '', y: relativeValue, color: getValueColor(relativeValue, upperLimit) },
                    { name: '', y: upperLimit - relativeValue, color: 'rgba(0, 0, 0, 0.08)' }
                ],
                center: ['50%', '65%'],
                states: {
                    hover: { enabled: false },
                    inactive: { opacity: 1 }
                }
            }],
            tooltip: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        style: {
                            fontWeight: 'bold',
                            color: 'white'
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '100%']
                }
            }
        }
        const mergedOptions = Highcharts.merge(options);
        return mergedOptions
    }

    const cpuOptions = makeChartOptions(chunksSent, lt('limit'))

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={cpuOptions}
            containerProps={containerProps}
        />
    )
};

interface translations {
    limit: string
    maxLabel: string
}
const enTranslations: translations = {
    limit: 'Limit %',
    maxLabel: '({{size}})'
}