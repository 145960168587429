import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'
import { NodeRole } from '../../models';

interface Props {
    role: NodeRole
};

export const Step1Help = ({role} : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep1Help:${key}`, interpolate)
    const imagePath = role ===  'peer' ? `Create-SideBar-Node-PeerNode.svg`: role ===  'orderer' ? `Create-SideBar-Node-OrdererNode.svg`: `Create-SideBar-Node-NodeSummary.svg`;
    const isFabric = role ===  'orderer' || role ===  'peer';
    const isOrderer = role === 'orderer';
    const isPeer = role === 'peer';
    return (
        <Help 
        imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/` + imagePath}
        header={lt(isPeer ? 'peerHelpHeader' : isOrderer ? 'ordererHelpHeader' : 'helpHeader')}
            description={ lt(isPeer ? 'peerNodeHelpDescription' : isOrderer ? 'ordererNodeHelpDescription' : 'helpDescription')}
            bullet1={!isFabric ? lt('helpDesc1') : isOrderer ? lt('ordererDesc1') : lt('peerDesc1') }
            bullet2={!isFabric ? lt('helpDesc2') : isOrderer ? lt('ordererDesc2') : lt('peerDesc2')}
            bullet3={!isFabric ? lt('helpDesc3') : isOrderer ? lt('ordererDesc3') : lt('peerDesc3')}
            docLink="https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    peerDesc1: string,
    peerDesc2: string,
    peerDesc3: string,
    ordererDesc1: string,
    ordererDesc2: string,
    ordererDesc3: string,
    ordererHelpHeader: string,
    peerHelpHeader: string,
    ordererNodeHelpDescription: string,
    peerNodeHelpDescription: string
}
const enTranslations: translations = {
    helpHeader: 'Node Summary',
    helpDescription: 'A node requires a few pieces of configuration for the initial setup:',
    helpDesc1: `Owning member - if you have multiple memberships under your organization's control, you can use this dropdown to bind the node to a membership of your choice.`,
    helpDesc2: 'Name - a string identifying the node in the environment.',
    helpDesc3: 'Deployment region - the cloud and underlying region where the node will be deployed.  If the environment is deployed as single region, then the region is inherited from the environment configuration.  If the environment is deployed as a hybrid multi-region, then any whitelisted regions will be available for choice.',
    peerDesc1: 'Embedded LevelDB is used as state database on the peer nodes',
    peerDesc2: 'Mutual TLS is required for clients to communicate with the peer nodes running on Kaleido',
    peerDesc3: 'All peer nodes will automatically be joined to the “default-channel”',
    ordererDesc1: 'Raft consensus algorithm is supported',
    ordererDesc2: 'Mutual TLS is required for clients to communicate with the orderer nodes running on Kaleido',
    ordererDesc3: 'All orderer nodes will automatically be joined to the “default-channel”',
    ordererHelpHeader: 'Orderer Node Summary',
    peerHelpHeader: 'Peer Node Summary',
    ordererNodeHelpDescription: 'Orderer nodes are responsible for transaction ordering, using consensus algorithms to ensure consistency among the members of a channel. Orderer nodes also produce blocks and disseminate them to peers.',
    peerNodeHelpDescription: 'Peer nodes are responsible for running chaincodes and processing transactions, as well as maintaining the shared ledger for each channel.',
}