import TextBoxCheckOutlineIcon from 'mdi-react/TextBoxCheckOutlineIcon';
import AccountDetailsIcon from "mdi-react/AccountDetailsOutlineIcon";
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import GamepadCircleLeftOutlineIcon from 'mdi-react/GamepadCircleLeftIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Kpi } from '../../components/DisplayWrappers';

interface Props {
    ordererNodes?: number,
    baf?: number,
    peerNodes?: number,
    members?: number,
    chaincodes?: number
}

export const ChannelCard = ({
        ordererNodes,
        baf,
        peerNodes,
        members,
        chaincodes
    } : Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChannelCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChannelCard:${key}`, interpolate)

    const kpiData = [
        {
            key: 'channel-ordererNodes',
            value: (ordererNodes !== undefined) ? ordererNodes : '-',
            label: lt('ordererNodes'),
            icon: <BullseyeIcon/>
        },
        {
            key: 'channel-peerNodes',
            value: (peerNodes !== undefined) ? peerNodes : '-',
            label: lt('peerNodes'),
            icon: <GamepadCircleLeftOutlineIcon/>
        },
        {
            key: 'channel-members',
            value: (members !== undefined) ? members : '-',
            label: lt('members'),
            icon: <AccountDetailsIcon/>
        },
        {
            key: 'channel-chaincodes',
            value: (chaincodes !== undefined) ? chaincodes : '-',
            label: lt('chaincodes'),
            icon: <TextBoxCheckOutlineIcon/>
        }
    ];

    return <Kpi data={kpiData} />;
};

interface translations {
    ordererNodes: string,
    baf: string,
    peerNodes: string,
    members: string,
    chaincodes: string,
}
const enTranslations: translations = {
    ordererNodes: "Orderer Nodes",
    peerNodes: "Peer Nodes",
    baf: "Blockchain Firewalls",
    members: "Members",
    chaincodes: "Chaincodes",
}
