import { gql } from '@apollo/client';
import { EnvironmentFields, ConsortiumZoneFields, EnvironmentsData, ConsortiumZonesData, MembershipFields, ConsortiumMembershipsData } from '../models'

export const ConsortiumWrapperQuery = gql`
    ${EnvironmentFields}
    ${MembershipFields}
    ${ConsortiumZoneFields}

    query ConsortiumWrapperQuery($consortia_id: String!) {
        environments(consortia_id: $consortia_id) {
            ...EnvironmentFields
        }
        consortiumMemberships(consortia_id: $consortia_id) {
            ...MembershipFields
        }
        consortiumZones(consortia_id: $consortia_id) {
            ...ConsortiumZoneFields
        }
    }`;

export interface ConsortiumWrapperResult extends EnvironmentsData, ConsortiumMembershipsData, ConsortiumZonesData {}