import { useMutation } from '@apollo/client';
import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { UpdateCordaUserData, UpdateCordaUserMutation, UpdateCordaUserVars } from '../../models';
import { MessageSnackbar } from '../DialogWrappers';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    afterSave: () => void,
    username: string,
    existingRoles?: string,
}

export const UpdateCordaUserDialog = ({ open, setOpen, afterSave, username, existingRoles }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'UpdateCordaUserDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`UpdateCordaUserDialog:${key}`, interpolate)

    const { consortium_id, environment_id, node_id } = useParams<any>();
    const nodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        node_id: node_id!
    }

    const [roles, setRoles] = useState('');
    const [okMessage, setOkMessage] = useState('');

    const [updateCordaUser, { loading }] = useMutation<UpdateCordaUserData, UpdateCordaUserVars>(UpdateCordaUserMutation)

    useEffect(() => {
        if (open) {
            setRoles(existingRoles || '');
        }
    }, [open, existingRoles])

    const onSave = async () => {
        await updateCordaUser({
            variables: {
                ...nodeVars,
                id: username,
                user: {
                    roles: roles.split(',').map(r => r.trim()),
                }
            }
        });
        afterSave();
        setOpen(false);
        setOkMessage(lt('updatedOk', {username}));
    }

    const controlsWrapper = <>
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextField data-test="textField_roles"
                    label={lt('rolesHelp')}
                    value={roles}
                    onChange={event => setRoles(event.target.value)}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        </Grid>
    </>

    return (<>
        <MessageSnackbar messageType="success" message={okMessage} setMessage={setOkMessage}/>
        <FormDialog
            {...{open}} 
            {...{setOpen}} 
            header={lt('updateUser', {username})} 
            description={lt('updateUserDescription')} 
            {...{controlsWrapper}}
            saveDisabled={loading || !roles}
            {...{onSave}}
            closeDialogAfterSave={false}
            saveText={lt('update')} />
    </>);
};

interface translations {
    updateUser: string,
    updateUserDescription: string,
    rolesHelp: string,
    update: string,
    updatedOk: string,
}
const enTranslations: translations = {
    updateUser: 'Update Corda RPC user ({{username}})',
    updateUserDescription: 'Update the role set for the user',
    rolesHelp: 'Enter a comma separated list of role names',
    update: 'Update',
    updatedOk: 'User "{{username}}" updated',
}