import React, { useMemo } from "react";
import { FullScreenCreate } from "../../../components/DialogWrappers";
import { useTranslation } from "react-i18next";
import { Step1 } from "./Step1";
import { useQuery } from "@apollo/client";
import { ConfigData, ConfigQuery, ConfigTypes } from "../../../models/configs";
import {
    EnvironmentResourceVars,
    EnvironmentResourcesVars,
} from "../../../interfaces";
import {
    ServicesQuery,
    ServicesData,
    NodesData,
    NodesQuery,
    ServicesEnum,
    ManagedWalletServices,
} from "../../../models";
import { useParams, useLocation, Redirect } from "react-router-dom";
import { getPathParams } from "../TypeUtils";

type ServicesList = keyof typeof ServicesEnum;

interface RuntimeList {
    services: Array<ServicesList | []>;
    nodes: boolean;
}

export const AttachConfig = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "AttachConfig", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`AttachConfig:${key}`, interpolate);

    const {
        consortium_id,
        environment_id,
        config_id,
        cancelPath,
        basePath,
    } = getPathParams(useLocation().pathname, useParams<any>());

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const {
        loading: configsLoading,
        data: { config } = { config: null },
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: config_id!,
        },
    });

    const { data: { services } = { services: [] } } = useQuery<
        ServicesData,
        EnvironmentResourcesVars
    >(ServicesQuery, {
        variables: {
            ...queryVariables,
        },
        fetchPolicy: "cache-only",
    });

    const { data: { nodes } = { nodes: [] } } = useQuery<
        NodesData,
        EnvironmentResourcesVars
    >(NodesQuery, {
        variables: {
            ...queryVariables,
        },
        fetchPolicy: "cache-only",
    });

    const typeRuntimes: Map<ConfigTypes, RuntimeList> = useMemo(() => new Map([
        [
            "backup",
            {
                services: Object.values(ServicesEnum),
                nodes: true,
            },
        ],
        [
            "kms",
            {
                services: [...ManagedWalletServices],
                nodes: true,
            },
        ],
        [
            "opsmetric",
            {
                services: [],
                nodes: true,
            },
        ],
        [
            "networking",
            {
                services: [],
                nodes: true,
            },
        ],
        [
            "storage",
            {
                services: ["documentstore"],
                nodes: false,
            },
        ],
        [
            "cloudhsm",
            {
                services: ["cloudhsm"],
                nodes: false,
            },
        ],
        [
            "baf",
            {
                services: [],
                nodes: true,
            },
        ],
        [
            "node_config",
            {
                services: [],
                nodes: true,
            },
        ]
    ]), []);

    const availableRuntimes = useMemo(() => {
        if (config) {
            const runtimes = typeRuntimes.get(config.type);
            const configServices =
                services
                    ?.filter(
                        (service) =>
                            service.details.storage_id !== config_id &&
                            service.details.kms_id !== config_id &&
                            service.details.backup_id !== config_id
                    )
                    .filter((service) =>
                        runtimes?.services.find(
                            (entry) => service.service === entry
                        )
                    ) || [];
            let configNodes = runtimes?.nodes
                ? nodes.filter(
                      (node) =>
                          node.role !== "monitor" && 
                          node.backup_id !== config_id &&
                          node.kms_id !== config_id &&
                          node.opsmetric_id !== config_id &&
                          node.networking_id !== config_id &&
                          node.baf_id !== config_id && 
                          node.node_config_id !== config_id
                  )
                : [];
            return [...configServices, ...configNodes].filter(
                (entry) => entry.membership_id === config.membership_id
            );
        }
        return [];
    }, [typeRuntimes, services, config, nodes, config_id]);

    if (!configsLoading && !config) return <Redirect to={basePath} />;

    const stepComponents = [
        {
            step: "",
            component: (
                <Step1
                    {...{ availableRuntimes }}
                    {...{config}}
                    cancelPath={cancelPath}
                />
            ),
        },
    ];

    const loading = configsLoading;

    return (
        <FullScreenCreate
            cancelPath={cancelPath}
            {...{ loading }}
            toolbarHeader={lt("header")}
            {...{ stepComponents }}
            stepUrlParam={"1"}
            hideStepper
        />
    );
};

interface translations {
    header: string;
}

const enTranslations: translations = {
    header: "Attach Config",
};
