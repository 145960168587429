import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from "@material-ui/core";
import { NetworkControl } from './NetworkControl'
import { PrivateStackPanel } from '../../components/PrivateStack/PrivateStackPanel';

export const SecurityNetworkControl: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsSecurity', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsSecurity:${key}`, interpolate);

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('networkControl')}
                        </Typography>
                    </Grid>
                </Grid>
                <PrivateStackPanel headerText={lt('description')} />
                <NetworkControl />
            </Grid>
        </>
    )
};

interface translations {
    networkControl: string,
    description: string,
}
const enTranslations: translations = {
    networkControl: 'Network Control',
    description: 'Kaleido offers the ability to run self-managed components in your own private network. Connectivity into the firewall isolated network of your environment is controlled via a Network Bridge component, which runs on both ends of the secure connection.',
}