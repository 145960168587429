import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';
import { PromoteCompiledContractVars, PromoteCompiledContractMutation, ContractProjectData, ContractProjectQuery, Environment } from '../../../models';
import { MessageSnackbar, ErrorSnackbarCatcher, CreateWrapper } from '../../../components/DialogWrappers'
import { Typography, Grid, TextField } from "@material-ui/core";
import { Step1EthHelp } from './Step1EthHelp'
import { Step1CordaHelp } from './Step1CordaHelp'
import { EnvironmentSelector } from '../../../components/FormControls/EnvironmentSelector'
import { APPS_BASE_PATH, APPS_CHAINCODES_PATH, APPS_GATEWAY_APIS_PATH, APPS_CORDAPPS_PATH } from '../../../components/MainNav/SideNavs/AppsIntegrations';
import { CreateStepProps, ConsortiumResourceVars } from '../../../interfaces';
import { Step1FabricHelp } from './Step1FabricHelp';

interface Props extends CreateStepProps {
    environmentId: string
    setEnvironmentId: React.Dispatch<React.SetStateAction<string>>,
    promotedEnvironments?: string[]
};

export const Step1 = ({ environmentId, setEnvironmentId, cancelPath, promotedEnvironments = [] }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsPromoteCompilationStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsPromoteCompilationStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, contract_id, compiled_contract_id } = useParams<any>();

    const [message, setMessage] = useState('');
    const [endpoint, setEndpoint] = useState('');

    const {
        data: {
            contractProject
        } = { contractProject: null }
    } = useQuery<ContractProjectData, ConsortiumResourceVars>(ContractProjectQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: contract_id!
        },
        fetchPolicy: 'cache-only' 
    });

    const isCorda = contractProject?.type.startsWith('corda')
    const isFabric = contractProject?.type.startsWith('fabric')

    const getPromoteRedirect = () => {
        if (isCorda) {
            return `/orgs/${org_id}/consortia/${consortium_id}/environments/${environmentId}/${APPS_BASE_PATH}/${APPS_CORDAPPS_PATH}/${compiled_contract_id!}`
        } else if (isFabric) {
            return `/orgs/${org_id}/consortia/${consortium_id}/environments/${environmentId}/${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}/${compiled_contract_id!}`
        } else {
            return `/orgs/${org_id}/consortia/${consortium_id}/environments/${environmentId}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${compiled_contract_id!}`
        }
    }

    const [promoteCompiledContract, { loading: promoteCompiledContractLoading }] = 
        useMutation<string, PromoteCompiledContractVars>(PromoteCompiledContractMutation)

    const save = async () => {
        promoteCompiledContract({
            variables: {
                consortia_id: consortium_id!,
                contract_id: contract_id!,
                id: compiled_contract_id!,
                promoteCompiledContract: {
                    endpoint,
                    environment_id: environmentId
                }
            }
        }).then(() => {
            history.push(getPromoteRedirect())
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const disabled = promoteCompiledContractLoading || !environmentId

    const filterEnvironments = (env: Environment) => env.state === 'live' && (isCorda ? env.isCorda : isFabric ? env.isFabric : env.isEthereum) && !promotedEnvironments.includes(env._id)

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {isCorda ? lt('cordaDescription') : isFabric ? lt('fabricDescription') : lt('ethereumDescription')}
                </Typography>
            </Grid>

            { !isCorda && !isFabric && <Grid item>
                <TextField
                    data-test="promoteEndpoint"
                    value={endpoint} 
                    onChange={event => setEndpoint(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="none"
                    label={lt('endpoint')}
                    variant="outlined"
                />
            </Grid> }

            <Grid item >
                <EnvironmentSelector {...{environmentId}} {...{setEnvironmentId}} {...{filterEnvironments}} />
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            { isCorda ? <Step1CordaHelp /> : isFabric ? <Step1FabricHelp /> : <Step1EthHelp /> }
        </>
    )
};

interface translations {
    header: string,
    ethereumDescription: string,
    cordaDescription: string,
    fabricDescription: string,
    endpoint: string,
}
const enTranslations: translations = {
    header: 'Promote to Environment',
    ethereumDescription: 'When promoting a contract to an environment, you can provide an endpoint (friendly path) which will be registered environment-wide on a first-come first-serve naming basis. Overwriting an existing endpoint, or renaming an already promoted endpoint, is not supported. For example, an endpoint of `simplestorage` will result in a Gateway API being installed on the path `/gateways/simplestorage` that can be accessed on all nodes in the environment.',
    cordaDescription: 'Promoting the CorDapp to the environment distributes it to all nodes in a staging area ready to deploy',
    fabricDescription: 'Promoting the Chaincode to the environment distributes it to all nodes',
    endpoint: 'Endpoint (friendly path for the Gateway API)'
}