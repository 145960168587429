import React, { useState } from 'react';
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher } from '../../../components/DialogWrappers';
import { Grid, Typography, CircularProgress, makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LedgerContractData, LedgerContractQuery, Account, TokenContractBalanceOfData, TokenContractBalanceOfQuery, TokenContractBalanceOfVars, Node, getFromForGatewayAPI } from '../../../models';
import { EnvironmentResourceVars } from '../../../interfaces';
import Jazzicon from 'react-jazzicon';
import { jsNumberForAddress } from '../../../utils/StringUtils';
import { AccountSelector } from '../../../components/AccountSelector/AccountSelector';
import { BurnHelp } from './BurnHelp';

interface Props {
    senderAddress: Account
    cancelPath: string
    cookieAppCred: string
    membershipNode: Node
}

export const Burn = ({senderAddress, cancelPath, cookieAppCred, membershipNode}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Burn', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Burn:${key}`, interpolate);

    const [burnAmount, setBurnAmount] = useState('');
    const [tokenId, setTokenId] = useState('');
    const [message, setMessage] = useState('');
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');
    
    const {consortium_id, environment_id, token_address} = useParams<any>();

    const classes = useStyles();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        refetch: refetchContract,
        loading,
        data: {
            ledgerContract
        } = {ledgerContract: null}
    } = useQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        variables: {
            ...environmentVariables,
            id: token_address!
        },
        fetchPolicy: 'cache-first'
    });

    const {
        refetch: refetchBalance,
        data: {
            tokenContractBalanceOf: accountBalance
        } = { tokenContractBalanceOf: '' }
    } = useQuery<TokenContractBalanceOfData, TokenContractBalanceOfVars>(TokenContractBalanceOfQuery, { 
        variables: {
            ...environmentVariables,
            contractAddress: token_address!,
            accountAddress: senderAddress._id
        },
        skip: !senderAddress || !token_address
    });   

    if (loading) return <CircularProgress />;
    if (!ledgerContract) return <Redirect to={cancelPath} />;

    const onSubmit = async () => {
        const url = `${membershipNode.urls?.kaleido_connect}/instances/${token_address}/burn`;
        const queryParams = `?kld-from=${getFromForGatewayAPI(senderAddress)}&kld-sync=true`;
        const fullURl = url + queryParams
        const body = ledgerContract.isERC721 ? {
            tokenId
        } : { // providing extra / duplicate parameters doesnt hurt and is done b/c of different OZ versions of token contracts (constantinople and old TokenFactory)
            amount: burnAmount,
            value: burnAmount
        };

        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(cookieAppCred));
        setTransactionLoading(true);
        try {
            const response = await fetch(fullURl, {
                method:'POST',
                body: JSON.stringify(body),
                headers: headers,
            });
            if (!response.ok) {
                const processResponse = await response.json();
                throw new Error(processResponse.error || lt('unsuccessfulBurn'));
            }
            setMessageType('success');
            setMessage(lt('successfulBurn'));
            if(ledgerContract.isERC20) refetchContract();
            refetchBalance();
        } catch (err) {
            setMessageType('error');
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setTransactionLoading(false)
        }
    }

    const isAmountValid = parseInt(burnAmount) > 0

    const disabled = transactionLoading || ( !isAmountValid && !tokenId );

    const routerState = {
        account: senderAddress
    }

    const content = (
        <>
            <MessageSnackbar message={message} setMessage={setMessage} messageType={messageType}  />
            <Grid item container alignItems="center" spacing={1}>
                <Grid item className={classes.icon}>
                    <Jazzicon diameter={20} seed={jsNumberForAddress(ledgerContract.address)} />
                </Grid>
                <Grid item>
                    <Typography variant="h5">{lt('header', {tokenName: ledgerContract?.tokenName})}</Typography>
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column">
                <Grid item container spacing={1} justify="space-between" alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="body1">{lt('burningAddress')}</Typography>
                    </Grid>
                    <Grid item>
                        <AccountSelector account={senderAddress} />
                    </Grid>
                </Grid>
                {ledgerContract.isERC20 && 
                    <Grid item container spacing={1} justify="space-between">
                        <Grid item>
                            <Typography variant="body1">{lt('tokenSupply')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{ledgerContract.erc20TotalSupply}</Typography>
                        </Grid>
                    </Grid>
                }
                <Grid item container spacing={1} justify="space-between">
                    <Grid item>
                        <Typography variant="body1">{lt('accountBalance')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{accountBalance}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
                {ledgerContract.isERC721 ? 
                    <>
                        <Grid item xs={3}>
                            <Typography variant="body1">{lt('tokenId')}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField fullWidth 
                                type="number" 
                                required 
                                label={lt('id')} 
                                value={tokenId} 
                                onChange={e => setTokenId(e.target.value)} 
                                variant="outlined" />
                        </Grid> 
                    </> :
                    <>
                        <Grid item xs={3}>
                            <Typography variant="body1">{lt('amountToBurn')}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField fullWidth label={lt('amount')} type="number" value={burnAmount} onChange={e => setBurnAmount(e.target.value)} variant="outlined" />
                        </Grid>
                    </>
                }
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper customNextButtonLabel={lt('burn')} {...{routerState }} {...{cancelPath}} {...{content}} {...{disabled}} onNext={onSubmit} isLastStep isFirstStep saving={transactionLoading} />
            <BurnHelp />
        </>
    )
};

interface translations {
    burningAddress: string
    header: string
    accountBalance: string
    amountToBurn: string
    amount: string
    address: string
    tokenId: string
    tokenSupply: string
    id: string
    unsuccessfulBurn: string
    successfulBurn: string
    burn: string
}

const enTranslations: translations = {
    burn: 'Burn',
    burningAddress: 'Burn from Address:',
    header: '{{tokenName}} Burn',
    accountBalance: 'Account Balance:',
    amountToBurn: 'Amount to Burn',
    amount: 'Amount',
    address: 'Address',
    tokenId: 'Token ID',
    id: 'ID',
    tokenSupply: 'Token Supply',
    unsuccessfulBurn: 'Unsuccessful Burn',
    successfulBurn: 'Successful Burn!'
};

const useStyles = makeStyles(() => ({
    icon: {
        display: 'flex',
        alignSelf: 'center'
    },
    orPosition: {
        alignSelf: 'flex-end'
    }
}))