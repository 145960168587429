import { useApolloClient, useQuery } from '@apollo/client';
import { Button, Divider, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { SessionData } from '../../interfaces';
import { FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, Organization, RolesData, RolesQuery, EnRolesTranslations, RolesTranslations } from '../../models';
import { SessionQuery } from '../../queries/Session';
import { CREATE_ORG_PATH, MANAGE_ORG_PATH, ORGS_PATH } from '../ManageOrgNav/ManageOrgNav';
import { OrgSwitcherOrgItem } from './OrgSwitcherOrgItem';

interface Props {
    organizations: Organization[]
    setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const OrgSwitcherPopover: React.FC<Props> = ({ organizations, setPopoverOpen }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgSwitcherPopover', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgSwitcherPopover:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const history = useHistory();
    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;
    const currentOrg = organizations.find(organization => organization._id === org_id);
    const currentOrgOwner = currentOrg?.owner === session.user_id;
    const ownedOrgs = organizations.filter(organization => organization._id !== org_id && organization.owner === session.user_id);
    const joinedOrgs = organizations.filter(organization => organization._id !== org_id && organization.owner !== session.user_id);

    const {
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, { variables: { org_id }, fetchPolicy: 'cache-and-network' });

    const classes = useStyles();

    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { }
    });
    
    const handleOrgSelection = async (orgId: string) => {
        setPopoverOpen(false);
        if(orgId !== org_id) {
            history.push(`/${ORGS_PATH}/${orgId}`);
        }
    };

    return (
        <List className={classes.minWidthMenu}>
            {currentOrg && <>
                <ListItem button onClick={() => handleOrgSelection(currentOrg._id)}
                    key={currentOrg._id}>
                    <ListItemText primary={currentOrg.name}
                        primaryTypographyProps={{variant: 'h6', noWrap: true}}
                        secondary={currentOrgOwner ? lt('ownerRole') : lt(roles.find(r => r.user_id === session.user_id)?.role || 'empty').toUpperCase()}
                        secondaryTypographyProps={{variant: 'caption' }} />
                </ListItem>
                {currentOrgOwner &&
                    <ListItem>
                        <Button size="small" variant="outlined" fullWidth={true}
                        onClick={() => {setPopoverOpen(false); history.push(`/${ORGS_PATH}/${currentOrg._id}/${MANAGE_ORG_PATH}`)}}>{lt('manage')}</Button>
                    </ListItem>
                }
            </>}
            {ownedOrgs.map(organization => (
                <React.Fragment key={organization._id}>
                    <Divider />
                    <ListItem button onClick={() => handleOrgSelection(organization._id)}
                        key={organization._id}>
                        <ListItemText primary={organization.name}
                            primaryTypographyProps={{variant: 'h6', noWrap: true}}
                            secondary={lt('ownerRole')}
                            secondaryTypographyProps={{variant: 'caption' }} />
                    </ListItem>
                </React.Fragment>
            ))}
            {joinedOrgs.map(organization => (
                <OrgSwitcherOrgItem organization={organization} handleOrgSelection={() => handleOrgSelection(organization._id)} />
            ))}
            {featureToggles.newOrg && session.realm !== 'oidc' && <>
                <Divider />
                <ListItem>
                    <Button size="small" variant="outlined" fullWidth={true}
                    onClick={() => {setPopoverOpen(false); history.push(`/${ORGS_PATH}/${CREATE_ORG_PATH}/1`)}}>{lt('createNewOrg')}</Button>
                </ListItem>
            </>}
        </List>);
};

const useStyles = makeStyles(() => ({
    minWidthMenu: {
        width: '250px',
        maxHeight: '500px',
    }
}));

interface translations extends RolesTranslations {
    manage: string
    createNewOrg: string
    ownerRole: string
    empty: string
}
const enTranslations = {
    ...EnRolesTranslations,
    manage: 'Manage',
    createNewOrg: 'Create New Org',
    ownerRole: 'OWNER',
    empty: ''
}
