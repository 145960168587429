import React, { useState } from "react"
import { useLocation, Redirect } from 'react-router-dom';
import { SetNewPassword } from '../components/AuthForms';
import { useApolloClient } from "@apollo/client";
import { cognitoController } from '../utils/cognitoController';
import { Region } from '../interfaces';
import queryString from 'query-string';
import { SetupWelcome } from "../components/AuthForms/SetupWelcome";
import { PasswordPrompt } from "../components/AuthForms/PasswordPrompt";

interface Props {
    regions: Region[]
}

export const UserAccountSetup: React.FC<Props> = ({ regions }) => {

    const apolloClient = useApolloClient();
    const { search } = useLocation();
    const { code } = queryString.parse(search);
    const [step, setStep] = useState<'welcome' | 'confirmAccount' | 'changePassword'>('welcome');
    const [email, setEmail] = useState('');
    const [mfaRequired, setMFARequired] = useState(false);
    const [mfaCode, setMFACode] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const verificationCode = Array.isArray(code) ? code[0] : code;
    if (!verificationCode) {
        return <Redirect to="/" />;
    }

    const handleWelcome = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            const user = await cognitoController.checkLogin(email);
            setMFARequired(user.mfa?.type === 'totp');
            if (user?.state === 'active') {
                setStep('changePassword');
            } else {
                setStep('confirmAccount');
            }
        } catch (err) {
            setErrorMessage(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitPassword = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            await cognitoController.verifySignup(verificationCode, email, password, apolloClient, regions);
        } catch(err) {
            setErrorMessage(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSetNewPassword = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            await cognitoController.confirmPassword(
                email,
                verificationCode,
                newPassword,
                apolloClient,
                regions,
                mfaCode
            );
        } catch (err) {
            setErrorMessage(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (step === 'welcome') {
        return (
            <SetupWelcome
                email={email}
                setEmail={setEmail}
                errorMessage={errorMessage}
                handleWelcome={handleWelcome}
                loading={loading}
            />
        );
    } else if (step === 'confirmAccount') {
        return (
            <PasswordPrompt
                password={password}
                setPassword={setPassword}
                errorMessage={errorMessage}
                handleSubmitPassword={handleSubmitPassword}
                loading={loading}
            />
        );
    } else {
        return (
            <SetNewPassword
                email={email}
                mfaRequired={mfaRequired}
                mfaCode={mfaCode}
                setMFACode={setMFACode}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                handleSetNewPassword={handleSetNewPassword}
                errorMessage={errorMessage}
                loading={loading}
            />
        );
    }
}