import React, { useEffect, useState } from 'react';
import { SubNavs } from './SubNavs';
import { SideNavProps } from '../../../interfaces';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { NavItem } from '../../NavComponents/NavItem'
import BankOutlineIcon from 'mdi-react/BankOutlineIcon';

export const NETWORK_GOVERNANCE_PATH = 'governance'
export const NETWORK_GOVERNANCE_MEMBERSHIPS_PATH = 'memberships'
export const NETWORK_GOVERNANCE_INVITATIONS_PATH = 'invitations'
export const NETWORK_GOVERNANCE_REGIONS_PATH = 'regions'
export const NETWORK_GOVERNANCE_ACTIVITYLOG_PATH = 'activitylog'
export const NETWORK_GOVERNANCE_IDENTITY_PATH = 'identity'

export const Governance = ({ selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Governance', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Governance:${key}`, interpolate)

    const history = useHistory();
    const { org_id, consortium_id } = useParams<any>();
    const { pathname } = useLocation();

    const [selectedItem, setSelectedItem] = useState<string>();
    const [active, setActive] = useState<boolean>(false);

    const MEMBERSHIPS = lt('memberships')
    const INVITATIONS = lt('invitations')
    const REGIONS = lt('regions')
    const ACTIVITYLOG = lt('activityLog')

    useEffect(() => { 
        if (!consortium_id) return

        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.includes(`${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}`)) {
            setSelectedItem(MEMBERSHIPS)
            setActiveAndSelected()
        } else if (pathname.includes(`${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_INVITATIONS_PATH}`)) {
            setSelectedItem(INVITATIONS)
            setActiveAndSelected()
        } else if (pathname.includes(`${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_REGIONS_PATH}`)) {
            setSelectedItem(REGIONS)
            setActiveAndSelected()
        } else if (pathname.includes(`${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_ACTIVITYLOG_PATH}`)) {
            setSelectedItem(ACTIVITYLOG)
            setActiveAndSelected()
        } else {
            setSelectedItem('')
            setActiveAndSelected(false)
        }
        
    }, [consortium_id, pathname, setSelected, MEMBERSHIPS, INVITATIONS, REGIONS, ACTIVITYLOG])

    const historyPusher = (path?: string) => history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}${path ? `/${path}` : ''}`)

    const tabs = [
        {
            name: MEMBERSHIPS,
            action: () => historyPusher(NETWORK_GOVERNANCE_MEMBERSHIPS_PATH)
        },
        // {
        //     name: INVITATIONS,
        //     action: () => historyPusher(NETWORK_GOVERNANCE_INVITATIONS_PATH)
        // },
        // {
        //     name: REGIONS,
        //     action: () => historyPusher(NETWORK_GOVERNANCE_REGIONS_PATH)
        // },
        {
            name: ACTIVITYLOG,
            action: () => historyPusher(NETWORK_GOVERNANCE_ACTIVITYLOG_PATH)
        }
    ]

    return (
        <>
            <NavItem icon={<BankOutlineIcon />} 
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={tabs} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations {
    name: string
    memberships: string
    invitations: string
    regions: string
    activityLog: string
}
const enTranslations: translations = {
    name: 'Governance',
    memberships: 'Memberships',
    invitations: 'Invitations',
    regions: 'Regions',
    activityLog: 'Activity Log'
}