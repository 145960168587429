import { useQuery } from "@apollo/client";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ChaincodesTable } from '../../components/Chaincodes/ChaincodesTable';
import { GatewayAPIsData, GatewayAPIsVars, GatewayAPIsQuery } from "../../models";

export const Chaincodes: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Chaincodes', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Chaincodes:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id } = useParams<any>();

    const onRowClick = (gatewayAPIId: string) => history.push(`${pathname}/${gatewayAPIId}`)

    const {
        loading,
        data: {
            gatewayAPIs
        } = { gatewayAPIs: null }
    } = useQuery<GatewayAPIsData, GatewayAPIsVars>(GatewayAPIsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-and-network'
    });

    if (loading && !gatewayAPIs) return <CircularProgress />

    const chaincodes = gatewayAPIs?.gatewayAPIs;
    const totalCount = gatewayAPIs?.totalCount;

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('chaincodes')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    <ChaincodesTable {...{chaincodes}} {...{totalCount}} header={lt('description', {count : totalCount})} gatewayAPISource {...{onRowClick}} />
                </Grid> 
            </Grid>
        </>
    )
};

interface translations {
    description: string,
    chaincodes: string
}
const enTranslations: translations = {
    description: 'All Chaincodes Promoted to Environment ({{count}})',
    chaincodes: 'Chaincodes'
}