import React from "react";
import { Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

export const ExpiredSession: React.FC = () => {

    const classes = useStyles();
    const { search } = useLocation();
    const { path } = queryString.parse(search);
    const history = useHistory();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ExpiredSession', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ExpiredSession:${key}`, interpolate);

    const handleLogin = () => {
        history.push('/login' + (path? `?path=${path}` : ''));
    };

    return (
        <Grid container direction="column" spacing={3} className={classes.expiredSessionContainer}>
            <Grid item>
                <Typography variant="h5">{lt('yourSessionHasExpired')}</Typography>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" size="large" fullWidth
                    onClick={handleLogin}>
                    {lt('login')}
                </Button>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    expiredSessionContainer: {
        width: '300px'
    }
}));

interface translations {
    yourSessionHasExpired: string
    login: string
}

const enTranslations: translations = {
    yourSessionHasExpired: 'Your session has expired',
    login: 'Login'
}
