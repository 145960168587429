import React, { useState, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, Grid, makeStyles, CircularProgress } from "@material-ui/core";
import { MessageSnackbar, ErrorSnackbarCatcher } from './MessageSnackbar'
import CloseIcon from 'mdi-react/CloseIcon';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    header: string,
    description?: string | JSX.Element,
    controlsWrapper?: JSX.Element,
    descriptionLinksWrapper?: JSX.Element,
    saveDisabled?: boolean,
    saveText?: string,
    cancelText?: string,
    confirmResourcePromptName?: string,
    confirmSuffix?: string,
    successMessage?: string,
    dialogContentHeight?: number,
    width?: "sm" | "md" | "lg" | "xl" | "xs",
    closeDialogAfterSave?: boolean // this avoids setState on unmounted components if you are redirecting in your onSave() call
    onCancel?: () => void,
    onSave?: () => Promise<void>,
    hideActions?: boolean,
    hideSave?: boolean,
    dialogType?: 'small' | 'medium' // medium dialog boxes have a close button on the top-right corner
    subtitle?: string
    dataTestId?: string
    disableContentPadding?: boolean
    disableBackdropClick?: boolean
    noScroll?: boolean
    cancelDisabled?: boolean
    saving?: boolean
}

export const FormDialog = ({ open, setOpen, header, description, controlsWrapper, saveDisabled = false, onCancel, onSave, saveText,
    cancelText, confirmResourcePromptName, confirmSuffix, descriptionLinksWrapper,
    successMessage, width = "sm", dialogContentHeight, closeDialogAfterSave, hideSave, hideActions, dialogType = 'small', 
    subtitle, dataTestId, disableContentPadding = false, noScroll = false, disableBackdropClick = false, cancelDisabled = false, saving }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FormDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`FormDialog:${key}`, interpolate)

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<"error" | "success">('error');

    const classes = useStyles(dialogContentHeight)

    const save = () => onSave!().then(() => {
        if (successMessage) {
            setMessageType('success');
            setMessage(successMessage)
        }
    }).catch(e => {
        setMessageType('error');
        ErrorSnackbarCatcher(e, setMessage)
    }).finally(() => {
        if (closeDialogAfterSave) {
            setOpen(false);
        }
    });

    const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (onSave) save();
    };

    const handleOnCancel = () => {
        if (onCancel) onCancel();
        setOpen(false);
    }

    const dialogContentClassnames = disableContentPadding ? classes.disablePadding : noScroll ? classes.noScroll : ''
    const dialogContentClassnamesContent = dialogContentHeight ? classes.contentHeight : ''

    return (
        <>
            <MessageSnackbar {...{ message }} {...{ setMessage }} messageType={messageType} />
            <Dialog onClick={e => e.stopPropagation()} fullWidth maxWidth={width} open={open}
            onClose={handleOnCancel} data-test={`dialog_${dataTestId}`} disableBackdropClick={disableBackdropClick || cancelDisabled}
            disableEscapeKeyDown={cancelDisabled} scroll="body">
                <form onSubmit={e => handleOnSubmit(e)}>
                    <DialogTitle className={classes.headerContainer}>
                        <Grid container justify="space-between" wrap="nowrap" alignItems="center">
                            <Grid container item direction="column">
                                <Typography variant="h6">{header}</Typography>
                                {subtitle &&
                                    <Typography variant="body2">{subtitle}</Typography>
                                }
                            </Grid>

                            {dialogType === 'medium' &&
                                <IconButton size="small" onClick={() => setOpen(false)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={`${dialogContentClassnames} ${dialogContentClassnamesContent}`}>
                        {description &&
                            <DialogContentText>
                                {isValidElement(description) ? description : <Typography variant="body2" component="span">{description}</Typography>}                                
                            </DialogContentText>
                        }

                        {descriptionLinksWrapper &&
                            <>
                                {descriptionLinksWrapper}
                                <br />
                            </>}

                        {confirmResourcePromptName &&
                            <DialogContentText>
                                <Typography variant="body2" component="span">{lt('confirmPrefix')}</Typography>
                                <Typography variant="body1" component="span">{confirmResourcePromptName}</Typography>
                                <Typography variant="body2" component="span">{confirmSuffix || lt('confirmSuffix')}</Typography>
                            </DialogContentText>
                        }
                        {controlsWrapper}
                    </DialogContent>
                    {!hideActions &&
                        <DialogActions>
                            {saving && <CircularProgress size={30} />}
                            <Button onClick={handleOnCancel} color="inherit" disabled={cancelDisabled}>
                                {cancelText || lt('cancel')}
                            </Button>
                            {!hideSave &&
                                <Button type="submit" color="primary" disabled={saveDisabled} data-test="button_FormDialogSubmit">
                                    {saveText || lt('save')}
                                </Button>
                            }
                        </DialogActions>
                    }
                </form>
            </Dialog>
        </>
    )
};

interface translations {
    cancel: string,
    save: string,
    confirmPrefix: string,
    confirmSuffix: string
}
const enTranslations: translations = {
    cancel: 'Cancel',
    save: 'Save',
    confirmPrefix: 'In the box below, type ',
    confirmSuffix: ' to confirm deletion of this resource.'
}

const useStyles = makeStyles(theme => ({
    disablePadding: {
        padding: '0'
    },
    headerContainer: {
        padding: theme.spacing(3)
    },
    noScroll: {
        overflowY: 'hidden'
    },
    contentHeight: {
        height: dialogContentHeight => dialogContentHeight ? `${dialogContentHeight}px` : ''
    }
}))