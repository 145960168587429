import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertBanner } from '../../components/Banners/AlertBanner';
import { FormDialog } from '../../components/DialogWrappers';
import { CopyableSettings } from '../../components/DisplaySettings';
import { HDWallet } from '../../models/hdwallet';

interface Props {
    hdwallet?: HDWallet,
}

export const HDWalletInfo = ({ hdwallet }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'HDWalletsHDWalletInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`HDWalletsHDWalletInfo:${key}`, interpolate)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(hdwallet ? true : false)
    }, [hdwallet])

    const copyableList = [
        {
            title: lt('id'),
            displayValue: hdwallet?.id ?? ''
        },
        {
            title: lt('passphrase'),
            displayValue: hdwallet?.secret ?? ''
        }
    ]

    const controlsWrapper = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <AlertBanner description={lt('backupPassphrase')} />
            </Grid>
            <Grid item>
                <CopyableSettings copyableList={copyableList} />
            </Grid>
        </Grid>
    )

    return (
        <>
            <FormDialog {...{open}} {...{setOpen}} disableBackdropClick
                header={lt('header')} 
                saveText={lt('done')}
                width='md'
                dialogType="medium" 
                onSave={async () => setOpen(false)}
                controlsWrapper={controlsWrapper} 
            />
        </>
    )
};

interface translations {
    id: string
    passphrase: string
    backupPassphrase: string
    header: string
    done: string
}
const enTranslations: translations = {
    id: 'ID',
    passphrase: 'Recovery Passphrase',
    backupPassphrase: 'Please backup your passphrase and keep it in a safe place. This is the only time it will be displayed.',
    header: 'Successfully created a new HD Wallet!',
    done: 'Done'
}