import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';
import { ContractProject, CompiledContractsData, CompiledContractsQuery, CompiledContractsVars} from '../../../models';
import { MessageSnackbar, ErrorSnackbarCatcher, CreateWrapper } from '../../../components/DialogWrappers'
import { Typography, Grid, Checkbox, FormControlLabel, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { CreateStepProps } from '../../../interfaces';
import { Step1FabricHelp } from './Step1FabricHelp';
import { CompiledContractSelector } from './CompiledContractSelector';
import { Channel, DeployCompiledContractMutation, DeployContractInputVars } from '../../../models/channels';
import { CHANNELS_PATH } from '../../../components/MainNav/SideNavs/AddressBook';
import { CHANNEL_CHAINCODES_PATH } from '../../../components/ChannelNav/ChannelNav';

interface Props extends CreateStepProps {
    contractProject: string,
    setContractProject: React.Dispatch<React.SetStateAction<string>>,
    compilationChosen: string,
    setCompilationChosen: React.Dispatch<React.SetStateAction<string>>,
    contractProjects: ContractProject[],
    promotedCompilations: string[]
};

export const Step1 = ({ contractProject, setContractProject, compilationChosen = '', setCompilationChosen, contractProjects, promotedCompilations = [], cancelPath}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsDeployCompilationStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsDeployCompilationStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, channel_id} = useParams<any>();
    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: channel_id!
    }
    const [message, setMessage] = useState('');
    const [reqInitialization, setReqInitialization] = useState(false);

    const {
        data: {
            compiledContracts
        } = { compiledContracts: [] }
    } = useQuery<CompiledContractsData, CompiledContractsVars>(CompiledContractsQuery, {
        variables: { 
            consortia_id: consortium_id!,
            contract_id: contractProject!
        },
        skip: contractProject === '',
        fetchPolicy: 'cache-and-network'
    });

    const contractsPromotedToEnv = compiledContracts?.filter((c) => promotedCompilations.includes(c._id));
    if (contractsPromotedToEnv.length > 0 && compilationChosen === '') {
        setCompilationChosen(contractsPromotedToEnv[0]._id)
    }

    if (contractsPromotedToEnv.length < 1 && compilationChosen !== '') {
        setCompilationChosen('')
    }

    const redirectToChaincode = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${channel_id}/${CHANNEL_CHAINCODES_PATH}`
    const [deployCompiledContract, { loading: deployContractLoading }] = useMutation<Channel, DeployContractInputVars>(DeployCompiledContractMutation);

    const save = async () => {
        return deployCompiledContract({
            variables: {
                ...environmentVariables,
                compiledContract: {
                    compiled_contract_id: compilationChosen,
                    init_required: reqInitialization
                }
            }
        }).then(() => {
            history.push(redirectToChaincode);
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const disabled = deployContractLoading || compilationChosen === ''

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>
            <Grid item>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel required>
                        {lt('selectChaincode')}
                    </InputLabel>
                    <Select 
                        disabled={contractProjects.length === 0}
                        labelWidth={80}
                        value={contractProject || contractProjects[0]?._id || ''}
                        onChange={e => setContractProject(e.target.value as string)}>
                        {contractProjects.map(l => {
                            return (
                                <MenuItem key={l._id} selected={l._id === contractProject} value={l._id}>{l.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <CompiledContractSelector contracts={contractsPromotedToEnv} {...{compilationChosen}} {...{setCompilationChosen}}/>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={reqInitialization}
                            onChange={e => setReqInitialization(e.target.checked)}
                            value='reqInitialization'/>
                    }
                    label={lt('reqInitialization')}
                    labelPlacement="end"
                    />
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('reqInitializationDesc')}
                    </Typography>                
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            <Step1FabricHelp />
        </>
    )
};

interface translations {
    header: string,
    reqInitialization: string,
    reqInitializationDesc: string
    selectChaincode: string,
}
const enTranslations: translations = {
    header: 'Select Chaincode',
    reqInitialization: 'Require initialization before invocation',
    reqInitializationDesc: 'This adds the "--init-required" parameter to the lifecycle commands, which requires the chaincode to be initialized before it can process transactions.',
    selectChaincode: 'Chaincode'
}
