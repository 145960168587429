import { Grid, Typography, TextField, MenuItem, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { Region } from '../../interfaces';
import { AgreementsTable } from './AgreementsTable';
import { useHistory } from 'react-router-dom';
import { OPS_MANAGE_AGREEMENT_PATH, OPS_PATH, OPS_MANAGE_AGREEMENT_PATH_CREATE } from '../../components/OpsNav/OpsNav';

interface Props {
    regions?: Region[]
}

export const Agreements: React.FC<Props> = ({regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsAgreements', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsAgreements:${key}`, interpolate)

    const history = useHistory();

    const [message, setMessage] = useState('');
    const [templateFilter, setTemplateFilter] = useState('');
    const [idFilter, setIDFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');

    const filterBar = <Grid container spacing={2} alignItems="center" alignContent="stretch">
        <Grid item xs>
            <Typography variant="h5">{lt('filter')}</Typography>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('template')}                 
                value={templateFilter} 
                variant="outlined" fullWidth
                select
                onChange={e => setTemplateFilter(e.target.value)}>
                    <MenuItem value="">{lt('any')}</MenuItem>
                    <MenuItem value="true">{lt('templatesOnly')}</MenuItem>
                    <MenuItem value="false">{lt('noTemplates')}</MenuItem>
            </TextField>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('id')} 
                value={idFilter} 
                variant="outlined" fullWidth
                onChange={e => setIDFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('name')} 
                value={nameFilter} 
                variant="outlined" fullWidth
                onChange={e => setNameFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('type')} 
                value={typeFilter} 
                variant="outlined" fullWidth
                onChange={e => setTypeFilter(e.target.value)}/>
        </Grid>
        <Grid item>
            <Button
                variant="contained"
                color="primary"
                onClick={e => history.push(`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}/${OPS_MANAGE_AGREEMENT_PATH_CREATE}`)}>{lt('add')}</Button>
        </Grid>    
    </Grid>;

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <Grid container direction="column" spacing={3}>
            <Grid item>
                {filterBar}
            </Grid>
            {regions?.map(({code, cloud, region}) => (
                <Grid item container direction="column" spacing={3} key={`${code}-${cloud}-${region}`}>
                    <Grid item>
                        <Typography variant="h5">{`${cloud}: ${region}`}</Typography>
                    </Grid>
                    <Grid item>
                        <AgreementsTable region={code} {...{setMessage}} {...{idFilter}} {...{nameFilter}} {...{typeFilter}} {...{templateFilter}}/>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </>;
};

interface translations {
    filter: string;
    any: string;
    template: string;
    templatesOnly: string;
    noTemplates: string;
    id: string;
    name: string;
    type: string;
    add: string;
}
const enTranslations: translations = {
    filter: 'Filter:',
    any: 'Any',
    template: 'Template?',
    templatesOnly: 'Templates Only',
    noTemplates: 'No Templates',
    id: 'ID',
    name: 'Name',
    type: 'Type',
    add: 'Add',
}
