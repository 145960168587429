import { useQuery } from '@apollo/client';
import { CircularProgress, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GenericStatusIcon } from '../../components/DisplayWrappers/GenericStatusIcon';
import { EnvironmentResourceVars, NodeResourcesVars } from '../../interfaces';
import { CorDappsData, CorDappsQuery, GatewayAPIData, GatewayAPIQuery, Node } from '../../models';
import { APPS_CORDAPPS_PATH } from '../MainNav/SideNavs/AppsIntegrations';

interface Props {
    corDappId: string,
    node: Node
};

export const CorDappNodeInstance = ({ corDappId, node }: Props) => {
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const classes = useStyles();
    const history = useHistory();

    const envVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const nodeResourceVars = {
        ...envVars,
        node_id: node._id!
    }

    const getRedirectToCorDapp = () => {
        return history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node._id}/${APPS_CORDAPPS_PATH}`);
    }

    const {
        loading: loadingCorDapps,
        data: {
            cordapps : allCorDapps
        } = { CorDapps: [] }
    } = useQuery<CorDappsData, NodeResourcesVars>(CorDappsQuery, { 
        variables: nodeResourceVars,
        fetchPolicy: 'cache-and-network'
    });


    const {
        loading,
        data: {
            gatewayAPI
        } = { gatewayAPI: null }
    } = useQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, {
        variables: { 
            ...envVars,
            id: corDappId
        },
        fetchPolicy: 'cache-and-network'
    });

    if (loadingCorDapps) {
        return <></>;
    }

    const cordappsLive = (allCorDapps!.live)?.filter(c => c.id === corDappId);

return (
    <>
    <TableRow hover={true}>
        <TableCell onClick={()=>{getRedirectToCorDapp()}} className={classes.tableCell}>
            <Typography noWrap variant="body2" color="inherit">
                {loading && !gatewayAPI ? <CircularProgress /> : <GenericStatusIcon overrideOk={cordappsLive[0] ? true : false} />}
            </Typography>
        </TableCell>
        <TableCell onClick={()=>{getRedirectToCorDapp()}} className={classes.tableCell}>
            <Typography noWrap variant="body2" color="inherit">
                {node.name}
            </Typography>
        </TableCell>
        <TableCell onClick={()=>{getRedirectToCorDapp()}} className={classes.tableCell}>
            <Typography noWrap variant="body2" color="inherit">
                {node._id}
            </Typography>
        </TableCell>
    </TableRow>
    </>
)
};

const useStyles = makeStyles((theme) => ({
    tableCell: {
        paddingLeft: theme.spacing(3),
        cursor: 'pointer'
    },
    maxWidth: {
        maxWidth: '100px'
    }
}));