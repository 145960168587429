import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const CordaStep1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationCordaStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationCordaStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Your CorDapp version',
    helpDescription: 'Set the details for ',
    helpDesc1: 'Owner - the membership you are acting under when uploading this subscription.',
    helpDesc2: 'Description - Give this version a description so you will recognize it later.',
    helpDesc3: 'Jar file - upload the compiled CorDapp for distribution to nodes in the network',
}