import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { SideNavProps, EnvironmentResourcesVars, ConsortiumResourceVars} from '../../../interfaces';
import { ServicesTranslations, EnServicesTranslations, ServicesData, ServicesQuery, EnvironmentData, EnvironmentQuery, ReleaseData, ReleaseQuery, ReleaseVars } from '../../../models';
import { SubNavs } from './SubNavs';
import { NavItem } from '../../NavComponents/NavItem';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/client';

export const FIREFLY_BASE_PATH = 'firefly';
export const FIREFLY_INITIALIZE_PATH = 'initialize';
export const FIREFLY_REGISTRATION_PATH = 'register';
export const FIREFLY_SERVICE_PATH = 'firefly-os';

export const Firefly = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Firefly', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Firefly:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const classes = useStyles();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`
    const DASHBOARD = lt('dashboard')
    const NODES = lt('nodes')

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const { data: { 
           release 
         } = { release: null }
    } = useQuery<ReleaseData, ReleaseVars>(ReleaseQuery, {
         skip: !environment,
         variables: {
             id: environment?.release_id!
         },
         fetchPolicy: 'cache-and-network'
    });


    const {
        loading: servicesLoading,
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: {
            consortia_id: consortium_id,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    let popoverItems = services
        .filter(n => n.membership.isMine && n.service === 'firefly-os').map(s => ({
            name: s.name,
            action: () => history.push(`${basePath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/${s._id}`),
            divider: false,
            disabled: false
        }))

    if (popoverItems.length) {
        popoverItems[popoverItems.length - 1].divider = true
    };

    popoverItems.push({
        name: lt('addNode'),
        action: () =>
            history.push(
                `${basePath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/create/1`
            ),
        divider: false,
        disabled:
            environment?.state !== 'live' ||
            !!(release &&
            release.supported_features &&
            release.supported_features.fireflyVersion !== '1.2'),
    });

    const nodesSubItems = useCallback(() => {
        const itemsArr = [{
            name: DASHBOARD,
            action: () => history.push(`${basePath}/${FIREFLY_BASE_PATH}`)
        }, {
            name: NODES,
            popoverItems
        }]
        return itemsArr
    }, [
        DASHBOARD,
        basePath,
        history,
        NODES,
        popoverItems
    ])

    const splitPathname = pathname.split('/');
    const fireflyTab = splitPathname[splitPathname.length -2];

    useEffect(() => {
        if (servicesLoading) return

        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.endsWith(`${environment_id}/${FIREFLY_BASE_PATH}`)) {
            setSelectedItem(DASHBOARD);
            setActiveAndSelected()
        } else {
            setActiveAndSelected(false)
            setSelectedItem('')
        }
    }, [setSelected, environment_id, pathname, DASHBOARD, fireflyTab, NODES, servicesLoading])

    return (
        <>
            <NavItem icon={<img className={classes.image} src={`${process.env.PUBLIC_URL}/img/environmentTypes/FireFly-Icon.svg`} alt=""></img>}
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={nodesSubItems()} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations extends ServicesTranslations {
    name: string
    dashboard: string
    nodes: string
    addNode: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    name: 'FireFly',
    dashboard: 'Dashboard',
    nodes: 'Nodes',
    addNode: 'Add Node'
}

const useStyles = makeStyles(() => ({
    image: {
        maxHeight: '16px',
        opacity: 0.6
    }
}))
