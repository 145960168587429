import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import CloseIcon from 'mdi-react/CloseIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateWrapper, MessageSnackbar } from '../../../components/DialogWrappers';
import { CreateStepProps } from '../../../interfaces';
import {
    CreateEditEventStream, EnEventStreamTranslations,
    EventStream,
    EventStreamTranslations, EventStreamType
} from "../../../models/eventStreams";
import { isValidURL } from "../../../utils/StringUtils";
import { Step2WebhookHelp } from './Step2WebhookHelp';

interface Props extends CreateStepProps {
    name: string,
    type: EventStreamType,
    existing?: EventStream,
    save: (es: CreateEditEventStream) => Promise<void>,
    loading: boolean,
};

export const Step2Webhook = ({ name, type, cancelPath, existing, loading, save }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamsCreateEventStreamStep2Webhook', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamsCreateEventStreamStep2Webhook:${key}`, interpolate)

    const [message, setMessage] = useState('');
    const [url, setUrl] = useState(existing?.webhook?.url || '');
    const [batchSize, setBatchSize] = useState(existing?.batchSize || 1);
    const [batchTimeout, setBatchTimeout] = useState(existing?.batchTimeoutMS || 5000);
    const [requestTimeoutSec, setRequestTimeoutSec] = useState(existing?.webhook?.requestTimeoutSec || 120);
    const [blockedRetryDelaySec, setBlockedRetryDelaySec] = useState(existing?.blockedReryDelaySec || 30);
    const [verifyTlsCertificates, setVerifyTlsCertificates] = useState(existing?.webhook?.tlsSkipHostVerify ? false : true);
    const [blockOnErrors, setBlockOnErrors] = useState(existing?.errorHandling === 'skip' ? false : true);
    const [headers, setHeaders] = useState<{ key: string, value: string }[]>(
            existing?.webhook?.headers ? Object.entries(existing?.webhook?.headers).map(([k,v]) => ({key: k, value: v})) : []
    );

    const setHeader = (i: number, headerName: string, headerValue: string) => {
        let newHeaders = [...headers];
        newHeaders[i] = {
            key: headerName,
            value: headerValue,
        }
        setHeaders(newHeaders)
    }

    const removeHeader = (i: number) => {
        let newHeaders = [...headers];
        newHeaders.splice(i, 1)
        setHeaders(newHeaders)
    }

    const makeHeader = (i: number, h: { key: string, value: string }) => (
        <>
            <Grid item xs={5}>
                <TextField required data-test={`textField_eventStreamHeaderName_${i}`}
                    value={h.key} 
                    onChange={event => setHeader(i, event.target.value, h.value)}
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required data-test={`textField_eventStreamHeaderValue_${i}`}
                    value={h.value} 
                    onChange={event => setHeader(i, h.key, event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('value')}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton color="inherit" onClick={() => removeHeader(i)}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </>
    )

    const saveWrapper = async () => {
        // flatten the headers
        const headersToSave: { [key: string]: string } = {}
        headers.forEach(h => headersToSave[h.key] = h.value)

        // show error if url is invalid
        if (!isValidURL(url)) {
            setMessage(lt('invalidUrl'))
            return
        }

        // show error if headers aren't unique
        if (Object.keys(headersToSave).length !== headers.length) {
            setMessage(lt('headersMustBeUnique'))
            return
        }

        await save({
            name,
            type,
            batchSize,
            batchTimeoutMS: batchTimeout,
            errorHandling: blockOnErrors ? 'block' : 'skip',
            blockedReryDelaySec: blockedRetryDelaySec,
            webhook: {
                url,
                tlsSkipHostVerify: !verifyTlsCertificates,
                requestTimeoutSec: requestTimeoutSec,
                headers: headersToSave
            }
        });
    }

    const disabled = !name || !type || loading || !url

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField required data-test="textField_eventStreamUrl" 
                    value={url} 
                    onChange={event => setUrl(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('url')}
                    variant="outlined"
                />
            </Grid>

            <Grid item container spacing={3}>
                <Grid item xs={4}>
                    <TextField type="number"
                        value={batchSize} 
                        onChange={event => setBatchSize(parseInt(event.target.value))}
                        fullWidth
                        margin="normal"
                        label={lt('batchSize')}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField type="number"
                        value={batchTimeout} 
                        onChange={event => setBatchTimeout(parseInt(event.target.value))}
                        fullWidth
                        margin="normal"
                        label={lt('batchTimeoutMS')}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField type="number"
                        value={blockedRetryDelaySec} 
                        onChange={event => setBlockedRetryDelaySec(parseInt(event.target.value))}
                        fullWidth
                        margin="normal"
                        label={lt('blockedReryDelaySec')}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <Grid item container spacing={3}>
                <Grid item xs={4}>
                    <TextField type="number"
                        value={requestTimeoutSec} 
                        onChange={event => setRequestTimeoutSec(parseInt(event.target.value))}
                        fullWidth
                        margin="normal"
                        label={lt('requestTimeoutSec')}
                        variant="outlined"
                    />
                </Grid>                
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">
                        {lt('tlsOptions')}
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={verifyTlsCertificates} onChange={(e) => setVerifyTlsCertificates(e.target.checked)} value={verifyTlsCertificates} />}
                        label={lt('verifyTlsCertificates')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary">
                        {lt('batchOptions')}
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={blockOnErrors} onChange={(e) => setBlockOnErrors(e.target.checked)} value={blockOnErrors} />}
                        label={lt('blockOnErrors')}
                    />
                </Grid>
            </Grid>

            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('headers')}
                </Typography>
                <Grid item container direction="column" spacing={1}>
                    {headers.map((h, i) => (
                        <Grid key={i} item container spacing={3} alignItems='center'>
                            {makeHeader(i, h)}
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item>
                <Button variant={'text'} onClick={() => setHeader(headers.length, '', '')} color='primary' startIcon={<PlusIcon />}>
                    {lt('addHeader')}
                </Button> 
            </Grid>

        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={saveWrapper} isLastStep />
            <Step2WebhookHelp />
        </>
    )
};

interface translations extends EventStreamTranslations {
    header: string
    headerDescription: string
    tlsOptions: string
    requestTimeoutSec: string
    batchOptions: string
    blockOnErrors: string
    headers: string
    value: string
    addHeader: string
    headersMustBeUnique: string
    invalidUrl: string
}
const enTranslations: translations = {
    ...EnEventStreamTranslations,
    header: 'Enter Webhook Details',
    headerDescription: 'Provide us the URL that can accept batches of events, and we will listen for, parse, batch-up and send you data from your chain.',
    tlsOptions: 'TLS options',
    requestTimeoutSec: 'Request timeout (sec)',
    batchOptions: 'Batch options',
    blockOnErrors: 'Block on errors',
    headers: 'Headers',
    value: 'Value',
    addHeader: 'Add header',
    headersMustBeUnique: 'Error: Header names must be unique.',
    invalidUrl: 'Invalid URL'
}