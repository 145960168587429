import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { CreateContractProjectData, CreateContractProjectVars, 
    CreateContractProjectMutation, MakeContractProjectCreateMutationOptions, ContractProjectType, Protocol } from '../../../models';
import { MessageSnackbar, ErrorSnackbarCatcher, CreateWrapper } from '../../../components/DialogWrappers'
import { Typography, Grid, FormControlLabel, TextField, FormControl, RadioGroup, Radio } from "@material-ui/core";
import { Step2Help } from './Step2Help'
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector'
import { NETWORK_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper'
import { CreateStepProps } from '../../../interfaces';

interface Props extends CreateStepProps {
    protocol: Protocol,
};

export const Step2 = ({ protocol, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep2:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id } = useParams<any>();

    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState<ContractProjectType | "">('');
    const [description, setDescription] = useState('');
    const [membershipId, setMembershipId] = useState('');

    useEffect(() => {
        if (protocol === 'corda') setType('corda_jar');
    }, [protocol]);

    const [createContractProject, { loading: createContractProjectLoading }] = 
        useMutation<CreateContractProjectData, CreateContractProjectVars>(CreateContractProjectMutation)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setType(event.target.value as ContractProjectType);
    };

    const mapProjectType = (s: ContractProjectType) => s

    const makeProjectTypeLabel = (label: string, description: string) => 
        <div>
            <Typography variant="body1">
                {label}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {description}
            </Typography>
        </div>

    const save = async () => {
        if (!type) return
        
        createContractProject(MakeContractProjectCreateMutationOptions({
            consortia_id: consortium_id!,
            contractProject: {
                name,
                membership_id: membershipId,
                description,
                type
            }
        })).then(result => {
            if (result.errors?.length) throw result.errors[0];
            if (result) {
                const newProjectId = result.data?.createContractProject?._id
                const splitPathname = pathname.split('/')
                const baseContractsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_CONTRACTS_PATH) + 1).join('/')
                if (newProjectId) {
                    history.push(`${baseContractsPath}/${newProjectId}`)
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const disabled = createContractProjectLoading || !name || !membershipId || !type

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item >
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            <Grid item>
                <TextField
                    data-test="contractProjectName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="none"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField
                    multiline
                    rows={2}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>

            {protocol === 'ethereum' && 
            <Grid item>
                <Typography variant="h5">
                    {lt('projectSourceCode')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('projectSourceCodeDescription')}
                </Typography>

                <FormControl component="fieldset" margin="dense">
                    <RadioGroup value={type} onChange={handleChange}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <FormControlLabel checked={type === 'github'} value={mapProjectType('github')} 
                                    control={<Radio color="primary" />} label={makeProjectTypeLabel(lt('github'), lt('githubDescription'))} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel checked={type === 'upload'} value={mapProjectType('upload')} 
                                    control={<Radio color="primary" />} label={makeProjectTypeLabel(lt('upload'), lt('uploadDescription'))} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel checked={type === 'precompiled'} value={mapProjectType('precompiled')} 
                                    control={<Radio color="primary" />} label={makeProjectTypeLabel(lt('precompiled'), lt('precompiledDescription'))} />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
            }

            {protocol === 'fabric' && 
            <Grid item>
                <Typography variant="h5">
                    {lt('fabricProjectType')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('fabricProjectDescription')}
                </Typography>

                <FormControl component="fieldset" margin="dense">
                    <RadioGroup value={type} onChange={handleChange}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <FormControlLabel checked={type === 'fabric_precompiled_go'} value={mapProjectType('fabric_precompiled_go')} 
                                    control={<Radio color="primary" />} label={makeProjectTypeLabel(lt('fabricGo'), lt('fabricGoDescription'))} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel checked={type === 'fabric_upload_node'} value={mapProjectType('fabric_upload_node')} 
                                    control={<Radio color="primary" />} label={makeProjectTypeLabel(lt('fabricNode'), lt('fabricNodeDescription'))} />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
            }
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isLastStep />
            <Step2Help {...{protocol}} />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    name: string,
    description: string,
    projectSourceCode: string,
    projectSourceCodeDescription: string,
    github: string,
    githubDescription: string,
    precompiled: string,
    precompiledDescription: string
    upload: string;
    uploadDescription: string;
    fabricProjectType: string
    fabricProjectDescription: string
    fabricGo: string
    fabricGoDescription: string
    fabricNode: string
    fabricNodeDescription: string
}
const enTranslations: translations = {
    header: 'Enter App Details',
    headerDescription: 'Create your app by specifying a name and telling us where the source code for this project is located.',
    name: 'Name',
    description: 'Description (optional)',
    projectSourceCode: 'Source code',
    projectSourceCodeDescription: 'Kaleido supports importing Solidity files from Github, or, you can "bring your own" source files.',
    github: 'Managed compilation from Github',
    githubDescription: 'My project source code is stored in a Github repository (supports Truffle projects!)',
    upload: 'Upload source',
    uploadDescription: 'I will upload my source code files for compilation',
    precompiled: 'Pre-compiled',
    precompiledDescription: 'I will compile the contracts myself, then upload the ABI, Bytecode and Dev Docs',
    fabricProjectType: 'Source code',
    fabricProjectDescription: 'Kaleido supports uploading a precompiled Golang executable file (.bin), or, a zipped Node.js project directory.',
    fabricGo: 'Golang Executable',
    fabricGoDescription: 'Select to upload a precompiled Golang executable file with a .bin extension.',
    fabricNode: 'Node.js Project',
    fabricNodeDescription: 'Select to upload a zipped Node.js project directory (including Typescript). Kaleido will install your project\'s node modules during chaincode packaging.'
}