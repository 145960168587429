import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicItems } from './BasicItems';

export const ROTATE_SIGNERS_MANAGE_PATH = 'manage';

interface Props {
    baseServicePath: string
}

export const RotateSignersItems = ({ baseServicePath } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'RotateSignersItems', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`RotateSignersItems:${key}`, interpolate)

    const MANAGE = lt('manage')

    const serviceSpecificTabs = [
        {
            name: MANAGE,
            path: ROTATE_SIGNERS_MANAGE_PATH,
            selectedItem: MANAGE
        }
    ]

    return (
        <BasicItems {...{baseServicePath}} {...{serviceSpecificTabs}} />
    );
};

interface translations {
    manage: string
}

const enTranslations: translations = {
    manage: 'Manage',
}