import React, { useState } from 'react';
import { FormDialog } from '../DialogWrappers';
import { DisplayTable, ToolBarOptions } from '../DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { EnvironmentChainConfig as EnvChainConfig } from '../../models/environmentStatus';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    chainConfig: EnvChainConfig,
    optionalChainConfig: string[]
}

export const EnvironmentChainConfig = ({ open, setOpen, chainConfig, optionalChainConfig }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentChainConfig', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentChainConfig:${key}`, interpolate);
    const validEIPs = Object.keys(chainConfig).filter(eip => !(eip.startsWith('__typename') || chainConfig[eip as keyof EnvChainConfig] == null));

    const columns = [lt('config'), lt('blockNumber')];

    const [typeSelected, setTypeSelected] = useState(0);

    const eips : DisplayTableRecord[] = validEIPs.map(cfg => ({
        columns: [{
            value: cfg
        }, {
            value: chainConfig[cfg as keyof EnvChainConfig] || 0
        }]
    }))

    const optionalEIPS: DisplayTableRecord[] = optionalChainConfig.map(cfg => ({
        columns: [{
            value: cfg
        }, {
            value: ''
        }]
    }))

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTypeSelected(newValue);
    };

    const toolBarOptions: ToolBarOptions = {
        headerTab: {
            onChange: handleChange,
            selected: typeSelected,
            tabs: [{
                label: lt('appliedConfig'),
                action: () => setTypeSelected(0)
            }, {
                label: lt('availableConfig'),
                action: () => setTypeSelected(1),
            }]
        }
    }

    const entries = (type: number) => {
        if (type === 0) {
            return eips;
        } else {
            if (optionalChainConfig.length === 0) return [];
            else return optionalEIPS;
        }
    }

    const makeControls = (type: number) => {
        return <DisplayTable height="50vh" records={entries(type)} columnHeaders={columns} {...{toolBarOptions}} stickyHeader emptyLabel={lt('emptyTable')} />
    }

    return (
        <FormDialog {...{open}} {...{setOpen}} header={lt('envChainConfiguration')} hideActions
            disableContentPadding dialogType="medium" controlsWrapper={makeControls(typeSelected)} width="md" />
    )
};

interface translations {
    config: string
    blockNumber: string
    envChainConfiguration: string
    emptyTable: string
    appliedConfig: string
    availableConfig: string
};

const enTranslations: translations = {
    config: 'Chain Configuration EIP',
    blockNumber: 'Block Number',
    envChainConfiguration: 'Chain Configuration',
    emptyTable: 'No EIPs found',
    appliedConfig: 'Current Chain Configuration',
    availableConfig: 'Available for Hard Fork'
};