import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { RegenerateAppCredData, RegenerateAppCredMutation } from '../../../models'
import { FormDialog } from '../../../components/DialogWrappers'
import { EnvironmentResourceVars } from '../../../interfaces';

interface Props {
    name: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const Regenerate = ({ name, open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppCredsRegenerate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppCredsRegenerate:${key}`, interpolate)

    const { consortium_id, environment_id, appcred_id } = useParams<any>();

    const [regenerateAppCred, { loading: regenerateAppCredLoading }] = useMutation<RegenerateAppCredData, EnvironmentResourceVars>(RegenerateAppCredMutation)
    
    const updater = () => {         
        return regenerateAppCred({
            variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: appcred_id!
        }}).then(() => {})
    }

    return <FormDialog 
        {...{open}} 
        {...{setOpen}} 
        header={lt('regenerateHeader', {name})} 
        description={lt('regenerateDescription')} 
        saveDisabled={regenerateAppCredLoading}
        onSave={updater}
        closeDialogAfterSave
        saveText={lt('regenerate')} />
};

interface translations {
    regenerateHeader: string,
    regenerateDescription: string,
    regenerate: string
}
const enTranslations: translations = {
    regenerateHeader: 'Regenerate this app cred ({{name}})?',
    regenerateDescription: 'This will regenerate a new password for this app cred and will invalidate the prior password.',
    regenerate: 'Regenerate'
}