import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { Environment, Membership, ContractProject, ConsortiumZone } from '../../models'
import { DisplayCard } from '../../components/DisplayWrappers'
import AppsIcon from 'mdi-react/AppsIcon';
import LanIcon from 'mdi-react/LanIcon';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon'
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon'
import { NETWORK_GOVERNANCE_PATH, NETWORK_GOVERNANCE_MEMBERSHIPS_PATH } from '../../components/MainNav/SideNavs/Governance';
import { makeStyles } from '@material-ui/core';

interface Props {
    environments: Environment[],
    consortiumMemberships: Membership[],
    contractProjects: ContractProject[],
    consortiumZones: ConsortiumZone[],
    supportsMultiRegion: boolean,
    loading: boolean
};

export const Overview = ({ environments, consortiumMemberships, contractProjects, consortiumZones, supportsMultiRegion, loading }: Props) => {
    const classes = useStyles();
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NetworkDashboardOverview', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NetworkDashboardOverview:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const homeRegion = consortiumZones.find(c => c.default)

    const itemList = [
        {
            title: lt('environments'), 
            value: environments.length,
            icon: <LanIcon />
        },
        {
            title: lt('memberships'), 
            value: consortiumMemberships.length,
            icon: <CardAccountDetailsOutlineIcon />
        },
        {
            title: lt('contractProjects'), 
            value: contractProjects.length,
            icon: <AppsIcon />
        },
        {
            title: supportsMultiRegion ? lt('enabledRegions') : lt('homeRegion'), 
            value: supportsMultiRegion ? consortiumZones.length : `${homeRegion?.cloud?.toUpperCase()}: ${homeRegion?.region}`,
            icon: <MapMarkerOutlineIcon />,
            actionIcon: !supportsMultiRegion && homeRegion ? <img className={classes.flag} src={`${process.env.PUBLIC_URL}/img/flags/4x3/${homeRegion!.flag}.svg`} alt=""></img> : undefined
        }
    ]

    const linkButton = {
        text: lt('viewMemberships'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}`)
    }
   
    return (
        <>
            <DisplayCard {...{loading}}
                header={lt('overview')} 
                {...{itemList}}
                {...{linkButton}} />
        </>
    )
};

const useStyles = makeStyles(() => ({
    flag: {
        height: '30px',
        width: '30px'
    }
}));

interface translations {
    overview: string
    environments: string
    memberships: string
    contractProjects: string
    enabledRegions: string
    homeRegion: string
    viewMemberships: string
    addRegion: string
}
const enTranslations: translations = {
    overview: 'Overview',
    environments: 'Environments',
    memberships: 'Memberships',
    enabledRegions: 'Enabled Regions',
    homeRegion: 'Home Region',
    contractProjects: 'Apps',
    viewMemberships: 'View Memberships',
    addRegion: 'Add Region'
}