import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { MessagingKPI } from "../../../components/Charts/B2B/MessagingKPI";
import { ConnectRuntimeWrapper } from "../../../components/ConnectRuntime/ConnectRuntimeWrapper";
import { DisplayCard, DisplayTable } from "../../../components/DisplayWrappers";
import { SECURITY_BASE_PATH } from "../../../components/MainNav/SideNavs/Security";
import { B2B_BASE_PATH } from "../../../components/MainNav/SideNavs/B2bCommunication";
import { APP2APP_DESTINATIONS_PATH } from "../../../components/ServicesNav/Services/App2AppItems";
import { EnvironmentResourceVars, LinkButtonProps, ServiceResourcesVars } from "../../../interfaces";
import { App2AppDestinationsData, App2appDestinationsQuery, App2AppMetricsData, App2AppMetricsQuery, App2AppMetricsVars, ServiceData, ServiceQuery, ServicesEnum } from "../../../models";
import { MonthlyLimits } from "./MonthlyLimits";

export const App2AppDashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'App2AppDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`App2AppDashboard:${key}`, interpolate);

    const history = useHistory()
    const { consortium_id, environment_id, service_id, org_id } = useParams<any>();

    const { data: { service } = { service: null } } = useQuery<
        ServiceData,
        EnvironmentResourceVars
    >(ServiceQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: service_id!,
        },
        fetchPolicy: "cache-only",
    });

    const thisMonth = moment().month() + 1
    const thisYear = moment().year()
    const {
        data: {
            app2AppMetrics
        } = { app2AppMetrics: null },
    } = useQuery<App2AppMetricsData, App2AppMetricsVars>(App2AppMetricsQuery, {
        variables: {
            consortia_id: consortium_id, 
            environment_id, 
            id: service_id,
            month: thisMonth,
            year: thisYear
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 20000
    });

    const {
        loading: destinationsLoading,
        data: {
            app2appDestinations
        } = { app2appDestinations: null },
    } = useQuery<App2AppDestinationsData, ServiceResourcesVars>(App2appDestinationsQuery, {
        variables: { service_id: service_id! }, 
        fetchPolicy: 'cache-and-network', 
        skip: service?.state !== 'started'
    });

    const documentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("app2app"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://docs.kaleido.io/kaleido-services/app2app/"
                ),
        },
        {
            icon: <DescriptionIcon />,
            title: lt("app2appApi"),
            value: lt("apiDocs"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://api.kaleido.io/app2app.html"
                ),
        },
        {
            icon: <DescriptionIcon />,
            title: lt("snippets"),
            value: lt("snippetsDescription"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://github.com/kaleido-io/app2app-snippets"
                ),
        },
    ];

    const appCredsLinkButton: LinkButtonProps = {
        text: lt('viewAppCreds'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}`)
    };

    const destinationsLinkButton: LinkButtonProps = {
        text: lt('viewAllDestinations'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/${service_id}/${APP2APP_DESTINATIONS_PATH}`)
    };
    const records = app2appDestinations?.map((entry, index) => ({
        key: `${index}`,
        columns: [{
            value: entry.uri
        },{
            value: lt(entry.kaleido_managed ? 'kaleidoManaged' : 'externallyManaged')
        }, {
            value: lt(entry.setup_complete ? 'ready' : 'setupIncomplete')
        }],
    }));
    const headers = [lt('uri'), lt('type'), lt('status')];

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">
                    {lt("app2appOverview")}
                </Typography>
            </Grid>

            <Grid item>
                <MessagingKPI allRuntimes={[{ _id: service_id, name: service!.name }]} forDashboard />
            </Grid>

            <Grid item container direction="row" spacing={3}>
                <Grid item container xs={12} lg={8}>
                    <DisplayTable linkButton={destinationsLinkButton} emptyLabel={lt('noDestinations')} loading={destinationsLoading && !app2appDestinations?.length} columnHeaders={headers} {...{records}} header={lt('destinations')} />
                </Grid>
                {app2AppMetrics &&
                <Grid item container xs={12} lg={4}>
                    <MonthlyLimits {...{app2AppMetrics}} service={service!} />
                </Grid>
                }

                <Grid item container xs={12} md={6} lg={8}>
                    <ConnectRuntimeWrapper customLinkButton={appCredsLinkButton} service={service!} />
                </Grid>

                <Grid item container xs={12} sm={4}>
                    <DisplayCard
                        header={lt("documentation")}
                        itemList={documentationList}
                    />
                </Grid>
            </Grid>                
        </Grid>
    );
}

interface translations {
    dashboard: string
    app2app: string
    apiDocs: string
    documentation: string
    app2appApi: string
    snippets: string
    snippetsDescription: string
    app2appOverview: string
    viewAppCreds: string
    kaleidoManaged: string
    externallyManaged: string
    uri: string
    type: string
    status: string
    ready: string
    setupIncomplete: string
    viewAllDestinations: string
    noDestinations: string
    destinations: string
}

const enTranslations: translations = {
    dashboard: 'Dashboard',
    app2app: "App 2 App",
    apiDocs: "API Docs",
    documentation: "Documentation",
    app2appApi: "App 2 App API",
    snippets: 'App 2 App Snippets',
    snippetsDescription: 'View sample code for this runtime',
    app2appOverview: 'App 2 App Overview',
    viewAppCreds: 'View App Creds',
    kaleidoManaged: 'Kaleido managed',
    externallyManaged: 'Externally managed',
    uri: 'URI',
    type: 'Type',
    status: 'Status',
    ready: 'Ready',
    setupIncomplete: 'Setup Incomplete',
    viewAllDestinations: 'View All Destinations',
    noDestinations: 'No Destinations',
    destinations: 'Destinations'
}