import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { DisplayGridWrapper } from '../DisplayWrappers'
import { Grid, Select, MenuItem, Divider, IconButton } from "@material-ui/core";
import CloudDownloadOutlinedIcon from 'mdi-react/CloudDownloadOutlineIcon';
import { LogsContent } from './LogsContent'
import { capitalize } from '../../utils/StringUtils';

interface Props {
    logContainers: string[]
};

export const LogsWrapper = ({ logContainers }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LogsLogsWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LogsLogsWrapper:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id, node_id, service_id, container: urlContainer } = useParams<any>();

    const container = urlContainer ? logContainers.find(c => c === urlContainer) || logContainers[0] : logContainers[0]

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newContainer = event.target.value as string;

        if (newContainer !== container) {
            const splitPath = pathname.split('/')
            const lastItem = splitPath[splitPath.length - 1]
            
            if (lastItem === 'logs') {
                history.push(`${pathname}/${newContainer}`)
            } else {
                const newPath = splitPath.slice(0, splitPath.length - 1).join('/')
                history.push(`${newPath}/${newContainer}`)
            }
        }
    };

    const mapContainerToDisplayName = (l: string) => {
        if (l in enTranslations) {
            return lt(l as keyof translations);
        }        
        return capitalize(l);
    }

    let downloadPrefix = `/api/ui/v2/consortia/${consortium_id}/environments/${environment_id}`
    if (node_id) {
        downloadPrefix += `/nodes/${node_id}`
    } else {
        downloadPrefix += `/services/${service_id}`
    }
    downloadPrefix += `/logs/${container}`

    const actionBar = (
        <Grid item container spacing={3} direction="row">
            <Grid item>
                <Select
                    value={container}
                    onChange={handleChange}>
                    {logContainers.map(l => {
                        const displayName = mapContainerToDisplayName(l)
                        return (
                            <MenuItem key={l} selected={l === container} value={l}>{displayName}</MenuItem>
                        )
                    })}
                </Select>
            </Grid>
            <Grid item>
                <Divider orientation="vertical" />
            </Grid>
            <Grid item>
                <IconButton color="inherit" size="small" onClick={() => window.open(downloadPrefix)}>
                    <CloudDownloadOutlinedIcon />
                </IconButton>
            </Grid>
        </Grid>
    )

    // set key=container to recreate the entire LogsContent component with a freshly initialized state any time the container changes
    const content = (
        <LogsContent key={container} {...{container}} />
    )

    return (
        <>
            <DisplayGridWrapper 
                header={lt('viewRecentLogs')} 
                {...{actionBar}}
                displayGrid={content} />
        </>
    )
}

// might look like some of these arent used, but `mapContainerToDisplayName` allows overriding the container name. (ex: ethwallet) 
interface translations {
    viewRecentLogs: string,
    geth: string,
    pantheon: string,
    quorum: string,
    constellation: string,
    ethsigner: string,
    orion: string,
    autonity: string,
    ethconnect: string,
    baf: string,
    ethwallet: string,
    'polygon-edge': string
}
const enTranslations: translations = {
    viewRecentLogs: 'Viewing recent logs',
    geth: "Geth",
    pantheon: "Hyperledger Besu",
    quorum: "Quorum",
    constellation: "Tessera (TX Manager)",
    ethsigner: "EthSigner (Embedded Wallet)",
    orion: "Orion (TX Manager)",
    autonity: "Autonity",
    ethconnect: "REST API Gateway",
    baf: "Blockchain Application Firewall",
    ethwallet: 'Kaleido Wallet',
    'polygon-edge': 'Polygon Edge'
}