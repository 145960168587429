import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { NETWORK_COMPILED_CONTRACTS_PATH, NETWORK_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { Step1 } from './Step1';

export const Promote: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsPromoteCompilation', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsPromoteCompilation:${key}`, interpolate)

    type locationState = { environmentId: string, promotedEnvironments: string[] }
    const history = useHistory<locationState>()
    const { pathname } = useLocation()
    const { org_id, consortium_id, contract_id, compiled_contract_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${contract_id}/${NETWORK_COMPILED_CONTRACTS_PATH}/${compiled_contract_id}`

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const [environmentId, setEnvironmentId] = useState('');

    const { 
        location: { 
            state: {
                environmentId: promoteEnvironmentId,
                promotedEnvironments
            } = { environmentId: '', promotedEnvironments: [] }
        }
    } = history

    useEffect(() => {
        setEnvironmentId(promoteEnvironmentId)
    }, [promoteEnvironmentId]);

    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep >= 1) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: '', component: <Step1 cancelPath={cancelPath} {...{promotedEnvironments}} {...{environmentId}} {...{setEnvironmentId}} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} hideStepper toolbarHeader={lt('promote')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    promote: string,
}
const enTranslations: translations = {
    promote: 'Promote the Compilation to an Environment',
}