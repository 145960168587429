import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { SideNavProps } from '../../../interfaces';
import { SubNavs } from './SubNavs';
import { ConfigTypes, ConfigTypeTranslationsInterface, ConfigTypeTranslations } from '../../../models/configs';
import CloudQueueIcon from 'mdi-react/CloudOutlineIcon';
import { NavItem } from '../../NavComponents/NavItem';
import { BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH } from '../..//MainNav/SideNavs/Blockchain';

//For a new Config to be added this types must be updated
export type CloudConfigTypesUrl = "backup" | "logstream" | "keystore" | "networking" | "storage" | "cloudhsm";
export type ConfigTypesUrl = CloudConfigTypesUrl | "baf" | typeof BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH;

export const cloudConfigTypeMap: Map<CloudConfigTypesUrl, ConfigTypes> = new Map([
    ['backup', 'backup'],
    ['keystore', 'kms'],
    ['logstream', 'opsmetric'],
    ['networking', 'networking'],
    ['storage', 'storage'],
    ['cloudhsm', 'cloudhsm'],
]);

export const configTypeMap: Map<ConfigTypesUrl, ConfigTypes> = new Map([
    ...cloudConfigTypeMap,
    ['baf', 'baf'],
    ['configuration', 'node_config']
]);

export const cloudConfigTypeList : string[] = [];
export const cloudConfigTypeURList: string[] = [];

for (let key of cloudConfigTypeMap.keys()) {
    cloudConfigTypeURList.push(key)
}

cloudConfigTypeMap.forEach(t => cloudConfigTypeList.push(t.valueOf()))

// -----------------------------------------------------------

export const CLOUDCONFIG_BASE_PATH = 'cloudconfig';
export const CLOUDCONFIG_BACKUP_PATH = 'backup';
export const CLOUDCONFIG_KEYSTORE_PATH = 'keystore';
export const CLOUDCONFIG_LOGSTREAM_PATH = 'logstream';
export const CLOUDCONFIG_NETWORK_PATH = 'networking';
export const CLOUDCONFIG_STORAGE_PATH = 'storage';
export const CLOUDCONFIG_CLOUDHSM_PATH = 'cloudhsm';
export const CREATE_CLOUDCONFIG_PATH = 'create';
export const ATTACH_CLOUDCONFIG_PATH = 'attach';

export const CloudConfigs = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CloudConfigs', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CloudConfigs:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const historyPusher = useCallback((path?: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}${path ? `/${path}` : ''}`), 
        [history, org_id, consortium_id, environment_id])

    const DASHBOARD = lt('dashboard');
    const BACKUP = lt('backup');
    const KEYSTORE = lt('keystore');
    const LOGSTREAM = lt('logstream');
    const NETWORK = lt('networking');
    const STORAGE = lt('storage');
    const CLOUDHSM = lt('cloudhsm');


    const addressBookSubItems = useCallback(() => {
        return [{
            name: DASHBOARD,
            action: () => historyPusher()
        },{
            name: KEYSTORE,
            action: () => historyPusher(CLOUDCONFIG_KEYSTORE_PATH)
        },{
            name: BACKUP,
            action: () => historyPusher(CLOUDCONFIG_BACKUP_PATH)
        },{
            name: LOGSTREAM,
            action: () => historyPusher(CLOUDCONFIG_LOGSTREAM_PATH)
        },{
            name: NETWORK,
            action: () => historyPusher(CLOUDCONFIG_NETWORK_PATH)
        },{
            name: STORAGE,
            action: () => historyPusher(CLOUDCONFIG_STORAGE_PATH)
        },{
            name: CLOUDHSM,
            action: () => historyPusher(CLOUDCONFIG_CLOUDHSM_PATH)
        }]
    }, [historyPusher, DASHBOARD, BACKUP, KEYSTORE, LOGSTREAM, NETWORK, STORAGE, CLOUDHSM])

    
    useEffect(() => {
        
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        const splitPathname = pathname.split('/');
        const configType = splitPathname[splitPathname.length -2];
        
        if (pathname.endsWith(`${environment_id}/${CLOUDCONFIG_BASE_PATH}`)) {
            setSelectedItem(DASHBOARD);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_KEYSTORE_PATH}`) || 
            configType === CLOUDCONFIG_KEYSTORE_PATH) {
            setSelectedItem(KEYSTORE);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_BACKUP_PATH}`) ||
            configType === CLOUDCONFIG_BACKUP_PATH) {
            setSelectedItem(BACKUP);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_LOGSTREAM_PATH}`) || 
            configType === CLOUDCONFIG_LOGSTREAM_PATH) {
            setSelectedItem(LOGSTREAM);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_NETWORK_PATH}`) || 
            configType === CLOUDCONFIG_NETWORK_PATH) {
            setSelectedItem(NETWORK);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_STORAGE_PATH}`) || 
            configType === CLOUDCONFIG_STORAGE_PATH) {
            setSelectedItem(STORAGE);
            setActiveAndSelected()
        } else if (pathname.endsWith(`${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_CLOUDHSM_PATH}`) || 
            configType === CLOUDCONFIG_CLOUDHSM_PATH) {
            setSelectedItem(CLOUDHSM);
            setActiveAndSelected()
        } else {
            setActiveAndSelected(false)
            setSelectedItem('')
        }
    }, [setSelected, environment_id, pathname, DASHBOARD, BACKUP, KEYSTORE, LOGSTREAM, NETWORK, STORAGE, CLOUDHSM]);

    return (
        <>
            <NavItem icon={<CloudQueueIcon />}
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={addressBookSubItems()} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations extends ConfigTypeTranslationsInterface {
    name: string
    dashboard: string,
}
const enTranslations: translations = {
    ...ConfigTypeTranslations,
    name: 'Cloud Configurations',
    dashboard: 'Dashboard',
}


