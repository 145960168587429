import { gql } from '@apollo/client';

// INTERFACES

export interface MFATypeData {
    mfaType: string
}

export interface MFADraft {
    secret: string
}

export interface MFAConfirmation {
    recovery_codes: string[]
}

export interface MFADraftData {
    draftMFA: MFADraft
}

export interface MFAConfirmationData {
    confirmMFA: MFAConfirmation
}

export interface MFAInput {
    token?: string
    recovery_code?: string
}

export interface MFADisableData {
    disableMFA: boolean
}

export interface MFAVars {
    mfaInput: MFAInput
}

export interface MFADraftVars {
    mfaInput?: MFAInput
}

// QUERIES & SUBSCRIPTIONS

export const MFATypeQuery = gql`
    query mfaType {
        mfaType
    }`;

export const DraftMFAMutation = gql`
    mutation draftMFA($mfaInput: MFAInput) {
      draftMFA(mfaInput: $mfaInput) {
            secret
        }
    }`;

export const ConfirmMFAMutation = gql`
    mutation confirmMFA($mfaInput: MFAInput!) {
        confirmMFA(mfaInput: $mfaInput) {
            recovery_codes
            }
        }
    `;

export const DisableMFAMutation = gql`
    mutation disableMFA($mfaInput: MFAInput!) {
        disableMFA(mfaInput: $mfaInput)
        }
    `;