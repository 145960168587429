import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ConsortiumResourceVars } from '../../interfaces';
import { EnvironmentData, EnvironmentQuery, Service, ServicesEnum } from '../../models';
import { NavPopoverItem } from '../MainNav/SideNavs/NavPopoverItem';

interface Props {
    createServicePath: string,
    services: Service[]
}

const ServicesPicker = ({ createServicePath, services } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ServicesPicker', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ServicesPicker:${key}`, interpolate)

    const history = useHistory();
    const { consortium_id, environment_id, service_id } = useParams<any>();
    const { pathname } = useLocation();

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const service = services.find(s => s._id === service_id);

    const handleServicesSelect = (service: Service) => {
        let splitPath = pathname.split('/');
        const foundIndex = splitPath.findIndex((x: string) => x === service_id);
        splitPath[foundIndex - 1 ] = service.service;
        splitPath[foundIndex] = service._id;
        history.push(splitPath.slice(0, foundIndex + 1).join('/'));
    };

    const popoverItems = services.filter(s => s._id !== service!._id && s.membership.isMine).map(s => ({
        name: s.name,
        action: () => handleServicesSelect(s),
        divider: false,
        disabled: false
    }));

    if (popoverItems.length) {
        popoverItems[popoverItems.length - 1].divider = true
    }

    if(service?.service !== ServicesEnum.rotatesigners && service?.service !== ServicesEnum.idregistry && service?.service !== ServicesEnum.tether) {
        popoverItems.push({
            name: lt('addRuntime'),
            action: () => history.push(`${createServicePath}/create/1`),
            divider: false,
            disabled: environment?.state !== 'live'
        })
    }

    const item = {
        name: service!.name, 
        popoverItems
    }

    return <NavPopoverItem itemIsActive={true} {...{item}} />;
};


interface translations {
    addRuntime: string,
}
const enTranslations: translations = {
    addRuntime: 'Add Runtime',
}

export { ServicesPicker };

