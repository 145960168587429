import React, { useState } from 'react';
import { AddressTransactionsData, AddressTransactionsVars, AddressTransactionsQuery } from '../../models';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { TransactionsTable } from '../Transactions/TransactionsTable';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../DialogWrappers';
import { TablePagination, CircularProgress } from '@material-ui/core';

const LIMITLIST = [10, 25];

interface Props {
    address: string
}
export const AddressTransactions = ({address}: Props) => {
    const [limit, setLimit] = useState(LIMITLIST[0]);
    const [currentPage, setCurrentPage] = useState(0);
    const [errorMessage, setErrorMessasge] = useState('');

    const { consortium_id, environment_id} = useParams<any>();

    const {
        fetchMore,
        loading: transactionsLoading,
        data: {
            addressTransactions
        } = {addressTransactions: []}
    } = useQuery<AddressTransactionsData, AddressTransactionsVars>(AddressTransactionsQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            address: address,
            limit
        }, 
        fetchPolicy: 'cache-and-network'
    });

    if(transactionsLoading && addressTransactions.length === 0) return <CircularProgress />;

    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        try {
            if ( page > currentPage && (page * limit) >= addressTransactions.length) {
                await fetchMore({
                    variables: {
                        start: page * limit
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return {addressTransactions: [...prev.addressTransactions, ...fetchMoreResult.addressTransactions]}
                    }
                })
            }
            setCurrentPage(page);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    };

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCurrentPage(0)
        setLimit(parseInt(event.target.value));
    }

    
    const transactionsRecords = addressTransactions.slice(currentPage * limit, currentPage * limit + limit);
    const Pagination = <TablePagination rowsPerPageOptions={LIMITLIST} component="div" count={transactionsRecords.length + 1 > limit ? -1 : addressTransactions.length} rowsPerPage={limit} onChangeRowsPerPage={onChangeRowsPerPage} onPageChange={onChangePage} page={currentPage} />;

    return (
        <>
            <MessageSnackbar setMessage={setErrorMessasge} message={errorMessage} />
            <TransactionsTable transactions={transactionsRecords} pagination={Pagination} />
        </>
    )
};
