import React, { useState} from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useLocation, Redirect, useHistory } from 'react-router-dom';
import { CopyableSetting, CopyableSettings, EditableSettings } from '../../components/DisplaySettings';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { Grid, Typography, CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LedgerContractQuery, LedgerContractData, AddressWidgetInfoData, AddressWidgetInfoQuery, AccountsQuery, AccountsData, 
    Account, TokenContractBalanceOfData, TokenContractBalanceOfVars, TokenContractBalanceOfQuery } from '../../models';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { APPS_BASE_PATH, APPS_GATEWAY_APIS_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';
import { EnvironmentResourceVars } from '../../interfaces';
import Jazzicon from 'react-jazzicon';
import { jsNumberForAddress } from '../../utils/StringUtils';
import { FormLink } from '../../components/FormControls/FormLink';
import { TransferDialog } from './TransferDialog';
import { MintDialog } from './MintDialog';
import { AccountSelector } from '../../components/AccountSelector/AccountSelector';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_TOKENS_PATH } from '../../components/MainNav/SideNavs/DigitalAssets';
import SendIcon from 'mdi-react/SendIcon';
import FireIcon from 'mdi-react/FireIcon';
import GoldIcon from 'mdi-react/GoldIcon';
import { NoGatewayAPI } from '../../components/Banners/NoGatewayAPI';

type locationState = {account?: Account, sender?: Account}

export const TokenInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenInfo:${key}`, interpolate);

    

    const {consortium_id, environment_id, token_address, org_id} = useParams<any>();
    const history = useHistory<locationState>();
    const { pathname } = useLocation()
    const redirectUrl = pathname.replace(`${token_address}`, '');
    const classes = useStyles();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        location: {
            state: {
                account,
            } = { account: null}
        }
    } = history;

    const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(account || undefined);
    const [openTransfer, setOpenTransfer] = useState(false);
    const [openMint, setOpenMint] = useState(false);
    
    const {
        loading,
        data: {
            ledgerContract
        } = {ledgerContract: null}
    } = useQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        variables: {
            ...environmentVariables,
            id: token_address!
        },
        fetchPolicy: 'cache-first'
    });

    const {
        loading: addressLoading,
        data: {
            addressWidgetInfo
        } = { addressWidgetInfo: null }
    } = useQuery<AddressWidgetInfoData, EnvironmentResourceVars>(AddressWidgetInfoQuery, {
        variables: {
            ...environmentVariables,
            id: token_address!.toLowerCase()
        }
    });

    const {
        loading: accountsLoading,
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData>(AccountsQuery, {
        variables: environmentVariables,
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: {
            tokenContractBalanceOf: accountBalance
        } = { tokenContractBalanceOf: '' }
    } = useQuery<TokenContractBalanceOfData, TokenContractBalanceOfVars>(TokenContractBalanceOfQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            contractAddress: token_address!,
            accountAddress: selectedAccount?._id!
        },
        fetchPolicy: 'cache-and-network',
        skip: !selectedAccount
    });

    if ((loading && !ledgerContract) || (addressLoading && !addressWidgetInfo) || (accountsLoading && !accounts)) return <CircularProgress />;
    if (!ledgerContract || !addressWidgetInfo) return <Redirect to={redirectUrl} />;

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/`;

    const getType = () => {
        if (ledgerContract.isERC20) return lt('erc20')
        if (ledgerContract.isERC721) return lt('erc721');
        return lt('token');
    }

    const isMintingDisabled = () => {
        if((ledgerContract.isERC721 || ledgerContract.isERCMintable) && selectedAccount) {
            if (ledgerContract.creator === selectedAccount._id) return false;
            if (ledgerContract.ERCMinters && ledgerContract.ERCMinters.length > 0 && ledgerContract.ERCMinters.includes(selectedAccount._id)) return false;
            return true
        }
        return true
    };

    const isBurningDisabled = () => {
        if(ledgerContract.isERCBurnable && selectedAccount) {
            return parseInt(accountBalance) === 0
        }
        return true
    };

    const isTransferableDisabled = () => {
        if (selectedAccount) {
            return parseInt(accountBalance) === 0
        }
        return true
    }

    const tokenInfoList : CopyableSetting[] = [
        {
            title: lt('address'),
            displayValue: <ShortenedHash showFullAddress address={ledgerContract.address ?? ''} />,
            copyableValue: ledgerContract.address ?? ''
        }, {
            title: lt('type'),
            displayValue: getType(),
            disableCopy: true
        }, {
            title: lt('mintable'),
            displayValue: ledgerContract.isERCMintable  || ledgerContract.isERC721 ? lt('yes') : lt('no'),
            disableCopy: true
        },{
            title: lt('burnable'),
            displayValue: ledgerContract.isERCBurnable ? lt('yes') : lt('no'),
            disableCopy: true
        }];
    
        if(ledgerContract.isERC20) {
            tokenInfoList.push({
                title: lt('totalSupply'),
                displayValue: ledgerContract.erc20TotalSupply ?? '--',
                disableCopy: true,
            })
        }

        tokenInfoList.push( {
            title: lt('deployedBy'),
            displayValue: <ShortenedHash showFullAddress address={ledgerContract.creator ?? ''} /> ,
            copyableValue: ledgerContract.creator ?? ''
        }, {
            title: lt('genesisTransaction'),
            displayValue: <FormLink children={ledgerContract.genesisTransaction} to={`${basePath}${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}/${ledgerContract.genesisTransaction}`} />,
            copyableValue: ledgerContract.genesisTransaction ?? ''
        },)

    if (ledgerContract.gatewayAPIId) {
        tokenInfoList.push({
            title: lt('gatewayAPIId'),
            displayValue: <FormLink children={ledgerContract.gatewayAPIEndpoint ?? ledgerContract.gatewayAPIId} to={`${basePath}${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${ledgerContract.gatewayAPIId}`}/>,                
            copyableValue: ledgerContract.gatewayAPIId ?? ''
        })
    }

    const rolesList : CopyableSetting[] = [
        {
            title: lt('owner'),
            displayValue: <ShortenedHash showFullAddress address={ledgerContract.creator ?? ''} />,
            disableCopy: true
        }
    ]

    if (ledgerContract.ERCMinters && ledgerContract.ERCMinters.length > 0) {
        rolesList.push({
            title: lt('minters'),
            displayValue: ledgerContract.ERCMinters.map(minter => <ShortenedHash showFullAddress address={minter} />),
            disableCopy: true
        })
    };

    const actionPathPusher = (action: string) => 
        history.push(`${basePath}${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/${token_address}/${action}/1`, {
            sender: selectedAccount
        })
    
    const header = (
        <Grid container spacing={1} alignItems="center">
            <Grid item className={classes.icon}>
                <Jazzicon diameter={20} seed={jsNumberForAddress(ledgerContract.address)} />
            </Grid>
            <Grid item>
                <Typography variant="h6" noWrap>
                    {`${ledgerContract.tokenName} (${ledgerContract.tokenSymbol})`}
                </Typography>
            </Grid>
        </Grid>
    );

    const accountMintText = () => {
        if(!ledgerContract.gatewayAPIId) return lt('unknownToken')
        if (!ledgerContract.isERCMintable && !ledgerContract.isERC721) return lt('tokenNotMintable');
        if (!selectedAccount) return lt('noAccountSelected');
        if((ledgerContract.creator !== selectedAccount._id) &&
            (!ledgerContract.ERCMinters || (ledgerContract.ERCMinters.length > 0 && !ledgerContract.ERCMinters.includes(selectedAccount._id)))) return lt('accountNotMinter');
        return ''
    };

    const accountBurnText = () => {
        if(!ledgerContract.gatewayAPIId) return lt('unknownToken');
        if(!ledgerContract.isERCBurnable) return lt('tokenNotBurnable')
        if(!selectedAccount) return lt('noAccountSelected');
        if(parseInt(accountBalance) === 0) return lt('insufficientFunds');
        return ''
    }

    const actionsList = [{
        icon: <SendIcon />,
        title: lt('addressBalance'),
        buttonLabel: lt('transfer'),
        disabledButton: !ledgerContract.gatewayAPIId || isTransferableDisabled(),
        value: selectedAccount ? accountBalance : lt('noAccountSelected'),
        action: () => actionPathPusher('transfer')
    }, {
        icon: <GoldIcon />,
        title: lt('mintable'),
        value: accountMintText(),
        buttonLabel: lt('mint'),
        disabledButton: !ledgerContract.gatewayAPIId || isMintingDisabled(),
        action: () => actionPathPusher('mint')
    }, {
        icon: <FireIcon />,
        value: accountBurnText(),
        title: lt('burnable'),
        buttonLabel: lt('burn'),
        disabledButton: !ledgerContract.gatewayAPIId || isBurningDisabled(),
        action: () => actionPathPusher('burn')
    }];

    const accountSectionHeader = (
        <Grid item container alignItems="center" spacing={1}>
            <Grid item>
                <Typography variant="h6">{lt('signingAccountActions')}</Typography>
            </Grid>
            {selectedAccount && (
                <Grid item>
                    <ShortenedHash showFullAddress address={selectedAccount._id} />
                </Grid>
            )}
        </Grid>
    );

    return (
        <>
            <MintDialog fromAccount={selectedAccount?._id} open={openMint} setOpen={setOpenMint} />
            <TransferDialog open={openTransfer} setOpen={setOpenTransfer} fromAccount={selectedAccount?._id} />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Typography variant="h5">{lt('header')}</Typography>
                    </Grid>
                    <Grid item>
                        <AccountSelector setAccount={setSelectedAccount} account={selectedAccount} isMine />
                    </Grid>
                </Grid>
                { !ledgerContract.gatewayAPIId && 
                    <Grid item>
                        <NoGatewayAPI {...{ledgerContract}} />
                    </Grid>
                }
                <Grid item container alignItems="stretch">
                    <EditableSettings reducePadding header={accountSectionHeader} {...{actionsList}}  />
                </Grid>
                <Grid item>
                    <CopyableSettings {...{header}} copyableList={tokenInfoList} />
                </Grid>
                <Grid item>
                    <CopyableSettings header={lt('roles')} copyableList={rolesList} />
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    header: string
    bytecode: string
    gatewayAPIId: string
    genesisTransaction: string
    deployedBy: string
    address: string
    erc20: string
    erc721: string
    type: string
    status: string
    zkpToken: string
    token: string
    totalSupply: string
    burnable: string
    mintable: string
    owner: string
    roles: string
    minters: string
    yes: string
    no: string
    addressBalance: string
    mint: string
    burn: string
    transfer: string
    noAccountSelected: string
    signingAccount: string
    signingAccountActions: string
    unknownToken: string
    accountNotMinter: string
    tokenNotMintable: string
    tokenNotBurnable: string
    insufficientFunds: string
    comingSoon: string
}

const enTranslations: translations = {
    comingSoon: 'Coming Soon',
    insufficientFunds: 'Insufficient Funds',
    tokenNotBurnable: 'Token is not burnable',
    unknownToken: 'Unknown Token',
    accountNotMinter: 'Account is not a minter',
    tokenNotMintable: 'Token is not mintable',
    header: 'Token Info',
    bytecode: 'ByteCode',
    gatewayAPIId: 'Gateway API Endpoint',
    genesisTransaction: 'Genesis Transaction',
    deployedBy: 'Deployed By',
    address: 'Address',
    erc20: 'ERC 20 Token',
    erc721: 'ERC 721 Token',
    type: 'Type',
    zkpToken: 'ZKP Token',
    token: 'Token',
    status: 'Status',
    totalSupply: 'Total Supply',
    burnable: 'Burnable',
    mintable: 'Mintable',
    owner: 'Owner',
    roles: 'Roles',
    minters: 'Minters',
    yes: 'Yes',
    no: 'No',
    addressBalance: 'Address Balance',
    mint: 'Mint',
    burn: 'Burn',
    transfer: 'Transfer',
    noAccountSelected: 'No Account Selected',
    signingAccountActions: 'Signing Account Actions',
    signingAccount: 'Signing Account',
};

const useStyles = makeStyles(() => ({
    icon: {
        display: 'flex',
        alignSelf: 'center'
    }
}))
