import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const EnterpriseBlockchain = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnterpriseBlockchain', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnterpriseBlockchain:${key}`, interpolate);

    return (
        <>
            <Grid item>
                <Typography className={classes.title} variant="h4">{lt('enterpriseBlockchain')}</Typography>
            </Grid>
            <Grid item container justify="center">
                <img src={process.env.PUBLIC_URL + '/img/Login/Hero-Node.png'} alt="" className={classes.illustration} />
            </Grid>
        </>
    )
};

const useStyles = makeStyles(() => ({
    title: {
        maxWidth: '500px',
        textAlign: 'center',
        color: 'white'
    },
    illustration: {
        maxWidth: '500px'
    },
}));

interface translations {
    enterpriseBlockchain: string
}

const enTranslations: translations = {
    enterpriseBlockchain: 'Your Web3 Platform for Blockchain & Digital Assets'
}
