import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { EnvironmentData, EnvironmentQuery, NodesData, NodesQuery, 
    ServicesData, ServicesQuery, FeatureTogglesData, FeatureTogglesVars, FeatureTogglesQuery } from '../../models'
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { Status } from './Status'
import { Services } from './Services'
import { ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces';
import { CpuChart } from './CpuChart';
import { Activity } from './Activity'
import { EmptyState } from '../../components/DisplayWrappers';
import { NodesCard } from '../../components/NodesCard/NodesCard';
import { ServicesCard } from '../BlockchainDashboard/ServicesCard';
import { ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH, ADDRESSBOOK_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { FIREFLY_BASE_PATH, FIREFLY_SERVICE_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { FireflyServicesCard } from '../BlockchainDashboard/FireflyServicesCard';

export const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentDashboard:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!,
        id: environment_id!
    }
    const environmentResourcesVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const isFireflyEnv = environment?.optional_features?.firefly;
    const isCorda = environment?.isCorda
    const isFabric = environment?.isFabric

    const {
        data: {
            nodes
        } = { nodes: [] },
        startPolling: startNodesPolling,
        stopPolling: stopNodesPolling
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-and-network'
    });

    const ordererNodes = nodes.filter(n => n.role === "orderer");
    const peerNodes = nodes.filter(n => n.role === "peer");

    const {
        data: {
            services
        } = { services: [] },
        startPolling,
        stopPolling
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        fetchPolicy: 'cache-first'
    });

    useEffect(() => {
        if (services?.find(s=>s.state !== 'started')) {
            startPolling(3000)
        } else {
            stopPolling()
        }
    }, [services, startPolling, stopPolling])

    useEffect(() => {
        if (nodes?.find(n=>n.state !== 'started')) {
            startNodesPolling(3000)
        } else {
            stopNodesPolling()
        }
    }, [nodes, startNodesPolling, stopNodesPolling])

    const certificateAuthorities = services.filter((s) => s.service === 'fabric-ca');
    const fireflyNodes = services.filter((s) => s.service === 'firefly-os');
    const otherRuntimes = services.filter((s) => s.service !== 'fabric-ca' && s.service !== 'firefly-os');

    const allRuntimes = useMemo(() => 
        nodes.map(n => 
            ({ _id: n._id, name: n.name })
        ).concat(services.map(s => 
            ({ _id: s._id, name: s.name })
        ))
    , [nodes, services])

    if (loading || !environment) return <CircularProgress />

    const createNode = () => history.push(`${pathname}/nodes/create/1`)
    const setupFirefly = () => history.push(`${pathname}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/create/1`)

    if (!loading && !nodes.filter(n => n.role !== 'monitor').length && !certificateAuthorities.length) return (
        <EmptyState imageFile={isFireflyEnv ? 'Empty-Firefly.svg' : 'Empty-Node.svg'}
            title={isFireflyEnv ? lt('addFireflyNode') : lt('addANode')}
            description={isFireflyEnv ? lt('fireflyCreateNodeDescription') : lt('emptyDescription')}
            button={{ text: lt(isFireflyEnv ? 'createSuperNode' : 'createNode'), onClick: isFireflyEnv ? setupFirefly : createNode, disabled: environment.state !== 'live' }}
            documentation={{ text: lt('emptyDocumentation'), link: isFireflyEnv ? 'https://docs.kaleido.io/kaleido-platform/firefly/' : 'https://docs.kaleido.io/using-kaleido/quick-start/first-blockchain/' }} />
    )

    const ffCard = (
        <Grid item container lg={4} sm={6} xs={12}>
            <FireflyServicesCard services={fireflyNodes}
                emptyHeader={lt('fireflyNodes', {count: fireflyNodes.length})}
                emptyImagePath="Empty-Firefly.svg"
                emptyDescription={lt('addFireflyNode')}
                servicePath={`${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}`}
                serviceInfoLink={'https://hyperledger.github.io/firefly/'}
                dataTestId="fireflyInstance" 
            />
        </Grid>
    )

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('dashboard')}
                    </Typography>
                </Grid>
            </Grid>
            {/* set key={environment_id} to remount the dashboard content whenever the environment_id changes.
                this avoids old data being displayed especially when queries fail (as data is ignored from the response). */}
            <Grid key={environment_id} item container direction="row" spacing={3}>
                <Grid item container lg={4} sm={6} xs={12}>
                    <Status {...{environment}} {...{nodes}} />
                </Grid>
                
                {!isCorda && isFireflyEnv && !(isFabric && !featureToggles?.fabricFirefly) ? ffCard : null}

                {environment?.isFabric ? 
                    <>
                        <Grid item container lg={4} sm={6} xs={12}>
                            <NodesCard type="orderer" nodes={ordererNodes}  />
                        </Grid>
                        <Grid item container lg={4} sm={6} xs={12}>
                            <NodesCard type="peer" nodes={peerNodes}  />
                        </Grid>
                    </>
                    : <Grid item container lg={4} sm={6} xs={12}>
                        <NodesCard {...{nodes}} />
                    </Grid>
                }

                {environment?.isFabric &&
                    (<Grid item container lg={4} sm={6} xs={12}>
                        <ServicesCard services={certificateAuthorities}
                            emptyHeader={lt('identities', {count: certificateAuthorities.length})}
                            emptyImagePath="Empty-CertificateAuthority.svg"
                            emptyDescription={lt('emptyDescriptionCertificateAuthority')}
                            servicePath={`${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}`}
                            serviceInfoLink={'https://docs.kaleido.io/'}
                            noLinkButton 
                            createDisabled />
                    </Grid>)
                }

                <Grid item container lg={4} sm={6} xs={12}>
                    <Services services={otherRuntimes} />
                </Grid>

                {!isCorda && !isFireflyEnv && !(isFabric && !featureToggles?.fabricFirefly) ? ffCard : null}

                <Grid item container lg={6} xs={12}>
                    <CpuChart {...{allRuntimes}} />
                </Grid>

                <Grid item container lg={6} xs={12}>
                    <Activity />
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    dashboard: string
    addANode: string
    createNode: string
    emptyDescription: string
    emptyDocumentation: string
    fireflyDescription: string
    fireflyNodes: string
    getStarted: string
    identities: string
    initializeFirefly: string
    emptyDescriptionCertificateAuthority: string
    fireflyCreateNodeDescription: string
    addFireflyNode: string
    createSuperNode: string
}
const enTranslations: translations = {
    dashboard: 'Dashboard',
    addANode: 'Add a Node',
    createNode: 'Create Node',
    emptyDescription: 'Now that the environment has been created, let\'s add a blockchain node.',
    emptyDocumentation: 'Documentation',
    fireflyDescription: 'Get started with all the resources you need to run a FireFly Node including a Blockchain Node and an IPFS Service.',
    fireflyNodes: 'FireFly Nodes ({{count}})',
    getStarted: 'Get Started',
    identities: 'Certificate Authorities ({{count}})',
    initializeFirefly: 'Initialize FireFly',
    emptyDescriptionCertificateAuthority: 'Create an orderer node to register a certificate authority.',
    fireflyCreateNodeDescription: 'Now that the environment has been created, let\'s add a FireFly SuperNode.',
    addFireflyNode: 'Get started with Hyperledger FireFly by creating your first SuperNode.',
    createSuperNode: 'Create SuperNode'
}