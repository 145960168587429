import {gql} from '@apollo/client';
import { ServiceResourcesVars, EnvironmentResourcesVars } from '../interfaces';

export type DocExchangeTransferStatus = 'sent' | 'received' | 'failed';

export const DOCUMENTSTORE_LIMITS = {
    chunks: {
        small: 100,
        medium: 1000
    },
    fileSize: {
        small: '100 KB',
        medium: '50 MB',
        large: '128 MB'
    }
}

export interface DocumentStoreLimitTranslations {
    documentstoreSmallDescription: string
    documentstoreMediumDescription: string
    documentstoreLargeDescription: string
}

export const EnDocumentStoreLimitTranslations: DocumentStoreLimitTranslations = {
    documentstoreSmallDescription: `Max File Size (${DOCUMENTSTORE_LIMITS.fileSize.small}), Transfer Limit (${DOCUMENTSTORE_LIMITS.chunks.small}/month)`,
    documentstoreMediumDescription: `Max File Size (${DOCUMENTSTORE_LIMITS.fileSize.medium}), Transfer Limit (${DOCUMENTSTORE_LIMITS.chunks.medium}/month)`,
    documentstoreLargeDescription: `Max File Size (${DOCUMENTSTORE_LIMITS.fileSize.large}), Transfer Limit (Unlimited)`,
}

export interface DocExchangeTransferStatusTranslationsInterface {
    sent: string
    received: string
    failed: string
}

export const DocExchangeTransferStatusTranslations: DocExchangeTransferStatusTranslationsInterface  = {
    sent: 'Sent',
    received: 'Received',
    failed: 'Failed'
}

export interface Document {
    name: string
    is_directory: boolean
    hash: string
    last_modified: string
    size: number
}

export interface DocumentTransfer {
    hash?: string
    transferHash?: string
    senderSignature?: string
    recipientSignature?: string
    id: string
    status: DocExchangeTransferStatus
    from: string
    to: string
    timestamp: string
    document?: string
}

export interface DocumentStoreMetrics {
    id: string
    month: string
    year: string
    metrics: DocumentStoreMetric[]
}

export interface DocumentStoreMetric {
    time: string
    documents_in_kaleido_storage: number
    bytes_sent: number
    bytes_received: number
    chunks_sent: number
    chunks_received: number
    sent_status_success: number
    sent_status_sent: number
    sent_status_failed: number
    received_status_success: number
    received_status_failed: number
}

export interface DocumentStoreMetricsVars extends EnvironmentResourcesVars{
    id: string,
    month: number,
    year: number
}
export interface AllDocumentStoreMetricsVars extends EnvironmentResourcesVars{
    ids: string[],
    month: number,
    year: number
}
export interface DocumentStoreMetricsData {
    documentStoreMetrics: DocumentStoreMetrics
}
export interface AllDocumentStoreMetricsData {
    allDocumentStoreMetrics: DocumentStoreMetrics[]
}

export interface CreateDestinationVars extends ServiceResourcesVars {
    new_dest: string
}
export interface DocumentsVars extends ServiceResourcesVars {
    path: string
    offset: number
    limit: number
}
export interface DocumentsTransfersVars extends ServiceResourcesVars {
    offset: number
    limit: number
}

export interface DocumentTransferVars extends ServiceResourcesVars {
    transferId: string
}

export interface SearchDocumentVars extends ServiceResourcesVars {
    query: string
    byHash: boolean
}

export interface DestinationsVars extends ServiceResourcesVars {
    clearCache?: boolean
}

export interface AddressbookVars extends ServiceResourcesVars {}

export interface UpdateDocumentStorePreferencesVars extends ServiceResourcesVars {
    preference: {
        key: string,
        value: string
    }
}

export interface CheckIdRegistryVars extends ServiceResourcesVars {}

export interface RecalculateDocumentHashVars extends ServiceResourcesVars {
    path: string
}
export interface Documents {
    entries: Document[]
    is_truncated: boolean
}

export interface DocumentsTransfers {
    transfers: DocumentTransfer[]
    is_truncated: boolean
}

export interface SearchDocument {
    full_path: string
    last_modified: string
    hash: string
    size: number
}

export interface DocumentStoreConfig {
    provider: 'internal' | 'aws' | 'azure'
    region?: string
    bucket_or_container?: string
}

export interface DocumentStoreProviderTranslations {
    internal: string
    aws: string
    azure: string
}

export const EnDocumentStoreProviderTranslations: DocumentStoreProviderTranslations = {
    internal: 'Kaleido',
    aws: 'AWS',
    azure: 'Azure'
}

export interface DocumentStoreConfigData {
    documentStoreConfig: DocumentStoreConfig
}

export interface Destination {
    name: string
    uri: string
}

export interface Destinations extends Destination {
    setup_complete: boolean
}

export interface Status {
    status: string 
}

export interface Addressbook {
    membership: string
    destinations: Destination[]
}

export interface AddressbookData {
    addressbook: Addressbook[]
}
export interface SearchDocumentsData{
    searchDocuments: {
        documents: SearchDocument[]
    }
}

export interface DocumentsData {
    documents: Documents
}

export interface DocumentsTransfersData {
    documentsTransfers: DocumentsTransfers
}

export interface DocumentTransferData {
    documentTransfer: DocumentTransfer
}

export interface CheckIdRegistryData {
    checkIdRegistry: Status
}

export interface DestinationsData {
    destinations: Destinations[]
}

export interface NewDestinationData {
    createDestinations: {
        result: string
    }
}

export interface DocumentStorePreferencesData {
    documentStorePreferences: {
        receivedDocumentsPath: string
    }
}

export interface RecalculateDocumentHashData{
    recalculateDocumentHash: {
        result: string,
        hash: string
    }
}

export interface UpdateDocumentStorePreferencesData {
    updateDocumentStorePreferences: {
        result: string
    }
}

export const DocumentStoreMetricsFields = `
    fragment DocumentStoreMetricsFields on DocumentStoreMetrics {
        id
        month
        year
        metrics {
            time
            documents_in_kaleido_storage
            bytes_sent
            bytes_received
            chunks_sent
            chunks_received
            sent_status_success
            sent_status_sent
            sent_status_failed
            received_status_success
            received_status_failed
        }
    }
`;

export const DocumentFields = `
    fragment DocumentFields on Documents {
        entries {
            name
            is_directory
            hash
            last_modified
            size
        }
        is_truncated
    }
`;

export const SearchDocumentsFields = `
    fragment SearchDocumentsFields on SearchDocuments {
        documents {
            full_path
            last_modified
            hash
            size
        }
    }
`

export const CheckIdRegistryFields = `
    fragment CheckIdRegistryFields on CheckIdRegistryStatus {
        status
    }
`;

export const TransfersFields = `
    fragment TransfersFields on DocumentStoreTransfers {
        transfers {
            id
            status
            from
            to
            document
            hash
            timestamp
        }
        is_truncated
    }
`
export const DocumentsTransfersFields = `
    fragment DocumentsTransfersFields on DocumentStoreTransfer {
        hash
        transferHash
        recipientSignature
        senderSignature
        id
        status
        from
        to
        document
        timestamp
    }
`

export const DestinationFields = `
    fragment DestinationFields on Destinations {
        name
        uri
        setup_complete
    }
`

export const AddressbookFields = `
    fragment AddressbookFields on Addressbook {
        membership
        destinations {
            name
            uri
        }
    }
`
export const DocumentStoreConfigFields = `
    fragment DocumentStoreConfigFields on DocumentStoreConfig {
        provider
        region
        bucket_or_container
    }
`
export const DocumentStorePreferencesFields = `
    fragment DocumentStorePreferencesFields on DocumentStorePreferences {
        receivedDocumentsPath
    }
`

export const AllDocumentStoreMetricsQuery = gql `
    ${DocumentStoreMetricsFields}
    query allDocumentStoreMetrics($consortia_id: String!, $environment_id: String!, $ids: [String!]!, $month: Int!, $year: Int!) {
        allDocumentStoreMetrics(consortia_id: $consortia_id, environment_id: $environment_id, ids: $ids, month: $month, year: $year) {
            ...DocumentStoreMetricsFields
        }
    }
`

export const DocumentStoreMetricsQuery = gql `
    ${DocumentStoreMetricsFields}
    query documentStoreMetrics($consortia_id: String!, $environment_id: String!, $id: String!, $month: Int!, $year: Int!) {
        documentStoreMetrics(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, month: $month, year: $year) {
            ...DocumentStoreMetricsFields
        }
    }
`

export const DocumentsQuery = gql `
    ${DocumentFields}
    query getDocuments($service_id: String!, $path: String!, $offset: Int!, $limit: Int!) {
        documents(service_id: $service_id, path: $path, offset: $offset, limit: $limit) {
            ...DocumentFields
        }
    }
`

export const SearchDocumentsQuery = gql `
    ${SearchDocumentsFields}
    query getSearchDocuments($service_id: String!, $query: String!, $byHash: Boolean) {
        searchDocuments(service_id: $service_id, query: $query, byHash: $byHash) {
            ...SearchDocumentsFields
        }
    }
`
export const DocumentStoreConfigQuery = gql `
    ${DocumentStoreConfigFields}
    query getDocumentStoreConfig($service_id: String!) {
        documentStoreConfig(service_id: $service_id) {
            ...DocumentStoreConfigFields
        }
    }
`

export const DocumentsTransfersQuery = gql `
    ${TransfersFields}
    query getDocumentsTransfers($service_id: String!, $offset: Int!, $limit: Int!) {
        documentsTransfers(service_id: $service_id, offset: $offset, limit: $limit) {
            ...TransfersFields
        }
    }
`
export const DocumentTransferQuery = gql `
    ${DocumentsTransfersFields}
    query getDocumentTransfer($service_id: String!, $transferId: String!) {
        documentTransfer(service_id: $service_id, transferId: $transferId) {
            ...DocumentsTransfersFields
        }
    }
`

export const AddressbookQuery = gql `
    ${AddressbookFields}
    query getAddressbook($service_id: String!) {
        addressbook(service_id: $service_id) {
            ...AddressbookFields
        }
    }
`
export const DestinationsQuery = gql `
    ${DestinationFields}
    query getDestinations($service_id: String!, $clearCache: Boolean) {
        destinations(service_id: $service_id, clearCache: $clearCache) {
            ...DestinationFields
        }
    }
`

export const CheckIdRegistryQuery = gql `
    ${CheckIdRegistryFields}
    query checkIdRegistry($service_id: String!) {
        checkIdRegistry(service_id: $service_id){
            ...CheckIdRegistryFields
        }
    }
`

export const DocumentStorePreferencesQuery = gql `
    ${DocumentStorePreferencesFields}
    query getDocumentStorePreferences($service_id: String!) {
        documentStorePreferences(service_id: $service_id){
            ...DocumentStorePreferencesFields
        }
    }
`

export const DeleteDocumentMutation = gql `
    mutation deleteDocument($service_id: String!, $path: String!) {
        deleteDocument(service_id: $service_id, path: $path)
    }
`

export const DeleteDestinations = gql `
    mutation deteleDestination($service_id: String!, $destinationName: String!) {
        deleteDestination(service_id: $service_id, destinationName: $destinationName)
    }
`

//Return type should be improved
export const TransferDocumentMutation = gql `
    mutation transferDocuments($service_id: String!, $transfer: DocumentTransfer!) {
        transferDocument(service_id: $service_id, transfer: $transfer) {
            result
        }
    }
`
export const CreateDestinationMutation = gql `
    mutation createDestination($service_id: String!, $newDest: String!) {
        createDestination(service_id: $service_id, newDest: $newDest ) {
            result
        }
    }
`

export const UpdateDocumentStorePreferencesMutation = gql `
    mutation updateDocumentStorePreferences($service_id: String!, $preference: UpdatePreferenceInput!) {
        updateDocumentStorePreferences(service_id: $service_id, preference: $preference) {
            result
        }
    }
`

export const RecalculateDocumentHashMutation = gql `
    mutation recalculateDocumentHash($service_id: String!, $path: String!) {
        recalculateDocumentHash(service_id: $service_id, path: $path) {
            result,
            hash
        }
    }
`