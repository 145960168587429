import { gql, MutationFunctionOptions } from '@apollo/client';
import { RuntimeSize } from '.';
import { PlanName } from './plans';
import { ServicesEnum } from './services';

// INTERFACES

export interface BillingProvider {
    funding?: string
    brand?: string
    last4?: string
    exp_month?: string
    exp_year?: string
    name?: string
    address_line1?: string
    address_line2?: string
    address_city?: string
    address_state?: string
    address_zip?: string
    address_country?: string
    type: string
}

export interface BillingSummary {
    memberships: MembershipBilling[]
    nodes: NodeBilling[]
    services: ServiceBilling[]
    storage: ResourceBilling[]
    support: SupportBilling[]
    adjustments?: Adjustment[]
}

export interface BaseBilling {
    total_usd: number
}

export interface ResourceBilling extends BaseBilling {
    plan_id: PlanName
    unit_cost_usd: number
    unit_hrs: number
}

export interface MembershipBilling extends ResourceBilling {
    consortia_id: string
    membership_id: string
}

export interface NodeBilling extends ResourceBilling {
    environment_id: string
    node_id: string
    size: RuntimeSize
}

export interface ServiceBilling extends ResourceBilling {
    environment_id: string
    service: keyof typeof ServicesEnum
    service_id: string
    size: RuntimeSize
}

export interface SupportBilling extends ResourceBilling {
    support_level: number
}

export interface Adjustment {
    total_usd: number
    description: string
}

export interface BillingProviderData {
    billingProvider: BillingProvider
}

export interface BillingSummaryData {
    billingSummary: BillingSummary
}

export interface OpsBillingSummaryData {
    opsBillingSummary: BillingSummary
}

export interface OpsBillingSummaryVars {
    org_id: string
    year?: string
    month?: string
    agreement?: string
}

export interface UpdateBillingProviderData {
    updateBillingProvider: BillingProvider
}

export interface UpdateBillingProviderVars {
    org_id: string
    billingProvider: UpdateBillingProviderInput
}

export interface CreateSetupIntentVars {
    org_id: string
}

export interface UpdateBillingProviderInput {
    type: string
    id: string
}

export interface OpsEnsureStripeCustomerData {
    opsEnsureStripeCustomer: BillingProvider
}

export interface CreateSetupIntentData {
    createSetupIntent: {
        client_secret: string
    }
}

export const BillingProviderFields = ` 
    fragment BillingProviderFields on BillingProvider {
        funding
        brand
        last4
        exp_month
        exp_year
        name
        address_line1
        address_line2
        address_city
        address_state
        address_zip
        address_country
        type
    }`

export const BillingSummaryFields = ` 
    fragment BillingSummaryFields on BillingSummary {
        memberships {
            consortia_id
            membership_id
            plan_id
            total_usd
            unit_cost_usd
            unit_hrs
        }
        nodes {
            environment_id
            node_id
            plan_id
            size
            total_usd
            unit_cost_usd
            unit_hrs
        }
        services {
            environment_id
            plan_id
            service
            service_id
            size
            total_usd
            unit_cost_usd
            unit_hrs
        }
        storage {
            plan_id
            total_usd
            unit_cost_usd
            unit_hrs
        }
        support {
            support_level
            total_usd
            unit_cost_usd
            unit_hrs
        }
        adjustments {
            total_usd
            description
        }
    }`

// QUERIES & SUBSCRIPTIONS

export const BillingProviderQuery = gql`
    ${BillingProviderFields}
    query getBillingProvider($org_id: String!) {
        billingProvider(org_id: $org_id) {
            ...BillingProviderFields
        }
    }`;

export const BillingSummaryQuery = gql`
    ${BillingSummaryFields}
    query getBillingSummary($org_id: String!) {
        billingSummary(org_id: $org_id) {
            ...BillingSummaryFields
        }
    }`;

export const OpsBillingSummaryQuery = gql`
    ${BillingSummaryFields}
    query getOpsBillingSummary($org_id: String!, $year: String, $month: String, $agreement: String) {
        opsBillingSummary(org_id: $org_id, year: $year, month: $month, agreement: $agreement) {
            ...BillingSummaryFields
        }
    }`;

export const UpdateBillingProviderMutation = gql`
    ${BillingProviderFields}
    mutation updateBillingProvider($org_id: String!, $billingProvider: UpdateBillingProviderInput!) {
        updateBillingProvider(org_id: $org_id, billingProvider: $billingProvider) {
            ...BillingProviderFields
        }
    }`;

export const CreateSetupIntentMutation = gql`
    mutation createSetupIntent($org_id: String!) {
        createSetupIntent(org_id: $org_id) {
            client_secret
        }
    }`;

export const OpsEnsureStripeCustomerMutation = gql`
    ${BillingProviderFields}
    mutation opsEnsureStripeCustomer( $id: String! ) {
        opsEnsureStripeCustomer(id: $id) {
            ...BillingProviderFields
        }
    }
`;

export const OpsTriggerInvoiceMutation = gql`
    mutation opsTriggerInvoice($org_id: String!, $month: String, $year: String) {
        opsTriggerInvoice(org_id: $org_id, month: $month, year: $year)
    }
`;

export function MakeUpdateBillingCreateMutationOptions(
    variables: UpdateBillingProviderVars,
    overrideOptions?: MutationFunctionOptions<UpdateBillingProviderData, UpdateBillingProviderVars>): MutationFunctionOptions<UpdateBillingProviderData, UpdateBillingProviderVars> {
    return {
        ...{
            variables,
            update(cache, { data }) {
                if (data?.updateBillingProvider) {
                    cache.writeQuery({
                        query: BillingProviderQuery,
                        data: { billingProvider: data.updateBillingProvider },
                        variables: { org_id: variables.org_id }
                    });
                }
            }
        }, ...overrideOptions
    }
}