import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { AppCredsData, AppCredsQuery, CreateAppCredData, CreateAppCredVars, CreateAppCredMutation, MakeAppCredCreateMutationOptions } from '../../models'
import { FormDialog } from '../DialogWrappers'
import { AppCredSelector } from '../FormControls/AppCredSelector';
import { Grid, FormControl, RadioGroup, Radio, FormControlLabel, TextField } from "@material-ui/core";
import { EnvironmentResourcesVars } from '../../interfaces';
import { SECURITY_APPCREDS_PATH, SECURITY_BASE_PATH } from '../MainNav/SideNavs/Security';
interface Props {
    membershipId: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    runtimeId?: string,
}

export const ConnectRuntimeDialog = ({ membershipId, open, setOpen, runtimeId }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConnectRuntime', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConnectRuntime:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id, service_id } = useParams<any>();

    const baseAppCredsPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_APPCREDS_PATH}`

    const { 
        data: { 
            appCreds: allAppCreds
        } = { appCreds: [] } 
    } = useQuery<AppCredsData, EnvironmentResourcesVars>(AppCredsQuery, {
        variables: { consortia_id: consortium_id!, environment_id: environment_id! },
        fetchPolicy: 'cache-only'
    });    

    const appCreds = allAppCreds.filter(a => a.membership.isMine && a.membership_id === (membershipId || a.membership_id) && !a.service_id)

    const [createNew, setCreateNew] = useState(!appCreds.length);
    const [appCredId, setAppCredId] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateNew((event.target.value === "true" ? true : false));
    };

    const [createAppCred, { loading: createAppCredLoading }] = 
        useMutation<CreateAppCredData, CreateAppCredVars>(CreateAppCredMutation)

    const [name, setName] = useState('');

    // If we're passed an ID by the wrapper, that's the one we use. Otherwise try and pick it up from the nav
    const resolvedRuntimeId = runtimeId ? runtimeId : node_id || service_id;
    
    const appCredCreator = () => 
        createAppCred(MakeAppCredCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            appCred: {
                membership_id: membershipId,
                name
            }
        })).then((d) => history.push(`${baseAppCredsPath}/${d.data?.createAppCred._id}?runtime_id=${resolvedRuntimeId}`))

    const controlsWrapper = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <FormControl component="fieldset" margin="none">
                    <RadioGroup value={createNew} onChange={handleChange}>
                        <FormControlLabel checked={createNew === false} disabled={!appCreds.length}
                            value={false} 
                            control={<Radio color="primary" />} 
                            label={lt('reuseExisting')} />
                        <FormControlLabel checked={createNew === true}
                            value={true} 
                            control={<Radio color="primary" />} 
                            label={lt('createNew')} />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {createNew && 
                <TextField required
                    value={name} onChange={event => setName(event.target.value)}
                    autoFocus
                    margin="normal"
                    label={lt('appCredName')}
                    fullWidth
                    variant="outlined"
                />
            }
            {!createNew && 
                <Grid item>
                    <AppCredSelector {...{membershipId}} {...{appCredId}} {...{setAppCredId}} />
                </Grid>
            }
        </Grid>
    )

    const onSave = !createNew ? () => new Promise<void>(() => {
        history.push(`${baseAppCredsPath}/${appCredId}?runtime_id=${resolvedRuntimeId}`)
    }) : appCredCreator

    return (
        <FormDialog 
            {...{open}} 
            {...{setOpen}} 
            header={lt('connectRuntime')} 
            description={lt('connectRuntimeDescription')} 
            {...{controlsWrapper}} 
            saveDisabled={createAppCredLoading || (createNew && !name)}
            {...{onSave}}
            saveText={createNew ? lt('create') : lt('connect')}
        />
    )
};

interface translations {
    connectRuntime: string,
    connectRuntimeDescription: string,
    createNew: string,
    reuseExisting: string,
    connect: 'Connect',
    create: 'Create',
    appCredName: 'App Cred Name'
}
const enTranslations: translations = {
    connectRuntime: 'Connect Runtime',
    connectRuntimeDescription: 'Select an option below to connect to this runtime.',
    createNew: 'Create new App Cred',
    reuseExisting: 'Reuse existing App Cred',
    connect: 'Connect',
    create: 'Create',
    appCredName: 'App Cred Name'
}