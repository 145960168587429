import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, makeStyles, Grid, Paper, FormControl, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { Step2Help } from './Step2Help'
import { ZoneRegion } from '../../models'
import { CreateWrapper } from '../../components/DialogWrappers'
import { CreateStepProps } from '../../interfaces';

interface Props extends CreateStepProps {
    name: string
    description: string,
    homeRegion: string,
    setHomeRegion: React.Dispatch<React.SetStateAction<string>>,
    regions: ZoneRegion[]
};

export const Step2 = ({ name, description, homeRegion, setHomeRegion, regions, cancelPath }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConsortiumCreateStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConsortiumCreateStep2:${key}`, interpolate)

    const history = useHistory()
    const { org_id } = useParams<any>();

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/create/3`, {
            name,
            description,
            homeRegion
        })
    }

    const handleHomeRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHomeRegion((event.target.value as string));
    };

    const makeRegionRadio = (r: ZoneRegion) => {
        return (
            <Grid item key={r.region}>
                <Paper className={homeRegion === r.region ? classes.radioPaperSelected : classes.radioPaper} elevation={0}>
                    <Grid container direction="row" alignItems="center" spacing={3}>
                        <Grid item xs={4}>
                            <FormControlLabel checked={homeRegion === r.region} value={r.region} control={<Radio color="primary" />} label={r.location} />
                        </Grid>
                        <Grid item xs={2}>
                            <img className={classes.flag} src={`${process.env.PUBLIC_URL}/img/flags/4x3/${r.flag}.svg`} alt=""></img>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">
                                {r.cloud}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">
                                {r.region}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item container direction="column">
                <FormControl component="fieldset" margin="none">
                    <RadioGroup value={homeRegion} onChange={handleHomeRegionChange}>
                        <Grid container direction="column" spacing={1}>
                            {regions.map(r => makeRegionRadio(r))}
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={homeRegion ? false : true} onNext={save} />
            <Step2Help />
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
    },
    radioPaperSelected: {
        padding: theme.spacing(1, 2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
    },
    flag: {
        height: '40px',
        width: '40px'
    }
}));

interface translations {
    header: string,
    headerDescription: string,
}
const enTranslations: translations = {
    header: 'Set Home Region',
    headerDescription: 'The home region is where information about the network (such as membership details) is stored.',
}