import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Divider, Button, TextField, IconButton, Tooltip } from "@material-ui/core";
import ShieldLinkVariantIcon from 'mdi-react/ShieldLinkVariantIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import DeleteOffIcon from 'mdi-react/DeleteOffIcon';
import { EditableSettings } from '../../components/DisplaySettings'
import { BAFSaveWithUndo, BAFPolicyRulesets, BAFConfig } from './BAFPolicySchema';
import { useHistory } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { SECURITY_BAF_RULESET_PATH } from '../../components/MainNav/SideNavs/Security';

interface Props {
    rulesetsSection: BAFPolicyRulesets,
    bafConfig: BAFConfig,
    basePath: string,
    usedRulesets: {[ruleset: string]: true},
    loading: boolean,
    save: BAFSaveWithUndo,
}

export const BAFPolicyRulesetSection: React.FC<Props> = ({ bafConfig, save, loading, rulesetsSection, basePath, usedRulesets }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFPolicyRulesetSection', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFPolicyRulesetSection:${key}`, interpolate)

    const classes = useStyles();

    const history = useHistory();

    const [addRulesetOpen, setAddRulesetOpen] = useState(false);
    const [newRulesetName, setNewRulesetName] = useState('');
    
    const deleteRuleset = (ruleset: string) => {
        let newRulesets = {...bafConfig.details.rulesets};
        delete newRulesets[ruleset];
        save(bafConfig.details, 'rulesets', newRulesets, (oldRulesets) => {
            bafConfig.details.rulesets = oldRulesets;
        });
    }

    const actionsList = Object.entries(rulesetsSection).map(([name,ruleset]) => {
        const subvalues: {[x:string]: string[]} = {};
        if (ruleset.tx?.length === 1) {
            subvalues[lt('transactionsSingle')] = Object.entries(ruleset.tx[0]).map(([k,v]) => `${k}: ${JSON.stringify(v)}`)
        } else if (ruleset.tx) {
            ruleset.tx.forEach((tx,i) => {
                subvalues[lt('transactions', {i})] = Object.entries(tx).map(([k,v]) => `${k}: ${JSON.stringify(v)}`)
            })
        }
        if (ruleset.chain) {
            subvalues[lt('chain')] = Object.entries(ruleset.chain).map(([k,v]) => `${k}: ${JSON.stringify(v)}`)
        }
        if (ruleset.accounts) {
            subvalues[lt('accounts')] = Object.entries(ruleset.accounts).map(([k,v]) => `${k}: ${JSON.stringify(v)}`)
        }
        if (ruleset.rpc?.length === 1) {
            subvalues[lt('rpcSingle')] = Object.entries(ruleset.rpc[0]).map(([k,v]) => `${k}: ${JSON.stringify(v)}`)
        } else if (ruleset.rpc) {
            ruleset.rpc.forEach((rpc,i) => {
                subvalues[lt('rpc', {i})] = Object.entries(rpc).map(([k,v]) => `${k}: ${JSON.stringify(v)}`)
            })
        }
        return {
            icon: <ShieldLinkVariantIcon/>,
            title: ruleset.templated ? lt('rulesetTemplated', {ruleset: name}) : name,
            description: ruleset.comment || '',
            buttonLabel: lt('edit'),
            buttonDisabled: loading,
            extraButtons: [
                <Tooltip title={usedRulesets[name] ? lt('inUse') : lt('delete')}>
                    <span>
                        <IconButton key="delete" disabled={loading || usedRulesets[name]}  onClick={() => deleteRuleset(name)}>
                            {usedRulesets[name] ? <DeleteOffIcon/> : <DeleteIcon/>}
                        </IconButton>
                    </span>
                </Tooltip>
            ],
            action: () => history.push(`${basePath}/${SECURITY_BAF_RULESET_PATH}/${name}/1`),
            subvalues,
        }
    })

    const badRulesetName = !/^[a-zA-Z0-9-_.]*$/.test(newRulesetName);

    const addRulesetControls = <>
        <TextField
            data-test="textField_addRulesetName"
            value={newRulesetName} onChange={event => setNewRulesetName(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('rulesetNameGuidance')}
            fullWidth
            variant="outlined"            
            error={badRulesetName}
        /></>

    return (
        <>
            <FormDialog dataTestId="addRulesetDialog"
                setOpen={setAddRulesetOpen}
                open={addRulesetOpen}
                header={lt('addRuleset')} 
                saveText={lt('add')}
                description={lt('nameRuleset')} 
                controlsWrapper={addRulesetControls}
                saveDisabled={!newRulesetName || badRulesetName || loading}
                onSave={async () => history.push(`${basePath}/${SECURITY_BAF_RULESET_PATH}/${newRulesetName}/1`)}
                closeDialogAfterSave />
                
            <Grid item xs={12} sm={12} md={8}>
                <EditableSettings
                    header={lt('rulesets')}
                    {...{actionsList}}>
                    <Divider />
                    <Grid item container className={classes.itemContainer} alignItems="center" justify="space-between">
                        <Grid item>
                            <Button
                                variant="text"
                                color="primary"
                                startIcon={<PlusIcon />}
                                onClick={() => setAddRulesetOpen(true)}
                            >{lt('addRuleset')}</Button>
                        </Grid>
                    </Grid>
                </EditableSettings>
            </Grid>
    </>
    )
};

interface translations {
    rulesets: string,
    edit: string,
    addRuleset: string,
    chain: string,
    accounts: string,
    rpcSingle: string,
    rpc: string,
    transactionsSingle: string,
    transactions: string,
    add: string,
    nameRuleset: string,
    rulesetNameGuidance: string,
    inUse: string,
    delete: string,
    rulesetTemplated: string,
}
const enTranslations: translations = {
    rulesets: "Rulesets",
    edit: "Edit",
    addRuleset: "Add Ruleset",
    chain: "Chain",
    accounts: "Accounts",
    rpcSingle: "RPC",
    rpc: "RPC ({{i}})",
    transactionsSingle: "Transactions",
    transactions: "Transactions ({{i}})",
    add: "Add",
    nameRuleset: "New Ruleset Name",
    rulesetNameGuidance: "Name must only contain characters a-z A-Z 0-9 _ - .",
    inUse: "Ruleset referred to by mappings",
    delete: "Delete ruleset",
    rulesetTemplated: "{{ruleset}} (templated)",
}

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        padding: theme.spacing(3),
    },
    missingComment:  {
        fontStyle: "italic",
    },
}));
