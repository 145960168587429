import React, { useState, useEffect } from 'react';
import { 
    List,
    makeStyles,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
} from '@material-ui/core';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { SubNavs } from '../MainNav/SideNavs/SubNavs';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { CHANNELS_PATH } from '../MainNav/SideNavs/AddressBook';
import { ChannelPicker } from './ChannelPicker';

export const CHANNEL_MEMBERS_PATH = 'members'
export const CHANNEL_NODES_PATH = 'nodes'
export const CHANNEL_CHAINCODES_PATH = 'chaincodes'
export const CHANNEL_METRICS_MONITORING_PATH = 'metricsmonitoring'
export const CHANNEL_CONFIGURATIONS_PATH = 'configurations'
export const CHANNEL_SETTINGS_PATH = 'settings'

const ChannelNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChannelNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChannelNav:${key}`, interpolate)

    const OVERVIEW = lt('overview');
    const MEMBERS = lt('members')
    const NODES = lt('nodes')
    const CHAINCODES = lt('chaincodes')
    const METRICS_MONITORING = lt('metricsMonitoring')
    const CONFIGURATIONS = lt('configurations')
    const SETTINGS = lt('settings')

    const history = useHistory()
    const { org_id, consortium_id, environment_id, channel_id, runtime_id, address, destination_uri } = useParams<any>();
    const { pathname } = useLocation();
    
    const [selectedItem, setSelectedItem] = useState<string>();

    useEffect(() => {
        if (pathname.endsWith(channel_id!)) setSelectedItem(OVERVIEW)
        if (pathname.endsWith(runtime_id!)) setSelectedItem(MEMBERS)
        if (pathname.endsWith(CHANNEL_MEMBERS_PATH)) setSelectedItem(MEMBERS)
        if (pathname.endsWith(destination_uri!)) setSelectedItem(NODES)
        if (pathname.endsWith(CHANNEL_NODES_PATH)) setSelectedItem(NODES)
        if (pathname.endsWith(address!)) setSelectedItem(CHAINCODES)
        if (pathname.endsWith(CHANNEL_CHAINCODES_PATH)) setSelectedItem(CHAINCODES)
        if (pathname.endsWith(CHANNEL_METRICS_MONITORING_PATH)) setSelectedItem(METRICS_MONITORING)
        if (pathname.endsWith(CHANNEL_CONFIGURATIONS_PATH)) setSelectedItem(CONFIGURATIONS)
        if (pathname.endsWith(CHANNEL_SETTINGS_PATH)) setSelectedItem(SETTINGS)
    }, [pathname, channel_id, OVERVIEW, MEMBERS, NODES, METRICS_MONITORING, CONFIGURATIONS, SETTINGS, CHAINCODES, runtime_id, address, destination_uri ])

    const baseChannelsPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}`

    const historyPusher = (path?: string) => history.push(`${baseChannelsPath}/${channel_id}${path ? `/${path}` : ''}`)

    let tabs = [
        {
            name: OVERVIEW,
            action: () => historyPusher()
        },
        {
            name: MEMBERS,
            action: () => historyPusher(CHANNEL_MEMBERS_PATH)
        },
        {
            name: NODES,
            action: () => historyPusher(CHANNEL_NODES_PATH)
        },
        {
            name: CHAINCODES,
            action: () => historyPusher(CHANNEL_CHAINCODES_PATH)
        },
        // {
        //     name: METRICS_MONITORING,
        //     action: () => historyPusher(CHANNEL_METRICS_MONITORING_PATH)
        // },
        // {
        //     name: CONFIGURATIONS,
        //     action: () => historyPusher(CHANNEL_CONFIGURATIONS_PATH)
        // },
        // {
        //     name: SETTINGS,
        //     action: () => historyPusher(CHANNEL_SETTINGS_PATH)
        // }
    ]

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(baseChannelsPath)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{lt('channels')}</Typography>} 
                        disableTypography />
                </ListItem>
                <ListItem>
                    <ListItemText secondary={lt('channel')} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>
                <ChannelPicker />
                <SubNavs list={tabs} selected={true} {...{selectedItem}} />
            </List>
        </div> 
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    backButton: {
        paddingLeft: theme.spacing(1)
    },
    backIcon: {
        minWidth: 0
    }
}));

interface translations {
    overview: string
    members: string
    nodes: string
    chaincodes: string
    metricsMonitoring: string
    channels: string
    configurations: string
    settings: string
    channel: string
}
const enTranslations: translations = {
    overview: 'Overview',
    members: 'Members',
    nodes: 'Nodes',
    chaincodes: 'Chaincodes',
    metricsMonitoring: 'Metrics & Monitoring',
    channels: 'Channels',
    configurations: 'Configurations',
    settings: 'Settings',
    channel: 'Channel'
}

export { ChannelNav };

