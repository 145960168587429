import React from 'react';
import { Paper, Grid, Typography, makeStyles, Divider } from '@material-ui/core';


export interface KpiData {
    key?: string
    value: String | Number
    label: string
    icon?: JSX.Element
}
interface Props {
    data: KpiData[]
    noPaper?: boolean
}

export const Kpi = ({data, noPaper} : Props) => {

    const classes = useStyles();

    const content = (
        <Grid container>
            {data.map((entry, index) => (
                <Grid key={entry.key || `kpi-${index}`}  item container wrap="nowrap" justify='space-between' xs={6} md={true}>
                    <Grid item container alignItems="center" wrap="nowrap" spacing={1} className={classes.container}>
                        {entry.icon && (
                            <Grid item>
                                {entry.icon}
                            </Grid>
                        )}
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography noWrap variant={entry.value.toString().length > 4 ? "h6" : "h5"}>
                                    {entry.value}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography noWrap variant="body2">
                                    {entry.label}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(!noPaper || (index < data.length -1)) &&
                    <Grid item>
                        <Divider orientation="vertical" />
                    </Grid>
                    }
                    
                </Grid>
            ))}
        </Grid>
    )

    if (noPaper) return content

    return (
        <Paper className={classes.paperContainer}>
            {content}
        </Paper>
    )
}

const useStyles= makeStyles(theme => ({
    container: {
        padding: theme.spacing(2, 3)
    },
    paperContainer: {
        overflow: 'auto'
    }
}));