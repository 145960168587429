import React from 'react';
import { makeStyles, Grid, Typography, TextField, Switch, Divider } from '@material-ui/core';

export const InputRow = ({ setAuthConfigValue, dataTest, fieldName, fieldReadable, fieldDesc, checked, value, isSwitch, disabled }: {
    setAuthConfigValue: (propName: string, value: any) => void,
    dataTest?: string,
    fieldName: string,
    fieldReadable: string,
    fieldDesc: string,
    isSwitch: boolean,
    checked?: boolean,
    value?: string,
    disabled?: boolean,
}) => {
    const classes = useStyles();
    return <>
        <Grid item container spacing={2} justify="space-between" className={classes.innerPadding} alignItems="center">
            <Grid item xs>
                <Grid item container direction="column">
                    <Grid><Typography variant="body1" color="textPrimary">{fieldReadable}</Typography></Grid>
                    <Grid><Typography variant="body2" color="textSecondary">{fieldDesc}</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item>
                {isSwitch
                ? <Switch
                        data-test={dataTest}
                        checked={checked ? true : false}
                        color="primary"
                        onChange={event => setAuthConfigValue(fieldName, event.target.checked)}
                        name={fieldName}
                    />
                : <TextField
                    fullWidth
                    value={value || ''}
                    variant="outlined" 
                    onChange={event => setAuthConfigValue(fieldName, event.target.value)}
                    disabled={disabled}/>
                }
            </Grid>
        </Grid>
        <Grid item>
            <Divider />
        </Grid>
    </>;
}

const useStyles = makeStyles(theme => ({
    innerPadding: {
        padding: theme.spacing(2),
    }
}));
