import {
    AppBar,
    Container,
    Dialog,
    Grid,
    IconButton,
    LinearProgress,
    Slide,
    Step, StepLabel,
    Stepper,
    Toolbar,
    Typography, makeStyles
} from "@material-ui/core";
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from 'mdi-react/CloseIcon';
import React from 'react';
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

interface Props {
    stepUrlParam: string,
    toolbarHeader: string,
    stepComponents: Array<{ step: string, component: JSX.Element }>,
    cancelPath?: string,
    loading?: boolean,
    hideStepper?: boolean,
    routerState?: {[key: string]: any}
};


export const FullScreenCreate = ({ cancelPath, loading, stepUrlParam, toolbarHeader, stepComponents, hideStepper = false, routerState }: Props) => {
    const classes = useStyles();

    const history = useHistory()
    
    const steps = stepComponents.map(s => s.step)

    const createStep = parseInt(stepUrlParam) - 1

    const getStepComponent = () => {
        return stepComponents[createStep].component
    }

    const cancel = () => {cancelPath ? history.push(cancelPath, routerState) : history.goBack()}
    const slClasses = stepperLabelStyles();
    const siClasses = stepperIconStyles();

    return (
        <Dialog fullScreen open={true} onClose={cancel} TransitionComponent={Transition} PaperProps={{classes: { root: classes.paper } }}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid item>
                            <IconButton edge="start" color="inherit" onClick={cancel} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h5" >
                                {toolbarHeader}
                            </Typography>
                        </Grid>
                        {!hideStepper &&
                        <Grid item xs={12} sm={8} lg={6} xl={5}>
                            <Stepper activeStep={createStep} className={classes.inHeader}>
                                {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = { completed: createStep > index };
                                const labelProps: { optional?: React.ReactNode } = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps} classes={slClasses} StepIconProps={{classes: siClasses}}
                                        >{label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                        </Grid>
                        }                    
                    </Grid>
                </Toolbar>
            </AppBar>

            { loading && <LinearProgress /> }
            
            <Container>
                <Grid container justify="center" spacing={3} className={classes.dropBelowHeader}>
                    {getStepComponent()}
                </Grid>
            </Container>
            
        </Dialog>
    )
};

const stepperIconStyles = makeStyles(theme => ({
    // Took a while to work out how to override this to work in the header
    root: {
        color: theme.palette.background.paper,
        "&$active": {
            color: theme.palette.background.paper
        },
        "&$completed": {
            color: theme.palette.background.paper
        }
    },
    text: {
        fill: theme.palette.primary.main,
        "&$active": {
            fill: theme.palette.primary.main
        },
        "&$completed": {
            fill: theme.palette.primary.main
        }
    },
    active: {
        "&$active": {
            color: theme.palette.background.paper
        },        
    },
    completed: {
        "&$completed": {
            color: theme.palette.background.paper
        },
    },
}))

const stepperLabelStyles = makeStyles(theme => ({
    // Took a while to work out how to override this to work in the header
    root: {
        color: theme.palette.background.paper,
        "&$active": {
            color: theme.palette.background.paper
        },
        "&$completed": {
            color: theme.palette.background.paper
        }
    },
    active: {
        "&$active": {
            color: theme.palette.background.paper
        },
    },
    completed: {
        "&$completed": {
            color: theme.palette.background.paper
        },
    },
    label: {
        color: theme.palette.background.paper
    },
}))

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.default
    },
    inHeader: {
        background: 'none'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dropBelowHeader: {
        paddingTop: theme.spacing(8)
    }
}));
