import React from 'react';
import { CssBaseline, Grid, makeStyles } from '@material-ui/core';
import { BrandColors } from '../../utils/Colors';

interface Props {
    children: JSX.Element
};


export const AuthenticationBackgroundWrapper = ({children}: Props) => {

    const classes = useStyles();

    return (
        <>
            <CssBaseline />
            <Grid container className={classes.container} direction="column" justify="space-between">
                <Grid item>
                    <a href="/">
                        <img src={process.env.PUBLIC_URL + '/Kaleido-Logo-Horizontal-White.png'} alt="" className={classes.kaleidoLogo} />
                    </a>
                </Grid>
                {children}

                {/* The two empty Grid items below are used to shift full-screen
                content vertically using space-between in the container Grid */}
                <Grid item></Grid>
                <Grid item></Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: BrandColors.blurple,
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/Login/BG-Pattern.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '80vh',
        backgroundPositionX: 'right',
        minHeight: '100vh',
        paddingBottom: '40px'
    },
    kaleidoLogo: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(6),
        marginBottom: theme.spacing(3),
        height: '40px'
    }
}));