import React, { isValidElement } from 'react';
import { ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";

export interface DisplayCardListItem {
    icon: JSX.Element,
    title: string | JSX.Element,
    value?: string | number | JSX.Element,
    actionIcon?: JSX.Element | string | null,
    onClick?: () => void,
    key?: string, // ideally this would be required
    divider?: boolean
    disabled?: boolean
}

interface Props {
    listItem: DisplayCardListItem
}

export const DisplayCardItem = ({ listItem }: Props) => {
    const classes = useStyles();

    // the stupidness here is because the button prop type is not a boolean...
    const makeListItemWrapper = (children: JSX.Element) => {
        if (listItem.onClick) {
            return (
                <ListItem disableGutters button={true} disabled={listItem.disabled} onClick={listItem.onClick} divider={listItem.divider} className={classes.padListItem}>
                    {children}
                </ListItem>
            )
        }
        return (
            <ListItem disableGutters disabled={listItem.disabled} divider={listItem.divider} className={classes.padListItem}>
                {children}
            </ListItem>
        )
    }

    const actionIconIsElement = isValidElement(listItem.actionIcon)

    return makeListItemWrapper(
        <>
            <ListItemIcon className={classes.identifierIcon}>{listItem.icon}</ListItemIcon>

            <ListItemText className={actionIconIsElement ? classes.minTitleWidth : ''} primary={<Typography component='div' variant="body1">{listItem.title}</Typography>} 
                secondary={<Typography component='div' variant="body2" color="textSecondary">{listItem.value}</Typography>} 
                disableTypography />

            {
                actionIconIsElement ? 
                <ListItemIcon className={classes.statusIcon}>
                    {listItem.actionIcon}
                </ListItemIcon> : 
                <Typography>
                    {listItem.actionIcon}
                </Typography>
            }
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    minTitleWidth: {
        minWidth: '30%'
    },
    identifierIcon: {
        minWidth: '46px'
    },
    statusIcon: {
        justifyContent: 'flex-end',
        maxWidth: '65%' 
    },
    padListItem: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    }
}));