import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Environment, NodesData, NodesQuery } from '../../models';
import { CorDappNodeInstance } from '../../components/CorDapps/CorDappNodeInstance';
import { DisplayTable } from '../../components/DisplayWrappers';

interface Props {
    environment: Environment
};

export const CorDappInstances =({ environment }: Props)=> {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CorDappInstances', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CorDappInstances:${key}`, interpolate)

    const { gateway_api_id, consortium_id, environment_id } = useParams<any>();

    const [deployedNodes, setDeployedNodes] = useState<string[]>([]);
    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: { consortia_id: consortium_id!, environment_id: environment_id! },
        fetchPolicy: 'cache-only'
    });

    const columnHeaders = [
        '',
        lt('nodeName'),
        lt('nodeId'),
    ]

    const preBuiltRows = (
        <>
            {nodes.filter(
                n => n.membership.isMine).map(node => 
                <CorDappNodeInstance {...{node}} {...{setDeployedNodes}} {...{deployedNodes}} {...{environment}} key={node._id} corDappId={gateway_api_id} />) }
        </>
    )
    
    return (
        <DisplayTable {...{columnHeaders}} header={lt('header')} description={lt('description')} {...{preBuiltRows}} maxHeight='extend' />
    )
};

interface translations {
    header: string
    nodeName: string
    nodeId: string
    createdAt: string,
    description: string
}
const enTranslations: translations = {
    header: 'CorDapp Nodes',
    nodeName: 'Node name',
    nodeId: 'Node ID',
    createdAt: 'Created at',
    description: 'This CorDapp has been deployed to nodes with the green check mark.'
}