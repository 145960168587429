import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../../components/DialogWrappers';
import { AccountSelector } from '../../components/AccountSelector/AccountSelector';
import { Account, NodesQuery, NodesData } from '../../models';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { NODE_CONTRACTS_PATH } from '../../components/NodeNav/NodeNav';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ChooseAccount = ({open, setOpen}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DigitalAssetsTokens', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DigitalAssetsTokens:${key}`, interpolate);

    const [account, setAccount]  = useState<Account | undefined>(undefined);

    const {consortium_id, environment_id, org_id} = useParams<any>();
    const history = useHistory();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes`

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVariables,
        fetchPolicy: 'cache-only',
    });

    const createPath = useMemo(() => {
        if (account) {
            const node = nodes.find(node => node.membership_id === account.membership_id);
            return `${basePath}/${node?._id}/${NODE_CONTRACTS_PATH}/create/1`;
        }
    }, [account, nodes, basePath]);

    const content = <AccountSelector isMine fullWidth {...{account}} {...{setAccount}} />;

    const disabled = !account && !createPath;

    const onCreate = async () => {
        if (createPath) history.push(createPath, {
            sourceCodeType: 'token',
            deployerAccount: account
        });
    }

    return (
        <FormDialog header={lt('header')} 
            saveDisabled={disabled} 
            description={lt('description')} 
            {...{open}} 
            {...{setOpen}} 
            saveText={lt('create')} 
            controlsWrapper={content}
            onSave={onCreate} />
    )
}

interface translations {
    header: string
    description: string
    create: string
}

const enTranslations: translations = {
    header: 'Choose Account',
    description: 'Choose the account you would like to deploy the contract with.',
    create: 'Create Token'
}