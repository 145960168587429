import { useApolloClient, useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import KeyOutlineIcon from 'mdi-react/KeyOutlineIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UpdateName } from '../../components/DialogWrappers';
import { EditableSettingItem, EditableSettings } from '../../components/DisplaySettings';
import { OrganizationData, OrganizationQuery, OrganizationVars, RolesData, RolesQuery } from '../../models';
import { TransferOwner } from './TransferOwner';
import { SetDelegate } from './SetDelegate';

export const OrgSettings: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgSettings:${key}`, interpolate);
    const { org_id } = useParams<any>();

    const [editOrgNameVisible, setEditOrgNameVisible] = useState(false);
    const [editOrgOwnerVisible, setEditOrgOwnerVisible] = useState(false);
    const [editDelegateVisible, setEditDelegateVisible] = useState(false);

    const client = useApolloClient();
    const { organization } = client.cache.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id} })!;

    const {
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, {
        variables: { org_id },
        fetchPolicy: 'cache-and-network' }
    );
    
    const actionList: EditableSettingItem[] = [
        {
            icon: <AccountCircleIcon />,
            title: lt('organizationName'),
            description: lt('organizationNameDesc'),
            value: organization?.name,
            action: () => setEditOrgNameVisible(true),
            buttonLabel: lt('change'),
            buttonTestData: 'renameOrgButton',
        },
        {
            icon: <KeyOutlineIcon />,
            title: lt('organizationOwner'),
            description: lt('organizationOwnerDesc'),
            value: roles.find(r => r.user_id === organization?.owner)?.email,
            action: () => setEditOrgOwnerVisible(true),
            buttonLabel: lt('change'),
        },
        {
            icon: <EmailOutlineIcon />,
            title: lt('primaryContactEmail'),
            description: lt('primaryContactEmailDesc'),
            value: organization?.delegate,
            action: () => setEditDelegateVisible(true),
            buttonLabel: lt('change'),
        }
    ];

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('organizationSettings')}</Typography>
                </Grid>
                <Grid item sm={8}>
                    <EditableSettings actionsList={actionList} />
                </Grid>
            </Grid>
            <UpdateName defaultName={organization.name} open={editOrgNameVisible} setOpen={setEditOrgNameVisible} />
            <TransferOwner open={editOrgOwnerVisible} setOpen={setEditOrgOwnerVisible}/>
            <SetDelegate open={editDelegateVisible} setOpen={setEditDelegateVisible}/>
        </>
    );
};


interface translations {
    organizationSettings: string
    organizationName: string
    organizationNameDesc: string
    organizationOwner: string
    organizationOwnerDesc: string
    primaryContactEmail: string
    primaryContactEmailDesc: string
    change: string
}

const enTranslations: translations = {
    organizationSettings: 'Organization Settings',
    organizationName: 'Name',
    organizationNameDesc: 'Change the primary display label for this org.',
    organizationOwner: 'Organization Owner',
    organizationOwnerDesc: 'Transfer the ownership of this org to another user.',
    primaryContactEmail: 'Primary Contact Email',
    primaryContactEmailDesc: 'Main contact email for this organization.',
    change: 'Change'
}