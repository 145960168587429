import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step2Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep2Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-CertificateAuthority.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#the-system-membership"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Adding Members to a Channel',
    helpDescription: 'All the orderers and peers of each participating memberships are joined to a channel that the membership is part of, rather than allowing a designation of individual nodes to join. This simplifies the mental model of managing channels.',
    helpDesc1: `The system membership is a built-in membership for every Kaleido environment, which operates the System Monitor node. Adding the system membership to a channel can be a useful cost-saving mechanism when operating bilateral channels.`,
    helpDesc2: 'After a channel is created, additional members can be added to it. Kaleido will orchestrate the channel configuration update and join the new member\'s orderer and peer nodes to the channel.',
  
}