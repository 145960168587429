import { useApolloClient, useMutation } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import FaceAgentIcon from 'mdi-react/FaceAgentIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CongratsMessage } from '../../components/CongratsMessage/CongratsMessage';
import { CreateWrapper, MessageSnackbar } from '../../components/DialogWrappers';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import { CreateStepProps } from '../../interfaces';
import { EnPlansTranslations, PlanName, PlansEnum, PlansTranslations, PlanUpgradeData, PlanUpgradeVars, UpgradePlanMutation } from '../../models';
import { cognitoController } from '../../utils/cognitoController';
import { MakeUpdateContactInfoMutationOptions, UpdateContactInfoData, UpdateContactInfoVars, UpdateContactInfoMutation } from '../../models/contactInfo';

interface Props extends CreateStepProps {
    plan: PlanName
    supportLevel: Number
}

export const Step3 = ({ plan, supportLevel, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChangePlanStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChangePlanStep2:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const [errorMessage, setErrorMessage] = useState('');
    const [planChangeComplete, setPlanChangeComplete] = useState(false);
    const client = useApolloClient();

    const [upgradePlan] = useMutation<PlanUpgradeData, PlanUpgradeVars>(UpgradePlanMutation);
    const [updateContactInfo] = useMutation<UpdateContactInfoData, UpdateContactInfoVars>(UpdateContactInfoMutation);

    const getSupportLabel = () => {
        switch (supportLevel) {
            case 100: return lt('free');
            case 200: return lt('select');
            case 300: return lt('priority');
        }
    };

    const itemList: DisplayCardListItem[] = [
        {
            icon: <TuneIcon />,
            title: lt('subscriptionPlan'),
            value: lt(PlansEnum[plan])
        },
        {
            icon: <FaceAgentIcon />,
            title: lt('supportLevel'),
            value: getSupportLabel()
        }
    ];

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('confirmPlan', {plan: lt(PlansEnum[plan])})}
                </Typography>
            </Grid>
            <Grid item>
                <DisplayCard itemList={itemList} />
            </Grid>
        </>
    );

    const save = async () => {
        try {
            const {data}= await upgradePlan({ variables: { org_id: org_id!, targetPlan: { plan, support_level: supportLevel } } });
            if(data?.upgradePlan) {
                await cognitoController.renewSession(client, org_id);
                
                await updateContactInfo(MakeUpdateContactInfoMutationOptions({
                    properties: {
                        kaleido_plan_at_signup: plan
                    }
                }));

                setPlanChangeComplete(true);
            }
        } catch(err) {
            setErrorMessage(lt('failedToChangePlan'));
            console.error(err)
        }
    };

    if (planChangeComplete) {
        return (
            <CongratsMessage {...{plan}} redirectPath={`/`} />
        );
    } else {
        return (
            <>
                <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
                <CreateWrapper disabled={false} cancelPath={cancelPath} {...{ content }} onNext={save} isLastStep customNextButtonLabel={lt('confirm')} />
            </>
        );
    }
};

interface translations extends PlansTranslations {
    confirmPlan: string
    subscriptionPlan: string
    supportLevel: string
    free: string
    select: string
    priority: string
    confirm: string
    failedToChangePlan: string
    congratulationsTitle: string
    congratulationsSubtitle: string
    congratulationsDescription: string
    finish: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    confirmPlan: 'Confirm {{plan}} Plan Selection',
    subscriptionPlan: 'Subscription Plan',
    supportLevel: 'Support Level',
    free: 'Free',
    select: 'Select',
    priority: 'Priority',
    confirm: 'Confirm',
    failedToChangePlan: 'Could not change plans. Please check plan requirements.',
    congratulationsTitle: 'Congratulations!',
    congratulationsSubtitle: 'You are now on the {{planName}} Plan.',
    congratulationsDescription: 'We hope you enjoy exploring new possibilities with your plan.',
    finish: 'Finish'
}