import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { BrandColors } from '../../../utils/Colors';

interface StatusProps {
    status: string;
}

export default function Status({ status }: StatusProps) {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SupportInboxStatus', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`SupportInboxStatus:${key}`, interpolate);
    const classes = useStyles();
    let statusText;
    let statusClassName;
    switch (status) {
        case 'new':
        case 'open':
            statusText = lt('open');
            statusClassName = classes.open;
            break;
        case 'pending':
            statusClassName = classes.pending;
            statusText = lt('awaitingYourReply');
            break;
        case 'closed':
        case 'solved':
            statusClassName = classes.solved;
            statusText = status;
            break;
        default:
            statusClassName = classes.open;
            statusText = status;
    }

    return (
        <div className={classes.container}>
            <div className={clsx(classes.statusText, statusClassName)}>
                {statusText}
            </div>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 7,
    },
    statusText: {
        display: 'inline-block',
        height: 20,
        padding: '3px 8px',
        marginTop: 5,
        borderRadius: 2,
        fontSize: 10,
        fontWeight: 500,
        textTransform: 'uppercase',
        color: 'white',
    },
    open: {
        backgroundColor: BrandColors.blurple,
    },
    pending: {
        backgroundColor: BrandColors.magenta,
    },
    solved: {
        backgroundColor: BrandColors.gray86,
    },
}));

interface translations {
    open: string;
    awaitingYourReply: string;
}

const enTranslations: translations = {
    open: 'open',
    awaitingYourReply: 'AWAITING YOUR REPLY',
};
