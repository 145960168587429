import { NodeResourcesVars } from '../interfaces';
import { gql } from '@apollo/client';

export interface UpdateCordappInput {
    state: string
}

export interface CorDapp extends UpdateCordappInput {
    id: string
    size: number
    created: number
    consortiaContractId: string
    consortiaContractName: string
    description: string
}

export interface CorDapps {
    status: 'need-restart' | 'up-to-date'
    live: CorDapp[]
    staging: CorDapp[]
}

export interface CorDappData {
    cordapp: CorDapp
}

export interface CorDappsData {
    cordapps: CorDapps
}

export interface UpdateCorDappData {
    updateCordapp: CorDapp
}

export interface UpdateCorDappVars extends NodeResourcesVars {
    id: string,
    cordapp: UpdateCordappInput
}

export const CorDappFields = ` 
    fragment CorDappFields on CorDapp {
        id
        state
        size
        created
        consortiaContractId
        consortiaContractName
        description
    }`;

export const CorDappsFields = ` 
    fragment CorDappsFields on CorDapps {
        status
        live {
            id
            state
            size
            created
            consortiaContractId
            consortiaContractName
            description
        }
        staging {
            id
            state
            size
            created
            consortiaContractId
            consortiaContractName
            description
        }
    }`;

export const CorDappsQuery = gql`
    ${CorDappsFields}
    query getCordapps($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        cordapps(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id) {
            ...CorDappsFields
        }
    }`;

export const CorDappQuery = gql`
    ${CorDappFields}
    query getCordapp($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        cordapp(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ...CorDappFields
        }
    }`;

export const UpdateCorDappMutation = gql`
    ${CorDappFields}
    mutation updateCordapp($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!, $cordapp: UpdateCordappInput!) {
        updateCordapp(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id, cordapp: $cordapp) {
            ...CorDappFields
        }
    }`;

