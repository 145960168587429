import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateConfigForm, CreateConfigInputList } from './CreateConfigForm';
import { FieldElement } from 'react-hook-form';
import { ConfigDetails } from '../../../models/configs';

interface Props {
    register: {<Element extends FieldElement<ConfigDetails> = FieldElement<ConfigDetails>>(): (ref: Element | null) => void;}
}

export const CreateLogStream = ({register}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateLogStream', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateLogStream:${key}`, interpolate);

    const AwsInputList: CreateConfigInputList[] = [
        {
            label: lt('region'),
            value: 'region'
        },{
            label: lt('group'),
            value: 'group'
        }
    ]


    return  <CreateConfigForm inputList={AwsInputList} showCredsTypeSelection {...{register}} /> 

}

interface translations {
    name: string,
    region: string,
    group: string
}
const enTranslations: translations = {
    name: 'Name',
    region: 'Region',
    group: 'Group'
}