import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ProcessItem } from '../../../../components/DisplayWrappers/ProcessItem';
import { useProvisionService } from '../../../../hooks/useProvisionService';

interface Props {
    hasIdReg: boolean,
    setIsSetupOnProgress: React.Dispatch<React.SetStateAction<boolean>>,
    isSetupOnProgress: boolean,
    membershipId: string
    isExternal: boolean
};

export const IdRegSetup = ({isExternal, hasIdReg, isSetupOnProgress, membershipId, setIsSetupOnProgress }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'IdRegSetup', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`IdRegSetup:${key}`, interpolate)

    const [loading, setLoading] = useState(false);

    const { consortium_id, environment_id } = useParams<any>();

    const {
        provisionService: provisionIdRegistry
    } = useProvisionService({
        consortiumId: consortium_id,
        environmentId: environment_id,
        name: "On-chain Registry",
        service: 'idregistry',
        membershipId
    })

    useEffect(() => {
        if(hasIdReg) {
            setLoading(false);
            if (isExternal) {
                setIsSetupOnProgress(false);
            }
        } 
    }, [hasIdReg, isExternal, setIsSetupOnProgress]);

    useEffect(() => {
        if (isSetupOnProgress && !hasIdReg) {
            setLoading(true)
            provisionIdRegistry().catch(() => {
                setIsSetupOnProgress(false)
                setLoading(false)
            });

        }
    }, [isSetupOnProgress, hasIdReg, provisionIdRegistry, setIsSetupOnProgress])


    return (
       <ProcessItem 
            title={lt('onChainRegistry')} 
            isDone={hasIdReg} 
            statusMessage={hasIdReg ? lt('provisioned') : lt('notProvisioned')} 
            loading={loading} />
    )
};

interface translations {
    onChainRegistry: string,
    notProvisioned: string,
    provisioned: string
}
const enTranslations: translations = {
    onChainRegistry: 'Provision On-Chain Registry',
    notProvisioned: 'Not Provisioned',
    provisioned: 'Provisioned'
}
