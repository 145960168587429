import React, { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../../components/DialogWrappers';
import { Step1 } from '../../Common/createDestination/Step1';
import { Step2 } from './Step2';
import { useQuery } from '@apollo/client';
import { ServicesData, ServicesQuery, CheckIdRegistryQuery, CheckIdRegistryData, CheckIdRegistryVars } from '../../../../models';
import { DOCSTORE_DESTINATIONS_PATH } from '../../../../components/ServicesNav/Services/DocumentStoreItems';
import { CircularProgress } from '@material-ui/core';

export const CreateDestinations: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateDestinations', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateDestinations:${key}`, interpolate);

    const history = useHistory();

    const { org_id, consortium_id, environment_id, service_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/b2b/documentstore/${service_id}/${DOCSTORE_DESTINATIONS_PATH}`

    const {
        loading: getServicesLoading,
        data: { 
            services
        } = { services: [] } 
    } = useQuery<ServicesData>(ServicesQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        refetch: refetchCheck,
        loading: checkIdRegistryloading,
        data: {
            checkIdRegistry
        } = {checkIdRegistry: null } 
    } = useQuery<CheckIdRegistryData, CheckIdRegistryVars>(CheckIdRegistryQuery, {
        variables: { service_id: service_id! },
        fetchPolicy: "no-cache"
    });

    const membershipId = useMemo(() => {
        const service = services.find(s => s._id === service_id);
        return service?.membership_id ?? '';
    }, [services, service_id]);
   
    const isSetupComplete = useMemo(() => {
        return checkIdRegistry?.status === 'ok';
    }, [checkIdRegistry]);


    const invalidStep = useMemo(() => {
        return step !== "1" && step !== "2"
    }, [step]);

    const redirect = useCallback(() => {
        if (isSetupComplete) {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/b2b/documentstore/${service_id}/${DOCSTORE_DESTINATIONS_PATH}/create/2`)
        }
        if (!isSetupComplete || invalidStep) {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/b2b/documentstore/${service_id}/${DOCSTORE_DESTINATIONS_PATH}/create/1`)
        }
    }, [history, org_id, consortium_id, service_id, isSetupComplete, invalidStep, environment_id]);

    const checkIfReady = async () => {
        try {
            await refetchCheck()
        } catch(err) {}
    };

    useEffect(() => {
        redirect();
    }, [redirect]);

    const loading = checkIdRegistryloading || getServicesLoading;

    const stepComponents = [
        { 
            step: lt('initialSetup'), 
            component: loading ? <CircularProgress />: <Step1 prerequisitesComplete={checkIfReady} {...{cancelPath}} {...{membershipId}} />
        },
        { 
            step: lt('addDestination'), 
            component: <Step2 {...{cancelPath}} />
        }
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createDestination')} stepUrlParam={step!} {...{stepComponents}} {...{loading}}/>
};

interface translations {
    createDestination: string,
    initialSetup: string,
    addDestination: string
}
const enTranslations: translations = {
    createDestination: 'Create Destination',
    initialSetup: 'Initial Setup',
    addDestination: 'Add Destination'
}