import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Box, Divider, Grid, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, MessageSnackbar } from '../../components/DialogWrappers';
import { CREATE_ENVIRONMENTS_PATH } from '../../components/MainNav/EnvironmentPicker';
import { CreateStepProps } from '../../interfaces';
import { EnvironmentType, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, Protocol, FeatureTogglesData, FeatureTogglesVars, FeatureTogglesQuery } from '../../models';
import { Step2Help } from './Step2Help';
import { PublicChainConnectivity } from './PublicChainConnectivity';
import { HigherAccessRequestData, SubmitHigherAccessRequestFormVars, SubmitHigherAccessMutation } from '../../models/contactInfo';
import { requestHigherAccessFormFields } from '../../utils/HubspotUtils';

interface Props extends CreateStepProps {
    environmentType: EnvironmentType,
    protocol: Protocol | undefined,
    setProtocol: (p: Protocol) => void
};

export const Step2 = ({ protocol, setProtocol, environmentType, cancelPath }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep2:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<
        'error' | 'success' | undefined
    >();
    const [requestSubmitted, setRequestSubmitted] =
        useState<boolean>(false);
    const [submitAccessRequest] = useMutation<
        HigherAccessRequestData,
        SubmitHigherAccessRequestFormVars
    >(SubmitHigherAccessMutation);

    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsFabric = useMemo(() => PlanSupports.fabric(organization), [organization])

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        fetchPolicy: 'cache-first'
    });

        const requestDedicatedAccess = async () => {
            const formFields = requestHigherAccessFormFields(
                'publicChainConnectivity',
                window.location.href
            );
            const { data } = await submitAccessRequest({
                variables: {
                    ...formFields,
                },
            });
            if (data?.requestHigherAccess) {
                setMessageType('success');
                setMessage(lt('requestSubmitted'));
                setRequestSubmitted(true);
            }
        };


    useEffect(() => {
        // We use an effect here, so that only Step2 sets the state (F5 refresh zaps you back to step 1)
        if (!protocol || (environmentType === 'firefly' && (protocol === 'corda' || (protocol === 'fabric' && !featureToggles?.fabricFirefly)))) {
            setProtocol('ethereum');
        }
    }, [setProtocol, protocol, environmentType, featureToggles?.fabricFirefly])

    const makeProtocolRadio = (p: Protocol, label: string, description: string, link: string, beta: boolean, disabled: boolean) => {
        return (
            <Grid item>
                <Paper className={disabled ? classes.radioPaperDisabled : protocol === p ? classes.radioPaperSelected : classes.radioPaper } elevation={0}
                    onClick={() => !(environmentType === 'firefly' && (p === 'corda' || (p === 'fabric' && !featureToggles?.fabricFirefly))) && setProtocol(p)}
                >
                    <Grid container direction="row" alignItems="center" alignContent="center" justify='center' spacing={2} wrap='nowrap'>
                        <Grid item className={classes.logoWidth}>
                        {beta && <Grid item>
                                     <Box component="span" className={classes.betaTag}>{lt('beta')}</Box>
                                 </Grid>}
                            <img className={classes.logoCentered} src={`${process.env.PUBLIC_URL}/img/protocols/${p}-logo.svg`} alt={lt('logo', {label})} ></img>
                        </Grid>
                        <Grid item container direction='column' spacing={1}>
                            <Grid item>
                                <Typography variant="h6" color="textPrimary">
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="textSecondary">
                                    {description}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link href={link} target="_blank">{lt('learnMore')}</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

        const requestAccess = (
            <>
                <Divider style={{ margin: '0 0 20px' }} />
                <PublicChainConnectivity
                    buttonDisabled={requestSubmitted}
                    buttonFn={requestDedicatedAccess}
                />
            </>
        );

    const save = async () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${CREATE_ENVIRONMENTS_PATH}/3`, {
            protocol,
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid container direction="column" spacing={1}>
                {makeProtocolRadio(
                    'ethereum',
                    lt('ethereum'),
                    lt('ethereumDescription'),
                    'https://docs.kaleido.io/kaleido-platform/protocol/ethereum',
                    false, false)}
                {supportsFabric && makeProtocolRadio(
                    'fabric',
                    lt('fabric'),
                    lt('fabricDescription'),
                    'https://docs.kaleido.io/kaleido-platform/protocol/fabric',
                    false, environmentType === 'firefly' && !featureToggles?.fabricFirefly ? true : false)}
                {environmentType !== 'firefly' && makeProtocolRadio(
                    'corda',
                    lt('corda'),
                    lt('cordaDescription'),
                    'https://docs.kaleido.io/kaleido-platform/protocol/corda',
                    false, false)}
            </Grid>
            
         
        </>
    )

    return (
        <>
            <MessageSnackbar
                messageType={messageType}
                {...{ message }}
                {...{ setMessage }}
            />
            <CreateWrapper
                {...{ cancelPath }}
                {...{ content }}
                disabled={false}
                onNext={save}
                additionalContent={requestAccess}
            />
            <Step2Help />
        </>
    );
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    radioPaperSelected: {
        padding: theme.spacing(2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
        cursor: 'pointer',
    },
    radioPaperDisabled: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
        opacity: '50%'
    },
    logoWidth: {
        minWidth: '150px',
    },
    logoCentered: {
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    betaTag: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: '1px 3px 1px 3px',
        borderRadius: '2px',
        textTransform: 'uppercase',
        fontSize: 'small'
    },
}));

interface translations {
    header: string;
    headerDescription: string;
    ethereum: string;
    ethereumDescription: string;
    corda: string;
    cordaDescription: string;
    fabric: string;
    fabricDescription: string;
    beta: string;
    logo: string;
    learnMore: string;
    requestSubmitted: string;
    supportedFireflyProtocolsWithFabric: string;
}
const enTranslations: translations = {
    header: 'Select a Protocol',
    headerDescription:
        'Choose the core blockchain technology for the environment',
    ethereum: 'Ethereum',
    ethereumDescription:
        'Create a permissioned blockchain network tailored to the Enterprise, with efficient consensus and privacy extensions. Benefit from resources and tooling developed by the vibrant Ethereum community',
    corda: 'Corda',
    cordaDescription:
        'Build next-generation Business applications with transparency, security and efficiency. Use blockchain technology to agree transaction state with select counterparties on a transaction by transaction basis',
    fabric: 'Hyperledger Fabric',
    fabricDescription:
        'Fabric is a permissioned distributed ledger framework for developing solutions and applications. Its modular and versatile design satisfies a broad range of industry use cases',
    beta: 'Beta',
    logo: '{{label}} Logo',
    learnMore: 'Learn More',
    requestSubmitted:
        'Request submitted. Someone will be in touch with you soon.',
    supportedFireflyProtocolsWithFabric:
        'Support for Fabric and Corda is coming soon.',
};