import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'
import { EnvironmentType } from '../../models';


interface Props {
    helpHeader: string
    environmentType?: EnvironmentType
};

export const Step1Help = ({ helpHeader, environmentType } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep1Help:${key}`, interpolate)

    const getHelpDescription = () => {
        if (!environmentType) return lt('empty')
        return environmentType === 'firefly' ? lt('helpDescriptionFirefly') : lt('helpDescription')
    }

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Environment-Protocol.svg`}
            header={helpHeader}
            description={getHelpDescription()}
            finalInfo={environmentType && lt('moreHelpDeciding')}
            docLink={environmentType && (environmentType === 'firefly' ? "https://docs.kaleido.io/kaleido-platform/firefly/" : "https://docs.kaleido.io/kaleido-platform/")}
        />
    )
};

interface translations {
    helpDescriptionFirefly: string,
    helpDescription: string,
    moreHelpDeciding: string,
    empty: string
}
const enTranslations: translations = {
    helpDescriptionFirefly: 'The FireFly API provides an open, standardized abstraction layer for private and public chains to orchestrate and manage digital assets, data flows, and blockchain transactions. Build production-ready apps on popular chains and protocols radically faster.',
    helpDescription: "The Kaleido API provides a robust layer to manage your blockchain and other plug and play services. Kaleido was designed to offer choice and flexibility that matches the diverse needs of modern business networks. Customize your solution with a wide range of integrated services and capabilities to accelerate the deployment of live production Web3 solutions.",
    moreHelpDeciding: 'Need more help choosing?',
    empty: 'To create an environment you need to first select which type you would like to create. You can choose our standard blockchain service or our standard blockchain service with FireFly SuperNodes.'
}