import { useQuery } from '@apollo/client';
import { CircularProgress, FormControl, Grid, RadioGroup, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CreateWrapper } from '../../../components/DialogWrappers';
import { FormRadio } from '../../../components/FormControls/FormRadio';
import { CHANGE_PLAN_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, ORGS_PATH } from '../../../components/ManageOrgNav/ManageOrgNav';
import { CreateStepProps, OrgResourceVars } from '../../../interfaces';
import { PlanName } from '../../../models';
import { PlanComplianceData, PlanComplianceQuery } from '../../../models/planCompliance';
import { PlanNonCompliance } from './NonCompliance';

interface Props extends CreateStepProps {
    plan: PlanName
    supportLevel: Number
    setSupportLevel: React.Dispatch<React.SetStateAction<Number>>
}

export const Step2 = ({ cancelPath, plan, supportLevel, setSupportLevel }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChangePlanStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChangePlanStep2:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const history = useHistory();

    const {
        loading,
        data: {
            planCompliance
        } = { planCompliance: undefined }
    } = useQuery<PlanComplianceData, OrgResourceVars>(PlanComplianceQuery, { variables: { org_id: org_id! }, fetchPolicy: 'network-only' });

    if(loading || !planCompliance) {
        return <CircularProgress />
    }
    
    const handleSupportLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch(event.target.value) {
            case 'basic': setSupportLevel(100); break;
            case 'select': setSupportLevel(200); break;
            case 'priority': setSupportLevel(300); break;
        }
    };

    let content;
    let disabled = false;

    if(planCompliance[plan].compliant) {
        content = (
            <>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {lt('selectSupportLevel')}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset" margin="none">
                        <RadioGroup value={supportLevel} onChange={handleSupportLevelChange}>
                            <Grid container direction="column" spacing={1}>
                                {plan !== 'business' &&
                                <FormRadio selected={supportLevel === 100} value="basic" label={lt('basicTitle')} description={lt('basicDescription')} />}
                                <FormRadio disabled={plan !== 'business'} selected={supportLevel === 200} value="select" label={lt('selectTitle')} description={lt('selectDescription')} />
                                <FormRadio disabled={plan !== 'business'} selected={supportLevel === 300} value="priority" label={lt('priorityTitle')} description={lt('priorityDescription')} />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </>);
    } else {
        content = (
            <>
                <PlanNonCompliance plan={plan} complianceDetails={planCompliance[plan]} />
            </>
        );
        disabled = true;
    }
    const save = () => {
        history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/3`, { plan, supportLevel });
    };

    return <CreateWrapper disabled={disabled} cancelPath={cancelPath} {...{ content }} onNext={save} />;
}

interface translations {
    selectSupportLevel: string
    basicTitle: string
    basicDescription: string
    selectTitle: string
    selectDescription: string
    priorityTitle: string
    priorityDescription: string
}

const enTranslations: translations = {
    selectSupportLevel: 'Select Support Level',
    basicTitle: 'Basic (Free)',
    basicDescription: 'Best effort response time. Limited to business hours (US Eastern). Low severity tickets only.',
    selectTitle: 'Select (7% of Total Monthly Spend)',
    selectDescription: 'Guaranteed responses within 1 business day. Limited to business hours (US Eastern).',
    priorityTitle: 'Priority (15% of Total Monthly Spend)',
    priorityDescription: '24/7 availability for urgent requests. Guaranteed responses within 1 business day. 1 hour response for critical requests. Recommended for production environments.'
}