import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';

// INTERFACES

export type ConsortiumState = "setup" | "live" | "delete_pending" | "deleted" | "archived"

// this should be an autogenerated interface from the Consortium model in graphql
export interface Consortium {
    _id: string
    name: string
    description?: string
    owner: string
    state: ConsortiumState
    mode: string
    created_at: string
    deleted_at: string
    member_policy: string
    amend_policy: string
    locked_policies: string[]
    admin_tags?: string[];
}

export interface ConsortiaData {
    consortia: Consortium[]
}

export interface OpsConsortiaData {
    opsConsortia: Consortium[]
}

export interface ConsortiumData {
    consortium: Consortium
}

export interface CreateConsortiumVars {
    consortium: {
        name: Consortium['name'],
        description?: Consortium['description'],
        owner: Consortium['owner'],
        region: string,
        org_name: string
    }
}

export interface UpdateConsortiumVars {
    id: string,
    consortium: {
        name?: Consortium['name']
    }
}

export interface OpsUpdateConsortiumVars {
    id: string,
    consortium: {
        name?: Consortium['name'],
        state?: string
        admin_tags?: string[]
    }
}

export interface CreateConsortiumData {
    createConsortium: Consortium
}

// FRAGMENTS

export const ConsortiumFields = ` 
    fragment ConsortiumFields on Consortium {
        _id
        name
        state
        created_at
        admin_tags
    }`

// QUERIES & SUBSCRIPTIONS

export const ConsortiaQuery = gql`
    ${ConsortiumFields}
    query getConsortia {
        consortia {
            ...ConsortiumFields
        }
    }`;

export const OpsConsortiaQuery = gql`
    ${ConsortiumFields}
    query getOpsConsortia($region: String, $query: String!) {
        opsConsortia(region: $region, query: $query) {
            ...ConsortiumFields
        }
    }`;

export const ConsortiaSubscription = gql`
    ${ConsortiumFields}
    subscription onConsortiaChanged {
        consortium {
            ...ConsortiumFields
        }
    }`;

export const CreateConsortiumMutation = gql`
    ${ConsortiumFields}
    mutation createConsortium($consortium: CreateConsortiumInput!) {
        createConsortium(consortium: $consortium) {
            ...ConsortiumFields
        }
    }`;

export const UpdateConsortiumMutation = gql`
    ${ConsortiumFields}
    mutation updateConsortium($id: String!, $consortium: UpdateConsortiumInput!) {
        updateConsortium(id: $id, consortium: $consortium) {
            ...ConsortiumFields
        }
    }`;

export const DeleteConsortiumMutation = gql`
    ${ConsortiumFields}
    mutation deleteConsortium($id: String!) {
        deleteConsortium(id: $id) {
            ...ConsortiumFields
        }
    }`;

export const OpsUpdateConsortiumMutation = gql`
    ${ConsortiumFields}
    mutation opsUpdateConsortium($id: String!, $consortium: OpsUpdateConsortiumInput!) {
        opsUpdateConsortium(id: $id, consortium: $consortium) {
            ...ConsortiumFields
        }
    }`;

// MUTATION OPTIONS

export function MakeConsortiumCreateMutationOptions(
    variables: CreateConsortiumVars,
    overrideOptions?: MutationFunctionOptions<CreateConsortiumData, CreateConsortiumVars>): MutationFunctionOptions<CreateConsortiumData, CreateConsortiumVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const consortium = data?.createConsortium
            if (consortium) {
                const { consortia } = cache.readQuery<ConsortiaData>({ query: ConsortiaQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!consortia.find(n => n._id === consortium._id)) {
                    cache.writeQuery({
                        variables,
                        query: ConsortiaQuery,
                        data: { consortia: consortia.concat(consortium) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

// SUBSCRIPTION OPTIONS

// these MakeConsortiaSubscriptionOptions could probably be made into functions that take typescript generics as 
// they are _mostly_ identical across the models

export function MakeConsortiaSubscriptionOptions(
    overrideOptions?: SubscriptionHookOptions<ConsortiumData>): SubscriptionHookOptions<ConsortiumData> {
    return { ...{
        onSubscriptionData: ({client, subscriptionData}) => {
            // Get the consortiaChanged object from the event
            const { 
                data: { 
                    consortium
                } = { consortium: null }
            } = subscriptionData

            if (!consortium) return

            // Get current list of consortia from the cache
            // assert that consortia must exist since this function throws if it doesnt
            const { consortia: existingConsortia } = client.readQuery<ConsortiaData>({query: ConsortiaQuery})!
            
            // if it already exists in the store, (its an update), apollo automagically updated the cache
            const cachedConsortium = existingConsortia.find(c => c._id === consortium._id)

            // but, if it came back as deleted, we need to remove it from the cache manually
            if (cachedConsortium) {
                if (cachedConsortium.state === 'deleted') {
                    client.cache.evict({id: `Consortium:${cachedConsortium._id}`, broadcast: false})
                }
                client.writeQuery<ConsortiaData>({ 
                    query: ConsortiaQuery, 
                    data: Object.assign({}, existingConsortia, {
                        consortia: [...existingConsortia.filter(c => c.state !== 'deleted')]
                    })
                })
                return 
            }

            // in case of duplicate events, never add a deleted consortium into the cache
            if (consortium.state === 'deleted') return

            // if it doesnt exist in the store, (it's been added), so we need to add it in manually
            client.writeQuery<ConsortiaData>({ 
                query: ConsortiaQuery, 
                data: Object.assign({}, existingConsortia, {
                    consortia: [consortium, ...existingConsortia]
                })
            })
        }
    }, ...overrideOptions }
}
