import React, { useState, useEffect } from 'react';
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher, Help } from '../../../components/DialogWrappers';
import { Grid, Typography, TextField, Switch, Button } from '@material-ui/core';
import PlusIcon from 'mdi-react/PlusIcon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CreateStepProps } from '../../../interfaces';
import { BAFPolicyRuleset, BAFPolicyTXRule, BAFPolicyRPCRule, BAFPolicyAccountsRule, BAFPolicyChainRule } from '../BAFPolicySchema';
import { RulePanel } from './RulePanel';

interface Props extends CreateStepProps {
    ruleset: BAFPolicyRuleset,
    save: () => Promise<void>,
    loading: boolean,
    setRuleset: (r: BAFPolicyRuleset) => void,
    ruleset_id: string,
}

export const Step1 = ({ruleset_id, ruleset, setRuleset, cancelPath, save, loading}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'RulesetEditorStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`RulesetEditorStep1:${key}`, interpolate)

    const history = useHistory();

    const [errorMessage, setErrorMessasge] = useState('');

    const onNext = async () => {
        try {
            await save();
            history.push(cancelPath);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    }

    const newTX = () => ({
        from: '.*',
        to: '.*',
        call: true,
        deploy: true,
        estimate: true,
        send: true,
        sendRaw: true,
    } as BAFPolicyTXRule)

    const newRPC = () => ({
        method: '',
        allow: true,
    } as BAFPolicyRPCRule)

    const newAccounts = () => ({
        coinbase: true,
        list: true,
        nonce: true,
        balance: true,
        sign: false,
        storage: false,
    } as BAFPolicyAccountsRule)

    const newChain = () => ({
        info: true,
        blocks: true,
        filter: true,
        pending: false,
        receipts: true,
        subscribe: true,
        transactions: true,
    } as BAFPolicyChainRule)

    useEffect(() => {
        if (Object.keys(ruleset).length === 0) {
            setRuleset({
                tx: [newTX()],
                chain: newChain(),
                accounts: newAccounts()
            })
        }
    }, [ruleset, setRuleset])

    const setRulesetComment = (comment: string) => {
        setRuleset({
            ...ruleset,
            comment
        })
    }

    const setRulesetTemplated = (templated: boolean) => {
        setRuleset({
            ...ruleset,
            templated
        })
    }

    const setChainToggle = (toggleName: string, checked: boolean) => {
        setRuleset({
            ...ruleset,
            chain: {
                ...ruleset.chain,
                [toggleName]: checked
            }
        })
    }

    const setAccountsToggle = (toggleName: string, checked: boolean) => {
        setRuleset({
            ...ruleset,
            accounts: {
                ...ruleset.accounts,
                [toggleName]: checked
            }
        })
    }

    const setTXValue = (i: number, prop: string, val: any) => {
        setRuleset({
            ...ruleset,
            tx: ruleset.tx!.map((tx,j) => i===j ? {
                ...tx,
                [prop]: val
            } : tx)
        })
    }

    const setRPCValue = (i: number, prop: string, val: any) => {        
        setRuleset({
            ...ruleset,
            rpc: ruleset.rpc!.map((rpc,j) => i===j ? {
                ...rpc,
                [prop]: val
            } : {...rpc})
        })
    }

    const addChainRule = () => {
        setRuleset({
            ...ruleset,
            chain: ruleset.chain || newChain(),
        })
    }

    const addAccountsRule = () => {
        setRuleset({
            ...ruleset,
            accounts: ruleset.accounts || newAccounts(),
        })
    }

    const addTXMatchingRule = () => {
        setRuleset({
            ...ruleset,
            tx: [...(ruleset.tx || []), newTX()]
        })
    }

    const deleteChainRule = () => {
        setRuleset({
            ...ruleset,
            chain: undefined,
        })
    }

    const deleteAccountsRule = () => {
        setRuleset({
            ...ruleset,
            accounts: undefined,
        })
    }

    const deleteTXMatchingRule = (i: number) => {
        setRuleset({
            ...ruleset,
            tx: ruleset.tx!.slice(0,i).concat(ruleset.tx!.slice(i+1)),
        })
    }

    const addRPCMatchingRule = () => {
        setRuleset({
            ...ruleset,
            rpc: [...(ruleset.rpc || []), newRPC()]
        })
    }

    const deleteRPCMatchingRule = (i: number) => {
        setRuleset({
            ...ruleset,
            rpc: ruleset.rpc!.slice(0,i).concat(ruleset.rpc!.slice(i+1)),
        })
    }

    const rulePanels : JSX.Element[] = [];

    ruleset.tx?.forEach((tx,i) => {
        rulePanels.push(<RulePanel key={`tx-panel-${i}`} title={lt('transactions', {i:i+1})}
            regexInputs={{
                [lt('txFrom')]: {desc: lt('txFromDesc'), val: tx.from || '', setter: val => setTXValue(i, 'from', val)},
                [lt('txTo')]: {desc: lt('txToDesc'), val: tx.to || '', setter: val => setTXValue(i, 'to', val)},
            }}
            toggles={{
                [lt('txCall')]: {desc: lt('txCallDesc'), checked: tx.call, setter: checked => setTXValue(i, 'call', checked)},
                [lt('txEstimate')]: {desc: lt('txEstimateDesc'), checked: tx.estimate, setter: checked => setTXValue(i, 'estimate', checked)},
                [lt('txSend')]: {desc: lt('txSendDesc'), checked: tx.send, setter: checked => setTXValue(i, 'send', checked)},
                [lt('txSendRaw')]: {desc: lt('txSendRawDesc'), checked: tx.sendRaw, setter: checked => setTXValue(i, 'sendRaw', checked)},
                [lt('txDeploy')]: {desc: lt('txDeployDesc'), checked: tx.deploy, setter: checked => setTXValue(i, 'deploy', checked)},
            }}
            handleDelete={() => deleteTXMatchingRule(i)}
        />)
    });

    rulePanels.push(<Grid key="add-tx-rule" item container><Grid item>
        <Button
            variant="text"
            color="primary"
            startIcon={<PlusIcon />}
            onClick={() => addTXMatchingRule()}
        >{lt('addTXRule')}</Button>
    </Grid></Grid>)

    if (ruleset.chain) {
        rulePanels.push(<RulePanel key="chain-panel" title={lt('chain')} toggles={{
            [lt('chainInfo')]: {desc: lt('chainInfoDesc'), checked: ruleset.chain?.info, setter: checked => setChainToggle('info', checked)},
            [lt('chainReceipts')]: {desc: lt('chainReceiptsDesc'), checked: ruleset.chain?.receipts, setter: checked => setChainToggle('receipts', checked)},
            [lt('chainPending')]: {desc: lt('chainPendingDesc'), checked: ruleset.chain?.pending, setter: checked => setChainToggle('pending', checked)},
            [lt('chainBlocks')]: {desc: lt('chainBlocksDesc'), checked: ruleset.chain?.blocks, setter: checked => setChainToggle('blocks', checked)},
            [lt('chainTransactions')]: {desc: lt('chainTransactionsDesc'), checked: ruleset.chain?.transactions, setter: checked => setChainToggle('transactions', checked)},
            [lt('chainFilter')]: {desc: lt('chainFilterDesc'), checked: ruleset.chain?.filter, setter: checked => setChainToggle('filter', checked)},
            [lt('chainSubscribe')]: {desc: lt('chainSubscribeDesc'), checked: ruleset.chain?.subscribe, setter: checked => setChainToggle('subscribe', checked)},
        }}
        handleDelete={() => deleteChainRule()}
        />)
    }
    else {
        rulePanels.push(<Grid key="add-chain-rule" item container><Grid item>
            <Button
                variant="text"
                color="primary"
                startIcon={<PlusIcon />}
                onClick={() => addChainRule()}
            >{lt('addChainRule')}</Button>
        </Grid></Grid>)
    }

    if (ruleset.accounts) {
            rulePanels.push(<RulePanel key="accounts-panel" title={lt('accounts')} toggles={{
            [lt('accountsCoinbase')]: {desc: lt('accountsCoinbaseDesc'), checked: ruleset.accounts?.coinbase, setter: checked => setAccountsToggle('coinbase', checked)},
            [lt('accountsNonce')]: {desc: lt('accountsNonceDesc'), checked: ruleset.accounts?.nonce, setter: checked => setAccountsToggle('nonce', checked)},
            [lt('accountsBalance')]: {desc: lt('accountsBalanceDesc'), checked: ruleset.accounts?.balance, setter: checked => setAccountsToggle('balance', checked)},
            [lt('accountsStorage')]: {desc: lt('accountsStorageDesc'), checked: ruleset.accounts?.storage, setter: checked => setAccountsToggle('storage', checked)},
            [lt('accountsList')]: {desc: lt('accountsListDesc'), checked: ruleset.accounts?.list, setter: checked => setAccountsToggle('list', checked)},
            [lt('accountsSign')]: {desc: lt('accountsSignDesc'), checked: ruleset.accounts?.sign, setter: checked => setAccountsToggle('sign', checked)},
        }}
        handleDelete={() => deleteAccountsRule()}
        />)
    }
    else {
        rulePanels.push(<Grid key="add-accounts-rule" item container><Grid item>
            <Button
                variant="text"
                color="primary"
                startIcon={<PlusIcon />}
                onClick={() => addAccountsRule()}
            >{lt('addAccountsRule')}</Button>
        </Grid></Grid>)
    }

    ruleset.rpc?.forEach((rpc,i) => {
        rulePanels.push(<RulePanel key={`rpc-panel-${i}`} title={lt('rpc', {i:i+1})}
            regexInputs={{
                [lt('rpcMethod')]: {desc: lt('rpcMethodDesc'), val: rpc.method || '', setter: val => setRPCValue(i, 'method', val)},
            }}
            toggles={{
                [lt('rpcAllow')]: {desc: lt('rpcAllowDesc'), checked: rpc.allow, setter: checked => setRPCValue(i, 'allow', checked)},
            }}
            handleDelete={() => deleteRPCMatchingRule(i)}
        />)
    });
    
    rulePanels.push(<Grid key="add-rpc-rule" item container><Grid item>
        <Button
            data-test="button_addRPCRule"
            variant="text"
            color="primary"
            startIcon={<PlusIcon />}
            onClick={() => addRPCMatchingRule()}
        >{lt('addRPCRule')}</Button>
    </Grid></Grid>)

    const content =
        <Grid container direction="column" spacing={2} >
            <Grid item container  spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">{lt('ruleset', {ruleset_id})}</Typography>
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        label={lt('comment')}
                        value={ruleset.comment || ''}
                        variant="outlined"
                        onChange={event => setRulesetComment(event.target.value)}/>
                </Grid>
            </Grid>
            {rulePanels}
            <Grid item container spacing={2} xs={12}>
                <Grid item xs>
                    <Grid item container direction="column">
                        <Grid item><Typography variant="body1" color="textPrimary">{lt('templated')}</Typography></Grid>
                        <Grid item><Typography variant="body2" color="textSecondary">{lt('templatedDesc')}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Switch
                        color="primary"
                        checked={ruleset.templated ? true : false}                        
                        onChange={event => setRulesetTemplated(event.target.checked)}/>
                </Grid>
            </Grid>
        </Grid>


    return(
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge}/>
            <CreateWrapper cancelPath={cancelPath} disabled={loading} {...{content}} {...{onNext}} isFirstStep isLastStep/>
            <Help 
                imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
                header={lt('helpHeader')}
                description={lt('helpDescription')}
                docLink="https://docs.kaleido.io/kaleido-services/baf/rulesets/"
                bullet1={lt('bulletHelp1')}
                bullet2={lt('bulletHelp2')}
                bullet3={lt('bulletHelp3')}
            />
        </>
    )
}

interface translations {
    header: string,
    selectAvailableRuntimes: string,
    runtime: string,
    noRuntimeName: string,
    noRuntimes: string,
    idRegistry: string,
    ruleset: string,
    chain: string,
    accounts: string,
    transactions: string,
    rpc: string,
    addTXRule: string,
    addRPCRule: string,
    addChainRule: string,
    addAccountsRule: string,
    regex: string,
    comment: string,
    templated: string,
    templatedDesc: string,

    txFrom: string,
    txFromDesc: string,
    txTo: string,
    txToDesc: string,
    txCall: string,
    txCallDesc: string,
    txEstimate: string,
    txEstimateDesc: string,
    txSend: string,
    txSendDesc: string,
    txSendRaw: string,
    txSendRawDesc: string,
    txDeploy: string,
    txDeployDesc: string,

    chainInfo: string,
    chainInfoDesc: string,
    chainReceipts: string,
    chainReceiptsDesc: string,
    chainPending: string,
    chainPendingDesc: string,
    chainBlocks: string,
    chainBlocksDesc: string,
    chainTransactions: string,
    chainTransactionsDesc: string,
    chainFilter: string,
    chainFilterDesc: string,
    chainSubscribe: string,
    chainSubscribeDesc: string,

    accountsCoinbase: string,
    accountsCoinbaseDesc: string,
    accountsNonce: string,
    accountsNonceDesc: string,
    accountsBalance: string,
    accountsBalanceDesc: string,
    accountsStorage: string,
    accountsStorageDesc: string,
    accountsList: string,
    accountsListDesc: string,
    accountsSign: string,
    accountsSignDesc: string,

    rpcMethod: string,
    rpcMethodDesc: string,
    rpcAllow: string,
    rpcAllowDesc: string,

    helpHeader: string,
    helpDescription: string,
    bulletHelp1: string,
    bulletHelp2: string,
    bulletHelp3: string,
}
const enTranslations: translations = {
    header: 'Attach Config to runtime',
    selectAvailableRuntimes: 'Select Available Runtimes',
    runtime: "Runtime",
    noRuntimeName: "No Name Provided: {{id}}",
    noRuntimes: 'No more runtimes available',
    idRegistry: 'Id Registry',
    ruleset: 'Ruleset "{{ruleset_id}}"',
    chain: "Chain operations",
    accounts: "Account operations",
    transactions: "Transaction operations (match rule {{i}})",
    rpc: "Additional JSON/RPC operations (match rule {{i}})",
    addTXRule: "Add Transaction Matching Rule",
    addChainRule: "Add Chain Rule",
    addAccountsRule: "Add Accounts Rule",
    addRPCRule: "Add JSON/RPC Operation Matching Rule",
    regex: "Case-insensitive regex",
    comment: "Description",
    templated: "Templated",
    templatedDesc: "Allow templating in match expressions from authenticated claims",

    txFrom: "From Address",
    txFromDesc: "Match signing addresses, without 0x prefix",
    txTo: "To Address",
    txToDesc: "Match target addresses, empty for deploy",
    txCall: "Call",
    txCallDesc: "Read-only invocation of matching smart contracts to query data",
    txEstimate: "Estimate",
    txEstimateDesc: "Test execution of matching smart contracts to esimtate gas cost",
    txSend: "Send",
    txSendDesc: "Send transactions for managed signing",
    txSendRaw: "Send Raw",
    txSendRawDesc: "Send pre-signed transactions",
    txDeploy: "Deploy",
    txDeployDesc: "Deploy new smart contract instances",

    chainInfo: "Info",
    chainInfoDesc: "Get basic chain information like the networkId",
    chainReceipts: "Receipts",
    chainReceiptsDesc: "Poll the chain for receipts for individual transactions",
    chainPending: "Pending",
    chainPendingDesc: "Query for pending transactions",
    chainBlocks: "Blocks",
    chainBlocksDesc: "Query historical block information",
    chainTransactions: "Transactions",
    chainTransactionsDesc: "Query historical transaction information",
    chainFilter: "Filter",
    chainFilterDesc: "Query for chain data updates using filters",
    chainSubscribe: "Subscribe",
    chainSubscribeDesc: "Subscribe to be pushed updates on chain data",

    accountsCoinbase: "Coinbase",
    accountsCoinbaseDesc: "Query the 'coinbase' address of the node",
    accountsNonce: "Nonce",
    accountsNonceDesc: "Query the transaction count of an account",
    accountsBalance: "Balance",
    accountsBalanceDesc: "Query the ether balance of an account",
    accountsStorage: "Storage",
    accountsStorageDesc: "Query the storage owned by an address or smart contract",
    accountsList: "List",
    accountsListDesc: "List the accounts available on the node for signing",
    accountsSign: "Sign",
    accountsSignDesc: "Sign arbitrary data using accounts on the node",

    rpcMethod: "Method",
    rpcMethodDesc: "JSON/RPC Method Name",
    rpcAllow: "Allow",
    rpcAllowDesc: "Allow (whitelist) or deny (blacklist) matching RPC method names",

    helpHeader: "Application Firewall Ruleset",
    helpDescription: "A ruleset defines a set of permissions that an authenticated connection has against a blockchain node.",
    bulletHelp1: "Transaction rules sub-match against the from and to addresses of individual transactions to permission sending transactions",
    bulletHelp2: "Chain and Account rules govern other common commands that applications use, such as querying the 'nonce' (or transaction count) for a signing address",
    bulletHelp3: "RPC rules whitelist or blacklist JSON/RPC commands by name",
}
