import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const PrecompiledPasteExistingContract = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'PrecompiledPasteExistingContractHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`PrecompiledPasteExistingContractHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            finalInfo={lt('finalInfo')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'Provide your contract\'s ABI',
    helpDescription: 'We have prefilled the bytecode for you as this contract has already been deployed. You will however, need to provide the ABI for this contract. It\'s important that this ABI is accurate as it\'s needed to generate the correct REST Gateway API.',
    finalInfo: 'If this is a token contract we have pre-filled the ABI with our best guess ABI based on the metadata we have stored for this contract. Please double check it and overwrite it where necessary.'
}