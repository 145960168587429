import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers';

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateDestinationsStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateDestinationsStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/Node_Settings.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/using-kaleido/environments"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Destinations Setup',
    helpDescription: 'In order to create a destination you need the following pre requisites:',
    helpDesc1: 'Provision On Chain Registry Service',
    helpDesc2: 'Have a certificate mapped to your membership',
    helpDesc3: 'Register that certificate on chain.',
}