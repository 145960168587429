import { useQuery } from "@apollo/client";
import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { EmptyState, Kpi } from "../../components/DisplayWrappers";
import { EnvironmentResourceVars, ServiceResourcesVars, ServiceStatusResourcesVars, EnvironmentResourcesVars } from "../../interfaces";
import { ServiceQuery, ServiceData, NodesData, NodesQuery } from "../../models";
import { RotatesignersConfigurationData, RotatesignersStatusData, RotatesignersStatusQuery, RotatesignersConfigurationQuery, RotatesignersCurrentSignersData, RotatesignersCurrentSignersQuery, RotatesignersNextSignersData, RotatesignersNextSignersQuery } from "../../models/rotatesigners";
import { ROTATE_SIGNERS_MANAGE_PATH } from "../../components/ServicesNav/Services/RotateSignersItems";
import GamepadCircleOutlineIcon from 'mdi-react/GamepadCircleOutlineIcon';
import GamepadCircleUpIcon from 'mdi-react/GamepadCircleUpIcon';
import RecordCircleOutlineIcon from 'mdi-react/RecordCircleOutlineIcon';
import TimerOutlineIcon from 'mdi-react/TimerOutlineIcon';
import GoogleCirclesCommunitiesIcon from 'mdi-react/GoogleCirclesCommunitiesIcon';
import { SignersTable } from "./SignersTable";

const POLL_INTERVAL = 30000

export const Overview = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "RotateSignersOverview", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`RotateSignersOverview:${key}`, interpolate);

    const { consortium_id, environment_id, service_id } = useParams<any>();
    const history = useHistory();

    const basePath = history.location.pathname

    const {
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: { 
            consortia_id: consortium_id,
            environment_id: environment_id
        },
        fetchPolicy: 'cache-only'
    });

    const { data: { service } = { service: null } } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: { consortia_id: consortium_id, environment_id, id: service_id },
        fetchPolicy: "cache-only",
    });

    const { 
        loading: configurationLoading,
        data: { 
            rotatesignersConfiguration 
        } = { rotatesignersConfiguration: null } 
    } = useQuery<RotatesignersConfigurationData, ServiceResourcesVars>(RotatesignersConfigurationQuery, {
        variables: { service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const { 
        loading: statusLoading,
        data: { 
            rotatesignersStatus 
        } = { rotatesignersStatus: null } 
    } = useQuery<RotatesignersStatusData, ServiceStatusResourcesVars>(RotatesignersStatusQuery, {
        variables: { consortia_id: consortium_id, environment_id, service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const { 
        loading: rotatesignersCurrentSignersLoading,
        data: { 
            rotatesignersCurrentSigners 
        } = { rotatesignersCurrentSigners: null } 
    } = useQuery<RotatesignersCurrentSignersData, ServiceResourcesVars>(RotatesignersCurrentSignersQuery, {
        variables: { service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const { 
        loading: rotatesignersNextSignersLoading,
        data: { 
            rotatesignersNextSigners 
        } = { rotatesignersNextSigners: null } 
    } = useQuery<RotatesignersNextSignersData, ServiceResourcesVars>(RotatesignersNextSignersQuery, {
        variables: { service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const loading = (configurationLoading || statusLoading || rotatesignersCurrentSignersLoading || rotatesignersNextSignersLoading) && rotatesignersConfiguration == null

    const showContent = !loading && service?.state === 'started' && rotatesignersStatus?.isActivated

    const kpiData = [
        {
            key: 'kpi-targetSigners',
            value: rotatesignersConfiguration?.targetSignerCount || '',
            label: lt('targetSigners'),
            icon: <GamepadCircleOutlineIcon />
        },
        {
            key: 'kpi-totalSigners',
            value: nodes.filter(n => n.init_consensus_role === 'signer').length,
            label: lt('totalSigners'),
            icon: <GamepadCircleUpIcon />
        },
        {
            key: 'kpi-rotationSize',
            value: rotatesignersConfiguration?.rotationSize || '',
            label: lt('rotationSize'),
            icon: <RecordCircleOutlineIcon />
        },
        {
            key: 'kpi-intervalTime',
            value: `${rotatesignersConfiguration?.rotationInterval || ''} ${lt('minutes')}`,
            label: lt('intervalTime'),
            icon: <TimerOutlineIcon />
        },
        {
            key: 'kpi-algorithm',
            value: rotatesignersConfiguration?.rotationAlgorithm === 'oldest-first' ? lt('roundRobin') : '' || '',
            label: lt('rotationAlgorithm'),
            icon: <GoogleCirclesCommunitiesIcon />
        },
    ]

    const content = (
        <Grid item container direction='column' spacing={3}>
            <Grid item>
                <Kpi data={kpiData} />  
            </Grid>
            <Grid item>
                <SignersTable {...{nodes}} {...{rotatesignersCurrentSigners}} {...{rotatesignersNextSigners}} />
            </Grid>
        </Grid>
    )

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container wrap="nowrap" justify="space-between" alignItems='center'>
                <Grid item>
                    <Typography variant="h5">
                        {lt("header")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button size="large" variant="contained" color="primary" onClick= {() => history.push(`${basePath}/${ROTATE_SIGNERS_MANAGE_PATH}`) }>
                        {lt('emptyGoTo')}
                    </Button>
                </Grid>
            </Grid>

            <Grid item>
                {loading && <CircularProgress />}
                {showContent && content}
                {!showContent && !loading && (
                    <EmptyState imageFile='Empty-RotateSigners.svg' 
                        title={lt('emptyTitle')} 
                        description={lt('emptyDescription')} 
                        button={{ text: lt('emptyGoTo'), onClick: () => history.push(`${basePath}/${ROTATE_SIGNERS_MANAGE_PATH}`) }} />
                )}
            </Grid>
        </Grid>
    );
};

interface translations {
    header: string;
    status: string;
    emptyTitle: string
    emptyDescription: string
    emptyGoTo: string
    targetSigners: string
    totalSigners: string
    rotationSize: string
    intervalTime: string
    rotationAlgorithm: string
    roundRobin: string
    minutes: string
}

const enTranslations: translations = {
    status: "Status",
    header: "Dashboard",
    emptyTitle: 'Setup Rotating Signers',
    emptyDescription: 'Scale IBFT consensus algorithm to allow more than 16 signing nodes to achieve equitable participation in the voting and block signing processes.',
    emptyGoTo: 'Manage',
    targetSigners: 'Target Signers',
    totalSigners: 'Total Signers',
    rotationSize: 'Node Rotation Size',
    intervalTime: 'Rotation Interval',
    rotationAlgorithm: 'Rotation Algorithm',
    roundRobin: 'Round Robin',
    minutes: 'mins',
};
