import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { FormDialog } from '../../components/DialogWrappers';
import { Organization, LeaveOrgMutation, OrganizationVars, LeaveOrgData } from '../../models';
import { Alert } from '../../components/FormControls/Alert';
import { cognitoController } from '../../utils/cognitoController';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    org: Organization
}

export const LeaveOrg: React.FC<Props> = ({ open, setOpen, org }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LeaveOrg', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LeaveOrg:${key}`, interpolate);
    const [leaveOrg] = useMutation<LeaveOrgData, OrganizationVars>(LeaveOrgMutation);

    const handleLeaveOrg = () => {
        return leaveOrg({ variables: { id: org._id } }).then(async() => {
            await cognitoController.logout('/login');
        });
    }

    const controlsWrapper = (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography>{lt('message', { orgName: org.name })}</Typography>
            </Grid>
            <Grid item>
                <Alert severity="warning" title={lt('warningTitle')} description={lt('warningDescription')} />
            </Grid>
        </Grid>
    );

    return (
        <FormDialog open={open} setOpen={setOpen} header={lt('leaveOrg')} closeDialogAfterSave={false}
            saveText={lt('leave')} controlsWrapper={controlsWrapper} onSave={handleLeaveOrg} />
    );
}

interface translations {
    leaveOrg: string
    leave: string
    message: string
    warningTitle: string
    warningDescription: string
}

const enTranslations: translations = {
    leaveOrg: 'Leave Org',
    leave: 'Leave',
    message: 'Are you sure you want to leave the org {{orgName}}?',
    warningTitle: 'New session required',
    warningDescription: 'Leaving the org will cause the current session to end.'
}