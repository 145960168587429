import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
import { AppCredData, AppCredQuery, NodesData, NodesQuery, ServicesData, ServicesQuery, ServicesTranslations, EnServicesTranslations, ServicesEnum,
        TunnelerConfigData, TunnelerConfigVars, TunnelerConfigQuery } from '../../../models'
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { CopyableSettings, CopyableSetting, EditableSettings } from '../../../components/DisplaySettings'
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import { SECURITY_APPCREDS_PATH, SECURITY_BASE_PATH } from '../../../components//MainNav/SideNavs/Security'
import AutorenewIcon from 'mdi-react/AutorenewIcon';
import { UpdateName, DeleteResource } from '../../../components/DialogWrappers'
import { Regenerate } from './Regenerate'
import { EnvironmentResourceVars, EnvironmentResourcesVars, LinkButtonProps } from '../../../interfaces'
import { DisplayGridWrapper } from '../../../components/DisplayWrappers';
import { RuntimeSelector } from '../../EnvironmentHealth/RuntimeSelector';
import { ConnectList } from '../../../components/ConnectRuntime/ConnectList';
import queryString from 'query-string';
import { AlertBanner } from '../../../components/Banners/AlertBanner';
import fileDownload from 'js-file-download'
import { NODE_CONTRACTS_PATH, NODE_CORDAPPS_PATH } from '../../../components/NodeNav/NodeNav';
import { Alert } from '../../../components/FormControls/Alert';
import { getUIAppCredName } from '../../../components/CookieAppCred/CookieAppCred';

export const AppCred: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsSecurityAppCred', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`AppsSecurityAppCred:${key}`, interpolate), [t])

    const history = useHistory()
    const { pathname, search } = useLocation()
    const { org_id, consortium_id, environment_id, appcred_id } = useParams<any>();

    const client = useApolloClient()

    const { runtime_id } = queryString.parse(search);

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [regenerateDialogOpen, setRegenerateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const baseAppCredsPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_APPCREDS_PATH}`

    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const appCredVars = {
        ...environmentVars,
        id: appcred_id!
    }

    const {
        loading,
        data: {
            appCred
        } = { appCred: null }
    } = useQuery<AppCredData, EnvironmentResourceVars>(AppCredQuery, { 
        variables: appCredVars, 
        fetchPolicy: 'cache-only' 
    });

    // query the runtimes
    const { 
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });    

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const allRuntimes = useMemo(() => {
        return nodes.filter(n => n.membership_id === appCred?.membership_id).map(n => 
            ({ _id: n._id, name: `${n.name || '--'} (${lt('node')})` })
        ).concat(services.filter(s => s.membership_id === appCred?.membership_id).map(s => 
            ({ _id: s._id, name: `${s.name || '--'} (${lt(ServicesEnum[s.service])})` })
        ))
    }, [nodes, services, appCred, lt])

    const defaultRuntimeId = allRuntimes.find((r) => r._id === runtime_id)?._id ?? allRuntimes.find(() => true)?._id ?? ''
    const [selectedRuntimeIds, setSelectedRuntimeIds] = useState<string[]>([defaultRuntimeId]);
    const _id = selectedRuntimeIds[0]
    const node = nodes.find(n => n._id === _id)
    const service = services.find(s => s._id === _id)

    const [getTunnelerConfig, {
        loading: tunnelerConfigLoading,
        data: {
            tunnelerConfig
        } = { tunnelerConfig: '' }
    }] = useLazyQuery<TunnelerConfigData, TunnelerConfigVars>(TunnelerConfigQuery, { 
        variables: {
            consortia_id: consortium_id!, environment_id: environment_id!, service_id: service?._id!
        }, 
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (selectedRuntimeIds.length) {
            if (runtime_id) {
                history.replace(search.replace(runtime_id as string, selectedRuntimeIds[0]))
            } else {
                history.replace(`${pathname}?runtime_id=${selectedRuntimeIds[0]}`)   
            }
        }
    }, [selectedRuntimeIds, runtime_id, history, search, pathname]);


    // we only show the password one time - clear it from the cache on unmount
    useEffect(() => {
        if (appCred?.password) {
            return () => {
                client.cache.modify({ 
                    id:`AppCred:${appCred._id}`,
                    fields: {
                        password() { return '' } 
                    }
                })
            }
        }
    }, [client, appCred])

    useEffect(() => {
        if (tunnelerConfig) {
            let tunnelerConfigJSON = JSON.parse(tunnelerConfig);
            let auth = tunnelerConfigJSON?.nodes[0]?.auth;
            if (auth && appCred) {
                auth.user = appCred._id;
                auth.secret = appCred.password;
            }
            fileDownload(JSON.stringify(tunnelerConfigJSON, null, 2), `${environment_id}-${service?._id}-client.json`);
        }
    }, [tunnelerConfig, environment_id, service, appCred]);

    // if the appCred was deleted, then you need to get kicked out of the detail view.
    if (!loading && !appCred) return (<Redirect to={baseAppCredsPath} />)

    const uiAppCredName = getUIAppCredName(environment_id, appCred?.membership_id || '');

    const isUIAppCred = uiAppCredName === appCred?.name;

    const actionsList = [
        {
            icon: <AccountCircleIcon />,
            title: lt('changeName'),
            buttonLabel: lt('change'),
            value: appCred?.name,
            action: () => setUpdateNameDialogOpen(true),
            disabledButton: isUIAppCred
        },
        {
            icon: <AutorenewIcon />,
            title: lt('regenerate'),
            buttonLabel: lt('regenerateLabel'),
            action: () => setRegenerateDialogOpen(true),
            disabledButton: isUIAppCred
        },
        {
            icon: <DeleteOutlineIcon />,
            title: lt('delete'),
            buttonLabel: lt('deleteLabel'),
            action: () => setDeleteDialogOpen(true),
            disabledButton: isUIAppCred
        }
    ]

    const copyableList: CopyableSetting[] = [
        {
            title: lt('id'),
            displayValue: appCred?._id ?? '',
        }
    ]

    if (appCred?.password) {
        copyableList.push({
            title: lt('password'),
            displayValue: appCred.password,
        })
        copyableList.push({
            title: lt('basicAuthFormat'),
            displayValue: `${appCred._id}:${appCred.password}`,
        })
        copyableList.push({
            title: lt('authorizationHeader'),
            displayValue: `Authorization: Basic ${window.btoa(`${appCred._id}:${appCred.password}`)}`,
        })

        // remove regenerate option for newly generated app creds where the password is in the cache
        actionsList.splice(actionsList.findIndex(a => a.title === lt('regenerate')), 1)
    }

    copyableList.push({ 
        disableCopy: true, 
        title: lt('owner'), displayValue: appCred?.membership.name ?? '' 
    })
    copyableList.push({ 
        disableCopy: true, 
        title: lt('createdAt'), displayValue: appCred?.created_at ? new Date(appCred?.created_at).toLocaleString() : '' 
    })

    const content = (
        <Grid item container direction="row" spacing={3}>
            <Grid item sm={12}>
                <CopyableSettings header={lt('appCredInformation')} {...{copyableList}} />
            </Grid>
            <Grid item sm={12}>
                <EditableSettings
                    header={lt('appCredSettings')}
                    description={lt('appCredSettingsDesc')}
                    {...{actionsList}} />
            </Grid>
        </Grid>
    )

    const connectRuntime = allRuntimes.length > 0 ? (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <RuntimeSelector {...{allRuntimes}} {...{selectedRuntimeIds}} {...{setSelectedRuntimeIds}} disableMultiSelect refreshIsDisabled={false} />
            </Grid>
            <Grid item>
                <ConnectList maxHeight='disable' {...{node}} {...{service}} appcredId={appCred?._id} appcredSecret={appCred?.password}/>
            </Grid>
        </Grid>
    ) : (
        <Grid item container>
            <Typography variant="body2">
                {lt('noRuntimesExist')}
            </Typography>
        </Grid>
    )

    const getLinkButton = (): (LinkButtonProps | undefined) => {
        if (node) {
            const isCorda = node?.provider.includes('corda');
            return {
                text: isCorda ? lt('newCorDapp') : lt('newGatewayAPI'),
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node._id}/${isCorda ? NODE_CORDAPPS_PATH : NODE_CONTRACTS_PATH}/create/1`),
                color: 'primary',
                variant: 'contained',
                disabled: node.state !== 'started'
            }
        }
        if (service?.service === 'tunneler') {
            return {
                text: lt('tunnelerConfig'),
                onClick: getTunnelerConfig,
                color: 'primary',
                disabled: tunnelerConfigLoading,
                variant: "contained"
            }
        }
        return undefined
    }

    const getLinkButton2 = (): (LinkButtonProps | undefined) => {
        if (service?.service === 'tunneler') {
            return {
                text: lt('tunnelerDownload'),
                onClick: () => window.open('https://downloads.kaleido.io/kldbridge/latest'),
                color: 'primary',
                variant: "contained"
            }
        }
    }

    return (
        <>
            <UpdateName defaultName={appCred?.name ?? ''} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <Regenerate name={appCred?.name ?? ''} open={regenerateDialogOpen} setOpen={setRegenerateDialogOpen} />
            <DeleteResource disableNameConfirmation name={appCred?.name ?? ''} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />

            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('appCredDetails')}
                        </Typography>
                    </Grid>
                </Grid>

                {appCred?.password 
                ?   <Grid item>
                        <AlertBanner description={lt('firstTime')} />
                    </Grid>
                :   <Grid item>
                        { isUIAppCred ? 
                            <Alert severity="warning" title={lt('warning')} description={lt('uiAppCredInfo')} /> : 
                            <Alert severity="info" title={lt('info')} description={lt('regenToSee')} />
                        }
                    </Grid>
                }

                <Grid item container direction="row" spacing={3}>
                    <Grid item container direction="column" xs={12} md={8} spacing={3}>
                        {loading && !appCred ? <CircularProgress /> : content }
                    </Grid>
                    <Grid item container direction="column" xs={12} md={4}>
                        <DisplayGridWrapper padDisplayGrid header={lt('connectRuntime')} description={lt('connectRuntimeDescription')} displayGrid={connectRuntime} linkButton={getLinkButton()} linkButton2={getLinkButton2()}/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends ServicesTranslations {
    appCredDetails: string,
    appCredInformation: string,
    createdAt: string,
    owner: string,
    id: string,
    password: string,
    basicAuthFormat: string,
    firstTime: string,
    changeName: string,
    delete: string,
    appCredSettings: string,
    appCredSettingsDesc: string,
    regenerate: string,
    connectRuntime: string,
    connectRuntimeDescription: string,
    node: string,
    noRuntimesExist: string,
    authorizationHeader: string,
    change: string,
    regenerateLabel: string,
    deleteLabel: string,
    tunnelerConfig: string,
    newGatewayAPI: string,
    newCorDapp: string,
    info: string,
    regenToSee: string,
    tunnelerDownload: string,
    uiAppCredInfo: string,
    warning: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    warning: 'Warning',
    uiAppCredInfo: 'This App Credential is used by the Kaleido Console and is not meant to be used by external applications.',
    appCredDetails: 'App Cred Details',
    appCredInformation: 'App Cred information',
    createdAt: 'Created at',
    owner: 'Owner',
    id: 'ID',
    password: 'Password',
    basicAuthFormat: 'Basic auth',
    firstTime: 'We do not store the credential password. This is the only time the password will be displayed. Copy it somewhere safe for later use.',
    changeName: 'Change app cred name',
    delete: 'Delete app cred',
    appCredSettings: 'App Cred settings',
    appCredSettingsDesc: 'You can rename this app cred, as well as regenerate the password in case you\'ve forgotten or misplaced it. You can also permanently delete the app cred which will terminate access to any application that was using it to connect to your runtime.',
    regenerate: 'Regenerate password',
    connectRuntime: 'Connect runtime',
    connectRuntimeDescription: 'Select a runtime to view it\'s connection endpoints with this application credential embedded in the URL.',
    node: 'Node',
    noRuntimesExist: 'No runtimes are owned by this App Cred\'s membership.',
    authorizationHeader: 'Authorization header',
    change: 'Change',
    regenerateLabel: 'Regenerate',
    deleteLabel: 'Delete',
    tunnelerConfig: 'Download Config',
    tunnelerDownload: 'Download Network Bridge',
    newGatewayAPI: 'Import Smart Contract',
    newCorDapp: 'Add CorDapp',
    info: 'Info',
    regenToSee: 'We cannot show you the secret for this App Cred unless you regenerate it',
}