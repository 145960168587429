import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { SECURITY_OAUTH_CONFIGURATION_PATH } from '../../../components/MainNav/SideNavs/Security';
import { SECURITY_BASE_PATH } from '../../../components/MainNav/SideNavs/Security';


export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOauthConfiguration', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOauthConfiguration:${key}`, interpolate)

    type locationState = { name: string, membershipId: string, consortiumZoneId: string }
    const { org_id, consortium_id, environment_id, step } = useParams<any>();
    const history = useHistory<locationState>();

    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_OAUTH_CONFIGURATION_PATH}`;

    const { 
        location: { 
            state: {
                name: serviceName,
                membershipId: serviceMembershipId,
            } = { name: '',  membershipId: ''} 
        }
    } = history;


    const [name, setName] = useState((step && step === "2") ? serviceName as string : '');
    const [membershipId, setMembershipId] = useState((step && step === "2") ? serviceMembershipId as string : '');

    const invalidStep = step !== "1" && step !== "2";
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || (createStep >= 1 && (!serviceName || !serviceMembershipId))) return <Redirect to={`${cancelPath}/create/1`} />

    const stepComponents = [
        { step: lt('createWallet'), component: <Step1 {...{cancelPath}} {...{name}} {...{setName}} {...{membershipId}} {...{setMembershipId}} />},
        { step: lt('selectType'), component: <Step2  {...{name}} {...{membershipId}} {...{cancelPath}} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createWallet')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createWallet: string,
    description: string
    selectType: string
}
const enTranslations: translations = {
    createWallet: 'Create OAuth Configuration',
    description: '',
    selectType: 'Select Type'
}
