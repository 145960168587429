import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
    ConfigData,
    ConfigQuery,
    NodeConfigsDetailsTranslationsInterface,
    NodeConfigsDetailsTranslations,
    FullNodeConfigurationDetails,
} from "../../models/configs";
import { ConsortiumResourceVars, EnvironmentResourceVars } from "../../interfaces";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import {
    CopyableSetting,
    EditableSettings,
    EditableSettingItem,
} from "../../components/DisplaySettings";
import { ConfigsAttachedTable } from "../Configurations/ConfigsAttachedTable";
import { Alert } from "../../components/FormControls/Alert";
import { BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import { EnvironmentData, EnvironmentQuery } from "../../models";
import { buildProtocolConfigPropertiesList } from "../Configurations/TypeUtils";

export const ProtocolConfigurationInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CloudConfigInfo", enTranslations);
    const lt = (key: keyof translations) => t(`CloudConfigInfo:${key}`);

    const { org_id, consortium_id, environment_id, config_id } = useParams<any>();
    const history = useHistory();
    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}`;

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });


    const {
        loading: configsLoading,
        data: { config } = { config: null },
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: config_id!,
        },
    });

    const [alertText, setAlertText] = useState("");

    if (configsLoading) return <CircularProgress />;
    if (!config) return <Redirect to={basePath} />;

    const buildInfoList = (data: FullNodeConfigurationDetails) => {
        return buildProtocolConfigPropertiesList((key: string) => t(`CloudConfigInfo:${key}`), environment, data)
    }

    const infoList: CopyableSetting[] = [
        {
            title: lt("name"),
            displayValue: config.name ?? "",
            disableCopy: true,
        },
        {
            title: lt("id"),
            displayValue: config._id ?? "",
        },
        {
            title: lt("type"),
            displayValue: config.type ?? "",
            disableCopy: true,
        },
        {
            title: lt("membership"),
            displayValue: config.membership.name ?? "",
            disableCopy: true,
        },
        {
            title: lt("dateCreated"),
            displayValue:
                new Date(config.created_at || "").toLocaleString() ?? "",
            disableCopy: true,
        },
    ];

    infoList.push(...buildInfoList(config.details));

    const editableList: EditableSettingItem[] = [{
        title: lt('configDetails'),
        icon: <AccountGroupIcon />,
        action: () => history.push(`${basePath}/update/${config_id}/1`),
        copyableSettings: infoList
    }]

    return (
        <>
            <Grid
                container
                direction="column"
                spacing={3}
                alignItems="stretch"
                alignContent="stretch"
            >
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h5">{lt("configInfo")}</Typography>
                    </Grid>
                </Grid>
                {alertText && (
                    <Grid item>
                        <Alert
                            severity="info"
                            title={lt("info")}
                            description={alertText}
                        />
                    </Grid>
                )}
                <Grid item>
                    <EditableSettings
                        actionsList={editableList}
                    />
                </Grid>
                <Grid item>
                    <ConfigsAttachedTable setAlert={setAlertText} />
                </Grid>
            </Grid>
        </>
    );
};

interface translations extends NodeConfigsDetailsTranslationsInterface {
    configInfo: string;
    configDetails: string;
    info: string;
    name: string;
    membership: string;
    type: string;
    id: string;
    dateCreated: string;
    edit: string
}

const enTranslations: translations = {
    ...NodeConfigsDetailsTranslations,
    configInfo: "Configuration Information",
    configDetails: "Config Details",
    info: "Info",
    name: "Name",
    type: "Config Type",
    membership: "Membership",
    id: "Id",
    dateCreated: "Date Created",
    edit: 'Edit'
};
