import { useQuery } from "@apollo/client";
import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GatewayAPIsTable } from '../../components/GatewayAPIs/GatewayAPIsTable';
import { ConsortiumResourceVars } from "../../interfaces";
import { EnvironmentData, EnvironmentQuery } from "../../models";

export const GatewayAPIs: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GatewayAPIs', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GatewayAPIs:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id } = useParams<any>();

    const [createContractsPath, setCreateContractsPath] = useState('');

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;

    const onRowClick = (gatewayAPIId: string) => history.push(`${pathname}/${gatewayAPIId}`)
    
    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {isCorda ? lt('corDapps') : lt('smartContracts')}
                        </Typography>
                    </Grid>
                    {(createContractsPath && !isCorda) && 
                    <Grid item>
                        <Button color="primary" variant="contained" size="large" onClick={() => history.push(createContractsPath, { cancelPath: pathname })}>
                            {lt('newGatewayAPI')}
                        </Button>
                    </Grid>
                    }
                </Grid>
                <Grid item container>
                    <GatewayAPIsTable {...{setCreateContractsPath}} {...{isCorda}} header={isCorda ? lt('corDapps') : lt('smartContracts')} description={lt('description', {appType: isCorda ? lt('corDapps') : lt('smartContracts').toLowerCase()})} {...{onRowClick}} />
                </Grid> 
            </Grid>
        </>
    )
};

interface translations {
    smartContracts: string,
    corDapps: string,
    description: string,
    newGatewayAPI: string
}
const enTranslations: translations = {
    smartContracts: 'Smart Contracts',
    corDapps: 'CorDapps',
    description: 'The following {{appType}} have been promoted to this environment.',
    newGatewayAPI: 'Add New'
}