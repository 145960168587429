import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers'
import { CreateStepProps } from '../../interfaces';
import { ConsortiumZone, ZoneRegion, CreateConsortiumZoneData, CreateConsortiumZoneVars, 
    CreateConsortiumZoneMutation, MakeConsortiumZoneCreateMutationOptions } from '../../models';
import { AddRegions } from '../../components/Regions/AddRegions';
import { useMutation, FetchResult } from '@apollo/client';
import { AddRegionsHelp } from '../../components/Regions/AddRegionsHelp';

interface Props extends CreateStepProps {
    loading: boolean
    consortiumZones: ConsortiumZone[]
    regions: ZoneRegion[]
};

export const Step1 = ({ loading, consortiumZones, regions, cancelPath }: Props) => {
    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const [message, setMessage] = useState('')

    const [createConsortiumZone, { loading: createConsortiumZoneLoading }] = 
        useMutation<CreateConsortiumZoneData, CreateConsortiumZoneVars>(CreateConsortiumZoneMutation)

    const save = () => {
        // create the additional regions if we dont already have them 
        const zonePromises = [] as Promise<FetchResult<CreateConsortiumZoneData>>[];

        additionalRegions.filter(ar => !consortiumZones.some(z => z.region === ar)).forEach(ar => {
            const { region, cloud } = regions.find(r => r.region === ar)!
            zonePromises.push(createConsortiumZone(MakeConsortiumZoneCreateMutationOptions({
                consortia_id: consortium_id!,
                consortiumZone: {
                    region,
                    cloud: cloud.toLowerCase(),
                    type: 'kaleido'
                }
            })))
        })

        Promise.all(zonePromises).then(() => history.push(`/orgs/${org_id}/consortia/${consortium_id}`)).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
            return null
        })
    }

    const [additionalRegions, setAdditionalRegions] = useState<string[]>(consortiumZones.filter(z => 
        !z.default && !regions.some(r => r.region === z.region)).map(z => z.region))

    const homeRegion = consortiumZones.find(z => z.default)?.region
    const alreadyEnabledRegions = consortiumZones.filter(z => !z.default).map(z => z.region)

    const content = loading ? <CircularProgress /> : (
        <AddRegions {...{homeRegion}} {...{regions}} {...{additionalRegions}} {...{setAdditionalRegions}} {...{alreadyEnabledRegions}} />
    )

    const disabled = createConsortiumZoneLoading || !additionalRegions.length

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} isFirstStep isLastStep />
            <AddRegionsHelp />
        </>
    )
};
