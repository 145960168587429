import React from "react";
import { FullScreenCreate } from "../../../components/DialogWrappers";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH } from "../../../components/MainNav/SideNavs/Blockchain";

type locationState = { name: string; membershipId: string };

export const CreateProtocolConfiguration = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateProtocolConfiguration", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateProtocolConfiguration:${key}`, interpolate);

    const { consortium_id, environment_id, org_id, config_id } = useParams<any>(); // config id for updates
    const history = useHistory<locationState>();

    const {
        location: { state: { name, membershipId } = { name: "", membershipId: "" } },
    } = history;

    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}${config_id ? `/${config_id}` : ''}`;

    const { step } = useParams<any>();

    if (parseInt(step) > 1 && (!name || !membershipId))
        return <Redirect to={`${cancelPath}/create/1`} />;


    const stepComponents = [
        {
            step: lt("setupConfig"),
            component: <Step2 {...{ cancelPath }} />,
        },
    ];

    if (!config_id) {
        stepComponents.unshift({
            step: lt("chooseMembership"),
            component: <Step1 {...{ cancelPath }} />,
        });
    }

    return (
        <FullScreenCreate
            cancelPath={cancelPath}
            toolbarHeader={lt("toolBarHeader")}
            stepUrlParam={step!}
            {...{ stepComponents }}
            hideStepper={!!config_id}
        />
    );
};

interface translations {
    toolBarHeader: string;
    setupConfig: string;
    chooseMembership: string;
}
const enTranslations: translations = {
    toolBarHeader: "Create Protocol Configuration",
    setupConfig: "Set Up Configuration",
    chooseMembership: "Choose Membership and Name",
};
