import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { doUpload, UploadBinary } from '../../components/ContractManagement/UploadBinary';
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { ConsortiumResourcesVars, CreateStepProps } from '../../interfaces';
import { ContractProjectsData, ContractProjectsQuery, CreateContractProjectData, CreateContractProjectMutation, CreateContractProjectVars, MakeContractProjectCreateMutationOptions, PromoteCompiledContractMutation, PromoteCompiledContractVars } from '../../models';
import { CREATE_NEW_PROJECT, SourceCodeType } from './Create';
import { Step1Help } from './Step1Help';

interface Props extends CreateStepProps {
    membershipId: string,
    sourceCodeType: SourceCodeType
    contractId: string
    setContractId: React.Dispatch<React.SetStateAction<string>>
    newProjectName: string,
    setNewProjectName: React.Dispatch<React.SetStateAction<string>>,
};

export const Step1 = ({ sourceCodeType, membershipId, contractId, setContractId, newProjectName, setNewProjectName, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CorDappsContractsCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CorDappsContractsCreateStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();

    const [file, setFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [presaveLoading, setPresaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [compiledContractId, setCompiledContractId] = useState('');
    const [resolvedProjectId, setResolvedProjectId] = useState('');

    const {
        loading : contractsLoading,
        data: {
            contractProjects
        } = { contractProjects: [] }
    } = useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    useEffect(() => {
        setContractId(CREATE_NEW_PROJECT)
    }, [sourceCodeType, contractProjects, setContractId, setNewProjectName, newProjectName])

    // in the preSave create the new app if necessary
    const [createContractProject] = 
        useMutation<CreateContractProjectData, CreateContractProjectVars>(CreateContractProjectMutation)
    const preSave = (!contractId || contractId === CREATE_NEW_PROJECT) ? async () => {
        setPresaveLoading(true)
        return createContractProject(MakeContractProjectCreateMutationOptions({
            consortia_id: consortium_id!,
            contractProject: {
                name: newProjectName,
                membership_id: membershipId,
                type: 'corda_jar',
            }
        })).then(result => {
            if (result) {
                const newProjectId = result.data?.createContractProject?._id
                if (newProjectId) {
                    setContractId(newProjectId)
                    return newProjectId
                }
            }
        }).catch(e => {
            setLoading(false)
            ErrorSnackbarCatcher(e, setErrorMessage)
        });
    } : () => new Promise<string>(function(resolve) {
        setPresaveLoading(true)
        resolve(contractId)
    });

    const save = async () => {
        const projectId = await preSave();
        if (projectId) {
            setResolvedProjectId(projectId);
            doUpload({
                consortium_id,
                contract_id: projectId,
                description: `${file!.name} (${file!.size}B)`,
                membershipId,
                file: file!,
                setLoading: setPresaveLoading,
                setErrorMessage,
                onComplete: setCompiledContractId // triggers postSave
            });
        }
    }

    // in the postSave wait for the contract to be compiled and promote it to our environment
    // or, wait for it to fail and show the error snackbar
    const [promoteCompiledContract, { loading: promoteCompiledContractLoading }] = 
        useMutation<string, PromoteCompiledContractVars>(PromoteCompiledContractMutation)
    useEffect(() => {
        setLoading(promoteCompiledContractLoading || contractsLoading || presaveLoading)
    }, [promoteCompiledContractLoading, contractsLoading, presaveLoading])    
    useEffect(() => {
        if (compiledContractId && resolvedProjectId) {
            promoteCompiledContract({
                variables: {
                    consortia_id: consortium_id!,
                    contract_id: resolvedProjectId,
                    id: compiledContractId,
                    promoteCompiledContract: {
                        endpoint: '',
                        environment_id
                    }
                }
            }).then(() => {
                history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}`)
            }).catch(e => {
                setLoading(false)
                ErrorSnackbarCatcher(e, setErrorMessage)
            })
        }
    }, [resolvedProjectId, compiledContractId, consortium_id, contractId, environment_id, node_id,
        history, newProjectName, org_id, promoteCompiledContract, sourceCodeType ])
    
    const content = contractsLoading ? <CircularProgress /> : (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <UploadBinary {...{loading}} {...{file}} {...{setFile}} accept=".jar"/>

            <Grid item>
                <Typography variant="h5">
                    {lt('selectProject')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('selectProjectDescription')}
                    {sourceCodeType === 'token' && lt('tokenProjectDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField data-test="selectProject" 
                    required select fullWidth label={lt('project')} value={contractId} 
                    variant="outlined" onChange={e => setContractId(e.target.value)}>
                    <MenuItem value={CREATE_NEW_PROJECT}>{lt('createNewProject')}</MenuItem>
                    {contractProjects.filter(c => c.type === 'corda_jar').map(c => (
                        <MenuItem key={c._id} value={c._id}>{c.name}</MenuItem>
                    ))}
                </TextField>
            </Grid>
            {contractId === CREATE_NEW_PROJECT &&
            <Grid item>
                <TextField data-test="textField_projectName" 
                    required
                    value={newProjectName} 
                    onChange={event => setNewProjectName(event.target.value)}
                    fullWidth
                    label={lt('projectName')}
                    variant="outlined"
                />
            </Grid>
            }
        </>
    )

    const disabled = loading || !file;

    return (
        <>
            <MessageSnackbar setMessage={setErrorMessage} message={errorMessage} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} isFirstStep isLastStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string
    headerDescription: string
    upload: string
    uploadDescription: string
    paste: string
    pasteDescription: string
    token: string,
    tokenDescription: string
    github: string,
    githubDescription: string
    selectProject: string
    selectProjectDescription: string
    project: string
    createNewProject: string
    projectName: string
    tokenProjectDescription: string
}
const enTranslations: translations = {
    header: 'Import type',
    headerDescription: 'Select your method of choice for importing a contract.',
    upload: 'Upload file with source code',
    uploadDescription: 'Select this option to upload your Solidity source code as a single file or zip with dependencies',
    paste: 'Paste version metadata',
    pasteDescription: 'Select this option if you\'ve already compiled your code and have the bytecode and ABI handy',
    token: 'Use our Token Factory',
    tokenDescription: 'No coding required. Create fungible and non-fungible token contracts from a template',
    github: 'Import from Github',
    githubDescription: 'Select this option to import your Solidity file from Github (supports Truffle projects!)',
    selectProject: 'Select App',
    selectProjectDescription: 'Select the project where this contract should reside, or, create a new project.',
    project: 'Project',
    createNewProject: 'Create New Project',
    projectName: 'New Project Name',
    tokenProjectDescription: 'Token contracts built from Kaleido templates are stored in the Kaleido Contract Templates project.'
}
