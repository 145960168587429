import React, { useState } from 'react';
import { Grid, Typography, CircularProgress, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { InvoicesData, InvoicesQuery } from '../../models/invoices';
import { DisplayTable, EmptyState } from '../../components/DisplayWrappers';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import ReceiptIcon from 'mdi-react/ReceiptIcon';
import { Dotdotdot } from '../../components';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { BillingProviderData, BillingProviderQuery } from '../../models/billing';
import { OrgResourceVars } from '../../interfaces';

export const Invoices: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Invoices', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Invoices:${key}`, interpolate);
    const { org_id } = useParams<any>();

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarMessageType, setsnackbarMessageType] = useState<'success' | 'error'>('success');

    let {
        loading: loadingBillingProvider,
        data: {
            billingProvider
        } = { billingProvider: { type: 'none' } }
    } = useQuery<BillingProviderData>(BillingProviderQuery, { variables: { org_id } });

    const {
        loading: loadingInvoices,
        data: {
            invoices
        } = { invoices: [] }
    } = useQuery<InvoicesData, OrgResourceVars>(InvoicesQuery,
        {
            variables: { org_id },
            fetchPolicy: 'cache-and-network',
            skip: !['stripe', 'other', 'contract'].includes(billingProvider.type)
        });

    if ((loadingInvoices || loadingBillingProvider) && !invoices) {
        return <CircularProgress />
    }

    const handleDownloadInvoice = async (invoiceId: string) => {
        try {
            setsnackbarMessageType('success');
            setSnackbarMessage(lt('downloadInProgress'));
            const response = await fetch(`/api/ui/v2/billing/invoices/${org_id}/${invoiceId}`);
            const responseBlob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([responseBlob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', lt('invoiceFileName', { invoiceId }));
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (err) {
            setsnackbarMessageType('error');
            setSnackbarMessage(lt('downloadFailure'));
        }
    };

    const columnHeaders = [
        '',
        lt('invoiceNumber'),
        lt('dateOfIssue'),
        ''
    ];

    const records: DisplayTableRecord[] = invoices.map(invoice => ({
        columns: [
            { value: <ReceiptIcon /> },
            { value: invoice.name },
            { value: new Date(invoice.date).toLocaleDateString() },
            {
                value: <Dotdotdot menuItems={[
                    {
                        name: lt('download'),
                        action: () => handleDownloadInvoice(invoice.id)
                    }
                ]} />
            }
        ]
    }));

    const generateContent = () => {
        if (['stripe', 'other', 'contract'].includes(billingProvider.type)) {
            if (records.length > 0) {
                return <DisplayTable header={lt('invoices')} columnHeaders={columnHeaders} records={records} />
            } else {
                return (
                    <Paper>
                        <EmptyState imageFile='Empty-NoResults.svg' title={lt('emptyStateTitle')} description={lt('emptyStateDescription')} />
                    </Paper>
                );
            }
        } else if (['aws', 'azure'].includes(billingProvider.type)) {
            const provider = lt(billingProvider.type as 'aws' | 'azure');
            return (
                <Paper>
                    <EmptyState imageFile='Empty-NoResults.svg' title={lt('awsOrAzureBillingProviderTitle',
                        { provider })} description={lt('awsOrAzureBillingProviderDescription', { provider })} />
                </Paper>
            );
        } else {
            return (
                <Paper>
                    <EmptyState imageFile='Empty-NoResults.svg' title={lt('emptyStateTitle')} description={lt('noBillingProviderSetDescription')} />
                </Paper>
            );
        }
    }

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('invoices')}</Typography>
                </Grid>
                <Grid item>
                    {generateContent()}
                </Grid>
            </Grid>
            <MessageSnackbar message={snackbarMessage} setMessage={setSnackbarMessage} messageType={snackbarMessageType} />
        </>
    );
};

interface translations {
    invoices: string
    invoiceNumber: string
    dateOfIssue: string
    download: string
    invoiceFileName: string
    downloadInProgress: string
    downloadFailure: string
    emptyStateTitle: string
    emptyStateDescription: string
    aws: string
    azure: string
    awsOrAzureBillingProviderTitle: string
    awsOrAzureBillingProviderDescription: string
    noBillingProviderSetDescription: string
}

const enTranslations: translations = {
    invoices: 'Invoices',
    invoiceNumber: 'Invoice Number',
    dateOfIssue: 'Date of Issue',
    download: 'Download',
    invoiceFileName: 'Invoice-{{invoiceId}}.pdf',
    downloadInProgress: 'Download in progress...',
    downloadFailure: 'Failed to download invoice',
    emptyStateTitle: 'There are currently no invoices',
    emptyStateDescription: 'Please check back after the next billing cycle.',
    aws: 'Amazon Web Services (AWS)',
    azure: 'Microsoft Azure',
    awsOrAzureBillingProviderTitle: 'Your billing provider is set to {{provider}}',
    awsOrAzureBillingProviderDescription: 'Please login to the {{provider}} console to view invoices.',
    noBillingProviderSetDescription: 'No billing provider has been set'
}
