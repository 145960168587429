import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';
import { EmptyState } from '../../components/DisplayWrappers';
import { Invitation } from '../../models';

interface Props {
    invitation?: Invitation,
};

export const Step2Success = ({ invitation }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ViewInvitationsStep2Success', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ViewInvitationsStep2Success:${key}`, interpolate)

    const history = useHistory()
    const { org_id } = useParams<any>();

    return (
        <EmptyState imageFile='Empty-Network.svg'
            title={lt('title', {name: invitation?.charter.consortia.name})}
            description={lt('description')}
            button={{ text: lt('viewNetwork'), onClick: () => history.push(`/orgs/${org_id}/consortia/${invitation?.consortia_id}`) }} />
    )
};

interface translations {
    title: string
    description: string
    viewNetwork: string
}

const enTranslations: translations = {
    title: 'Welcome to {{name}}!',
    description: 'You have successfully joined the network. You can now create your own resources and security credentials.',
    viewNetwork: 'View Network'
}
