import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers';

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOnChainRegistryHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOnChainRegistryHelp:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Chainlink.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/kaleido-services/registry/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
}
const enTranslations: translations = {
    helpHeader: 'On-Chain Registry',
    helpDescription: 'On-Chain Registry is an utility service that makes it possible to authoritatively map x509 identity certificates to organizational Ethereum addresses. It also provides key/value based smart contract profiles for publicly accessible information within the environment.',
}