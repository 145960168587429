import React from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { ContractProjectData, ContractProjectQuery } from '../../../models';
import { ConsortiumResourceVars } from '../../../interfaces'
import { CreateGithub } from './CreateGithub'
import { CreateKaleidoTemplate } from './CreateKaleidoTemplate'
import { CreatePrecompiled } from './CreatePrecompiled'
import { CreateCorda } from './CreateCorda'
import { CreateUpload } from './CreateUpload'
import { KALEIDO_CONTRACT_TEMPLATES } from '../../NodeContracts/Create';
import { CreateFabric } from './CreateFabric';

export const Create: React.FC = () => {
    const { consortium_id, contract_id } = useParams<any>();

    const {
        data: {
            contractProject
        } = { contractProject: null }
    } = useQuery<ContractProjectData, ConsortiumResourceVars>(ContractProjectQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: contract_id!
        },
        fetchPolicy: 'cache-only' 
    });

    if (contractProject?.type === 'github') {
        if (contractProject?.name === KALEIDO_CONTRACT_TEMPLATES) {
            return <CreateKaleidoTemplate />
        }
        return <CreateGithub />
    } else if (contractProject?.type === 'precompiled') {
        return <CreatePrecompiled />
    } else if (contractProject?.type === 'upload') {
        return <CreateUpload />
    } else if (contractProject?.type === 'corda_jar') {
        return <CreateCorda />
    } else if (contractProject?.type.startsWith('fabric')) {
        return <CreateFabric />
    }

    return <CreatePrecompiled />
};
