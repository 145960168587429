import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Grid, Typography, IconButton, MenuItem, makeStyles, FormControl, InputLabel, Select } from "@material-ui/core";
import { useQuery } from '@apollo/client';
import { EnvironmentResourceUsageData, EnvironmentResourceUsageQuery, EnvironmentResourceUsageVars, 
    NodesData, NodesQuery, ServicesData, ServicesQuery } from '../../models'
import { DisplayGridWrapper } from '../../components/DisplayWrappers'
import { MessageSnackbar, ErrorSnackbarCatcher } from '../../components/DialogWrappers'
import RefreshIcon from 'mdi-react/RefreshIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EnvironmentResourcesVars } from '../../interfaces';
import { RuntimeSelector } from './RuntimeSelector';
import { ResourceChart } from '../../components/Charts/ResourceChart';
import { RuntimeType, RuntimeTypeSelector } from './RuntimeTypeSelector';

const REFRESH_TIMEOUT = 2500
const MONITORING_TIMEFRAMES = [3, 6, 12, 24, 48, 72] // last X hours
const INTERVALS = [5, 15, 30, 45, 60] // minutes

const MENU_PROPS = { getContentAnchorEl: null } // avoids the select menu jumping around when selecting runtimes

export const Dashboard = () => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthDashboard', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`EnvironmentHealthDashboard:${key}`, interpolate), [t])

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!
    }

    // unfortunately cant use TextField with multiple select so need this ref business here to get a consistent look
    const intervalInputLabel = useRef<HTMLLabelElement>(null);
    const [intervalLabelWidth, setIntervalLabelWidth] = useState(0);
    useEffect(() => {
        if (intervalInputLabel.current)
        setIntervalLabelWidth(intervalInputLabel.current.offsetWidth);
    }, [intervalInputLabel])

    const timeframeInputLabel = useRef<HTMLLabelElement>(null);
    const [timeframeLabelWidth, setTimeframeLabelWidth] = useState(0);
    useEffect(() => {
        if (timeframeInputLabel.current)
        setTimeframeLabelWidth(timeframeInputLabel.current.offsetWidth);
    }, [timeframeInputLabel])
    
    const [message, setMessage] = useState('');
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [monitoringTimeframe, setMonitoringTimeframe] = useState(24);
    const [interval, setInterval] = useState(15);
    const [runtimeType, setRuntimeType] = useState<RuntimeType>('all');

    // refresh the metrics using a timeout with a visual indicator that its refreshing
    const timeoutRef = useRef<number>(0)
    useEffect(() => {
        return () => window.clearTimeout(timeoutRef.current);
    }, []);
    const handleRefresh = () => {
        window.clearTimeout(timeoutRef.current);
        setDisableRefresh(true)

        Promise.all([refetchEnvironmentResourceUsage()]).then(() => {
            timeoutRef.current = window.setTimeout(() => {
                setDisableRefresh(false)
            }, REFRESH_TIMEOUT)
        })
    }

    // query the runtimes
    const { 
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });    

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const allRuntimes = useMemo(() => {
        let list = [] as { _id: string, name: string }[]
        if (runtimeType === 'node' || runtimeType === 'all') {
            list = list.concat(nodes.map(n => 
                ({ _id: n._id, name: n.name })
            ))
        }
        if (runtimeType !== 'node') {
            list = list.concat(services.filter(s => runtimeType === 'all' || s.service === runtimeType).map(s => 
                ({ _id: s._id, name: s.name })
            ))
        }
        return list
    }, [nodes, services, runtimeType])

    // when toggling runtime type, reset all to selected
    useEffect(() => {
        setSelectedRuntimeIds(allRuntimes.map(r => r._id))
    }, [runtimeType, allRuntimes])

    // track the runtimes to show in the filter
    const [selectedRuntimeIds, setSelectedRuntimeIds] = useState<string[]>(allRuntimes.map(r => r._id));

    // query the resource usage stats
    const {
        refetch: refetchEnvironmentResourceUsage,
        loading: environmentResourceUsageLoading,
        data: {
            environmentResourceUsage
        } = { environmentResourceUsage: [] },
        error: environmentResourceUsageError
    } = useQuery<EnvironmentResourceUsageData, EnvironmentResourceUsageVars>(EnvironmentResourceUsageQuery, {
        variables: {
            ...environmentVars, 
            hours: monitoringTimeframe,
            interval
        },
        fetchPolicy: 'cache-and-network'
    });

    const runtimesForChart = useMemo(() => (
        environmentResourceUsage.filter(r => selectedRuntimeIds.find(id => id === r.id))
    ), [selectedRuntimeIds, environmentResourceUsage])

    // show error if the query fails
    useEffect(() => {
        const e = environmentResourceUsageError
        if (e) {
            ErrorSnackbarCatcher(e, setMessage)
        }
    }, [environmentResourceUsageError]);

    const wrapChartWithLoading = (chart: JSX.Element, header: string) => {
        if (environmentResourceUsageLoading && !runtimesForChart?.length) {
            return (
                <DisplayGridWrapper hideDivider {...{header}} padDisplayGrid displayGrid={<CircularProgress />}/>
            )
        } else if (!selectedRuntimeIds.length) {
            const noRuntimesSelected = <Typography variant="body2">{lt('noRuntimesSelected')}</Typography>
            return (
                <DisplayGridWrapper {...{header}} padDisplayGrid displayGrid={noRuntimesSelected}/>
            )
        } else if (!environmentResourceUsage.length || (runtimesForChart.find(() => true)?.data.length ?? 0) <= 1) {
            const notEnoughData = <Typography variant="body2">{lt('notEnoughData')}</Typography>
            return (
                <DisplayGridWrapper {...{header}} padDisplayGrid displayGrid={notEnoughData}/>
            )
        }   
        return (
            <DisplayGridWrapper hideDivider {...{header}} displayGrid={chart}/>
        )
    }

    const preventRenderError = environmentResourceUsageError ? true : false
    const refreshIsDisabled = environmentResourceUsageLoading || disableRefresh
                
    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('environmentMetrics')}
                        </Typography>
                    </Grid>
                    {!preventRenderError && 
                    <Grid item>
                        <RuntimeTypeSelector servicesToInclude={services.map(s => s.service)} {...{runtimeType}} {...{setRuntimeType}} disabled={refreshIsDisabled} />

                        <RuntimeSelector {...{allRuntimes}} {...{selectedRuntimeIds}} {...{setSelectedRuntimeIds}} {...{refreshIsDisabled}} />
                        
                        <FormControl variant="outlined" className={classes.padDropdown}>
                            <InputLabel ref={intervalInputLabel}>
                                {lt('interval')}
                            </InputLabel>
                            <Select
                                labelWidth={intervalLabelWidth}
                                disabled={refreshIsDisabled}
                                value={interval}
                                onChange={e => setInterval(e.target.value as number)}
                                MenuProps={MENU_PROPS}> 
                                {INTERVALS.map((entry, index) => (
                                    <MenuItem key={`interval-${index}`} value={entry}>{lt('everyInterval', { interval: entry})}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className={classes.padDropdown}>
                            <InputLabel ref={timeframeInputLabel}>
                                {lt('timeframe')}
                            </InputLabel>
                            <Select
                                labelWidth={timeframeLabelWidth}
                                disabled={refreshIsDisabled}
                                value={monitoringTimeframe}
                                onChange={e => setMonitoringTimeframe(e.target.value as number)}
                                MenuProps={MENU_PROPS}> 
                                {MONITORING_TIMEFRAMES.map((entry, index) => (
                                    <MenuItem key={`timeframe-${index}`} value={entry}>{lt('lastHours', { hours: entry})}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <IconButton color="primary" size={refreshIsDisabled ? "small" : "medium"} disabled={refreshIsDisabled}
                                    onClick={() => handleRefresh()} >
                            { refreshIsDisabled ? <CircularProgress color="inherit" /> : <RefreshIcon /> }
                        </IconButton>
                    </Grid>
                    }
                </Grid>

                {!preventRenderError && 
                <Grid item container spacing={3}>
                    <Grid item container>
                        {wrapChartWithLoading(<ResourceChart height='25vh' {...{allRuntimes}} {...{runtimesForChart}} chart='cpu' />, lt('cpu'))}
                    </Grid>

                    <Grid item container>
                        {wrapChartWithLoading(<ResourceChart height='25vh' {...{allRuntimes}} {...{runtimesForChart}} chart='memory' />, lt('memory'))}
                    </Grid>

                    <Grid item container>
                        {wrapChartWithLoading(<ResourceChart height='25vh' {...{allRuntimes}} {...{runtimesForChart}} chart='storage' />, lt('storage'))}
                    </Grid>
                </Grid>
                }
            </Grid>
        </>
    ) 
};

const useStyles = makeStyles(theme => ({
    padDropdown: {
        paddingRight: theme.spacing(2)
    }
}));

interface translations {
    environmentMetrics: string,
    time: string,
    cpu: string,
    memory: string,
    storage: string,
    notEnoughData: string,
    timeframe: string,
    lastHours: string,
    interval: string,
    everyInterval: string,
    noRuntimesSelected: string
}
const enTranslations: translations = {
    environmentMetrics: 'Dashboard',
    time: 'Time',
    cpu: 'CPU',
    memory: 'Memory',
    storage: 'Disk',
    notEnoughData: 'This environment has not been running long enough to collect any metrics data. Try again later.',
    timeframe: 'Time frame',
    interval: ' Metric interval',
    lastHours: 'Last {{hours}} hours',
    everyInterval: 'Every {{interval}} minutes',
    noRuntimesSelected: 'No runtimes have been selected. Use the filter above to select one or more runtimes.'
}