import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { CompiledContract } from '../../../models';

interface Props {
    contracts: CompiledContract[]
    compilationChosen: string,
    setCompilationChosen: React.Dispatch<React.SetStateAction<string>>,
};

export const CompiledContractSelector = ({contracts, compilationChosen, setCompilationChosen}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractSelector:${key}`, interpolate)

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCompilationChosen(event.target.value as string);
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel required>
                {contracts.length > 0 ? lt('version') : lt('promoteACompiledContract')}
            </InputLabel>
            <Select 
                disabled={contracts.length === 0 || compilationChosen === ''}
                data-test="compiledContractSelector"
                labelWidth={60}
                value={compilationChosen}
                onChange={handleChange}>
                {contracts.map(m => (
                    <MenuItem key={m._id} selected={m._id === compilationChosen} value={m._id}>
                        {contracts.find(x => x._id === m._id)?.description ?? ''}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    )
};

interface translations {
    version: string,
    promoteACompiledContract: string
}
const enTranslations: translations = {
    version: 'Version',
    promoteACompiledContract: 'Please promote a compiled contract to this environment through Apps.'
}