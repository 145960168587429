import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const UploadStep1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationUploadStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationUploadStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Bring your own source',
    helpDescription: 'This project is configured for you to upload your contract source code for managed compilation. Both a single solidity file and zip file formats are acceptable. Kaleido will then automatically detect the contracts in your file and compile the one you choose. On this step, we ask for the following:',
    helpDesc1: 'Owner - the Membership who will own this version.',
    helpDesc2: 'Description - Give this version a description so you will recognize it later.',
}