import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { EnvironmentResourcesVars } from '../interfaces'
import { MembershipOwnedResource } from './memberships';

export interface AppCred extends MembershipOwnedResource {
    _id: string
    password: string
    consortia_id: string
    environment_id: string
    dapp_id: string
    name: string
    auth_type: string
    service_id: string
    integration_id: string
    created_at: string
    updated_at: string
    state: "deleted" | null
}

export interface AppCredData {
    appCred: AppCred
}

export interface RegenerateAppCredData {
    regenerateAppCred: AppCred
}

export interface AppCredsData {
    appCreds: AppCred[]
}

export interface CreateAppCredVars extends EnvironmentResourcesVars {
    appCred: {
        name: AppCred['name'],
        membership_id: AppCred['membership_id']
    }
}

export interface UpdateAppCredVars extends EnvironmentResourcesVars {
    appCred: {
        name: AppCred['name'],
    }
}

export interface CreateAppCredData {
    createAppCred: AppCred
}

export const AppCredFields = ` 
    fragment AppCredFields on AppCred {
        _id
        password
        consortia_id
        environment_id
        membership_id
        dapp_id
        name
        auth_type
        service_id
        integration_id
        state
        created_at
        updated_at
        membership @client
    }`

export const AppCredsQuery = gql`
    ${AppCredFields}
    query getAppCreds($consortia_id: String!, $environment_id: String!) {
        appCreds(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...AppCredFields
        }
    }`

export const AppCredQuery = gql`
    ${AppCredFields}
    query getAppCred($consortia_id: String!, $environment_id: String!, $id: String!) {
        appCred(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...AppCredFields
        }
    }`

export const AppCredsSubscription = gql`
    ${AppCredFields}
    subscription onAppCredsChanged($consortia_id: String!, $environment_id: String!) {
        appCred(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...AppCredFields
        }
    }`

export const CreateAppCredMutation = gql`
    ${AppCredFields}
    mutation createAppCred($consortia_id: String!, $environment_id: String!, $appCred: CreateAppCredInput!) {
        createAppCred(consortia_id: $consortia_id, environment_id: $environment_id, appCred: $appCred) {
            ...AppCredFields
        }
    }`;

export const UpdateAppCredMutation = gql`
    ${AppCredFields}
    mutation updateAppCred($consortia_id: String!, $environment_id: String!, $id: String!, $appCred: UpdateAppCredInput!) {
        updateAppCred(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, appCred: $appCred) {
            ...AppCredFields
        }
    }`;

export const RegenerateAppCredMutation = gql`
    ${AppCredFields}
    mutation regenerateAppCred($consortia_id: String!, $environment_id: String!, $id: String!) {
        regenerateAppCred(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...AppCredFields
        }
    }`;

export const DeleteAppCredMutation = gql`
    mutation deleteAppCred($consortia_id: String!, $environment_id: String!, $id: String!) {
        deleteAppCred(consortia_id: $consortia_id, environment_id: $environment_id, id: $id)
    }`;

export function MakeAppCredCreateMutationOptions(
    variables: CreateAppCredVars,
    overrideOptions?: MutationFunctionOptions<CreateAppCredData, CreateAppCredVars>): MutationFunctionOptions<CreateAppCredData, CreateAppCredVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const appCred = data?.createAppCred
            if (appCred) {
                const { appCreds } = cache.readQuery<AppCredsData>({ query: AppCredsQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!appCreds.find(a => a._id === appCred._id)) {
                    cache.writeQuery({
                        variables,
                        query: AppCredsQuery,
                        data: { appCreds: appCreds.concat(appCred) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

export function MakeAppCredsSubscriptionOptions(
    variables: EnvironmentResourcesVars,
    overrideOptions?: SubscriptionHookOptions<AppCredData>
    ): SubscriptionHookOptions<AppCredData> {
        return { ...{
            variables,
            onSubscriptionData: ({client, subscriptionData}) => {
                // Get the servicesChanged object from the event
                const { 
                    data: { 
                        appCred
                    } = { appCred: null }
                } = subscriptionData
    
                if (!appCred) return
    
                // Get current list of appCreds from the cache
                // assert that appCreds must exist since this function throws if it doesnt
                const { appCreds: existingAppCreds } = client.readQuery<AppCredsData>({ 
                    query: AppCredsQuery, 
                    variables 
                })!
                
                // if it already exists in the store, (its an update), apollo automagically updated the cache
                const cachedAppCreds = existingAppCreds.find(c => c._id === appCred._id)
    
                // but, if it came back as deleted, we need to remove it from the cache manually
                if (cachedAppCreds) {
                    if (cachedAppCreds.state === 'deleted') {
                        client.cache.evict({id: `AppCred:${cachedAppCreds._id}`, broadcast: false})
                    }
                    client.writeQuery<AppCredsData>({ 
                        query: AppCredsQuery, 
                        data: Object.assign({}, existingAppCreds, {
                            appCreds: [...existingAppCreds.filter(c => c.state !== 'deleted')]
                        }),
                        variables
                    })
                    return 
                }
    
                // in case of duplicate events, never add a deleted one into the cache
                if (appCred.state === 'deleted') return
    
                // if it doesnt exist in the store, (it's been added), so we need to add it in manually
                client.writeQuery<AppCredsData>({ 
                    query: AppCredsQuery, 
                    data: Object.assign({}, existingAppCreds, {
                        appCreds: [...existingAppCreds, appCred]
                    }),
                    variables
                })
            }
        }, ...overrideOptions }
}