import React, { useEffect, useState } from "react";
import { Grid, Typography, CircularProgress, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { App2appClientsQuery, App2AppClientsData, App2AppClient } from "../../../models";
import { useQuery } from "@apollo/client";
import { ServiceResourcesVars } from "../../../interfaces";
import { useParams } from "react-router-dom";
import { DisplayGridWrapper, DisplayTable, EmptyState } from "../../../components/DisplayWrappers";
import { CopyableSettings } from "../../../components/DisplaySettings";
import { DisplayTableRecord } from "../../../components/DisplayWrappers/DisplayTableRow";

export const App2AppClients: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'App2AppClients', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`App2AppClients:${key}`, interpolate)
    const { service_id } = useParams<any>();
    const[time, setTime] = useState(Date.now());

    const {
        loading,
        data: {
            app2appClients
        } = { app2appClients: [] }
    } = useQuery<App2AppClientsData, ServiceResourcesVars>(App2appClientsQuery, {
        variables: {
            service_id
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000
    });

    useEffect(() => {
        const interval = window.setInterval(() => {
            setTime(Date.now());
        }, 1000);
        return () => {
            window.clearInterval(interval);
        }
    }, [setTime]);

    if (loading) {
        return <CircularProgress />
    }

    const getMetricRecords = (app2appClient: App2AppClient): DisplayTableRecord[] => {
        return [
            {
                columns: [
                    {
                        value: lt('bytes')
                    }, {
                        value: app2appClient.metrics.sent.bytes

                    }, {
                        value: app2appClient.metrics.received.bytes
                    }
                ]
            },{
                columns: [
                    {
                        value: lt('chunks')
                    }, {
                        value: app2appClient.metrics.sent.chunks

                    }, {
                        value: app2appClient.metrics.received.chunks
                    }
                ]
            },{
                columns: [
                    {
                        value: lt('messages')
                    }, {
                        value: app2appClient.metrics.sent.messages

                    }, {
                        value: app2appClient.metrics.received.messages
                    }
                ]
            }
        ];
    };

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('connectedClients')}</Typography>
            </Grid>
            {app2appClients.map(app2appClient => (
                <Grid item key={app2appClient.id}>
                    <DisplayGridWrapper
                        displayGrid={<CopyableSettings copyableList={[{
                            title: lt('established'),
                            displayValue: new Date(app2appClient.established * 1000).toLocaleString(),
                            disableCopy: true
                        },{
                            title: lt('connectionTime'),
                            displayValue: (new Date(time - app2appClient.established * 1000)).toISOString().substr(11, 8),
                            disableCopy: true
                        }, {
                            title: lt('subscriptions'),
                            displayValue: app2appClient.subscriptions.join(', ') || '--',
                            disableCopy: true
                        }, {
                            title: lt('metrics'),
                            displayValue: <DisplayTable
                                columnHeaders={['', lt('sent'), lt('received')]}
                                records={getMetricRecords(app2appClient)}
                            />,
                            disableCopy: true
                        }]} />}
                        header={lt('clientId', { clientId: app2appClient.id })} />
                </Grid>
            ))}
            {app2appClients.length === 0 &&
                <Grid item>
                    <Paper>
                        <EmptyState title={lt('noClients')} description={lt('noClientsCurrentlyConnected')} imageFile='Empty-NoResults.svg' />
                    </Paper>
                </Grid>}
        </Grid>
    );
};

interface translations {
    connectedClients: string
    clientId: string
    established: string
    connectionTime: string
    subscriptions: string
    metrics: string
    sent: string
    received: string
    bytes: string
    chunks: string
    messages: string
    noClients: string
    noClientsCurrentlyConnected: string
}

const enTranslations: translations = {
    connectedClients: 'Connected Clients',
    clientId: 'Client ID {{clientId}}',
    established: 'Established',
    connectionTime: 'Connection time',
    subscriptions: 'Subscriptions',
    metrics: 'Metrics',
    sent: 'Sent',
    received: 'Received',
    bytes: 'Bytes',
    chunks: 'Chunks',
    messages: 'Messages',
    noClients: 'No clients',
    noClientsCurrentlyConnected: 'There are no clients currently connected'
}
