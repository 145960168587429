import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
    TetherStatusQuery,
    TetherStatusData,
} from "../../models/servicesStatus";
import {
    EnvironmentResourceVars,
    ServiceResourcesVars,
} from "../../interfaces";
import { useParams, Redirect } from "react-router-dom";
import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    CopyableSettings,
    CopyableSetting,
} from "../../components/DisplaySettings";
import { BLOCKCHAIN_BASE_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import { ResourceStateChip } from "../../components/FormControls/ResourceStateChip";
import { CostSummary } from "./CostSummary";
import {
    ErrorSnackbarCatcher,
    MessageSnackbar,
} from "../../components/DialogWrappers";
import { FormLink } from "../../components/FormControls/FormLink";
import { ServicesEnum } from "../../models";
import { LastReportQuery, LastReportData } from "../../models/tether";
import { capitalize } from "../../utils/StringUtils";

export const TetherActivity = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "TetherActivity", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`TetherActivity:${key}`, interpolate);

    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState<"error" | "success">(
        "error"
    );

    const { org_id, service_id, consortium_id, environment_id } = useParams<any>();

    const {
        refetch: refetchTether,
        loading,
        data: { tetherStatus } = { tetherStatus: null },
    } = useQuery<TetherStatusData, EnvironmentResourceVars>(TetherStatusQuery, {
        variables: {
            id: service_id,
            environment_id,
            consortia_id: consortium_id,
        },
        fetchPolicy: "cache-and-network",
    });

    const {
        refetch: getLastReport,
        data: { lastReport } = { lastReport: null },
    } = useQuery<LastReportData, ServiceResourcesVars>(LastReportQuery, {
        variables: {
            service_id,
        },
    });

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    if (loading && !tetherStatus) return <CircularProgress />;
    if (!tetherStatus)
        return (
            <Redirect
                to={`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/${service_id}`}
            />
        );

    const network = tetherStatus.active_network;

    const getBlockRedirectPath = () => {
        const blockNumber = tetherStatus.tx_stats[network]!.last_success_block;
        if (network === "mainnet") {
            return `https://etherscan.io/block/${blockNumber}`;
        } else {
            return `https://goerli.etherscan.io/txs?block=${blockNumber}`;
        }
    };

    const getContractRedirectPath = () => {
        const contract = tetherStatus.arbitrator_address;
        if (network === "mainnet") {
            return `https://etherscan.io/address/${contract}`;
        } else {
            return `https://goerli.etherscan.io/address/${contract}`;
        }
    };

    const downloadLastReport = async () => {
        try {
            if (lastReport && lastReport.report?.length > 0) {
                const dataStr =
                    "data:text/json;charset=utf-8," +
                    encodeURIComponent(JSON.stringify(lastReport.report));
                const downloadAnchorNode = document.createElement("a");
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute(
                    "download",
                    `${service_id}-report-${network}-block-${lastReport.report[0].blockNumber}.json`
                );
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            }
        } catch (err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    const canDisplaySummary = tetherStatus.tx_stats[network]?.last_success_timestamp

    const copyableList: CopyableSetting[] = [
        {
            title: lt("network"),
            displayValue: lt("protectedBy", {
                network: capitalize(tetherStatus.active_network),
            }),
            disableCopy: true,
        },
        {
            title: lt("status"),
            displayValue: (
                <ResourceStateChip
                    state={tetherStatus.is_activated ? "active" : "inactive"}
                />
            ),
        },
        {
            title: lt("smartContractAddress"),
            displayValue: tetherStatus.arbitrator_address ? (
                <FormLink
                    target="_blank"
                    isExternal
                    to={getContractRedirectPath()}
                >
                    {tetherStatus.arbitrator_address}
                </FormLink>
            ) : (
                "--"
            ),
            disableCopy: !tetherStatus.arbitrator_address,
        },
        {
            title: lt("lastSuccess"),
            displayValue: canDisplaySummary
                ? new Date(
                      tetherStatus.tx_stats[network]!.last_success_timestamp
                  ).toLocaleString()
                : "--",
            disableCopy: !tetherStatus.tx_stats[network],
        },
        {
            title: lt("lastSuccessBlock"),
            displayValue: canDisplaySummary ? (
                <FormLink
                    target="_blank"
                    isExternal
                    to={getBlockRedirectPath()}
                >
                    {tetherStatus.tx_stats[
                        network
                    ]!.last_success_block.toString() ?? ""}
                </FormLink>
            ) : (
                "--"
            ),
            disableCopy: !tetherStatus.tx_stats[network],
        },
        {
            title: lt("lastGasPrice"),
            displayValue: canDisplaySummary
                ? tetherStatus.tx_stats[network]!.last_gas_price.toLocaleString(
                      "en"
                  )
                : "--",
            disableCopy: !tetherStatus.tx_stats[network],
        },
    ];

    if (tetherStatus.is_activated) {
        copyableList.push({
            title: lt("relayInterval"),
            displayValue:
                tetherStatus.relay_interval > 60
                    ? lt("multipleHours", {
                          hours: tetherStatus.relay_interval / 60 ?? 0,
                      })
                    : lt("everyHour"),
            disableCopy: true,
        });
    }

    const onRefreshClick = async () => {
        try {
            await refetchTether();
            await getLastReport();
            setMessageType("success");
            setMessage(lt("successRefresh"));
        } catch (err) {
            setMessageType("error");
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    return (
        <>
            <MessageSnackbar
                {...{ setMessage }}
                {...{ message }}
                {...{ messageType }}
            />
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt("activitySummary")}
                    </Typography>
                </Grid>
                <Grid item>
                    <CopyableSettings
                        actionBar={
                            <Box
                                display="flex"
                                flexWrap="nowrap"
                                alignItems="center"
                            >
                                <Button
                                    onClick={downloadLastReport}
                                    variant="text"
                                    size="small"
                                    disabled={!lastReport}
                                >
                                    {lt("downloadLastReport")}
                                </Button>
                                <Box ml={1}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={onRefreshClick}
                                    >
                                        {lt("refresh")}
                                    </Button>
                                </Box>
                            </Box>
                        }
                        header={lt("activity")}
                        {...{ copyableList }}
                    />
                </Grid>
                <Grid item container spacing={3}>
                    <Grid container item xs={12} md={6}>
                        <CostSummary network="goerli" {...{ tetherStatus }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CostSummary network="mainnet" {...{ tetherStatus }} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

interface translations {
    goerli: string;
    mainnet: string;
    activity: string;
    activitySummary: string;
    status: string;
    smartContractAddress: string;
    lastSuccess: string;
    lastSuccessBlock: string;
    lastTransactionPrice: string;
    lastGasPrice: string;
    relayInterval: string;
    costSummary: string;
    network: string;
    totalGasSpent: string;
    lastTransactionGasSpent: string;
    totalEthSpent: string;
    totalTransactionCost: string;
    protectedBy: string;
    refresh: string;
    downloadLastReport: string;
    successRefresh: string;
    everyHour: string;
    multipleHours: string;
}
const enTranslations: translations = {
    mainnet: "Mainnet",
    goerli: "Goerli",
    activity: "Activity",
    activitySummary: "Activity Summary",
    status: "Status",
    smartContractAddress: "Smart Contract Address",
    lastSuccess: "Last Success",
    lastSuccessBlock: "Last Success Block",
    lastTransactionPrice: "Last Transaction Price (Gwei)",
    lastGasPrice: "Last Gas Price (Gwei)",
    relayInterval: "Relay Interval",
    network: "Network",
    totalEthSpent: "Total Eth Spent",
    totalGasSpent: "Total Gas Spent",
    totalTransactionCost: "Total Transaction Cost",
    costSummary: "Cost Summary - {{network}}",
    lastTransactionGasSpent: "Last Transaction Gas Spent",
    protectedBy: "Protected by {{network}}",
    refresh: "Refresh",
    downloadLastReport: "Download Last Report",
    successRefresh: "Successful Refresh",
    everyHour: "Every hour",
    multipleHours: "Every {{hours}} hours",
};
