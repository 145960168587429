import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more.js"
import SolidGauge from "highcharts/modules/solid-gauge.js";
import { BaseChartOptions } from '../../utils/ChartOptions'
import { useTranslation } from 'react-i18next';
import { BrandColors } from '../../utils/Colors';
import { PieChart } from '../../components/Charts/PieChart';


interface Props {
    failedTransactions: number,
    successTransactions: number
}

export const TransactionsGauge = ({failedTransactions, successTransactions}: Props) => {
    Highcharts.setOptions(BaseChartOptions);
    HighchartsMore(Highcharts);
    SolidGauge(Highcharts);

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransactionsGauge', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransactionsGauge:${key}`, interpolate);

    const data =  [
        {name:'Successful', y: successTransactions, color: BrandColors.blurple },
        {name: 'Failed', y: failedTransactions, color: BrandColors.magenta }
    ];

    function labelFormatter (this: Highcharts.Point | Highcharts.Series)  {
        return this.name + ` (${this.name === 'Successful' ? successTransactions : failedTransactions})`
    }

    return <PieChart subtitle={lt('last100')} {...{data}} {...{labelFormatter}} title={lt('header')} /> 
}

interface translations {
    header: string,
    last100: string
}
const enTranslations: translations = {
    header: 'Transactions',
    last100: 'Last 100 Blocks'
}
