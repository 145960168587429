import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models'
import { DisplayCard } from '../../components/DisplayWrappers'
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { AuditData, AuditVars, AuditQuery } from '../../models/audit';
import { ActivityIcon } from '../../components/ActivityLog/ActivityIcon';
import { ActivityOwner } from '../../components/ActivityLog/ActivityOwner';
import { ConsortiumResourcesVars } from '../../interfaces';
import { ActivityDescription } from '../../components/ActivityLog/ActivityDescription';
import { ActivityStatus } from '../../components/ActivityLog/ActivityStatus';
import moment from 'moment';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_ACTIVITY_PATH } from '../../components/MainNav/SideNavs/AddressBook';

export const Activity = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentDashboardActivity', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentDashboardActivity:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const {
        loading,
        data: {
            audit
        } = { audit: [] }
    } = useQuery<AuditData, AuditVars>(AuditQuery, {
        variables: { 
            consortia_id: consortium_id!, 
            environment_id: environment_id!,
            skip: 0, 
            limit: 25
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables:  {
        consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const auditList = audit.map(auditEntry => ({
        title: (
            <>
                <ActivityStatus {...{auditEntry}} /> - <ActivityDescription omitEnvironment {...{auditEntry}} />
            </>
        ),
        value: (
            <>
                <ActivityOwner {...{auditEntry}} {...{consortiumMemberships}} /> ({moment(new Date(Number(auditEntry.timestamp))).fromNow()})
            </>
        ),
        icon: <ActivityIcon {...{auditEntry}} />
    }))

    const linkButton = {
        text: lt('viewActivityLog'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACTIVITY_PATH}`)
    }
    
    return (
        <DisplayCard 
            loading={loading && !audit.length}
            header={lt('activityLog')}
            itemList={auditList} 
            {...{linkButton}} />
    )
};

interface translations {
    activityLog: string,
    viewActivityLog: string
}
const enTranslations: translations = {
    activityLog: 'Activity Log',
    viewActivityLog: 'View Activity Log'
}