import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, Grid, TextField, makeStyles, FormControlLabel, FormControl, RadioGroup, Radio } from "@material-ui/core";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../../../components/DialogWrappers'
import { useMutation, useApolloClient } from '@apollo/client';
import { CreateStepProps } from '../../../../interfaces';
import { CreateApp2AppDestinationMutation, CreateApp2AppDestinationData, CreateApp2AppDestinationVars } from '../../../../models/app2app';
import { ExtManagedDestInstructions } from './ExtManagedDestInstructions';
import { ServicesEnum, OrganizationData, OrganizationVars, OrganizationQuery } from '../../../../models';
import { ORGS_PATH } from '../../../../components/ManageOrgNav/ManageOrgNav';
import { B2B_BASE_PATH } from '../../../../components/MainNav/SideNavs/B2bCommunication';
import { APP2APP_DESTINATIONS_PATH } from '../../../../components/ServicesNav/Services/App2AppItems';
import { KldManagedDestInstructions } from './KldManagedDestInstructions';
import { Paywall } from '../../../../components/FormControls/Paywall';

const destNameRegex = /^[0-9a-z-_.]{1,44}$/;

type App2AppDestinationManagement = 'kaleido-managed' | 'externally-managed';

export const Step2 = ({ cancelPath }: CreateStepProps) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'App2AppCreateDestinationStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`App2AppCreateDestinationStep2:${key}`, interpolate);

    const [destinationName, setDestinationName] = useState('');
    const [destinationManagement, setDestinationManagement] = useState<App2AppDestinationManagement>('kaleido-managed');
    const [instructionsVisible, setInstructionsVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [createDestination, { loading }] = useMutation<CreateApp2AppDestinationData, CreateApp2AppDestinationVars>(CreateApp2AppDestinationMutation);

    const classes = useStyles();
    const history = useHistory();
    const { org_id, consortium_id, environment_id, service_id } = useParams<any>();
    const client = useApolloClient();
    const { organization } = client.cache.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id} })!;

    const handleNext = () => {
        if (instructionsVisible) {
            history.push(`/${ORGS_PATH}/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}` +
                `/${ServicesEnum.app2app}/${service_id}/${APP2APP_DESTINATIONS_PATH}`);
        } else {
            createDestination({
                variables: {
                    service_id: service_id!,
                    destination: { name: destinationName, kaleido_managed: destinationManagement === 'kaleido-managed' }
                }
            }).then(result => {
                if (destinationManagement === 'externally-managed') {
                    setInstructionsVisible(true);
                } else if (result.data?.createApp2AppDestination.result === 'Destination successfully added.') {
                    history.push(`/${ORGS_PATH}/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${B2B_BASE_PATH}` +
                        `/${ServicesEnum.app2app}/${service_id}/${APP2APP_DESTINATIONS_PATH}`);
                } else {
                    setInstructionsVisible(true);
                }
            }).catch(err => {
                ErrorSnackbarCatcher(err, setErrorMessage);
            });
        }
    };

    const content = (
        <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item>
                <Typography variant="h5">{lt('destinationDetails')}</Typography>
            </Grid>
            {instructionsVisible ?
                <Grid item>
                    {destinationManagement === 'kaleido-managed' ?
                        <KldManagedDestInstructions destinationName={destinationName} />
                        :
                        <ExtManagedDestInstructions destinationName={destinationName} />
                    }
                </Grid> :
                <>
                    <Grid item>
                        <TextField disabled={loading} error={!!destinationName && !destNameRegex.test(destinationName)}
                            onChange={event => setDestinationName(event.target.value)} helperText={lt('destinationNameInputHelpText')} label="Destination Name" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={destinationManagement} onChange={event => setDestinationManagement(event.target.value as App2AppDestinationManagement)}>
                                <FormControlLabel disabled={loading} value="kaleido-managed" control={<Radio color="primary" />} label={lt('kaleidoManagedDestination')} />
                                <FormControlLabel disabled={loading || organization.plan !== 'enterprise'} value="externally-managed" control={<Radio color="primary" />} label={lt('externallyManagedDestination')} />
                            </RadioGroup>
                        </FormControl>
                        {organization.plan !== 'enterprise' &&
                        <Paywall description={lt('paywallMessage')} />}
                    </Grid>
                </>}
        </Grid>
    );

    return (
        <>
            <CreateWrapper saving={loading} hideBack={instructionsVisible || loading} cancelPath={cancelPath} content={content}
                disabled={!destinationName || loading || !destNameRegex.test(destinationName)} onNext={handleNext}
                isLastStep={destinationManagement === 'kaleido-managed' || instructionsVisible} />
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
        </>
    );
};

interface translations {
    header: string,
    certificate: string,
    destinationDetails: string,
    destinationNameInputHelpText: string
    kaleidoManagedDestination: string
    externallyManagedDestination: string
    paywallMessage: string
}
const enTranslations: translations = {
    header: 'Initial Setup',
    certificate: 'Certificate',
    destinationDetails: 'Destination Details',
    destinationNameInputHelpText: '* Allowed characters: Lowercase [a-z] [0-9] [- , _ , .], maximum length 44',
    kaleidoManagedDestination: 'Let Kaleido manage the destination key',
    externallyManagedDestination: 'Use an externally managed destination key',
    paywallMessage: 'Your current plan does not support externally managed destinations'
}

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(2)
    }
}));