import React, { useState } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { CircularProgress, Grid, Typography, Button } from "@material-ui/core";
import { CreateWrapper } from '../../components/DialogWrappers'
import { CreateStepProps, EnvironmentResourceVars } from '../../interfaces';
import { GatewayAPIData, GatewayAPIQuery, NodeData, NodeQuery, Account } from '../../models';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SourceCodeType } from './Create';
import { Step3Help } from './Step3Help'
import { FormLink } from '../../components/FormControls/FormLink';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { NETWORK_CONTRACTS_PATH, NETWORK_COMPILED_CONTRACTS_PATH } from '../../components/MainNav/ConsortiumWrapper';
import { TokenDeploy } from './TokenDeploy';
import { AccountSelector } from '../../components/AccountSelector/AccountSelector';
import { APPS_BASE_PATH, APPS_GATEWAY_APIS_PATH, APPS_GATEWAY_VIEW_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';

interface Props extends CreateStepProps {
    sourceCodeType: SourceCodeType
    contractId: string
    compiledContractId: string
    deployerAccount?: Account
    contractAddress: string
};

export const Step3 = ({ sourceCodeType, contractId, compiledContractId, cancelPath, deployerAccount, contractAddress }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeContractsCreateStep3', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeContractsCreateStep3:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();
    const { pathname } = useLocation()

    const [isDeploying, setIsDeploying] = useState(false);
    const [account, setAccount] = useState<Account | undefined>(deployerAccount);

    const {
        loading,
        data: {
            gatewayAPI
        } = { gatewayAPI: null }
    } = useQuery<GatewayAPIData, EnvironmentResourceVars>(GatewayAPIQuery, { 
        variables: { consortia_id: consortium_id!, environment_id: environment_id!, id: compiledContractId },
        fetchPolicy: 'cache-first' 
    });

    const {
        data: {
            node
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: node_id!
        },
        fetchPolicy: 'cache-only'
    });

    const makeLink = (path: string, displayValue: string) => (
        <Grid item container>
            <FormLink to={path}>
                {displayValue}
            </FormLink>
        </Grid>
    )

    const splitPathname = pathname.split('/')
    const gatewayAPIRedirectPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${compiledContractId}`
    const baseContractsPath = `${splitPathname.slice(0, splitPathname.indexOf(consortium_id!) + 1).join('/')}/${NETWORK_CONTRACTS_PATH}`
    const projectRedirectPath = `${baseContractsPath}/${contractId}`
    const compilationRedirectPath = `${projectRedirectPath}/${NETWORK_COMPILED_CONTRACTS_PATH}/${compiledContractId}`

    const copyableList: CopyableSetting[] = [
        { title: lt('gatewayAPIPath'), displayValue: makeLink(gatewayAPIRedirectPath, `/gateways/${gatewayAPI?.endpoint}` || ''), disableCopy: true },
        { title: lt('contractName'), displayValue: makeLink(projectRedirectPath, gatewayAPI?.consortiaContractName || ''), disableCopy: true },
        { title: lt('compiledContractDescription'), displayValue: makeLink(compilationRedirectPath, gatewayAPI?.description || ''), disableCopy: true },
    ]

    const save = () => {
        history.push(gatewayAPIRedirectPath)
    };

    const factoryApiButton = (fullWidth: boolean) =>  (
        <Button data-test="button_viewFactoryAPI" color="primary" size="large" variant="contained" disabled={isDeploying}
            fullWidth={fullWidth} onClick={() => history.push(`${gatewayAPIRedirectPath}/${APPS_GATEWAY_VIEW_PATH}`, {factory: true, node: node?._id})}>
            {lt('viewFactoryApi')}
        </Button>
    )

    const content = loading ? <CircularProgress /> : (
        <>
            { !contractAddress &&  
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>
            }

            { sourceCodeType === 'token' &&
                <Grid item container spacing={2}>
                    <Grid item container direction="column" spacing={2} md={12} lg={7}>
                        <TokenDeploy {...{account}} {...{setAccount}} {...{setIsDeploying}} {...{isDeploying}} endpoint={gatewayAPI?.endpoint || ''} node={node!} />
                    </Grid>
                    <Grid item container alignItems="center" justify="center" md={12} lg={1}>
                        {lt('or')}
                    </Grid>
                    <Grid item container alignItems="center" justify="center" md={12} lg={4}>
                        {factoryApiButton(true)}
                    </Grid>
                </Grid>
            }
            
            { sourceCodeType !== 'token' && !contractAddress && 
            <>
                <Grid item>
                    <AccountSelector {...{account}} {...{setAccount}} membershipId={node!.membership_id} />
                </Grid>
                <Grid item>
                    {factoryApiButton(false)}
                </Grid>
            </>
            }

            <Grid item>
                <Typography variant="h5">
                    {lt('gatewayAPIHeader')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('gatewayAPIDescription')}
                </Typography>
            </Grid>
            <Grid item>
                <CopyableSettings {...{copyableList}} />
            </Grid>

        </>
    )

    return (
        <>
            <CreateWrapper saving={isDeploying} {...{cancelPath}} {...{content}} disabled={false} onNext={save} isLastStep hideBack />
            <Step3Help />
        </>
    )
};

interface translations {
    header: string
    headerDescription: string
    gatewayAPIHeader: string 
    gatewayAPIDescription: string
    gatewayAPIPath: string
    contractName: string
    compiledContractDescription: string
    gatewayAPIInformation: string
    viewFactoryApi: string
    viewDeployAPIHeader: string,
    viewDeployAPIDescription: string
    or: string
}
const enTranslations: translations = {
    header: 'Deploy Contract Instance',
    headerDescription: 'The Gateway API has been created successfully. Now we can proceed with deploying a new contract instance using the Gateway API.',
    gatewayAPIHeader: 'Gateway API Created',
    gatewayAPIDescription: 'Below you will find links to the Gateway API details page, the App details page, and the Compilation description/version that were created as part of Kaleido\'s comprehensive Smart Contract Management solution.',
    gatewayAPIPath: 'Gateway API path',
    contractName: 'App name',
    compiledContractDescription: 'Compilation description',
    gatewayAPIInformation: 'Gateway API Information',
    viewFactoryApi: 'View Factory Api',
    viewDeployAPIHeader: 'Deploy an instance via the Gateway API',
    viewDeployAPIDescription: 'Select a node to view the Gateway API to deploy a new instance.',
    or: 'OR'
}
