import { useQuery } from "@apollo/client";
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from "react-router-dom";
import MonitorStarIcon from 'mdi-react/MonitorStarIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import { MembershipSelector } from '../../components/FormControls/MembershipSelector';
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { ConsortiumResourcesVars, CreateStepProps } from '../../interfaces';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models';
import { Step2Help } from './Step2Help';
import { Paywall } from "../../components/FormControls/Paywall";
import { CHANNELS_PATH } from "../../components/MainNav/SideNavs/AddressBook";
import { CREATE_CHANNELS_PATH } from "../AddressBook/Channels";
import { DisplayTable } from "../../components/DisplayWrappers";
import { DisplayTableRecord } from "../../components/DisplayWrappers/DisplayTableRow";

interface Props extends CreateStepProps {
    name: string,
    membershipId: string,
    description: string,
    excludedMembershipIds?: string[],
    selectedMembers: string[],
    setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>
};

export const Step2 = ({ 
        name, membershipId, description, selectedMembers, setSelectedMembers, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateChannelCreateStep2', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`CreateChannelCreateStep2:${key}`, interpolate), [t])
    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const [member, setMember] = useState("");
    const [message, setMessage] = useState("");
    const [records, setRecords] = useState<DisplayTableRecord[]>([]);
    const [consortiumMemberships, setConsortiumMemberships] = useState([{_id: "sys--mon", org_name:"System", is_mine: false}]);
    const { 
        data: { 
            consortiumMemberships : cm
        } = { consortiumMemberships: [] } 
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    useEffect(()=> {
        if (consortiumMemberships.length === 1) {
            setConsortiumMemberships(memberships => [...memberships, ...cm.map((m) => ({_id: m._id, org_name: m.org_name, is_mine: m.is_mine}))]);
        }
    }, [cm, consortiumMemberships])

    useEffect(() => {
        const removeMember = (memberId: string) => {
            const updatedSelectedMembers = selectedMembers.filter((m) => m !== memberId);
            setSelectedMembers(updatedSelectedMembers);
        }
        
        const updateRecords = () => {
            let updatedRecords = selectedMembers.map((selectedMember) => {
                const memberName = selectedMember === 'sys--mon' ? lt('system') : consortiumMemberships.find(m=> m._id === selectedMember)?.org_name;
                return {
                    key: `${selectedMember}`,
                    columns: [
                        {    
                            value: <Grid item container spacing={3} alignItems="center" wrap="nowrap">
                                    <Grid item>{selectedMember === 'sys--mon' ? <MonitorStarIcon></MonitorStarIcon> : <AccountCircleOutlineIcon></AccountCircleOutlineIcon>}</Grid>
                                    <Grid item><Typography variant="body2">{memberName}</Typography></Grid>
                                </Grid>,
                        },
                        {
                            value: <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={true}
                                    disabled
                                    onChange={() => {}}
                                />
                            }
                            label={""}
                        />,
                        },
                        {
                            value: <IconButton disabled={selectedMember === membershipId} edge="start" color="inherit" onClick={()=>{removeMember(`${selectedMember}`)}}><TrashCanOutlineIcon /></IconButton> 
                        }
                    ]}
            });
                setRecords(updatedRecords);
        }
        updateRecords();
     }, [selectedMembers, membershipId, consortiumMemberships,setSelectedMembers, lt]);

     useEffect(() => {
        const resetDefaultMember = () => {
            const defaultMember = consortiumMemberships.filter((m) => !selectedMembers.includes(m._id)).map(membership => membership._id);
            setMember(defaultMember[0] !== membershipId ? defaultMember[0] : ""); 
        }
        resetDefaultMember();
     }, [selectedMembers, consortiumMemberships, membershipId]);


     if (!selectedMembers) {
        return <Redirect to={`${cancelPath}/create/1`}/>
    }
    const allMembersSelected = selectedMembers.length === consortiumMemberships.length;
    const disabled = allMembersSelected; 
    
    const save = async () => {
        try {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${CREATE_CHANNELS_PATH}/4`, {
                name,
                membershipId,
                description,
                members: selectedMembers
            })
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    }

    const columnHeaders = [
        lt('name'),
        lt('signer'),
        ""
    ]

    const addMember = async () => {
        const updatedSelectedMembers = [...selectedMembers, member];
        setSelectedMembers(updatedSelectedMembers);
    }

    const content = (
        <>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('header')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('headerDescription')}
                    </Typography>
                </Grid>
                <Grid item>
                    <MembershipSelector excludedMembershipIds={selectedMembers} membershipId={selectedMembers.length === consortiumMemberships.length ? "" : member} includeSysMon includeAllOrgsMemberships setMembershipId={setMember} />
                </Grid>
                <Grid item>
                    <Button variant="outlined" {...{disabled}} onClick={addMember}>
                        {lt('add')}
                    </Button>
                </Grid>
                <Grid item>
                    <Box border={1} borderColor={"#adabab"}>
                        <DisplayTable columnHeaders={columnHeaders} records={records}/>
                    </Box>
                </Grid>
                {selectedMembers.length < 3 && <Grid item>
                    <Paywall description={lt('membershipWarning')} />
                </Grid>}
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={false} onNext={save} />
            <Step2Help />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    name: string,
    add: string,
    membershipWarning: string,
    signer: string,
    system: string
}
const enTranslations: translations = {
    header: 'Add Members',
    headerDescription: 'Add an organization to join in the Channel. Their nodes will be added automatically.',
    name: 'Membership Name',
    add: 'Add',
    membershipWarning: 'For reliable operation of your blockchain, we strongly recommend having additional memberships and nodes.',
    signer: 'Signer',
    system: 'System'
}