import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, Grid, TextField, makeStyles } from "@material-ui/core";
import { CreateWrapper } from '../../../../components/DialogWrappers'
import { useMutation } from '@apollo/client';
import { CreateDestinationMutation } from '../../../../models';
import { Step2Help } from './Step2Help';
import { DOCSTORE_DESTINATIONS_PATH } from '../../../../components/ServicesNav/Services/DocumentStoreItems';
import { CreateStepProps } from '../../../../interfaces';

const destNameRegex = /^[0-9a-z-_.]{1,38}$/;

export const Step2 = ({ cancelPath }: CreateStepProps) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateDestinationStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateDestinationStep2:${key}`, interpolate);

    const [newDestination, setNewDestination] = useState('');

    const [createDestination, {loading}] = useMutation(CreateDestinationMutation);

    const classes = useStyles() ;
    const history = useHistory();
    const {org_id, consortium_id, environment_id, service_id } = useParams<any>();

    const onCreate = async () => {
        try {
            await createDestination({
                variables: {
                    service_id: service_id!,
                    newDest: newDestination
                }
            });
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/b2b/documentstore/${service_id}/${DOCSTORE_DESTINATIONS_PATH}`)
        } catch(err) {
            console.log(err);
        }
    };

    const content = (
        <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item>
                <Typography variant="h5">{lt('createDestinations')}</Typography>
            </Grid>
            <Grid item>
                <TextField disabled={loading} error={!!newDestination && !destNameRegex.test(newDestination)} onChange={e => setNewDestination(e.target.value)} helperText={lt('inputCheck')} label="Destination Name" fullWidth variant="outlined"/>
            </Grid>
        </Grid>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={!newDestination || loading || !destNameRegex.test(newDestination)} onNext={onCreate} isLastStep hideBack />
            <Step2Help />
        </>
    )
};

interface translations {
    header: string,
    onChainRegistry: string,
    notProvisioned: string,
    certificate: string,
    single: string,
    multi: string,
    onChainCert: string,
    awsUsEast2Placeholder: string,
    selectRegion: string,
    letKaleido: string,
    createDestinations: string,
    inputCheck: string
}
const enTranslations: translations = {
    header: 'Initial Setup',
    onChainRegistry: 'On Chain Registry',
    notProvisioned: 'Not Provisioned',
    onChainCert: 'Register certificate on chain',
    certificate: 'Certificate',
    single: 'Single',
    multi: 'Multi-Region',
    awsUsEast2Placeholder: 'AWS: US-East-2',
    selectRegion: 'Select Region',
    letKaleido: 'Let Kaleido register my org on-chain',
    createDestinations: 'Create Destinations',
    inputCheck: '* Allowed characters: Lowercase [a-z] [0-9] [- , _ , .], maximum length 38'
}

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(2)
    }
}))