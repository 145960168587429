import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { CircularProgress, TablePagination, Typography } from "@material-ui/core";
import { DisplayTable } from '../DisplayWrappers';
import { Channel } from '../../models/channels';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import ArrowDecisionOutlineIcon from 'mdi-react/ArrowDecisionOutlineIcon';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery, NodesData, NodesQuery, GatewayAPI, ChaincodeStatuses } from '../../models';
import { useQuery } from '@apollo/client';
import { CHANNELS_PATH } from '../MainNav/SideNavs/AddressBook';
import { ConsortiumResourcesVars } from '../../interfaces';
import { MembershipApprovalsCell } from './MembershipApprovalsCell';
import { StatusCheckCell } from './StatusCheckCell';
import { ResourceStateChip } from '../FormControls/ResourceStateChip';

interface Props {
    header: string,
    channels: Channel[],
    loading?: boolean,
    description?: string
    gatewayAPI?: GatewayAPI
}

export const ChannelsTable = ({ header, channels, loading, description, gatewayAPI }: Props) => {
    const { t, i18n } = useTranslation();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    i18n.addResourceBundle('en', 'ChannelsTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChannelsTable:${key}`, interpolate)
    const history = useHistory();
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    if (loading && !channels.length) {
        return <CircularProgress />
    }

    function handlePageChange(_event: unknown, page: number) {
        setCurrentPage(page);
    }

    const pagination = (
        <TablePagination component="div" 
            count = {channels.length}
            onPageChange={handlePageChange}
            onChangeRowsPerPage={({ target }) => {setCurrentPage(0); setRowsPerPage(Number(target.value))}}
            rowsPerPage={rowsPerPage} 
            page={currentPage} />
    )

    let channelRecords: DisplayTableRecord[] = [];
    for(const channelEntry of channels) {
        let membersCount = channelEntry.members?.length ?? 0;
        const membershipNodes = nodes.filter(n => channelEntry.members?.some(m => m === n.membership_id && m !== 'sys--mon'));

        channelRecords.push({
            key: channelEntry._id,
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${channelEntry._id}`),
            columns: [
                { isIcon: true, value: <ArrowDecisionOutlineIcon /> },
                { value:<Typography variant="body2">{channelEntry.name}</Typography>},
                { value: consortiumMemberships.find((ch) => ch._id === channelEntry.membership_id)?.org_name ?? ''},
                { value: membersCount.toString()},
                { value: (membershipNodes.length).toString()},
                { value: <ResourceStateChip state={channelEntry.state} /> }
            ]
        })
        if (gatewayAPI) {
            const peerNodes = membershipNodes.filter(n => n.role === 'peer')
            // choose the most recently added peer node when querying the various chaincode statuses
            const peerNode = peerNodes.length > 0 ? peerNodes[peerNodes.length - 1] : undefined

            const approvedStatusChecker = (chaincodeStatus?: GatewayAPI | ChaincodeStatuses | null) => {
                return chaincodeStatus?.approvedChannels?.some(a => a.id === channelEntry._id) || false
            }
            const committedStatusChecker = (chaincodeStatus?: GatewayAPI | ChaincodeStatuses | null) => {
                return chaincodeStatus?.committedChannels?.some(a => a.id === channelEntry._id) || false
            }
            const latestSequenceStatusChecker = (chaincodeStatus?: GatewayAPI | ChaincodeStatuses | null) => {
                return chaincodeStatus?.committedChannels?.find(a => a.id === channelEntry._id)?.sequence === chaincodeStatus?.sequence || false
            }

            channelRecords.find(r => r.key === channelEntry._id)?.columns.push(
                { value: (
                    <StatusCheckCell statusChecker={approvedStatusChecker} node={peerNode} gatewayAPI={gatewayAPI} isMonitor={!peerNode} />
                )},
                { value: (
                    <MembershipApprovalsCell channel={channelEntry} node={peerNode} gatewayAPI={gatewayAPI} isMonitor={!peerNode} />
                )},
                { value: (
                    <StatusCheckCell statusChecker={committedStatusChecker} node={peerNode} gatewayAPI={gatewayAPI} isMonitor={!peerNode} />
                )},
                { value: (
                    <StatusCheckCell statusChecker={latestSequenceStatusChecker} checkSequenceChannelId={channelEntry._id} node={peerNode} gatewayAPI={gatewayAPI} isMonitor={!peerNode} />
                )}
            )
        }
    }

    const columnHeaders = [
        '',
        lt('name'),
        lt('admin'),
        lt('memberships'),
        lt('nodes'),
        lt('status')
    ]

    if (gatewayAPI) {
        columnHeaders.push(
            lt('chaincodeApproved'),
            lt('membershipApprovals'),
            lt('chaincodeCommitted'),
            lt('isLatestSequence')
        )
    }

    return (
        <DisplayTable {...{header}} {...{description}} columnHeaders={columnHeaders} emptyLabel={lt('noChannels')} records={channelRecords.splice(currentPage * rowsPerPage, (currentPage * rowsPerPage) + rowsPerPage)} actionFooter={pagination} />
    )
};

interface translations {
    name: string
    admin: string
    memberships: string
    nodes: string
    defaultDescription: string
    noChannels: string
    chaincodeApproved: string
    membershipApprovals: string
    chaincodeCommitted: string
    yes: string
    no: string
    isLatestSequence: string
    system: string
    status: string
}
const enTranslations: translations = {
    name: 'Name',
    admin: 'Admin',
    memberships: 'Memberships',
    nodes: 'Nodes',
    defaultDescription: 'A default channel exists for every Fabric environment.',
    noChannels: 'No channels.',
    chaincodeApproved: 'Chaincode Approved',
    membershipApprovals: 'Membership Approvals',
    chaincodeCommitted: 'Chaincode Committed',
    yes: 'Yes',
    no: 'No',
    isLatestSequence: 'Latest Sequence/Version',
    system: 'System',
    status: 'Status'
}
