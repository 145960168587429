import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { PasswordInput } from "../PasswordInput/PasswordInput";
import {
    TextField,
    Typography,
    Button,
    Grid,
    Backdrop,
    useTheme,
} from "@material-ui/core";
import { useTranslation, Trans } from 'react-i18next';
import { FormLink } from '../FormControls/FormLink';
import Cookies from 'js-cookie'
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { encodeQueryData } from '../../utils/StringUtils';
import { KaleidoLogoBlue } from '../Logos/KaleidoBlue';

interface Props {
    username: string,
    password: string,
    setPassword: React.Dispatch<React.SetStateAction<string>>
    setUsername: React.Dispatch<React.SetStateAction<string>>
    errorMessage: string
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
    loading: boolean
    handleLogin: () => void
    ssoGoogle: boolean
}

const AWS_MARKETPLACE_TOKEN_COOKIE = 'aws-marketplace-signup-token';
const AZURE_MARKETPLACE_TOKEN_COOKIE = 'azure-marketplace-signup-token';

export const LoginForm: React.FC<Props> = ({
    username,
    setUsername,
    loading,
    password,
    setPassword,
    errorMessage,
    setErrorMessage,
    handleLogin,
    ssoGoogle
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LoginForm', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LoginForm:${key}`, interpolate);
    const history = useHistory();
    const theme = useTheme();

    const [alias, setAlias] = useState('');
    const [checkingAlias, setCheckingAlias] = useState(false);

    const { search } = useLocation();
    const { path, redirect: redirectParam, return_to: zendeskGoToLink, oidc } = queryString.parse(search);
    const {
        [AWS_MARKETPLACE_TOKEN_COOKIE]: awsCookie,
        [AZURE_MARKETPLACE_TOKEN_COOKIE]: azureCookie
    } = Cookies.get();

    const isOidc = oidc ? true : false

    const getMessage = () => {
        if (isOidc) return lt('signInWithOidc')
        if (!!awsCookie) return lt('providerWelcome', { provider: 'AWS' });
        if (!!azureCookie) return lt('providerWelcome', { provider: 'Azure' });
        return lt('welcomeBack');
    }

    const handleSignUp = () => {
        history.push('/signup' + search);
    };

    const handleSSOGoogle = () => {
        let googleSSOEndpoint = '/sso/google';
        const searchParams = buildRedirectParams()
        const redirectURl = `${googleSSOEndpoint}${searchParams ? `?${searchParams}` : ''}`;
        window.location.href = redirectURl;
    };

    const setOidcLogin = () => {
        let s = search
        if (s && !s.includes('oidc')) {
            s = `${s}&oidc=true`
        } else if (!s) {
            s = '?oidc=true'
        }
        history.push('/login' + s)
    }

    const handleOidcLogin = () => {
        setCheckingAlias(true)
        fetch(`/api/ui/v2/sso/orgs/${encodeURIComponent(alias)}`)
            .then(r => r.json())
            .then(d => {
                if (d.redirect) {
                    const searchParams = buildRedirectParams()
                    const redirectURl = `${d.redirect}${searchParams ? `?${searchParams}` : ''}`;
                    window.location.href = redirectURl;
                } else {
                    throw new Error('Invalid organization alias')
                }
            })
            .catch(err => {
                setCheckingAlias(false)
                setErrorMessage(err.message)
            });
    }

    const buildRedirectParams = () => {
        const params: Partial<{ path: string | string[], redirect: string | string[], return_to: string | string[] }> = {};
        if (path) params.path = path;
        if (redirectParam) params.redirect = redirectParam;
        if (zendeskGoToLink) params.return_to = zendeskGoToLink;
        return encodeQueryData(params as { [key: string]: string });
    }

    const onLoginSubmit = () => {
        if (isOidc) {
            handleOidcLogin()
        } else {
            handleLogin()
        }
    }

    useEffect(() => {
        setErrorMessage('')
    }, [isOidc, setErrorMessage])

    const badAlias = !/^[a-zA-Z0-9-]*$/.test(alias)

    const oidcLogin = (
        <>
            <Grid item>
                <TextField
                    id='login-alias-input'
                    label={lt('alias')}
                    value={alias}
                    fullWidth
                    variant="outlined"
                    autoComplete='organization-alias'
                    helperText={badAlias && lt('invalidAlias')}
                    margin="none"
                    onChange={event => setAlias(event.target.value)}
                    error={badAlias}
                />
            </Grid>
            <Grid item>
                <Button
                    id='login-submit'
                    type="submit"
                    fullWidth
                    disabled={loading || !alias || badAlias || checkingAlias}
                    variant="contained"
                    size="large"
                    color='primary'>
                    {lt('loginWithOidc')}
                </Button>
            </Grid>
            <Grid item>
                <Trans i18nKey="LoginForm:noOidc"
                    components={[<FormLink onClick={() => history.push('/login' + search.replace('oidc=true', ''))} />]}>
                </Trans>
            </Grid>
        </>
    )

    const regularLogin = (
        <>
            <Grid item>
                <TextField
                    id='login-email-input'
                    label={lt('username')}
                    value={username}
                    fullWidth
                    variant="outlined"
                    autoComplete="username"
                    margin="none"
                    type="email"
                    onChange={event => setUsername(event.target.value)}
                />
            </Grid>
            <Grid item>
                <PasswordInput
                    id='login-loginpass-input'
                    fullWidth
                    variant="outlined"
                    label={lt('password')}
                    value={password}
                    autoComplete="current-password"
                    margin="none"
                    onChange={event => setPassword(event.target.value)} />
            </Grid>
            <Grid item>
                <FormLink to="/forgotpassword">
                    {lt('forgotPassword')}
                </FormLink>
            </Grid>
            <Grid item>
                <Button
                    id='login-submit'
                    type="submit"
                    fullWidth
                    disabled={loading}
                    variant="contained"
                    size="large"
                    color='primary'>
                    {lt('login')}
                </Button>
            </Grid>
            <Grid item>
                <Trans i18nKey="LoginForm:newHere"
                    components={[<FormLink onClick={handleSignUp} />]}>
                </Trans>
            </Grid>
            {ssoGoogle &&
                <Grid item>
                    <Button disabled={loading} startIcon={<img className={classes.signInWithGoogleButtonLogo} src={process.env.PUBLIC_URL + '/img/Login/Google.svg'} alt="" />}
                        className={classes.signInWithGoogleButton} variant="contained" fullWidth onClick={handleSSOGoogle} size="large">
                        {lt('signInWithGoogle')}
                    </Button>
                </Grid>}
            <Grid item>
                <Button disabled={loading}
                    className={classes.signInWithGoogleButton} variant="contained" fullWidth onClick={setOidcLogin} size="large">
                    {lt('signInWithOidc')}
                </Button>
            </Grid>
        </>
    )

    return (
        <>
            <form id="LoginForm" className={'Hubspot'} style={{ width: '300px' }} onSubmit={event => { event.preventDefault(); onLoginSubmit() }} >
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h5">{getMessage()}</Typography>
                    </Grid>
                    {errorMessage &&
                        <Grid item>
                            <Typography color="error" variant="subtitle1">
                                {errorMessage}
                            </Typography>
                        </Grid>
                    }
                    {!isOidc && regularLogin}
                    {isOidc && oidcLogin}
                </Grid>
            </form>
            <Backdrop transitionDuration={700} style={{ backgroundColor: '#F2F2F2', zIndex: theme.zIndex.tooltip + 1 }} open={loading}>
                <KaleidoLogoBlue />
            </Backdrop>
        </>
    )
}

const useStyles = makeStyles(() => ({
    signInWithGoogleButton: {
        textTransform: 'none',
        backgroundColor: 'white'
    },
    signInWithGoogleButtonLogo: {
        margin: '-8px',
    },
    signInWithOidcLogo: {
        marginRight: '8px',
        height: 30
    }
}));

interface translations {
    welcomeBack: string
    username: string
    password: string
    forgotPassword: string
    login: string
    newHere: string
    signInWithGoogle: string
    providerWelcome: string
    signInWithOidc: string
    alias: string
    noOidc: string
    invalidAlias: string
    loginWithOidc: string
}

const enTranslations: translations = {
    welcomeBack: 'Welcome back!',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'Forgot your password?',
    login: 'Login',
    newHere: 'New here? <0>Create an account</0>',
    signInWithGoogle: 'Sign in with Google',
    providerWelcome: 'Login or Register as the Kaleido user you wish to attach to your {{provider}} Marketplace subscription',
    signInWithOidc: 'Sign in with Enterprise SSO',
    alias: 'Organization alias',
    noOidc: 'No organization alias? <0>Login here</0>',
    invalidAlias: 'Invalid alias',
    loginWithOidc: 'Proceed to login'
}
