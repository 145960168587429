import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { ConsortiumZonesData, ConsortiumZonesQuery,
    EnvironmentZonesData, EnvironmentZonesQuery,
    EnvironmentData, EnvironmentQuery } from '../../../.../../models';
import { ConsortiumResourcesVars, ConsortiumResourceVars, EnvironmentResourcesVars } from '../../../interfaces'
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_CLOUD_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import { Step1 } from './Step1';
import { Step2 } from './Step2';


export const CreateCloudHsmWallet: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateCloudHsmWallet', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateCloudHsmWallet:${key}`, interpolate)

    type locationState = { name: string, membershipId: string, consortiumZoneId: string}
    const { org_id, consortium_id, environment_id, step } = useParams<any>();
    const history = useHistory<locationState>();

    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}`;

    const { 
        location: { 
            state: {
                name: serviceName,
                membershipId: serviceMembershipId,
                consortiumZoneId: serviceZoneId,
            } = { name: '',  membershipId: '',  consortiumZoneId: ''} 
        }
    } = history;
    
    const { 
        data: { 
            environmentZones
        } = { environmentZones: [] } 
    } = useQuery<EnvironmentZonesData, EnvironmentResourcesVars>(EnvironmentZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });


    const isMultiRegion = environment?.test_features?.multi_region ?? false
    const [name, setName] = useState((step && step === "2") ? serviceName as string : '');
    const [membershipId, setMembershipId] = useState((step && step === "2") ? serviceMembershipId as string : '');
    const [consortiumZoneId, setConsortiumZoneId] = useState(
        (step && step >= "2") ? serviceZoneId as string : (isMultiRegion && consortiumZones.length ? consortiumZones.find(z => z.default)?._id ?? '' : ''));

    const invalidStep = step !== "1" && step !== "2";
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || (createStep >= 1 && (!serviceName || !serviceMembershipId || (isMultiRegion && !serviceZoneId)))) return <Redirect to={`${cancelPath}/create/1`} />

    const stepComponents = [
        { step: lt('createWallet'), component: <Step1 {...{cancelPath}} {...{isMultiRegion}} {...{name}} 
        {...{setName}} {...{membershipId}} {...{setMembershipId}} {...{consortiumZones}} {...{consortiumZoneId}} {...{setConsortiumZoneId}} /> },
        { step: lt('selectConfiguration'), component: <Step2 {...{environmentZones}} {...{consortiumZones}} {...{name}} {...{membershipId}} {...{consortiumZoneId}} {...{cancelPath}} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createWallet')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createWallet: string,
    selectConfiguration: string
}
const enTranslations: translations = {
    createWallet: 'Create Wallet',
    selectConfiguration: 'Select Configuration'
}