import { gql } from '@apollo/client';

export type FireflyState = "uninitialized" | "initializing" | "started";
export type FireflyTokenPluginNames = 'erc1155' | 'erc20_erc721'
// INTERFACES

export interface FireflyInitialize {
    contract_address?: string
    channel?: string
    chaincode?: string
    plugins?: FireflyInitializePlugin[]
    namespace: string
}

export interface FireflyInitializeInput extends FireflyInitialize {
    prerequisiteInfo?: { // used to query the chaincode status before initializing fabric
        consortia_id: string
        environment_id: string
        node_id: string
        gateway_api_id?: string
    }
}

export interface FireflyNamespace {
    name: string
}

export interface FireflyNode {
    name: string
    registered: boolean
}

export interface FireflyOrg {
    name: string
    registered: boolean
}

export interface FireflyNamespaceDetails {
    namespace: FireflyNamespace
    node: FireflyNode
    org: FireflyOrg
    contract_address: string
}

export interface FireflyInitializePlugin {
    plugin?: 'fftokens'
    name?: FireflyTokenPluginNames
}

export interface FireflyInitializeData {
    initializeFireflyInstance: FireflyInitialize
}

export interface FireflyInitializeTokensData {
    initializeFireflyTokens: FireflyInitializePlugin
}

interface FireflyExtensions {
    key: 'tokens'
    value: { plugin: 'fftokens', name: FireflyTokenPluginNames }[]
}

export type FireflyExtensionsData = {
    fireflyExtensions: FireflyExtensions[]
}

export type FireflyNamespacesWithDetailsData = {
    fireflyNamespacesWithDetails: FireflyNamespaceDetails[]
}
interface IsFireflyInitialized {
    initialized: boolean
    readyForRegistration: boolean
    fireflyTokensErc1155: boolean
    totalNamespaces: number
    namespacesRegisteredCount: number
}

export type IsFireflyInitializedData = {
    isFireflyInitialized: IsFireflyInitialized
}

export interface FireflyInitializeVars {
    service_id: string
    fireflyInitialize: FireflyInitializeInput
}

export interface FireflyRegistrationData {
    fireflyRegistration: FireflyRegistrationStatus
}

export interface FireflyRegistrationStatus {
    success: boolean
}

export interface FireflyRegisterVars {
    service_id: string
    fireflyRegister: FireflyRegistrationInput
}

export interface FireflyRegistrationInput {
    namespace: string
    node_name: string
    org_name: string
    identity: string
}

export interface FireflyInitializeTokensVars {
    service_id: string
    fireflyInitializeTokens: FireflyInitializeTokensInput
}

export interface FireflyInitializeTokensInput extends FireflyInitializePlugin {}


// QUERIES & SUBSCRIPTIONS

export const InitializeFireflyInstanceMutation = gql`
    mutation initializeFireflyInstance($service_id: String!, $fireflyInitialize: FireflyInitializeInput!) {
        initializeFireflyInstance(service_id: $service_id, fireflyInitialize: $fireflyInitialize) {
            contract_address
            channel
            chaincode
            namespace
            plugins {
                plugin
                name
            }
            prerequisiteInfo {
                consortia_id
                environment_id
                node_id
                gateway_api_id
            }
        }
    }`;

export const RegisterFireflyMutation = gql`
    mutation fireflyRegistration($service_id: String!, $fireflyRegister: FireFlyRegistrationInput!) {
        fireflyRegistration(service_id: $service_id, fireflyRegister: $fireflyRegister) {
           success
        }
    }`;


export const FireflyInitializeTokensMutation = gql`
    mutation fireflyInitializeTokens($service_id: String!, $fireflyInitializeTokens: FireflyInitializePluginInput!) {
        initializeFireflyTokens(service_id: $service_id, fireflyInitializeTokens: $fireflyInitializeTokens) {
            plugin
            name
        }
    }`;

export const IsFireflyInitializedQuery = gql`
    query isFireflyInitialized($consortia_id: String!, $environment_id: String!, $id: String!) {
        isFireflyInitialized(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            initialized
            readyForRegistration
            fireflyTokensErc1155
            totalNamespaces
            namespacesRegisteredCount
        }
    }`;

export const FireflyExtensionsQuery = gql`
    query fireflyExtensions($service_id: String!) {
        fireflyExtensions(service_id: $service_id) {
            key
            value
        }
    }`;

export const FireflyNamespacesWithDetailsQuery = gql`
    query fireflyNamespacesWithDetails($service_id: String!) {
        fireflyNamespacesWithDetails(service_id: $service_id) {
            namespace {
                name
            }
            node {
                name
                registered
            }
            org {
                name
                registered
            }
            contract_address
        }
    }
`

export const FireflyNamespaceDetailQuery = gql`
    query fireflyNamespace($service_id: String!, $namespace_id: String!) {
        fireflyNamespace(service_id: $service_id, namespace_id: $namespace_id) {
            namespace {
                name
            }
            node {
                name
                registered
            }
            org {
                name
            }
        }
    }`;