import React from 'react';
import { AddressWidgetInfo } from '../../../models';
import { ListItemText, ListItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_ACCOUNTS_PATH } from '../../MainNav/SideNavs/AddressBook';
import { FormLink } from '../FormLink';

interface Props {
    addressWidgetInfo: AddressWidgetInfo
};

export const ShortenedHashAddress = ({ addressWidgetInfo }: Props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ShortenedHashAddress', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ShortenedHashAddress:${key}`, interpolate)
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const viewAddressDetailsUrl = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}` +
        `/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}/${addressWidgetInfo._id}`;

    return (
        <>
            {window.location.pathname !== viewAddressDetailsUrl &&
                <ListItem>
                    <ListItemText primary={lt('moreInformation')} secondary={
                        <span className={classes.linkContainer}>
                            <FormLink to={viewAddressDetailsUrl}>
                                {lt('viewAddressDetails')}
                            </FormLink>
                        </span>} />
                </ListItem>}
        </>
    );
};

const useStyles = makeStyles(() => ({
    linkContainer: {
        display: 'block',
        marginTop: 6
    }
}));

interface translations {
    moreInformation: string
    viewAddressDetails: string
}

const enTranslations: translations = {
    moreInformation: 'More Information',
    viewAddressDetails: 'View Address Details'
}