import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ConfigData, ConfigQuery, ConfigTypeTranslationsInterface, ConfigTypeTranslations } from '../../models/configs';
import { EnvironmentResourceVars } from '../../interfaces';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings';
import { CLOUDCONFIG_BASE_PATH } from '../../components/MainNav/SideNavs/CloudConfigs';
import CheckIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import { AlertDarkColors } from '../../utils/Colors';
import { ConfigsAttachedTable } from '../Configurations/ConfigsAttachedTable';
import { Alert } from '../../components/FormControls/Alert';

export const CloudConfigInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CloudConfigInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CloudConfigInfo:${key}`, interpolate);

    const {org_id, consortium_id, environment_id, config_id} = useParams<any>();
    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}`;

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading: configsLoading,
        data: {
            config
        } = { config: null }
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: config_id!
        }
    });

    const [ alertText, setAlertText ] = useState('');

    const infoList: CopyableSetting[] = [{
        title: lt('name'),
        displayValue: config?.name ?? '',
        disableCopy: true
    }, {
        title: lt('type'),
        displayValue: config?.type  ?? '',
        disableCopy: true
    }, {
        title: lt('membership'),
        displayValue: config?.membership.name ?? '',
        disableCopy: true
    }, {
        title: lt('dateCreated'),
        displayValue: new Date(config?.created_at || '').toLocaleString() ?? '',
        disableCopy: true
    }, {
        title: lt('provider'),
        displayValue: config?.details.provider ?? 'aws', //hard coded to aws for networking
        disableCopy: true
    }, {
        title: lt('id'),
        displayValue: config?._id ?? ''
    }]

    if(config?.details.region) {
        infoList.push({
            title: lt('region'),
            displayValue: config?.details.region ?? ''
        })
    }

    if(config?.details.bucket) {
        infoList.push({
            title: lt('bucket'),
            displayValue: config?.details.bucket ?? ''
        })
    }

    if(config?.details.bucket) {
        infoList.push({
            title: lt('environment_backup_target'),
            displayValue: config?.details.environment_backup_target ? lt('yes') : lt('no')
        })
    }

    if(config?.details.role_arn) {
        infoList.push({
            title: lt('roleArn'),
            displayValue: config?.details.role_arn ?? ''
        })
    }

    if(config?.details.external_id_seed) {
        infoList.push({
            title: lt('externalIdSeed'),
            displayValue: config?.details.external_id_seed ?? ''
        })
    }

    if(config?.details.user_id) {
        infoList.push({
            title: lt('userId'),
            displayValue: config?.details.user_id ?? ''
        })
    }

    if(config?.details.group) {
        infoList.push({
            title: lt('group'),
            displayValue: config?.details.group ?? ''
        })
    }

    if(config?.details.api_key) {
        infoList.push({
            title: lt('apiKey'),
            displayValue: config?.details.api_key ?? ''
        })
    }

    if(config?.details.master_key) {
        infoList.push({
            title: lt('masterKey'),
            displayValue: config?.details.master_key ?? ''
        })
    }

    if(config?.details.target_address) {
        infoList.push({
            title: lt('targetAddress'),
            displayValue: config?.details.target_address ?? ''
        })
    }
    if(config?.details.target_region) {
        infoList.push({
            title: lt('targetRegion'),
            displayValue: config?.details.target_region ?? ''
        })
    }
    if(config?.details.tenant_id) {
        infoList.push({
            title: lt('tenantId'),
            displayValue: config?.details.tenant_id ?? ''
        })
    }
    if(config?.details.vault_name) {
        infoList.push({
            title: lt('vaultName'),
            displayValue: config?.details.vault_name ?? ''
        })
    }

    if(config?.details.subscription_id) {
        infoList.push({
            title: lt('subscriptionId'),
            displayValue: config?.details.subscription_id ?? ''
        })
    }

    if(config?.details.allow_private !== null) {
        infoList.push({
            title: lt('allowPrivate'),
            displayValue: config?.details.allow_private ? <CheckIcon color={AlertDarkColors.green} /> : <CloseCircleIcon color={AlertDarkColors.red} /> ?? '',
            disableCopy: true
        })
    }
    
    if(config?.details.allow_public !== null) {
        infoList.push({
            title: lt('allowPublic'),
            displayValue: config?.details.allow_public ? <CheckIcon color={AlertDarkColors.green} /> : <CloseCircleIcon color={AlertDarkColors.red} /> ?? '',
            disableCopy: true
        })
    }


    if (!configsLoading && !config) return (<Redirect to={basePath} />)

    if(configsLoading) return <CircularProgress />

    return (
        <>
            <Grid container direction="column" spacing={3} alignItems="stretch" alignContent="stretch">
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">{lt('configInfo')}</Typography>
                    </Grid>
                </Grid>
                {alertText &&
                <Grid item>
                    <Alert severity="info" title={lt('info')} description={alertText} />
                </Grid>
                }
                <Grid item>
                    <CopyableSettings header={lt('information')} copyableList={infoList}/>
                </Grid>
                <Grid item>
                    <ConfigsAttachedTable setAlert={setAlertText}/>
                </Grid>
            </Grid>
        </>
    )
}

interface translations extends ConfigTypeTranslationsInterface {
    name: string,
    type: string,
    dateCreated: string,
    configInfo: string,
    information: string,
    provider: string,
    id: string,
    bucket: string,
    userId: string,
    roleArn: string,
    externalIdSeed: string,
    region: string,
    targetRegion: string,
    node: string,
    service: string,
    info: string,
    membership: string,
    group: string,
    allowPublic: string,
    allowPrivate: string,
    masterKey: string
    apiKey: string
    targetAddress: string
    vaultName: string
    subscriptionId: string
    tenantId: string
    environment_backup_target: string
    yes: string
    no: string
}

const enTranslations: translations = {
    ...ConfigTypeTranslations,
    name: 'Name',
    type: 'Type',
    dateCreated: 'Date Created',
    configInfo: 'Configuration Info',
    information: 'Information',
    provider: 'Provider',
    id: 'Id',
    bucket: 'Bucket',
    roleArn: 'Role ARN',
    externalIdSeed: 'External Id Seed',
    userId: 'User Id',
    region: 'Region',
    targetRegion: 'Region',
    node: 'Node',
    service: 'Service',
    info: 'Info',
    membership: 'Membership',
    group: 'Group',
    allowPrivate: 'Allow Private',
    allowPublic: 'Allow Public',
    masterKey: 'Master Key',
    apiKey: 'API Key',
    targetAddress: 'Target Address',
    vaultName: 'Vault Name',
    tenantId: 'Tenant Id',
    subscriptionId: 'Subscription Id',
    environment_backup_target: 'Environment Backup Target',
    yes: 'Yes',
    no: 'No'
}