import React from 'react'
import { Menu, MenuItem, Grid, Typography } from '@material-ui/core';
import {
    bindMenu,
    PopupState,
  } from 'material-ui-popup-state/hooks';

interface Props {
    popupState: PopupState,
    menuItems: Array<{icon?: JSX.Element, name: string, action: () => void}> 
}

export const ActionMenu = ({menuItems, popupState}: Props) => {
    

    const wrapActionWithPopupClose = (action: () => void) => {
        popupState.close()
        action()
    }

    return (
        <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            {
                menuItems.map((item, index)  => (
                    <MenuItem key={index} onClick={() => wrapActionWithPopupClose(item.action)}>
                        <Grid container justify="space-between" spacing={3} wrap="nowrap" alignItems="center">
                            <Grid item>
                                <Typography variant="body2">{item.name}</Typography>
                            </Grid>
                            { item.icon && 
                                <Grid item>
                                    {item.icon}
                                </Grid>
                            }
                        </Grid>
                    </MenuItem>
                ))
            }
        </Menu>
    )
}

