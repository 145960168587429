import React, { useState } from 'react';
import { Grid, Typography, TextField, MenuItem, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import {
    ContactInfoJobFunctionEnum,
    ContactInfoProperties,
    ContactInfoTranslations,
    ContactInfoTranslationsInterface,
    ContactInfo,
} from '../models/contactInfo';
import { PhoneNumberInput } from '../components/PhoneNumberInput/PhoneNumberInput';
import { isPhoneValid } from '../utils/StringUtils';

interface Props {
    handleSave: (properties: ContactInfoProperties) => Promise<void>
    contactInfo: ContactInfo | null
    loading?: boolean
}

export const UserInfoFormV2: React.FC<Props> = ({ handleSave, contactInfo, loading }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'UserInfoFormV2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`UserInfoFormV2:${key}`, interpolate);

    const { register, control, handleSubmit, watch } = useForm<ContactInfoProperties>();
    const data = watch();
    const disabled = Object.entries(data).find(([k, v]) => k !== 'phone' && !v) !== undefined;

    const [phone, setPhone] = useState(contactInfo?.phone ?? '');
    const phoneError = phone ? !isPhoneValid(phone) : false

    const onSubmitForm = (properties: ContactInfoProperties) => {
        properties.phone = phone
        handleSave(properties)
    }

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h5">{lt('header')}</Typography>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={6}>
                        <TextField required autoFocus inputRef={register({ required: true })} defaultValue={contactInfo?.firstname || ''}
                            disabled={contactInfo?.firstname ? true : false} label={lt('name')} name="firstname" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField required inputRef={register({ required: true })} defaultValue={contactInfo?.lastname || ''}
                            disabled={contactInfo?.lastname ? true : false} label={lt('lastName')} name="lastname" fullWidth variant="outlined" />
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField required inputRef={register({ required: true })} defaultValue={contactInfo?.company || ''}
                        disabled={contactInfo?.company ? true : false} label={lt('companyName')} name="company" fullWidth variant="outlined" />
                </Grid>
                <Grid item>
                    <PhoneNumberInput
                        onPhoneNumberChange={setPhone}
                        phoneNumber={phone}
                    />
                </Grid>
                {!contactInfo?.job_function ?
                    <Grid item>
                        <Controller
                            name="job_function"
                            rules={{ required: true }}
                            as={
                                <TextField required data-test="textField_selectJobFunction" select variant="outlined" fullWidth label={lt('jobReason')}>
                                    {(Object.keys(ContactInfoJobFunctionEnum) as (keyof typeof ContactInfoJobFunctionEnum)[]).map((jobFunction, index) => (
                                        <MenuItem key={`job-${index}`} value={ContactInfoJobFunctionEnum[jobFunction]}>{lt(jobFunction)}</MenuItem>
                                    ))}
                                </TextField>
                            }
                            control={control}
                            defaultValue=""
                        />
                    </Grid> : null}
                <Grid item>
                    <Button disabled={loading || disabled || phoneError} size="large" type="submit" fullWidth variant="contained" color="primary">{lt('next')}</Button>
                </Grid>
            </Grid>
        </form>
    )
};

interface translations extends ContactInfoTranslationsInterface {
    header: string
    companyName: string
    jobReason: string
    name: string
    lastName: string
    next: string
    phone: string
    phoneError: string
}

const enTranslations: translations = {
    ...ContactInfoTranslations,
    name: 'First Name',
    lastName: 'Last Name',
    header: 'Finish setting up your profile',
    companyName: 'Company Name',
    jobReason: 'Reason for using Kaleido',
    next: 'Complete Profile',
    phone: 'Phone',
    phoneError: 'Invalid Phone'
}
