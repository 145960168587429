import React, { useState } from 'react';
import { makeStyles, Typography, Button, Grid, CircularProgress } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { PlansEnum, OrganizationData, OrganizationVars, OrganizationQuery, PlansTranslations, EnPlansTranslations, PlanName } from '../../../models';
import { useQuery, useApolloClient } from '@apollo/client';
import { BillingProviderData, BillingProviderQuery } from '../../../models';
import { useParams, useHistory } from 'react-router-dom';
import { Alert } from '../../../components/FormControls/Alert';
import { ORGS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_PAYMENT_METHOD_PATH, SETUP_BILLING_PROVIDER_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, CHANGE_PLAN_PATH } from '../../../components/ManageOrgNav/ManageOrgNav';
import { FormLink } from '../../../components/FormControls/FormLink';
import { PlanCard } from '../../../components/PlanCard/PlanCard';
import { OrgResourceVars } from '../../../interfaces';
import { HelpCenter, HelpCenterTab } from '../../../components/HelpCenter/HelpCenter';
import { Category } from '../../../components/HelpCenter/ContactUs/ContactUs';

interface Props {
    setPlan: React.Dispatch<React.SetStateAction<PlanName>>
    supportLevel: Number
    setSupportLevel: React.Dispatch<React.SetStateAction<Number>>
}

export const Step1 = ({ setPlan, supportLevel, setSupportLevel }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChangePlanStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChangePlanStep1:${key}`, interpolate);
    const classes = useStyles();
    const { org_id } = useParams<any>();
    const history = useHistory();
    const [helpCenter, setHelpCenter] = useState(false);
    const [preSelectedCategory, setPreSelectedCategory] = useState<Category>('salesEnquiry');
    const client = useApolloClient();

    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id! } })!;
    const { organization: parentOrg } = organization.master_org
        ? client.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: organization.master_org } })!
        : { organization: undefined };

    // We want to know the billing provider of the parent org if there is one, or this org if there is not.
    const {
        loading,
        data: {
            billingProvider
        } = { billingProvider: undefined },
    } = useQuery<BillingProviderData, OrgResourceVars>(BillingProviderQuery, { variables: { org_id: parentOrg?._id || org_id } });

    if (loading || !billingProvider) {
        return <CircularProgress />
    }

    const handlePlanSelect = (plan: PlanName) => {
        if (plan === 'enterprise') {
            setPreSelectedCategory('enterprisePlanEnquiry');
            setHelpCenter(true);
        } else {
            setPlan(plan);
            if ((plan === 'starter' || plan === 'team') && supportLevel !== 100) {
                setSupportLevel(100);
            }
            if (plan === 'business' && supportLevel === 100) {
                setSupportLevel(200);
            }
            history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/2`, { plan });
        }
    };

    const isButtonDisabled = (plan: PlanName) => {
        let disabled = false;
        if (organization.plan === plan) {
            disabled = true;
        } else if (billingProvider.type === 'none') {
            disabled = true;
        }
        return disabled;
    }

    return (
        <>
            {billingProvider.type === 'none' &&
                <Grid className={classes.alertContainer} container justify="center" direction="column">
                    <Grid item>
                        <Alert title={lt('addBillingProvider')} description={lt('addBillingProviderDescription')} severity={"info"}
                            button={<Button onClick={() => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}/${SETUP_BILLING_PROVIDER_PATH}/1`)}
                                variant="outlined" style={{ color: 'white', borderColor: 'white' }}>{lt('addBillingProvider')}</Button>} />
                    </Grid>
                </Grid>
            }
            <Grid container justify="center" spacing={3}>
                {!parentOrg && <Grid item>
                    <PlanCard
                        plan="starter"
                        color="#462DE0"
                        buttonLabel={organization.plan === 'starter' ? lt('currentPlan') : undefined}
                        name={lt(PlansEnum['starter'])}
                        feesLine1={lt('noUsageFeesAreIncluded')}
                        description={lt('starterDescription')}
                        onClick={() => handlePlanSelect('starter')}
                        disabled={isButtonDisabled('starter')}
                     />
                </Grid>}
                <Grid item>
                    <PlanCard
                        plan="team"
                        color="#A73DD5"
                        name={lt(PlansEnum['team'])}
                        feesLine1={lt('usageFeesStartAt')}
                        feesLine2={lt('$015perNodeHour')}
                        buttonLabel={organization.plan === 'team' ? lt('currentPlan') : undefined}
                        description={lt('teamDescription')}
                        onClick={() => handlePlanSelect('team')}
                        disabled={isButtonDisabled('team')}
                     />
                </Grid>
                <Grid item>
                    <PlanCard
                        plan="business"
                        color="#FF396A"
                        name={lt(PlansEnum['business'])}
                        feesLine1={lt('usageFeesStartAt')}
                        buttonLabel={organization.plan === 'business' ? lt('currentPlan') : undefined}
                        feesLine2={lt('$055PerNodeHour')}
                        description={lt('businessDescription')}
                        onClick={() => handlePlanSelect('business')}
                        disabled={isButtonDisabled('business')}
                     />
                </Grid>
                <Grid item>
                    <PlanCard
                        plan="enterprise"
                        color="#FFCA00"
                        name={lt(PlansEnum['enterprise'])}
                        feesLine1={lt('enterpriseFees1')}
                        feesLine2={lt('enterpriseFees2')}
                        description={lt('enterpriseDescription')}
                        onClick={() => handlePlanSelect('enterprise')}
                        buttonLabel={lt('contactUs')}
                        disabled={isButtonDisabled('enterprise')}
                     />
                </Grid>
            </Grid>
            <Typography className={classes.trialMessage} variant="body2">
                <Trans i18nKey="ChangePlanStep1:trialMessage"
                    components={[<FormLink onClick={() => { setPreSelectedCategory('salesEnquiry'); setHelpCenter(true) }} />]}>
                </Trans>
            </Typography>
            <HelpCenter
                isOpen={helpCenter}
                setOpen={setHelpCenter}
                tab={HelpCenterTab.CONTACT_US}
                preSelectedCategory={preSelectedCategory}
            />
            <Button className={classes.enterPriseButton} data-test="enterprisePlanSelection"
                onClick={() => { setPlan('enterprise'); setSupportLevel(300); history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/3`) }}><></></Button>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    alertContainer: {
        maxWidth: 1150,
        marginBottom: 40,
        marginLeft: 30,
        marginRight: 30
    },
    trialMessage: {
        marginTop: 40,
        marginBottom: 40
    },
    enterPriseButton: {
        display: 'none'
    }
}));

interface translations extends PlansTranslations {
    free: string
    starterDescription: string
    twoSmallNodesOnly: string
    twoServicesOnly: string
    basicSupportOnly: string
    CentralizedNetwork: string
    LimitedTransactions: string
    NoDisasterRecovery: string
    NoSupportSLA: string
    currentPlan: string
    learnMore: string
    viewDetails: string
    noUsageFeesAreIncluded: string
    $015PerHour: string
    usageFeesStartAt: string
    $015perNodeHour: string
    teamDescription: string
    unlimitedSmallNodes: string
    unlimitedServices: string
    $055PerHour: string
    $055PerNodeHour: string
    businessDescription: string
    unlimitedNodeSizes: string
    allSupportLevels: string
    decentralizedNetwork: string
    higherTransactions: string
    disasterRecovery: string
    supportForSLAAvailable: string
    contactUs: string
    enterpriseFees1: string
    enterpriseFees2: string
    enterpriseDescription: string
    everythingInBusiness: string
    privateStack: string
    cloudHSMKeyManagement: string
    federatedLogin: string
    multiFactorAuth: string
    select: string
    trialMessage: string
    addBillingProvider: string
    addBillingProviderDescription: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    free: 'Free',
    starterDescription: 'Get started on your blockchain journey with our free plan.',
    twoSmallNodesOnly: '2 Small Nodes Only',
    twoServicesOnly: '2 Services Only',
    basicSupportOnly: 'Basic Support Only',
    CentralizedNetwork: 'Centralized Network',
    LimitedTransactions: 'Limited Transctions',
    NoDisasterRecovery: 'No Disaster Recovery',
    NoSupportSLA: 'No Support SLA',
    currentPlan: 'Current Plan',
    learnMore: 'Learn More',
    viewDetails: 'View Details',
    noUsageFeesAreIncluded: 'No usage fees are included.',
    $015PerHour: 'Nodes $0.15/hr',
    usageFeesStartAt: 'Usage fees start at',
    teamDescription: 'Grow your projects with expanded resources',
    $015perNodeHour: '$ 0.15 per node hour',
    unlimitedSmallNodes: 'Unlimited Small Nodes',
    unlimitedServices: 'Unlimited Services',
    $055PerHour: 'Nodes $0.55/hr',
    $055PerNodeHour: '$0.55 per node hour',
    businessDescription: 'Production-ready with enhanced services and support levels.',
    unlimitedNodeSizes: 'Unlimited Nodes/Sizes',
    allSupportLevels: 'All Support Levels',
    decentralizedNetwork: 'Decentralized Network',
    higherTransactions: 'Higher Transactions',
    disasterRecovery: 'Disaster Recovery',
    supportForSLAAvailable: 'Support SLA Available',
    contactUs: 'Contact Us',
    enterpriseFees1: 'Usage fees comes with',
    enterpriseFees2: 'additional savings',
    enterpriseDescription: 'Production-ready with full support and SLA guarantees',
    everythingInBusiness: 'Everything in Business',
    privateStack: 'PrivateStack',
    cloudHSMKeyManagement: 'Cloud HSM Key Management',
    federatedLogin: 'Federated Login',
    multiFactorAuth: 'Multi-Factor Auth',
    select: 'Select',
    trialMessage: 'To enquire about a trial of a paid plan, please <0>contact us</0>.',
    addBillingProvider: 'Add Billing Provider',
    addBillingProviderDescription: 'Before you can upgrade to a paid plan, we\'ll need to add a payment method first'
}