import React from 'react';
import { makeStyles, Grid, Typography, MobileStepper, Button, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from 'mdi-react/KeyboardArrowRightIcon';
import KeyboardArrowLeftIcon from 'mdi-react/KeyboardArrowLeftIcon';

export const G2Crowd = () => {
    const theme = useTheme();

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'G2Crowd', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`G2Crowd:${key}`, interpolate);

    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const makeReview = (i: 1 | 2 | 3) => (
        <div className={classes.review}>
            <Grid item>
                <Typography gutterBottom className={classes.title} variant="h4">{lt(`quote${i}` as keyof translations)}</Typography>
            </Grid>
            <Grid item>
                <Typography gutterBottom className={classes.title} variant="h6">{lt(`quote${i}Author` as keyof translations)}</Typography>
            </Grid>
        </div>
    )

    return (
        <>
            <Grid item>
                <img src={process.env.PUBLIC_URL + '/img/Login/G2_Crowd_logo.svg'} alt="" className={classes.g2Logo} />
            </Grid>
            <Grid item>
                <Typography gutterBottom className={classes.title} variant="h4">{lt('g2Crowd')}</Typography>
            </Grid>
            <Grid item container spacing={1} justify="center" alignItems="center">
                <Grid item>
                    <img src={process.env.PUBLIC_URL + '/img/Login/Icon-Star-Full.svg'} alt="" className={classes.illustration} />
                </Grid>
                <Grid item>
                    <img src={process.env.PUBLIC_URL + '/img/Login/Icon-Star-Full.svg'} alt="" className={classes.illustration} />
                </Grid>
                <Grid item>
                    <img src={process.env.PUBLIC_URL + '/img/Login/Icon-Star-Full.svg'} alt="" className={classes.illustration} />
                </Grid>
                <Grid item>
                    <img src={process.env.PUBLIC_URL + '/img/Login/Icon-Star-Full.svg'} alt="" className={classes.illustration} />
                </Grid>
                <Grid item>
                    <img src={process.env.PUBLIC_URL + '/img/Login/Icon-Star-3Q.svg'} alt="" className={classes.illustration} />
                </Grid>
            </Grid>
            {activeStep === 0 && makeReview(1)}
            {activeStep === 1 && makeReview(2)}
            {activeStep === 2 && makeReview(3)}
            <Grid item>
                <MobileStepper className={classes.stepper}
                    classes={{
                        dotActive: classes.dotActive
                    }}
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === 2}>
                        {theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                        </Button>
                    }
                    />
            </Grid>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    title: {
        maxWidth: '550px',
        textAlign: 'center',
        color: 'white'
    },
    review: {
        marginTop: theme.spacing(1),
        minHeight: '175px'
    },
    illustration: {
        maxWidth: '500px'
    },
    g2Logo: {
        height: '70px',
        width: '70px'
    },
    stepper: {
        backgroundColor: theme.palette.primary.main,
    },
    dotActive: {
        backgroundColor: 'white'
    }
}));

interface translations {
    g2Crowd: string
    quote1: string
    quote1Author: string
    quote2: string
    quote2Author: string
    quote3: string
    quote3Author: string
}

const enTranslations: translations = {
    g2Crowd: 'Ranked #1 on G2crowd.',
    quote1: '"Kaleido was a great way to jump start our enterprise blockchain solution."',
    quote1Author: 'Jon L, Founder/CTO',
    quote2: '"Super user friendly and easy to implement!"',
    quote2Author: 'Maxime P, Founder/CTO',
    quote3: '"Gamechanger! Kaleido offering is 2 generations ahead of any other blockchain technology offering."',
    quote3Author: 'Management Consultant',
}
