import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import { AccountsData, AccountsQuery, Account } from '../../../models'
import { useTranslation } from 'react-i18next';
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { DisplayTable } from '../../../components/DisplayWrappers';
import { capitalize } from '../../../utils/StringUtils';
import AccountBalanceWalletOutlinedIcon from 'mdi-react/WalletIcon';
import { ShortenedHash } from '../../../components/FormControls/ShortenedHash';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../../components/MainNav/SideNavs/AddressBook';
import { MEMBERSHIP_SIGNING_ACCOUNTS_PATH } from '../../../components/MembershipNav/MembershipNav';


export const MemberSigningAccounts: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'MemberSigningAccounts', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MemberSigningAccounts:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, membership_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData>(AccountsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-and-network'
    });

    if (loading && accounts.length === 0) {
        return <CircularProgress />
    }

    const columnHeaders = [
        '',
        lt('address'),
        lt('type'),
        lt('created')
    ];

    const includeAccount = (account: Account) => {
        return account.membership_id === membership_id || 
            (membership_id === SYSTEM_MEMBERSHIP && 
                (account.membership_id === 'sys--mon' || account.membership_id === environment_id)) // i dont like this
    }

    const accountRecords = accounts.filter(account => includeAccount(account)).map(account => ({
        key: account._id,
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membership_id}/${MEMBERSHIP_SIGNING_ACCOUNTS_PATH}/${account._id}`),
        columns: [
            { value: <AccountBalanceWalletOutlinedIcon /> },
            { value: <ShortenedHash address={account._id} retry /> },
            { value: capitalize(account.account_type) },
            { value: new Date(account.created_at).toLocaleString() },
        ]
    }));

    return (
        <Grid container direction={"column"} spacing={3}>
            <Grid item>
                <Typography variant="h5">
                    {lt('signingAccounts')}
                </Typography>
            </Grid>
            <Grid item>
                {accountRecords.length > 0 ? <DisplayTable header={lt('accounts')} columnHeaders={columnHeaders} records={accountRecords} />
                    : <Typography >{lt('noAccounts')}</Typography>}
            </Grid>
        </Grid>
    )
};

interface translations {
    signingAccounts: string,
    accounts: string,
    noAccounts: string,
    address: string,
    type: string,
    created: string,
}

const enTranslations: translations = {
    signingAccounts: 'Signing Accounts',
    accounts: 'Accounts',
    noAccounts: 'Member has no accounts',
    address: 'Address',
    type: 'Type',
    created: 'Date created',
}