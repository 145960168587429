import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { ConsortiumResourcesVars, ConsortiumResourceVars } from '../interfaces'
import { MembershipOwnedResource } from './memberships';

export type ContractProjectType = "github" | "upload" | "precompiled" | "corda_jar" | "fabric_precompiled_go" | "fabric_upload_node"

export const isCordaPromotable = (isCorda: boolean, cpt?: ContractProjectType) => isCorda && cpt === 'corda_jar'
export const isFabricPromotable = (isFabric: boolean, cpt?: ContractProjectType) => isFabric && (cpt?.startsWith('fabric'))
export const isEthPromotable = (isEthereum: boolean, cpt?: ContractProjectType) => isEthereum && (cpt === 'github' || cpt === 'upload' || cpt === 'precompiled')

export interface ContractProject extends MembershipOwnedResource {
    _id: string
    created_at: string
    consortia_id: string
    type: ContractProjectType
    name: string
    description?: string,
    state: "deleted" | null
}

export interface ContractProjectData {
    contractProject: ContractProject
}

export interface ContractProjectsData {
    contractProjects: ContractProject[]
}

export interface UpdateContractProjectVars extends ConsortiumResourceVars {
    contractProject: {
        name?: ContractProject['name'],
        description?: ContractProject['description']
    }
}

export interface CreateContractProjectVars extends ConsortiumResourcesVars {
    contractProject: {
        name: ContractProject['name'],
        description?: ContractProject['description'],
        membership_id: ContractProject['membership_id'],
        type: ContractProject['type'],
    }
}

export interface CreateContractProjectData {
    createContractProject: ContractProject
}

export const ContractProjectFields = ` 
    fragment ContractProjectFields on ContractProject {
        _id
        created_at
        consortia_id
        membership_id
        type
        name
        description
        membership @client
        state
    }`

export const ContractProjectsQuery = gql`
    ${ContractProjectFields}
    query getContractProjects($consortia_id: String!) {
        contractProjects(consortia_id: $consortia_id) {
            ...ContractProjectFields
        }
    }`

export const ContractProjectQuery = gql`
    ${ContractProjectFields}
    query getContractProject($consortia_id: String!, $id: String!) {
        contractProject(consortia_id: $consortia_id, id: $id) {
            ...ContractProjectFields
        }
    }`

export const ContractProjectsSubscription = gql`
    ${ContractProjectFields}
    subscription onContractProjectsChanged($consortia_id: String!) {
        contractProject(consortia_id: $consortia_id) {
            ...ContractProjectFields
        }
    }`

export const CreateContractProjectMutation = gql`
    ${ContractProjectFields}
    mutation createContractProject($consortia_id: String!, $contractProject: CreateContractProjectInput!) {
        createContractProject(consortia_id: $consortia_id, contractProject: $contractProject) {
            ...ContractProjectFields
        }
    }`;

export const UpdateContractProjectMutation = gql`
    ${ContractProjectFields}
    mutation updateContractProject($consortia_id: String!, $id: String!, $contractProject: UpdateContractProjectInput!) {
        updateContractProject(consortia_id: $consortia_id, id: $id, contractProject: $contractProject) {
            ...ContractProjectFields
        }
    }`;

export const DeleteContractProjectMutation = gql`
    mutation deleteContractProject($consortia_id: String!, $id: String!) {
        deleteContractProject(consortia_id: $consortia_id, id: $id)
    }`;

export function MakeContractProjectCreateMutationOptions(
    variables: CreateContractProjectVars,
    overrideOptions?: MutationFunctionOptions<CreateContractProjectData, CreateContractProjectVars>): MutationFunctionOptions<CreateContractProjectData, CreateContractProjectVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const contractProject = data?.createContractProject
            if (contractProject) {
                const { contractProjects } = cache.readQuery<ContractProjectsData>({ query: ContractProjectsQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!contractProjects.find(a => a._id === contractProject._id)) {
                    cache.writeQuery({
                        variables,
                        query: ContractProjectsQuery,
                        data: { contractProjects: contractProjects.concat(contractProject) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

export function MakeContractProjectsSubscriptionOptions(
    variables: ConsortiumResourcesVars,
    overrideOptions?: SubscriptionHookOptions<ContractProjectData>
    ): SubscriptionHookOptions<ContractProjectData> {
        return { ...{
            variables,
            onSubscriptionData: ({client, subscriptionData}) => {
                // Get the servicesChanged object from the event
                const { 
                    data: { 
                        contractProject
                    } = { contractProject: null }
                } = subscriptionData
    
                if (!contractProject) return
    
                // Get current list of contractProjects from the cache
                // assert that contractProjects must exist since this function throws if it doesnt
                const { contractProjects: existingContractProjects } = client.readQuery<ContractProjectsData>({ 
                    query: ContractProjectsQuery, 
                    variables 
                })!
                
                // if it already exists in the store, (its an update), apollo automagically updated the cache
                const cachedContractProjects = existingContractProjects.find(c => c._id === contractProject._id)
    
                // but, if it came back as deleted, we need to remove it from the cache manually
                if (cachedContractProjects) {
                    if (cachedContractProjects.state === 'deleted') {
                        client.cache.evict({id: `ContractProject:${cachedContractProjects._id}`, broadcast: false})
                    }
                    client.writeQuery<ContractProjectsData>({ 
                        query: ContractProjectsQuery, 
                        data: Object.assign({}, existingContractProjects, {
                            contractProjects: [...existingContractProjects.filter(c => c.state !== 'deleted')]
                        }),
                        variables
                    })
                    return 
                }
    
                // in case of duplicate events, never add a deleted one into the cache
                if (contractProject.state === 'deleted') return
    
                // if it doesnt exist in the store, (it's been added), so we need to add it in manually
                client.writeQuery<ContractProjectsData>({ 
                    query: ContractProjectsQuery, 
                    data: Object.assign({}, existingContractProjects, {
                        contractProjects: [...existingContractProjects, contractProject]
                    }),
                    variables
                })
            }
        }, ...overrideOptions }
}

export interface ContractProjectTypeTranslations {
    github: string
    precompiled: string
    upload: string
    corda_jar: string
    fabric_precompiled_go: string
    fabric_upload_node: string
}

export const EnContractProjectTypeTranslations: ContractProjectTypeTranslations = {
    github: 'Github',
    precompiled: 'Precompiled',
    upload: 'Upload',
    corda_jar: 'Corda Jar Files',
    fabric_precompiled_go: 'Precompiled Go',
    fabric_upload_node: 'Zipped Node.js Project Directory'
}