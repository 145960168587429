import React from 'react';
import { AddressWidgetInfo } from '../../../models';
import { ListItemText, ListItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../MainNav/SideNavs/AddressBook';
import { DATA_CONTRACTS, ENVIRONMENT_DATA_EXPLORER } from '../../MainNav/SideNavs/DataExplorer';
import { FormLink } from '../FormLink';

interface Props {
    addressWidgetInfo: AddressWidgetInfo
};

export const ShortenedHashContract = ({ addressWidgetInfo }: Props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ShortenedHashContract', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ShortenedHashContract:${key}`, interpolate)
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const baseUrl = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    const viewContractDetailsUrl = baseUrl + `/${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}/${addressWidgetInfo._id}`;
    const viewAddressBookEntryUrl = baseUrl + `/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/` +
        `${addressWidgetInfo.membership_id === environment_id ? SYSTEM_MEMBERSHIP : addressWidgetInfo.membership_id}`;

    return (
        <>
            <ListItem>
                <ListItemText primary={lt('moreInformation')} secondary={
                    <>
                        {window.location.pathname !== viewContractDetailsUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewContractDetailsUrl}>
                                    {lt('viewContractDetails')}
                                </FormLink>
                            </span>}
                        {window.location.pathname !== viewAddressBookEntryUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewAddressBookEntryUrl}>
                                    {lt('viewDeployerAddressBookEntry')}
                                </FormLink>
                            </span>}
                    </>} />
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(() => ({
    linkContainer: {
        display: 'block',
        marginTop: 6
    }
}));

interface translations {
    supply: string
    moreInformation: string
    viewContractDetails: string
    viewDeployerAddressBookEntry: string
}

const enTranslations: translations = {
    supply: 'Supply',
    moreInformation: 'More Information',
    viewContractDetails: 'View Contract Details',
    viewDeployerAddressBookEntry: 'View Deployer Address Book Entry'
}