import { useApolloClient, useMutation } from '@apollo/client';
import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../../../components/DialogWrappers';
import { SessionData } from '../../../interfaces';
import { ConfirmMFAMutation, DraftMFAMutation, MFAConfirmationData, MFADraftData, MFADraftVars, MFAInput, MFAVars } from '../../../models/mfa';
import { SessionQuery } from '../../../queries/Session';

interface Props {
    setupMFADialogOpen: boolean
    setSetupMFADialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    mfaStatusChanged: () => Promise<void>
    isReset?: boolean
}

const downloadFile = (filename: string, data: string) => {
    let blob = new Blob([data], { type: 'text/plain' });
    if (!!window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    }
    else {
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}

const RECOVERY_FILE_NAME = 'Kaleido MFA Recovery Codes';

export const ProfileSettingsSetupMFA: React.FC<Props> = ({ setupMFADialogOpen, setSetupMFADialogOpen, mfaStatusChanged, isReset = false }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ProfileSettingsSetupMFA', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ProfileSettingsSetupMFA:${key}`, interpolate);
    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;
    const classes = useStyles();

    const [step, setStep] = useState<'reset' | 'draft' | 'recoveryCodes'>('draft');
    const [secret, setSecret] = useState<string | undefined>();
    const [confirmationCode, setConfirmationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [recoveryCodes, setRecoveryCodes] = useState<string[]>();
    const [code, setCode] = useState('');
    const [codesCopiedOrDownloaded, setCodesCopiedOrDownloaded] = useState(false);

    const [draftMAF] = useMutation<MFADraftData, MFADraftVars>(DraftMFAMutation);
    const [confirmMFA] = useMutation<MFAConfirmationData, MFAVars>(ConfirmMFAMutation);

    useEffect(() => {
        if (setupMFADialogOpen) {
                setErrorMessage('');
                setConfirmationCode('');
                setCodesCopiedOrDownloaded(false);
            if(isReset) {
                setStep('reset');
            } else {
                draftMAF().then(result => {
                    setSecret(result.data?.draftMFA.secret);
                }).catch(err => {
                    setErrorMessage(err.toString());
                });
                setStep('draft');
            }
        }
    }, [draftMAF, setupMFADialogOpen, isReset]);

    const handleDownloadCodes = () => {
        if (recoveryCodes) {
            setCodesCopiedOrDownloaded(true);
            downloadFile(RECOVERY_FILE_NAME, recoveryCodes.join('\n'))
        }
    };

    const setupMFAControlsWrapper = (
        <Grid container direction="column" spacing={3}>
            {errorMessage &&
                <Grid item>
                    <Typography variant="body1" color="error">{errorMessage}</Typography>
                </Grid>}
            {step === 'reset' && (
                <Grid item container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="body1">{lt('resetDescription')}</Typography>
                    </Grid>
                    <Grid item>
                        <TextField value={code} onChange={event => setCode(event.target.value)} variant="outlined" label={lt('code')} fullWidth />
                    </Grid>
                </Grid>
            )}
            {step === 'draft' && secret &&
                <Grid item container direction="column" spacing={3}>
                    <Grid item>
                        {lt('introduction')}
                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item container sm={6} justify="center">
                            <Grid item >
                                <QRCode size={160} value={`otpauth://totp/Kaleido:${session.email}?secret=${secret}&issuer=Kaleido`} />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems="center" sm={6} spacing={1} wrap="nowrap" justify="center" direction="column">
                            <Grid item>
                                <TextField inputProps={{ readOnly: true, className: classes.codeInput }} className={classes.mfaCode} variant="outlined" value={secret} multiline />
                            </Grid>
                            <Grid item>
                                <CopyToClipboard text={secret}>
                                    <Button variant="outlined">{lt('copyCode')}</Button>
                                </CopyToClipboard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {lt('enterCode')}
                    </Grid>
                    <Grid item>
                        <TextField label={lt('confirmationCode')} variant="outlined" fullWidth value={confirmationCode}
                            onChange={event => setConfirmationCode(event.target.value)} />
                    </Grid>
                </Grid>}
            {step === 'recoveryCodes' && recoveryCodes &&
                <Grid item container direction="column" spacing={3}>
                    <Grid item>
                        {lt('recoveryCodeSuggestion')}
                    </Grid>
                    <Grid item container alignItems="center" justify="center" spacing={1} wrap="nowrap">
                        <Grid item container direction="column" spacing={1}>
                            {recoveryCodes.slice(0, 5).map(code => (
                                <Grid item>
                                    <TextField className={classes.recoveryCodesComponent} size="small" inputProps={{ className: classes.codeInput }} fullWidth variant="outlined" value={code} multiline InputProps={{ readOnly: true }} />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item container direction="column" spacing={1}>
                            {recoveryCodes.slice(5).map(code => (
                                <Grid item>
                                    <TextField className={classes.recoveryCodesComponent} size="small" inputProps={{ className: classes.codeInput }} fullWidth variant="outlined" value={code} multiline InputProps={{ readOnly: true }} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} justify="center" alignItems="center">
                        <Grid item>
                            <CopyToClipboard text={recoveryCodes.join('\n')} onCopy={() => setCodesCopiedOrDownloaded(true)}>
                                <Button color="primary" className={classes.actionButton} variant="contained">{lt('copy')}</Button>
                            </CopyToClipboard>
                        </Grid>
                        <Grid item>
                            <Button color="primary" className={classes.actionButton} variant="contained" onClick={handleDownloadCodes}>{lt('download')}</Button>
                        </Grid>
                    </Grid>
                </Grid>}
        </Grid>
    );

    const handleNext = async () => {
        setErrorMessage('');
        if (step === 'draft') {
            try {
                const result = await confirmMFA({ variables: { mfaInput: { token: confirmationCode } } });
                setRecoveryCodes(result.data?.confirmMFA.recovery_codes);
                setStep('recoveryCodes');
                await mfaStatusChanged();
            } catch (err) {
                setErrorMessage(err.toString());
            }
        } else if (step === 'reset') {
            try {
                let mfaInput: MFAInput = {};
                if (code.length === 6) {
                    mfaInput.token = code;
                } else {
                    mfaInput.recovery_code = code;
                }
                const draft = await draftMAF({ variables: { mfaInput } });
                setSecret(draft.data?.draftMFA.secret);
                setStep('draft');
            } catch (err) {
                setErrorMessage(err.toString());
            }
        } else {
            setSetupMFADialogOpen(false);
        }
    };

    return (
        <FormDialog
            width="sm"
            header={step === 'reset' ? lt('resetAuthenticationApp') : lt('setupAuthenticationApp')}
            open={setupMFADialogOpen}
            setOpen={setSetupMFADialogOpen}
            controlsWrapper={setupMFAControlsWrapper}
            saveText={lt(['draft', 'confirm', 'reset'].includes(step) ? 'confirm' : 'close')}
            saveDisabled={step === 'reset' ? !code : confirmationCode.length !== 6}
            closeDialogAfterSave={false}
            onSave={handleNext}
            cancelText={step === 'recoveryCodes' ? lt('close') : undefined}
            cancelDisabled={step === 'recoveryCodes' && !codesCopiedOrDownloaded}
            hideSave={step === 'recoveryCodes'}
        />
    );
}

const useStyles = makeStyles(() => ({
    actionButton: {
        minWidth: '110px'
    },
    mfaCode: {
        minWidth: '250px'
    },
    recoveryCodesComponent: {
        minWidth: '260px'
    },
    codeInput: {
        textAlign: 'center',
        fontFamily: 'monospace'
    }
}));

interface translations {
    resetAuthenticationApp: string
    setupAuthenticationApp: string
    introduction: string
    enterCode: string
    confirmationCode: string
    recoveryCodeSuggestion: string
    confirm: string
    copy: string
    resetDescription: string
    close: string
    code: string
    download: string
    copyCode: string
}

const enTranslations: translations = {
    resetAuthenticationApp: 'Reset Authentication App',
    setupAuthenticationApp: 'Setup Authentication App',
    resetDescription: 'Enter the 6-digit code you see in your authentication app. Optionally enter one of the unused recovery codes.',
    introduction: 'Using your authentication app (such as Duo or Google Authenticator) scan or enter the following code.',
    enterCode: 'Next, enter the 6-digit code you see in your authentication app.',
    confirmationCode: 'Confirmation Code',
    recoveryCodeSuggestion: 'We recommend you save or print these recovery codes. Each can be used only once',
    confirm: 'Confirm',
    copy: 'Copy All',
    close: 'Close',
    code: 'Code',
    download: 'Download',
    copyCode: 'Copy Code'
}