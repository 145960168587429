import React from "react";
import { useTranslation } from "react-i18next";
import { DisplayTable } from "../../components/DisplayWrappers";
import { ResourceStateChip } from "../../components/FormControls/ResourceStateChip";
import { Node } from "../../models";
import { RotatesignersSignerInfo } from "../../models/rotatesigners";
import { DisplayTableRecord } from "../../components/DisplayWrappers/DisplayTableRow";

interface Props {
    nodes: Node[]
    rotatesignersCurrentSigners: RotatesignersSignerInfo[] | null
    rotatesignersNextSigners: RotatesignersSignerInfo[] | null
    isManageView?: boolean
}

export const SignersTable = ({ nodes, rotatesignersCurrentSigners, rotatesignersNextSigners, isManageView = false }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "RotateSignersSignersTable", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`RotateSignersSignersTable:${key}`, interpolate);

    const columnHeaders = [lt('name'), lt('owner'), lt('lastSigned'), lt('consensus')];

    type DisplayTableRecordWithSortKey = {
        sortKey: number
    } & DisplayTableRecord

    let currentSignerRecords: DisplayTableRecordWithSortKey[] = [] 
    let nextSignerRecords: DisplayTableRecordWithSortKey[] = [] 
    let waitingSignerRecords: DisplayTableRecordWithSortKey[] = [] 
    
    nodes.filter(n => n.init_consensus_role === 'signer').forEach(n => {
        const hasBeenActivated = (rotatesignersCurrentSigners?.length || 0) > 0

        const currentSignerInfo = rotatesignersCurrentSigners?.find(s => s.signer.toLowerCase() === n.consensus_identity.toLowerCase())
        const isCurrentSigner = (currentSignerInfo ? true : false) || (isManageView && !hasBeenActivated)
        const nextSignerInfo = rotatesignersNextSigners?.find(s => s.signer.toLowerCase() === n.consensus_identity.toLowerCase())
        const signerInfo = currentSignerInfo || nextSignerInfo

        if (signerInfo || isManageView) {
            const hasLastRotatedIn = signerInfo && signerInfo.lastActive && signerInfo.lastActive !== "0"
            const recordToPush = {
                columns: [
                    {
                        value: n.name
                    }, 
                    {
                        value: n.membership.name
                    }, 
                    {
                        value: hasLastRotatedIn ? new Date(Number(signerInfo!.lastActive)).toLocaleString() : ''
                    },
                    {
                        value: <ResourceStateChip state={isCurrentSigner ? 'live' : (nextSignerInfo ? 'upNext' : 'waiting')} />
                    }
                ],
                sortKey: hasLastRotatedIn ? Number(signerInfo!.lastActive) : 0
            }

            if (isCurrentSigner) {
                currentSignerRecords.push(recordToPush)
            } else if (nextSignerInfo) {
                nextSignerRecords.push(recordToPush)
            } else {
                waitingSignerRecords.push(recordToPush)
            }
        }
    })

    currentSignerRecords = currentSignerRecords.sort((a, b) => b.sortKey - a.sortKey)
    nextSignerRecords = nextSignerRecords.sort((a, b) => b.sortKey - a.sortKey)
    const records = currentSignerRecords.concat(nextSignerRecords).concat(waitingSignerRecords)

    return (
        <DisplayTable {...{columnHeaders}} {...{records}} header={lt('runtimes')} />
    )
}

interface translations {
    name: string;
    owner: string;
    lastSigned: string
    consensus: string
    runtimes: string
}

const enTranslations: translations = {
    name: "Name",
    owner: "Owner",
    lastSigned: 'Last Rotated In',
    consensus: 'Consensus',
    runtimes: 'Participating Node Signers'
};
