import { useApolloClient, useMutation } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { EnRuntimeSizeTranslation, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, RuntimeSize, RuntimeSizeTranslation, ServicesEnum } from '../../models';
import { HigherAccessRequestData, SubmitHigherAccessMutation, SubmitHigherAccessRequestFormVars } from '../../models/contactInfo';
import { requestHigherAccessFormFields } from '../../utils/HubspotUtils';
import { AlertBanner } from '../Banners/AlertBanner';
import { RadioSelector, RadioSelectorOptions } from '../FormControls/RadioSelector';
import { PayWallBanner } from '../PayWall/PayWallBanner';

interface Props {
    size: RuntimeSize
    setSize: React.Dispatch<React.SetStateAction<RuntimeSize>>
    setMessage?: React.Dispatch<React.SetStateAction<string>>
    setMessageType?: React.Dispatch<React.SetStateAction<'error'|'success'|undefined>>
    smallDescription?: string
    mediumDescription?: string
    largeDescription?: string
    hideHeaderAndDescription?: boolean
    enableSmallOnly?: boolean
}

export const RuntimeCreationSelectSizeContent = ({ size: runtimeSize, setSize, setMessage, setMessageType, smallDescription, mediumDescription, largeDescription, hideHeaderAndDescription, enableSmallOnly }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "RuntimeCreationSelectSizeContent", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`RuntimeCreationSelectSizeContent:${key}`, interpolate);

    // treat starter as small
    const size = runtimeSize === 'starter' ? 'small' : runtimeSize

    const { org_id } = useParams<any>();
    const { pathname } = useLocation();
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [submitAccessRequest] =  useMutation<HigherAccessRequestData, SubmitHigherAccessRequestFormVars>(SubmitHigherAccessMutation)

    const app2AppOrDocStore = pathname.includes(ServicesEnum.documentstore) || pathname.includes(ServicesEnum.app2app);
    const smallDescTranslation = app2AppOrDocStore ? lt("app2appDocstoreSmallDescription") : lt("smallDescription");
    const mediumDescTranslation = app2AppOrDocStore ? lt("app2appDocstoreMediumDescription") : lt("mediumDescription");
    const largeDescTranslation = app2AppOrDocStore ? lt("app2appDocstoreLargeDescription") : lt("largeDescription");

    // direct read from cache
    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsServiceSizes = useMemo(() => PlanSupports.serviceSizes(organization), [organization])
    const supportedNodeSizes = useMemo(() => PlanSupports.supportedNodeSizes(organization), [organization])

    const radioOptions: RadioSelectorOptions[] = [
        {
            value: "small",
            label: lt("small"),
            description: smallDescription ? `${smallDescription}. ${smallDescTranslation}` : smallDescTranslation,
        },
        {
            isDisabled: enableSmallOnly || !supportsServiceSizes || !supportedNodeSizes.some(supportedNodeSize => supportedNodeSize === 'medium'),
            value: "medium",
            label: lt("medium"),
            description: mediumDescription ? `${mediumDescription}. ${mediumDescTranslation}` : mediumDescTranslation,
        },
        {
            isDisabled: enableSmallOnly || !supportsServiceSizes || !supportedNodeSizes.some(supportedNodeSize => supportedNodeSize === 'large'),
            value: "large",
            label: lt("large"),
            description: largeDescription ? `${largeDescription}. ${largeDescTranslation}` : largeDescTranslation,
        },
    ];

    const requestMediumLargeNode = {
        text: lt('sendRequest'),
        disabled: requestSubmitted,
        onClick: async () => {
            const formFields = requestHigherAccessFormFields('upgradeFireFlyNode', window.location.href);
            const { data }= await submitAccessRequest({
                variables: {
                    ...formFields
                }
            });
            if (data?.requestHigherAccess && setMessage && setMessageType) {
                setMessageType('success')
                setMessage(lt('requestSubmitted'))
                setRequestSubmitted(true)
            }
        }
    }

    const content = (
        <>
            <Grid item container direction="column" spacing={3}>
                {!hideHeaderAndDescription && 
                <Grid item container spacing={1} direction="column">
                    <Grid item>
                        <Typography variant="h5">{lt("header")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            gutterBottom
                        >
                            {lt("headerDescription")}
                        </Typography>
                    </Grid>
                </Grid>
                }
                <Grid item>
                    <RadioSelector
                        options={radioOptions}
                        selected={size}
                        onChange={(e) => setSize(e.target.value as RuntimeSize)}
                    />
                </Grid>

                {!supportsServiceSizes && <PayWallBanner description={lt('sizePaywall')} />}
                {supportsServiceSizes && enableSmallOnly && <Grid item><AlertBanner description={lt('smallOnly')} buttonVariant={'text'} linkButton={requestMediumLargeNode} /></Grid>}
            </Grid>
        </>
    );

    return content
};

interface translations extends RuntimeSizeTranslation {
    header: string;
    headerDescription: string;
    smallDescription: string
    mediumDescription: string
    largeDescription: string
    app2appDocstoreSmallDescription: string
    app2appDocstoreMediumDescription: string
    app2appDocstoreLargeDescription: string
    requestSubmitted: string
    sizePaywall: string
    smallOnly: string
    sendRequest: string
}

const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    header: "Select a Size",
    headerDescription: "You can select a size for this runtime best suited for your specific needs.",
    smallDescription: '(1GB memory, 0.5 vCPU)',
    mediumDescription: '(2GB memory, 1 vCPU)',
    largeDescription: '(4GB memory, 2 vCPU)',
    app2appDocstoreSmallDescription: '(512MB memory, 0.3 vCPU)',
    app2appDocstoreMediumDescription: '(512MB memory, 0.5 vCPU)',
    app2appDocstoreLargeDescription: '(1GB memory, 1 vCPU)',
    requestSubmitted: 'Request submitted. Someone will be in touch with you soon.',
    sizePaywall: 'Additional sizes (medium & large) with expanded capabilities are only available on the Business and Enterprise plans.',
    smallOnly: 'Interested in medium and large FireFly nodes?',
    sendRequest: 'Request Access'
};
