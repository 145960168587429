import React, { useMemo, useState } from 'react';
import { BAFPolicyAuthSection } from './BAFPolicyAuthSection';
import { BAFPolicyRulesetSection } from './BAFPolicyRulesetSection';
import { BAFPolicyCORSSection } from './BAFPolicyCORSSection';
import { ConfigData, ConfigQuery, UpdateConfigVars, UpdateConfigMutation, Config } from '../../models/configs';
import { MessageSnackbar, ErrorSnackbarCatcher } from '../../components/DialogWrappers';
import { EnvironmentResourceVars } from '../../interfaces';
import { CircularProgress, Grid } from "@material-ui/core";
import { useParams, Redirect } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../../components/MainNav/SideNavs/Security';
import { BAFPolicy, BAFConfig, BAFSaveWithUndo } from './BAFPolicySchema';
import { BAFPolicyDetailsSection } from './BAFPolicyDetailsSection';
import { ConfigsAttachedTable } from '../Configurations/ConfigsAttachedTable';
import { Alert } from '../../components/FormControls/Alert';
import { useTranslation } from 'react-i18next';

export const BAFPolicyViewer = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CloudConfigInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CloudConfigInfo:${key}`, interpolate);

    const [errorMessage, setErrorMessage] = useState('');
    const [ alertText, setAlertText ] = useState('');

    const { consortium_id, environment_id, config_id, org_id } = useParams<any>();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const [updateConfig, { loading: configSaving }] = useMutation<Config, UpdateConfigVars>(UpdateConfigMutation)

    const {
        loading: configLoading,
        data: {
            config
        } = { config: null },
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...environmentVariables,
            id: config_id!
        }
    });

    const bafConfig: BAFConfig | undefined = useMemo(() => {
        if (config) {
            return {
                ...config,
                details: JSON.parse(config.details.baf_policy!) as BAFPolicy,
            }
        }
    }, [config])

    const loading = configLoading || configSaving;

    if (!configLoading && !bafConfig) return (<Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`} />)

    if(configLoading) return <CircularProgress />

    if (!bafConfig!.details.cors) {
        bafConfig!.details.cors ={
            allowedOrigins: ["*"],
            allowedMethods: ["POST","GET"],
            allowedHeaders: ["*"],
            allowCredentials: true,
            maxAge: 600,
        }
    }

    const save: BAFSaveWithUndo = async (parentLoc : any, fieldName: string, newValue: any, restoreFunc: (oldValue: any) => void) => {
        let oldValue = parentLoc[fieldName];
        try {
            parentLoc[fieldName] = newValue;
            await updateConfig({
                variables: {
                    ...environmentVariables,
                    id: config_id!,
                    configuration: {
                        name: bafConfig!.name,
                        details: {
                            baf_policy: JSON.stringify(bafConfig!.details),
                        }
                    }
                }
            })
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
            parentLoc[fieldName] = oldValue;
            restoreFunc(oldValue);
        }
    }

    const rulesetNames = Object.keys(bafConfig!.details.rulesets);
    const usedRulesets : {[ruleset: string]: true} = {};
    if (bafConfig!.details.appcreds?.mappings) {
        for (let mapping of bafConfig!.details.appcreds?.mappings) {
            if (mapping.ruleset) {
                usedRulesets[mapping.ruleset] = true;
            }
        }
    }
    if (bafConfig!.details.jwt?.mappings) {
        for (let mapping of bafConfig!.details.jwt?.mappings) {
            if (mapping.ruleset) {
                usedRulesets[mapping.ruleset] = true;
            }
        }
    }

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/${config_id}`;

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage}/>
            <Grid container direction="column" spacing={3} wrap="nowrap">
                {alertText &&
                <Grid item>
                    <Alert severity="info" title={lt('info')} description={alertText} />
                </Grid>
                }
                <BAFPolicyDetailsSection {...{loading}} {...{save}} bafConfig={bafConfig!}/>
                <BAFPolicyRulesetSection {...{loading}} {...{save}} bafConfig={bafConfig!} rulesetsSection={bafConfig!.details.rulesets} {...{usedRulesets}}  {...{basePath}}/>
                <BAFPolicyAuthSection {...{loading}} {...{save}} type='appcreds' {...{rulesetNames}} authSection={bafConfig!.details.appcreds} {...{basePath}}/>
                <BAFPolicyAuthSection {...{loading}} {...{save}} type='jwt' {...{rulesetNames}} authSection={bafConfig!.details.jwt} {...{basePath}}/>
                <BAFPolicyCORSSection {...{loading}} {...{save}} corsSection={bafConfig!.details.cors!}/>
                <Grid item xs={12} sm={12} md={8}>
                    <ConfigsAttachedTable setAlert={setAlertText}/>
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    info: string,
}

const enTranslations: translations = {
    info: 'Info',
}