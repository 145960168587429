import { Grid, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateWrapper, MessageSnackbar } from '../../components/DialogWrappers';
import { RuntimeCreationSelectSizeContent } from '../../components/RuntimeCreation/RuntimeCreationSelectSizeContent';
import { CreateStepProps } from '../../interfaces';
import { RuntimeSize } from '../../models';
import { Step3Help } from "./Step3Help";

interface CreateNodeStep3Props extends CreateStepProps {
    save: () => void,
    createNodeLoading: boolean,
    creatingCA: boolean,
    createMembershipLoading: boolean,
    size: RuntimeSize,
    setSize: React.Dispatch<React.SetStateAction<RuntimeSize>>
};

// In this step, the user will select a size for their firefly node
export const CreateNodeStep3 = (props: CreateNodeStep3Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateFireflyNodeStep3', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateFireflyNodeStep3:${key}`, interpolate)
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error'|'success'|undefined>();
    
    const content = (
        <Grid item>
            <Typography variant="h5">
                {lt('header')}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                {lt('sizeDescription')}
            </Typography>
            <RuntimeCreationSelectSizeContent enableSmallOnly size={props.size} setSize={props.setSize} hideHeaderAndDescription setMessage={setMessage} setMessageType={setMessageType} />
        </Grid>
    )

    const disabled = props.createNodeLoading || props.creatingCA || props.createMembershipLoading;

    return (
        <>
            <MessageSnackbar {...{messageType}} {...{message}} {...{setMessage}} />
            <CreateWrapper cancelPath={props.cancelPath} {...{content}} disabled={disabled} onNext={props.save} isLastStep />
            <Step3Help />
        </>
    )
};

interface translations {
    header: string,
    sizeDescription: string
}
const enTranslations: translations = {
    header: 'Configure the size of your node',
    sizeDescription: 'Select the level of resources available to your node.'

}