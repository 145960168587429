import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, MenuItem } from "@material-ui/core";
import ShieldOutlineIcon from 'mdi-react/ShieldOutlineIcon';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import LifebuoyIcon from 'mdi-react/LifebuoyIcon';
import { EditableSettings } from '../../components/DisplaySettings'
import { FormDialog } from '../../components/DialogWrappers'
import { BAFConfig, BAFSaveWithUndo } from './BAFPolicySchema';

interface Props {
    bafConfig: BAFConfig,
    loading: boolean,
    save: BAFSaveWithUndo,
}

export const BAFPolicyDetailsSection: React.FC<Props> = ({ bafConfig, save, loading }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFPolicyDetailsSection', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFPolicyDetailsSection:${key}`, interpolate)

    const getLoggingLevelWithDefault = (level?: number) => {
        return level !== undefined ? level : 1;
    }

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [updateTenantsTagDialogOpen, setUpdateTenantsTagDialogOpen] = useState(false);
    const [updateLoggingLevelDialogOpen, setUpdateLoggingLevelDialogOpen] = useState(false);
    const [name, setName] = useState(bafConfig.name);
    const [tenantsTag, setTenantsTag] = useState(bafConfig.details.tenants_tag || '');
    const [loggingLevel, setLoggingLevel] = useState(getLoggingLevelWithDefault(bafConfig.details.logging?.level));

    const logLevel = (i : number) => {
        switch(i) {
            case 0: return lt('loglevelError');
            case 1: return lt('loglevelInfo');
            case 2: return lt('loglevelDebug');
            case 3: return lt('loglevelTrace');
            default: return String(i)
        }
    }

    const actionsList = [
        {
            icon: <ShieldOutlineIcon />,
            title: lt('changeName'),
            description: lt('nameDescription'),
            value: bafConfig.name,
            buttonLabel: lt('change'),
            action: () => setUpdateNameDialogOpen(true)
        },
        {
            icon: <AccountMultipleIcon />,
            title: lt('changeTenantsTag'),
            description: lt('tenantsTagDescription'),
            value: bafConfig.details.tenants_tag || '',
            buttonLabel: lt('change'),
            action: () => setUpdateTenantsTagDialogOpen(true)
        },
        {
            icon: <LifebuoyIcon />,
            title: lt('changeLoggingLevel'),
            description: lt('loggingLevelDescription'),
            value: logLevel(getLoggingLevelWithDefault(bafConfig.details.logging?.level)),
            buttonLabel: lt('change'),
            action: () => setUpdateLoggingLevelDialogOpen(true)
        }
    ]

    const setNameControls = <>
        <TextField
            data-test="textField_updateName"
            value={name} onChange={event => setName(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('name')}
            fullWidth
            variant="outlined"
        /></>

    const setTenantsTagControls = <>
        <TextField
            data-test="textField_updateTenantsTag"
            value={tenantsTag} onChange={event => setTenantsTag(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('name')}
            fullWidth
            variant="outlined"
        /></>

    const setLoggingLevelControls = <>
        <TextField
            data-test="textField_updateLoggingLevel"
            value={loggingLevel} onChange={event => setLoggingLevel(Number(event.target.value))}
            autoFocus
            margin="normal"
            label={lt('loggingLevel')}
            fullWidth
            variant="outlined"
            select
        >
            <MenuItem value="0">{lt('loglevelError')}</MenuItem>
            <MenuItem value="1">{lt('loglevelInfo')}</MenuItem>
            <MenuItem value="2">{lt('loglevelDebug')}</MenuItem>
            <MenuItem value="3">{lt('loglevelTrace')}</MenuItem>
        </TextField></>
    
    const onSaveName = async () => {
        await save(bafConfig, 'name', name, setName)
    }

    const onSaveTenantsTag = async () => {
        await save(bafConfig.details, 'tenants_tag', tenantsTag || undefined, t => setTenantsTag(t || ''))
    }

    const onSaveLoggingLevel = async () => {
        bafConfig.details.logging = bafConfig.details.logging || {};
        await save(bafConfig.details.logging, 'level', loggingLevel, t => setLoggingLevel(getLoggingLevelWithDefault(t)))
    }

    return (
        <>
            <FormDialog dataTestId="updateName"
                setOpen={setUpdateNameDialogOpen}
                open={updateNameDialogOpen}
                header={lt('changeName')} 
                description={lt('changeNameDescription')} 
                controlsWrapper={setNameControls}
                saveDisabled={!name || loading}
                onSave={onSaveName}
                closeDialogAfterSave />
            
            <FormDialog dataTestId="updateTenantsTag"
                setOpen={setUpdateTenantsTagDialogOpen}
                open={updateTenantsTagDialogOpen}
                header={lt('changeTenantsTag')} 
                description={lt('changeTenantsTagDescription')} 
                controlsWrapper={setTenantsTagControls}
                saveDisabled={loading}
                onSave={onSaveTenantsTag}
                closeDialogAfterSave />
            
            <FormDialog dataTestId="updateLoggingLevel"
                setOpen={setUpdateLoggingLevelDialogOpen}
                open={updateLoggingLevelDialogOpen}
                header={lt('changeLoggingLevel')} 
                description={lt('changeLoggingLevelDescription')} 
                controlsWrapper={setLoggingLevelControls}
                saveDisabled={loading}
                onSave={onSaveLoggingLevel}
                closeDialogAfterSave />                

            <Grid item xs={12} sm={12} md={8}>
                <EditableSettings
                    header={lt('policyDetails')}
                    {...{actionsList}} />
            </Grid>
        </>
    )
};

interface translations {
    policyDetails: string,
    change: string,

    name: string,
    nameDescription: string,
    changeName: string,
    changeNameDescription: string,

    tenantsTag: string,
    tenantsTagDescription: string,
    changeTenantsTag: string,
    changeTenantsTagDescription: string,

    loggingLevel: string,
    loggingLevelDescription: string,
    changeLoggingLevel: string,
    changeLoggingLevelDescription: string,

    loglevelError: string,
    loglevelInfo: string,
    loglevelDebug: string,
    loglevelTrace: string,
}
const enTranslations: translations = {
    policyDetails: "Policy Details",
    change: "Change",

    name: "Name",
    nameDescription: "Name of your Blockchain Application Firewall Policy",
    changeName: "Set Policy Name",
    changeNameDescription: "Set the new name for your Blockchain Application Firewall Policy",

    tenantsTag: "Tenants Tag",
    tenantsTagDescription: "Dynamically generate rules for Kaleido multi-tenant nodes",
    changeTenantsTag: "Set Tenants Tag",
    changeTenantsTagDescription: "Leave this unset if you are not deploying Kaleido multi-tenant nodes",

    loggingLevel: "Logging Level",
    loggingLevelDescription: "Logs are available on each node with this configuration attached",
    changeLoggingLevel: "Change Logging Level",
    changeLoggingLevelDescription: "Use Debug during policy development. Info will minimally record all requests",

    loglevelError: "Error",
    loglevelInfo: "Info",
    loglevelDebug: "Debug",
    loglevelTrace: "Trace",

}