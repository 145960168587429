import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
    ServicesData,
    ServicesQuery,
    IDRegistryOrgsData,
    IDRegistryOrgsQuery,
    IDRegistryOrgDetailsData,
    IDRegistryOrgDetailsVars,
    IDRegistryOrgDetailsQuery,
    IDRegistryProfilePropertiesData,
    IDRegistryProfilePropertiesVars,
    IDRegistryProfilePropertiesQuery,
    MembershipData,
    MembershipQuery,
} from "../../../models";
import { useTranslation } from "react-i18next";
import {
    Typography,
    CircularProgress,
    Grid,
    Button,
} from "@material-ui/core";
import {
    ServiceResourcesVars,
    ConsortiumResourceVars,
} from "../../../interfaces";
import {
    CopyableSettings,
    CopyableSetting,
} from "../../../components/DisplaySettings";
import { useCertificateInfo } from "../../../hooks/useCertificateInfo";
import { FormLink } from "../../../components/FormControls/FormLink";
import { ShortenedHash } from "../../../components/FormControls/ShortenedHash";
import { AddNewEntryProfile } from "./AddNewEntryProfile";
import { MemberProfileCard } from "./MemberProfileCard";

export const MemberOnChainProfile: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "MemberOnChainProfile", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`MemberOnChainProfile:${key}`, interpolate);

    const [open, setOpen] = useState(false);

    const { consortium_id, environment_id, membership_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    const { data: { services } = { services: [] } } = useQuery<ServicesData>(
        ServicesQuery,
        {
            variables: environmentVars,
            fetchPolicy: "cache-only",
        }
    );

    const { data: { membership } = { membership: null } } = useQuery<
        MembershipData,
        ConsortiumResourceVars
    >(MembershipQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: membership_id!,
        },
        fetchPolicy: "cache-only",
    });

    const idRegistryServiceID = services.find(
        (entry) => entry.service === "idregistry" && entry.state === "started"
    )?._id;

    const {
        loading: loadingIdRegistryOrgs,
        data: { idRegistryOrgs } = { idRegistryOrgs: [] },
    } = useQuery<IDRegistryOrgsData, ServiceResourcesVars>(
        IDRegistryOrgsQuery,
        {
            variables: { service_id: idRegistryServiceID! },
            fetchPolicy: "cache-and-network",
            skip: !idRegistryServiceID,
        }
    );

    const idRegistryMembershipOrgId = idRegistryOrgs.find((org) =>
        org.name.endsWith(membership_id!)
    )?.id;

    const {
        loading: loadingIdRegistryOrgDetails,
        data: { idRegistryOrgDetails } = { idRegistryOrgDetails: null },
    } = useQuery<IDRegistryOrgDetailsData, IDRegistryOrgDetailsVars>(
        IDRegistryOrgDetailsQuery,
        {
            variables: {
                service_id: idRegistryServiceID!,
                id: idRegistryMembershipOrgId,
            },
            fetchPolicy: "cache-and-network",
            skip: !idRegistryMembershipOrgId,
        }
    );

    const idRegistryMembershipOrgOwner = idRegistryOrgDetails?.owner;

    const {
        loading: loadingIdRegistryProfileProperties,
        data: { idRegistryProfileProperties } = {
            idRegistryProfileProperties: [],
        },
    } = useQuery<
        IDRegistryProfilePropertiesData,
        IDRegistryProfilePropertiesVars
    >(IDRegistryProfilePropertiesQuery, {
        variables: {
            service_id: idRegistryServiceID!,
            owner: idRegistryMembershipOrgOwner,
        },
        fetchPolicy: "cache-and-network",
        skip: !idRegistryMembershipOrgOwner,
    });

    const {
        proof: membershipJSONIDProof,
        isLoading: isMembershipProofLoading,
    } = useCertificateInfo({
        membershipId: membership_id,
        skipQuery: !membership?.verification_proof,
    });

    if (!idRegistryServiceID) {
        return <Typography>{lt("noIDRegistry")}</Typography>;
    }

    if (
        isMembershipProofLoading ||
        (loadingIdRegistryOrgs && idRegistryOrgs.length === 0) ||
        (loadingIdRegistryOrgDetails && idRegistryOrgDetails === null) ||
        (loadingIdRegistryProfileProperties &&
            idRegistryProfileProperties.length === 0)
    ) {
        return <CircularProgress />;
    }

    let certificateCopyableList: CopyableSetting[] = [];

    if (membershipJSONIDProof) {
        certificateCopyableList = [
            {
                disableCopy: idRegistryOrgDetails?.owner === undefined,
                title: lt("account"),
                displayValue: (
                    <ShortenedHash
                        address={
                            idRegistryOrgDetails?.owner || lt("notAvailable")
                        }
                    />
                ),
            },
            {
                disableCopy: membershipJSONIDProof.commonName === undefined,
                title: lt("commonName"),
                displayValue:
                    membershipJSONIDProof.commonName || lt("notAvailable"),
            },
            {
                disableCopy: membershipJSONIDProof.organization === undefined,
                title: lt("organization"),
                displayValue:
                    membershipJSONIDProof.organization || lt("notAvailable"),
            },
            {
                disableCopy: membershipJSONIDProof.country === undefined,
                title: lt("country"),
                displayValue:
                    membershipJSONIDProof.country || lt("notAvailable"),
            },
            {
                disableCopy: membershipJSONIDProof.serial === undefined,
                title: lt("serial"),
                displayValue:
                    membershipJSONIDProof.serial || lt("notAvailable"),
            },
        ];
    }

    const downloadCertificateLink = (
        <FormLink
            download="cert.pem"
            isExternal={true}
            to={`data:text/plain;charset=utf-8,${membership?.verification_proof}`}
        >
            {lt("downloadCertificate")}
        </FormLink>
    );

    return (
        <>
            <AddNewEntryProfile
                {...{ open }}
                {...{ setOpen }}
                serviceId={idRegistryServiceID}
                membershipId={membership_id}
                profileId={idRegistryOrgDetails?.id ?? ""}
                profileAddress={idRegistryOrgDetails?.profile_address ?? ""}
                idRegistryMembershipOrgOwner={
                    idRegistryMembershipOrgOwner ?? ""
                }
            />
            <Grid container direction={"column"} spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt("onChainProfile")}</Typography>
                </Grid>
                <Grid item>
                    <CopyableSettings
                        actionBar={downloadCertificateLink}
                        header={lt("pkiIdentity")}
                        copyableList={certificateCopyableList}
                    />
                </Grid>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h5">
                            {lt("memberProfile")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            data-test="button_addMembership"
                            variant="contained"
                            color="primary"
                            disabled={!idRegistryMembershipOrgId}
                            onClick={() => setOpen(true)}
                        >
                            {lt("addProfileKey")}
                        </Button>
                    </Grid>
                </Grid>
                {idRegistryProfileProperties.length === 0 && (
                    <Grid item>
                        <Typography>{lt("noProfileProperties")}</Typography>
                    </Grid>
                )}
                <Grid item container spacing={3} direction="column">
                    {idRegistryProfileProperties.map(
                        (profileProperty, index) => (
                            <Grid key={profileProperty.name} item>
                                <MemberProfileCard
                                    {...{ profileProperty }}
                                    {...{ index }}
                                    serviceId={idRegistryServiceID}
                                    membershipId={membership_id}
                                    profileId={idRegistryOrgDetails?.id ?? ""}
                                    profileAddress={
                                        idRegistryOrgDetails?.profile_address ??
                                        ""
                                    }
                                    idRegistryMembershipOrgOwner={
                                        idRegistryMembershipOrgOwner ?? ""
                                    }
                                />
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
        </>
    );
};

interface translations {
    onChainProfile: string;
    profileProperties: string;
    noProfileProperties: string;
    noIDRegistry: string;
    pkiIdentity: string;
    commonName: string;
    organization: string;
    country: string;
    serial: string;
    notAvailable: string;
    downloadCertificate: string;
    account: string;
    memberProfile: string;
    addProfileKey: string;
    addNewProfileKey: string;
    key: string;
    value: string;
}

const enTranslations: translations = {
    onChainProfile: "On-Chain Profile",
    profileProperties: "Profile Properties ({{count}})",
    noProfileProperties: "Member has no profile properties",
    noIDRegistry:
        "On-Chain registry is not currently running in this environment",
    pkiIdentity: "PKI Identity",
    commonName: "Common name",
    organization: "Organization",
    country: "Country",
    serial: "Serial",
    notAvailable: "Not Available",
    downloadCertificate: "Download Certificate",
    account: "Account",
    memberProfile: "Member Profile",
    addProfileKey: "Add profile key",
    addNewProfileKey: "Add New Profile Key",
    key: "Key",
    value: "Value",
};
