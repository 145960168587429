import React, { useState, useEffect, useCallback } from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { NodeData, NodeQuery } from '../../models'
import { Typography, Grid, CircularProgress, Paper, makeStyles } from "@material-ui/core";
import { EnvironmentResourceVars } from '../../interfaces'
import { MessageSnackbar, ErrorSnackbarCatcher } from '../../components/DialogWrappers';
import { CookieAppCred } from '../../components/CookieAppCred/CookieAppCred';

export const Fabconnect: React.FC = () => {
    const classes = useStyles();
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Fabconnect', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`Fabconnect:${key}`, interpolate), [t])

    const { consortium_id, environment_id, node_id } = useParams<any>();
    const [spec, setSpec] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const [appCred, setAppCred] = useState('');

    const nodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: node_id!
    }

    const {
        data: {
            node
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: nodeVars,
        fetchPolicy: 'cache-only'
    });

    const apiUrl = `${node?.urls?.kaleido_connect}/spec.yaml?schemes=https&noauth`

    const fetchSpec = useCallback(async () => {
        try {
            const auth = window.btoa(appCred);
            const httpHeaders = {
                Authorization: `Basic ${auth}`
            };
            const data = await fetch(apiUrl, {
                method: 'GET',
                headers: new Headers(httpHeaders)
            })
            if (!data.ok) {
                const response = await data.json();
                throw new Error(response.error);
            }
            const yaml = await data.text()
            return yaml
        } catch(err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
    }, [apiUrl, appCred])

    useEffect(() => {
        if (appCred && !spec) {
            fetchSpec().then(s => {
                setSpec(s)
            })
        }
    }, [appCred, spec, fetchSpec])

    useEffect(() => {
        if (node && node?.state !== 'started') {
            setErrorMessage(lt('nodeNotStarted'))
        }
    }, [node, lt])

    if (!node) return <CircularProgress />

    const loading = !appCred

    if (node.state !== 'started') return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <Grid item>
                <Typography variant="h5">{lt('fabconnect')}</Typography>
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <CookieAppCred key={node.membership_id} membershipId={node.membership_id} 
                nodeId={node._id} setCookieAppCred={setAppCred}
                {...{setErrorMessage}} />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">{lt('fabconnect')}</Typography>
                    </Grid>
                </Grid>

                <Grid item container spacing={3}>
                {(loading || !spec) && <Grid item><CircularProgress /></Grid>}
                { !loading && (
                    <Grid item className={!spec ? classes.hide : ''}>
                        <Paper className={classes.paperPadding}>
                            <Grid item >
                                <SwaggerUI 
                                    requestInterceptor={(req) => {
                                        req.url = req.url.replace(window.location.origin, node?.urls?.kaleido_connect)
                                        let authHeaders = req.headers['Authorization'];
                                        if (!authHeaders || authHeaders === 'Basic ') {
                                            req.headers['Authorization'] = `Basic ${window.btoa(appCred)}`
                                        }
                                        return req
                                    }}
                                    spec={spec} />
                            </Grid>
                        </Paper>
                    </Grid>
                )}
                </Grid>
            </Grid>
        </>
    )
};

const useStyles = makeStyles(theme => ({
    paperPadding: {
        padding: theme.spacing(3)
    },
    hide: {
        display: 'none'
    }
}));

interface translations {
    fabconnect: string
    nodeNotStarted: string
}
const enTranslations: translations = {
    fabconnect: 'REST API Gateway',
    nodeNotStarted: 'The node must be started to view the REST API Gateway'
}