import React from 'react';
import { DisplayCardListItem, DisplayCardItem } from './DisplayCardItem'
import { List, makeStyles, Grid } from "@material-ui/core";
import { DisplayGridWrapper } from './DisplayGridWrapper';
import { CircularProgress } from '@material-ui/core';
import { LinkButtonProps } from '../../interfaces';

interface Props {
    header?: string,
    description?: string,
    actionBar?: JSX.Element,
    linkButton?: LinkButtonProps,
    linkButton2?: LinkButtonProps,
    itemList?: DisplayCardListItem[],
    itemListCol2?: DisplayCardListItem[],
    prebuiltDisplayCardItems?: JSX.Element,
    maxHeight?: 'disable' | 'extend' // default is MAX_HEIGHT_PX. extend is to account for the link button real estate
    divider?: boolean,
    loading?: boolean,
    hidePaper?: boolean,
    dataTestId?: string,
    actionFooter?: JSX.Element,
    leftAlignActionFooter?: boolean
};

const MAX_HEIGHT_PX = 384
const LINK_BUTTON_PX = 68 

const DisplayCard = ({ header, description, actionBar, linkButton, linkButton2, itemList, itemListCol2, prebuiltDisplayCardItems, 
    maxHeight, divider, loading, hidePaper, dataTestId, actionFooter, leftAlignActionFooter }: Props) => {
    let listMaxHeightPixelValue = maxHeight === 'disable' ? 0 : MAX_HEIGHT_PX
    
    if (maxHeight === 'extend') {
        listMaxHeightPixelValue += LINK_BUTTON_PX
    }

    const classes = useStyles(listMaxHeightPixelValue)();

    // if at least one item is clickable, override and put the divider for all items
    divider = divider || itemList?.some(i => !!i.onClick)

    const noItems = !itemList || itemList.length === 0;

    const displayGrid = loading ? <CircularProgress /> :
        <>
            <Grid item container className={classes.list} data-test={`displayCard_${dataTestId}`}>
                <Grid item sm>
                    <List disablePadding>
                        { prebuiltDisplayCardItems || 
                        itemList?.map((l, i) => <DisplayCardItem key={l.key || i} listItem={{ ...l, divider: i === itemList?.length - 1 ? false : divider }} />) }
                    </List>
                </Grid>
                {!prebuiltDisplayCardItems && itemListCol2 &&
                <Grid item sm>
                    <List disablePadding>
                        {itemListCol2?.map((l, i) => <DisplayCardItem key={l.key || i} listItem={{ ...l, divider: i === itemListCol2?.length - 1 ? false : divider }} />)}
                    </List>
                </Grid>
                }
            </Grid>
        </>

    if (!header) {
        return displayGrid
    }

    return (
        <DisplayGridWrapper {...{hidePaper}} {...{linkButton}} {...{linkButton2}} padDisplayGrid={loading} {...{header}} {...{description}} {...{actionBar}} {...{displayGrid}} {...{actionFooter}} {...{leftAlignActionFooter}} hideDivider={noItems}/>
    )
};

const useStyles = (listMaxHeightPixelValue: number) => makeStyles((theme) => ({
    list: {
        maxHeight: listMaxHeightPixelValue ? `${listMaxHeightPixelValue}px` : undefined,
        overflowY: 'auto',
        padding: theme.spacing(0, 3)
    }
}));

export { DisplayCard };