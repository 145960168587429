import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import {
    ConsortiumMembershipsData, ConsortiumMembershipsQuery, EnvironmentsData, EnvironmentsQuery
} from '../../models'
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from "@material-ui/core";
import { DisplayTable } from '../../components/DisplayWrappers';
import { ConsortiumResourcesVars } from '../../interfaces'
import { AuditEntry } from '../../models/audit';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { ActivityDescription } from './ActivityDescription'
import { ActivityStatus } from './ActivityStatus'
import { ActivityOwner } from './ActivityOwner'
import { ActivityIcon } from './ActivityIcon'
import { ActivityAction } from './ActivityAction';

interface Props {
    header: string,
    auditEntries: AuditEntry[],
    pagination: JSX.Element, // the TablePagination component
    loading?: boolean,
    omitEnvironmentDescriptions?: boolean
}

export const ActivityTable = ({ header, auditEntries, pagination, loading, omitEnvironmentDescriptions }: Props) => {
    const { t, i18n } = useTranslation();
    
    i18n.addResourceBundle('en', 'ActivityTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ActivityTable:${key}`, interpolate)

    const { consortium_id } = useParams<any>();
    const auditVars = {
        consortia_id: consortium_id!
    }

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: auditVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environments
        } = { environments: [] }
    } = useQuery<EnvironmentsData, ConsortiumResourcesVars>(EnvironmentsQuery, {
        variables: auditVars,
        fetchPolicy: 'cache-only'
    });

    if (loading && !auditEntries.length) {
        return <CircularProgress />
    }

    const wrapValue = (jsx: JSX.Element) => (
        <Typography noWrap variant="body2" color="inherit">
            {jsx}
        </Typography>
    )

    let auditRecords: DisplayTableRecord[] = [];

    for(const auditEntry of auditEntries) {
        auditRecords.push({
            key: auditEntry.action + auditEntry.timestamp,
            columns: [
                { isIcon: true, value: <ActivityIcon {...{auditEntry}} /> },
                { value: new Date(Number(auditEntry.timestamp)).toLocaleString() },
                { value: wrapValue(<ActivityAction {...{auditEntry}} />) },
                { value: wrapValue(<ActivityOwner {...{auditEntry}} {...{consortiumMemberships}} />) },
                { value: wrapValue(<ActivityStatus {...{auditEntry}} />) },
                { value: wrapValue(<ActivityDescription omitEnvironment={omitEnvironmentDescriptions} {...{auditEntry}} {...{environments}} />) }
            ]
        });
    }

    const columnHeaders = [
        '',
        lt('timestamp'),
        lt('action'),
        lt('owner'),
        lt('status'),
        lt('resource')
    ];

    return (
        <DisplayTable {...{header}} columnHeaders={columnHeaders} records={auditRecords} actionFooter={pagination} />
    )
};

interface translations {
    status: string
    owner: string
    timestamp: string
    unknown: string
    action: string
    resource: string
}
const enTranslations: translations = {
    status: 'Status',
    owner: 'Owner',
    timestamp: 'Timestamp',
    unknown: 'Unknown',
    action: 'Action',
    resource: 'Resource',
}