import { useQuery, useMutation } from "@apollo/client";
import { Grid, Typography, CircularProgress, Button, TextField, MenuItem } from "@material-ui/core";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { EnvironmentResourceVars, ServiceResourcesVars, ServiceStatusResourcesVars, EnvironmentResourcesVars } from "../../interfaces";
import { ServiceQuery, ServiceData, NodesData, NodesQuery } from "../../models";
import { RotatesignersConfigurationData, RotatesignersStatusData, RotatesignersStatusQuery, RotatesignersConfigurationQuery, RotatesignersCurrentSignersData, RotatesignersCurrentSignersQuery, RotatesignersNextSignersData, RotatesignersNextSignersQuery, UpdateRotatesignersConfigurationVars, UpdateRotatesignersConfigurationMutation, RotatesignersConfiguration, RotatesignersActivateMutation, RotatesignersDeactivateMutation } from "../../models/rotatesigners";
import { SignersTable } from "./SignersTable";
import { DisplayCard, DisplayGridWrapper } from "../../components/DisplayWrappers";
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";
import { ErrorSnackbarCatcher, MessageSnackbar } from "../../components/DialogWrappers";
import { ROTATE_SIGNERS_MANAGE_PATH } from "../../components/ServicesNav/Services/RotateSignersItems";
import { AlertBanner } from "../../components/Banners/AlertBanner";

const POLL_INTERVAL = 30000

export const Manage = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "RotateSignersManage", enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`RotateSignersManage:${key}`, interpolate), [t])

    const INTERVALS = useMemo(() => [ 
        // { value: 1, display: 'Every 1 Minute' },
        { value: 5, display: lt('every5Mins') },
        { value: 30, display: lt('every30Mins') },
        { value: 60, display: lt('every1Hour') },
        { value: 360, display: lt('every6Hours') },
        { value: 720, display: lt('every12Hours') },
        { value: 1440, display: lt('every1Day') },
        { value: 21600, display: lt('every15Days') },
        { value: 43200, display: lt('every30Days')}
    ], [lt])

    const { consortium_id, environment_id, service_id } = useParams<any>();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState('')

    const configLoaded = useRef(false)

    const [targetSigners, setTargetSigners] = useState(4)
    const [rotationInterval, setRotationInterval] = useState(INTERVALS[0].value)
    const [rotationSize, setRotationSize] = useState(1)

    const [hasChanges, setHasChanges] = useState(false)

    const basePath = history.location.pathname

    const [updateRotateSignersConfiguration, { loading: updateRotateSignersConfigurationLoading }] = 
        useMutation<RotatesignersConfigurationData, UpdateRotatesignersConfigurationVars>(UpdateRotatesignersConfigurationMutation)

    const [rotatesignersActivate, { loading: activateLoading }] = useMutation<void, ServiceResourcesVars>(RotatesignersActivateMutation);
    const [rotatesignersDeactivate, { loading: deactivateLoading }] = useMutation<void, ServiceResourcesVars>(RotatesignersDeactivateMutation);

    const toggleStatus = (op: 'activate' | 'deactivate') => {
        (op === 'activate' ? rotatesignersActivate : rotatesignersDeactivate)({
            variables: {
                service_id
            }
        }).then(() => {
            op === 'activate' ? history.push(`${basePath.replace(`/${service_id}/${ROTATE_SIGNERS_MANAGE_PATH}`, `/${service_id}`)}`) : refetchStatus()
        }).catch(e => {
            ErrorSnackbarCatcher(e, setErrorMessage)
        })
    }

    const {
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: { 
            consortia_id: consortium_id,
            environment_id: environment_id
        },
        fetchPolicy: 'cache-only'
    });

    const { data: { service } = { service: null } } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: { consortia_id: consortium_id, environment_id, id: service_id },
        fetchPolicy: "cache-only",
    });

    const { 
        refetch: refetchConfiguration,
        loading: configurationLoading,
        data: { 
            rotatesignersConfiguration 
        } = { rotatesignersConfiguration: null } 
    } = useQuery<RotatesignersConfigurationData, ServiceResourcesVars>(RotatesignersConfigurationQuery, {
        variables: { service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const { 
        refetch: refetchStatus,
        loading: statusLoading,
        data: { 
            rotatesignersStatus 
        } = { rotatesignersStatus: null } 
    } = useQuery<RotatesignersStatusData, ServiceStatusResourcesVars>(RotatesignersStatusQuery, {
        variables: { consortia_id: consortium_id, environment_id, service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const { 
        loading: rotatesignersCurrentSignersLoading,
        data: { 
            rotatesignersCurrentSigners 
        } = { rotatesignersCurrentSigners: null } 
    } = useQuery<RotatesignersCurrentSignersData, ServiceResourcesVars>(RotatesignersCurrentSignersQuery, {
        variables: { service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const { 
        loading: rotatesignersNextSignersLoading,
        data: { 
            rotatesignersNextSigners 
        } = { rotatesignersNextSigners: null } 
    } = useQuery<RotatesignersNextSignersData, ServiceResourcesVars>(RotatesignersNextSignersQuery, {
        variables: { service_id },
        fetchPolicy: "cache-and-network",
        skip: service?.state !== 'started',
        pollInterval: POLL_INTERVAL
    });

    const update = useCallback(async (configuration: RotatesignersConfiguration) => {
        setHasChanges(true)
        updateRotateSignersConfiguration({
            variables: {
                service_id,
                configuration
            }
        }).then(() => {
            refetchConfiguration()
        }).catch(e => {
            setHasChanges(false)
            ErrorSnackbarCatcher(e, setErrorMessage)
        })
    }, [service_id, updateRotateSignersConfiguration, refetchConfiguration])

    // if dropdowns change, update the configuration on the fly
    useEffect(() => {
        if (!rotatesignersConfiguration || !configLoaded.current) return
        if (rotatesignersConfiguration.targetSignerCount !== targetSigners) {
            update({ targetSignerCount: targetSigners })
        } else if (rotatesignersConfiguration.rotationInterval !== rotationInterval) {
            update({ rotationInterval: rotationInterval })
        } else if (rotatesignersConfiguration.rotationSize !== rotationSize) {
            update({ rotationSize: rotationSize })
        }
    }, [rotatesignersConfiguration, targetSigners, rotationSize, rotationInterval, update])

    // when config is updated for the first time, update the dropdowns
    useEffect(() => {
        if (rotatesignersConfiguration && !configLoaded.current) {
            setTargetSigners(rotatesignersConfiguration.targetSignerCount!)
            setRotationSize(rotatesignersConfiguration.rotationSize!)
            setRotationInterval(INTERVALS.find(i => i.value === rotatesignersConfiguration.rotationInterval)?.value || 5)
            configLoaded.current = true
        }
    }, [rotatesignersConfiguration, INTERVALS])

    const loading = (configurationLoading || statusLoading || rotatesignersCurrentSignersLoading || rotatesignersNextSignersLoading) && rotatesignersConfiguration == null

    const showContent = !loading && service?.state === 'started'

    const documentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("introduction"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://docs.kaleido.io/kaleido-services/rotate-signers/"
                ),
        },
        {
            icon: <DescriptionIcon />,
            title: lt("apiDocs"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://api.kaleido.io/rotatesigners.html"
                ),
        },
        {
            icon: <DescriptionIcon />,
            title: lt("scale"),
            value: lt("blog"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://www.kaleido.io/blockchain-blog/using-rotating-signers-to-scale-ibft-consensus-algorithm"
                ),
        },
    ]

    const disabled = updateRotateSignersConfigurationLoading || activateLoading || deactivateLoading

    const configsEditor = (
        <Grid item container spacing={3}>
            <Grid item container spacing={3} direction="column" lg={6}>
                <Grid item>
                    <TextField select 
                        fullWidth
                        disabled={disabled} 
                        label={lt('targetSigners')} 
                        value={targetSigners} 
                        variant="outlined" 
                        onChange={e => setTargetSigners(e.target.value as unknown as number)}>
                            {Array.from(Array(17).keys()).slice(4).map((entry) => (
                                <MenuItem key={`targetSigners-${entry}`} value={entry}>{entry}</MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField select 
                        fullWidth
                        disabled={disabled} 
                        label={lt('intervalTime')} 
                        value={rotationInterval} 
                        variant="outlined" 
                        onChange={e => setRotationInterval(e.target.value as unknown as number)}>
                            {INTERVALS.map((i) => (
                                <MenuItem key={`intervals-${i.value}`} value={i.value}>{i.display}</MenuItem>
                            ))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid item container spacing={3} direction="column" lg={6}>
                <Grid item>
                    <TextField select 
                        fullWidth
                        disabled={disabled} 
                        label={lt('rotationSize')} 
                        value={rotationSize} 
                        variant="outlined" 
                        onChange={e => setRotationSize(e.target.value as unknown as number)}>
                            {Array.from(Array(5).keys()).slice(1).map((entry) => (
                                <MenuItem key={`rotationSize-${entry}`} value={entry}>{lt('nodeWithCount', { count: entry})}</MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField select 
                        fullWidth
                        disabled={disabled} 
                        label={lt('rotationAlgorithm')} 
                        value={lt('roundRobin')} 
                        variant="outlined">
                        <MenuItem key={`rotationAlgorithm-oldest-first`} value={lt('roundRobin')}>{lt('roundRobin')}</MenuItem>    
                    </TextField>
                </Grid>
            </Grid>
            <Grid item container justify='flex-end'>
                <Button disabled={disabled} size="large" variant="contained" color={rotatesignersStatus?.isActivated ? "default" : "primary"}
                    onClick= {() =>  toggleStatus(rotatesignersStatus?.isActivated ? 'deactivate' : 'activate')}>
                    {rotatesignersStatus?.isActivated ? lt('deactivate') : lt('activate')}
                </Button>
            </Grid>
            {hasChanges && rotatesignersStatus?.isActivated &&
            <Grid item>
                <AlertBanner description={lt('hasChanges')} />
            </Grid>}
        </Grid>
    )

    const content = (
        <Grid item container spacing={3}>
            <Grid item container direction='column' spacing={3} xs={12} lg={8}>
                <Grid item>
                    <DisplayGridWrapper padDisplayGrid header={lt('settings')} displayGrid={configsEditor} />
                </Grid>
                <Grid item>
                    <SignersTable {...{nodes}} {...{rotatesignersCurrentSigners}} {...{rotatesignersNextSigners}} isManageView />
                </Grid>
            </Grid>
            <Grid item container direction='column' spacing={3} xs={12} lg={4}>
                <Grid item>
                    <DisplayCard header={lt('documentation')} itemList={documentationList}/>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage}/>
            <Grid container direction="column" spacing={3}>
                <Grid item container wrap="nowrap" justify="space-between" alignItems='center'>
                    <Grid item>
                        <Typography variant="h5">
                            {lt("header")}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item>
                    {service?.state !== 'started' ? (
                        <Grid item>
                            <Typography variant="h6">
                                {lt("notStarted")}
                            </Typography>
                        </Grid>
                    ) : (
                        <>
                            {loading && <CircularProgress />}
                            {showContent && content}
                        </>
                    )}
                    
                </Grid>
            </Grid>
        </>
    );
};

interface translations {
    header: string;
    notStarted: string
    targetSigners: string
    rotationSize: string
    intervalTime: string
    rotationAlgorithm: string
    roundRobin: string
    documentation: string
    introduction: string
    apiDocs: string
    blog: string
    scale: string
    settings: string
    nodeWithCount: string
    nodeWithCount_plural: string
    activate: string
    deactivate: string
    hasChanges: string
    every5Mins: string
    every30Mins: string
    every1Hour: string
    every6Hours: string
    every12Hours: string
    every1Day: string
    every15Days: string
    every30Days: string
}

const enTranslations: translations = {
    header: "Manage",
    notStarted: "This runtime must be started to manage its configuration.",
    targetSigners: 'Target Number of Signers',
    rotationSize: 'Node Rotation Size',
    intervalTime: 'Rotation Interval',
    rotationAlgorithm: 'Rotation Algorithm',
    roundRobin: 'Round Robin (oldest first)',
    documentation: 'Documentation',
    introduction: 'Introduction',
    apiDocs: 'API Reference',
    blog: 'Blog',
    scale: 'Scale IBFT Consensus Algorithm',
    settings: 'Settings',
    nodeWithCount: "{{count}} Node Per Interval",
    nodeWithCount_plural: "{{count}} Nodes Per Interval",
    activate: 'Activate',
    deactivate: 'Deactivate',
    hasChanges: 'One or more settings have been changed. You must Deactivate and then Re-Activate the service for the changes to take effect.',
    every5Mins: 'Every 5 Minutes',
    every30Mins: 'Every 30 Minutes',
    every1Hour: 'Every 1 Hour',
    every6Hours: 'Every 6 Hours',
    every12Hours: 'Every 12 Hours',
    every1Day: 'Every 1 Day',
    every15Days: 'Every 15 Days',
    every30Days: 'Every 30 Days'
};
