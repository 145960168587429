import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { EnvironmentResourcesVars, EnvironmentResourceVars } from '../interfaces';

// INTERFACES
export type ChannelState = "creating" | "updating" | "live" | "failed"

// this should be an autogenerated interface from the Channel model in graphql
export interface Channel {
    _id: string
    membership_id: string
    name: string
    description?: string
    members: string[]
    state: ChannelState
    channel_map_version: number
    contracts: ChannelChaincode[]
    policies: ChannelPolicy[]
    created_at: string
    updated_at: string
}

export interface ChannelPolicy {
    name: string
    path: string
    type: string
    rule: string
}

export interface ChannelChaincode {
    _id: string
    label: string
    init_required: boolean
    sequence?: number
    contract_id: string
    consortiaContractName: string
    description: string
}

export interface ChannelsData {
    channels: Channel[]
}

export interface ChannelData {
    channel: Channel
}

export interface ChannelInput {
    name: Channel['name'],
    description: Channel['description'],
    membership_id: Channel['membership_id']
    members: Channel['members']
}

export interface CreateChannelVars extends EnvironmentResourcesVars {
    channel: ChannelInput
}

interface UpdateChannelInput {
    description?: Channel['description'],
    members?: Channel['members'],
}

export interface DeployCompiledContractData {
    deployCompiledContract: Channel
}

interface DeployContractInput {
    compiled_contract_id: string
    init_required: boolean
}

export interface UpdateChannelVars {
    channel: UpdateChannelInput
}

export interface DeployContractInputVars extends EnvironmentResourceVars {
    compiledContract: DeployContractInput
}

export interface CreateChannelData {
    createChannel: Channel
}

export interface ChannelsData {
    channels: Channel[]
}

// FRAGMENTS

export const ChannelFields = ` 
    fragment ChannelFields on Channel {
        _id
        name
        channel_map_version
        contracts {
            _id
            label
            init_required
            sequence
            contract_id
            consortiaContractName
            description
        }
        policies {
            name
            path
            rule
        }
        description
        membership_id
        members
        created_at
        updated_at
        state
    }`

// QUERIES & SUBSCRIPTIONS

export const ChannelsQuery = gql`
    ${ChannelFields}
    query getChannels($consortia_id: String!, $environment_id: String!) {
        channels(consortia_id: $consortia_id, environment_id: $environment_id){
            ...ChannelFields
        }
    }`;

export const ChannelQuery = gql`
${ChannelFields}
query getChannel($consortia_id: String!, $environment_id: String!, $id: String!) {
    channel(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
        ...ChannelFields
    }
}`;  

export const ChannelsSubscription = gql`
    ${ChannelFields}
    subscription onChannelsChanged($consortia_id: String!, $environment_id: String!) {
        channel(consortia_id: $consortia_id, environment_id: $environment_id) {
            ...ChannelFields
        }
    }`

export const CreateChannelMutation = gql`
    ${ChannelFields}
    mutation createChannel($consortia_id: String!, $environment_id: String!, $channel: CreateChannelInput!) {
        createChannel(consortia_id: $consortia_id, environment_id: $environment_id, channel: $channel) {
            ...ChannelFields
        }
    }`;

export const UpdateChannelMutation = gql`
    ${ChannelFields}
    mutation updateChannel($consortia_id: String!, $environment_id: String!, $id: String!, $channel: UpdateChannelInput!) {
        updateChannel(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, channel: $channel) {
            ...ChannelFields
        }
    }`;

export const DeployCompiledContractMutation = gql`
    ${ChannelFields}
    mutation deployCompiledContract($consortia_id: String!, $environment_id: String!, $id: String!, $compiledContract: DeployContractInput!) {
        deployCompiledContract(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, compiledContract: $compiledContract) {
            ...ChannelFields
        }
    }`;
    
    
// MUTATION OPTIONS

export function MakeChannelCreateMutationOptions(
    variables: CreateChannelVars,
    overrideOptions?: MutationFunctionOptions<CreateChannelData, CreateChannelVars>): MutationFunctionOptions<CreateChannelData, CreateChannelVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const channel = data?.createChannel
            if (channel) {
                const { channels } = cache.readQuery<ChannelsData>({ query: ChannelsQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!channels.find(n => n._id === channel._id)) {
                    cache.writeQuery({
                        variables,
                        query: ChannelsQuery,
                        data: { channels: channels.concat(channel) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}


// SUBSCRIPTION OPTIONS

// these MakeChannelsSubscriptionOptions could probably be made into functions that take typescript generics as 
// they are _mostly_ identical across the models

export function MakeChannelsSubscriptionOptions(
    variables: EnvironmentResourcesVars,
    overrideOptions?: SubscriptionHookOptions<ChannelData>
    ): SubscriptionHookOptions<ChannelData> {
        return { ...{
            variables,
            onSubscriptionData: ({client, subscriptionData}) => {
                // Get the channel object from the event
                const { 
                    data: { 
                        channel
                    } = { channel: null }
                } = subscriptionData
    
                if (!channel) return
    
                // Get current list of channels from the cache
                // assert that channels must exist since this function throws if it doesnt
                const { channels: existingChannels } = client.readQuery<ChannelsData>({ 
                    query: ChannelsQuery, 
                    variables 
                })!
                
                // if it already exists in the store, (its an update), apollo automagically updated the cache
                const cachedChannels = existingChannels.find(c => c._id === channel._id)
    
                // but, if it came back as deleted, we need to remove it from the cache manually
                if (cachedChannels) {
                    client.writeQuery<ChannelsData>({ 
                        query: ChannelsQuery, 
                        data: Object.assign({}, existingChannels, {
                            channels: [...existingChannels]
                        }),
                        variables
                    })
                    return 
                }
    
                // if it doesnt exist in the store, (it's been added), so we need to add it in manually
                client.writeQuery<ChannelsData>({ 
                    query: ChannelsQuery, 
                    data: Object.assign({}, existingChannels, {
                        channels: [channel, ...existingChannels]
                    }),
                    variables
                })
            }
        }, ...overrideOptions }
}
