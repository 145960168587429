import React from 'react';
import { DisplayTable } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { useHistory, useParams } from 'react-router-dom';
import { LedgerContract } from '../../models';
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TOKENS_PATH } from '../../components/TokenExplorerNav/TokenExplorerNav';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Jazzicon from 'react-jazzicon';
import { jsNumberForAddress } from '../../utils/StringUtils';

interface Props {
    tokens: LedgerContract[]
    setSuspendUpdates?: React.Dispatch<React.SetStateAction<boolean>>
}

export const LatestContracts = ({tokens, setSuspendUpdates} : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LatestContracts', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LatestContracts:${key}`, interpolate);

    const history = useHistory();
    const classes = useStyles();

    const {org_id, environment_id, consortium_id} = useParams<any>();

    const historyPusher = (address?: string) => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TOKENS_PATH}${address ? `/${address}` : ''}`)
    }

    const returnType = (token: LedgerContract) => {
        if (token.isERC20) return lt('ERC20');
        if (token.isERC721) return lt('ERC721');
        return ''
    }

    const getNameElement = (token: LedgerContract) => (
        <Grid item container spacing={1} alignItems="center">
            <Grid item className={classes.icon}>
                <Jazzicon diameter={20} seed={jsNumberForAddress(token.address)} />
            </Grid>
            <Grid item>
                <Typography variant="body2">{token.tokenName ?? ''}</Typography>
            </Grid>
        </Grid>
    )

    const records: DisplayTableRecord[] = tokens.map((token, index) => ({
        key: `tokens-${index}`,
        columns: [{
            value: getNameElement(token),
        }, {
            value: token.address ? <ShortenedHash address={token.address} setModalOpen={setSuspendUpdates} /> : '--'
        }, {
            value: token.tokenSymbol || '--'
        }, {
            value: returnType(token)
        }],
        onClick: () => historyPusher(token.address)
    }));

    const columns = [lt('name'), lt('address'), lt('symbol'), lt('type')];

    const linkButton = {
        text: lt('viewAllTokens'),
        onClick: () => historyPusher()
    }

    return (
        <DisplayTable header={lt('header')} records={records} columnHeaders={columns} {...{linkButton}} />
    )
}

interface translations {
    header: string
    name: string
    address: string
    symbol: string
    type: string
    ERC20: string
    ERC721: string
    viewAllTokens: string
}

const enTranslations: translations = {
    header: 'Most Recent Tokens',
    name: 'Name',
    address: 'Address',
    symbol: 'Symbol',
    type: 'Type',
    ERC20: 'ERC20',
    ERC721: 'ERC721',
    viewAllTokens: 'View All Tokens'
};

const useStyles = makeStyles(() => ({
    icon: {
        display: 'flex',
        alignSelf: 'center'
    }
}))