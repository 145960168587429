import React, { useState, useEffect } from 'react';
import { 
    List,
    makeStyles,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { NodeData, NodeQuery, EnvironmentZonesData, EnvironmentZonesQuery, FeatureTogglesQuery, FeatureTogglesVars, FeatureTogglesData } from '../../models';
import { useParams, useHistory, useLocation, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { NodePicker } from './NodePicker'
import { SubNavs } from '../MainNav/SideNavs/SubNavs';
import { EnvironmentResourceVars, EnvironmentResourcesVars } from '../../interfaces';
import { BLOCKCHAIN_BASE_PATH } from '../MainNav/SideNavs/Blockchain';
export const NODE_METRICS_PATH = 'metrics'
export const NODE_LOGS_PATH = 'logs'
export const NODE_SETTINGS_PATH = 'settings'
export const NODE_ACCOUNTS_PATH = 'accounts'
export const NODE_ClOUD_CONFIGURATIONS_PATH = 'cloud';
export const NODE_PROTOCOL_CONFIGURATIONS_PATH = 'protocol';
export const NODE_EVENTSTREAMS_PATH = 'eventstreams'
export const NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH = 'subscriptions'
export const NODE_USER_MANAGEMENT_PATH = 'users'
export const NODE_CONTRACTS_PATH = 'contracts'
export const NODE_CORDAPPS_PATH = 'cordapps';
export const NODE_TETHER_PATH = 'tether';
export const FABCONNECT_REST_API_PATH = 'fabconnect';

const NodeNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeNav:${key}`, interpolate)

    const OVERVIEW = lt('overview')
    const METRICS = lt('metrics')
    const LOGS = lt('logs')
    const ACCOUNTS = lt('accounts')
    const SETTINGS = lt('settings')
    const CLOUD_CONFIGURATIONS = lt('cloudConfigurations');
    const PROTOCOL_CONFIGURATIONS  = lt('protocolConfigurations');
    const EVENTSTREAMS = lt('eventStreams');
    const USER_MANAGEMENT = lt('userManagement');
    const CORDAPPS = lt('cordapps');
    const TETHER = lt('publicEthereumTether');
    const FABCONNECT = lt('fabconnect')

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();
    const { pathname } = useLocation();
    
    const [selectedItem, setSelectedItem] = useState<string>();

    useEffect(() => {
        const splitPathname = pathname.split('/')

        if (pathname.endsWith(node_id!)) setSelectedItem(OVERVIEW)
        else if (pathname.endsWith(NODE_METRICS_PATH)) setSelectedItem(METRICS)
        else if (pathname.endsWith(NODE_ClOUD_CONFIGURATIONS_PATH)) setSelectedItem(CLOUD_CONFIGURATIONS)
        else if (pathname.endsWith(NODE_PROTOCOL_CONFIGURATIONS_PATH)) setSelectedItem(PROTOCOL_CONFIGURATIONS)
        else if (pathname.endsWith(NODE_SETTINGS_PATH)) setSelectedItem(SETTINGS)
        else if (pathname.endsWith(NODE_TETHER_PATH)) setSelectedItem(TETHER)
        else if (pathname.endsWith(NODE_EVENTSTREAMS_PATH) || 
            splitPathname[splitPathname.length -2] === NODE_EVENTSTREAMS_PATH || 
            splitPathname[splitPathname.length -4] === NODE_EVENTSTREAMS_PATH) setSelectedItem(EVENTSTREAMS);
        else if (pathname.endsWith(NODE_USER_MANAGEMENT_PATH) || splitPathname[splitPathname.length -2] === NODE_USER_MANAGEMENT_PATH) setSelectedItem(USER_MANAGEMENT);
        else if (pathname.endsWith(NODE_CORDAPPS_PATH) || splitPathname[splitPathname.length -2] === NODE_CORDAPPS_PATH) setSelectedItem(CORDAPPS);
        else if (pathname.endsWith(NODE_LOGS_PATH) || splitPathname[splitPathname.length -2] === NODE_LOGS_PATH) setSelectedItem(LOGS)
        else if (pathname.endsWith(NODE_ACCOUNTS_PATH) || splitPathname[splitPathname.length -2] === NODE_ACCOUNTS_PATH) setSelectedItem(ACCOUNTS)
        else if (pathname.endsWith(FABCONNECT_REST_API_PATH)) setSelectedItem(FABCONNECT)
    }, [pathname, node_id, LOGS, TETHER, METRICS, OVERVIEW, ACCOUNTS, SETTINGS, CLOUD_CONFIGURATIONS, PROTOCOL_CONFIGURATIONS, USER_MANAGEMENT, CORDAPPS, EVENTSTREAMS, FABCONNECT])

    const {
        data: { 
            node
        } = { node: null } 
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: node_id!
        },
        fetchPolicy: 'cache-only'
    });
    const {
        data: { 
            environmentZones: zones
        } = { environmentZones: null } 
    } = useQuery<EnvironmentZonesData, EnvironmentResourcesVars>(EnvironmentZonesQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!,
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        fetchPolicy: 'cache-first'
    });

    const isCorda = node?.isCorda
    const isFabric = node?.isFabric
    const zone = zones?.find(z => node && node.zone_id === z._id);
    const isPrivateStack = zone && zone.type !== 'kaleido';

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    const blockchainDashboardPath = `${basePath}/${BLOCKCHAIN_BASE_PATH}`;
    const baseNodesPath = `${basePath}/nodes`

    const historyPusher = (path?: string) => 
        history.push(`${baseNodesPath}/${node_id}${path ? `/${path}` : ''}`)

    let tabs = [
        {
            name: OVERVIEW,
            action: () => historyPusher()
        }
    ]

    if (!isPrivateStack) {
        tabs.push({
            name: METRICS,
            action: () => historyPusher(NODE_METRICS_PATH)
        });
    }
    
   
        
    if (!isCorda && !isFabric) {
        tabs.push(
            {
                name: ACCOUNTS,
                action: () => historyPusher(NODE_ACCOUNTS_PATH)
            },
            {
                name: EVENTSTREAMS,
                action: () => historyPusher(NODE_EVENTSTREAMS_PATH)
            }
        )
        if (featureToggles?.nodeConfigs) {
            tabs.push({
                name: PROTOCOL_CONFIGURATIONS,
                action: () => historyPusher(NODE_PROTOCOL_CONFIGURATIONS_PATH)
            })
        }
        if (featureToggles?.tether) {
            tabs.push({
                name: TETHER,
                action: () => historyPusher(NODE_TETHER_PATH)
            })
        }
    }

    if (isFabric) {
        if (node?.role === 'peer') {
            tabs.push({
                name: FABCONNECT,
                action: () => historyPusher(FABCONNECT_REST_API_PATH)
            })
        }
        if (featureToggles?.nodeConfigs) {
            tabs.push({
                name: PROTOCOL_CONFIGURATIONS,
                action: () => historyPusher(NODE_PROTOCOL_CONFIGURATIONS_PATH)
            })
        }
    }
    
    if (isCorda) {
        tabs.push({
            name: CORDAPPS,
            action: () => historyPusher(NODE_CORDAPPS_PATH)
        },{
            name: USER_MANAGEMENT,
            action: () => historyPusher(NODE_USER_MANAGEMENT_PATH)
        });
    }
    
    tabs = tabs.concat([
        {
            name: CLOUD_CONFIGURATIONS,
            action: () => historyPusher(NODE_ClOUD_CONFIGURATIONS_PATH)
        },
        {
            name: SETTINGS,
            action: () => historyPusher(NODE_SETTINGS_PATH)
        },
        {
            name: LOGS,
            action: () => historyPusher(NODE_LOGS_PATH)
        }
    ]);
    

    if (!node) {
        return <Redirect to={blockchainDashboardPath} />
    }

    return (
        <div data-test="nodeWrapper">
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(blockchainDashboardPath)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{lt('dashboard')}</Typography>} 
                        disableTypography />
                </ListItem>

                <ListItem>
                    <ListItemText secondary={lt('node')} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>
                
                <NodePicker />

                <SubNavs list={tabs} selected={true} {...{selectedItem}} />
            </List>
        </div> 
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    backButton: {
        paddingLeft: theme.spacing(1)
    },
    backIcon: {
        minWidth: 0
    }
}));

interface translations {
    overview: string
    metrics: string
    logs: string
    settings: string,
    userManagement: string,
    accounts: string,
    cloudConfigurations: string,
    protocolConfigurations: string
    node: string,
    dashboard: string,
    cordapps: string,
    eventStreams: string
    publicEthereumTether: string
    fabconnect: string
}
const enTranslations: translations = {
    overview: 'Overview',
    metrics: 'Metrics & Monitoring',
    logs: 'Logs',
    settings: 'Settings',
    userManagement: 'User Management',
    accounts: 'Wallet',
    cloudConfigurations: 'Cloud Configurations',
    protocolConfigurations: 'Protocol Configurations',
    node: 'Node',
    dashboard: 'Dashboard',
    cordapps: 'CorDapp Management',
    eventStreams: 'Event Streams',
    publicEthereumTether: "Public Ethereum Tether",
    fabconnect: 'REST API Gateway'
}

export { NodeNav };

