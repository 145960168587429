import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, TextField, Grid } from "@material-ui/core";
import { Step1Help } from './Step1Help'
import { CreateWrapper } from '../../components/DialogWrappers'
import { CreateStepProps } from '../../interfaces';

interface Props extends CreateStepProps {
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>,
    description: string,
    setDescription: React.Dispatch<React.SetStateAction<string>>
};

export const Step1 = ({ name, setName, description, setDescription, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConsortiumCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConsortiumCreateStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id } = useParams<any>();

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/create/2`, {
            name,
            description
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>

            <Grid item >
                <TextField
                    data-test="consortiumName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            <Grid item >
                <TextField
                    multiline
                    rows={4}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={name ? false : true} onNext={save} isFirstStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string,
    name: string,
    description: string,
}
const enTranslations: translations = {
    header: 'Enter Network Details',
    name: 'Name of this network',
    description: 'Network mission statement',
}