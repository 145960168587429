import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateConfigForm, CreateConfigInputList } from './CreateConfigForm';
import { FieldElement } from 'react-hook-form';
import { ConfigDetails, ProviderType } from '../../../models/configs';


interface Props {
    provider: ProviderType,
    register: {<Element extends FieldElement<ConfigDetails> = FieldElement<ConfigDetails>>(): (ref: Element | null) => void;}
}

export const CreateKeyStore = ({provider, register}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateKeyStore', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateKeyStore:${key}`, interpolate);


    const AzureInputList: CreateConfigInputList[] = [
        {
            label: lt('applicationId'),
            value: 'api_key'
        },
        {
            label: lt('applicationSecret'),
            value: 'api_secret'
        },{
            label: lt('masterKeyIdentifier'),
            value: 'master_key'
        }
    ]

    const AwsInputList: CreateConfigInputList[] = [
        {
            label: lt('region'),
            value: 'region'
        }, {
            label: lt('masterKey'),
            value: 'master_key'
        }
    ]


    return <CreateConfigForm configType='kms' inputList={provider === 'aws' ? AwsInputList : AzureInputList} showCredsTypeSelection={provider === 'aws'} {...{register}} />;

}

interface translations {
    name: string,
    region: string,
    storageAccount: string, 
    accessKey: string,
    masterKey: string,
    applicationId: string
    applicationSecret: string
    masterKeyIdentifier: string
}
const enTranslations: translations = {
    name: 'Name',
    region: 'Region',
    storageAccount: 'Storage Account Name',
    accessKey: 'Account Access Key',
    masterKey: 'Master Key',
    applicationId: 'Application ID',
    applicationSecret: 'Application Secret',
    masterKeyIdentifier: 'Master Key Identifier'
}