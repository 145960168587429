import React, { useState } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { MessageSnackbar } from '../../../components/DialogWrappers'
import { GithubHelp } from '../../../components/ContractManagement/GithubHelp'
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper'
import { GithubCompilationProps, Github } from '../../../components/ContractManagement/Github';
import { CreateStepProps } from '../../../interfaces';

interface Props extends GithubCompilationProps, CreateStepProps{}

export const GithubStep1 = (
    { 
        description,
        membershipId,
        githubUrl,
        contractToCompile,
        solcVersion,
        evmVersion,
        cancelPath
    }: Props) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { contract_id } = useParams<any>();

    const [message, setMessage] = useState('');

    const postSave = async (compiledContractId: string) => {
        const splitPathname = pathname.split('/')
        const baseCompilationsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_COMPILED_CONTRACTS_PATH) + 1).join('/')
        history.push(`${baseCompilationsPath}/${compiledContractId}`)
    }

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <Github {...{contract_id}} {...{description}} {...{membershipId}} {...{githubUrl}} {...{contractToCompile}} {...{solcVersion}} {...{evmVersion}}
                {...{postSave}} isFirstStep isLastStep {...{cancelPath}} {...{setMessage}} />
            <GithubHelp />
        </>
    )
};
