import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1EthHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep1EthHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep1EthHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Why promote this version?',
    helpDescription: 'Now that you have compiled the contract, the next step is to promote the contract to your environment. This will install the Gateway API on every single node in the environment. Then, you can use the Gateway API on any of your nodes to deploy new instances of this contract, as well as send transactions to existing instances. Highlights:',
    helpDesc1: 'Create a Gateway API - Create a dynamic swagger console and downloadable specification for RESTful interaction with smart contract methods.',
    helpDesc2: 'Deploy contracts - Use the Gateway API to deploy your smart contract programmatically or directly through the console.',
    helpDesc3: 'Send transactions - Leverage the API to interact with existing on-chain smart contracts by passing the contract address as a parameter in the call.',
}