import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { useParams } from "react-router-dom";
import { Typography, makeStyles, Grid, Paper, FormControl, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { ZoneRegion, PlanSupports, OrganizationQuery, OrganizationData, OrganizationVars } from '../../models'
import { Paywall } from '../../components/FormControls/Paywall';

interface Props {
    homeRegion: string | undefined,
    regions: ZoneRegion[]
    additionalRegions: string[],
    setAdditionalRegions: React.Dispatch<React.SetStateAction<string[]>>,
    alreadyEnabledRegions?: string[]
};

export const AddRegions = ({ homeRegion, regions, additionalRegions, setAdditionalRegions, alreadyEnabledRegions }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'RegionsAddRegions', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`RegionsAddRegions:${key}`, interpolate)

    const { org_id } = useParams<any>();
    const client = useApolloClient()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setAdditionalRegions(prev => [...prev, event.target.value as string])
        } else {
            setAdditionalRegions(prev => prev.filter(a => a !== (event.target.value as string)))
        }
    };

    // direct read from cache
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsMultiRegion = useMemo(() => PlanSupports.multiRegion(organization), [organization])

    const makeRegionCheckbox = (r: ZoneRegion) => {
        const paywalled = !supportsMultiRegion && homeRegion !== r.region
        const alreadyEnabled = alreadyEnabledRegions?.some(a => a === r.region)
        const checked = alreadyEnabled || additionalRegions.some(a => a === r.region)
        return (
            <Grid item key={r.region}>
                <Paper className={checked && !alreadyEnabled ? classes.radioPaperSelected : classes.radioPaper} elevation={0}>
                    <Grid container direction="row" alignItems="center" spacing={3} className={paywalled ? classes.paywalled : ''}>
                        <Grid item xs={4}>
                            {homeRegion === r.region ?
                            <>
                                <Typography variant="overline">
                                    {lt('homeRegion')}
                                </Typography>
                                <Typography variant="body2">
                                    {r.location}
                                </Typography>
                            </> : 
                            <FormControlLabel
                                control={<Checkbox disabled={!supportsMultiRegion || alreadyEnabled} color="primary" checked={checked} onChange={handleChange} value={r.region} />}
                                label={r.location}
                            />
                            }
                        </Grid>
                        <Grid item xs={2}>
                            <img className={classes.flag} src={`${process.env.PUBLIC_URL}/img/flags/4x3/${r.flag}.svg`} alt=""></img>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">
                                {r.cloud}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">
                                {r.region}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            {!supportsMultiRegion && 
            <Grid item>
                <Paywall description={lt('paywall')} />
            </Grid>
            }
            
            <Grid item container direction="column">
                <FormControl component="fieldset" margin="none">
                    <FormGroup>
                        <Grid item container direction="column" spacing={1} wrap="nowrap">
                            {regions.filter(r => r.region === homeRegion).map(r => makeRegionCheckbox(r))}
                            {regions.filter(r => r.region !== homeRegion).map(r => makeRegionCheckbox(r))}
                        </Grid>
                    </FormGroup>
                </FormControl>
            </Grid>
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
    },
    radioPaperSelected: {
        padding: theme.spacing(1, 2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
    },
    flag: {
        height: '40px',
        width: '40px'
    },
    paywalled: {
        opacity: '50%'
    }
}));

interface translations {
    header: string,
    headerDescription: string,
    homeRegion: string,
    paywall: string
}
const enTranslations: translations = {
    header: 'Enable Multi Region - Add Deployment Regions',
    headerDescription: 'Kaleido allows blockchain networks to span geographies. Your members can choose from these regions when creating nodes.',
    homeRegion: 'Home region',
    paywall: 'Additional regions are unavailable on your current plan.'
}