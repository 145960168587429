import React from "react";
import {
    Grid,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";
import {
    useCertificateInfo,
} from "../../hooks/useCertificateInfo";
import { DisplayInfoList } from "../../components/DisplayInfoList";
import { NETWORK_GOVERNANCE_IDENTITY_PATH } from "../../components/MainNav/SideNavs/Governance";

interface Props {
    cancelPath: string;
}

export const SelfSignInfoDisplay = ({ cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "SelfSignInfoDisplay", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`SelfSignInfoDisplay:${key}`, interpolate);

    const { membership_id } = useParams<any>();

    const {
        proof: membershipJSONIDProof,
        isLoading: isMembershipProofLoading = true,
    } = useCertificateInfo({
        membershipId: membership_id,
    });

    if (isMembershipProofLoading) return <CircularProgress />;
    if (!membershipJSONIDProof) {
        return (
            <Redirect
                to={`${cancelPath}/${NETWORK_GOVERNANCE_IDENTITY_PATH}/1`}
            />
        );
    }

    const infoList = [
        {
            label: lt("commonName"),
            content: membershipJSONIDProof?.commonName ?? lt("notAvailable"),
        },
        {
            label: lt("organization"),
            content: membershipJSONIDProof?.organization ?? lt("notAvailable"),
        },
        {
            label: lt("country"),
            content: membershipJSONIDProof?.country ?? lt("notAvailable"),
        },
        {
            label: lt("serial"),
            content: membershipJSONIDProof?.serial ?? lt("notAvailable"),
        },
    ];

    return (
        <Grid item container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h5">
                    {lt("assertedTestIdentity")}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">{lt("description")}</Typography>
            </Grid>
            <Grid item>
                <DisplayInfoList info={infoList} />
            </Grid>
        </Grid>
    );

};


interface translations {
    assertedTestIdentity: string;
    description: string;
    certificate: string;
    commonName: string;
    organization: string;
    country: string;
    serial: string;
    notAvailable: string;
}
const enTranslations: translations = {
    assertedTestIdentity: "Asserted Test Identity Created",
    description:
        "The certificate chain below was submitted by a member of the Kaleido organization. This chain serves as their identity within this Consortium.",
    certificate: "Certificate",
    commonName: "Common name",
    organization: "Organization",
    country: "Country",
    serial: "Serial",
    notAvailable: "N/A",
};
