import React, { useEffect, useState, useCallback } from 'react';
import { SubNavs } from './SubNavs';
import { SideNavProps, ConsortiumResourceVars } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from "react-router-dom";
import ShapeOutlineIcon from 'mdi-react/ShapeOutlineIcon';
import { NavItem } from '../../NavComponents/NavItem'
import { useQuery } from '@apollo/client';
import { EnvironmentData, EnvironmentQuery } from '../../../models';

export const DIGITAL_ASSETS_BASE_PATH = 'digitalassets'
export const DIGITAL_ASSETS_TOKENS_PATH = 'tokens'
export const DIGITAL_ASSETS_ETHER_POOL_PATH = 'etherpool'

export const DigitalAssets = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DigitalAssets', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DigitalAssets:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const DASHBOARD = lt('dashboard')
    const TOKENS = lt('tokens')

    const historyPusher = useCallback((path?: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${DIGITAL_ASSETS_BASE_PATH}${path ? `/${path}` : ''}`), 
        [history, org_id, consortium_id, environment_id]);

    const splitPathname = pathname.split('/');
    const explorerType = splitPathname[splitPathname.length -2];

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;
    
    useEffect(() => { 
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.endsWith(DIGITAL_ASSETS_BASE_PATH)) {
            setSelectedItem(DASHBOARD)
            setActiveAndSelected()
        }else if (pathname.endsWith(DIGITAL_ASSETS_TOKENS_PATH) || explorerType === DIGITAL_ASSETS_TOKENS_PATH) {
            setSelectedItem(TOKENS)
            setActiveAndSelected()
        } else {
            setSelectedItem('')
            setActiveAndSelected(false)
        }
    }, [pathname, setSelected, explorerType, DASHBOARD, TOKENS])

    const subItems = useCallback(() => {
        return [
            {
                name: DASHBOARD,
                action: () => historyPusher(),
            },
            {
                name: TOKENS,
                action: () => historyPusher(DIGITAL_ASSETS_TOKENS_PATH),
            },
        ]
    }, [historyPusher, DASHBOARD, TOKENS])

    return (
        <>
            <NavItem comingSoon={isCorda} icon={<ShapeOutlineIcon />}
                header={lt('name')} 
                action={() => {
                    if (isCorda) historyPusher()
                    else if (!active) setSelected(!selected)
                }} 
            />
            {!isCorda && <SubNavs list={subItems()} selected={active || selected} {...{selectedItem}} />}
        </>
    )
};

interface translations {
    name: string
    dashboard: string,
    tokens: string,
}
const enTranslations: translations = {
    name: 'Digital Assets',
    dashboard: 'Dashboard',
    tokens: 'Tokens', 
}
