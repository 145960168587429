import { useMutation, useQuery } from '@apollo/client';
import {
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import CheckIcon from 'mdi-react/CheckIcon';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AccountSelector } from '../../../components/AccountSelector/AccountSelector';
import { CookieAppCred } from '../../../components/CookieAppCred/CookieAppCred';
import {
    CreateWrapper,
    ErrorSnackbarCatcher,
    MessageSnackbar,
} from '../../../components/DialogWrappers';
import {
    ConsortiumResourceVars,
    EnvironmentResourceVars,
    ServiceResourcesVars
} from '../../../interfaces';
import {
    Account,
    EnvironmentData,
    EnvironmentQuery,
    NodeData,
    NodeQuery,
    NodeStatusData,
    NodeStatusQuery,
    ReleaseData,
    ReleaseQuery,
    ReleaseVars,
    ServiceData,
    ServiceQuery
} from '../../../models';
import {
    FireflyNamespacesWithDetailsData,
    FireflyNamespacesWithDetailsQuery,
    FireflyRegisterVars,
    FireflyRegistrationData,
    IsFireflyInitializedData,
    IsFireflyInitializedQuery,
    RegisterFireflyMutation
} from '../../../models/firefly';
import { AlertDarkColors } from '../../../utils/Colors';
import { FireflyTemplateHelp } from './FireflyTemplateHelp';

export const Step1 = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FireflyInitializeStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`FireflyInitializeStep1:${key}`, interpolate);

    const history = useHistory();
    const { consortium_id, environment_id, service_id } = useParams<any>();
    const { search } = useLocation();
    const { namespaceId } = queryString.parse(search);

    const [namespace, setNamespace] = useState<string>('');
    const [account, setAccount] = useState<Account>();
    const [orgName, setOrgName] = useState<string>('');
    const [nodeName, setNodeName] = useState<string>('');
    const [identity, setIdentity] = useState<string>('');
    const [cookieAppCred, setCookieAppCred] = useState('');
    const [saving, setSaving] = useState<
        'importing' | 'registering' | 'done' | 'cookie' | ''
    >('cookie');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error' | 'success'>(
        'error'
    );

    const [registerFirefly] = useMutation<
        FireflyRegistrationData,
        FireflyRegisterVars
    >(RegisterFireflyMutation);

    const envVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    };

    let { data: { fireflyNamespacesWithDetails } = { fireflyNamespaceWithDetails: [] } } =
        useQuery<FireflyNamespacesWithDetailsData, ServiceResourcesVars>(
            FireflyNamespacesWithDetailsQuery,
            {
                variables: {
                    service_id: service_id || '',
                },
                fetchPolicy: 'network-only', // make sure we get the latest right away, since the value from the FireflyStatusChip is likely stale since it doesnt poll
            }
        );
    
    fireflyNamespacesWithDetails = fireflyNamespacesWithDetails?.filter(n=> !n.node?.registered && !n.org?.registered)
    if (fireflyNamespacesWithDetails && fireflyNamespacesWithDetails?.length > 0 && namespace === '') {
        const namespaceFound = fireflyNamespacesWithDetails.find(n=> n.namespace?.name === namespaceId)
        if (namespaceFound){
            setNamespace(namespaceFound.namespace?.name)
        } else {
            setNamespace(fireflyNamespacesWithDetails[0]?.namespace?.name)
        }
    }

    // if we get a new error message, kill the loading bar
    useEffect(() => {
        if (message && messageType === 'error') {
            setSaving('');
        }
    }, [message, messageType, setSaving]);

    useEffect(() => {
        if (cookieAppCred && saving === 'cookie') {
            setSaving('');
        }
    }, [cookieAppCred, saving]);

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: 'cache-only',
    });
    
    useEffect(() => {
        if (environment?.isFabric && saving === 'cookie') {
            setSaving('');
        }
    }, [environment?.isFabric, saving]);

    const { data: { service } = { service: null } } = useQuery<
        ServiceData,
        EnvironmentResourceVars
    >(ServiceQuery, {
        variables: {
            ...envVars,
            id: service_id,
        },
        fetchPolicy: 'cache-only',
    });

    const nodeId = service?.details.node_id ?? '';

    const { data: { node } = { node: null } } = useQuery<
        NodeData,
        EnvironmentResourceVars
    >(NodeQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: nodeId,
        },
        fetchPolicy: 'cache-only',
    });

    const {
        loading: isFireflyInitializedLoading,
        data: { isFireflyInitialized } = {
            isFireflyInitialized: {
                initialized: false,
                readyForRegistration: false,
                fireflyTokensErc1155: false,
                totalNamespaces: 0,
                namespacesRegisteredCount: 0
            },
        },
    } = useQuery<IsFireflyInitializedData, EnvironmentResourceVars>(
        IsFireflyInitializedQuery,
        {
            variables: {
                ...envVars,
                id: service?._id || '',
            },
            fetchPolicy: 'network-only',
        }
    );

    const {
        loading: node1StatusLoading,
        data: { nodeStatus: node1Status } = { nodeStatus: null },
    } = useQuery<NodeStatusData, EnvironmentResourceVars>(NodeStatusQuery, {
        variables: {
            ...envVars,
            id: nodeId,
        },
        fetchPolicy: 'cache-and-network',
    });

    const node1PreselectedAccount =
        node1Status?.user_accounts?.find(() => true) ||
        node?.consensus_identity;

    const { data: { release } = { release: null } } = useQuery<
        ReleaseData,
        ReleaseVars
    >(ReleaseQuery, {
        skip: !environment,
        variables: {
            id: environment?.release_id!,
        },
        fetchPolicy: 'cache-and-network',
    });

    const isNewFireFly = () => {
        if (
            release?.supported_features &&
            release?.supported_features.fireflyVersion === '1.2'
        ) {
            return true;
        }
        return false;
    };

    const save = async () => {
        let successfulRegistration = false;
        try {
            setSaving('registering');
            const registrationResults = await registerFirefly({
                variables: {
                    service_id: service_id,
                    fireflyRegister: {
                        namespace,
                        identity: environment?.isFabric ? identity : account?._id!,
                        node_name: nodeName,
                        org_name: orgName,
                    },
                },
            });

            if (registrationResults.data) {
                const { fireflyRegistration } = registrationResults.data;
                if (!!fireflyRegistration.success) {
                    successfulRegistration = true;
                }
            }
            setMessageType(successfulRegistration ? 'success' : 'error');
            setMessage(
                lt(
                    successfulRegistration
                        ? 'doneRegistering'
                        : 'registrationFailed'
                )
            );
            setSaving(successfulRegistration ? 'done': '')
        } catch (e) {
            setSaving('');
            ErrorSnackbarCatcher(e, setMessage);
        }
    };

    const loading =
        isFireflyInitializedLoading ||
        node1StatusLoading;

    if (loading) {
        return <CircularProgress />;
    }

    const disabled =
        loading ||
        saving !== '' ||
        nodeName === '' ||
        orgName === '' ||
        namespace === '' ||
        (environment?.isFabric ? identity === '' : account === undefined) ||
        (environment?.isEthereum === true && !cookieAppCred);

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">{lt('header')}</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('description')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {lt('takesTime')}
                </Typography>
            </Grid>

            {isNewFireFly() && (
                <>
                    <Grid item>
                        <TextField
                            select
                            style={{ width: 400 }}
                            label={lt('namespace')}
                            value={
                                namespace
                            }
                            variant="outlined"
                            onChange={(e) => setNamespace(e.target.value)}
                        >
                            {fireflyNamespacesWithDetails?.map((n) => {
                                return (
                                    <MenuItem key={n.namespace?.name} value={n.namespace?.name}>
                                        {n.namespace?.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" gutterBottom>
                            {lt('nameRequirements')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                size="medium"
                                style={{ width: 400 }}
                                data-test="ff-nodeName"
                                required
                                label={lt('nodeName')}
                                value={nodeName}
                                variant="outlined"
                                onChange={(e) =>
                                    setNodeName(e.target.value as string)
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                size="medium"
                                style={{ width: 400 }}
                                data-test="ff-orgName"
                                required
                                label={lt('orgName')}
                                value={orgName}
                                variant="outlined"
                                onChange={(e) =>
                                    setOrgName(e.target.value as string)
                                }
                            />
                        </FormControl>
                    </Grid>
                    {environment?.isFabric &&  <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                size="medium"
                                style={{ width: 400 }}
                                data-test="ff-identity"
                                required
                                label={lt('identity')}
                                value={identity}
                                variant="outlined"
                                onChange={(e) =>
                                    setIdentity(e.target.value as string)
                                }
                            />
                        </FormControl>
                    </Grid>}
                </>
            )}

            {environment?.isEthereum && (
                <>
                    <Grid item>
                        <AccountSelector
                            disabled={loading || saving !== ''}
                            preSelectedAddress={node1PreselectedAccount}
                            fullWidth
                            {...{ account }}
                            {...{ setAccount }}
                            membershipId={service?.membership_id}
                        />
                    </Grid>
                </>
            )}

            {saving === 'registering' && (
                <Grid item container spacing={3} alignItems="center">
                    <Grid item>
                        <Typography variant="body2">
                            {lt(
                                isFireflyInitialized.readyForRegistration
                                    ? 'registering'
                                    : 'registered'
                            )}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}

            {saving === 'done' && (
                <Grid item container spacing={3} alignItems="center">
                    <Grid item>
                        <Typography variant="body2">
                            {lt('doneRegistering')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CheckIcon color={AlertDarkColors.green} />
                    </Grid>
                </Grid>
            )}
        </>
    );

    return (
        <>
            {!!service && environment?.isEthereum && (
                <CookieAppCred
                    membershipId={service.membership_id}
                    nodeId={nodeId}
                    {...{ setCookieAppCred }}
                    setErrorMessage={setMessage}
                />
            )}
            <MessageSnackbar
                {...{ message }}
                {...{ setMessage }}
                {...{ messageType }}
            />
            <CreateWrapper
                cancelPath=''
                saving={saving !== '' && saving !== 'done'}
                customNextButtonLabel={
                    saving === 'done'
                        ? undefined
                        : lt(
                              isFireflyInitialized.initialized
                                  ? 'register'
                                  : 'init'
                          )
                }
                {...{ content }}
                onNext={
                    saving === 'done' ? () =>  history.goBack() : save
                }
                isFirstStep
                isLastStep
                disabled={disabled && saving !== 'done'}
            />
            <FireflyTemplateHelp />
        </>
    );
};

interface translations {
    header: string;
    init: string;
    description: string;
    register: string;
    takesTime: string;
    error: string;
    registering: string;
    done: string;
    doneRegistering: string;
    orgName: string;
    nodeName: string;
    identity: string;
    registered: string;
    namespace: string;
    nameRequirements: string;
    registrationFailed: string;
}

const enTranslations: translations = {
    header: 'Register node and organization to FireFly',
    description: 'Choose an identity to register to a FireFly namespace',
    init: 'Initialize',
    register: 'Register',
    takesTime: 'Note: This step may take up to 1 minute to complete',
    error: 'An error occurred during initialization',
    registering: 'Registering...',
    done: 'Initializing FireFly Node! The initializing status will be viewable on the Dashboard page.',
    doneRegistering: 'Successfully registered identity to FireFly!',
    orgName: 'Org Name',
    nodeName: 'Node Name',
    identity: 'Identity',
    registered: 'Registered',
    namespace: 'Namespace',
    nameRequirements:
        '*Node and org names must be 1-64 characters, including alphanumerics (a-zA-Z0-9), dot (.), dash (-) and underscore (_), and must start/end in an alphanumeric. It may not contain spaces.',
    registrationFailed: 'Registration has failed. Please try again.',
};
