import { useApolloClient, useQuery } from '@apollo/client';
import { Divider, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SessionData } from '../../interfaces';
import { Organization, RolesData, RolesQuery, RolesTranslations, EnRolesTranslations } from '../../models';
import { SessionQuery } from '../../queries/Session';

interface Props {
    organization: Organization
    handleOrgSelection: (orgId: string) => void
}

export const OrgSwitcherOrgItem: React.FC<Props> = ({ organization, handleOrgSelection }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgSwitcherOrgItem', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgSwitcherOrgItem:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    const {
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, { variables: { org_id: organization._id }, fetchPolicy: 'cache-and-network' });

    return (
        <React.Fragment key={organization._id}>
            <Divider />
            <ListItem button onClick={() => handleOrgSelection(organization._id)}
                key={organization._id}
                selected={organization._id === org_id}>
                <ListItemText primary={organization.name}
                    primaryTypographyProps={{variant: 'h6', noWrap: true}}
                    secondary={lt(roles.find(r => r.user_id === session.user_id)?.role || 'empty').toUpperCase()}
                    secondaryTypographyProps={{variant: 'caption' }} />
            </ListItem>
        </React.Fragment>
    )
};

interface translations extends RolesTranslations {
    empty: string
}
const enTranslations = {
    ...EnRolesTranslations,
    empty: ''
}