import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteIdName } from '../../interfaces'

interface Props {
    selected: AutocompleteIdName,
    setSelected: React.Dispatch<React.SetStateAction<AutocompleteIdName>>
    options: AutocompleteIdName[]
    error?: boolean,
    helperText?: string
    dataTest?: string,
    setWidth?: number,
    label?: string
    disabled?: boolean
    defaultId?: string
};
  
export const IdNameAutocomplete = ({ selected, setSelected, options, error, helperText, dataTest, setWidth, label, disabled, defaultId }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'IdNameAutoComplete', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`IdNameAutoComplete:${key}`, interpolate)

    const hasDupes = [...new Set(options.map(m => m.name))].length < options.length

    const makeAutocompleteValue = (ac: AutocompleteIdName) => {
        return hasDupes && ac.id ? `${ac.name} (${ac.id})` : `${ac.name}`
    }

    const mapToAutocompleteValue = (ac?: AutocompleteIdName) => {
        return ac ? makeAutocompleteValue(ac) : undefined
    }

    const setDefaultAlready = useRef<boolean>(false)

    useEffect(() => {
        if (defaultId && !setDefaultAlready.current) {
            setDefaultAlready.current = true
            const d = options.find(o => o.id === defaultId)
            if (d) {
                setSelected(d)
            }
        }
    }, [defaultId, options, setSelected])

    return (
        <FormControl variant="outlined" fullWidth>
            <Autocomplete
                disabled={disabled}
                value={mapToAutocompleteValue(selected)}
                getOptionSelected={(option) => option.id === selected.id}
                onInputChange={(_event, value) => {
                    const ac = hasDupes ? options.find(m => m.id === value.slice(-11, 13)) : options.find(m => m.name === value)
                    const option = options.find(m => m.id === ac?.id)
                    if (option) {
                        setSelected(option)
                    } else {
                        setSelected({id: '', name: value})
                    }
                }}
                selectOnFocus
                options={options}
                getOptionLabel={(option) => {
                    return option && typeof(option) !== 'string' ? makeAutocompleteValue(option) : (option || '')}
                }
                style={{ width: setWidth ? setWidth : 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField data-test={dataTest} {...{error}} {...{helperText}} {...params}
                        label={label || lt('select')} variant="outlined" />
                )}
            />
        </FormControl>
    )
};

interface translations {
    select: string
}
const enTranslations: translations = {
    select: 'Select existing or type a new one'
}