import { gql } from '@apollo/client';

export interface SupportTicket {
    id: string;
    subject: string;
    description: string;
    status: string;
    updatedAt: string;
}

export interface SupportTicketData {
    supportTickets: SupportTicket[];
}

export interface SupportInboxVars {
    org_id: string;
}

export interface PendingTicketsCountData {
    pendingTicketsCount: number;
}

export const PendingTicketsCountQuery = gql`
    query getPendingTicketsCount($org_id: String!) {
        pendingTicketsCount(org_id: $org_id)
    }
`;

export const SupportInboxQuery = gql`
    query getSupportTickets($org_id: String!) {
        supportTickets(org_id: $org_id) {
            id
            status
            subject
            description
            updatedAt
        }
    }
`;

export interface TicketForm {
    subject: string;
    body: string;
    org_id: string;
    orgName: string;
    uploads?: string[];
    severity: string;
    reason: string;
    collaborators?: string[];
}

export interface SubmitTicketVars {
    ticket: TicketForm;
}

export const SubmitTicketMutation = gql`
    mutation submitTicket($ticket: TicketForm) {
        submitTicket(ticket: $ticket)
    }
`;
