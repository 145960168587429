import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Grid, Typography, TablePagination, CircularProgress } from "@material-ui/core";
import { AuditVars, AuditData, AuditQuery } from '../../models/audit';
import { ActivityTable } from '../../components/ActivityLog/ActivityTable'

export const GovernanceNetworkActivity: React.FC = () => {
    const { t, i18n } = useTranslation();

    const [createdBefore] = useState(encodeURIComponent(new Date().toISOString()));
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    i18n.addResourceBundle('en', 'NetworkActivity', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NetworkActivity:${key}`, interpolate)

    const { consortium_id } = useParams<any>();

    const {
        loading,
        data: {
            audit
        } = { audit: [] }
    } = useQuery<AuditData, AuditVars>(AuditQuery, {
        variables: { consortia_id: consortium_id!, createdBefore, skip: currentPage * rowsPerPage, limit: rowsPerPage },
        fetchPolicy: 'no-cache'
    });

    if (loading && audit.length === 0) {
        return <CircularProgress />
    }

    function handlePageChange(_event: unknown, page: number) {
        setCurrentPage(page);
    }

    const pagination = (
        <TablePagination component="div" 
            count={audit.length < rowsPerPage ? rowsPerPage * currentPage + audit.length : -1} 
            onPageChange={handlePageChange}
            onChangeRowsPerPage={({ target }) => {setCurrentPage(0); setRowsPerPage(Number(target.value))}}
            rowsPerPage={rowsPerPage} 
            page={currentPage} />
    )

    return (
        <Grid item container direction={"column"} spacing={3}>
            <Grid item container>
                <Typography variant="h5">
                    {lt('networkActivity')}
                </Typography>
            </Grid>
            <Grid item container>
                <ActivityTable header={lt('networkActivity')} {...{pagination}} auditEntries={audit} />
            </Grid>
        </Grid>
    )
};

interface translations {
    networkActivity: string
    status: string
    owner: string
    timestamp: string
}
const enTranslations: translations = {
    networkActivity: 'Network Activity',
    status: 'Status',
    owner: 'Owner',
    timestamp: 'Timestamp',
}