import React, { useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
    DocumentsQuery,
    DocumentsData,
    DocumentsVars,
    DeleteDocumentMutation,
    SearchDocumentsData,
    SearchDocumentVars,
    SearchDocumentsQuery,
    RecalculateDocumentHashData,
    RecalculateDocumentHashVars,
    RecalculateDocumentHashMutation,
    SearchDocument,
    Document,
} from "../../../models/documentstore";
import { UploadFile } from "./UploadFile";
import { BreadCrumb } from "./BreadCrumb";
import { useTranslation } from "react-i18next";
import {
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Button,
    TablePagination,
    CircularProgress,
} from "@material-ui/core";
import { TransferFile } from "./TransferFile";
import {
    FormDialog,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../../components/DialogWrappers";
import fileSize from "filesize";
import { DisplayTable, ToolBarOptions } from "../../../components/DisplayWrappers";
import InsertDriveFileIcon from "mdi-react/FileIcon";
import { ShortenedString } from "../../../components/FormControls/ShortenedString";
import CachedIcon from "mdi-react/CachedIcon";
import { DocStoreActionMenu } from "./DocumentStoreTable/DocStoreActionMenu";
import FolderIcon from "mdi-react/FolderIcon";

const LIMITLIST = [10, 25];

const getDocumentName = (docName: string, searchActive: boolean) => {
    let downloadName;
    if (searchActive) {
        const n = docName.split("/");
        downloadName = n[n.length - 1];
    } else {
        downloadName = docName;
    }
    return downloadName;
};

export const DocumentStore: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "DocumentsTable", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`DocumentsTable:${key}`, interpolate);

    const { service_id } = useParams<any>();
    const classes = useStyles();

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [path, setPath] = useState<Array<string>>([]);
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [searchType, setSearchType] = useState<string>("name");
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState<number>(LIMITLIST[0]);
    const [openUpload, setOpenUpload] = useState<boolean>(false);
    const [openTransfer, setOpenTransfer] = useState<boolean>(false);
    const [actionItemName, setActionItemName] = useState<string>("");
    const [isActionItemDirectory, setIsActionItemDirectory] = useState<boolean>(
        false
    );
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [errorMessage, setErrorMessasge] = useState<string>("");

    const {
        loading,
        refetch,
        data: {
            documents: { entries, is_truncated } = {
                entries: [],
                is_truncated: false,
            },
        } = { documents: {} },
    } = useQuery<DocumentsData, DocumentsVars>(DocumentsQuery, {
        variables: {
            service_id: service_id!,
            path: path.join("/"),
            offset: page * limit,
            limit,
        },
        fetchPolicy: "cache-and-network",
        onError: () => setPath([]),
    });

    const [
        fetchSearch,
        { data: searchResults, loading: searchLoading },
    ] = useLazyQuery<SearchDocumentsData, SearchDocumentVars>(
        SearchDocumentsQuery,
        {
            fetchPolicy: "no-cache",
        }
    );

    const [recalculateHash] = useMutation<
        RecalculateDocumentHashData,
        RecalculateDocumentHashVars
    >(RecalculateDocumentHashMutation);

    const [deleteDocument] = useMutation(DeleteDocumentMutation);

    if (loading && entries?.length === 0) return <CircularProgress />;

    const updatePath = (folderName: string) => {
        setPage(0);
        setPath((c) => [...c, folderName]);
    };
    const onBreadCrumbClick = (index: number) => {
        setPage(0);
        setPath((c) => c.slice(0, index));
    };

    const onChangePage = async (e: unknown, page: number) => {
        setPage(page);
    };

    const onLimitChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setPage(0);
        setLimit(parseInt(event.target.value));
    };

    const onDeleteConfirm = async (docName: string) => {
        if (!searchActive) {
            await deleteDocument({
                variables: {
                    service_id: service_id!,
                    path: [...path, docName].join("/"),
                },
            });
            //Try catch needed because on error isnt called for cache first
            try {
                await refetch();
            } catch (err) {
                setPath([]);
                await refetch();
            }
        } else {
            await deleteDocument({
                variables: {
                    service_id: service_id!,
                    path: docName,
                },
            });
            await fetchSearch({
                variables: {
                    service_id: service_id!,
                    query: searchQuery,
                    byHash: searchType === "hash",
                },
            });
            await refetch();
        }
    };

    const onRecalculateHashClick = async (docName: string) => {
        try {
            if (searchActive) {
                await recalculateHash({
                    variables: {
                        service_id: service_id!,
                        path: docName,
                    },
                });
                await fetchSearch({
                    variables: {
                        service_id: service_id!,
                        query: searchQuery,
                        byHash: searchType === "hash",
                    },
                });
            } else {
                await recalculateHash({
                    variables: {
                        service_id: service_id!,
                        path: [...path, docName].join("/"),
                    },
                });
                await refetch();
            }
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    };

    const onDeleteClick = (docName: string, isDirectory: boolean) => {
        setIsActionItemDirectory(isDirectory);
        setActionItemName(docName);
        setConfirmDelete(true);
    };

    const onDownloadClick = async (docName: string) => {
        try {
            const documentPath = searchActive
                ? `${docName}`
                : `${path.join('/')}/${docName}`;
            const response = await fetch(
                `/api/ui/v2/documentstore/${service_id}/documents/${documentPath}`,
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        "x-photic-binary": "true",
                    },
                }
            );
            const responseBlob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([responseBlob]));
            const link = document.createElement("a");
            link.href = url;
            let downloadName = getDocumentName(docName, searchActive);
            link.setAttribute("download", downloadName);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    };

    const onTransferClick = (docName: string) => {
        setActionItemName(docName);
        setOpenTransfer(true);
    };

    const onSearchSubmit = (searchInput: string) => {
        setSearchQuery(searchInput); //To re render after deletion on search
        fetchSearch({
            variables: {
                service_id: service_id!,
                query: searchInput,
                byHash: searchType === "hash",
            },
        });
    };

    const typeList = [
        {
            value: "hash",
            label: lt("hash"),
        },
        {
            value: "name",
            label: lt("name"),
        },
    ];

    const columns = [
        searchActive ? lt("path") : lt("name"),
        lt("size"),
        lt("updated"),
        lt("hash"),
        "",
    ];

    const instanceOfDocument = (object: any): object is Document => {
        return "name" in object;
    };

    const getNameContent = (document: Document) => {
        return document.is_directory ? (
            <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                    <FolderIcon className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                    <Typography noWrap>{document.name}</Typography>
                </Grid>
            </Grid>
        ) : (
            <Grid container wrap="nowrap" alignItems="center">
                <Grid item>
                    <InsertDriveFileIcon className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                    <Typography noWrap>{document.name}</Typography>
                </Grid>
            </Grid>
        );
    };

    const getHash = (document: Document | SearchDocument) => {
        if (document.hash) {
            return <ShortenedString content={document.hash} />;
        } else {
            return (
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item>
                        <Typography noWrap>{lt("reloadHash")}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() =>
                                onRecalculateHashClick(
                                    instanceOfDocument(document)
                                        ? document.name
                                        : document.full_path
                                )
                            }
                            size="small"
                            color="primary"
                        >
                            <CachedIcon color="primary" />
                        </IconButton>
                    </Grid>
                </Grid>
            );
        }
    };

    const getRecords = () => {
        return (
            entries?.map((entry) => ({
                onClick:
                    entry.is_directory && !loading
                        ? () => updatePath(entry.name)
                        : undefined, //Waits for laoding to avoid double clicks
                columns: [
                    {
                        value: getNameContent(entry),
                        maxWidthPixelValue: 200,
                    },
                    {
                        value: entry.is_directory ? "--" : fileSize(entry.size),
                    },
                    {
                        value: entry.is_directory
                            ? "--"
                            : new Date(entry.last_modified).toLocaleString(),
                    },
                    {
                        value: entry.is_directory ? "--" : getHash(entry),
                        minWidthPixelValue: 200,
                    },
                    {
                        value: (
                            <DocStoreActionMenu
                                {...{ onDownloadClick }}
                                isDirectory={entry.is_directory}
                                actionDocumentName={entry.name}
                                {...{ onDeleteClick }}
                                {...{ onTransferClick }}
                            />
                        ),
                        onClick: () => {},
                    },
                ],
            })) ?? []
        );
    };

    const getSearchRecords = () => {
        return (
            searchResults?.searchDocuments?.documents.map((entry) => ({
                columns: [
                    {
                        value: (
                            <Grid container spacing={1} wrap="nowrap">
                                <Grid item>
                                    <InsertDriveFileIcon
                                        className={classes.icon}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography noWrap>
                                        {entry.full_path}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ),
                        maxWidthPixelValue: 200,
                    },
                    {
                        value: fileSize(entry.size),
                    },
                    {
                        value: new Date(entry.last_modified).toLocaleString(),
                    },
                    {
                        value: getHash(entry),
                    },
                    {
                        value: (
                            <DocStoreActionMenu
                                {...{ onDownloadClick }}
                                isDirectory={false}
                                actionDocumentName={entry.full_path}
                                {...{ onDeleteClick }}
                                {...{ onTransferClick }}
                            />
                        ),
                    },
                ],
            })) ?? []
        );
    };

    const onRefrehClick = async () => {
        try {
            if (searchActive) {
                await fetchSearch({
                    variables: {
                        service_id: service_id!,
                        query: searchQuery,
                        byHash: searchType === "hash",
                    },
                });
            } else {
                await refetch()
            }
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    }

    const records = searchActive ? getSearchRecords() : getRecords();

    const tableHeader = (
        <BreadCrumb
            initial={lt("header")}
            currentPath={path}
            onClick={onBreadCrumbClick}
        />
    );

    const toolBarOptions: ToolBarOptions = {
        search: {
            onSearch: onSearchSubmit,
            setSearchActive,
            label: lt("searchDocument", {
                type: lt(searchType as "name" | "hash"),
            }),
            searchType: {
                setTypeValue: setSearchType,
                typeValue: searchType,
                typeList: typeList,
            },
        },
        refresh: {
            onRefresh: onRefrehClick,
        },
    };

    const getEmptyLabel = () => {
        if (searchActive) return lt("emptySearch", { search: searchQuery });
        return lt("noDocuments");
    };

    const Pagination =
        searchActive || !(records.length > 0) ? undefined : (
            <TablePagination
                rowsPerPageOptions={LIMITLIST}
                component="div"
                count={
                    !is_truncated && entries
                        ? entries.length + limit * page
                        : -1
                }
                rowsPerPage={limit}
                onChangeRowsPerPage={onLimitChange}
                onPageChange={onChangePage}
                page={page}
                nextIconButtonProps={{ disabled: loading || !is_truncated }}
            />
        );

    return (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessasge}
            />
            <FormDialog
                successMessage={lt("successDelete")}
                open={confirmDelete}
                description={actionItemName}
                setOpen={setConfirmDelete}
                header={lt("deleteConfirmation", {
                    item: isActionItemDirectory
                        ? lt("directory")
                        : lt("document"),
                })}
                onSave={() => onDeleteConfirm(actionItemName)}
                saveText={lt("delete")}
                closeDialogAfterSave
            />
            <UploadFile
                open={openUpload}
                setOpen={setOpenUpload}
                currentPath={path.join("/")}
                refetchDocuments={refetch}
            />
            <TransferFile
                open={openTransfer}
                documentName={actionItemName}
                setOpen={setOpenTransfer}
                currentPath={path.join("/")}
            />
            <Grid container spacing={3} direction="column">
                <Grid
                    item
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item>
                        <Typography variant="h5">{lt("header")}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => setOpenUpload(true)}
                        >
                            {lt("uploadFile")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container>
                    <DisplayTable
                        loading={loading || (searchActive && searchLoading)}
                        emptyLabel={
                            loading || searchLoading
                                ? undefined
                                : getEmptyLabel()
                        }
                        columnHeaders={columns}
                        records={records}
                        header={tableHeader}
                        {...{ toolBarOptions }}
                        actionFooter={Pagination}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1),
        display: "flex",
        alignSelf: "center",
    },
}));

interface translations {
    header: string;
    rowsPerPage: string;
    name: string;
    updated: string;
    hash: string;
    size: string;
    documents: string;
    deleteDirectory: string;
    uploadFile: string;
    sendDocument: string;
    downloadDocument: string;
    deleteDocument: string;
    deleteDescription: string;
    deleteConfirmation: string;
    delete: string;
    successDelete: string;
    directory: string;
    document: string;
    reloadHash: string;
    searchDocument: string;
    noDocuments: string;
    emptySearch: string;
    path: string;
}

const enTranslations: translations = {
    path: "Path",
    searchDocument: "Search Document by {{type}}",
    header: "Documents",
    rowsPerPage: "rows per page:",
    name: "Name",
    updated: "Uploaded",
    hash: "Hash",
    size: "Size",
    documents: "documents",
    deleteDirectory: "Delete Directory",
    uploadFile: "Upload Document",
    sendDocument: "Send Document",
    downloadDocument: "Download Document",
    deleteDocument: "Delete Document",
    deleteDescription: "Once deleted there is no going back!",
    deleteConfirmation: "Do you want to delete {{item}}?",
    successDelete: "Delete Successful",
    delete: "Delete",
    directory: "directory",
    document: "document",
    reloadHash: "Calculate Hash",
    noDocuments: "No Documents Stored",
    emptySearch: '"{{search}}" Not Found',
};