import React from 'react';
import { AuditEntry } from '../../models/audit';
import RecordCircleOutlineIcon from 'mdi-react/RecordCircleOutlineIcon';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import LanIcon from 'mdi-react/LanIcon';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon'
import CubeScanIcon from 'mdi-react/CubeScanIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import EarthIcon from 'mdi-react/EarthIcon';
import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon';

interface Props {
    auditEntry: AuditEntry
}

export const ActivityIcon = ({ auditEntry }: Props) => {
    const getIcon = (entry: AuditEntry): JSX.Element => {
        switch (entry.objectType) {
            case 'env_zones': // handled in next case
            case 'con_zones': return <EarthIcon />
            case 'services': return <CogOutlineIcon />
            case 'nodes': return <RecordCircleOutlineIcon />
            case 'environments': return <LanIcon />
            case 'appcreds': return <CardAccountDetailsOutlineIcon />
            case 'memberships': return <AccountIcon />
            case 'consortia': return <AccountGroupIcon />
            case 'invitations': return <AccountPlusIcon />
            case 'compiled_contracts': return <CubeScanIcon />
            case 'configurations': return <TuneIcon />
            default: return <CircleOutlineIcon />
        }
    }

    // calling component can wrap this however it likes
    return <>{getIcon(auditEntry)}</> 
};
