import React, { useState } from 'react';
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher } from '../../../components/DialogWrappers';
import { Grid, Typography, CircularProgress, makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LedgerContractData, LedgerContractQuery, Account, Node, TokenContractBalanceOfData, TokenContractBalanceOfVars, TokenContractBalanceOfQuery, getFromForGatewayAPI } from '../../../models';
import { EnvironmentResourceVars } from '../../../interfaces';
import Jazzicon from 'react-jazzicon';
import { jsNumberForAddress, isEthAddress } from '../../../utils/StringUtils';
import { AccountSelector } from '../../../components/AccountSelector/AccountSelector';
import { MintHelp } from './MintHelp';

interface Props {
    senderAddress: Account
    cancelPath: string
    cookieAppCred: string
    membershipNode: Node
}
export const Mint = ({senderAddress, cancelPath, cookieAppCred, membershipNode}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Mint', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Mint:${key}`, interpolate);

    const [mintAmount, setMintAmount] = useState('');
    const [environmentAddress, setEnvironmentAddress] = useState<Account | undefined>(undefined);
    const [customAddress, setCustomAddress] = useState('');
    const [tokenId, setTokenId] = useState('');
    const [tokenURI, setTokenURI] = useState('');
    const [message, setMessage] = useState('');
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [messageType, setMessageType] = useState<'success' | 'error'>('error');
    
    const {consortium_id, environment_id, token_address} = useParams<any>();

    const classes = useStyles();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        refetch: refetchContract,
        loading,
        data: {
            ledgerContract
        } = {ledgerContract: null}
    } = useQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        variables: {
            ...environmentVariables,
            id: token_address!
        },
        fetchPolicy: 'cache-first'
    });

    const {
        refetch: refetchBalance,
        data: {
            tokenContractBalanceOf: accountBalance
        } = { tokenContractBalanceOf: '' }
    } = useQuery<TokenContractBalanceOfData, TokenContractBalanceOfVars>(TokenContractBalanceOfQuery, { 
        variables: {
            ...environmentVariables,
            contractAddress: token_address!,
            accountAddress: senderAddress._id
        },
        skip: !senderAddress || !token_address
    });   

    if (loading) return <CircularProgress />;
    if (!ledgerContract) return <Redirect to={cancelPath} />;

    const onSubmit = async () => {
        const url = `${membershipNode.urls?.kaleido_connect}/instances/${token_address}/${ledgerContract.isERC20 ? 'mint' : 'mintWithTokenURI'}`;
        const queryParams = `?kld-from=${getFromForGatewayAPI(senderAddress)}&kld-sync=true`;
        const fullURl = url + queryParams
        const bodyRecipient = {
            to: environmentAddress?._id || customAddress || senderAddress._id
        };
        const body = ledgerContract.isERC721 ? {
            ...bodyRecipient,
            tokenId,
            tokenURI
        } : { // providing extra / duplicate parameters doesnt hurt and is done b/c of different OZ versions of token contracts (constantinople and old TokenFactory)
            ...bodyRecipient,
            amount: mintAmount,
            value: mintAmount
        }

        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(cookieAppCred));
        setTransactionLoading(true);
        try {
            const response = await fetch(fullURl, {
                method:'POST',
                body: JSON.stringify(body),
                headers: headers,
            });
            if (!response.ok) {
                const processResponse = await response.json();
                throw new Error(processResponse.error || lt('unsuccessfulMint'));
            }
            setMessageType('success');
            setMessage(lt('successfulMint'));
            if(ledgerContract.isERC20) refetchContract();
            refetchBalance();
        } catch (err) {
            setMessageType('error');
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setTransactionLoading(false)
        }
    }

    const isAmountValid = parseInt(mintAmount) > 0;

    const isCustomAddressValid = isEthAddress(customAddress);

    const disabled = ledgerContract.isERC20 ? transactionLoading || !isAmountValid || (!!customAddress && !isCustomAddressValid) : transactionLoading || !tokenId;

    const routerState = {
        account: senderAddress
    }


    const content = (
        <>
            <MessageSnackbar message={message} setMessage={setMessage} messageType={messageType}  />
            <Grid item container alignItems="center" spacing={1}>
                <Grid item className={classes.icon}>
                    <Jazzicon diameter={20} seed={jsNumberForAddress(ledgerContract.address)} />
                </Grid>
                <Grid item>
                    <Typography variant="h5">{lt('header', {tokenName: ledgerContract?.tokenName})}</Typography>
                </Grid>
            </Grid>
            <Grid item container spacing={2} direction="column">
                <Grid item container spacing={1} justify="space-between" alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="body1">{lt('senderAccount')}</Typography>
                    </Grid>
                    <Grid item>
                        <AccountSelector account={senderAddress} />
                    </Grid>
                </Grid>
                {ledgerContract.isERC20 && 
                    <Grid item container spacing={1} justify="space-between">
                        <Grid item>
                            <Typography variant="body1">{lt('tokenSupply')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{ledgerContract.erc20TotalSupply}</Typography>
                        </Grid>
                    </Grid>
                }
                <Grid item container spacing={1} justify="space-between">
                    <Grid item>
                        <Typography variant="body1">{lt('accountBalance')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{accountBalance}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1}>
                {ledgerContract.isERC721 ? 
                    <>
                        <Grid item xs={3}>
                            <Typography variant="body1">{lt('tokenId')}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField required 
                                fullWidth 
                                type="number" 
                                label={lt('id')} 
                                value={tokenId} 
                                onChange={e => setTokenId(e.target.value)} 
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1">{lt('tokenURI')}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField fullWidth 
                                label={lt('URI')} 
                                value={tokenURI} 
                                onChange={e => setTokenURI(e.target.value)} 
                                variant="outlined" />
                        </Grid>
                    </> :
                    <>
                        <Grid item xs={3}>
                            <Typography variant="body1">{lt('amountToMing')}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField required fullWidth label={lt('amount')} type="number" value={mintAmount} onChange={e => setMintAmount(e.target.value)} variant="outlined" />
                        </Grid>
                    </>
                }
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h6">{lt('targetAddress')}</Typography>
                </Grid>
                <Grid item container direction="column" spacing={1} wrap="nowrap">
                    <Grid item xs={3}>
                        <Typography variant="body1">{lt('environmentAddress')}</Typography>
                    </Grid>
                    <Grid item> 
                        <AccountSelector disabled={!!customAddress} fullWidth setAccount={setEnvironmentAddress} account={environmentAddress} />
                    </Grid>
                </Grid>
                <Grid item container justify="center" className={classes.orPosition}>
                    <Typography variant="body2">{lt('or')}</Typography>
                </Grid>
                <Grid item container direction="column" spacing={1} wrap="nowrap"> 
                    <Grid item xs={3}>
                        <Typography variant="body1">{lt('customAddress')}</Typography>
                    </Grid>
                    <Grid item>
                        <TextField error={(customAddress && !isCustomAddressValid) || undefined} 
                            helperText={ customAddress && !isCustomAddressValid && lt('notValidHex')}  
                            disabled={!!environmentAddress} 
                            label={lt('address')} 
                            fullWidth 
                            variant="outlined" 
                            onChange={e => setCustomAddress(e.target.value)} 
                            value={customAddress} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper customNextButtonLabel={lt('mint')} {...{routerState}} {...{cancelPath}} {...{content}} {...{disabled}} onNext={onSubmit} isFirstStep isLastStep saving={transactionLoading} />
            <MintHelp />
        </>
    )
};

interface translations {
    senderAccount: string
    header: string
    accountBalance: string
    amountToMing: string
    targetAddress: string
    environmentAddress: string
    customAddress: string
    optional: string
    or: string
    amount: string
    address: string
    tokenId: string
    id: string
    tokenURI: string
    URI: string
    tokenSupply: string
    unsuccessfulMint: string
    successfulMint: string
    notValidHex: string
    mint: string
}

const enTranslations: translations = {
    mint: 'Mint',
    notValidHex: 'Invalid Address',
    senderAccount: 'Minter Address:',
    header: '{{tokenName}} Mint',
    accountBalance: 'Account Balance:',
    amountToMing: 'Amount to Mint',
    targetAddress: 'Target Address (Optional)',
    environmentAddress: 'Environment Address:',
    customAddress: 'Custom Address:',
    optional: 'Optional',
    or: 'OR',
    amount: 'Amount',
    address: 'Address',
    tokenId: 'Token ID',
    id: 'ID',
    tokenURI: 'Token URI',
    URI: 'URI',
    tokenSupply: 'Token Supply:',
    unsuccessfulMint: 'Unsuccessful Mint',
    successfulMint: 'Successful Mint'
};

const useStyles = makeStyles(() => ({
    icon: {
        display: 'flex',
        alignSelf: 'center'
    },
    orPosition: {
        alignSelf: 'flex-end'
    }
}))