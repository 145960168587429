import { useMutation } from '@apollo/client';
import { FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { OpsTriggerInvoiceData, OpsTriggerInvoiceInputs, OpsTriggerInvoiceMutation } from '../../models';

interface Props {
    orgId?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export const OrgTriggerInvoice: React.FC<Props> = ({orgId, open, setOpen}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgTriggerInvoice', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgTriggerInvoice:${key}`, interpolate)
    
    const [message, setMessage] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [latest, setLatest] = useState(true);

    const [triggerInvoiceMutation] = useMutation<OpsTriggerInvoiceData, OpsTriggerInvoiceInputs>(OpsTriggerInvoiceMutation);

    const triggerInvoice = async () => {
        try {
            await triggerInvoiceMutation({
                variables: {
                    org_id: orgId!,
                    month: latest ? undefined : month,
                    year: latest ? undefined : year,
                }
            })
        } catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog header={lt('triggerInvoice')} open={open} setOpen={setOpen} closeDialogAfterSave onSave={triggerInvoice} saveDisabled={!latest && (!year || !month)} controlsWrapper={
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <FormControlLabel
                        control={<Switch
                            checked={latest}
                            onChange={(e, v) => setLatest(v)}
                        />}
                        label={lt('latest')}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={lt('month')}
                        variant="outlined"
                        fullWidth
                        value={month}
                        disabled={latest}
                        onChange={(e) => setMonth(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={lt('year')}
                        variant="outlined"
                        fullWidth
                        value={year}
                        disabled={latest}
                        onChange={(e) => setYear(e.target.value)}
                    />
                </Grid>                
            </Grid>
        }/>
    </>;
};


interface translations {
    triggerInvoice: string;
    month: string;
    year: string;
    latest: string;
}

const enTranslations: translations = {
    triggerInvoice: 'Trigger Invoice',
    month: 'Month',
    year: 'Year',
    latest: 'Latest?',
};
