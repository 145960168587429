import { useMutation } from '@apollo/client';
import { Grid, TextField } from "@material-ui/core";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { UpdateCordaRoleData, UpdateCordaRoleMutation, UpdateCordaRoleVars } from '../../models';
import { MessageSnackbar } from '../DialogWrappers';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    afterSave: () => void,
    role: string,
    existingPermissions?: string,
}

export const UpdateCordaRoleDialog = ({ open, setOpen, role, existingPermissions, afterSave }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'UpdateCordaRoleDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`UpdateCordaRoleDialog:${key}`, interpolate)

    const { consortium_id, environment_id, node_id } = useParams<any>();
    const nodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        node_id: node_id!
    }
    const [permissions, setPermissions] = useState('');
    const [okMessage, setOkMessage] = useState('');

    useEffect(() => {
        if (open) setPermissions(existingPermissions || '');
    }, [open, existingPermissions])

    const [updateCordaRole, { loading }] = useMutation<UpdateCordaRoleData, UpdateCordaRoleVars>(UpdateCordaRoleMutation)

    const onSave = async () => {
        await updateCordaRole({
            variables: {
                ...nodeVars,
                id: role,
                role: {
                    permissions: permissions.split(',').map(r => r.trim()),
                }
            }
        });
        afterSave();
        setOpen(false);
        setOkMessage(lt('updateOk', {role}));
    }

    const controlsWrapper = <>
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextField data-test="textField_permissions"
                    label={lt('permissionsHelp')}
                    value={permissions}
                    onChange={event => setPermissions(event.target.value)}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        </Grid>
    </>

    return (<>
        <MessageSnackbar messageType="success" message={okMessage} setMessage={setOkMessage}/>
        <FormDialog
            {...{open}} 
            {...{setOpen}} 
            header={lt('updateRole', {role})} 
            description={lt('updateRoleDescription')} 
            {...{controlsWrapper}}
            saveDisabled={loading || !role || !permissions}
            {...{onSave}}
            closeDialogAfterSave={false}
            saveText={lt('update')} />
    </>);
};

interface translations {
    updateRole: string,
    updateRoleDescription: string,
    permissionsHelp: string,
    update: string,
    updateOk: string,
}
const enTranslations: translations = {
    updateRole: 'Update Corda RPC role ({{role}})',
    updateRoleDescription: 'Update the permission set for the role',
    permissionsHelp: 'Enter a comma separated list of permissions',
    update: 'Update',
    updateOk: 'Role "{{role}}" updated',
}