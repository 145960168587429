import { CompiledContract } from '../../../models';
import { valid, inc } from 'semver';

export function getDefaultCompilationDescription(compiledContracts: CompiledContract[] | null) {
    let defaultCompilationDescription = '1.0.0';
    if (compiledContracts && compiledContracts.length > 0) {
        const latestVersionDescription = compiledContracts[compiledContracts.length - 1].description ?? '-';
        if (valid(latestVersionDescription)) {
            const nextVersion = inc(latestVersionDescription, 'minor');
            defaultCompilationDescription = nextVersion ?? '';
        } else {
            defaultCompilationDescription = '';
        }
    }
    return defaultCompilationDescription;
}
