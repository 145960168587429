import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { ErrorSnackbarCatcher, FullScreenCreate, MessageSnackbar } from '../../components/DialogWrappers'
import { CreateNodeStep1 } from './CreateNodeStep1'
import { CreateNodeStep2 } from './CreateNodeStep2'
import { CreateNodeStep3 } from './CreateNodeStep3'
import { ConsortiumZonesData, ConsortiumZonesQuery,
    EnvironmentData, EnvironmentQuery, NodeRole, CreateNodeData, CreateNodeMutation, CreateNodeVars, CreateServiceData, CreateServiceMutation, CreateServiceVars, MakeNodeCreateMutationOptions, RuntimeSize, MakeServiceCreateMutationOptions, ServicesEnum, ConsortiumMembershipsQuery, CreateMembershipData, CreateMembershipMutation, CreateMembershipVars, PlanSupports, OrganizationData, OrganizationQuery, OrganizationVars, EnvironmentZonesData, EnvironmentZonesQuery } from '../../models';
import { ConsortiumResourcesVars, ConsortiumResourceVars, AutocompleteIdName } from '../../interfaces'
import { CircularProgress } from '@material-ui/core';
import { CREATE_NODES_PATH } from '../../components/NodeNav/NodePicker';
import { FIREFLY_BASE_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { FireflyTokenPluginNames } from '../../models/firefly';

const makeEmptyAutocompleteIdName = () => ({id: '', name: ''})

export const CreateNode: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateFireflyNode', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateFireflyNode:${key}`, interpolate);

    type locationState = { 
        fireflyNodeName: string, 
        tokensPluginName?: FireflyTokenPluginNames
        membershipId: string, 
        membershipInfo: AutocompleteIdName 
        blockchainNode: AutocompleteIdName 
        ipfsNode: AutocompleteIdName 
        ordererNode: AutocompleteIdName 
        peerNode: AutocompleteIdName 
        fabricCaNode: AutocompleteIdName 
    };
    const history = useHistory<locationState>();
    const { org_id, consortium_id, environment_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const {
        location: {
            state: {
                fireflyNodeName: historyFireflyNodeName,
                tokensPluginName: historyTokensPluginName,
                membershipInfo: historyMembershipInfo,
                blockchainNode: historyBlockchainNode,
                ipfsNode: historyIpfsNode,
                ordererNode: historyOrdererNode,
                peerNode: historyPeerNode,
                fabricCaNode: historyFabricCaNode
            } = { 
                fireflyNodeName: '',  
                membershipInfo: makeEmptyAutocompleteIdName(), 
                blockchainNode: makeEmptyAutocompleteIdName(),
                ipfsNode: makeEmptyAutocompleteIdName(),
                ordererNode: makeEmptyAutocompleteIdName(),
                peerNode: makeEmptyAutocompleteIdName(),
                fabricCaNode: makeEmptyAutocompleteIdName(),
                tokensPluginName: 'erc20_erc721',
            }
        }
    } = history;

    const {
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!,
        },
        fetchPolicy: 'cache-only'
    });


    const [createNode, { loading: createNodeLoading }] = 
        useMutation<CreateNodeData, CreateNodeVars>(CreateNodeMutation)
    const [createService, { loading: creatingCA }] = 
        useMutation<CreateServiceData, CreateServiceVars>(CreateServiceMutation);
    const [createMembership, { loading: createMembershipLoading }] = 
        useMutation<CreateMembershipData, CreateMembershipVars>(CreateMembershipMutation);
        
    const [size, setSize] = useState<RuntimeSize>('small');
    const [message, setMessage] = useState('');    
        
    const client = useApolloClient();
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const signerNodesAllowed = PlanSupports.signerNodes(organization);
    const isRaft = environment?.consensus_type === 'raft';
    const isFabric = environment?.isFabric || false;
    const isMultiRegion = environment?.test_features?.multi_region ?? false

    const step1Fulfilled = step && step >= "2"
    
    const [fireflyNodeName, setFireflyNodeName] = useState(step1Fulfilled ? historyFireflyNodeName : '');
    const [tokensPluginName, setTokensPluginName] = useState<FireflyTokenPluginNames>(step1Fulfilled ? historyTokensPluginName || 'erc20_erc721' : 'erc20_erc721');
    const [membershipInfo, setMembershipInfo] = useState<AutocompleteIdName>(step1Fulfilled ? historyMembershipInfo : makeEmptyAutocompleteIdName());

    const step2Fulfilled = step && step >= "3"

    const [ipfsNode, setIpfsNode] = useState<AutocompleteIdName>(step2Fulfilled ? historyIpfsNode : makeEmptyAutocompleteIdName());
    const [blockchainNode, setBlockchainNode] = useState<AutocompleteIdName>(step2Fulfilled ? historyBlockchainNode : makeEmptyAutocompleteIdName());
    const [fabricCaNode, setFabricCaNode] = useState<AutocompleteIdName>(step2Fulfilled ? historyFabricCaNode : makeEmptyAutocompleteIdName());
    const [ordererNode, setOrdererNode] = useState<AutocompleteIdName>(step2Fulfilled ? historyOrdererNode : makeEmptyAutocompleteIdName());
    const [peerNode, setPeerNode] = useState<AutocompleteIdName>(step2Fulfilled ? historyPeerNode : makeEmptyAutocompleteIdName());

    const consortiumZoneId = (isMultiRegion && consortiumZones.length ? consortiumZones.find(z => z.default)?._id ?? '' : '')
    const consortiumZone = consortiumZones.find(
        (z) => z._id === consortiumZoneId
    );
    const environmentZone = environmentZones.find(
        (z) =>
            z.region === consortiumZone?.region &&
            z.type === consortiumZone?.type &&
            z.cloud === consortiumZone?.cloud
    );

    const envZoneId = environmentZone?._id;
        
    const invalidStep = step !== "1" && step !== "2" && step !== "3";

    if (invalidStep || (step1Fulfilled && (!membershipInfo.name || !fireflyNodeName))) {
        return <Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${FIREFLY_BASE_PATH}/${ServicesEnum['firefly-os']}/${CREATE_NODES_PATH}/1`} />
    }

    const save = async () => {
        try {
            await setUpMemberAndRuntimes();
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`);
        } catch (err) {
            console.error(err)
        }
    }

    const setUpMemberAndRuntimes = async () => {
        if (membershipInfo.id === '' && membershipInfo.name) {
            await createNewMemberAndRuntimes(membershipInfo.name);
        } else {
            await createRuntimes(fireflyNodeName, membershipInfo.id, tokensPluginName);
        }
    }

    const createNewMemberAndRuntimes = async (memberName: string) => {
        const result = await createMembership({
            variables: {
                consortia_id: consortium_id!,
                membership: {
                    org_name: memberName,
                    org_id: org_id!
                }
            },
            refetchQueries: [{
                query: ConsortiumMembershipsQuery,
                variables: {
                    consortia_id: consortium_id
                }
            }]
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
            throw e
        })
        const createdMemberId = result.data?.createMembership._id;
        await createRuntimes(fireflyNodeName, createdMemberId!, tokensPluginName);
    }

    const createRuntimes = async (name: string, membership_id: string, tokensPluginName: FireflyTokenPluginNames) => {
        let nodeId;
        if (environment?.isFabric) {
            fabricCaNode.id || await createFabricCaService(fabricCaNode.name, membership_id);
            ordererNode.id || await createFabricNode('orderer', membership_id, ordererNode.name);
            nodeId = !!peerNode.id ? peerNode.id : await createFabricNode('peer', membership_id, peerNode.name);
        } else {
            // create a blockchain node only it doesn't already exist
            nodeId = !!blockchainNode.id ? blockchainNode.id : await createBlockchainNode(blockchainNode.name, membership_id);
        }
        // create an ipfs node only it doesn't already exist
        const ipfsId = !!ipfsNode.id ? ipfsNode.id : await createIPFS(ipfsNode.name, membership_id);
        if (!!nodeId  && !!ipfsId) {
            await createFireflyService(name, membership_id, nodeId, ipfsId, tokensPluginName);
        }
    }

    const createBlockchainNode = async (name: string, membership_id: string) => {
        let nodeId = ''
        await createNode(MakeNodeCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node: {
                name,
                membership_id,
                role: 'validator',
                size,
                init_consensus_role: (isRaft || signerNodesAllowed) ? 'signer' : 'non-signer',
                zone_id: envZoneId || '',
            }
        })).then(result => {
            nodeId = result.data?.createNode?._id ?? '';
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
            throw e
        })
        return nodeId;
    }
    
    const createFabricCaService = async (name: string, membership_id: string) => {
        await createService({
            variables: {
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                service: {
                    membership_id: membership_id!,
                    service: 'fabric-ca',
                    name,
                    zone_id: envZoneId || ''
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
            throw e
        })
    }

    const createFabricNode = async (role: NodeRole, membership_id: string, name: string) => {
        let nodeId;
        await createNode(MakeNodeCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node: {
                name,
                role,
                membership_id,
                size,
                init_consensus_role: (isRaft || signerNodesAllowed) ? 'signer' : 'non-signer',
                zone_id: envZoneId || ''
            }
        })).then(result => {
            if (result) {
                nodeId = result.data?.createNode?._id;
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
            throw e
        })
        return nodeId;

    }

    const createIPFS = async (name: string, membership_id: string) => {
        let ipfsId = ''
        await createService(
            MakeServiceCreateMutationOptions({
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                service: {
                    name,
                    membership_id,
                    zone_id: envZoneId || '',
                    service: ServicesEnum.ipfs,
                    size: size
                },
            })
        ).then((result) => {
            ipfsId = result.data?.createService?._id ?? '';
        }).catch((e) => {
            ErrorSnackbarCatcher(e, setMessage);
            throw e
        });
        return ipfsId;
    }

    const createFireflyService = async (name: string, membership_id: string, node_id: string, ipfs_service_id: string, tokensPluginName: FireflyTokenPluginNames) => {
        await createService(
            MakeServiceCreateMutationOptions({
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                service: {
                    name,
                    membership_id,
                    details: {
                        node_id,
                        ipfs_service_id,
                        tokens_plugin_name: tokensPluginName,
                    },
                    zone_id: envZoneId || '',
                    service: ServicesEnum['firefly-os'],
                    size: size
                },
            })
        ).catch((e) => {
            ErrorSnackbarCatcher(e, setMessage);
            throw e
        });
    }

    if (!environment) return <CircularProgress/>

    const stepComponents = [
        { step: lt('nodeDetails'), component: <CreateNodeStep1 {...{isFabric}} {...{cancelPath}} {...{fireflyNodeName}} {...{setFireflyNodeName}} {...{tokensPluginName}} {...{setTokensPluginName}}
            {...{membershipInfo}} {...{setMembershipInfo}}/> },
        { step: lt('dependentRuntimes'), component: <CreateNodeStep2 {...{cancelPath}} {...{fireflyNodeName}} {...{membershipInfo}} {...{setIpfsNode}} {...{setBlockchainNode}} 
            {...{setOrdererNode}} {...{setPeerNode}} {...{ipfsNode}} {...{blockchainNode}} {...{isFabric}} {...{setFabricCaNode}} 
            {...{fabricCaNode}} {...{ordererNode}} {...{peerNode}} {...{ envZoneId }} /> },
        { step: lt('nodeSettings'), component: <CreateNodeStep3 {...{ cancelPath }} {...{ fireflyNodeName }}
            {...{save}} {...{size}} {...{setSize}} {...{createNodeLoading}} {...{creatingCA}} {...{createMembershipLoading}} />}
    ]

    return (
        <>
            <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createNode')} stepUrlParam={step!} {...{stepComponents}} />
            <MessageSnackbar {...{message}} {...{setMessage}} />
        </>
    )
};

interface translations {
    createNode: string,
    createPeerNode: string,
    createOrdererNode: string,
    nodeDetails: string,
    dependentRuntimes: string,
    nodeSettings: string,
}
const enTranslations: translations = {
    createNode: 'Create a FireFly SuperNode',
    createPeerNode: 'Create a Peer Node',
    createOrdererNode: 'Create an Orderer Node',
    nodeDetails: 'Node Details',
    dependentRuntimes: 'Dependent Runtimes',
    nodeSettings: 'Node Settings',
}