import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import { ComingSoon } from "../../components/DisplayWrappers";
import { EmptyCard } from "../../components/DisplayWrappers/EmptyCard";
import { B2B_BASE_PATH } from "../../components/MainNav/SideNavs/B2bCommunication";
import { ConsortiumResourceVars } from "../../interfaces";
import { EnServicesTranslations, EnvironmentData, EnvironmentQuery, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, ServicesData, ServicesEnum, ServicesQuery, ServicesTranslations } from "../../models";
import { ServicesCard } from "../BlockchainDashboard/ServicesCard";

export const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'B2bDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`B2bDashboard:${key}`, interpolate)

    const { pathname } = useLocation()
    const { consortium_id, environment_id } = useParams<any>()

    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const docStores = services.filter(s => s.service === 'documentstore' && s.membership.isMine)
    const app2apps = services.filter(s => s.service === 'app2app' && s.membership.isMine)

    const { data: { featureToggles } = { featureToggles: null } } = useQuery<
        FeatureTogglesData,
        FeatureTogglesVars
    >(FeatureTogglesQuery, {
        variables: {},
        fetchPolicy: "cache-first",
    });

    if ((!featureToggles?.docExchange ?? true) || environment?.isCorda || environment?.isFabric) {
        return <ComingSoon page='b2bDashboard' />
    }

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('dashboard')}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container direction="row" spacing={3}>
                {docStores.length > 0 &&
                    <Grid item container lg={4} sm={6} xs={12}>
                        <ServicesCard 
                        services={docStores}
                        emptyHeader={lt(ServicesEnum.documentstore)}
                        emptyImagePath="Empty-DocEx.svg"
                        emptyDescription={lt('documentExchangeDescription')}
                        servicePath={`${B2B_BASE_PATH}/${ServicesEnum.documentstore}`}
                        serviceInfoLink={'https://docs.kaleido.io/kaleido-services/document-store'} />
                    </Grid>
                }
                {app2apps.length > 0 &&
                    <Grid item container lg={4} sm={6} xs={12}>
                        <ServicesCard 
                            services={app2apps}
                            emptyHeader={lt(ServicesEnum.app2app)}
                            emptyImagePath="Empty-App2App.svg"
                            emptyDescription={lt('app2appDescription')}
                            servicePath={`${B2B_BASE_PATH}/${ServicesEnum.app2app}`}
                            serviceInfoLink={'https://docs.kaleido.io/kaleido-services/app2app'} />
                    </Grid>
                }
                <Grid item container lg={4} sm={6} xs={12}>
                    <EmptyCard header={lt(ServicesEnum.documentstore)} createDisabled={environment?.state !== 'live'}
                        imageFiles="Empty-DocEx.svg"
                        description={lt('documentExchangeDescription')} 
                        cancelPath={pathname}
                        createPath={`${pathname}/${ServicesEnum.documentstore}/create/1`} />
                </Grid>

                <Grid item container lg={4} sm={6} xs={12}>
                    <EmptyCard header={lt(ServicesEnum.app2app)} createDisabled={environment?.state !== 'live'}
                        imageFiles="Empty-App2App.svg"
                        description={lt('app2appDescription')} 
                        cancelPath={pathname}
                        createPath={`${pathname}/${ServicesEnum.app2app}/create/1`} />
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations extends ServicesTranslations {
    dashboard: string
    documentExchangeDescription: string
    app2appDescription: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    dashboard: 'Dashboard',
    documentExchangeDescription: 'Use the Document Exchange service to securely upload and transfer files within a Kaleido environment.',
    app2appDescription: 'Kafka-backed bilateral app-to-app pipeline for the encryption, submission and decryption of messages between members.'
}