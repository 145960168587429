import React from 'react';
import { useQuery} from '@apollo/client';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { DisplayTable } from '../../components/DisplayWrappers/DisplayTable';
import AccountDetailsIcon from "mdi-react/AccountDetailsOutlineIcon";
import { DisplayTableColumns } from '../../components/DisplayWrappers/DisplayTableRow';
import { RegisteredIdentitiesData, RegisteredIdentitiesQuery } from '../../models/certificateAuthorities';
import { CAIdentityRoleTranslation, EnCAIdentityRoleTranslation } from './CreateIdentity';
import { capitalize } from '../../utils/StringUtils';

export const CertificateIdentities: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CertificateIdentities', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CertificateIdentities:${key}`, interpolate)
    const { service_id} = useParams<any>();

    const {
        loading,
        data: {
            registeredIdentities
        } = { registeredIdentities: [] }
    } = useQuery<RegisteredIdentitiesData>(RegisteredIdentitiesQuery, {
        variables: {
            service_id: service_id!
        },
        fetchPolicy: 'cache-and-network'
    });

    if (loading) {
        return <CircularProgress/>;
    }

    let registeredIdentitiesRecords: Array<{
        key: string,
        columns: DisplayTableColumns[],
        onClick?: () => void
    }> = [];


    for (const reg of registeredIdentities) {
        const record = {
            key: reg.id,
            columns: [
                { isIcon: true, value: <AccountDetailsIcon /> },
                { value: reg.id },
                { value: capitalize(reg.type) },
                { value: reg.max_enrollments },
            ]
        };
        registeredIdentitiesRecords.push(record);
    }

    const columnHeaders = [
        '',
        lt('id'),
        lt('type'),
        lt('maxEnrollments')
    ];

    return (
        <Grid container spacing={3}>
            <Grid container direction={"column"} item spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('registeredIdentities', { count: registeredIdentities.length })}
                    </Typography>
                </Grid>
                <Grid item>
                    {registeredIdentities?.length > 0 ? <DisplayTable header={lt('identities')} columnHeaders={columnHeaders} records={registeredIdentitiesRecords} />
                        : <Typography>{lt('noRegisteredIdentities')}</Typography>}
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations extends CAIdentityRoleTranslation {
    registeredIdentities: string
    noRegisteredIdentities: string
    identities: string
    id: string
    name: string
    type: string
    maxEnrollments: string
}
const enTranslations: translations = {
    ...EnCAIdentityRoleTranslation,
    registeredIdentities: 'Registered Identities ({{count}})',
    noRegisteredIdentities: 'No Registered Identities',
    identities: 'Registered Identities',
    id: 'ID',
    name: 'Name',
    type: 'Type',
    maxEnrollments: 'Max Enrollments'
}