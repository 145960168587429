import { useQuery } from '@apollo/client';
import { Button, TablePagination, Typography } from "@material-ui/core";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { GatewayAPI, GatewayAPIsData, GatewayAPIsQuery, GatewayAPIsVars, NodesData, NodesQuery } from '../../models';
import { DisplayTable, EmptyState, ToolBarOptions } from '../DisplayWrappers';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { NETWORK_CONTRACTS_PATH } from '../MainNav/ConsortiumWrapper';
import { EnvironmentResourcesVars } from '../../interfaces';
import { NODE_CONTRACTS_PATH } from '../NodeNav/NodeNav';

const PAGINATION_OPTIONS = [10, 25];

interface Props {
    header?: string
    description?: string
    onRowClick?: (gatewayAPIId: string) => void,
    onSelectClick?: React.Dispatch<React.SetStateAction<GatewayAPI | undefined>>,
    isSelector?: boolean,
    isCorda?: boolean,
    setCreateContractsPath?: React.Dispatch<React.SetStateAction<string>>,
}

// shared for Eth & Corda

export const GatewayAPIsTable = ({ header, description, onRowClick, onSelectClick, isSelector, isCorda, setCreateContractsPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GatewayAPIsTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GatewayAPIsTable:${key}`, interpolate)

    const { pathname } = useLocation()
    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const baseEnvironmentPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`
    
    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_OPTIONS[0]);
    const [page, setPage] = useState(0);
    
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const {
        loading: gatewayAPIsLoading,
        data: {
            gatewayAPIs
        } = { gatewayAPIs: null }
    } = useQuery<GatewayAPIsData, GatewayAPIsVars>(GatewayAPIsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            start: rowsPerPage * page, 
            limit: rowsPerPage,
            endpoint: isSearchActive ? searchInput : undefined
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: { 
            consortia_id: consortium_id,
            environment_id: environment_id
        },
        fetchPolicy: 'cache-only'
    });

    const myNodes = nodes.filter(n => n.membership.isMine && n.state === 'started')

    // just pick the first node to create the contract at
    const createContractsPath = myNodes.length ? `${baseEnvironmentPath}/nodes/${myNodes.find(() => true)?._id}/${NODE_CONTRACTS_PATH}/create/1` : ''

    let columnHeaders = [
        '',
        lt('contractName'),
        lt('compiledContractDescription'),
    ]

    if (!isCorda) {
        columnHeaders.push(lt('endpoint'))
        columnHeaders.push(lt('promotedAt'));
    }

    if (isSelector) {
        columnHeaders.splice(0, 1)
        columnHeaders.splice(columnHeaders.length - 1, 1)
        columnHeaders.push('')
    }

    const records = gatewayAPIs?.gatewayAPIs?.map(g => {
        let columns = [
            {isIcon: true, value: <img src={`${process.env.PUBLIC_URL}/img/protocols/TableIcons/icon-${isCorda ? 'corda' : 'ethereum'}.svg`} alt=""></img>},
            {value: g.consortiaContractName ?? '-'},
            {value: g.description || '', maxWidthPixelValue: 250},
        ] 

        if (!isCorda) {
            columns.push({value: g.endpoint})
            columns.push({value: g.createdAt ? new Date(g.createdAt).toLocaleString() : '-'});
        }

        const record: DisplayTableRecord = {
            key: g._id,
            onClick: onRowClick ? () => onRowClick(g._id) : undefined,
            columns: columns
        }

        if (isSelector && onSelectClick) {
            record.columns.splice(0, 1)
            record.columns.splice(record.columns.length - 1, 1)
            const selectButton = (
                <Button fullWidth 
                    variant="outlined" 
                    onClick={() => onSelectClick(gatewayAPIs?.gatewayAPIs?.find(g => g._id === record.key))}>
                    <Typography variant="body2" color="textSecondary">
                        {lt('select')}
                    </Typography>
                </Button>
            )
            record.columns.push({ value: selectButton })
        }

        return record
    })

    const loading = gatewayAPIsLoading && !gatewayAPIs

    const showEmptyState = !loading && !isSearchActive && !gatewayAPIs?.gatewayAPIs?.length

    useEffect(() => {
        if (setCreateContractsPath) {
            setCreateContractsPath(showEmptyState ? '' : createContractsPath)
        }
    }, [createContractsPath, setCreateContractsPath, showEmptyState])

    if (showEmptyState) return (
        <EmptyState imageFile='Empty-Contracts-GatewayAPIs.svg' 
            title={isCorda ? lt('promoteACordapp') : lt('createGatewayAPI')} 
            description={isCorda ? lt('emptyDescriptionCordapp') : lt('emptyDescription')} 
            button={{ text: isCorda ? lt('manageCordapps'): lt('goToContractManagement'), onClick: () => history.push( (!isCorda && createContractsPath) || `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}`, { cancelPath: pathname }) }}
            documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/smart-contracts/' }} />
    )

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const pagination = (
        <TablePagination rowsPerPageOptions={PAGINATION_OPTIONS} component="div" count={ gatewayAPIs?.totalCount ?? 0 } 
            rowsPerPage={rowsPerPage} page={page} 
            onChangeRowsPerPage={handleChangeRowsPerPage} onPageChange={handleChangePage}  />
    )

    const onSearchSubmit = (searchInput: string) => {
        setSearchInput(searchInput);
    }

    const searchOptions = !isCorda ? {
        search: {
            label: lt('search'),
            setSearchActive: setIsSearchActive,
            onSearch: onSearchSubmit
        }
    } : undefined

    const toolBarOptions: ToolBarOptions | undefined = isSelector ? {
        headerTab: {
            selected: 0,
            tabs: [{
                label: lt('header')
            }]
        },
        ...searchOptions
    } : searchOptions
    
    return (
        <DisplayTable height={isSelector ? "50vh" : undefined} {...{toolBarOptions}} stickyHeader={isSelector ? true : false} emptyLabel={lt('empty')}
            {...{loading}} actionFooter={pagination} {...{header}} {...{description}} 
            {...{columnHeaders}} {...{records}} hideFooterDivider={isSelector} />
    )
};

interface translations {
    header: string,
    description: string,
    endpoint: string,
    contractName: string,
    compiledContractDescription: string,
    promotedAt: string,
    promoteACordapp: string,
    createGatewayAPI: string,
    emptyDescription: string,
    emptyDescriptionCordapp: string,
    goToContractManagement: string,
    emptyDocumentation: string,
    select: string,
    search: string,
    empty: string,
    manageCordapps: string
}
const enTranslations: translations = {
    header: 'Gateway API\'s',
    description: 'The following Gateway API\'s have been promoted to this environment.',
    endpoint: 'API endpoint',
    contractName: 'App name',
    compiledContractDescription: 'Version',
    promotedAt: 'Promoted at',
    promoteACordapp: 'Promote a CorDapp',
    createGatewayAPI: 'Create a Smart Contract',
    emptyDescription: 'Let\'s create a new smart contract.',
    emptyDescriptionCordapp: 'Go to Apps under the Network submenu to manage CorDapps.',
    goToContractManagement: 'Create Smart Contract',
    emptyDocumentation: 'Documentation',
    select: 'Select',
    search: 'Search exact API endpoint',
    empty: 'No Smart Contract\'s found.',
    manageCordapps: 'Manage CorDapps'
}