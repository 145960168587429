import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { SupportedEvmVersions } from '../../models';

const evmVersionList: SupportedEvmVersions[] = ["byzantium", "homestead", "tangerineWhistle", "spuriousDragon", "constantinople", "istanbul"]

interface Props {
    evmVersion: string,
    setEvmVersion: React.Dispatch<React.SetStateAction<SupportedEvmVersions>>,
    disabled: boolean
};

export const EvmVersionSelector = ({ evmVersion, setEvmVersion, disabled }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EvmVersionSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EvmVersionSelector:${key}`, interpolate)

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel}>
                {lt('selectEvmVersion')}
            </InputLabel>
            <Select {...{disabled}}
                labelWidth={labelWidth}
                value={evmVersion}
                onChange={e => setEvmVersion(e.target.value as SupportedEvmVersions)}>
                {evmVersionList.map(c => (
                    <MenuItem key={c} selected={c === evmVersion} value={c}>
                        {c}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    )
};

interface translations {
    selectEvmVersion: string
}
const enTranslations: translations = {
    selectEvmVersion: 'EVM Version'
}