import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
    ConsortiumZonesData,
    ConsortiumZonesQuery,
    EnvironmentZonesData,
    EnvironmentZonesQuery,
    EnvironmentData,
    EnvironmentQuery,
    ServicesEnum,
} from "../../models";
import {
    ConsortiumResourcesVars,
    ConsortiumResourceVars,
    EnvironmentResourcesVars,
} from "../../interfaces";
import { FullScreenCreate } from "../../components/DialogWrappers";
import { BLOCKCHAIN_BASE_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import { Step1 } from "./Step1";

export const CreateOnChainRegistry: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateOnChainRegistry", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateOnChainRegistry:${key}`, interpolate);

    const { org_id, consortium_id, environment_id, step } = useParams<any>();

    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKCHAIN_BASE_PATH}`;

    const { data: { environmentZones } = { environmentZones: [] } } = useQuery<
        EnvironmentZonesData,
        EnvironmentResourcesVars
    >(EnvironmentZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });

    const { data: { consortiumZones } = { consortiumZones: [] } } = useQuery<
        ConsortiumZonesData,
        ConsortiumResourcesVars
    >(ConsortiumZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
        },
        fetchPolicy: "cache-only",
    });

    const { data: { environment } = { environment: null } } = useQuery<
        EnvironmentData,
        ConsortiumResourceVars
    >(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: "cache-only",
    });

    const isMultiRegion = environment?.test_features?.multi_region ?? false;
    const [name, setName] = useState("");
    const [membershipId, setMembershipId] = useState("");
    const [consortiumZoneId, setConsortiumZoneId] = useState(
        consortiumZones.find((z) => z.default)?._id ?? ""
    );

    const invalidStep = step !== "1";

    if (invalidStep)
        return (
            <Redirect to={`${cancelPath}/${ServicesEnum.idregistry}/create/1`} />
        );

    const stepComponents = [
        {
            step: lt("createOnChain"),
            component: (
                <Step1
                    {...{ cancelPath }}
                    {...{ isMultiRegion }}
                    {...{ name }}
                    {...{ environmentZones }}
                    {...{ setName }}
                    {...{ membershipId }}
                    {...{ setMembershipId }}
                    {...{ consortiumZones }}
                    {...{ consortiumZoneId }}
                    {...{ setConsortiumZoneId }}
                />
            ),
        },
    ];

    return (
        <FullScreenCreate
            cancelPath={cancelPath}
            toolbarHeader={lt("createOnChain")}
            stepUrlParam={step!}
            {...{ stepComponents }}
            hideStepper
        />
    );
};

interface translations {
    createOnChain: string;
    description: string;
    selectType: string;
}
const enTranslations: translations = {
    createOnChain: "Create On-Chain Registry",
    description: "",
    selectType: "Select Type",
};
