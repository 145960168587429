import React from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography, Grid, makeStyles } from "@material-ui/core";
import { Channel } from '../../models/channels';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery, GatewayAPI, Node, ChaincodeStatusesData, ChaincodeStatusesVars, ChaincodeStatusesQuery } from '../../models';
import { useQuery } from '@apollo/client';
import { ConsortiumResourcesVars } from '../../interfaces';
import { ResourceStateChip } from '../FormControls/ResourceStateChip';

interface Props {
    channel: Channel
    node?: Node
    gatewayAPI?: GatewayAPI
    isMonitor?: boolean
}

export const MembershipApprovalsCell = ({ channel, node, gatewayAPI, isMonitor }: Props) => {
    const classes = useStyles();
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MembershipApprovalsCell', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MembershipApprovalsCell:${key}`, interpolate)
    const { consortium_id, environment_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        data: {
            chaincodeStatuses
        } = { chaincodeStatuses: null }
    } = useQuery<ChaincodeStatusesData, ChaincodeStatusesVars>(ChaincodeStatusesQuery, { 
        variables: {
            consortia_id: consortium_id,
            environment_id,
            node_id: node?._id || '',
            id: gatewayAPI?._id || ''
        }, 
        skip: isMonitor || false,
        fetchPolicy: 'cache-first' // pick up the status from the InstalledStatusRow polling
    });

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const getChannelStatusChip = (membershipId: string) => {
        if (loading && !chaincodeStatuses) {
            return <CircularProgress size={24} />
        }

        let isApproved = false
        let supportsStatuses = true
        if (isMonitor) {
            isApproved = gatewayAPI?.committedChannels?.find(a => a.id === channel._id)?.approvals[membershipId] || false
        } else {
            supportsStatuses = !!chaincodeStatuses
            isApproved = chaincodeStatuses?.committedChannels?.find(a => a.id === channel._id)?.approvals[membershipId] || false
        }

        const status = isApproved ? 'yes' : 'no'
        return <ResourceStateChip state={supportsStatuses ? status : 'unknown'} />
    }

    return (
        <Grid item container spacing={1} direction='column'>
        {channel.members.map((membershipId, i) => (
            <Grid key={membershipId} item container spacing={1} direction='column'>
                <Grid item container direction='row' alignItems='center' justify='space-between'>
                    <Grid item>
                        <Typography variant="body2">{membershipId === 'sys--mon' ? lt('system') : consortiumMemberships.find((ch) => ch._id === membershipId)?.org_name}</Typography>
                    </Grid>
                    <Grid item>
                        {getChannelStatusChip(membershipId)}
                    </Grid>
                </Grid>
                {i < channel.members.length - 1 && 
                <Grid item>
                    <hr className={classes.hr} />
                </Grid>
                }
            </Grid>
        ))}
        </Grid>
    )
};

const useStyles = makeStyles((theme) => ({
    hr: {
        padding: theme.spacing(0),
        margin: 0,
        borderColor: theme.palette.background.paper,
        opacity: '50%'
    },
}));

interface translations {
    name: string
    admin: string
    memberships: string
    nodes: string
    defaultDescription: string
    noChannels: string
    chaincodeApproved: string
    membershipApprovals: string
    chaincodeCommitted: string
    yes: string
    no: string
    isLatestSequence: string
    system: string
}
const enTranslations: translations = {
    name: 'Name',
    admin: 'Admin',
    memberships: 'Memberships',
    nodes: 'Nodes',
    defaultDescription: 'A default channel exists for every Fabric environment.',
    noChannels: 'No channels.',
    chaincodeApproved: 'Chaincode Approved',
    membershipApprovals: 'Membership Approvals',
    chaincodeCommitted: 'Chaincode Committed',
    yes: 'Yes',
    no: 'No',
    isLatestSequence: 'Latest Sequence/Version',
    system: ' System'
}
