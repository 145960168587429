import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { OPS_MANAGE_CONSORTIA_PATH, OPS_PATH } from '../../components/OpsNav/OpsNav';
import { OpsEnvironmentsData } from '../../models';
import { OpsEnvironmentsQuery } from '../../models/environments';
import { OpsTable, SkipLimt } from './OpsTable';

interface Props {
    setMessage: (m: string) => void,
    region: string,
    idFilter: string,
    nameFilter: string,
    consortiaFilter: string,
    stateFilter: string;
    tagsFilter: string;
}

export const EnvironmentsTable: React.FC<Props> = ({setMessage, region, idFilter, nameFilter, consortiaFilter, stateFilter, tagsFilter}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsEnvironmentsDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsEnvironmentsDashboard:${key}`, interpolate)

    const [skipLimit, setSkipLimit] = useState<SkipLimt>({skip: 0, limit: 0});

    const history = useHistory();

    let query = `skip=${skipLimit.skip}&limit=${skipLimit.limit}`;
    query = idFilter ? `${query}&_id=~${idFilter}` : query;
    query = nameFilter ? `${query}&name=~${nameFilter}` : query;
    query = consortiaFilter ? `${query}&consortia_id=~${consortiaFilter}` : query;
    query = stateFilter ? `${query}&state=~${stateFilter}` : query;
    query = tagsFilter ? `${query}&admin_tags=~${tagsFilter}` : query;

    const {
        data: {
            opsEnvironments: orgs
        } = { opsEnvironments: [] },
        error: fetchError, 
    } = useQuery<OpsEnvironmentsData>(OpsEnvironmentsQuery, {
        skip: !skipLimit.limit,
        variables: {
            query,
            region
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    const records = useMemo<DisplayTableRecord[]>(() => {
        return orgs.map(e => ({
            columns: [{
                value: e._id,
                maxWidthPixelValue: 75,
            },{
                value: e.name,
                maxWidthPixelValue: 250,
            }, {
                value: e.consortia_id,
                maxWidthPixelValue: 250,
            }, {
                value: e.state,
                maxWidthPixelValue: 75,
            }, {
                value: e.admin_tags?.join(',') || '',
                maxWidthPixelValue: 75,
            }],
            onClick: () => history.push(`/${OPS_PATH}/${OPS_MANAGE_CONSORTIA_PATH}/${e.consortia_id}`)
        }))
    }, [orgs, history]);

    return (
        <OpsTable {...{setSkipLimit}} tableProps={{
            records,
            columnHeaders: [
                lt('id'),
                lt('name'),
                lt('consortium'),
                lt('state'),
                lt('tags'),
            ]
        }}/>
    )
};

interface translations {
    id: string;
    name: string;
    consortium: string;
    state: string;
    tags: string;
}
const enTranslations: translations = {
    id: 'ID',
    name: 'Name',
    consortium: 'Consortium',
    state: 'State',
    tags: 'Tags',
}
