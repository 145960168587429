import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step2WebSocketHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep2WebSocketHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep2WebSocketHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Webhook.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/kaleido-services/event-streams/usage-websockets"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Setup the WebSocket API',
    helpDescription: 'Batches of events will be delivered over connected WebSockets, once you send a message to the server with a "type" property set to "listen"',
    helpDesc1: 'If you have different applications that need to consume different events, you can configure each to listen on separate "topic"',
    helpDesc2: 'You must acknowledged each batch of events with an "ack" message (including the appropriate "topic"), before the next batch is delivered',
    helpDesc3: 'If your application is unale to consume a batch, it can respond with an "error" message and batch retry will be performed by Kaleido',
}