import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { NodeLogTypesData, NodeLogTypesQuery ,
        EnvironmentData, EnvironmentQuery } from '../../models'
import { Typography, Grid } from "@material-ui/core";
import { LogsWrapper } from './LogsWrapper'
import { ConsortiumResourceVars } from '../../interfaces';

// This list is the exact static list that will be returned by pre-upgrade environments.
// Need to filter this down to a sensible default in the front-end. Once we have a baseline, this code can be removed.
const BACK_LEVEL_CONTAINER_LIST = JSON.stringify([ "geth", "constellation", "pantheon", "eth-signer", "orion", "autonity", "ethconnect", "polygon-edge" ])

export const NodeLogs = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LogsNodeLogs', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LogsNodeLogs:${key}`, interpolate)

    const { consortium_id, environment_id, node_id } = useParams<any>();

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });

    let { 
        data: { 
            nodeLogTypes
        } = { nodeLogTypes: [] } 
    } = useQuery<NodeLogTypesData>(NodeLogTypesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: node_id
        },
        fetchPolicy: environment?.isFabric ? 'cache-and-network' : 'cache-first' // recheck to pick up any new chaincode logs
    });

    let logContainers = nodeLogTypes;
    if (JSON.stringify(logContainers) === BACK_LEVEL_CONTAINER_LIST) {
        switch (environment?.provider) {
            case 'geth':
                logContainers = ['geth', 'ethconnect']
                break
            case 'quorum':
                logContainers = ['geth', 'constellation', 'ethconnect']
                break
            case 'pantheon':
                logContainers = ['pantheon', 'ethsigner', 'ethconnect', 'orion']
                break
            case 'autonity':
                logContainers = ['autonity', 'ethconnect']
                break
            case 'polygon-edge':
                logContainers = ['polygon-edge', 'ethconnect']
                break
            default:
                break
        }
    }
    
    return (
        <>
            <Grid container direction="column" spacing={3} alignItems="stretch">
                <Grid item>
                    <Typography variant="h5">
                        {lt('nodeLogs')}
                    </Typography>
                </Grid>
                <Grid item container>
                    { logContainers.length ? <LogsWrapper {...{logContainers}} /> : <></> }
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    nodeLogs: string,
}
const enTranslations: translations = {
    nodeLogs: 'Node Logs',
}