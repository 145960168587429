import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers';

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConfirmIdentityHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConfirmIdentityHelp:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Chainlink.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks/identity/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
}
const enTranslations: translations = {
    helpHeader: 'Organizational Identity',
    helpDescription: 'A membership is an organization’s representation within a consortia and serves as the parent resource for deployed nodes and services. Memberships are in effect an “asserted identity” and can be underpinned with digital certificates for trustworthy and authoritative identification. An organization can have one or more memberships, whether for delineation of inter-organizational departments or for the proxied management of resources on behalf of fellow organizations.',
}