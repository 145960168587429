import { Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { UploadBinary } from "../../../components/ContractManagement/UploadBinary";
import { doUpload } from "../../../components/ContractManagement/UploadBinary";
import { CreateWrapper, MessageSnackbar } from '../../../components/DialogWrappers';
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector';
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { CreateStepProps } from '../../../interfaces';
import { CordaStep1Help } from './CordaStep1Help';

interface Props extends CreateStepProps {
    description: string,
    setDescription: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
};

export const CordaStep1 = ({ description, setDescription, membershipId, setMembershipId, loading, setLoading, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationCordaStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationCordaStep1:${key}`, interpolate)

    const { consortium_id, contract_id } = useParams<any>();
    const { pathname } = useLocation();
    const history = useHistory();

    const [file, setFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState('');

    const disabled = !membershipId || !description || !file

    const save = () => {
        const splitPathname = pathname.split('/')
        const baseCompilationsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_COMPILED_CONTRACTS_PATH) + 1).join('/')
        doUpload({
            consortium_id,
            contract_id,
            description,
            membershipId,
            file: file!,
            setLoading,
            setErrorMessage,
            onComplete: (compiledContractId: string) => history.push(`${baseCompilationsPath}/${compiledContractId}`),
        });
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item >
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            <Grid item>
                <TextField 
                    data-test="compilationDescription"
                    required
                    multiline
                    rows={2}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>

            <UploadBinary {...{loading}} {...{file}} {...{setFile}} accept='jar'/>
        </>
    )

    return (
        <>
            <MessageSnackbar setMessage={setErrorMessage} message={errorMessage} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            <CordaStep1Help />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    description: string,
    selectFile: string,
    selectFileHeader: string,
    selectFileDescription: string,
}
const enTranslations: translations = {
    header: 'Enter Version Details',
    headerDescription: 'Select the Membership who will own this version and give this specific version a description.',
    description: 'Description',
    selectFile: 'Select file',
    selectFileHeader: 'Select a file',
    selectFileDescription: 'Select the Jar file to upload',
}
