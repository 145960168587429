import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { Environment, Node, EnvironmentStatusData, 
    EnvironmentStatusQuery,
    ConsortiumMembershipsData, 
    ConsortiumMembershipsQuery,
    EnvironmentState,
    EnvironmentProviderTranslations,
    EnEnvironmentProviderTranslations,
    ReleaseData,
    ReleaseQuery,
    ReleaseVars,
    ServicesData,
    ServicesQuery} from '../../models'
import { CircularProgress, Button, Grid } from "@material-ui/core";
import { DisplayCard } from '../../components/DisplayWrappers'
import { ConsortiumResourceVars, ConsortiumResourcesVars, EnvironmentResourcesVars } from '../../interfaces';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import CalendarBlankOutlineIcon from 'mdi-react/CalendarBlankOutlineIcon';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import SignalCellularOutlineIcon from 'mdi-react/SignalCellularOutlineIcon';
import { BLOCKEXPLORER_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { ENVIRONMENT_SETTINGS_PATH } from '../../components/MainNav/EnvironmentWrapper';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { capitalize } from '../../utils/StringUtils';
import LanPendingIcon from 'mdi-react/LanPendingIcon';
import CalendarClockIcon from 'mdi-react/CalendarClockIcon';
import { BlocksData, BlocksVars, BlocksQuery } from '../../models/ledger';
import { EnvironmentPauseResume } from '../../components/DialogWrappers/EnvironmentPauseResume';

interface Props {
    environment: Environment,
    nodes: Node[]
};

const UPGRADABLE_STATES: EnvironmentState[] = ['upgrading','paused_upgrading','live','paused']

export const Status = ({ environment, nodes }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentDashboardStatus', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentDashboardStatus:${key}`, interpolate)

    const history = useHistory();
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!,
        id: environment_id!
    }

    const environmentResourcesVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const { 
        data: { 
            consortiumMemberships
        } = { consortiumMemberships: [] } 
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    const { data: { release } = { release: null } } = useQuery<
        ReleaseData,
        ReleaseVars
    >(ReleaseQuery, {
        skip: !environment,
        variables: {
            id: environment?.release_id!,
        },
        fetchPolicy: 'cache-and-network',
    });

    const { data: { services } = { services: [] } } = useQuery<
        ServicesData,
        EnvironmentResourcesVars
    >(ServicesQuery, {
        variables: environmentResourcesVars,
        fetchPolicy: 'cache-only',
    });


    const {
        data: {
            blocks
        } = {blocks:[]}
    } = useQuery<BlocksData, BlocksVars>(BlocksQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            limit: 1
        },
        fetchPolicy: 'cache-and-network',
        skip: !environment.isEthereum
    });

    const fireflyNodes = services.filter((s) => s.service === 'firefly-os');

    const {
        refetch: refetchEnvironmentStatus,
        data: {
            environmentStatus
        } = { environmentStatus: null }
    } = useQuery<EnvironmentStatusData, ConsortiumResourceVars>(EnvironmentStatusQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-and-network'
    });

    const [updateOpen, setUpdateOpen] = useState(false);

    // unnecessary to refetch env status on initial load since it will be up to date anyways via the subscription
    // need to refetch the env status however if the environment state re-enters live, or paused
    const isInitialMount = useRef(true);
    const environmentState = environment.state
    useEffect(() => { 
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (UPGRADABLE_STATES.some(s => s === environmentState)) { 
            refetchEnvironmentStatus()
        }
    }, [environmentState, refetchEnvironmentStatus])

    if (!environmentStatus) return <CircularProgress />

    const showPauseOrResume = 
        (environment.state === "live" && nodes.filter(n => n.state === "started").length === nodes.length) || 
        environment.state === "paused"
    const envStatusUpgrade = environmentStatus.upgrade;

    const showUpgrade = envStatusUpgrade.available && !envStatusUpgrade.require_hard_fork_upgrade && UPGRADABLE_STATES.some(s => s === environmentState)

    const pauseOrResumeButton = (): JSX.Element | null => {
        if (showPauseOrResume) {
            return environment.state === "live" ? (
                <Button data-test="button_environmentPause" color="inherit" variant="outlined" size="small" onClick={() => setUpdateOpen(true)}>
                    {lt('pause')}
                </Button> 
            ) : (
                <Button data-test="button_environmentResume" color="primary" variant="contained" size="small" onClick={() => setUpdateOpen(true)}>
                    {lt('resume')}
                </Button> 
            )
        }
        return null
    }

    const disabledUpgradeButton = ()=>{
        if ((!release?.supported_features || release?.supported_features.fireflyVersion !== '1.2') && fireflyNodes.length > 0) {
            return true;
        }
        return false;
    }

    const statusList: DisplayCardListItem[] = [
        {
            title: lt('status'),
            value: (
                <Grid item container direction="row" spacing={1}>
                    <Grid item>
                        <ResourceStateChip state={environment.state} />
                    </Grid>
                    {environmentStatus.backup?.in_progress && 
                    <Grid item>
                        <ResourceStateChip state={'backupInProgress'} />
                    </Grid>
                    }
                </Grid>
            ),
            icon: <ChartLineIcon />,
            actionIcon: pauseOrResumeButton()
        },
        {
            title: lt('creationDate'),
            value: new Date(environment.created_at).toLocaleString(),
            icon: <CalendarClockIcon />,
        }]

        if (environment.isEthereum) {
            statusList.push({
                title: lt('blockHeight'),
                value: blocks[0]?.number ?? '--',
                icon: <SignalCellularOutlineIcon />,
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}`),
                actionIcon: <ChevronRightIcon />
            })
        }

        statusList.push(
        {
            title: lt('memberships'),
            value: consortiumMemberships.length,
            icon: <AccountCircleOutlineIcon />,
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}`),
            actionIcon: <ChevronRightIcon />
        },
        {
            title: lt('releaseVersion'),
            value: lt('versionInfo', { 
                version: envStatusUpgrade.current_release.version,
                message: envStatusUpgrade.available ? lt('outOfDate') : lt('upToDate')
            }),
            icon:  <CalendarBlankOutlineIcon />,
            actionIcon: showUpgrade ?
                <Button color="primary" variant="contained" size="small" disabled={disabledUpgradeButton()} onClick={() => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/environments/${environment_id}/${ENVIRONMENT_SETTINGS_PATH}`)}>
                    {lt('upgrade')}
                </Button> : null
        },
        {
            title: lt('protocol'),
            value: `${capitalize(lt(environment.provider))} / ${capitalize(environment.consensus_type)}`,
            icon:  <LanPendingIcon />,
        });
    
    return (
        <>
            {showPauseOrResume && <EnvironmentPauseResume open={updateOpen} setOpen={setUpdateOpen} {...{environment}} />}
            <DisplayCard dataTestId="environmentStatus" maxHeight="disable"
                header={lt('overview')} 
                itemList={statusList} />
        </>
    )
};

interface translations extends EnvironmentProviderTranslations {
    overview: string,
    status: string,
    releaseVersion: string, 
    versionInfo: string,
    outOfDate: string,
    upToDate: string,
    pause: string,
    resume: string,
    memberships: string,
    blockHeight: string,
    upgrade: string,
    paused: string,
    protocol: string,
    creationDate: string
}
const enTranslations: translations = {
    ...EnEnvironmentProviderTranslations,
    overview: 'Overview',
    status: 'Status',
    releaseVersion: 'Release version', 
    versionInfo: '{{version}} {{message}}',
    outOfDate: 'out of date',
    upToDate: 'up to date',
    pause: 'Pause',
    resume: 'Start',
    memberships: 'Memberships',
    blockHeight: 'Block height',
    upgrade: 'Upgrade',
    paused: 'Paused',
    protocol: 'Protocol',
    creationDate: 'Creation Date'
}