import { gql } from '@apollo/client';
import { EnvironmentProvider, RuntimeSize, Services } from '.';
import { ConfigTypes } from './configs';
import { ServicesEnum } from './services';

// INTERFACES

export enum PlansEnum {
    preview = 'preview',
    starter = 'starter',
    team = 'team',
    business = 'business',
    enterprise = 'enterprise',
    partner = 'partner',
    partner_pref = 'partner_pref',
    signup = 'signup'
}
export type PlanName = keyof typeof PlansEnum;

export const PlanNames: PlanName[] = []
for (const plan in PlansEnum) PlanNames.push(plan as PlanName)

export enum FeaturesEnum {
    mfa = 'mfa',
    hybrid = 'hybrid',
    suborgs = 'suborgs',
    ibft2sec = 'ibft2sec',
}

export interface PlansData {
    plans: PlanDetails[]
}

export interface PlanDetails {
    enabled: boolean
    level: PlanName
    limits: PlanLimits
}

export interface PlanLimits {
    nodes: NodeLimits
    services: ServiceLimits
    configurations: ConfigurationLimits
    providers?: ProviderLimits // marking optional for now, until we're confident AM has it on all plan
    keys: KeyLimits
    roles: RoleLimits
    tenants: TenantLimits
    features?: FeatureLimits
}

export type Feature = keyof typeof FeaturesEnum;

interface NodeLimits {
    allowed_tiers: PlanName[]
    allowed_sizes: RuntimeSize[]
}

interface ServiceLimits {
    allowed_tiers: PlanName[]
    allowed_services: Services[]
}

interface ConfigurationLimits {
    allowed_tiers: PlanName[]
    allowed_configurations: ConfigTypes[]
}

interface ProviderLimits {
    allowed_tiers: PlanName[]
    allowed_providers: EnvironmentProvider[]
}

interface FeatureLimits {
    allowed_features: Feature[]
    allowed_providers: EnvironmentProvider[]
}

interface KeyLimits {
    per: {
        environment: number
    }
}

interface RoleLimits {
    per: {
        org: number
    }
}

interface TenantLimits {
    per: {
        tag: number
    }
}

export interface PlanSupplements {
    services?: (keyof typeof ServicesEnum)[];
    service_sizes?: RuntimeSize[];
    configurations?: ConfigTypes[];
    node_sizes?: RuntimeSize[];
    providers?: EnvironmentProvider[];
    features?: (keyof typeof FeaturesEnum)[];
}

export interface OpsSupplementPlanInputs {
    org_id: string
    supplements: PlanSupplements
}

export interface OpsSupplementPlanData {
    opsSupplementPlan: PlanDetails
}

export interface OpsTriggerInvoiceData {
   opsTriggerInvoice: string;
}

export interface OpsTriggerInvoiceInputs {
    org_id: string;
    year?: string;
    month?: string;
}

export interface PlansTranslations {
    [PlansEnum.preview]: string
    [PlansEnum.starter]: string
    [PlansEnum.team]: string
    [PlansEnum.business]: string
    [PlansEnum.enterprise]: string
    [PlansEnum.partner]: string
    [PlansEnum.partner_pref]: string
    [PlansEnum.signup]: string
}

export const EnPlansTranslations: PlansTranslations = {
    [PlansEnum.preview]: 'Preview',
    [PlansEnum.starter]: 'Starter',
    [PlansEnum.team]: 'Developer',
    [PlansEnum.business]: 'Business',
    [PlansEnum.enterprise]: 'Enterprise',
    [PlansEnum.partner]: 'Partner',
    [PlansEnum.partner_pref]: 'Partner Preferred',
    [PlansEnum.signup]: 'Signup'
}

// QUERIES & SUBSCRIPTIONS

export const PlanFields = `
    fragment PlanFields on Plan {
        enabled
        level
        limits {
            configurations {
                allowed_configurations
                allowed_tiers
                per {
                    environment
                }
            }
            keys {
                per {
                    environment
                }
            }
            nodes {
                allowed_sizes
                allowed_tiers
            }
            providers {
                allowed_providers
                allowed_tiers
            }
            roles {
                per {
                    org
                }
            }
            services {
                allowed_sizes
                allowed_services
                allowed_tiers
            }
            features {
                allowed_features
                allowed_tiers
            }
            tenants {
                per {
                    tag
                }
            }
        }        
    }`

export const PlansQuery = gql`
    ${PlanFields}
    query getPlans {
        plans {
            ...PlanFields
        }
    }
`;

export const OpsSupplementPlanMutation = gql`
    ${PlanFields}
    mutation opsSupplementPlan($org_id: String!, $supplements: PlanSupplements!) {
        opsSupplementPlan(org_id: $org_id, supplements: $supplements) {
            ...PlanFields
        }
    }`;
