import { useApolloClient, useQuery } from '@apollo/client';
import { Backdrop, Button, IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { createBrowserHistory } from "history";
import CloseIcon from 'mdi-react/CloseIcon';
import queryString from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { AuthenticationWrapper } from '../components/AuthenticationWrapper';
import { CorDappViewer, CorDapps } from '../components/CorDapps';
import { CordaUsers } from '../components/CordaUsers';
import { NodeLogs, ServiceLogs } from '../components/Logs';
import { Wallet, WalletAccount } from '../components/Wallet';
import { Config, SessionData } from '../interfaces';
import { ServicesEnum } from '../models/services';
import { SessionQuery } from '../queries/Session';
import { HubspotPushParams } from '../utils/HubspotUtils';
import { cognitoController } from '../utils/cognitoController';
import { ForgotPassword, Login, Signup, UserAccountSetup } from '../views';
import { AddressBookAccountInfo, AddressBookAccounts, AddressBookChannels, AddressBookDestinations, AddressBookMemberships, AddressBookRuntimes, EnvironmentActivity, MemberDestinations, MemberOnChainProfile, MemberRuntimes, MemberSigningAccounts } from '../views/AddressBook';
import { CREATE_CHANNELS_PATH } from '../views/AddressBook/Channels';
import { MemberDestinationDetails } from '../views/AddressBook/Membership/DestinationDetails';
import { MemberDetails } from '../views/AddressBook/Membership/MemberDetails';
import { AddressBookMemberRuntimeDetails } from '../views/AddressBook/Membership/RuntimeDetails';
import { MemberSigningAccount } from '../views/AddressBook/Membership/SigningAccount';
import { SecurityNetworkControl } from '../views/AppsSecurity';
import { AppCred } from '../views/AppsSecurity/AppCreds/AppCred';
import { AppCreds } from '../views/AppsSecurity/AppCreds/AppCreds';
import { OauthConfigurationCreate } from '../views/AppsSecurity/OauthConfiguration';
import { OauthConfiguration } from '../views/AppsSecurity/OauthConfiguration/OauthConfiguration';
import { OauthConfigurations } from '../views/AppsSecurity/OauthConfiguration/OauthConfigurations';
import { App2AppClients } from '../views/B2B/App2App/Clients';
import { CreateApp2App } from '../views/B2B/App2App/CreateApp2App';
import { CreateApp2AppDestination } from '../views/B2B/App2App/CreateDestination/CreateDestination';
import { App2AppDashboard } from '../views/B2B/App2App/Dashboard';
import { App2AppDestinations } from '../views/B2B/App2App/Destinations';
import { App2AppProducer } from '../views/B2B/App2App/Producer';
import { Dashboard as B2bDashboard } from '../views/B2B/Dashboard';
import { CreateDestinations, CreateDocExchange, Destinations, Dashboard as DocStoreDashboard, Settings as DocStoreSettings, DocumentStore, TransferInfo, Transfers } from '../views/B2B/DocumentStore';
import { BAFAuthSettingsEditor, BAFMappingEditor, BAFPolicyCreate, BAFPolicyViewer, BAFRulesetEditor } from '../views/BAF';
import { BlockExplorer, BlockInfo, Blocks, ContractInfo, Contracts, TransactionInfo, Transactions } from '../views/BlockExplorer';
import { Dashboard as BlockchainDashboard } from '../views/BlockchainDashboard/Dashboard';
import { Chaincode, INSTANTIATE_CHAINCODE_PATH } from '../views/Chaincodes/Chaincode';
import { Chaincodes } from '../views/Chaincodes/Chaincodes';
import { DeployToChannel } from '../views/Chaincodes/InstantiateToChannel/DeployToChannel';
import { ChainlinkOverview } from '../views/Chainlink/Chainlink';
import { CreateChainlink } from '../views/Chainlink/Create/CreateChainlink';
import { DeployChaincodeToChannel } from '../views/Channels/AddChaincode/DeployChaincodeToChannel';
import { ADD_CHAINCODE_PATH, AddressBookChannelChaincodes } from '../views/Channels/ChannelChaincodes';
import { AddressBookChannelDetails } from '../views/Channels/ChannelDetails';
import { AddressBookChannelMembers } from '../views/Channels/ChannelMembers';
import { AddressBookChannelNodes } from '../views/Channels/ChannelNodes';
import { CloudConfigInfo, CloudConfigsDashboard } from '../views/CloudConfigs';
import { CreateCloudConfig } from '../views/CloudConfigs/CreateCloudConfig';
import { AttachConfig, ConfigTable } from '../views/Configurations';
import { ConfirmIdentity } from '../views/ConfirmIdentity/ConfirmIdentity';
import { ContractsRouter } from '../views/Contracts/ContractsRouter';
import { Create as CreateChannel } from '../views/CreateChannel';
import { Create as CreateConsortium } from '../views/CreateConsortium';
import { Create as CreateEnvironment } from '../views/CreateEnvironment';
import { Create as CreateNode } from '../views/CreateNode';
import { Create as CreateOrganization } from '../views/CreateOrganization/Create';
import { Create as CreateRegions } from '../views/CreateRegions';
import { Create as CreateTunneler } from '../views/CreateTunneler';
import { DigitalAssetsDashboard, DigitalAssetsTokenAction, DigitalAssetsTokenInfo, DigitalAssetsTokens } from '../views/DigitalAssets';
import { Create as CreateEtherPool } from '../views/DigitalAssets/CreateEtherPool/Create';
import { Dashboard as EnvironmentDashboard } from '../views/EnvironmentDashboard/Dashboard';
import { Blockchain as EnvironmentHealthBlockchain } from '../views/EnvironmentHealth/Blockchain';
import { Dashboard as EnvironmentHealthDashboard } from '../views/EnvironmentHealth/Dashboard';
import { Documents as EnvironmentHealthDocuments } from '../views/EnvironmentHealth/Documents';
import { Messaging as EnvironmentHealthMessaging } from '../views/EnvironmentHealth/Messaging';
import { Runtimes as EnvironmentHealthRuntimes } from '../views/EnvironmentHealth/Runtimes';
import { Settings as EnvironmentSettings } from '../views/EnvironmentSettings';
import { ExpiredSession } from '../views/ExpiredSession';
import { CreateNode as CreateFireflyNode } from '../views/Firefly/CreateNode';
import { Firefly } from '../views/Firefly/Firefly';
import { FireflyInitialize } from '../views/Firefly/Initialize/FireflyInitialize';
import { Dashboard as FireflyDashboard } from '../views/FireflyDashboard/Dashboard';
import { GatewayAPI } from '../views/GatewayAPIs/GatewayAPI';
import { GatewayAPIs } from '../views/GatewayAPIs/GatewayAPIs';
import { GatewaySwagger } from '../views/GatewayAPIs/GatewaySwagger';
import { Create as InviteOrganization } from '../views/Governance/InviteOrganization/Create';
import { GovernanceMembershipDetails } from '../views/Governance/MembershipDetails';
import { GovernanceMemberships } from '../views/Governance/Memberships';
import { GovernanceNetworkActivity } from '../views/Governance/NetworkActivity';
import { HDWallets } from '../views/HDWallets/HDWallets';
import { CreateIpfs } from '../views/IPFS/Create/CreateIpfs';
import { Ipfs } from '../views/IPFS/Ipfs';
import { CertificateDetails } from '../views/IdentityManagement/CertificateDetails';
import { CertificateIdentities } from '../views/IdentityManagement/CertificateIdentities';
import { IdentityManagement } from '../views/IdentityManagement/IdentityManagement';
import { CloudHsmWallets, CreateCloudHsmWallet, CreateManagedWallet, KeyManagementDashboard, ManagedWallets, NodeWallets } from '../views/KeyManagement';
import { CloudHsmWalletDashboard } from '../views/KeyManagement/CloudHsmWalletDashboard';
import { ManageOrgBilling } from '../views/ManageOrg/Billing';
import { SetupBillingProvider } from '../views/ManageOrg/BillingProvider/Setup';
import { ChangePlan } from '../views/ManageOrg/ChangePlan/ChangePlan';
import { OrgDashboard } from '../views/ManageOrg/Dashboard';
import { Invoices } from '../views/ManageOrg/Invoices';
import { ManageOrgPaymentMethod } from '../views/ManageOrg/PaymentMethod';
import { OrgSecurity } from '../views/ManageOrg/Security';
import { OrgSettings } from '../views/ManageOrg/Settings';
import { ManageOrgSubscription } from '../views/ManageOrg/Subscription';
import { ManageUsers } from '../views/ManageOrg/Users/ManageUsers';
import { Dashboard as NetworkDashboard } from '../views/NetworkDashboard/Dashboard';
import { Settings as NetworkSettings } from '../views/NetworkSettings/Settings';
import { CloudConfigurations as NodeCloudConfigurations, ProtocolConfigurations as NodeProtocolConfigurations } from '../views/NodeConfigurations';
import { Create as CreateNodeContract } from '../views/NodeContracts/Create';
import { Create as CreateNodeCorDapp } from '../views/NodeCorDapps/Create';
import { Create as CreateEventStream } from '../views/NodeEventStreams/CreateEditEventStream/Create';
import { Edit as EditEventStream } from '../views/NodeEventStreams/CreateEditEventStream/Edit';
import { Create as CreateEventStreamSubscription } from '../views/NodeEventStreams/CreateEventStreamSubscription/Create';
import { NodeEventStream } from '../views/NodeEventStreams/NodeEventStream';
import { NodeEventStreamSubscription } from '../views/NodeEventStreams/NodeEventStreamSubscription';
import { NodeEventStreams } from '../views/NodeEventStreams/NodeEventStreams';
import { Metrics as NodeMetrics } from '../views/NodeMetrics';
import { Overview as NodeOverview } from '../views/NodeOverview';
import { Fabconnect } from '../views/NodeOverview/Fabconnect';
import { Settings as NodeSettings } from '../views/NodeSettings';
import { NodeTether } from '../views/NodeTether/NodeTether';
import { CreateOnChainRegistry } from '../views/OnChainRegistry/CreateOnChainRegistry';
import { OnChainRegistryOverview } from '../views/OnChainRegistry/OnChainRegistryOverview';
import { AgreementEditor } from '../views/Ops/AgreementEditor';
import { Agreements as OpsAgreements } from '../views/Ops/Agreements';
import { OpsConsortia } from '../views/Ops/Consortia';
import { OpsConsortium } from '../views/Ops/Consortium';
import { OpsEnvironments } from '../views/Ops/Environments';
import { Node as OpsNode } from '../views/Ops/Node';
import { OpsBilling } from '../views/Ops/OpsBilling';
import { OpsResources } from '../views/Ops/OpsResources';
import { Org as OpsOrg } from '../views/Ops/Org';
import { OrgAgreementEditor as OpsOrgAgreementEditor } from '../views/Ops/OrgAgreementEditor';
import { Orgs as OpsOrgs } from '../views/Ops/Orgs';
import { Service as OpsService } from '../views/Ops/Service';
import { Dashboard as LandingDashboard } from '../views/OrgLandingDashboard/Dashboard';
import { OrgListDashboard } from '../views/OrgListDashboard/Dashboard';
import { AdminAPIKeys } from '../views/ProfileSettings/AdminAPIKeys';
import { ProfilePropertiesPersonalInfo } from '../views/ProfileSettings/PersonalInfo';
import { ProfileSettingsSecurity } from '../views/ProfileSettings/Security';
import { CreateProtocolConfiguration } from '../views/ProtocolConfigurations/Create/CreateProtocolConfiguration';
import { ProtocolConfigurationInfo } from '../views/ProtocolConfigurations/ProtocolConfigurationInfo';
import { Create as CreateRotateSigners } from '../views/RotateSigners/Create/Create';
import { Manage as RotateSignersManage } from '../views/RotateSigners/Manage';
import { Overview as RotateSignersOverview } from '../views/RotateSigners/Overview';
import { ServiceSettings } from '../views/ServiceSettings';
import { SignupBilling } from '../views/SignupBilling';
import { SIGNUP_BILLING_PATH, SIGNUP_PLANS_BASE_PATH, SIGNUP_PLANS_PATH, SignupPlans } from '../views/SignupPlans/SignupPlans';
import { CreateTether } from '../views/Tether/CreateTether';
import { TetherActivity } from '../views/Tether/TetherActivity';
import { TetherManage } from '../views/Tether/TetherManage';
import { TetherOverview } from '../views/Tether/TetherOverview';
import { Dashboard as TokenExplorerDashboard, TokenInfo as TokenExplorerTokenInfo, Tokens as TokenExplorerTokens, TransferInfo as TokenExplorerTransferInfo, Transfers as TokenExplorerTransfers } from '../views/TokenExplorer';
import { UserInfo } from '../views/UserInfo';
import { Invitation, VIEW_INVITATION_PATH } from '../views/ViewInvitation/Invitation';
import { AppWrapper } from './AppWrapper';
import { BLOCKEXPLORER_BLOCKS_PATH, BLOCKEXPLORER_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from './BlockExplorerNav/BlockExplorerNav';
import { CERTIFICATE_AUTHORITY_IDENTITIES } from './CertificateAuthorityNav/CertificateAuthorityNav';
import { AllEventStreams } from './EventStreams/AllEventStreams';
import { KaleidoLogoBlue } from './Logos/KaleidoBlue';
import { NETWORK_COMPILED_CONTRACTS_PATH, NETWORK_CONTRACTS_PATH, NETWORK_REGIONS_PATH, NETWORK_SETTINGS_PATH } from './MainNav/ConsortiumWrapper';
import { CREATE_ENVIRONMENTS_PATH } from './MainNav/EnvironmentPicker';
import { ENVIRONMENT_SETTINGS_PATH } from './MainNav/EnvironmentWrapper';
import { ADDRESSBOOK_ACCOUNTS_PATH, ADDRESSBOOK_ACTIVITY_PATH, ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH, ADDRESSBOOK_DESTINATIONS_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_PATH, ADDRESSBOOK_RUNTIMES_PATH, CHANNELS_PATH } from './MainNav/SideNavs/AddressBook';
import { APPS_BASE_PATH, APPS_CHAINCODES_PATH, APPS_CORDAPPS_PATH, APPS_EVENTSTREAMS_PATH, APPS_GATEWAY_APIS_PATH, APPS_GATEWAY_VIEW_PATH } from './MainNav/SideNavs/AppsIntegrations';
import { B2B_BASE_PATH } from './MainNav/SideNavs/B2bCommunication';
import { BLOCKCHAIN_BASE_PATH, BLOCKCHAIN_CREATE_PATH, BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH, BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH } from './MainNav/SideNavs/Blockchain';
import { ATTACH_CLOUDCONFIG_PATH, CLOUDCONFIG_BASE_PATH, CREATE_CLOUDCONFIG_PATH } from './MainNav/SideNavs/CloudConfigs';
import { DATA_CONTRACTS, ENVIRONMENT_DATA_EXPLORER } from './MainNav/SideNavs/DataExplorer';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_ETHER_POOL_PATH, DIGITAL_ASSETS_TOKENS_PATH } from './MainNav/SideNavs/DigitalAssets';
import { FIREFLY_BASE_PATH, FIREFLY_INITIALIZE_PATH, FIREFLY_REGISTRATION_PATH, FIREFLY_SERVICE_PATH } from './MainNav/SideNavs/Firefly';
import { NETWORK_GOVERNANCE_ACTIVITYLOG_PATH, NETWORK_GOVERNANCE_IDENTITY_PATH, NETWORK_GOVERNANCE_INVITATIONS_PATH, NETWORK_GOVERNANCE_MEMBERSHIPS_PATH, NETWORK_GOVERNANCE_PATH, NETWORK_GOVERNANCE_REGIONS_PATH } from './MainNav/SideNavs/Governance';
import { ENVIRONMENT_HEALTH_BLOCKCHAIN_PATH, ENVIRONMENT_HEALTH_DOCUMENTS_PATH, ENVIRONMENT_HEALTH_MESSAGING_PATH, ENVIRONMENT_HEALTH_PATH, ENVIRONMENT_HEALTH_RUNTIMES_PATH } from './MainNav/SideNavs/HealthMonitoring';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_CLOUD_PATH, KEY_MANAGEMENT_MANAGED_PATH, KEY_MANAGEMENT_NODE_PATH } from './MainNav/SideNavs/KeyManagement';
import { ATTACH_BAF_PATH, CREATE_BAF_PATH, CREATE_OAUTH_CONFIGURATION_PATH, CREATE_TUNNELER_PATH, SECURITY_APPCREDS_PATH, SECURITY_BAF_MAPPINGS_PATH, SECURITY_BAF_PATH, SECURITY_BAF_RULESET_PATH, SECURITY_BASE_PATH, SECURITY_NETWORK_CONTROL_PATH, SECURITY_OAUTH_CONFIGURATION_PATH } from './MainNav/SideNavs/Security';
import { CHANGE_PLAN_PATH, CREATE_ORG_PATH, MANAGE_ORG_BILLING_PATH, MANAGE_ORG_INVOICES_PATH, MANAGE_ORG_MANAGE_USERS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_PAYMENT_METHOD_PATH, MANAGE_ORG_SECURITY_PATH, MANAGE_ORG_SETTINGS_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, ORGS_PATH, SETUP_BILLING_PROVIDER_PATH } from './ManageOrgNav/ManageOrgNav';
import { MEMBERSHIP_DESTINATIONS_PATH, MEMBERSHIP_ON_CHAIN_PROFILE_PATH, MEMBERSHIP_RUNTIMES_PATH, MEMBERSHIP_SIGNING_ACCOUNTS_PATH } from './MembershipNav/MembershipNav';
import { FABCONNECT_REST_API_PATH, NODE_ACCOUNTS_PATH, NODE_CONTRACTS_PATH, NODE_CORDAPPS_PATH, NODE_ClOUD_CONFIGURATIONS_PATH, NODE_EVENTSTREAMS_PATH, NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH, NODE_LOGS_PATH, NODE_METRICS_PATH, NODE_PROTOCOL_CONFIGURATIONS_PATH, NODE_SETTINGS_PATH, NODE_TETHER_PATH, NODE_USER_MANAGEMENT_PATH } from './NodeNav/NodeNav';
import { CREATE_NODES_PATH } from './NodeNav/NodePicker';
import { OPS_MANAGE_AGREEMENT_PATH, OPS_MANAGE_AGREEMENT_PATH_CREATE, OPS_MANAGE_CONSORTIA_PATH, OPS_MANAGE_ENVIRONMENTS_PATH, OPS_MANAGE_NODE_PATH, OPS_MANAGE_ORG_PATH, OPS_MANAGE_SERVICE_PATH, OPS_PATH } from './OpsNav/OpsNav';
import { OPS_ORG_AGREEMENT_PATH, OPS_ORG_BILLING_PATH, OPS_ORG_RESOURCES_PATH } from './OpsOrgNav/OpsOrgNav';
import { PROFILE_SECURITY_PATH, PROFILE_SETTINGS_ADMIN_API_KEYS_PATH, PROFILE_SETTINGS_PATH } from './ProfileSettingsNav/ProfileSettingsNav';
import { APP2APP_CLIENTS_PATH, APP2APP_CREATE_DESTINATION_PATH, APP2APP_DESTINATIONS_PATH, APP2APP_PRODUCER_PATH } from './ServicesNav/Services/App2AppItems';
import { LOGS_PATH, NAMESPACES_PATH, SETTINGS_PATH } from './ServicesNav/Services/BasicItems';
import { CLOUDHSM_WALLET_PATH } from './ServicesNav/Services/CloudHsmItems';
import { DOCSTORE_DESTINATIONS_PATH, DOCSTORE_DOCUMENTS_PATH, DOCSTORE_TRANSFERS_PATH } from './ServicesNav/Services/DocumentStoreItems';
import { ROTATE_SIGNERS_MANAGE_PATH } from './ServicesNav/Services/RotateSignersItems';
import { TETHER_ACTIVITY_PATH, TETHER_MANAGE_PATH } from './ServicesNav/Services/TetherItems';
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TOKENS_PATH, TOKENEXPLORER_TRANSFERS_PATH } from './TokenExplorerNav/TokenExplorerNav';
import { FireflyRegister } from '../views/Firefly/Register/FireflyRegister';
import { NamespaceManagement } from '../views/ServiceSettings/NamespaceManagement';


declare global {
    interface Window {
        _hsq?: { push: (hsParam: HubspotPushParams) => void }
    }
}

const SESSION_EXPIRY_WARNING_MINUTES = 15;

const history = createBrowserHistory();

const wrap = (component: JSX.Element, additionalTags?: JSX.Element): JSX.Element => {
    return (
        helmetWrap(
            <AppWrapper>
                {component}
            </AppWrapper>, undefined, undefined, additionalTags
        )
    )
}

const noAuthWrap = (component: JSX.Element, title: string, description: string, additionalTags?: JSX.Element): JSX.Element => {
    return helmetWrap(
        component,
        title,
        description,
        additionalTags
    )
}

const helmetWrap = (component: JSX.Element, title?: string, description?: string, additionalTags?: JSX.Element): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>{title || "Kaleido"}</title>
                <meta name="description" content={description || "Kaleido"} />
                {additionalTags}
            </Helmet>
            {component}
        </>
    )
}

interface Props {
    setServerError: (msg: string) => void
}

const Routes: React.FC<Props> = ({ setServerError }) => {

    const [sessionSnackbarVisible, setSessionSnackbarVisible] = useState<boolean>(false);
    const [sessionSnackbarMessage, setSessionSnackbarMessage] = useState<string>();
    const [sessionActions, setSessionActions] = useState<Array<JSX.Element>>([]);
    const [messageDismissed, setMessageDismissed] = useState(false);
    const apolloClient = useApolloClient();
    const { data: { session } = { session: undefined } } = useQuery<SessionData>(SessionQuery, { fetchPolicy: 'cache-only' });
    const formRef = useRef<HTMLFormElement>(null);
    const jwtRef = useRef<HTMLInputElement>(null);
    const returnToRef = useRef<HTMLInputElement>(null);

    const { t, i18n } = useTranslation();

    i18n.addResourceBundle('en', 'routes', enTranslations);

    useEffect(() => {
        const dismissButton = <IconButton key="dismissSessionSnackbar" size="small" color="inherit" onClick={() => setMessageDismissed(true)}><CloseIcon /></IconButton>
        let warningTimer: NodeJS.Timeout;
        let expirationTimer: NodeJS.Timeout;
        if (session) {
            const handleSessionRefresh = async () => {
                try {
                    await cognitoController.renewSession(apolloClient, session.selected_org);
                    setSessionSnackbarMessage(t('routes:sessionExtended'));
                    setSessionActions([dismissButton]);
                    setMessageDismissed(false);
                    setTimeout(() => {
                        setSessionSnackbarVisible(false);
                    }, 6000);
                } catch (err) {
                    await cognitoController.logout(`/expired?path=${window.location.pathname}`);
                }
            }
            warningTimer = setTimeout(() => {
                setSessionSnackbarMessage(t('routes:sessionAboutToExpire'));
                setSessionActions([
                    <Button key="refreshSession" color="secondary" onClick={handleSessionRefresh}>{t('routes:extendSession')}</Button>, dismissButton
                ]);
                setSessionSnackbarVisible(true);
            }, Math.max(session.exp * 1000 - Date.now() - SESSION_EXPIRY_WARNING_MINUTES * 60 * 1000, 0));
            expirationTimer = setTimeout(async () => {
                await cognitoController.logout(`/expired?path=${window.location.pathname}`);
            }, Math.max(session.exp * 1000 - Date.now(), 0));
        }
        return () => {
            clearTimeout(warningTimer);
            clearTimeout(expirationTimer);
        }
    }, [session, t, apolloClient, messageDismissed, sessionSnackbarVisible]);

    const { search } = history.location;
    const { path, redirect: redirectParam, return_to: zendeskGoToLink, contact_support } = queryString.parse(search);
    const haveSession = !!session;

    const [config, setConfig] = useState<Config | undefined>()

    const fetchConfig = useCallback(() => {
        fetch('/api/ui/v2/config').then(async response => {
            try {
                setConfig(await response.json() as Config);
            }
            catch (err) {
                console.error("Unable to query static config from Kaleido", err);
                setServerError(t('serverError:kaleidoUnavailable'))
            }
        })
    }, [setServerError, t]);

    // we always need a config
    useEffect(() => {
        if (!config) {
            fetchConfig()
        }
    }, [config, fetchConfig]);

    // If we've just established a session and have a path or zendesk variable, we need to redirect
    useEffect(() => {
        if (haveSession) {
            // If search param available we should make a call to api to redirect to zendesk sso
            if (redirectParam === 'zendesk') {
                const redirectToZendesk = async () => {
                    await fetch(
                        `/api/ui/v2/sso/zendesk`,
                        {
                            cache: 'no-cache',
                        }).then((res) => {
                            return res.json();
                        }).then(async (res) => {
                            const urlObject = new URL(res?.zendeskUrl);
                            const urlParams = new URLSearchParams(urlObject.search);
                            const jwtParam = urlParams.get('jwt') ?? '';
                            if (jwtRef?.current && formRef?.current && returnToRef?.current) {
                                jwtRef.current.value = jwtParam
                                if (zendeskGoToLink) {
                                    returnToRef.current.value = zendeskGoToLink as string
                                }
                                formRef.current.submit();
                            }
                            return jwtParam;
                        })
                        .catch((err) => {
                            setSessionSnackbarVisible(true);
                            setSessionSnackbarMessage(err);
                            setTimeout(() => {
                                setSessionSnackbarVisible(false);
                            }, 6000);
                        });
                }
                redirectToZendesk();
            } else if (haveSession && typeof path === "string" && path.length) {
                history.push(path);
            }
        }
    }, [path, haveSession, redirectParam, zendeskGoToLink]);

    const regions = config?.regions
    const ssoGoogle = config?.ssoGoogle || false
    const disableRecaptcha = config?.disableRecaptcha || false

    if (regions && haveSession) {
        const baseConsortiumPath = '/orgs/:org_id/consortia/:consortium_id'
        const baseEnvironmentPath = `${baseConsortiumPath}/environments/:environment_id`
        const baseContractsPath = `${baseConsortiumPath}/${NETWORK_CONTRACTS_PATH}`
        return (
            <>
                <Router history={history}>
                    <form ref={formRef} action={`https://kaleidoio.zendesk.com/access/jwt`} method="post">
                        <input ref={jwtRef} type="hidden" name="jwt"></input>
                        <input ref={returnToRef} type="hidden" name="return_to"></input>
                    </form>
                    <Switch>

                        {/* root */}
                        <Route exact path="/" render={() => wrap(<></>, <meta name="robots" content="noindex" />)} />
                        {/* Signup */}
                        <Route exact path="/signup" render={() => wrap(<UserInfo {...{ regions }} />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_PLANS_PATH}`} render={() => wrap(<SignupPlans />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_BILLING_PATH}/:step`} render={() => wrap(<SignupBilling />)} />

                        {/* orgs routes */}
                        <Route exact path={`/${ORGS_PATH}`} render={() => wrap(<OrgListDashboard />)} />
                        <Route exact path={`/${ORGS_PATH}/${CREATE_ORG_PATH}/:step`} render={() => wrap(<CreateOrganization {...{ regions }} />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id`} render={() => wrap(<LandingDashboard />)} />

                        <Route exact path={`/${ORGS_PATH}/:org_id/${VIEW_INVITATION_PATH}/:invitation_id/:step`} render={() => wrap(<Invitation />)} />

                        {/*  profile properties routes */}
                        <Route exact path={`/${PROFILE_SETTINGS_PATH}`} render={() => wrap(<ProfilePropertiesPersonalInfo />)} />
                        <Route exact path={`/${PROFILE_SETTINGS_PATH}/${PROFILE_SECURITY_PATH}`} render={() => wrap(<ProfileSettingsSecurity />)} />
                        <Route exact path={`/${PROFILE_SETTINGS_PATH}/${PROFILE_SETTINGS_ADMIN_API_KEYS_PATH}`} render={() => wrap(<AdminAPIKeys />)} />

                        {/*  manage org routes */}
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}`} render={() => wrap(<OrgDashboard />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}`} render={() => wrap(<ManageOrgSubscription />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/:step`} render={() => wrap(<ChangePlan />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_BILLING_PATH}`} render={() => wrap(<ManageOrgBilling />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}`} render={() => wrap(<ManageOrgPaymentMethod />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}/${SETUP_BILLING_PROVIDER_PATH}/:step`} render={() => wrap(<SetupBillingProvider />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_INVOICES_PATH}`} render={() => wrap(<Invoices />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_MANAGE_USERS_PATH}`} render={() => wrap(<ManageUsers />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_SECURITY_PATH}`} render={() => wrap(<OrgSecurity />)} />
                        <Route exact path={`/${ORGS_PATH}/:org_id/${MANAGE_ORG_PATH}/${MANAGE_ORG_SETTINGS_PATH}`} render={() => wrap(<OrgSettings />)} />

                        {/* consortia routes */}
                        <Route exact path={`/orgs/:org_id/consortia/create/:step`} render={() => wrap(<CreateConsortium />)} />
                        <Route exact path={baseConsortiumPath} render={() => wrap(<NetworkDashboard />)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_SETTINGS_PATH}`} render={() => wrap(<NetworkSettings />)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_REGIONS_PATH}/create/:step`} render={() => wrap(<CreateRegions />)} />

                        {/* governance routes */}
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}/:membership_id`} render={() => wrap(<GovernanceMembershipDetails />)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}/:membership_id/${NETWORK_GOVERNANCE_IDENTITY_PATH}/:step`} render={() => wrap(<ConfirmIdentity />)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}`} render={() => wrap(<GovernanceMemberships />)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_INVITATIONS_PATH}`} render={() => wrap(<div>not implemented</div>)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_INVITATIONS_PATH}/create/:step`} render={() => wrap(<InviteOrganization />)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_REGIONS_PATH}`} render={() => wrap(<div>not implemented</div>)} />
                        <Route exact path={`${baseConsortiumPath}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_ACTIVITYLOG_PATH}`} render={() => wrap(<GovernanceNetworkActivity />)} />

                        {/* contracts have their own nested router */}
                        {/* but, we still want the AppWrapper Breadcrumbs to have knowledge of :contract_id and :compiled_contract_id */}
                        <Route exact path={`${baseContractsPath}/:contract_id/${NETWORK_COMPILED_CONTRACTS_PATH}/:compiled_contract_id`}
                            render={() => wrap(<ContractsRouter baseRoutePath={`${baseContractsPath}`} />)} />
                        <Route exact path={`${baseContractsPath}/:contract_id`}
                            render={() => wrap(<ContractsRouter baseRoutePath={`${baseContractsPath}`} />)} />
                        <Route path={`${baseContractsPath}`}
                            render={({ match }) => wrap(<ContractsRouter baseRoutePath={match.path} />)} />


                        {/* environment routes */}
                        <Route exact path={`${baseConsortiumPath}/environments/${CREATE_ENVIRONMENTS_PATH}/:step`} render={() => wrap(<CreateEnvironment />)} />

                        <Route exact path={baseEnvironmentPath} render={() => wrap(<EnvironmentDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_SETTINGS_PATH}`} render={() => wrap(<EnvironmentSettings />)} />

                        {/* data & insights routes */}
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_DATA_EXPLORER}`} render={() => wrap(<Contracts />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}`} render={() => wrap(<Contracts />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}/:contract_address`} render={() => wrap(<ContractInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKEXPLORER_PATH}`} render={() => wrap(<BlockExplorer />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}`} render={() => wrap(<Blocks />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}/:block_number`} render={() => wrap(<BlockInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}`} render={() => wrap(<Transactions />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}/:transaction_hash`} render={() => wrap(<TransactionInfo />)} />

                        <Route exact path={`${baseEnvironmentPath}/${TOKENEXPLORER_PATH}`} render={() => wrap(<TokenExplorerDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TOKENS_PATH}`} render={() => wrap(<TokenExplorerTokens />)} />
                        <Route exact path={`${baseEnvironmentPath}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TOKENS_PATH}/:token_address`} render={() => wrap(<TokenExplorerTokenInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TRANSFERS_PATH}`} render={() => wrap(<TokenExplorerTransfers />)} />
                        <Route exact path={`${baseEnvironmentPath}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TRANSFERS_PATH}/:transfer_hash`} render={() => wrap(<TokenExplorerTransferInfo />)} />

                        {/* health & monitoring routes */}
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_HEALTH_PATH}`} render={() => wrap(<EnvironmentHealthDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_RUNTIMES_PATH}`} render={() => wrap(<EnvironmentHealthRuntimes />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_BLOCKCHAIN_PATH}`} render={() => wrap(<EnvironmentHealthBlockchain />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_DOCUMENTS_PATH}`} render={() => wrap(<EnvironmentHealthDocuments />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ENVIRONMENT_HEALTH_PATH}/${ENVIRONMENT_HEALTH_MESSAGING_PATH}`} render={() => wrap(<EnvironmentHealthMessaging />)} />

                        {/* Address Book routes */}
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}`} render={() => wrap(<AddressBookMemberships />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_RUNTIMES_PATH}`} render={() => wrap(<AddressBookRuntimes />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_DESTINATIONS_PATH}`} render={() => wrap(<AddressBookDestinations />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACTIVITY_PATH}`} render={() => wrap(<EnvironmentActivity />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}`} render={() => wrap(<AddressBookAccounts />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_ACCOUNTS_PATH}/:address`} render={() => wrap(<AddressBookAccountInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}`} render={() => wrap(<IdentityManagement />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}/:service_id`} render={() => wrap(<CertificateDetails />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}/:service_id/${CERTIFICATE_AUTHORITY_IDENTITIES}`} render={() => wrap(<CertificateIdentities />)} />

                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id`} render={() => wrap(<MemberDetails />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id/${MEMBERSHIP_RUNTIMES_PATH}/:runtime_id`} render={() => wrap(<AddressBookMemberRuntimeDetails />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id/${MEMBERSHIP_RUNTIMES_PATH}`} render={() => wrap(<MemberRuntimes />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id/${MEMBERSHIP_DESTINATIONS_PATH}/:destination_uri`} render={() => wrap(<MemberDestinationDetails />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id/${MEMBERSHIP_DESTINATIONS_PATH}`} render={() => wrap(<MemberDestinations />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id/${MEMBERSHIP_SIGNING_ACCOUNTS_PATH}/:address`} render={() => wrap(<MemberSigningAccount />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id/${MEMBERSHIP_SIGNING_ACCOUNTS_PATH}`} render={() => wrap(<MemberSigningAccounts />)} />
                        <Route exact path={`${baseEnvironmentPath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/:membership_id/${MEMBERSHIP_ON_CHAIN_PROFILE_PATH}`} render={() => wrap(<MemberOnChainProfile />)} />

                        {/* Channel routes */}
                        <Route exact path={`${baseEnvironmentPath}/${CHANNELS_PATH}`} render={() => wrap(<AddressBookChannels />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CHANNELS_PATH}/${CREATE_CHANNELS_PATH}/:step?`} render={() => wrap(<CreateChannel />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CHANNELS_PATH}/:channel_id`} render={() => wrap(<AddressBookChannelDetails />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CHANNELS_PATH}/:channel_id/nodes`} render={() => wrap(<AddressBookChannelNodes />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CHANNELS_PATH}/:channel_id/members`} render={() => wrap(<AddressBookChannelMembers />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CHANNELS_PATH}/:channel_id/chaincodes`} render={() => wrap(<AddressBookChannelChaincodes />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CHANNELS_PATH}/:channel_id/chaincodes/${ADD_CHAINCODE_PATH}/:step?`} render={() => wrap(<DeployChaincodeToChannel />)} />

                        {/* Node routes */}
                        <Route exact path={`${baseEnvironmentPath}/nodes/${CREATE_NODES_PATH}/:step/:nodeType?`} render={() => wrap(<CreateNode />)} />

                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}`} render={() => wrap(<BlockchainDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}/${BLOCKCHAIN_CREATE_PATH}/:step`} render={() => wrap(<CreateIpfs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}/:service_id`} render={() => wrap(<Ipfs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}/:service_id/${LOGS_PATH}`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.chainlink}/${BLOCKCHAIN_CREATE_PATH}/:step`} render={() => wrap(<CreateChainlink />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.chainlink}/:service_id`} render={() => wrap(<ChainlinkOverview />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.chainlink}/:service_id/${LOGS_PATH}`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.chainlink}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}`} render={() => wrap(<ConfigTable />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}/create/:step`} render={() => wrap(<CreateProtocolConfiguration />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}/update/:config_id/:step`} render={() => wrap(<CreateProtocolConfiguration />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}/attach/:config_id`} render={() => wrap(<AttachConfig />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}/:config_id`} render={() => wrap(<ProtocolConfigurationInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id`} render={() => wrap(<NodeOverview />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${FABCONNECT_REST_API_PATH}`} render={() => wrap(<Fabconnect />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_ClOUD_CONFIGURATIONS_PATH}`} render={() => wrap(<NodeCloudConfigurations />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_PROTOCOL_CONFIGURATIONS_PATH}`} render={() => wrap(<NodeProtocolConfigurations />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_EVENTSTREAMS_PATH}`} render={() => wrap(<NodeEventStreams />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_EVENTSTREAMS_PATH}/:eventstream_id`} render={() => wrap(<NodeEventStream />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_EVENTSTREAMS_PATH}/:eventstream_id/edit/:step`} render={() => wrap(<EditEventStream />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_EVENTSTREAMS_PATH}/:eventstream_id/${NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH}/create/:step`} render={() => wrap(<CreateEventStreamSubscription />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_EVENTSTREAMS_PATH}/:eventstream_id/${NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH}/:eventstream_subscription_id`} render={() => wrap(<NodeEventStreamSubscription />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_SETTINGS_PATH}`} render={() => wrap(<NodeSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_METRICS_PATH}`} render={() => wrap(<NodeMetrics />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_LOGS_PATH}/:container?`} render={() => wrap(<NodeLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_ACCOUNTS_PATH}`} render={() => wrap(<Wallet />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_ACCOUNTS_PATH}/:address`} render={() => wrap(<WalletAccount />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_USER_MANAGEMENT_PATH}`} render={() => wrap(<CordaUsers />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_CONTRACTS_PATH}/create/:step/:contract_type?`} render={() => wrap(<CreateNodeContract />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_CORDAPPS_PATH}`} render={() => wrap(<CorDapps />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_CORDAPPS_PATH}/create/:step`} render={() => wrap(<CreateNodeCorDapp />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_CORDAPPS_PATH}/:cordapp_id`} render={() => wrap(<CorDappViewer />)} />
                        <Route exact path={`${baseEnvironmentPath}/nodes/:node_id/${NODE_TETHER_PATH}`} render={() => wrap(<NodeTether />)} />

                        {/* On Chain Registry routes */}
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.idregistry}/${BLOCKCHAIN_CREATE_PATH}/:step`} render={() => wrap(<CreateOnChainRegistry />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.idregistry}/:service_id`} render={() => wrap(<OnChainRegistryOverview />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.idregistry}/:service_id/${LOGS_PATH}`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.idregistry}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />

                        {/*Firefly routes */}
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}`} render={() => wrap(<FireflyDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/create/:step`} render={() => wrap(<CreateFireflyNode />)} />
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/:service_id`} render={() => wrap(<Firefly />)} />
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/:service_id/${FIREFLY_INITIALIZE_PATH}`} render={() => wrap(<FireflyInitialize />)} />
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/:service_id/${FIREFLY_REGISTRATION_PATH}`} render={() => wrap(<FireflyRegister />)} />
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/:service_id/${NAMESPACES_PATH}`} render={() => wrap(<NamespaceManagement />)} />

                        {/* Security routes */}
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_NETWORK_CONTROL_PATH}`} render={() => wrap(<SecurityNetworkControl />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_APPCREDS_PATH}`} render={() => wrap(<AppCreds />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_APPCREDS_PATH}/:appcred_id`} render={() => wrap(<AppCred />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_OAUTH_CONFIGURATION_PATH}`} render={() => wrap(<OauthConfigurations />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_OAUTH_CONFIGURATION_PATH}/:oauth_id`} render={() => wrap(<OauthConfiguration />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_OAUTH_CONFIGURATION_PATH}/${CREATE_OAUTH_CONFIGURATION_PATH}/:step`} render={() => wrap(<OauthConfigurationCreate />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`} render={() => wrap(<ConfigTable />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/${CREATE_BAF_PATH}/:step`} render={() => wrap(<BAFPolicyCreate />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/${ATTACH_BAF_PATH}/:config_id`} render={() => wrap(<AttachConfig />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/:config_id/${SECURITY_BAF_RULESET_PATH}/:ruleset_id/:step`} render={() => wrap(<BAFRulesetEditor />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/:config_id/:auth_type/${SECURITY_BAF_MAPPINGS_PATH}/:mapping_id/:step`} render={() => wrap(<BAFMappingEditor />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/:config_id/:auth_type/:step`} render={() => wrap(<BAFAuthSettingsEditor />)} />
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}/:config_id`} render={() => wrap(<BAFPolicyViewer />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}`} render={() => wrap(<GatewayAPIs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/:gateway_api_id`} render={() => wrap(<GatewayAPI />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/:gateway_api_id/${APPS_GATEWAY_VIEW_PATH}`} render={() => wrap(<GatewaySwagger />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/:gateway_api_id/${APPS_GATEWAY_VIEW_PATH}/:endpoint`} render={() => wrap(<GatewaySwagger />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_CORDAPPS_PATH}`} render={() => wrap(<GatewayAPIs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_CORDAPPS_PATH}/:gateway_api_id`} render={() => wrap(<GatewayAPI />)} />


                        {/* Apps routes */}
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_EVENTSTREAMS_PATH}`} render={() => wrap(<AllEventStreams />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_EVENTSTREAMS_PATH}/create/:step`} render={() => wrap(<CreateEventStream />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}`} render={() => wrap(<Chaincodes />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}/:gateway_api_id`} render={() => wrap(<Chaincode />)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}/:gateway_api_id/${INSTANTIATE_CHAINCODE_PATH}/:step?`} render={() => wrap(<DeployToChannel />)} />
                        {/* Tunneler routes */}
                        <Route exact path={`${baseEnvironmentPath}/${SECURITY_BASE_PATH}/${ServicesEnum.tunneler}/${CREATE_TUNNELER_PATH}/:step`} render={() => wrap(<CreateTunneler />)} />

                        {/* Openlaw routes */}
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${ServicesEnum.openlaw}/:service_id`} render={() => wrap(<div>OPENLAW overview not implemented</div>)} />
                        <Route exact path={`${baseEnvironmentPath}/${APPS_BASE_PATH}/${ServicesEnum.openlaw}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />

                        {/* B2B routes */}
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}`} render={() => wrap(<B2bDashboard />)} />

                        {/* Cloud Configurations routes */}
                        <Route exact path={`${baseEnvironmentPath}/${CLOUDCONFIG_BASE_PATH}`} render={() => wrap(<CloudConfigsDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CLOUDCONFIG_BASE_PATH}/${CREATE_CLOUDCONFIG_PATH}/:step`} render={() => wrap(<CreateCloudConfig />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CLOUDCONFIG_BASE_PATH}/:config_type/${ATTACH_CLOUDCONFIG_PATH}/:config_id`} render={() => wrap(<AttachConfig />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CLOUDCONFIG_BASE_PATH}/:config_type/:config_id`} render={() => wrap(<CloudConfigInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/${CLOUDCONFIG_BASE_PATH}/:config_type`} render={() => wrap(<ConfigTable />)} />;

                        {/* Key Management */}
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}`} render={() => wrap(<KeyManagementDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_NODE_PATH}`} render={() => wrap(<NodeWallets />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}`} render={() => wrap(<ManagedWallets />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/create/:step`} render={() => wrap(<CreateManagedWallet />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}`} render={() => wrap(<CloudHsmWallets />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/create/:step`} render={() => wrap(<CreateCloudHsmWallet />)} />

                        {/* DocStore routes */}
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/create/:step`} render={() => wrap(<CreateDocExchange />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id/${DOCSTORE_DESTINATIONS_PATH}/create/:step`} render={() => wrap(<CreateDestinations />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id`} render={() => wrap(<DocStoreDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id/${DOCSTORE_DOCUMENTS_PATH}`} render={() => wrap(<DocumentStore />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id/${DOCSTORE_DESTINATIONS_PATH}`} render={() => wrap(<Destinations />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id/${DOCSTORE_TRANSFERS_PATH}`} render={() => wrap(<Transfers />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id/${DOCSTORE_TRANSFERS_PATH}/:transfer_id`} render={() => wrap(<TransferInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<DocStoreSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.documentstore}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />

                        {/* App2App routes */}
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/create/:step`} render={() => wrap(<CreateApp2App />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/:service_id`} render={() => wrap(<App2AppDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/:service_id/${APP2APP_DESTINATIONS_PATH}`} render={() => wrap(<App2AppDestinations />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/:service_id/${APP2APP_DESTINATIONS_PATH}/${APP2APP_CREATE_DESTINATION_PATH}/:step`} render={() => wrap(<CreateApp2AppDestination />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/:service_id/${APP2APP_CLIENTS_PATH}`} render={() => wrap(<App2AppClients />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/:service_id/${APP2APP_PRODUCER_PATH}`} render={() => wrap(<App2AppProducer />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${B2B_BASE_PATH}/${ServicesEnum.app2app}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />

                        {/* Digital Assets routes */}
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}`} render={() => wrap(<DigitalAssetsDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}`} render={() => wrap(<DigitalAssetsTokens />)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/:token_address`} render={() => wrap(<DigitalAssetsTokenInfo />)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/:token_address/transfer/:step`} render={() => wrap(<DigitalAssetsTokenAction />)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/:token_address/mint/:step`} render={() => wrap(<DigitalAssetsTokenAction />)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/:token_address/burn/:step`} render={() => wrap(<DigitalAssetsTokenAction />)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_ETHER_POOL_PATH}/create/:step`} render={() => wrap(<CreateEtherPool />)} />

                        {/* ETHWALLET routes */}
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.ethwallet}/:service_id`} render={() => wrap(<Wallet />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.ethwallet}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.ethwallet}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.ethwallet}/:service_id/:address`} render={() => wrap(<WalletAccount />)} />

                        {/* HDWALLET routes */}
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.hdwallet}/:service_id`} render={() => wrap(<HDWallets />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.hdwallet}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_MANAGED_PATH}/${ServicesEnum.hdwallet}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />

                        {/* TOKENSWAP routes */}
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${ServicesEnum.tokenswap}/:service_id`} render={() => wrap(<div>TOKENSWAP overview not implemented</div>)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${ServicesEnum.tokenswap}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />

                        {/* TOKENZKP routes */}
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${ServicesEnum.tokenzkp}/:service_id`} render={() => wrap(<div>TOKENZKP overview not implemented</div>)} />
                        <Route exact path={`${baseEnvironmentPath}/${DIGITAL_ASSETS_BASE_PATH}/${ServicesEnum.tokenzkp}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />

                        {/* CLOUDHSM routes */}
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/${ServicesEnum.cloudhsm}/:service_id`} render={() => wrap(<CloudHsmWalletDashboard />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/${ServicesEnum.cloudhsm}/:service_id/${CLOUDHSM_WALLET_PATH}`} render={() => wrap(<Wallet />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/${ServicesEnum.cloudhsm}/:service_id/${CLOUDHSM_WALLET_PATH}/:address`} render={() => wrap(<WalletAccount />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/${ServicesEnum.cloudhsm}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />
                        <Route exact path={`${baseEnvironmentPath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/${ServicesEnum.cloudhsm}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />

                        {/* ROTATESIGNERS routes */}
                        {/* On Chain Registry routes */}
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}/${BLOCKCHAIN_CREATE_PATH}/:step`} render={() => wrap(<CreateRotateSigners />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}/:service_id`} render={() => wrap(<RotateSignersOverview />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}/:service_id/${ROTATE_SIGNERS_MANAGE_PATH}`} render={() => wrap(<RotateSignersManage />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}/:service_id/${LOGS_PATH}`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />

                        {/* TETHER routes */}
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/${BLOCKCHAIN_CREATE_PATH}/:step`} render={() => wrap(<CreateTether />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/:service_id`} render={() => wrap(<TetherOverview />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/:service_id/${TETHER_ACTIVITY_PATH}`} render={() => wrap(<TetherActivity />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/:service_id/${TETHER_MANAGE_PATH}`} render={() => wrap(<TetherManage />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />

                        {/* CERTIFICATE AUTHORITIES routes */}
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}`} render={() => wrap(<IdentityManagement />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}/:service_id`} render={() => wrap(<CertificateDetails />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}/:service_id/${CERTIFICATE_AUTHORITY_IDENTITIES}`} render={() => wrap(<CertificateIdentities />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}/:service_id/${LOGS_PATH}/:container?`} render={() => wrap(<ServiceLogs />)} />
                        <Route exact path={`${baseEnvironmentPath}/${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}/:service_id/${SETTINGS_PATH}`} render={() => wrap(<ServiceSettings />)} />

                        {/* ops routes */}
                        <Route exact path={`/${OPS_PATH}`} render={() => wrap(<OpsOrgs {...{ regions }} />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}`} render={() => wrap(<OpsOrgs {...{ regions }} />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/:ops_org_id`} render={() => wrap(<OpsOrg />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/:ops_org_id/${OPS_ORG_RESOURCES_PATH}`} render={() => wrap(<OpsResources />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/:ops_org_id/${OPS_ORG_AGREEMENT_PATH}`} render={() => wrap(<OpsOrgAgreementEditor />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/:ops_org_id/${OPS_ORG_BILLING_PATH}`} render={() => wrap(<OpsBilling />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/:ops_org_id/${OPS_MANAGE_NODE_PATH}/:ops_node_id`} render={() => wrap(<OpsNode />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/:ops_org_id/${OPS_MANAGE_SERVICE_PATH}/:ops_service_id`} render={() => wrap(<OpsService />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_ENVIRONMENTS_PATH}`} render={() => wrap(<OpsEnvironments {...{ regions }} />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_CONSORTIA_PATH}`} render={() => wrap(<OpsConsortia {...{ regions }} />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_CONSORTIA_PATH}/:ops_consortia_id`} render={() => wrap(<OpsConsortium />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}`} render={() => wrap(<OpsAgreements {...{ regions }} />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}/${OPS_MANAGE_AGREEMENT_PATH_CREATE}`} render={() => wrap(<AgreementEditor {...{ regions }} />)} />
                        <Route exact path={`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}/:ops_agreement_id`} render={() => wrap(<AgreementEditor {...{ regions }} />)} />

                        <Redirect to={`/${contact_support ? '?contact_support=true' : ''}`} />
                    </Switch>
                </Router>
                <Snackbar
                    open={sessionSnackbarVisible && !messageDismissed}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    message={sessionSnackbarMessage}
                    action={sessionActions}
                />
            </>
        );
    } else if (regions) {
        return (
            <Router history={history}>
                <AuthenticationWrapper>
                    <Switch>
                        <Route path='/setup' render={() => noAuthWrap(
                            <UserAccountSetup regions={regions} />,
                            t('routes:login'),
                            t('routes:loginDescription')
                        )} />
                        <Route exact path="/login" render={() => noAuthWrap(
                            <Login regions={regions} ssoGoogle={ssoGoogle} />,
                            t('routes:login'),
                            t('routes:loginDescription'),
                            <link rel="canonical" href="https://console.kaleido.io/login" />
                        )} />
                        <Route exact path="/signup" render={() => helmetWrap(
                            <Signup regions={regions} ssoGoogle={ssoGoogle} disableRecaptcha={disableRecaptcha} />,
                            t('routes:signup'),
                            t('routes:signupDescription'),
                            <link rel="canonical" href="https://console.kaleido.io/signup" />
                        )} />
                        <Route path="/forgotpassword" render={() => noAuthWrap(
                            <ForgotPassword regions={regions} disableRecaptcha={disableRecaptcha} />,
                            t('routes:forgotPassword'),
                            t('routes:forgotPasswordDescription'),
                            <link rel="canonical" href="https://console.kaleido.io/forgotpassword" />
                        )} />
                        <Route path="/expired" render={() => noAuthWrap(
                            <ExpiredSession />,
                            t('routes:login'),
                            t('routes:loginDescription')
                        )} />
                        <Redirect to={`/login${contact_support ? '?contact_support=true' : ''}`} />
                    </Switch>
                </AuthenticationWrapper>
            </Router>
        );
    } else {
        return (
            <Backdrop transitionDuration={700} style={{ backgroundColor: 'white' }} open={true}>
                <KaleidoLogoBlue />
            </Backdrop>
        )
    }

};

const enTranslations = {
    sessionAboutToExpire: 'Your session is about to expire',
    extendSession: 'Extend session',
    sessionExtended: 'Your session has been extended',
    couldNotExtendSession: 'Could not extend session',
    retry: "Retry",
    signup: 'Try Kaleido Free - Sign up for a free Kaleido account',
    signupDescription: 'Get started on your blockchain journey with Kaleido\'s totally free Starter plan. No credit card required!',
    login: 'Kaleido Login - Sign in to your Kaleido account',
    loginDescription: 'Login to your Kaleido account: Your web3 platform for blockchain and digital assets.',
    forgotPassword: 'Reset Password - Kaleido',
    forgotPasswordDescription: 'Forgot the password for your Kaleido account? Enter your email address to reset it and get back into your account.'
}

export default Routes;
