import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DisplayTableRecord } from '../../components/DisplayWrappers/DisplayTableRow';
import { OPS_MANAGE_AGREEMENT_PATH, OPS_PATH } from '../../components/OpsNav/OpsNav';
import { OpsAgreementsData, OpsAgreementsQuery } from '../../models/agreements';
import { OpsTable, SkipLimt } from './OpsTable';
import { OpsRegionalQueryVars } from '../../interfaces';

interface Props {
    setMessage: (m: string) => void,
    region: string,
    idFilter: string,
    templateFilter: string,
    nameFilter: string,
    typeFilter: string,
}

export const AgreementsTable: React.FC<Props> = ({setMessage, region, idFilter, templateFilter, nameFilter, typeFilter}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsAgreementsTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsAgreementsTable:${key}`, interpolate)

    const [skipLimit, setSkipLimit] = useState<SkipLimt>({skip: 0, limit: 0});

    const history = useHistory();

    let query = `skip=${skipLimit.skip}&limit=${skipLimit.limit}`;
    query = idFilter ? `${query}&_id=~${idFilter}` : query;
    query = nameFilter ? `${query}&name=~${nameFilter}` : query;
    query = typeFilter ? `${query}&type=~${typeFilter}` : query;
    query = templateFilter === 'true' ? `${query}&template=` : templateFilter === 'false' ? `${query}&template=!` : query;

    const {
        data: {
            opsAgreements: agreements
        } = { opsAgreements: [] },
        error: fetchError, 
    } = useQuery<OpsAgreementsData, OpsRegionalQueryVars>(OpsAgreementsQuery, {
        skip: !skipLimit.limit,
        variables: {
            query,
            region
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    const records: DisplayTableRecord[] = agreements.map(a => ({
        columns: [{
            value: a.name || '',
            maxWidthPixelValue: 250,
        }, {
            value: a.template ? lt('yes') : '',
            maxWidthPixelValue: 75,
        }, {
            value: a.type,
            maxWidthPixelValue: 250,
        }, {
            value: a._id!,
            maxWidthPixelValue: 75,
        }],
        onClick: () => history.push(`/${OPS_PATH}/${OPS_MANAGE_AGREEMENT_PATH}/${a._id}`)
    }));

    return (
        <OpsTable {...{setSkipLimit}} tableProps={{
            records,
            columnHeaders: [
                lt('name'),
                lt('template'),
                lt('type'),
                lt('id'),
            ]
        }}/>
    )
};

interface translations {
    yes: string,
    id: string,
    template: string;
    name: string;
    type: string;
}
const enTranslations: translations = {
    yes: 'Yes',
    id: 'ID',
    template: 'Template',
    name: 'Name',
    type: 'Type',
}
