import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';
import { MessageSnackbar, ErrorSnackbarCatcher, CreateWrapper } from '../../../components/DialogWrappers'
import { Typography, Grid, Checkbox, FormControlLabel, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { CreateStepProps, EnvironmentResourcesVars } from '../../../interfaces';
import { Step1FabricHelp } from './Step1FabricHelp';
import { Channel, ChannelsData, ChannelsQuery, DeployCompiledContractMutation, DeployContractInputVars } from '../../../models/channels';
import { APPS_BASE_PATH, APPS_CHAINCODES_PATH } from '../../../components/MainNav/SideNavs/AppsIntegrations';

interface Props extends CreateStepProps {
    channelChosen: string,
    setChannelChosen: React.Dispatch<React.SetStateAction<string>>,
};

export const Step1 = ({ channelChosen = '', setChannelChosen, cancelPath}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DeployChaincodeToChannelStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DeployChaincodeToChannelStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, gateway_api_id } = useParams<any>();
    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [message, setMessage] = useState('');
    const [reqInitialization, setReqInitialization] = useState(false);

    const {
        data: {
            channels: envChannels
        } = { channels: [] }
    } = useQuery<ChannelsData, EnvironmentResourcesVars>(ChannelsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const availableChannels = envChannels.filter((ch) => ch.state === 'live' && !ch.contracts.some(c => c._id === gateway_api_id))

    if (availableChannels.length > 0 && channelChosen === '') {
        setChannelChosen(availableChannels[0]._id)
    }

    if (availableChannels.length < 1 && channelChosen !== '') {
        setChannelChosen('')
    }

    const chaincodeVariables = {
        ...environmentVariables,
        id: channelChosen!
    }

    const redirectToChaincode = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}/${APPS_CHAINCODES_PATH}/${gateway_api_id}`
    const [deployCompiledContract, { loading: deployContractLoading }] = useMutation<Channel, DeployContractInputVars>(DeployCompiledContractMutation);

    const save = async () => {
        return deployCompiledContract({
            variables: {
                ...chaincodeVariables,
                compiledContract: {
                    compiled_contract_id: gateway_api_id,
                    init_required: reqInitialization
                }
            }
        }).then(() => {
            history.push(redirectToChaincode);
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const disabled = deployContractLoading || channelChosen === ''

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>
            <Grid item>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel required>
                        {availableChannels.length > 0 ? lt('selectChannel') : lt('installedToAllValidChannels')}
                    </InputLabel>
                    <Select 
                        disabled={availableChannels.length === 0}
                        labelWidth={80}
                        value={channelChosen || availableChannels[0]?._id || ''}
                        onChange={e => setChannelChosen(e.target.value as string)}>
                        {availableChannels.map(l => {
                            return (
                                <MenuItem key={l._id} selected={l._id === channelChosen} value={l._id}>{l.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={reqInitialization}
                            onChange={e => setReqInitialization(e.target.checked)}
                            value='reqInitialization'/>
                    }
                    label={lt('reqInitialization')}
                    labelPlacement="end"
                    />
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('reqInitializationDesc')}
                    </Typography>                
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            <Step1FabricHelp />
        </>
    )
};

interface translations {
    header: string,
    reqInitialization: string,
    reqInitializationDesc: string
    selectChannel: string,
    installedToAllValidChannels: string
}
const enTranslations: translations = {
    header: 'Select Channel',
    reqInitialization: 'Require initialization before invocation',
    reqInitializationDesc: 'This adds the "--init-required" parameter to the lifecycle commands, which requires the chaincode to be initialized before it can process transactions.',
    selectChannel: 'Channel',
    installedToAllValidChannels: 'Chaincode has been installed to all available channels.'
}
