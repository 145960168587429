import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RuntimeStatistics, RuntimeStatistic, RuntimeSize, NODE_SIZE_DETAILS } from '../../models';
import { LineChart } from './LineChart'

interface Props {
    allRuntimes: { _id: string, name: string }[],
    runtimesForChart: RuntimeStatistics[],
    chart: 'cpu' | 'memory' | 'storage',
    height?: string
};

export const ResourceChart = ({ allRuntimes, runtimesForChart, chart, height }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsResourceChart', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`ChartsResourceChart:${key}`, interpolate), [t])

    const getMaxCpuValue = (size: RuntimeSize) => {
        return NODE_SIZE_DETAILS[size].cores * 100
    }
    const getCpuRelativeValue = (c: number, size: RuntimeSize) => {
        return Math.round((c / getMaxCpuValue(size)) * 100)
    }

    const getValue = (d: RuntimeStatistic, size: RuntimeSize): [number, number] => {
        if (chart === 'cpu') return [Date.parse(d.t), getCpuRelativeValue(d.c.valueOf(), size)]
        if (chart === 'memory') return [Date.parse(d.t), d.m.valueOf()]
        return [Date.parse(d.t), d.s.valueOf()]
    }

    const getValueSuffix = () => {
        if (chart === 'cpu') return '%'
        if (chart === 'memory') return ' MB'
        if (chart === 'storage') return ' MB'
        return ''
    }

    const series = runtimesForChart.map(r => {
        return { 
            name: allRuntimes.find(runtime => runtime._id === r.id)?.name || '', 
            values: r.data.map(d => getValue(d, r.size))
        }
    })

    return <LineChart title={lt(chart)} series={series} valuesSuffix={getValueSuffix()} height={height} />
};

interface translations {
    cpu: string,
    memory: string,
    storage: string,
}
const enTranslations: translations = {
    cpu: 'CPU',
    memory: 'Memory',
    storage: 'Disk',
}