import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { AccountsData, AccountsVars, AccountsQuery } from '../../models'
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { WalletAccountDetails } from '../../components/Accounts/WalletAccountDetails';

export const AddressBookAccountInfo: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AccountInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AccountInfo:${key}`, interpolate)

    const { consortium_id, environment_id, node_id, service_id, address } = useParams<any>();

    const accountsVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        wallet_id: node_id || service_id
    }

    const {
        loading,
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData, AccountsVars>(AccountsQuery, {
        variables: accountsVars
    });

    const account = accounts.find(a => a._id === address);
    
    if (loading) return <CircularProgress />

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item >
                <Typography variant="h5">
                    {lt('accountDetails')}
                </Typography>
            </Grid>
            <WalletAccountDetails account={account} />
        </Grid>
    )
};

interface translations {
    accountDetails: string
}

const enTranslations: translations = {
    accountDetails: 'Account Details'
}