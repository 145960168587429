import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { ConsortiumResourcesVars, ConsortiumResourceVars, EnvironmentResourcesVars } from '../interfaces'
import { MembershipOwnedResource } from './memberships';

export type SupportedEvmVersions = "homestead" | "tangerineWhistle" | "spuriousDragon" | "byzantium" | "constantinople" | "istanbul"

export type CompiledContractState = "created" | "compiling" | "compiled" | "failed" | "deleted"

export type PrecompiledTemplate = 'firefly_ethereum' | 'firefly_fabric' | 'firefly_erc1155'

export interface CompiledContract extends MembershipOwnedResource {
    _id: string
    consortia_id: string
    contract_url: string
    contract_name: string
    description: string
    contract_id: string
    state: CompiledContractState, // allow deleted for graphql cache deletes
    created_at: string
    updated_at: string
    abi: string
    bytecode: string
    dev_docs: string
    commit_hash: string
    solc_version: string
    evm_version: SupportedEvmVersions,
    has_constructor_params: boolean
    bytecode_hash: string
    errors: string[]
}

export interface CompiledContractData {
    compiledContract: CompiledContract
}

export interface CompiledContractsData {
    compiledContracts: CompiledContract[]
}

export interface UpdateCompiledContractVars extends CompiledContractVars {
    compiledContract: {
        description?: CompiledContract['description'],
        abi?: CompiledContract['abi'],
        dev_docs?: CompiledContract['dev_docs'],
    }
}

export interface CompiledContractsVars extends ConsortiumResourcesVars {
    contract_id: string
}

export interface CompiledContractVars extends ConsortiumResourceVars, CompiledContractsVars {
    id: string
}

export interface CreateCompiledContractVars extends CompiledContractsVars {
    compiledContract: {
        description: CompiledContract['description']
        membership_id: CompiledContract['membership_id']
        evm_version?: CompiledContract['evm_version']
        solc_version?: CompiledContract['solc_version']
        contract_url?: CompiledContract['contract_url']
        contract_name?: CompiledContract['contract_name']
        abi?: CompiledContract['abi']
        bytecode?: CompiledContract['bytecode']
        dev_docs?: CompiledContract['dev_docs']
        oauth_token?: string
        precompiledTemplate?: PrecompiledTemplate // special templates that ui-server handles differently
    }
}

export interface ForceCreateCompiledContractVars extends EnvironmentResourcesVars {
    forceCompiledContract: {
        membership_id: CompiledContract['membership_id']
        precompiledTemplate?: PrecompiledTemplate // special templates that ui-server handles differently
        evm_version?: CompiledContract['evm_version']
        solc_version?: CompiledContract['solc_version']
        fireflyVersion: string
        selectedChannel?: string
    }
}

export interface PromoteCompiledContractVars extends CompiledContractVars {
    promoteCompiledContract: {
        endpoint?: string
        environment_id: string
    }
}

export interface CreateCompiledContractData {
    createCompiledContract: CompiledContract
}

export interface ForceCreateCompiledContract {
    chaincodeLabel: string
    instanceAddress: string
    gatewayAPIEndpoint: string
    compiledContract: CompiledContract
}

export interface ForceCreateCompiledContractData {
    forceCreateCompiledContract: ForceCreateCompiledContract
}

export const CompiledContractFields = ` 
    fragment CompiledContractFields on CompiledContract {
        _id
        consortia_id
        membership_id
        contract_url
        contract_name
        description
        contract_id
        state
        created_at
        updated_at
        abi
        bytecode
        dev_docs
        commit_hash
        solc_version
        evm_version
        has_constructor_params
        bytecode_hash
        errors
        membership @client
    }`

export const CompiledContractsQuery = gql`
    ${CompiledContractFields}
    query getCompiledContracts($consortia_id: String!, $contract_id: String!) {
        compiledContracts(consortia_id: $consortia_id, contract_id: $contract_id) {
            ...CompiledContractFields
        }
    }`

export const CompiledContractQuery = gql`
    ${CompiledContractFields}
    query getCompiledContract($consortia_id: String!, $contract_id: String!, $id: String!) {
        compiledContract(consortia_id: $consortia_id, contract_id: $contract_id, id: $id) {
            ...CompiledContractFields
        }
    }`

export const CompiledContractsSubscription = gql`
    ${CompiledContractFields}
    subscription onCompiledContractsChanged($consortia_id: String!, $contract_id: String!) {
        compiledContract(consortia_id: $consortia_id, contract_id: $contract_id) {
            ...CompiledContractFields
        }
    }`

export const CreateCompiledContractMutation = gql`
    ${CompiledContractFields}
    mutation createCompiledContract($consortia_id: String!, $contract_id: String!, $compiledContract: CreateCompiledContractInput!) {
        createCompiledContract(consortia_id: $consortia_id, contract_id: $contract_id, compiledContract: $compiledContract) {
            ...CompiledContractFields
        }
    }`;

export const ForceCreateCompiledContractMutation = gql`
    ${CompiledContractFields}
    mutation forceCreateCompiledContract($consortia_id: String!, $environment_id: String!, $forceCompiledContract: ForceCompiledContractInput!) {
        forceCreateCompiledContract(consortia_id: $consortia_id, environment_id: $environment_id, forceCompiledContract: $forceCompiledContract) {
            chaincodeLabel
            instanceAddress
            gatewayAPIEndpoint
            compiledContract {
                ...CompiledContractFields
            }
        }
    }`;

export const PromoteCompiledContractMutation = gql`
    mutation promoteCompiledContract($consortia_id: String!, $contract_id: String!, $id: String!, $promoteCompiledContract: PromoteCompiledContractInput!) {
        promoteCompiledContract(consortia_id: $consortia_id, contract_id: $contract_id, id: $id, promoteCompiledContract: $promoteCompiledContract)
    }`;

export const UpdateCompiledContractMutation = gql`
    ${CompiledContractFields}
    mutation updateCompiledContract($consortia_id: String!, $contract_id: String!, $id: String!, $compiledContract: UpdateCompiledContractInput!) {
        updateCompiledContract(consortia_id: $consortia_id, contract_id: $contract_id, id: $id, compiledContract: $compiledContract) {
            ...CompiledContractFields
        }
    }`;

export const DeleteCompiledContractMutation = gql`
    mutation deleteCompiledContract($consortia_id: String!, $contract_id: String!, $id: String!) {
        deleteCompiledContract(consortia_id: $consortia_id, contract_id: $contract_id, id: $id)
    }`;

export function MakeCompiledContractCreateMutationOptions(
    variables: CreateCompiledContractVars,
    overrideOptions?: MutationFunctionOptions<CreateCompiledContractData, CreateCompiledContractVars>): MutationFunctionOptions<CreateCompiledContractData, CreateCompiledContractVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const compiledContract = data?.createCompiledContract
            if (compiledContract) {
                const { compiledContracts } = cache.readQuery<CompiledContractsData>({ query: CompiledContractsQuery, variables })!;
                // if subscription wrote it to the cache first, then we dont need to write it in here.
                // (the already added cached entry will be auto updated with the result of the mutation)
                if (!compiledContracts.find(a => a._id === compiledContract._id)) {
                    cache.writeQuery({
                        variables,
                        query: CompiledContractsQuery,
                        data: { compiledContracts: compiledContracts.concat(compiledContract) },
                    });
                }
            }
        }
    }, ...overrideOptions }
}

export function MakeCompiledContractsSubscriptionOptions(
    variables: CompiledContractsVars,
    overrideOptions?: SubscriptionHookOptions<CompiledContractData>
    ): SubscriptionHookOptions<CompiledContractData> {
        return { ...{
            variables,
            onSubscriptionData: ({client, subscriptionData}) => {
                // Get the servicesChanged object from the event
                const { 
                    data: { 
                        compiledContract
                    } = { compiledContract: null }
                } = subscriptionData
    
                if (!compiledContract) return
    
                // Get current list of compiledContracts from the cache
                // assert that compiledContracts must exist since this function throws if it doesnt
                const { compiledContracts: existingCompiledContracts } = client.readQuery<CompiledContractsData>({ 
                    query: CompiledContractsQuery, 
                    variables 
                })!
                
                // if it already exists in the store, (its an update), apollo automagically updated the cache
                const cachedCompiledContracts = existingCompiledContracts.find(c => c._id === compiledContract._id)
    
                // but, if it came back as deleted, we need to remove it from the cache manually
                if (cachedCompiledContracts) {
                    if (cachedCompiledContracts.state === 'deleted') {
                        client.cache.evict({id: `CompiledContract:${cachedCompiledContracts._id}`, broadcast: false})
                    }
                    client.writeQuery<CompiledContractsData>({ 
                        query: CompiledContractsQuery, 
                        data: Object.assign({}, existingCompiledContracts, {
                            compiledContracts: [...existingCompiledContracts.filter(c => c.state !== 'deleted')]
                        }),
                        variables
                    })
                    return 
                }
    
                // in case of duplicate events, never add a deleted one into the cache
                if (compiledContract.state === 'deleted') return
    
                // if it doesnt exist in the store, (it's been added), so we need to add it in manually
                client.writeQuery<CompiledContractsData>({ 
                    query: CompiledContractsQuery, 
                    data: Object.assign({}, existingCompiledContracts, {
                        compiledContracts: [...existingCompiledContracts, compiledContract]
                    }),
                    variables
                })
            }
        }, ...overrideOptions }
}
