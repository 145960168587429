import React from 'react';
import { Tooltip, makeStyles, Typography, Zoom, IconButton, Grid, GridJustification } from "@material-ui/core";
import { TypographyProps } from '@material-ui/core/Typography';
import { TooltipProps } from '@material-ui/core/Tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';

interface Props {
    content: string,
    textColor?: TypographyProps["color"],
    hideCopy?: boolean,
    tooltipPlacement?: TooltipProps["placement"],
    prefixCharacters?: number,
    postfixCharacters?: number,
    gridJustify?: GridJustification,
    noLowerCase?: boolean
};

export const ShortenedString = ({ 
    content, textColor = "inherit", hideCopy = false, tooltipPlacement = "top",
    prefixCharacters = 6, postfixCharacters = 6, gridJustify = "flex-start", noLowerCase = false }: Props) => {

    const classes = useStyles();
    
    const title = <Typography variant="body1">{content}</Typography>
    
    const truncate = (s: string) => {
        let truncated = ""
        if (s.length < (prefixCharacters + postfixCharacters)) {
            return s
        }

        if (prefixCharacters > 0) {
            truncated += s.slice(0, prefixCharacters)
        }

        if (postfixCharacters > 0) {
            truncated += `...${s.slice(s.length - postfixCharacters, s.length)}`
        } else {
            truncated += '...'
        }

        return truncated
    }

    const contentToDisplay = truncate(noLowerCase ? content : content.toLowerCase())

    return (
        <Grid item container alignItems="center" justify={gridJustify} spacing={1} wrap="nowrap">
            <Grid item>
                {contentToDisplay !== content ?
                <Tooltip arrow enterDelay={100} TransitionComponent={Zoom} {...{title}}
                    classes={{ tooltip: classes.customWidth }} placement={tooltipPlacement}>
                    <Typography variant="body2" color={textColor}>{contentToDisplay}</Typography>
                </Tooltip> :
                <Typography variant="body2" color={textColor}>{contentToDisplay}</Typography>
                }
            </Grid>

            {!hideCopy && 
            <Grid item>
                <CopyToClipboard text={content}>
                    <IconButton color="inherit" size="small">
                        <ContentCopyIcon />
                    </IconButton>
                </CopyToClipboard> 
            </Grid>
            }
        </Grid>
    )
};

const useStyles = makeStyles(() => ({
    customWidth: {
        maxWidth: 475
    }
}));
