import { gql } from '@apollo/client';
import { PaginatedEnvironmentResourcesVars, EnvironmentResourcesVars } from '../interfaces'

export interface LedgerContract {
    _id: string,
    consortia_id: string,
    environment_id: string,
    abi: string,
    address: string,
    type: string,
    bytecodeHash: string,
    compilerVersion: string,
    contractName: string,
    creationBytecode: string,
    creator: string,
    dateVerified: string,
    description?: string,
    erc20TotalSupply: string,
    ERCMinters: string[],
    genesisTransaction: string,
    isERC20: boolean,
    isERC721: boolean,
    isERCMintable: boolean,
    isERCBurnable: boolean,
    isHTLCER20: boolean,
    isHTLCER721: boolean,
    isZKPToken: boolean,
    optimization: boolean,
    runtimeBytecode: string,
    transactionCount: number,
    sourceCode: string,
    tokenName: string,
    tokenSymbol: string,
    gatewayAPIId: string,
    gatewayAPIEndpoint: string,
    instanceEndpointId: string,
    bytecodeWithoutConstructor: string
}


export interface TokenContractsVars extends PaginatedEnvironmentResourcesVars {
    type?: string
}

export interface EtherBalanceOfVars extends EnvironmentResourcesVars {
    accountAddress: string
}

export interface TokenContractBalanceOfVars extends EtherBalanceOfVars {
    contractAddress: string,
}

export interface LedgerContractData {
    ledgerContract: LedgerContract
}
export interface LedgerContractsData {
    ledgerContracts: {
        totalCount: number
        contracts:LedgerContract[]
    }
}

export interface TokenContractsData {
    tokenContracts: {
        totalCount: number
        contracts: LedgerContract[]
    }
}

export interface TokenContractBalanceOfData {
    tokenContractBalanceOf: string
}

export interface EtherBalanceOfData {
    etherBalanceOf: string
}



export const LedgerContractFields = ` 
    fragment LedgerContractFields on LedgerContract {
        _id
        consortia_id
        environment_id
        abi
        address
        type
        compilerVersion
        contractName
        creationBytecode
        bytecodeHash
        creator
        dateVerified
        description
        erc20TotalSupply
        ERCMinters
        genesisTransaction
        isERC20
        isERC721
        isERCMintable
        isERCBurnable
        isHTLCER20
        isHTLCER721
        isZKPToken
        optimization
        runtimeBytecode
        sourceCode
        tokenName
        tokenSymbol
        gatewayAPIId
        gatewayAPIEndpoint
        transactionCount
        instanceEndpointId
        bytecodeWithoutConstructor
    }`

export const LedgerContractsFields = `
    ${LedgerContractFields}
    fragment LedgerContractsFields on LedgerContracts {
        totalCount
        contracts {
            ...LedgerContractFields
        }
    }
`

export const LedgerContractsQuery = gql`
    ${LedgerContractsFields}
    query getLedgerContracts($consortia_id: String!, $environment_id: String!, $start: Int, $limit: Int) {
        ledgerContracts(consortia_id: $consortia_id, environment_id: $environment_id, start: $start, limit: $limit) {
            ...LedgerContractsFields
        }
    }`;

export const LedgerContractQuery = gql`
    ${LedgerContractFields}
    query getLedgerContract($consortia_id: String!, $environment_id: String!, $id: String!) {
        ledgerContract(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...LedgerContractFields
        }
    }`;

export const TokenContractsQuery = gql`
    ${LedgerContractFields}
    query getTokenContracts($consortia_id: String!, $environment_id: String!, $type: String, $start: Int, $limit: Int) {
        tokenContracts(consortia_id: $consortia_id, environment_id: $environment_id, type: $type, start: $start, limit: $limit) {
            totalCount
            contracts {
                ...LedgerContractFields
            }
        }
    }`;    

export const TokenContractBalanceOfQuery = gql`
    query getTokenContractBalanceOf($consortia_id: String!, $environment_id: String!, $contractAddress: String!, $accountAddress: String!) {
        tokenContractBalanceOf(consortia_id: $consortia_id, environment_id: $environment_id, contractAddress: $contractAddress, accountAddress: $accountAddress)
    }`; 

export const EtherBalanceOfQuery = gql`
    query getEtherBalanceOf($consortia_id: String!, $environment_id: String!, $accountAddress: String!) {
        etherBalanceOf(consortia_id: $consortia_id, environment_id: $environment_id, accountAddress: $accountAddress)
    }`; 