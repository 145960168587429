import { useMutation } from "@apollo/client";
import { Grid, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { FormLink } from "../../components/FormControls/FormLink";
import { CHANNELS_PATH } from "../../components/MainNav/SideNavs/AddressBook";
import { CreateStepProps } from '../../interfaces';
import { CreateChannelData, CreateChannelMutation, CreateChannelVars, MakeChannelCreateMutationOptions } from '../../models';
import { Step4Help } from './Step4Help';


interface Props extends CreateStepProps {
    name: string,
    membershipId: string,
    description: string,
    selectedMembers: string[]
};

export const Step4 = ({ 
        name, membershipId, selectedMembers, description, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateChannelCreateStep4', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateChannelCreateStep4:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const [message, setMessage] = useState("");
    const [createChannel, { loading: createChannelLoading }] = 
        useMutation<CreateChannelData, CreateChannelVars>(CreateChannelMutation)
    const disabled = createChannelLoading;
    const save = async () => {
        createChannel(MakeChannelCreateMutationOptions({
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            channel: {
                name,
                membership_id: membershipId,
                members: selectedMembers,
                description
            }
        })).then(result => {
            if (result) {
                const newChannelId = result.data?.createChannel?._id
                if (newChannelId) {
                    history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${newChannelId}`)
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const content = (
        <>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('header')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('headerDescription')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('comingSoon')}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormLink isExternal={true} to="https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#user-created-channels" target="_blank">
                        {lt('learnMore')}
                    </FormLink>
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} isLastStep />
            <Step4Help />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    comingSoon: string,
    name: string,
    add: string,
    membershipWarning: string,
    signer: string,
    learnMore: string
}
const enTranslations: translations = {
    header: 'Choose a Policy',
    headerDescription: 'Decide how you want your channel endorsement policy set up.',
    comingSoon: 'Support for choosing different policies are coming soon. The channel has been set up with the policies described in the documentation.',
    name: 'Membership Name',
    add: 'Add',
    membershipWarning: 'For reliable operation of your blockchain, we strongly recommend having additional memberships and nodes.',
    signer: 'Signer',
    learnMore: 'View documentation'
}
