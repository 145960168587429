import React, { useMemo, useState, useEffect } from 'react';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { Step1 } from './Step1';
import { useQuery, useMutation } from '@apollo/client';
import { ConfigData, ConfigQuery, UpdateConfigVars, UpdateConfigMutation } from '../../../models/configs';
import { EnvironmentResourceVars, Config } from '../../../interfaces';
import { useParams, Redirect } from 'react-router-dom';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../../../components/MainNav/SideNavs/Security';
import { BAFConfig, BAFPolicy, BAFPolicyAppCredsOrJWT } from '../BAFPolicySchema';
import { CircularProgress } from "@material-ui/core";

export const BAFAuthSettingsEditor = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFAuthSettingsEditor', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFAuthSettingsEditor:${key}`, interpolate);

    const {consortium_id, environment_id, config_id, org_id, auth_type} = useParams<any>();
    const notFoundPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`;
    const cancelPath = `${notFoundPath}/${config_id}`;

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [updateConfig, { loading: configSaving }] = useMutation<Config, UpdateConfigVars>(UpdateConfigMutation)

    const {
        loading: configsLoading,
        data: {
            config
        } = { config: null }
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: config_id!
        },
    });

    const [ version, setVersion ] = useState('')
    const [ authConfig, setAuthConfig ] = useState(undefined as BAFPolicyAppCredsOrJWT | undefined)

    const bafConfig: BAFConfig | undefined = useMemo(() => {
        if (config) {
            return {
                ...config,
                details: JSON.parse(config.details.baf_policy!) as BAFPolicy,
            }
        }
    }, [config])

    const appCredsOrJWT : 'appcreds' | 'jwt' | undefined =
        (auth_type === 'appcreds') ? 'appcreds' : (auth_type === 'jwt') ? 'jwt' : undefined;

    useEffect(() => {
        if (bafConfig) {
            let newVersion = `${config_id}-${config?._revision}-jwt`;
            if (version !== newVersion) {
                setVersion(newVersion);
                setAuthConfig(bafConfig.details[appCredsOrJWT!] || {})
            }
        }
    }, [version, bafConfig, config, config_id, appCredsOrJWT]);

    if(!configsLoading && !(config && appCredsOrJWT)) return <Redirect to={notFoundPath}/>

    if (!config || !authConfig || !appCredsOrJWT) return <CircularProgress />

    const save = async () => {
        if (appCredsOrJWT === 'jwt') {
            bafConfig!.details.jwt = {
                enabled: authConfig?.enabled ? true : false,
                bearer: authConfig?.bearer,
                cookie: authConfig?.cookie,
                query: authConfig?.query,
                header: authConfig?.header,
                mappings: authConfig?.mappings || [],
            };
        } else {
            bafConfig!.details.appcreds = {
                enabled: authConfig?.enabled ? true : false,
                basicAuth: authConfig?.basicAuth,
                bearer: authConfig?.bearer,
                cookie: authConfig?.cookie,
                query: authConfig?.query,
                header: authConfig?.header,
                mappings: authConfig?.mappings || [],
            };
        }
        await updateConfig({
            variables: {
                ...queryVariables,
                id: config_id!,
                configuration: {
                    name: bafConfig!.name,
                    details: {
                        baf_policy: JSON.stringify(bafConfig!.details),
                    }
                }
            }
        })
    }

    const loading = configsLoading || configSaving;

    const stepComponents = [{
        step: '',
        component: <Step1 authConfig={authConfig!} {...{setAuthConfig}} {...{cancelPath}} {...{loading}} {...{save}} {...{appCredsOrJWT}}/>
    }]

    return (
        <FullScreenCreate cancelPath={cancelPath} {...{loading}} toolbarHeader={lt('header')} {...{stepComponents}} stepUrlParam={'1'} hideStepper />
    )
}

interface translations {
    header: string
}

const enTranslations: translations = {
    header: 'Application Credentials'
}