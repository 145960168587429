import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { Grid, Typography, CircularProgress, TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LedgerContractQuery, LedgerContractData, AddressTransactionsData, AddressTransactionsVars, AddressTransactionsQuery } from '../../models';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { APPS_GATEWAY_APIS_PATH, APPS_BASE_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';
import { EnvironmentResourceVars } from '../../interfaces';
import { TransactionsTable } from '../../components/Transactions/TransactionsTable';
import { FormLink } from '../../components/FormControls/FormLink';
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TOKENS_PATH } from '../../components/TokenExplorerNav/TokenExplorerNav';
import { NoGatewayAPI } from '../../components/Banners/NoGatewayAPI';

const LIMITLIST = [10, 25];

export const ContractInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractInfo:${key}`, interpolate);

    const [limit, setLimit] = useState(LIMITLIST[0]);
    const [currentPage, setCurrentPage] = useState(0);
    const [errorMessage, setErrorMessasge] = useState('');

    const {consortium_id, environment_id, contract_address, org_id} = useParams<any>();
    const { pathname } = useLocation()
    const redirectUrl = pathname.replace(`${contract_address}`, '');

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }
    
    const {
        loading,
        data: {
            ledgerContract
        } = {ledgerContract: null}
    } = useQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        variables: {
            ...environmentVariables,
            id: contract_address!
        },
        fetchPolicy: 'cache-first'
    });

    const {
        refetch: refetchTransactions,
        fetchMore,
        loading: transactionsLoading,
        data: {
            addressTransactions
        } = {addressTransactions: []}
    } = useQuery<AddressTransactionsData, AddressTransactionsVars>(AddressTransactionsQuery, {
        variables: {
            ...environmentVariables,
            address: contract_address!,
            limit
        }
    });

    if (loading && !ledgerContract) return <CircularProgress />;
    if (!ledgerContract) return <Redirect to={redirectUrl} />;

    const getType = () => {
        if (ledgerContract.isERC20) return lt('erc20')
        if (ledgerContract.isERC721) return lt('erc721');
        if (ledgerContract.isZKPToken) return lt('zkpToken');
        return lt('contract');
    };

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const copyableList : CopyableSetting[] = [
        {
            title: lt('type'),
            displayValue: getType(),
            disableCopy: true
        }, {
            title: lt('address'),
            displayValue: <ShortenedHash address={ledgerContract.address ?? ''} />,
            copyableValue: ledgerContract.address ?? ''
        },{
            title: lt('deployedBy'),
            displayValue: <ShortenedHash address={ledgerContract.creator ?? ''} /> ,
            copyableValue: ledgerContract.creator ?? ''
        },{
            title: lt('genesisTransaction'),
            displayValue: <FormLink to={`${basePath}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}/${ledgerContract.genesisTransaction}`}>
                            <Typography variant="body2">
                                {ledgerContract.genesisTransaction}
                            </Typography>
                        </FormLink>,
            copyableValue: ledgerContract.genesisTransaction ?? ''
        }];

    if (ledgerContract.isERC721 || ledgerContract.isERC20) {
        copyableList.push({
            title: lt('tokenDetails'),
            displayValue: <FormLink to={`${basePath}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TOKENS_PATH}/${ledgerContract._id}`}>
                            <Typography variant="body2">
                                {ledgerContract.tokenName}
                            </Typography>
                        </FormLink>,
            disableCopy: true
        })
    }

    if (ledgerContract.instanceEndpointId) {
        copyableList.push({
            title: lt('instanceEndpoint'),
            displayValue: ledgerContract.instanceEndpointId
        })
    }

    if (ledgerContract.gatewayAPIId) {
        copyableList.push({
            title: lt('gatewayAPIId'),
            displayValue:
                <FormLink to={`${basePath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${ledgerContract.gatewayAPIId}`}>
                    {ledgerContract.gatewayAPIEndpoint ?? ledgerContract.gatewayAPIId}
                </FormLink>,
            copyableValue: ledgerContract.gatewayAPIId ?? ''
        })
    }

    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        try {
            if ( page > currentPage && (page * limit) >= addressTransactions.length) {
                await fetchMore({
                    variables: {
                        start: page * limit
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        return {addressTransactions: [...prev.addressTransactions, ...fetchMoreResult.addressTransactions]}
                    }
                })
            }
            setCurrentPage(page);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    }

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCurrentPage(0)
        setLimit(parseInt(event.target.value));
    }

    const Pagination = <TablePagination rowsPerPageOptions={LIMITLIST} component="div" count={ledgerContract.transactionCount ?? -1} rowsPerPage={limit} onChangeRowsPerPage={onChangeRowsPerPage} onPageChange={onChangePage} page={currentPage} />;

    const onRefreshClick = () =>{
        setCurrentPage(0);
        refetchTransactions();
    }

    const toolBarOptions = {
        refresh: {
            active: true,
            onRefresh: onRefreshClick
        }
    };

    return (
        <>
            <MessageSnackbar setMessage={setErrorMessasge} message={errorMessage} />
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('header')}</Typography>
                </Grid>
                { !ledgerContract.gatewayAPIId && 
                    <Grid item>
                        <NoGatewayAPI {...{ledgerContract}} />
                    </Grid>
                }
                <Grid item>
                    <CopyableSettings header={lt('header')} {...{copyableList}} />
                </Grid>
                <Grid item>
                    {transactionsLoading && addressTransactions.length === 0 ? <CircularProgress /> : (
                        <TransactionsTable transactions={addressTransactions.slice(currentPage * limit, currentPage * limit + limit)} pagination={Pagination} {...{toolBarOptions}} />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    header: string
    bytecode: string
    gatewayAPIId: string
    genesisTransaction: string
    contractName: string
    deployedBy: string
    address: string
    type: string,
    erc20: string,
    erc721:string,
    status: string,
    zkpToken: string,
    contract: string
    tokenDetails: string
    instanceEndpoint: string
}

const enTranslations: translations = {
    tokenDetails: 'Token Details',
    header: 'Contract Info',
    bytecode: 'ByteCode',
    gatewayAPIId: 'Gateway API Endpoint',
    genesisTransaction: 'Genesis Transaction',
    contractName: 'Contract Name',
    deployedBy: 'Deployed By',
    address: 'Address',
    type: 'Type',
    erc20: 'ERC 20 Token',
    erc721: 'ERC 721 Token',
    zkpToken: 'ZKP Token',
    contract: 'Contract',
    status: 'Status',
    instanceEndpoint: 'Instance Endpoint'
}