import { XAxisOptions } from 'highcharts';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RuntimeSize, DOCUMENTSTORE_LIMITS } from '../../../models';
import { BrandColors } from '../../../utils/Colors';
import { LineChart } from '../LineChart';

interface ChunksMetrics {
    id: string,
    metrics: {
        time: string
        chunks_sent: number
        chunks_received: number
    }[]
}

interface Props {
    xAxis: XAxisOptions,
    allRuntimes: { _id: string, name: string, size: RuntimeSize }[],
    runtimesForChart: ChunksMetrics[],
    height?: string,
    service: 'documentstore' | 'app2app'
};

export const ChunksSent = ({ xAxis, allRuntimes, runtimesForChart, height, service }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsDocumentStoreChunksSent', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`ChartsDocumentStoreChunksSent:${key}`, interpolate), [t])

    const SM_LIMIT = service === 'documentstore' ? DOCUMENTSTORE_LIMITS.chunks.small : 10000
    const MD_LIMIT = service === 'documentstore' ? DOCUMENTSTORE_LIMITS.chunks.medium : 50000
    const TICK_INTERVAL = service === 'documentstore' ? 200 : 20000

    let max = 0
    let count = 0

    const getValue = (time: string, chunksSent: number): [number, number] => {
        count += chunksSent
        if (count > max) max = count
        return [Date.parse(time), count]
    }

    const getLimit = (s: RuntimeSize) => {
        if (s === 'medium') return MD_LIMIT
        return SM_LIMIT
    }

    const getColor = (s: RuntimeSize) => {
        if (s === 'large') return BrandColors.cyan
        if (s === 'medium') return BrandColors.magenta
        return BrandColors.blurple
    }

    const series = runtimesForChart.map(r => {
        count = 0
        const runtime = allRuntimes.find(runtime => runtime._id === r.id)!
        const values = r.metrics.filter(m => new Date(m.time) < new Date()).map(d => getValue(d.time, d.chunks_sent))
        const nameSuffix =  `(${count}/${getLimit(runtime.size)})`
        return { 
            name: `${runtime.name} ${nameSuffix}`, 
            values: values,
            color: getColor(runtime.size)
        }
    })

    const extraHCOptions: Highcharts.Options = {
        time: {
            useUTC: true
        },
        xAxis: { ...xAxis, ...{ title: { text: '' } }},
        yAxis: {
            min: 0,
            max: Math.max(MD_LIMIT, max),
            tickInterval: TICK_INTERVAL,
            plotLines:[{
                color: getColor('small'),
                value: SM_LIMIT,
                label: {
                    align: 'right',
                    y: 12,
                    text: lt('smallLimit')
                },
                width: 2
            }, {
                color: getColor('medium'),
                value: MD_LIMIT,
                label: {
                    align: 'right',
                    y: 12,
                    text: lt('mediumLargeLimit')
                },
                width: 2
            }]
        }
    }

    return <LineChart showLegend title={lt('chart')} series={series} height={height} {...{extraHCOptions}} />
};

interface translations {
    chart: string
    smallLimit: string
    mediumLargeLimit: string
}
const enTranslations: translations = {
    chart: 'Chunks Sent',
    smallLimit: 'Small Limit',
    mediumLargeLimit: 'Medium Limit'
}