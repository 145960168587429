import React from 'react';
import { useParams, useLocation, Redirect} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { TransactionQuery, TransactionData, TransactionVars, TransferEvent, Transaction } from '../../models';
import { useTranslation } from 'react-i18next';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_BLOCKS_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import moment from 'moment';
import { FormLink } from '../../components/FormControls/FormLink';


export const TransferInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransferInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransferInfo:${key}`, interpolate);

    const {environment_id, consortium_id, transfer_hash, org_id} = useParams<any>();

    const { pathname } = useLocation()
    const redirectUrl = pathname.replace(`${transfer_hash}`, '');

    const {
        loading,
        data: {
            transaction
        } = {transaction: null}
    } = useQuery<TransactionData, TransactionVars>(TransactionQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            hash: transfer_hash!
        }
    });

    if (loading && !transaction) return <CircularProgress />;
    if (!transaction || (!transaction.isERC721 && !transaction.isERC20)) return <Redirect to={redirectUrl} />;

    const transferEvents = transaction.events ? transaction.events as TransferEvent[] : [];

    const transferTo = (transaction: Transaction) => {
        if(transaction.tokenInfo?.burned) return lt('burned');
        return transferEvents.length !== 0 ? <ShortenedHash address={transferEvents[0].to} /> : '--' 
    }

    const transferFrom = (transaction: Transaction) => {
        if(transaction.tokenInfo?.minted) return lt('minted')
        return transferEvents.length !== 0 ? <ShortenedHash address={transferEvents[0].from} /> : '--' 
    }

    const copyableList : CopyableSetting[] = [
        {
            title: lt('tokenName'),
            displayValue: transaction.tokenInfo?.tokenDetails.tokenName ?? '',
            copyableValue: transaction.tokenInfo?.tokenDetails.tokenName ?? ''
        }, {
            title: lt('tokenSymbol'),
            displayValue: transaction.tokenInfo?.tokenDetails.tokenSymbol ?? '',
            copyableValue: transaction.tokenInfo?.tokenDetails.tokenSymbol ?? ''
        }
    ];

    if (transferEvents[0]?.tokenId) {
        copyableList.push({
            title: lt('tokenId'),
            displayValue:  transferEvents[0].tokenId,
            copyableValue: transferEvents[0].tokenId
        })
    }

    copyableList.push({
        title: lt('tokenAddress'),
        displayValue: transaction.to ? <ShortenedHash address={transaction.to} /> : '--',
        copyableValue: transaction.to
    }, {
        title: lt('from'),
        displayValue: transferFrom(transaction),
        copyableValue: transferEvents ? transferEvents[0].from : '--'
    }, {
        title: lt('to'),
        displayValue: transferTo(transaction),
        copyableValue: transferEvents ? transferEvents[0].to : '--'
    }, {
        title: lt('transactionCreator'),
        displayValue: transaction.from ? <ShortenedHash address={transaction.from} /> : '--',
        copyableValue:  transaction.from ?? '--'
    });

    if (transferEvents[0]?.value) {
        copyableList.push({
            title: lt('amount'),
            displayValue: transferEvents[0].value,
            copyableValue: transferEvents[0].value
        })
    }

    copyableList.push({
        title: lt('block'),
        displayValue: <FormLink children={transaction.blockNumber?.toString() ?? ''} to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}/${transaction.blockNumber?.toString()}`}/>,
        copyableValue: transaction.blockNumber?.toString() ?? ''
    },{
        title: lt('transactionHash'),
        displayValue: <FormLink children={transaction.hash} to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}/${transaction.hash}`} />,
        copyableValue: transaction.hash ?? ''
    },{
        title: lt('timestamp'),
        displayValue: transaction.timestamp ? `${new Date(transaction.timestamp).toLocaleString()} (${moment(transaction.timestamp).fromNow()})` : '--',
        copyableValue: transaction.timestamp ?? '',
        disableCopy: true
    })

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('transferInfo')}</Typography>
            </Grid>
            <Grid item>
                <CopyableSettings header={lt('transfer', {transfer: transfer_hash})} {...{copyableList}} />
            </Grid>
        </Grid>
    )
}

interface translations {
    transactionHash: string
    block: string
    from: string
    to: string
    timestamp: string
    gas: string
    inputData: string
    contractAddress: string
    bytes: string
    ascii: string
    transferInfo: string
    transfer: string
    bytecode: string
    hex: string
    hexViewer: string
    method: string
    inputs: string
    logs: string
    status: string
    amount: string
    tokenAddress: string
    tokenName: string
    tokenSymbol: string
    tokenId: string
    transactionCreator: string
    minted: string
    burned: string
}

const enTranslations: translations = {
    transactionHash: 'Transaction Hash',
    block: 'Block',
    contractAddress: 'Contract Address',
    from: 'From',
    to: 'To',
    timestamp: 'Timestamp',
    gas: 'Gas',
    inputData: 'Input Data',
    bytes: 'Bytes',
    ascii: 'Ascii',
    transferInfo: 'Transfer Info',
    transfer: 'Transfer - {{transfer}}',
    bytecode: 'Bytecode',
    hex: 'Hex',
    hexViewer: 'Hex Viewer',
    method: 'Method',
    inputs: 'Inputs',
    logs: 'Logs (Events)',
    status: 'Status',
    amount: 'Amount',
    tokenAddress: 'Token Address',
    tokenSymbol: 'Token Symbol',
    tokenName: 'Token Name',
    tokenId: 'Token ID',
    transactionCreator: 'Transaction Creator',
    minted: 'Minted',
    burned: 'Burned'
}