import React from 'react';
import { 
    ListItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import { StyledBadge } from '../AppWrapper/StyledBadge';

interface Props {
    icon: JSX.Element,
    header: string,
    action: () => void,
    itemIsActive?: boolean,
    comingSoon?: boolean,
    badgeContent?: React.ReactNode,
    disabled?: boolean
};

export const NavItem = ({ disabled, icon, header, action, itemIsActive, comingSoon, badgeContent = 0 }: Props) => {
    const classes = useStyles();

    const onClick = () => {
        if (!itemIsActive && action) {
            action()
        }
    }

    return (
        <>
            <ListItem disabled={disabled} button onClick={onClick} selected={itemIsActive} className={itemIsActive ? classes.itemIsActive : ''}>
                <ListItemIcon className={`${classes.listItemIcon} ${itemIsActive ? classes.itemIsActive : ''}`}>
                    {icon}
                </ListItemIcon>
                <StyledBadge
                    badgeContent={badgeContent}
                    relativePosition
                    classes={{ badge: classes.badge }}
                >
                    <ListItemText 
                        primary={comingSoon ? <i>{header}</i> : header} 
                        primaryTypographyProps={{variant: "body2"}} 
                    />
                </StyledBadge>
            </ListItem>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    itemIsActive: {
        color: theme.palette.primary.main
    },
    listItemIcon: {
        minWidth: 0,
        paddingRight: theme.spacing(1)
    },
    badge: {
        top: 14
    }
}));