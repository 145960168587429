import { useQuery } from '@apollo/client';
import { Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import WalletTravelIcon from 'mdi-react/WalletTravelIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DisplayCard, DisplayTable, EmptyState } from '../../components/DisplayWrappers';
import { FetchAccount } from '../../components/HDWallet/FetchAccount';
import { EnvironmentResourceVars, ServiceResourcesVars } from '../../interfaces';
import { ServiceData, ServiceQuery } from '../../models';
import { HDWallet, HDWalletsData, HDWalletsQuery } from '../../models/hdwallet';
import { CreateHDWallet } from './CreateHDWallet';
import { HDWalletInfo } from './HDWalletInfo';

export const HDWallets = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'HDWallets', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`HDWallets:${key}`, interpolate)

    const docsLink = 'https://docs.kaleido.io/kaleido-services/hdwallet/'

    const history = useHistory()
    const { org_id, consortium_id, environment_id, service_id } = useParams<{org_id: string, consortium_id: string, environment_id: string, service_id: string}>();

    const [walletsQueryFailed, setWalletsQueryFailed] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)
    const [walletId, setWalletId] = useState('')
    const [createdHDWallet, setCreatedHDWallet] = useState<HDWallet>()
    
    const {
        data: { 
            service
        } = { service: null } 
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, { 
        variables: { 
            consortia_id: consortium_id,
            environment_id: environment_id,
            id: service_id
        },
        fetchPolicy: 'cache-only'
    });

    const {
        refetch,
        loading: hdwalletsLoading,
        data: { 
            hdwallets
        } = { hdwallets: null } 
    } = useQuery<HDWalletsData, ServiceResourcesVars>(HDWalletsQuery, { 
        variables: { 
            service_id: service_id
        },
        fetchPolicy: 'cache-and-network',
        skip: service?.state !== 'started',
        onError: () => setWalletsQueryFailed(true)
    });
    
    const serviceStarted = service?.state === 'started'

    const upgradeNeeded = serviceStarted && walletsQueryFailed

    const columnHeaders = [
        '',
        lt('walletId'),
    ]

    const records = hdwallets?.wallets.map(w => {
        return {
            key: w,
            onClick: () => setWalletId(w),
            columns: [
                {isIcon: true, value: <WalletTravelIcon />},
                {value: w}
            ]
        }
    })
    
    const loading = hdwalletsLoading && !hdwallets?.wallets.length;

    if (loading) return <CircularProgress />;

    const showEmptyState = !hdwalletsLoading && !hdwallets?.wallets.length

    const onHDWalletCreated = (h: HDWallet) => {
        refetch()
        setCreatedHDWallet(h)
    }

    const docsList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('intro'),
            value: lt('introDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/hdwallet/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('api'),
            value: lt('apiDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://api.kaleido.io/hdwallet.html')
        },
    ]

    return (
        <>
            <FetchAccount serviceId={service_id} {...{walletId}} {...{setWalletId}} />
            <CreateHDWallet open={createOpen} setOpen={setCreateOpen} onHDWalletCreated={onHDWalletCreated} />
            <HDWalletInfo hdwallet={createdHDWallet} />

            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('hdwallets')}
                        </Typography>
                    </Grid>
                    {!showEmptyState && 
                    <Grid item>
                        <Button color="primary" variant="contained" size="large" onClick={() => setCreateOpen(true)} disabled={!serviceStarted}>
                            {lt('newHDWallet')}
                        </Button>
                    </Grid>
                    }
                </Grid>

                {upgradeNeeded && 
                <Grid item>
                    <EmptyState imageFile='Empty-HDWallet.svg' 
                        title={lt('upgradeNeeded')} 
                        description={lt('upgradeDescription')} 
                        button={{ text: lt('goToDashBoard'), onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`) }}
                        documentation={{ text: lt('emptyDocumentation'), link: docsLink }} />
                </Grid> 
                }

                {!upgradeNeeded &&
                <Grid item container spacing={3}>
                    <Grid item lg={!showEmptyState ? 8 : undefined} xs={12}>
                        {showEmptyState ? 
                        <EmptyState imageFile='Empty-HDWallet.svg' 
                            title={lt('emptyTitle')} 
                            description={lt('emptyDescription')} 
                            button={{ text: lt('emptyGoTo'), onClick: () => setCreateOpen(true), disabled: !serviceStarted }}
                            documentation={{ text: lt('emptyDocumentation'), link: docsLink }} /> :
                        <DisplayTable {...{loading}} header={lt('header')} description={lt('description')} {...{columnHeaders}} {...{records}} />
                        }
                    </Grid>
                    {!showEmptyState && 
                    <Grid item lg={4} xs={12}>
                        <DisplayCard header={lt('documentation')} itemList={docsList} />
                    </Grid> 
                    }
                </Grid> 
                }
            </Grid>

        </>
    )
};

interface translations {
    header: string,
    description: string,
    emptyTitle: string,
    emptyDescription: string,
    emptyGoTo: string,
    emptyDocumentation: string,
    hdwallets: string,
    newHDWallet: string,
    walletId: string,
    intro: string,
    introDescription: string,
    api: string,
    apiDescription: string,
    documentation: string,
    upgradeDescription: string,
    upgradeNeeded: string,
    goToDashBoard: string
}
const enTranslations: translations = {
    header: 'HD Wallets',
    description: 'The following HD Wallets have been created. Select one to view it\'s signing keys.',
    emptyTitle: 'Create an HD Wallet',
    emptyDescription: 'Create your first HD Wallet to anonymize identity via unique signing keys.',
    emptyGoTo: 'Create HD Wallet',
    emptyDocumentation: 'Documentation',
    hdwallets: 'HD Wallets',
    newHDWallet: 'New HD Wallet',
    walletId: 'ID',
    intro: 'Intro to HD Wallets',
    introDescription: 'Anonymize via unique signing keys',
    api: 'API Documentation',
    apiDescription: 'API reference for Kaleido',
    documentation: 'Documentation',
    upgradeDescription: 'You will need to upgrade your environment to view the new HD Wallets Dashboard.',
    upgradeNeeded: 'Upgrade Needed',
    goToDashBoard: 'Go to Environment Dashboard',
}