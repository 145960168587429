import React from 'react';
import { 
    ListItem,
    makeStyles,
    ListItemText
} from '@material-ui/core';
import { LeftNavTab } from './SubNavs'
import { NavPopoverItem } from './NavPopoverItem'

interface Props {
    item: LeftNavTab,
    itemIsActive: boolean
};

export const SubNavItem = ({ item, itemIsActive }: Props) => {
    const classes = useStyles();

    const onClick = () => {
        if (!itemIsActive && item.action) {
            item.action()
        }
    }

    return (
        <>
            {item.popoverItems ?
            <NavPopoverItem {...{item}} {...{itemIsActive}} /> :
            <ListItem selected={itemIsActive} disabled={item.disabled} button {...{onClick}}
                className={itemIsActive ? classes.itemIsActive : classes.nested}>
                <ListItemText 
                    primary={item.comingSoon ? <i>{item.name}</i> : item.name} 
                    primaryTypographyProps={{variant: "body2"}} />
            </ListItem>
            }
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    itemIsActive: {
        color: theme.palette.primary.main,
        borderLeft: 'solid',
        borderWidth: theme.spacing(1),
        paddingLeft: theme.spacing(5)
    },
    nested: {
        paddingLeft: theme.spacing(6)
    },
    selectedIcon: {
        color: theme.palette.primary.main,
        minWidth: theme.spacing(4),
        width: theme.spacing(4),
        paddingLeft: theme.spacing(0)
    }
}));