import { useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { ErrorSnackbarCatcher, FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { FormLink } from '../../components/FormControls/FormLink';
import { OPS_MANAGE_ORG_PATH, OPS_PATH } from '../../components/OpsNav/OpsNav';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { EnvironmentProvider, FeaturesEnum, OpsEnsureStripeCustomerData, OpsEnsureStripeCustomerMutation, OpsOrganizationData, OpsOrganizationQuery, OpsSupplementPlanData, OpsSupplementPlanInputs, OpsSupplementPlanMutation, OpsUpdateOrganizationMutation, OpsUpdateOrganizationVars, Organization, OrganizationVars, RuntimeSize, ServicesEnum } from '../../models';
import { ConfigTypes } from '../../models/configs';
import { flatten } from './OpsTable';
import { OpsUpdateOrg } from './OpsUpdateOrg';
import { OrgTriggerInvoice } from './OrgTriggerInvoice';

export const Org: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsOrg', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsOrg:${key}`, interpolate)

    const {ops_org_id} = useParams<any>();

    const [message, setMessage] = useState('');
    const [supplementPlanOpen, setSupplementPlanOpen] = useState(false);
    const [triggerInvoiceOpen, setTriggerInvoiceOpen] = useState(false);
    const [updateOidcOpen, setUpdateOidcOpen] = useState(false);
    const [updateOrgOpen, setUpdateOrgOpen] = useState(false);
    const [nodeSizes, setNodeSizes] = useState('');
    const [services, setServices] = useState('');
    const [serviceSizes, setServiceSizes] = useState('');
    const [configurations, setConfigurations] = useState('');
    const [providers, setProviders] = useState('');
    const [features, setFeatures] = useState('');
    
    // oidc details
    const [type, setType] = useState('');
    const [url, setUrl] = useState('');
    const [alias, setAlias] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    const [supplementPlanMutation, {loading: supplementing}] = useMutation<OpsSupplementPlanData, OpsSupplementPlanInputs>(OpsSupplementPlanMutation);
    const [ensureStripeCustomerMutation] = useMutation<OpsEnsureStripeCustomerData, OrganizationVars>(OpsEnsureStripeCustomerMutation);

    const [opsUpdateOrganizationMutation, {loading: updatingOidc}] = useMutation<Organization, OpsUpdateOrganizationVars>(OpsUpdateOrganizationMutation);

    const {
        data: {
            opsOrganization: org
        } = { opsOrganization: null },
        loading,
        refetch,
        error: fetchError, 
    } = useQuery<OpsOrganizationData>(OpsOrganizationQuery, {
        variables: {
            id: ops_org_id,
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (org) {
            setType(org.type)
            setUrl(org.oidc_details?.url || '')
            setAlias(org.oidc_details?.alias || '')
            setClientId(org.oidc_details?.client_id || '')
            setClientSecret(org.oidc_details?.client_secret || '')
        }
    }, [org])

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    if (loading) return <CircularProgress />;
    if (!org) return <Redirect to={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}`} />;

    const copyableList : CopyableSetting[] = [];
    for (let [k,v] of Object.entries(flatten(org))) {
        if (k.includes('stripe_customer_id') && v) {
            copyableList.push({
                title: k,
                displayValue: <FormLink to={`https://dashboard.stripe.com/customers/${v}`} isExternal={true} target='_blank'>{`${v}`}</FormLink>
            })    

        } else {
            copyableList.push({
                title: k,
                displayValue: k.includes('secret') ? <PasswordInput fullWidth size='small' variant='outlined' value={v} /> : v,
                copyableValue: k.includes('secret') ? v : undefined
            })    
        }
    }

    const supplementPlanSave = async () => {
        try {
            await supplementPlanMutation({
                variables: {
                    org_id: ops_org_id,
                    supplements: {
                        services: services ? services.split(',') as (keyof typeof ServicesEnum)[]: undefined,
                        service_sizes: serviceSizes ? serviceSizes.split(',') as RuntimeSize[]: undefined,
                        configurations: configurations ? configurations.split(',') as ConfigTypes[]: undefined,
                        node_sizes: nodeSizes ? nodeSizes.split(',') as RuntimeSize[]: undefined,
                        providers: providers ? providers.split(',') as EnvironmentProvider[]: undefined,
                        features: features ? features.split(',') as (keyof typeof FeaturesEnum)[]: undefined,
                    }
                }
            });
            refetch();
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    const updateOidcSave = async () => {
        try {
            await opsUpdateOrganizationMutation({
                variables: {
                    id: ops_org_id,
                    opsUpdates: {
                        type,
                        oidc_details: {
                            url,
                            alias: alias || undefined,
                            client_id: clientId,
                            client_secret: clientSecret
                        }
                    }
                }
            });
            refetch();
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    const ensureStripeCustomerSave = async () => {
        try {
            await ensureStripeCustomerMutation({
                variables: {
                    id: ops_org_id,
                }
            });
            refetch();
        }
        catch(err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    const actionBar = (
        <Grid container alignItems="center" spacing={3} wrap="nowrap">
            {!org.billing_account?.stripe_customer_id && <Grid item>
                <Button variant="contained" color="primary" onClick={ensureStripeCustomerSave}>
                    {lt('ensureStripeCustomer')}
                </Button>
            </Grid>}
            <Grid item>
                <Button variant="contained" color="primary" onClick={() => setUpdateOrgOpen(true)}>
                    {lt('updateOrg')}
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={() => setTriggerInvoiceOpen(true)}>
                    {lt('invoice')}
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={() => setSupplementPlanOpen(true)}>
                    {lt('supplementPlan')}
                </Button>
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={() => setUpdateOidcOpen(true)}>
                    {lt('updateOidc')}
                </Button>
            </Grid>
        </Grid>
    )

    const supplementPlanDialog = <FormDialog
        open={supplementPlanOpen}
        setOpen={setSupplementPlanOpen}
        header={lt('supplementPlan')}
        onSave={supplementPlanSave}
        saveDisabled={supplementing}
        closeDialogAfterSave={true}
        controlsWrapper={<Grid container direction="column" spacing={1}>
            <Grid item>
                <TextField fullWidth variant="outlined" value={providers} onChange={e => setProviders(e.target.value)} label={lt('providers')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={nodeSizes} onChange={e => setNodeSizes(e.target.value)} label={lt('nodeSizes')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={services} onChange={e => setServices(e.target.value)} label={lt('services')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={serviceSizes} onChange={e => setServiceSizes(e.target.value)} label={lt('serviceSizes')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={configurations} onChange={e => setConfigurations(e.target.value)} label={lt('configurations')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={features} onChange={e => setFeatures(e.target.value)} label={lt('features')}/>
            </Grid>
        </Grid>}
    />;

    const updateOidcDialog = <FormDialog
        open={updateOidcOpen}
        setOpen={setUpdateOidcOpen}
        header={lt('updateOidc')}
        onSave={updateOidcSave}
        saveDisabled={updatingOidc}
        closeDialogAfterSave={true}
        controlsWrapper={<Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="body2">{lt('typeDescription')}</Typography>
            </Grid>
            <Grid item>
                <TextField select style={{ width: 400 }} 
                    label={lt('type')} 
                    value={type} 
                    variant="outlined" 
                    onChange={e => setType(e.target.value)}>
                        <MenuItem value={'kaleido'}>{'kaleido'}</MenuItem>
                        <MenuItem value={'oidc'}>{'oidc'}</MenuItem>
                </TextField>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={url} onChange={e => setUrl(e.target.value)} label={lt('url')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={alias} onChange={e => setAlias(e.target.value)} label={lt('alias')}/>
            </Grid>
            <Grid item>
                <TextField fullWidth variant="outlined" value={clientId} onChange={e => setClientId(e.target.value)} label={lt('clientId')}/>
            </Grid>
            <Grid item>
                <PasswordInput fullWidth variant="outlined" value={clientSecret} onChange={e => setClientSecret(e.target.value)} label={lt('clientSecret')} />
            </Grid>
        </Grid>}
    />;

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        {supplementPlanDialog}
        {updateOidcDialog}
        <OpsUpdateOrg open={updateOrgOpen} setOpen={setUpdateOrgOpen} organization={org} onSave={refetch}/>
        <OrgTriggerInvoice open={triggerInvoiceOpen} setOpen={setTriggerInvoiceOpen} orgId={ops_org_id}/>
        <Grid container direction="column" spacing={3}>
            <Grid item>
               <CopyableSettings
                    header={`${org.name} (${org._id})`}
                    {...{copyableList}}
                    {...{actionBar}}/>
            </Grid>
        </Grid>
    </>;
};

interface translations {
    supplementPlan: string;
    ensureStripeCustomer: string;
    nodeSizes: string;
    services: string;
    serviceSizes: string;
    configurations: string;
    providers: string;
    features: string;
    updateOrg: string;
    invoice: string;
    updateOidc: string
    type: string
    url: string
    alias: string
    clientId: string
    clientSecret: string
    typeDescription: string
}
const enTranslations: translations = {
    supplementPlan: 'Supplement Plan',
    ensureStripeCustomer: 'Ensure Stripe Customer',
    nodeSizes: 'Node Sizes',
    services: 'Service Types',
    serviceSizes: 'Service Sizes',
    configurations: 'Configurations',
    providers: 'Providers',
    features: 'Features',
    updateOrg: 'Update',
    invoice: 'Invoice',
    updateOidc: 'Update OIDC Details',
    type: 'Type',
    url: 'URL',
    alias: 'Organization Alias',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    typeDescription: 'OIDC login is not enabled until the Type is set to "oidc".'
}
