import React from 'react';
import { TransactionSummary, TransferEvent } from '../../models';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { useHistory, useParams } from 'react-router-dom';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from '../BlockExplorerNav/BlockExplorerNav';
import { ShortenedString } from '../FormControls/ShortenedString';
import { ShortenedHash } from '../FormControls/ShortenedHash';
import { DisplayTable, ToolBarOptions } from '../DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { ResourceStateChip } from '../FormControls/ResourceStateChip';
import moment from 'moment';
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TRANSFERS_PATH } from '../TokenExplorerNav/TokenExplorerNav';


interface TransactionsColumnsTranslations {
    transaction: string
    from: string
    to: string
    dateMined: string
    status: string
    amount: string
    type: string
    token: string
}

export type TransactionsColumns = keyof TransactionsColumnsTranslations;

const defaultColumns: TransactionsColumns[] = ['transaction', 'status', 'from', 'to', 'dateMined']

interface Props {
    header?: string
    columns?: TransactionsColumns[]
    transactions: TransactionSummary[]
    pagination?: JSX.Element
    toolBarOptions?: ToolBarOptions
    isToken?: boolean
    setSuspendUpdates?: React.Dispatch<React.SetStateAction<boolean>>
    isLatest?: boolean // This is for latest transactiosn table on explroer dashboards where linkButton is needed
}
export const TransactionsTable = ({header, transactions, pagination, toolBarOptions, isToken, columns = defaultColumns, setSuspendUpdates, isLatest}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransactionsTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransactionsTable:${key}`, interpolate);
    
    const {consortium_id, environment_id, org_id} = useParams<any>();
    const history = useHistory();

    const historyPusher = (transaction?: string) => {
        if (isToken) {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TRANSFERS_PATH}${transaction ? `/${transaction}` : ''}`);
        } else {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}${transaction ? `/${transaction}` : ''}`);
        }
    };

    const returnTransferValue = (transaction: TransactionSummary) => {
        if (transaction.events) {
            const transferEvents = transaction.events as TransferEvent[];
            if(transferEvents[0].value) {
                return transferEvents[0]['value'];
            }
            if(transferEvents[0].tokenId) {
                return transferEvents[0]['tokenId'];
            }
        }
        return '--'
    }

    const transferTo = (transaction: TransactionSummary) => {
        if (transaction.events) {
            const transferEvents = transaction.events as TransferEvent[];
            if(transaction?.tokenInfo?.burned) return lt('burned');
            return transferEvents[0].to ? <ShortenedHash {...{setSuspendUpdates}} address={transferEvents[0].to} /> : '--' 
        }
        return '';
    }

    const transferFrom = (transaction: TransactionSummary) => {
        if (transaction.events) {
            const transferEvents = transaction.events as TransferEvent[];
            if(transaction?.tokenInfo?.minted) return lt('minted')
            return transferEvents[0].from ? <ShortenedHash {...{setSuspendUpdates}} address={transferEvents[0].from} /> : '--' 
        }
        return '';
    }

    const columnsHeaders = columns.map(column => lt(column));

    const columnMap = new Map<TransactionsColumns, (transaction: TransactionSummary) => number | string | JSX.Element>([
        ['transaction', (transaction: TransactionSummary) => <ShortenedString content={transaction.hash} hideCopy />],
        ['amount', (transaction: TransactionSummary) => isToken ? returnTransferValue(transaction) : '--'],
        ['token', (transaction: TransactionSummary) => <ShortenedHash {...{setSuspendUpdates}} address={transaction.to ?? ''} /> ],
        ['status', (transaction: TransactionSummary) => transaction.status === 'success' ? <ResourceStateChip state="success" /> : <ResourceStateChip state={transaction.status} />],
        ['from', (transaction: TransactionSummary) => isToken ? transferFrom(transaction) : <ShortenedHash {...{setSuspendUpdates}} address={transaction.from ?? ''} />],
        ['to', (transaction: TransactionSummary) => isToken ? transferTo(transaction) : transaction.to ? <ShortenedHash {...{setSuspendUpdates}} address={transaction.to} /> : '--'],
        ['dateMined', (transaction: TransactionSummary) => transaction.timestamp ? moment(transaction.timestamp).fromNow() : '--'],
        ['type', (transaction: TransactionSummary) => transaction.isERC20 ? lt('ERC20') : lt('ERC721')]
    ])

    const transactionsRecords : DisplayTableRecord[] = transactions.map((transaction, index) => ({
        key: `${index}-transactions`,
        columns: columns.map(column => ({
            value: columnMap.get(column)!(transaction)
        })),
        onClick: () => historyPusher(transaction.hash)
    }));

    const linkButton = {
        text: isToken ? lt('viewTransfers') : lt('viewTransactions'),
        onClick: () => historyPusher()
    }

    const getHeader = () => {
        if (header) return header;
        if (isLatest) return lt('latestTransactions');
        if (isToken) return lt('transfers');
        return lt('transactions')
    }

    return (
        <DisplayTable linkButton={isLatest ? linkButton : undefined} {...{toolBarOptions}} header={getHeader()} columnHeaders={columnsHeaders} records={transactionsRecords} actionFooter={pagination} />
    )
};

interface translations extends TransactionsColumnsTranslations {
    transactions: string
    transfers: string
    burned: string
    minted: string
    ERC20: string
    ERC721: string
    viewTransfers: string
    viewTransactions: string
    latestTransactions: string
}

const enTranslations: translations = {
    transactions: 'Transactions',
    transaction: 'Transaction',
    from: 'From',
    to: 'To',
    dateMined: 'Date Mined',
    status: 'Status',
    amount: 'Amount / Id',
    transfers: 'Transfers',
    token: 'Token',
    burned: 'Burned',
    minted: 'Minted',
    ERC20: 'ERC20',
    ERC721: 'ERC721',
    type: 'Type',
    viewTransfers: 'View Transfers',
    viewTransactions: 'View Transactions',
    latestTransactions: 'Latest Transactions'
}