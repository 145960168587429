import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Paper, Button, CircularProgress } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { CreateStepProps } from '../../interfaces';

interface Props extends CreateStepProps {
    content: JSX.Element,
    disabled: boolean,
    onNext: () => void,
    isFirstStep?: boolean,
    isLastStep?: boolean,
    customNextButtonLabel?: string,
    customCancelButtonLabel?: string,
    customSecondaryButtonLabel?: string,
    onSecondary?: () => void,
    onSecondaryDisabled?: boolean
    saving?: boolean,
    hideBack?: boolean,
    routerState?: {[key: string]: any}
    footerImg?: JSX.Element
    additionalContent?: JSX.Element
}

export const CreateWrapper = ({ content, disabled, cancelPath, onNext, isFirstStep, isLastStep, saving, hideBack, routerState, 
    customNextButtonLabel, customCancelButtonLabel, customSecondaryButtonLabel, onSecondary, onSecondaryDisabled, footerImg, additionalContent }: Props) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DialogWrappersCreateWrapper', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DialogWrappersCreateWrapper:${key}`, interpolate)

    const history = useHistory()

    const goBack = () =>
        isFirstStep && cancelPath
            ? history.push(cancelPath, routerState)
            : history.goBack();

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault() //to avoid re render on submit
        onNext();
    }

    return (
        <>
            <Grid item container xs={12} md={8} direction="column">
                <form onSubmit={e => handleOnSubmit(e)}>
                    <Paper className={classes.paper}>
                        <Grid item container direction="column" spacing={3}>
                            {content}
                            <Grid item container direction="row" justify="flex-end" spacing={3} className={classes.buttons} alignItems='center'>
                                {footerImg && (
                                <Grid item className={classes.footerImg}>
                                    {footerImg}
                                </Grid>
                                )}
                                { !hideBack && 
                                <Grid item>
                                    <Button color="default" size="large" onClick={goBack}>
                                        {customCancelButtonLabel || (isFirstStep ? lt('cancel') : lt('back'))}
                                    </Button>
                                </Grid>
                                }
                                { customSecondaryButtonLabel && onSecondary ? 
                                <Grid item>
                                    { saving ? <CircularProgress /> :
                                    <Button onClick={onSecondary} variant="text" color="default" size="large" disabled={onSecondaryDisabled}>
                                        {customSecondaryButtonLabel}
                                    </Button>
                                    }
                                </Grid> : null
                                }
                                <Grid item>
                                    { saving ? <CircularProgress /> :
                                    <Button data-test="button_submit" type="submit" variant="contained" color="primary" size="large" disabled={disabled}>
                                        {isLastStep ? customNextButtonLabel || lt('finish') : customNextButtonLabel || lt('next')}
                                    </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        { additionalContent }
                    </Paper>
                </form>
            </Grid>
        </>
    )
};

const useStyles = makeStyles(theme => ({
    footerImg: {
        marginRight: 'auto'
    },
    paper: {
        padding: theme.spacing(4, 5, 2, 5),
    },
    buttons: {
        marginBottom: theme.spacing(2)
    }
}));

interface translations {
    cancel: string, 
    back: string,
    next: string,
    finish: string
}
const enTranslations: translations = {
    cancel: 'Cancel',
    back: 'Back',
    next: 'Next',
    finish: 'Finish'
}