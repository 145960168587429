import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, ErrorSnackbarCatcher, MessageSnackbar } from '../../../components/DialogWrappers';
import { NETWORK_GOVERNANCE_MEMBERSHIPS_PATH, NETWORK_GOVERNANCE_PATH } from "../../../components/MainNav/SideNavs/Governance";
import { ConsortiumResourcesVars, CreateStepProps } from '../../../interfaces';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery, CreateInvitationData, CreateInvitationMutation, CreateInvitationVars, MembershipPermissions } from "../../../models";
import { Step3Help } from './Step3Help';

interface Props extends CreateStepProps {
    orgName: string
    email: string
};

export const Step3 = ({ orgName, email, cancelPath }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GovernanceInviteOrganizationCreateStep3', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GovernanceInviteOrganizationCreateStep3:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const {
        data: {
            consortiumMemberships
        } = { consortiumMemberships: [] }
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: {
            consortia_id: consortium_id
        },
        fetchPolicy: 'cache-only'
    });

    const myPermissions = consortiumMemberships.find(m => m.is_mine)?.permissions

    const [message, setMessage] = useState('')
    const [permissions, setPermissions] = useState<MembershipPermissions>({
        manage_envs: myPermissions?.manage_envs ?? false,
        invite_orgs: myPermissions?.invite_orgs ?? false,
        create_signers: myPermissions?.create_signers ?? false,
        multiple_members: myPermissions?.multiple_members ?? false,
        manage_contracts: myPermissions?.manage_contracts ?? false
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPermissions(prev => ({ ...prev, ...{ [event.target.value as keyof MembershipPermissions]: event.target.checked } }))
    };

    const [createInvitation, { loading: createInvitationLoading }] = useMutation<CreateInvitationData, CreateInvitationVars>(CreateInvitationMutation);

    const save = async () => {
        createInvitation({
            variables: {
                consortia_id: consortium_id!,
                invitation: {
                    email,
                    org_name: orgName,
                    permissions,
                    from_org_id: org_id
                }
            }
        }).then(() => {
            history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}`)
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const makePermissionsCheckbox = (p: keyof MembershipPermissions) => {
        const disabled = (myPermissions && myPermissions[p]) ? false : true
        const checked = permissions[p]

        return (
            <Grid item key={p}>
                <Paper className={checked ? classes.radioPaperSelected : classes.radioPaper} elevation={0}>
                    <Grid item container direction="column" spacing={1} className={disabled ? classes.disabled : ''}>
                        <FormControlLabel
                            control={<Checkbox disabled={disabled} color="primary" checked={checked} onChange={handleChange} value={p} />}
                            label={lt(p)}
                        />
                        <Typography className={classes.description} variant="body2" color="textSecondary" gutterBottom>
                            {lt(`${p}Description` as keyof translations)}
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const content = (
        <>
            <MessageSnackbar {...{ message }} {...{ setMessage }} />
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item container direction="column">
                <FormControl component="fieldset" margin="none">
                    <FormGroup>
                        <Grid item container direction="column" spacing={1} wrap="nowrap">
                            {makePermissionsCheckbox('create_signers')}
                            {makePermissionsCheckbox('manage_envs')}
                            {makePermissionsCheckbox('invite_orgs')}
                            {makePermissionsCheckbox('multiple_members')}
                            {makePermissionsCheckbox('manage_contracts')}
                        </Grid>
                    </FormGroup>
                </FormControl>
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {lt('orgName')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {orgName}
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body1">
                    {lt('email')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {email}
                </Typography>
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={createInvitationLoading} onNext={save} isLastStep customNextButtonLabel={lt('sendInvitation')} />
            <Step3Help />
        </>
    )
};

const useStyles = makeStyles(theme => ({
    radioPaper: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        borderWidth: '3px',
    },
    radioPaperSelected: {
        padding: theme.spacing(1, 2),
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
    },
    disabled: {
        opacity: '50%'
    },
    description: {
        marginLeft: theme.spacing(4)
    }
}));

interface translations {
    header: string
    headerDescription: string
    manage_envs: string
    invite_orgs: string
    create_signers: string
    multiple_members: string
    manage_contracts: string
    manage_envsDescription: string
    invite_orgsDescription: string
    create_signersDescription: string
    multiple_membersDescription: string
    manage_contractsDescription: string
    orgName: string
    email: string
    sendInvitation: string
}
const enTranslations: translations = {
    header: 'Permissions',
    headerDescription: 'Set the permissions of the newly invited member. These permissions can only be set during this step, and will only be revoked if the organization leaves this network.',
    manage_envs: 'Manage Environments',
    invite_orgs: 'Invite Organizations',
    create_signers: 'Create Signers',
    multiple_members: 'Multiple Memberships',
    manage_contracts: 'Manage Smart Contracts',
    manage_envsDescription: 'Create and upgrade environments',
    invite_orgsDescription: 'Invite other organizations to the consortium',
    create_signersDescription: 'Create signing nodes in environments',
    multiple_membersDescription: 'Create additional memberships for oneself',
    manage_contractsDescription: 'Promote managed smart contracts to environments',
    orgName: 'Organization Name',
    email: 'Email',
    sendInvitation: 'Send Invitation'
}