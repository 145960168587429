import React, { useState } from "react";
import {
    CreateWrapper,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../components/DialogWrappers";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormRadio } from "../../components/FormControls/FormRadio";
import CheckIcon from "mdi-react/CheckIcon";
import { useHistory, useParams } from "react-router-dom";
import {
    NETWORK_GOVERNANCE_IDENTITY_PATH,
    NETWORK_GOVERNANCE_MEMBERSHIPS_PATH,
    NETWORK_GOVERNANCE_PATH,
} from "../../components/MainNav/SideNavs/Governance";
import { useMutation } from "@apollo/client";
import { AddIdentityMutation, AddIdentityVars } from "../../models";
import { Step1Help } from './Step1Help';

interface Props {
    cancelPath: string;
    isSelfSigned: boolean;
}

type signTypes = "self" | "external";

export const Step1 = ({ cancelPath, isSelfSigned }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "ConfirmIdentityStep1", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`ConfirmIdentityStep1:${key}`, interpolate);

    const { consortium_id, membership_id, org_id } = useParams<any>();
    const [errorMessage, setErrorMessage] = useState("");

    const classes = useStyles();
    const history = useHistory();

    const step2Path = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_GOVERNANCE_PATH}/${NETWORK_GOVERNANCE_MEMBERSHIPS_PATH}/${membership_id}/${NETWORK_GOVERNANCE_IDENTITY_PATH}/2`;

    const [createIdentity] = useMutation<any, AddIdentityVars>(
        AddIdentityMutation
    );

    const [signType, setSignType] = useState<signTypes>(
        isSelfSigned ? "self" : "external"
    );

    const makeDescription = (
        description: string,
        items: Array<keyof translations>
    ) => {
        return (
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="body2">{description}</Typography>
                </Grid>
                <Grid item container direction="column">
                    {items.map((item, index) => (
                        <Grid
                            key={`${item}-${index}`}
                            item
                            container
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item>
                                <CheckIcon className={classes.checkIcon} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    {lt(item)}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );
    };

    const onChange = (v: string) => {
        setSignType(v as signTypes);
    };

    const onNext = async () => {
        if (signType === "self") {
            try {
                await createIdentity({
                    variables: {
                        consortia_id: consortium_id,
                        id: membership_id,
                        identity: {
                            test_certificate: true,
                        },
                    },
                });
            } catch (err) {
                ErrorSnackbarCatcher(err, setErrorMessage);
            }
        }
        history.push({
            pathname: step2Path,
            state: {
                isSelfSigned: signType === "self",
                cancelPath
            },
        });
    };

    const content = (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h5">{lt("howToConfirm")}</Typography>
            </Grid>
            <Grid item>
                <FormRadio
                    value="self"
                    label={lt("generateSelfSigned")}
                    description={makeDescription(lt("selfSignedInfo"), [
                        "keyMaterial",
                        "elipticiCurve",
                        "onChainProfile",
                        "selfCertificate",
                    ])}
                    selected={signType === "self"}
                    setValue={onChange}
                />
            </Grid>
            <Grid item>
                <FormRadio
                    value="external"
                    label={lt("generateExternallySigned")}
                    description={makeDescription(lt("externallySignedInfo"), [
                        "userControlledKeyMaterial",
                        "curveSupport",
                        "cliOnChainEditing",
                        "trustChain",
                    ])}
                    setValue={onChange}
                    selected={signType === "external"}
                />
            </Grid>
        </Grid>
    );

    return (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessage}
            />
            <CreateWrapper
                {...{ cancelPath }}
                disabled={false}
                {...{ content }}
                {...{ onNext }}
            />
            <Step1Help />
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        color: theme.palette.primary.main,
        display: "flex",
        alignSelf: "center",
    },
}));

interface translations {
    howToConfirm: string;
    generateSelfSigned: string;
    selfSignedInfo: string;
    keyMaterial: string;
    elipticiCurve: string;
    onChainProfile: string;
    selfCertificate: string;
    generateExternallySigned: string;
    externallySignedInfo: string;
    userControlledKeyMaterial: string;
    curveSupport: string;
    cliOnChainEditing: string;
    trustChain: string;
}
const enTranslations: translations = {
    howToConfirm: "How do you want to confirm your identity?",
    generateSelfSigned: "Generate a Self-Signed Certificate",
    selfSignedInfo:
        "For convenience, we can auto-generate a self signed x509 certificate for you.",
    keyMaterial: "Kaleido-Generated Key Material",
    elipticiCurve: "Prime 256 Elliptic Curve",
    onChainProfile: "Easy Editing of On-chain Profile",
    selfCertificate: "Self-signed x509 Certificate",
    generateExternallySigned: "Generate an Externally-Signed Certificate",
    externallySignedInfo:
        "Leverage your own key materials to produce a properly signed x509 certificate",
    userControlledKeyMaterial: "User-Controlled Key Material",
    curveSupport: "P-256 & P-384 Curve Support",
    cliOnChainEditing: "CLI for On-Chain Profile Editing",
    trustChain: "Full PKI Trust Chain",
};
