import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { TokenContractsData, TokenContractsVars, TokenContractsQuery, LedgerContract } from '../../models'
import { DisplayTable } from '../DisplayWrappers'
import { ShortenedHash } from '../FormControls/ShortenedHash'
import { TokenBalance } from './TokenBalance'
import { CircularProgress } from '@material-ui/core';
import Jazzicon from 'react-jazzicon';
import { jsNumberForAddress } from '../../utils/StringUtils';

export const TokenBalances: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'WalletAccountBalances', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`WalletAccountBalances:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const tokenContractsVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        data: {
            tokenContracts: {
                contracts
            }
        } = { tokenContracts: {contracts: [] as LedgerContract[]} }
    } = useQuery<TokenContractsData, TokenContractsVars>(TokenContractsQuery, { 
        variables: tokenContractsVars
    });    

    if (loading && contracts.length === 0) return <CircularProgress />

    const columnHeaders = [
        lt('tokenName'),
        lt('symbol'),
        lt('type'),
        lt('contractAddress'),
        lt('balance'),
    ]

    const records = contracts.map(t => ({
        key: t._id,
        columns: [
            {value: t.tokenName, icon: <Jazzicon diameter={20} seed={jsNumberForAddress(t.address)} /> },
            {value: t.tokenSymbol },
            {value: t.isERC20 ? lt('erc20') : lt('erc721')},
            {value: <ShortenedHash address={t.address} />},
            {value: <TokenBalance contractAddress={t.address} />}
        ]
    }))

    return (
        <DisplayTable header={lt('balances')} {...{columnHeaders}} {...{records}} />
    )
};

interface translations {
    balances: string,
    tokenName: string,
    symbol: string,
    type: string,
    contractAddress: string,
    balance: string,
    erc20: string,
    erc721: string
}
const enTranslations: translations = {
    balances: 'Token Balances',
    tokenName: 'Token name',
    symbol: 'Symbol',
    type: 'Type',
    contractAddress: 'Contract address',
    balance: 'Balance',
    erc20: 'ERC20',
    erc721: 'ERC721'
}