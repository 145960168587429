import CubeSendIcon from 'mdi-react/CubeSendIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Kpi } from '../../components/DisplayWrappers';

interface Props {
    appCreds?: number,
    baf?: number,
    gatewayAPIs?: number,
    liveEventStreams?: number,
    pausedEventStreams?: number,
    eventStreamSubscriptions?: number,
}

export const KpiCard = ({
        liveEventStreams,
        pausedEventStreams,
        eventStreamSubscriptions,
    } : Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsIntegrationsDashboardKpi', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsIntegrationsDashboardKpi:${key}`, interpolate)

    const kpiData = [
        {
            key: 'kpi-liveEventStreams',
            value: (liveEventStreams !== undefined) ? liveEventStreams : '-',
            label: lt('liveEventStreams'),
            icon: <CubeSendIcon/>
        },
        {
            key: 'kpi-pausedEventStreams',
            value: (pausedEventStreams !== undefined) ? pausedEventStreams : '-',
            label: lt('pausedEventStreams'),
            icon: <CubeSendIcon/>
        },
        {
            key: 'kpi-eventStreamSubscriptions',
            value: (eventStreamSubscriptions !== undefined) ? eventStreamSubscriptions : '-',
            label: lt('eventStreamSubscriptions'),
            icon: <CubeSendIcon/>
        }
    ];

    return <Kpi data={kpiData} />;
};

interface translations {
    liveEventStreams: string,
    pausedEventStreams: string,
    eventStreamSubscriptions: string
}
const enTranslations: translations = {
    liveEventStreams: "Live Streams",
    pausedEventStreams: "Paused Streams",
    eventStreamSubscriptions: "Stream Subscriptions"
}