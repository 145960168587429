import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../../../components/DialogWrappers';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { DeleteApp2AppDestinationMutation, DeleteApp2AppDestinationVars, DeleteApp2AppDestinationData, MakeDeleteApp2AppDestinationMutationOptions } from '../../../models';
import { useParams } from 'react-router-dom';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    destinationName: string
}

export const DeleteApp2AppDestinationDialog: React.FC<Props> = ({ open, setOpen, destinationName }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DeleteApp2AppDestination', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DeleteApp2AppDestination:${key}`, interpolate);
    const { service_id } = useParams<any>();

    const [deleteApp2AppDestination, { loading }] = useMutation<DeleteApp2AppDestinationData, DeleteApp2AppDestinationVars>(DeleteApp2AppDestinationMutation);

    const handleDelete = () => {
        return deleteApp2AppDestination(MakeDeleteApp2AppDestinationMutationOptions({ destinationName, service_id })).then(() => { });
    }

    const controlsWrapper = (
        <Typography>{lt('warning', { destinationName })}</Typography>
    );

    return <FormDialog open={open} setOpen={setOpen} header={lt('deleteDestination')} saveDisabled={loading}
        cancelDisabled={loading} cancelText={loading ? lt('deleting') : undefined} hideSave={loading} saveText={lt('delete')}
        controlsWrapper={controlsWrapper} onSave={handleDelete} />
}

interface translations {
    deleteDestination: string
    delete: string
    warning: string
    deleting: string
}

const enTranslations: translations = {
    deleteDestination: 'Delete Destination',
    delete: 'Delete',
    warning: 'Are you sure you want to delete destination {{destinationName}}?',
    deleting: 'Deleting...'
}
