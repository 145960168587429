import React from 'react';
import { CreateWrapper } from '../../components/DialogWrappers';
import { CreateStepProps } from '../../interfaces';
import { ConsortiumZone } from '../../models';
import { RuntimeCreationStep1Content } from './RuntimeCreationStep1Content';

interface Props extends CreateStepProps {
    isMultiRegion: boolean,
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    consortiumZoneId: string,
    consortiumZones: ConsortiumZone[],
    setConsortiumZoneId: React.Dispatch<React.SetStateAction<string>>
    header: string
    description?: string
    onSave: () => void
    isLastStep?: boolean
    isFirstStep?: boolean
    loading?: boolean
};

export const RuntimeCreationStep1Wrapper = ({ 
        isMultiRegion, name, setName, membershipId, setMembershipId, 
        consortiumZoneId, consortiumZones, setConsortiumZoneId, 
        cancelPath, header, description, onSave, isLastStep, loading = false, isFirstStep }: Props) => {

    const disabled = !name || (isMultiRegion && !consortiumZoneId) || loading;

    const content = (
        <RuntimeCreationStep1Content 
            {...{isMultiRegion}}
            {...{name}}
            {...{setName}}
            {...{membershipId}}
            {...{setMembershipId }}
            {...{consortiumZoneId}}
            {...{consortiumZones}}
            {...{setConsortiumZoneId }}
            {...{header}}
            {...{description}} />
    )

    return <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} {...{isFirstStep}} {...{isLastStep}} onNext={onSave}  />
};
