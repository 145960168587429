import { ApolloQueryResult, useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { NodeResourceVars } from '../../interfaces';
import { EventStreamData, ResumeEventStreamMutation, SuspendEventStreamMutation } from '../../models/eventStreams';

interface Props {
    action: 'suspend' | 'resume',
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    refetchEventStream: () => Promise<ApolloQueryResult<EventStreamData>>
};

export const StreamSuspendResume = ({ action, open, setOpen, refetchEventStream }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'StreamSuspendResume', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`StreamSuspendResume:${key}`, interpolate)

    const { consortium_id, environment_id, node_id, eventstream_id } = useParams<any>();

    const streamVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        node_id: node_id!,
        id: eventstream_id!
    }

    const [suspendStream, { loading: suspendStreamLoading }] = useMutation<string, NodeResourceVars>(SuspendEventStreamMutation)
    const [resumeStream, { loading: resumeStreamLoading }] = useMutation<string, NodeResourceVars>(ResumeEventStreamMutation)

    const saveDisabled = suspendStreamLoading || resumeStreamLoading

    if (action === "resume") {
        return (
            <FormDialog dataTestId="resumeStream" saveDisabled={saveDisabled}
                open={open}
                setOpen={setOpen} 
                header={lt('resumeStream', {name: eventstream_id})} 
                description={lt('resumeDescription')} 
                onSave={() => resumeStream({
                    variables: streamVars
                }).then(refetchEventStream).then(() => {})}
                closeDialogAfterSave
                saveText={lt('resume')} />
        )
    }
    else {
        return (
            <FormDialog dataTestId="suspendStream" saveDisabled={saveDisabled}
                open={open}
                setOpen={setOpen} 
                header={lt('suspendStream', {name: eventstream_id})} 
                description={lt('suspendDescription')} 
                onSave={() => suspendStream({
                    variables: streamVars
                }).then(refetchEventStream).then(() => {})}
                closeDialogAfterSave
                saveText={lt('suspend')} />
        )
    }
};

interface translations {
    resumeStream: string,
    resumeDescription: string,
    resume: string,
    suspendStream: string,
    suspendDescription: string,
    suspend: string,
}
const enTranslations: translations = {
    resumeStream: 'Resume this stream ({{name}})?',
    resumeDescription: 'All subscriptions for this event stream will be resumed.',
    resume: 'Resume',
    suspendStream: 'Suspend this stream ({{name}})?',
    suspendDescription: 'All subscriptions for this event stream will be suspended and will remain dormant until the event stream is resumed.',
    suspend: 'Suspend',
}