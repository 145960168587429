import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Node, Service, ResetNodeMutation, ResetServiceMutation } from '../../models'
import { FormDialog } from '../../components/DialogWrappers'
import { EnvironmentResourceVars } from '../../interfaces';

interface Props {
    name: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ResetRuntime = ({ name, open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ResetRuntime', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ResetRuntime:${key}`, interpolate)

    const { consortium_id, environment_id, node_id, service_id } = useParams<any>();

    const envResourceVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [resetNode, { loading: resetNodeLoading }] = useMutation<Node, EnvironmentResourceVars>(ResetNodeMutation)
    const [resetService, { loading: restartServiceLoading }] = useMutation<Service, EnvironmentResourceVars>(ResetServiceMutation)

    const loading = resetNodeLoading || restartServiceLoading
    
    const updater = () => {         
        if (node_id) {
            const updateVars = { ...envResourceVars, ...{ id: node_id! } }
            return resetNode({
                variables: updateVars
            }).then(() => {})
        } else if (service_id) {
            const updateVars = { ...envResourceVars, ...{ id: service_id! } }
            return resetService({
                variables: updateVars
            }).then(() => {})
        } else {
            return new Promise<void>(() => {})
        }
    }

    return <FormDialog 
        {...{open}} 
        {...{setOpen}} 
        header={lt('resetRuntime', {name})} 
        description={lt('resetDescription')} 
        saveDisabled={loading}
        onSave={updater}
        closeDialogAfterSave
        saveText={lt('reset')} />
};

interface translations {
    resetRuntime: string,
    resetDescription: string,
    reset: string
}
const enTranslations: translations = {
    resetRuntime: 'Reset this runtime ({{name}})?',
    resetDescription: 'This will stop all software processing running and apply newly applied configurations. Could take several minutes to complete.',
    reset: 'Reset'
}