import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { RuntimeCreationSelectSizeContent } from '../../components/RuntimeCreation/RuntimeCreationSelectSizeContent';
import { EnvironmentResourceVars } from '../../interfaces';
import { Node, ResetNodeMutation, UpdateNodeMutation, UpdateNodeVars } from '../../models';

interface Props {
    node: Node,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const UpdateSize = ({ node, open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeSettingsUpdateSize', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeSettingsUpdateSize:${key}`, interpolate)

    const { consortium_id, environment_id, node_id } = useParams<any>();

    const nodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: node_id!
    }

    const [size, setSize] = useState(node.size);

    useEffect(() => {
        setSize(node.size)
    }, [node.size])

    const [updateNode, { loading: updateNodeLoading }] = useMutation<Node, UpdateNodeVars>(UpdateNodeMutation);
    const [resetNode, { loading: resetNodeLoading }] = useMutation<Node, EnvironmentResourceVars>(ResetNodeMutation)

    const updater = () => updateNode({
        variables: {...{ node: { size: size} }, ...nodeVars}
    }).then(() => {
        resetNode({
            variables: nodeVars
        })
    })

    const controlsWrapper = <RuntimeCreationSelectSizeContent {...{size}} {...{setSize}} hideHeaderAndDescription />

    return <FormDialog 
        {...{open}} 
        {...{setOpen}} 
        header={lt('changeNodeSize')} 
        description={lt('changeNodeSizeDescription')} 
        {...{controlsWrapper}} 
        saveDisabled={!size || updateNodeLoading || resetNodeLoading}
        onSave={updater}
        closeDialogAfterSave />
};

interface translations {
    changeNodeSize: string,
    changeNodeSizeDescription: string
}
const enTranslations: translations = {
    changeNodeSize: 'Change node size',
    changeNodeSizeDescription: 'You can change the size of this node by making a new selection below. This will reset the node.'
}