import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers';
import { Protocol } from '../../models';

export const Step3Help = ({ protocol } : { protocol: Protocol }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep3Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep3Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Environment-Summary.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            finalInfo={lt('helpMultiRegion')}
            docLink="https://docs.kaleido.io/using-kaleido/environments"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    helpMultiRegion: string,
}
const enTranslations: translations = {
    helpHeader: 'Environment Summary',
    helpDescription: 'Each environment contains:',
    helpDesc1: 'A firewall isolated network for all resources',
    helpDesc2: 'The core blockchain technology',
    helpDesc3: 'The full-stack of services that make up your decentralized solution',
    helpMultiRegion: 'Our multi-region and hybrid deployment model securely bridges your environment across muliple global regions and cloud providers, restricted to those that have been whitelisted by the business network.',
}