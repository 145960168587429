import React    from 'react';
import { useTranslation } from 'react-i18next';
import { Service, ServicesTranslations, EnServicesTranslations } from '../../models'
import { DisplayCard } from '../../components/DisplayWrappers'
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import MapOutlineIcon from 'mdi-react/MapOutlineIcon';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { useParams, useHistory } from 'react-router-dom';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_MANAGED_PATH, KEY_MANAGEMENT_CLOUD_PATH } from '../../components/MainNav/SideNavs/KeyManagement';
import { useQuery } from '@apollo/client';
import { ConfigData, ConfigQuery } from '../../models/configs';
import { EnvironmentResourceVars } from '../../interfaces';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import CloudOutlineIcon from 'mdi-react/CloudOutlineIcon';

interface Props {
    service: Service
};

export const WalletCard = ({ service }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'KeyManagementWalletCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`KeyManagementWalletCard:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            config
        } = { config: null }
    }= useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: service.details.cloudhsm_id ?? ''
        },
        skip: service.service !== 'cloudhsm'
    });

    const itemList: DisplayCardListItem[] = [
        {
            title: lt('status'),
            value: <ResourceStateChip state={service.state} />,
            icon: <ChartLineIcon />
        }
    ];

    if (config) {
        itemList.push({
            title: lt('provider'),
            value: config.details.provider ?? '',
            icon: <CloudOutlineIcon />
        })
    }

    itemList.push({
        title: lt('type'),
        value: lt(service.service as keyof ServicesTranslations) ,
        icon: <CardAccountDetailsOutlineIcon />
    }, {
        title: lt('creationDate'),
        value: new Date(service.created_at).toLocaleString(),
        icon: <MapOutlineIcon />
    })

    const linkButton = {
        text: lt('viewWallet'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/${config ? `${KEY_MANAGEMENT_CLOUD_PATH}/` : `${KEY_MANAGEMENT_MANAGED_PATH}/`}${service.service}/${service._id}`)
    };
    
    return (
        <DisplayCard {...{linkButton}}
            header={service.name || lt('emptyName')}
            {...{itemList}} />
    )
};

interface translations extends ServicesTranslations {
    status: string
    type: string
    creationDate: string
    viewWallet: string
    provider: string
    emptyName: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    status: 'Status',
    type: "Type",
    creationDate: 'Creation Date',
    viewWallet: 'View Wallet',
    provider: 'Provider',
    emptyName: 'No Name'
}