import { useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { MessageSnackbar } from '../../components/DialogWrappers';
import { EditableSettings } from '../../components/DisplaySettings';
import { FIREFLY_BASE_PATH, FIREFLY_INITIALIZE_PATH, FIREFLY_REGISTRATION_PATH, FIREFLY_SERVICE_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { EnvironmentResourceVars, ServiceResourcesVars } from '../../interfaces';
import { ServiceData, ServiceQuery } from '../../models';
import { FireflyNamespacesWithDetailsData, FireflyNamespacesWithDetailsQuery } from '../../models/firefly';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';

export const NamespaceManagement: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NamespaceManagement', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NamespaceManagement:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, service_id } = useParams<any>();
    const history = useHistory()

    const [message, setMessage] = useState('');

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;
    const addNamespace = () => history.push(`${basePath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/${service_id}/${FIREFLY_INITIALIZE_PATH}?operation=addNamespace`)
    const registerNamespace = (namespaceId: string) => history.push(`${basePath}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/${service_id}/${FIREFLY_REGISTRATION_PATH}?namespaceId=${namespaceId}`)

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        loading,
        data: {
            service
        } = { service: null }
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, { 
        variables: {
            ...environmentVariables,
            id: service_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        loading: namespacesLoading,
        data: {
            fireflyNamespacesWithDetails
        } = { fireflyNamespacesWithDetails: [] }
    } = useQuery<FireflyNamespacesWithDetailsData, ServiceResourcesVars>(FireflyNamespacesWithDetailsQuery, {
        variables: {
            service_id: service?._id || ''
        },
        fetchPolicy: 'network-only', // make sure we get the latest right away, since the value from the FireflyStatusChip is likely stale since it doesnt poll
    });

    if (loading || !service) return <CircularProgress />
    
    const actionsList =
        fireflyNamespacesWithDetails.length > 0
            ? fireflyNamespacesWithDetails.map((ns) => {
                const registered = ns?.org?.registered && ns?.node?.registered;
                  return {
                      title: ns.namespace?.name,
                      description: ns?.contract_address ? (
                          <Grid item>
                              <ShortenedHash address={ns?.contract_address} />
                          </Grid>
                      ) : (
                          ''
                      ),
                      value: registered ? lt('registered') : undefined,
                      action: () => {
                          registerNamespace(ns.namespace?.name);
                      },
                      hiddenButton: registered,
                      buttonLabel: lt('register'),
                  };
              })
            : [
                  {
                      title: lt('noNamespaceFound'),
                      action: () => {},
                      hiddenButton: true,
                      buttonLabel: lt('register'),
                  },
              ];
    return (
        <>
            <MessageSnackbar
                messageType={'success'}
                {...{ message }}
                {...{ setMessage }}
            />

            <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h5">
                            {lt('namespaceManagement')}
                        </Typography>
                    </Grid>
                    {service?.membership?.isMine && 
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={addNamespace}
                            >
                                {lt('addNamespace')}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    {namespacesLoading ? (
                        <CircularProgress />
                    ) : (
                        <EditableSettings
                            header={lt('namespaces')}
                            {...{ actionsList }}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

interface translations {
    namespaceManagement: string,
    namespaces: string,
    addNamespace: string,
    register: string,
    registered: string,
    noNamespaceFound: string
}
const enTranslations: translations = {
    namespaceManagement: 'Namespace Management',
    namespaces: 'Namespaces',
    addNamespace: 'Add Namespace',
    register: 'Register',
    registered: 'Registered',
    noNamespaceFound: 'No namespace found.'
}