import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EnPlansTranslations, PlanName, PlansEnum, PlansTranslations } from '../../models';

interface Props {
    plan: PlanName,
    redirectPath: string
    withPaper?: boolean
}

export const CongratsMessage = ({plan, redirectPath, withPaper}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CongratsMessage', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CongratsMessage:${key}`, interpolate);

    const history = useHistory();

    const content = (
        <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
                <img src={`${process.env.PUBLIC_URL}/img/CreateWizards/Confirm-Upgrade.svg`} alt=""></img>
            </Grid>
            <Grid item>
                <Typography variant="h4">{lt('congratulationsTitle')}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="h4">{lt('congratulationsSubtitle', { planName: lt(PlansEnum[plan]) })}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">{lt('congratulationsDescription')}</Typography>
            </Grid>
            <Grid item>
                <Button color="primary" variant="contained"
                    onClick={() => history.push(redirectPath)}>
                        {lt('finish')}
                </Button>
            </Grid>
        </Grid>
    )
    if (withPaper) {
        return (
            <Paper>
                {content}
            </Paper>
        )
    } else {
        return content
    }
}

interface translations extends PlansTranslations {
    congratulationsTitle: string
    congratulationsSubtitle: string
    congratulationsDescription: string
    finish: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    congratulationsTitle: 'Congratulations!',
    congratulationsSubtitle: 'You are now on the {{planName}} Plan.',
    congratulationsDescription: 'We hope you enjoy exploring new possibilities with your plan.',
    finish: 'Finish'
}
