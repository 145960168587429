import { Grid, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CreateWrapper } from '../../components/DialogWrappers';
import { NodeAutocomplete } from '../../components/FormControls/NodeAutocomplete';
import { ServiceAutocomplete } from '../../components/FormControls/ServiceAutocomplete';
import { CreateStepProps, AutocompleteIdName } from '../../interfaces';
import { FIREFLY_BASE_PATH, FIREFLY_SERVICE_PATH } from '../../components/MainNav/SideNavs/Firefly';
import { Step2Help } from './Step2Help';

interface CreateNodeStep2Props extends CreateStepProps {
    fireflyNodeName: string,
    membershipInfo: AutocompleteIdName,
    setIpfsNode: Dispatch<SetStateAction<AutocompleteIdName>>,
    setBlockchainNode: Dispatch<SetStateAction<AutocompleteIdName>>,
    setOrdererNode: Dispatch<SetStateAction<AutocompleteIdName>>,
    setPeerNode: Dispatch<SetStateAction<AutocompleteIdName>>,
    setFabricCaNode: Dispatch<SetStateAction<AutocompleteIdName>>,
    blockchainNode: AutocompleteIdName,
    ipfsNode: AutocompleteIdName,
    ordererNode: AutocompleteIdName,
    peerNode: AutocompleteIdName,
    fabricCaNode: AutocompleteIdName,
    isFabric?: boolean,
    envZoneId: string | undefined
};

// In this step, the user will add (or create) the other necessary runtimes for their firefly node
export const CreateNodeStep2 = (props: CreateNodeStep2Props) => {
    const history = useHistory();
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateFireflyNodeStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateFireflyNodeStep2:${key}`, interpolate);

    const content = (
        <>
        <Grid item>
            <Typography variant="h5">
                {lt('header')}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
                {props.isFabric ? lt('fabricHeaderDescription') : lt('ethHeaderDescription')}
            </Typography>
        </Grid>
        {!props.isFabric &&
            <Grid item>
                <NodeAutocomplete node={props.blockchainNode} setNode={props.setBlockchainNode} dataTest={"blockchainNode"} role={"validator"} membershipId={props.membershipInfo.id} envZoneId={props.envZoneId} />
            </Grid>
        }
        {props.isFabric &&
        <>
            <Grid item>
                <NodeAutocomplete node={props.ordererNode} setNode={props.setOrdererNode} dataTest={"ordererNode"} role={"orderer"} membershipId={props.membershipInfo.id} envZoneId={props.envZoneId}/>
            </Grid>
            <Grid item>
                <NodeAutocomplete node={props.peerNode} setNode={props.setPeerNode} dataTest={"peerNode"} role={"peer"} membershipId={props.membershipInfo.id} envZoneId={props.envZoneId}/>
            </Grid>
            <Grid item>
                <ServiceAutocomplete service={props.fabricCaNode} setService={props.setFabricCaNode} dataTest={"caNode"} serviceType={"fabric-ca"} membershipId={props.membershipInfo.id} envZoneId={props.envZoneId}/>
            </Grid>
            </>
        }
        <Grid item>
            <ServiceAutocomplete service={props.ipfsNode} setService={props.setIpfsNode} dataTest={"ipfsNode"} serviceType={"ipfs"} membershipId={props.membershipInfo.id} envZoneId={props.envZoneId}/>
        </Grid>
        </>
    )

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${FIREFLY_BASE_PATH}/${FIREFLY_SERVICE_PATH}/create/3`, {
            fireflyNodeName: props.fireflyNodeName,
            membershipInfo: props.membershipInfo,
            blockchainNode: props.blockchainNode,
            ipfsNode: props.ipfsNode,
            ordererNode: props.ordererNode,
            peerNode: props.peerNode,
            fabricCaNode: props.fabricCaNode,
        })
    }

    const disabled = (props.isFabric ? (!props.ordererNode.name || !props.peerNode.name || !props.fabricCaNode.name) : !props.blockchainNode.name) || !props.ipfsNode.name;

    return (
        <>
            <CreateWrapper cancelPath={props.cancelPath} {...{content}} onNext={save} {...{disabled}} />
            <Step2Help />
        </>
    );
}

interface translations {
    header: string,
    ethHeaderDescription: string,
    fabricHeaderDescription: string
}
const enTranslations: translations = {
    header: 'Choose existing or create new FireFly dependent runtimes',
    ethHeaderDescription: 'Choose a blockchain node and an IPFS runtime for your FireFly SuperNode to connect with.',
    fabricHeaderDescription: 'Choose an orderer node, a peer node, a CA runtime, and an IPFS runtime for your FireFly node to connect with.'
}