import React from 'react';
import { ListItemAvatar, ListItemText, ListItemSecondaryAction, makeStyles, List, ListItem, Avatar, IconButton, Theme } from '@material-ui/core';

interface Props {
    title: string
    subtitle?: string
    icon?: JSX.Element
    action?: {
        handler: () => void
        icon: JSX.Element
    }
    colors?: Colors
}

export const PopoverTitle = ({ icon, title, subtitle, action, colors }: Props) => {

    const classes = useStyles(colors || { background: '#2D2D2D', text: 'white'});

    return (
        <List className={classes.title}>
            <ListItem classes={{ root: classes.titleBackground }}>
                {icon &&
                    <ListItemAvatar>
                        <Avatar>
                            {icon}
                        </Avatar>
                    </ListItemAvatar>}
                <ListItemText
                    classes={{ primary: classes.titleText, secondary: classes.titleText }}
                    primary={title}
                    secondary={subtitle} />
                {action &&
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={action.handler}>
                            {action.icon}
                        </IconButton>
                    </ListItemSecondaryAction>
                }
            </ListItem>
        </List>
    );
};

interface Colors {
    background: string
    text: string
}

const useStyles = makeStyles<Theme, Colors>(() => ({
    title: {
        paddingTop: 0,
        paddingBottom: 0
    },
    titleBackground: props => ({
        backgroundColor: props.background,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3
    }),
    titleText: props => ({
        color: props.text
    })
}));