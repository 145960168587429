import { gql, MutationFunctionOptions } from '@apollo/client';

export interface CaIdentity {
    service_id?: string
    role?: string
    enrollmentSecret?: string
    enrollmentID?: string
}

export interface CaIdentityRegistrations {
    registrations: CaIdentity[]
}

export interface CaIdentityInput {
    enrollmentID: CaIdentity['enrollmentID'],
    role: CaIdentity['role']
}

export interface CreateCaIdentityVars {
    service_id: CaIdentity['service_id'],
    caIdentity: CaIdentityInput
}

export interface CreateCaIdentityData {
    createCaIdentity: CaIdentityRegistrations
}

export interface RegisteredIdentity{
    id: string
    type: string
    max_enrollments: number
}

export interface RegisteredIdentitiesData {
    registeredIdentities: RegisteredIdentity[]
}

export interface CaCertificate {
    cert: string
}

export interface CaCertificateData {
    caCertificate: CaCertificate
}

// FRAGMENTS

export const CaIdentityFields = ` 
    fragment CaIdentityFields on CaIdentity{
        role
        enrollmentID
        enrollmentSecret
    }`

export const CaIdentityRegistrationFields = ` 
    fragment CaIdentityRegistrationFields on CaIdentityRegistrations{
        registrations {
            role
            enrollmentID
            enrollmentSecret
        }
    }`

export const RegisteredIdentitiesFields = `
    fragment RegisteredIdentitiesFields on RegisteredIdentity {
        id
        type
        max_enrollments
    }
`
export const CaCertFields = ` 
    fragment CaCertFields on CaCertificate{
        cert
    }`

// QUERIES & SUBSCRIPTIONS

export const CaCertificateQuery = gql`
    ${CaCertFields}
    query caCertificate($service_id: String!) {
        caCertificate(service_id: $service_id) {
            ...CaCertFields
        }
    }`;

export const RegisteredIdentitiesQuery = gql`
    ${RegisteredIdentitiesFields}
    query registeredIdentities($service_id: String!) {
        registeredIdentities(service_id: $service_id) {
            ...RegisteredIdentitiesFields
        }
    }`;

export const CreateCaIdentityMutation = gql`
    ${CaIdentityRegistrationFields}
    mutation createCaIdentity($service_id: String!, $caIdentity: CreateCaIdentityInput!) {
        createCaIdentity(service_id: $service_id, caIdentity: $caIdentity) {
            ...CaIdentityRegistrationFields
        }
    }`;
    
// MUTATION OPTIONS

export function MakeCaIdentityCreateMutationOptions(
    variables: CreateCaIdentityVars,
    overrideOptions?: MutationFunctionOptions<CreateCaIdentityData, CreateCaIdentityVars>): MutationFunctionOptions<CreateCaIdentityData, CreateCaIdentityVars> {
    return { ...{
        variables,
        update(cache, { data }) {}
    }, ...overrideOptions }
}
