import React, { Dispatch, SetStateAction } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { NodesData, NodesQuery } from '../../models';
import { EnvironmentResourcesVars, AutocompleteIdName } from '../../interfaces'
import { IdNameAutocomplete } from './IdNameAutocomplete';

interface NodeAutocompleteProps {
    node: AutocompleteIdName,
    setNode: Dispatch<SetStateAction<AutocompleteIdName>>,
    role: 'validator' | 'orderer' | 'peer',
    membershipId: string,
    dataTest?: string,
    envZoneId: string | undefined
};
  
export const NodeAutocomplete = ({ node, setNode, role, membershipId, dataTest, envZoneId }: NodeAutocompleteProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeAutocomplete', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeAutocomplete:${key}`, interpolate)
    const { consortium_id, environment_id } = useParams<any>();
    let label = '';
    let isAutocompleteDisabled = false

    const {
        data: {
            nodes: allNodes
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: { consortia_id: consortium_id!, environment_id: environment_id! },
        fetchPolicy: 'cache-only'
    });

    switch (role) {
        case 'validator':
            label = lt('selectOrAddBlockchain');
            break;
        case 'orderer':
            label = lt('selectOrAddOrderer');
            break;
        case 'peer':
            label = lt('selectOrAddPeer');
            break;
    }

    let nodes = allNodes.filter(n => n.membership.isMine && n.membership_id === membershipId && n.role === role && (envZoneId && n.zone_id === envZoneId));
    let defaultId: string | undefined = undefined

    if (role === "orderer") {
        // if an orderer node aready exists, pre set it and disable autocomplete
        if(nodes.length !== 0) {
            defaultId = nodes[0]._id
            isAutocompleteDisabled = true;
        }
    }

    const options = nodes.map(m => ({id: m._id, name: m.name}))

    return (
        <IdNameAutocomplete selected={node} setSelected={setNode} options={options} disabled={isAutocompleteDisabled}
            defaultId={defaultId} dataTest={dataTest} label={label} setWidth={400} />
    )
};

interface translations {
    selectOrAddBlockchain: string,
    selectOrAddOrderer: string,
    selectOrAddPeer: string
}
const enTranslations: translations = {
    selectOrAddBlockchain: 'Select existing or create a new blockchain node',
    selectOrAddOrderer: 'Select existing or create a new orderer node',
    selectOrAddPeer: 'Select existing or create a new peer node'
}