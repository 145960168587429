import { Grid, Typography, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { Region } from '../../interfaces';
import { OrgsTable } from './OrgsTable';

interface Props {
    regions?: Region[]
}

export const Orgs: React.FC<Props> = ({regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsOrgs', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsOrgs:${key}`, interpolate)

    const [message, setMessage] = useState('');
    const [idFilter, setIDFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [emailFilter, setEmailFilter] = useState('');
    const [agreementFilter, setAgreementFilter] = useState('');
    const [parentFilter, setParentFilter] = useState('');
    const [planFilter, setPlanFilter] = useState('');
    const [billingFilter, setBillingFilter] = useState('');

    const filterBar = <Grid container spacing={2} alignItems="center" alignContent="stretch">
        <Grid item xs>
            <Typography variant="h5">{lt('filter')}</Typography>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('id')} 
                value={idFilter} 
                variant="outlined" 
                onChange={e => setIDFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('name')} 
                value={nameFilter} 
                variant="outlined" 
                onChange={e => setNameFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('email')} 
                value={emailFilter} 
                variant="outlined" 
                onChange={e => setEmailFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('billing')} 
                value={billingFilter} 
                variant="outlined" 
                onChange={e => setBillingFilter(e.target.value)}/>
        </Grid>        
        <Grid item xs>
            <TextField
                label={lt('plan')} 
                value={planFilter} 
                variant="outlined" 
                onChange={e => setPlanFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('parent')} 
                value={parentFilter} 
                variant="outlined" 
                onChange={e => setParentFilter(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                label={lt('agreement')} 
                value={agreementFilter} 
                variant="outlined" 
                onChange={e => setAgreementFilter(e.target.value)}/>
        </Grid>        
    </Grid>;

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <Grid container direction="column" spacing={3}>
            <Grid item>
                {filterBar}
            </Grid>
            {regions?.map(({code, cloud, region}) => (
                <Grid item container direction="column" spacing={3} key={`${code}-${cloud}-${region}`}>
                    <Grid item>
                        <Typography variant="h5">{`${cloud}: ${region}`}</Typography>
                    </Grid>
                    <Grid item>
                        <OrgsTable region={code} {...{setMessage}} {...{idFilter}} {...{nameFilter}} {...{emailFilter}} {...{billingFilter}} {...{planFilter}} {...{parentFilter}} {...{agreementFilter}}/>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </>;
};

interface translations {
    filter: string;
    id: string;
    name: string;
    email: string;
    billing: string;
    plan: string,
    parent: string,
    agreement: string;
}
const enTranslations: translations = {
    filter: 'Filter:',
    id: 'ID',
    name: 'Name',
    email: 'E-mail',
    billing: 'Billing',
    plan: 'Plan',
    parent: 'Parent',
    agreement: 'Agreement',
}
