import { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";

export function useLocalStorageState<T>(
    key: string,
    defaultValue?: T
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
    const [state, setState] = useState<typeof defaultValue>(() => {
        const valueInLocalStorage = window.localStorage.getItem(key);
        if (valueInLocalStorage) {
            return JSON.parse(valueInLocalStorage);
        }
        return typeof defaultValue === "function"
            ? defaultValue()
            : defaultValue || "";
    });

    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;
        if (prevKey !== key) {
            window.localStorage.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        window.localStorage.setItem(key, JSON.stringify(state));

        return () => window.localStorage.removeItem(key);
    }, [state, key]);

    return [state, setState];
}
