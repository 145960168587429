import React from 'react';
import { Grid, makeStyles, Typography, Hidden } from '@material-ui/core';
import InfoIcon from 'mdi-react/InformationIcon';
import WarningIcon from 'mdi-react/AlertIcon';
import ErrorIcon from 'mdi-react/AlertCircleIcon';

type Severity = 'info' | 'error' | 'warning' // Add severity options here (with their respective icons)

interface Props {
    severity: Severity,
    title: string,
    description?: string,
    button?: JSX.Element
}

export const Alert = ({severity, title, description, button} : Props) => {

    const classes = useStyles();

    const iconsMap: Map<Severity, JSX.Element> = new Map([
        ['info', <InfoIcon />],
        ['warning', <WarningIcon />],
        ['error', <ErrorIcon />]
    ])

    return (
        <Grid container justify="space-between" className={classes.container} wrap="nowrap" alignItems="center">
            <Grid item container spacing={1} alignItems="center">
                <Grid item>
                    {iconsMap.get(severity)}
                </Grid>
                <Hidden smDown implementation="js">
                    <Grid item>
                        <Typography variant="subtitle2">{title}</Typography>
                    </Grid>
                </Hidden>
                {description && (
                    <Grid item>
                        <Typography variant="body2">{description}</Typography>
                    </Grid>
                )}
            </Grid>
            {button && 
                <Grid item>
                    {button}
                </Grid>
            }
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#666666',
        color: "white",
        padding: theme.spacing(2)
    }
}))