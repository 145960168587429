import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConsortiumCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConsortiumCreateStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Organization.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('helpOffChain')}
            docLink="https://docs.kaleido.io/using-kaleido/orgs-and-users/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpOffChain: string,
}
const enTranslations: translations = {
    helpHeader: 'New Organization',
    helpDescription: 'The new organization has a separate set of users, providing an adminstration domain over a separate set of resources.',
    helpDesc1: 'You will own the new organization, and be the only administrator. You can add more users and transfer ownership later.',
    helpDesc2: 'Billing for the new organiztion will roll up to the root organization. You can detach the organization later, with its own billing.',
    helpOffChain: '',
}