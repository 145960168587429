import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, TextField, Grid } from "@material-ui/core";
import { Step1Help } from './Step1Help'
import { MembershipSelector } from '../../components/FormControls/MembershipSelector'
import { ConsortiumZone, EnvironmentZone } from '../../models';
import { CreateWrapper } from '../../components/DialogWrappers'
import { CreateStepProps } from '../../interfaces';
import { CHANNELS_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { CREATE_CHANNELS_PATH } from '../AddressBook/Channels';

const channelNameRegex = /^[a-z0-9]([a-z0-9.-]*[a-z0-9])?$/;

interface Props extends CreateStepProps {
    isMultiRegion: boolean,
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>
    description: string
    setDescription: React.Dispatch<React.SetStateAction<string>>
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    ordererNodeId: string,
    setOrdererNodeId: React.Dispatch<React.SetStateAction<string>>,
    selectedMembers: string[],
    setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>,
    consortiumZones: ConsortiumZone[],
    privateZones: EnvironmentZone[],
    provider?: string,
};

export const Step1 = ({ 
        name, setName, membershipId, setMembershipId, ordererNodeId, setOrdererNodeId, description, setDescription, selectedMembers, setSelectedMembers, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateChannelStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateChannelStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${CREATE_CHANNELS_PATH}/2`, {
            name,
            membershipId,
            description,
            members: selectedMembers,
            ordererNodeId
        })
    }

    useEffect(() => {
        setSelectedMembers([membershipId])
    }, [membershipId, setSelectedMembers]);

    const disabled = !name || !channelNameRegex.test(name)

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField error={!!name && !channelNameRegex.test(name)}
                    data-test="channelName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    label={lt('name')}
                    helperText={name && !channelNameRegex.test(name) ? lt('channelRegex') : undefined}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>
            
            {/* This feature is coming soon
             <Grid item>
                <NodeSelector filterByRole={"orderer"} nodeId={ordererNodeId} setNodeId={setOrdererNodeId}/>
            </Grid> */}

            <Grid item>
                <TextField
                    data-test="channelDescription"
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} isFirstStep />
            <Step1Help />
        </>
    )
};


interface translations {
    header: string,
    headerDescription: string,
    name: string,
    description: string,
    membership: string,
    membershipName: string,
    ordererNode: string,
    peer: string,
    learnMore: string,
    logo: string,
    channelRegex: string
}
const enTranslations: translations = {
    header: 'Set Up Your Channel',
    headerDescription: 'Provision a channel to take part in this environment.',
    name: 'Name of this channel',
    description: 'Channel Description',
    membership: 'Membership',
    membershipName: 'Membership Name',
    ordererNode: 'Orderer Node',
    peer: 'Peer',
    learnMore: 'Learn More',
    logo: '{{role}} Logo',
    channelRegex: 'Name must be lower case alphanumerics, dots and dashes, starting and ending with an alphanumeric'
}