import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { NodesData, NodesQuery } from '../../models';
import { EnvironmentResourcesVars } from '../../interfaces'

interface Props {
    nodeId: string,
    setNodeId: React.Dispatch<React.SetStateAction<string>>,
};

export const NodeSelector = ({ nodeId, setNodeId }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeSelector:${key}`, interpolate)
    const { consortium_id, environment_id } = useParams<any>();

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const { 
        data: { 
            nodes: allNodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: { consortia_id: consortium_id!, environment_id: environment_id! },
        fetchPolicy: 'cache-only'
    });    

    const nodes = allNodes.filter(n => n.state === 'started' && n.membership.isMine)

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNodeId(event.target.value as string);
    };

    useEffect(() => {
        if (!nodeId && nodes.length) {
            setNodeId(nodes.find(n => true)?._id ?? '')
        }
    }, [nodes, nodeId, setNodeId])

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} required>
                {lt('selectNode')}
            </InputLabel>
            <Select 
                labelWidth={labelWidth}
                value={nodeId}
                onChange={handleChange}>
                {nodes.map(m => (
                    <MenuItem key={m._id} selected={m._id === nodeId} value={m._id}>
                        {nodes.find(x => x._id === m._id)?.name ?? ''}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    )
};

interface translations {
    selectNode: string
}
const enTranslations: translations = {
    selectNode: 'Select Node'
}