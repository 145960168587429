import React from 'react';
import { CreateStepProps } from '../../../interfaces';
import { Grid, Typography, TextField } from '@material-ui/core';
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector';
import { Step1Help } from './Step1Help';
import { useTranslation } from 'react-i18next';
import { CreateWrapper } from '../../../components/DialogWrappers';
import { useHistory } from 'react-router-dom';

interface Props extends CreateStepProps {
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>
}


export const Step1 = ({membershipId, setMembershipId, name, setName, cancelPath}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOauthConfigurationStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOauthConfigurationStep1:${key}`, interpolate);

    const history = useHistory();

    const disabled = !name || !membershipId

    const save = () => {
        history.push(`${cancelPath}/create/2`, {
            name,
            membershipId,
        })
    }

    const content =  (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />

                <TextField
                    data-test="runtimeName"
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>
        </>
    );

    return (
        <>
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep />
            <Step1Help />
        </>
    )
            
};

interface translations {
    header: string,
    name: string
}
const enTranslations: translations = {
    header: 'Create OAuth Configuration',
    name: 'Name'
}
