import React, { useState, useEffect, useMemo } from 'react';
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher } from '../../../components/DialogWrappers';
import { Grid, MenuItem, Typography, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CLOUDCONFIG_BASE_PATH, configTypeMap, CloudConfigTypesUrl } from '../../../components/MainNav/SideNavs/CloudConfigs';
import { CreateKeyStore } from './CreateKeyStore';
import { CreateBackUp } from './CreateBackup';
import { CreateLogStream } from './CreateLogStream';
import { useMutation } from '@apollo/client';
import { CreateConfigMutation, CreateConfigData, CreateConfigVars, ConfigDetails, ProviderType, ConfigTypeTranslationsInterface, ConfigTypeTranslations } from '../../../models/configs';
import { useParams, useHistory } from 'react-router-dom';
import { KeyStoreHelp, BackupStorageHelp, LogStreamHelp } from './Helpers';
import { CreateNetworking } from './CreateNetworking';
import { ConfigsHelp } from './Helpers/ConfigsHelp';
import { CreateStepProps } from '../../../interfaces';
import { NetworkHelp } from './Helpers/NetworkHelp';
import { CreateCloudHsm } from './CreateCloudHsm';
import { CloudHsmHelp } from './Helpers/CloudHsmHelp';
import { useForm } from "react-hook-form";


type locationState = { name: string, membershipId: string};

const configTypesOptions: Array<CloudConfigTypesUrl> = ['backup', 'logstream', 'keystore', 'networking', 'storage', 'cloudhsm'];

const configProvider : Map<CloudConfigTypesUrl, ProviderType[]> = new Map([
    ['backup', ['aws', 'azure']],
    ['storage', ['aws', 'azure']],
    ['keystore', ['aws', 'azure']],
    ['logstream', ['aws']],
    ['networking', []],
    ['cloudhsm', ['aws', 'azure', 'hashicorp', 'aws_kms']]
])

export interface NetworkingData {
    allowPublic: false,
    allowPrivate: false
}

interface Props extends CreateStepProps {
    preSelectedConfigType: CloudConfigTypesUrl | ''
}

export const Step2 = ({preSelectedConfigType, cancelPath}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateCloudConfigStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateCloudConfigStep2:${key}`, interpolate);

    const [selectedConfigType, setSelectedConfigType] = useState<CloudConfigTypesUrl | ''>(preSelectedConfigType);
    const [selectedProvider, setSelectedProvider] = useState<ProviderType>('aws');
    const { register, handleSubmit, reset } = useForm<ConfigDetails>();

    const [errorMessage, setErrorMessage] = useState('');

    const { consortium_id, environment_id, org_id } = useParams<any>();
    
    const history = useHistory<locationState>();

    const {
        location: {
            state: {
                name,
                membershipId
            } = { name: '', membershipId: ''}
        }
    } = history;

    const [createConfig, {loading}] = useMutation<CreateConfigData, CreateConfigVars>(CreateConfigMutation);

    useEffect(() => {
        if (selectedProvider) reset();
    }, [selectedProvider, reset])

    const providerOptions = useMemo(() => {
        if (selectedConfigType) {
            reset();
            setSelectedProvider('aws')
            return configProvider.get(selectedConfigType)!
        }
        return []
    }, [selectedConfigType, reset])

    const onNext = async (data: ConfigDetails) => {
        try {
            if(selectedConfigType) {
                let details = {...data};
                if (selectedConfigType !== 'networking') details.provider = selectedProvider
                await createConfig({
                    variables: {
                        consortia_id: consortium_id!,
                        environment_id: environment_id!,
                        configuration: {
                            membership_id: membershipId!,
                            name: name,
                            type: configTypeMap.get(selectedConfigType) || '',
                            details
                        }
                    }
                })
                history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${selectedConfigType}`)
            }
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
    }

    // Log Stream does not support azure
    useEffect(() => {
        if(selectedConfigType === 'logstream') setSelectedProvider('aws');
    }, [selectedConfigType]);

    const disableProviderSelector = selectedConfigType === 'logstream'; // Cloud hsm will be left to azure temporarely;

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">{lt('header')}</Typography>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <TextField fullWidth select label={lt('configurationType')} value={selectedConfigType} variant="outlined" onChange={e => setSelectedConfigType(e.target.value as CloudConfigTypesUrl)}>
                        {configTypesOptions.map((entry, index) => (
                            <MenuItem key={`type-${index}`} value={entry}>{lt(entry)}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                { providerOptions.length !== 0 && (
                    <Grid item>
                        <TextField select 
                            fullWidth
                            data-test='textField_selectProvider'
                            disabled={disableProviderSelector} 
                            label={lt('configurationProvider')} 
                            value={selectedProvider} 
                            variant="outlined" 
                            onChange={e => setSelectedProvider(e.target.value as ProviderType)}>
                                {providerOptions.map((entry, index) => (
                                    <MenuItem key={`provider-${index}`} value={entry}>{lt(entry)}</MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                )}
                { selectedConfigType === 'keystore' && <CreateKeyStore provider={selectedProvider} {...{register}} /> }
                { (selectedConfigType === 'backup' || selectedConfigType === 'storage')  && <CreateBackUp provider={selectedProvider} {...{register}} /> }
                { selectedConfigType === 'logstream' && <CreateLogStream {...{register}} /> }
                { selectedConfigType === 'networking' && <CreateNetworking {...{register}} />}
                { selectedConfigType === 'cloudhsm' && <CreateCloudHsm {...{register}} provider={selectedProvider} /> }
            </Grid>
        </>
    );

    return (
        <>  
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} disabled={loading} onNext={handleSubmit(onNext)} isLastStep />
            { selectedConfigType === 'keystore' && <KeyStoreHelp /> }
            { selectedConfigType === 'backup' && <BackupStorageHelp configType="backup" /> }
            { selectedConfigType === 'storage' && <BackupStorageHelp configType="storage" /> }
            { selectedConfigType === 'logstream' && <LogStreamHelp /> }
            { selectedConfigType === 'networking' && <NetworkHelp />}
            { selectedConfigType === 'cloudhsm' && <CloudHsmHelp />}
            { selectedConfigType === ''  && <ConfigsHelp />}
        </>
    )
}

interface translations extends ConfigTypeTranslationsInterface {
    header: string,
    configurationType: string,
    configurationProvider: string
    aws: string
    aws_kms: string
    azure: string
    hashicorp: string
}
const enTranslations: translations = {
    ...ConfigTypeTranslations,
    header: 'Enter Information',
    aws: 'AWS',
    azure: 'Azure',
    hashicorp: 'Hashicorp',
    aws_kms: 'AWS KMS',
    configurationType: 'Configuration Type',
    configurationProvider: 'Configuration Provider',
}
