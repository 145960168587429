import React, { useState, useMemo } from 'react';
import { Service, Node, UpdateServiceVars, UpdateServiceMutation, ServiceDetails, NodeConfigs, UpdateNodeMutation, UpdateNodeVars } from '../../../models';
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher } from '../../../components/DialogWrappers';
import { Grid, Typography, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Config, ConfigTypes } from '../../../models/configs';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { CreateStepProps } from '../../../interfaces';

interface Props extends CreateStepProps {
    availableRuntimes: (Service | Node)[],
    config: Config | null
}

export const Step1 = ({availableRuntimes, config, cancelPath}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AttachRuntimesStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AttachRuntimesStep1:${key}`, interpolate)

    const [selectedRuntimeId, setSelectedRuntimeId] = useState('');
    const [errorMessage, setErrorMessasge] = useState('');

    const { environment_id, consortium_id } = useParams<any>();

    const history = useHistory();

    const instanceOfService = (object: any): object is Service => {
        if(object) {
            return 'service' in object;
        }
        return false
    };
    
    const isService = useMemo(() => {
        const runtime = availableRuntimes.find(entry => entry._id === selectedRuntimeId);
        return instanceOfService(runtime)
    }, [selectedRuntimeId, availableRuntimes]);

    const [updateService, {loading: serviceLoading}] = useMutation<any, UpdateServiceVars>(UpdateServiceMutation);
    const [updateNode, {loading: nodeLoading}] = useMutation<any, UpdateNodeVars>(UpdateNodeMutation);

    const detailsForm: Map<ConfigTypes | undefined, ServiceDetails | NodeConfigs>= new Map([
        [ 'backup', { backup_id: config?._id }],
        [ 'opsmetric', { opsmetric_id: config?._id}],
        [ 'kms', { kms_id: config?._id}],
        [ 'networking', { networking_id: config?._id}],
        [ 'storage', { storage_id: config?._id}],
        [ 'baf', { baf_id: config?._id}],
        [ 'node_config', { node_config_id: config?._id}]
    ])

    const mutationVariables = {
        environment_id: environment_id!,
        consortia_id: consortium_id!
    }

    const onNext = async () => {
        try {
            if (isService) {
                const details = detailsForm.get(config?.type) as ServiceDetails;
                await updateService({
                    variables: {
                        ...mutationVariables,
                        id: selectedRuntimeId,
                        service: {
                            details
                        }
                    }
                })
            } else {
                const details = detailsForm.get(config?.type) as NodeConfigs;
                await updateNode({
                    variables: {
                        ...mutationVariables,
                        id: selectedRuntimeId,
                        node: {
                            ...details
                        }
                    }
                })
            }
            history.push(cancelPath)
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    }

    const getName = (runtime: Service | Node ) => {
        return instanceOfService(runtime) && runtime.service === 'idregistry' 
            ? lt('idRegistry') 
            : lt('noRuntimeName', {id: runtime._id})
    }

    const selected = availableRuntimes?.find(entry => entry._id === selectedRuntimeId) ? selectedRuntimeId : '';

    const loading = nodeLoading || serviceLoading

    const content = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Typography>{lt('selectAvailableRuntimes')}</Typography>
                </Grid>
                <Grid item>
                    <TextField data-test="textField_AttachRuntime"
                        disabled={availableRuntimes.length === 0} select fullWidth variant="outlined" label={lt('runtime')} value={selected} onChange={e => setSelectedRuntimeId(e.target.value)}>
                        {availableRuntimes?.map( (entry, index) => (
                            <MenuItem key={index} value={entry._id}>{entry.name || getName(entry)}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {
                    availableRuntimes.length === 0 && !loading && (
                        <Grid item>
                            <Typography color="error">{lt('noRuntimes')}</Typography>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    )

    return(
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge}/>
            <CreateWrapper cancelPath={cancelPath} disabled={!selectedRuntimeId|| loading} {...{content}} {...{onNext}} isLastStep/>
        </>
    )
}

interface translations {
    header: string,
    selectAvailableRuntimes: string,
    runtime: string,
    noRuntimeName: string,
    noRuntimes: string,
    idRegistry: string
}
const enTranslations: translations = {
    header: 'Attach Config to runtime',
    selectAvailableRuntimes: 'Select Available Runtimes',
    runtime: "Runtime",
    noRuntimeName: "No Name Provided: {{id}}",
    noRuntimes: 'No more runtimes available',
    idRegistry: 'Id Registry'
}
