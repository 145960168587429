import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { Dotdotdot } from '../../components';
import { Config } from '../../models/configs';

interface Props {
    config: Config,
    onDeleteClick: (destName: string, configName: string) => void,
}

export const ConfigMenu = ({config, onDeleteClick}: Props) => {
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConfigMenu', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConfigMenu:${key}`, interpolate);

    const menuItems = [{
        name: lt('deleteDestination'),
        icon: <DeleteIcon />,
        action: () => onDeleteClick(config._id, config.name)
    }]

    return (
        <>
            <Dotdotdot {...{menuItems}} />
        </>
    )
}

interface translations {
    deleteDestination: string,
}

const enTranslations: translations = {
    deleteDestination: 'Delete',
}