import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers';
import { EnvironmentProvider } from '../../models';

interface Props {
    provider?: EnvironmentProvider,
}

export const Step4Help = ({ provider } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep4Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep4Help:${key}`, interpolate)

    const isCorda = provider?.includes('corda');

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-SizeRole.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={isCorda ? undefined : lt('helpDesc2')}
            docLink="https://docs.kaleido.io/using-kaleido/resource-limits"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
}
const enTranslations: translations = {
    helpHeader: 'Node Settings',
    helpDescription: 'The last piece of configuration before you start your node',
    helpDesc1: `Each size has a direct correlation to ingress rate limiting and underlying memory CPU.`,
    helpDesc2: 'The role of "signer" refers to the node\'s responsibility in the environment\'s consensus algorithm. Any signing node will append its digital signature to the proposed block\'s header during IBFT and clique PoA voting rounds.',
}