import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const AddNamespaceHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AddNamespaceFireflyTemplateHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddNamespaceFireflyTemplateHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Firefly.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            bullet4={lt('helpDesc4')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/kaleido-platform/firefly/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    helpDesc4: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'Add Namespace to FireFly',
    helpDescription:
        'Namespaces are a construct for segregating data and operations within a FireFly supernode. Each namespace is an isolated environment within a FireFly runtime, that allows independent configuration of:',
    helpDesc1: 'Plugin and infrastructure components',
    helpDesc2:
        'API security',
    helpDesc3:
        'Identity broadcasting',
    helpDesc4:
        'On-chain data indexing',
    finalInfo: 'Clicking Add will create the new namespace, which you will be able to switch to on the top right namespace selection of the FireFly Web UI.',
};
