import React, { useState } from 'react';
import { useMutation, FetchResult, useApolloClient } from '@apollo/client';
import { useHistory, useParams } from "react-router-dom";
import { ZoneRegion, UserMembershipsQuery, OrganizationQuery, OrganizationData, OrganizationVars } from '../../models'
import { CreateConsortiumData, CreateConsortiumVars, CreateConsortiumMutation, MakeConsortiumCreateMutationOptions,
    CreateConsortiumZoneData, CreateConsortiumZoneVars, CreateConsortiumZoneMutation, MakeConsortiumZoneCreateMutationOptions } from '../../models'
import { MessageSnackbar, ErrorSnackbarCatcher, CreateWrapper } from '../../components/DialogWrappers'
import { CreateStepProps } from '../../interfaces';
import { AddRegions } from '../../components/Regions/AddRegions';
import { AddRegionsHelp } from '../../components/Regions/AddRegionsHelp';

interface Props extends CreateStepProps {
    name: string
    description: string,
    homeRegion: string,
    regions: ZoneRegion[]
};

export const Step3 = ({ name, description, homeRegion, regions, cancelPath }: Props) => {
    const history = useHistory()
    const { org_id } = useParams<any>();
    const client = useApolloClient()

    const [message, setMessage] = useState('');
    const [additionalRegions, setAdditionalRegions] = useState<string[]>([])
    const [refreshingMemberships, setRefreshingMemberships] = useState(false)

    // direct read from cache
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!

    const [createConsortium, { loading: createConsortiumLoading }] = 
        useMutation<CreateConsortiumData, CreateConsortiumVars>(CreateConsortiumMutation)
    const [createConsortiumZone, { loading: createConsortiumZoneLoading }] = 
        useMutation<CreateConsortiumZoneData, CreateConsortiumZoneVars>(CreateConsortiumZoneMutation)

    const save = async () => {
        // create the consortium
        const region = regions.find(r => r.region === homeRegion)
        const c = await createConsortium(MakeConsortiumCreateMutationOptions({
            consortium: {
                name,
                description,
                owner: org_id!,
                region: region?.code ?? '',
                org_name: organization.name
            }
        })).then(async (c) => {
            // after creating the consortium, await refreshing our cache with the users memberships
            setRefreshingMemberships(true)
            await client.query({
                query: UserMembershipsQuery,
                fetchPolicy: 'network-only'
            })
            return c
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
            setRefreshingMemberships(false)
            return null
        })
        
        // create the additional regions 
        const consortiaId = c?.data?.createConsortium?._id
        if (consortiaId) {
            const zonePromises = [] as Promise<FetchResult<CreateConsortiumZoneData>>[];

            additionalRegions.forEach(ar => {
                const { region, cloud } = regions.find(r => r.region === ar)!
                zonePromises.push(createConsortiumZone(MakeConsortiumZoneCreateMutationOptions({
                    consortia_id: consortiaId,
                    consortiumZone: {
                        region,
                        cloud: cloud.toLowerCase(),
                        type: 'kaleido'
                    }
                })))
            })

            Promise.all(zonePromises).then(() => history.push(`/orgs/${org_id}/consortia/${consortiaId}`)).catch(e => {
                ErrorSnackbarCatcher(e, setMessage)
                return null
            })
        }
    }

    const content = <AddRegions {...{homeRegion}} {...{regions}} {...{additionalRegions}} {...{setAdditionalRegions}} />
    const disabled = homeRegion && !createConsortiumLoading && !createConsortiumZoneLoading && !refreshingMemberships ? false : true

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} isLastStep />
            <AddRegionsHelp />
        </>
    )
};
