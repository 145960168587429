import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step4HelpCorda = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep4HelpCorda', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep4HelpCorda:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Environment-MinReqs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('helpMonitorNode')}
            docLink="https://docs.kaleido.io/kaleido-platform/protocol/corda"
        />
    )
};


interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpMonitorNode: string,
}
const enTranslations: translations = {
    helpHeader: 'Consensus in Corda',
    helpDescription: 'A transaction must pass through two forms of consensus to reach the ledger:',
    helpDesc1: 'Validity consensus - the business rules of the CorDapp determine which participants must verify and sign the transaction before it can complete',
    helpDesc2: 'Uniqueness consensus - a notary service ensures that no other transactions have been processed that invalidate the result',
    helpMonitorNode: 'Kaleido automatically provides a highly-available notary service that is available to all network participants.',
}