import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentsData, EnvironmentsQuery, CompiledContract, ContractProject, isCordaPromotable, isFabricPromotable, isEthPromotable } from '../../models'
import { DisplayTable } from '../../components/DisplayWrappers'
import { useParams } from 'react-router-dom';
import { ConsortiumResourcesVars } from '../../interfaces'
import { useQuery } from '@apollo/client';
import { PromotedEnvironment } from './PromotedEnvironment';

interface Props {
    contractProject: ContractProject
    compiledContract: CompiledContract
    promotedEnvironments: string[]
    setPromotedEnvironments: React.Dispatch<React.SetStateAction<string[]>>,
};

export const PromotedEnvironments = ({ contractProject, compiledContract, promotedEnvironments, setPromotedEnvironments }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CompiledContractPromotedEnvironments', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CompiledContractPromotedEnvironments:${key}`, interpolate)

    const { consortium_id } = useParams<any>();

    const {
        data: {
            environments
        } = { environments: [] }
    } = useQuery<EnvironmentsData, ConsortiumResourcesVars>(EnvironmentsQuery, {
        variables: { 
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const columnHeaders = [
        '',
        lt('environment'),
        lt('status'),
        ''
    ]

    const preBuiltRows = (
        <>
            { environments.filter(
                env => isCordaPromotable(env.isCorda, contractProject?.type) || 
                isFabricPromotable(env.isFabric, contractProject?.type) || 
                isEthPromotable(env.isEthereum, contractProject?.type)).map(environment => 
                <PromotedEnvironment {...{setPromotedEnvironments}} {...{promotedEnvironments}} key={environment._id} {...{compiledContract}} {...{environment}} />) }
        </>
    )
    
    return (
        <DisplayTable {...{columnHeaders}} header={lt('promotedEnvironmentsHeader')} {...{preBuiltRows}} maxHeight='extend' />
    )
};

interface translations {
    promotedEnvironmentsHeader: string
    environment: string
    status: string
}
const enTranslations: translations = {
    promotedEnvironmentsHeader: 'Promotions',
    environment: 'Environment',
    status: 'Status'
}