import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { GithubCompilationProps } from '../../components/ContractManagement/Github';
import { DeleteResource } from '../../components/DialogWrappers/DeleteResource';
import { UpdateName } from '../../components/DialogWrappers/UpdateName';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { FormLink } from '../../components/FormControls/FormLink';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { NETWORK_COMPILED_CONTRACTS_PATH, NETWORK_COMPILED_CONTRACTS_PROMOTE_PATH } from '../../components/MainNav/ConsortiumWrapper';
import { ConsortiumResourceVars, ConsortiumResourcesVars } from '../../interfaces';
import { CompiledContractData, CompiledContractQuery, CompiledContractVars, ContractProjectData, ContractProjectQuery, EnvironmentsData, EnvironmentsQuery, CompiledContract as CompiledContractDef, UpdateCompiledContractVars, UpdateCompiledContractMutation, isFabricPromotable, isCordaPromotable, isEthPromotable } from '../../models';
import { PrecompiledCompilationProps } from './CreateCompilation/CreatePrecompiled';
import { PromotedEnvironments } from './PromotedEnvironments';
import { FormDialog } from '../../components/DialogWrappers';
import { DisplayCard } from '../../components/DisplayWrappers';
import KeyboardArrowRightIcon from 'mdi-react/KeyboardArrowRightIcon';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';

export const CompiledContract = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCompiledContract', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCompiledContract:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, contract_id, compiled_contract_id } = useParams<any>();

    const [updateDescriptionDialogOpen, setUpdateDescriptionDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [promotedEnvironments, setPromotedEnvironments] = useState<string[]>([]);

    const [updateABIDialogOpen, setUpdateABIDialogOpen] = useState(false);
    const [newABI, setNewABI] = useState('');
    const [updateDevDocsDialogOpen, setUpdateDevDocsDialogOpen] = useState(false);
    const [newDevDocs, setNewDevDocs] = useState('');

    const recompileRedirect = pathname.replace(`/${NETWORK_COMPILED_CONTRACTS_PATH}/${compiled_contract_id}`, `/${NETWORK_COMPILED_CONTRACTS_PATH}/create/1`)
    const contractProjectRedirect = pathname.replace(`/${NETWORK_COMPILED_CONTRACTS_PATH}/${compiled_contract_id}`, '')
    const promoteCompilationRedirect = `${pathname}/${NETWORK_COMPILED_CONTRACTS_PROMOTE_PATH}/1`

    const consortiaVars = { 
        consortia_id: consortium_id!,
    }
    const contractVars = {
        ...consortiaVars,
        id: contract_id!
    }
    const compiledContractVars = { 
        ...consortiaVars,
        contract_id: contract_id!,
        id: compiled_contract_id!
    }

    

    const {
        data: {
            compiledContract
        } = { compiledContract: null }
    } = useQuery<CompiledContractData, CompiledContractVars>(CompiledContractQuery, {
        variables: compiledContractVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            contractProject
        } = { contractProject: null }
    } = useQuery<ContractProjectData, ConsortiumResourceVars>(ContractProjectQuery, {
        variables: contractVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environments
        } = { environments: [] }
    } = useQuery<EnvironmentsData, ConsortiumResourcesVars>(EnvironmentsQuery, {
        variables: { 
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });


    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('contractManagement'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/smart-contracts/')
        }
    ];
    
    
    const canPromote = environments.some(env => (
        env.state === 'live' && !promotedEnvironments.includes(env._id)) && (
            isCordaPromotable(env.isCorda, contractProject?.type) || 
            isFabricPromotable(env.isFabric, contractProject?.type) || 
            isEthPromotable(env.isEthereum, contractProject?.type)
        )
    )

    // errors are deleted off of the websocket event for storage reasons.
    // unfortunately, we need to refetch if we receive the event saying the compilation failed.
    
    // we refetch in a separate, lazy query (which will update the cached compilation with the errors),
    // since we cant refetch() on a cache-only fetch policy.
    
    // we want to keep the cache-only fetch policy on the main query so that when the compilation is deleted, and evicted, 
    // it wont refetch from the server as it would with a cache-first fetch policy.
    const [getFailedCompiledContract] = useLazyQuery<CompiledContractData, CompiledContractVars>(CompiledContractQuery, {
        variables: compiledContractVars,
        fetchPolicy: 'network-only'
    });

    const [updateCompiledContract, { loading: updateCompiledContractLoading }] = useMutation<CompiledContractDef, UpdateCompiledContractVars>(UpdateCompiledContractMutation)

    useEffect(() => {
        if (compiledContract?.state === 'failed' && !compiledContract?.errors?.length) {
            getFailedCompiledContract()
        }
    }, [compiledContract, getFailedCompiledContract])

    useEffect(() => {
        if (compiledContract && updateABIDialogOpen) {
            let abi = compiledContract.abi || '';
            if (abi) {
                try {
                    abi = JSON.stringify(JSON.parse(abi), null, 2);
                } catch(err) {
                    // we just use the original String
                }
            }
            setNewABI(abi);
        }
    }, [updateABIDialogOpen, compiledContract])

    useEffect(() => {
        if (compiledContract && updateDevDocsDialogOpen) {
            let devDocs = compiledContract.dev_docs || '';
            if (devDocs) {
                try {
                    devDocs = JSON.stringify(JSON.parse(devDocs), null, 2);
                } catch(err) {
                    // we just use the original String
                }
            }
            setNewABI(devDocs);
        }
    }, [updateDevDocsDialogOpen, compiledContract])

    // check the compiled_contract_id is valid
    if (!compiledContract || !contractProject) {
        return <Redirect to={contractProjectRedirect} />
    }

    const isCompiled = compiledContract.state === 'compiled'

    const copyableList: CopyableSetting[] = [
        { title: lt('id'), displayValue: compiledContract._id },
        { title: lt('description'), displayValue: compiledContract.description, 
            disableCopy: true, editOnClick: isCompiled ? () => setUpdateDescriptionDialogOpen(true) : undefined },
        { title: lt('status'), displayValue: <ResourceStateChip state={compiledContract.state} />, disableCopy: true },
        { title: lt('owner'), displayValue: compiledContract.membership.name, disableCopy: true },
        { title: lt('createdAt'), displayValue: new Date(compiledContract.created_at).toLocaleString(), disableCopy: true },
    ]

    if (compiledContract.contract_url) {
        copyableList.push({ 
            title: lt('sourceCodeUrl'), 
            displayValue: (
                <FormLink isExternal={true} to={compiledContract.contract_url} target="_blank">
                    {compiledContract.contract_url}
                </FormLink>
            ),
            copyableValue: compiledContract.contract_url,
            disableCopy: true
        })
    }
    if (compiledContract.contract_name) {
        copyableList.push({ title: lt('contractToBeCompiled'), displayValue: compiledContract.contract_name })
    }
    if (compiledContract.commit_hash) {
        copyableList.push({ title: lt('commitHash'), displayValue: compiledContract.commit_hash })
    }
    if (compiledContract.errors) {
        copyableList.push({ title: lt('errors'), displayValue: compiledContract.errors })
    }
    if (compiledContract.solc_version) {
        copyableList.push({ title: lt('solcVersion'), displayValue: compiledContract.solc_version, disableCopy: true })
    }
    if (compiledContract.evm_version) {
        copyableList.push({ title: lt('evmVersion'), displayValue: compiledContract.evm_version, disableCopy: true })
    }

    const makeTextField = (s: string | undefined) => (
        <TextField multiline rows={4} value={s} 
            disabled fullWidth margin="none" variant="outlined"
        />
    )

    if (compiledContract.abi) {
        copyableList.push({ 
            title: lt('abi'), 
            displayValue: makeTextField(compiledContract.abi),
            copyableValue: compiledContract.abi,
            editOnClick: () => setUpdateABIDialogOpen(true),
        })
    }
    if (compiledContract.bytecode) {
        copyableList.push({ 
            title: lt('bytecode'), 
            displayValue: makeTextField(compiledContract.bytecode),
            copyableValue: compiledContract.bytecode
        })
    }
    if (compiledContract.dev_docs) {
        copyableList.push({ 
            title: lt('devDocs'), 
            displayValue: makeTextField(compiledContract.dev_docs),
            copyableValue: compiledContract.dev_docs,
            editOnClick: () => setUpdateDevDocsDialogOpen(true),
        })
    }

    const incrementDescription = () => {
        return `${compiledContract.description} ${lt('incrementDescription')}`
    }

    const recompile = () => {
        if (compiledContract.contract_url) {
            const githubState: GithubCompilationProps = {
                description: incrementDescription(),
                membershipId: compiledContract.membership_id,
                githubUrl: compiledContract.contract_url,
                contractToCompile: compiledContract.contract_name,
                solcVersion: compiledContract.solc_version,
                evmVersion: compiledContract.evm_version
            }
            history.push(recompileRedirect, githubState)
        } else {
            const precompiledState: PrecompiledCompilationProps = {
                description: incrementDescription(),
                membershipId: compiledContract.membership_id
            }
            history.push(recompileRedirect, precompiledState)
        }
    }

    const actionBar = (
        <Grid item container spacing={3} direction="row" alignItems="center">
            {contractProject?.type === 'github' && <Grid item>
                <Button color="primary" size="large" variant="contained" onClick={recompile}>
                    {lt('recompile')}
                </Button>
            </Grid>}
            {compiledContract.state === 'failed' && 
            <Grid item>
                <Button color="default" size="medium" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
                    {lt('deleteCompilation')}
                </Button>
            </Grid>
            }
        </Grid>
    )

    const content = (
        <>
            <Grid item xs={12} lg={isCompiled ? 8 : 12}>
                <CopyableSettings {...{actionBar}} header={lt('versionInfo')} {...{copyableList}} />
            </Grid>
            <Grid item xs={6} lg={4}>
                <DisplayCard header={lt('documentation')} itemList={DocumentationList} />
            </Grid>
        </>
    )

    const saveUpdates = async (field: 'dev_docs' | 'abi', newValue: string): Promise<void> => {
        await updateCompiledContract({
            variables: {
                ...compiledContractVars,
                compiledContract: {
                    [field]: newValue
                }
            }
        });
    }

    const updateABIControls = 
    <>
        <TextField 
            required
            multiline
            rows={20}
            value={newABI} 
            onChange={event => setNewABI(event.target.value)}
            fullWidth
            margin="none"
            label={lt('abi')}
            variant="outlined"
        />
    </>

    const updateDevDocsControls = 
    <>
        <TextField 
            required
            multiline
            rows={20}
            value={newDevDocs} 
            onChange={event => setNewDevDocs(event.target.value)}
            fullWidth
            margin="none"
            label={lt('devDocs')}
            variant="outlined"
        />
    </>

    const updateABIDialog = <FormDialog
        open={updateABIDialogOpen}
        setOpen={setUpdateABIDialogOpen}
        header={lt('updateABI')} 
        description={<Trans i18nKey="ContractsCompiledContract:updateABIDescription"
            components={[<FormLink to="https://docs.kaleido.io/kaleido-services/ethconnect/rest-apis/#smart-contract-management-integration" isExternal={true} target="_blank" />]}>
        </Trans>} 
        controlsWrapper={updateABIControls} 
        onSave={() => saveUpdates('abi', newABI)}
        saveDisabled={updateCompiledContractLoading}
        closeDialogAfterSave />
    
    const updateDevDocsDialog = <FormDialog
        open={updateDevDocsDialogOpen}
        setOpen={setUpdateDevDocsDialogOpen}
        header={lt('updateDevDocs')} 
        description={lt('updateDevDocsDescription')} 
        controlsWrapper={updateDevDocsControls} 
        onSave={() => saveUpdates('dev_docs', newDevDocs)}
        saveDisabled={updateCompiledContractLoading}
        closeDialogAfterSave />
                
    return (
        <>
            <UpdateName fieldNameToUpdate="description" defaultName={compiledContract.description} open={updateDescriptionDialogOpen} setOpen={setUpdateDescriptionDialogOpen} />
            <DeleteResource disableNameConfirmation name={compiledContract.description?.slice(0, 30)} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />
            {updateABIDialog}
            {updateDevDocsDialog}
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('versionDetails')}
                        </Typography>
                    </Grid>
                    { compiledContract.state === "compiled" && 
                    <Grid item>
                        <Button disabled={!canPromote} color="primary" variant="contained" size="large" onClick={() => history.push(promoteCompilationRedirect, { promotedEnvironments })}>
                            {lt('promote')}
                        </Button>
                    </Grid>
                    }
                </Grid>
                <Grid item container spacing={3}>
                    {content}
                </Grid>
                {isCompiled && 
                    <Grid item container>
                        <PromotedEnvironments {...{promotedEnvironments}} {...{setPromotedEnvironments}} {...{compiledContract}} {...{contractProject}} />
                    </Grid>
                }
            </Grid>
        </>
    )
};

interface translations {
    versionInfo: string,
    versionDetails: string,
    promote: string,
    description: string,
    owner: string,
    createdAt: string,
    status: string,
    solcVersion: string,
    evmVersion: string,
    abi: string,
    bytecode: string,
    devDocs: string,
    sourceCodeUrl: string,
    commitHash: string,
    id: string,
    errors: string,
    deleteCompilation: string,
    recompile: string,
    contractToBeCompiled: string,
    incrementDescription: string,
    updateABI: string,
    updateABIDescription: string,
    updateDevDocs: string,
    updateDevDocsDescription: string,
    documentation: string,
    contractManagement: string
}
const enTranslations: translations = {
    versionInfo: 'Information',
    versionDetails: 'Version Details',
    promote: 'Promote to Environment',
    solcVersion: 'SOLC version',
    evmVersion: 'EVM version',
    description: 'Description',
    owner: 'Owner',
    createdAt: 'Created at',
    status: 'Status',
    abi: 'ABI',
    bytecode: 'Bytecode',
    devDocs: 'Dev docs',
    sourceCodeUrl: 'Source code URL',
    commitHash: 'Commit hash',
    id: 'ID',
    errors: 'Compilation errors',
    deleteCompilation: 'Delete version',
    recompile: 'Recompile',
    contractToBeCompiled: 'Contract to be compiled',
    incrementDescription: '(recompiled)',
    updateABI: 'Update ABI',
    updateABIDescription: 'The Gateway API instance of each node will need to be refreshed after updating the ABI. <0>More info</0>',
    updateDevDocs: 'Update Dev Docs',
    updateDevDocsDescription: 'Update the Dev Docs JSON for the compiled contract.',
    documentation: 'Documentation',
    contractManagement: 'Contract Management'
}