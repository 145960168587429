import React, { useState, useMemo } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EditableSettings } from '../../components/DisplaySettings';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
    OrganizationData,
    OrganizationQuery,
    RequireMFAOrgMutation,
    Organization,
    RequireOrgMFAVars,
    FeaturesEnum,
} from '../../models';
import { FormDialog } from '../../components/DialogWrappers';
import SecurityIcon from 'mdi-react/SecurityIcon';
import { MFATypeQuery, MFATypeData } from '../../models/mfa';
import {
    PROFILE_SECURITY_PATH,
    PROFILE_SETTINGS_PATH,
} from '../../components/ProfileSettingsNav/ProfileSettingsNav';
import { PayWallBanner } from '../../components/PayWall/PayWallBanner';
import { InfoBanner } from '../../components/Banners/InfoBanner';
import { HelpCenter, HelpCenterTab } from '../../components/HelpCenter/HelpCenter';

export const OrgSecurity: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgSecurity', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`OrgSecurity:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const history = useHistory();

    const [openSetMFARequired, setOpenSetMFARequired] = useState(false);
    const [helpCenter, setHelpCenter] = useState(false);
    
    const { data: { mfaType } = { mfaType: '' } } = useQuery<MFATypeData>(
        MFATypeQuery,
        { fetchPolicy: 'cache-and-network' }
    );

    const { data: { organization } = { organization: null } } = useQuery<
        OrganizationData
    >(OrganizationQuery, {
        variables: {
            id: org_id,
        },
    });

    const userMFAEnable = mfaType === 'totp';
    const isMFAAllowed = organization?.limits.features?.allowed_features.includes(FeaturesEnum.mfa);

    const orgMFAEnforce = useMemo(() => {
        return !!organization?.mfa_required;
    }, [organization]);

    const [requireMFA, { loading }] = useMutation<
        Organization,
        RequireOrgMFAVars
    >(RequireMFAOrgMutation);

    const actionList = [
        {
            icon: <SecurityIcon />,
            title: lt('mfa'),
            disabledButton: !isMFAAllowed,
            value: organization?.mfa_required
                ? lt('required')
                : lt('notRequired'),
            action: () => setOpenSetMFARequired(true),
            buttonLabel: lt('change'),
            buttonTestData: 'renameOrgButton',
        },
    ];

    const enableMFADescription = () => {
        if (!userMFAEnable) return lt('enableMFADescription');
        if (orgMFAEnforce) return lt('disableMFARequiredDescription');
        return lt('enableMFARequiredDescription');
    };

    const onSave = async () => {
        await requireMFA({
            variables: {
                id: org_id,
                MFARequired: {
                    isRequired: orgMFAEnforce ? false : true,
                }
            },
        });
    };

    const enableMFAComponent = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="body2">
                    {lt("enableMFADescription")}
                </Typography>
            </Grid>
            <Grid item container justify="center">
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            history.push(
                                `/${PROFILE_SETTINGS_PATH}/${PROFILE_SECURITY_PATH}`
                            )
                        }
                    >
                        {lt("goToProfileSecurity")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt('organizationSecurity')}
                    </Typography>
                </Grid>
                <Grid item sm={8} container direction="column" spacing={1}>
                    <Grid item xs>
                        <EditableSettings actionsList={actionList} />
                    </Grid>
                    { !isMFAAllowed && (
                        <Grid item>
                            <PayWallBanner description={lt('planInsufficient')} />
                        </Grid>
                    )}
                    <Grid item>
                        <InfoBanner linkButton={{ text: lt('contactUs'), onClick: () => setHelpCenter(true) }} title={lt('oidcTitle')} description={lt('oidcDescription')} paperWrapBackground />
                    </Grid>
                    <HelpCenter
                        isOpen={helpCenter}
                        setOpen={setHelpCenter}
                        tab={HelpCenterTab.CONTACT_US}
                        preSelectedCategory='salesEnquiry'
                    />
                </Grid>
            </Grid>
            <FormDialog
                open={openSetMFARequired}
                setOpen={setOpenSetMFARequired}
                header={lt('mfa')}
                description={userMFAEnable ? enableMFADescription() : undefined}
                onSave={onSave}
                hideSave={!userMFAEnable}
                saveText={lt('apply')}
                controlsWrapper={
                    !userMFAEnable ? enableMFAComponent : undefined
                }
                saveDisabled={loading}
                closeDialogAfterSave={true}
            />
        </>
    );
};

interface translations {
    organizationSecurity: string
    required: string
    notRequired: string
    mfa: string
    change: string
    enableMFADescription: string
    disableMFARequiredDescription: string
    enableMFARequiredDescription: string
    goToProfileSecurity: string
    apply: string
    planInsufficient: string
    oidcTitle: string
    oidcDescription: string
    contactUs: string
}

const enTranslations: translations = {
    organizationSecurity: 'Organization Security',
    required: 'Required',
    notRequired: 'Not Required',
    mfa: 'Multi-Factor Authentication',
    enableMFADescription: 'Multi-Factor Authentication must be enabled in profile security before setting it as an organization requirement.',
    change: 'Change',
    disableMFARequiredDescription: 'Members of this organization will not be required to have Multi-Factor Authentication enabled.',
    enableMFARequiredDescription: 'Members of this organization will be required to have Multi-Factor Authentication enabled.',
    goToProfileSecurity: 'Go to profile security',
    apply: 'Apply',
    planInsufficient: 'Some features are not allowed in the current plan.',
    oidcTitle: 'Single Sign On (SSO)',
    oidcDescription: 'Contact us if you are interested in Kaleido\'s OpenId Connect (OIDC) integration which supports user authentication though your organization\'s OIDC compliant identity provider.',
    contactUs: 'Contact support'
};
