import { useQuery, useApolloClient } from "@apollo/client";
import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import CardBulletedOutlineIcon from "mdi-react/CardBulletedOutlineIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import CogOutlineIcon from "mdi-react/CogOutlineIcon";
import SecurityIcon from "mdi-react/SecurityIcon";
import ViewDashboardOutlineIcon from "mdi-react/ViewDashboardOutlineIcon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
    FeatureToggles,
    FeatureTogglesData,
    FeatureTogglesQuery, FeatureTogglesVars, OrganizationData, OrganizationVars, OrganizationQuery, RolesData, RolesQuery
} from "../../models";
import { SubNavs } from "../MainNav/SideNavs/SubNavs";
import { NavItem } from "../NavComponents";
import { OrgPicker } from "./OrgPicker";
import { SessionData } from "../../interfaces";
import { SessionQuery } from "../../queries/Session";

export const ORGS_PATH = "orgs";
export const CREATE_ORG_PATH = "create";
export const MANAGE_ORG_PATH = "manage";

export const MANAGE_ORG_SUBSCRIPTION_PATH = "subscription";
export const MANAGE_ORG_BILLING_PATH = "billing";
export const MANAGE_ORG_PAYMENT_METHOD_PATH = "payment";
export const MANAGE_ORG_INVOICES_PATH = "invoices";
export const MANAGE_ORG_MANAGE_USERS_PATH = "users";
export const MANAGE_ORG_SECURITY_PATH = "security";
export const MANAGE_ORG_SETTINGS_PATH = "settings";

export const CHANGE_PLAN_PATH = "change";
export const SETUP_BILLING_PROVIDER_PATH = "setup";

export const ManageOrgNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "ManageOrgNav", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`ManageOrgNav:${key}`, interpolate);
    const { org_id } = useParams<any>();

    const {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-first'
    });

    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    const { organization } = client.cache.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id} })!;
    const isOrgOwner = organization.owner === session.user_id;

    const {
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, { variables: { org_id }, fetchPolicy: 'cache-and-network', skip: isOrgOwner });

    const DASHBOARD = lt("dashboard");
    const SUBSCRIPTIONS = lt("subscription");
    const BILLING = lt("billingAndUsage");
    const PAYMENT_METHOD = lt("paymentMethod");
    const INVOICES = lt("invoices");
    const MANAGE_USERS = lt("manageUsers");
    const SECURITY = lt("security");
    const SETTINGS = lt("settings");

    const history = useHistory();
    const { pathname } = useLocation();

    const [selectedItem, setSelectedItem] = useState("");
    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
        if (pathname.endsWith(MANAGE_ORG_PATH)) {
            setSelectedItem(DASHBOARD);
            setActive(false);
        }
        if (pathname.endsWith(MANAGE_ORG_SUBSCRIPTION_PATH)) {
            setSelectedItem(SUBSCRIPTIONS);
            setActive(true);
        }
        if (pathname.endsWith(MANAGE_ORG_BILLING_PATH)) {
            setSelectedItem(BILLING);
            setActive(true);
        }
        if (pathname.endsWith(MANAGE_ORG_PAYMENT_METHOD_PATH)) {
            setSelectedItem(PAYMENT_METHOD);
            setActive(true);
        }
        if (pathname.endsWith(MANAGE_ORG_INVOICES_PATH)) {
            setSelectedItem(INVOICES);
            setActive(true);
        }
        if (pathname.endsWith(MANAGE_ORG_MANAGE_USERS_PATH)) {
            setSelectedItem(MANAGE_USERS);
            setActive(false);
        }
        if (pathname.endsWith(MANAGE_ORG_SECURITY_PATH)) {
            setSelectedItem(SECURITY);
            setActive(false);
        }
        if (pathname.endsWith(MANAGE_ORG_SETTINGS_PATH)) {
            setSelectedItem(SETTINGS);
            setActive(false);
        }
    }, [
        pathname,
        DASHBOARD,
        SUBSCRIPTIONS,
        BILLING,
        PAYMENT_METHOD,
        INVOICES,
        MANAGE_USERS,
        SETTINGS,
        SECURITY
    ]);

    const baseNodesPath = `/${ORGS_PATH}`;

    const historyPusher = (path?: string) =>
        history.push(
            `${baseNodesPath}/${org_id}/${MANAGE_ORG_PATH}` +
                (path ? `/${path}` : "")
        );

    let tabs = [
        {
            name: SUBSCRIPTIONS,
            action: () => historyPusher(MANAGE_ORG_SUBSCRIPTION_PATH),
        },
        {
            name: BILLING,
            action: () => historyPusher(MANAGE_ORG_BILLING_PATH),
        },
        {
            name: PAYMENT_METHOD,
            action: () => historyPusher(MANAGE_ORG_PAYMENT_METHOD_PATH),
            disabled: !isOrgOwner
        },
        {
            name: INVOICES,
            action: () => historyPusher(MANAGE_ORG_INVOICES_PATH),
            disabled: !isOrgOwner
        },
    ];

    // eject if not an owner or admin
    const canManage = isOrgOwner || roles.some(r => r.user_id === session.user_id && r.role === 'admin')
    if (!canManage) {
        return <Redirect to={`/${ORGS_PATH}`} />
    }

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(baseNodesPath)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{lt('organizations')}</Typography>} 
                        disableTypography />
                </ListItem>

                <ListItem>
                    <ListItemText
                        secondary={lt("organization")}
                        secondaryTypographyProps={{ variant: "button" }}
                    />
                </ListItem>

                <OrgPicker />

                <NavItem
                    icon={<ViewDashboardOutlineIcon />}
                    header={lt("dashboard")}
                    action={() => historyPusher()}
                    itemIsActive={pathname.endsWith(MANAGE_ORG_PATH)}
                />

                <NavItem
                    icon={<CardBulletedOutlineIcon />}
                    header={lt("subscriptionsAndBilling")}
                    action={() => {
                        if (
                            ![
                                SUBSCRIPTIONS,
                                BILLING,
                                PAYMENT_METHOD,
                                INVOICES,
                            ].includes(selectedItem)
                        )
                            setActive(!active);
                    }}
                />

                <SubNavs list={tabs} selected={active} {...{ selectedItem }} />

                <NavItem
                    icon={<AccountMultipleIcon />}
                    header={lt("manageUsers")}
                    action={() => historyPusher(MANAGE_ORG_MANAGE_USERS_PATH)}
                    itemIsActive={pathname.endsWith(
                        MANAGE_ORG_MANAGE_USERS_PATH
                    )}
                />

                {featureToggles.mfa &&
                <NavItem
                    icon={<SecurityIcon />} disabled={organization.type === 'oidc'}
                    header={lt("security")}
                    action={() => historyPusher(MANAGE_ORG_SECURITY_PATH)}
                    itemIsActive={pathname.endsWith(
                        MANAGE_ORG_SECURITY_PATH
                    )}
                />}

                <NavItem
                    icon={<CogOutlineIcon />}
                    header={lt("settings")}
                    action={() => historyPusher(MANAGE_ORG_SETTINGS_PATH)}
                    itemIsActive={pathname.endsWith(MANAGE_ORG_SETTINGS_PATH)}
                />
            </List>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    toolbar: {
        [theme.breakpoints.up("md")]: theme.mixins.toolbar,
    },
    backButton: {
        paddingLeft: theme.spacing(1),
    },
    backIcon: {
        minWidth: 0,
    },
}));

interface translations {
    organizations: string;
    subscriptionsAndBilling: string;
    subscription: string;
    billingAndUsage: string;
    paymentMethod: string;
    invoices: string;
    manageUsers: string;
    settings: string;
    organization: string;
    dashboard: string;
    security: string;
}

const enTranslations: translations = {
    organizations: 'Organizations',
    subscriptionsAndBilling: "Subscription & Billing",
    subscription: "Subscription",
    billingAndUsage: "Billing Forecast & Usage",
    paymentMethod: "Payment Method",
    invoices: "Invoices",
    manageUsers: "Manage Users",
    settings: "Settings",
    organization: "Organization",
    dashboard: "Dashboard",
    security: "Security",
};
