import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { CHANNELS_PATH } from '../../../components/MainNav/SideNavs/AddressBook';
import { ConsortiumResourcesVars, EnvironmentResourcesVars } from '../../../interfaces';
import { GatewayAPIsVars, GatewayAPIsQuery, GatewayAPIsData, isFabricPromotable } from '../../../models';
import { ContractProjectsData, ContractProjectsQuery } from '../../../models'
import { ChannelData, ChannelQuery } from '../../../models/channels';
import { Step1 } from './Step1';

export const DeployChaincodeToChannel: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DeployChaincodeToChannel', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DeployChaincodeToChannel:${key}`, interpolate)

    const { pathname } = useLocation()
    const { org_id, consortium_id, environment_id, channel_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${channel_id}/chaincodes`;

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: channel_id!
    }

    const [contractProject, setContractProject] = useState('');
    const [compilationChosen, setCompilationChosen] = useState('');

    const {
        data: {
            contractProjects : cp
        } = { contractProjects: [] }
    } = useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: {
            consortia_id: consortium_id!,
        },
        fetchPolicy: 'cache-and-network' 
    });

    const contractProjects = cp.filter(c => isFabricPromotable(true, c.type));
    if (contractProject === '' && contractProjects.length > 0) {
        setContractProject(contractProjects[0]._id);
    }

    const {
        data: {
            channel
        } = { channel: null }
    } = useQuery<ChannelData, EnvironmentResourcesVars>(ChannelQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            gatewayAPIs 
        } = { gatewayAPIs: null }
    } = useQuery<GatewayAPIsData, GatewayAPIsVars>(GatewayAPIsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-and-network'
    });

    const promotedCompilations = gatewayAPIs ? gatewayAPIs.gatewayAPIs.map((g) => g._id) : [];
    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep >= 1) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: '', component: <Step1 cancelPath={cancelPath} {...{promotedCompilations}} {...{contractProjects}} {...{contractProject}} {...{setContractProject}} {...{compilationChosen}} {...{setCompilationChosen}} /> }
    ]

    return <FullScreenCreate cancelPath={cancelPath} hideStepper toolbarHeader={lt('promote', { channelName: channel?.name } )} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    promote: string,
}
const enTranslations: translations = {
    promote: 'Instantiate Chaincode to {{channelName}}',
}