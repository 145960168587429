import { useQuery } from '@apollo/client';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Jazzicon from 'react-jazzicon';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ComingSoon, DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import { EmptyCard } from '../../components/DisplayWrappers/EmptyCard';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_ETHER_POOL_PATH, DIGITAL_ASSETS_TOKENS_PATH } from '../../components/MainNav/SideNavs/DigitalAssets';
import { ConsortiumResourceVars, LinkButtonProps } from '../../interfaces';
import { AccountsData, AccountsQuery, EnvironmentData, EnvironmentQuery, TokenContractsData, TokenContractsQuery, TokenContractsVars } from '../../models';
import { jsNumberForAddress } from '../../utils/StringUtils';

export const Dashboard = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DigitalAssetsDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DigitalAssetsDashboard:${key}`, interpolate);

    const { pathname } = useLocation()
    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${DIGITAL_ASSETS_BASE_PATH}`
    const etherPoolPath = `${basePath}/${DIGITAL_ASSETS_ETHER_POOL_PATH}/create/1`

    const environmenVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading: tokensLoading,
        data: {
            tokenContracts: {
                contracts
            }
        } = {tokenContracts: { totalCount: 0, contracts: []}}
    } = useQuery<TokenContractsData, TokenContractsVars>(TokenContractsQuery, {
        variables: {
            ...environmenVariables,
            limit: 25 //This page still has to be work on
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        loading: accountsLoading,
        data: {
            accounts
        } = { accounts: [] }
    } = useQuery<AccountsData>(AccountsQuery, {
        variables: environmenVariables,
        fetchPolicy: 'cache-and-network'
    });

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;

    if (isCorda) return <ComingSoon page="digitalAssetsDashboard"/>

    if((accountsLoading && accounts.length === 0) || (tokensLoading && contracts.length === 0)) return <CircularProgress />;

    const historyPusher = (id: string) => {
        history.push(`${basePath}/${DIGITAL_ASSETS_TOKENS_PATH}/${id}`);
    }

    const tokensList: DisplayCardListItem[] = contracts.map(token => ({
        icon: <Jazzicon diameter={30} seed={jsNumberForAddress(token.address)} />,
        title: token.tokenName,
        value: token.isERC20 ? lt('ERC20') : lt('ERC721'),
        onClick: () => historyPusher(token._id),
        actionIcon: <ChevronRightIcon />
    }))

    const linkButton: LinkButtonProps = {
        text: lt('viewTokens'),
        onClick: () => historyPusher('')
    }

    const DocumentationList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('header'),
            value: lt('documentation'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/token-factory')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('tokenFactory'),
            value: lt('apiDocs'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://api.kaleido.io/platform.html#tag/tokenfactory') 
        }
    ];

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('header')}</Typography>
            </Grid>
            <Grid item container spacing={3} alignContent='stretch'>
                <Grid item container lg={4} sm={6} xs={12}>
                    {tokensList.length === 0 ? (
                        <EmptyCard header={lt('tokens')}
                            imageFiles="Empty-Token.svg"
                            description={lt('noTokensDescription')} 
                            onViewDetailsClick={() => window.open('https://docs.kaleido.io/kaleido-services/token-factory/')}
                            createPath={`${basePath}/${DIGITAL_ASSETS_TOKENS_PATH}`} />
                    ) : <DisplayCard header={lt('tokens')} itemList={tokensList} divider {...{linkButton}} /> }
                </Grid>
                <Grid item container lg={4} sm={6} xs={12}>
                    <EmptyCard header={lt('etherPool')} createDisabled={environment?.state !== 'live'}
                        imageFiles="Empty-Ether-Pool.svg"
                        description={lt('etherPoolDescription')} 
                        onViewDetailsClick={() => window.open('https://docs.kaleido.io/kaleido-services/ether-pool/')} 
                        buttonLabel={lt('fundAccount')}
                        cancelPath={pathname}
                        createPath={etherPoolPath} />
                </Grid>
                <Grid item container lg={4} sm={6} xs={12}>
                    <DisplayCard header={lt('documentation')} itemList={DocumentationList} divider  />
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    header: string
    ERC20: string
    ERC721: string
    createToken: string
    tokens: string
    viewTokens: string
    documentation: string
    apiDocs: string
    tokenFactory: string
    noTokens: string
    noTokensDescription: string
    etherPool: string
    etherPoolDescription: string
    fundAccount: string
}
const enTranslations: translations = {
    header: 'Digital Assets',
    ERC20: 'ERC 20',
    ERC721: 'ERC 721',
    createToken: 'Create Token',
    tokens: 'Tokens',
    viewTokens: 'View All Tokens',
    documentation: 'Documentation',
    tokenFactory: 'Token Factory',
    apiDocs: 'API Docs',
    noTokens: 'No Tokens',
    noTokensDescription: 'Tokens are the representation of real world or digital assets on the blockchain. To deploy your first token please click on create new.',
    etherPool: 'Ether Pool',
    etherPoolDescription: 'Each Kaleido environment is configured with one billion ether in the chain’s genesis block. It can be used to enforce optional gas costs on specific smart contract methods and/or to represent a fungible and transactional asset.',
    fundAccount: 'Fund Account'
}