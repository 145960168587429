import { useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { Region } from '../../interfaces';
import { OpsBillingSummaryData, OpsBillingSummaryQuery, OpsBillingSummaryVars } from '../../models';
import { ManageOrgBillingUsage } from '../ManageOrg/BillingUsage';

interface Props {
    regions?: Region[]
}

export const OpsBilling: React.FC<Props> = ({regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OpsBilling', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OpsBilling:${key}`, interpolate)
    
    const {ops_org_id} = useParams<any>();
    
    const [message, setMessage] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [agreement, setAgreement] = useState('');
    const [runOnce, setRunOnce] = useState(false);
    const [variables, setVariables] = useState<{
        org_id: string,
        agreement?: string,
        year?: string,
        month?: string
    }>({org_id: ''});

    const {
        data: {
            opsBillingSummary: billingSummary
        } = { opsBillingSummary: null },
        error: fetchError,
        refetch
    } = useQuery<OpsBillingSummaryData, OpsBillingSummaryVars>(OpsBillingSummaryQuery, {
        skip: !variables.org_id,
        variables,
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (ops_org_id && !runOnce) {
            setRunOnce(true);
            setVariables({ org_id: ops_org_id, agreement, year, month });
        }
    }, [ops_org_id, agreement, year, month, runOnce]);

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    if (!billingSummary) return <CircularProgress/>

    const filterBar = <Grid container spacing={2} alignItems="center" alignContent="stretch">
        <Grid item xs>
            <TextField
                fullWidth
                label={lt('year')} 
                value={year} 
                variant="outlined" 
                onChange={e => setYear(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                fullWidth
                label={lt('month')} 
                value={month} 
                variant="outlined" 
                onChange={e => setMonth(e.target.value)}/>
        </Grid>
        <Grid item xs>
            <TextField
                fullWidth
                label={lt('agreement')} 
                value={agreement} 
                variant="outlined" 
                onChange={e => setAgreement(e.target.value)}/>
        </Grid>
        <Grid item>
            <Button
                variant="contained" 
                color="primary"
                onClick={() => {
                    setVariables({ org_id: ops_org_id, agreement, year, month });
                    refetch();
                }}>{lt('go')}</Button>
        </Grid>
    </Grid>;

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        {filterBar}
        <ManageOrgBillingUsage {...{billingSummary}} memberships={[]} consortia={[]} hideHeader={true}/>
    </>;
};


interface translations {
    year: string;
    month: string;
    agreement: string;
    go: string;
}

const enTranslations: translations = {
    year: 'Year',
    month: 'Month',
    agreement: 'Simulate Agreement',
    go: 'Go',
};
