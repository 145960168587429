import React from 'react';
import { AddressWidgetInfo, CompiledContract } from '../../../models';
import { ListItemText, ListItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { APPS_BASE_PATH, APPS_GATEWAY_APIS_PATH } from '../../MainNav/SideNavs/AppsIntegrations';
import { ADDRESSBOOK_PATH, ADDRESSBOOK_MEMBERSHIP_PATH, SYSTEM_MEMBERSHIP } from '../../MainNav/SideNavs/AddressBook';
import { ENVIRONMENT_DATA_EXPLORER, DATA_CONTRACTS } from '../../MainNav/SideNavs/DataExplorer';
import { FormLink } from '../FormLink';

interface Props {
    addressWidgetInfo: AddressWidgetInfo
    compiledContract?: CompiledContract
};

export const ShortenedHashGatewayAPIContract = ({ addressWidgetInfo, compiledContract }: Props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ShortenedHashGatewayAPIContract', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ShortenedHashGatewayAPIContract:${key}`, interpolate)
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const viewContractUrl = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/` +
        `${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}/${addressWidgetInfo._id}`;
    const viewAPIGatewayDetailsUrl = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/` +
        `${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${addressWidgetInfo.gatewayAPIInfo!.gatewayAPIId}`;
    const viewDeployerAddressBookEntryUrl = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/` +
        `${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${addressWidgetInfo.membership_id === environment_id ? SYSTEM_MEMBERSHIP : addressWidgetInfo.membership_id}`

    return (
        <>
            <ListItem>
                <ListItemText primary={lt('compilationDescription')}
                    secondary={compiledContract?.description ?? '--'} />
            </ListItem>
            {addressWidgetInfo.gatewayAPIInfo!.instanceEndpointId &&
                <ListItem>
                    <ListItemText primary={lt('instanceEndpointId')} secondary={addressWidgetInfo.gatewayAPIInfo!.instanceEndpointId} />
                </ListItem>
            }
            <ListItem>
                <ListItemText primary={lt('apiEndpoint')} secondary={addressWidgetInfo.gatewayAPIInfo!.gatewayAPIEndpoint} />
            </ListItem>
            <ListItem>
                <ListItemText primary={lt('moreInformation')} secondary={
                    <>
                        {window.location.pathname !== viewContractUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewContractUrl}>
                                    {lt('viewContract')}
                                </FormLink>
                            </span>}
                        {window.location.pathname !== viewAPIGatewayDetailsUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewAPIGatewayDetailsUrl}>
                                    {lt('viewAPIGatewayDetails')}
                                </FormLink>
                            </span>}
                        {window.location.pathname !== viewDeployerAddressBookEntryUrl &&
                            <span className={classes.linkContainer}>
                                <FormLink to={viewDeployerAddressBookEntryUrl}>
                                    {lt('viewDeployerAddressBookEntry')}
                                </FormLink>
                            </span>}
                    </>} />
            </ListItem>
        </>
    );
};

const useStyles = makeStyles(() => ({
    linkContainer: {
        display: 'block',
        marginTop: 6
    }
}));

interface translations {
    compilationDescription: string
    apiEndpoint: string
    moreInformation: string
    viewContract: string
    viewAPIGatewayDetails: string
    viewDeployerAddressBookEntry: string
    instanceEndpointId: string
}

const enTranslations: translations = {
    compilationDescription: 'Compilation Description',
    apiEndpoint: 'API Endpoint',
    moreInformation: 'More Information',
    viewContract: 'View Contract Details',
    viewAPIGatewayDetails: 'View API Gateway Details',
    viewDeployerAddressBookEntry: 'View Deployer Address Book Entry',
    instanceEndpointId: 'Instance Endpoint ID'
}