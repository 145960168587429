import { Button, Grid, IconButton, makeStyles, Typography, TextField, MenuItem, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { CreateWrapper, FormDialog, ErrorSnackbarCatcher, MessageSnackbar } from '../../../components/DialogWrappers';
import { GatewayAPIsTable } from "../../../components/GatewayAPIs/GatewayAPIsTable";
import { CreateStepProps } from '../../../interfaces';
import { GatewayAPI, CompiledContractData, CompiledContractVars, CompiledContractQuery } from "../../../models";
import { Step1Help } from './Step1Help';
import { useQuery, useMutation } from "@apollo/client";
import { GatewayAPIInstancesTable } from "../../../components/GatewayAPIs/GatewayAPIInstancesTable";
import { CreateEventStreamSubscriptionData, CreateEventStreamSubscriptionVars, CreateEventStreamSubscriptionMutation } from "../../../models/eventStreams";
import { NODE_EVENTSTREAMS_PATH, NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH } from "../../../components/NodeNav/NodeNav";

interface ABIEntry {
    type: string, 
    name: string, 
    inputs: { 
        type: string 
    }[]
}

export const Step1 = ({ cancelPath }: CreateStepProps) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamsCreateEventStreamSubscriptionStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamsCreateEventStreamSubscriptionStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id, eventstream_id } = useParams<any>();
    const [message, setMessage] = useState('');

    const [name, setName] = useState('');
    const [event, setEvent] = useState('');
    const [fromLatest, setFromLatest] = useState(true);
    const [fromBlock, setFromBlock] = useState(0);
    const [events, setEvents] = useState<string[]>([]);
    const [gatewaysOpen, setGatewaysOpen] = useState(false);
    const [contractsOpen, setContractsOpen] = useState(false);
    const [gatewayAPI, setGatewayAPI] = useState<GatewayAPI | undefined>();
    const [contract, setContract] = useState('');

    const [createEventStreamSubscription, { loading: createEventStreamSubscriptionLoading }] = 
        useMutation<CreateEventStreamSubscriptionData, CreateEventStreamSubscriptionVars>(CreateEventStreamSubscriptionMutation)

    const {
        data: {
            compiledContract
        } = { compiledContract: null }
    } = useQuery<CompiledContractData, CompiledContractVars>(CompiledContractQuery, {
        variables: { 
            consortia_id: consortium_id,
            contract_id: gatewayAPI?.consortiaContractId ?? '',
            id: gatewayAPI?._id ?? ''
        },
        fetchPolicy: 'network-only', // there is no active subscription on compiledContracts here so must use network-only
        skip: !gatewayAPI
    });

    useEffect(() => {
        if (gatewayAPI) {
            setGatewaysOpen(false)
        } else {
            setEvent('')
            setEvents([])
            setContract('')
        }
    }, [gatewayAPI])

    useEffect(() => {
        if (contract) {
            setContractsOpen(false)
        } 
    }, [contract])

    useEffect(() => {
        if (compiledContract) {
            const abi = JSON.parse(compiledContract.abi) as ABIEntry[]
            const eventEntries = abi.filter(a => a.type === 'event')
            setEvents(eventEntries.map(e => `${e.name}(${e.inputs.map(i => i.type).join(',')})`))
        }
    }, [compiledContract])

    const save = async () => {
        // create the event stream subscription
        createEventStreamSubscription({
            variables: {
                consortia_id: consortium_id!,
                environment_id: environment_id!,
                node_id: node_id,
                eventStreamSubscription: {
                    name,
                    stream: eventstream_id,
                    fromBlock: fromLatest ? '' : fromBlock.toString(),
                    gateway: gatewayAPI?.endpoint ?? '',
                    instance: contract,
                    event: event.substr(0, event.indexOf('('))
                }
            }
        }).then(result => {
            if (result) {
                const newEventStreamSubscriptionId = result.data?.createEventStreamSubscription?.id
                if (newEventStreamSubscriptionId) {
                    history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_EVENTSTREAMS_PATH}/${eventstream_id}/${NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH}/${newEventStreamSubscriptionId}`)
                }
            }
        }).catch(e => {
            ErrorSnackbarCatcher(e, setMessage)
        })
    }

    const content = (
        <>
            <FormDialog hideActions dialogType="medium" width="md" disableContentPadding dataTestId="selectGatewayAPI"
                open={gatewaysOpen}
                setOpen={setGatewaysOpen}
                controlsWrapper={<GatewayAPIsTable onSelectClick={setGatewayAPI} isSelector />}
                header={lt('selectGatewayAPI')} 
                saveDisabled={!gatewayAPI}
                saveText={lt('select')} />

            <FormDialog hideActions dialogType="medium" width="md" disableContentPadding dataTestId="selectGatewayAPIInstance"
                open={contractsOpen}
                setOpen={setContractsOpen}
                controlsWrapper={<GatewayAPIInstancesTable gatewayAPIId={gatewayAPI?._id} onSelectClick={setContract} isSelector />}
                header={lt('selectInstance')} 
                saveDisabled={!contract}
                saveText={lt('select')} />

            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField required data-test='textField_eventStreamSubscriptionName' 
                    value={name} 
                    onChange={event => setName(event.target.value)}
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>

            <Grid item container spacing={3}>
                <Grid item xs={gatewayAPI ? 11 : 12}>
                    <Button fullWidth className={classes.buttonHeight}
                        variant="outlined" 
                        onClick={() => setGatewaysOpen(true)}>
                        { gatewayAPI?.endpoint || lt('selectGatewayAPI') }
                    </Button>
                </Grid>
                {gatewayAPI && 
                    <Grid item onClick={() => setGatewayAPI(undefined)} xs={1}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                }
            </Grid>

            {gatewayAPI && 
            <>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {lt('selectEvent')}
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField data-test='select_contractEvent' 
                        required select fullWidth disabled={false} label={lt('selectEvent')} value={event} variant="outlined" 
                        onChange={e => setEvent(e.target.value)}>
                        {events.map(e => (
                            <MenuItem key={e} value={e}>{e}</MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item>
                    <Typography variant="h5">
                        {lt('fromBlock')}
                    </Typography>

                    <FormControl component="fieldset" margin="none">
                        <RadioGroup value={fromLatest} onChange={(event) => setFromLatest(event.target.value === 'true' ? true : false)}>
                            <FormControlLabel checked={fromLatest}
                                value={true} 
                                control={<Radio color="primary" />} 
                                label={lt('latest')} />
                            <FormControlLabel checked={!fromLatest}
                                value={false} 
                                control={<Radio color="primary" />} 
                                label={lt('blockNumber')} />
                        </RadioGroup>
                        {!fromLatest &&
                        <TextField type="number" required data-test='textField_fromBlock' 
                            value={fromBlock} 
                            onChange={event => setFromBlock(parseInt(event.target.value))}
                            fullWidth
                            margin="normal"
                            label={lt('fromBlock')}
                            variant="outlined"
                        />
                        }
                    </FormControl>
                </Grid>
            </>
            }

            {event && 
            <>
                <Grid item>
                    <Typography variant="h5" gutterBottom>
                        {lt('selectInstance')}
                    </Typography>
                </Grid>

                <Grid item container spacing={3}>
                    <Grid item xs={contract ? 11 : 12}>
                        <Button fullWidth className={classes.buttonHeight}
                            variant="outlined" 
                            onClick={() => setContractsOpen(true)}>
                            { contract || lt('selectInstance') }
                        </Button>
                    </Grid>
                    {contract && 
                        <Grid item onClick={() => setContract('')} xs={1}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </>
            }
        </>
    )

    const disabled = !name || !event || createEventStreamSubscriptionLoading || (!fromLatest && fromBlock < 0)

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            <Step1Help />
        </>
    )
};

const useStyles = makeStyles(() => ({
    buttonHeight: {
        height: '50px'
    }
}));

interface translations {
    header: string,
    headerDescription: string,
    selectGatewayAPI: string,
    selectGatewayAPIDescription: string
    select: string
    selectEvent: string
    selectInstance: string
    fromBlock: string
    latest: string
    blockNumber: string
    name: string
}
const enTranslations: translations = {
    header: 'Add New Subscription',
    headerDescription: 'Add a new subscription to this event stream.',
    selectGatewayAPI: 'Select a Gateway API',
    selectGatewayAPIDescription: 'Select a Gateway API to subscribe to.',
    select: 'Select',
    selectEvent: 'Select an Event',    
    selectInstance: 'Optional - Select a Contract Instance',
    fromBlock: 'From block',
    latest: 'Latest',
    blockNumber: 'Block number',
    name: 'Name'
}