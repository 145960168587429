import React, { isValidElement } from 'react';
import { Typography, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { Size } from "@material-ui/core/Table";
import clsx from 'clsx';

export interface DisplayTableColumns {
    isIcon?: boolean;
    value: string | number | JSX.Element,
    onClick?: () => void,
    maxWidthPixelValue?: number,
    minWidthPixelValue?: number,
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right',
    size?: Size,
}

export interface DisplayTableRecord {
    columns: DisplayTableColumns[],
    onClick?: () => void,
    key?: string, // ideally this would be required,
    noBorders?: boolean
}

interface Props {
    record: DisplayTableRecord
};

export const DisplayTableRow = ({ record }: Props) => {
    const classes = useStyles();

    return (
        <>
            <TableRow hover={record.onClick ? true : false} className={record.onClick ? classes.tableRow : ''}>
                { record.columns.map((c, i) => (
                    <TableCell className={clsx(classes.tableCell, { [classes.noBorders]: record.noBorders })} 
                        style={{maxWidth: c.maxWidthPixelValue, minWidth: c.minWidthPixelValue}} 
                        key={i} 
                        onClick={c.onClick || record.onClick} 
                        align={c.align} 
                        size={c.size}>
                        {isValidElement(c.value) ? 
                        <div className={classes.tableIcon}>
                            {c.value}
                        </div> : <Typography noWrap variant="body2" color="inherit">{c.value}</Typography>}
                    </TableCell> 
                ))}
            </TableRow>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    tableIcon: {
        display: 'flex',
        alignSelf: 'center'
    },
    tableRow: {
        cursor: "pointer"
    },
    tableCell: {
        paddingLeft: theme.spacing(3)
    },
    noBorders: {
        border: 0
    }
}));
