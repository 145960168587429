import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from "react-router-dom";
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { StaticConfigQuery, StaticConfigData, ConsortiumZonesData, ConsortiumZonesQuery } from '../../models';
import { useQuery } from '@apollo/client';
import { NETWORK_REGIONS_PATH } from '../../components/MainNav/ConsortiumWrapper';
import { ConsortiumResourcesVars } from '../../interfaces';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateRegionsCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateRegionsCreate:${key}`, interpolate)

    const { org_id, consortium_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}`

    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    const redirect = `/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_REGIONS_PATH}/create/1`

    const {
        loading: consortiumZonesLoading,
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        loading: regionsLoading,
        data: {
            staticConfig: {
                zoneRegions: regions
            }
        } = { staticConfig: { zoneRegions: [] } }
    } = useQuery<StaticConfigData>(StaticConfigQuery, { 
        fetchPolicy: 'cache-first'
    });

    if (invalidStep || createStep >= 1) {
        return <Redirect to={redirect} />
    }

    const stepComponents = [
        { 
            step: lt('createRegion'), 
            component: <Step1 loading={consortiumZonesLoading || regionsLoading} {...{cancelPath}} {...{consortiumZones}} {...{regions}} /> 
        }
    ]

    return <FullScreenCreate hideStepper {...{cancelPath}} toolbarHeader={lt('createRegion')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    createRegion: string,
}
const enTranslations: translations = {
    createRegion: 'Add Region'
}