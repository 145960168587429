import { useQuery } from '@apollo/client';
import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { OPS_MANAGE_ORG_PATH, OPS_PATH } from '../../components/OpsNav/OpsNav';
import { OpsServicesData, OpsServicesQuery } from '../../models';
import { flatten } from './OpsTable';

export const Service: React.FC = () => {

    const {ops_org_id, ops_service_id} = useParams<any>();

    const [message, setMessage] = useState('');

    const {
        data: {
            opsServices: services
        } = { opsServices: [] },
        loading,
        error: fetchError, 
    } = useQuery<OpsServicesData>(OpsServicesQuery, {
        skip: !ops_service_id,
        variables: {
            query: `_id=${ops_service_id}`,
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    if (loading) return <CircularProgress />;
    if (!services.length) return <Redirect to={`/${OPS_PATH}/${OPS_MANAGE_ORG_PATH}/${ops_org_id}`} />;
    const service = services[0];

    const copyableList : CopyableSetting[] = [];
    for (let [k,v] of Object.entries(flatten(service))) {
        copyableList.push({
            title: k,
            displayValue: `${v}`
        })
    }

    const actionBar = (
        <Grid container alignItems="center" spacing={3} wrap="nowrap">
            {/* TODO: Ops actions go here */}
        </Grid>
    )

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <Grid container direction="column" spacing={3}>
            <Grid item>
               <CopyableSettings
                    header={`${service.name} (${service._id})`}
                    {...{copyableList}}
                    {...{actionBar}}/>
            </Grid>
        </Grid>
    </>;
};
