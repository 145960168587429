import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { ConsortiumZonesData, ConsortiumZonesQuery,
    EnvironmentZonesData, EnvironmentZonesQuery,
    EnvironmentData, EnvironmentQuery, NodeConfigs,
    ServicesEnum } from '../../models';
import { ConsortiumResourcesVars, ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces'
import { SECURITY_BASE_PATH, CREATE_TUNNELER_PATH, SECURITY_NETWORK_CONTROL_PATH } from '../../components/MainNav/SideNavs/Security';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreate:${key}`, interpolate)

    type locationState = { name: string, membershipId: string, consortiumZoneId: string, configs: NodeConfigs }
    const history = useHistory<locationState>()
    const { org_id, consortium_id, environment_id, step } = useParams<any>();
    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_NETWORK_CONTROL_PATH}`
    
    const { 
        location: { 
            state: {
                name: tunnelerName,
                membershipId: tunnelerMembershipId,
                consortiumZoneId: tunnelerConsortiumZoneId
            } = { name: '',  membershipId: '',  consortiumZoneId: '' } 
        }
    } = history;

    const { 
        data: { 
            environmentZones
        } = { environmentZones: [] } 
    } = useQuery<EnvironmentZonesData, EnvironmentResourcesVars>(EnvironmentZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            consortiumZones
        } = { consortiumZones: [] }
    } = useQuery<ConsortiumZonesData, ConsortiumResourcesVars>(ConsortiumZonesQuery, { 
        variables: {
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id:  environment_id!,
        },
        fetchPolicy: 'cache-only'
    });


    const isMultiRegion = environment?.test_features?.multi_region ?? false

    const [name, setName] = useState((step && step >= "2") ? tunnelerName as string : '');
    const [membershipId, setMembershipId] = useState((step && step >= "2") ? tunnelerMembershipId as string : '');
    const [consortiumZoneId, setConsortiumZoneId] = useState(
        (step && step >= "2") ? tunnelerConsortiumZoneId as string : (isMultiRegion && consortiumZones.length ? consortiumZones.find(z => z.default)?._id ?? '' : ''));
    const invalidStep = step !== "1" && step !== "2" && step !== "3"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || (createStep >= 1 && (!tunnelerName || !tunnelerMembershipId || (isMultiRegion && !tunnelerConsortiumZoneId)))) {
        return <Redirect to={`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${ServicesEnum.tunneler}/${CREATE_TUNNELER_PATH}/:step/1`} />
    }

    const stepComponents = [
        { step: lt('tunnelerConfigs'), component: <Step1 {...{cancelPath}} {...{isMultiRegion}} {...{name}} {...{setName}} 
            {...{membershipId}} {...{setMembershipId}}
            {...{consortiumZones}}  {...{environmentZones}} {...{consortiumZoneId}} {...{setConsortiumZoneId}} /> },
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('createTunneler')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    tunnelerConfigs: string,
    createTunneler: string
}
const enTranslations: translations = {
    tunnelerConfigs: "Network Bridge Details",
    createTunneler: 'Create a PrivateStack network bridge'
}