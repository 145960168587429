import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { FullScreenCreate } from '../../components/DialogWrappers';
import { PlanName } from '../../models';
import { StripeWrapper } from '../ManageOrg/BillingProvider/StripeWrapper';
import { SIGNUP_PLANS_BASE_PATH, SIGNUP_PLANS_PATH } from '../SignupPlans/SignupPlans';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

export const SignupBilling: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SignupBilling', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SignupBilling:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const { step } = useParams<any>();
    const cancelPath = `/${org_id}/${SIGNUP_PLANS_BASE_PATH}/${SIGNUP_PLANS_PATH}`;

    type changePlanState = { plan: PlanName | null, supportLevel: number };
    const history = useHistory<changePlanState>();

    const {
        location: {
            state: {
                plan: selectedPlan,
                supportLevel: selectedSupportLevel
            } = { plan: null, supportLevel: 100 } //Its always basic by default
        }
    } = history;
    
    const [supportLevel, setSupportLevel] = useState<Number>(selectedSupportLevel || 100);

    if (!selectedPlan) return <Redirect to={cancelPath} />


    const stepComponents: { step: string; component: JSX.Element; }[] = [
        {
            step: lt('selectSupport'), component: <Step1 plan={selectedPlan} supportLevel={supportLevel} setSupportLevel={setSupportLevel} cancelPath={cancelPath} />
        }, {
            step: lt('billingProvider'), component: <StripeWrapper><Step2 cancelPath={cancelPath} plan={selectedPlan} {...{supportLevel}} /></StripeWrapper> 
        }, {
            step: lt('confirm'), component: <Step3 plan={selectedPlan} supportLevel={supportLevel} {...{cancelPath}} />
        }
    ];

    return (
        <FullScreenCreate toolbarHeader={lt('upgradePlan')} stepUrlParam={step!} cancelPath={cancelPath} stepComponents={stepComponents} />
    );
}

interface translations {
    upgradePlan: string
    selectPlan: string
    selectSupport: string
    confirm: string
    billingProvider: string
}

const enTranslations: translations = {
    upgradePlan: 'Upgrade Plan',
    selectPlan: 'Select Plan',
    selectSupport: 'Select Support',
    confirm: 'Confirm',
    billingProvider: 'Billing Provider'
}