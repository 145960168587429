import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step2Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FireflyCreateNodeStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`FireflyCreateNodeStep2Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-PeerNode.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            bullet4={lt('helpDesc4')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/using-kaleido/quick-start-firefly/firefly-usage/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    helpDesc4: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'FireFly Dependent Runtimes',
    helpDescription: 'A FireFly SuperNode is a collection of multiple runtimes with a single unified HTTPS/Websocket API (exposed by the Core). A FireFly SuperNode consists of the following runtimes:',
    helpDesc1: `FireFly Core - the API and event server for your multi-party applications.`,
    helpDesc2: 'Blockchain node(s) - the decentralized ledger technology establishing a shared source of truth.',
    helpDesc3: 'Public storage node - a network-wide peer-to-peer store of shared data (private to your environment).',
    helpDesc4: 'Data exchange - for private member to member communications of messages and files. FireFly Private Network Sandbox uses direct HTTPS with mutual TLS.',
    finalInfo: 'When selecting the dependent runtimes, you may choose from existing resources, or create new ones.'
}