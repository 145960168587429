import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import AppsIcon from 'mdi-react/AppsIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { DisplayCard } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_PATH, ADDRESSBOOK_RUNTIMES_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';
import { ServicesTranslations, EnServicesTranslations, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, getManageServiceSegments, Service, ServicesEnum, ServiceState } from '../../models';
import { BLOCKCHAIN_BASE_PATH, BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH } from '../../components/MainNav/SideNavs/Blockchain';
import { B2B_BASE_PATH } from '../../components/MainNav/SideNavs/B2bCommunication';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_CLOUD_PATH, KEY_MANAGEMENT_MANAGED_PATH } from '../../components/MainNav/SideNavs/KeyManagement';

interface Props {
    services: Service[]
    headerTitle?: string
};

export const Services = ({ services, headerTitle }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentDashboardServices', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentDashboardServices:${key}`, interpolate)

    const { org_id, consortium_id, environment_id } = useParams<any>()
    const history = useHistory()

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-first'
    });

    const makeIcon = (state: ServiceState) => {
        return (
            <Grid item container spacing={1}>
                <Grid item>
                    <ResourceStateChip state={state} />
                </Grid>
                <Grid item>
                    <ChevronRightIcon />
                </Grid>
            </Grid>
        )
    }

    const servicesList = services.slice().sort((a) => a.membership.isMine || a.service_type === 'utility' ? -1 : 1).map(s => {
        const membershipId = s.service_type === 'utility' ? SYSTEM_MEMBERSHIP : s.membership_id
        const membershipName = s.service_type === 'utility' ? lt('system') : s.membership.name
        const addressBookSegments = `${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membershipId}/${ADDRESSBOOK_RUNTIMES_PATH}/${s._id}`
        const appsCASegments = `${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}/${s._id}`;
        const manageServiceSegments = featureToggles ? getManageServiceSegments(
            s, featureToggles, 
            BLOCKCHAIN_BASE_PATH, 
            B2B_BASE_PATH, 
            KEY_MANAGEMENT_BASE_PATH, 
            KEY_MANAGEMENT_CLOUD_PATH, 
            KEY_MANAGEMENT_MANAGED_PATH) : undefined

        const linkPath = s.service === 'fabric-ca' ? appsCASegments : (manageServiceSegments || addressBookSegments);
        const displayName = `${s.name} (` + lt(ServicesEnum[s.service]) + ')'
        return {
            title: displayName,
            value: membershipName,
            icon: <AppsIcon />,
            onClick: () => history.push(`${basePath}/${linkPath}`),
            actionIcon: makeIcon(s.state)
        }
    })
    
    return (
        <DisplayCard maxHeight='extend'
            header={headerTitle ?? lt('otherRuntimes', { count: `(${servicesList.length})` })}
            itemList={servicesList} />
    )
};

interface translations extends ServicesTranslations{
    otherRuntimes: string,
    system: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    otherRuntimes: 'Other Runtimes {{count}}',
    system: 'System'
}