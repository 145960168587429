import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'
import { ContractProjectType } from '../../../models';

interface Props {
    type: ContractProjectType
}

export const FabricStep1Help = ({ type }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationFabricStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationFabricStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt(type === 'fabric_precompiled_go' ? 'helpDesc3' : 'helpDesc3Node')}
            docLink="https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#uploading-chaincode-binary"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    helpDesc3Node: string
}
const enTranslations: translations = {
    helpHeader: 'Your chaincode version',
    helpDescription: 'Set the details for ',
    helpDesc1: 'Owner - the membership you are acting under when uploading this subscription.',
    helpDesc2: 'Description - Give this version a description so you will recognize it later.',
    helpDesc3: 'Binary file - upload the linux compiled go binary for distribution to nodes in the network',
    helpDesc3Node: 'Zipped file - upload a zipped file containing your entire Node.js project directory with package.json at the root and excluding node_modules as these will be installed during the chaincode packaging process.'
}