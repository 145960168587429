import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step2Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'InviteOrganizationCreateStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`InviteOrganizationCreateStep2Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Transactions.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    finalInfo: string,
}
const enTranslations: translations = {
    helpHeader: 'Contact Information',
    helpDescription: 'Invite an external organization to participate in this network. You will need to provide:',
    helpDesc1: 'The name of the organization to invite',
    helpDesc2: 'An email address for the organization\'s representative',
    finalInfo: 'The organization representative will receive an email from Kaleido explaining the details of how to proceed with accepting or declining the invitation.',
}