import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { RuntimeCreationSelectSizeContent } from '../../components/RuntimeCreation/RuntimeCreationSelectSizeContent';
import { EnvironmentResourceVars } from '../../interfaces';
import { ResetServiceMutation, Service, UpdateServiceMutation, UpdateServiceVars } from '../../models';

interface Props {
    service: Service,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    smallDescription?: string
    mediumDescription?: string
    largeDescription?: string
}

export const UpdateSize = ({ service, open, setOpen, smallDescription, mediumDescription, largeDescription }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ServiceSettingsUpdateSize', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ServiceSettingsUpdateSize:${key}`, interpolate)

    const { consortium_id, environment_id, service_id } = useParams<any>();

    const serviceVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        id: service_id!
    }

    const [size, setSize] = useState(service.size);

    useEffect(() => {
        setSize(service.size)
    }, [service.size])

    const [updateService, { loading: updateServiceLoading }] = useMutation<Service, UpdateServiceVars>(UpdateServiceMutation);
    const [resetService, { loading: resetServiceLoading }] = useMutation<Service, EnvironmentResourceVars>(ResetServiceMutation)

    const updater = () => updateService({
        variables: {...{ service: { size: size} }, ...serviceVars}
    }).then(() => {
        resetService({
            variables: serviceVars
        })
    })

    const controlsWrapper = (
      <RuntimeCreationSelectSizeContent {...{size}} {...{setSize}} hideHeaderAndDescription
        {...{smallDescription}} {...{mediumDescription}} {...{largeDescription}} />
    )

    return <FormDialog 
        {...{open}} 
        {...{setOpen}} 
        header={lt('changeRuntimeSize')} 
        description={lt('changeRuntimeSizeDescription')} 
        {...{controlsWrapper}} 
        saveDisabled={!size || updateServiceLoading || resetServiceLoading}
        onSave={updater}
        closeDialogAfterSave />
};

interface translations {
    changeRuntimeSize: string,
    changeRuntimeSizeDescription: string
}
const enTranslations: translations = {
    changeRuntimeSize: 'Change runtime size',
    changeRuntimeSizeDescription: 'You can change the size of this runtime by making a new selection below. This will reset the runtime.'
}