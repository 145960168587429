import { useQuery } from '@apollo/client';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { DeleteResource } from '../../components/DialogWrappers';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { DisplayCard } from '../../components/DisplayWrappers';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { NODE_EVENTSTREAMS_PATH, NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH, NODE_LOGS_PATH } from '../../components/NodeNav/NodeNav';
import { NodeResourceVars } from '../../interfaces';
import { EnEventStreamTranslations, EventStreamData, EventStreamQuery, EventStreamSubscriptionData, EventStreamSubscriptionQuery, EventStreamTranslations } from '../../models/eventStreams';

export const NodeEventStreamSubscription = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamSubscription', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamSubscription:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()
    const { consortium_id, environment_id, node_id, eventstream_id, eventstream_subscription_id } = 
        useParams<{org_id: string, consortium_id: string, environment_id: string, 
            node_id: string, eventstream_id: string, eventstream_subscription_id: string}>();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const eventStreamsRedirect = pathname.replace(`/${NODE_EVENTSTREAMS_SUBSCRIPTIONS_PATH}/${eventstream_subscription_id}`, '')
    const ethconnectLogsRedirect = eventStreamsRedirect.replace(`${NODE_EVENTSTREAMS_PATH}/${eventstream_id}`, `${NODE_LOGS_PATH}/ethconnect`)

    const {
        loading: eventStreamLoading,
        data: {
            eventStream
        } = { eventStream: null }
    } = useQuery<EventStreamData, NodeResourceVars>(EventStreamQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_id: node_id,
            id: eventstream_id
        },
        fetchPolicy: 'cache-first'
    });

    const {
        loading: eventStreamSubscriptionLoading,
        data: {
            eventStreamSubscription
        } = { eventStreamSubscription: null }
    } = useQuery<EventStreamSubscriptionData, NodeResourceVars>(EventStreamSubscriptionQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_id: node_id,
            id: eventstream_subscription_id
        },
        fetchPolicy: 'cache-first'
    });

    if ((!eventStreamLoading && !eventStream) || (!eventStreamSubscriptionLoading && !eventStreamSubscription)) return (<Redirect to={eventStreamsRedirect} />)

    const copyableList: CopyableSetting[] = [
        { title: lt('id'), displayValue: eventStreamSubscription?.id ?? '' },
        { title: lt('name'), displayValue: eventStreamSubscription?.name ?? '' },
        { title: lt('path'), displayValue: eventStreamSubscription?.path ?? '' },
        { title: lt('event'), displayValue: eventStreamSubscription?.event.name ?? '' },
        { title: lt('contract'), displayValue: eventStreamSubscription?.filter.address?.length ? <ShortenedHash showFullAddress address={eventStreamSubscription.filter.address[0]} /> : lt('na'), disableCopy: true },
        { title: lt('fromBlock'), displayValue: eventStreamSubscription?.fromBlock ?? '', disableCopy: true },
        { title: lt('createdAt'), displayValue: eventStreamSubscription ? new Date(eventStreamSubscription.created).toLocaleString() : '', disableCopy: true },
    ]

    const docsList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('intro'),
            value: lt('introDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/event-streams/usage-webhooks/#subscribe-to-an-event')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('api'),
            value: lt('apiDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://api.kaleido.io/ethconnect.html#tag/Event-Streams/paths/~1subscriptions/get')
        },
    ]

    const actionBar = (
        <Grid item container spacing={3} direction="row">
            <Grid item>
                <Button color="default" size="medium" variant="outlined" onClick={() => history.push(ethconnectLogsRedirect)}>
                    {lt('viewLogs')}
                </Button>
            </Grid>
            <Grid item>
                <Button color="default" size="medium" variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
                    {lt('delete')}
                </Button>
            </Grid>
        </Grid>
    )

    const actionAfterDelete = () => history.push(eventStreamsRedirect)

    return (
        <>
            <DeleteResource {...{actionAfterDelete}} disableNameConfirmation name={eventStreamSubscription?.id ?? ''} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} />
            
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('header')}
                        </Typography>
                    </Grid>
                    
                </Grid>

                <Grid item container direction="row" spacing={3}>
                    {eventStreamSubscriptionLoading && !eventStreamSubscription ? 
                    <CircularProgress /> : 
                    <>
                        <Grid item container direction="column" spacing={3} xs={12} lg={8}>
                            <Grid item container>
                                <CopyableSettings header={lt('copyableHeader')} {...{actionBar}} {...{copyableList}} />
                            </Grid>
                        </Grid>
                    </>
                    }

                    <Grid item xs={6} lg={4}>
                        <DisplayCard header={lt('documentation')} itemList={docsList} />
                    </Grid> 
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends EventStreamTranslations {
    header: string
    copyableHeader: string
    documentation: string
    intro: string
    introDescription: string
    api: string
    apiDescription: string
    contract: string
    na: string
    delete: string
    viewLogs: string
}
const enTranslations: translations = {
    ...EnEventStreamTranslations,
    header: 'Subscription Details',
    copyableHeader: 'Subscription information',
    documentation: 'Documentation',
    intro: 'Event Stream Subscriptions',
    introDescription: 'Subscribe to a contract event',
    api: 'API Documentation',
    apiDescription: 'API reference for Kaleido',
    contract: 'Contract',
    na: 'All contracts matching the event signature',
    delete: 'Delete Subscription',
    viewLogs: 'View logs'
}