import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1CordaHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep1CordaHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep1CordaHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            docLink="https://kaleido.io/smart-contract-management-solution-how-it-works-why-you-need-it/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
}
const enTranslations: translations = {
    helpHeader: 'Why promote this version?',
    helpDescription: 'Your uploaded Jar file needs to be promoted to an environment so it can be deployed onto the nodes',
    helpDesc1: 'Node administrator can then choose which promoted Jar files they accept into their node runtime',
}