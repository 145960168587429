import React, { useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { RolesData, RolesQuery, Role, RolesTranslations, EnRolesTranslations } from '../../../models/roles';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid, Typography, Button } from '@material-ui/core';
import { DisplayTable } from '../../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { DisplayTableRecord } from '../../../components/DisplayWrappers/DisplayTableRow';
import { Dotdotdot } from '../../../components';
import { DeleteUser } from './DeleteUser';
import AccountIcon from 'mdi-react/AccountIcon';
import { OrganizationData, OrganizationQuery } from '../../../models';
import { EditRole } from './EditRole';
import { SessionData } from '../../../interfaces';
import { SessionQuery } from '../../../queries/Session';

export const ManageUsers: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ManageUsers', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ManageUsers:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [deleteUserOpen, setDeleteUserOpen] = useState(false);
    const [updateUserOpen, setUpdateUserOpen] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState('');
    const [userIdToUpdate, setUserIdToUpdate] = useState('');

    const {
        data: {
            organization
        } = { organization: null }
    } = useQuery<OrganizationData>(OrganizationQuery, { variables: { id: org_id }, fetchPolicy: 'cache-only' });

    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    const {
        loading,
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, { variables: { org_id }, fetchPolicy: 'cache-and-network' });

    if (loading) {
        return <CircularProgress />
    }

    const columnHeaders = [
        '',
        lt('email'),
        lt('role'),
        lt('joinDate'),
        ''
    ];

    const hideRoleActions = (role: Role) => role.user_id === organization?.owner || role.user_id === session.user_id

    const records: DisplayTableRecord[] = roles.map(role => ({
        key: role._id,
        columns: [
            { value: <AccountIcon /> },
            { value: role.email },
            { value: role.user_id === organization?.owner ? lt('owner') : lt(role.role) },
            { value: new Date(role.created_at).toLocaleString() },
            {
                value: hideRoleActions(role) ? <></> : (
                    <Dotdotdot menuItems={[
                        {
                            name: lt('updateUser'),
                            action: () => { setUserIdToUpdate(role._id); setUpdateUserOpen(true) }
                        },
                        {
                            name: lt('deleteUser'),
                            action: () => { setUserIdToDelete(role._id); setDeleteUserOpen(true) }
                        }
                    ]} 
                    />
                )
            }
        ]
    }));

    return (
        <>
            <Grid container spacing={3} direction="column">
                <Grid item container justify="space-between">
                    <Grid item>
                        <Typography variant="h5">{lt('users')}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => setAddUserOpen(true)}>{lt('addUser')}</Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <DisplayTable header={lt('users')} columnHeaders={columnHeaders} records={records} />
                </Grid>
            </Grid>
            <EditRole open={addUserOpen} setOpen={setAddUserOpen} />
            <EditRole open={updateUserOpen} setOpen={setUpdateUserOpen} roleToUpdate={roles.find(r => r._id === userIdToUpdate)} />
            <DeleteUser open={deleteUserOpen} setOpen={setDeleteUserOpen} roleId={userIdToDelete} />
        </>);
}

interface translations extends RolesTranslations {
    users: string
    addUser: string
    email: string
    role: string
    joinDate: string
    deleteUser: string
    owner: string
    updateUser: string
}

const enTranslations: translations = {
    ...EnRolesTranslations,
    users: 'Users',
    addUser: 'Add User',
    email: 'E-mail',
    role: 'Role',
    joinDate: 'Join Date',
    deleteUser: 'Delete User',
    owner: 'Owner',
    updateUser: 'Update User'
}