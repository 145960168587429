import {
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Region } from "../../interfaces";
import { isEmailDomainBlocked, isEmailValid, isPasswordCompliant } from "../../utils/StringUtils";
import { FormLink } from "../FormControls/FormLink";
import { PasswordInput } from "../PasswordInput/PasswordInput";

interface Props {
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    handleCreateAccount: () => void
    loading: boolean
    errorMessage: string
    region: Region
    setRegion: React.Dispatch<React.SetStateAction<Region>>
    regions: Region[]
    ssoGoogle: boolean
    disableRecaptcha: boolean
    recaptcha: string | null
    setRecaptcha: (s: string | null) => void
}

const VWO_BUSINESS_EMAIL_RESTRICTION_TAG = 'vwo-business-email';

export const CreateAccount: React.FC<Props> = ({
    email,
    setEmail,
    password,
    setPassword,
    handleCreateAccount,
    region,
    setRegion,
    loading,
    errorMessage,
    regions,
    disableRecaptcha,
    recaptcha,
    setRecaptcha,
}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateAccount', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateAccount:${key}`, interpolate);

    const { search } = useLocation();
    const [submitted, setSubmitted] = useState(false);
    const [businessEmailOnly, setBusinessEmailOnly] = useState(false);

    const showRecaptcha = !disableRecaptcha && !!process.env.REACT_APP_RECAPTCHA_SITE_ID

    useEffect(() => {
        if (businessEmailOnly) return;
        const divElement = document.getElementById('CreateAccountForm');
        if (divElement?.classList.contains(VWO_BUSINESS_EMAIL_RESTRICTION_TAG)) {
            setBusinessEmailOnly(true);
        }
    }, [businessEmailOnly, email]);

    const recaptured = !showRecaptcha || recaptcha;
    const invalid = !email || !isEmailValid(email) || !isPasswordCompliant(password) || isEmailDomainBlocked(email, businessEmailOnly);

    const getEmailError = () => {
        if (email && !isEmailValid(email)) {
            return lt('mustByValidEmail')
        }
        if (email && isEmailDomainBlocked(email, businessEmailOnly)) {
            return lt('invalidDomain')
        }
    }

    return (
        <form id="CreateAccountForm" className={'Hubspot'} onSubmit={event => {
            event.preventDefault();
            if (!invalid) handleCreateAccount();
        }} style={{ width: '300px' }}>
            <Grid container spacing={3} direction="column" >
                <Grid item>
                    <Typography variant="h5">
                        {lt('createAnAccount')}
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        label={lt('workEmailAddress')}
                        fullWidth
                        required
                        autoFocus
                        variant="outlined"
                        value={email}
                        autoComplete="username"
                        margin="none"
                        onChange={event => setEmail(event.target.value)}
                        error={submitted && (!isEmailValid(email) || isEmailDomainBlocked(email, businessEmailOnly))}
                        helperText={getEmailError()}
                    />
                </Grid>
                <Grid item>
                    <PasswordInput
                        fullWidth
                        label={lt('password')}
                        variant="outlined"
                        value={password}
                        autoComplete="new-password"
                        margin="none"
                        onChange={event => setPassword(event.target.value)}
                        required
                        error={submitted && password && !isPasswordCompliant(password) ? true : false}
                        helperText={password && !isPasswordCompliant(password) && lt('passwordRequirements')}
                    />
                </Grid>
                <Grid item>
                    <TextField label={lt('homeLocation')}
                        fullWidth
                        variant="outlined"
                        select
                        value={region.code}
                        onChange={event => setRegion(regions.find(region => region.code === event.target.value)!)}>
                        {regions.filter(region => !region.redirectLoginTo).map(region => (
                            <MenuItem key={region.code} value={region.code}>
                                {region.geo}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {errorMessage &&
                    <Grid item>
                        <Typography variant="subtitle1" color="error">
                            {errorMessage}
                        </Typography>
                    </Grid>
                }

                {showRecaptcha &&
                    <Grid item>
                        <ReCAPTCHA size="normal"
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_ID!}
                            onChange={e => setRecaptcha(e)}
                        />
                    </Grid>}
                <Grid item>
                    <Trans i18nKey="CreateAccount:disclaimer"
                        components={[<FormLink to="https://www.kaleido.io/privacy-policy" isExternal={true} target="_blank" />,
                        <FormLink to="https://www.kaleido.io/terms-of-service" isExternal={true} target="_blank" />]}>
                    </Trans>
                </Grid>
                <Grid item>
                    <Button type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={loading || !recaptured}
                        onClick={() => setSubmitted(true)}
                    >{lt('createAccount')}
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="body1" align='center'>
                        <Trans i18nKey="CreateAccount:alreadyHaveAccount"
                            components={[<FormLink to={"/login" + search} />]}>
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
        </form >
    );
}

interface translations {
    createAnAccount: string
    createAccount: string
    homeLocation: string
    signUpWithGoogle: string
    or: string
    workEmailAddress: string
    mustByValidEmail: string
    password: string
    passwordRequirements: string
    confirmPassword: string
    passwordMismatch: string
    alreadyHaveAccount: string
    disclaimer: string
    invalidDomain: string
}

const enTranslations: translations = {
    createAnAccount: 'Create an Account',
    createAccount: 'Create Account',
    homeLocation: 'Set Home Location',
    signUpWithGoogle: 'Sign up with Google',
    or: 'OR',
    workEmailAddress: 'Work E-mail Address',
    mustByValidEmail: 'Must be a valid e-mail address',
    password: 'Password',
    passwordRequirements: 'Min 8 characters. Must include 1 of each: upper & lower case, number & special character',
    confirmPassword: 'Confirm Password',
    passwordMismatch: 'Passwords must be equal',
    alreadyHaveAccount: 'Already have an account? <0>Sign in</0>',
    disclaimer: 'Signing up for a Kaleido account means that you agree to the <0>Privacy Policy</0> and <1>Terms of Service</1>.',
    invalidDomain: 'The provided email address is not currently supported for signup to Kaleido'
}