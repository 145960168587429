import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step2WebhookHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateProjectStep2WebhookHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateProjectStep2WebhookHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Webhook.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/kaleido-services/event-streams/usage-webhooks"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    finalInfo: string
}
const enTranslations: translations = {
    helpHeader: 'Setup the webhook',
    helpDescription: 'With your target URL in hand, you can proceed to create an event stream on Kaleido and subscribe smart contract events to it.',
    helpDesc1: 'If you are using an API Key to secure access to your stream, add a header with "x-api-key" as the name and your API key as the value.',
    finalInfo: 'For new event streams there will be no subscriptions by default. Subscriptions are created when you subscribe an event within your smart contract to an event stream ID.'
}