import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Environment, 
    ForkUpgradeEnvironmentMutation,
    HardForkAndUpgradeEnvironmentMutation,
    EnvironmentStatus} from '../../models'
import { TextField, Grid } from "@material-ui/core";
import { FormDialog } from '.'
import { ConsortiumResourceVars } from '../../interfaces';
import { FormLink } from '../FormControls/FormLink';

interface Props {
    environment: Environment
    environmentStatus: EnvironmentStatus,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
};

export const EnvironmentForkUpgrade = ({ environment, environmentStatus, open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentForkUpgrade', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EnvironmentForkUpgrade:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!,
        id: environment_id!,
    }

    const [upgradeNameToMatch, setUpgradeNameToMatch] = useState('');
    const [forkUpgradeEnvironment] = useMutation<Environment, ConsortiumResourceVars>(ForkUpgradeEnvironmentMutation)
    const [hardforkAndUpgradeEnvironment] = useMutation<Environment, ConsortiumResourceVars>(HardForkAndUpgradeEnvironmentMutation)

    const upgradeDescriptionLinksWrapper = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <FormLink isExternal={true} to="https://help.kaleido.io/hc/en-us/articles/12551021819405-How-do-I-select-a-hard-fork-of-my-environment-" target="_blank">
                    {lt('forkUpgradeLearnMore')}
                </FormLink>
            </Grid>
        </Grid>
    )

    const upgradeControlsWrapper = (
        <TextField
            value={upgradeNameToMatch} onChange={event => setUpgradeNameToMatch(event.target.value)}
            autoFocus
            margin="normal"
            label={lt('confirmForkUpgrade')}
            fullWidth
            variant="outlined"
        />
    )

    const onForkUpgrade = async() => {
        if (environmentStatus.upgrade.require_hard_fork_upgrade)
          hardforkAndUpgradeEnvironment({variables: environmentVars}).then(() => {});
        else
          forkUpgradeEnvironment({variables: environmentVars}).then(() => {});
    }

    return (
        <FormDialog 
            open={open}
            setOpen={setOpen}
            header={ environmentStatus.upgrade.require_hard_fork_upgrade ? lt('hardforkAndUpgradeEnvironment', {name: environment.name}) : lt('forkUpgradeEnvironment', {name: environment.name})}
            description={ environmentStatus.upgrade.require_hard_fork_upgrade ? lt('hardforkAndUpgradeDescription', {eips: environmentStatus.upgrade.optional_hardfork_eips}) : lt('forkUpgradeDescription', {eips: environmentStatus.upgrade.optional_hardfork_eips})}
            onSave={onForkUpgrade}
            closeDialogAfterSave
            saveDisabled={environment.name !== upgradeNameToMatch}
            confirmResourcePromptName={environment.name}
            confirmSuffix={lt('confirmForkUpgradeSuffix')}
            descriptionLinksWrapper={upgradeDescriptionLinksWrapper}
            controlsWrapper={upgradeControlsWrapper}
            saveText={lt('forkUpgrade')} />
    )
};

interface translations {
    forkUpgrade: string,
    forkUpgradeEnvironment: string,
    hardforkAndUpgradeEnvironment: string,
    forkUpgradeDescription: string,
    hardforkAndUpgradeDescription: string,
    confirmForkUpgrade: string
    confirmForkUpgradeSuffix: string,
    forkUpgradeLearnMore: string
}
const enTranslations: translations = {
    forkUpgrade: 'Hard Fork Upgrade',
    forkUpgradeEnvironment: 'Hard Fork to apply new EIPs to this environment ({{name}})?',
    hardforkAndUpgradeEnvironment: 'Hard Fork to apply new EIPs and upgrade this environment ({{name}})?',
    forkUpgradeDescription: 'This environment will be hard forked to apply EIP(s) {{eips}}.',
    hardforkAndUpgradeDescription: 'This environment will be hard forked to apply EIP(s) {{eips}}. and upgraded to the latest release',
    confirmForkUpgrade: 'Name',
    confirmForkUpgradeSuffix: ' to confirm the hard fork.',
    forkUpgradeLearnMore: 'Learn more about our hard fork process'
}