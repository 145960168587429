import { gql } from '@apollo/client';
import { EnvironmentResourcesVars } from '../interfaces';
import { MembershipOwnedResource } from './memberships';
import { TransactionReceipt, TransactionReceiptFields } from './transactions';

// INTERFACES

export type AccountType = "node" | "ethwallet" | "hdwallet" | "cloudhsm";

export interface AccountTypeTranslationInterface {
    node: string
    ethwallet: string
    hdwallet: string
    cloudhsm: string
}

export const AccountTypeTranslation : AccountTypeTranslationInterface = {
    node: 'Node Wallet',
    ethwallet: 'Eth Wallet',
    cloudhsm: 'Cloud HSM Wallet',
    hdwallet: 'HD Wallet'
}

export interface Account extends MembershipOwnedResource {
    _id: string
    account_type: AccountType
    created_at: string
    updated_at?: string
    description?: string
    membership_id: string
    wallet_id?: string
    wallet_origin?: WalletOrigin
    deactivated?: Boolean
    hdwalletDetails?: { // this is used by the signing widget only. it is not returned by the API
        serviceId: string
        walletId: string
        accountIndex: string
    }
}

export function getFromForGatewayAPI(a: Account) {
    if (a.hdwalletDetails) {
        return `HD-${a.hdwalletDetails.serviceId}-${a.hdwalletDetails.walletId}-${a.hdwalletDetails.accountIndex}`
    }
    return a._id
}

export interface WalletOrigin {
    _id: string
    name: string
    type: string
}

export interface AccountsData {
    accounts: Account[]
}

export interface AccountsVars extends EnvironmentResourcesVars {
    wallet_id?: string
}

export interface CreateNodeAccountVars extends EnvironmentResourcesVars {
    node_id: string
}
export interface CreateNodeAccountData {
    createNodeAccount: string
}

export interface CreateEthwalletAccountVars {
    service_id: string
}
export interface CreateEthwalletAccountData {
    createEthwalletAccount: string
}

export type FundingUnit = 'ether' | 'wei';

export type FundType = 'eth' | 'token'
export interface FundAccountData {
    fundAccount: TransactionReceipt
}
export interface FundAccountVars extends EnvironmentResourcesVars {
    fund: {
        type?: FundType
        account: string
        amount: string
        unit?: FundingUnit
        tokenAddress?: string
    }
}

// FRAGMENTS

export const AccountFields = ` 
    fragment AccountFields on Account {
        _id
        account_type
        created_at
        updated_at
        description
        membership_id
        wallet_id
        wallet_origin {
            _id
            name
            type
        }
        deactivated
        membership @client
    }`

// MUTATIONS

export const CreateNodeAccountMutation = gql`
    mutation createNodeAccount($consortia_id: String!, $environment_id: String!, $node_id: String!) {
        createNodeAccount(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id)
    }`;

export const CreateEthwalletAccountMutation = gql`
    mutation createEthwalletAccount($service_id: String!) {
        createEthwalletAccount(service_id: $service_id)
    }`;

export const FundAccountMutation = gql`
    ${TransactionReceiptFields}
    mutation fundAccount($consortia_id: String!, $environment_id: String!, $fund: FundAccountInput!) {
        fundAccount(consortia_id: $consortia_id, environment_id: $environment_id, fund: $fund) {
            ...TransactionReceiptFields
        }
    }`;    

// QUERIES & SUBSCRIPTIONS

export const AccountsQuery = gql`
    ${AccountFields}
    query getAccounts($consortia_id: String!, $environment_id: String!, $wallet_id: String) {
        accounts(consortia_id: $consortia_id, environment_id: $environment_id, wallet_id: $wallet_id) {
            ...AccountFields
        }
    }`;

