import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from "react-router-dom";
import { FullScreenCreate } from '../../components/DialogWrappers'
import { Step1 } from './Step1'
import { Step2Success } from './Step2Success';
import { useQuery } from '@apollo/client';
import { UserInvitationsData, UserInvitationsQuery } from '../../models';

export const VIEW_INVITATION_PATH = 'invitations'

export const Invitation = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ViewInvitationInvitation', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ViewInvitationInvitation:${key}`, interpolate)

    const { org_id, step, invitation_id } = useParams<any>();
    const cancelPath = `/orgs/${org_id}`

    const invalidStep = step !== "1" && step !== "2"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    const { 
        loading,
        data: { 
            invitations
        } = { invitations: [] } 
    } = useQuery<UserInvitationsData>(UserInvitationsQuery, { fetchPolicy: 'cache-and-network' });

    const invitation = invitations.find(i => i._id === invitation_id)
    const invalidInvitation = (!loading && !invitation) || (!loading && step === "2" && invitation?.state !== 'accepted')

    if (invalidStep || createStep >= 2 || invalidInvitation) {
        return <Redirect to={cancelPath} />
    }

    const stepComponents = [
        { 
            step: lt('viewInvitation'), 
            component: <Step1 {...{cancelPath}} {...{invitation}} /> 
        },
        { 
            step: lt('viewInvitation'), 
            component: <Step2Success {...{invitation}} /> 
        },
    ]

    return <FullScreenCreate loading={loading} hideStepper {...{cancelPath}} toolbarHeader={lt('viewInvitation')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    viewInvitation: string,
}
const enTranslations: translations = {
    viewInvitation: 'View Network Invitation'
}