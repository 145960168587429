import { gql } from '@apollo/client';
import { LedgerContractFields, LedgerContract } from './ledgerContracts'
import { PaginatedEnvironmentResourcesVars, NodeResourcesVars } from '../interfaces';

export interface GatewayAPI {
    _id: string
    consortia_id?: string
    environment_id?: string
    abi?: string
    bytecode?: string
    bytecodeHash?: string
    bytecodeLength?: number
    devDocs?: string
    endpoint: string
    hasConstructorParams?: boolean
    isFactoryDeployEnabled: boolean
    createdAt?: string
    updatedAt?: string
    consortiaContractId: string
    consortiaContractName?: string
    description?: string
    label?:string
    sequence?: string
    init_required?: boolean
    installed?: boolean
    approvedChannels?: {
        id: string
        name: string
    }[]
    committedChannels?: {
        id: string
        name: string
        sequence: string
        approvals: { [membershipId: string]: boolean }
    }[]
}

export interface GatewayAPIsVars extends PaginatedEnvironmentResourcesVars{
    endpoint?: string
}

export interface GatewayAPIsData {
    gatewayAPIs: {
        totalCount: number,
        gatewayAPIs: GatewayAPI[]
    }
}
export interface GatewayAPIContractsData {
    gatewayAPIContracts: {
        totalCount: number,
        contracts: LedgerContract[]
    }
}

export interface FireflyDeployedContractsData {
    fireflyDeployedContracts: {
        totalCount: number,
        contracts: LedgerContract[]
    }
}

export interface GatewayAPIData {
    gatewayAPI?: GatewayAPI
}

export const GatewayAPIFields = ` 
    fragment GatewayAPIFields on GatewayAPI {
        _id
        consortia_id
        environment_id
        abi
        bytecode
        bytecodeHash
        bytecodeLength
        devDocs
        endpoint
        hasConstructorParams
        isFactoryDeployEnabled
        createdAt
        updatedAt
        consortiaContractId
        consortiaContractName
        description
        label
        sequence
        init_required
        installed
        approvedChannels {
            id
            name
        }
        committedChannels {
            id
            name
            sequence
            approvals
        }
    }`

export const GatewayAPIsQuery = gql`
    ${GatewayAPIFields}
    query getGatewayAPIs($consortia_id: String!, $environment_id: String!, $start: Int, $limit: Int, $endpoint: String) {
        gatewayAPIs(consortia_id: $consortia_id, environment_id: $environment_id, start: $start, limit: $limit, endpoint: $endpoint) {
            totalCount
            gatewayAPIs {
                ...GatewayAPIFields
            }
        }
    }`;      

export const GatewayAPIQuery = gql`
    ${GatewayAPIFields}
    query getGatewayAPI($consortia_id: String!, $environment_id: String!, $id: String!) {
        gatewayAPI(consortia_id: $consortia_id, environment_id: $environment_id, id: $id) {
            ...GatewayAPIFields
        }
    }`;    

export const GatewayAPIContractsQuery = gql`
    ${LedgerContractFields}
    query getGatewayAPIContracts($consortia_id: String!, $environment_id: String!, $id: String!, $start: Int, $limit: Int) {
        gatewayAPIContracts(consortia_id: $consortia_id, environment_id: $environment_id, id: $id, start: $start, limit: $limit) {
            totalCount
            contracts {
                ...LedgerContractFields
            }
        }
    }`;

export const FireflyGatewayAPIContractsQuery = gql`
    ${LedgerContractFields}
    query getFireflyGatewayAPIContracts($consortia_id: String!, $environment_id: String!) {
        fireflyDeployedContracts(consortia_id: $consortia_id, environment_id: $environment_id) {
            totalCount
            contracts {
                ...LedgerContractFields
            }
        }
    }`;

export interface ChaincodeStatuses {
    _id: GatewayAPI["_id"]
    consortiaContractId: GatewayAPI["consortiaContractId"]
    endpoint: GatewayAPI["endpoint"]
    label: GatewayAPI["label"]
    sequence: GatewayAPI["sequence"]
    init_required: GatewayAPI["init_required"]
    installed: GatewayAPI["installed"]
    approvedChannels: GatewayAPI["approvedChannels"]
    committedChannels: GatewayAPI["committedChannels"]
}

export interface ChaincodeStatusesData {
    chaincodeStatuses: ChaincodeStatuses
}

export interface ChaincodeStatusesVars extends NodeResourcesVars{
    id: string // the gateway api id
}

const ChaincodeStatusesFields = `
    fragment ChaincodeStatusesFields on ChaincodeStatuses {
        _id
        consortiaContractId
        endpoint
        label
        sequence
        init_required
        installed
        approvedChannels {
            id
            name
        }
        committedChannels {
            id
            name
            sequence
            approvals
        }
    }`

export const ChaincodeStatusesQuery = gql`
    ${ChaincodeStatusesFields}
    query getChaincodeStatuses($consortia_id: String!, $environment_id: String!, $node_id: String!, $id: String!) {
        chaincodeStatuses(consortia_id: $consortia_id, environment_id: $environment_id, node_id: $node_id, id: $id) {
            ...ChaincodeStatusesFields
        }
    }`;  