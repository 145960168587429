import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ConsortiumResourceVars, EnvironmentResourceVars, NodeResourceVars } from '../../interfaces';
import { AppCred, CompiledContract, CompiledContractVars, Consortium, ContractProject, DeleteAppCredMutation, DeleteCompiledContractMutation, DeleteConsortiumMutation, DeleteContractProjectMutation, DeleteEnvironmentMutation, DeleteNodeMutation, DeleteServiceMutation, Environment, Node, Service } from '../../models';
import { DeleteResourceDialog } from './DeleteResourceDialog';
import { OauthConfiguration, DeleteOauthConfigurationMutation } from '../../models/oauthConfiguration';
import { DeleteEventStreamSubscriptionMutation, MakeEventStreamSubscriptionDeleteMutationOptions, DeleteEventStreamMutation, MakeEventStreamDeleteMutationOptions } from '../../models/eventStreams';

interface Props {
    name: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    disableNameConfirmation?: boolean,
    closeDialogAfterSave?: boolean,
    actionAfterDelete?: () => void,
    service?: Service['service']
}

export const DeleteResource = ({ name, open, setOpen, disableNameConfirmation = false, closeDialogAfterSave = false, actionAfterDelete, service }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DeleteResource', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DeleteResource:${key}`, interpolate)

    const { consortium_id, environment_id, node_id, service_id, appcred_id, contract_id, compiled_contract_id, oauth_id, 
        eventstream_subscription_id, eventstream_id } = useParams<any>();

    const envResourceVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [deleteNode, { loading: deleteNodeLoading }] = useMutation<Node, EnvironmentResourceVars>(DeleteNodeMutation)
    const [deleteService, { loading: deleteServiceLoading }] = useMutation<Service, EnvironmentResourceVars>(DeleteServiceMutation)
    const [deleteAppCred, { loading: deleteAppCredLoading }] = useMutation<AppCred, EnvironmentResourceVars>(DeleteAppCredMutation)
    const [deleteOauthConfiguration, { loading: deleteOauthConfigurationLoading }] = useMutation<OauthConfiguration, EnvironmentResourceVars>(DeleteOauthConfigurationMutation)
    const [deleteContractProject, { loading: deleteContractProjectLoading }] = useMutation<ContractProject, ConsortiumResourceVars>(DeleteContractProjectMutation)
    const [deleteCompiledContract, { loading: deleteCompiledContractLoading }] = useMutation<CompiledContract, CompiledContractVars>(DeleteCompiledContractMutation)
    const [deleteEnvironment, { loading: deleteEnvironmentLoading }] = useMutation<Environment, ConsortiumResourceVars>(DeleteEnvironmentMutation)
    const [deleteConsortium, { loading: deleteConsortiumLoading }] = useMutation<Consortium, { id: string }>(DeleteConsortiumMutation)
    const [deleteEventStream, { loading: deleteEventStreamLoading }] = useMutation<string, NodeResourceVars>(DeleteEventStreamMutation)
    const [deleteEventStreamSubscription, { loading: deleteEventStreamSubscriptionLoading }] = useMutation<string, NodeResourceVars>(DeleteEventStreamSubscriptionMutation)

    const loading = deleteNodeLoading || deleteServiceLoading || deleteAppCredLoading || 
        deleteContractProjectLoading || deleteCompiledContractLoading || deleteEnvironmentLoading ||
        deleteConsortiumLoading || deleteOauthConfigurationLoading || deleteEventStreamLoading ||
        deleteEventStreamSubscriptionLoading

    const updater = () => {      
        if (eventstream_subscription_id) {
            const updateVars = { ...envResourceVars, ...{ node_id: node_id!, id: eventstream_subscription_id } }
            return deleteEventStreamSubscription(MakeEventStreamSubscriptionDeleteMutationOptions(updateVars))
                .then(actionAfterDelete ? actionAfterDelete : () => {})
        } else if (eventstream_id) {
            const updateVars = { ...envResourceVars, ...{ node_id: node_id!, id: eventstream_id } }
            return deleteEventStream(MakeEventStreamDeleteMutationOptions(updateVars))
                .then(actionAfterDelete ? actionAfterDelete : () => {})
        } else if (node_id) {
            const updateVars = { ...envResourceVars, ...{ id: node_id! } }
            return deleteNode({
                variables: updateVars
            }).then(() => {})
        } else if (service_id) {
            const updateVars = { ...envResourceVars, ...{ id: service_id! } }
            return deleteService({
                variables: updateVars
            }).then(() => {})
        } else if (appcred_id) {
            const updateVars = { ...envResourceVars, ...{ id: appcred_id! } }
            return deleteAppCred({
                variables: updateVars
            }).then(() => {})
        } else if (oauth_id) {
            const updateVars = { ...envResourceVars, ...{ id: oauth_id! } }
            return deleteOauthConfiguration({
                variables: updateVars
            }).then(() => {})
        } else if (compiled_contract_id) {
            const updateVars = {
                consortia_id: consortium_id!, 
                contract_id: contract_id!,
                id: compiled_contract_id! 
            }
            return deleteCompiledContract({
                variables: updateVars
            }).then(() => {})
        } else if (contract_id) {
            const updateVars = {
                consortia_id: consortium_id!, 
                id: contract_id! 
            }
            return deleteContractProject({
                variables: updateVars
            }).then(() => {})
        } else if (environment_id) {
            const updateVars = {
                consortia_id: consortium_id!, 
                id: environment_id! 
            }
            return deleteEnvironment({
                variables: updateVars
            }).then(() => {})
        } else if (consortium_id) {
            const updateVars = {
                id: consortium_id! 
            }
            return deleteConsortium({
                variables: updateVars
            }).then(() => {})
        } else {
            return new Promise<void>(() => {})
        }
    }

    const deleteWarning = service === 'firefly-os' ? lt('ffWarning') : ''

    return <DeleteResourceDialog deleteWarning={deleteWarning} resourceType={lt('resource')} {...{name}} {...{open}} {...{setOpen}} onSave={updater} {...{loading}} {...{disableNameConfirmation}} {...{closeDialogAfterSave}} />
};

interface translations {
    deleteResource: string,
    delete: string,
    confirmDeletion: string,
    resource: string, // Could be improved by lookig up resource type (service type is not passed in)
    ffWarning: string
}
const enTranslations: translations = {
    deleteResource: 'Delete this resource ({{name}})?',
    delete: 'Delete',
    confirmDeletion: 'Name',
    resource: 'Resource',
    ffWarning: 'Note that you can no longer use the signing account address selected during the register process for this FireFly node if you wish to recreate one with the same membership. If you delete this FireFly node, you will have to select a different signing address to register a new FireFly node to the same namespace.'
}