import { useQuery } from '@apollo/client';
import { Button, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CLOUDCONFIG_BASE_PATH, CLOUDCONFIG_CLOUDHSM_PATH } from '../../../components/MainNav/SideNavs/CloudConfigs';
import { KEY_MANAGEMENT_CLOUD_PATH, KEY_MANAGEMENT_BASE_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import { RuntimeCreationFinalStep } from '../../../components/RuntimeCreation/RuntimeCreationFinalStep';
import { EnvironmentResourcesVars } from '../../../interfaces';
import { ConsortiumZone, EnvironmentZone, ServiceDetails, ServicesEnum } from '../../../models';
import { ConfigsData, ConfigsQuery } from '../../../models/configs';
import { StepHelp } from './StepHelp';

interface Props {
    cancelPath: string
    name: string
    membershipId: string,
    consortiumZoneId: string,
    consortiumZones: ConsortiumZone[],
    environmentZones: EnvironmentZone[]
}


export const Step2 = ({cancelPath, name, consortiumZones, environmentZones, membershipId, consortiumZoneId}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateCloudHsmWalletStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateCloudHsmWalletStep2:${key}`, interpolate)

    const [config, setConfig] = useState('');

    const { consortium_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const {
        loading,
        data: {
            configs
        } = { configs: [] }
    } = useQuery<ConfigsData, EnvironmentResourcesVars>(ConfigsQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!
        },
        fetchPolicy: 'cache-and-network'
    });

    const cloudHsmConfigs = useMemo(() => {
        return configs.filter(config => config.type === "cloudhsm");
    }, [configs]);

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const content = (
        <>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('header')}</Typography>
                </Grid>
                <Grid item container justify="space-between" wrap="nowrap">
                    <Grid item xs={10}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {lt('configDescription')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" 
                            onClick={() => history.push(`${basePath}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_CLOUDHSM_PATH}`)}>
                            {lt('create')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <TextField data-test="textField_configSelector" disabled={cloudHsmConfigs.length === 0} fullWidth select label={lt('configuration')} value={config} variant="outlined" onChange={e => setConfig(e.target.value)}>
                        {cloudHsmConfigs.map((entry, index) => (
                            <MenuItem key={`type-${index}`} value={entry._id}>{entry.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                { cloudHsmConfigs.length === 0 && !loading && (
                    <Grid item>
                        <Typography color="error" variant="body2">{lt('noConfig')}</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    )

    const details: ServiceDetails = {
        cloudhsm_id: config
    }

    return (
        <RuntimeCreationFinalStep disabled={!config}
            servicePath={`${basePath}/${KEY_MANAGEMENT_BASE_PATH}/${KEY_MANAGEMENT_CLOUD_PATH}/${ServicesEnum.cloudhsm}`}
            service={ServicesEnum.cloudhsm}
            {...{cancelPath}}
            {...{name}}
            {...{membershipId}}
            {...{consortiumZones}}
            {...{consortiumZoneId}}
            {...{environmentZones}}
            {...{content}}
            {...{details}}
            helpContent={<StepHelp />} />
    )
}

interface translations {
    header: string
    configuration: string
    configDescription: string
    noConfig: string
    create: string
}
const enTranslations: translations = {
    header: 'Cloud HSM Wallets',
    configuration: 'Cloud HSM Configuration',
    configDescription: "You need to select a Cloud HSM Cloud Configuration. If you haven't created one, please create one first and then come back.",
    noConfig: 'No Cloud HSM configuration created',
    create: 'Create'
}