import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { SideNavProps, ConsortiumResourceVars } from '../../../interfaces';
import { useQuery } from '@apollo/client';
import { NodesQuery, NodesData, EnvironmentData, EnvironmentQuery, FeatureTogglesQuery, FeatureTogglesVars, FeatureTogglesData, ServicesData, ServicesQuery, ServicesEnum, ServicesTranslations, EnServicesTranslations, environmentSupportsRotateSigners } from '../../../models';
import { SubNavs } from './SubNavs';
import { EnvironmentResourcesVars } from '../../../interfaces';
import HexagonMultipleOutlineIcon from 'mdi-react/HexagonMultipleOutlineIcon';
import { NavItem } from '../../NavComponents/NavItem';

export const BLOCKCHAIN_BASE_PATH = 'blockchain';
export const BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH = 'configuration';
export const BLOCKCHAIN_CREATE_PATH = 'create';
export const BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH = 'certificateauthorities';

export const Blockchain = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Blockchain', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Blockchain:${key}`, interpolate)

    const { org_id, consortium_id } = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();

    const [active, setActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: { services } = { services: [] },
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id,
        },
        fetchPolicy: "cache-only",
    });

    const {
        loading: nodesLoading,
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });


    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    const {
        data: { featureToggles } = { featureToggles: null }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        fetchPolicy: 'cache-first'
    });

    const historyPusher = useCallback((specificPath?: string) => 
        history.push(`${basePath}/nodes${specificPath ? `/${specificPath}` : ''}`), 
        [history, basePath])

    const DASHBOARD = lt('dashboard')
    const NODES = lt('nodes');
    const PROTOCOL_CONFIGURATIONS = lt('protocolConfigurations');
    const ON_CHAIN_REGISTRY = lt(ServicesEnum.idregistry)
    const IPFS = lt(ServicesEnum.ipfs);
    const ROTATE_SIGNERS = lt(ServicesEnum.rotatesigners);
    const CHAINLINK = lt(ServicesEnum.chainlink);
    const TETHER = lt(ServicesEnum.tether);
    const IDENTITY_MANAGEMENT = lt('identityManagement')

    let ipfsItems = services.filter(s => s.membership.isMine && s.service === ServicesEnum.ipfs)
                        .map((s, index, items) => ({
                            name: s.name,
                            action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}/${s._id}`),
                            divider: index === (items.length - 1),
                            disabled: !featureToggles?.ipfs ?? true
                        }));

    let rotateSignersItems = services.filter(s => s.service === ServicesEnum.rotatesigners)
                        .map((s, index, items) => ({
                            name: s.name,
                            action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}/${s._id}`),
                            divider: index === (items.length - 1),
                            disabled: false
                        }));
    
    let chainlinkItems = services.filter(s => s.membership.isMine && s.service === ServicesEnum.chainlink)
                        .map((s, index, items) => ({
                            name: s.name,
                            action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.chainlink}/${s._id}`),
                            divider: index === (items.length - 1),
                            disabled: !featureToggles?.chainlink ?? true                    
                        }));

    let idRegItems = services.filter(s => s.service === ServicesEnum.idregistry)
                        .map((s, index, items) => ({
                            name: s.name,
                            action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.idregistry}/${s._id}`),
                            divider: index === (items.length - 1),
                            disabled: !featureToggles?.idregistry ?? true                    
                        }));

    let tetherItems = services.filter(s => s.service === ServicesEnum.tether)
                        .map((s, index, items) => ({
                            name: s.name,
                            action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/${s._id}`),
                            divider: index === (items.length - 1),
                            disabled: !featureToggles?.tether ?? true
                        }));

    let caItems = services.filter(s => s.service === ServicesEnum['fabric-ca'] && s.membership.isMine)
                    .map((s, index, items) => ({
                        name: s.name,
                        action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}/${s._id}`),
                        divider: false,
                        disabled: !featureToggles?.fabricChannels ?? true
                    }));

    let popoverItems = nodes
    .filter(n => n.membership.isMine).map(node => ({
        name: node.name,
        action: () => historyPusher(node ? node._id : ''),
        divider: false,
        disabled: false
    }))

    if (popoverItems.length) {
        popoverItems[popoverItems.length - 1].divider = true
    };

    if (environment?.isFabric) {
        popoverItems.push({
            name: lt('addOrdererNode'),
            action: () => history.push(`${basePath}/nodes/create/1/orderer`),
            divider: false,
            disabled: environment?.state !== 'live'
        })
        popoverItems.push({
            name: lt('addPeerNode'),
            action: () => history.push(`${basePath}/nodes/create/1/peer`),
            divider: false,
            disabled: environment?.state !== 'live'
        })
    } else {
        popoverItems.push({
            name: lt('addNode'),
            action: () => history.push(`${basePath}/nodes/create/1`),
            divider: false,
            disabled: environment?.state !== 'live'
        });
    }

    chainlinkItems.push({
        name: lt('addRuntime'),
        action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.chainlink}/${BLOCKCHAIN_CREATE_PATH}/1`),
        divider: false,
        disabled: !featureToggles?.chainlink || environment?.state !== 'live'
    });

    ipfsItems.push({
        name: lt('addRuntime'),
        action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.ipfs}/${BLOCKCHAIN_CREATE_PATH}/1`),
        divider: false,
        disabled: !featureToggles?.ipfs || environment?.state !== 'live'
    });

    rotateSignersItems.push({
        name: lt('addRuntime'),
        action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.rotatesigners}/${BLOCKCHAIN_CREATE_PATH}/1`),
        divider: false,
        disabled: rotateSignersItems.length !== 0 || environment?.state !== 'live' // Only one service is allowed
    });

    idRegItems.push({
        name: lt('addRuntime'),
        action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.idregistry}/${BLOCKCHAIN_CREATE_PATH}/1`),
        divider: false,
        disabled: idRegItems.length !== 0 || !featureToggles?.idregistry || environment?.state !== 'live' // Only one service is allowed
    });

    tetherItems.push({
        name: lt('addRuntime'),
        action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/${BLOCKCHAIN_CREATE_PATH}/1`),
        divider: false,
        disabled: tetherItems.length !== 0 || environment?.state !== 'live'
    });

    const nodesSubItems = useCallback(() => {
        const itemsArr = [{
            name: DASHBOARD,
            action: () => history.push(`${basePath}/${BLOCKCHAIN_BASE_PATH}`)
        }, {
            name: NODES,
            popoverItems: popoverItems
        }];

        if (featureToggles?.ipfs && !isCorda) {
            itemsArr.push({
                name: IPFS,
                popoverItems: ipfsItems
            })
        };

        if (environmentSupportsRotateSigners(environment)) {
            itemsArr.push({
                name: ROTATE_SIGNERS,
                popoverItems: rotateSignersItems
            })
        }

        if (isFabric) {
            itemsArr.push({
                name: IDENTITY_MANAGEMENT,
                popoverItems: caItems
            })
        }

        if (featureToggles?.chainlink && !isCorda && !isFabric) {
            itemsArr.push({
                name: CHAINLINK,
                popoverItems: chainlinkItems
            })
        };

        if (featureToggles?.idregistry && !isCorda && !isFabric) {
            itemsArr.push({
                name: ON_CHAIN_REGISTRY,
                popoverItems: idRegItems
            })
        }

        if (featureToggles?.tether && !isCorda && !isFabric) {
            itemsArr.push({
                name: TETHER,
                popoverItems: tetherItems
            })
        }

        if (featureToggles?.nodeConfigs && !isCorda) {
            itemsArr.push({
                name: PROTOCOL_CONFIGURATIONS,
                action: () => historyPusher(BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH),
            })
        }

        return itemsArr
    }, [
        historyPusher, 
        DASHBOARD, 
        NODES, 
        popoverItems, 
        ipfsItems, 
        chainlinkItems, 
        idRegItems,
        tetherItems,
        caItems,
        PROTOCOL_CONFIGURATIONS, 
        IPFS, 
        CHAINLINK,
        ON_CHAIN_REGISTRY,
        IDENTITY_MANAGEMENT,
        TETHER,
        featureToggles,
        basePath,
        history,
        isCorda,
        isFabric,
        ROTATE_SIGNERS,
        rotateSignersItems,
        environment
    ])

    const splitPathname = pathname.split('/');
    const blockchainTab = splitPathname[splitPathname.length -2];

    useEffect(() => {
        if (nodesLoading) return

        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.endsWith(`${environment_id}/${BLOCKCHAIN_BASE_PATH}`)) {
            setSelectedItem(DASHBOARD);
            setActiveAndSelected()
        } else if (pathname.endsWith(`nodes/${BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH}`) || blockchainTab === BLOCKCHAIN_PROTOCOL_CONFIGURATIONS_PATH) {
            setSelectedItem(PROTOCOL_CONFIGURATIONS);
            setActiveAndSelected();
        } else {
            setActiveAndSelected(false)
            setSelectedItem('')
        }
    }, [setSelected, environment_id, nodesLoading, pathname, DASHBOARD, PROTOCOL_CONFIGURATIONS, IDENTITY_MANAGEMENT, blockchainTab])

    return (
        <>
            <NavItem icon={<HexagonMultipleOutlineIcon />}
                header={lt('name')} 
                action={() => { if (!active) setSelected(!selected) }} 
            />
            <SubNavs list={nodesSubItems()} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations extends ServicesTranslations {
    name: string
    dashboard: string
    nodes: string
    addNode: string
    addOrdererNode: string
    addPeerNode: string
    protocolConfigurations: string
    addRuntime: string
    identityManagement: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    name: 'Blockchain',
    dashboard: 'Dashboard',
    nodes: 'Nodes',
    addNode: 'Add Node',
    addOrdererNode: 'Add Orderer Node',
    addPeerNode: 'Add Peer Node',
    protocolConfigurations: 'Protocol Configurations',
    addRuntime: 'Add Runtime',
    identityManagement: 'Certificate Authorities'
}

