import React from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ConsortiumMembershipsData, ConsortiumMembershipsQuery } from '../../models';
import { ConsortiumResourcesVars, AutocompleteIdName } from '../../interfaces'
import { IdNameAutocomplete } from './IdNameAutocomplete';

interface Props {
    membershipInfo: AutocompleteIdName,
    setMembershipInfo: React.Dispatch<React.SetStateAction<AutocompleteIdName>>
    excludedMembershipIds?: string[],
    error?: boolean,
    helperText?: string
    dataTest?: string,
    setWidth?: number
};
  
export const MembershipAutocomplete = ({ membershipInfo, setMembershipInfo, excludedMembershipIds, error, helperText, dataTest, setWidth }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MembershipSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MembershipSelector:${key}`, interpolate)
    const { consortium_id } = useParams<any>();

    const { 
        data: { 
            consortiumMemberships
        } = { consortiumMemberships: [] } 
    } = useQuery<ConsortiumMembershipsData, ConsortiumResourcesVars>(ConsortiumMembershipsQuery, {
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    const memberships: AutocompleteIdName[] = consortiumMemberships.filter(m => m.is_mine && !excludedMembershipIds?.includes(m._id)).map(m => ({id: m._id, name: m.org_name}));

    return (
        <IdNameAutocomplete selected={membershipInfo} setSelected={setMembershipInfo} options={memberships} 
            error={error} helperText={helperText} dataTest={dataTest} setWidth={setWidth} label={lt('selectOrAddMember')} />
    )
};

interface translations {
    selectOrAddMember: string
}
const enTranslations: translations = {
    selectOrAddMember: 'Select existing or create a new membership'
}