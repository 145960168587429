import React, { useState, useEffect } from 'react';
import { 
    List,
    makeStyles,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
} from '@material-ui/core';
import { useParams, useHistory, useLocation, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { SubNavs } from '../MainNav/SideNavs/SubNavs';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { useQuery } from '@apollo/client';
import { EnvironmentResourceVars } from '../../interfaces';
import { ServiceData, ServiceQuery } from '../../models';
import { BLOCKCHAIN_BASE_PATH, BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH } from '../MainNav/SideNavs/Blockchain';
import { CertificateAuthorityPicker } from './CertificateAuthorityPicker';
import { ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH, ADDRESSBOOK_PATH } from '../MainNav/SideNavs/AddressBook';

export const CERTIFICATE_AUTHORITY_IDENTITIES = 'identities'

const CertificateAuthorityNav = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CertificateAuthorityNav', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CertificateAuthorityNav:${key}`, interpolate)

    const OVERVIEW = lt('overview');
    const IDENTITIES = lt('identities');
    const LOGS = lt('logs');
    const SETTINGS = lt('settings');

    const history = useHistory()
    const { org_id, consortium_id, environment_id, service_id, destination_uri  } = useParams<any>();
    const { pathname } = useLocation();
    const addressBookMode = pathname.includes(`${ADDRESSBOOK_PATH}`);

    const [selectedItem, setSelectedItem] = useState<string>();

    useEffect(() => {
        if (pathname.endsWith(service_id!)) setSelectedItem(OVERVIEW)
        if (pathname.endsWith("identities")) setSelectedItem(IDENTITIES)
        if (pathname.endsWith("logs")) setSelectedItem(LOGS)
        if (pathname.endsWith("settings")) setSelectedItem(SETTINGS)
    }, [pathname, service_id, OVERVIEW, IDENTITIES, LOGS, SETTINGS, destination_uri])

    const { data: { service } = { service: null } } = useQuery<
        ServiceData,
        EnvironmentResourceVars
    >(ServiceQuery, {
        variables: {
            environment_id: environment_id!,
            consortia_id: consortium_id!,
            id: service_id!,
        },
        fetchPolicy: "cache-only"
    });

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/`;
    const servicePath = addressBookMode ?  `${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}` : `${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}`
    const baseIdentityManagementPath = basePath + `${servicePath}`;
    const backButtonPath = addressBookMode ? baseIdentityManagementPath : basePath + `${BLOCKCHAIN_BASE_PATH}`;
    const historyPusher = (path?: string) => history.push(`${baseIdentityManagementPath}/${service_id}${path ? `/${path}` : ''}`)

    if (!service) {
        return <Redirect to={backButtonPath} />
    }


    let tabs = [
        {
            name: OVERVIEW,
            action: () => historyPusher()
        },
        {
            name: IDENTITIES,
            action: () => historyPusher(CERTIFICATE_AUTHORITY_IDENTITIES)
        }
    ]
    
    if (service?.membership.isMine && !addressBookMode) {
        tabs.push({
            name: LOGS,
            action: () => historyPusher(LOGS.toLowerCase())
        }, {
            name: SETTINGS,
            action: () => historyPusher(SETTINGS.toLowerCase())
        });
    }

    return (
        <div>
            <div className={classes.toolbar} />
            <List disablePadding>
                <ListItem className={classes.backButton} button onClick={() => history.push(backButtonPath)}>
                    <ListItemIcon className={classes.backIcon}><ChevronLeftIcon /></ListItemIcon>
                    <ListItemText secondary={<Typography component='div' variant="button" color="textSecondary">{addressBookMode ? lt('addressBook') : lt('dashboard')}</Typography>} 
                        disableTypography />
                </ListItem>

                <ListItem>
                    <ListItemText secondary={lt('certificateAuthority')} secondaryTypographyProps={{variant: "button"}} />
                </ListItem>
                
                <CertificateAuthorityPicker />

                <SubNavs list={tabs} selected={true} {...{selectedItem}} />
            </List>
        </div> 
    );
};

const useStyles = makeStyles( theme => ({
    toolbar: {
        [theme.breakpoints.up('md')]: theme.mixins.toolbar
    },
    backButton: {
        paddingLeft: theme.spacing(1)
    },
    backIcon: {
        minWidth: 0
    }
}));

interface translations {
    overview: string
    identities: string
    dashboard: string
    certificateAuthority: string
    logs: string
    addressBook: string
    settings: string
}
const enTranslations: translations = {
    overview: 'Overview',
    identities: 'Identities',
    dashboard: 'Dashboard',
    certificateAuthority: 'Certificate Authorities',
    logs: 'Logs',
    addressBook: 'Address Book',
    settings: 'Settings'
}

export { CertificateAuthorityNav };

