import React from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { useParams, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { ContractProjectData, ContractProjectQuery,
    CompiledContractsData, CompiledContractsVars, CompiledContractsQuery,
    CompiledContractsSubscription, MakeCompiledContractsSubscriptionOptions } from '../../models'
import { CircularProgress } from "@material-ui/core";
import { ConsortiumResourceVars, BaseRouterProps } from '../../interfaces'
import { ContractProject } from './ContractProject'
import { CompiledContract } from './CompiledContract'
import { NETWORK_COMPILED_CONTRACTS_PATH, NETWORK_COMPILED_CONTRACTS_PROMOTE_PATH } from '../../components/MainNav/ConsortiumWrapper'
import { Create as CreateCompilation } from './CreateCompilation/Create'
import { Promote as PromoteCompilation } from './PromoteCompilation/Promote'

export const ContractProjectRouter = ({baseRoutePath} : BaseRouterProps) => {
    const { consortium_id, contract_id } = useParams<any>();

    const compiledContractsVars = { 
        consortia_id: consortium_id!,
        contract_id: contract_id!
    }

    const {
        data: {
            contractProject
        } = { contractProject: null }
    } = useQuery<ContractProjectData, ConsortiumResourceVars>(ContractProjectQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: contract_id!
        },
        fetchPolicy: 'cache-only' 
    });

    // setup the compiled contracts query and subscription
    const {
        loading,
        data: {
            compiledContracts
        } = { compiledContracts: null }
    } = useQuery<CompiledContractsData, CompiledContractsVars>(CompiledContractsQuery, {
        variables: compiledContractsVars,
        fetchPolicy: 'cache-and-network'
    });
    useSubscription(CompiledContractsSubscription, MakeCompiledContractsSubscriptionOptions(compiledContractsVars));

    const baseContractsRouteMatch = useRouteMatch(baseRoutePath.replace('/:contract_id', ''))

    if (loading && !Array.isArray(compiledContracts)) {
        return <CircularProgress />
    }

    // if the contract_id isnt valid, attempt to redirect first to the base contracts path and all else fails back to the root
    if (!contractProject) {
        return <Redirect to={baseContractsRouteMatch?.url ?? "/"} />
    }
    
    return (
        <Switch>
            <Route exact path={`${baseRoutePath}/${NETWORK_COMPILED_CONTRACTS_PATH}/create/:step`} render={() => <CreateCompilation />} />
            <Route exact path={`${baseRoutePath}/${NETWORK_COMPILED_CONTRACTS_PATH}/:compiled_contract_id`} render={() => <CompiledContract />} />
            <Route exact path={`${baseRoutePath}/${NETWORK_COMPILED_CONTRACTS_PATH}/:compiled_contract_id/${NETWORK_COMPILED_CONTRACTS_PROMOTE_PATH}/:step`} 
                render={() => <PromoteCompilation />} />
            <Route exact path={baseRoutePath} render={() => <ContractProject />} />
            <Redirect to={baseRoutePath} />
        </Switch>
    )
};
