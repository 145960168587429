import { gql } from '@apollo/client';
import { ServiceResourcesVars } from '../interfaces/shared';

// INTERFACES

export interface IDRegistryOrg {
    id: string
    name: string
}

export interface IDRegistryDirectory {
    id: string
    name: string
    description: string
    directory: string
    profiles: string
    createdAt: string
    updatedAt: string
}

export interface IDRegistryOrgDetails {
    id: string
    name: string
    owner: string
    parent: string
    profile_address: string
    proof: {
        headers: string[]
        signatures: string[]
    }
}

export interface IDRegistryAllProfileProperties {
    name: string
    details: {
        profile_properties: IDRegistryProfileProperty[]
    }
}

export interface IDRegistryOrgDetailsVars extends ServiceResourcesVars {
    id?: string
}

export interface IDRegistryProfilePropertiesVars extends ServiceResourcesVars {
    owner?: string
}

export interface IDRegistryDirectoriesData {
    idRegistryDirectories: IDRegistryDirectory
}
export interface IDRegistryOrgsData {
    idRegistryOrgs: IDRegistryOrg[]
}

export interface IDRegistryOrgDetailsData {
    idRegistryOrgDetails: IDRegistryOrgDetails
}

export interface IDRegistryAllProfilePropertiesData {
    idRegistryOrgs: IDRegistryAllProfileProperties[]
}

export interface IDRegistryProfileProperty {
    name: string
    value: string
    version: string
}

export interface IDRegistryVersionedProfileProperty {
    name: string
    values: {
        version: string
        value: string
    }[]
}

export interface IDRegistryProfilePropertiesData {
    idRegistryProfileProperties: IDRegistryVersionedProfileProperty[]
}

export interface Identity {
    id: string
    owner: string
    parent: string
    name: string
}

export interface ProfileEntry {
    key_name: string
    value: string
    version: string
}

export interface CreateIdentityData {
    createIdentity: Identity
}

export interface AddNewProfileEntryData {
    addProfileEntry: ProfileEntry
}

export interface CreateIdentityVars {
    service_id: string
    identity: {
        membership_id: string
    }
}

export interface AddNewEntryVars {
    service_id: string
    profile_id: string
    profileEntry: {
        key_name: string
        membership_id: string
        profile_address: string
        value: string
    }
}

export const IdentityFields = `
    fragment IdentityFields on Identity {
        id
        owner
        parent
        name
    }
`

export const AddProfileEntryFields = `
    fragment AddProfileEntryFields on NewProfileEntry {
        key_name
        value
        version
    }
`
// QUERIES & SUBSCRIPTIONS


export const IDRegistryDirectoriesQuery = gql`
    query getIDRegistryDirectories($service_id: String!) {
        idRegistryDirectories(service_id: $service_id) {
            id
            name
            description
            directory
            profiles
            createdAt
            updatedAt
        }
    }`;

export const IDRegistryOrgsQuery = gql`
    query getIDRegistryOrgs($service_id: String!) {
        idRegistryOrgs(service_id: $service_id) {
            id
            name
        }
    }`;

export const IDRegistryAllProfilePropertiesQuery = gql`
    query getIDRegistryAllProfileProperties($service_id: String!) {
        idRegistryOrgs(service_id: $service_id) {
            id
            name
            details {
                profile_properties {
                    name
                    value
                    version
                }
            }
        }
    }`;

export const IDRegistryOrgDetailsQuery = gql`
    query getIDRegistryOrgDetails($service_id: String!, $id: String!) {
        idRegistryOrgDetails(service_id: $service_id, id: $id) {
            id
            name
            owner
            profile_address
            proof {
                headers
                payload
                signatures
            }
        }
    }`;

export const IDRegistryProfilePropertiesQuery = gql`
    query getIDRegistryProfilePropertiesQuery($service_id: String!, $owner: String!) {
        idRegistryProfileProperties(service_id: $service_id, owner: $owner) {
            name
            values {
                version
                value
            }
        }
    }`;

//Return type should be improved
export const CreateIdentityOnChainMutation = gql`
    ${IdentityFields}
    mutation createIdentity($service_id: String!, $identity: CreateIdentityInput!) {
        createIdentity(service_id: $service_id, identity: $identity) {
            ...IdentityFields
        }
    }
`

export const AddProfileEntryMutation = gql`
    ${AddProfileEntryFields}
    mutation addProfileEntry($service_id: String!, $profile_id: String!, $profileEntry: ProfileEntryInput!) {
        addProfileEntry(service_id: $service_id, profile_id: $profile_id, profileEntry: $profileEntry) {
            ...AddProfileEntryFields
        }
    }
` 