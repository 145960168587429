import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { Typography, TextField, Grid } from "@material-ui/core";
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector'
import { CreateWrapper } from '../../../components/DialogWrappers'
import { CLOUDCONFIG_BASE_PATH, CREATE_CLOUDCONFIG_PATH, CloudConfigTypesUrl } from '../../../components/MainNav/SideNavs/CloudConfigs';
import { ConfigsHelp } from './Helpers/ConfigsHelp';
import { CreateStepProps } from '../../../interfaces';

interface Props extends CreateStepProps {
    configType: CloudConfigTypesUrl | ''
    name: string
    setName: React.Dispatch<React.SetStateAction<string>>
}

export const Step1 = ({ cancelPath, configType, name, setName }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigStep1:${key}`, interpolate)

    const [membershipId, setMembershipId] = useState('');

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CREATE_CLOUDCONFIG_PATH}/2`, {
            name,
            membershipId,
            configType
        })
    }

    const disabled = !name || !membershipId;

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />

                <TextField data-test="textField_configName"
                    value={name || ''} 
                    onChange={event => setName(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('name')}
                    variant="outlined"
                />
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep />
            <ConfigsHelp />
        </>
    )
};

interface translations {
    header: string,
    name: string,
}
const enTranslations: translations = {
    header: 'Choose your membership and name',
    name: 'Name',
}