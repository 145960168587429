import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
    TetherStatusQuery,
    TetherStatusData,
} from "../../models/servicesStatus";
import {
    EnvironmentResourceVars,
    ServiceResourcesVars,
} from "../../interfaces";
import { useParams, Redirect } from "react-router-dom";
import { BLOCKCHAIN_BASE_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import {
    Grid,
    Typography,
    CircularProgress,
    TextField,
    MenuItem,
    makeStyles,
    Box,
    Button,
} from "@material-ui/core";
import {
    CopyableSettings,
    CopyableSetting,
} from "../../components/DisplaySettings";
import { useTranslation } from "react-i18next";
import {
    TetherAccountQuery,
    TetherAccountData,
    tetherNetworkTypes,
    DeployTetherContractMutation,
    DeployTetherContractData,
    SetTetherTargetNetwork,
    SetTetherTargetNetworkVars,
    ActivateTetherMutation,
    DeactivateTetherMutation,
    SetTetherIntervalMutation,
    SetTetherIntervalVars,
} from "../../models/tether";
import { DisplayBalance } from "./DisplayBalance";
import { FormLink } from "../../components/FormControls/FormLink";
import { CreateTetherAccount } from "./CreateTetherAccount";
import {
    ErrorSnackbarCatcher,
    MessageSnackbar,
} from "../../components/DialogWrappers";
import { ServicesEnum } from "../../models";

export const TetherManage = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "TetherManage", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`TetherManage:${key}`, interpolate);

    const { org_id, service_id, consortium_id, environment_id } = useParams<any>();
    const classes = useStyles();

    const [network, setNetwork] = useState<tetherNetworkTypes>("goerli");
    const [refreshBalance, setRefreshBalance] = useState(false);
    const [relayInterval, setRelayInterval] = useState(360);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState<"success" | "error">(
        "error"
    );
    const [actionLoading, setActionLoading] = useState(false);

    const {
        refetch: refetchStatus,
        loading,
        data: { tetherStatus } = { tetherStatus: null },
    } = useQuery<TetherStatusData, EnvironmentResourceVars>(TetherStatusQuery, {
        variables: {
            id: service_id,
            environment_id,
            consortia_id: consortium_id,
        },
        fetchPolicy: "cache-and-network",
    });

    const {
        refetch: refetchAccount,
        loading: loadingTetherAccount,
        data: { tetherAccount } = { tetherAccount: null },
    } = useQuery<TetherAccountData, ServiceResourcesVars>(TetherAccountQuery, {
        variables: {
            service_id,
        },
        fetchPolicy: "cache-and-network",
    });

    const [deployContract, {loading: deployLoading}] = useMutation<
        DeployTetherContractData,
        ServiceResourcesVars
    >(DeployTetherContractMutation);

    const [setTargetNetwork] = useMutation<
        void, //Do not need response
        SetTetherTargetNetworkVars
    >(SetTetherTargetNetwork);

    const [activateTether] = useMutation<
        void, //Do not need response
        ServiceResourcesVars
    >(ActivateTetherMutation);

    const [deactivateTether] = useMutation<
        void, //Do not need response
        ServiceResourcesVars
    >(DeactivateTetherMutation);

    const [setInterval] = useMutation<
        void, //Do not need response
        SetTetherIntervalVars
    >(SetTetherIntervalMutation);

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const refreshData = async () => {
        try {
            await refetchAccount();
            await refetchStatus();
        } catch (err) {
            ErrorSnackbarCatcher(err, setMessage);
        }
    };

    useEffect(() => {
        if (tetherStatus) {
            setNetwork(tetherStatus.active_network); //Set current network
            setRelayInterval(tetherStatus.relay_interval ?? 360); // Set current interval
        }
    }, [tetherStatus]);

    if ((loadingTetherAccount || loading) && !tetherAccount)
        return <CircularProgress />;
    if (!tetherStatus)
        return (
            <Redirect
                to={`${basePath}/${BLOCKCHAIN_BASE_PATH}/${ServicesEnum.tether}/${service_id}`}
            />
        );
    if (!tetherAccount?.address)
        return (
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt("manageTether")}</Typography>
                </Grid>
                <Grid item>
                    <CreateTetherAccount
                        {...{ setMessage }}
                        {...{ refreshData }}
                    />
                </Grid>
            </Grid>
        );

    const getAddFundPath = () => {
        if (network === "mainnet") {
            return "https://metamask.io/";
        } else {
            return "https://goerlifaucet.com//";
        }
    };

    const getAddressRedirectPath = (address: string) => {
        if (network === "mainnet") {
            return `https://etherscan.io/address/${address}`;
        } else {
            return `https://goerli.etherscan.io/address/${address}`;
        }
    };

    const handleContractDeploy = async () => {
        setActionLoading(true);
        try {
            await deployContract({
                variables: {
                    service_id,
                },
            });
            await refreshData();
        } catch (err) {
            setMessageType("error");
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setActionLoading(false);
        }
    };

    const hanleSetAsTargetNetwork = async () => {
        setActionLoading(true);
        try {
            await setTargetNetwork({
                variables: {
                    network,
                    service_id,
                },
            });
            await refreshData();
        } catch (err) {
            setMessageType("error");
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setActionLoading(false);
        }
    };

    const handleActivate = async () => {
        setActionLoading(true);
        try {
            await setInterval({
                variables: {
                    interval: relayInterval,
                    service_id,
                },
            });
            await activateTether({
                variables: {
                    service_id,
                },
            });
            await refreshData();
            setMessageType("success");
            setMessage(lt("activateSuccess"));
        } catch (err) {
            setMessageType("error");
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setActionLoading(false);
        }
    };

    const handleDeactivate = async () => {
        setActionLoading(true);
        try {
            await deactivateTether({
                variables: {
                    service_id,
                },
            });
            await refreshData();
            setMessageType("success");
            setMessage(lt("deactivateSuccess"));
        } catch (err) {
            setMessageType("error");
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setActionLoading(false);
        }
    };

    const handleSubmitChanges = async () => {
        setActionLoading(true);
        try {
            await setInterval({
                variables: {
                    interval: relayInterval,
                    service_id,
                },
            });
            await refreshData();
            setMessageType("success");
            setMessage(lt("submitChangesSuccess"));
        } catch (err) {
            setMessageType("error");
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setActionLoading(false);
        }
    };

    // Logic to handle action Button state since there are many scenarios
    const generateActionButton = () => {
        let label = "";
        let disabled = false;
        let action;
        if (tetherStatus.active_network !== network) {
            label = lt("setAsTargetNewtork");
            action = hanleSetAsTargetNetwork;
            if (tetherStatus.is_activated) {
                disabled = true;
            } else {
                disabled = false;
            }
        } else if (!tetherStatus.arbitrator_address) {
            label = lt("deployContract");
            action = handleContractDeploy;
        } else if (tetherStatus.is_activated) {
            label = lt("submit");
            disabled = tetherStatus.relay_interval === relayInterval;
            action = handleSubmitChanges; //Submit Interval
        } else if (!tetherStatus.is_activated) {
            label = lt("activate");
            action = handleActivate; //activate and set interval
        }
        return (
            <Button
                disabled={disabled || actionLoading}
                variant="contained"
                color="primary"
                onClick={action}
            >
                {label}
            </Button>
        );
    };

    const copyableList: CopyableSetting[] = [
        {
            title: lt("accountAddress"),
            displayValue: tetherAccount.address ? (
                <FormLink
                    target="_blank"
                    isExternal
                    to={getAddressRedirectPath(tetherAccount.address)}
                >
                    {tetherAccount.address}
                </FormLink>
            ) : (
                "--"
            ),
            copyableValue: tetherAccount.address,
        },
        {
            title: lt("contractAddress"),
            displayValue:
                tetherStatus.arbitrator_address &&
                tetherStatus.active_network === network ? (
                    <FormLink
                        target="_blank"
                        isExternal
                        to={getAddressRedirectPath(
                            tetherStatus.arbitrator_address
                        )}
                    >
                        {tetherStatus.arbitrator_address}
                    </FormLink>
                ) : (
                    deployLoading ? <CircularProgress /> : "--"
                ),
            copyableValue: tetherStatus.arbitrator_address,
        },
        {
            title: lt("network"),
            displayValue: (
                <TextField
                    size="small"
                    variant="outlined"
                    select
                    value={network}
                    className={classes.dropdown}
                    onChange={(e) =>
                        setNetwork(e.target.value as tetherNetworkTypes)
                    }
                >
                    <MenuItem value="mainnet">{lt("mainnet")}</MenuItem>
                    <MenuItem value="goerli">{lt("goerli")}</MenuItem>
                </TextField>
            ),
        },
        {
            title: lt("relayFrequency"),
            displayValue: (
                <TextField
                    select
                    size="small"
                    variant="outlined"
                    value={relayInterval}
                    className={classes.dropdown}
                    disabled={!tetherStatus.arbitrator_address}
                    onChange={(e) => setRelayInterval(parseInt(e.target.value))}
                >
                    <MenuItem value={60}>{lt("everyHour")}</MenuItem>
                    <MenuItem value={360}>{lt("every6Hours")}</MenuItem>
                    <MenuItem value={720}>{lt("every12Hours")}</MenuItem>
                    <MenuItem value={1440}>{lt("every24Hours")}</MenuItem>
                </TextField>
            ),
        },
        {
            title: lt("balance"),
            displayValue: (
                <DisplayBalance
                    refresh={refreshBalance}
                    setRefresh={setRefreshBalance}
                    {...{ network }}
                    {...{ service_id }}
                />
            ),
            customAction: (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <FormLink isExternal target="_blank" to={getAddFundPath()}>
                        {lt("addFunds")}
                    </FormLink>
                    <Box ml={1}>
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() => setRefreshBalance(true)}
                        >
                            {lt("refresh")}
                        </Button>
                    </Box>
                </Box>
            ),
        },
    ];

    const actionBar = (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
            {tetherStatus.is_activated &&
                tetherStatus.active_network === network && (
                    <Button
                        onClick={handleDeactivate}
                        disabled={actionLoading}
                        variant="outlined"
                    >
                        {lt("deactivateRelay")}
                    </Button>
                )}
            <Box ml={1}>{generateActionButton()}</Box>
        </Box>
    );

    return (
        <>
            <MessageSnackbar
                {...{ message }}
                {...{ setMessage }}
                {...{ messageType }}
            />
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt("manageTether")}</Typography>
                </Grid>
                <Grid item>
                    <CopyableSettings
                        {...{ actionBar }}
                        header={lt("manage")}
                        {...{ copyableList }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

interface translations {
    manageTether: string;
    manage: string;
    accountAddress: string;
    network: string;
    mainnet: string;
    goerli: string;
    balance: string;
    refresh: string;
    addFunds: string;
    relayFrequency: string;
    everyHour: string;
    every6Hours: string;
    every12Hours: string;
    every24Hours: string;
    deployContract: string;
    submitChanges: string;
    deactivateRelay: string;
    generateAccount: string;
    emptyDescription: string;
    generateAccountAndPrivateKey: string;
    contractAddress: string;
    submit: string;
    activate: string;
    setAsTargetNewtork: string;
    activateSuccess: string;
    deactivateSuccess: string;
    submitChangesSuccess: string;
}
const enTranslations: translations = {
    manageTether: "Manage Tether",
    manage: "Manage",
    accountAddress: "Account Address",
    mainnet: "Mainnet",
    goerli: "Goerli",
    network: "Network",
    balance: "Balance",
    refresh: "Refresh",
    addFunds: "Add Funds",
    relayFrequency: "Relay Frequency",
    every12Hours: "Every 12 hours",
    every24Hours: "Every 24 hours",
    every6Hours: "Every 6 hours",
    everyHour: "Every hour",
    deployContract: "Deploy Contract",
    submitChanges: "Submit Changes",
    deactivateRelay: "Deactivate Relay",
    generateAccountAndPrivateKey:
        "Generate an account and secure your private key",
    emptyDescription:
        "Pinning state proofs to a public Ethereum network offers robust protections against historical rewrites via the proven indelibility of confirmed transactions, while still maintaining the integrity of your private chain's sensitive ledger data.",
    generateAccount: "Generate Account",
    contractAddress: "Contract Address",
    submit: "Submit",
    activate: "Activate Tether",
    setAsTargetNewtork: "Set as target network",
    activateSuccess: "Tether Activated Succesfully",
    deactivateSuccess: "Tether Deactivated Succesfully",
    submitChangesSuccess: "Changes Submitted Succesfully",
};

const useStyles = makeStyles(() => ({
    dropdown: {
        width: 200,
    },
}));
