import { useQuery } from '@apollo/client';
import { Button, TablePagination, Typography } from "@material-ui/core";
import TextBoxCheckOutlineIcon from 'mdi-react/TextBoxCheckOutlineIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Dotdotdot } from '..';
import { EnvironmentResourceVars, PaginatedEnvironmentResourceVars } from '../../interfaces';
import { GatewayAPIContractsData, GatewayAPIContractsQuery, LedgerContract, LedgerContractData, LedgerContractQuery } from '../../models';
import { DisplayTable, EmptyState, ToolBarOptions } from '../DisplayWrappers';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { ShortenedHash } from '../FormControls/ShortenedHash';
import { DATA_CONTRACTS, ENVIRONMENT_DATA_EXPLORER } from '../MainNav/SideNavs/DataExplorer';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_TOKENS_PATH } from '../MainNav/SideNavs/DigitalAssets';
import { APPS_BASE_PATH, APPS_GATEWAY_APIS_PATH } from '../MainNav/SideNavs/AppsIntegrations';

const PAGINATION_OPTIONS = [10, 25];

interface Props {
    gatewayAPIId?: string,
    header?: string
    description?: string
    onRowClick?: (gatewayAPIId: string) => void,
    onSelectClick?: React.Dispatch<React.SetStateAction<string>>,
    isSelector?: boolean
}

export const GatewayAPIInstancesTable = ({ gatewayAPIId, header, description, onRowClick, onSelectClick, isSelector }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GatewayAPIInstancesTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GatewayAPIInstancesTable:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_OPTIONS[0]);
    const [page, setPage] = useState(0);
    
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const {
        loading: gatewayAPIContractsLoading,
        data: {
            gatewayAPIContracts
        } = { gatewayAPIContracts: null }
    } = useQuery<GatewayAPIContractsData, PaginatedEnvironmentResourceVars>(GatewayAPIContractsQuery, { 
        skip: !gatewayAPIId,
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: gatewayAPIId || '',
            start: rowsPerPage * page, 
            limit: rowsPerPage
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: isSelector ? undefined : 10000
    });

    const {
        data: {
            ledgerContract
        } = {ledgerContract: null}
    } = useQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: searchInput,
        },
        fetchPolicy: 'cache-first',
        skip: !searchInput,
        errorPolicy: 'ignore'
    });

    const columnHeaders = [
        '',
        lt('endpoint'),
        lt('address'),
        lt('deployedBy'),
        ''
    ]

    if (isSelector) {
        columnHeaders.splice(0, 2)
        columnHeaders.push('')
    }

    const makeDotMenuItems = (contract: LedgerContract) => {
        let menu = [{
            name: lt('viewInstanceAPI'),
            action: () => onRowClick ? onRowClick(contract.instanceEndpointId || contract.address) : undefined
        }, {
            name: lt('viewInstanceDetails'),
            action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}/${contract.address}`)
        }]

        if (contract.isERC20 || contract.isERC721) {
            menu.push({
                name: lt('viewTokenActions'),
                action: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/${contract.address}`)
            })
        }

        return menu
    }

    const makeRecord = (g: LedgerContract) => {
        const record: DisplayTableRecord = {
            key: g._id,
            columns: [
                {isIcon: true, value: <TextBoxCheckOutlineIcon />},
                {value: `/instances/${g.instanceEndpointId || g.address}`},
                {value: <ShortenedHash address={g.address} showFullAddress={isSelector} />},
                {value: <ShortenedHash address={g.creator} />},
                {value: <Dotdotdot menuItems={makeDotMenuItems(g)}/>}
            ]
        }

        if (isSelector && onSelectClick) {
            record.columns.splice(0, 2)
            record.columns.splice(record.columns.length - 1, 1)
            const selectButton = (
                <Button fullWidth 
                    variant="outlined" 
                    onClick={() => onSelectClick(gatewayAPIContracts?.contracts?.find(g => g._id === record.key)?._id ?? '')}>
                    <Typography variant="body2" color="textSecondary">
                        {lt('select')}
                    </Typography>
                </Button>
            )
            record.columns.push({ value: selectButton })
        }

        return record
    }

    const makeRecords = () => {
        if (isSearchActive && ledgerContract?._id && ledgerContract?.gatewayAPIId) {
            return [makeRecord(ledgerContract!)]
        } else if (isSearchActive) {
            return []
        } else {
            return gatewayAPIContracts?.contracts?.map(g => makeRecord(g))
        }
    } 

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const loading = gatewayAPIContractsLoading && !gatewayAPIContracts

    if (!loading && !isSearchActive && !gatewayAPIContracts?.contracts?.length) return (
        <EmptyState imageFile='Empty-Contracts-GatewayAPIs.svg' 
            title={lt('emptyTitle')} 
            button={isSelector ? { text: lt('deployInstance'), onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${gatewayAPIId}`) } : undefined}
            description={lt('emptyDescription')} 
            documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/smart-contracts/' }} />
    )

    const pagination = (
        <TablePagination rowsPerPageOptions={PAGINATION_OPTIONS} component="div" count={ gatewayAPIContracts?.totalCount ?? 0 } 
            rowsPerPage={rowsPerPage} page={page} 
            onChangeRowsPerPage={handleChangeRowsPerPage} onPageChange={handleChangePage}
            nextIconButtonProps={{disabled: loading || (page + 1) * rowsPerPage >= (gatewayAPIContracts?.totalCount ?? 0)}}
            backIconButtonProps={{disabled: loading || page <= 0}} />
    )

    const onSearchSubmit = (searchInput: string) => {
        setSearchInput(searchInput);
    }

    const toolBarOptions: ToolBarOptions | undefined = isSelector ? {
        headerTab: {
            selected: 0,
            tabs: [{
                label: lt('header')
            }]
        },
        search: {
            label: lt('search'),
            setSearchActive: setIsSearchActive,
            onSearch: onSearchSubmit
        }
    } : undefined
    
    return (
        <DisplayTable height={isSelector ? "50vh" : undefined} {...{toolBarOptions}} stickyHeader={isSelector ? true : false} emptyLabel={lt('empty')}
            {...{loading}} actionFooter={pagination} {...{header}} {...{description}} 
            {...{columnHeaders}} records={makeRecords()} hideFooterDivider={isSelector} />
    )
};

interface translations {
    header: string,
    endpoint: string,
    address: string,
    deployedBy: string,
    viewInstanceAPI: string,
    viewInstanceAPIHeader: string,
    viewInstanceAPIDescription: string,
    select: string,
    search: string,
    empty: string,
    emptyTitle: string,
    emptyDescription: string,
    emptyGoTo: string,
    emptyDocumentation: string,
    viewInstanceDetails: string,
    viewTokenActions: string,
    deployInstance: string
}
const enTranslations: translations = {
    header: 'Contract instances',
    endpoint: 'API endpoint path',
    address: 'Address',
    deployedBy: 'Deployed by',
    viewInstanceAPI: 'View instance API',
    viewInstanceAPIHeader: 'View the Contract Instance API',
    viewInstanceAPIDescription: 'Select a node to view the Contract Instance API ({{endpoint}})',
    emptyTitle: 'Deploy a Contract Instance',
    emptyDescription: 'Deploy a contract instance using the Gateway API to this environment.',
    emptyGoTo: 'Go to Contract Management',
    emptyDocumentation: 'Documentation',
    select: 'Select',
    search: 'Search exact contract address',
    empty: 'No contracts found for this Gateway API.',
    viewInstanceDetails: 'View contract details',
    viewTokenActions: 'View token actions',
    deployInstance: 'View Gateway API'
}