import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers';

interface Props {
    configType: 'backup' | 'storage'
}

export const BackupStorageHelp = ({configType}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigBackupStorageHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigBackupStorageHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
            header={lt(configType)}
            description={configType === 'storage' ? lt('storageDescription') : lt('backupDescription')}
            docLink="https://docs.kaleido.io/using-kaleido/cloud-integrations/backup/"
            bullet1={configType === 'backup' ? lt('backupHelp') : undefined}
            bullet2={configType === 'backup' ? lt('envBackup') : undefined}
        />
    )
};

interface translations {
    backup: string
    storage: string
    backupDescription: string
    storageDescription: string
    backupHelp: string
    envBackup: string
}

const enTranslations: translations = {
    backup: 'Back Up',
    storage: 'Storage',
    storageDescription: 'Storage configurations provide Kaleido access to the users AWS S3 buckets or Azure Blobs, enabling the ability to create a Document Exchange Service with access to this configuration. ',
    backupDescription: 'Node backups perform a snapshot backup into an AWS S3 bucket or Azure Blob and provide an added layer of ledger persistence and give owners full access to a node’s ledger and key materials.',
    backupHelp:  'Users can orchestrate workflows to call the /backup API on the node on a periodic basis.',
    envBackup: 'Selecting the Environment Backup Target option will upload your scheduled environment backups (if enabled) to the location specified in this configuration in addition to Kaleido\'s cloud storage.'
}