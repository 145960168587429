import React, { useState } from 'react';
import { bindPopover, PopupState } from 'material-ui-popup-state/core';
import { Grid, Typography, TextField, MenuItem, Button, makeStyles, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/StringUtils';

export interface FilterOptions {
    onSubmit: (options: Options) => void
    loading?: boolean
    inputs: {
        label: string
        options: string[]
    }[]
}

export interface Props  {
    filterOptions: FilterOptions
    popupState: PopupState
}

interface Options {
    [key: string]: string;
}

export const FilterPopOver = ({filterOptions, popupState}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'FilterPopOver', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`FilterPopOver:${key}`, interpolate);

    
    const getIntialSelectedOptions: () => Options = () => 
    filterOptions.inputs.reduce((allOptions, filter) => {
        allOptions = { [filter.label]: '' };
        return allOptions;
    }, {});
    
    const [options, setOptions] = useState<Options>(getIntialSelectedOptions());
    
    const classes = useStyles();

    const getOnChange = (label: string) => (current: string) => {
        const newOptions = { ...options, [label]: current };
        setOptions(newOptions);
    };

    const filterPopOver = (
        <Grid container direction="column" className={classes.container} spacing={1}>
            <Grid item xs={12}>
                <Typography variant="body2">{lt('filters').toLocaleUpperCase()}</Typography>
            </Grid>
            {filterOptions.inputs.map((filter, index) => (
                <Grid key={`filter-${index}`} item xs={12}>
                    <TextField select value={options[filter.label] || ''} fullWidth label={filter.label} onChange={e => getOnChange(filter.label)(e.target.value)}>
                        <MenuItem value=''>
                            {lt('all')}
                        </MenuItem>
                        {filter.options.map((option, index) => (
                            <MenuItem key={`option-${index}`} value={option || ''}>
                                {capitalize(option)}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            ))}
            <Grid item className={classes.button}>
                <Button disabled={filterOptions.loading} fullWidth variant="contained" color="primary" onClick={() => filterOptions.onSubmit(options)}>{lt('applyFilter')}</Button>
            </Grid>
        </Grid>
    )

    return (
        <Popover 
            {...bindPopover(popupState)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            {filterPopOver}
        </Popover>
    )
};


interface translations {
    filters: string
    applyFilter: string
    all: string
}

const enTranslations: translations = {
    filters: 'Filters',
    applyFilter: 'Apply Filter',
    all: 'All'
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
        width: 300
    },
    button: {
        marginTop: theme.spacing(2)
    }
}));
