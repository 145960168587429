import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

interface Props {
    supportExternalCA: boolean
}

export const Step2Help = ({supportExternalCA} : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep2Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-CertificateAuthority.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            finalInfo={supportExternalCA ? lt('finalInfo') : lt('externalCAComingSoon')}
            docLink="https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#managed-membership-cas"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    finalInfo: string,
    externalCAComingSoon: string
}
const enTranslations: translations = {
    helpHeader: 'Certificate Authority Summary',
    helpDescription: 'The permissioning and policy systems in Hyperledger Fabric are based on x.509 certificates. Certificate Authority (CA) plays a key role in being the trusted root authority for an organization. CAs issue certificates to other entities involved in the protocol (orderers, peers, clients and users) for them to authenticate themselves when communicating with each other. The following CA options may be selected:',
    helpDesc1: `Self-Signed (default): The root CA uses a self-signed certificate uniquely generated for the membership for the Kaleido environment.`,
    helpDesc2: 'Custom Keys: The root CA uses explicit signing and external key content which you provide.',
    helpDesc3: 'External Server: Provide external CA details for the root CA to enroll with, to become an intermediate CA.',
    finalInfo: 'Sensitive information such as custom keys and/or the enrollment secret are stored encrypted by Kaleido.',
    externalCAComingSoon: 'Support for external CA options (Custom Keys and External Server) is coming soon.'
}