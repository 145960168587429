import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers';

export const StepHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateApp2AppHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateApp2AppHelp:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-App2App.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/kaleido-services/app2app/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string
}
const enTranslations: translations = {
    helpHeader: 'App2App Messaging',
    helpDescription: 'Use the App2App Messaging service to securely send messages within a Kaleido environment. The Kaleido messaging service offers a Kafka-backed bilateral app-to-app pipeline for the encryption, submission and decryption of messages between members of an environment.',
    helpDesc1: 'Securely subscribe to pertinent topics for consumption and delivery of data',
    helpDesc2: 'Leverages the On-Chain Registry to establish and verify membership identities',
    helpDesc3: 'Messages are automatically encrypted and signed, thereby ensuring that only the intended recipient can access the content while also providing proof of the sender’s identity.'
}