import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { isEmailValid } from "../../utils/StringUtils";

interface Props {
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    errorMessage: string
    handleWelcome: () => void
    loading: boolean
}

export const SetupWelcome: React.FC<Props> = ({ email, setEmail, errorMessage, handleWelcome, loading }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SetupWelcome', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SetupWelcome:${key}`, interpolate);

    return (
        <form id="SetupWelcomeForm" className={'Hubspot'} style={{ width: '300px' }} onSubmit={event => { event.preventDefault(); handleWelcome() }}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('welcomeBack')}</Typography>
                </Grid>
                {errorMessage &&
                    <Grid item>
                        <Typography color="error" variant="subtitle1">
                            {errorMessage}
                        </Typography>
                    </Grid>
                }
                <Grid item>
                    <TextField
                        label={lt('workEmailAddress')}
                        value={email}
                        fullWidth
                        variant="outlined"
                        autoComplete="username"
                        margin="none"
                        onChange={event => setEmail(event.target.value)}
                        helperText={email && !isEmailValid(email) && lt('mustByValidEmail')}
                    />
                </Grid>
                <Grid item>
                    <Button type="submit"
                        fullWidth
                        disabled={loading || !isEmailValid(email)}
                        variant="contained"
                        size="large"
                        color='primary'>
                        {lt('continue')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

interface translations {
    welcomeBack: string
    confirmationCode: string
    mustProvideConfirmationCode: string
    workEmailAddress: string
    mustByValidEmail: string
    userAndPasswordRequiredMessage: string
    continue: string
}

const enTranslations: translations = {
    welcomeBack: 'Welcome back!',
    confirmationCode: 'Confirmation code',
    mustProvideConfirmationCode: 'Must provide confirmation code',
    workEmailAddress: 'Work E-mail Address',
    mustByValidEmail: 'Must be a valid e-mail address',
    userAndPasswordRequiredMessage: 'Username and password are required',
    continue: 'Continue'
}
