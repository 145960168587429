import { Checkbox, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateWrapper } from '../../../components/DialogWrappers';
import { CreateStepProps } from '../../../interfaces';
import {
    CreateEditEventStream,
    EnEventStreamTranslations,
    EventStream,
    EventStreamTranslations, EventStreamType
} from "../../../models/eventStreams";
import { Step2WebSocketHelp } from './Step2WebSocketHelp';

interface Props extends CreateStepProps {
    name: string,
    type: EventStreamType,
    existing?: EventStream,
    save: (es: CreateEditEventStream) => Promise<void>,
    loading: boolean,
};

export const Step2WebSocket = ({ name, type, cancelPath, existing, save, loading }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamsCreateEventStreamStep2WebSocket', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamsCreateEventStreamStep2WebSocket:${key}`, interpolate)

    const [topic, setTopic] = useState(existing?.websocket?.topic || '');
    const [batchSize, setBatchSize] = useState(existing?.batchSize || 1);
    const [batchTimeout, setBatchTimeout] = useState(existing?.batchTimeoutMS || 5000);
    const [blockedRetryDelaySec, setBlockedRetryDelaySec] = useState(existing?.blockedReryDelaySec || 30);
    const [blockOnErrors, setBlockOnErrors] = useState(existing?.errorHandling === 'skip' ? false : true);

    const saveWrapper = async () => {
        await save({
            name,
            type,
            batchSize,
            batchTimeoutMS: batchTimeout,
            errorHandling: blockOnErrors ? 'block' : 'skip',
            blockedReryDelaySec: blockedRetryDelaySec,
            websocket: {
                topic
            }
        })
    }

    const disabled = !name || !type || loading;

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <TextField 
                    value={topic} 
                    onChange={event => setTopic(event.target.value)}
                    autoFocus
                    fullWidth
                    margin="normal"
                    label={lt('topic')}
                    variant="outlined"
                />
            </Grid>

            <Grid item container spacing={3}>
                <Grid item xs={4}>
                    <TextField type="number"
                        value={batchSize} 
                        onChange={event => setBatchSize(parseInt(event.target.value))}
                        fullWidth
                        margin="normal"
                        label={lt('batchSize')}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField type="number"
                        value={batchTimeout} 
                        onChange={event => setBatchTimeout(parseInt(event.target.value))}
                        fullWidth
                        margin="normal"
                        label={lt('batchTimeoutMS')}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField type="number"
                        value={blockedRetryDelaySec} 
                        onChange={event => setBlockedRetryDelaySec(parseInt(event.target.value))}
                        fullWidth
                        margin="normal"
                        label={lt('blockedReryDelaySec')}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <Grid item container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h6" color="textSecondary">
                        {lt('batchOptions')}
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={blockOnErrors} onChange={(e) => setBlockOnErrors(e.target.checked)} value={blockOnErrors} />}
                        label={lt('blockOnErrors')}
                    />
                </Grid>
            </Grid>

        </>
    )

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} {...{disabled}} onNext={saveWrapper} isLastStep />
            <Step2WebSocketHelp />
        </>
    )
};

interface translations extends EventStreamTranslations {
    header: string
    headerDescription: string
    batchOptions: string
    blockOnErrors: string
    topic: string
}
const enTranslations: translations = {
    ...EnEventStreamTranslations,
    header: 'Enter WebSocket Details',
    headerDescription: 'Optionally choose a topic to allow different apps to listen on different streams of messages',
    batchOptions: 'Batch options',
    blockOnErrors: 'Block on errors',
    topic: 'Topic',
}