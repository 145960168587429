import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Reference, ServerError, ServerParseError, split, StoreValue } from '@apollo/client';
import { CanReadFunction, SafeReadonly, ToReferenceFunction } from '@apollo/client/cache/core/types/common';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { EnvironmentCordaProviderEnum, EnvironmentEthereumProviderEnum, EnvironmentFabricProviderEnum } from './models';
import { onError } from "@apollo/client/link/error";

const PORT = 4000;

export const createApolloClient = () => {
    const { hostname } = window.location;
    const socketURI = hostname.includes('localhost') ? `ws://localhost:${PORT}/gql/subscription` : `wss://${hostname}/gql/subscription`;
      
    const ensureName = {
        read(name: string | null | undefined) {
            return name || '(Unnamed)';
        }
    }

    const ensureSize = {
        read(size: string | null | undefined) {
            return size || 'small';
        }
    }

    const readRef = (existingData: any, toReference: ToReferenceFunction, canRead: CanReadFunction, __typename: string, query: object, typeHasSubscription: boolean) => {
        if (canRead(existingData)) {
            return existingData
        }
        const ref = toReference({ __typename, ...query })
        if (canRead(ref)) {
            return ref
        }
        // when looking up a singular reference, if !canRead(ref), it could be because it hasnt been fetched yet, or its been evicted
        // if it hasnt been fetched yet, returning undefined will tell the query to fetch it (if the fetch policy supports it). 
        // otherwise it seems an undefined result is ignored by useQuery with cache-only :(
        // returning null means it just doesnt exist anymore in the cache, and queries will not attempt to fetch it
        // so as a workaround, if this is a singular reference that is kept up to date via a subscription on the reference's collection query (meaning its useQuery is cache-only) return null
        // otherwise, return undefined
        // the implication is if the singular query is used in a location without an active subscription on the collection, then it should use a network-only fetchPolicy
        return typeHasSubscription ? null : undefined
    }

    const makeIdQueryObject = (id: string) => ( { id } )

    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    apiKeys: { merge: false },
                    roles: { merge: false },
                    appCreds: { merge: false },
                    compiledContracts: { merge: false },
                    consortia: { merge: false },
                    consortiumZones: { merge: false },
                    contractProjects: { merge: false },
                    environments: { merge: false },
                    environmentZones: { merge: false },
                    memberships: { merge: false },
                    consortiumMemberships: { merge: false },
                    nodes: { merge: false },
                    services: { merge: false },
                    eventStreams: { merge: false },
                    eventStreamSubscriptions: { merge: false },
                    consortium(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'Consortium', makeIdQueryObject(args?.id), true)
                    },
                    node(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'Node', makeIdQueryObject(args?.id), true)
                    },
                    service(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'Service', makeIdQueryObject(args?.id), true)
                    },
                    environment(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'Environment', makeIdQueryObject(args?.id), true)
                    },
                    appCred(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'AppCred', makeIdQueryObject(args?.id), true)
                    },
                    membership(existingData, {args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'Membership', makeIdQueryObject(args?.id), true)
                    },
                    contractProject(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'ContractProject', makeIdQueryObject(args?.id), true)
                    },
                    compiledContract(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'CompiledContract', makeIdQueryObject(args?.id), true)
                    },
                    gatewayAPI(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'GatewayAPI', { _id: args?.id, environment_id: args?.environment_id }, false)
                    },
                    organization(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'Organization', makeIdQueryObject(args?.id), true)
                    },
                    eventStream(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'EventStream', makeIdQueryObject(args?.id), false)
                    },
                    eventStreamSubscription(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'EventStreamSubscription', makeIdQueryObject(args?.id), false)
                    },
                    channel(existingData, { args, toReference, canRead }) {
                        return readRef(existingData, toReference, canRead, 'Channel', makeIdQueryObject(args?.id), true)
                    }
                }
            },
            Organization: {
                fields: {
                    name: ensureName,
                },
            },
            APIKey: {
                fields: {
                    name: ensureName,
                },
            },
            BlockForChart: {
                keyFields: false,
            },
            Consortium: {
                fields: {
                    name: ensureName,
                }
            },
            EventData: {
                keyFields: false
            },
            DocumentStoreMetrics: {
                keyFields: ['id', 'month', 'year'],
            },
            App2AppMetrics: {
                keyFields: ['id', 'month', 'year'],
            },
            GatewayAPI: {
                keyFields: ['_id', 'environment_id'],
            },
            Membership: {
                fields: {
                    org_name: ensureName,
                },
            },
            Node: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                    isCorda(_existingData, { readField }) {
                        const provider = readField('provider') as string
                        return provider ? provider in EnvironmentCordaProviderEnum : false
                    },
                    isEthereum(_existingData, { readField }) {
                        const provider = readField('provider') as string
                        return provider ? provider in EnvironmentEthereumProviderEnum : false
                    },
                    isFabric(_existingData, { readField }) {
                        const provider = readField('provider') as string
                        return provider ? provider in EnvironmentFabricProviderEnum : false
                    },
                    name: ensureName,
                    size: ensureSize
                },
            },
            Environment: {
                fields: {
                    isCorda(_existingData, { readField }) {
                        const provider = readField('provider') as string
                        return provider ? provider in EnvironmentCordaProviderEnum : false
                    },
                    isEthereum(_existingData, { readField }) {
                        const provider = readField('provider') as string
                        return provider ? provider in EnvironmentEthereumProviderEnum : false
                    },
                    isFabric(_existingData, { readField }) {
                        const provider = readField('provider') as string
                        return provider ? provider in EnvironmentFabricProviderEnum : false
                    },                    
                },
            },
            Service: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                    name: ensureName,
                    size: ensureSize
                },
            },
            Account: {
                fields: {
                    membership(_existingData, { toReference, readField}) {
                        return getMembership(toReference, readField)
                    },
                }
            },
            AppCred: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                    name: ensureName,
                },
            },
            Channel: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                },
            },
            ContractProject: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                },
            },
            // the individual LedgerContract query returns the supply, symbol, and name, but the list LedgerContracts query doesnt.
            // so, we can merge them in to avoid the list query overwriting individual LedgerContract's fields with blanks for these fields.
            LedgerContract: {
                fields: {
                    erc20TotalSupply: {
                        merge(existing, incoming) {
                            return existing || incoming
                        }
                    },
                    tokenSymbol: {
                        merge(existing, incoming) {
                            return existing || incoming
                        }
                    },
                    tokenName: {
                        merge(existing, incoming) {
                            return existing || incoming
                        }
                    }
                },
            },
            CompiledContract: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                    description: ensureName, // there is no name on compiled contracts, so we use description like a name
                },
            },
            Address: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                },
            },
            AddressWidgetInfo: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                },
            },
            Config: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                    name: ensureName,
                },
            },
            Wallet: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                },
            },
            OauthConfiguration: {
                fields: {
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                },
            },
            EnvironmentZone: {
                fields: {
                    // Only environment zones of type private have a membership_id
                    membership(_existingData, { toReference, readField }) {
                        return getMembership(toReference, readField)
                    },
                    displayName(_existingData, { toReference, readField }) {
                        const type = readField('type');
                        if (type === 'private') {
                            const bridge = toReference({ __typename: 'Service', id: readField('bridge_id')})
                            const bridgeName = bridge ? readField('name', bridge) : '';
                            return `PrivateStack: ${bridgeName}`
                        }
                        const cloud = readField('cloud');
                        const region = readField('region');
                        return `${cloud ? String(cloud).toUpperCase() : ''}: ${region ? region : ''}`;
                    },
                },
            },
            Role: {
                fields: {
                    email: {
                        merge(existing, incoming) {
                            return existing || incoming
                        }
                    },
                }
            },
        },
    });

    const getMembership = (toReference: ToReferenceFunction, 
        readField: <T = StoreValue>(nameOrField: string, foreignObjOrRef?: Reference) => SafeReadonly<T> | undefined) => {
        const membership = toReference({ __typename: 'Membership', id: readField('membership_id')})
        return {
            isMine: (membership && readField('is_mine', membership)) ? true : false,
            name: (membership && readField('org_name', membership)) || ''
        }
    }

    const httpLink = new HttpLink({
        uri: hostname.includes('localhost') ? `http://localhost:${PORT}/gql` : '/gql',
        credentials: 'include'
    });

    const wsLink = new GraphQLWsLink(createClient({
        url: socketURI,
        lazy: true
    }));

    // Send queries over HTTP and subscriptions over websocket
    const splitLink = split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink
    );

    const ac = new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.map(({ message, locations, path }) => console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
                    ));
                }
                if (networkError) {
                    if ((networkError as ServerError | ServerParseError).statusCode === 401 && 
                        window.location.pathname !== '/login') {
                        const url = `/login?path=${window.location.pathname}`;
                        window.location.href = url;
                    } else {
                        console.log(`[Network error]: ${networkError.message}`);
                    }
                }
            }),
            splitLink
        ]),
        cache,
        resolvers: {},
        defaultOptions: {
            watchQuery: {
                nextFetchPolicy(lastFetchPolicy) {
                    if (lastFetchPolicy === "cache-and-network") {
                        return "cache-only";
                    }
                    return lastFetchPolicy;
                }
            }
        }
    });
    
    return ac;
};
