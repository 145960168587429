import { Box, Button, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnPlansTranslations, PlanName, PlansTranslations } from '../../models';
import { BrandColors } from '../../utils/Colors';

interface Props {
    plan: PlanName,
    color?: string,
    name: string,
    feesLine1?: string,
    feesLine2?: string,
    description: string,
    disabled?: boolean
    isSignup?: boolean
    buttonLabel?: string
    bestValue?: boolean
    bestValueOffer?: string
    onClick: () => void
}

export const PlanCard = ({
    plan,
    color,
    name,
    feesLine1,
    feesLine2,
    description,
    disabled,
    buttonLabel,
    isSignup,
    bestValue,
    bestValueOffer,
    onClick
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'PlanCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`PlanCard:${key}`, interpolate);

    const planSupportedItems: Map<PlanName, string[]>  = new Map([
        ['starter', isSignup ? 
            [lt('twoSmallNodesOnly'), lt('twoServicesOnly'), lt('noUsageFeesAreIncluded')] : 
            [lt('twoSmallNodesOnly'), lt('twoServicesOnly')]],
        ['team', isSignup ? 
            [lt('unlimitedSmallNodes'), lt('unlimitedServices'), lt('startingAt015')] : 
            [lt('unlimitedSmallNodes'), lt('unlimitedServices')]],
        ['business', isSignup ? 
            [lt('unlimitedNodeSizes'), lt('unlimitedServices'), lt('startingAt055'), lt('allSupportLevels'), lt('decentralizedNetwork')] : 
            [lt('unlimitedNodeSizes'), lt('unlimitedServices'), lt('allSupportLevels'), lt('decentralizedNetwork'), lt('higherTransactions'),
                lt('disasterRecovery'), lt('supportForSLAAvailable')]],
        ['enterprise', [lt('everythingInBusiness'), lt('privateStack'), lt('cloudHSMKeyManagement'), lt('federatedLogin'), lt('multiFactorAuth')]]
    ]);

    const planUnsupportedItems: Map<PlanName, string[]>  = new Map([
        ['starter', isSignup ? 
            [lt('basicSupportOnly'), lt('CentralizedNetwork')] : 
            [lt('basicSupportOnly'), lt('CentralizedNetwork'), lt('LimitedTransactions'), lt('NoDisasterRecovery'), lt('NoSupportSLA')]],
        ['team', isSignup ? 
            [lt('basicSupportOnly'), lt('CentralizedNetwork')] :
            [lt('basicSupportOnly'), lt('CentralizedNetwork'), lt('LimitedTransactions'), lt('NoDisasterRecovery'), lt('NoSupportSLA')]],
        ['business', []],
        ['enterprise', []]
    ]);

    const planPrice: Map<PlanName, string>  = new Map([
        ['starter', lt('free')],
        ['team', lt('$015PerHour')],
        ['business', lt('$055PerHour')],
        ['enterprise', lt('contactUs')]
    ]);

    const classes = useStyles();

    return (
        <Paper className={classes.planContainer} elevation={2}>
            {bestValue && (
                <Box className={classes.bestValueHeader} style={{ backgroundColor: color }}>
                    <Typography variant="body1">{lt('bestValue')}</Typography>
                </Box>
            )}
            {color && <Box className={classes.coloredHeader} style={{ backgroundColor: color }} />}
            <Box className={classes.planContent}>
                {isSignup ? (
                        <>
                            <Typography variant='h5' className={classes.planName}>{name}</Typography>
                        </>
                    ) : 
                    (
                        <>
                            <Typography variant='h6' className={classes.planName}>{name}</Typography>
                            <Typography variant='h5' className={classes.planPrice}>{planPrice.get(plan)}</Typography>        
                        </>
                    )
                }
                {feesLine1 && (
                    <Box className={classes.planPriceSuffix}>
                        <Typography variant='body2'>{feesLine1}</Typography>
                        {feesLine2 && <Typography variant='body2'>{feesLine2}</Typography>}
                    </Box>
                )}
                <Typography className={classes.planDescription} style={{minHeight: isSignup ? 45 : 60}}>{description}</Typography>
                <Box className={classes.planItemsContainer} style={{ minHeight: isSignup ? 160 : 240}}>
                    {planSupportedItems.get(plan)!.map(item => (
                        <Box key={plan + item} className={classes.planItem}>
                            <CheckCircleIcon size="19" className={classes.planItemIcon} />
                            <Typography variant="body2">{item}</Typography>
                        </Box>
                    ))}
                    {planUnsupportedItems.get(plan)!.map(item => (
                        <Typography key={plan + item} variant="body2" className={classes.planItemDisabled}>{item}</Typography>
                    ))}
                </Box>
                <Button 
                    data-test={`${plan}PlanSelectionButton`} 
                    disabled={disabled} 
                    onClick={onClick} 
                    color="primary" 
                    className={classes.button} 
                    variant={plan === 'enterprise' ? 'outlined' : 'contained'}>
                        {buttonLabel || lt('select')}
                </Button>
                <Button onClick={() => window.open('https://kaleido.io/pricing/', '_blank')} className={classes.button}>{lt('viewDetails')}</Button>
            </Box>
                {bestValueOffer && (
                    <>
                        <Divider />
                        <Box className={classes.limitedOffer}>
                            <Typography className={classes.limitedTimeOfferTitle}>{lt('limitedTimeOffer')}</Typography>
                            {bestValueOffer && <Typography>{bestValueOffer}</Typography>}
                        </Box>
                    </>
                )}
        </Paper>
    );
};

const useStyles = makeStyles(theme => ({
    bestValueHeader: {
        height: 30,
        color: 'white',
        backgroundColor: '#FF396A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    planContainer: {
        width: 270,
        borderRadius: 0,
    },
    coloredHeader: {
        height: 30
    },
    planPriceMargin: {
        marginBottom: 20,
    },
    planContent: {
        paddingLeft: 30,
        paddingRight: 30,
        textAlign: 'center',
        paddingTop: 30
    },
    planName: {
        marginBottom: 30
    },
    planPrice: {
        marginBottom: 20,
        fontSize: 28
    },
    planPriceSuffix: {
        minHeight: 70
    },
    planDescription: {
        marginBottom: 20
    },
    planItemsContainer: {
        textAlign: 'left'
    },
    planItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    },
    planItemIcon: {
        color: '#462DE0',
        marginRight: 8
    },
    planItemDisabled: {
        color: theme.palette.text.disabled,
        marginLeft: 28,
        marginBottom: 10
    },
    button: {
        width: '100%',
        marginBottom: 10
    },
    limitedOffer: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    limitedTimeOfferTitle: {
        color: BrandColors.blurple,
        marginBottom: 10
    }
}));

interface translations extends PlansTranslations {
    free: string
    starterDescription: string
    twoSmallNodesOnly: string
    twoServicesOnly: string
    basicSupportOnly: string
    CentralizedNetwork: string
    LimitedTransactions: string
    NoDisasterRecovery: string
    NoSupportSLA: string
    currentPlan: string
    learnMore: string
    viewDetails: string
    noUsageFeesAreIncluded: string
    $015PerHour: string
    usageFeesStartAt: string
    $015perNodeHour: string
    teamDescription: string
    unlimitedSmallNodes: string
    unlimitedServices: string
    $055PerHour: string
    $055PerNodeHour: string
    businessDescription: string
    unlimitedNodeSizes: string
    allSupportLevels: string
    decentralizedNetwork: string
    higherTransactions: string
    disasterRecovery: string
    supportForSLAAvailable: string
    contactUs: string
    enterpriseFees1: string
    enterpriseFees2: string
    enterpriseDescription: string
    everythingInBusiness: string
    privateStack: string
    cloudHSMKeyManagement: string
    federatedLogin: string
    multiFactorAuth: string
    select: string
    trialMessage: string
    addBillingProvider: string
    addBillingProviderDescription: string
    bestValue: string
    limitedTimeOffer: string
    startingAt015: string
    startingAt055: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    startingAt015: 'Starting at $0.15 per node/hr',
    startingAt055: 'Starting at $0.55 per node/hr',
    bestValue: 'BEST VALUE',
    limitedTimeOffer: 'LIMITED TIME OFFER',
    free: 'Free',
    starterDescription: 'Get started on your blockchain journey with our free plan.',
    twoSmallNodesOnly: '2 Small Nodes Only',
    twoServicesOnly: '2 Services Only',
    basicSupportOnly: 'Basic Support Only',
    CentralizedNetwork: 'Centralized Network',
    LimitedTransactions: 'Limited Transctions',
    NoDisasterRecovery: 'No Disaster Recovery',
    NoSupportSLA: 'No Support SLA',
    currentPlan: 'Current Plan',
    learnMore: 'Learn More',
    viewDetails: 'View Details',
    noUsageFeesAreIncluded: 'No usage fees are included.',
    $015PerHour: 'Nodes $0.15/hr',
    usageFeesStartAt: 'Usage fees start at',
    teamDescription: 'Grow your team projects with expanded resources',
    $015perNodeHour: '$ 0.15 per node hour',
    unlimitedSmallNodes: 'Unlimited Small Nodes',
    unlimitedServices: 'Unlimited Services',
    $055PerHour: 'Nodes $0.55/hr',
    $055PerNodeHour: '$0.55 per node hour',
    businessDescription: 'Production-ready with enhanced services and support levels.',
    unlimitedNodeSizes: 'Unlimited Nodes/Sizes',
    allSupportLevels: 'All Support Levels',
    decentralizedNetwork: 'Decentralized Network',
    higherTransactions: 'Higher Transactions',
    disasterRecovery: 'Disaster Recovery',
    supportForSLAAvailable: 'Support SLA Available',
    contactUs: 'Contact Us',
    enterpriseFees1: 'Usage fees comes with',
    enterpriseFees2: 'additional savings',
    enterpriseDescription: 'Production-ready with full support and SLA guarantees',
    everythingInBusiness: 'Everything in Business',
    privateStack: 'PrivateStack',
    cloudHSMKeyManagement: 'Cloud HSM Key Management',
    federatedLogin: 'Federated Login',
    multiFactorAuth: 'Multi-Factor Auth',
    select: 'Select',
    trialMessage: 'To enquire about a trial of a paid plan, please <0>contact us</0>.',
    addBillingProvider: 'Add Billing Provider',
    addBillingProviderDescription: 'Before you can upgrade to a paid plan, we\'ll need to add a payment method first'
}