import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNodeCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNodeCreateStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-NodeSummary.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/using-kaleido/blockchain-nodes"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Node Summary',
    helpDescription: 'A PrivateStack Network Bridge requires three pieces of configuration for the initial setup:',
    helpDesc1: `Owning member - if you have multiple memberships under your organization's control, you can use this dropdown to bind the node to a membership of your choice.`,
    helpDesc2: 'Name - a string identifying the node in the environment.  This does not need to contain the name or membership of the owning organization as this information will be clearly displayed in the environment dashboard.',
    helpDesc3: 'Deployment Region - the cloud and underlying region where the node will be deployed.  If the environment is deployed as single region, then the region is inherited from the environment configuration.  If the environment is deployed as a hybrid multi-region, then any whitelisted regions will be available for choice.',
}