import React, { useState } from "react";
import {
    CreateWrapper,
    MessageSnackbar,
    ErrorSnackbarCatcher,
} from "../../../components/DialogWrappers";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import {
    CreateConfigMutation,
    CreateConfigData,
    CreateConfigVars,
    NodeConfigsDetails,
    NodeConfigsDetailsTranslationsInterface,
    NodeConfigsDetailsTranslations,
    UpdateConfigMutation,
    UpdateConfigVars,
    UpdateConfigData,
    ConfigData,
    ConfigQuery,
    Config,
    KafkaTopicConfiguration,
    GetSupportedConfigDetailsKeys,
} from "../../../models/configs";
import { useParams, useHistory } from "react-router-dom";
import { ConsortiumResourceVars, CreateStepProps, EnvironmentResourceVars } from "../../../interfaces";
import { useForm } from "react-hook-form";
import { ConfigForm, InputList } from "./ConfigForm";
import { HostsInputs } from "./HostsInputs";
import { HelpStep2 } from "./HelpStep2";
import { Environment, EnvironmentData, EnvironmentQuery } from "../../../models";
import { FormLink } from "../../../components/FormControls/FormLink";

type locationState = { name: string; membershipId: string };
type KafkaConfigurationType = {
    partition_count: number
    replication_factor: number
    retention_ms: number
    retention_bytes: number
    segment_bytes: number
  };

const DEFAULT_VALUES = {
    gas_price: 0,
    target_gas_limit: 804247552,
    rpc_gas_cap: 804247552,
    cors_origin_hosts: ["*"],
    gc_mode: "full",
    sync_mode: "full",
    geth_log_verbosity: "debug",
    geth_cache_size: 64,
    geth_cache_database_share: 50,
    geth_cache_trie_share: 15,
    geth_cache_gc_share: 25,
    geth_cache_snapshot_share: 10,
    geth_cache_trie_rejournal_interval: 60,
    restgw_max_tx_wait_time: 60,
    restgw_flush_frequency: 0,
    restgw_flush_bytes: 0,
    restgw_flush_msgs: 0,
    partition_count: 1,
    replication_factor: 2,
    retention_ms: 86400000,
    retention_bytes: 10485760,
    segment_bytes: 5242880
};

const DEFAULT_VALUES_PANTHEON = {
    ...DEFAULT_VALUES,
    geth_log_verbosity: "info",
    gc_mode: "archive",
}

const KAFKA_TOPIC_CONFIGURATION = ["partition_count", "replication_factor", "retention_ms", "retention_bytes", "segment_bytes"]

const buildDefaultDetails = (data: NodeConfigsDetails, environment: Environment | null) => {
    let obj: NodeConfigsDetails = {};
    let restGatewayKafkaTopicConf: KafkaConfigurationType = {
        "partition_count": 1,
        "replication_factor": 2,
        "retention_ms": 86400000,
        "retention_bytes": 10485760,
        "segment_bytes": 5242880
    };
    let includeRestGwKafka = false
    for (let [key, value] of Object.entries(data)) {
        if (key in GetSupportedConfigDetailsKeys(environment)) {
            if (KAFKA_TOPIC_CONFIGURATION.includes(key)) {
                restGatewayKafkaTopicConf[key as keyof KafkaTopicConfiguration]= value as number;
                includeRestGwKafka = true
            } else {
                obj[key as keyof NodeConfigsDetails] = value;
            }
        }
    }
    if (includeRestGwKafka) {
        obj.restgw_kafka_topic_conf = restGatewayKafkaTopicConf;
    }
    return obj;
};

const getConfigCurrentData: (config: Config, environment: Environment | null) => NodeConfigsDetails = (
    config: Config,
    environment: Environment | null
) => buildDefaultDetails(config.details, environment);

export const Step2 = ({ cancelPath }: CreateStepProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CreateCloudConfigStep2", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CreateCloudConfigStep2:${key}`, interpolate);

    const { consortium_id, environment_id, config_id } = useParams<any>();
    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const { register, handleSubmit, control, errors, watch, reset } = useForm<
    NodeConfigsDetails
    >({
        defaultValues: (environment?.provider === 'pantheon') ? DEFAULT_VALUES_PANTHEON : DEFAULT_VALUES,
    });
    
    const {
        loading: configsLoading,
        data: { config } = { config: null },
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            environment_id,
            consortia_id: consortium_id,
            id: config_id!,
        },
        skip: !config_id,
        onCompleted: config_id ? (data) => reset(getConfigCurrentData(data.config, environment)) : undefined
    });
    

    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory<locationState>();

    const { cors_origin_hosts } = watch();

    const {
        location: {
            state: { name, membershipId } = { name: "", membershipId: "" },
        },
    } = history;

    const [createConfig, { loading }] = useMutation<
        CreateConfigData,
        CreateConfigVars
    >(CreateConfigMutation);

    const [updateConfig, { loading: updateLoading }] = useMutation<
        UpdateConfigData,
        UpdateConfigVars
    >(UpdateConfigMutation);

    if (configsLoading) return <CircularProgress />;
    
    const onNext = async (data: NodeConfigsDetails) => {
        try {
            let details = { ...data };
            if (config) {
                await updateConfig({
                    variables: {
                        id: config_id,
                        consortia_id: consortium_id!,
                        environment_id: environment_id!,
                        configuration: {
                            name: config.name,
                            details: buildDefaultDetails(details, environment),
                        },
                    },
                });
            } else {
                await createConfig({
                    variables: {
                        consortia_id: consortium_id!,
                        environment_id: environment_id!,
                        configuration: {
                            membership_id: membershipId!,
                            name: name,
                            type: "node_config",
                            details: buildDefaultDetails(details, environment),
                        },
                    },
                });
            }
            history.push(cancelPath);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
    };

    const gasPriceList: InputList[] = [
        {
            type: "int",
            label: lt("gas_price"),
            name: "gas_price",
            min: 0,
        },
        {
            type: "int",
            name: "target_gas_limit",
            label: lt("target_gas_limit"),
            min: 0,
        },
        {
            type: "int", 
            name: "rpc_gas_cap",
            label: lt("rpc_gas_cap"),
            min: 0,
            max: 804247552
        },
    ];

    const gethVerbosityList: InputList[] = [
        {
            type: "dropdown",
            name: "geth_log_verbosity",
            label: lt("geth_log_verbosity"),
            options: [
                { value: "silent", label: lt("silent") },
                { value: "error", label: lt("error") },
                { value: "warn", label: lt("warn") },
                { value: "info", label: lt("info") },
                { value: "debug", label: lt("debug") },
            ],
            md: 12,
        },
        {
            type: "int",
            label: lt("geth_cache_size"),
            name: "geth_cache_size",
            min: 0,
            max: 2048
        },
        {
            type: "int",
            label: lt("geth_cache_database_share"),
            name: "geth_cache_database_share",
            min: 0,
            max: 100
        },
        {
            type: "int",
            label: lt("geth_cache_trie_share"),
            name: "geth_cache_trie_share",
            min: 0,
            max: 100
        },
        {
            type: "int",
            label: lt("geth_cache_gc_share"),
            name: "geth_cache_gc_share",
            min: 0,
            max: 100
        },
        {
            type: "int",
            label: lt("geth_cache_snapshot_share"),
            name: "geth_cache_snapshot_share",
            min: 0,
            max: 100
        },
        {
            type: "int",
            label: lt("geth_cache_trie_rejournal_interval"),
            name: "geth_cache_trie_rejournal_interval",
            min: 1,
            max: 120
        }
    ];

    const kafkaTopicConfigurationList: InputList[] = [
        {
            type: "int",
            label: lt("partition_count"),
            name: "partition_count",
            min: 1,
            max: 10
        },
        {
            type: "int",
            label: lt("replication_factor"),
            name: "replication_factor",
            min: 1,
            max: 5
        },
        {
            type: "int",
            label: lt("retention_ms"),
            name: "retention_ms",
            min: -1,
            max: 604800000
        },
        {
            type: "int",
            label: lt("retention_bytes"),
            name: "retention_bytes",
            min: 14,
            max: 1073741824
        },
        {
            type: "int",
            label: lt("segment_bytes"),
            name: "segment_bytes",
            min: 14,
            max: 1073741824
        }
    ];

    const modesList: InputList[] = [
        {
            type: "dropdown",
            name: "gc_mode",
            label: lt("gc_mode"),
            options: [
                { value: "full", label: lt("full") },
                { value: "archive", label: lt("archive") },
            ],
        },
        {
            type: "dropdown",
            name: "sync_mode",
            label: lt("sync_mode"),
            options: [
                { value: "full", label: lt("full") },
                { value: "fast", label: lt("fast") },
                { value: "light", label: lt("light") },
            ],
        },
    ];

    const fabricLoggingList: InputList[] = [
        {
            type: "string",
            name: "fabric_logging_spec",
            label: lt("fabric_logging_spec"),
            optional: true,
        },
    ]

    const additionalSettingsList: InputList[] = [
        {
            type: "boolean",
            name: "enable_trace_api",
            label: lt("enable_trace_api"),
            optional: true,
        },
        {
            type: "boolean",
            name: "enable_revert_reason",
            label: lt("enable_revert_reason"),
            optional: true,
        }
    ]

    const gatewayList: InputList[] = [
        {
            type: "int",
            name: "restgw_flush_bytes",
            label: lt("restgw_flush_bytes"),
            optional: true,
            min: 0,
        },
        {
            type: "int",
            name: "restgw_flush_msgs",
            label: lt("restgw_flush_msgs"),
            optional: true,
            min: 0,
        },
        {
            type: "int",
            name: "restgw_flush_frequency",
            label: lt("restgw_flush_frequency"),
            optional: true,
            min: 0,
        },
        {
            type: "int",
            name: "restgw_max_tx_wait_time",
            label: lt("restgw_max_tx_wait_time"),
            optional: true,
            min: 1,
        },
        {
            type: "int",
            name: "restgw_max_inflight",
            label: lt("restgw_max_inflight"),
            optional: true,
            min: 1,
        },
        {
            type: "int",
            name: "restgw_send_concurrency",
            label: lt("restgw_send_concurrency"),
            optional: true,
            min: 1,
        },
        {
            type: "boolean",
            name: "restgw_always_manage_nonce",
            label: lt("restgw_always_manage_nonce"),
            optional: true,
        },
        {
            type: "boolean",
            name: "restgw_attempt_gap_fill",
            label: lt("restgw_attempt_gap_fill"),
            optional: true,
        },
    ];

    const subtitleComponent = (title: string, description: string) => (
        <Grid item container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">{description}</Typography>
            </Grid>
        </Grid>
    );

    const fabricGatewayConfigProps = GetSupportedConfigDetailsKeys(environment)
    const fabricContent = (
        <>
            <Grid item>
                <Typography variant="h5">{lt("header")}</Typography>
            </Grid>
            {subtitleComponent(lt("gatewayApi"), lt("gatewayApiDescription"))}
            <Grid item container spacing={3}>
                <ConfigForm
                    {...{ errors }}
                    inputList={gatewayList.filter(g => Object.keys(fabricGatewayConfigProps).some(k => k === g.name))}
                    {...{ register }}
                    {...{ control }}
                />
            </Grid>

            <Grid item container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="h6">{lt("fabricLogging")}</Typography>
                </Grid>
                <Grid item>
                    <Trans i18nKey="CreateCloudConfigStep2:fabricLoggingHelp"
                        components={[<FormLink onClick={() => 
                            window.open('https://hyperledger-fabric.readthedocs.io/en/release-2.4/logging-control.html#logging-specification')} />]}>
                    </Trans>
                </Grid>
            </Grid>
            <Grid item container spacing={3}>
                <ConfigForm
                    {...{ errors }}
                    inputList={fabricLoggingList}
                    {...{ register }}
                    {...{ control }}
                />
            </Grid>
        </>
    )

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">{lt("header")}</Typography>
            </Grid>
            {subtitleComponent(lt("gasPrice"), lt("gasPriceDescription"))}
            <Grid item container spacing={3}>
                <ConfigForm
                    {...{ errors }}
                    inputList={gasPriceList}
                    {...{ register }}
                    {...{ control }}
                />
            </Grid>
            {subtitleComponent(lt("corsHost"), lt("corsHostDescription"))}
            <HostsInputs {...{ control }} {...{errors}} preDefinedHosts={cors_origin_hosts} />
            {subtitleComponent(lt("modes"), lt("modesDescription"))}
            <Grid item container spacing={3}>
                <ConfigForm
                    {...{ errors }}
                    inputList={modesList}
                    {...{ register }}
                    {...{ control }}
                />
            </Grid>
            {environment?.provider === 'pantheon' ? (
                <>
                    {subtitleComponent(lt("additionalSettings"), lt("additionalSettingsDescription"))}
                    <Grid item container spacing={3}>
                        <ConfigForm
                            {...{ errors }}
                            inputList={additionalSettingsList}
                            {...{ register }}
                            {...{ control }}
                        />
                    </Grid>
                </>
            ) : null }
            {subtitleComponent(lt("gatewayApi"), lt("gatewayApiDescription"))}
            <Grid item container spacing={3}>
                <ConfigForm
                    {...{ errors }}
                    inputList={gatewayList}
                    {...{ register }}
                    {...{ control }}
                />
            </Grid>
            {subtitleComponent(lt("gethPerformanceParams"), lt("gethPerformanceParamsDescription"))}
            <Grid item container spacing={3}>
                <ConfigForm
                    {...{ errors }}
                    inputList={gethVerbosityList}
                    {...{ register }}
                    {...{ control }}
                />
            </Grid>
            {subtitleComponent(lt("restGatewayKafkaTopicConf"), lt("restGatewayKafkaTopicConfDescription"))}
            <Grid item container spacing={3}>
                <ConfigForm
                    {...{ errors }}
                    inputList={kafkaTopicConfigurationList}
                    {...{ register }}
                    {...{ control }}
                />
            </Grid>
        </>
    );

    return (
        <>
            <MessageSnackbar
                message={errorMessage}
                setMessage={setErrorMessage}
            />
            <CreateWrapper
                cancelPath={cancelPath}
                content={environment?.isFabric ? fabricContent : content}
                disabled={loading}
                onNext={handleSubmit(onNext)}
                isLastStep
                hideBack={!!config_id}
                saving={loading || updateLoading}
            />
            <HelpStep2 />
        </>
    );
};

interface translations extends NodeConfigsDetailsTranslationsInterface {
    header: string;
    warn: string;
    full: string;
    silent: string;
    fast: string;
    light: string;
    archive: string;
    debug: string;
    info: string;
    error: string;
    gasPrice: string;
    gasPriceDescription: string;
    corsHost: string;
    corsHostDescription: string;
    gethVerbosity: string;
    gethVerbosityDescription: string;
    modes: string;
    modesDescription: string;
    additionalSettings: string;
    additionalSettingsDescription: string;
    gatewayApi: string;
    gatewayApiDescription: string;
    gethPerformanceParams: string;
    gethPerformanceParamsDescription: string;
    restGatewayKafkaTopicConf: string;
    restGatewayKafkaTopicConfDescription: string;
    fabricLogging: string
    fabricLoggingHelp: string
}
const enTranslations: translations = {
    ...NodeConfigsDetailsTranslations,
    gatewayApi: "REST API Gateway",
    gatewayApiDescription:
        "Configure various REST API Gateway parameters",
    modes: "Modes",
    modesDescription:
        "Garbage Collection Mode and Sync Mode for Geth/Quorum nodes",
    additionalSettings: "Addtional Settings",
    additionalSettingsDescription: "Configure Besu node to enable Trace API and Revert Reason in transaction receipts",
    gethVerbosity: "Geth Log Verbosity",
    gethVerbosityDescription: "Verbosity of Geth logs",
    corsHost: "CORS Origin Hosts",
    corsHostDescription:
        "List of origins that are allowed to access RPC/WSS endpoints on nodes",
    gasPrice: "Gas Price",
    gasPriceDescription: "Minimum gas price to accept for a transaction",
    header: "Configuration Parameters",
    warn: "Warn",
    full: "Full",
    silent: "Silent",
    fast: "Fast",
    light: "Light",
    archive: "Archive",
    debug: "Debug",
    info: "Info",
    error: "Error",
    gethPerformanceParams: 'Geth Performance Parameters',
    gethPerformanceParamsDescription: 'Configure parameters to allocate memory for internal caching and tune performance',
    restGatewayKafkaTopicConf: 'REST Gateway Kafka Topic Configuration',
    restGatewayKafkaTopicConfDescription: 'Custom configuration for Kafka topics used by gateway to send transactions and receive replies',
    fabricLogging: "Fabric Logging Control",
    fabricLoggingHelp: "Configure global and logger-level control of logging by severity. <0>Click here</0> to view the logging specification.",
};
