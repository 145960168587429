import { gql } from '@apollo/client';

export interface TunnelerConfigVars {
    consortia_id: string,
    environment_id: string,
    service_id: string,
}

export interface TunnelerConfigData {
    tunnelerConfig: string
}

export const TunnelerConfigQuery = gql`
    query getTunnelerConfig($consortia_id: String!, $environment_id: String!, $service_id: String!) {
        tunnelerConfig(consortia_id: $consortia_id, environment_id: $environment_id, service_id: $service_id)
    }`;
