import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicItems } from './BasicItems';

export const DOCSTORE_DOCUMENTS_PATH = 'documents';
export const DOCSTORE_TRANSFERS_PATH = 'transfers';
export const DOCSTORE_DESTINATIONS_PATH = 'destinations';

interface Props {
    baseServicePath: string
}

const DocumentStoreItems = ({ baseServicePath } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DocumentStoreItems', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DocumentStoreItems:${key}`, interpolate)

    const DOCUMENTS = lt('documents')
    const TRANSFERS = lt('transfers')
    const DESTINATIONS = lt('destinations');

    const serviceSpecificTabs = [
        {
            name: DOCUMENTS,
            path: DOCSTORE_DOCUMENTS_PATH,
            selectedItem: DOCUMENTS
        },
        {
            name: TRANSFERS,
            path: DOCSTORE_TRANSFERS_PATH,
            selectedItem: TRANSFERS
        },
        {
            name: DESTINATIONS,
            path: DOCSTORE_DESTINATIONS_PATH,
            selectedItem: DESTINATIONS
        },
    ]

    return (
        <BasicItems {...{baseServicePath}} {...{serviceSpecificTabs}} />
    );
};

interface translations {
    documents: string
    transfers: string
    destinations: string,
}

const enTranslations: translations = {
    documents: 'Documents',
    transfers: 'Transfers',
    destinations: 'Destinations',
}

export { DocumentStoreItems };

