import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FormDialog } from '../../components/DialogWrappers';
import { ComingSoon, DisplayTable, EmptyState } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { cloudConfigTypeList, configTypeMap } from '../../components/MainNav/SideNavs/CloudConfigs';
import { CHANGE_PLAN_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { EnvironmentResourcesVars } from '../../interfaces';
import { FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, NodesData, NodesQuery, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, ServicesData, ServicesQuery } from '../../models';
import { ConfigsData, ConfigsQuery, DeleteConfigMutation, DeleteConfigVars, ConfigTypeTranslationsInterface, ConfigPageTitleTranslationsInterface, ConfigTypeTranslations, ConfigPageTitleTranslations } from '../../models/configs';
import { ConfigMenu } from './ConfigMenu';
import { getPathParams } from './TypeUtils';

export const ConfigTable = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ConfigTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ConfigTable:${key}`, interpolate);

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [actionConfig, setActionConfig] = useState({id: '', name: ''});

    const history = useHistory();

    const client = useApolloClient();

    const {org_id, consortium_id, environment_id, configType, basePath, createConfigPath} =  getPathParams(useLocation().pathname, useParams<any>());

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }
    const {
        refetch: refetchConfigs,
        loading: configsLoading,
        data: {
            configs: allConfigs
        } = { configs: null }
    } = useQuery<ConfigsData, EnvironmentResourcesVars>(ConfigsQuery, {
        variables: queryVariables,
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: { 
            services
        } = { services: null } 
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: queryVariables,
        fetchPolicy: 'cache-only'
    });

    const {
        data: { 
            nodes
        } = { nodes: null } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, { 
        variables: queryVariables,
        fetchPolicy: 'cache-only'
    });

    let {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-and-network'
    });

    const [deleteConfig] = useMutation<any, DeleteConfigVars>(DeleteConfigMutation);

    const configs = useMemo(() => allConfigs?.filter(entry => entry.type === configTypeMap.get(configType)), [allConfigs, configType]);

    const getAttachedAmount = (id: string) => {
        const servicesAttachedLength = services?.filter(entry => entry.details.storage_id === id || entry.details.kms_id === id || entry.details.backup_id === id || entry.details.cloudhsm_id === id ).length ?? 0;
        const nodesAttachedLength = nodes?.filter(node => node.backup_id === id || node.kms_id === id || node.opsmetric_id === id || node.networking_id === id || node.baf_id === id || node.node_config_id === id).length ?? 0;
        return servicesAttachedLength + nodesAttachedLength;
    };

    const historyPusher = useCallback((configId: string) => 
        history.push(`${basePath}/${configType}/${configId}`), 
        [history, basePath, configType]);

    const onConfirmDelete = async () => {
        await deleteConfig({
            variables: {
                consortia_id: consortium_id!,
                id: actionConfig.id,
                environment_id: environment_id!
            }
        })
        await refetchConfigs();
    }

    const onDeleteClick = (configId: string, configName: string) => {
        setConfirmDelete(true);
        setActionConfig({
            id: configId,
            name: configName
        })
    }

    const isCloudConfig = cloudConfigTypeList.includes(configType);

    const records = configs?.map((entry, index) => {
        const columns = [];
        columns.push({
            value: entry.name
        });
        if (isCloudConfig) columns.push({
            value: entry.details.provider ?? 'aws' //Only for networking
        });
        columns.push({
            value: getAttachedAmount(entry._id)
        });
        columns.push({
            value: new Date(entry.created_at).toLocaleString()
        });
        columns.push({
            value: getAttachedAmount(entry._id) > 0 ? <ResourceStateChip state='active' /> : <ResourceStateChip state='inactive' />,
        });
        columns.push({
            value: <ConfigMenu {...{onDeleteClick}} config={entry} />,
            onClick: () => {}
        });
        return {
            onClick: () => historyPusher(entry._id),
            key: `table_${index}`,
            columns
        }
    });

    const isNodeConfig = configType === 'configuration';
    const isBAF = (configType === "baf");
    const createConfig = () => {
        if (isNodeConfig) {
            history.push(`${basePath}/${configType}/create/1`);
        } else {
            history.push(`${basePath}/${createConfigPath}/1`, {configType: configType});
        }
    } 
    const doUpgrade = () => history.push(`/orgs/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`)

    const columnHeaders = isCloudConfig
        ? [lt('name'), lt('provider'), lt('attachments'), lt('lastUpdated'), lt('status'), '']
        : [lt('name'), lt('attachments'), lt('lastUpdated'), lt('status'), ''];

    
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const supportsBAF = useMemo(() => PlanSupports.baf(organization), [organization])
    if (isBAF && !featureToggles.baf) return <ComingSoon page='blockchainFirewall' />;

    if(configsLoading && !configs) return <CircularProgress />

    if(!configsLoading && !configs?.length) {
        if (isBAF) {
            return (
                <EmptyState imageFile='Empty-BAF.svg'
                    title={lt((configType + "_title") as keyof ConfigPageTitleTranslationsInterface)} 
                    description={ supportsBAF ? lt('emptyBAFDescription') : lt('paywallBAFDescription')} 
                    button={ supportsBAF
                        ? { text: lt('addConfig', {configType}), onClick: createConfig }
                        : { text: lt('upgrade', {configType}), onClick: doUpgrade }
                    }
                    documentation={{ text: lt('documentation'), link: 'https://docs.kaleido.io/kaleido-services/baf/' }} />
            )
        } else if (isNodeConfig) {
            return (
                <EmptyState imageFile='Empty-CloudConfigs.svg'
                    title={lt((configType + "_title") as keyof ConfigPageTitleTranslationsInterface)} 
                    description={ lt('emptyConfigDescription') } 
                    button={ { text: lt('addConfig', {configType}), onClick: createConfig } }
                    documentation={{ text: lt('documentation'), link: 'https://docs.kaleido.io/using-kaleido/blockchain-advanced-configuration/' }} />
            )
        } else {
            return (
                <EmptyState imageFile='Empty-CloudConfigs.svg'
                    title={lt((configType + "_title") as keyof ConfigPageTitleTranslationsInterface)} 
                    description={ lt('emptyConfigDescription') } 
                    button={ { text: lt('addConfig', {configType}), onClick: createConfig } }
                    documentation={{ text: lt('documentation'), link: 'https://docs.kaleido.io/using-kaleido/cloud-integrations/' }} />
            )
        } 
    }

    return (
        <>
            <FormDialog successMessage={lt('successDelete')} open={confirmDelete} description={actionConfig.name} setOpen={setConfirmDelete} header={lt('confirmDelete')} onSave={() => onConfirmDelete()} saveText={lt('delete')} closeDialogAfterSave/>
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">{lt((configType + "_title") as keyof ConfigPageTitleTranslationsInterface)}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" size="large" onClick={createConfig}>
                            {lt('addConfig', {configType: lt(configType)})}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <DisplayTable {...{columnHeaders}} records={records} header={lt(configType)} cellSize="medium" />
                </Grid>
            </Grid>
        </>
    )
}

interface translations extends ConfigTypeTranslationsInterface, ConfigPageTitleTranslationsInterface {
    name: string,
    provider: string,
    lastUpdated: string,
    addConfig: string,
    attachments: string,
    status: string,
    active: string,
    inactive: string,
    successDelete: string,
    confirmDelete: string,
    delete: string,
    documentation: string,
    emptyBAFDescription: string,
    upgrade: string,
    paywallBAFDescription: string,
    emptyConfigDescription: string,
}

const enTranslations: translations = {
    ...ConfigTypeTranslations,
    ...ConfigPageTitleTranslations,
    name: 'Name',
    provider: 'Provider',
    lastUpdated: 'Date Created',
    addConfig: 'Add {{configType}}',
    attachments: 'Attachments',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    successDelete: 'Delete was succesful',
    confirmDelete: 'Do you want to delete config?',
    delete: 'Delete',
    documentation: 'Documentation',
    emptyBAFDescription: 'The Kaleido Blockchain Application Firewall (BAF) provides rich options for authentication and authorization of application connections to your Blockchain resources. Let\'s create your first firewall policy.',
    upgrade: 'Upgrade',
    paywallBAFDescription: 'The Blockchain Application Firewall is not enabled on your plan.',
    emptyConfigDescription: 'Let\'s create your first configuration.',
}