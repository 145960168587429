import React, { useState } from 'react';
import { Account, AccountTypeTranslationInterface, AccountTypeTranslation } from '../../models';
import { Button, makeStyles, Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TableDialog } from './TableDialog';
import CloseIcon from 'mdi-react/CloseIcon';

interface Props {
    account: Account | undefined,
    setAccount?: React.Dispatch<React.SetStateAction<Account | undefined>>,
    isMine?: boolean
    fullWidth?: boolean
    disabled?: boolean
    membershipId?: string
    preSelectedAddress?: string
    customSelectAccountMessage?: string
}

export const AccountSelector = ({account, preSelectedAddress, setAccount, isMine, fullWidth, disabled, membershipId, customSelectAccountMessage}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AccountSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AccountSelector:${key}`, interpolate);

    const classes = useStyles();
    
    const [openSelector, setOpenSelector] = useState(false);

    const accountText = account ? `${account.membership.name.length > 10 ? `${account.membership.name.substr(0, 10)}...` : account.membership.name} | ${lt(account.account_type)} | ${account._id.substr(2, 6).toLowerCase()}` : (customSelectAccountMessage || lt('noAccountSelected'))
    return (
        <>
            {setAccount && <TableDialog {...{preSelectedAddress}}  open={openSelector} setOpen={setOpenSelector} setSelectedAccount={setAccount} selectedAccount={account} {...{isMine}} {...{membershipId}} />}
            <Grid container alignItems="center" wrap="nowrap">
                <Grid item container={fullWidth}>
                    <Button data-test="button_selectAccount" {...{disabled}} className={!setAccount ? `${classes.notClickable} ${classes.buttonHeight}` : classes.buttonHeight} 
                        {...{fullWidth}} 
                        variant="outlined" 
                        onClick={setAccount ? (() => setOpenSelector(c => !c)) : undefined}>
                            {accountText}
                    </Button>
                </Grid>
                {account && setAccount && 
                    <Grid item>
                        <IconButton {...{disabled}} onClick={() => setAccount(undefined)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                }
            </Grid>
        </>
    )
}

interface translations extends AccountTypeTranslationInterface {
    noAccountSelected: string
}

const enTranslations: translations = {
    ...AccountTypeTranslation,
    noAccountSelected: 'Select Signing Account',
};

const useStyles = makeStyles(theme => ({
    notClickable: {
        cursor: 'auto',
    },
    buttonHeight: {
        height: '50px'
    }
}))