import React, { useState } from 'react';
import { CreateWrapper, MessageSnackbar, ErrorSnackbarCatcher, Help } from '../../../components/DialogWrappers';
import { Grid, Typography, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CreateStepProps } from '../../../interfaces';
import { BAFPolicyAppCredsOrJWT } from '../BAFPolicySchema';
import { InputRow } from './InputRow';

interface Props extends CreateStepProps {
    authConfig: BAFPolicyAppCredsOrJWT,
    appCredsOrJWT: 'appcreds' | 'jwt',
    save: () => Promise<void>,
    loading: boolean,
    setAuthConfig: (r: BAFPolicyAppCredsOrJWT) => void,
}

export const Step1 = ({authConfig, setAuthConfig, appCredsOrJWT, cancelPath, save, loading}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFAuthSettingsStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFAuthSettingsStep1:${key}`, interpolate)

    const history = useHistory();

    const [errorMessage, setErrorMessasge] = useState('');

    const onNext = async () => {
        try {
            await save();
            history.push(cancelPath);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessasge);
        }
    }

    const setAuthConfigValue = (prop: string, val: any) => {
        setAuthConfig({
            ...authConfig,
            [prop]: val,
        })
    }

    const content =
        <Grid container direction="column" spacing={2} >
            <Grid item container  spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">{lt(appCredsOrJWT)}</Typography>
                </Grid>
            </Grid>
            <Grid item container spacing={2} justify="center">
                <Grid item xs={12}>
                    <Paper>
                        <InputRow
                            dataTest="button_authEnabled"
                            fieldName='enabled'
                            fieldReadable={lt('enabled')}
                            fieldDesc={appCredsOrJWT === 'appcreds' ? lt('enabledAppCredsDesc') : lt('enabledJWTDesc')}
                            checked={authConfig.enabled}
                            setAuthConfigValue={setAuthConfigValue}
                            isSwitch={true}
                        />
                        {appCredsOrJWT === 'appcreds' && <>
                            <InputRow
                                fieldName="basicAuth"
                                fieldReadable={lt('basicAuth')}
                                fieldDesc={lt('basicAuthDesc')}
                                checked={authConfig.basicAuth}
                                setAuthConfigValue={setAuthConfigValue}
                                isSwitch={true}
                            />
                        </>}
                        <InputRow
                            fieldName="bearer"
                            fieldReadable={lt('bearer')}
                            fieldDesc={lt('bearerDesc')}
                            checked={authConfig.bearer}
                            setAuthConfigValue={setAuthConfigValue}
                            isSwitch={true}
                        />
                        <InputRow
                            fieldName="header"
                            fieldReadable={lt('header')}
                            fieldDesc={lt('headerDesc')}
                            value={authConfig.header}
                            setAuthConfigValue={setAuthConfigValue}
                            isSwitch={false}
                        />                        
                        <InputRow
                            fieldName="cookie"
                            fieldReadable={lt('cookie')}
                            fieldDesc={lt('cookieDesc')}
                            value={authConfig.cookie}
                            setAuthConfigValue={setAuthConfigValue}
                            isSwitch={false}
                        />
                        <InputRow
                            fieldName="query"
                            fieldReadable={lt('query')}
                            fieldDesc={lt('queryDesc')}
                            value={authConfig.query}
                            setAuthConfigValue={setAuthConfigValue}
                            isSwitch={false}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Grid>

    return(
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessasge}/>
            <CreateWrapper cancelPath={cancelPath} disabled={loading} {...{content}} {...{onNext}} isFirstStep isLastStep/>
            <Help 
                imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
                header={appCredsOrJWT === 'appcreds' ? lt('helpHeaderAppCreds') : lt('helpHeaderJWT')}
                description={appCredsOrJWT === 'appcreds' ? lt('helpDescriptionAppCreds') : lt('helpDescriptionJWT')}
                docLink={appCredsOrJWT === 'appcreds' ? "https://docs.kaleido.io/kaleido-services/baf/appcreds/" : "https://docs.kaleido.io/kaleido-services/baf/oauth/"}
                bullet1={appCredsOrJWT === 'appcreds' ? lt('bulletHelp1AppCreds') : lt('bulletHelp1JWT')}
                bullet2={appCredsOrJWT === 'appcreds' ? lt('bulletHelp2AppCreds') : lt('bulletHelp2JWT')}
                bullet3={lt('bulletHelp3')}
            />
        </>
    )
}

interface translations {
    jwt: string,
    appcreds: string,

    enabled: string,
    enabledAppCredsDesc: string,
    enabledJWTDesc: string,
    basicAuth: string,
    basicAuthDesc: string,
    bearer: string,
    bearerDesc: string,
    header: string,
    headerDesc: string,
    cookie: string,
    cookieDesc: string,
    query: string,
    queryDesc: string,

    helpHeaderAppCreds: string,
    helpDescriptionAppCreds: string,
    bulletHelp1AppCreds: string,
    bulletHelp2AppCreds: string,
    helpHeaderJWT: string,
    helpDescriptionJWT: string,
    bulletHelp1JWT: string,
    bulletHelp2JWT: string,
    bulletHelp3: string
}
const enTranslations: translations = {
    appcreds: "Application Credential Transmission",
    jwt: "JWT Access Token Transmission",
    
    enabled: "Enabled",
    enabledAppCredsDesc: "Enable application credential based authenication",
    enabledJWTDesc: "Enable JWT based authenication",
    basicAuth: "Basic Auth",
    basicAuthDesc: "HTTP basic authentication header",
    bearer: "Bearer Token",
    bearerDesc: "HTTP bearer authentication header",
    header: "Header",
    headerDesc: "Custom HTTP header",
    cookie: "Cookie",
    cookieDesc: "Custom HTTP cookie",
    query: "Query",
    queryDesc: "URL query parameter",

    helpHeaderAppCreds: "Application Credentials",
    helpDescriptionAppCreds: "Configure how your application will pass application credentials to the application firewall for authentication",
    bulletHelp1AppCreds: "Basic Authentication requires the ID of the application credential to be used as the username, and the password is the secret",
    bulletHelp2AppCreds: "Other mechanisms only require the secret part to be passed as an API Key, and the ID will be calculated based on which application credential matches",
    bulletHelp3: "You can provide multiple options for the application to pass the credential",

    helpHeaderJWT: "JWT Access Tokens",
    helpDescriptionJWT: "Configure how your application will pass JSON Web Token (JWT) access tokens to the application firewall for authentication",
    bulletHelp1JWT: "The application needs to perform an OAuth exchange with a server to get a suitably signed access token, or it can generate the JWT directly",
    bulletHelp2JWT: "The JSON Web Token will be verified against the public keys of all OAuth server configurations (configured separately)",
}
