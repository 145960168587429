import React, { useMemo, useState, useEffect } from 'react';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { Step1 } from './Step1';
import { useQuery, useMutation } from '@apollo/client';
import { ConfigData, ConfigQuery, UpdateConfigVars, UpdateConfigMutation } from '../../../models/configs';
import { EnvironmentResourceVars, Config } from '../../../interfaces';
import { useParams, Redirect } from 'react-router-dom';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../../../components/MainNav/SideNavs/Security';
import { BAFConfig, BAFPolicy, BAFPolicyMapping } from '../BAFPolicySchema';
import { CircularProgress } from "@material-ui/core";

export const BAFMappingEditor = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFMappingEditor', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFMappingEditor:${key}`, interpolate);

    const {consortium_id, environment_id, config_id, org_id, auth_type, mapping_id} = useParams<any>();
    const notFoundPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`;
    const cancelPath = `${notFoundPath}/${config_id}`;

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [updateConfig, { loading: configSaving }] = useMutation<Config, UpdateConfigVars>(UpdateConfigMutation)

    const {
        loading: configsLoading,
        data: {
            config
        } = { config: null }
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: config_id!
        },
    });

    const [ version, setVersion ] = useState('')
    const [ mapping, setMapping ] = useState(undefined as BAFPolicyMapping | undefined)

    const bafConfig: BAFConfig | undefined = useMemo(() => {
        if (config) {
            return {
                ...config,
                details: JSON.parse(config.details.baf_policy!) as BAFPolicy,
            }
        }
    }, [config])

    const appCredsOrJWT : 'appcreds' | 'jwt' | undefined =
        (auth_type === 'appcreds') ? 'appcreds' : (auth_type === 'jwt') ? 'jwt' : undefined;
    const mappingIdx = Number(mapping_id);
    
    const newMapping = () => ({
        claims: {},
        ruleset: '',
    } as BAFPolicyMapping);

    useEffect(() => {
        if (bafConfig && mappingIdx >= 0) {
            let newVersion = `${config_id}-${config?._revision}-${appCredsOrJWT}-mapping-${mappingIdx}`;
            if (version !== newVersion) {
                setVersion(newVersion);
                const mappings = bafConfig.details[appCredsOrJWT!]?.mappings;
                let existing = (mappings?.length > mappingIdx) ? mappings[mappingIdx] : newMapping();
                setMapping(existing)
            }
        }
    }, [version, bafConfig, config, config_id, appCredsOrJWT, mappingIdx]);

    if(!configsLoading && !(config && appCredsOrJWT)) return <Redirect to={notFoundPath}/>

    if (!mapping || !appCredsOrJWT) return <CircularProgress />

    const rulesetNames = Object.keys(bafConfig!.details.rulesets);

    const tenantsSet = bafConfig?.details.tenants_tag ? true : false;

    const save = async () => {
        let mappings = bafConfig!.details[appCredsOrJWT!].mappings ? [...bafConfig!.details[appCredsOrJWT!].mappings] : [];
        mapping.templated = tenantsSet && mapping.templated;
        while (mappings?.length <= mappingIdx) mappings.push(newMapping());
        mappings[mappingIdx] = mapping;
        bafConfig!.details[appCredsOrJWT!].mappings = mappings;
        await updateConfig({
            variables: {
                ...queryVariables,
                id: config_id!,
                configuration: {
                    name: bafConfig!.name,
                    details: {
                        baf_policy: JSON.stringify(bafConfig!.details),
                    }
                }
            }
        })
    }

    const loading = configsLoading || configSaving;

    const stepComponents = [{
        step: '',
        component: <Step1 rulesets={rulesetNames} {...{mapping}} {...{setMapping}} {...{cancelPath}} {...{loading}} {...{save}} {...{appCredsOrJWT}} {...{tenantsSet}}/>
    }]

    return (
        <FullScreenCreate cancelPath={cancelPath} {...{loading}} toolbarHeader={lt('header')} {...{stepComponents}} stepUrlParam={'1'} hideStepper />
    )
}

interface translations {
    header: string
}

const enTranslations: translations = {
    header: 'Authentication Mapping'
}