import React, { useState, useEffect } from 'react';
import { SubNavs } from './SubNavs';
import { SideNavProps, ConsortiumResourceVars } from '../../../interfaces';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CompassOutlineIcon from 'mdi-react/CompassOutlineIcon';
import { NavItem } from '../../NavComponents/NavItem'
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_BLOCKS_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from '../../BlockExplorerNav/BlockExplorerNav'
import { TOKENEXPLORER_PATH, TOKENEXPLORER_TRANSFERS_PATH, TOKENEXPLORER_TOKENS_PATH } from '../../TokenExplorerNav/TokenExplorerNav'
import { EnvironmentQuery, EnvironmentData } from '../../../models';
import { useQuery } from '@apollo/client';

export const ENVIRONMENT_DATA_EXPLORER = 'data';
export const DATA_CONTRACTS = 'contracts';

export const DataExplorer = ({ environment_id, selected, setSelected }: SideNavProps) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DataExplorer', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DataExplorer:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();
    const { pathname } = useLocation()

    const [selectedItem, setSelectedItem] = useState<string>();
    const [active, setActive] = useState<boolean>(false);

    const CONTRACTS = lt('contracts')
    const BLOCK_EXPLORER = lt('blockExplorer')
    const TOKEN_EXPLORER = lt('tokenExplorer');

    useEffect(() => {
        const setActiveAndSelected = (activeAndSelected: boolean = true) => {
            setSelected(activeAndSelected)
            setActive(activeAndSelected)
        }

        if (pathname.includes(`${environment_id}/${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}`)) {
            setSelectedItem(CONTRACTS)
            setActiveAndSelected()
        } else {
            setSelectedItem('')
            setActiveAndSelected(false)
        }
        
    }, [environment_id, pathname, setSelected, CONTRACTS])

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;

    const historyPusher = (path?: string) => 
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}${path ? `/${path}` : ''}`)

    const tabs = isCorda ? [] : [
        {
            name: CONTRACTS,
            action: () => historyPusher(`${ENVIRONMENT_DATA_EXPLORER}/${DATA_CONTRACTS}`)
        },
        {
            name: BLOCK_EXPLORER,
            popoverItems: [
                {
                    name: lt('dashboard'),
                    action: () => historyPusher(BLOCKEXPLORER_PATH)
                },
                {
                    name: lt('blocks'),
                    action: () => historyPusher(`${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_BLOCKS_PATH}`)
                },
                {
                    name: lt('transactions'),
                    action: () => historyPusher(`${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}`)
                }
            ]
        },
        {
            name: TOKEN_EXPLORER,
            popoverItems: [
                {
                    name: lt('dashboard'),
                    action: () => historyPusher(TOKENEXPLORER_PATH)
                },
                {
                    name: lt('tokens'),
                    action: () => historyPusher(`${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TOKENS_PATH}`)
                },
                {
                    name: lt('transfers'),
                    action: () => historyPusher(`${TOKENEXPLORER_PATH}/${TOKENEXPLORER_TRANSFERS_PATH}`)
                }
            ]
        }
    ]

    return (
        <>
            <NavItem
                comingSoon={isCorda} 
                icon={<CompassOutlineIcon />}
                header={lt('name')} 
                action={() => {
                    if (isCorda) historyPusher(`${ENVIRONMENT_DATA_EXPLORER}`)
                    else if (!active) setSelected(!selected)
                }} 
            /> 
            <SubNavs list={tabs} selected={active || selected} {...{selectedItem}} />
        </>
    )
};

interface translations {
    name: string
    dashboard: string
    blocks: string
    transactions: string
    contracts: string
    transfers: string
    tokens: string
    blockExplorer: string
    tokenExplorer: string
}
const enTranslations: translations = {
    name: 'Data Explorer',
    dashboard: 'Dashboard',
    blocks: 'Blocks',
    tokens: 'Tokens',
    transactions: 'Transactions',
    contracts: 'Smart Contracts',
    transfers: 'Transfers',
    blockExplorer: 'Block Explorer',
    tokenExplorer: 'Token Explorer'
}