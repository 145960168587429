import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography, TextField, CircularProgress } from "@material-ui/core";
import { MessageSnackbar, ErrorSnackbarCatcher } from '../../components/DialogWrappers'
import { CreateStepProps, ConsortiumResourceVars, ConsortiumResourcesVars } from '../../interfaces';
import { CompiledContractsData, CompiledContractsVars, CompiledContractsQuery, CompiledContractsSubscription, MakeCompiledContractsSubscriptionOptions, CreateContractProjectData, 
    CreateContractProjectVars, CreateContractProjectMutation, MakeContractProjectCreateMutationOptions, PromoteCompiledContractVars, 
    PromoteCompiledContractMutation, CompiledContractData, CompiledContractVars, CompiledContractQuery, Account, EnvironmentStatusData, EnvironmentStatusQuery, ContractProjectType, ContractProjectsData, ContractProjectsQuery } from '../../models';
import { useQuery, useSubscription, useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SourceCodeType } from './Create';
import { NODE_CONTRACTS_PATH } from '../../components/NodeNav/NodeNav';
import { Github } from '../../components/ContractManagement/Github';
import { GithubHelp } from '../../components/ContractManagement/GithubHelp';
import { PrecompiledUpload } from '../../components/ContractManagement/PrecompiledUpload';
import { PrecompiledUploadHelp } from '../../components/ContractManagement/PrecompiledUploadHelp';
import { PrecompiledPaste } from '../../components/ContractManagement/PrecompiledPaste';
import { PrecompiledPasteHelp } from '../../components/ContractManagement/PrecompiledPasteHelp';
import { MembershipSelector } from '../../components/FormControls/MembershipSelector';
import { ContractTemplate, KALEIDO_TEMPLATE_CONTRACTS, KALEIDO_TEMPLATE_CONTRACT_OPTIONS } from '../../components/ContractManagement/ContractTemplate';
import { KaleidoTemplateHelp } from '../../components/ContractManagement/KaleidoTemplateHelp';
import { PrecompiledPasteExistingContract } from '../../components/ContractManagement/PrecompiledPasteExistingContract';
import { getDefaultCompilationDescription } from '../Contracts/CreateCompilation/VersioningUtils';

interface Props extends CreateStepProps {
    sourceCodeType: SourceCodeType
    setSourceCodeType: React.Dispatch<React.SetStateAction<SourceCodeType>>
    contractId: string
    setContractId: React.Dispatch<React.SetStateAction<string>>
    compiledContractId: string
    setCompiledContractId: React.Dispatch<React.SetStateAction<string>>
    newProjectName: string,
    setNewProjectName: React.Dispatch<React.SetStateAction<string>>,
    deployerAccount?: Account,
    contractAddress: string
};

export const Step2 = ({ sourceCodeType, contractId, setContractId, compiledContractId, setCompiledContractId, newProjectName, cancelPath, deployerAccount, contractAddress }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeContractsCreateStep2', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeContractsCreateStep2:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();
    
    let redirectToStep3 = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_CONTRACTS_PATH}/create/3`

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState('');
    const [membershipId, setMembershipId] = useState(deployerAccount?.membership_id || '');

     // setup the compiled contracts query and subscription
    // we need the query to establish the records in the cache. 
    // dont setup the subscription until our compiled contract query has loaded to ensure cache has been established
    const compiledContractsVars = {
        consortia_id: consortium_id!,
        contract_id: contractId
    }
    const {
        loading: compiledContractsLoading,
        data: {
            compiledContracts: ccsFromCache // only intended for use in the subscription setup lifecycle
        } = { compiledContracts: null }
    } = useQuery<CompiledContractsData, CompiledContractsVars>(CompiledContractsQuery, {
        variables: compiledContractsVars,
        fetchPolicy: 'cache-and-network',
        skip: !contractId
    });

    let defaultCompilationDescription = getDefaultCompilationDescription(ccsFromCache);
    const [description, setDescription] = useState(sourceCodeType === 'token' ? '' : defaultCompilationDescription);

    useEffect(() => { 
        setDescription(sourceCodeType === 'token' ? '' : getDefaultCompilationDescription(ccsFromCache))
    },[ ccsFromCache, sourceCodeType ]);

    const ownerAndDescriptionSetterPanel = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('ownerHeader')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('ownerDescription')}
                </Typography>
            </Grid>

            <Grid item>
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            <Grid item>
                <TextField 
                    required
                    multiline
                    rows={2}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('compilationDescription')}
                    variant="outlined"
                />
            </Grid>
        </>
    )


    const {
        loading: envStatusLoading,
        data: {
            environmentStatus : envStatus
        } = { environmentStatus: null }
    } = useQuery<EnvironmentStatusData, ConsortiumResourceVars>(EnvironmentStatusQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!,
        },
        fetchPolicy: 'cache-and-network',
    });

    useSubscription(CompiledContractsSubscription, MakeCompiledContractsSubscriptionOptions(
        compiledContractsVars, {skip: !contractId || (compiledContractsLoading && !ccsFromCache) }));

    // in the preSave create the new app if necessary
    const [createContractProject, { loading: createContractProjectLoading }] = 
        useMutation<CreateContractProjectData, CreateContractProjectVars>(CreateContractProjectMutation)
    const preSave = !contractId ? async () => {
        setSaving(true)

        let contractProjectType: ContractProjectType = 'precompiled'
        if (sourceCodeType === 'github' || sourceCodeType === 'token') {
            contractProjectType = 'github'
        }
        if (sourceCodeType === 'upload') {
            contractProjectType = 'upload'
        }

        return createContractProject(MakeContractProjectCreateMutationOptions({
            consortia_id: consortium_id!,
            contractProject: {
                name: newProjectName,
                membership_id: membershipId,
                type: contractProjectType
            }
        })).then(result => {
            if (result) {
                const newProjectId = result.data?.createContractProject?._id
                if (newProjectId) {
                    setContractId(newProjectId)
                    return newProjectId
                }
            }
            return ''
        })
    } : () => new Promise<string>(function(resolve) {
        setSaving(true)
        resolve('')
    });

    // errors are deleted off of the websocket event for storage reasons.
    // unfortunately, we need to refetch if we receive the event saying the compilation failed.
    const {
        data: {
            compiledContract
        } = { compiledContract: null }
    } = useQuery<CompiledContractData, CompiledContractVars>(CompiledContractQuery, {
        variables: { ...compiledContractsVars, ...{ id: compiledContractId} },
        fetchPolicy: 'cache-only',
        skip: !compiledContractId
    });
    const [getFailedCompiledContract] = useLazyQuery<CompiledContractData, CompiledContractVars>(CompiledContractQuery, {
        variables: { ...compiledContractsVars, ...{ id: compiledContractId} },
        fetchPolicy: 'network-only',
        onError: () => ErrorSnackbarCatcher({ message: lt('duplicateBytecodeError', { compiledContractId }) }, setMessage)
    });

    // in the postSave wait for the contract to be compiled and promote it to our environment
    // or, wait for it to fail and show the error snackbar
    const [promoteCompiledContract, { loading: promoteCompiledContractLoading }] = 
        useMutation<string, PromoteCompiledContractVars>(PromoteCompiledContractMutation)
    const postSave = async (compiledContractId: string, overrideContractProjectId?: string) => {
        if (overrideContractProjectId) {
            setContractId(overrideContractProjectId)
        }
        setCompiledContractId(compiledContractId)
        setLoading(true)
    }
    useEffect(() => {
        setLoading(promoteCompiledContractLoading || createContractProjectLoading || envStatusLoading)
    }, [promoteCompiledContractLoading, createContractProjectLoading, envStatusLoading])
    useEffect(() => {
        if (compiledContract?.state === 'compiled') {
            promoteCompiledContract({
                variables: {
                    consortia_id: consortium_id!,
                    contract_id: contractId,
                    id: compiledContractId,
                    promoteCompiledContract: {
                        endpoint: sourceCodeType === 'token' ?  KALEIDO_TEMPLATE_CONTRACT_OPTIONS.get(compiledContract?.description as KALEIDO_TEMPLATE_CONTRACTS)?.endpoint: compiledContractId,
                        environment_id: environment_id!
                    }
                }
            }).then(() => {
                history.push(redirectToStep3, {
                    compiledContractId,
                    contractId,
                    sourceCodeType,
                    deployerAccount,
                    contractAddress,
                    cancelPath
                })
            }).catch(e => {
                const error = (e?.graphQLErrors[0]?.message ?? '') as string
                setLoading(false)
                // if its already been promoted, we can just push to step 3 as by having attempting the promote
                // we know that this compiledContractId is stored under our contractId
                if (error.includes('has already been promoted to environment')) { // this is horrible
                    history.push(redirectToStep3, {
                        compiledContractId,
                        contractId,
                        sourceCodeType,
                        deployerAccount,
                        contractAddress,
                        cancelPath
                    })
                } else {
                    ErrorSnackbarCatcher(e, setMessage)
                }
            })
        }
    }, [compiledContract, compiledContractId, consortium_id, contractId, environment_id, cancelPath, contractAddress,
        history, newProjectName, node_id, org_id, promoteCompiledContract, sourceCodeType, description, redirectToStep3, deployerAccount])    

    useEffect(() => {
        if (compiledContract?.state === 'failed') {
            if (!compiledContract.errors) {
                getFailedCompiledContract()
            } else {
                setLoading(false)
                if (compiledContract.errors[0]?.endsWith('exact same bytecode')) {
                    setCompiledContractId(compiledContract.errors[0].slice(77, 87)) // this is horrible
                } else {
                    ErrorSnackbarCatcher({ error: compiledContract.errors.join(',') }, setMessage)
                }
            }
        }        
    }, [compiledContract, getFailedCompiledContract, setCompiledContractId])

    // if we get a new error message, kill the loading bar
    useEffect(() => {
        if (message) {
            setLoading(false)
            setSaving(false)
        }
    }, [message, setLoading, setSaving])

    const {
        loading: contractsLoading    } = useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    if (contractsLoading) {
        return <CircularProgress/>
    }

    const importType = () => {
        switch (sourceCodeType) {
            case 'github': return (
                <>
                    <Github {...{loading}} {...{saving}} contract_id={contractId} {...{description}} {...{membershipId}} {...{setMembershipId}} 
                        {...{preSave}} {...{postSave}} {...{cancelPath}} {...{setMessage}} />
                    <GithubHelp />
                </>
            )
            case 'upload': return (
                <>
                    <PrecompiledUpload {...{saving}} {...{ownerAndDescriptionSetterPanel}} {...{loading}} {...{setLoading}} {...{description}} {...{membershipId}}
                        {...{preSave}} contract_id={contractId} {...{postSave}} {...{cancelPath}} {...{setMessage}} />
                    <PrecompiledUploadHelp />
                </>
            )
            case 'paste': return (
                <>
                    <PrecompiledPaste {...{saving}} {...{ownerAndDescriptionSetterPanel}} {...{loading}} {...{description}} {...{membershipId}} contract_id={contractId} 
                        {...{preSave}} {...{postSave}} {...{cancelPath}} {...{setMessage}} {...{contractAddress}} />
                    { contractAddress ? <PrecompiledPasteExistingContract /> : <PrecompiledPasteHelp />}
                </>
            )
            case 'token': return (
                <>
                    <ContractTemplate templateType='kaleido' {...{loading}} {...{saving}} contract_id={contractId} {...{membershipId}} {...{setMembershipId}} {...{description}} {...{setDescription}}
                        {...{preSave}} {...{postSave}} {...{cancelPath}} {...{setMessage}} {...{envStatus}} />
                    <KaleidoTemplateHelp />
                </>
            )
        }
    }

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            {importType()}
        </>
    )
}

interface translations {
    ownerHeader: string
    ownerDescription: string
    compilationDescription: string
    header: string
    headerDescription: string
    tokenProjectDescription: string
    duplicateBytecodeError: string
}
const enTranslations: translations = {
    ownerHeader: 'Version Details',
    ownerDescription: 'Give this contract a name and/or version to identify it.',
    compilationDescription: 'Contract Description / Version',
    header: 'Import type',
    headerDescription: 'Select your method of choice for importing this contract.',
    tokenProjectDescription: 'Token contracts built from Kaleido templates are stored in the Kaleido Contract Templates project.',
    duplicateBytecodeError: 'Failed to compile since the Compiled Contract: {{compiledContractId}} already exists with the exact same bytecode'
}
