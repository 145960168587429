import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';

interface ErrorProps {
    message: string;
}

const ServerError: React.FC<ErrorProps> = ({message}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'serverError', enTranslations);

    return (
        <>
            <Typography variant="h3">{t('serverError:kaleidoUnavailable')}</Typography>
            <Typography>{t('serverError:checkYourNework')}</Typography>
            <Typography variant="overline">{message}</Typography>
            <Button onClick={() => window.location.reload()}>{t('serverError:retry')}</Button>
        </>
    );
};

const enTranslations = {
    kaleidoUnavailable: 'Could not connect to Kaleido',
    checkYourNework: 'Check your network connection. If the problem persists, please contact us',
    retry: 'Retry'
}

export default ServerError;