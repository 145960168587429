import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import {
    ServicesData, ServicesQuery, IDRegistryOrgsData, IDRegistryOrgsQuery, IDRegistryOrgDetailsData,
    IDRegistryOrgDetailsVars, IDRegistryOrgDetailsQuery, IDRegistryProfilePropertiesData,
    IDRegistryProfilePropertiesVars, IDRegistryProfilePropertiesQuery
} from '../../../models'
import { useTranslation } from 'react-i18next';
import { Grid, Typography, CircularProgress, TextField } from "@material-ui/core";
import { CopyableSetting, CopyableSettings } from '../../../components/DisplaySettings';
import { ServiceResourcesVars } from '../../../interfaces';

export const MemberDestinationDetails: React.FC = () => {
    const { t, i18n } = useTranslation();

    i18n.addResourceBundle('en', 'AddressBookDestinationDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookDestinationDetails:${key}`, interpolate)

    const { consortium_id, environment_id, membership_id, destination_uri } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const idRegistryServiceID = services.find(entry => entry.service === 'idregistry' && entry.state === 'started')?._id;

    const {
        loading: loadingIdRegistryOrgs,
        data: {
            idRegistryOrgs
        } = { idRegistryOrgs: [] }
    } = useQuery<IDRegistryOrgsData, ServiceResourcesVars>(IDRegistryOrgsQuery, {
        variables: { service_id: idRegistryServiceID! },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryServiceID
    });

    const idRegistryMembershipOrgId = idRegistryOrgs.find(org => org.name.endsWith(membership_id!))?.id;

    const {
        loading: loadingIdRegistryOrgDetails,
        data: {
            idRegistryOrgDetails
        } = { idRegistryOrgDetails: null }
    } = useQuery<IDRegistryOrgDetailsData, IDRegistryOrgDetailsVars>(IDRegistryOrgDetailsQuery, {
        variables: { service_id: idRegistryServiceID!, id: idRegistryMembershipOrgId },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryMembershipOrgId
    });

    const idRegistryMembershipOrgOwner = idRegistryOrgDetails?.owner;

    const {
        loading: loadingIdRegistryProfileProperties,
        data: {
            idRegistryProfileProperties
        } = { idRegistryProfileProperties: [] }
    } = useQuery<IDRegistryProfilePropertiesData, IDRegistryProfilePropertiesVars>(IDRegistryProfilePropertiesQuery, {
        variables: { service_id: idRegistryServiceID!, owner: idRegistryMembershipOrgOwner },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryMembershipOrgOwner
    });

    if ((loadingIdRegistryOrgs && idRegistryOrgs.length === 0) || (loadingIdRegistryOrgDetails && idRegistryOrgDetails === null) ||
        (loadingIdRegistryProfileProperties && idRegistryProfileProperties.length === 0)) {
        return <CircularProgress />
    }

    const destinationURI = decodeURIComponent(destination_uri!);
    const idRegistryProfileEntry = idRegistryProfileProperties.find(entry => entry.name === destinationURI);

    if (!idRegistryProfileEntry) {
        return <Typography variant="h5">{lt('destinationNotFound')}</Typography>
    }

    const destination = idRegistryProfileEntry.values[0];

    let destinationDetailsCopyableList: CopyableSetting[] = [
        {
            title: lt('name'),
            displayValue: destinationURI.substr(destinationURI.lastIndexOf('/') + 1)
        },
        {
            title: lt('uri'),
            displayValue: destinationURI
        },
        {
            title: lt('type'),
            displayValue: lt(destinationURI.startsWith('kld://app2app') ? 'app2app' : 'documentstore')
        },
        {
            title: lt('lastUpdate'),
            displayValue: new Date(Number(destination.version)).toLocaleString()
        },
        {
            title: lt('certificate'),
            displayValue: <TextField fullWidth variant="outlined" multiline rows="4" disabled value={destination.value} />,
            copyableValue: destination.value
        }
    ];

    return (
        <Grid container spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('destination')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={3}>
                <CopyableSettings header={lt('destination')} copyableList={destinationDetailsCopyableList} />
            </Grid>
        </Grid>
    )
};

interface translations {
    destination: string
    name: string
    type: string
    uri: string
    lastUpdate: string
    app2app: string
    documentstore: string
    certificate: string
    destinationNotFound: string
}

const enTranslations: translations = {
    destination: 'Destination',
    name: 'Name',
    type: 'Type',
    uri: 'URI',
    lastUpdate: 'Last update',
    app2app: 'App2App',
    documentstore: 'Document Exchange',
    certificate: 'Certificate',
    destinationNotFound: 'Destination not found'
}