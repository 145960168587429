import React from 'react'
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'mdi-react/DeleteIcon';
import SendIcon from 'mdi-react/SendIcon';
import { Dotdotdot } from '../../../components';

interface Props {
    actionDestinationName: string,
    onSetupClick: (destName: string) => void,
    onDeleteClick: (destName: string) => void,
    setupComplete: boolean
}

export const DestinationsMenu = ({actionDestinationName, onDeleteClick, onSetupClick, setupComplete}: Props) => {
    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'DestinationsMenu', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`DestinationsMenu:${key}`, interpolate);

    const menuItems = [{
        name: lt('deleteDestination'),
        icon: <DeleteIcon />,
        action: () => onDeleteClick(actionDestinationName)
    }]

    if(!setupComplete) {
        menuItems.unshift({
            name: lt('setup'),
            icon: <SendIcon />,
            action: () => onSetupClick(actionDestinationName)
        })
    }

    return <Dotdotdot {...{menuItems}} />
}

interface translations {
    setup: string,
    deleteDestination: string,
}

const enTranslations: translations = {
    setup: 'Setup',
    deleteDestination: 'Delete',
}