import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress, Grid, Typography, TextField, FormControl, RadioGroup, FormControlLabel, Radio, MenuItem } from "@material-ui/core";
import { CreateWrapper } from '../../components/DialogWrappers'
import { CreateStepProps, ConsortiumResourcesVars } from '../../interfaces';
import { ContractProjectsData, ContractProjectsQuery, Account } from '../../models';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SourceCodeType, KALEIDO_CONTRACT_TEMPLATES, CREATE_NEW_PROJECT } from './Create';
import { Step1Help } from './Step1Help'
import { NODE_CONTRACTS_PATH } from '../../components/NodeNav/NodeNav';

interface Props extends CreateStepProps {
    sourceCodeType: SourceCodeType
    setSourceCodeType: React.Dispatch<React.SetStateAction<SourceCodeType>>
    contractId: string
    setContractId: React.Dispatch<React.SetStateAction<string>>
    newProjectName: string,
    setNewProjectName: React.Dispatch<React.SetStateAction<string>>,
    deployerAccount?: Account,
    disableOtherSourceCodeTypeOptions?: boolean,
    contractAddress: string
};

export const Step1 = ({ sourceCodeType, setSourceCodeType, contractId, setContractId, newProjectName, setNewProjectName, 
    cancelPath, deployerAccount, disableOtherSourceCodeTypeOptions, contractAddress }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeContractsCreateStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeContractsCreateStep1:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();

    const {
        loading,
        data: {
            contractProjects
        } = { contractProjects: [] }
    } = useQuery<ContractProjectsData, ConsortiumResourcesVars>(ContractProjectsQuery, { 
        variables: { consortia_id: consortium_id! },
        fetchPolicy: 'cache-only'
    });

    const isKaleidoTemplateContract = sourceCodeType === 'token'

    useEffect(() => {
        if (isKaleidoTemplateContract) {
            const templateName = KALEIDO_CONTRACT_TEMPLATES 

            const templateProject = contractProjects.find(c => c.name === templateName)
            setContractId(templateProject?._id || CREATE_NEW_PROJECT)
            setNewProjectName(templateProject?.name || templateName)
        } else {
            setContractId(CREATE_NEW_PROJECT)
            if (!isKaleidoTemplateContract && (newProjectName === KALEIDO_CONTRACT_TEMPLATES)) {
                setNewProjectName('')
            }
        }
    }, [sourceCodeType, contractProjects, isKaleidoTemplateContract, setContractId, setNewProjectName, newProjectName])

    useEffect(() => {
        if (!isKaleidoTemplateContract) {
            setContractId(contractId ?? CREATE_NEW_PROJECT)
        }
    }, [isKaleidoTemplateContract, contractId, setContractId])

    const mapSourceCodeType = (s: SourceCodeType) => s

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSourceCodeType(event.target.value as SourceCodeType);
    };

    const save = () => {
        history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_CONTRACTS_PATH}/create/2`, {
            sourceCodeType,
            contractId,
            newProjectName,
            deployerAccount,
            contractAddress,
            cancelPath
        })
    }

    const makeLabel = (label: string, description: string) => 
        <div>
            <Typography variant="body1">
                {label}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {description}
            </Typography>
        </div>   

    const showProjectSelector = sourceCodeType ? true : false

    const shouldDisableOption = (t: SourceCodeType) => disableOtherSourceCodeTypeOptions && sourceCodeType !== t
    const content = loading ? <CircularProgress /> : (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>
            <Grid item>
                <FormControl component="fieldset" margin="dense">
                    <RadioGroup value={sourceCodeType} onChange={handleChange}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <FormControlLabel checked={sourceCodeType === 'token'} value={mapSourceCodeType('token')} disabled={shouldDisableOption('token')}
                                    control={<Radio color="primary" />} label={makeLabel(lt('token'), lt('tokenDescription'))} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel checked={sourceCodeType === 'upload'} value={mapSourceCodeType('upload')} disabled={shouldDisableOption('upload')}
                                    control={<Radio color="primary" />} label={makeLabel(lt('upload'), lt('uploadDescription'))} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel checked={sourceCodeType === 'paste'} value={mapSourceCodeType('paste')} disabled={shouldDisableOption('paste')}
                                    control={<Radio color="primary" />} label={makeLabel(lt('paste'), lt('pasteDescription'))} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel checked={sourceCodeType === 'github'} value={mapSourceCodeType('github')} disabled={shouldDisableOption('github')}
                                    control={<Radio color="primary" />} label={makeLabel(lt('github'), lt('githubDescription'))} />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>

            {showProjectSelector && 
            <>
                <Grid item>
                    <Typography variant="h5">
                        {lt('selectProject')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lt('selectProjectDescription')}
                        &nbsp;
                        {sourceCodeType === 'token' && lt('tokenProjectDescription')}
                    </Typography>
                </Grid>

                <Grid item>
                    <TextField data-test="selectProject" 
                        disabled={isKaleidoTemplateContract} required select fullWidth label={lt('project')} value={contractId} 
                        variant="outlined" onChange={e => setContractId(e.target.value)}>
                        <MenuItem value={CREATE_NEW_PROJECT}>{lt('createNewProject')}</MenuItem>
                        {contractProjects.filter(c => 
                                (sourceCodeType === 'github' || sourceCodeType === 'token') ? c.type === "github"
                                : (sourceCodeType === 'upload') ? c.type === "upload" : c.type === "precompiled")
                            .filter(c => sourceCodeType === 'github' ? (c.name !== KALEIDO_CONTRACT_TEMPLATES): true).map(c => (
                            <MenuItem key={c._id} value={c._id}>{c.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {contractId === CREATE_NEW_PROJECT &&
                <Grid item>
                    <TextField disabled={isKaleidoTemplateContract} data-test="textField_projectName" 
                        required
                        value={newProjectName} 
                        onChange={event => setNewProjectName(event.target.value)}
                        fullWidth
                        label={lt('projectName')}
                        variant="outlined"
                    />
                </Grid>}
            </>
            }
        </>
    )

    const disabled = !sourceCodeType || 
        (showProjectSelector && contractId === undefined ? true : false) || 
        (showProjectSelector && contractId === CREATE_NEW_PROJECT && !newProjectName ? true : false)

    return (
        <>
            <CreateWrapper {...{cancelPath}} {...{content}} disabled={disabled} onNext={save} isFirstStep />
            <Step1Help />
        </>
    )
};

interface translations {
    header: string
    headerDescription: string
    upload: string
    uploadDescription: string
    paste: string
    pasteDescription: string
    token: string,
    tokenDescription: string
    github: string,
    githubDescription: string
    selectProject: string
    selectProjectDescription: string
    project: string
    createNewProject: string
    projectName: string
    tokenProjectDescription: string
}
const enTranslations: translations = {
    header: 'Import type',
    headerDescription: 'Select your method of choice for importing a contract.',
    upload: 'Upload file with source code',
    uploadDescription: 'Select this option to upload your Solidity source code as a single file or zip with dependencies',
    paste: 'Paste version metadata',
    pasteDescription: 'Select this option if you\'ve already compiled your code and have the bytecode and ABI handy',
    token: 'Use our Token Factory',
    tokenDescription: 'No coding required. Create fungible and non-fungible token contracts from a template',
    github: 'Import from Github',
    githubDescription: 'Select this option to import your Solidity file from Github (supports Truffle projects!)',
    selectProject: 'Select App',
    selectProjectDescription: 'Select the App where this contract should reside, or, create a new App.',
    project: 'App',
    createNewProject: 'Create New App',
    projectName: 'New App Name',
    tokenProjectDescription: 'Token contracts built from Kaleido templates are stored in the Kaleido Contract Templates App.'
}
