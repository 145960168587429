import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { CopyableSetting, CopyableSettings } from '../../components/DisplaySettings';
import { ShortenedHash } from '../../components/FormControls/ShortenedHash';
import { Grid, Typography, CircularProgress, TablePagination, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LedgerContractQuery, LedgerContractData, AddressTransactionsVars, TokenTransfersData, TokenTransfersQuery } from '../../models';
import { BLOCKEXPLORER_PATH, BLOCKEXPLORER_TRANSACTIONS_PATH } from '../../components/BlockExplorerNav/BlockExplorerNav';
import { APPS_BASE_PATH, APPS_GATEWAY_APIS_PATH } from '../../components/MainNav/SideNavs/AppsIntegrations';
import { EnvironmentResourceVars } from '../../interfaces';
import { TransactionsTable, TransactionsColumns } from '../../components/Transactions/TransactionsTable';
import Jazzicon from 'react-jazzicon';
import { jsNumberForAddress } from '../../utils/StringUtils';
import { FormLink } from '../../components/FormControls/FormLink';
import { DIGITAL_ASSETS_TOKENS_PATH, DIGITAL_ASSETS_BASE_PATH } from '../../components/MainNav/SideNavs/DigitalAssets';
import { NoGatewayAPI } from '../../components/Banners/NoGatewayAPI';

const LIMITLIST = [10, 25];

export const TokenInfo = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenInfo', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenInfo:${key}`, interpolate);

    const [limit, setLimit] = useState(LIMITLIST[0]);
    const [currentPage, setCurrentPage] = useState(0);

    const {consortium_id, environment_id, token_address, org_id} = useParams<any>();
    const { pathname } = useLocation()
    const redirectUrl = pathname.replace(`${token_address}`, '');
    const classes = useStyles();

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }
    
    const {
        loading,
        data: {
            ledgerContract
        } = {ledgerContract: null}
    } = useQuery<LedgerContractData, EnvironmentResourceVars>(LedgerContractQuery, {
        variables: {
            ...environmentVariables,
            id: token_address!
        },
        fetchPolicy: 'cache-first'
    });

    const {
        refetch: refetchTransactions,
        loading: transactionsLoading,
        data: {
            tokenTransfers: {
                totalCount,
                transactions
            }
        } = {tokenTransfers: {totalCount: 0, transactions: []}}
    } = useQuery<TokenTransfersData, AddressTransactionsVars>(TokenTransfersQuery, {
        variables: {
            ...environmentVariables,
            address: token_address!,
            limit,
            start: currentPage * limit,
        },
        fetchPolicy: 'cache-and-network'
    });

    if (loading && !ledgerContract) return <CircularProgress />;
    if (!ledgerContract) return <Redirect to={redirectUrl} />;

    const getType = () => {
        if (ledgerContract.isERC20) return lt('erc20')
        if (ledgerContract.isERC721) return lt('erc721');
        if (ledgerContract.isZKPToken) return lt('zkpToken');
        return lt('token');
    }

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const tokenInfoList : CopyableSetting[] = [
        {
            title: lt('address'),
            displayValue: <ShortenedHash address={ledgerContract.address ?? ''} />,
            copyableValue: ledgerContract.address ?? ''
        }, {
            title: lt('type'),
            displayValue: getType(),
            disableCopy: true
        }];
    
        if(ledgerContract.isERC20) {
            tokenInfoList.push({
                title: lt('totalSupply'),
                displayValue: ledgerContract.erc20TotalSupply ?? '--',
                disableCopy: true
            })
        }

        tokenInfoList.push({
            title: lt('mintable'),
            displayValue: ledgerContract.isERCMintable ? lt('yes') : lt('no'),
            disableCopy: true
        },{
            title: lt('burnable'),
            displayValue: ledgerContract.isERCBurnable ? lt('yes') : lt('no'),
            disableCopy: true
        }, {
            title: lt('deployedBy'),
            displayValue: <ShortenedHash address={ledgerContract.creator ?? ''} /> ,
            copyableValue: ledgerContract.creator ?? ''
        }, {
            title: lt('genesisTransaction'),
            displayValue: <FormLink children={ledgerContract.genesisTransaction} to={`${basePath}/${BLOCKEXPLORER_PATH}/${BLOCKEXPLORER_TRANSACTIONS_PATH}/${ledgerContract.genesisTransaction}`} />,
            copyableValue: ledgerContract.genesisTransaction ?? ''
        })

    

    if (ledgerContract.gatewayAPIId) {
        tokenInfoList.push({
            title: lt('gatewayAPIId'),
            displayValue: <FormLink children={ledgerContract.gatewayAPIEndpoint ?? ledgerContract.gatewayAPIId} to={`${basePath}/${APPS_BASE_PATH}/${APPS_GATEWAY_APIS_PATH}/${ledgerContract.gatewayAPIId}`}/>,                
            copyableValue: ledgerContract.gatewayAPIId ?? ''
        }, {
            title: lt('manageToken'),
            displayValue: <FormLink to={`${basePath}/${DIGITAL_ASSETS_BASE_PATH}/${DIGITAL_ASSETS_TOKENS_PATH}/${ledgerContract._id}`}>
                                <Typography variant="body2">{ledgerContract.tokenName}</Typography>
                        </FormLink>,
            disableCopy: true
        })
    }

    const rolesList : CopyableSetting[] = [
        {
            title: lt('owner'),
            displayValue: <ShortenedHash address={ledgerContract.creator ?? ''} />,
            disableCopy: true
        }
    ]

    if (ledgerContract.ERCMinters && ledgerContract.ERCMinters.length > 0) {
        rolesList.push({
            title: lt('minters'),
            displayValue: ledgerContract.ERCMinters.map(minter => <ShortenedHash address={minter} />),
            disableCopy: true
        })
    }

    const onChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        setCurrentPage(page)
    }
    
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCurrentPage(0);
        setLimit(parseInt(event.target.value))
    }

    const Pagination = <TablePagination 
                            rowsPerPageOptions={LIMITLIST} 
                            component="div" 
                            count={totalCount} 
                            rowsPerPage={limit} 
                            onChangeRowsPerPage={onChangeRowsPerPage} 
                            onPageChange={onChangePage} 
                            page={currentPage} 
                            nextIconButtonProps={{disabled: loading || (currentPage + 1) * limit >= totalCount}} 
                            backIconButtonProps={{disabled: loading || currentPage <= 0}} />;
    
    const transactionsColumns: TransactionsColumns[] = ['token', 'amount', 'from', 'to', 'dateMined'];
    
    const header = (
        <Grid container spacing={1} alignItems="center">
            <Grid item className={classes.icon}>
                <Jazzicon diameter={20} seed={jsNumberForAddress(ledgerContract.address)} />
            </Grid>
            <Grid item>
                <Typography variant="h6" noWrap>
                    {`${ledgerContract.tokenName} (${ledgerContract.tokenSymbol})`}
                </Typography>
            </Grid>
        </Grid>
    )

    const onRefreshClick = () =>{
        setCurrentPage(0);
        refetchTransactions();
    }

    const toolBarOptions = {
        refresh: {
            active: true,
            onRefresh: onRefreshClick
        }
    };
    
    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{lt('header')}</Typography>
                </Grid>
                { !ledgerContract.gatewayAPIId && 
                    <Grid item>
                        <NoGatewayAPI {...{ledgerContract}} />
                    </Grid>
                }
                <Grid item>
                    <CopyableSettings {...{header}} copyableList={tokenInfoList} />
                </Grid>
                <Grid item>
                    <CopyableSettings header={lt('roles')} copyableList={rolesList} />
                </Grid>
                <Grid item>
                    {transactionsLoading && transactions.length === 0 ? <CircularProgress /> : (
                        <TransactionsTable columns={transactionsColumns} transactions={transactions} pagination={Pagination} isToken {...{toolBarOptions}} />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    header: string
    bytecode: string
    gatewayAPIId: string
    genesisTransaction: string
    deployedBy: string
    address: string
    erc20: string
    erc721: string
    type: string
    status: string
    zkpToken: string
    token: string
    totalSupply: string
    burnable: string
    mintable: string
    owner: string
    roles: string
    minters: string
    yes: string
    no: string
    manageToken: string
}

const enTranslations: translations = {
    header: 'Token Info',
    bytecode: 'ByteCode',
    gatewayAPIId: 'Gateway API Endpoint',
    genesisTransaction: 'Genesis Transaction',
    deployedBy: 'Deployed By',
    address: 'Address',
    erc20: 'ERC 20 Token',
    erc721: 'ERC 721 Token',
    type: 'Type',
    zkpToken: 'ZKP Token',
    token: 'Token',
    status: 'Status',
    manageToken: 'Manage Token',
    totalSupply: 'Total Supply',
    burnable: 'Burnable',
    mintable: 'Mintable',
    owner: 'Owner',
    roles: 'Roles',
    minters: 'Minters',
    yes: 'Yes',
    no: 'No'
};

const useStyles = makeStyles(() => ({
    icon: {
        display: 'flex',
        alignSelf: 'center'
    }
}))