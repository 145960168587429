import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { NODE_EVENTSTREAMS_PATH } from '../../../components/NodeNav/NodeNav';
import { Step1 } from './Step1';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeEventStreamsCreateEventStreamSubscription', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeEventStreamsCreateEventStreamSubscription:${key}`, interpolate)

    const { pathname } = useLocation()
    const { org_id, consortium_id, environment_id, node_id, eventstream_id, step } = useParams<any>();

    const cancelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node_id}/${NODE_EVENTSTREAMS_PATH}/${eventstream_id}`

    const splitPathname = pathname.split('/')
    const redirectToStep1 = splitPathname.slice(0, splitPathname.indexOf(step!)).concat('1').join('/')

    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    if (invalidStep || createStep > 1) {
        return <Redirect to={redirectToStep1} />
    }

    const stepComponents = [
        { step: lt('addNewSubscription'), component: <Step1 cancelPath={cancelPath} /> },
    ]

    return <FullScreenCreate cancelPath={cancelPath} toolbarHeader={lt('addNewSubscription')} stepUrlParam={step!} {...{stepComponents}} hideStepper />
};

interface translations {
    addNewSubscription: string,
}
const enTranslations: translations = {
    addNewSubscription: 'Add New Subscription',
}