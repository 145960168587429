import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { NodeData, NodeQuery, NodeStatusData, NodeStatusQuery,
    EnvironmentZonesData, EnvironmentZonesQuery, AttemptSetNodeReadyMutation, EnvironmentQuery, EnvironmentData, RuntimeSizeTranslation, EnRuntimeSizeTranslation } from '../../models'
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings'
import { ShortenedHash } from '../../components/FormControls/ShortenedHash'
import { EnvironmentResourceVars, LinkButtonProps } from '../../interfaces'
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { capitalize } from '../../utils/StringUtils';
import { ConnectRuntimeWrapper } from '../../components/ConnectRuntime/ConnectRuntimeWrapper';
import { PrivateStackPanel } from '../../components/PrivateStack/PrivateStackPanel';
import { DisplayCard, DisplayTable } from '../../components/DisplayWrappers';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { NODE_CORDAPPS_PATH, FABCONNECT_REST_API_PATH } from '../../components/NodeNav/NodeNav';
import { WebUiCard } from '../../components/WebUICard/WebUICard';

export const Overview: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeSettings:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, node_id } = useParams<any>();
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');

    const envResVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const nodeVars = {
        ...envResVars,
        id: node_id!
    }

    const {
        loading,
        data: {
            node
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: nodeVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        refetch: refetchNodeStatus,
        data: {
            nodeStatus
        } = { nodeStatus: null },
        startPolling,
        stopPolling
    } = useQuery<NodeStatusData>(NodeStatusQuery, {
        variables: nodeVars,
        fetchPolicy: 'cache-and-network',
        
    });

    const user_accounts = useMemo(() => {
       return nodeStatus?.user_accounts ?? [] as string[]
    }, [nodeStatus]);

    const fabricExplorerUsername = useMemo(() => {
        return nodeStatus?.fabric?.explorer?.username
     }, [nodeStatus]);

    const isCorda = environment?.provider.includes('corda');
    const isFabric = environment?.provider.includes('fabric');
    const isEthereum = !isCorda && !isFabric && environment;

    // when node comes up for the first time, refetch the node status one time to update the user_accounts
    const refetchedNodeStatusOnCreation = useRef<boolean>(false)
    useEffect(() => {
        if (!isFabric && !user_accounts.length && node?.state === "started" && !refetchedNodeStatusOnCreation.current) {
            refetchedNodeStatusOnCreation.current = true
            refetchNodeStatus()
        }
    }, [user_accounts, node, refetchNodeStatus, isFabric])

    useEffect(() => {
        if (isFabric && node?.state === 'started' && node?.role === 'peer' && !fabricExplorerUsername) {
            startPolling(3000)
        } else if (isFabric && fabricExplorerUsername) {
            stopPolling()
        }
    }, [node?.role, node?.state, startPolling, stopPolling, isFabric, fabricExplorerUsername])

    const [attemptSetNodeReady, { loading: attemptSetNodeReadyLoading }] = useMutation<Node, EnvironmentResourceVars>(AttemptSetNodeReadyMutation)

    if (loading || !node) return <CircularProgress />

    const hexShortener = (s: string, c = 12) => s ? `${s.slice(0,c)}...${s.slice(s.length - c, s.length)}` : ''

    const envZone = environmentZones.find(z => z._id === node.zone_id)
    const isRemoteNode = envZone?.type === 'private';
    const requiresPrivateStackBridge = isRemoteNode || isCorda;

    const isAnOrdererNode = node.role === "orderer";

    let copyableList: CopyableSetting[] = [
        { disableCopy: true, title: lt('name'), displayValue: node.name },
        {
            title: lt('nodeId'),
            displayValue: node._id,
        },
        { disableCopy: true, title: lt('nodeSize'), displayValue: lt(node.size) },
        { disableCopy: true, title: lt('owner'), displayValue: node.membership.name },
        { disableCopy: true, title: lt('status'), displayValue: <ResourceStateChip state={node.state} /> },
        { disableCopy: true, title: lt('region'), displayValue: envZone?.displayName || '' },
    ];

    if (isFabric) {
        copyableList = copyableList.concat([
            { disableCopy: true, title: lt('nodeRole'), displayValue: capitalize(node.role) }
        ]);
    }

    if (environment?.test_features?.hybrid) {
        copyableList.push(
            { disableCopy: true, title: lt('portAllocation'), displayValue: `${node.hybrid_port_allocation}` }
        );
    }
    copyableList.push(
        { disableCopy: true, title: lt('creationDate'), displayValue: new Date(node.created_at).toLocaleString() },
        { disableCopy: true, title: lt('lastUpdatedDate'), displayValue: new Date(node.updated_at ?? node.created_at).toLocaleString() },
        {
            title: lt('enodeId'),
            displayValue: hexShortener(node.node_identity),
            copyableValue: node.node_identity,
        },
    );
    if (isEthereum) {
        copyableList = copyableList.concat([
            { disableCopy: true, title: lt('consensusRole'), displayValue: capitalize(node.init_consensus_role) },
            {
                title: lt('consensusId'),
                displayValue: hexShortener(node.consensus_identity),
                copyableValue: node.consensus_identity,
            },
        ]);
    }

    if (node.quorum_private_address || node.pantheon_private_address) {
        copyableList.push({ 
            title: lt('privateTxParticipant'), 
            displayValue: hexShortener(node.quorum_private_address || node.pantheon_private_address || ''), 
            copyableValue: node.quorum_private_address || node.pantheon_private_address 
        })
    }

    if (nodeStatus?.clientName) {
        copyableList.push({ title: lt('clientName'), displayValue: nodeStatus.clientName, disableCopy: true })
    }

    if (user_accounts.length) {
        copyableList.push({
            title: lt('userAccounts'),
            displayValue: user_accounts.map(a => <ShortenedHash address={a} showFullAddress retry />),
            copyableValue: user_accounts.map(a => a.toLowerCase()),
        })
    }

    const testReady = async () => {
        try {
            await attemptSetNodeReady({ variables: nodeVars });
        }
        catch(e) {
            ErrorSnackbarCatcher(e, setErrorMessage)
        }
    }

    const readyButton: LinkButtonProps = {
        onClick: testReady,
        text: lt('verifyConnectivity'),
        color: 'primary',
        variant: 'contained',
        disabled: attemptSetNodeReadyLoading,
    };

    const onFabricUIClick = () => {
        history.push(`${history.location.pathname}/${FABCONNECT_REST_API_PATH}`)
    }
    
    return (
        <>
            <MessageSnackbar setMessage={setErrorMessage} message={errorMessage} />
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        {lt(node.role === 'peer' ? 'peerNodeOverview' : node.role === 'orderer' ? 'ordererNodeOverview' : 'nodeOverview')}
                    </Typography>
                </Grid>

                <Grid item container direction="row" spacing={3} alignItems="flex-start">
                    <Grid item container direction="column" xs={12} md={7} spacing={3}>
                        <Grid item>
                            <CopyableSettings
                                header={lt('nodeInfo')}
                                description={lt('nodeDescription')}
                                {...{copyableList}} />
                        </Grid>
                        {isFabric && !isAnOrdererNode && node.urls?.kaleido_connect && (
                            <Grid item container>
                                <WebUiCard header={lt('fabconnect')} disabled={node.state !== 'started'}
                                    imageFiles={'Fabconnect.png'}  
                                    description={lt('fabconnectDescription')}
                                    onClick={onFabricUIClick}
                                    buttonText={lt('viewAPI')} />
                            </Grid>
                        )}
                    </Grid>

                    <Grid item container direction="column" xs={12} md={5} spacing={3}>
                        {requiresPrivateStackBridge
                        ?   <>
                                <Grid item>
                                    <PrivateStackPanel headerText={lt('privateStack')} membershipId={node.membership_id}/>
                                </Grid>
                                {isCorda && <Grid item>
                                    <DisplayCard header={lt('cordapps')} description={lt('cordappsDescription')} 
                                        itemList={[]}
                                        linkButton={{
                                            text: lt('newCordapp'),
                                            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node._id}/${NODE_CORDAPPS_PATH}/create/1`),
                                            color: 'primary',
                                            variant: 'contained',
                                            disabled: node.state !== 'started'
                                        }}/>
                                </Grid>}
                            </>
                        :
                           !isAnOrdererNode && (
                            <>
                                {isFabric && (
                                    <Grid item container>
                                        <WebUiCard header={lt('fabricExplorer')} disabled={node.state !== 'started' || !nodeStatus?.urls?.explorer || !fabricExplorerUsername}
                                            credentials={{username: fabricExplorerUsername, password: nodeStatus?.fabric?.explorer?.password}}
                                            imageFiles={'Fabric-Explorer.png'}  
                                            description={lt('fabricExplorerDescription')}
                                            onClick={() => window.open(nodeStatus?.urls?.explorer)} />
                                    </Grid>
                                )}
                                <Grid item>
                                    <ConnectRuntimeWrapper {...{open}} {...{setOpen}} node={node} />
                                </Grid>
                            </>
                           )
                        } 
                        {isRemoteNode && ['initializing','failed'].includes(node.state) &&
                            <Grid item>
                                <DisplayTable header={lt('completeSetup')} description={lt('completeSetupDesc')} linkButton={readyButton}/>
                            </Grid>                        
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends RuntimeSizeTranslation {
    nodeOverview: string,
    nodeInfo: string,
    peerNodeOverview: string,
    ordererNodeOverview: string,
    nodeDescription: string,
    name: string,
    nodeSize: string,
    creationDate: string,
    lastUpdatedDate: string,
    status: string,
    nodeRole: string,
    consensusRole: string,
    owner: string,
    enodeId: string,
    nodeId: string,
    consensusId: string,
    userAccounts: string,
    region: string,
    portAllocation: string,
    privateStack: string,
    cordapps: string,
    cordappsDescription: string,
    newCordapp: string,
    clientName: string,
    privateTxParticipant: string,
    completeSetup: string,
    completeSetupDesc: string,
    verifyConnectivity: string,
    fabricExplorer: string
    fabricExplorerDescription: string
    explorerUsername: string
    explorerPassword: string
    fabconnect: string
    fabconnectDescription: string
    viewAPI: string
}
const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    nodeOverview: 'Node Overview',
    nodeInfo: 'Node information',
    peerNodeOverview: 'Peer Node Overview',
    ordererNodeOverview: 'Orderer Node Overview',
    nodeDescription: 'Below you can view all the metadata for this node including node endpoints and other important information you may wish to copy.',
    name: 'Name',
    nodeSize: 'Node size',
    creationDate: 'Creation date',
    lastUpdatedDate: 'Last updated date',
    status: 'Status',
    nodeRole: 'Node role',
    consensusRole: 'Consensus role',
    owner: 'Owner',
    enodeId: 'Blockchain Node ID',
    nodeId: 'Node ID',
    consensusId: 'Consensus ID',
    userAccounts: 'User accounts',
    region: 'Region',
    portAllocation: 'Base Network Port',
    privateStack: "Your node is running within your environment's firewall isolated network. To create RPC connections to your node, you must configure a bridge network connection from your local private network.",
    cordapps: 'CorDapps',
    cordappsDescription: 'CorDapps are deployed to this node via promotion from apps',
    newCordapp: 'Add CorDapp',
    clientName: 'Runtime version',
    privateTxParticipant: 'Private TX participant',
    completeSetup: 'Complete Node Setup',
    completeSetupDesc: 'Once you have completed your setup local setup of PrivateStack, trigger Kaleido to communicate with your node and complete the blockchain setup',
    verifyConnectivity: 'Verify Connectivity',
    fabricExplorer: 'Fabric Blockchain Explorer',
    fabricExplorerDescription: 'Each peer node contains a Block Explorer where you can monitor blockchain activity on each channel this node is a member of.',
    explorerUsername: 'Fabric Explorer Username',
    explorerPassword: 'Fabric Explorer Password',
    fabconnect: 'REST API Gateway',
    fabconnectDescription: 'Hyperledger FireFly FabConnect is a connector that provides a RESTful API to submit requests to Fabric networks and an event stream to listen for Fabric events over websocket or webhook based channels.',
    viewAPI: "View REST API"
}