import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Node, StopNodeMutation } from '../../models'
import { FormDialog } from '../../components/DialogWrappers'
import { EnvironmentResourceVars } from '../../interfaces';

interface Props {
    name: string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const StopRuntime = ({ name, open, setOpen }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'StopRuntime', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`StopRuntime:${key}`, interpolate)

    const { consortium_id, environment_id, node_id, service_id } = useParams<any>();

    const envResourceVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [stopNode, { loading: stopNodeLoading }] = useMutation<Node, EnvironmentResourceVars>(StopNodeMutation)

    const loading = stopNodeLoading
    
    const updater = () => {         
        if (node_id) {
            const updateVars = { ...envResourceVars, ...{ id: node_id! } }
            return stopNode({
                variables: updateVars,
            }).then(() => {})
        } else if (service_id) {
            return new Promise<void>(() => {})
        } else {
            return new Promise<void>(() => {})
        }
    }

    return <FormDialog 
        {...{open}} 
        {...{setOpen}} 
        header={lt('stopRuntime', {name})} 
        description={lt('stopDescription')} 
        saveDisabled={loading}
        onSave={updater}
        closeDialogAfterSave
        saveText={lt('stop')} />
};

interface translations {
    stopRuntime: string,
    stopDescription: string,
    stop: string
}
const enTranslations: translations = {
    stopRuntime: 'Stop this runtime ({{name}})?',
    stopDescription: 'This will stop all software processes running as part of this resource and will keep the node suspended until explicitly started.',
    stop: 'stop'
}