import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuditEntry } from '../../models/audit';
import { capitalize } from '../../utils/StringUtils';

interface Props {
    auditEntry: AuditEntry
}

export const ActivityAction = ({ auditEntry }: Props) => {
    const { t, i18n } = useTranslation();
    
    i18n.addResourceBundle('en', 'ActivityAction', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ActivityAction:${key}`, interpolate)

    const getStatus = (entry: AuditEntry): string => {
        switch (entry.action) {
            case 'create': return lt('create');
            case 'update': return lt('update');
            case 'delete': return lt('delete');
            default: return capitalize(entry.action)
        }
    };

    // calling component can wrap this however it likes
    return <>{getStatus(auditEntry)}</> 
};

interface translations {
    create: string
    update: string
    delete: string
}
const enTranslations: translations = {
    create: 'Create',
    update: 'Update',
    delete: 'Delete'
}