import React from 'react';
import { BlockForChart, Environment } from '../../models';
import PollIcon from 'mdi-react/PollIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import { Kpi } from '../../components/DisplayWrappers/Kpi';
import { useTranslation } from 'react-i18next';

interface Props {
    totalBlocks: number,
    blocks: BlockForChart[],
    environment: Environment | null,
    totalTransactions: number
}

export const BlockKpi = ({totalBlocks, blocks, environment, totalTransactions} : Props) => {    
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BlocksKpi', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BlocksKpi:${key}`, interpolate);

    const kpiItems = [{
        value: totalBlocks ?? '--',
        label: lt('latestBlocks'),
        icon: <PollIcon />
    }, {
        value: environment?.block_period ?? '--',
        label: lt('blockPeriod'),
        icon: <ClockOutlineIcon />
    }, {
        value: blocks[blocks.length - 1]?.y  ?? '--',
        label: lt('lastBlockTxs'),
        icon: <PollIcon />
    }, {
        value: totalTransactions ?? '--',
        label: lt('totalTxs'),
        icon: <PollIcon />
    }]; 

    return <Kpi data={kpiItems} />
}

interface translations {
    latestBlocks: string
    blockPeriod: string
    lastBlockTxs: string
    totalTxs: string
}

const enTranslations: translations = {
    latestBlocks: 'Latest Block',
    blockPeriod: 'Block Period',
    lastBlockTxs: 'Last Block Txs',
    totalTxs: 'Total Txs'
}
