import React from 'react';
import { TransactionLog as Log } from '../../models';
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings';
import { useTranslation } from 'react-i18next';
import { TextField, makeStyles } from '@material-ui/core';

interface Props {
    log: Log,
}

export const TransactionLog = ({log}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TransactionLog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TransactionLog:${key}`, interpolate);

    const classes = useStyles();

    const copyableList : CopyableSetting[] = [
        {
            title: lt('address'),
            displayValue: log.address ?? '',
            copyableValue: log.address
        },{
            title: lt('topics'),
            displayValue: log.topics ?? '',
            copyableValue: log.topics 
        },{
            title: lt('data'),
            displayValue:  <TextField fullWidth value={log.data ?? ''} multiline rows="4" variant="outlined" inputProps={{className: classes.multiline}} />,
            copyableValue: log.data
        }
    ];

    if(log.event) {
        copyableList.push({
            title: lt('event'),
            displayValue: log.event,
            copyableValue: log.event
        })
    };

    if(log.inputs) {
        copyableList.push({
            title: lt('inputs'),
            displayValue: <TextField multiline value={JSON.stringify(log.inputs, null, 4)} fullWidth variant="outlined" rows={3} inputProps={{className: classes.multiline}} />,
            copyableValue: JSON.stringify(log.inputs)
        })
    }

    return (
        <CopyableSettings header={lt('log', {index: log.logIndex})} {...{copyableList}} />
    )
}

interface translations {
    address: string,
    topics: string,
    data: string,
    log: string,
    event: string,
    back: string,
    next:string,
    inputs: string
}

const enTranslations: translations = {
    address: 'Address',
    topics: 'Topics',
    data: 'Data',
    log: 'Log ({{index}})',
    event: 'Event',
    inputs: 'Input',
    back: 'Back',
    next: 'Next'
}

const useStyles = makeStyles(theme => ({
    multiline: {
        color: theme.palette.text.secondary
    }
}))