import { useMutation } from '@apollo/client';
import { Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { FormDialog } from '../../components/DialogWrappers';
import { CreateCordaRoleData, CreateCordaRoleMutation, CreateCordaRoleVars } from '../../models';
import { MessageSnackbar } from '../DialogWrappers';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    afterSave: () => void,
}

export const AddCordaRoleDialog = ({ open, setOpen, afterSave }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AddCordaRoleDialog', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddCordaRoleDialog:${key}`, interpolate)

    const { consortium_id, environment_id, node_id } = useParams<any>();
    const nodeVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
        node_id: node_id!
    }

    const [role, setRole] = useState('');
    const [permissions, setPermissions] = useState('');
    const [okMessage, setOkMessage] = useState('');

    const [addCordaRole, { loading }] = useMutation<CreateCordaRoleData, CreateCordaRoleVars>(CreateCordaRoleMutation)

    useEffect(() => {
        if (open) {
            setRole('');
            setPermissions('');
        }
    }, [open])

    const onSave = async () => {
        await addCordaRole({
            variables: {
                ...nodeVars,
                role: {
                    role,
                    permissions: permissions.split(',').map(r => r.trim()),
                }
            }
        });
        afterSave();
        setOpen(false);
        setOkMessage(lt('createdOk', {role}));
    }

    const controlsWrapper = <>
        <Grid container direction="column" spacing={2}>
            <Grid item xs>
                <TextField data-test="textField_role"
                    label={lt('roleHelp')}
                    value={role}
                    onChange={event => setRole(event.target.value)}
                    autoFocus
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <TextField data-test="textField_permissions"
                    label={lt('permissionsHelp')}
                    value={permissions}
                    onChange={event => setPermissions(event.target.value)}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        </Grid>
    </>

    return (<>
        <MessageSnackbar messageType="success" message={okMessage} setMessage={setOkMessage}/>
        <FormDialog
            {...{open}} 
            {...{setOpen}} 
            header={lt('createRole')} 
            description={lt('createDescription')} 
            {...{controlsWrapper}}
            saveDisabled={loading || !role || !permissions}
            {...{onSave}}
            closeDialogAfterSave={false}
            saveText={lt('create')} />
    </>);
};

interface translations {
    createRole: string,
    createDescription: string,
    roleHelp: string,
    permissionsHelp: string,
    create: string,
    createdOk: string,
}
const enTranslations: translations = {
    createRole: 'Create a new Corda RPC role',
    createDescription: 'Set a role name and list of permissions',
    roleHelp: 'Enter the new role name',
    permissionsHelp: 'Enter a comma separated list of permissions',
    create: 'Create',
    createdOk: 'New role "{{role}}" created',
}