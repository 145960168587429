import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'

export const Step2Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEnvironmentCreateStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEnvironmentCreateStep2Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Environment-Protocol.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            finalInfo={lt('moreHelpDeciding')}
            docLink="https://kaleido.io/a-technical-analysis-of-ethereum-vs-fabric-vs-corda/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
    moreHelpDeciding: string,
}
const enTranslations: translations = {
    helpHeader: 'Blockchain Technology',
    helpDescription: "Whether you're a pioneer helping make the initial technology choice, or part of an established business network ready to accelerate the next phase of business value - at Kaleido we have what you need:",
    helpDesc1: 'PoC: Environments ready to run in minutes',
    helpDesc2: 'Pilot: Secure decentralized transactions at scale',
    helpDesc3: 'Production: Enterprise grade infra, key management, hybrid networking, ISO Certification, and SLAs',
    moreHelpDeciding: 'Need more help choosing?'
}
