import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { BrandColors } from '../../../utils/Colors';
import { Attachment } from './Attachment';
import { AttachmentsType } from './ContactUs';

interface Props {
    attachments: AttachmentsType;
    uploadFile: (file: File) => Promise<void>;
    onAttachmentDelete: (id: string, token?: string) => void;
}

export const Attachments = ({
    attachments,
    uploadFile,
    onAttachmentDelete,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Attachments', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`Attachments:${key}`, interpolate);

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event?.target?.files;
        if (files) {
            const fileToUpload = files[0];
            if (!fileToUpload) {
                return;
            }
            uploadFile(fileToUpload);
            // clean up to allow same file selection
            event.target.value = '';
        }
    };

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    let attachmentsToRender = [];
    for (const id in attachments) {
        const attachment = attachments[id];
        attachmentsToRender.push(
            <Attachment key={attachment.id} attachment={attachment} onDelete={onAttachmentDelete} />
        );
    }

    const classes = useStyles();
    return (
        <Grid container className={classes.attachmentArea}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.topContainer}
            >
                <Typography variant="body2">{lt('attachments')}</Typography>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleInputChange}
                    style={{ display: 'none' }}
                />
                <Button size="small" variant="outlined" onClick={handleClick}>
                    {lt('chooseFile')}
                </Button>
            </Grid>
            <Grid container direction="column">
                {attachmentsToRender}
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    attachmentArea: {
        backgroundColor: BrandColors.lightGrey,
        borderRadius: 3,
        padding: '20px 20px 15px 25px',
    },
    topContainer: {
        marginBottom: theme.spacing(1),
    },
}));

interface translations {
    attachments: string;
    chooseFile: string;
}

const enTranslations: translations = {
    attachments: 'Attachments',
    chooseFile: 'Choose File',
};
