import { useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import queryString from 'query-string';
import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { InfoBanner } from '../../components/Banners/InfoBanner';
import { CHANGE_PLAN_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { OrganizationData, OrganizationQuery, OrganizationVars } from '../../models';
import { capitalize } from '../../utils/StringUtils';

export const Step2Success = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateOrganizationsStep2Success', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateOrganizationsStep2Success:${key}`, interpolate)

    const { search } = useLocation();
    const history = useHistory();
    const { newOrg } = queryString.parse(search);
    const orgId = (Array.isArray(newOrg) && newOrg[0]) || (newOrg as string);

    const classes = useStyles();

    const {
        data: {
            organization
        } = { organization: undefined },
        loading,
    } = useQuery<OrganizationData, OrganizationVars>(OrganizationQuery, {
        skip: !orgId,
        variables: {
            id: orgId!
        },
        fetchPolicy: 'cache-only'
    });

    if (!newOrg || (!loading && !organization)) {
        return <Redirect to={`/orgs`}/>
    }

    if (!organization) {
        return <CircularProgress/>
    }

    return (
        <Grid container direction="column" spacing={3} alignContent="center" alignItems="center">
            <Grid item>
                <img src={`${process.env.PUBLIC_URL}/img/CreateWizards/Confirm-Upgrade.svg`} alt=""></img>
            </Grid>
            <Grid item>
                <Typography variant="h5">{lt('successfullyCreated', organization)}</Typography>
            </Grid>
            <Grid item>
                <Paper elevation={0} className={classes.paper}>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs>
                            <Typography variant="body2">
                                <Trans i18nKey="CreateOrganizationsStep2Success:currentlyOn" values={{plan: capitalize(organization.plan)}}
                                    components={[<Typography variant="body1" display="inline" component="span"/>]}>
                                </Trans>
                            </Typography>
                            <Typography variant="body2">
                                {lt('changeNow')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push(`/orgs/${organization._id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`)}>
                                {lt('changePlan')}
                            </Button>
                        </Grid>
                        {organization.plan.includes('partner') &&
                            <Grid item xs={12}>
                                <InfoBanner description={lt('partnerNotice')} />
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <Paper elevation={0} className={classes.paper}>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs>
                            <Typography variant="body2">
                                {lt('setupNetwork')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push(`/orgs/${organization._id}`)}>
                                {lt('viewNetwork')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
};

const useStyles = makeStyles(theme => ({
    paper: {
        width: '575px',
        padding: theme.spacing(3),
    }
}));

interface translations {
    successfullyCreated: string;
    plan: string,
    currentlyOn: string;
    changeNow: string;
    changePlan: string;
    setupNetwork: string;
    viewNetwork: string;
    partnerNotice: string;
}

const enTranslations: translations = {
    successfullyCreated: '{{name}} has been successfully created.',
    plan: '{{plan}} Plan',
    currentlyOn: 'This organization is currently on the <0>{{plan}} Plan</0>.',
    changeNow: 'You have the option to change plans now or later',
    changePlan: 'Change Plan',
    setupNetwork: 'You are ready to setup up your network and environment',
    viewNetwork: 'View Network',
    partnerNotice: 'Remember that partner plans cannot be used for paid customer projects',
}
