import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import { useQuery } from '@apollo/client';
import { EnvironmentResourceUsageData, EnvironmentResourceUsageQuery, EnvironmentResourceUsageVars } from '../../models'
import { DisplayGridWrapper } from '../../components/DisplayWrappers'
import CircularProgress from '@material-ui/core/CircularProgress';
import { ResourceChart } from '../../components/Charts/ResourceChart';
import { ENVIRONMENT_HEALTH_PATH } from '../../components/MainNav/SideNavs/HealthMonitoring';
import { LinkButtonProps } from '../../interfaces';

interface Props {
    allRuntimes: { _id: string, name: string }[]
};

export const CpuChart = ({ allRuntimes }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentDashboardCpuChart', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`EnvironmentDashboardCpuChart:${key}`, interpolate), [t])

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!
    }

    // query the resource usage stats
    const {
        loading: environmentResourceUsageLoading,
        data: {
            environmentResourceUsage
        } = { environmentResourceUsage: [] }
    } = useQuery<EnvironmentResourceUsageData, EnvironmentResourceUsageVars>(EnvironmentResourceUsageQuery, {
        variables: {
            ...environmentVars, 
            hours: 12,
            interval: 15
        },
        fetchPolicy: 'cache-and-network'
    });

    const wrapChartWithLoading = (chart: JSX.Element, header: string) => {
        const linkButton: LinkButtonProps = {
            text: lt('viewHealthData'),
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ENVIRONMENT_HEALTH_PATH}`)
        }

        if (environmentResourceUsageLoading && !environmentResourceUsage?.length) {
            return (
                <DisplayGridWrapper {...{linkButton}} hideDivider {...{header}} padDisplayGrid displayGrid={<CircularProgress />}/>
            )
        } else if (!environmentResourceUsage.length || (environmentResourceUsage.find(() => true)?.data.length ?? 0) <= 1) {
            const notEnoughData = <Typography variant="body2">{lt('notEnoughData')}</Typography>
            return (
                <DisplayGridWrapper {...{linkButton}}{...{header}} padDisplayGrid displayGrid={notEnoughData}/>
            )
        }   

        const displayGrid = (
            <Grid item>
                {chart}
            </Grid>
        )
        return (
            <DisplayGridWrapper {...{linkButton}} hideDivider {...{header}} {...{displayGrid}} />
        )
    }
                
    return wrapChartWithLoading(<ResourceChart {...{allRuntimes}} runtimesForChart={environmentResourceUsage} chart='cpu' />, lt('cpu'))
};

interface translations {
    cpu: string,
    notEnoughData: string,
    viewHealthData: string
}
const enTranslations: translations = {
    cpu: 'CPU Utilization',
    notEnoughData: 'This environment has not been running long enough to collect any metrics data. Try again later.',
    viewHealthData: 'View Health & Monitoring'
}