import { useApolloClient } from '@apollo/client';
import { Button } from "@material-ui/core";
import BullseyeIcon from 'mdi-react/BullseyeIcon';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import ClipboardCheckOutlineIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import MapOutlineIcon from 'mdi-react/MapOutlineIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { VoteInRuntime } from '../../components/DialogWrappers/VoteInRuntime';
import { VoteOutRuntime } from '../../components/DialogWrappers/VoteOutRuntime';
import { DisplayCard } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { EnvironmentZone, Node, OrganizationData, OrganizationQuery, OrganizationVars, PlanSupports, RuntimeSizeTranslation, EnRuntimeSizeTranslation } from '../../models';
import { capitalize } from '../../utils/StringUtils';

interface Props {
    node: Node
    environmentZones: EnvironmentZone[]
    showNodeTypeLabel?: Boolean
};

export const NodeCard = ({ node, environmentZones, showNodeTypeLabel = false }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BlockchainDashboardNodeCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BlockchainDashboardNodeCard:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>()

    const envZone = environmentZones.find(z => z._id === node.zone_id)

    const [voteInDialogOpen, setVoteInDialogOpen] = useState(false);
    const [voteOutDialogOpen, setVoteOutDialogOpen] = useState(false);

    const client = useApolloClient();
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!

    const canVote = PlanSupports.signerNodes(organization) && node.isEthereum && node.consensus_type !== 'raft';
    const canVoteIn = canVote &&  node.init_consensus_role === 'non-signer';
    const canVoteOut = canVote &&  node.init_consensus_role === 'signer';

    const getVoteInOutAction = () => {
        if (canVoteIn) {
            return <Button color="primary" variant="contained" size="small" onClick={() => setVoteInDialogOpen(true)}>
                        {lt('change')}
                    </Button>
        } else if(canVoteOut){
            return <Button color="primary" variant="contained" size="small" onClick={() => setVoteOutDialogOpen(true)}>
                        {lt('change')}
                    </Button>
        }
        return null
    }

    const itemList = [
        {
            title: lt('status'),
            value: <ResourceStateChip state={node.state} />,
            icon: <ChartLineIcon />
        },
        {
            title: lt('size'),
            value: lt(node.size),
            icon: <BullseyeIcon />
        },
        {
            title: lt('region'),
            value: envZone?.displayName,
            icon: <MapOutlineIcon />
        },
        {
            title: lt('consensusRole'),
            value: capitalize(node.init_consensus_role),
            icon: <ClipboardCheckOutlineIcon />,
            actionIcon: ( node.state === "started" && node.role === "monitor" ) ? getVoteInOutAction() : null
        }
    ]

    const linkButton = node.role !== 'monitor' ? {
        text: lt('viewNode'),
        onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/nodes/${node._id}`)
    } : undefined
    
    return (
        <>
        { canVoteIn && <VoteInRuntime {...{node}} open={voteInDialogOpen} setOpen={setVoteInDialogOpen}/>}
        { canVoteOut && <VoteOutRuntime {...{node}} open={voteOutDialogOpen} setOpen={setVoteOutDialogOpen}/>}
        <DisplayCard {...{linkButton}}
            header={showNodeTypeLabel ? lt('blockchainNode') : node.name}
            {...{itemList}} />
        </>
    )
};

interface translations extends RuntimeSizeTranslation {
    status: string
    size: string
    region: string
    consensusRole: string
    viewNode: string
    change: string
    blockchainNode: string
}
const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    status: 'Status',
    size: 'Size',
    region: 'Region',
    consensusRole: 'Consensus role',
    viewNode: 'View Node',
    change: 'Change',
    blockchainNode: 'Blockchain Node'
}