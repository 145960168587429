import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Config } from '../../models/configs';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { useLazyQuery } from '@apollo/client';
import { NodeStatusData, NodeStatusQuery, Node } from '../../models';
import { EnvironmentResourceVars } from '../../interfaces';
import { useParams } from 'react-router-dom';

interface Props {
    configuration: Config,
    node: Node
}


export const ConfigStatus = ({configuration, node}: Props) => {

    const [isApplied, setIsApplied] = useState(false);
    const [isRestarting, setIsRestarting] = useState(false);

    const { environment_id, consortium_id } = useParams<any>();

    const queryVariables = {
        environment_id: environment_id!,
        consortia_id: consortium_id!,
        id: node._id
    }

    const [getNodeStatus, {
        loading,
        data: {
            nodeStatus
        } = {nodeStatus: null}
    }] = useLazyQuery<NodeStatusData, EnvironmentResourceVars>(NodeStatusQuery, {
        variables: {
            ...queryVariables,
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if(node.state === 'started') {
            setIsRestarting(false);
            getNodeStatus()
        } else {
            setIsRestarting(true);
        }
    }, [node, getNodeStatus]);

    useEffect(() => {
        if (configuration.type === 'backup' || configuration.type === 'storage' || configuration.type === 'cloudhsm') {
            setIsApplied(true);
        } else if (nodeStatus?.configVersions) {
            if(nodeStatus.configVersions[configuration.type]?.rev === configuration._revision && nodeStatus.configVersions[configuration.type]?.id === configuration._id  ){
                setIsApplied(true);
            } 
        }
    }, [nodeStatus, configuration, node]);

    const status = () => {
        if (isRestarting) return <ResourceStateChip state={node.state} />;
        return isApplied ? <ResourceStateChip state='applied' /> : <ResourceStateChip state='notApplied' />
    }

    return (
        <Grid container wrap="nowrap" spacing={1} alignItems="center">
            <Grid item>
                {!loading && status()}
            </Grid>
            <Grid item>
                <Typography color="textPrimary" variant="body1">
                    {configuration.name}
                </Typography>
            </Grid>
        </Grid>
    )
}