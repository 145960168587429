import { gql } from '@apollo/client';

export interface FeatureToggles {
    tunneler?: boolean
    corda?: boolean
    autonity?: boolean
    keyManagement?: boolean
    docExchange?: boolean
    app2app: boolean
    baf?: boolean
    ssoGoogle?: boolean
    mfa: boolean
    nodeConfigs?: boolean
    newOrg?: boolean
    ipfs?: boolean
    chainlink?: boolean
    idregistry?: boolean
    fabricChannels?: boolean
    tether?: boolean
    firefly?: boolean
    fabricFirefly?: boolean
    removeChannelMember?: boolean
    externalCA?: boolean
}

export interface FeatureTogglesData {
    featureToggles: FeatureToggles
}

export interface FeatureTogglesVars { }

export const FeatureTogglessFields = ` 
    fragment FeatureTogglessFields on FeatureToggles {
        corda
        tunneler
        autonity
        keyManagement
        docExchange
        app2app
        baf
        ssoGoogle
        mfa
        nodeConfigs
        newOrg
        ipfs
        chainlink
        idregistry
        fabricChannels
        tether
        firefly
        fabricFirefly
        removeChannelMember
        externalCA
    }`

export const FeatureTogglesQuery = gql`
    ${FeatureTogglessFields}
    query getFeatureToggles {
        featureToggles {
            ...FeatureTogglessFields
        }
    }`;
