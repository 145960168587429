import { useQuery } from '@apollo/client';
import ArrowUpDownIcon from 'mdi-react/ArrowUpDownIcon';
import CloudDownloadOutlineIcon from 'mdi-react/CloudDownloadOutlineIcon';
import CloudUploadOutlineIcon from 'mdi-react/CloudUploadOutlineIcon';
import DatabaseIcon from 'mdi-react/DatabaseIcon';
import EarthIcon from 'mdi-react/EarthIcon';
import FileDocumentOutlineIcon from 'mdi-react/FileDocumentOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import SendIcon from 'mdi-react/SendIcon';
import SetSplitIcon from 'mdi-react/SetSplitIcon';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Kpi } from '../../../components/DisplayWrappers';
import { EnvironmentResourceVars } from '../../../interfaces';
import { AllDocumentStoreMetricsData, AllDocumentStoreMetricsQuery, AllDocumentStoreMetricsVars, DestinationsData, DestinationsQuery, DestinationsVars, DocumentStoreConfigData, DocumentStoreConfigQuery, ServiceData, ServiceQuery, DocumentStoreProviderTranslations, EnDocumentStoreProviderTranslations, RuntimeSizeTranslation, EnRuntimeSizeTranslation } from '../../../models';
import { capitalize } from '../../../utils/StringUtils';
import { KpiData } from '../../DisplayWrappers/Kpi';

interface Props {
    allRuntimes: { _id: string, name: string }[],
    forDashboard?: boolean // for the docstore dashboard format
}

export const DocumentsKPI = ({ allRuntimes, forDashboard } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsDocumentStoreDocumentsKPI', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChartsDocumentStoreDocumentsKPI:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!
    }

    // kpi is always based on the current month / year
    const thisMonth = moment().month() + 1
    const thisYear = moment().year()

    const {
        data: {
            allDocumentStoreMetrics
        } = { allDocumentStoreMetrics: [] },
    } = useQuery<AllDocumentStoreMetricsData, AllDocumentStoreMetricsVars>(AllDocumentStoreMetricsQuery, {
        variables: {
            ...environmentVars, 
            ids: allRuntimes.map(r => r._id),
            month: thisMonth,
            year: thisYear
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: forDashboard ? 20000 : undefined
    });

    const { data: { service } = { service: null } } = useQuery<
        ServiceData,
        EnvironmentResourceVars
    >(ServiceQuery, {
        variables: {
            ...environmentVars,
            id: allRuntimes.find(() => true)?._id ?? '',
        },
        fetchPolicy: "cache-only",
        skip: !forDashboard
    });

    const {
        data: {
            destinations
        } = { destinations: [] }
    } = useQuery<DestinationsData, DestinationsVars>(DestinationsQuery, {
        variables: { 
            service_id: allRuntimes.find(() => true)?._id ?? '' 
        },
        fetchPolicy: 'cache-and-network',
        skip: !forDashboard
    });

    const {
        data: { documentStoreConfig } = { documentStoreConfig: null },
    } = useQuery<DocumentStoreConfigData>(DocumentStoreConfigQuery, {
        variables: {
            service_id: allRuntimes.find(() => true)?._id ?? ''
        },
        fetchPolicy: 'cache-and-network',
        skip: !forDashboard || service?.state !== 'started',
    });

    let totalDocs = 0
    let outboundTransfers = 0
    let kbSent = 0
    let kbReceived = 0
    let chunksSent = 0
    let chunksReceived = 0

    allDocumentStoreMetrics.forEach(d => {
        d.metrics.forEach(m => {
            totalDocs += m.documents_in_kaleido_storage
            outboundTransfers += m.sent_status_success
            kbSent += (m.bytes_sent / 1000)
            kbReceived += (m.bytes_received / 1000)
            chunksSent += m.chunks_sent
            chunksReceived += m.chunks_received
        })
    })

    const kpiData: KpiData[] = []

    if (!forDashboard || documentStoreConfig?.provider === 'internal') {
        kpiData.push({
            key: 'kpi-docs',
            value: totalDocs,
            label: lt('totalDocs'),
            icon: <FileDocumentOutlineIcon/>
        })
    }

    if (forDashboard) {
        kpiData.push(
            {
                key: 'kpi-transfers',
                value: outboundTransfers,
                label: lt('outboundTransfers'),
                icon: <SendIcon/>
            },
            {
                key: 'kpi-destinations',
                value: destinations.length,
                label: lt('destinations'),
                icon: <MapMarkerOutlineIcon/>
            },
            {
                key: 'kpi-size',
                value: service ? lt(service.size) : '',
                label: lt('size'),
                icon: <ArrowUpDownIcon/>
            },
            {
                key: 'kpi-storage',
                value: documentStoreConfig?.provider ? lt(documentStoreConfig.provider) : '',
                label: lt('storage'),
                icon: <DatabaseIcon/>
            }
        )

        if (documentStoreConfig?.region) {
            kpiData.push(
                {
                    key: 'kpi-region',
                    value: capitalize(documentStoreConfig?.region),
                    label: lt('region'),
                    icon: <EarthIcon/>
                }
            )
        }
    } else {
        kpiData.push(
            {
                key: 'kpi-kbsent',
                value: kbSent.toFixed(2),
                label: lt('kbSent'),
                icon: <CloudUploadOutlineIcon/>
            },
            {
                key: 'kpi-kbreceived',
                value: kbReceived.toFixed(2),
                label: lt('kbReceived'),
                icon: <CloudDownloadOutlineIcon/>
            },
            {
                key: 'kpi-chunkssent',
                value: chunksSent,
                label: lt('chunksSent'),
                icon: <SetSplitIcon/>
            },
            {
                key: 'kpi-chunksreceived',
                value: chunksReceived,
                label: lt('chunksReceived'),
                icon: <SetSplitIcon/>
            },
        );
    }

    return <Kpi data={kpiData} />;
};

interface translations extends DocumentStoreProviderTranslations, RuntimeSizeTranslation {
    totalDocs: string,
    kbSent: string,
    kbReceived: string,
    chunksSent: string,
    chunksReceived: string,
    outboundTransfers: string,
    destinations: string,
    size: string,
    storage: string,
    region: string
}
const enTranslations: translations = {
    ...EnDocumentStoreProviderTranslations,
    ...EnRuntimeSizeTranslation,
    totalDocs: 'Documents',
    kbSent: 'KB Sent',
    kbReceived: 'KB Received',
    chunksSent: 'Chunks Sent',
    chunksReceived: 'Chunks Received',
    outboundTransfers: 'Outbound Transfers',
    destinations: 'Destinations',
    size: 'Runtime Size',
    storage: 'Storage',
    region: 'Region'
}