// Password rules as implemented on the cordaapi side
export const isStrongPassword = (password: string) => {
    let lower = 0;
    let upper = 0;
    let number = 0;
    let other = 0;
    for (let i = 0; i < password.length; i++) {
      const c = password.charAt(i);
      if (/[A-Z]/.test(c)) upper = 1;
      else if (/[a-z]/.test(c)) lower = 1;
      else if (/[0-9]/.test(c)) number = 1;
      else other = 1;
    }
    return password.length >= 8 && (lower + upper + number + other) >= 3;
}