import React, { useState, useEffect } from 'react';
import { 
    Drawer,
    makeStyles,
    Hidden,
    CircularProgress,
    List,
    ListItem,
    Toolbar,
    Grid,
    Divider,
    AppBar,
} from '@material-ui/core';
import { useParams, useLocation, Redirect } from "react-router-dom";
import { MainNav } from '../MainNav'
import { NodeNav } from '../NodeNav'
import { MembershipNav } from '../MembershipNav'
import { ServicesNav } from '../ServicesNav'
import { ChannelNav } from '../ChannelNav'
import { CertificateAuthorityNav } from '../CertificateAuthorityNav';
import { BlockchainServices, B2bServices, ManagedWalletServices, CloudWalletServices,  } from '../../models/services'
import { OpsOKQuery, OpsOKData } from '../../models'
import { B2B_BASE_PATH } from '../MainNav/SideNavs/B2bCommunication'
import { BLOCKEXPLORER_PATH, BlockExplorerNav } from '../BlockExplorerNav/BlockExplorerNav'
import { CHANNELS_PATH, ADDRESSBOOK_PATH } from '../MainNav/SideNavs/AddressBook';
import { useQuery } from '@apollo/client';
import { ConsortiaData, ConsortiaQuery } from '../../models';
import { BlueDrawer } from './BlueDrawer'
import { BLUE_DRAWER_WIDTH, DRAWER_WIDTH, TOOLBAR_HEIGHT, KALEIDO_LOGO } from './LayoutConstants'
import { ProfileSettingsNav } from '../ProfileSettingsNav';
import { PROFILE_SETTINGS_PATH } from '../ProfileSettingsNav/ProfileSettingsNav';
import { ManageOrgNav } from '../ManageOrgNav';
import { OpsNav } from '../OpsNav';
import { TOKENEXPLORER_PATH, TokenExplorerNav } from '../TokenExplorerNav/TokenExplorerNav';
import { KEY_MANAGEMENT_MANAGED_PATH, KEY_MANAGEMENT_CLOUD_PATH } from '../MainNav/SideNavs/KeyManagement';
import { BLOCKCHAIN_BASE_PATH, BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH } from '../MainNav/SideNavs/Blockchain';
import { OpsOrgNav } from '../OpsOrgNav';

interface Props {
    canRender: () => boolean,
    setConsortiumQueriesComplete: React.Dispatch<React.SetStateAction<boolean>>,
    setEnvironmentQueriesComplete: React.Dispatch<React.SetStateAction<boolean>>,
    isOpen: boolean | undefined,
    onClose: () => void,
    refreshingSession: boolean
};

const AppNav = ({ canRender, setConsortiumQueriesComplete, setEnvironmentQueriesComplete, isOpen, onClose, refreshingSession }: Props) => {

    const classes = useStyles();
    const [redirectHome, setRedirectHome] = useState(false);

    const { consortium_id, node_id, service_id, membership_id, environment_id, ops_org_id, channel_id } = useParams<any>();
    const { pathname } = useLocation();

    const {
        data: { 
            consortia
        } = { consortia: [] } 
    } = useQuery<ConsortiaData>(ConsortiaQuery, { fetchPolicy: 'cache-only'} );

    const consortium = consortia.find(c => c._id === consortium_id)

    const [cachedEnvironmentId, setCachedEnvironmentId] = useState(environment_id || '')

    const showProfileSettingsNav = pathname.startsWith(`/${PROFILE_SETTINGS_PATH}`);

    const showManageOrgNav = /\/orgs\/[a-z0-9]{10}\/manage/.test(pathname);

    const showOpsNav = /^\/konsole/.test(pathname);

    const {
        data: { opsOk = false } = {},
        loading: opsLoading,
    } = useQuery<OpsOKData>(OpsOKQuery, {
        skip: !showOpsNav
    });

    useEffect(() => {
        if (showOpsNav && !opsLoading && !opsOk) setRedirectHome(true);
    }, [showOpsNav, opsOk, opsLoading])

    if (redirectHome) {
        return <Redirect to='/'/>;
    }

    // if we dont have a consortium and we are not showing list of orgs or profile settings, then we only have a blueDrawer
    if (!consortium && !showProfileSettingsNav && !showManageOrgNav && !showOpsNav) {
        return (
            <>
                <Hidden smDown implementation="js">
                    <BlueDrawer variant="permanent" />
                </Hidden>
                <Hidden mdUp implementation="js">
                    <BlueDrawer variant="persistent" isOpen={isOpen} onClose={onClose} />
                </Hidden>
            </>
        )
    }

    const showNodeNav = node_id ? true : false
    const showMembershipNav = membership_id && pathname.includes(ADDRESSBOOK_PATH) ? true : false
    const showOpsOrgNav = ops_org_id ? true : false;
    const showChannelNav = channel_id && pathname.includes(CHANNELS_PATH) ? true : false
    const showCertificateAuthorityNav = service_id && pathname.includes(BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH) ? true : false

    const checkServicePath = (serviceNames: Set<string>, bundleBasePath?: string): boolean => {
        let isServicePath = false
        serviceNames.forEach(s => 
            isServicePath = isServicePath || pathname.includes(`${bundleBasePath ? `/${bundleBasePath}` : ''}/${s}/${service_id}`))
        return isServicePath
    }

    const showServicesNav = 
        checkServicePath(BlockchainServices) ||
        checkServicePath(B2bServices, B2B_BASE_PATH) ||
        checkServicePath(CloudWalletServices, KEY_MANAGEMENT_CLOUD_PATH) ||
        checkServicePath(ManagedWalletServices, KEY_MANAGEMENT_MANAGED_PATH) || 
        checkServicePath(BlockchainServices, BLOCKCHAIN_BASE_PATH )

    const showBlockExplorerNav = pathname.includes(`${environment_id}/${BLOCKEXPLORER_PATH}`);
    const showTokenExplorerNav = pathname.includes(`${environment_id}/${TOKENEXPLORER_PATH}`)

    const hide = showNodeNav || showMembershipNav || showChannelNav || showServicesNav || showCertificateAuthorityNav || showBlockExplorerNav || showTokenExplorerNav || showProfileSettingsNav || showManageOrgNav || showOpsOrgNav


    const navSpinner = (
        <div>
            <List>
                <ListItem>
                    <CircularProgress />
                </ListItem>
            </List>
        </div>
    )

    const nodeNav = showNodeNav ? (canRender() ? <NodeNav /> : navSpinner) : null
    const membershipNav = showMembershipNav? (canRender() ? <MembershipNav /> : navSpinner) : null
    const servicesNav = showServicesNav ? (canRender() ? <ServicesNav /> : navSpinner) : null
    const blockExplorerNav = showBlockExplorerNav ? (canRender() ? <BlockExplorerNav /> : navSpinner) : null
    const profileSettingsNav = showProfileSettingsNav ? (canRender() ? <ProfileSettingsNav /> : navSpinner) : null
    const manageOrgNav = showManageOrgNav ? (canRender() ? <ManageOrgNav /> : navSpinner) : null
    const tokenExplorerNav = showTokenExplorerNav ? (canRender() ? <TokenExplorerNav /> : navSpinner) : null
    const opsNav = !showOpsOrgNav && showOpsNav ? (canRender() ? <OpsNav /> : navSpinner) : null
    const opsOrgNav = showOpsOrgNav ? (canRender() ? <OpsOrgNav /> : navSpinner) : null
    const channelNav = showChannelNav? (canRender() ? <ChannelNav /> : navSpinner) : null
    const certificateAuthorityNav = showCertificateAuthorityNav? (canRender() ? <CertificateAuthorityNav /> : navSpinner) : null

    const makeMainDrawer = (variant: 'permanent' | 'persistent') => (
        <>
            <BlueDrawer variant={variant} isOpen={isOpen} onClose={onClose} {...{cachedEnvironmentId}} />

            <AppBar position="absolute" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolBar} disableGutters>
                    <Grid container alignItems="flex-start">
                        <Grid item>
                            <img src={process.env.PUBLIC_URL + '/Kaleido-Logo-Horizontal-Primary.svg'} alt="" className={classes.kaleidoLogo}></img>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Divider />
            </AppBar>

            <div className={classes.toolbarSpacing} />
            
            {/* MainNav handles displaying spinners on part of its left nav that it shouldnt show yet */}
            {consortium &&
                <MainNav refreshingSession={refreshingSession} {...{cachedEnvironmentId}} {...{setCachedEnvironmentId}} {...{setConsortiumQueriesComplete}} {...{setEnvironmentQueriesComplete}} {...{hide}} />
            }
            
            { nodeNav }
            { membershipNav }
            { channelNav }
            { certificateAuthorityNav }
            { servicesNav }
            { blockExplorerNav }
            { profileSettingsNav }
            { manageOrgNav }
            { tokenExplorerNav }
            { opsNav }
            { opsOrgNav }
        </>
    )

    return (
        <>
            <Hidden smDown implementation="js">
                <Drawer data-test="leftNav"
                        classes={{
                            paper: classes.desktopDrawer
                        }}
                        variant='permanent'
                        className={classes.mobileDrawer}
                >
                    {makeMainDrawer('permanent')}
                </Drawer>
            </Hidden>
            <Hidden mdUp implementation="js">
                <Drawer ModalProps={{ keepMounted: true }} // so we dont lose the subscriptions
                        open={isOpen}
                        variant='persistent'
                        classes={{
                            paper: classes.desktopDrawer
                        }}
                        transitionDuration={0} // disable transition since having a drawer within a drawer transitions weirdly
                    >
                    {makeMainDrawer('persistent')}
                </Drawer>
            </Hidden>
        </>
    )   
};

const useStyles = makeStyles( theme => ({
    mobileDrawer: {
        width: DRAWER_WIDTH,
        marginLeft: BLUE_DRAWER_WIDTH
    },
    toolBar: {
        height: TOOLBAR_HEIGHT
    },
    toolbarSpacing: {
        [theme.breakpoints.down('sm')]: theme.mixins.toolbar
    },
    appBar: {
        backgroundColor: theme.palette.background.paper
    },
    desktopDrawer: {
        width: DRAWER_WIDTH,
        marginLeft: BLUE_DRAWER_WIDTH
    },
    kaleidoLogo: KALEIDO_LOGO,
}));

export { AppNav };

