import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, Grid, Typography, Button, makeStyles } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { BillingProviderData, BillingProviderQuery } from '../../models/billing';
import { useParams, useHistory } from 'react-router-dom';
import { ORGS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_PAYMENT_METHOD_PATH, SETUP_BILLING_PROVIDER_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { DisplayCard } from '../../components/DisplayWrappers';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import CreditCardOutlineIcon from 'mdi-react/CreditCardOutlineIcon';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import { FormLink } from '../../components/FormControls/FormLink';
import { HelpCenter, HelpCenterTab } from '../../components/HelpCenter/HelpCenter';

export const ManageOrgPaymentMethod: React.FC = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ManageOrgPaymentMethod', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ManageOrgPaymentMethod:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const history = useHistory();
    const [helpCenter, setHelpCenter] = useState(false);

    const {
        loading,
        data: {
            billingProvider
        } = { billingProvider: undefined }
    } = useQuery<BillingProviderData>(BillingProviderQuery, { variables: { org_id } });

    if (loading || !billingProvider) {
        return <CircularProgress />
    }

    const getPaymentTypeLabel = () => {
        switch (billingProvider?.type) {
            case 'aws':
            case 'azure':
            case 'stripe': return lt('payAsYouGo');
            case 'contract':
            case 'other': return lt('contract');
        }
        return lt('none');
    };

    const getPaymentMethodLabel = () => {
        switch (billingProvider.type) {
            case 'stripe':
                return <>
                    <Typography variant="body2" className={classes.creditCard}>
                        {lt('creditCard', { last4: billingProvider.last4, expMonth: billingProvider.exp_month, expYear: billingProvider.exp_year })}
                    </Typography>
                    <FormLink to={`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}/${SETUP_BILLING_PROVIDER_PATH}/2`}>{lt('change')}</FormLink>
                </>;
            case 'aws': return lt('aws');
            case 'azure': return lt('azure');
            case 'contract':
            case 'other': return lt('notAvailable');
        }
        return lt('none');
    };

    const getPaymentPeriodLabel = () => {
        if (billingProvider.type === 'none') {
            return lt('none');
        } else {
            return lt('endOfMonth');
        }
    };

    const billingProviderInformation: DisplayCardListItem[] = [
        {
            icon: <ViewGridIcon />,
            title: lt('type'),
            value: getPaymentTypeLabel()
        },
        {
            icon: <CreditCardOutlineIcon />,
            title: lt('paymentMethod'),
            value: getPaymentMethodLabel(),
        },
        {
            icon: <CalendarIcon />,
            title: lt('paymentPeriod'),
            value: getPaymentPeriodLabel()
        }
    ];

    const documentationList: DisplayCardListItem[] = [
        {
            icon: <DescriptionIcon />,
            title: lt('billingProviders'),
            value: lt('subscriptionRequiredInformation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/billing-provider/')
        }
    ];

    return (
        <>
            <Grid container spacing={3} direction="column">
                <Grid item container justify="space-between">
                    <Grid item>
                        <Typography variant="h5">{lt('paymentMethod')}</Typography>
                    </Grid>
                    <Grid item>
                        {billingProvider.type === 'none' &&
                            <Button variant="contained" color="primary"
                                onClick={() => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}/${SETUP_BILLING_PROVIDER_PATH}/1`)}>
                                {lt('setupBillingProvider')}
                            </Button>}
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item md={8}>
                        <DisplayCard header={lt('paymentMethod')} itemList={billingProviderInformation} leftAlignActionFooter={true}
                            actionFooter={<Typography variant="body2">
                                <Trans i18nKey="ManageOrgPaymentMethod:contactDescription"
                                    components={[<FormLink onClick={() => setHelpCenter(true)} />]}>
                                </Trans>
                            </Typography>} />
                    </Grid>
                    <Grid item md={4}>
                        <DisplayCard header={lt('documentation')} itemList={documentationList} />
                    </Grid>
                </Grid>
            </Grid>
            <HelpCenter
                isOpen={helpCenter}
                setOpen={setHelpCenter}
                tab={HelpCenterTab.CONTACT_US}
            />
        </>
    );
}

const useStyles = makeStyles(theme => ({
    creditCard: {
        marginRight: 10,
        display: 'inline-block'
    }
}));

interface translations {
    paymentMethod: string
    setupBillingProvider: string
    documentation: string
    billingProviders: string
    subscriptionRequiredInformation: string
    type: string
    paymentPeriod: string
    none: string
    contactDescription: string
    contract: string
    payAsYouGo: string
    creditCard: string
    endOfMonth: string
    change: string
    aws: string
    azure: string
    notAvailable: string
}

const enTranslations: translations = {
    paymentMethod: 'Payment Method',
    setupBillingProvider: 'Setup Billing Provider',
    documentation: 'Documentation',
    billingProviders: 'Billing Providers',
    subscriptionRequiredInformation: 'Subscription Required Information',
    type: 'Type',
    paymentPeriod: 'Payment Period',
    none: 'None',
    contactDescription: 'To change your payment type, please <0>contact us</0>.',
    contract: 'Contract',
    payAsYouGo: 'Pay As You Go',
    creditCard: 'Credit Card ({{last4}}) Exp {{expMonth}}/{{expYear}}',
    endOfMonth: 'End of Month',
    change: 'Change',
    aws: 'AWS',
    azure: 'Azure',
    notAvailable: 'N/A'
}
