import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { EnvironmentResourceVars } from '../../interfaces';
import { NodeData, NodeQuery } from '../../models';
import { EventStreamsData, EventStreamsQuery, EventStreamSubscriptionsData, EventStreamSubscriptionsQuery } from '../../models/eventStreams';
import { EventStreamsList } from '../../components/EventStreams/EventStreamsList';

export const NodeEventStreams = () => {
    const { consortium_id, environment_id, node_id } = useParams<{org_id: string, consortium_id: string, environment_id: string, node_id: string}>();

    const {
        data: { 
            node
        } = { node: null } 
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: { 
            consortia_id: consortium_id,
            environment_id: environment_id,
            id: node_id
        },
        fetchPolicy: 'cache-only'
    });

    const {
        loading: eventStreamsLoading,
        data: {
            eventStreams
        } = { eventStreams: [] }
    } = useQuery<EventStreamsData>(EventStreamsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_id: node_id
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        loading: eventStreamSubscriptionsLoading,
        data: {
            eventStreamSubscriptions
        } = { eventStreamSubscriptions: [] }
    } = useQuery<EventStreamSubscriptionsData>(EventStreamSubscriptionsQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            node_id: node_id
        },
        fetchPolicy: 'cache-and-network'
    });

    return <EventStreamsList nodes={[node!]} {...{eventStreams}} {...{eventStreamsLoading}} {...{eventStreamSubscriptionsLoading}} {...{eventStreamSubscriptions}} />
};

