import React, { useState } from 'react';
import { Typography, makeStyles, TableHead,
    TableContainer, Table, TableBody, TableCell, TableRow, Grid, IconButton, Tab, Tabs } from "@material-ui/core";
import SearchIcon from 'mdi-react/SearchIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { SearchBar, SearchType } from '../FormControls/SearchBar';
import { DisplayGridWrapper } from './DisplayGridWrapper'
import { DisplayTableRow, DisplayTableRecord } from './DisplayTableRow'
import { CircularProgress } from '@material-ui/core';
import { LinkButtonProps } from '../../interfaces';
import {
    bindTrigger, usePopupState
} from 'material-ui-popup-state/hooks';
import Filter1Icon from 'mdi-react/FilterListIcon';
import { FilterPopOver, FilterOptions } from '../FilterPopOver/FilterPopOver';

export interface ToolBarOptions {
    search?: {
        onSearch: (searchInput: string) => Promise<void> | void,
        setSearchActive?: (value: React.SetStateAction<boolean>) => void,
        label?: string
        searchType?: SearchType
    },
    refresh?: {
        onRefresh: (() => Promise<void> ) | (() => void)
    },
    filter?: FilterOptions,
    headerTab?: { //Tabs to be used as header
        selected: number
        onChange?: (event: React.ChangeEvent<{}>, newValue: number) => void
        tabs: {
            disabled?: boolean
            label: string
            action?: () => void
        }[]
    }
}

export interface Props {
    loading?: boolean,
    columnHeaders?: string[],
    records?: DisplayTableRecord[],
    preBuiltRows?: JSX.Element | JSX.Element[],
    actionBar?: JSX.Element, 
    linkButton?: LinkButtonProps,
    header?: string | JSX.Element, //optional param to put the header and wrap with a Paper and Grid, otherwise just the table body Grid item is rendered
    description?: string,
    actionFooter?: JSX.Element,
    cellSize?: "small" | "medium" | undefined,
    stickyHeader?: boolean,
    toolBarOptions?: ToolBarOptions,
    maxHeight?: string,
    height?: string //Forced height
    hideFooterDivider?: boolean,
    emptyLabel?: string,
    hideEmptyIcon?: boolean
};

export const DisplayTable = ({ loading, header, description, toolBarOptions, columnHeaders, height, emptyLabel, hideEmptyIcon = false,
    records, actionBar, linkButton, actionFooter, preBuiltRows, stickyHeader, maxHeight, hideFooterDivider }: Props) => {

    const classes = useStyles();

    const [searchOpen, setSearchOpen] = useState(false);

    const popupState = usePopupState({
        variant: 'popover',
        popupId: null,
    })

    const toolsIcons = (
        <Grid container alignItems="center" wrap="nowrap" spacing={1}>
            {toolBarOptions?.search && (
                <Grid item>
                    <IconButton size="small" onClick={() => setSearchOpen(c => !c)} >
                        <SearchIcon />
                    </IconButton>
                </Grid>
            )}
            {toolBarOptions?.refresh && (
                <Grid item>
                    <IconButton size="small" onClick={() => toolBarOptions.refresh?.onRefresh()}>
                        <RefreshIcon />
                    </IconButton>
                </Grid>
            )}
            {toolBarOptions?.filter && (
                <Grid item>
                    <IconButton size="small" {...bindTrigger(popupState)}>
                        <Filter1Icon />
                    </IconButton>
                </Grid>
            )}
            {actionBar && (
                <Grid item>
                    {actionBar}
                </Grid>
            )}
        </Grid>
    );

    const toolBar = (
        <Grid item container justify="space-between" className={!toolBarOptions?.headerTab ? classes.headerBar : classes.headerTab} alignItems="center" wrap="nowrap">
            <Grid item container wrap="nowrap" xs={searchOpen && 6} className={classes.headerContainer} alignItems="center">
                {searchOpen && toolBarOptions?.search?.onSearch && toolBarOptions.search.setSearchActive ? 
                    <SearchBar 
                        onSubmit={toolBarOptions.search.onSearch}
                        searchType={toolBarOptions.search.searchType}
                        isOpen={true}
                        setIsOpen={setSearchOpen}
                        setSearchActive={toolBarOptions.search.setSearchActive}
                        label={toolBarOptions.search.label}         
                    /> : toolBarOptions?.headerTab ? 
                            <Tabs value={toolBarOptions.headerTab.selected} onChange={toolBarOptions.headerTab.onChange} indicatorColor="primary" textColor="primary">
                                {toolBarOptions.headerTab.tabs.map(tab => (
                                    <Tab key={`tab-${tab.label}`} label={tab.label} disabled={tab.disabled} />
                                ))}
                            </Tabs>
                        :
                            <Typography variant="h6" noWrap>
                                {header}
                            </Typography>
            }    
            </Grid>
            <Grid item>
                {toolsIcons}
            </Grid>
        </Grid>
    );

    const EmptyDisplay = (
        <Grid container className={classes.emptyContainer} justify="center" alignItems="center" direction="column" spacing={2} wrap="nowrap">
            {!hideEmptyIcon &&           
                <Grid item>
                    <img className={classes.emptyIcon} src={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-NoResults.svg`} alt=""></img>
                </Grid>}
            <Grid item>
                <Typography variant="h6">{emptyLabel}</Typography>
            </Grid>
        </Grid>
    )

    const displayGrid = 
        <>
            {toolBarOptions?.filter && <FilterPopOver filterOptions={toolBarOptions.filter} {...{popupState}} /> }
            <Grid item container>
                <TableContainer style={{maxHeight, height}}>
                    { emptyLabel && !preBuiltRows && records?.length === 0 ? EmptyDisplay : 
                        <Table size="small" stickyHeader={stickyHeader}>
                            <TableHead>
                                <TableRow>
                                    { columnHeaders?.map((c, i) => 
                                        <TableCell key={i} className={classes.tableCell}>
                                            <Typography noWrap variant='body1' color="textSecondary">{c}</Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            {loading ? <TableBody><TableRow><TableCell><CircularProgress /></TableCell></TableRow></TableBody> :
                                <TableBody>
                                    { preBuiltRows || records?.map((record, i) => <DisplayTableRow key={record.key || i} {...{record}} />)}
                                </TableBody>
                            }
                        </Table>
                    }
                </TableContainer>
            </Grid>
        </>

    if (!header && !toolBarOptions && !actionFooter) return displayGrid
    
    return (
        <DisplayGridWrapper {...{header}} 
            hideDivider={!header && actionFooter ? true : false} hidePaper={!header && actionFooter ? true : false}
            toolBar={toolBarOptions && toolBar}
            actionBar={!toolBarOptions ? actionBar : undefined} 
            {...{description}} 
            {...{displayGrid}}
            {...{linkButton}}
            {...{actionFooter}}
            {...{hideFooterDivider}}/>
    )
};


const useStyles = makeStyles(theme => ({
    headerBar: {
        padding: theme.spacing(3)
    },
    headerContainer: {
        height: '35px'
    },
    headerTab: {
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(3)
    },
    emptyIcon: {
        maxHeight: '30vh',
        minHeight: '200px',
        paddingTop: theme.spacing(4)
    },
    emptyContainer: {
        padding: theme.spacing(3),
        height: '100%'
    },
    tableCell: {
        paddingLeft: theme.spacing(3)
    }
}));
