import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ConfigsQuery, ConfigsData, Config } from '../../models/configs';
import { EnvironmentResourcesVars } from '../../interfaces';
import { useParams, useHistory } from 'react-router-dom';
import { DisplayCard, EmptyState } from '../../components/DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { cloudConfigTypeList, CREATE_CLOUDCONFIG_PATH, CLOUDCONFIG_KEYSTORE_PATH, CLOUDCONFIG_BACKUP_PATH, CLOUDCONFIG_LOGSTREAM_PATH, CLOUDCONFIG_BASE_PATH, CLOUDCONFIG_NETWORK_PATH, CLOUDCONFIG_STORAGE_PATH } from '../../components/MainNav/SideNavs/CloudConfigs';
import VpnKeyIcon from 'mdi-react/KeyIcon';
import BackupIcon from 'mdi-react/CloudUploadIcon';
import NetworkIcon from 'mdi-react/AccessPointIcon';
import StreamIcon from 'mdi-react/SwapHorizontalIcon';
import StorageIcon from 'mdi-react/ServerIcon';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';


export const CloudConfigsDashboard = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CloudConfigsDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CloudConfigsDashboard:${key}`, interpolate);
    const {consortium_id, environment_id, org_id} = useParams<any>();

    const history = useHistory();

    const {
        loading: configsLoading,
        data: {
            configs
        } = { configs: null }
    } = useQuery<ConfigsData, EnvironmentResourcesVars>(ConfigsQuery, {
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-and-network'
    });

    const cloudConfigs = useMemo(() => configs?.filter(config => cloudConfigTypeList.includes(config.type)), [configs]);

    // Networking would be hard coded into aws until further changes
    const awsConfigs = useMemo(() => cloudConfigs?.filter(entry => entry.details.provider === 'aws' || entry.type === 'networking'), [cloudConfigs])
    const azureConfigs = useMemo(() => cloudConfigs?.filter(entry => entry.details.provider === 'azure'), [cloudConfigs])

    const getTypeAmount = (type: string, data: Config[] | undefined) => {
        const configs = data?.filter(entry => entry.type === type);
        return configs?.length ?? 0;
    }

    const getConfigList = (provider: 'aws' | 'azure') => {
        const config = provider === 'aws' ? awsConfigs : azureConfigs;
        const content = [
            {
                icon: <VpnKeyIcon />,
                title: lt('keyStore'),
                actionIcon: getTypeAmount('kms', config).toString(),
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_KEYSTORE_PATH}`)
            },
            {
                icon: <BackupIcon />,
                title: lt('backupStore'),
                actionIcon: getTypeAmount('backup', config).toString(),
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_BACKUP_PATH}`)
            },
            {
                icon: <StorageIcon />,
                title: lt('storage'),
                actionIcon: getTypeAmount('storage', config).toString(),
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_STORAGE_PATH}`)
            }
        ]

        if (provider === 'aws') {
            content.push(       {
                icon: <StreamIcon />,
                title: lt('logStream'),
                actionIcon: getTypeAmount('opsmetric', config).toString(),
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_LOGSTREAM_PATH}`)
            })
            content.push({
                icon: <NetworkIcon />,
                title: lt('networkControl'),
                actionIcon: getTypeAmount('networking', config).toString(),
                onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CLOUDCONFIG_NETWORK_PATH}`)
            });
        }

        return content
    }   


    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('cloudConfigurations'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>  window.open('https://docs.kaleido.io/using-kaleido/cloud-integrations/')
        }
    ]

    if (configsLoading) return <CircularProgress />

    const createConfig = () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CLOUDCONFIG_BASE_PATH}/${CREATE_CLOUDCONFIG_PATH}/1`)

    if (!cloudConfigs?.length) return (
        <EmptyState imageFile='Empty-CloudConfigs.svg' 
            title={lt('createAConfig')} 
            description={lt('emptyDescription')} 
            button={{ text: lt('createConfig'), onClick: createConfig }}
            documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/cloud-integrations/' }} />
    )

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">{lt('cloudConfigurations')}</Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" size="large" onClick={createConfig}>
                        {lt('addConfig')}
                    </Button>
                </Grid>
            </Grid>
            <Grid item container spacing={3} alignContent='stretch'>
                <Grid item container xl={4} lg={4} md={6} sm={6} xs={12}>
                    <DisplayCard header={lt('awsHeader')} itemList={getConfigList('aws')} divider /> 
                </Grid>
                <Grid item container xl={4} lg={4} md={6} sm={6} xs={12}>
                    <DisplayCard header={lt('azureHeader')} itemList={getConfigList('azure')} divider />
                </Grid>
                <Grid item container xl={4} lg={4} md={6} sm={6} xs={12}>
                    <DisplayCard header={lt('documentation')} itemList={DocumentationList} />
                </Grid>
            </Grid>
        </Grid>
    )
}

interface translations {
    awsHeader: string
    azureHeader: string
    cloudConfigurations: string
    addConfig: string
    documentation: string
    keyStore: string
    backupStore: string
    storage: string
    logStream: string
    networkControl: string
    createAConfig: string
    createConfig: string
    emptyDescription: string
    emptyDocumentation: string
}

const enTranslations: translations = {
    awsHeader: 'Amazon Web Services',
    azureHeader: 'Microsoft Azure',
    cloudConfigurations: 'Cloud Configurations',
    addConfig: 'Add Cloud Config',
    documentation: 'Documentation',
    keyStore: 'Key Store',
    backupStore: 'Backup Store',
    storage: 'Storage',
    logStream: 'Log Stream',
    networkControl: 'Network Control',
    createAConfig: 'Create a Cloud Config',
    createConfig: 'Create Cloud Config',
    emptyDescription: 'Configurations will allow us to integrate our nodes with native AWS and Azure services.',
    emptyDocumentation: 'Documentation'
}