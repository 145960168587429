import React from  'react';
import { Grid, Paper, makeStyles, Typography } from '@material-ui/core';
import ArrowUpCircleOutlineIcon from 'mdi-react/ArrowUpCircleOutlineIcon';
import { useTranslation } from 'react-i18next';
import { FormLink } from '../FormControls/FormLink';
import { ORGS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, CHANGE_PLAN_PATH } from '../ManageOrgNav/ManageOrgNav';
import { useParams, useHistory } from 'react-router-dom';

interface Props {
    description: string
    actionLabel?: string
    action?: () => void
}

export const PayWallBanner = ({description, actionLabel, action}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'PayWallBanner', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`PayWallBanner:${key}`, interpolate)

    const { org_id } = useParams<any>();
    const history = useHistory();

    const upgradePath = `/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`

    const classes = useStyles();

    return (
        <Grid item>
            <Paper className={classes.paywallPaper} elevation={0}>
                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <ArrowUpCircleOutlineIcon />
                    </Grid>
                    <Grid item xs container spacing={1}>
                        <Grid item>
                            <Typography variant="body2">
                                {description}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormLink contrastColor bold onClick={action ? action : () => history.push(upgradePath) }>
                                {actionLabel || lt('pleaseUpgrade')}
                            </FormLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
};

interface translations {
    pleaseUpgrade: string
}
const enTranslations: translations = {
    pleaseUpgrade: 'Upgrade Here'
}


const useStyles = makeStyles((theme) => ({
    paywallPaper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));