import { gql, SubscriptionHookOptions, MutationFunctionOptions } from '@apollo/client';
import { ConsortiumResourcesVars } from '../interfaces'

// this should be an autogenerated interface from the ConsortiumZone model in graphql
export interface ConsortiumZone {
    _id: string
    consortia_id: string
    default: boolean
    cloud: string
    code: string
    flag: string
    name: string
    region: string
    type: 'kaleido' // note at the consortium level it cannot be 'private'
    state: string
}

export interface CreateConsortiumZoneVars extends ConsortiumResourcesVars {
    consortiumZone: {
        region: ConsortiumZone['region'],
        cloud: ConsortiumZone['cloud'],
        type: ConsortiumZone['type'],
    }
}

export interface CreateConsortiumZoneData {
    createConsortiumZone: ConsortiumZone
}

export interface ConsortiumZonesData {
    consortiumZones: ConsortiumZone[]
}

export interface ConsortiumZoneData {
    consortiumZone: ConsortiumZone
}

// FRAGMENTS

export const ConsortiumZoneFields = ` 
    fragment ConsortiumZoneFields on ConsortiumZone {
        _id
        consortia_id
        default
        cloud
        code
        flag
        name
        region
        type
        state
    }`

// MUTATIONS

export const CreateConsortiumZoneMutation = gql`
    ${ConsortiumZoneFields}
    mutation createConsortiumZone($consortia_id: String!, $consortiumZone: CreateConsortiumZoneInput!) {
        createConsortiumZone(consortia_id: $consortia_id, consortiumZone: $consortiumZone) {
            ...ConsortiumZoneFields
        }
}`;

// QUERIES & SUBSCRIPTIONS

export const ConsortiumZonesQuery = gql`
    ${ConsortiumZoneFields}
    query getConsortiumZones($consortia_id: String!) {
        consortiumZones(consortia_id: $consortia_id) {
            ...ConsortiumZoneFields
        }
    }`;

export const ConsortiumZoneQuery = gql`
    ${ConsortiumZoneFields}
    query getConsortiumZone($consortia_id: String!, $id: String!) {
        consortiumZone(consortia_id: $consortia_id, id: $id) {
            ...ConsortiumZoneFields
        }
    }`;

export const ConsortiumZonesSubscription = gql`
    ${ConsortiumZoneFields}
    subscription onConsortiumZonesChanged($consortia_id: String!) {
        consortiumZone(consortia_id: $consortia_id) {
            ...ConsortiumZoneFields
        }
    }`;

// MUTATION OPTIONS

export function MakeConsortiumZoneCreateMutationOptions(
    variables: CreateConsortiumZoneVars,
    overrideOptions?: MutationFunctionOptions<CreateConsortiumZoneData, CreateConsortiumZoneVars>): 
        MutationFunctionOptions<CreateConsortiumZoneData, CreateConsortiumZoneVars> {
    return { ...{
        variables,
        update(cache, { data }) {
            const zone = data?.createConsortiumZone
            if (zone) {
                try {
                    const { consortiumZones } = cache.readQuery<ConsortiumZonesData>({ query: ConsortiumZonesQuery, variables })!;
                    // if subscription wrote it to the cache first, then we dont need to write it in here.
                    // (the already added cached entry will be auto updated with the result of the mutation)
                    if (!consortiumZones.find(z => z._id === zone._id)) {
                        cache.writeQuery({
                            variables,
                            query: ConsortiumZonesQuery,
                            data: { consortiumZones: consortiumZones.concat(zone) },
                        });
                    }
                } catch(err) {
                    console.log('Error during create consortium zone cache update')
                }
            }
        }
    }, ...overrideOptions }
}

// SUBSCRIPTION OPTIONS

export function MakeConsortiumZonesSubscriptionOptions(
    variables: ConsortiumResourcesVars,
    overrideOptions?: SubscriptionHookOptions<ConsortiumZoneData>): SubscriptionHookOptions<ConsortiumZoneData> {
    return { ...{
        variables,
        onSubscriptionData: ({client, subscriptionData}) => {
            // Get the consortiumZone object from the event
            const { 
                data: { 
                    consortiumZone
                } = { consortiumZone: null }
            } = subscriptionData

            if (!consortiumZone) return

            // Get current list of consortiumZones from the cache
            // assert that consortiumZones must exist since this function throws if it doesnt
            const { consortiumZones: existingConsortiumZones } = client.readQuery<ConsortiumZonesData>({ 
                query: ConsortiumZonesQuery, 
                variables 
            })!
            
            // if it already exists in the store, (its an update), apollo automagically updated the cache
            const cachedConsortiumZone = existingConsortiumZones.find(c => c._id === consortiumZone._id)

            // but, if it came back as deleted, we need to remove it from the cache manually
            if (cachedConsortiumZone) {
                if (cachedConsortiumZone.state === 'deleted') {
                    client.cache.evict({id: `ConsortiumZone:${cachedConsortiumZone._id}`, broadcast: false})
                }
                client.writeQuery<ConsortiumZonesData>({ 
                    query: ConsortiumZonesQuery, 
                    data: Object.assign({}, existingConsortiumZones, {
                        consortiumZones: [...existingConsortiumZones.filter(c => c.state !== 'deleted')]
                    }),
                    variables
                })
                return 
            }

            // in case of duplicate events, never add a deleted item into the cache
            if (consortiumZone.state === 'deleted') return

            // if it doesnt exist in the store, (it's been added), so we need to add it in manually
            client.writeQuery<ConsortiumZonesData>({ 
                query: ConsortiumZonesQuery, 
                data: Object.assign({}, existingConsortiumZones, {
                    consortiumZones: [consortiumZone, ...existingConsortiumZones]
                }),
                variables
            })
        }
    }, ...overrideOptions }
}

