import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ServicesData, ServicesQuery, ServiceLogTypesData, ServiceLogTypesQuery } from '../../models'
import { Typography, Grid } from "@material-ui/core";
import { LogsWrapper } from './LogsWrapper'

export const ServiceLogs = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LogsServiceLogs', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LogsServiceLogs:${key}`, interpolate)

    const { consortium_id, environment_id, service_id } = useParams<any>();

    let { 
        data: { 
            services
        } = { services: [] } 
    } = useQuery<ServicesData>(ServicesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    let { 
        data: { 
            serviceLogTypes
        } = { serviceLogTypes: [] } 
    } = useQuery<ServiceLogTypesData>(ServiceLogTypesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: service_id
        } 
    });

    const service = services.find(s => s._id === service_id)
    const serviceName = service?.name ?? ''
    
    return (
        <>
            <Grid container direction="column" spacing={3} alignItems="stretch">
                <Grid item>
                    <Typography variant="h5">
                        {lt('serviceLogs', {service: serviceName})}
                    </Typography>
                </Grid>
                <Grid item container>
                    { serviceLogTypes.length ? <LogsWrapper logContainers={serviceLogTypes} /> : <></> }
                </Grid>
            </Grid>
        </>
    )
}

interface translations {
    serviceLogs: string
}
const enTranslations: translations = {
    serviceLogs: '{{service}} Logs'
}