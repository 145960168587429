import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../components/DialogWrappers'


export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateChannelCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateChannelCreateStep1Help:${key}`, interpolate)
    return (
        <Help imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-Channel.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink='https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#user-created-channels'
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string
}
const enTranslations: translations = {
    helpHeader: 'Channels Summary',
    helpDescription: 'A Hyperledger Fabric channel is a private “subnet” of communication between two or more specific network members, for the purpose of conducting private and confidential transactions. A channel is defined by members (organizations), anchor peers per member, the shared ledger, chaincode application(s) and the ordering service node(s). Each transaction on the network is executed on a channel, where each party must be authenticated and authorized to transact on that channel. Each peer that joins a channel, has its own identity given by a membership services provider (MSP), which authenticates each peer to its channel peers and services.',
    helpDesc1: `Owning member - if you have multiple memberships under your organization's control, you can use this dropdown to bind the node to a membership of your choice.`,
    helpDesc2: 'Name - a string identifying the node in the environment.',
    helpDesc3: 'Deployment region - the cloud and underlying region where the node will be deployed.  If the environment is deployed as single region, then the region is inherited from the environment configuration.  If the environment is deployed as a hybrid multi-region, then any whitelisted regions will be available for choice.',
}