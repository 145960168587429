import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import ArrowUpDownIcon from 'mdi-react/ArrowUpDownIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import ResetIcon from 'mdi-react/LockResetIcon';
import RotateRightIcon from 'mdi-react/RotateRightIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { DeleteResource, MessageSnackbar, RestartRuntime, UpdateName } from '../../components/DialogWrappers';
import { ResetRuntime } from '../../components/DialogWrappers/ResetRuntime';
import { EditableSettings } from '../../components/DisplaySettings';
import { EnvironmentResourceVars } from '../../interfaces';
import { App2AppLimitTranslations, DocumentStoreLimitTranslations, EnApp2AppLimitTranslations, EnDocumentStoreLimitTranslations, EnRuntimeSizeTranslation, OrganizationData, OrganizationQuery, OrganizationVars, RuntimeSizeTranslation, ServiceData, ServiceQuery, SizeSupportedServices } from '../../models';
import { HigherAccessRequestData, SubmitHigherAccessMutation, SubmitHigherAccessRequestFormVars } from '../../models/contactInfo';
import { requestHigherAccessFormFields } from '../../utils/HubspotUtils';
import { UpdateSize } from './UpdateSize';

export const ServiceSettings: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ServiceSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ServiceSettings:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, service_id } = useParams<any>();

    // direct read from cache
    const client = useApolloClient()
    const { organization } = client.readQuery<OrganizationData, OrganizationVars>({query: OrganizationQuery, variables: { id: org_id! }})!
    const [message, setMessage] = useState('');
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [submitAccessRequest] =  useMutation<HigherAccessRequestData, SubmitHigherAccessRequestFormVars>(SubmitHigherAccessMutation)

    const environmentVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        loading,
        data: {
            service
        } = { service: null }
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, { 
        variables: {
            ...environmentVariables,
            id: service_id!
        },
        fetchPolicy: 'cache-only'
    });

    const [updateNameDialogOpen, setUpdateNameDialogOpen] = useState(false);
    const [updateSizeDialogOpen, setUpdateSizeDialogOpen] = useState(false);
    const [restartDialogOpen, setRestartDialogOpen] = useState(false);
    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    if (loading || !service) return <CircularProgress />

    const requestUpgradeInformation = async() => {
        const formFields = requestHigherAccessFormFields('upgradeFireFlyNode', window.location.href);
        const { data }= await submitAccessRequest({
            variables: {
                ...formFields
            }
        });
        if (data?.requestHigherAccess) {
            setMessage(lt('requestSubmitted'))
            setRequestSubmitted(true)
        }
    }
    

    const actionsList = [
        {
            icon: <AccountCircleIcon />,
            title: lt('changeName'),
            description: lt('nameDescription'),
            value: service.name,
            buttonLabel: lt('change'),
            action: () => setUpdateNameDialogOpen(true)
        },
        {
            icon: <ArrowUpDownIcon />,
            title: lt('changeSize'),
            description: lt('changeSizeDescription'),
            value: lt(service.size),
            buttonLabel: SizeSupportedServices.has(service.service) ? lt('change') : lt('requestLargerRuntime'),
            action: () => {SizeSupportedServices.has(service.service) ? setUpdateSizeDialogOpen(true) : requestUpgradeInformation()},
            disabledButton: requestSubmitted
        },
        {
            icon: <RotateRightIcon />,
            title: lt('restartService'),
            description: lt('restartDescription'),
            buttonLabel: lt('restart'),
            action: () => setRestartDialogOpen(true)
        },
        {
            icon: <ResetIcon />,
            title: lt('resetService'),
            description: lt('resetDescription'),
            buttonLabel: lt('reset'),
            action: () => setResetDialogOpen(true)
        },
        {
            icon: <DeleteOutlineIcon />,
            title: lt('deleteservice'),
            description: lt('deleteDescription'),
            buttonLabel: lt('delete'),
            action: () => setDeleteDialogOpen(true)
        }
    ]

    let smallDescription = ''
    let mediumDescription = ''
    let largeDescription = ''

    if (service.service === 'app2app') {
        smallDescription = organization.plan === 'starter' ? lt('app2appStarterDescription') : lt('app2appSmallDescription')
        mediumDescription = lt('app2appMediumDescription')
        largeDescription = lt('app2appLargeDescription')
    }

    return (
        <>
            <MessageSnackbar messageType={'success'} {...{message}} {...{setMessage}} />
            <UpdateName defaultName={service.name} open={updateNameDialogOpen} setOpen={setUpdateNameDialogOpen} />
            <UpdateSize {...{service}} open={updateSizeDialogOpen} setOpen={setUpdateSizeDialogOpen}
                {...{smallDescription}} {...{mediumDescription}} {...{largeDescription}} />
            <RestartRuntime name={service.name} open={restartDialogOpen} setOpen={setRestartDialogOpen} />
            <ResetRuntime name={service.name} open={resetDialogOpen} setOpen={setResetDialogOpen} />
            <DeleteResource service={service.service} name={service.name} open={deleteDialogOpen} setOpen={setDeleteDialogOpen} closeDialogAfterSave />

            <Grid container direction="column" spacing={3} wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">
                        {lt('runtimeSettings')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <EditableSettings
                        header={lt('runtimeSettings')}
                        {...{actionsList}} />
                </Grid>
            </Grid>
        </>
    )
};

interface translations extends RuntimeSizeTranslation, App2AppLimitTranslations, DocumentStoreLimitTranslations {
    runtimeSettings: string,
    serviceSettingsDesc: string,
    changeName: string,
    restartService: string,
    deleteservice: string,
    name: string,
    provider: string,
    change: string,
    restart: string,
    delete: string,
    nameDescription: string,
    restartDescription: string,
    deleteDescription: string,
    resetService: string,
    resetDescription: string,
    reset: string,
    changeSize: string,
    changeSizeDescription: string,
    requestLargerRuntime: string,
    requestSubmitted: string
}
const enTranslations: translations = {
    ...EnApp2AppLimitTranslations,
    ...EnRuntimeSizeTranslation,
    ...EnDocumentStoreLimitTranslations,
    runtimeSettings: 'Runtime Settings',
    serviceSettingsDesc: 'You can rename this runtime, as well as change it\'s size. Restarting the runtime can be a decent troubleshooting option. The runtime can also be permanently deleted.',
    changeName: 'Change runtime name',
    restartService: 'Restart runtime',
    deleteservice: 'Delete runtime',
    provider: 'Provider',
    name: 'Name',
    change: 'Change',
    restart: 'Restart',
    delete: 'Delete',
    nameDescription: 'Change the name that identifies the runtime.',
    restartDescription: 'Restart the runtime keeping its actual configuration.',
    deleteDescription: 'Delete the runtime.',
    resetService: 'Reset runtime',
    resetDescription: 'Hard reset runtime to apply new configurations.',
    reset: 'Reset',
    changeSize: 'Change runtime size',
    changeSizeDescription: 'Change the size of this runtime to increase CPU, Memory, and other runtime specific limits.',
    requestLargerRuntime: 'Request larger runtime',
    requestSubmitted: 'Request submitted. Someone will be in touch with you soon.',
}