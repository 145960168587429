import { useQuery } from '@apollo/client';
import { Grid, IconButton, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from 'mdi-react/RefreshIcon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { DisplayGridWrapper } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_PATH, ADDRESSBOOK_RUNTIMES_PATH, SYSTEM_MEMBERSHIP } from '../../components/MainNav/SideNavs/AddressBook';
import { ConsortiumResourceVars, EnvironmentResourcesVars, LinkButtonProps } from '../../interfaces';
import { EnvironmentData, EnvironmentPeerCount, EnvironmentPeerCountsData, EnvironmentPeerCountsQuery, EnvironmentQuery, EnvironmentResourceUsageData, EnvironmentResourceUsageQuery, EnvironmentResourceUsageVars, NodesData, RuntimeSize, NodesQuery, NodeState, ServicesData, ServicesQuery, ServiceState, ServicesEnum } from '../../models';
import { Runtime } from './Runtime';
import { RuntimeSelector } from './RuntimeSelector';
import { RuntimeType, RuntimeTypeSelector } from './RuntimeTypeSelector';

const REFRESH_TIMEOUT = 2500

export type RuntimeChart = { 
    id: string, 
    membership_id: string,
    name: string, 
    size: RuntimeSize,
    timestamp?: number, 
    cpu?: number, 
    memory?: number, 
    disk?: number, 
    peerCounts?: EnvironmentPeerCount,
    state: NodeState | ServiceState,
    service?: keyof typeof ServicesEnum
}

export const Runtimes = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EnvironmentHealthRuntimes', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`EnvironmentHealthRuntimes:${key}`, interpolate), [t])

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!
    }
    
    const [message, setMessage] = useState('');
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [runtimeType, setRuntimeType] = useState<RuntimeType>('all');

    const { data: { 
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    // refresh the metrics using a timeout with a visual indicator that its refreshing
    const timeoutRef = useRef<number>(0)
    useEffect(() => {
        return () => window.clearTimeout(timeoutRef.current);
    }, []);
    const handleRefresh = () => {
        window.clearTimeout(timeoutRef.current);
        setDisableRefresh(true)
        
        Promise.all([refetchEnvironmentResourceUsage(), refetchEnvironmentPeerCounts()]).then(() => {
            timeoutRef.current = window.setTimeout(() => {
                setDisableRefresh(false)
            }, REFRESH_TIMEOUT)
        })
    }

    // query the runtimes
    const { 
        data: { 
            nodes
        } = { nodes: [] } 
    } = useQuery<NodesData, EnvironmentResourcesVars>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });    

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    // query the resource usage stats
    const {
        refetch: refetchEnvironmentResourceUsage,
        loading: environmentResourceUsageLoading,
        data: {
            environmentResourceUsage
        } = { environmentResourceUsage: [] },
        error: environmentResourceUsageError
    } = useQuery<EnvironmentResourceUsageData, EnvironmentResourceUsageVars>(EnvironmentResourceUsageQuery, {
        variables: {
            ...environmentVars, 
            hours: 1,
            interval: 5
        },
        fetchPolicy: 'no-cache' // no cache for now, to not overwrite any cached entries from health dashboard
    });

    // query the peer counts
    const {
        refetch: refetchEnvironmentPeerCounts,
        loading: environmentPeerCountsLoading,
        data: {
            environmentPeerCounts
        } = { environmentPeerCounts: [] },
        error: environmentPeerCountsError
    } = useQuery<EnvironmentPeerCountsData, EnvironmentResourcesVars>(EnvironmentPeerCountsQuery, {
        skip: isCorda || isFabric,
        variables: {
            ...environmentVars
        },
        fetchPolicy: 'cache-and-network'
    });

    const allRuntimes = useMemo(() => {
        let list = [] as { _id: string, name: string }[]
        if (runtimeType === 'node' || runtimeType === 'all') {
            list = list.concat(nodes.map(n => 
                ({ _id: n._id, name: n.name })
            ))
        }
        if (runtimeType !== 'node') {
            list = list.concat(services.filter(s => runtimeType === 'all' || s.service === runtimeType).map(s => 
                ({ _id: s._id, name: s.name })
            ))
        }
        return list
    }, [nodes, services, runtimeType])

    // when toggling runtime type, reset all to selected
    useEffect(() => {
        setSelectedRuntimeIds(allRuntimes.map(r => r._id))
    }, [runtimeType, allRuntimes])

    // track the runtimes to show in the filter
    const [selectedRuntimeIds, setSelectedRuntimeIds] = useState<string[]>(allRuntimes.map(r => r._id));

    const findRuntimeName = useCallback((id: string) => {
        return nodes.find(n => n._id === id)?.name || services.find(n => n._id === id)?.name || ''
    }, [nodes, services])

    // show error if the query fails
    useEffect(() => {
        const e = environmentResourceUsageError || environmentPeerCountsError
        if (e) {
            ErrorSnackbarCatcher(e, setMessage)
        }
    }, [environmentResourceUsageError, environmentPeerCountsError]);

    const wrapChartWithLoading = (id: string, membershipId: string, chart: JSX.Element, header: string, state: NodeState | ServiceState) => {
        const linkButton: LinkButtonProps = {
            text: lt('viewRuntime'),
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/${membershipId}/${ADDRESSBOOK_RUNTIMES_PATH}/${id}`)
        }

        const actionBar = <ResourceStateChip state={state} />
        if (state === 'failed') {
            const failed = <Typography variant="body2">{lt('runtimeFailed')}</Typography>
            return (
                <DisplayGridWrapper {...{actionBar}} {...{header}} {...{linkButton}} padDisplayGrid displayGrid={failed}/>
            )
        } else if ((environmentResourceUsageLoading && !environmentResourceUsage.length) ||
            (environmentPeerCountsLoading && !environmentPeerCounts.length)) {
            return (
                <DisplayGridWrapper {...{actionBar}} hideDivider {...{header}} {...{linkButton}} padDisplayGrid displayGrid={<CircularProgress />}/>
            )
        } else if ((!isCorda && !environmentPeerCounts.length) || !environmentResourceUsage.length || !environmentResourceUsage.find(e => e.id === id)) {
            const notEnoughData = <Typography variant="body2">{lt('notEnoughData')}</Typography>
            return (
                <DisplayGridWrapper {...{actionBar}} {...{header}} {...{linkButton}} padDisplayGrid displayGrid={notEnoughData}/>
            )
        }   
        return (
            <DisplayGridWrapper {...{linkButton}} hideDivider {...{header}} {...{actionBar}} displayGrid={chart}/>
        )
    }

    const preventRenderError = environmentResourceUsageError ? true : false
    const refreshIsDisabled = environmentResourceUsageLoading || environmentPeerCountsLoading || disableRefresh

    // Charts

    const runtimesToChart = useMemo(() => {
        const runtimesToChart = [] as RuntimeChart[]
        
        nodes.filter(n => selectedRuntimeIds.includes(n._id)).forEach(n => {
            const resourceUsage = environmentResourceUsage.find(r => r.id === n._id)
            const data = resourceUsage?.data[resourceUsage.data.length - 1]
            runtimesToChart.push({ 
                id: n._id,
                membership_id: n.role === 'monitor' ? SYSTEM_MEMBERSHIP : n.membership_id,
                name: findRuntimeName(n._id),
                size: n.size,
                timestamp: data?.t ? Date.parse(data.t) : undefined,
                cpu: data?.c?.valueOf(),
                memory: data?.m?.valueOf(),
                disk: data?.s?.valueOf(),
                peerCounts: environmentPeerCounts.find(p => p.id === n._id),
                state: n.state
            })
        })

        services.filter(s => selectedRuntimeIds.includes(s._id)).forEach(s => {
            const resourceUsage = environmentResourceUsage.find(r => r.id === s._id)
            const data = resourceUsage?.data[resourceUsage.data.length - 1]
            runtimesToChart.push({ 
                id: s._id,
                membership_id: s.service_type === 'utility' ? SYSTEM_MEMBERSHIP : s.membership_id,
                name: findRuntimeName(s._id),
                size: s.size,
                timestamp: data?.t ? Date.parse(data.t) : undefined,
                cpu: data?.c?.valueOf(),
                memory: data?.m?.valueOf(),
                disk: data?.s?.valueOf(),
                peerCounts: environmentPeerCounts.find(p => p.id === s._id),
                state: s.state,
                service: s.service
            })
        })

        return runtimesToChart
    }, [selectedRuntimeIds, environmentPeerCounts, environmentResourceUsage, findRuntimeName, nodes, services])

    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('environmentMetrics')}
                        </Typography>
                    </Grid>
                    {!preventRenderError && 
                    <Grid item>
                        <RuntimeTypeSelector servicesToInclude={services.map(s => s.service)} {...{runtimeType}} {...{setRuntimeType}} disabled={refreshIsDisabled} />
                        
                        <RuntimeSelector {...{allRuntimes}} {...{selectedRuntimeIds}} {...{setSelectedRuntimeIds}} {...{refreshIsDisabled}} />

                        <IconButton color="primary" size={refreshIsDisabled ? "small" : "medium"} disabled={refreshIsDisabled}
                                    onClick={() => handleRefresh()} >
                            { refreshIsDisabled ? <CircularProgress color="inherit" /> : <RefreshIcon /> }
                        </IconButton>
                    </Grid>
                    }
                </Grid>

                {!preventRenderError && 
                <Grid item container spacing={3}>
                    { !runtimesToChart.length && 
                    <Grid item>
                        <Typography variant="body2">
                            {lt('noRuntimesSelected')}
                        </Typography>
                    </Grid>
                    }
                    {
                        runtimesToChart.map(c => (
                            <Grid item container key={c.id} xs={12} sm={6} lg={4}>
                                { wrapChartWithLoading(c.id, c.membership_id, <Runtime runtimeChart={c} service={c.service} nodeLength={nodes.length} />, c.name, c.state) }
                            </Grid>
                        ))
                    }
                </Grid>
                }
            </Grid>
        </>
    ) 
};

interface translations {
    environmentMetrics: string,
    notEnoughData: string,
    includeRuntimes: string,
    deselectAll: string,
    selectAll: string,
    noRuntimesSelected: string,
    viewRuntime: string,
    runtimeFailed: string
}
const enTranslations: translations = {
    environmentMetrics: 'Runtimes',
    notEnoughData: 'This environment has not been running long enough to collect any metrics data. Try again later.',
    includeRuntimes: 'Filter runtimes',
    deselectAll: 'Deselect all',
    selectAll: 'Select all',
    noRuntimesSelected: 'No runtimes have been selected. Use the filter above to select one or more runtimes.',
    viewRuntime: 'View runtime',
    runtimeFailed: 'This runtime has failed and as such no metrics are available.'
}