import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { Service, ServicesData, ServicesQuery } from '../../models';
import { DisplayCard } from '../../components/DisplayWrappers';
import { DisplayCardListItem } from '../../components/DisplayWrappers/DisplayCardItem';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import BallotOutlineIcon from 'mdi-react/BallotOutlineIcon';
import BusinessIcon from 'mdi-react/BusinessIcon';
import CalendarBlankOutlineIcon from 'mdi-react/CalendarBlankOutlineIcon';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import { ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH, ADDRESSBOOK_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { BLOCKCHAIN_BASE_PATH, BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH } from '../../components/MainNav/SideNavs/Blockchain';

export const IdentityManagement: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'IdentityManagement', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`IdentityManagement:${key}`, interpolate)

    const { org_id, consortium_id, environment_id} = useParams<any>();
    const history = useHistory();
    const { pathname } = useLocation();
    const addressBookMode = pathname.includes(`${ADDRESSBOOK_PATH}`);

    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }
    
    const {
        data: {
            services: allServices
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const showAllOrgsIdentities = pathname.includes(`/${ADDRESSBOOK_PATH}`);

    function generateItemList(identity: Service) {
        let items: DisplayCardListItem[] = [
            {
                title: lt('membership'),
                value: identity.membership.name,
                icon: <BusinessIcon />
            },
            {
                title: lt('state'),
                value: <ResourceStateChip state={identity.state} />,
                icon: <ChartLineIcon />
            },
            {
                title: lt('runtimeId'),
                value: identity._id,
                icon: <BallotOutlineIcon />
            },
            {
                title: lt('lastUpdate'),
                value: new Date(identity.updated_at).toLocaleString(),
                icon: <CalendarBlankOutlineIcon />
            },

        ]
        return items;
    };

    const makeLinkButton = (caId: string) => {
        const servicePath = addressBookMode ?  `${ADDRESSBOOK_PATH}/${ADDRESSBOOK_CERTIFICATE_AUTHORITIES_PATH}` : `${BLOCKCHAIN_BASE_PATH}/${BLOCKCHAIN_IDENTITY_MANAGEMENT_PATH}`
        return {
            text: lt('viewCertificateDetails'),                       
            onClick: () => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${servicePath}/${caId}`)
        }
    }

    const services = allServices.filter((entry) => entry.service === 'fabric-ca' && (!showAllOrgsIdentities ? entry.membership.isMine : entry.membership))

    const identitiesContent = (
        <>
            {services.map(identity => (
                <Grid key={identity._id} item container xl={3} lg={4} md={6} sm={6} xs={12}>
                    <DisplayCard header={identity.name} itemList={generateItemList(identity)}
                        linkButton={makeLinkButton(identity._id)} />
                </Grid>)
            )}
        </>
    )

    return (
        <>
            <Grid container spacing={3}>
                <Grid item container direction={"column"} spacing={3}>
                    <Grid item container>
                        <Typography variant="h5">
                            {lt('identityManagement', { count: services.length })}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={3}>
                        {identitiesContent}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

interface translations {
    identityManagement: string,
    membership: string,
    viewCertificateDetails: string
    runtimeId: string
    lastUpdate: string
    identities: string
    state: string
}
const enTranslations: translations = {
    identityManagement: 'Certificate Authorities ({{count}})',
    membership: 'Membership',
    viewCertificateDetails: 'View certificate details',
    runtimeId: 'Runtime ID',
    lastUpdate: 'Last Update',
    identities: 'Identities',
    state: 'State'
}