import { BrandColors } from './Colors';

import Highcharts from 'highcharts';

export const BaseChartOptions: Highcharts.Options = {
    credits: { enabled: false },
    legend: { enabled: false },
    title: {
        text: ''
    },
    xAxis: {
        // https://stackoverflow.com/questions/7101464/how-to-get-highcharts-dates-in-the-x-axis
        dateTimeLabelFormats: {
            minute: { main: '%l:%M %p' },
            hour: { main: '%l %p' },
            day: { main: '%b %d' },
        },
        tickLength: 0,
    },
    yAxis: {
        min: 0,
        minTickInterval: 1,
        minRange: 5
    },
    plotOptions: {
        column: {
            grouping: false,
            stacking: 'normal',
            groupPadding: 0
        },
        line: {
            marker: {
                enabled: false,
                symbol: 'circle'
            }
        }
    },
    tooltip: {
        useHTML: true,
        dateTimeLabelFormats: {
            minute: '%b %d, %l:%M %p',
            hour: '%b %d, %l %p',
        }
    },
    colors: [
        BrandColors.blurple,
        BrandColors.purple,
        BrandColors.magenta,
        BrandColors.yellow,
        BrandColors.cyan
    ]
}

