import React, { useState, useEffect } from "react";
import {
    Controller,
    Control,
    NestDataObject,
    FieldError,
} from "react-hook-form";
import { NodeConfigsDetails } from "../../../models/configs";
import { Grid, TextField, IconButton, Button } from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import { useTranslation } from "react-i18next";

interface Props {
    control: Control<NodeConfigsDetails>;
    errors: NestDataObject<NodeConfigsDetails, FieldError>;
    preDefinedHosts?: string[]
}

const ETHCONNECT_CORS_REGEX = "\\*|^(http|https)://(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]):?[0-9]*$"


export const HostsInputs = ({ control, errors, preDefinedHosts }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "HostsInputs", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`HostsInputs:${key}`, interpolate);

    const [inputs, setInputs] = useState([""]);

    useEffect(() => {
        if (preDefinedHosts) {
            setInputs(preDefinedHosts);
        }
    }, [preDefinedHosts]) 

    const addInput = () => {
        setInputs((c) => [...c, ""]);
    };

    const removeInput = (index: number) => {
        setInputs((c) => {
            let arr = [...c];
            arr.splice(index, 1);
            return arr;
        });
    };

    const inputComponent = (index: number) => {
        return (
            <Grid container item key={`cors-input-${index}`} spacing={1}>
                <Grid item xs={index > 0 ? 11 : 12}>
                    <Controller
                        control={control}
                        rules={{
                            pattern: new RegExp(ETHCONNECT_CORS_REGEX),
                            required: true,
                        }}
                        defaultValue=""
                        as={
                            <TextField
                                fullWidth
                                autoComplete="off"
                                label={lt("hosts")}
                                variant="outlined"
                                helperText={
                                    errors["cors_origin_hosts"] && lt("patternError")
                                }
                                error={
                                    !!errors["cors_origin_hosts"]
                                }
                            />
                        }
                        name={`cors_origin_hosts[${index}]`}
                    />
                </Grid>
                {index > 0 && (
                    <Grid item xs={1}>
                        <IconButton onClick={() => removeInput(index)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        );
    };

    const AddInputButton = (
        <Grid item>
            <Button
                data-test="button_addRPCRule"
                variant="text"
                color="primary"
                startIcon={<PlusIcon />}
                onClick={() => addInput()}
            >
                {lt("addHosts")}
            </Button>
        </Grid>
    );

    return (
        <Grid item container spacing={1} direction="column">
            <Grid item container spacing={2}>
                {inputs.map((input, index) => inputComponent(index))}
            </Grid>
            <Grid item>{AddInputButton}</Grid>
        </Grid>
    );
};

interface translations {
    addHosts: string;
    hosts: string;
    patternError: string;
}
const enTranslations: translations = {
    addHosts: "Add Host",
    hosts: "Host",
    patternError: "Not a valid host",
};
