import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../DialogWrappers';
import {
    FormControl,
    MenuItem,
    makeStyles,
    TextField,
    FormHelperText,
    Grid,
    Button,
    CircularProgress,
    Box,
    Fade,
    Typography,
    Select,
    ListItemText,
    Checkbox,
    InputLabel
} from '@material-ui/core';
import {
    OrganizationsQuery,
    OrganizationsData,
    TicketForm,
    RolesData,
    RolesQuery
} from '../../../models';
import { SubmitTicketVars, SubmitTicketMutation } from '../../../models';
import { EmptyState } from '../../DisplayWrappers';
import { Attachments } from './Attachments';
import { SessionData } from '../../../interfaces';
import { SessionQuery } from '../../../queries/Session';

export type Category =
    | 'enterprisePlanEnquiry'
    | 'salesEnquiry'
    | 'productAssist'
    | 'feedback'
    | 'reportBug'
    | 'limitIncrease'
    | 'privateLink'
    | 'cancelAccount';

interface Props {
    preSelectedCategory?: Category;
    closeHelpCenter: () => void;
    shareFeedback?: boolean // the Share Feedback panel uses a stripped down version of this component (renders just a subject & message)
}

interface UploadTokensType {
    [key: string]: string;
}

export interface AttachmentType {
    id: string;
    file: File;
    isUploading: boolean;
    uploadRequest: XMLHttpRequest;
}

export interface AttachmentsType {
    [key: string]: AttachmentType;
}

enum Severities {
    feedback = 'feedback',
    generalGuidance = 'generalGuidance',
    systemImpaired = 'systemImpaired',
    nonProductionSystemDown = 'nonProductionSystemDown',
    productionImpact = 'productionImpact',
    businessCritical = 'businessCritical',
}

export const ContactUs = ({
    preSelectedCategory,
    closeHelpCenter,
    shareFeedback
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContactUs', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) =>
        t(`ContactUs:${key}`, interpolate), [t])

    const classes = useStyles();

    const { org_id } = useParams<any>();
    const client = useApolloClient();

    const [category, setCategory] = useState(shareFeedback ? 'feedback' : 'productAssist');
    const [severity, setSeverity] = useState(shareFeedback ? Severities.feedback : Severities.generalGuidance);
    const [subject, setSubject] = useState(shareFeedback ? lt('productFeedback') : '');
    const [message, setMessage] = useState('');
    const [disableSave, setDisableSave] = useState(true);
    const [showMessageSentScreen, setShowMessageSentScreen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [attachments, setAttachments] = useState<AttachmentsType>({});
    const [uploadTokens, setUploadTokens] = useState<UploadTokensType>({});
    const [ccSelection, setCCSelection] = useState([] as string[])

    useEffect(() => {
        const requiredFieldsFilled = subject.trim() && message.trim();
        setDisableSave(!requiredFieldsFilled);
    }, [subject, message]);

    useEffect(() => {
        if (preSelectedCategory) {
            setCategory(preSelectedCategory);
        }
    }, [preSelectedCategory]);

    const {
        data: { organizations } = { organizations: [] },
    } = useQuery<OrganizationsData>(OrganizationsQuery, {
        fetchPolicy: 'cache-only',
    });

    const {
        data: {
            roles
        } = { roles: [] }
    } = useQuery<RolesData>(RolesQuery, { variables: { org_id }, fetchPolicy: 'cache-and-network' });

    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;
    const org = organizations.find((o) => o._id === org_id)!;

    const getSupportLabel = () => {
        switch (org?.support_level) {
            case 100: return lt('free');
            case 200: return lt('select');
            case 300: return lt('priority');
        }
    };

    const [submitTicket, { loading: loading2 }] = useMutation<
        null,
        SubmitTicketVars
    >(SubmitTicketMutation);

    const deleteAttachment = (id: string) => {
        setAttachments((attachments) => {
            delete attachments[id];
            return {
                ...attachments,
            };
        });
    };

    useEffect(() => {
        return () => {
            for (const id in attachments) {
                const attachment = attachments[id];
                if (attachment.isUploading) {
                    attachment.uploadRequest.abort();
                }
            }
        };
    }, [attachments]);

    const uploadFile = async (file: File) => {
        const id = new Date().getTime() + file.name;
        const uploadRequest = new XMLHttpRequest();
        const attachment = {
            id,
            isUploading: true,
            uploadRequest,
            file: file,
        };
        setAttachments({ ...attachments, [id]: attachment });
        uploadRequest.open(
            'POST',
            `/api/ui/v2/support/attachments?filename=${file.name}`,
            true
        );
        uploadRequest.setRequestHeader('kaleido-custom-content-type', 'true');

        uploadRequest.onload = () => {
            const data = JSON.parse(uploadRequest.response);
            if (uploadRequest.status === 200) {
                setUploadTokens({
                    ...uploadTokens,
                    [id]: data.upload.token,
                });
                setAttachments((attachments) => ({
                    ...attachments,
                    [id]: { ...attachments[id], isUploading: false },
                }));
            } else {
                setErrorMessage(
                    data.errorMessage || uploadRequest.responseText
                );
                deleteAttachment(id);
            }
        };

        // only triggers if the request couldn't be made at all
        uploadRequest.onerror = () => {
            setErrorMessage(lt('networkError'));
            deleteAttachment(id);
        };

        uploadRequest.send(file);
    };

    const onAttachmentDelete = async (id: string) => {
        const uploadToken = uploadTokens[id];
        if (uploadToken) {
            try {
                const response = await fetch(
                    `/api/ui/v2/support/attachments/${uploadToken}`,
                    {
                        method: 'DELETE',
                    }
                );
                if (!response.ok) {
                    const data = await response.json();
                    setErrorMessage(data.errorMessage || response.statusText);
                    return;
                }
            } catch (err) {
                ErrorSnackbarCatcher(err, setErrorMessage);
                return;
            }
        }

        deleteAttachment(id);
        setUploadTokens((uploadTokens) => {
            delete uploadTokens[id];
            return { ...uploadTokens };
        });
    };

    const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const ticket: TicketForm = {
            subject: subject,
            body: buildTicketMessage(message),
            org_id: org._id,
            orgName: org.name,
            severity: severity,
            collaborators: ccSelection.map((c) => {
                const role = roles.find(r => r.email === c)
                if (role && role.is_oidc_user) {
                    const emailParts = c.split("@");
                    if (emailParts[0].indexOf("+") < 0) {
                      return `${emailParts[0]}+${role.user_id}@${emailParts[1]}`;
                    } else {
                      return `${emailParts[0]}_${role.user_id}@${emailParts[1]}`;
                    }
                }
                return c;
            }),
            reason:
                enTranslations.categories[
                    category as keyof translations['categories']
                ],
        };
        const uploads = Object.values(uploadTokens);
        if (uploads) {
            ticket.uploads = uploads;
        }
        try {
            await submitTicket({
                variables: {
                    ticket,
                },
            });
            setSubject('');
            setMessage('');
            setAttachments({});
            setUploadTokens({});
            setCCSelection([]);
            setShowMessageSentScreen(true);
        } catch (err) {
            ErrorSnackbarCatcher(err, setErrorMessage);
        }
    };

    const userEmails = roles.filter((role) => role.email !== session.email).map((r)=> r.email)

    const handleChange = (event: any) => {
        setCCSelection(event.target.value);
      };

     if (showMessageSentScreen) {
        return (
            <Fade in={true}>
                <Box mt={11}>
                    <EmptyState
                        imageFile="Empty-MessageSent.svg"
                        title={lt('messageSent')}
                        description={lt('willGetBackToYou')}
                        button={{
                            text: lt('backToForm'),
                            onClick: () => {
                                setShowMessageSentScreen(false);
                            },
                        }}
                    />
                </Box>
            </Fade>
        );
    }

    const highSevDisabled = org.support_level < 300;
    const buildTicketMessage = (msg : string) => {
        const currentPath = window.location;
        return `Origin: ${currentPath}
        
        ${msg}`;
    }

    const makeSubjectMessagePanel = (messageLabel: string, messagePlaceholder: string, messageRows: number, autoFocusMessage?: boolean) => (
        <>
            <Grid item>
                <FormControl fullWidth>
                    <TextField
                        data-test='contactUs-subject'
                        fullWidth
                        required
                        label={lt('subject')}
                        multiline
                        value={subject}
                        variant="outlined"
                        onChange={(e) =>
                            setSubject(e.target.value as string)
                        }
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <TextField
                        data-test='contactUs-messageContent'
                        fullWidth
                        required 
                        autoFocus={autoFocusMessage}
                        label={messageLabel}
                        placeholder={messagePlaceholder}
                        multiline
                        rows={messageRows}
                        variant="outlined"
                        onChange={(e) =>
                            setMessage(e.target.value as string)
                        }
                        inputProps={{
                            maxLength: 1000,
                        }}
                    />
                    <FormHelperText
                        className={classes.charlimit}
                    >{`${message.length}/1000`}</FormHelperText>
                </FormControl>
            </Grid>
        </>
    )

    const createTicketPanel = (
        <>
            <Grid item>
                <Typography variant="body2">{lt('sendAsOrgMessage', {orgName: org?.name})}</Typography>
            </Grid>
            <Grid item>
                <TextField
                    select
                    fullWidth
                    label={t('ContactUs:severity')}
                    value={severity}
                    variant="outlined"
                    helperText={highSevDisabled ? lt('upgradeYourPlan', {plan: getSupportLabel()}) : ''}
                    onChange={(e) =>
                        setSeverity(e.target.value as Severities)
                    }
                >
                    {Object.keys(Severities).map((sevName) => {
                        return (
                            <MenuItem
                                key={`contactus-sev-${sevName}`}
                                value={sevName}
                                disabled={(sevName === 'productionImpact' && org.support_level < 200) || (sevName === 'businessCritical' && org.support_level < 300)}
                            >
                                {t(`ContactUs:severities.${sevName}`)}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
            <Grid item>
                <TextField
                    select
                    fullWidth
                    label={t('ContactUs:reason')}
                    value={category}
                    variant="outlined"
                    onChange={(e) =>
                        setCategory(e.target.value as Category)
                    }
                >
                    {Object.keys(enTranslations.categories).map(
                        (catName) => (
                            <MenuItem
                                key={`contactus-cat-${catName}`}
                                value={catName}
                            >
                                {t(`ContactUs:categories.${catName}`)}
                            </MenuItem>
                        )
                    )}
                </TextField>
            </Grid>
            {makeSubjectMessagePanel(lt('message'), '', 6)}
            <Grid item>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">{lt('ccOrgMembers')}</InputLabel>
                        <Select
                            className={classes.ccSelection}
                            disabled={userEmails.length === 0}
                            id="ccTicket-checkbox"
                            multiple
                            labelWidth={150}
                            value={ccSelection}
                            onChange={handleChange}
                            renderValue={(selected: any) => {return (selected[0].concat(selected.length > 1 ? lt('othersCount', {ccCount: selected.length - 1}) : ''))}}
                            >
                            {userEmails.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox color="primary" checked={ccSelection.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            <Grid item>
                <Attachments
                    attachments={attachments}
                    uploadFile={uploadFile}
                    onAttachmentDelete={onAttachmentDelete}
                />
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar
                setMessage={setErrorMessage}
                message={errorMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <form onSubmit={(e) => handleOnSubmit(e)}>
                <Grid
                    item
                    container
                    direction="column"
                    spacing={3}
                    classes={{ root: classes.container }}
                >
                    {shareFeedback ? makeSubjectMessagePanel(lt('feedback'), lt('feedbackPlaceholder'), 24, true) : createTicketPanel}
                    <Grid item container justify="flex-end">
                        <Button
                            onClick={closeHelpCenter}
                            color="inherit"
                            size="large"
                            disabled={false}
                        >
                            {lt('cancel')}
                        </Button>
                        <div className={classes.buttonWrapper}>
                            <Button
                                className={classes.sendButton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={disableSave || loading2}
                            >
                                {lt('send')}
                            </Button>
                            {loading2 && (
                                <div className={classes.buttonProgress}>
                                    <CircularProgress size={24} />
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '5px 40px 0',
        width: 'auto',
        margin: 0,
    },
    charlimit: {
        textAlign: 'right',
    },
    attachmentArea: {
        backgroundColor: '#F2F2F2',
        height: 70,
        borderRadius: 3,
    },
    attachmentText: {
        marginLeft: theme.spacing(2.5),
    },
    choseFileButton: {
        marginRight: theme.spacing(2.5),
    },
    sendButton: {
        marginLeft: theme.spacing(3),
    },
    buttonWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '20%',
        left: '50%',
    },
    ccMembers: {
        marginTop: theme.spacing(2)
    },
    ccSelection: {
        width: '100%',
        textOverflow: 'ellipsis'
    }
}));

interface translations {
    description: string;
    reason: string;
    severity: string;
    subject: string;
    message: string;
    attachments: string;
    chooseFile: string;
    cancel: string;
    send: string;
    free: string;
    basic: string;
    select: string;
    priority: string;
    categories: {
        enterprisePlanEnquiry: string;
        salesEnquiry: string;
        productAssist: string;
        feedback: string
        reportBug: string;
        limitIncrease: string;
        privateLink: string;
        cancelAccount: string;
    };
    severities: {
        feedback: string
        generalGuidance: string;
        systemImpaired: string;
        nonProductionSystemDown: string;
        productionImpact: string;
        businessCritical: string;
    };
    messageSent: string;
    willGetBackToYou: string;
    backToForm: string;
    upgradeYourPlan: string;
    networkError: string;
    ccOrgMembers: string
    sendAsOrgMessage: string
    othersCount: string
    feedback: string
    feedbackPlaceholder: string
    productFeedback: string
}

const enTranslations: translations = {
    description:
        'We are available to help with anything from general questions to deep technical requests. Select a category and type in your request below.',
    reason: 'Reason',
    severity: 'Severity',
    subject: 'Subject',
    message: 'Message',
    attachments: 'Attachments',
    chooseFile: 'Choose File',
    cancel: 'Cancel',
    send: 'Send',
    free: 'Free',
    basic: 'Basic',
    select: 'Select',
    priority: 'Priority',
    categories: {
        enterprisePlanEnquiry: 'Enterprise Plan Enquiry',
        salesEnquiry: 'Sales Enquiry',
        productAssist: 'Product Assistance',
        feedback: 'Feedback',
        reportBug: 'Report a Bug',
        limitIncrease: 'Limit Increase',
        privateLink: 'PrivateLink Access',
        cancelAccount: 'Cancel Account',
    },
    severities: {
        feedback: 'Feedback',
        generalGuidance: 'General Guidance',
        systemImpaired: 'System Impaired',
        nonProductionSystemDown: 'Non-Production System Down',
        productionImpact: 'Production Impact',
        businessCritical: 'Production Outage - Business Critical',
    },
    messageSent: 'Message Sent!',
    willGetBackToYou: "We'll get back to you soon.",
    backToForm: 'BACK TO FORM',
    upgradeYourPlan: 'You are currently on the {{plan}} support level. Upgrade to escalate the severity of this ticket.',
    networkError: 'Network Error',
    ccOrgMembers: 'CC Org Users (optional)',
    sendAsOrgMessage: 'You are sending this ticket on behalf of the {{orgName}} organization.',
    othersCount: ' & {{ccCount}} other(s)',
    feedback: 'Feedback',
    feedbackPlaceholder: 'Type your feedback here...',
    productFeedback: 'Product feedback'
};
