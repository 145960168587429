import { useApolloClient, useQuery } from '@apollo/client';
import { capitalize, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DisplayTable } from '../../../components/DisplayWrappers';
import { DisplayTableRecord } from '../../../components/DisplayWrappers/DisplayTableRow';
import { EnPlansTranslations, OrganizationData, OrganizationQuery, OrganizationVars, PlanName, PlansData, PlansEnum, PlansQuery, PlansTranslations } from '../../../models';
import { ComplianceDetails } from '../../../models/planCompliance';

interface Props {
    plan: PlanName
    complianceDetails: ComplianceDetails
}

export const PlanNonCompliance: React.FC<Props> = ({ plan, complianceDetails }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'PlanNonCompliance', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`PlanNonCompliance:${key}`, interpolate);
    const { org_id } = useParams<any>();

    const client = useApolloClient();
    const { organization } = client.cache.readQuery<OrganizationData, OrganizationVars>({ query: OrganizationQuery, variables: { id: org_id } })!;

    const {
        loading,
        data: {
            plans
        } = { plans: [] }
    } = useQuery<PlansData>(PlansQuery);

    const planLimits = plans.find(currentPlan => currentPlan.level === plan)?.limits;

    if (loading || !planLimits) {
        return <CircularProgress />
    }

    let records: DisplayTableRecord[] = [];
    let lockRecords: DisplayTableRecord[] = [];

    const addTableEntry = (count: number, object: string, location: string | undefined, limit: number) => {
        let description = lt('limitItem', { count, object });
        if (location) {
            description = lt('inLocation', { description, location });
        }
        records.push({
            columns: [
                { value: description },
                { value: limit },
                { value: count - limit }
            ]
        });
    };

    const addLockTableEntry = (description: string, location: string | undefined, resourceExceeded: string) => {
        if (location) {
            description = lt('inLocation', {description, location});
        }
        lockRecords.push({
            columns:[
                { value: description },
                { value: resourceExceeded }
            ]
        });
    }

    if (complianceDetails.roles) {
        addTableEntry(complianceDetails.roles.count, lt('roles'), lt('yourOrganization'), planLimits.roles.per.org);
    }
    if (complianceDetails.consortia) {
        addTableEntry(complianceDetails.consortia.count, lt('consortia'), undefined, 1);
    }
    if (complianceDetails.decentralizedConsortia) {
        addTableEntry(complianceDetails.decentralizedConsortia.consortia.length, lt('decentralizedConsortia'), undefined, 0);
    }
    if (complianceDetails.environments) {
        for (const environmentLimit of complianceDetails.environments) {
            addTableEntry(environmentLimit.count, lt('environments'), environmentLimit.consortia_id, 1);
        }
    }
    if (complianceDetails.lockedEnvironments) {
        for (const lockedEnvironmentList of complianceDetails.lockedEnvironments) {
            for (const environment of lockedEnvironmentList.environment_ids) {
                addLockTableEntry(lt('specificEnvironment', {id: environment}), lt('specificConsortium', {id: lockedEnvironmentList.consortia_id}), lt('lifetime', {object: lt('environment'), time: lt('days', {time: '60'})}))
            }
        }
    }
    if (complianceDetails.appcreds) {
        for (const appCredLimit of complianceDetails.appcreds) {
            addTableEntry(appCredLimit.count, lt('appCreds'), appCredLimit.environment_id, planLimits.keys.per.environment);
        }
    }
    if (complianceDetails.providers) {
        for (const [provider, providerDetails] of Object.entries(complianceDetails.providers)) {
            if (Array.isArray(providerDetails)) {
                addTableEntry(providerDetails.length, lt('provider', { provider: capitalize(provider) }), undefined, 0);
            }
        }
    }
    if (complianceDetails.nodes) {
        if (complianceDetails.nodes.size) {
            if (complianceDetails.nodes.size.medium) {
                addTableEntry(complianceDetails.nodes.size.medium.length, lt('mediumNodes'), undefined, 0);
            }
            if (complianceDetails.nodes.size.large) {
                addTableEntry(complianceDetails.nodes.size.large.length, lt('largeNodes'), undefined, 0);
            }
        }
        if (complianceDetails.nodes.count) {
            for(const nodeCountLimit of complianceDetails.nodes.count) {
                addTableEntry(nodeCountLimit.count, lt('nodes'), nodeCountLimit.environment_id, 2);
            }
        }
    }
    if (complianceDetails.services) {
        for (const serviceLimit of complianceDetails.services) {
            addTableEntry(serviceLimit.count, lt('services'), serviceLimit.environment_id, 2);
        }
    }
    if (complianceDetails.configurations) {
        for (const [configuration, configurationDetails] of Object.entries(complianceDetails.configurations)) {
            if (Array.isArray(configurationDetails)) {
                addTableEntry(configurationDetails.length, lt('configuration', { configuration: capitalize(configuration) }), undefined, 0);
            }
        }
    }
    if (complianceDetails.enforce_mfa) {
        addTableEntry(1, lt('enforceMFA'), undefined, 0);
    }

    const columnHeaders = [
        lt('currentPlan', { plan: lt(PlansEnum[organization.plan]) }),
        lt('planAllowed', { plan: lt(PlansEnum[plan]) }),
        lt('remove')
    ];

    const lockColumnHeaders = [
        lt('affectedResource'),
        lt('runtimeResourceExceeded')
    ]

    const createRecordTable = (suggestion: keyof translations, headers: string[], records: DisplayTableRecord[]) => {
        if (records.length) return (
            <>
                <Grid item>
                    <Typography variant="body1">
                        {lt(suggestion)}
                    </Typography>
                </Grid>
                <Grid item>
                    <DisplayTable columnHeaders={headers} records={records} />
                </Grid>
             </>
        )
        return;
    }

    return (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('oneMomentPlease')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {lt('limitDescription', { plan: lt(PlansEnum[plan]) })}
                </Typography>
            </Grid>
            {createRecordTable('removeSuggestion', columnHeaders, records)}
            {createRecordTable('removeLockSuggestion', lockColumnHeaders, lockRecords)}
        </>
    );
};

interface translations extends PlansTranslations {
    oneMomentPlease: string
    limitDescription: string
    removeSuggestion: string
    removeLockSuggestion: string
    runtimeResourceExceeded: string
    affectedResource: string,
    limitItem: string
    inLocation: string
    appCreds: string
    environment: string
    environments: string
    specificEnvironment: string
    specificConsortium: string
    lifetime: string
    days: string
    roles: string
    yourOrganization: string
    services: string
    mediumNodes: string
    largeNodes: string
    nodes: string
    provider: string
    consortia: string
    decentralizedConsortia: string
    configuration: string
    currentPlan: string
    planAllowed: string
    enforceMFA: string
    remove: string
}

const enTranslations: translations = {
    ...EnPlansTranslations,
    oneMomentPlease: 'One Moment Please',
    limitDescription: 'The {{plan}} plan comes with usage restrictions. Please ensure that all restrictions are met before selecting this plan.',
    removeSuggestion: 'Please remove the following before you can continue:',
    removeLockSuggestion: 'The following exceeds the usage allocations for the {{plan}} plan and must be removed before you can continue:',
    runtimeResourceExceeded: 'Allocation Exceeded',
    affectedResource: 'Affected Resource(s)',
    limitItem: '{{count}} {{object}}',
    inLocation: '{{description}} in {{location}}',
    appCreds: 'App credentials',
    environment: 'Environment',
    environments: 'Environments',
    specificEnvironment: 'Environment {{id}}',
    specificConsortium: 'Network {{id}}',
    lifetime: '{{object}} Lifetime: {{time}}',
    days: '{{time}} Days',
    roles: 'Roles',
    yourOrganization: 'your organization',
    services: 'Services',
    mediumNodes: 'Medium node(s)',
    largeNodes: 'Large node(s)',
    nodes: 'Nodes',
    provider: '{{provider}} provider(s)',
    consortia: 'Network',
    decentralizedConsortia: 'Decentralized network',
    configuration: '{{configuration}} configuration',
    currentPlan: 'Current {{plan}} Plan',
    planAllowed: '{{plan}} plan allowed',
    enforceMFA: 'MFA cannot be set as a requirement',
    remove: 'Remove'
}
