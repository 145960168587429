import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const BurnHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenBurnHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`TokenBurnHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Token-Burn.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            docLink="https://docs.kaleido.io/kaleido-services/token-factory/usage/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
}
const enTranslations: translations = {
    helpHeader: 'Burn Token',
    helpDescription: "Burning tokens will take tokens from the account's current balance and destroy them. These tokens can not be recovered. In order to burn tokens, it is needed to:",
    helpDesc1: "Input the amount of tokens you want to burn from your current balance.",
}