import React, {isValidElement} from 'react';
import { makeStyles, Button, Grid, Divider, Typography } from "@material-ui/core";
import { DisplayGridWrapper } from '../DisplayWrappers';
import { useTranslation } from 'react-i18next';
import { CopyableSettings, CopyableSetting } from '.';

export interface EditableSettingItem {
    icon?: JSX.Element,
    title: string | JSX.Element,
    description?: string | JSX.Element,
    value?: string | JSX.Element,
    buttonLabel?: string,
    buttonTestData?: string,
    disabledButton?: boolean,
    hiddenButton?: boolean,
    action: () => void,
    extraButtons?: JSX.Element[],
    subvalues?: { // displays multiple columns of key<br/>value<br/>value<br/>...
        [name: string]: string[]
    },
    subvaluesAction?: {
        action: () => void,
        label: string,
        disabled?: boolean
    },
    copyableSettings?: CopyableSetting[] // displays a single column of key: value using the CopyableSettings component,
}

export interface EditableSetting  {
    icon?: JSX.Element,
    title: string,
    description?: string,
    value?: string | JSX.Element,
    buttonLabel?: string,
    buttonTestData?: string,
    disabledButton?: boolean,
    action: () => void,
    extraButtons?: JSX.Element[],
    subvalues?: {
        [name: string]: string[]
    },
    subvaluesDivider?: boolean
}

interface Props {
    header?: string | JSX.Element,
    description?: string,
    imagePath?: string,
    reducePadding?: boolean,
    actionsList: EditableSettingItem[],
    copyableSettingsBottomPadding?: boolean,
    hidePaper?: boolean
};

export const EditableSettings : React.FC<Props> = ({ header, actionsList, description, hidePaper = false, children, reducePadding, copyableSettingsBottomPadding }) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EditableSettings', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EditableSettings:${key}`, interpolate)

    const classes = useStyles();

    const content = (
        <Grid data-test="editableSettings" container direction="column">
            {actionsList.map((l, i) => (
                <React.Fragment key={`setting-${i}`}>
                    <Grid
                        item
                        container
                        key={i}
                        className={
                            reducePadding
                                ? classes.itemContainerPaddingReduce
                                : classes.itemContainer
                        }
                        wrap="nowrap"
                        justify="space-between"
                    >
                        <Grid
                            item
                            container
                            wrap="nowrap"
                            alignItems="center"
                            spacing={2}
                        >
                            {l.icon && <Grid item>{l.icon}</Grid>}
                            <Grid item container direction="column" spacing={1}>
                                {l.title &&
                                    (isValidElement(l.title) ? (
                                        l.title
                                    ) : (
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {l.title}
                                            </Typography>
                                        </Grid>
                                    ))}
                                {l.description &&
                                    (isValidElement(l.description) ? (
                                        l.description
                                    ) : (
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {l.description}
                                            </Typography>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            spacing={2}
                            alignItems="center"
                            justify="flex-end"
                        >
                            <Grid item>
                                {isValidElement(l.value) ? (
                                    l.value
                                ) : (
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                    >
                                        {l.value}
                                    </Typography>
                                )}
                            </Grid>
                            {l.extraButtons &&
                                l.extraButtons.map((b, j) => (
                                    <Grid item key={`setting-${i}-button-${j}`}>
                                        {b}
                                    </Grid>
                                ))}
                            {!l.hiddenButton && (
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={l.action}
                                        disabled={l.disabledButton}
                                        data-test={l.buttonTestData}
                                    >
                                        {l.buttonLabel || lt('edit')}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {l.subvalues && Object.keys(l.subvalues).length !== 0 && (
                        <>
                            <Divider
                                variant="middle"
                                className={classes.subvaluesDivider}
                            />
                            <Grid
                                item
                                container
                                className={
                                    reducePadding
                                        ? classes.itemContainerPaddingReduce
                                        : classes.itemContainer
                                }
                                justify="space-between"
                                wrap="nowrap"
                                alignItems="center"
                            >
                                <Grid item>
                                    {Object.keys(l.subvalues).length !== 0 && (
                                        <React.Fragment>
                                            <Grid
                                                item
                                                container
                                                className={
                                                    reducePadding
                                                        ? classes.itemContainerPaddingReduce
                                                        : classes.itemContainer
                                                }
                                                spacing={2}
                                                justify="space-between"
                                                alignContent="space-between"
                                                alignItems="flex-start"
                                            >
                                                {Object.keys(l.subvalues).map(
                                                    (k) => (
                                                        <React.Fragment
                                                            key={`setting-${i}-${k}`}
                                                        >
                                                            <Grid
                                                                item
                                                                className={
                                                                    classes.subvaluesGrid
                                                                }
                                                                container
                                                                direction="row"
                                                                xs={12}
                                                                sm={6}
                                                                md={4}
                                                                lg={
                                                                    Object.keys(
                                                                        l.subvalues!
                                                                    ).length > 5
                                                                        ? 3
                                                                        : true
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Typography
                                                                        color="textPrimary"
                                                                        variant="body1"
                                                                    >
                                                                        {k}
                                                                    </Typography>
                                                                </Grid>
                                                                {l.subvalues![
                                                                    k
                                                                ].map(
                                                                    (v, j) => (
                                                                        <Grid
                                                                            key={`setting-${i}-${k}-v-${j}`}
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                            >
                                                                                {
                                                                                    v
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    )
                                                                )}
                                                            </Grid>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                                {l.subvaluesAction && (
                                    <Grid
                                        item
                                        container
                                        justify="flex-end"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                onClick={
                                                    l.subvaluesAction.action
                                                }
                                                disabled={
                                                    l.subvaluesAction.disabled
                                                }
                                            >
                                                {l.subvaluesAction.label ||
                                                    lt('view')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                    {l.copyableSettings && (
                        <>
                            <Grid
                                container
                                direction="column"
                                className={
                                    copyableSettingsBottomPadding
                                        ? classes.copyableSettingsContainerPaddingBottom
                                        : classes.copyableSettingsContainer
                                }
                            >
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <CopyableSettings
                                    copyableList={l.copyableSettings}
                                />
                            </Grid>
                        </>
                    )}
                    {i !== actionsList.length - 1 && <Divider />}
                </React.Fragment>
            ))}
            {children}
        </Grid>
    );

    return (
        <DisplayGridWrapper {...{header}} {...{description}} displayGrid={content} {...{hidePaper}} />
    )
};

const useStyles = makeStyles(theme => ({
    itemContainer: {
        padding: theme.spacing(3),
    },
    itemContainerPaddingReduce: {
        padding: theme.spacing(2, 3)
    },
    subvaluesDivider: {
        marginLeft: theme.spacing(6)
    },
    subvaluesGrid: {
        marginLeft: theme.spacing(3)
    },
    copyableSettingsContainer: {
        padding: theme.spacing(0, 6)
    },
    copyableSettingsContainerPaddingBottom: {
        padding: theme.spacing(0, 6),
        paddingBottom: theme.spacing(4)
    }
}));

interface translations {
    edit: string
    view: string
}
const enTranslations: translations = {
    edit: 'Edit',
    view: 'View'
}

