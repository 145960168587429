/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

//This component displays a list of the countries that are currently supported by stripe
//https://stripe.com/docs/api/country_specs/list

interface Props {
    country: string;
    setCountry: React.Dispatch<React.SetStateAction<string>>;
}

export default function StripeCountrySelector({ country, setCountry }: Props) {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "CountrySelect", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`CountrySelect:${key}`, interpolate);

    const [stripeCountry, setStripeCountry] = useState(country);

    useEffect(() => {
        //To make sure we comply with the new Stripe API we need to make sure that the new country is set with country code
        const countryByCode = supportedCountries.find(
            (c) => c.code === country
        );
        if (countryByCode) {
            setCountry(countryByCode.code);
            setStripeCountry(countryByCode.code);
            return;
        }

        const countryByLabel = supportedCountries.find(
            (c) => c.label === country
        );
        if (countryByLabel) {
            setCountry(countryByLabel.code);
            setStripeCountry(countryByLabel.code);
        } else {
            setCountry("");
            setStripeCountry("");
        }
    }, [country, setCountry]);

    return (
        <TextField
            id="country-select-demo"
            inputProps={{
                autoComplete: "new-password", // disable autocomplete and autofill
            }}
            label={lt("selectCountry")}
            value={stripeCountry}
            onChange={(e) => {
                setCountry(e.target.value);
                setStripeCountry(e.target.value);
            }}
            variant="outlined"
            select
            fullWidth
        >
            {supportedCountries.map((c) => (
                <MenuItem value={c.code} key={c.code}>
                    {lt(c.code)}
                </MenuItem>
            ))}
        </TextField>
    );
}

interface translations {
    selectCountry: string,
    AU: string,
    AT: string,
    BE: string,
    BG: string,
    BR: string,
    CA: string,
    CY: string,
    CZ: string,
    DK: string,
    EE: string,
    FI: string,
    FR: string,
    DE: string,
    GI: string,
    GR: string,
    HK: string,
    HU: string,
    HR: string,
    IN: string,
    IE: string,
    IT: string,
    JP: string,
    LI: string,
    LV: string,
    LT: string,
    LU: string,
    MY: string,
    MT: string,
    MX: string,
    NL: string,
    NZ: string,
    NO: string,
    PL: string,
    PT: string,
    RO: string,
    SG: string,
    SK: string,
    SI: string,
    ES: string,
    SE: string,
    CH: string,
    TH: string,
    AE: string,
    GB: string,
    US: string
}
const enTranslations: translations = {
    selectCountry: "Select Country",
    AU: "Australia",
    AT: "Austria",
    BE: "Belgium",
    BG: "Bulgaria",
    BR: "Brazil",
    CA: "Canada",
    HR: "Croatia",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    EE: "Estonia",
    FI: "Finland",
    FR: "France",
    DE: "Germany",
    GI: "Gibraltar",
    GR: "Greece",
    HK: "Hong Kong",
    HU: "Hungary",
    IN: "India",
    IE: "Ireland",
    IT: "Italy",
    JP: "Japan",
    LV: "Latvia",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MY: "Malaysia",
    MT: "Malta",
    MX: "Mexico ",
    NL: "Netherlands",
    NZ: "New Zealand",
    NO: "Norway",
    PL: "Poland",
    PT: "Portugal",
    RO: "Romania",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    ES: "Spain",
    SE: "Sweden",
    CH: "Switzerland",
    TH: "Thailand",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States"
};

const supportedCountries: {
    code: stripeSupportedCountriesTypes;
    label: string;
}[] = [
  {label: 'Australia', code: 'AU'},
  {label: 'Austria', code: 'AT'},
  {label: 'Belgium', code: 'BE'},
  {label: 'Bulgaria', code: 'BG'},
  {label: 'Brazil ', code: 'BR'},
  {label: 'Canada', code: 'CA'},
  {label: 'Croatia', code: 'HR'},
  {label: 'Cyprus', code: 'CY'},
  {label: 'Czech Republic', code: 'CZ'},
  {label: 'Denmark', code: 'DK'},
  {label: 'Estonia', code: 'EE'},
  {label: 'Finland', code: 'FI'},
  {label: 'France', code: 'FR'},
  {label: 'Germany', code: 'DE'},
  {label: 'Gibraltar', code: 'GI'},
  {label: 'Greece', code: 'GR'},
  {label: 'Hong Kong', code: 'HK'},
  {label: 'Hungary', code: 'HU'},
  {label: 'India', code: 'IN'},
  {label: 'Ireland', code: 'IE'},
  {label: 'Italy', code: 'IT'},
  {label: 'Japan', code: 'JP'},
  {label: 'Latvia', code: 'LV'},
  {label: 'Liechtenstein', code: 'LI'},
  {label: 'Lithuania', code: 'LT'},
  {label: 'Luxembourg', code: 'LU'},
  {label: 'Malaysia', code: 'MY'},
  {label: 'Malta', code: 'MT'},
  {label: 'Mexico ', code: 'MX'},
  {label: 'Netherlands', code: 'NL'},
  {label: 'New Zealand', code: 'NZ'},
  {label: 'Norway', code: 'NO'},
  {label: 'Poland', code: 'PL'},
  {label: 'Portugal', code: 'PT'},
  {label: 'Romania', code: 'RO'},
  {label: 'Singapore', code: 'SG'},
  {label: 'Slovakia', code: 'SK'},
  {label: 'Slovenia', code: 'SI'},
  {label: 'Spain', code: 'ES'},
  {label: 'Sweden', code: 'SE'},
  {label: 'Switzerland', code: 'CH'},
  {label: 'Thailand', code: 'TH'},
  {label: 'United Arab Emirates', code: 'AE'},
  {label: 'United Kingdom', code: 'GB'},
  {label: 'United States', code: 'US'}
];

type stripeSupportedCountriesTypes =
    | "US"
    | "AU"
    | "AT"
    | "BE"
    | "BG"
    | "BR"
    | "CA"
    | "CY"
    | "CZ"
    | "DK"
    | "EE"
    | "FI"
    | "FR"
    | "DE"
    | "GI"
    | "GR"
    | "HK"
    | "HU"
    | "HR"
    | "IN"
    | "IE"
    | "IT"
    | "JP"
    | "LI"
    | "LV"
    | "LT"
    | "LU"
    | "MY"
    | "MT"
    | "MX"
    | "NL"
    | "NZ"
    | "NO"
    | "PL"
    | "PT"
    | "RO"
    | "SG"
    | "SK"
    | "SI"
    | "ES"
    | "SE"
    | "CH"
    | "TH"
    | "AE"
    | "GB";
   
