import { Checkbox, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CreateWrapper, MessageSnackbar } from '../../../components/DialogWrappers';
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector';
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { CreateStepProps, ConsortiumResourceVars } from '../../../interfaces';
import { FabricStep1Help } from './FabricStep1Help';
import { UploadBinary, doUpload } from "../../../components/ContractManagement/UploadBinary";
import { useQuery } from "@apollo/client";
import { ContractProjectData, ContractProjectQuery } from "../../../models";

interface Props extends CreateStepProps {
    description: string,
    setDescription: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
};

export const FabricStep1 = ({ description, setDescription, membershipId, setMembershipId, loading, setLoading, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationFabricStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationFabricStep1:${key}`, interpolate)

    const { consortium_id, contract_id } = useParams<any>();
    const { pathname } = useLocation();
    const history = useHistory();

    const {
        data: {
            contractProject
        } = { contractProject: null }
    } = useQuery<ContractProjectData, ConsortiumResourceVars>(ContractProjectQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: contract_id!
        },
        fetchPolicy: 'cache-only' 
    });

    const [file, setFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [reqInitialization, setReqInitialization] = useState(false);

    const disabled = !membershipId || !description || !file

    const save = () => {
        const splitPathname = pathname.split('/')
        const baseCompilationsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_COMPILED_CONTRACTS_PATH) + 1).join('/')
        doUpload({
            consortium_id,
            contract_id,
            description,
            membershipId,
            file: file!,
            init_required: reqInitialization,
            isFabric: true,
            setLoading,
            setErrorMessage,
            onComplete: (compiledContractId: string) => history.push(`${baseCompilationsPath}/${compiledContractId}`),
        });
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item >
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            <Grid item>
                <TextField 
                    data-test="compilationDescription"
                    required
                    multiline
                    rows={2}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>

            <UploadBinary {...{loading}} {...{file}} {...{setFile}} accept={contractProject?.type === 'fabric_precompiled_go' ? '.bin' : '.zip,.tar,.gz,.tgz'}/>
            <Grid item>
                <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={reqInitialization}
                                onChange={e => setReqInitialization(e.target.checked)}
                                value='reqInitialization'/>
                        }
                        label={lt('reqInitialization')}
                        labelPlacement="end"
                        />
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {lt('reqInitializationDesc')}
                        </Typography>                
            </Grid>
        </>
    )

    return (
        <>
            <MessageSnackbar setMessage={setErrorMessage} message={errorMessage} />
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep isLastStep />
            <FabricStep1Help type={contractProject!.type} />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    description: string,
    selectFile: string,
    selectFileHeader: string,
    selectFileDescription: string,
    reqInitialization: string,
    reqInitializationDesc: string
}
const enTranslations: translations = {
    header: 'Enter Chaincode Details',
    headerDescription: 'Select the Membership who will own this version and give this specific version a description.',
    description: 'Description',
    selectFile: 'Select file',
    selectFileHeader: 'Select a file',
    selectFileDescription: 'Select the linux complied go binary to upload',
    reqInitialization: 'Require initialization before invocation',
    reqInitializationDesc: 'This adds the "--init-required" parameter to the lifecycle commands, which requires the chaincode to be initialized before it can process transactions.',

}
