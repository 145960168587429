import { useQuery } from '@apollo/client';
import { Grid, IconButton, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from 'mdi-react/RefreshIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { ResourceChart } from '../../components/Charts/ResourceChart';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { DisplayGridWrapper } from '../../components/DisplayWrappers';
import { ConsortiumResourceVars, EnvironmentResourceVars } from '../../interfaces';
import { EnvironmentData, EnvironmentQuery, NodeData, NodeQuery, NodeStatisticsData, NodeStatisticsQuery, NodeStatisticsVars, NodeTxPoolStatusData, NodeTxPoolStatusQuery, NodeTxPoolStatusVars, RuntimeStatistics } from '../../models';

const REFRESH_TIMEOUT = 2500

export const Metrics = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NodeMetricsMetrics', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NodeMetricsMetrics:${key}`, interpolate)

    const { consortium_id, environment_id, node_id } = useParams<any>();
    
    const [message, setMessage] = useState('');
    const [disableRefresh, setDisableRefresh] = useState(false);

    // refresh the metrics using a timeout with a visual indicator that its refreshing
    const timeoutRef = useRef<number>(0)
    useEffect(() => {
        return () => window.clearTimeout(timeoutRef.current);
    }, []);
    const handleRefresh = () => {
        window.clearTimeout(timeoutRef.current);
        setDisableRefresh(true)
        
        Promise.all([refetchTxPoolStatus(), refetchNodeStatistics()]).then(() => {
            timeoutRef.current = window.setTimeout(() => {
                setDisableRefresh(false)
            }, REFRESH_TIMEOUT)
        })
    }

    const {
        data: { 
            environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });
    const isCorda = environment?.isCorda;
    const isFabric = environment?.isFabric;

    const nodeVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!, 
        node_id: node_id!
    }

    const {
        refetch: refetchTxPoolStatus,
        loading: txPoolStatusLoading,
        data: {
            txPoolStatus
        } = { txPoolStatus: null },
        error: txPoolStatusError
    } = useQuery<NodeTxPoolStatusData, NodeTxPoolStatusVars>(NodeTxPoolStatusQuery, {
        skip: isCorda || isFabric,
        variables: nodeVars,
        fetchPolicy: 'cache-and-network'
    });

    const {
        refetch: refetchNodeStatistics,
        loading: nodeStatisticsLoading,
        data: {
            nodeStatistics
        } = { nodeStatistics: null },
        error: nodeStatisticsError
    } = useQuery<NodeStatisticsData, NodeStatisticsVars>(NodeStatisticsQuery, {
        variables: {
            ...nodeVars, 
            hours: 72,
            interval: 5
        },
        fetchPolicy: 'cache-and-network'
    });

    const {
        data: { 
            node
        } = { node: null } 
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, { 
        variables: { 
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: node_id!
        },
        fetchPolicy: 'cache-only'
    });

    // show error if the query fails
    useEffect(() => {
        const e = nodeStatisticsError || txPoolStatusError
        if (e) {
            ErrorSnackbarCatcher(e, setMessage)
        }
    }, [nodeStatisticsError, txPoolStatusError]);

    const wrapChartWithLoading = (chart: JSX.Element, header: string, description: string) => {
        if (nodeStatisticsLoading && !nodeStatistics?.data?.length) {
            return (
                <DisplayGridWrapper hideDivider {...{header}} {...{description}} padDisplayGrid displayGrid={<CircularProgress />}/>
            )
        } else if ((nodeStatistics?.data?.length ?? 0) <= 1) {
            const notEnoughData = <Typography variant="body2">{lt('notEnoughData')}</Typography>
            return (
                <DisplayGridWrapper {...{header}} {...{description}} padDisplayGrid displayGrid={notEnoughData}/>
            )
        }
        return (
            <DisplayGridWrapper hideDivider {...{header}} {...{description}} displayGrid={chart}/>
        )
    }

    const txPoolContent = (
        <Grid item container>
            {
                [
                    {title: lt('pendingTransactions'), value: txPoolStatus?.pending ?? 0},
                    {title: lt('queuedTransactions'), value: txPoolStatus?.queued ?? 0},
                ].map((l, i) => (
                    <Grid item key={i} xs={6}>
                        <List>
                            <ListItem>
                                <div>
                                    <ListItemText primary={l.title} primaryTypographyProps={{variant: "overline"}}/>
                                    <ListItemText secondary={l.value} secondaryTypographyProps={{variant: "body2"}} />
                                </div>
                            </ListItem>
                        </List>
                    </Grid>
                ))
            }
        </Grid>
    )

    // txPoolStatusError should not prevent renders
    const preventRenderError = nodeStatisticsError ? true : false
    const refreshIsDisabled = nodeStatisticsLoading || txPoolStatusLoading || disableRefresh
                
    return (
        <>
            <MessageSnackbar {...{message}} {...{setMessage}} />
            <Grid container direction="column" spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {lt('nodeMetrics')}
                        </Typography>
                    </Grid>
                    {!preventRenderError &&
                    <Grid item>
                        <IconButton color="primary" size={refreshIsDisabled ? "small" : "medium"} disabled={refreshIsDisabled}
                                    onClick={() => handleRefresh()} >
                            { refreshIsDisabled ? <CircularProgress color="inherit" /> : <RefreshIcon /> }
                        </IconButton>
                    </Grid>
                    }
                </Grid>

                {!preventRenderError && 
                <Grid item container spacing={3}>
                    {!isCorda && !isFabric && <Grid item container xs={6}>
                        <DisplayGridWrapper header={lt('transactionDetails')} displayGrid={txPoolContent} />
                    </Grid> }
                    <Grid item container>
                        {wrapChartWithLoading(
                            <ResourceChart height='25vh' allRuntimes={[{_id: node_id!, name: node?.name ?? ''}]} 
                                runtimesForChart={nodeStatistics ? [nodeStatistics] : [] as RuntimeStatistics[]} 
                                chart='cpu' />, 
                            lt('cpu'), 
                            lt('cpuDescription'))
                        }
                    </Grid>

                    <Grid item container>
                        {wrapChartWithLoading(
                            <ResourceChart height='25vh' allRuntimes={[{_id: node_id!, name: node?.name ?? ''}]} 
                                runtimesForChart={nodeStatistics ? [nodeStatistics] : [] as RuntimeStatistics[]} 
                                chart='memory' />, 
                            lt('memory'), 
                            lt('memoryDescription'))
                        }
                    </Grid>

                    <Grid item container>
                        {wrapChartWithLoading(
                            <ResourceChart height='25vh' allRuntimes={[{_id: node_id!, name: node?.name ?? ''}]} 
                                runtimesForChart={nodeStatistics ? [nodeStatistics] : [] as RuntimeStatistics[]} 
                                chart='storage' />, 
                            lt('storage'), 
                            lt('storageDescription'))
                        }
                    </Grid>
                </Grid>
                }
            </Grid>
        </>
    )
};

interface translations {
    nodeMetrics: string,
    time: string,
    cpu: string,
    cpuDescription: string,
    memory: string,
    memoryDescription: string,
    storage: string,
    storageDescription: string,
    notEnoughData: string,
    transactionDetails: string,
    pendingTransactions: string,
    queuedTransactions: string
}
const enTranslations: translations = {
    nodeMetrics: 'Node Metrics',
    time: 'Time',
    cpu: 'CPU',
    cpuDescription: 'This node\'s CPU utilization over the past 24 hours.',
    memory: 'Memory',
    memoryDescription: 'This node\'s memory (RAM) utilization over the past 24 hours.',
    storage: 'Disk',
    storageDescription: 'This node\'s amount of disk usage over the past 24 hours.',
    notEnoughData: 'This node has not been running long enough to collect any metrics data. Try again later.',
    transactionDetails: 'Transaction Details',
    pendingTransactions: 'Pending transactions',
    queuedTransactions: 'Queued transactions'
}