import React, { useState } from 'react';
import { CreateStepProps } from "../../../interfaces";
import { Step1Help } from './Step1Help'
import { CreateWrapper, MessageSnackbar } from '../../../components/DialogWrappers';
import { Grid, makeStyles, Typography, RadioGroup, FormControl, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { UpdateBillingProviderMutation, UpdateBillingProviderVars, MakeUpdateBillingCreateMutationOptions, UpdateBillingProviderData, BillingProviderData, BillingProviderQuery } from '../../../models/billing';
import { useParams, useHistory } from 'react-router-dom';
import { ORGS_PATH, MANAGE_ORG_PATH, MANAGE_ORG_PAYMENT_METHOD_PATH, SETUP_BILLING_PROVIDER_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, CHANGE_PLAN_PATH } from '../../../components/ManageOrgNav/ManageOrgNav';
import { FormRadio } from '../../../components/FormControls/FormRadio';

type PaymentType = 'paygo' | 'contract';

export const Step1 = ({ cancelPath }: CreateStepProps) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BillingProviderStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BillingProviderStep1:${key}`, interpolate)
    const { org_id } = useParams<any>();
    const history = useHistory();
    const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>('paygo');
    const [contractCode, setContractCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [updateBillingProvider] = useMutation<UpdateBillingProviderData, UpdateBillingProviderVars>(UpdateBillingProviderMutation);

    const {
        data: {
            billingProvider
        } = { billingProvider: { type: 'none' } }
    } = useQuery<BillingProviderData>(BillingProviderQuery, { variables: { org_id } });

    const handlePaymentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPaymentType(event.target.value as PaymentType);
    };

    const content = (
        <>
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    {lt('selectPaymentMethod')}
                </Typography>
            </Grid>
            <Grid item container direction="column">
                <Typography variant="h6" gutterBottom>
                    {lt('selectPaymentType')}
                </Typography>
                <FormControl component="fieldset" margin="none">
                    <RadioGroup value={selectedPaymentType} onChange={handlePaymentTypeChange}>
                        <Grid container direction="column" spacing={1}>
                            <FormRadio selected={selectedPaymentType === 'paygo'} value="paygo" label={lt('payAsYouGo')} description={lt('payAsYouGoDescription')} />
                            <FormRadio selected={selectedPaymentType === 'contract'} value="contract" label={lt('contract')} description={lt('contractDescription')} />
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
            {selectedPaymentType === 'paygo' &&
                <Grid item container direction="column">
                    <Typography variant="h6" gutterBottom>
                        {lt('selectPaymentMethod')}
                    </Typography>
                    <FormControl component="fieldset" margin="none">
                        <RadioGroup value={'creditCard'}>
                            <Grid container direction="column" spacing={1}>
                                <FormRadio selected={true} value="creditCard" label={lt('payAsYouGo')} description={lt('payAsYouGoDescription')} />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>}
            {selectedPaymentType === 'contract' &&
                <Grid item container direction="column">
                    <Typography variant="h6" gutterBottom>
                        {lt('joinExistingContract')}
                    </Typography>
                    <Typography variant="body2" className={classes.joinContractMessage}>{lt('joinExistingContractDescription')}</Typography>
                    <TextField label={lt('contractCode')} variant="outlined" value={contractCode} onChange={event => setContractCode(event.target.value)} />
                </Grid>}
        </>);

    const disabled = selectedPaymentType === 'contract' && !contractCode;

    const save = () => {
        if (selectedPaymentType === 'paygo') {
            history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}/${SETUP_BILLING_PROVIDER_PATH}/2`);
        } else {
            updateBillingProvider(MakeUpdateBillingCreateMutationOptions({ org_id: org_id!, billingProvider: { type: 'other', id: contractCode } })).then(() => {
                if (billingProvider.type !== 'none') {
                    history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_PAYMENT_METHOD_PATH}`);
                } else {
                    history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`);
                }
            }).catch(() => {
                setErrorMessage(lt('invalidContractCode'));
            });
        }
    };

    return (
        <>
            <MessageSnackbar message={errorMessage} setMessage={setErrorMessage} />
            <CreateWrapper cancelPath={cancelPath} {...{ content }} {...{ disabled }} onNext={save} isFirstStep isLastStep={selectedPaymentType === 'contract'} />
            <Step1Help />
        </>);
};


const useStyles = makeStyles(() => ({
    joinContractMessage: {
        marginBottom: 20
    }
}));

interface translations {
    selectPaymentMethod: string
    selectPaymentType: string
    payAsYouGo: string
    payAsYouGoDescription: string
    contract: string
    contractDescription: string
    creditCard: string
    creditCardDescription: string
    joinExistingContract: string
    joinExistingContractDescription: string
    contractCode: string
    submitCode: string
    invalidContractCode: string
}
const enTranslations: translations = {
    selectPaymentMethod: 'Select Payment Method',
    selectPaymentType: 'Select Payment Type',
    payAsYouGo: 'Pay As You Go',
    payAsYouGoDescription: 'Monthly charge based on prior month\'s usage.',
    contract: 'Contract',
    contractDescription: 'Annual agreement with additional savings.',
    creditCard: 'Credit Card',
    creditCardDescription: 'Secure money transfer using your bank card.',
    joinExistingContract: 'Join Existing Contract',
    joinExistingContractDescription: 'Have a contract code? Enter it here to join an existing contract.',
    contractCode: 'Contract Code',
    submitCode: 'Submit Code',
    invalidContractCode: 'Invalid Contract Code'
}