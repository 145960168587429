import { useQuery } from '@apollo/client';
import CloudDownloadOutlineIcon from 'mdi-react/CloudDownloadOutlineIcon';
import CloudUploadOutlineIcon from 'mdi-react/CloudUploadOutlineIcon';
import EmailReceiveOutlineIcon from 'mdi-react/EmailReceiveOutlineIcon';
import EmailSendOutlineIcon from 'mdi-react/EmailSendOutlineIcon';
import TransitConnectionVariantIcon from 'mdi-react/TransitConnectionVariantIcon';
import SetSplitIcon from 'mdi-react/SetSplitIcon';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Kpi } from '../../../components/DisplayWrappers';
import { AllApp2AppMetricsData, AllApp2AppMetricsQuery, AllApp2AppMetricsVars, App2AppClientsData, App2appClientsQuery, ServiceData, ServiceQuery } from '../../../models';
import { ServiceResourcesVars, EnvironmentResourceVars } from '../../../interfaces';

interface Props {
    allRuntimes: { _id: string, name: string }[]
    forDashboard?: boolean // for the docstore dashboard format
}

export const MessagingKPI = ({ allRuntimes, forDashboard } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsB2BMessagingKPI', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ChartsB2BMessagingKPI:${key}`, interpolate)

    const { consortium_id, environment_id } = useParams<any>();

    const environmentVars = {
        consortia_id: consortium_id!, 
        environment_id: environment_id!
    }

    // kpi is always based on the current month / year
    const thisMonth = moment().month() + 1
    const thisYear = moment().year()

    const {
        data: {
            allApp2AppMetrics
        } = { allApp2AppMetrics: [] },
    } = useQuery<AllApp2AppMetricsData, AllApp2AppMetricsVars>(AllApp2AppMetricsQuery, {
        variables: {
            ...environmentVars, 
            ids: allRuntimes.map(r => r._id),
            month: thisMonth,
            year: thisYear
        },
        fetchPolicy: 'cache-and-network'
    });

    const { data: { service } = { service: null } } = useQuery<
        ServiceData,
        EnvironmentResourceVars
    >(ServiceQuery, {
        variables: {
            ...environmentVars,
            id: allRuntimes.find(() => true)?._id ?? '',
        },
        fetchPolicy: "cache-only",
        skip: !forDashboard
    });

    const {
        data: {
            app2appClients
        } = { app2appClients: null }
    } = useQuery<App2AppClientsData, ServiceResourcesVars>(App2appClientsQuery, {
        variables: {
            service_id: allRuntimes.find(() => true)?._id ?? '',
        },
        fetchPolicy: 'network-only',
        pollInterval: forDashboard ? 20000 : undefined,
        skip: !forDashboard || service?.state !== 'started'
    });

    let messagesSent = 0
    let messagesReceived = 0
    let kbSent = 0
    let kbReceived = 0
    let chunksSent = 0
    let chunksReceived = 0
    let connectedClients = app2appClients?.length ?? 0

    allApp2AppMetrics.forEach(d => {
        d.metrics.forEach(m => {
            messagesSent += m.messages_sent
            messagesReceived += m.messages_received
            kbSent += (m.bytes_sent / 1000)
            kbReceived += (m.bytes_received / 1000)
            chunksSent += m.chunks_sent
            chunksReceived += m.chunks_received
        })
    })

    const kpiData = [
        {
            key: 'kpi-messagesSent',
            value: messagesSent,
            label: lt('messagesSent'),
            icon: <EmailSendOutlineIcon/>
        },
        {
            key: 'kpi-messagesReceived',
            value: messagesReceived,
            label: lt('messagesReceived'),
            icon: <EmailReceiveOutlineIcon/>
        },
        {
            key: 'kpi-kbsent',
            value: kbSent.toFixed(2),
            label: lt('kbSent'),
            icon: <CloudUploadOutlineIcon/>
        },
        {
            key: 'kpi-kbreceived',
            value: kbReceived.toFixed(2),
            label: lt('kbReceived'),
            icon: <CloudDownloadOutlineIcon/>
        },
        {
            key: 'kpi-chunkssent',
            value: chunksSent,
            label: lt('chunksSent'),
            icon: <SetSplitIcon/>
        },
        {
            key: 'kpi-chunksreceived',
            value: chunksReceived,
            label: lt('chunksReceived'),
            icon: <SetSplitIcon/>
        },
    ];

    if (forDashboard) {
        kpiData.unshift({
            key: 'kpi-connectedClients',
            value: connectedClients,
            label: lt('connectedClients'),
            icon: <TransitConnectionVariantIcon/>
        })
    }

    return <Kpi data={kpiData} />;
};

interface translations {
    messagesSent: string,
    messagesReceived: string,
    kbSent: string,
    kbReceived: string,
    chunksSent: string,
    chunksReceived: string,
    connectedClients: string
}
const enTranslations: translations = {
    messagesSent: 'Messages Sent',
    messagesReceived: 'Messages Received',
    kbSent: 'KB Sent',
    kbReceived: 'KB Received',
    chunksSent: 'Chunks Sent',
    chunksReceived: 'Chunks Received',
    connectedClients: 'Connected Clients'
}