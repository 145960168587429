import { TablePagination } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from "../../utils/StringUtils";
import { DisplayTable } from '../DisplayWrappers';
import { DisplayTableRecord } from '../DisplayWrappers/DisplayTableRow';
import { ContractProject, EnContractProjectTypeTranslations, ContractProjectTypeTranslations } from '../../models'
import { NETWORK_CONTRACTS_PATH } from "../MainNav/ConsortiumWrapper";
import { useHistory, useParams } from "react-router-dom";

const PAGINATION_OPTIONS = [10, 25];

interface Props {
    header?: string
    description?: string
    apps: ContractProject[]
}

export const getProtocolName = (appType: string) => {
    const name = appType.startsWith('fabric') ? 'fabric' : appType === 'corda_jar' ? 'corda' : 'ethereum';
    return name;
} 

export const AppsTable = ({ header, description, apps = []}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsTable', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsTable:${key}`, interpolate)
    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>()
    
    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_OPTIONS[0]);
    const [page, setPage] = useState(0);
    let columnHeaders = [
        '',
        lt('name'),
        lt('appId'),
        lt('type'),
        lt('source'),
        lt('owner'),
        lt('createdAt')
    ]

    let records: DisplayTableRecord[] = [];
    apps.forEach((app : ContractProject) => {
        let record: DisplayTableRecord = {
            key: app._id,
            onClick: ()=>{history.push(`/orgs/${org_id}/consortia/${consortium_id}/${NETWORK_CONTRACTS_PATH}/${app._id}`)},
            columns: [
                {isIcon: true, value: <img src={`${process.env.PUBLIC_URL}/img/protocols/TableIcons/icon-${getProtocolName(app.type)}.svg`} alt=""></img>            },
                {value: app.name || '-'},
                {value: app._id || '-'},
                {value: capitalize(getProtocolName(app.type))},
                {value: lt(app.type)},
                {value: app.membership.name},
                {value: new Date(app.created_at).toLocaleString() || ''}
            ] 
        }
        records.push(record);
    });

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const pagination = (
        <TablePagination rowsPerPageOptions={PAGINATION_OPTIONS} component="div" count={ apps.length } 
            rowsPerPage={rowsPerPage} page={page} 
            onChangeRowsPerPage={handleChangeRowsPerPage} onPageChange={handleChangePage}  />
    )

    return (
        <DisplayTable emptyLabel={lt('empty')}
            actionFooter={pagination} {...{header}} {...{description}}
            {...{columnHeaders}} records={records.splice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)} />
    )
};

interface translations extends ContractProjectTypeTranslations{
    header: string,
    description: string,
    appId: string,
    name: string,
    empty: string,
    createdAt: string,
    type: string,
    source: string,
    owner: string
}
const enTranslations: translations = {
    ...EnContractProjectTypeTranslations,
    header: 'apps',
    description: 'Description',
    appId: 'App ID',
    name: 'Name',
    empty: 'No apps found.',
    createdAt: 'Created At',
    type: 'Type',
    source: 'Source',
    owner: 'Owner'
}