import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers';

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateRotateSignersHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateRotateSignersHelp:${key}`, interpolate)

    return (
        <Help
            imagePath={`${process.env.PUBLIC_URL}/img/EmptyStates/Empty-RotateSigners.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('bullet1')}
            bullet2={lt('bullet2')}
            bullet3={lt('bullet3')}
            docLink="https://docs.kaleido.io/kaleido-services/rotate-signers/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    bullet1: string
    bullet2: string
    bullet3: string
}
const enTranslations: translations = {
    helpHeader: 'Rotate Signers',
    helpDescription: 'The rotate signers service is an inclusion and optimization service for environments running IBFT consensus.',
    bullet1: 'Kaleido caps the active signers limit at 16 to ensure the stability and performance of the algorithm.',
    bullet2: 'This service allows for environments with more than 16 signing nodes to achieve equitable participation in the voting and block signing processes.',
    bullet3: 'For environments with 16 or fewer signing nodes, the active signer count can be lowered to optimize the algorithm’s threads while still maintaining maximum byzantine fault tolerance.'
}