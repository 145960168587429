import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from "react-router-dom";
import {
    ServicesData, ServicesQuery, IDRegistryOrgsData, IDRegistryOrgsQuery, IDRegistryOrgDetailsQuery,
    IDRegistryOrgDetailsVars, IDRegistryOrgDetailsData, IDRegistryProfilePropertiesQuery,
    IDRegistryProfilePropertiesData, IDRegistryProfilePropertiesVars} from '../../../models'
import { useTranslation } from 'react-i18next';
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { ServiceResourcesVars } from '../../../interfaces';
import { DisplayTable, EmptyState } from '../../../components/DisplayWrappers';
import LocationOnIcon from 'mdi-react/MapMarkerIcon';
import { ADDRESSBOOK_MEMBERSHIP_PATH, ADDRESSBOOK_DESTINATIONS_PATH, ADDRESSBOOK_PATH } from '../../../components/MainNav/SideNavs/AddressBook';

export const MemberDestinations: React.FC = () => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    i18n.addResourceBundle('en', 'MemberDestinations', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MemberDestinations:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, membership_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const idRegistryServiceID = services.find(entry => entry.service === 'idregistry' && entry.state === 'started')?._id;

    const {
        loading: loadingIdRegistryOrgs,
        data: {
            idRegistryOrgs
        } = { idRegistryOrgs: [] }
    } = useQuery<IDRegistryOrgsData, ServiceResourcesVars>(IDRegistryOrgsQuery, {
        variables: { service_id: idRegistryServiceID! },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryServiceID
    });

    const idRegistryMembershipOrgId = idRegistryOrgs.find(org => org.name.endsWith(membership_id!))?.id;

    const {
        loading: loadingIdRegistryOrgDetails,
        data: {
            idRegistryOrgDetails
        } = { idRegistryOrgDetails: null }
    } = useQuery<IDRegistryOrgDetailsData, IDRegistryOrgDetailsVars>(IDRegistryOrgDetailsQuery, {
        variables: { service_id: idRegistryServiceID!, id: idRegistryMembershipOrgId },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryMembershipOrgId
    });

    const idRegistryMembershipOrgOwner = idRegistryOrgDetails?.owner;

    const {
        loading: loadingIdRegistryProfileProperties,
        data: {
            idRegistryProfileProperties
        } = { idRegistryProfileProperties: [] }
    } = useQuery<IDRegistryProfilePropertiesData, IDRegistryProfilePropertiesVars>(IDRegistryProfilePropertiesQuery, {
        variables: { service_id: idRegistryServiceID!, owner: idRegistryMembershipOrgOwner },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryMembershipOrgOwner
    });

    if (!idRegistryServiceID) {
        return <EmptyState
            title={lt('destinations')}
            description={lt('noDestinationsInEnv')}
            imageFile='Empty-Destination.svg'
            documentation={{text: lt('documentation'), link: 'https://docs.kaleido.io/kaleido-services/document-store/'}}
        />
    }

    if ((loadingIdRegistryOrgs && idRegistryOrgs.length === 0) || (loadingIdRegistryOrgDetails && idRegistryOrgDetails === null) ||
        (loadingIdRegistryProfileProperties && idRegistryProfileProperties.length === 0)) {
        return <CircularProgress />
    }


    const destinations = idRegistryProfileProperties.filter(entry => /^kld:\/\/documentstore|app2app\//.test(entry.name))
        .map(destination => ({
            key: destination.name,
            onClick: () => {
                history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}/` +
                    `${membership_id}/${ADDRESSBOOK_DESTINATIONS_PATH}/${encodeURIComponent(destination.name)}`);
            },
            columns: [
                { value: <LocationOnIcon /> },
                { value: destination.name.substr(destination.name.lastIndexOf('/') + 1) },
                { value: lt(destination.name.startsWith('kld://app2app') ? 'app2app' : 'documentstore') },
                { value: destination.name },
                { value: new Date(Number(destination.values[0].version)).toLocaleString() }
            ]
        }));
    
    if (destinations.length === 0) {
        return <EmptyState
            title={lt('destinations')}
            description={lt('noDestinationsForMember')}
            imageFile='Empty-Destination.svg'
        />
    }

    const columnHeaders = [
        '',
        lt('name'),
        lt('type'),
        lt('uri'),
        lt('lastUpdate')
    ];

    return (
        <Grid item container direction={"column"} spacing={3}>
            <Grid item>
                <Typography variant="h5">
                    {lt('destinations')}
                </Typography>
            </Grid>
            <Grid item>
                <DisplayTable header={lt('allDestinations')} columnHeaders={columnHeaders} records={destinations} />
            </Grid>
        </Grid>
    )
};

interface translations {
    destinations: string
    allDestinations: string
    name: string
    type: string
    uri: string
    lastUpdate: string
    app2app: string
    documentstore: string
    noDestinationsInEnv: string
    noDestinationsForMember: string
    documentation: string
}

const enTranslations: translations = {
    destinations: 'Destinations',
    allDestinations: 'All Destinations',
    name: 'Name',
    type: 'Type',
    uri: 'URI',
    lastUpdate: 'Last update',
    app2app: 'App2App',
    documentstore: 'Document Exchange',
    noDestinationsInEnv: 'No destinations have been configured yet in this environment',
    noDestinationsForMember: 'This member has not created any destinations yet',
    documentation: 'Documentation',
}