import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { SolcVersionsData, SolcVersionsQuery } from '../../models';

interface Props {
    solcVersion: string,
    setSolcVersion: React.Dispatch<React.SetStateAction<string>>,
    disabled: boolean
};

export const SolcVersionSelector = ({ solcVersion, setSolcVersion, disabled }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SolcVersionSelector', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SolcVersionSelector:${key}`, interpolate)

    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        if (inputLabel.current)
            setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel])

    const {
        data: {
            solcVersions: rawSolcVersions
        } = { solcVersions: [] }
    } = useQuery<SolcVersionsData>(SolcVersionsQuery);

    const solcVersions = rawSolcVersions.slice().map(v => ({ _id: v, name: v}))

    const availableCompilers = [{ _id: '0', name: lt('autoDetect') }, ...solcVersions]

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel}>
                {lt('selectSolcVersion')}
            </InputLabel>
            <Select {...{disabled}}
                labelWidth={labelWidth}
                value={solcVersion}
                onChange={e => setSolcVersion(e.target.value as string)}>
                {availableCompilers.map(c => (
                    <MenuItem key={c._id} selected={c._id === solcVersion} value={c._id}>
                        {c.name}
                    </MenuItem>
                ))
                }
            </Select>
        </FormControl>
    )
};

interface translations {
    selectSolcVersion: string,
    autoDetect: string
}
const enTranslations: translations = {
    selectSolcVersion: 'Compiler',
    autoDetect: 'Use version specified in source',
}