import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { AccountSelector } from '../../components/AccountSelector/AccountSelector';
import { Account, Node, getFromForGatewayAPI } from '../../models';
import { useTranslation } from 'react-i18next';
import { KALEIDO_TEMPLATE_CONTRACT_OPTIONS } from '../../components/ContractManagement/ContractTemplate';
import { ErrorSnackbarCatcher, MessageSnackbar } from '../../components/DialogWrappers';
import { CookieAppCred } from '../../components/CookieAppCred/CookieAppCred';

interface Props {
    node: Node
    endpoint: string
    setIsDeploying: React.Dispatch<React.SetStateAction<boolean>>
    isDeploying: boolean
    account: Account | undefined,
    setAccount: React.Dispatch<React.SetStateAction<Account | undefined>>
}

export const TokenDeploy = ({node, endpoint, setIsDeploying, isDeploying, account, setAccount}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'TokenDeploy', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`TokenDeploy:${key}`, interpolate), [t])

    const [name, setName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [initialSupply, setInitialSupply] = useState('');
    const [decimals, setDecimals] = useState('18')
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error' | 'success'>('error')
    const [cookieAppCred, setCookieAppCred] = useState('')

    const tokenType = useMemo(() => {
        for (let value of KALEIDO_TEMPLATE_CONTRACT_OPTIONS.values()) {
            if (value.endpoint === endpoint)
              return value.tokenType;
        }
        return '';
    }, [endpoint])

    const onSubmit = () => {
        setIsDeploying(true);
    }

    const deployToken = useCallback(async () => {
        const url = `${node.urls?.kaleido_connect}/gateways/${endpoint}`;
        const queryParams = `?kld-from=${getFromForGatewayAPI(account!)}&kld-sync=true`;
        const fullURl = url + queryParams

        const basicBody = {
            name,
            symbol
        }

        const body = tokenType === 'ERC20' ? {
            ...basicBody,
            initialSupply: parseInt(initialSupply),
            decimals: parseInt(decimals)
        } : basicBody

        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(cookieAppCred));
        
        try {
            const response = await fetch(fullURl, {
                method:'POST',
                body: JSON.stringify(body),
                headers: headers,
            });
            if (!response.ok) {
                throw new Error(lt('deploymentError', {status: `${response.status} ${response.statusText}`}));
            }
            const processResponse = await response.json();
            setMessage(lt('deploymentSuccessful', {address: processResponse.contractAddress}));
            setMessageType('success');
        } catch (err) {
            setMessageType('error')
            ErrorSnackbarCatcher(err, setMessage);
        } finally {
            setIsDeploying(false)
        }
    }, [account, cookieAppCred, decimals, endpoint, initialSupply, lt, name, node.urls, setIsDeploying, symbol, tokenType])

    useEffect(() => {
        if (isDeploying && cookieAppCred) {
            deployToken()
        }
    }, [isDeploying, cookieAppCred, deployToken])

    const disabled = isDeploying || !account || !name || !symbol || (tokenType === 'ERC20' && (!initialSupply || !decimals))

    return (
        <>
            <MessageSnackbar message={message} setMessage={setMessage} {...{messageType}} />
            { isDeploying &&
            <CookieAppCred
                membershipId={node.membership_id} 
                nodeId={node._id} {...{setCookieAppCred}} 
                setErrorMessage={setMessage} />
            }
            <Grid item>
                <AccountSelector fullWidth {...{account}} {...{setAccount}} membershipId={node.membership_id} />
            </Grid>
            <Grid item>
                <TextField fullWidth data-test="textField_tokenName"
                    label={lt('name')} 
                    value={name} 
                    onChange={e => setName(e.target.value)} 
                    variant="outlined" />
            </Grid>
            <Grid item>
                <TextField fullWidth data-test="textField_tokenSymbol"
                    label={lt('symbol')} 
                    value={symbol} 
                    onChange={e => setSymbol(e.target.value)} 
                    variant="outlined" />
            </Grid>
            {tokenType === 'ERC20' && 
                <>
                    <Grid item>
                        <TextField fullWidth data-test="textField_tokenInitialSupply"
                            label={lt('initialSupply')} 
                            type="number" 
                            value={initialSupply} 
                            onChange={e => setInitialSupply(e.target.value)} 
                            variant="outlined" />
                    </Grid>
                    <Grid item>
                        <TextField fullWidth
                            label={lt('decimals')} 
                            type="number" 
                            value={decimals} 
                            onChange={e => setDecimals(e.target.value)} 
                            variant="outlined" />
                    </Grid>
                </>}
            <Grid item>
                <Button fullWidth {...{disabled}} color="primary" size="large" variant="contained" onClick={onSubmit}>
                    {lt('deployToken')}
                </Button>
            </Grid>
        </>
    )
};

interface translations {
    initialSupply: string
    name: string
    symbol: string
    decimals: string
    deploymentError: string
    deployToken: string
    deploymentSuccessful: string
}

const enTranslations: translations = {
    initialSupply: 'Total Supply',
    name: 'Token Name',
    symbol: 'Token Symbol',
    decimals: 'Decimals',
    deploymentError: 'Deployment Error - {{status}}',
    deployToken: 'Deploy Token Instance',
    deploymentSuccessful: 'Deployment Successful. Contract address: {{address}}'
};