import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsortiumZone, ZoneRegion } from '../../models'
import { DisplayCard } from '../../components/DisplayWrappers'
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import MapMarkerMultipleOutlineIcon from 'mdi-react/MapMarkerMultipleOutlineIcon';
import { makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { NETWORK_REGIONS_PATH } from '../../components/MainNav/ConsortiumWrapper';

interface Props {
    consortiumZones: ConsortiumZone[],
    regions: ZoneRegion[],
    loading: boolean
};

// this component is only rendered if the plan supports multi region
export const Regions = ({ consortiumZones, regions, loading }: Props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'NetworkDashboardRegions', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`NetworkDashboardRegions:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id } = useParams<any>();

    const canAddRegion = useMemo(() => regions.length > consortiumZones.length, [consortiumZones.length, regions.length])

    const regionsList = consortiumZones.map(c => ({
        title: `${c.cloud.toUpperCase()}: ${c.region}`, 
        value: c.default ? lt('homeRegion') : lt('additionalRegion'),
        icon: c.default ? <MapMarkerOutlineIcon /> : <MapMarkerMultipleOutlineIcon />,
        actionIcon: <img className={classes.flag} src={`${process.env.PUBLIC_URL}/img/flags/4x3/${c.flag}.svg`} alt=""></img>
    }))

    const linkButton = canAddRegion ? {
        text: lt('addRegion'),
        onClick: () => history.push(`/orgs/${org_id!}/consortia/${consortium_id!}/${NETWORK_REGIONS_PATH}/create/1`)
    } : undefined
    
    return (
        <DisplayCard {...{loading}}
            header={lt('regions', { count: regionsList.length })}
            itemList={regionsList}
            {...{linkButton}} />
    )
};

const useStyles = makeStyles(() => ({
    flag: {
        height: '30px',
        width: '30px'
    }
}));

interface translations {
    regions: string
    addRegion: string
    homeRegion: string
    additionalRegion: string
}
const enTranslations: translations = {
    regions: 'Enabled Regions ({{count}})',
    addRegion: 'Add Region',
    homeRegion: 'Home Region',
    additionalRegion: 'Additional Region'
}