import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ServiceData, ServiceQuery } from '../../models';
import { EnvironmentResourceVars } from '../../interfaces';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { KEY_MANAGEMENT_CLOUD_PATH } from '../../components/MainNav/SideNavs/KeyManagement';
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from 'mdi-react/FileDocumentIcon';
import KeyboardArrowRightIcon from 'mdi-react/ChevronRightIcon';
import { DisplayCard } from '../../components/DisplayWrappers';
import { ConfigQuery, ConfigData } from '../../models/configs';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { ConnectRuntimeWrapper } from '../../components/ConnectRuntime/ConnectRuntimeWrapper';

export const CloudHsmWalletDashboard = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CloudHsmDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CloudHsmDashboard:${key}`, interpolate)

    const { org_id, service_id, consortium_id, environment_id } = useParams<any>();

    const envVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_CLOUD_PATH}/`;

    const {
        data: {
            service
        } = { service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            ...envVariables,
            id: service_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        loading: configLoading,
        data: {
            config
        } = { config: null }
    }= useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...envVariables,
            id: service?.details.cloudhsm_id ?? ''
        },
        skip: !service
    });

    if(configLoading && !config) return <CircularProgress />
    if (!config || !service) return <Redirect to={basePath} />

    const copyableList: CopyableSetting[] = [{
        title: lt('name'),
        displayValue: service.name,
        disableCopy: true
    },   {
        title: lt('id'),
        displayValue: service._id ?? '',
        copyableValue: service_id ?? ''
    },  {
        title: lt('state'),
        displayValue: <ResourceStateChip state={service.state}  />,
        disableCopy: true
    }, {
        title: lt('membership'),
        displayValue: service.membership.name ?? '',
        disableCopy: true
    }, {
        title: lt('provider'),
        displayValue: config.details.provider ?? '',
        disableCopy: true
    }, {
        title: lt('userId'),
        displayValue: config.details.user_id ?? '',
        copyableValue: config.details.user_id ?? ''
    },  {
        title: lt('dateCreated'),
        displayValue: new Date(service.created_at).toLocaleString() ?? '',
        disableCopy: true
    }];

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt('cloudConfigurations'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>  window.open('https://docs.kaleido.io/using-kaleido/cloud-integrations/')
        },
        {
            icon: <DescriptionIcon />,
            title: lt('cloudhsm'),
            value: lt('documentation'),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-services/cloudhsm/') 
        }
    ];

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('header')}</Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12}>
                    <CopyableSettings header={lt('details')} {...{copyableList}} />
                </Grid>
                <Grid container item lg={6}>
                    <ConnectRuntimeWrapper service={service} />
                </Grid>
                <Grid container item lg={6}>
                    <DisplayCard header={lt('documentation')} itemList={DocumentationList} />
                </Grid>
            </Grid>
        </Grid>
    )
    
}

interface translations {
    header: string
    name: string
    details: string
    cloudConfigurations: string
    cloudhsm: string
    documentation: string
    provider: string
    membership: string
    dateCreated: string
    id: string
    userId: string
    state: string
}
const enTranslations: translations = {
    name: 'Name',
    details: 'Details',
    header: 'Cloud HSM Dashboard',
    cloudConfigurations: 'Cloud Configurations',
    cloudhsm: 'Cloud HSM',
    documentation: 'Documentation',
    membership: 'Membership',
    dateCreated: 'Date Created',
    id: 'ID',
    userId: 'User Id',
    provider: 'Provider',
    state: 'State'
};