import React, { useState } from "react"
import { useLocation } from 'react-router-dom';
import { VerifyEmail, LoginForm } from '../components/AuthForms';
import { useApolloClient } from "@apollo/client";
import { cognitoController } from '../utils/cognitoController';
import { Region } from '../interfaces';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { MFAChallenge } from "../components/AuthForms/MFAChallenge";
import { HubspotEventEnum, hubspotIdentify, hubspotTrackEvent, hubspotTrackPageView, verifyEmailInHS } from "../utils/HubspotUtils";

interface Props {
    regions: Region[]
    ssoGoogle: boolean
}

const setTracking = (email: string) => {
    hubspotIdentify(email)
    hubspotTrackEvent(HubspotEventEnum.LoggedIn, email)
    hubspotTrackPageView()
}

export const Login: React.FC<Props> = ({ regions, ssoGoogle }) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'Login', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`Login:${key}`, interpolate);
    const apolloClient = useApolloClient();
    const { search } = useLocation();
    const { code } = queryString.parse(search);

    let codeFromQueryParams = '';
    if (code) {
        if (Array.isArray(code)) {
            codeFromQueryParams = code[0];
        } else {
            codeFromQueryParams = code;
        }
    }

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newCodeSent, setNewCodeSent] = useState(false);
    const [verificationRequired, setVerificationRequired] = useState(false);
    const [mfaRequired, setMFARequired] = useState(false);
    const [mfaCode, setMFACode] = useState('');

    const handleLogin = async () => {
        setErrorMessage('');
        if (username && password) {
            setLoading(true);
            try {
                await cognitoController.login(username, password, apolloClient, regions, mfaCode);
                setTracking(username)
            } catch (err) {
                if (err.code === 'UserNotConfirmedException') {
                    if (codeFromQueryParams) {
                        try {
                            await cognitoController.verifySignup(codeFromQueryParams, username, password, apolloClient, regions);
                            verifyEmailInHS(username)
                        } catch (err) {
                            setErrorMessage(err.message);
                            console.error(err)
                        }
                    } else {
                        setVerificationRequired(true);
                    }
                } else if (err.message === 'mfaRequired') {
                    setMFARequired(true);
                } else {
                    setErrorMessage(err.message);
                }
            } finally {
                setLoading(false);
            }
        } else {
            setErrorMessage(lt('userAndPasswordRequiredMessage'));
        }
    };

    const handleVerifyAccount = async () => {
        setLoading(true);
        try {
            await cognitoController.verifySignup(
                verificationCode,
                username,
                password,
                apolloClient,
                regions
            );
            verifyEmailInHS(username)
        } catch (err) {
            setErrorMessage(err.message);
            setLoading(false);
        }
    };

    const handleResendCode = async () => {
        setLoading(true);
        try {
            await cognitoController.resendCode(
                username,
                regions
            );
            setErrorMessage('');
            setNewCodeSent(true);
        } catch (err) {
            setErrorMessage(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (verificationRequired) {
        return (
            <VerifyEmail email={username}
                handleVerifyAccount={handleVerifyAccount}
                handleResendCode={handleResendCode}
                verificationCode={verificationCode}
                newCodeSent={newCodeSent}
                setVerificationCode={setVerificationCode}
                errorMessage={errorMessage}
                loading={loading} />
        );
    } else if (mfaRequired) {
        return (
            <MFAChallenge
                mfaCode={mfaCode}
                setMFACode={setMFACode}
                errorMessage={errorMessage}
                loading={loading}
                handleMFA={handleLogin}
            />
        );
    } else {
        return (
            <LoginForm username={username}
                password={password}
                setPassword={setPassword}
                setUsername={setUsername}
                handleLogin={handleLogin}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                loading={loading || !regions.length}
                ssoGoogle={ssoGoogle} />
        );
    }

}

interface translations {
    userAndPasswordRequiredMessage: string
}

const enTranslations: translations = {
    userAndPasswordRequiredMessage: 'Username and password are required',
}
