import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SetupBillingProviderStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`SetupBillingProviderStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-ContractProjects.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            bullet3={lt('helpDesc3')}
            docLink="https://docs.kaleido.io/using-kaleido/billing-provider/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    helpDesc3: string,
}
const enTranslations: translations = {
    helpHeader: 'Billing Provider',
    helpDescription: 'All Subscription plans require billing information to charge applicable usage, membership, and support fees, with the exception of the free Starter plan. Kaleido offers multiple billing options:',
    helpDesc1: 'Credit Card',
    helpDesc2: 'AWS Marketplace billing',
    helpDesc3: 'Invoice',
}