import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers';

export const Step2Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateDestinationStep2Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateDestinationStep2Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/Node_Settings.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/using-kaleido/environments"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
}
const enTranslations: translations = {
    helpHeader: 'Creating Destinations',
    helpDescription: 'We need to create a destination to allow you to send and recieve documents between yourself and other members in this environment. Kaleido will store a private key that will encrypt messages so that they can only be read by you.'
}