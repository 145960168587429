import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../../components/DialogWrappers';
import { FormControl, InputLabel, Select, MenuItem, TextField, Typography, Grid, IconButton } from '@material-ui/core';
import { Organization } from '../../models';
import { useMutation } from '@apollo/client';
import { CreateAPIKeyMutation, CreateAPIKeyVars, CreateAPIKeyData, MakeAPIKeyCreateMutationOptions } from '../../models/apiKeys';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import { Alert } from '../../components/FormControls/Alert';

interface Props {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    organizations: Organization[]
}

export const GenerateProfileAPIKey: React.FC<Props> = ({ open, setOpen, organizations }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'GenerateProfileAPIKey', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`GenerateProfileAPIKey:${key}`, interpolate);
    const [createAPIKey, { loading }] = useMutation<CreateAPIKeyData, CreateAPIKeyVars>(CreateAPIKeyMutation);
    const [selectedOrgId, setSelectedOrgId] = useState(organizations[0]._id);
    const [apiKeyName, setAPIKeyName] = useState('');
    const [apiKey, setAPIKey] = useState<string | undefined>();

    useEffect(() => {
        if (open) {
            setSelectedOrgId(organizations[0]._id);
            setAPIKey(undefined);
            setAPIKeyName('');
        }
    }, [open, organizations, setSelectedOrgId, setAPIKey, setAPIKeyName]);

    const handleCreate = () => {
        return createAPIKey(MakeAPIKeyCreateMutationOptions({ org_id: selectedOrgId, apiKey: { name: apiKeyName } })).then(({ data }) => {
            setAPIKey(data?.createAPIKey.apikey);
        });
    }

    let controlsWrapper;
    let hideSave;
    let cancelText;

    if (loading) {
        controlsWrapper = (
            <Typography>{lt('creatingAPIKey')}</Typography>
        );
        hideSave = true;
    } else if (apiKey) {
        controlsWrapper = (
            <Grid container spacing={2}>
                <Grid item container alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography>{apiKey}</Typography>
                    </Grid>
                    <Grid item>
                        <CopyToClipboard text={apiKey}>
                            <IconButton color="inherit" size="small">
                                <ContentCopyIcon />
                            </IconButton>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
                <Grid item>
                    <Alert severity="warning" title={lt('saveYourKey')} description={lt('warning')} />
                </Grid>
            </Grid>
        );
        hideSave = true;
        cancelText = lt('close');
    } else {
        controlsWrapper = (
            <Grid container spacing={2} direction="column">
                {organizations.length > 1 && (
                    <Grid item>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>{lt('organization')}</InputLabel>
                            <Select
                                value={selectedOrgId}
                                onChange={event => setSelectedOrgId(event.target.value as string)}
                                label={lt('organization')}>
                                {organizations.map(org => (
                                    <MenuItem key={org._id} value={org._id}>{org.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>)}
                <Grid item>
                    <TextField data-test="apiKeyName" label={lt('name')} variant="outlined" value={apiKeyName} onChange={event => setAPIKeyName(event.target.value)} fullWidth />
                </Grid>
            </Grid>
        );
        hideSave = false;
    }

    return <FormDialog open={open} setOpen={setOpen} header={lt('newAPIKey')} cancelText={cancelText}
        saveText={lt('generate')} controlsWrapper={controlsWrapper} onSave={handleCreate} hideSave={hideSave} />
}

interface translations {
    newAPIKey: string
    apiKeyValue: string
    generate: string
    organization: string
    name: string
    creatingAPIKey: string
    saveYourKey: string
    warning: string
    close: string
}

const enTranslations: translations = {
    newAPIKey: 'New API Key',
    apiKeyValue: 'API Key Value',
    generate: 'Generate',
    organization: 'Organization',
    name: 'Name',
    creatingAPIKey: 'Creating API Key...',
    saveYourKey: 'Save your key',
    warning: 'This is the last time your API Key will be displayed. Copy it and keep it safe.',
    close: 'Close'
}