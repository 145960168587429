import React, { useState, useEffect, useRef } from 'react';
import {
    ContactInfoProperties,
    UpdateContactInfoMutation,
    UpdateContactInfoVars,
    UpdateContactInfoData,
    MakeUpdateContactInfoMutationOptions,
    ContactInfoData,
    ContactInfoQuery,
} from '../models/contactInfo';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import { useHistory, Redirect } from 'react-router-dom';
import { OrganizationsQuery, OrganizationsData, UpdateOrganizationVars, UpdateOrganizationMutation } from '../models';
import { ORGS_PATH } from '../components/ManageOrgNav/ManageOrgNav';
import { SessionData, Region } from '../interfaces';
import { SessionQuery } from '../queries/Session';
import { verifyEmailInHS } from '../utils/HubspotUtils';
import { UserInfoFormV2 } from './UserInfoFormV2';

interface Props {
    regions: Region[];
}

export const UserInfo: React.FC<Props> = ({regions}) => {
    const _hsq = window._hsq

    const [loading, setLoading] = useState(false)

    const [updateContactInfo] = useMutation<UpdateContactInfoData, UpdateContactInfoVars>(UpdateContactInfoMutation);
    const [updateOrganization] = useMutation<undefined, UpdateOrganizationVars>(UpdateOrganizationMutation);

    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    const sendGoogleSignupToHS = useRef<boolean>(false)
    useEffect(() => {
        if (!sendGoogleSignupToHS.current && session.social_login && _hsq?.push) {
            sendGoogleSignupToHS.current = true
            verifyEmailInHS(session.email, regions.find(r => r.cplane === window.location.origin)?.region || regions.find(() => true)?.region, 'Google')
        }
    }, [session.email, session.social_login, regions, _hsq?.push])

    const {
        data: {
            contactInfo
        } = { contactInfo: null }
    } = useQuery<ContactInfoData>(ContactInfoQuery, { fetchPolicy: 'cache-first' });

    const {
        data: {
            organizations,
        } = { organizations: [] }
    } = useQuery<OrganizationsData>(OrganizationsQuery, { fetchPolicy: "cache-only" });

    const history = useHistory();

    if (organizations[0].plan !== 'signup') return <Redirect to="/" />;

    const handleSave = async (properties: ContactInfoProperties) => {
        setLoading(true);
        try {
            const ciProperties: UpdateContactInfoVars = {
                properties: {
                    ...properties,
                    kaleido_owned_org: session.selected_org,
                    lead_create_type: !contactInfo?.firstname ? 'Account Signup' : undefined,
                    account_signup_completed: true,
                    kaleido_email_confirmed: true,
                    kaleido_sign_in_type: session.social_login ? "Google" : "Kaleido"
                }
            }
            await updateContactInfo(MakeUpdateContactInfoMutationOptions(ciProperties));
            await updateOrganization({
                variables: {
                    organization: {
                        name: properties.company
                    },
                    id: session.selected_org
                }
            })
            setLoading(false);
            history.push(`/${ORGS_PATH}/${organizations[0]._id}/signup/plans`);
        } catch (err) {
            setLoading(false)
            console.error(err)
        }
    }

    return (
        <UserInfoFormV2 handleSave={handleSave} contactInfo={contactInfo} loading={loading} />
    )
};
