import { Grid, TextField, Typography } from "@material-ui/core";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { CreateWrapper } from '../../../components/DialogWrappers';
import { MembershipSelector } from '../../../components/FormControls/MembershipSelector';
import { NETWORK_COMPILED_CONTRACTS_PATH } from '../../../components/MainNav/ConsortiumWrapper';
import { CreateStepProps } from '../../../interfaces';
import { PrecompiledStep1Help } from './PrecompiledStep1Help';

interface Props extends CreateStepProps {
    description: string,
    setDescription: React.Dispatch<React.SetStateAction<string>>,
    membershipId: string,
    setMembershipId: React.Dispatch<React.SetStateAction<string>>,
};

export const PrecompiledStep1 = ({ description, setDescription, membershipId, setMembershipId, cancelPath }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ContractsCreateCompilationPrecompiledStep1', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ContractsCreateCompilationPrecompiledStep1:${key}`, interpolate)

    const history = useHistory()
    const { pathname } = useLocation()

    const disabled = !membershipId || !description

    const save = () => {
        const splitPathname = pathname.split('/')
        const baseCompilationsPath = splitPathname.slice(0, splitPathname.indexOf(NETWORK_COMPILED_CONTRACTS_PATH) + 1).join('/')
        history.push(`${baseCompilationsPath}/create/2`, {
            description,
            membershipId,
        })
    }

    const content = (
        <>
            <Grid item>
                <Typography variant="h5">
                    {lt('header')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {lt('headerDescription')}
                </Typography>
            </Grid>

            <Grid item >
                <MembershipSelector {...{membershipId}} {...{setMembershipId}} />
            </Grid>

            <Grid item>
                <TextField 
                    data-test="compilationDescription"
                    required
                    multiline
                    rows={2}
                    value={description} 
                    onChange={event => setDescription(event.target.value)}
                    fullWidth
                    margin="none"
                    label={lt('description')}
                    variant="outlined"
                />
            </Grid>
        </>
    )

    return (
        <>
            <CreateWrapper cancelPath={cancelPath} {...{content}} {...{disabled}} onNext={save} isFirstStep />
            <PrecompiledStep1Help />
        </>
    )
};

interface translations {
    header: string,
    headerDescription: string,
    description: string,
}
const enTranslations: translations = {
    header: 'Enter Version Details',
    headerDescription: 'Select the Membership who will own this version and give this specific version a description.',
    description: 'Description'
}
