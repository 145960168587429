import { useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, Typography, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormDialog, MessageSnackbar } from '../../components/DialogWrappers';
import { Region, OrgResourceVars } from '../../interfaces';
import { Agreement, BindAgreementData, BindAgreementVars, OpsBindAgreementMutation, OpsAgreementResolveQuery, OpsAgreementsResolveData, OpsUnBindAgreementMutation, OpsAgreementUpdateMutation, UnBindAgreementData, UpdateAgreementData, UpdateAgreementVars } from '../../models/agreements';
import { AgreementPanel } from './AgreementPanel';

interface Props {
    regions?: Region[]
}

export const OrgAgreementEditor: React.FC<Props> = ({regions}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'OrgAgreementEditor', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`OrgAgreementEditor:${key}`, interpolate)
    
    const {ops_org_id} = useParams<any>();
    
    const [message, setMessage] = useState('');
    const [agreement, setAgreement] = useState<Agreement | undefined>();
    const [bindOpen, setBindOpen] = useState(false);
    const [agreementName, setAgreementName] = useState('');
    const [unBindOpen, setUnBindOpen] = useState(false);

    const {
        data: {
            opsAgreementResolve: loadedAgreement
        } = { opsAgreementResolve: null },
        loading,
        error: fetchError,
        refetch,
    } = useQuery<OpsAgreementsResolveData, OrgResourceVars>(OpsAgreementResolveQuery, {
        skip: !ops_org_id,
        variables: {
            org_id: ops_org_id,
        },
        fetchPolicy: 'no-cache'
    });

    const [updateAgreementMutation, {loading: saving}] = useMutation<UpdateAgreementData, UpdateAgreementVars>(OpsAgreementUpdateMutation)
    const [bindAgreementMutation, {loading: binding}] = useMutation<BindAgreementData, BindAgreementVars>(OpsBindAgreementMutation)
    const [unBindAgreementMutation, {loading: unbinding}] = useMutation<UnBindAgreementData, OrgResourceVars>(OpsUnBindAgreementMutation)

    useEffect(() => {
        setMessage(fetchError ? fetchError.message : '');
    }, [setMessage, fetchError])

    useEffect(() => {
        if (loadedAgreement && loadedAgreement._id) setAgreement(loadedAgreement);
        else if (agreement) setAgreement(undefined);
    }, [agreement, loadedAgreement]);

    if (loading) return <CircularProgress />;

    const saveAgreement = async (agreement: Agreement, region?: string) => {
        const id = agreement._id!;
        const agreementUpdate = {
            ...agreement,
        }
        delete agreementUpdate._id;
        delete agreementUpdate.name;
        await updateAgreementMutation({
            variables: {
                id,
                agreement: agreementUpdate
            }
        });
        refetch();
    };

    const onBind = async () => {
        await bindAgreementMutation({
            variables: {
                org_id: ops_org_id,
                name: agreementName
            }
        });
        refetch();
    }

    const onUnBind = async () => {
        await unBindAgreementMutation({
            variables: {
                org_id: ops_org_id
            }
        });
        refetch();
    }

    return <>
        <MessageSnackbar {...{message}} {...{setMessage}} />
        <FormDialog header={lt('bind')} open={bindOpen} setOpen={setBindOpen} closeDialogAfterSave onSave={onBind} saveDisabled={!agreementName || binding} controlsWrapper={<>
            <TextField label={lt('agreementName')} variant="outlined" fullWidth value={agreementName} onChange={(e) => setAgreementName(e.target.value)}/>
        </>}/>
        <FormDialog header={lt('unbind')} open={unBindOpen} setOpen={setUnBindOpen} closeDialogAfterSave onSave={onUnBind} saveDisabled={unbinding} />
        <Grid container direction="row" spacing={3}>
            <Grid item>
                <Typography variant="h5">{lt('agreementFor', {org: ops_org_id})}</Typography>
            </Grid>
            <Grid item>
                {agreement
                    ? <Button variant="contained" color="primary" onClick={() => setUnBindOpen(true)}>{lt('unbind')}</Button>
                    : <Button variant="contained" color="primary" onClick={() => setBindOpen(true)}>{lt('bind')}</Button>
                }
            </Grid>
        </Grid>
        {agreement && <AgreementPanel {...{agreement}} {...{saveAgreement}} {...{regions}} loading={loading || saving || binding || unbinding}/>}
    </>;
};


interface translations {
    bind: string;
    unbind: string;
    agreementFor: string;
    agreementName: string;
}

const enTranslations: translations = {
    bind: 'Bind',
    unbind: 'Unbind',
    agreementFor: 'Agreement for organization {{org}}',
    agreementName: 'Agreement name',
};
