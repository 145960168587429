import React from 'react';
import { makeStyles } from '@material-ui/core';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
    const classes = useStyles();

    return (
        <div
            className={classes.root}
            role="tabpanel"
            hidden={value !== index}
            id={`help-center-tabpanel-${index}`}
        >
            {value === index && children}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'scroll',
        height: '100%',
    },
}));
