import React, { useState } from "react"
import { cognitoController } from '../utils/cognitoController';
import { CreateAccount, VerifyEmail } from '../components';
import { useApolloClient } from "@apollo/client";
import { Region } from '../interfaces';
import { HubspotEventEnum, hubspotIdentify, hubspotTrackEvent, hubspotTrackPageView, verifyEmailInHS } from "../utils/HubspotUtils";

interface SignupProps {
    regions: Region[]
    ssoGoogle: boolean
    disableRecaptcha: boolean
}

const setTracking = (email: string, region: string) => {
    hubspotIdentify(email, {
        kaleido_email_confirmed: false,
        kaleido_account_region: region
    })
    hubspotTrackEvent(HubspotEventEnum.SignedUp, email)
    hubspotTrackPageView()
};

export const Signup: React.FC<SignupProps> = ({ regions, ssoGoogle, disableRecaptcha }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState<'createAccount' | 'verifyAccount'>('createAccount');
    const [newCodeSent, setNewCodeSent] = useState(false);
    const [region, setRegion] = useState(regions[0]);
    const [recaptcha, setRecaptcha] = useState<string | null>('');
    const apolloClient = useApolloClient();

    const handleCreateAccount = async () => {
        setLoading(true);
        try {
            setErrorMessage('');
            await cognitoController.createAccount(
                email,
                password,
                region!,
                recaptcha ? recaptcha : '',
            );
            setTracking(email, region.region)
            setStep('verifyAccount');
        } catch (err) {
            setErrorMessage(err.message);
        } finally {
            setLoading(false);
        }
    }

    const handleVerifyAccount = async () => {
        setLoading(true);
        try {
            await cognitoController.verifySignup(
                verificationCode,
                email,
                password,
                apolloClient,
                regions
            );
            verifyEmailInHS(email, region.region)
        } catch (err) {
            setErrorMessage(err.message);
            setLoading(false);
        }
    };

    const handleResendCode = async () => {
        setLoading(true);
        try {
            await cognitoController.resendCode(
                email,
                regions
            );
            setErrorMessage('');
            setNewCodeSent(true);
        } catch (err) {
            setErrorMessage(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (step === 'createAccount') {
        return (
            <CreateAccount email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                handleCreateAccount={handleCreateAccount}
                loading={loading}
                errorMessage={errorMessage}
                regions={regions}
                region={region}
                setRegion={setRegion}
                ssoGoogle={ssoGoogle}
                disableRecaptcha={disableRecaptcha}
                recaptcha={recaptcha}
                setRecaptcha={setRecaptcha}
            />
        );
    } else {
        return (
            <VerifyEmail email={email}
                verificationCode={verificationCode}
                setVerificationCode={setVerificationCode}
                handleVerifyAccount={handleVerifyAccount}
                handleResendCode={handleResendCode}
                errorMessage={errorMessage}
                loading={loading}
                newCodeSent={newCodeSent}
            />
        );
    }

};