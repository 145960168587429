import { gql } from '@apollo/client';
import { ServiceResourcesVars } from '../interfaces';

export interface TetherAccountData {
    tetherAccount: {
        address: string
    }
}
export interface TetherBalanceData {
    tetherBalance: {
        address: string
        balance: number
    }
}

export interface DeployTetherContractData {
    arbitratorAddress: string
}


export interface VerifyReportData {
    verifyReport: {
        address: string
    }
}

export interface LastReportData {
    lastReport: {
        [key: string]: any
    }
}

export type tetherNetworkTypes = 'goerli' | 'mainnet';

export interface SetTetherAccountVars extends ServiceResourcesVars {
    privateKey: string
}

export interface TetherBalanceVars extends ServiceResourcesVars {
    network: tetherNetworkTypes
}

export interface SetTetherTargetNetworkVars extends ServiceResourcesVars {
    network: string
}

export interface SetTetherIntervalVars extends ServiceResourcesVars {
    interval: number
}

export interface VerifyReportVars {
    node_id: string,
    report: {
        sigOrHash: string
        sigOrV: string
        r: string
        s: string
    }
}


export const TetherAccountQuery = gql`
    query getTetherAccount($service_id: String!) {
        tetherAccount(service_id: $service_id ) {
            address
        }
    }`;

export const LastReportQuery = gql`
    query getLastReport($service_id: String!) {
        lastReport(service_id: $service_id )
    }`;

export const TetherBalanceQuery = gql`
    query getTetherBalance($service_id: String!, $network: String!) {
        tetherBalance(service_id: $service_id, network: $network ) {
            address
            balance
        }
    }`;

export const SetTetherAccountMutation = gql`
    mutation setTetherAccount($service_id: String!, $privateKey: String!) {
        setTetherAccount(service_id: $service_id, privateKey: $privateKey) {
            address
        }
    }
`

export const SetTetherTargetNetwork = gql`
    mutation setTargetNetwork($service_id: String!, $network: String!) {
        setTargetNetwork(service_id: $service_id, network: $network) {
            environmentId
        }
    }
`

export const SetTetherIntervalMutation = gql`
    mutation setTetherInterval($service_id: String!, $interval: Int!) {
        setTetherInterval(service_id: $service_id, interval: $interval) {
            environmentId
        }
    }
`

export const ActivateTetherMutation = gql`
    mutation activateTether($service_id: String!) {
        activateTether(service_id: $service_id) {
            success
        }
    }
`

export const DeactivateTetherMutation = gql`
    mutation deactivateTether($service_id: String!) {
        deactivateTether(service_id: $service_id) {
            success
        }
    }
`

export const DeployTetherContractMutation = gql`
    mutation deployTetherContract($service_id: String!) {
        deployTetherContract(service_id: $service_id) {
            arbitratorAddress
        }
    }
`

export const VerifyReportMutation = gql`
    mutation verifyReport($node_id: String!, $report: TetherReport!) {
        verifyReport(node_id: $node_id, report: $report) {
            address
        }
    }
` 