import React, { SetStateAction } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { DisplayInfoList } from "../../components/DisplayInfoList";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { MembershipQuery, MembershipData } from "../../models";
import { ConsortiumResourceVars } from "../../interfaces";
import { useParams } from "react-router-dom";
import { FormLink } from "../../components/FormControls/FormLink";

interface Props {
    uniqueIdentifier: string;
    setExtCertificate: React.Dispatch<SetStateAction<string>>;
}

const DOC_LINK = 'https://docs.kaleido.io/kaleido-services/registry/pki';

export const GenerateExtIdentity = ({
    uniqueIdentifier,
    setExtCertificate,
}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "GenerateExtIdentity", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`GenerateExtIdentity:${key}`, interpolate);


    const { consortium_id, membership_id } = useParams<any>();

    const { data: { membership } = { membership: null } } = useQuery<
        MembershipData,
        ConsortiumResourceVars
    >(MembershipQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: membership_id!,
        },
        fetchPolicy: "cache-first",
    });

    const infoList = [
        {
            label: lt("identityName"),
            content: membership?.org_name ?? "",
        },
        {
            label: lt("uniqueIdentifier"),
            content: (
                <Grid container direction="column" alignItems="flex-end">
                    <Grid item>
                        <Typography variant="body1">{uniqueIdentifier}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{lt('important')}</Typography>
                    </Grid>
                </Grid>
            ) ,
        },
        {
            label: lt("certficate"),
            content: (
                <FormLink target="_blank" isExternal={true} to={DOC_LINK}>
                    {lt("instructionsCertificate")}
                </FormLink>
            ),
        },
    ];

    return (
        <Grid item container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h5">{lt("generateExtSign")}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2">{lt("description")}</Typography>
            </Grid>
            <Grid item>
                <DisplayInfoList info={infoList} />
            </Grid>
            <Grid item>
                <TextField
                    multiline
                    variant="outlined"
                    fullWidth
                    rows={4}
                    label={lt("addCertificate")}
                    onChange={(e) => setExtCertificate(e.target.value)}
                />
            </Grid>
        </Grid>
    );
};

interface translations {
    identityName: string;
    uniqueIdentifier: string;
    certficate: string;
    generateExtSign: string;
    description: string;
    addCertificate: string;
    instructionsCertificate: string;
    important: string
}
const enTranslations: translations = {
    identityName: "Identity Name",
    uniqueIdentifier: "Kaleido Unique Identifier",
    certficate: "Certificate",
    description:
        "Kaleido allows for an organization’s asserted identity (i.e. membership) in a consortia to be underpinned with a digital x509 certificate for authoritative identification",
    generateExtSign: "Generate an Externally-Signed Certificate",
    addCertificate: "Add Certficate",
    instructionsCertificate:
        "Instructions on how to generate a Kaleido-compliant certificate.",
    important: 'IMPORTANT: Failure to use this unique id will result in an invalid identity.'
};
