import { useQuery } from "@apollo/client";
import { Grid, Typography, Button } from "@material-ui/core";
import DescriptionIcon from "mdi-react/FileDocumentIcon";
import KeyboardArrowRightIcon from "mdi-react/KeyboardArrowRightIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams, useHistory } from "react-router-dom";
import {
    CopyableSetting,
    CopyableSettings,
} from "../../components/DisplaySettings";
import { DisplayCard } from "../../components/DisplayWrappers";
import { ResourceStateChip } from "../../components/FormControls/ResourceStateChip";
import { BLOCKCHAIN_BASE_PATH } from "../../components/MainNav/SideNavs/Blockchain";
import {
    EnvironmentResourceVars,
    ServiceResourcesVars,
} from "../../interfaces";
import {
    ServiceQuery,
    IDRegistryDirectoriesQuery,
    IDRegistryDirectoriesData,
    ServiceData,
} from "../../models";
import {
    ADDRESSBOOK_PATH,
    ADDRESSBOOK_MEMBERSHIP_PATH,
} from "../../components/MainNav/SideNavs/AddressBook";
import { ShortenedHash } from "../../components/FormControls/ShortenedHash";

export const OnChainRegistryOverview = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "OnChainRegistryOverview", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`OnChainRegistryOverview:${key}`, interpolate);

    const { consortium_id, service_id, environment_id, org_id } = useParams<any>();
    const history = useHistory();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}`;

    const queryVariables = {
        consortia_id: consortium_id,
        id: service_id,
        environment_id: environment_id,
    };

    const { data: { service } = { service: null } } = useQuery<
        ServiceData,
        EnvironmentResourceVars
    >(ServiceQuery, {
        variables: queryVariables,
        fetchPolicy: "cache-only",
    });

    const {
        data: { idRegistryDirectories } = { idRegistryDirectories: null },
    } = useQuery<IDRegistryDirectoriesData, ServiceResourcesVars>(
        IDRegistryDirectoriesQuery,
        {
            variables: {
                service_id: service?._id ?? "",
            },
            skip: !service,
        }
    );

    if (!service)
        return <Redirect to={`${basePath}/${BLOCKCHAIN_BASE_PATH}`} />;

    const copyableList: CopyableSetting[] = [
        {
            title: lt("name"),
            displayValue: service.name || "",
            disableCopy: true,
        },
        {
            title: lt("id"),
            displayValue: service._id || "",
        },
        {
            title: lt("status"),
            displayValue: service.state ? (
                <ResourceStateChip state={service.state} />
            ) : (
                ""
            ),
            disableCopy: true,
        },
        {
            title: lt("owner"),
            displayValue: lt("system"),
            disableCopy: true,
        },
        {
            title: lt("createdOn"),
            displayValue: new Date(service.created_at || "").toLocaleString(),
            disableCopy: true,
        },
    ];

    if (service.urls?.http) {
        copyableList.push({
            title: lt("apiEndpoint"),
            displayValue: service.urls?.http || "",
        });
    }

    if (idRegistryDirectories) {
        copyableList.push({
            title: lt("contractAddress"),
            displayValue: (
                <ShortenedHash
                    showFullAddress
                    address={idRegistryDirectories.profiles}
                />
            ),
        });
    }

    const DocumentationList = [
        {
            icon: <DescriptionIcon />,
            title: lt("onChainRegistryDocumentation"),
            value: lt("documentation"),
            actionIcon: <KeyboardArrowRightIcon />,
            onClick: () =>
                window.open(
                    "https://docs.kaleido.io/kaleido-services/registry"
                ),
        },
    ];

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item container wrap="nowrap" justify="space-between">
                <Grid item>
                    <Typography variant="h5">
                        {lt("onChainOverview")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            history.push(
                                `${basePath}/${ADDRESSBOOK_PATH}/${ADDRESSBOOK_MEMBERSHIP_PATH}`
                            )
                        }
                    >
                        {lt("goToAddressbook")}
                    </Button>
                </Grid>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <CopyableSettings
                        header={lt("overview")}
                        {...{ copyableList }}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DisplayCard
                        header={lt("documentation")}
                        itemList={DocumentationList}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface translations {
    onChainOverview: string;
    name: string;
    id: string;
    status: string;
    owner: string;
    createdOn: string;
    apiEndpoint: string;
    documentation: string;
    overview: string;
    onChainRegistryDocumentation: string;
    system: string;
    goToAddressbook: string;
    contractAddress: string;
}

const enTranslations: translations = {
    name: "Name",
    status: "Status",
    id: "ID",
    owner: "Owner",
    createdOn: "Created On",
    apiEndpoint: "API Endpoint",
    onChainOverview: "On-Chain Registry Overview",
    documentation: "Documentation",
    overview: "Overview",
    onChainRegistryDocumentation: "On-Chain Registry Documentation",
    system: "System",
    goToAddressbook: "Go to addressbook",
    contractAddress: "Contract Address",
};
