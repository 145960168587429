import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from "react-router-dom";
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { DIGITAL_ASSETS_BASE_PATH, DIGITAL_ASSETS_ETHER_POOL_PATH } from '../../../components/MainNav/SideNavs/DigitalAssets';
import { Step1 } from './Step1';
import { Account } from '../../../models';

export const Create: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateEtherPoolCreate', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateEtherPoolCreate:${key}`, interpolate)

    const { org_id, consortium_id, environment_id, step } = useParams<any>();
    type locationState = { 
        account?: Account
        address?: string
        tokenAddress?: string
        cancelPath: string
    }
    const history = useHistory<locationState>()
    const { 
        location: { 
            state: {
                account,
                address,
                cancelPath: referrerCancelPath,
                tokenAddress
            } = {account: undefined, address: '', cancelPath: '', tokenAddress: '' }
        } 
    } = history
    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${DIGITAL_ASSETS_BASE_PATH}`
    const cancelPath = referrerCancelPath || basePath

    const invalidStep = step !== "1"
    const createStep = invalidStep ? 0 : (parseInt(step!) - 1)

    const redirect = `${basePath}/${DIGITAL_ASSETS_ETHER_POOL_PATH}/create/1`

    if (invalidStep || createStep >= 1) {
        return <Redirect to={redirect} />
    }

    const stepComponents = [
        { 
            step: lt('fundAccount'), 
            component: <Step1 {...{cancelPath}} {...{account}} {...{address}} {...{tokenAddress}}  /> 
        }
    ]

    return <FullScreenCreate hideStepper {...{cancelPath}} toolbarHeader={lt('fundAccount')} stepUrlParam={step!} {...{stepComponents}} />
};

interface translations {
    fundAccount: string,
}
const enTranslations: translations = {
    fundAccount: 'Fund Account'
}