
import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Grid, Typography, CircularProgress, Button } from "@material-ui/core";
import { ChannelsTable } from '../../components/Channels/ChannelsTable';
import { ConsortiumResourceVars, EnvironmentResourcesVars } from '../../interfaces'
import { ChannelsData, ChannelsQuery } from '../../models/channels';
import { CHANNELS_PATH } from '../../components/MainNav/SideNavs/AddressBook';
import { EnvironmentData, EnvironmentQuery } from '../../models';

export const CREATE_CHANNELS_PATH = 'create';
export const AddressBookChannels: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AddressBookChannels', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AddressBookChannels:${key}`, interpolate)
    const history = useHistory();
    const { org_id, consortium_id, environment_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        loading,
        data: {
            channels
        } = { channels: [] }
    } = useQuery<ChannelsData, EnvironmentResourcesVars>(ChannelsQuery, { 
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const { data: { 
        environment 
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    if (loading && channels.length === 0) {
        return <CircularProgress />
    }

    const createChannelPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${CHANNELS_PATH}/${CREATE_CHANNELS_PATH}/1`;

    return (
        <Grid item container spacing={3}>
            <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {channels.length > 0 ? lt('channelsCount', {count: channels.length}) : ''}
                    </Typography>
                </Grid>
                {channels.length > 0 && 
                        <Grid item>
                            <Button
                                disabled={environment?.state !== 'live'}
                                data-test="button_addChannel"
                                variant="contained"
                                color="primary"
                                onClick={()=> {history.push(createChannelPath)}}
                            >
                                {lt('newChannel')}
                            </Button>
                        </Grid>
                    }
            </Grid>
            <Grid item container alignItems="center" data-test="channelsTable">
                <ChannelsTable header={lt('channels')} {...{channels}} />
            </Grid>
        </Grid>
    )
};

interface translations {
    channels: string
    channelsCount: string
    name: string
    admin: string
    memberships: string
    nodes: string
    dateCreated: string
    status: string
    newChannel: string
    emptyDescription: string
}
const enTranslations: translations = {
    channels: 'Channels',
    channelsCount: 'Channels ({{count}})',
    name: 'Name',
    admin: 'Admin',
    memberships: 'Memberships',
    nodes: 'Nodes',
    dateCreated: 'Date Created',
    status: 'Status',
    newChannel: 'Add Channel',
    emptyDescription: 'A Hyperledger Fabric channel is a private “subnet” of communication between two or more specific network members, for the purpose of conducting private and confidential transactions.'

}
