import { useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ConnectRuntimeWrapper } from '../../components/ConnectRuntime/ConnectRuntimeWrapper';
import { DisplayTable } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import { ConsortiumResourceVars, EnvironmentResourcesVars, EnvironmentResourceVars } from '../../interfaces';
import { EnvironmentQuery, FeatureToggles, FeatureTogglesData, FeatureTogglesQuery, FeatureTogglesVars, ServicesData, ServicesEnum, ServicesQuery, EnvironmentData, NodeData, NodeQuery } from '../../models';
import { SECURITY_BASE_PATH } from '../MainNav/SideNavs/Security';

export const CREATE_TUNNELER_PATH = 'create';

interface Props {
    headerText: string,
    membershipId?: string,
}

export const PrivateStackPanel: React.FC<Props> = ({headerText, membershipId}) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'AppsSecurityPrivateStack', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`AppsSecurityPrivateStack:${key}`, interpolate)

    const history = useHistory()
    const {consortium_id, environment_id, org_id} = useParams<any>();
    const [open, setOpen] = useState(false)

    let {
        data: { featureToggles } = { featureToggles: {} as FeatureToggles }
    } = useQuery<FeatureTogglesData, FeatureTogglesVars>(FeatureTogglesQuery, { 
        variables: { },
        fetchPolicy: 'cache-first'
    });

    let {
        data: {
            environment
        } = { environment: null }
    } = useQuery<EnvironmentData, ConsortiumResourceVars>(EnvironmentQuery, { 
        variables: {
            consortia_id: consortium_id!,
            id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    let {
        data: {
            node: monitorNode
        } = { node: null }
    } = useQuery<NodeData, EnvironmentResourceVars>(NodeQuery, {
        skip: !environment?.node_list[0],
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!,
            id: environment?.node_list[0]!,
        },
        fetchPolicy: 'cache-only'
    });

    let {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData, EnvironmentResourcesVars>(ServicesQuery, { 
        variables: {
            consortia_id: consortium_id!,
            environment_id: environment_id!
        },
        fetchPolicy: 'cache-only'
    });

    if (!featureToggles?.tunneler) return <></>;

    const montiorNodeStarted = monitorNode?.state === 'started';

    const tunneler = services.find(s => s.service === 'tunneler' && (!membershipId || s.membership_id === membershipId));

    const actionBar =
        <Grid item>
            { tunneler ? // If it's started it's displayed with ConnectRuntimeWrapper below
                <ResourceStateChip state={tunneler.state} />
            :
                <Button color="primary" variant="contained" size="large" disabled={!montiorNodeStarted}
                    onClick={() => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${ServicesEnum.tunneler}/${CREATE_TUNNELER_PATH}/1`)}>
                    {lt('managePrivateStack')}
                </Button>
            }
        </Grid>;
    
    const hybrid = environment?.test_features?.hybrid;

    return (
        <Grid item container>
            {tunneler && tunneler.state === 'started' ?
                <>
                    <ConnectRuntimeWrapper header={lt('header')} description={headerText} actionText={lt('connectTunneler')} {...{open}} {...{setOpen}} service={tunneler} {...{hybrid}}/>
                </>
            :
                <DisplayTable header={lt('header')} description={headerText} {...{actionBar}}/>
            }
        </Grid>
    )
};

interface translations {
    header: string,
    managePrivateStack: string,
    connectTunneler: string,
}
const enTranslations: translations = {
    header: 'PrivateStack Bridge',
    managePrivateStack: 'Create network bridge',
    connectTunneler: 'Connect network bridge',
}