import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Container, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import WebIcon from 'mdi-react/WebIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { InfoBanner } from '../../components/Banners/InfoBanner';
import { MFABlockedOrgsBanner } from '../../components/Banners/MFABlockedOrgs';
import { DisplayCard, DisplayGridWrapper, EmptyState } from '../../components/DisplayWrappers';
import { CHANGE_PLAN_PATH, MANAGE_ORG_PATH, MANAGE_ORG_SUBSCRIPTION_PATH, ORGS_PATH } from '../../components/ManageOrgNav/ManageOrgNav';
import { ConsortiaData, ConsortiaQuery, MFABlockedOrganizationsData, MFABlockedOrganizationsQuery, UserInvitationsData, UserInvitationsQuery, UserMembershipsData, UserMembershipsQuery } from '../../models';
import { VIEW_INVITATION_PATH } from '../ViewInvitation/Invitation';
import { Network } from './Network';
import { MessageSnackbar } from '../../components/DialogWrappers';
import { HigherAccessRequestData, SubmitHigherAccessRequestFormVars, SubmitHigherAccessMutation } from '../../models/contactInfo';
import { requestHigherAccessFormFields } from '../../utils/HubspotUtils';
import { DedicatedWeb3SolutionsAccess } from '../CreateEnvironment/DedicatedWeb3SolutionsAccess';

export const Dashboard: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'LandingDashboardDashboard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`LandingDashboardDashboard:${key}`, interpolate)

    const classes = useStyles();
    const history = useHistory()
    const { pathname } = useLocation()
    const { org_id } = useParams<any>();

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<
        'error' | 'success' | undefined
    >();
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [submitAccessRequest] = useMutation<
        HigherAccessRequestData,
        SubmitHigherAccessRequestFormVars
    >(SubmitHigherAccessMutation);

    const {
        data: {
            mfaBlockedOrganizations
        } = { mfaBlockedOrganizations: [] }
    } = useQuery<MFABlockedOrganizationsData>(MFABlockedOrganizationsQuery);

    let {
        data: {
            consortia
        } = { consortia: [] }
    } = useQuery<ConsortiaData>(ConsortiaQuery, { fetchPolicy: 'cache-only' });

    const {
        data: {
            memberships
        } = { memberships: [] }
    } = useQuery<UserMembershipsData>(UserMembershipsQuery, { fetchPolicy: 'cache-only' });

    const {
        data: {
            invitations
        } = { invitations: [] }
    } = useQuery<UserInvitationsData>(UserInvitationsQuery, { fetchPolicy: 'cache-and-network' });

    // filter out consortia we dont have a membership to. cant use m.is_mine up at this level since the memberships query is not refreshed when org is switched.
    consortia = useMemo(() => (
        consortia.filter(c => memberships.some(m => m.consortia_id === c._id && m.org_id === org_id))
    ), [consortia, memberships, org_id])

    const docsList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('quickStart'),
            value: lt('quickStartDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/quick-start/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('whatIsKaleido'),
            value: lt('whatIsKaleidoDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-platform/foundational-concepts/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('decentralization'),
            value: lt('decentralizationDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/business-networks/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('kaleidoApi'),
            value: lt('apiDocs'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://api.kaleido.io/platform.html')
        }
    ]

    const quickStartList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('quickStartEth'),
            value: lt('quickStartDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/quick-start-ethereum/first-blockchain/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('quickStartCorda'),
            value: lt('quickStartDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/quick-start-corda/first-blockchain/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('quickStartFirefly'),
            value: lt('quickStartDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/using-kaleido/quick-start-firefly/first-firefly/')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('faqTitle'),
            value: lt('faqDescription'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://help.kaleido.io/hc/en-us'),
        },
    ];

    const requestDedicatedAccess = async () => {
        const formFields = requestHigherAccessFormFields(
            'dedicatedWeb3Solution',
            window.location.href
        );
        const { data } = await submitAccessRequest({
            variables: {
                ...formFields,
            },
        });
        if (data?.requestHigherAccess) {
            setMessageType('success');
            setMessage(lt('requestSubmitted'));
            setRequestSubmitted(true);
        }
    };

    const gettingStartedBtn = (href: string, btnText: string) => {
        return <Button href={href} target="_blank" color="inherit">{btnText}</Button>
    }

    const gettingStartedDisplay = (imgSrc: string, imgAlt: string, desc: string, video = false) => {
        return <>
            {video && <>
                <a href='https://videos.sproutvideo.com/embed/ea99ddb41410e5c163/8db5a146031290ae?autoPlay=true' className='sproutvideo-lightbox' data-width='640' data-height='326'>
                    <img src='https://cdn-thumbnails.sproutvideo.com/ea99ddb41410e5c163/8db5a146031290ae/0/w_640,h_326,btn_true/poster.jpg' alt={imgAlt} className={classes.gettingStartedImage} />
                </a>
                <script charSet='ISO-8859-1' src='https://c.sproutvideo.com/lightbox/v1.0.0.js'></script>
            </>
            }
            {!video &&
                <Grid item xs={12}>
                    <img src={imgSrc} alt={imgAlt} className={classes.gettingStartedImage} />
                </Grid>
            }
            <Grid item xs={12}>
                <Typography className={classes.bottomMargin} variant="body2">{desc}</Typography>
            </Grid>
        </>
    }

    const gettingStartedCards = [
        {
            actionBar: undefined,
            actionFooter: gettingStartedBtn('https://kaleido.vids.io/videos/ea99ddb41410e5c163/kaleido-overview-8-minute-demo-mov', lt('viewVideo')),
            header: lt('kaleidoWalkthrough'),
            itemList: undefined,
            leftAlignActionFooter: true,
            prebuiltDisplayCardItems: gettingStartedDisplay('', lt('kaleidoWalkthrough'), lt('kaleidoWalkthroughDescription'), true)
        },
        {
            actionBar: undefined,
            actionFooter: undefined,
            header: lt('quickStartGuides'),
            itemList: quickStartList,
            leftAlignActionFooter: undefined,
            prebuiltDisplayCardItems: undefined
        },
        {
            actionBar: <Button onClick={() => history.push(`/${ORGS_PATH}/${org_id}/${MANAGE_ORG_PATH}/${MANAGE_ORG_SUBSCRIPTION_PATH}/${CHANGE_PLAN_PATH}/1`)} color="primary"
                size="small" variant="contained">{lt('upgrade')}</Button>,
            actionFooter: (<>
                {gettingStartedBtn('https://docs.kaleido.io/using-kaleido/plans/', lt('viewPlans'))}
                {gettingStartedBtn('https://www.kaleido.io/pricing/', lt('viewPricing'))}
            </>),
            header: lt('plansAndPricing'),
            itemList: undefined,
            leftAlignActionFooter: true,
            prebuiltDisplayCardItems: gettingStartedDisplay('/img/WebUi/Plan-Compare.png', lt('plansAndPricing'), lt('plansAndPricingDescription'))
        }
    ]

    const createNetwork = () => history.push(`${pathname}/consortia/create/1`)

    const invitationBanners = invitations.map(i => (
        <Grid item key={i._id}>
            <InfoBanner description={lt('invited')} paperWrapBackground
                icon={<WebIcon />}
                boldDescriptionSuffix={i.charter.consortia.name}
                eventCreatedAtDate={i.createdAt}
                linkButton={{ text: lt('viewInvite'), onClick: () => history.push(`${pathname}/${VIEW_INVITATION_PATH}/${i._id}/1`) }} />
        </Grid>
    ))

    if (!consortia.length) return (
        <Container>
            <Grid container direction="column" spacing={3}>
                {mfaBlockedOrganizations.length > 0 &&
                    <Grid item>
                        <MFABlockedOrgsBanner />
                    </Grid>}
                {invitationBanners.length > 0 && invitationBanners}
                <Grid item className={classes.bottomMargin}>
                    <EmptyState imageFile='Empty-Network.svg'
                        title={lt('createANetwork')}
                        description={lt('emptyDescription')}
                        button={{ text: lt('createNetwork'), onClick: createNetwork }}
                        documentation={{ text: lt('emptyDocumentation'), link: 'https://docs.kaleido.io/using-kaleido/quick-start/' }}
                        isHorizontal={true} />
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
                    <Grid item container direction="row" spacing={3}>
                        {gettingStartedCards.map(c => (
                            <Grid item container key={c.header} lg={4} sm={6} xs={12}>
                                <DisplayCard
                                    actionBar={c.actionBar}
                                    header={c.header}
                                    itemList={c.itemList}
                                    prebuiltDisplayCardItems={c.prebuiltDisplayCardItems}
                                    leftAlignActionFooter={c.leftAlignActionFooter}
                                    actionFooter={c.actionFooter}
                                    maxHeight='disable'
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )

    const content = (
        <Grid container direction="column" spacing={3}>
            {mfaBlockedOrganizations.length > 0 &&
                <Grid item>
                    <MFABlockedOrgsBanner />
                </Grid>}
            {invitationBanners.length > 0 && invitationBanners}
            <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                        {lt('dashboard')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" size="large" onClick={createNetwork}>
                        {lt('createNetwork')}
                    </Button>
                </Grid>
            </Grid>

            <Grid item container direction="row" justifyContent="space-between">
                <Grid item container direction="column" spacing={3} xs={12} md={8}>
                    {
                        consortia.map(consortium => (
                            <Grid item container key={consortium._id}>
                                <Network {...{ consortium }} />
                            </Grid>
                        ))
                    }
                </Grid>

                <Grid item container direction="column" xs={12} md={4}>
                    <DisplayCard
                        header={lt('documentation')}
                        itemList={docsList}
                    />
                    <Box mt={3}>
                        <DisplayGridWrapper
                            header={lt('dedicatedWeb3Solutions')}
                            displayGrid={
                                <>
                                    <Grid
                                        container
                                        style={{
                                            padding: '16px',
                                            paddingTop: '8px',
                                        }}
                                    >
                                        <DedicatedWeb3SolutionsAccess
                                            buttonDisabled={requestSubmitted}
                                            buttonFn={requestDedicatedAccess}
                                            dashboardMode={true}
                                        />
                                    </Grid>
                                </>
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
            <MessageSnackbar
                messageType={messageType}
                {...{ message }}
                {...{ setMessage }}
            />
        </Grid>
    )

    return (
        <>
            <Hidden smDown implementation="js">
                <Container>
                    {content}
                </Container>
            </Hidden>
            <Hidden mdUp implementation="js">
                {content}
            </Hidden>
        </>
    )
};

const useStyles = makeStyles(theme => ({
    bottomMargin: {
        marginBottom: theme.spacing(4)
    },
    gettingStartedImage: {
        width: '100%',
        marginBottom: theme.spacing(1)
    }
}));

interface translations {
    dashboard: string
    createANetwork: string
    createNetwork: string
    dedicatedWeb3Solutions: string;
    docTitle: string
    documentation: string
    emptyDescription: string
    emptyDocumentation: string
    whatIsKaleido: string
    whatIsKaleidoDescription: string
    quickStart: string
    quickStartDescription: string
    quickStartEth: string
    quickStartCorda: string
    quickStartFirefly: string
    quickStartComingSoon: string
    kaleidoApi: string
    apiDocs: string
    decentralization: string
    decentralizationDescription: string
    invited: string
    viewInvite: string
    kaleidoWalkthrough: string
    kaleidoWalkthroughDescription: string
    quickStartGuides: string
    plansAndPricing: string
    plansAndPricingDescription: string
    requestSubmitted: string
    upgrade: string
    faqTitle: string
    faqDescription: string
    viewPlans: string
    viewPricing: string
    viewVideo: string
}
const enTranslations: translations = {
    dashboard: 'All Networks',
    createANetwork: 'Create a Network',
    createNetwork: 'Create Network',
    dedicatedWeb3Solutions: 'Dedicated Web3 Solutions',
    docTitle: 'Placeholder title',
    documentation: 'Documentation',
    emptyDescription: 'Let\'s go ahead and build out your first blockchain network.',
    emptyDocumentation: 'Documentation',
    whatIsKaleido: 'What is Kaleido?',
    whatIsKaleidoDescription: 'The blockchain business network',
    quickStart: 'Quick Start',
    quickStartDescription: 'Get running in minutes',
    quickStartEth: 'Quick Start Ethereum',
    quickStartCorda: 'Quick Start Corda',
    quickStartFirefly: 'Quick Start Firefly',
    quickStartComingSoon: 'Coming Soon',
    kaleidoApi: 'API Documentation',
    apiDocs: 'API reference for Kaleido',
    decentralization: 'Decentralization',
    decentralizationDescription: 'Shared ownership across stakeholders',
    invited: 'You\'ve been invited to join the Network: ',
    viewInvite: 'View Invite',
    kaleidoWalkthrough: 'Kaleido Walkthrough',
    kaleidoWalkthroughDescription: 'Watch a walkthrough of the Kaleido platform, and see how easy it is to create your first enterprise-grade blockchain network.',
    quickStartGuides: 'Quick Start Guides and FAQs',
    plansAndPricing: 'Compare Plans and Pricing',
    plansAndPricingDescription: "Kaleido offers subscription plans to match a project's needs from concepts to production. Plans govern access to resources and actions within both the consortia and blockchain layers.",
    requestSubmitted:
        'Request submitted. Someone will be in touch with you soon.',
    upgrade: 'Upgrade',
    faqTitle: 'Frequently Asked Questions',
    faqDescription: 'Find answers and solutions',
    viewPlans: 'View Plans',
    viewPricing: 'View Pricing',
    viewVideo: 'View Video'
}
