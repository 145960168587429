import React, { useMemo, useState, useEffect } from 'react';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { useTranslation } from 'react-i18next';
import { Step1 } from './Step1';
import { useQuery, useMutation } from '@apollo/client';
import { ConfigData, ConfigQuery, UpdateConfigVars, UpdateConfigMutation } from '../../../models/configs';
import { EnvironmentResourceVars, Config } from '../../../interfaces';
import { useParams, Redirect } from 'react-router-dom';
import { SECURITY_BAF_PATH, SECURITY_BASE_PATH } from '../../../components/MainNav/SideNavs/Security';
import { BAFConfig, BAFPolicy, BAFPolicyRuleset } from '../BAFPolicySchema';
import { CircularProgress } from "@material-ui/core";

export const BAFRulesetEditor = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'BAFRulesetEditor', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`BAFRulesetEditor:${key}`, interpolate);

    const {consortium_id, environment_id, config_id, org_id, ruleset_id} = useParams<any>();
    const notFoundPath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${SECURITY_BASE_PATH}/${SECURITY_BAF_PATH}`;
    const cancelPath = `${notFoundPath}/${config_id}`;

    const queryVariables = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const [updateConfig, { loading: configSaving }] = useMutation<Config, UpdateConfigVars>(UpdateConfigMutation)

    const {
        loading: configsLoading,
        data: {
            config
        } = { config: null }
    } = useQuery<ConfigData, EnvironmentResourceVars>(ConfigQuery, {
        variables: {
            ...queryVariables,
            id: config_id!
        },
    });

    const [ version, setVersion ] = useState('')
    const [ ruleset, setRuleset ] = useState<BAFPolicyRuleset | undefined>(undefined)

    const bafConfig: BAFConfig | undefined = useMemo(() => {
        if (config) {
            return {
                ...config,
                details: JSON.parse(config.details.baf_policy!) as BAFPolicy,
            }
        }
    }, [config])

    useEffect(() => {
        if (bafConfig && ruleset_id) {
            let newVersion = `${config_id}-${config?._revision}-${ruleset_id}`;
            if (version !== newVersion) {
                setVersion(newVersion);
                setRuleset(bafConfig.details.rulesets[ruleset_id] || {})
            }
        }
    }, [version, bafConfig, config, config_id, ruleset_id]);

    if(!configsLoading && !config) return <Redirect to={notFoundPath}/>

    if (!ruleset) return <CircularProgress />

    const save = async () => {
        bafConfig!.details.rulesets[ruleset_id!] = ruleset!;
        await updateConfig({
            variables: {
                ...queryVariables,
                id: config_id!,
                configuration: {
                    name: bafConfig!.name,
                    details: {
                        baf_policy: JSON.stringify(bafConfig!.details),
                    }
                }
            }
        })
    }

    const loading = configsLoading || configSaving;

    const stepComponents = [{
        step: '',
        component: <Step1 {...{ruleset}} {...{setRuleset}} {...{cancelPath}} {...{loading}} {...{save}} ruleset_id={ruleset_id!}/>
    }]

    return (
        <FullScreenCreate cancelPath={cancelPath} {...{loading}} toolbarHeader={lt('header')} {...{stepComponents}} stepUrlParam={'1'} hideStepper />
    )
}

interface translations {
    header: string
}

const enTranslations: translations = {
    header: 'Configure Ruleset'
}