import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayCard } from '../../../components/DisplayWrappers'
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import { useParams, useHistory } from 'react-router-dom';
import { KEY_MANAGEMENT_BASE_PATH, KEY_MANAGEMENT_MANAGED_PATH, KEY_MANAGEMENT_CLOUD_PATH } from '../../../components/MainNav/SideNavs/KeyManagement';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import KeyIcon from 'mdi-react/KeyOutlineIcon'
import { Service, CloudWalletServices, ServicesTranslations, EnServicesTranslations } from '../../../models';
import { EmptyCard } from '../../../components/DisplayWrappers/EmptyCard';

interface Props {
    services: Service[]
    isManaged?: boolean
};

export const WalletsCard = ({ services, isManaged}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'WalletsCard', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`WalletsCard:${key}`, interpolate)

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const basePath = `/orgs/${org_id}/consortia/${consortium_id}/environments/${environment_id}/${KEY_MANAGEMENT_BASE_PATH}/`;
    const managedCreatePath = `${basePath}${KEY_MANAGEMENT_MANAGED_PATH}/create/1`;
    const cloudHsmCreatePath = `${basePath}${KEY_MANAGEMENT_CLOUD_PATH}/create/1`;

    const itemList = services.map(service => ({
        title: service.name ?? '',
        value: CloudWalletServices.has(service.service) ? service.membership.name : lt(service.service as keyof ServicesTranslations),
        icon: isManaged ? <ChartLineIcon /> : <KeyIcon />,
        onClick: () => history.push(`${basePath}${isManaged ? `${KEY_MANAGEMENT_MANAGED_PATH}/${service.service}` : KEY_MANAGEMENT_CLOUD_PATH}/${service._id}`),
        actionIcon: <ChevronRightIcon />
    }))

    const linkButton = {
        text: isManaged ? lt('viewManagedWallet') : lt('viewCloudHsmWallet'),
        onClick: () => history.push(`${basePath}${isManaged ? KEY_MANAGEMENT_MANAGED_PATH : KEY_MANAGEMENT_CLOUD_PATH}`)
    };

    if (services.length === 0) return <EmptyCard header={ isManaged ? lt('managed') : lt('cloudhsm')}
                                            imageFiles={ isManaged ? "Empty-Wallet.svg" : "Empty-CloudHSM.svg" }
                                            description={ isManaged ? lt('managedDescription') : lt('cloudhsmDescription')}
                                            createPath={ isManaged ? managedCreatePath : cloudHsmCreatePath}
                                            onViewDetailsClick={() => window.open('https://docs.kaleido.io/kaleido-services/cloudhsm/')} />
    
    return (
        <DisplayCard {...{linkButton}}
            header={ isManaged ? lt('managed') : lt('cloudhsm')}
            {...{itemList}} />
    )
};

interface translations extends ServicesTranslations {
    managed: string
    cloudhsm: string
    type: string
    viewManagedWallet: string
    viewCloudHsmWallet: string
    managedDescription: string
    cloudhsmDescription: string
}
const enTranslations: translations = {
    ...EnServicesTranslations,
    managed: 'Managed Wallets',
    cloudhsm: 'Cloud HSM Wallets',
    type: "Type",
    viewManagedWallet: 'View Managed Wallets',
    viewCloudHsmWallet: 'View Cloud HSM Wallets',
    managedDescription: 'Create and manage individual ethereum account addresses that can be used for signing transactions.',
    cloudhsmDescription: 'Host your signing keys in your own private cloud account. Cloud HSM SIgner allows you to sign transactions using off-platform keys.'
};
