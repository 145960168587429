import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../../components/DialogWrappers'

export const ConfigsHelp = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigKeyStoreHelp', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigKeyStoreHelp:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Node-CloudConfigs.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            docLink="https://docs.kaleido.io/using-kaleido/cloud-integrations/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
}

const enTranslations: translations = {
    helpHeader: 'Cloud Configurations',
    helpDescription: 'Add extensibility and heightened control to your blockchain network by optionally integrating a node with native AWS and Azure services, all managed and configured within your own organizationally-controlled cloud suite. Services include key management, log streaming, backups and private data routing. Additionally, Kaleido logins can be delegated to existing directory services and identity providers for customizable user management.',
}