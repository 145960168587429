import { useQuery } from '@apollo/client';
import { Box, Fade, Grid } from '@material-ui/core';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    SupportInboxQuery,
    SupportInboxVars,
    SupportTicketData
} from '../../../models/helpCenter';
import { EmptyState } from '../../DisplayWrappers';
import { CenteredSpinner } from '../CenteredSpinner';
import { HelpCenterTab } from '../HelpCenter';
import Ticket from './Ticket';

interface Props {
    setCurrentTab: Dispatch<HelpCenterTab>;
}

export const SupportInbox = ({ setCurrentTab }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'SupportInbox', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`SupportInbox:${key}`, interpolate);

    const { org_id } = useParams<any>();
    const {
        loading,
        data: { supportTickets } = { environment: null },
        refetch,
    } = useQuery<SupportTicketData, SupportInboxVars>(SupportInboxQuery, {
        variables: { org_id },
        pollInterval: 5000,
    });
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        if (refetch) {
            refetch();
        }
    }, [refetch]);

    if (loading) return <CenteredSpinner />;

    if (!supportTickets || supportTickets.length === 0) {
        return (
            <Fade in={true}>
                <Box mt={11}>
                    <EmptyState
                        imageFile="Empty-Checklist.svg"
                        title={lt('noOpenTickets')}
                        description={lt('description')}
                        button={{
                            text: lt('createNewMessage'),
                            onClick: () => {
                                setCurrentTab(HelpCenterTab.CONTACT_US);
                            },
                        }}
                    />
                </Box>
            </Fade>
        );
    }

    return (
        <Grid container>
            {redirecting ? (
                <>
                    <CenteredSpinner text={lt('redirectingToZendesk')}/>
                </>
            ) : (
                supportTickets.map((ticket) => (
                    <Ticket
                        key={ticket.id}
                        data={ticket}
                        redirecting={redirecting}
                        setRedirecting={setRedirecting}
                    />
                ))
            )}
        </Grid>
    );
};

interface translations {
    noOpenTickets: string;
    description: string;
    createNewMessage: string;
    redirectingToZendesk: string;
}

const enTranslations: translations = {
    noOpenTickets: 'You have no open tickets',
    description: 'Support tickets you open with Kaleido will be shown here.',
    createNewMessage: 'Create new message',
    redirectingToZendesk: 'Redirecting to Zendesk...'
};
