import { useQuery } from '@apollo/client';
import React from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FullScreenCreate } from '../../../components/DialogWrappers';
import { EnvironmentResourceVars } from '../../../interfaces';
import { ServiceData, ServiceQuery } from '../../../models';
import { Step1 } from './Step1';

export const FireflyInitialize = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle("en", "FireflyInitialize", enTranslations);
    const lt = (key: keyof translations, interpolate?: object) =>
        t(`FireflyInitialize:${key}`, interpolate);
    const history = useHistory();
    const { consortium_id, service_id, environment_id } = useParams<any>();
    const { search } = useLocation();
    const { operation } = queryString.parse(search);

    const envVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!,
    }

    const {
        data: {
            service
        } = {service: null}
    } = useQuery<ServiceData, EnvironmentResourceVars>(ServiceQuery, {
        variables: {
            ...envVars,
            id: service_id
        },
        fetchPolicy: 'cache-only'
    });


    const stepComponents = [{ 
        step: lt('fireflyInitialize'), 
        component: <Step1/>
    }]

    if (!service){
        history.goBack()
    }

    return <FullScreenCreate hideStepper toolbarHeader={lt(operation === 'addNamespace' ? 'addNamespace' : 'fireflyInitialize')} stepUrlParam={"1"} {...{stepComponents}} />
};

interface translations {
    fireflyInitialize: string
    addNamespace: string
};

const enTranslations: translations = {
    fireflyInitialize: 'Initialize FireFly Node',
    addNamespace: 'Add a New Namespace'
};