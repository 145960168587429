import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CreateRoleVars, CreateRoleMutation, CreateRoleData, MakeRoleCreateMutationOptions, RoleType, ROLES, UpdateRoleData, UpdateRoleVars, UpdateRoleMutation, Role, RolesTranslations, EnRolesTranslations } from '../../../models/roles';
import { useParams } from 'react-router-dom';
import { FormDialog } from '../../../components/DialogWrappers';
import { TextField, Grid, MenuItem, Typography } from '@material-ui/core';
import { isEmailValid } from '../../../utils/StringUtils';

interface Props {
    roleToUpdate?: Role
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditRole: React.FC<Props> = ({ open, setOpen, roleToUpdate }: Props) => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'EditRole', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`EditRole:${key}`, interpolate);
    const { org_id } = useParams<any>();
    const [email, setEmail] = useState('');
    const [role, setRole] = useState<RoleType>('user');
    
    const [createRole] = useMutation<CreateRoleData, CreateRoleVars>(CreateRoleMutation);
    const [updateRole] = useMutation<UpdateRoleData, UpdateRoleVars>(UpdateRoleMutation);

    useEffect(() => {
        if (open) {
            setRole(roleToUpdate?.role || 'user')
            setEmail(roleToUpdate?.email || '');
        }
    }, [open, setEmail, roleToUpdate]);

    const onSave = () => {
        if (!roleToUpdate) return createRole(MakeRoleCreateMutationOptions({  org_id: org_id!, role: { email, role } } )).then(() => {})
        return updateRole({ 
            variables: {  
                org_id: org_id!, 
                id: roleToUpdate._id, 
                role: { 
                    role 
                } 
            } 
        }).then(() => {})
    }

    const controlsWrapper = (
        <Grid item container direction="column" spacing={3}>
            <Grid item>
                <TextField disabled={roleToUpdate ? true : false} data-test="userEmail" label={lt('email')} 
                    value={email} variant="outlined" fullWidth onChange={event => setEmail(event.target.value)} />
            </Grid>
            <Grid item>
                <TextField select data-test="userRole"
                        fullWidth
                        label={lt('role')} 
                        value={role} 
                        variant="outlined" 
                        onChange={e => setRole(e.target.value as RoleType)}>
                            {ROLES.map((i) => (
                                <MenuItem key={`roles-${i}`} value={i}>{lt(i)}</MenuItem>
                            ))}
                </TextField>
            </Grid>
            <Grid item>
                <Typography>{lt(`${role}Desc` as keyof translations)}</Typography>
            </Grid>
        </Grid>
    );

    return <FormDialog open={open} setOpen={setOpen} header={lt(roleToUpdate ? 'editUser' : 'addUser')} closeDialogAfterSave={true} 
        successMessage={lt(roleToUpdate ? 'userSuccessfullyUpdated' : 'userSuccessfullyAdded')}
        saveText={roleToUpdate ? undefined : lt('add')} controlsWrapper={controlsWrapper} onSave={onSave} 
        saveDisabled={roleToUpdate ? false : !isEmailValid(email)} />
}

interface translations extends RolesTranslations {
    addUser: string
    editUser: string
    add: string
    email: string
    role: string
    userSuccessfullyAdded: string
    userSuccessfullyUpdated: string
    adminDesc: string
    userDesc: string
    readerDesc: string
}

const enTranslations: translations = {
    ...EnRolesTranslations,
    addUser: 'Add User',
    editUser: 'Edit User',
    add: 'Add',
    email: 'E-mail',
    role: 'Role',
    userSuccessfullyAdded: 'User successfully added',
    userSuccessfullyUpdated: 'User successfully updated',
    adminDesc: 'The Admin role has all User role privileges and can also manage other users',
    userDesc: 'The User role can manage most resources within this organization',
    readerDesc: 'The Read Only role is a view only role and cannot make any changes'
}