import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts, { AxisTypeValue, SeriesLineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BaseChartOptions } from '../../utils/ChartOptions'

interface Props {
    title: string,
    series: { name: string, values: [number, number][], color?: string }[],
    valuesSuffix?: string,
    valuePrefix?: string,
    height?: string,
    xAxisLabel?: string, // defaults to Time
    xAxisType?: AxisTypeValue // defaults to datetime
    extraHCOptions?: Highcharts.Options
    showLegend?: boolean
};

export const LineChart = ({ title, series, valuesSuffix, height, xAxisLabel, xAxisType = 'datetime', extraHCOptions, valuePrefix, showLegend = false }: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ChartsLineChart', enTranslations);
    const lt = useCallback((key: keyof translations, interpolate?: object) => t(`ChartsLineChart:${key}`, interpolate), [t])

    // SHARED OPTIONS
    const containerProps = { style: { maxWidth: "98%", height } }
    const xAxis: Highcharts.XAxisOptions = {
        title: {
            text: xAxisLabel || lt('time')
        },
        type: xAxisType
    }

    // CHART
    const options: Highcharts.Options = {
        series: series.map(s => {
            const srs: SeriesLineOptions = {
                type: 'line',
                data: s.values,
                name: showLegend ? s.name : title,
                color: s.color,
                tooltip: {
                    footerFormat: showLegend ? title : s.name,
                    valueSuffix: !showLegend ? valuesSuffix : undefined
                }
            }
            return srs
        }),
        yAxis: {
            title: {
                text: title
            },
            labels: {
                format: `${valuePrefix?valuePrefix:''}{value}${valuesSuffix?valuesSuffix:''}`
            }
        },
        xAxis,
        time: {
            useUTC: false
        },
        legend: { enabled: showLegend }
    }
    const mergedOptions = Highcharts.merge(BaseChartOptions, options, extraHCOptions);
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={mergedOptions}
            containerProps={containerProps}
        />
    ) 
};

interface translations {
    time: string
}
const enTranslations: translations = {
    time: 'Time'
}