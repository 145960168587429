import React, { isValidElement } from 'react';
import { Typography, makeStyles, IconButton,
    Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DisplayTable } from '../DisplayWrappers'
import EditIcon from 'mdi-react/PencilIcon';

export interface CopyableSetting {
    title: string | JSX.Element,
    displayValue: string | string[] | JSX.Element | JSX.Element[],
    copyableValue?: string | string[],
    disableCopy?: boolean,
    editOnClick?: () => void,
    customAction?: JSX.Element
}
interface Props {
    header?: string | JSX.Element,
    description?: string,
    copyableList: Array<CopyableSetting>,
    actionBar?: JSX.Element,
    noBorders?: boolean
};

export const CopyableSettings = ({ header, description, actionBar, copyableList, noBorders }: Props) => {
    const classes = useStyles();

    const records = copyableList.map((l, i) => {
        const displayValues = Array.isArray(l.displayValue) ? l.displayValue : [l.displayValue]
        const copyableValues = Array.isArray(l.copyableValue) ? l.copyableValue : [l.copyableValue]

        return ({
            key: i.toString(),
            noBorders: noBorders,
            columns: [
                {value: isValidElement(l.title) ? l.title : <Typography variant="body1" noWrap>{l.title}</Typography>},
                {value: 
                    <Table size="small">
                        <TableBody>
                            {displayValues.map((d, i) => {
                                let displayElement: JSX.Element = <></>
                                let actionElement: JSX.Element = <></>

                                const makeActionElement = () => {
                                    if (l.customAction) return l.customAction;
                                    if (l.editOnClick) {
                                        return (
                                            <IconButton color="inherit" size="small" onClick={l.editOnClick}>
                                                <EditIcon />
                                            </IconButton>
                                        )
                                    }
                                    if (!l.disableCopy) {
                                        return (
                                            <CopyToClipboard text={copyableValues[i] || d.toString()}>
                                                <IconButton color="inherit" size="small">
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </CopyToClipboard>
                                        )
                                    } else {
                                        return <></>
                                    }
                                }

                                if (isValidElement(d)) {
                                    displayElement = d
                                    if (copyableValues[i] || l.customAction) {
                                        actionElement = makeActionElement()
                                    }
                                } else if (copyableValues[i] || d || l.editOnClick || typeof d === 'number') {
                                    displayElement = (
                                        <Typography variant="body2" color="textSecondary">{d}</Typography>
                                    )
                                    actionElement = makeActionElement()
                                }

                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left" className={classes.noBorderCell}>
                                            {displayElement}
                                        </TableCell>
                                        <TableCell align="right" className={classes.noBorderCell}>
                                            {actionElement}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                }
            ]
        })
    })   

    return (
        <DisplayTable {...{header}} {...{description}} {...{actionBar}} {...{records}} columnHeaders={[] as string[]} />
    )
};

const useStyles = makeStyles(() => ({
    noBorderCell: {
        borderBottom: '0px'
    }
}));
