import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from "react-router-dom";
import {
    ServicesData, ServicesQuery, IDRegistryOrgsData, IDRegistryOrgsQuery,
    MembershipData, MembershipQuery
} from '../../../models'
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from "@material-ui/core";
import { ServiceResourcesVars, ConsortiumResourceVars } from '../../../interfaces'
import { MembershipPermissions } from '../../../components/Memberships/MembershipPermissions';
import { MembershipSummary } from '../../../components/Memberships/MembershipSummary';

export const MembershipDetails: React.FC = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'MembershipDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`MembershipDetails:${key}`, interpolate)

    const { consortium_id, environment_id, membership_id } = useParams<any>();
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            membership
        } = { membership: null }
    } = useQuery<MembershipData, ConsortiumResourceVars>(MembershipQuery, {
        variables: {
            consortia_id: consortium_id!,
            id: membership_id!
        },
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const idRegistryServiceID = services.find(entry => entry.service === 'idregistry' && entry.state === 'started')?._id;

    const {
        data: {
            idRegistryOrgs
        } = { idRegistryOrgs: null }
    } = useQuery<IDRegistryOrgsData, ServiceResourcesVars>(IDRegistryOrgsQuery, {
        variables: { service_id: idRegistryServiceID! },
        fetchPolicy: 'cache-and-network',
        skip: !idRegistryServiceID
    });



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    {lt('membershipDetails')}
                </Typography>
            </Grid>
            <Grid item container direction="row" spacing={3}>
                <Grid item container xs={12}>
                    <MembershipSummary {...{membership}} {...{idRegistryOrgs}} />
                </Grid>
                <Grid item container xs={12}>
                    <MembershipPermissions {...{membership}} />
                </Grid>
            </Grid>
        </Grid>
    )
};

interface translations {
    membershipDetails: string,
    membershipSummary: string,
    certificate: string,
    commonName: string,
    organization: string,
    country: string,
    serial: string,
    downloadCertificate: string,
    notAvailable: string,
    undetermined: string
}

const enTranslations: translations = {
    membershipDetails: 'Membership Details',
    membershipSummary: 'Membership Summary',
    certificate: 'Certificate',
    commonName: 'Common name',
    organization: 'Organization',
    country: 'Country',
    serial: 'Serial',
    downloadCertificate: 'Download certificate',
    notAvailable: 'N/A',
    undetermined: 'Undetermined'
}