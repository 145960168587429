import React from 'react';
import { makeStyles, Chip, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckIcon from 'mdi-react/CheckIcon';
import CheckAllIcon from 'mdi-react/CheckAllIcon';
import AlertOutlineIcon from 'mdi-react/AlertOutlineIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import { EnvironmentState } from '../../models/environments';
import { capitalize } from '../../utils/StringUtils';
import { NodeState, RuntimeStateTranslationInterface, RuntimeStateTranslation } from '../../models/nodes';
import { ServiceState } from '../../models/services';
import { ChannelState } from '../../models/channels';
import { CompiledContractState, ConsortiumState, TransactionStatus, DocExchangeTransferStatus, InvitationState, MembershipSignatureTypes, OnChainRegistryStatesTypes } from '../../models';
import { ConfigState } from '../../models/configs';
import { AlertDarkColors } from '../../utils/Colors';
import { FireflyState } from '../../models/firefly';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';

interface Props {
    // build out as needed
    state: ChannelState | ConsortiumState | EnvironmentState | NodeState | ServiceState | CompiledContractState | 
        ConfigState | TransactionStatus | DocExchangeTransferStatus | InvitationState | 
        MembershipSignatureTypes | OnChainRegistryStatesTypes | FireflyState | 'yes' | 'no' |
        'upNext' | 'waiting' // (rotateSigners specific states) 
        | 'installed' | 'installing' | 'unknown' | 'notInstalled' // (chaincode specific states)
        | 'channelJoined' | 'channelJoining' // (channel joining specific states)
        | 'backupInProgress' // environment backup state
    size?: 'small' | 'medium' | undefined
}

export const ResourceStateChip = ({ state, size = 'small' } : Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'ResourceStateChip', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ResourceStateChip:${key}`, interpolate)

    const classes = useStyles();

    // Double check icon

     if (state === "externally_signed") {
        return (
            <Chip {...{size}} 
                label={capitalize(lt(state))} 
                icon={<CheckAllIcon className={classes.success_primary} />} 
                className={classes.success_primary} /> 
        )
    }

    // these states are good

    if (state === 'self_signed' || state === "live" || state === "started" || state === "compiled" || state === "active" || state === "applied" || 
        state === "success" || state === "received" || state === "accepted" || state === 'established' || state === 'installed' || state === 'yes' ||
        state === 'channelJoined') {
        return (
            <Chip {...{size}} 
                label={capitalize(lt(state))} 
                icon={<CheckIcon className={classes.success_primary} />} 
                className={classes.success_primary} /> 
        )
    }

    // failed is really bad

    if (state === "failed" || state === "declined" || state === 'undetermined') {
        return (
            <Chip {...{size}} 
                label={capitalize(lt(state))} 
                icon={<CloseIcon className={classes.error_primary} />} 
                className={classes.error_primary} /> 
        )
    }

    // these states are neutral with a clock icon

    if (state === 'waiting' || state === 'backupInProgress') {
        return (
            <Chip {...{size}} color='default'
                label={capitalize(lt(state))} 
                icon={<ClockOutlineIcon color='default' />}  /> 
        )
    }

    // these states are neutral with a question mark icon
    if (state === 'unknown' || state === 'notInstalled') {
        return (
            <Chip {...{size}} color='default'
                label={capitalize(lt(state))} 
                icon={<HelpCircleOutlineIcon color='default' />}  /> 
        )
    }

    // anything else, is sorta bad

    // fallback to raw state display if we dont have the translation
    let label = capitalize(state)
    if (state in enTranslations) {
        label = lt(state as keyof translations)
    }

    // Give the user a hint if we can
    if (state === 'notApplied' || state === 'updating' || state === 'creating' || state === 'uninitialized') {
        return <Tooltip title={lt(state === 'uninitialized' ? 'fireflyAction' : 'notAppliedAction')}>
            <Chip {...{size}} 
                {...{label}} 
                icon={<AlertOutlineIcon className={classes.warning_primary} />} 
                className={classes.warning_primary} /> 
        </Tooltip>
    }

    return (
        <Chip {...{size}} 
            {...{label}} 
            icon={<AlertOutlineIcon className={classes.warning_primary} />} 
            className={classes.warning_primary} /> 
    )
}

const useStyles = makeStyles(() => ({
    warning_primary: {
        backgroundColor: AlertDarkColors.orange,
        color: 'white',
    },
    success_primary: {
        backgroundColor: AlertDarkColors.green,
        color: 'white',
    },
    error_primary: {
        backgroundColor: AlertDarkColors.red,
        color: 'white',
    }
}))

interface translations extends RuntimeStateTranslationInterface {
    active: string,
    live: string,
    compiled: string
    applied: string
    notApplied: string
    notAppliedAction: string
    fireflyAction: string
    success: string
    sent: string
    received: string
    accepted: string
    declined: string
    self_signed: string
    externally_signed: string
    none: string
    established: string
    not_established: string
    undetermined: string
    updating: string
    creating: string
    upNext: string
    waiting: string
    installed: string
    installing: string
    yes: string
    no: string
    unknown: string
    channelJoined: string
    channelJoining: string
    notInstalled: string
    backupInProgress: string
}
const enTranslations: translations = {
    ...RuntimeStateTranslation,
    active: 'Active',
    live: 'Live',
    compiled: 'Compiled',
    applied: 'Applied',
    notApplied: 'Not Applied',
    notAppliedAction: 'Reset your node to apply the latest configuration',
    fireflyAction: 'Initialize your Firefly instances',
    success: 'Success',
    sent: 'Sent',
    received: 'Received',
    accepted: 'Accepted',
    declined: 'Declined',
    self_signed: 'Self Signed',
    externally_signed: 'Externally Signed',
    none: 'Not Confirmed',
    established: 'Established',
    not_established: 'Not Established',
    undetermined: 'Undetermined',
    updating: 'Updating',
    creating: 'Creating',
    upNext: 'Up Next',
    waiting: 'Waiting',
    installed: 'Installed',
    installing: 'Installing',
    yes: 'Yes',
    no: 'No',
    unknown: 'Unknown',
    channelJoined: 'Joined',
    channelJoining: 'Joining',
    notInstalled: 'Not Installed',
    backupInProgress: 'Backup Running'
}