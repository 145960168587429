import React from 'react';
import { useTranslation } from 'react-i18next';
import { Help } from '../../../components/DialogWrappers'

export const Step1Help = () => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'InviteOrganizationCreateStep1Help', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`InviteOrganizationCreateStep1Help:${key}`, interpolate)

    return (
        <Help 
            imagePath={`${process.env.PUBLIC_URL}/img/CreateWizards/Create-SideBar-Organization.svg`}
            header={lt('helpHeader')}
            description={lt('helpDescription')}
            bullet1={lt('helpDesc1')}
            bullet2={lt('helpDesc2')}
            finalInfo={lt('finalInfo')}
            docLink="https://docs.kaleido.io/using-kaleido/business-networks/"
        />
    )
};

interface translations {
    helpHeader: string,
    helpDescription: string,
    helpDesc1: string,
    helpDesc2: string,
    finalInfo: string,
}
const enTranslations: translations = {
    helpHeader: 'Invite External Organization',
    helpDescription: 'A core tenet of blockchain is the independent ownership of network resources and shared participation in the configured consensus algorithm.',
    helpDesc1: 'Decentralize ownership of your network by on-boarding an external organization',
    helpDesc2: 'Any on-boarded organization will be in sole control of their resources, runtimes and security credentials, and will possess any ascribed permissions',
    finalInfo: 'Governance schemes and access control become critical features when on-boarding external organizations to a network and will be configured in the following steps.',
}