import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuditEntry } from '../../models/audit';
import { capitalize } from '../../utils/StringUtils';

interface Props {
    auditEntry: AuditEntry
}

export const ActivityStatus = ({ auditEntry }: Props) => {
    const { t, i18n } = useTranslation();
    
    i18n.addResourceBundle('en', 'ActivityStatus', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`ActivityStatus:${key}`, interpolate)

    const getStatus = (entry: AuditEntry): string => {
        let status = entry.data.state || entry.action;
        switch (status) {
            case 'create': // handled in next case
            case 'created': return lt('statusCreated');
            case 'started': return lt('statusStarted');
            case 'live': return lt('statusLive');
            case 'pause_pending': return lt('statusPausePending');
            case 'paused': return lt('statusPaused');
            case 'resume_pending': return lt('statusResumePending');
            case 'failed': return lt('statusFailed');
            case 'compiling': return lt('statusCompiling');
            case 'compiled': return lt('statusCompiled');
            case 'initializing': return lt('statusInitializing');
            case 'provisioning': return lt('statusProvisioning')
            case 'deprovisioning': return lt('statusDeprovisioning');
            case 'delete': return lt('statusDelete');
            case 'active': return lt('statusActive');
            case 'update': return lt('statusUpdate');
            case 'sent': return lt('statusSent');
            case 'accepted': return lt('statusAccepted');
            case 'delete_pending': return lt('statusDeletePending');
            case 'setup': return lt('statusSetup');
            case 'archived': return lt('statusArchived');
            case 'locked': return lt('statusLocked');
            case 'joined': return lt('statusJoined');
            case 'joining': return lt('statusJoining');
            case 'deploying': return lt('statusDeploying');
            case 'upgrade_pending': return lt('statusUpgradePending');
            default: return capitalize(status)
        }
    };

    // calling component can wrap this however it likes
    return <>{getStatus(auditEntry)}</> 
};

interface translations {
    statusLive: string
    statusStarted: string
    statusPausePending: string
    statusResumePending: string
    statusPaused: string
    statusFailed: string
    statusCompiling: string
    statusCompiled: string
    statusCreated: string
    statusInitializing: string
    statusProvisioning: string
    statusDeprovisioning: string
    statusDelete: string
    statusActive: string
    statusUpdate: string
    statusSent: string
    statusAccepted: string
    statusDeletePending: string
    statusSetup: string
    statusArchived: string
    statusLocked: string
    statusJoined: string
    statusJoining: string
    statusDeploying: string
    statusUpgradePending: string
}
const enTranslations: translations = {
    statusLive: 'Live',
    statusStarted: 'Started',
    statusPausePending: 'Pause pending',
    statusResumePending: 'Resume pending',
    statusPaused: 'Paused',
    statusFailed: 'Failed',
    statusCompiling: 'Compiling',
    statusCompiled: 'Compiled',
    statusCreated: 'Created',
    statusInitializing: 'Initializing',
    statusProvisioning: 'Provisioning',
    statusDeprovisioning: 'Deprovisioning',
    statusDelete: 'Deleted',
    statusActive: 'Active',
    statusUpdate: 'Updated',
    statusSent: 'Sent',
    statusAccepted: 'Accepted',
    statusDeletePending: 'Delete pending',
    statusSetup: 'Setup',
    statusArchived: 'Archived',
    statusLocked: 'Locked',
    statusJoined: 'Joined',
    statusJoining: 'Joining',
    statusDeploying: 'Deploying',
    statusUpgradePending: 'Upgrade pending'
}