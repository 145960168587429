import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Grid, Button, Typography } from "@material-ui/core";
import { CopyableSettings, CopyableSetting } from '../../components/DisplaySettings'
import { EnRuntimeSizeTranslation, EnvironmentZonesData, EnvironmentZonesQuery, NodesData, NodesQuery, RuntimeSizeTranslation, ServicesData, ServicesQuery } from '../../models';
import { DisplayCard } from '../../components/DisplayWrappers';
import { ResourceStateChip } from '../../components/FormControls/ResourceStateChip';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import { useCertificateInfo } from '../../hooks/useCertificateInfo';
import { CreateIdentity } from './CreateIdentity';
import { FormLink } from '../../components/FormControls/FormLink';
import { Buffer } from 'buffer';

export const CertificateDetails: React.FC = () => {

    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CertificateDetails', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CertificateDetails:${key}`, interpolate)

    const {consortium_id, environment_id, service_id} = useParams<any>();
    const [open, setOpen] = useState(false);
    const environmentVars = {
        consortia_id: consortium_id!,
        environment_id: environment_id!
    }

    const {
        data: {
            environmentZones
        } = { environmentZones: [] }
    } = useQuery<EnvironmentZonesData>(EnvironmentZonesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    const {
        data: {
            nodes
        } = { nodes: [] }
    } = useQuery<NodesData>(NodesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only',
    });
    
    const {
        data: {
            services
        } = { services: [] }
    } = useQuery<ServicesData>(ServicesQuery, {
        variables: environmentVars,
        fetchPolicy: 'cache-only'
    });

    let caDetailsCopyableList: CopyableSetting[] = [];
    const ca = services.find((service) => service._id === service_id);
    const nodeIdentity = nodes.find((n) => n.membership_id === ca?.membership_id)?.node_identity_data;
    const certificate = nodeIdentity ? JSON.parse(Buffer.from(nodeIdentity, 'hex').toString()).orgCA : null;
    if (ca) {
        const environmentZone = environmentZones.find(zone => zone._id === ca.zone_id) || environmentZones[0];
        caDetailsCopyableList = [
            { title: lt('name'), displayValue: ca.name },
            { title: lt('runtimeId'), displayValue: ca._id },
            { title: lt('status'), displayValue: <ResourceStateChip state={ca.state} /> },
            { title: lt('size'), displayValue: lt(ca.size) },
            { title: lt('owner'), displayValue: ca.membership.name },
            { title: lt('region'), displayValue: environmentZone.displayName },
            { title: lt('created'), displayValue: new Date(ca.created_at).toLocaleString() },
            { title: lt('lastUpdate'), displayValue: new Date(ca.updated_at).toLocaleString() },
            { title: lt('httpEndpoint'), displayValue: ca.urls?.http ?? '-'}
        ];
        if (ca.details.external_key && ca.details.external_cert) {
            caDetailsCopyableList.push(
                { title: lt('externalCert'), displayValue: ca.details.external_cert},
                { title: lt('externalKey'), displayValue: ca.details.external_key}
            )
        }
        if (ca.details.enroll_with_ca) {
            caDetailsCopyableList.push(
                { title: lt('enrollWithCA'), displayValue: JSON.stringify(ca.details.enroll_with_ca)}
            )
        }
    }

    const {
        proof: membershipJSONIDProof
    } = useCertificateInfo({
        membershipId: ca?.membership_id ?? '',
        skipQuery: !ca,
    });

    let certificateCopyableList: CopyableSetting[] = [];
    if (membershipJSONIDProof) {
        certificateCopyableList = [
            {
                disableCopy: membershipJSONIDProof.commonName === undefined,
                title: lt("commonName"),
                displayValue:
                    membershipJSONIDProof.commonName || lt("notAvailable"),
            },
            {
                disableCopy: membershipJSONIDProof.organization === undefined,
                title: lt("organization"),
                displayValue:
                    membershipJSONIDProof.organization || lt("notAvailable"),
            },
            {
                disableCopy: membershipJSONIDProof.country === undefined,
                title: lt("country"),
                displayValue:
                    membershipJSONIDProof.country || lt("notAvailable"),
            },
            {
                disableCopy: membershipJSONIDProof.serial === undefined,
                title: lt("serial"),
                displayValue:
                    membershipJSONIDProof.serial || lt("notAvailable"),
            },
        ];
    }

    const docsList = [
        {
            icon: <FileDocumentIcon />,
            title: lt('certificateAuthority'),
            value: lt('documentation'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#managed-membership-cas')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('members'),
            value: lt('documentation'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#the-system-membership')
        },
        {
            icon: <FileDocumentIcon />,
            title: lt('identities'),
            value: lt('documentation'),
            actionIcon: <ChevronRightIcon />,
            onClick: () => window.open('https://docs.kaleido.io/kaleido-platform/protocol/fabric/fabric/#registering-identities-in-kaleido-ui')
        }
    ]

    return (
        <>
            <Grid item container spacing={3}>
                <Grid item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5">
                    {lt('caOverview')}
                    </Typography>
                </Grid>
                {ca?.membership.isMine && <Grid item>
                    <Button
                        data-test="button_addIdentity"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpen(true)}
                    >
                        {lt('newIdentity')}
                    </Button>
                </Grid>}
            </Grid>
                <Grid item md={12} lg={8}>
                    <CopyableSettings header={lt('information')} copyableList={caDetailsCopyableList} />
                </Grid>
                <Grid container item lg={4}>
                    <DisplayCard header={lt('documentation')} itemList={docsList}/>
                </Grid>
                {membershipJSONIDProof &&  (
                    <Grid item container spacing={3}>
                        <Grid item lg={6} xs={12}>
                            <CopyableSettings header={lt('certificate')} copyableList={certificateCopyableList} />
                        </Grid>
                    </Grid>
                )}
                 {certificate && 
                    <Grid item xs={12}>
                        <FormLink isExternal={true} download="cert.pem" to={`data:text/plain;charset=utf-8,${certificate}`}>{lt('downloadCertificate')}</FormLink>
                    </Grid>}
            </Grid>
            <CreateIdentity open={open} setOpen={setOpen} />
        </>
    )
};

interface translations extends RuntimeSizeTranslation {
    identityManagement: string,
    documentation: string
    type: string
    size: string
    name: string
    description: string
    region: string
    channelId: string
    status: string
    created: string
    lastUpdate: string
    manageChannel: string
    information: string
    membership: string
    memberships: string
    members: string
    viewCertificateDetails: string
    runtimeId: string
    identities: string
    certificateAuthority: string
    caOverview: string
    owner: string
    commonName: string;
    organization: string;
    country: string;
    httpEndpoint: string;
    serial: string;
    notAvailable: string;
    downloadCertificate: string;
    certificate: string
    newIdentity: string
    externalCert: string
    externalKey: string
    enrollWithCA: string
}
const enTranslations: translations = {
    ...EnRuntimeSizeTranslation,
    identityManagement: 'Identity Management',
    documentation: 'Documentation',
    type: 'Type',
    size: 'Node Size',
    name: 'Name',
    description: 'Description',
    region: 'Region',
    channelId: 'Channel ID',
    status: 'Status',
    created: 'Created',
    lastUpdate: 'Last update',
    manageChannel: 'Manage Channel',
    information: 'Information',
    membership: 'Membership',
    memberships: 'Memberships',
    members: 'Members',
    viewCertificateDetails: 'View certificate details',
    runtimeId: 'Runtime ID',
    identities: 'Identities',
    certificateAuthority: 'Certificate Authority',
    caOverview: 'Certificate Authority Overview',
    owner: 'Owner',
    commonName: "Common name",
    organization: "Organization",
    country: "Country",
    httpEndpoint: "HTTP Endpoint",
    serial: "Serial",
    notAvailable: "Not Available",
    downloadCertificate: "Download MSP CA Certificate",
    certificate: "Certificate",
    newIdentity: "Create New Identity",
    externalCert: 'External Cert',
    externalKey: 'External Key',
    enrollWithCA: 'External CA Details'
}
