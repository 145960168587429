import React, { useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { CloudConfigTypes, ConfigDetails } from '../../../models/configs';
import { FieldElement } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type configValues = keyof ConfigDetails | 'tls.ca_certs' | 'tls.client_certs' | 'tls.client_cert_secret';

export interface CreateConfigInputList {
    label: string
    value: configValues
    multiline?: boolean
    optional?: boolean
    checkbox?: boolean
}
interface Props {
    register: {<Element extends FieldElement<ConfigDetails> = FieldElement<ConfigDetails>>(): (ref: Element | null) => void;},
    inputList: CreateConfigInputList[],
    showCredsTypeSelection?: boolean
    configType?: CloudConfigTypes
}

export const CreateConfigForm = ({ register, inputList, showCredsTypeSelection = false ,configType}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateConfigForm', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateConfigForm:${key}`, interpolate);


    const [roleArn, setRoleArn] = useState<boolean>(true);

    const AwsCredsInputList: CreateConfigInputList[] = roleArn ? [
        {
            label: lt('roleArn'),
            value: 'role_arn'
        }] : [
        {
            label: lt('apiKey'),
            value: configType === 'kms' ? 'api_key' : 'user_id'
        },{
            label: lt('apiSecret'),
            value: configType === 'kms' ? 'api_secret' : 'user_secret'
        }
    ];
    return (
        <Grid item container direction="column" spacing={3}> 
            {inputList.map((entry, index) => {
                if (entry.checkbox) {
                    return (
                        <Grid item key={`config-form-${index}`}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        name={entry.value}
                                        inputRef={register} 
                                    />
                                }
                                label={entry.label}
                                labelPlacement="end"
                                />             
                        </Grid>
                    )
                }
                return (
                    <Grid item key={`config-form-${index}`}>
                        <TextField multiline={entry.multiline} 
                            rowsMax={4} 
                            data-test={`textField_${entry.value}`} 
                            fullWidth 
                            autoComplete='off' 
                            required={!entry.optional} 
                            label={entry.label} 
                            inputRef={register} 
                            variant="outlined" 
                            name={entry.value} />
                    </Grid>
                )
            })}
            {showCredsTypeSelection && 
            <>
                <Grid item>
                    <Typography variant="h5">{lt('credentials')}</Typography>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset" margin="none">
                        <RadioGroup value={roleArn} onChange={(event)=> setRoleArn(event.target.value === 'true' ? true : false)}>
                            <FormControlLabel checked={roleArn === true} 
                                value={true} 
                                control={<Radio color="primary" />} 
                                label={lt('roleArn')} />
                            <FormControlLabel checked={roleArn === false}
                                value={false} 
                                control={<Radio color="primary" />} 
                                label={lt('apiKeyAndSecret')} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {AwsCredsInputList.map((entry1, index1) => (
                    <Grid item key={`config-form-${entry1.value}`}>
                        <TextField multiline={entry1.multiline} 
                            rowsMax={4} 
                            data-test={`textField_${entry1.value}`} 
                            fullWidth 
                            autoComplete='off' 
                            required={true} 
                            label={entry1.label} 
                            inputRef={register} 
                            variant="outlined" 
                            name={entry1.value} />
                    </Grid>
                ))}
            </>
                }
        </Grid>

    )
}



interface translations {
    credentials: string,
    roleArn: string,
    apiKey: string, 
    apiSecret: string,
    apiKeyAndSecret: string
}
const enTranslations: translations = {
    credentials: 'Credentials',
    roleArn: 'Role ARN (Preferred)',
    apiKey: 'API Key (Deprecated)',
    apiSecret: 'API Secret (Deprecated)',
    apiKeyAndSecret: 'API Key and Secret (Deprecated)',
}