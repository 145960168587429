import React, { useState, useRef } from 'react';
import { makeStyles, Grid, List, Drawer, ListItem, ListItemIcon } from '@material-ui/core';
import { useParams, useHistory } from "react-router-dom";
import HomeOutlineIcon from 'mdi-react/HomeOutlineIcon';
import WebIcon from 'mdi-react/WebIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import clsx from 'clsx';
import { AccountWrapper } from './AccountWrapper'
import { BLUE_DRAWER_WIDTH } from './LayoutConstants'
import LanIcon from 'mdi-react/LanIcon';
import { useApolloClient } from '@apollo/client';
import { SessionData } from '../../interfaces';
import { SessionQuery } from '../../queries/Session';

interface Props {
    variant: 'permanent' | 'persistent'
    cachedEnvironmentId?: string
    isOpen?: boolean | undefined
    onClose?: () => void
};

export const BlueDrawer = ({ variant, cachedEnvironmentId, isOpen, onClose }: Props) => {
    const classes = useStyles();

    const history = useHistory()
    const { org_id, consortium_id, environment_id } = useParams<any>();

    const accountRef = useRef<HTMLElement>(null);
    const [accountOpen, setAccountOpen] = useState(false);

    const client = useApolloClient();
    const { session } = client.cache.readQuery<SessionData>({ query: SessionQuery })!;

    return (
    <>
        <Drawer classes={{
                    paper: classes.blueDrawer
                }}
                open={variant === 'persistent' ? isOpen : undefined}
                onClose={variant === 'persistent' ? onClose : undefined}
                variant={variant}
                className={classes.blueDrawerPaper}
                transitionDuration={0}
                anchor="left">
            <Grid item container className={clsx(classes.menuIcon, {
                [classes.hideMdUp]: true
            })}>
                <List disablePadding>
                    <ListItem button onClick={onClose} className={classes.listItem}>
                        <ListItemIcon className={classes.blueDrawerIcons}>
                            <MenuIcon />
                        </ListItemIcon>
                    </ListItem>
                </List>
            </Grid>

            <Grid container direction="column" justifyContent="space-between" alignItems="center" className={classes.allIcons}>
                <Grid item container direction="column" alignItems="center">
                    <Grid item className={classes.listContainer}>
                        <List disablePadding>
                            <ListItem button className={classes.listItem} onClick={() => history.push(`/orgs/${org_id || session.selected_org}`)} 
                                selected={!consortium_id ? true : false}
                                data-test="homeButton">
                                <ListItemIcon className={classes.blueDrawerIcons}>
                                    <HomeOutlineIcon />
                                </ListItemIcon>
                            </ListItem>
                        </List>
                    </Grid>
                    {consortium_id && 
                    <>
                        <Grid item className={clsx(classes.listContainer, classes.startResourceIcons)}>
                            <List disablePadding>
                                <ListItem button className={classes.listItem} onClick={() => history.push(`/orgs/${org_id}/consortia/${consortium_id}`)} 
                                    selected={consortium_id && !environment_id ? true : false}>
                                    <ListItemIcon className={classes.blueDrawerIcons}>
                                        <WebIcon />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </Grid>
                        {cachedEnvironmentId && 
                        <Grid item className={classes.listContainer}>
                            <List disablePadding>
                                <ListItem button className={classes.listItem} onClick={() => history.push(`/orgs/${org_id}/consortia/${consortium_id}/environments/${cachedEnvironmentId}`)} 
                                    selected={environment_id ? true : false}>
                                    <ListItemIcon className={classes.blueDrawerIcons}>
                                        <LanIcon />
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </Grid>}
                    </>
                    }
                </Grid>

                <Grid item container direction="column" alignItems="center">
                    <Grid item className={classes.listContainer}>
                        <List disablePadding>
                            <ListItem button onClick={() => setAccountOpen(!accountOpen)} selected={accountOpen} className={classes.listItem} 
                                data-test="accountWrapper">
                                <ListItemIcon className={classes.blueDrawerIcons} ref={accountRef}>
                                    <AccountWrapper open={accountOpen} setOpen={setAccountOpen} anchorRef={accountRef} />
                                </ListItemIcon>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>

            
        </Drawer>
    </>
    )
};

const useStyles = makeStyles( theme => ({
    startResourceIcons: {
        [theme.breakpoints.up('md')]: {
            marginTop: 6
        }
    }, 
    blueDrawerIcons: {
        color: 'white',
        minWidth: 0,
        paddingLeft: 10,
        paddingRight: 10
    },
    blueDrawer: {
        width: BLUE_DRAWER_WIDTH,
        backgroundColor: theme.palette.primary.main,
    },
    blueDrawerPaper: {
        width: BLUE_DRAWER_WIDTH,
    },
    menuIcon: {
        paddingTop: theme.spacing(2),
        paddingBottom: 12,
        color: 'white'
    },
    hideMdUp: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    allIcons: {
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(2),
        },
        flexGrow: 1,
        paddingBottom: theme.spacing(2)
    },
    listContainer: {
        paddingBottom: 6
    },
    listItem: {
        paddingLeft: 7,
        paddingRight: 8
    }
}));
