import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { FieldElement } from 'react-hook-form';
import { ConfigDetails } from '../../../models/configs';


interface Props {
    register: {<Element extends FieldElement<ConfigDetails> = FieldElement<ConfigDetails>>(): (ref: Element | null) => void;}
}

export const CreateNetworking = ({register}: Props) => {
    const { t, i18n } = useTranslation();
    i18n.addResourceBundle('en', 'CreateNetworking', enTranslations);
    const lt = (key: keyof translations, interpolate?: object) => t(`CreateNetworking:${key}`, interpolate);

    return  (
        <>
            <Grid item>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox color="primary" inputRef={register} name="allow_private" />}
                            label={lt('allowPrivate')}
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" inputRef={register} name="allow_public" />}
                            label={lt('allowPublic')}
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </>
    )

}

interface translations {
    allowPublic: string,
    allowPrivate: string
}
const enTranslations: translations = {
    allowPublic: 'Allow Public',
    allowPrivate: 'Allow Private'
}
